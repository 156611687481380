<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import ReviewList from './review-list.vue';
/**
 * Tabs & accordions component
 */
export default {
  components: { Layout, ReviewList, PageHeader },
  data() {
    return {};
  },
};
</script>

<template>
  <Layout>
    <review-list/>
  </Layout>
</template>