
// TaxSlab services rest api calling

import { multipart_headers } from '../Helper/helper';
import { routes } from '../config/api_routes'

export const taxSlabService = {
    
    // get taxslab
    getTaxSlab: () => {
        return window.axios.get(routes.getTaxSlabApi)
    },

}