<template>
    <div v-if= "order.task_id == null || (order.delivery_partner_status != null ? order.delivery_partner_status.toLowerCase() == 'cancelled' : order.delivery_partner_status == 'cancelled')" class="text-center">
    <b-button v-if="$auth.hasPermission(config.permissions.order_write) " variant="primary" size="sm" @click="checkDrivers()">{{trans.get('__JSON__.Assign')}}</b-button>
    <i v-else v-b-tooltip="'Need Permission'" class="mdi mdi-information h4 text-danger"></i>
    <b-modal :id="'modal-assign-'+order.order_id"
        lazy
        :ok-title="trans.get('__JSON__.Assign Driver') + modleShipmentMessage"
        @show="getDeliveryZones"
        @ok="assignDriver"
        scrollable
        >
        <template #modal-header="{ close }">
            <div class="align-self-center">
                <h4 class="mb-0">{{trans.get('__JSON__.Assign Driver') + modleShipmentMessage}}</h4>
            </div>
            <div class="align-items-center d-flex">
                <h4 class="mb-0">{{order.order_id}}</h4>
                <b-button variant="link" aria-label="Close"  @click="close()"><i class="fas fa-times text-black-50"></i></b-button>
            </div>
        </template>
        <div class="row">
            <div class="col-12">
                <b-form role="form">
                    <b-form-group :label="trans.get('Delivery Zone')">
                        <multiselect label="last_name" track-by="user_id" @input="onDeliveryZoneChange" v-model="selected" :options="options"></multiselect>
                    </b-form-group>
                    <div class="order-assign-m">
                        <b-table striped hover :items="drivers || []" :fields="fields" :busy="loading" thead-class="d-none">
                            <template #table-busy>
                                <div class="text-center text-danger my-2">
                                <b-spinner class="align-middle"></b-spinner>
                                <strong>{{trans.get('__JSON__.Loading...')}}</strong>
                                </div>
                            </template>
                            <template #cell(profile_path)="data">
                                <div class="d-flex justify-content-between">
                                    <div class="d-flex">
                                        <div class="d-flex align-items-center">
                                            <img role="button" :src="data.value" @click="openGallery" class="avatar-xs rounded-circle "
                                                @error="$event.target.src = config.no_image"
                                                alt=""/>
                                        </div>
                                        <div class="pl-2 text-left">
                                            <span class="h6">{{data.item.firstname +' '+data.item.lastname}}</span><br>
                                            <span class="small text-secondary">{{data.item.distance_in_km || '-'}} {{trans.get('__JSON__.KM')}} | {{data.item.order_count}} {{trans.get('__JSON__.Orders')}}</span>
                                        </div>
                                    </div>
                                    <div>
                                        <b-form-checkbox
                                            v-model="selected_driver"
                                            :value="data.item.driverid"
                                            :id="'order-driver-'+data.item.driverid"
                                            ></b-form-checkbox>
                                    </div>
                                </div>
                            </template>
                        </b-table>
                    </div>
                </b-form>
            </div>
        </div>
    </b-modal>
    <LightBox
        v-if="media && media.length > 0"
        ref="lightbox"
        :media="media"
        :show-caption="false"
        :show-light-box="false"      
    />
    </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import config from '../../../config';
import LightBox from 'vue-image-lightbox';
import { orderService, vendorService, } from '../../../services'
require('vue-image-lightbox/dist/vue-image-lightbox.min.css')
export default {
    props: ['order'],
    components:{Multiselect,LightBox},
    data(){
        return {
            config: config,
            frenchise_id: '0',
            options: [],
            selected: {last_name:'All',user_id:'0'},
            fields: [
                { key: "profile_path", label: '', sortable: false },
                // { key: "firstname", label: this.trans.get("__JSON__.Driver Name",{driver:this.$auth.getDriverName()}), sortable: false },
                // { key: "driverid", label: "#", sortable: false },
            ],
            media: [],
            drivers: [],
            selected_driver: {last_name:'All',user_id:'0'},
            loading: false,
            modleShipmentMessage: ""
        }
    },
    mounted() {
        // 
    },
    methods:{
        generateShipmentMessage() {
            if (this.order.suborder && this.order.suborder.id && this.order.suborder.id > 0) {
                if (this.order.suborder.order_status != 'Delivered') {
                    if (this.order.suborder.driver && this.order.suborder.driver.length > 0) {
                        this.modleShipmentMessage = ' To Shipment 2';
                    } else {
                        this.modleShipmentMessage = ' To Shipment 1';
                    }
                } else {
                    this.modleShipmentMessage = ' To Shipment 2';
                }
            } else {
                this.modleShipmentMessage = "";
            }
        },
        checkDrivers(){
            this.generateShipmentMessage();
            this.loading = true;
            this.$bvModal.show('modal-assign-'+this.order.order_id)
            orderService.checkAvailableDrivers({order_id:this.order.base_id, frenchise_id: this.frenchise_id})
            .then(response => {
                this.loading = false;
                this.drivers = response.data.result;
            })
        },
        getDeliveryZones(){
            vendorService.getDeliveryZones()
                .then(response => {
                    this.selected_driver = {last_name:'All',user_id:'0'};
                    this.options = [{last_name:'All',user_id:'0'},...response.data.result];
                });
        },
        onDeliveryZoneChange(){
            this.frenchise_id = '0';
            if(this.selected){
                this.frenchise_id = this.selected.user_id;
            }
            this.checkDrivers();
        },
        assignDriver(){
            let orderId = this.order.base_id;
            let shipmentMessage = '';
            if(this.selected_driver.length == 0){
                this.$toasted.error(this.trans.get('__JSON__.Please select driver'));
                return false;
            }
            if (this.order.suborder && this.order.suborder.id && this.order.suborder.id > 0) {
                if (this.order.suborder.order_status != 'Delivered') {
                    if (this.order.suborder.driver && this.order.suborder.driver.length > 0) {
                        orderId = this.order.base_id;
                        shipmentMessage = 'Shipment 2 notification send successfully';
                    } else {
                        orderId = btoa(this.order.suborder.id);
                        shipmentMessage = 'Shipment 1 notification send successfully';
                    }
                } else {
                    shipmentMessage = 'Shipment 2 notification send successfully';
                }
            }
            orderService.assignDriver({
                order_id: orderId,
                driver_id: this.selected_driver
            })
            .then(response => {
                this.$bvModal.hide('modal-assign-'+this.order.order_id);
                if (response.data.code == 200) {
                    if (shipmentMessage != '') {
                        this.$toasted.success(shipmentMessage);    
                    } else {
                        this.$toasted.success(response.data.message);
                    }
                } else {
                    this.$toasted.error(response.data.message);
                    this.$emit('actionChanged');
                }
            })
        },
        openGallery($event){
            this.media = [{
                src: $event.target.src,
                thumb: $event.target.src,
            }];
            this.$refs.lightbox.showImage(0);
        }
    }
};
</script>
<style scoped>
.order-assign-m {
    min-height: 250px;
}
</style>