
// Help services rest api calling

import { routes } from '../config/api_routes'

export const helpService = {
    // get help page 

    getHelpListing: () => {    
        return window.axios.get(routes.getHelpPageApi)
    }
}