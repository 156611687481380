<script>
import { webLayoutService } from "../../../services";
import { success_message, error_message } from "../../../Helper/helper";
import { required, requiredIf } from "vuelidate/lib/validators";
import config from "../../../config";
   
/**
 * Starter component
 */
export default {
   components: {},
   data() {
      return {
         config: config,
         web: [],
         web: {
           web_menu_category: '',
           web_vendor_listing_layout: '',
           web_restaurant_item_layout: '',
           web_business_category: ''
         },
      };
   },
   validations: {
      web: {
      }
   },
   mounted() {
      this.getWebSetting();
   },
   methods: {
      getWebSetting() {
         webLayoutService.getWebSetting().then((response) => {
            const { web_menu_category,
               web_vendor_listing_layout,
               web_restaurant_item_layout,
               web_business_category
            } = response.data.result;
            this.web = {
               web_menu_category: web_menu_category || '1',
               web_vendor_listing_layout: web_vendor_listing_layout || '1',
               web_restaurant_item_layout: web_restaurant_item_layout || '1',
               web_business_category: web_business_category || '1'
            };
         })
      },
      generalIt() {
         this.$v.$touch();
         //console.log(this.$v.setting.$invalid);
         if (this.$v.web.$invalid) {
            //console.log("if");
            return;
         } else {
            const fd = new FormData();
            fd.append("web_menu_category",this.web.web_menu_category);
            fd.append("web_vendor_listing_layout",this.web.web_vendor_listing_layout);
            fd.append("web_restaurant_item_layout",this.web.web_restaurant_item_layout);
            fd.append("web_business_category",this.web.web_business_category);
            fd.append("type", 5);
            webLayoutService.updateWebSetting(fd).then((response) => {
               if (response.data.code === 200) {
                  success_message(response.data.message);
               } else {
                  error_message(response.data.message);
               }
            });
         }
      },
   },
};
</script>
<template>
   <b-form @submit.prevent="generalIt">
      <div class="row">
         <div class="col-12">
            <div class="card">
               <div class="card-body setting-main">
                  <div class="row section-main">
                     <div class="col-md-4">
                        <div class="setting-data">
                           <h6>{{trans.get('__JSON__.Store Item Layout')}}</h6>
                        </div>
                        <div class="order-value">
                           <select class="custom-select" id="web_restaurant_item_layout" name="web_restaurant_item_layout" v-model="web.web_restaurant_item_layout">
                              <option v-bind:value="1" selected>{{ trans.get('__JSON__.Layout 1') }}</option>
                              <option v-bind:value="2">{{ trans.get('__JSON__.Layout 2') }}</option>
                           </select>
                           <!-- <multiselect v-model="setting.restaurant_payment_option" :options="payment"  name="payment" id="payment"></multiselect> -->
                        </div>
                        <div v-if="web.web_restaurant_item_layout == '1'" class="home-layout">
                           <img :src="config.web_store_baseurl+'storeitem/i2.png'">
                        </div>
                        <div v-else-if="web.web_restaurant_item_layout == '2'" class="home-layout">
                           <img :src="config.web_store_baseurl+'storeitem/i1.png'">
                        </div>
                     </div>
                     <div class="col-md-4" v-if="$auth.setting.business_category == 'Both'">
                        <div class="setting-data">
                           <h6>{{trans.get('__JSON__.Business Category Layout')}}</h6>
                        </div>
                        <div class="order-value">
                           <select class="custom-select" id="web_business_category" name="web_business_category" v-model="web.web_business_category">
                              <option v-bind:value="1" selected>{{ trans.get('__JSON__.Layout 1') }}</option>
                              <!-- <option v-bind:value="2">{{ trans.get('__JSON__.Layout 2') }}</option> -->
                              <!-- <option v-bind:value="3">{{ trans.get('__JSON__.Layout 3') }}</option> -->
                           </select>
                           <!-- <multiselect v-model="setting.restaurant_payment_option" :options="payment"  name="payment" id="payment"></multiselect> -->
                        </div>
                        <div v-if="web.web_business_category == '1'" class="home-layout">
                           <img :src="config.web_store_baseurl+'category/b1.png'">
                        </div>
                        <!-- <div v-else-if="web.web_business_category == '2'" class="home-layout">
                           <img :src="config.web_store_baseurl+'category/img1.png'">
                        </div> -->
                        <!-- <div v-else-if="web.web_menu_category == '3'" class="home-layout d-none">
                           <img :src="config.web_store_baseurl+'category/img1.png'">
                        </div> -->
                     </div>
                     <div class="col-md-4" v-else-if="$auth.setting.store_selection == 0">
                        <div class="setting-data">
                           <h6>{{trans.get('__JSON__.Store Category Layout')}}</h6>
                        </div>
                        <div class="order-value">
                           <select class="custom-select" id="web_menu_category" name="web_menu_category" v-model="web.web_menu_category">
                              <option v-bind:value="1" selected>{{ trans.get('__JSON__.Layout 1') }}</option>
                              <option v-bind:value="2">{{ trans.get('__JSON__.Layout 2') }}</option>
                              <!-- <option v-bind:value="3">{{ trans.get('__JSON__.Layout 3') }}</option> -->
                           </select>
                           <!-- <multiselect v-model="setting.restaurant_payment_option" :options="payment"  name="payment" id="payment"></multiselect> -->
                        </div>
                        <div v-if="web.web_menu_category == '1'" class="home-layout">
                           <img :src="config.web_store_baseurl+'itemcategory/store-category1.png'">
                        </div>
                        <div v-else-if="web.web_menu_category == '2'" class="home-layout">
                           <img :src="config.web_store_baseurl+'itemcategory/store-category2.png'">
                        </div>
                        <!-- <div v-else-if="web.web_menu_category == '3'" class="home-layout d-none">
                           <img :src="config.web_store_baseurl+'category/img1.png'">
                        </div> -->
                     </div>
                     <div class="col-md-4" v-if="$auth.setting.store_selection == 1">
                        <div class="setting-data">
                           <h6>{{trans.get('__JSON__.Store Listing Layout')}}</h6>
                        </div>
                        <div class="order-value">
                           <select class="custom-select" id="web_vendor_listing_layout" name="web_vendor_listing_layout" v-model="web.web_vendor_listing_layout">
                              <option v-bind:value="1" selected>{{ trans.get('__JSON__.Square') }}</option>
                              <option v-bind:value="2">{{ trans.get('__JSON__.Full') }}</option>
                           </select>
                           <!-- <multiselect v-model="setting.restaurant_payment_option" :options="payment"  name="payment" id="payment"></multiselect> -->
                        </div>
                        <div v-if="web.web_vendor_listing_layout == '1'" class="home-layout">
                           <img :src="config.web_store_baseurl+'listing/s1.png'">
                        </div>
                        <div v-else-if="web.web_vendor_listing_layout == '2'" class="home-layout">
                           <img :src="config.web_store_baseurl+'listing/s1.png'">
                        </div>
                     </div>
                  </div>
                  <div class="btn-update">
                     <div class="col-sm-6">
                        <button
                           v-if="$auth.hasPermission(config.permissions.web_layout_write)"
                           type="submit"
                           class="btn btn-primary mr-1 mt-3"
                        >{{ trans.get('__JSON__.Update') }}</button>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </b-form>
</template>
<style scoped>
.preview-category{
   display: inline-block;
   margin-left: 30px;
}
.home-layout img {
    width: 100%;
    border: 2px solid #000;
    border-radius: 2%;
    margin-top: 10px;
}
</style>