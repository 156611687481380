<script>
import config from "../../config";
import { authService, userService } from "../../services";
import {
  set_axios_defaults,
  set_partner_login,
  set_user,
  remove_partner_login,
  set_partner_token,
  set_partner_details,
  get_partner_token,
  remove_get_user,
  remove_get_user_detail,
  remove_partner,
  remove_partner_token,
  remove_partner_details,
  get_partner_login,
  set_partner_axios_defaults,
} from "../../Helper/helper";
import { required, email } from "vuelidate/lib/validators";
/**
 * Login component
 */

export default {
  data() {
    return {
      config: config,
      error: "",
      login: {
        email: "",
        password: "",
      },
    };
  },
  validations: {
    login: {
      email: {
        required,
        email,
      },
      password: {
        required,
      },
    },
  },
  mounted() {
    this.$refs.email.focus();
    if (this.$route.name == "vendor.login") {
      this.$auth.partner_login = true;
    } else {
      this.$auth.partner_login = false;
    }
  },
  methods: {
    logInIt() {
      this.$v.login.$touch();
      if (this.$v.login.$invalid) {
        return;
      } else {
        let name = "dashboard";
        if (this.$route.name == "vendor.login") {
          this.login.login_type = "partner";
          name = "vendor.dashboard";
        } else {
          this.login.login_type = "vendor";
        }
        this.$auth.login(this.login, (type) => {
          document.querySelector('title').innerHTML= this.$auth.setting.app_name;
          let img = document.querySelector('[type="image/x-icon"]');
          if (img) {
            img.setAttribute('href',this.$auth.setting.flat_icon)
          }
          this.$router.push({ name: type });
        });

        /* authService.loginUser(this.login).then((response) => {
          let token = response.data.result.token;
            remove_get_user();
            remove_get_user_detail();
            remove_partner_login();
            remove_partner();
            remove_partner_token();
            remove_partner_details();
          if (this.$route.name == "vendor.login") {
            set_partner_login();
          }
          if (token) {
            set_axios_defaults(token);
            this.$router.push({ name });
          }
           else {
            this.error = response.data.message;
          }
        }); */
      }
    },
  },
};
</script>

<template>
  <div>
    <div class="account-pages my-5 pt-5">
      <div class="container">
        <div class="row justify-content-center" v-if="!$auth.partner_login">
          <div class="col-md-8 col-lg-6 col-xl-5 login-main">
            <div class="card overflow-hidden">
              <div class="bg-soft-primary">
                <div class="row">
                  <div class="col-7">
                    <div class="text-primary p-4">
                      <h5 class="text-primary">{{ trans.get('__JSON__.Welcome Back') }} !</h5>
                      <p>Sign in to continue to {{ config.appname }} Admin Panel.</p>
                    </div>
                  </div>
                  <div class="col-5 align-self-end">
                    <img :src="config.basepath+'profile-img.png'" alt class="img-fluid" />
                  </div>
                </div>
              </div>
              <div class="card-body pt-0">
                <div>
                  <div class="avatar-md profile-user-wid mb-4 avatar-logo-main">
                    <span class="avatar-title rounded-circle bg-light">
                      <img :src="config.applogo" alt />
                    </span>
                  </div>
                </div>

                <b-form class="p-2" @submit.prevent="logInIt">
                  <div class="alert alert-danger" v-show="this.error">{{ this.error }}</div>
                  <slot />
                  <b-form-group
                    id="input-group-1"
                    :label="this.trans.get('__JSON__.Email')"
                    label-for="input-1"
                  >
                    <b-form-input
                      id="input-1"
                      name="email"
                      ref="email"
                      v-model="$v.login.email.$model"
                      type="text"
                      :placeholder="this.trans.get('__JSON__.Enter email')"
                      :class="{ 'is-invalid':  $v.login.email.$error }"
                    ></b-form-input>
                    <div v-if="$v.login.email.$error" class="invalid-feedback">
                      <span
                        v-if="!$v.login.email.required"
                      >{{ trans.get('__JSON__.Please enter email') }}</span>
                      <span
                        v-if="!$v.login.email.email"
                      >{{ trans.get('__JSON__.This value should be a valid email') }}</span>
                    </div>
                  </b-form-group>

                  <b-form-group
                    id="input-group-2"
                    :label="this.trans.get('__JSON__.Password')"
                    label-for="input-2"
                  >
                    <b-form-input
                      id="input-2"
                      v-model="$v.login.password.$model"
                      name="password"
                      type="password"
                      :placeholder="this.trans.get('__JSON__.Enter password')"
                      :class="{ 'is-invalid':  $v.login.password.$error  }"
                    ></b-form-input>
                    <div v-if="$v.login.password.$error" class="invalid-feedback">
                      <span
                        v-if="!$v.login.password.required"
                      >{{ trans.get('__JSON__.Please enter password')}}</span>
                    </div>
                  </b-form-group>
                  <div>
                    <button
                      class="btn btn-primary btn-block"
                      @keyup.enter="logInIt"
                    >{{ trans.get('__JSON__.Log In') }}</button>
                  </div>
                  <div class="col-sm-12">
                    <div class="row">
                      <div class="col-sm-12" v-if="!$auth.partner_login">
                        <div class="mt-3 text-center">
                          <a href="/vendor/login" target="_blank" class="text-muted">
                            <i class="mdi mdi-login mr-1"></i>
                            {{ config.merchantname }} {{ trans.get('__JSON__.Login') }}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-form>
              </div>
              <!-- end card-body -->
            </div>
            <!-- end card -->
          </div>
          <!-- end col -->
        </div>
        <!-- end row -->
         <div class="row justify-content-center" v-else>
          <div class="col-md-8 col-lg-6 col-xl-5 login-main">
            <div class="card overflow-hidden">
              <div class="bg-soft-danger">
                <div class="row">
                  <div class="col-7">
                    <div class="text-danger p-4">
                      <h5 class="text-danger">{{ trans.get('__JSON__.Welcome Back') }} !</h5>
                      <p>Sign in to continue to {{ config.appname }} {{ config.merchantname }} Panel.</p>
                    </div>
                  </div>
                  <div class="col-5 align-self-end">
                    <img :src="config.profile_img" alt class="img-fluid" />
                  </div>
                </div>
              </div>
              <div class="card-body pt-0">
                <div>
                  <div class="avatar-md profile-user-wid mb-4 avatar-logo-main">
                    <span class="avatar-title rounded-circle bg-light">
                      <img :src="config.applogo" alt />
                    </span>
                  </div>
                </div>

                <b-form class="p-2" @submit.prevent="logInIt">
                  <div class="alert alert-danger" v-show="this.error">{{ this.error }}</div>
                  <slot />
                  <b-form-group
                    id="input-group-1"
                    :label="this.trans.get('__JSON__.Email')"
                    label-for="input-1"
                  >
                    <b-form-input
                      id="input-1"
                      name="email"
                      ref="email"
                      v-model="$v.login.email.$model"
                      type="text"
                      :placeholder="this.trans.get('__JSON__.Enter email')"
                      :class="{ 'is-invalid':  $v.login.email.$error }"
                    ></b-form-input>
                    <div v-if="$v.login.email.$error" class="invalid-feedback">
                      <span
                        v-if="!$v.login.email.required"
                      >{{ trans.get('__JSON__.Please enter email') }}</span>
                      <span
                        v-if="!$v.login.email.email"
                      >{{ trans.get('__JSON__.This value should be a valid email') }}</span>
                    </div>
                  </b-form-group>

                  <b-form-group
                    id="input-group-2"
                    :label="this.trans.get('__JSON__.Password')"
                    label-for="input-2"
                  >
                    <b-form-input
                      id="input-2"
                      v-model="$v.login.password.$model"
                      name="password"
                      type="password"
                      :placeholder="this.trans.get('__JSON__.Enter password')"
                      :class="{ 'is-invalid':  $v.login.password.$error  }"
                    ></b-form-input>
                    <div v-if="$v.login.password.$error" class="invalid-feedback">
                      <span
                        v-if="!$v.login.password.required"
                      >{{ trans.get('__JSON__.Please enter password')}}</span>
                    </div>
                  </b-form-group>
                  <div>
                    <button
                      class="btn btn-danger btn-block"
                      @keyup.enter="logInIt"
                    >{{ trans.get('__JSON__.Log In') }}</button>
                  </div>
                  <div class="col-sm-12">
                    <div class="row">
                      <div class="col-sm-12">
                        <div class="mt-3 text-center">
                          <router-link to="forgot-password" class="text-muted">
                            <i class="mdi mdi-lock mr-1"></i>
                            {{ trans.get('__JSON__.Forgot your password') }}?
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-form>
              </div>
              <!-- end card-body -->
            </div>
            <!-- end card -->
          </div>
          <!-- end col -->
        </div>
         <!-- end row -->
      </div>
    </div>
  </div>
</template>

