<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import General from "./configuration/general";
import Order from "./configuration/order";
import DeliveryBoy from "./configuration/delivery-boy";
import PushNotifications from "./configuration/push-notifications";
import Keys from "./configuration/keys";
import Version from "./configuration/version";
import PaymentGateway from "./configuration/payment-gateway";
import Tax from "./configuration/tax";
import Wallet from "./configuration/wallet";

/** 
 * Tabs & accordions component
 */
export default {
  components: { Layout, PageHeader, General, Order, DeliveryBoy, PushNotifications, Keys, Version, PaymentGateway, Tax, Wallet },
  data() {
    return {
      setting:{},
    };
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between icon-main-page">
          <div class="page-title-left">
            <div class="avatar-xs mr-3">
              <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                <i class="bx bx-wrench"></i>
              </span>
            </div>
            <h4 class="mb-0 font-size-18">{{ trans.trans('__JSON__.Configuration') }}</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <!-- Tab panel start-->
            <b-tabs justified lazy nav-class="nav-tabs-custom mt-3 order-tabs" content-class="p-3 text-muted">
              <b-tab active>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-home"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">{{ trans.get('__JSON__.General') }}</span>
                </template>
                <General/>
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="far fa-user"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">{{ trans.get('__JSON__.Order') }}</span>
                </template>
                <Order/>
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="far fa-envelope"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">{{ $auth.getDriverName() }}</span>
                </template>
                <DeliveryBoy/>
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-home"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">{{ $auth.getTaxName() }}</span>
                </template>
                <Tax/>
              </b-tab>
              <!-- <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-cog"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">{{ trans.get('__JSON__.Push Notifications') }}</span>
                </template>
                <PushNotifications/>
              </b-tab> -->
              <b-tab v-if="$auth.user.wallet_status == 'active'">
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-cog"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">{{ trans.get('__JSON__.Wallet') }}</span>
                </template>
                <Wallet/>
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="far fa-envelope"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">{{ trans.get('__JSON__.Integrations') }}</span>
                </template>
                <Keys/>
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="far fa-user"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">{{ trans.get('__JSON__.Versions') }}</span>
                </template>
                <Version/>
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="far fa-user"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">{{ trans.get('__JSON__.Payment Gateway') }}</span>
                </template>
                <PaymentGateway/>
              </b-tab>
            </b-tabs>
            <!-- Tab panel end -->
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>