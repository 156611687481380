<script>
import Multiselect from "vue-multiselect";
import { required, requiredIf } from "vuelidate/lib/validators";
import { webLayoutService } from '../../../services';
import { success_message, error_message } from '../../../Helper/helper';
import config from '../../../config';

/**
 * Starter component
 */
export default {
  components: { Multiselect },
  data() {
    return {
      config:config,
      // web: [],
      web: {
        website_url: '',
        title: '',
        meta_description: '',
        keywords: '',
        is_custom_domain: 0//Default
      },
      domain_options: [
        {
          text: "Default",
          value: 0,
        },
        {
          text: "Custom",
          value: 1,
        }
      ]
    };
  },
  validations: {
    web: {
    }
  },
  mounted() {
    this.getSetting();
  },
  methods: {
    getSetting(){
      webLayoutService.getWebSetting().then((response) => {
        const { website_url,
          title,
          meta_description,
          keywords,
          is_custom_domain
        } = response.data.result;
        this.web = {
          website_url: website_url || '',
          title: title || '',
          meta_description: meta_description || '',
          keywords: keywords || '',
          is_custom_domain: is_custom_domain || 0
        };
      })
    },
    generalIt(){
      this.$v.$touch();
      if (this.$v.web.$invalid) {
        return;
      } else {
        const fd = new FormData();
        if (this.web.website_url != '' && this.web.website_url != null) {
          if (this.web.is_custom_domain == 1 || this.web.is_custom_domain == '1') { //Custom Domain
            fd.append("website_url", this.web.website_url);
          } else { //Default
            fd.append("website_url", this.web.website_url+config.orderWebDefaultUrl);
          }
        } else {
          fd.append("website_url", this.web.website_url);
        }
        fd.append("title", this.web.title);
        fd.append("meta_description", this.web.meta_description);
        fd.append("keywords",this.web.keywords);
        fd.append("is_custom_domain",this.web.is_custom_domain);
        fd.append('type',1);
        webLayoutService.updateWebSetting(fd).then((response) => {
          if (response.data.code === 200) {
            success_message(response.data.message);
          } else {
            error_message(response.data.message);
          }
        });
      }
    }
  }
};
</script>
<template>
  <b-form @submit.prevent="generalIt">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">

              <div class="col-md-3">
                <div class="form-group">
                  <label class="control-label">{{ trans.get('Web Ordering URL') }} <span class="infodetail" v-b-tooltip.hover title="To Bind Custom Domain Please contact Support & They will guide you for same">(?)</span></label>
                  <div class="input-group mb-3">
                    <input id="website_url" name="website_url" type="text" v-model="web.website_url" placeholder="Enter Business Name" class="form-control" autocomplete="off"/>
                    <div class="input-group-append" v-if="web.is_custom_domain == 0">
                      <span class="input-group-text defaulturl">{{ config.orderWebDefaultUrl }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-3 d-flex justify-content-center">
                <div class="form-group">
                  <label class="control-label">&nbsp;</label>
                  <div class="input-group mb-3">
                    <b-form-radio-group
                      id="btn-radios-1"
                      class="check-group"
                      v-model="web.is_custom_domain"
                      :options="domain_options"
                      buttons
                      name="radios-btn-default"
                    ></b-form-radio-group>
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label class="control-label">{{ trans.get('__JSON__.Page Title') }}</label>
                  <input id="title" name="title" type="text" class="form-control" v-model="web.title" :placeholder="trans.get('Enter Page Title')"/>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label class="control-label">{{ trans.get('__JSON__.Meta Description For SEO') }}</label>
                  <textarea
                    rows="5" 
                    id="meta_description" 
                    name="meta_description" 
                    type="text" 
                    v-model="web.meta_description" 
                    :placeholder="trans.get('Enter Meta Description For SEO')" 
                    class="form-control"
                  />
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label class="control-label">{{ trans.get('__JSON__.Keywords For SEO') }}</label>
                  <textarea
                    rows="5" 
                    id="keywords" 
                    name="keywords" 
                    type="text" 
                    v-model="web.keywords" 
                    :placeholder="trans.get('Enter Keywords For SEO')" 
                    class="form-control"
                  />
                </div>
              </div>
            </div>

            <div class="btn-update">
              <div class="col-sm-6">
                <button
                  v-if="$auth.hasPermission(config.permissions.web_layout_write)"
                  type="submit"
                  class="btn btn-primary mr-1 mt-3"
                >{{ trans.get('__JSON__.Update') }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-form>
</template>

<style type="text/css">
  .infodetail {
    color: red;
  }
  .defaulturl {
    background-color: #e9ecef !important;
  }
</style>