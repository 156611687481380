<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import vendorList from "./vendor-list";

/**
 * Vendor-list component
 */
export default {
  components: { Layout, PageHeader, vendorList },
};
</script>

<template>
  <Layout>
    <vendor-list />
  </Layout>
</template>