<script>
import vue2Dropzone from "vue2-dropzone";
import Multiselect from "vue-multiselect";
import { settingService } from "../../../services";
import { required, numeric, requiredIf } from "vuelidate/lib/validators";
import { success_message } from "../../../Helper/helper";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import config from "../../../config";

/**
 * Starter component
 */
export default {
  components: { vueDropzone: vue2Dropzone, Multiselect, DatePicker },
  data() {
    return {
      config: config,
      checked: true,
      checked1: true,
      checked2: true,
      value1: null,
      system_start_time: null,
      showTimePanel: false,
      showTimePanelend: false,
      setting: [],
      payment: [
        "Cash On Delivery",
        "Both",
        "PAYMENT ONLINE",
        "RESTAURANT SELECT PAYMENT OPTION",
      ],
      errors: "",
      app_signin_page: [{ facebook: "1", google: "1", phone: "1", email: "1" }],
      setting: {
        item_counts: "",
        min_order_value: "",
        restaurant_kilometer: "",
        contact_less_delivery_text: "",
        schedule_order_after_business_hours_text:"",
        schedule_order_after_business_hours_another_lang:"",
        custom_item: [],
        show_customer_to_vendor: "No",
        referral_Discount: "",
        instamed_cash_value: "",
        usable_cash_percentage: "",
        instamed_cash_value_affiliate: "",
        maximum_limit_instamed_cash_usages: "",
        affiliate_can_refer:"",
        customer_can_refer:"",
        maximum_order_to_settle:"",
        minimum_order_value_for_delivery:"",
        fixed_km:"",
        fixed_price:"",
        per_km_price:""
      },
    };
  },
  validations: {
    setting: {
      restaurant_kilometer: {
        required,
        numeric,
      },
      min_order_value: {
        required,
        numeric,
      },
      item_counts: {
        required,
        numeric,
      },
      contact_less_delivery_text: {
        required: requiredIf(function(form){
          return this.setting.contact_less_delivery == '1';
        }),
      },
      // schedule_order_after_business_hours_text:{
      //   required: requiredIf(function(form){
      //     return this.setting.is_enable_schedule_order_after_business_hours == '1';
      //   }),
      // },
      not_available_reason: {
        required: requiredIf(function (form) {
          return !this.setting.is_system_available;
        }),
        //  requiredIf:() => !this.setting.is_system_available,
      },
    },
  },
  mounted() {
    this.getSetting();
  },
  methods: {
    getSetting() {
      settingService.getSetting().then((response) => {
        this.setting = response.data.result;
        if (
          this.setting.app_signin_page &&
          this.setting.app_signin_page !== ""
        ) {
          this.app_signin_page = this.setting.app_signin_page;
        }
        this.system_start_time = response.data.result.system_start_time;
        if (this.setting.custom_item !== undefined && this.setting.custom_item.length == 0) {
          this.addCustomeField();
        }
      });
    },
    open_system() {
      this.setting.is_system_available = "Yes";
      // Swal.fire({
      //   title: "Are you sure?",
      //   text: "You won't be able to revert this!",
      //   icon: "success",
      //   showCancelButton: true,
      //   confirmButtonColor: "#34c38f",
      //   cancelButtonColor: "#f46a6a",
      //   confirmButtonText: "Ok"
      // }).then(result => {
      //   if (result.value) {
      //     Swal.fire("Ok!", "success");
      //   }
      // });
    },
    close_system() {
      this.setting.is_system_available = "No";
      // Swal.fire({
      //   title: "Are you sure?",
      //   text: "You won't be able to revert this!",
      //   icon: "warning",
      //   showCancelButton: true,
      //   confirmButtonColor: "#34c38f",
      //   cancelButtonColor: "#f46a6a",
      //   confirmButtonText: "Ok"
      // }).then(result => {
      //   if (result.value) {
      //     Swal.fire("Deleted!", "Your file has been deleted.", "success");
      //   }
      // });
    },
    toggleTimePanel() {
      this.showTimePanel = !this.showTimePanel;
    },
    handleOpenChange() {
      this.showTimePanel = false;
    },
    toggleTimePanelend() {
      this.showTimePanelend = !this.showTimePanelend;
    },
    handleOpenChangeend() {
      this.showTimePanelend = false;
    },
    generalIt() {
      this.$v.$touch();
      // console.log(this.$v.setting.$invalid);
      if (this.$v.setting.$invalid) {
        // console.log("if");
        return;
      } else {
        const fd = new FormData();
        fd.append("custom_item", JSON.stringify(this.setting.custom_item));
        fd.append("system_start_time", this.setting.system_start_time);
        fd.append("system_end_time", this.setting.system_end_time);
        fd.append("restaurant_kilometer", this.setting.restaurant_kilometer);
        fd.append("service_tax", this.setting.service_tax);
        fd.append("is_system_available", this.setting.is_system_available);
        fd.append("not_available_reason", this.setting.not_available_reason);
        fd.append(
          "not_available_reason_secondary_lang",
          this.setting.not_available_reason_secondary_lang
        );
        fd.append("show_customer_to_vendor", this.setting.show_customer_to_vendor);
        fd.append("is_pickup_enable", this.setting.is_pickup_enable);
        fd.append("is_same_day_delivery", this.setting.is_same_day_delivery);
        fd.append("is_enable_schedule_order_after_business_hours",this.setting.is_enable_schedule_order_after_business_hours);
        fd.append("schedule_order_after_business_hours_text",this.setting.schedule_order_after_business_hours_text);
        fd.append("schedule_order_after_business_hours_another_lang",this.setting.schedule_order_after_business_hours_another_lang);
        fd.append("min_order_value", this.setting.min_order_value);
        fd.append(
          "restaurant_payment_option",
          this.setting.restaurant_payment_option
        );
        fd.append("contact_less_delivery", this.setting.contact_less_delivery);
        fd.append(
          "contact_less_delivery_text",
          this.setting.contact_less_delivery_text
        );
        fd.append("payment", this.setting.payment);
        fd.append("item_counts", this.setting.item_counts);
        fd.append("app_signin_page", JSON.stringify(this.app_signin_page));
        fd.append("type", 1);
        fd.append("referral_Discount", this.setting.referral_Discount);
        fd.append("instamed_cash_value", this.setting.instamed_cash_value);
        fd.append("instamed_cash_value_affiliate", this.setting.instamed_cash_value_affiliate);
        fd.append("maximum_limit_instamed_cash_usages", this.setting.maximum_limit_instamed_cash_usages);
        fd.append("affiliate_can_refer", this.setting.affiliate_can_refer);
        fd.append("maximum_order_to_settle", this.setting.maximum_order_to_settle);
        fd.append("minimum_order_value_for_delivery", this.setting.minimum_order_value_for_delivery);
        fd.append("fixed_km", this.setting.fixed_km);
        fd.append("fixed_price", this.setting.fixed_price);
        fd.append("per_km_price", this.setting.per_km_price);
        fd.append("customer_can_refer", this.setting.customer_can_refer);
        fd.append("usable_cash_percentage", this.setting.usable_cash_percentage);
        settingService.updateSetting(fd).then((response) => {
          if (response.data.code === 200) {
            if (response.data.result) {
              this.$auth.updateSetting(response.data.result.settings);
            }
            success_message(response.data.message);
          } else {
            error_message(response.data.message);0
          }
        });
      }
    },
    addCustomeField: function () {
      let createNewId = (this.setting.custom_item.length + 1);
      this.setting.custom_item.push({ id: createNewId, name: ''});
    },
    removeCustomeField(index){
      if (this.setting.custom_item[index] && confirm('Are you sure...!! It will effect to items')) {
        this.setting.custom_item.splice(index,1);
      }
    },
  },
};
</script>

<template>
  <b-form @submit.prevent="generalIt">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body setting-main">
            <div class="row section-main">
              <div class="col-md-4">
                <div class="setting-heading">
                  <h4>Business Operation Hours</h4>
                </div>
              </div>
              <div class="col-md-8">
                <div class="setting-data">
                  <div class="row">
                    <div class="col-md-6">
                      <h6>Start Time</h6>
                      <date-picker
                        name="system_start_time"
                        id="system_start_time"
                        placeholder="Select datetime"
                        v-model="setting.system_start_time"
                        type="datetime"
                        value-type="format"
                        format="YYYY-MM-DD HH:mm:ss"
                        :show-time-panel="showTimePanel"
                        @close="handleOpenChange"
                      >
                        <template v-slot:footer>
                          <button
                            class="mx-btn mx-btn-text"
                            @click="toggleTimePanel"
                          >{{ showTimePanel ? 'select date' : 'select time' }}</button>
                        </template>
                      </date-picker>
                    </div>
                    <div class="col-md-6">
                      <h6>End Time</h6>
                      <date-picker
                        name="system_end_time"
                        id="system_end_time"
                        placeholder="Select datetime"
                        v-model="setting.system_end_time"
                        value-type="format"
                        format="YYYY-MM-DD HH:mm:ss"
                        type="datetime"
                        :show-time-panel="showTimePanelend"
                        @close="handleOpenChangeend"
                      >
                        <template v-slot:footer>
                          <button
                            class="mx-btn mx-btn-text"
                            @click="toggleTimePanelend"
                          >{{ showTimePanelend ? 'select date' : 'select time' }}</button>
                        </template>
                      </date-picker>
                    </div>
                  </div>
                  <div class="row mt-4">
                    <div class="col-md-6">
                      <h6>
                        Business Operation :
                        <span
                          class="business-status"
                          v-if="setting.is_system_available == 'Yes'"
                          @click="close_system"
                        >Running</span>
                        <span class="business-closed-status" v-else>Closed</span>
                      </h6>
                      <p>If you Pause Business Operation you will not able to take Any Orders</p>
                      <button
                        type="button"
                        class="btn btn-danger"
                        v-if="setting.is_system_available == 'Yes'"
                        @click="close_system"
                      >Pause Operation</button>
                      <button
                        type="button"
                        class="btn btn-success"
                        v-else
                        @click="open_system"
                      >Resume Operation</button>
                    </div>
                  </div>
                  <div class="tax-main" v-if="setting.is_system_available == 'No' || setting.is_system_available == false">
                    <div class="row mt-4">
                      <div class="col-md-12">
                        <div class="row">
                          <div class="col-md-12">
                            <h6>Message</h6>
                          </div>
                          <div class="col-md-12 order-value">
                            <b-form-group
                              id="input-group-1"
                              :label="trans.get('__JSON__.Pause operation Message')"
                              label-for="name"
                            >
                              <b-form-input
                                id="not_available_reason"
                                name="not_available_reason"
                                v-model="$v.setting.not_available_reason.$model"
                                type="text"
                                :placeholder="trans.get('__JSON__.Pause operation Message')"
                                :class="{ 'is-invalid':  $v.setting.not_available_reason.$error }"
                              ></b-form-input>
                              <div
                                v-if=" $v.setting.not_available_reason.$error"
                                class="invalid-feedback"
                              >
                                <span
                                  v-if="!$v.setting.not_available_reason.required"
                                >{{ trans.get('__JSON__.Pause operation Message') }}.</span>
                                <span
                                  v-if="!$v.setting.not_available_reason.numeric"
                                >{{ trans.get('__JSON__.Enter Numeric Value') }}.</span>
                              </div>
                            </b-form-group>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-4">
                      <div class="col-md-12 d-none" v-if="$auth.setting && $auth.langauges[1].name == 'No Secondary Language'"></div>
                      <div class="col-md-12" v-else>
                        <div class="row">
                          <div class="col-md-12">
                            <h6>{{ trans.trans('__JSON__.Message In Secondary Language', { lang: $auth.langauges[1].name }) }}</h6>
                          </div>
                          <div class="col-md-12 order-value">
                            <input
                              id="not_available_reason_secondary_lang"
                              name="not_available_reason_secondary_lang"
                              type="number"
                              class="form-control"
                              v-model="setting.not_available_reason_secondary_lang"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row section-main">
              <div class="col-md-4">
                <div class="setting-heading">
                  <h4>Application Settings</h4>
                </div>
              </div>
              <div class="col-md-8">
                <div class="setting-data">
                  <div class="row">
                    <div class="col-md-6">
                      <h6>Take Away Feature</h6>
                      <p>If you Turn OFF, On Application it will not show Take away Option.</p>
                    </div>
                    <div class="col-md-6">
                      <div class="swtich-data">
                        <b-form-group>
                          <label class="switch">
                            <input
                              type="checkbox"
                              id="is_pickup_enable"
                              name="is_pickup_enable"
                              v-model="setting.is_pickup_enable"
                              class="switch-on"
                              true-value="true"
                              false-value="false"
                            />
                            <div class="slider round">
                              <span class="on">{{ trans.get('__JSON__.On') }}</span>
                              <span class="off">{{ trans.get('__JSON__.Off') }}</span>
                            </div>
                          </label>
                        </b-form-group>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-md-6">
                      <h6>{{ trans.get('__JSON__.Same Day Delivery Feature') }}</h6>
                      <p>{{ trans.get('__JSON__.If you Enable this option, User can place schedule order for same day')}}.</p>
                    </div>
                    <div class="col-md-6">
                      <div class="swtich-data">
                        <b-form-group>
                          <label class="switch">
                            <input
                              type="checkbox"
                              id="is_same_day_delivery"
                              name="is_same_day_delivery"
                              v-model="setting.is_same_day_delivery"
                              class="switch-on"
                              true-value="1"
                              false-value="0"
                            />
                            <div class="slider round">
                              <span class="on">{{ trans.get('__JSON__.On') }}</span>
                              <span class="off">{{ trans.get('__JSON__.Off') }}</span>
                            </div>
                          </label>
                        </b-form-group>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-md-6">
                      <h6>{{ trans.get('__JSON__.Schedule Order After Business Hours') }}</h6>
                      <p>{{ trans.get('__JSON__.If Business hour is completed then system allow scheduled orders')}}.</p>
                    </div>
                    <div class="col-md-6">
                      <div class="swtich-data">
                        <b-form-group>
                          <label class="switch">
                            <input
                              type="checkbox"
                              id="is_enable_schedule_order_after_business_hours"
                              name="is_enable_schedule_order_after_business_hours"
                              v-model="setting.is_enable_schedule_order_after_business_hours"
                              class="switch-on"
                              true-value="1"
                              false-value="0"
                            />
                            <div class="slider round">
                              <span class="on">{{ trans.get('__JSON__.On') }}</span>
                              <span class="off">{{ trans.get('__JSON__.Off') }}</span>
                            </div>
                          </label>
                        </b-form-group>
                      </div>
                    </div>
                  </div>
                  <div class="row" v-if="setting.is_enable_schedule_order_after_business_hours == 1">
                    <div class="col-md-12">
                      <div class="row">
                        <div class="col-md-12">
                          <h6><!-- Description of Scheduled Order Delivery --></h6>
                          <h6>{{ trans.trans('__JSON__.Description of Scheduled Order Delivery',{ lang: $auth.langauges[0] && $auth.langauges[0].name}) }}</h6>
                        </div>
                        <div class="col-md-6 order-value">
                          <textarea rows="3" id="schedule_order_after_business_hours_text" type="text" class="form-control" v-model="setting.schedule_order_after_business_hours_text" name="schedule_order_after_business_hours_text"></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-4">
                      <div class="col-md-12 d-none" v-if="$auth.setting && $auth.langauges[1].name == 'No Secondary Language'"></div>
                      <div class="col-md-12" v-else>
                        <div class="row">
                          <div class="col-md-12">
                            <h6>{{ trans.trans('__JSON__.Description of Scheduled Order Delivery', { lang: $auth.langauges[1] && $auth.langauges[1].name }) }}</h6>
                          </div>
                          <div class="col-md-6 order-value">
                            <textarea rows="3" id="schedule_order_after_business_hours_another_lang" type="text" class="form-control" v-model="setting.schedule_order_after_business_hours_another_lang" name="schedule_order_after_business_hours_another_lang"></textarea>
                          </div>
                        </div>
                      </div>
                  </div>
                  <div class="tax-main">
                    <div class="row mt-4">
                      <div class="col-md-12">
                        <div class="row">
                          <div class="col-md-6 order-value">
                            <b-form-group
                              id="input-group-1"
                              :label="trans.get('__JSON__.Near By Restaurants Radius',{lang: $auth.setting && $auth.setting.restaurant_name})+' (In '+$auth.getVendorDistanceMetric()+')'"
                              label-for="name"
                            >
                              <b-form-input
                                id="restaurant_kilometer"
                                name="restaurant_kilometer"
                                v-model="$v.setting.restaurant_kilometer.$model"
                                type="text"
                                :placeholder="trans.get('__JSON__.Near By Restaurants Radius',{lang: $auth.setting && $auth.setting.restaurant_name})+' (In '+$auth.getVendorDistanceMetric()+')'"
                                :class="{ 'is-invalid':  $v.setting.restaurant_kilometer.$error }"
                              ></b-form-input>

                              <div
                                v-if=" $v.setting.restaurant_kilometer.$error"
                                class="invalid-feedback"
                              >
                                <span
                                  v-if="!$v.setting.restaurant_kilometer.required"
                                >{{ trans.get('__JSON__.Near By Restaurants Radius',{lang: $auth.setting && $auth.setting.restaurant_name}) }}.</span>
                                <span
                                  v-if="!$v.setting.restaurant_kilometer.numeric"
                                >{{ trans.get('__JSON__.Enter Numeric Value') }}.</span>
                              </div>
                            </b-form-group>
                          </div>
                          <div class="col-md-12">
                            <p>{{ trans.get('__JSON__.Ordering Application will show vendors within this radius') + ' (In '+$auth.getVendorDistanceMetric()+')'}}.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-4">
                      <div class="col-md-12">
                        <div class="row">
                          <div class="col-md-6 order-value">
                            <b-form-group
                              id="input-group-1"
                              :label="trans.get('__JSON__.Min Order Value')"
                              label-for="name"
                            >
                              <b-form-input
                                id="min_order_value"
                                name="min_order_value"
                                v-model="$v.setting.min_order_value.$model"
                                type="text"
                                :placeholder="trans.get('__JSON__.Min Order Value')"
                                :class="{ 'is-invalid':  $v.setting.min_order_value.$error }"
                              ></b-form-input>
                              <div
                                v-if=" $v.setting.min_order_value.$error"
                                class="invalid-feedback"
                              >
                                <span
                                  v-if="!$v.setting.min_order_value.required"
                                >{{ trans.get('__JSON__.Min Order Value') }}.</span>
                                <span
                                  v-if="!$v.setting.min_order_value.numeric"
                                >{{ trans.get('__JSON__.Enter Numeric Value') }}.</span>
                              </div>
                            </b-form-group>
                          </div>
                          <div class="col-md-12">
                            <p>{{ trans.get('__JSON__.Ordering Application will not allow placing an order less than the Minimum order value') }}.</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mt-4">
                      <div class="col-md-12">
                        <div class="row">
                          <div class="col-md-12">
                            <h6>{{trans.get('__JSON__.Payment Method')}}</h6>
                          </div>
                          <div class="col-md-6 order-value">
                            <select
                              class="custom-select"
                              v-model="setting.restaurant_payment_option"
                              id="restaurant_payment_option"
                              name="restaurant_payment_option"
                            >
                              <option value="Both">{{ trans.get('__JSON__.Both') }}</option>
                              <option value="COD">{{ trans.get('__JSON__.Cash On Delivery') }}</option>
                              <option value="PAY ONLINE">{{ trans.get('__JSON__.PAY ONLINE') }}</option>
                              <option
                                value="Restaurant Select Payment Option"
                              >{{ trans.get('__JSON__.RESTAURANT Select Payment Method', { vendor: $auth.setting.restaurant_name }) }}</option>
                            </select>
                            <!-- <multiselect v-model="setting.restaurant_payment_option" :options="payment"  name="payment" id="payment"></multiselect> -->
                          </div>
                          <div class="col-md-12">
                            <p>{{trans.get('__JSON__.Ordering Application will allow selected Payment methods')}}.</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mt-4">
                      <div class="col-md-12">
                        <div class="row">
                          <div class="col-md-6 order-value">
                            <b-form-group
                              id="input-group-1"
                              :label="trans.get('__JSON__.Item Loading Limit for Pagination')"
                              label-for="name"
                            >
                              <b-form-input
                                id="item_counts"
                                name="item_counts"
                                v-model="$v.setting.item_counts.$model"
                                type="text"
                                :placeholder="trans.get('__JSON__.Item Loading Limit for Pagination')"
                                :class="{ 'is-invalid':  $v.setting.item_counts.$error }"
                              ></b-form-input>
                              <div v-if=" $v.setting.item_counts.$error" class="invalid-feedback">
                                <span
                                  v-if="!$v.setting.item_counts.required"
                                >{{ trans.get('__JSON__.Item Loading Limit for Pagination') }}.</span>
                                <span
                                  v-if="!$v.setting.item_counts.numeric"
                                >{{ trans.get('__JSON__.Enter Numeric Value') }}.</span>
                              </div>
                            </b-form-group>
                          </div>
                          <div class="col-md-12">
                            <p>Number of Item Load Same time on {{ $auth.setting.restaurant_name }} Details Page.</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mt-4">
                      <div class="col-md-12">
                        <div class="row">
                          <div class="col-md-6 order-value">
                            <h6>Contactless Delivery</h6>
                          </div>
                          <div class="col-md-6">
                            <div class="swtich-data">
                              <b-form-group>
                                <label class="switch">
                                  <input
                                    type="checkbox"
                                    id="contact_less_delivery"
                                    name="contact_less_delivery"
                                    v-model="setting.contact_less_delivery"
                                    class="switch-on"
                                    true-value="1"
                                    false-value="0"
                                  />
                                  <div class="slider round">
                                    <span class="on">{{ trans.get('__JSON__.On') }}</span>
                                    <span class="off">{{ trans.get('__JSON__.Off') }}</span>
                                  </div>
                                </label>
                              </b-form-group>
                            </div>
                          </div>
                        </div>
                        <div class="row" v-if="setting.contact_less_delivery == '1'">
                          <div class="col-md-12">
                            <div class="row">
                              <div class="col-md-12">
                                <h6>Description of Contactless Delivery</h6>
                              </div>
                              <div class="col-md-6 order-value">
                                <textarea rows="3" id="contact_less_delivery_text" type="text" class="form-control" v-model="$v.setting.contact_less_delivery_text.$model" name="contact_less_delivery_text" :class="{ 'is-invalid':  $v.setting.contact_less_delivery_text.$error }"></textarea>
                                <div
                                  v-if=" $v.setting.contact_less_delivery_text.$error"
                                  class="invalid-feedback"
                                >
                                  <span
                                    v-if="!$v.setting.contact_less_delivery_text.required"
                                  >{{ trans.get('__JSON__.Please enter text')}}.</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mt-4">
                      <div class="col-md-12">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="row">
                              <div class="col-md-12">
                                <h6>Referral Discount</h6>
                              </div>
                              <div class="col-md-6 order-value">
                                <b-form-input rows="3" id="referral_Discount" type="text" class="form-control" v-model="setting.referral_Discount" name="referral_Discount"></b-form-input>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mt-4">
                      <div class="col-md-12">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="row">
                              <div class="col-md-12">
                                <h6>Usable Cash Percentage</h6>
                              </div>
                              <div class="col-md-6 order-value">
                                <b-form-input rows="3" id="usable_cash_percentage" type="text" class="form-control" v-model="setting.usable_cash_percentage" name="usable_cash_percentage"></b-form-input>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mt-4">
                      <div class="col-md-12">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="row">
                              <div class="col-md-12">
                                <h6>Instamed Cash Value Customer</h6>
                              </div>
                              <div class="col-md-6 order-value">
                                <b-form-input rows="3" id="instamed_cash_value" type="text" class="form-control" v-model="setting.instamed_cash_value" name="instamed_cash_value"></b-form-input>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mt-4">
                      <div class="col-md-12">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="row">
                              <div class="col-md-12">
                                <h6>Instamed Cash Value Affiliate</h6>
                              </div>
                              <div class="col-md-6 order-value">
                                <b-form-input rows="3" id="instamed_cash_value_affiliate" type="text" class="form-control" v-model="setting.instamed_cash_value_affiliate" name="instamed_cash_value_affiliate"></b-form-input>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mt-4">
                      <div class="col-md-12">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="row">
                              <div class="col-md-12">
                                <h6>Maximum Instamed Cash Usages Limit</h6>
                              </div>
                              <div class="col-md-6 order-value">
                                <b-form-input rows="3" id="maximum_limit_instamed_cash_usages" type="text" class="form-control" v-model="setting.maximum_limit_instamed_cash_usages" name="maximum_limit_instamed_cash_usages"></b-form-input>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mt-4">
                      <div class="col-md-12">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="row">
                              <div class="col-md-12">
                                <h6>Customer Can refer</h6>
                              </div>
                              <div class="col-md-6 order-value">
                                <b-form-input rows="3" id="customer_can_refer" type="text" class="form-control" v-model="setting.customer_can_refer" name="customer_can_refer"></b-form-input>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mt-4">
                      <div class="col-md-12">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="row">
                              <div class="col-md-12">
                                <h6>Affiliate Can refer</h6>
                              </div>
                              <div class="col-md-6 order-value">
                                <b-form-input rows="3" id="affiliate_can_refer" type="text" class="form-control" v-model="setting.affiliate_can_refer" name="affiliate_can_refer"></b-form-input>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mt-4">
                      <div class="col-md-12">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="row">
                              <div class="col-md-12">
                                <h6>Maximum Order Limit to Settle Weekly</h6>
                              </div>
                              <div class="col-md-6 order-value">
                                <b-form-input rows="3" id="maximum_order_to_settle" type="text" class="form-control" v-model="setting.maximum_order_to_settle" name="maximum_order_to_settle"></b-form-input>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mt-4">
                      <div class="col-md-12">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="row">
                              <div class="col-md-12">
                                <h6>Minimum Order Value for Delivery</h6>
                              </div>
                              <div class="col-md-6 order-value">
                                <b-form-input rows="3" id="minimum_order_value_for_delivery" type="text" class="form-control" v-model="setting.minimum_order_value_for_delivery" name="minimum_order_value_for_delivery"></b-form-input>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mt-4">
                      <div class="col-md-12">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="row">
                              <div class="col-md-12">
                                <h6>Fixed KM</h6>
                              </div>
                              <div class="col-md-6 order-value">
                                <b-form-input rows="3" id="fixed_km" type="text" class="form-control" v-model="setting.fixed_km" name="fixed_km"></b-form-input>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mt-4">
                      <div class="col-md-12">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="row">
                              <div class="col-md-12">
                                <h6>Fixed Price</h6>
                              </div>
                              <div class="col-md-6 order-value">
                                <b-form-input rows="3" id="fixed_price" type="text" class="form-control" v-model="setting.fixed_price" name="fixed_price"></b-form-input>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mt-4">
                      <div class="col-md-12">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="row">
                              <div class="col-md-12">
                                <h6>Per KM Price</h6>
                              </div>
                              <div class="col-md-6 order-value">
                                <b-form-input rows="3" id="per_km_price" type="text" class="form-control" v-model="setting.per_km_price" name="per_km_price"></b-form-input>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

             <div class="row section-main">
              <div class="col-md-4">
                <div class="setting-heading">
                  <h4>{{ trans.get('__JSON__.Data & Privacy') }}</h4>
                </div>
              </div>
              <div class="col-md-8">
                <div class="setting-data">
                  <div class="row">
                    <div class="col-md-6">
                      <h6>{{ trans.get('__JSON__.Show Customer Data to Vendor Panel/App',{ vendor: $auth.setting.restaurant_name }) }}</h6>
                      <p>{{ trans.get('__JSON__.If you turn OFF Vendor will not see Customers data Like Phone Number & Emails.',{ vendor: $auth.setting.restaurant_name }) }}</p>
                    </div>
                    <div class="col-md-6">
                      <div class="swtich-data">
                        <b-form-group>
                          <label class="switch">
                            <input
                              type="checkbox"
                              id="show_customer_to_vendor"
                              name="show_customer_to_vendor"
                              v-model="setting.show_customer_to_vendor"
                              class="switch-on"
                              true-value="Yes"
                              false-value="No"
                            />
                            <div class="slider round">
                              <span class="on">{{ trans.get('__JSON__.On') }}</span>
                              <span class="off">{{ trans.get('__JSON__.Off') }}</span>
                            </div>
                          </label>
                        </b-form-group>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row section-main">
              <div class="col-md-4">
                <div class="setting-heading">
                  <h4>{{ trans.get('__JSON__.Login Methods For Customers') }}</h4>
                </div>
              </div>
              <div class="col-md-8">
                <div class="setting-data" v-for="(v,index) in app_signin_page" :key="index">
                  <div class="row">
                    <div class="col-md-6 mt-1">
                      <h6>{{ trans.get('__JSON__.Facebook') }}</h6>
                    </div>
                    <div class="col-md-6">
                      <div class="swtich-data">
                        <b-form-group>
                          <label class="switch">
                            <input
                              type="checkbox"
                              id="facebook"
                              name="facebook"
                              v-model="v.facebook"
                              class="switch-on"
                              true-value="1"
                              false-value="0"
                            />
                            <div class="slider round">
                              <span class="on">{{ trans.get('__JSON__.On') }}</span>
                              <span class="off">{{ trans.get('__JSON__.Off') }}</span>
                            </div>
                          </label>
                        </b-form-group>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6 mt-1">
                      <h6>{{ trans.get('__JSON__.Google') }}</h6>
                    </div>
                    <div class="col-md-6">
                      <div class="swtich-data">
                        <!--    <label class="switch">
                        <input type="checkbox" id="is_same_day_delivery" name = "is_same_day_delivery" class="switch-on" v-model="setting.is_same_day_delivery"><div class="slider round"><span class="on">ON</span><span class="off">OFF</span></div></label>-->
                        <b-form-group>
                          <label class="switch">
                            <input
                              type="checkbox"
                              id="google"
                              name="google"
                              v-model="v.google"
                              class="switch-on"
                              true-value="1"
                              false-value="0"
                            />
                            <div class="slider round">
                              <span class="on">{{ trans.get('__JSON__.On') }}</span>
                              <span class="off">{{ trans.get('__JSON__.Off') }}</span>
                            </div>
                          </label>
                        </b-form-group>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6 mt-1">
                      <h6>{{ trans.get('__JSON__.Phone') }}</h6>
                    </div>
                    <div class="col-md-6">
                      <div class="swtich-data">
                        <!--    <label class="switch">
                        <input type="checkbox" id="is_same_day_delivery" name = "is_same_day_delivery" class="switch-on" v-model="setting.is_same_day_delivery"><div class="slider round"><span class="on">ON</span><span class="off">OFF</span></div></label>-->
                        <b-form-group>
                          <label class="switch">
                            <input
                              type="checkbox"
                              id="phone"
                              name="phone"
                              v-model="v.phone"
                              class="switch-on"
                              true-value="1"
                              false-value="0"
                            />
                            <div class="slider round">
                              <span class="on">{{ trans.get('__JSON__.On') }}</span>
                              <span class="off">{{ trans.get('__JSON__.Off') }}</span>
                            </div>
                          </label>
                        </b-form-group>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6 mt-1">
                      <h6>{{ trans.get('__JSON__.Email') }}</h6>
                    </div>
                    <div class="col-md-6">
                      <div class="swtich-data">
                        <!--    <label class="switch">
                        <input type="checkbox" id="is_same_day_delivery" name = "is_same_day_delivery" class="switch-on" v-model="setting.is_same_day_delivery"><div class="slider round"><span class="on">ON</span><span class="off">OFF</span></div></label>-->
                        <b-form-group>
                          <label class="switch">
                            <input
                              type="checkbox"
                              id="email"
                              name="email"
                              v-model="v.email"
                              class="switch-on"
                              true-value="1"
                              false-value="0"
                            />
                            <div class="slider round">
                              <span class="on">{{ trans.get('__JSON__.On') }}</span>
                              <span class="off">{{ trans.get('__JSON__.Off') }}</span>
                            </div>
                          </label>
                        </b-form-group>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row section-main">
              <div class="col-md-4">
                <div class="setting-heading">
                  <h4>{{ trans.get('__JSON__.Custom Items') }}</h4>
                </div>
              </div>
              <div class="col-md-8">
                <div class="setting-data" v-for="(v,index) in setting.custom_item" :key="index">
                  <div class="row" :class="{ 'mt-2': (index > 0) }">
                    <div class="col-md-4">
                      <h6>{{ trans.get('__JSON__.Custom Field Name') }}</h6>
                      <input
                        :id="'customefields_'+index"
                        :name="'customefields_'+index"
                        type="text"
                        class="form-control"
                        v-model="v.name"
                      />
                    </div>
                    <div class="col-md-3">
                      <h6>&nbsp;</h6>
                      <!-- <button class="btn btn-primary btn-sm btn-rounded" @click.prevent="addCustomeField">+</button>&nbsp;&nbsp; -->
                      <!-- <button class="btn btn-danger btn-sm btn-rounded" v-if="index != 0 || index!=''" @click.prevent="removeCustomeField(index)">-</button> -->
                      <button type="button" class="btn btn-danger btn-sm btn-rounded" @click.prevent="removeCustomeField(index)">-</button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4 mt-2">
                &nbsp;
              </div>
              <div class="col-md-8 mt-2">
                <button type="button" class="btn btn-primary btn-sm" @click.prevent="addCustomeField">Add Item</button>
              </div>
            </div>

            <div class="btn-update">
              <div class="col-sm-6">
                <button
                  type="submit"
                  v-if="$auth.hasPermission(config.permissions.configuration_write)"
                  class="btn btn-primary mr-1 mt-3"
                >Update</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-form>
</template>