<script>
import config from "../../config";
import pagination from "laravel-vue-pagination";
import moment from "moment";
import { deliveryZoneService} from '../../services';
import { set_axios_defaults } from '../../Helper/helper';
import { debounce,error_message } from "../../Helper/helper";

export default {
    components: { pagination, moment },
    data() {  
        const {country,state,city,orderBy,sortBy,status} = this.$route.query;
        return {
            sortBy: sortBy || "user_name",
            sortDesc: orderBy && orderBy != '' ? true : orderBy,
            zones:[],
            zoneData:[],
            zoneCount: '',
            paginations:{},
            limit:2,
            search:'',
            status: status == undefined ? "0" :status,
            config:config,
            moment: moment,
            countries: [],
            states: [],
            cities: [],
            country: country || 0,
            state: state || 0,
            city: city || 0,
            loading:true,
            isDownloading: false,
            searchData:debounce(()=>{
                const {status,country,state,city,orderBy,sortBy} = this.$data;
                this.$router.replace({name: 'list', query: {status,country,state,city,orderBy,sortBy}})
                this.getDeliveryZone();   
                // this.zoneExportCsv(); 
            },500),
            fields: [
                { key: 'last_name',label: this.trans.get("__JSON__.Delivery Zone Name"), sortable: true },
                { key: 'user_name',label: this.trans.get("__JSON__.Admin Name"), sortable: true },
                // { key: 'email',label: this.trans.get("__JSON__.Email"), sortable: true },
                // { key: 'franchisee_count',label: this.trans.get("__JSON__.Delivery Vendors"), sortable: true },
                { key: 'status',label: this.trans.get("__JSON__.Status"), sortable: true },
                { key: 'action',label: this.trans.get("__JSON__.Action"), sortable: false }
            ]
        }
    },
    mounted() {
        //this.fields[3].label = this.$auth.setting.restaurant_name;
        this.getVendorCountry();
    },
    methods:{
        downloadsCSV: function(){
            // Email,
            let zoneData = '\ufeff' + 'Delivery Zone Name,Admin Name,Status\n'
            this.zoneData.forEach(el=> {
                // + el['email'] + ','
                var line = el['last_name'] + ',' + el['user_name'] + ','  + (el['status'] == 0 ? 'Active' : 'Inactive') +'\n'
                zoneData +=line;
            })

            var blob = new Blob([ zoneData ], { "type" : "csv/plain" });
            var date = new Date().toLocaleString();
            let link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = 'Delivery_Zone_'+date+'.csv'
            link.click()
        },
        
        getDeliveryZone(page){
            this.loading = true;
            var filters = {
                search: this.search,
                status: this.status,
                sortBy: this.sortBy,
                orderBy: this.sortDesc ? "desc" : "asc",
                country: this.country,
                state: this.state,
                city: this.city,
                page: page && page > 0 ? page : 1,
            }
            deliveryZoneService.getDeliveryZone(filters)
            .then(response => {
                this.loading = false;
                this.paginations = response.data.result.data;
                this.zones = response.data.result.data.data;
                this.zoneCount = response.data.result.deliveryzoneCount;
            });
        },
        zoneExportCsv(){
            this.isDownloading = true;
            deliveryZoneService.zoneExportCsv({status: this.status, sortBy: this.sortBy, orderBy: this.sortDesc ? 'desc':'asc'})
            .then(response => {
                this.isDownloading = false;
                this.zoneData = response.data.result;
                this.downloadsCSV();
            });
        },
        getVendorCountry() {
            this.country = 0;
            this.city = 0;
            this.state = 0;
            this.countries = [];
            this.states = [];
            this.cities = [];
            deliveryZoneService.getVendorCountry().then((response) => {
                this.countries = response.data.result;
                if (this.countries.length == 1) {
                this.country = this.countries[0].id;
                }
                this.getVendorState();
            });
        },
        getVendorState() {
            this.city = 0;
            this.state = 0;
            this.states = [];
            this.cities = [];
            this.country = parseInt(this.country);
            if(this.country > 0) {
                deliveryZoneService.getVendorState({country_id: this.country})
                .then((response) => {
                this.states = response.data.result;
                if (this.states.length == 1) {
                    this.state = this.states[0].id;
                }
                this.getVendorCity();
                });
            } else {
                this.searchData();
            }
        },
        
        getVendorCity() {
            this.city = 0;
            this.cities = [];
            this.state = parseInt(this.state);
            if(this.state > 0) {
                deliveryZoneService.getVendorCity({state_id:this.state ? this.state : 0,country_id: this.country})
                .then((response) => {
                this.cities = response.data.result;
                if (this.cities.length == 1) {
                    this.city = this.cities[0].cityid;
                }
                this.searchData();
                });
            } else {
                this.searchData();
            }
        },
        sortingChanged(ctx){
            this.sortBy = ctx.sortBy;
            this.sortDesc = ctx.sortDesc;
            this.getDeliveryZone();
            this.zoneExportCsv(); 
        }
    }
};
</script>

<template>
    <div>
        <div class="row">
            <div class="col-12">
                <div
                class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
                >
                <div class="page-title-left">
                    <div class="avatar-xs mr-3">
                    <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                        <i class="bx bx-map-alt"></i>
                    </span>
                    </div>
                    <h4 class="mb-0 font-size-18">{{ trans.get('__JSON__.Delivery Zones') }}({{zoneCount}})</h4>
                </div>
                <div class="page-title-right d-flex">
                    <router-link v-if="$auth.hasPermission(config.permissions.delivery_zone_write)" :to="{name: 'delivery-zone-add'}" class="btn btn-success btn-rounded mr-2 add-btn">
                    <i class="mdi mdi-plus mr-1"></i> {{ trans.get('__JSON__.Add Delivery Zone') }}
                    </router-link>
                     <button @click="zoneExportCsv" class="btn btn-primary btn-rounded export-btn" v-if="isDownloading" disabled>{{ trans.get('__JSON__.Exporting...') }}</button>
                    <button @click="zoneExportCsv" class="btn btn-primary btn-rounded export-btn" v-else>
                        {{ trans.get('__JSON__.Export To CSV') }}
                    </button>
                  <div class="d-flex m-vendor-btn">
                    <router-link :to="{name: 'delivery-zone-add'}"  v-if="$auth.hasPermission(config.permissions.delivery_zone_write)" class="btn btn-success btn-rounded mr-2 m-add-btn">
                        <i class="mdi mdi-plus"></i>
                    </router-link>
                    <button
                        @click="zoneExportCsv"
                        class="btn btn-primary btn-rounded m-export-btn"
                    ><i class="bx bx-archive-in"></i></button>
                   </div>
                </div>
                </div>
            </div>
        </div>
        <div class="row list-table-main">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                        <div class="col-sm-2">
                        </div>
                        <div v-if="countries.length >1" class="col-sm-2">
                            <div class="form-group">
                                <div class="date-range-list">
                                    <label>{{ trans.get('__JSON__.Country') }} :</label>
                                        <select class="custom-select" v-model="country" @change="getVendorState">
                                        <option value="0" selected>All</option>
                                        <option v-for="con in countries" :value="con.id" :key="con.id">{{con.country_name}}</option>
                                        </select>
                                </div>
                            </div>
                        </div>
                        <div v-else class="col-sm-2">
                        </div>
                        <div v-if="countries.length >=1 && states.length >1" class="col-sm-2">
                            <div class="form-group">
                                <div class="date-range-list">
                                    <label>{{ trans.get('__JSON__.State') }} :</label>
                                    <select class="custom-select" v-model="state" @change="getVendorCity">
                                        <option value="0" selected>All</option>
                                        <option v-for="sat in states" :value="sat.id" :key="sat.id">{{sat.state_name}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div v-else class="col-sm-2">
                        </div>
                        <div v-if="states.length >=1 && cities.length >1" class="col-sm-2">
                            <div class="form-group">
                                <div class="date-range-list">
                                    <label>{{ trans.get('__JSON__.City') }} :</label>
                                    <select class="custom-select" v-model="city" @change="searchData">
                                        <option value="0" selected>All</option>
                                        <option v-for="cit in cities" :value="cit.cityid" :key="cit.cityid">{{cit.cityname}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div v-else class="col-sm-2">
                        </div>
                        <div class="col-sm-2">
                            <div class="form-group">
                            <div class="date-range-list">
                                <label>{{ trans.get('__JSON__.Status') }} :</label>
                                <select class="custom-select" v-model="status" @change="searchData()">
                                    <option value="">{{ trans.get('__JSON__.All') }}</option>
                                    <option value="0" selected>{{ trans.get('__JSON__.Active') }}</option>
                                    <option value="1">{{ trans.get('__JSON__.Inactive') }}</option>
                                </select>
                            </div>
                            </div>
                        </div>
                        <div class="col-sm-2">
                            <div class="search-box mr-2 mb-2 d-inline-block">
                            <div class="date-range-list">
                                <label>{{ trans.get('__JSON__.Search') }} :</label>
                                <div class="position-relative">
                                    <input type="text" class="form-control" @input="searchData()" v-model="search" :placeholder="trans.get('__JSON__.Search Placeholder')" />
                                    <i class="bx bx-search-alt search-icon"></i>
                                </div>
                                </div>
                            </div> 
                        </div>
                        </div>
                        <div class="row">
                        <div class="col-12 table-main-list delivery-zone-table table-responsive">
                        <b-table
                            :items="zones"
                            :fields="fields"
                            :sort-by.sync="sortBy"
                            :sort-desc.sync="sortDesc"
                            :no-local-sorting="true"
                            @sort-changed="sortingChanged"
                            :busy="loading"
                            show-empty
                        >
                            <template #table-busy>
                                <div class="text-center text-danger my-2">
                                <b-spinner class="align-middle"></b-spinner>
                                <strong>{{trans.get('__JSON__.Loading...')}}</strong>
                                </div>
                            </template>
                        
                            <template v-slot:cell(last_name)="data" >
                                <h5 class="font-size-14">{{data.item.last_name}}</h5>
                            </template>

                            <template v-slot:cell(user_name)="data">
                                <div>{{ data.item.user_name }}</div>
                            </template>

                            <template v-slot:cell(status)="data" >
                                <div v-if="data.item.status == '0'" class="badge badge-pill badge-soft-success font-size-12">
                                    {{ trans.get('__JSON__.Active') }}
                                </div>
                                <div v-else class="badge badge-pill badge-soft-danger font-size-12">
                                    {{ trans.get('__JSON__.Inactive') }}
                                </div>
                            </template>
                            
                            <template v-slot:cell(action)="data">
                                <div class="vendor-btn">
                                    <router-link
                                    v-if="$auth.hasPermission(config.permissions.delivery_zone_write)"
                                    class="btn btn-success btn-sm btn-rounded"
                                    :to="{ name: 'delivery-zone-edit', params: { user_id: base64(data.item.user_id)}}">{{ trans.get('__JSON__.Edit') }}
                                    </router-link>
                                    <router-link :to="{  name: 'delivery-zone-details', params: { user_id: base64(data.item.user_id)} }" class="btn btn-primary btn-sm btn-rounded ml-1">{{ trans.get('__JSON__.View') }}</router-link>
                                </div>
                            </template>

                            <template #empty>
                                <p class="text-center">{{ trans.get('__JSON__.No Delivery Zone') }}</p>
                            </template>
                        </b-table>  
                        </div>
                        <div class="row col-sm-5 offset-sm-7">
                            <div class="col">
                            <div class="dataTables_paginate paging_simple_numbers float-right">
                                <ul class="pagination pagination-rounded mb-0">
                                <!-- pagination -->
                                <pagination :data="paginations" :limit="limit" @pagination-change-page="getDeliveryZone"></pagination>
                                </ul>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
