<script>
import { required, numeric, email,requiredIf } from "vuelidate/lib/validators";
import { error_message } from "../../Helper/helper";
import { callCenterDashboardService } from "../../services";
import AddAddress from './address-add.vue';
export default {
  components: {AddAddress},
  data() {
    return {
      mobile_number: "",
      restaurant_count: "",
      restaurant:[],
      customerAddress: [],
      checksNumber: null,
      creating_user: false,
      user_details: { 
        user_id : null, 
        email : '', 
        user_name : '', 
        last_name : '', 
        mobile_number : '',
        address_id: '',
        restaurant_id: '',
        delivery_type: 'Delivery'
      },
      addNewAddress:false,
    };
  },
  validations: {
    user_details:{
      mobile_number: {
        required,
        numeric,
      },
      user_name:{
        required
      },
      email:{
        email,
        async isUnique(value){
          if (value === '') return true
          const response = await callCenterDashboardService.checkEmail({email: value, user_id: this.user_details.user_id})
          return Boolean(response.data.result.valid);
        }
      },
      address_id:{
        required: requiredIf((form) => (form.delivery_type == 'Delivery'))
      },
      restaurant_id:{
        required
      }
    }
  },
  methods: {
    validate(){
      this.$v.user_details.$touch();
      var isValid = !this.$v.user_details.$invalid
      this.$emit('on-validate', this.user_details, isValid)
      this.$emit('onGetUser',this.user_details)
      return isValid
    },
    checkNumber() {
      this.user_details = { user_id : null, email : '', user_name : '', last_name : '', mobile_number :  this.user_details.mobile_number,address_id: '',restaurant_id: '', delivery_type: this.user_details.delivery_type}
      this.customerAddress = []
      if(this.user_details.delivery_type == 'Delivery'){
        this.restaurant = []
      }
      this.$v.user_details.mobile_number.$touch();
      if (this.$v.user_details.mobile_number.$invalid) {
        return;
      } else {
        callCenterDashboardService
          .checkMobileNumber({
            mobile_number: this.user_details.mobile_number,
          })
          .then((response) => {
            this.checksNumber = response.data;            
            if (response.data.code == 200) {
              this.name =
                response.data.result[0].user_name +
                " " +
                response.data.result[0].last_name;
              this.emails = response.data.result[0].email;
              this.user_id = response.data.result[0].user_id;
              const { user_id, email, user_name, last_name,mobile_number } = response.data.result[0]
              this.user_details = { user_id, email, user_name, last_name,mobile_number, address_id: '',restaurant_id: '', delivery_type: this.user_details.delivery_type};
              this.$v.user_details.$touch()
            } else {
              error_message(response.data.message);
              if(response.data.message == 'No user found'){
                this.creating_user = true;
              }
              this.user_details = { user_id : null, email : '', user_name : '', last_name : '', mobile_number :  this.user_details.mobile_number,address_id: '',restaurant_id: '', delivery_type: this.user_details.delivery_type}
            }
            this.$emit('onGetUser',this.user_details)
            this.getCustomerAddress();
          });
      }
    },
    getCustomerAddress(id) {
      if(!this.user_details.user_id){
        this.user_details = {
          ...this.user_details,
          address_id: id || this.customerAddress[0].id
        }
        this.$emit('onRestaurantSelect','');
        this.getRestaurants();
          return false;
      }
      callCenterDashboardService
        .getCustomerAddress({
          user_id: this.user_details.user_id,
        })
        .then((response) => {
          this.customerAddress = response.data.result;
          if(!id){
            this.user_details.address_id = this.customerAddress.find(
              (a) => a.is_primary_address == "1"
            )?.id;
          }else{
            this.user_details = {
              ...this.user_details,
              address_id: id
            }
          }
          this.$emit('onRestaurantSelect','');
          this.getRestaurants();
        });
    },
    getRestaurants(){
        let address = this.customerAddress.find(add => add.id == this.user_details.address_id);
        this.$emit('addressChanged',address)
        if(this.user_details.delivery_type == 'Delivery'){
          this.restaurant = [];
        }
        callCenterDashboardService.getAllRestaurantName({
          lat: address && address.latitude || '',
          lng: address && address.longitude || '',
          delivery_type: this.user_details.delivery_type
        }).then((response) => {
          this.restaurant = response.data.result;
          this.$emit('onGetRestaurant',response.data.result);
          this.$emit('onGetUser',this.user_details)
        });
    },
    onAddressAdded(address){
      this.$bvModal.hide('addNewAddress');

      this.customerAddress = [...this.customerAddress,address];
      this.getCustomerAddress(address.id) 
    },
    clearData(){
      this.creating_user = false;
      this.user_details = { user_id : null, email : '', user_name : '', last_name : '', mobile_number : '', delivery_type:this.user_details.delivery_type }
      this.$v.user_details.$reset()
      this.customerAddress = []
      if(this.user_details.delivery_type == 'Delivery'){
        this.restaurant = []
      }
      this.$emit('onRestaurantSelect','');  
    },
    clearSave(){
      this.creating_user = false;      
      this.user_details.user_id = null;
      this.customerAddress = [];
      this.user_details.address_id='';
      if(this.user_details.delivery_type == 'Delivery'){
        this.restaurant = [];
      }      
    },
    getFilteredRestaurants(){
      return this.restaurant.filter(rest => [this.user_details.delivery_type == 'Delivery'?'1':'0','2'].includes(rest.delivery_type_time_slots));
    },
    resetRestaurant(){
      this.getRestaurants()
      this.user_details = {
        ...this.user_details,
        restaurant_id:''
      }
    },
    changeRestaurant(){
      this.$v.user_details.restaurant_id.$touch();
      this.$emit('onRestaurantSelect',this.user_details.restaurant_id)
    }
  },
};
</script>

<template>
  <div class="row">
    <div class="col-12">
      <div class="form-group row mb-3">
        <label class="col-md-3 col-form-label" for="mobile_number">{{
          trans.get("__JSON__.Phone Number")
        }}</label>
        <div class="col-md-9">
          <b-input-group :class="{ 'is-invalid': $v.user_details.mobile_number.$error }">
            <b-form-input
              type="tel"
              v-model="$v.user_details.mobile_number.$model"
              @change="clearSave"
              name="mobile_number"
              :disabled="user_details.user_id ? true : false"
              :placeholder="trans.get('__JSON__.Enter Phone Number')"
              @keyup.enter="checkNumber"
              @blur="checkNumber">
            </b-form-input>
            <b-input-group-append>
              <b-button variant="outline-danger" @click="clearData" ><i class="fas fa-times"></i></b-button>
              <b-button variant="outline-primary" @click="checkNumber" >Get Info</b-button>
            </b-input-group-append>
          </b-input-group>
          <div v-if="$v.user_details.mobile_number.$error" class="invalid-feedback">
            <span v-if="!$v.user_details.mobile_number.required">{{
              trans.get("__JSON__.Please enter contact number")
            }}</span>
            <span v-if="!$v.user_details.mobile_number.numeric">{{
              trans.get("__JSON__.This value should be a valid number")
            }}</span>
          </div>
          <!-- <input
            type="tel"
            v-model="$v.user_details.mobile_number.$model"
            @change="clearSave"
            name="mobile_number"
            class="form-control"
            :placeholder="trans.get('__JSON__.Enter Phone Number')"
            :class="{ 'is-invalid': $v.user_details.mobile_number.$error }"
            @keyup.enter="checkNumber"
          />
          <div v-if="$v.user_details.mobile_number.$error" class="invalid-feedback">
            <span v-if="!$v.user_details.mobile_number.required">{{
              trans.get("__JSON__.Please enter contact number")
            }}</span>
            <span v-if="!$v.user_details.mobile_number.numeric">{{
              trans.get("__JSON__.This value should be a valid number")
            }}</span>
          </div>
          <button type="button" class="btn btn-danger" @click="clearData" style="
                right: 85px;
                padding-top: 6px;
            ">
          <i class="fas fa-times"></i>
        </button>
          <button type="button" class="btn-btn primary" @click="checkNumber">
            {{ trans.get("__JSON__.Get Info") }}
          </button> -->
        </div>
      </div>

      <div class="form-group row mb-3">
        <label class="col-md-3 col-form-label" for="name">Name</label>
        <div class="col-md-9">
          <input
            type="text"
            name="name"
            v-model="$v.user_details.user_name.$model"
            :disabled="!creating_user || user_details.user_id ? true : false"
            class="form-control"
            :placeholder="trans.get('__JSON__.Enter Name')"
            :class="{ 'is-invalid': $v.user_details.user_name.$error }"
          />
          <div v-if="$v.user_details.user_name.$error" class="invalid-feedback">
            <span v-if="!$v.user_details.user_name.required">{{
              trans.get("__JSON__.Please enter user name")
            }}</span>
          </div>
        </div>
      </div>

      <div class="form-group row mb-3">
        <label class="col-md-3 col-form-label" for="email">Email</label>
        <div class="col-md-9">
          <input
            type="email"
            name="email"
            v-model="$v.user_details.email.$model"
            :disabled="!creating_user || user_details.user_id ? true : false"
            :class="{ 'is-invalid': $v.user_details.email.$error }"
            class="form-control"
            :placeholder="trans.get('__JSON__.Enter Email')"
          />
          <div v-if="$v.user_details.email.$error" class="invalid-feedback">
            <!-- <span v-if="!$v.user_details.email.required">{{
              trans.get("__JSON__.Please enter email")
            }}</span> -->
            <span v-if="!$v.user_details.email.email">{{
              trans.get("__JSON__.Please enter valid email")
            }}</span>
            <span v-if="!$v.user_details.email.isUnique">{{
              trans.get("__JSON__.Email already exists")
            }}</span>
          </div>
        </div>
      </div>

      <div class="form-group row mb-3">
        <label class="col-md-3 col-form-label" for="addreses">Address</label>
        <div class="col-md-9">
           <b-input-group :class="{ 'is-invalid': $v.user_details.address_id.$error }">
             <select
              class="custom-select"
              v-model="$v.user_details.address_id.$model"
              @change="getRestaurants"
              
            >
              <option key="a" value="">Select Address</option>
              <option
                v-for="adres in customerAddress"
                :key="adres.id"
                :value="adres.id"
              >
                {{ adres.address }}
              </option>
            </select>
            <b-input-group-append>
              <b-button v-b-modal.addNewAddress variant="outline-primary" @click="addNewAddress = true">{{ trans.get("__JSON__.Add Address") }}</b-button>
            </b-input-group-append>
          </b-input-group>         
          <div v-if="$v.user_details.address_id.$error" class="invalid-feedback">
            <span v-if="!$v.user_details.address_id.required">{{
              trans.get("__JSON__.Please select address")
            }}</span>
          </div>
        </div>
      </div>

      <div class="form-group row mb-3">
        <label class="col-md-3 col-form-label" for="restaurant">{{
          trans.trans("__JSON__.Vendor", {
            vendor: $auth.setting.restaurant_name,
          })
        }}</label>
        <div class="col-md-9">
          <b-input-group :class="{ 'is-invalid': $v.user_details.restaurant_id.$error }">
          <select
            class="custom-select"
            v-model="$v.user_details.restaurant_id.$model"
            v-on:change="changeRestaurant"
          >
            <option value="">
              {{
                trans.trans("__JSON__.All Vendor", {
                  vendor: $auth.setting.restaurant_name,
                })
              }}
            </option>
            <option
              v-for="res in getFilteredRestaurants()"
              :value="res.restaurant_id"
              :key="res.restaurant_id"
              :service_fee="res.service_fee"
            >
              {{ res.name }} {{ user_details.delivery_type =='Delivery'? ' - '+res.distance.toFixed(1)+' KM':'' }}
            </option>
          </select>
          <b-input-group-append is-text>
            <b-form-radio class="mr-n2 mb-n1" v-model="user_details.delivery_type" @input="resetRestaurant()" name="delivery_type" value="Delivery">
              <span class="font-weight-lighter pr-1">Delivery</span>
            </b-form-radio>
          </b-input-group-append>
          <b-input-group-append is-text>
            <b-form-radio class="mr-n2 mb-n1" v-model="user_details.delivery_type" @input="resetRestaurant()" name="delivery_type" value="Pickup">
              <span class="font-weight-lighter pr-1">TakeAway</span>
            </b-form-radio>
          </b-input-group-append>
          </b-input-group>
          <div v-if="$v.user_details.restaurant_id.$error" class="invalid-feedback">
            <span v-if="!$v.user_details.restaurant_id.required">{{
              trans.get("__JSON__.Please select restaurant")
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="addNewAddress" tabindex="-1" :title="trans.get('__JSON__.Add Address')" centered hide-footer>
      <add-address :user_id="user_details.user_id" @address-added="onAddressAdded" @onCancel="$bvModal.hide('addNewAddress')" />
    </b-modal>
  </div>
</template>
<style>
</style>