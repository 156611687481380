<script>
import vue2Dropzone from "vue2-dropzone";
import Multiselect from "vue-multiselect";
/**
 * Transactions component
 */
export default {
  components: { vueDropzone: vue2Dropzone, Multiselect },
  data() {
    return {
      showModal: false,
      x:15,
      tt:0,
      ap:['AM', 'PM'],
      i:0,
      selected : ''
    };
   },
   mounted(){
      this.getdaytime();
   },
    methods:{
      getdaytime()
      {
            var datetime = [];
            for (this.i=0;this.tt<24*60; this.i++) {
              let hh = Math.floor(this.tt/60); // getting hours of day in 0-24 format
              let mm = (this.tt%60); // getting minutes of the hour in 0-55 format
              datetime[this.i] = ("0" + (hh % 12)).slice(-2) + ':' + ("0" + mm).slice(-2) + this.ap[Math.floor(hh/12)]; // pushing data in array in [00:00 - 12:00 AM/PM format]
              this.tt = this.tt + this.x;
              this.selected = this.datetime;
            }
            console.log(this.selected);
      }
  },
};
</script>

<template>
  <div>
    <div class="table-responsive table-main-list">
      <table class="table table-centered table-nowrap">
        <thead>
          <tr>
            <th>Day</th>
            <th>Start Time</th>
            <th>Closing Time</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <select>
                <option :value="datetime">{{ datetime }}</option>
              </select>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row">
      <div class="col-sm-6 operation-btn">
        <button type="submit" class="btn btn-primary mr-1 mt-3">Update</button>
      </div>
    </div>
  </div>
  <!-- end table -->
</template>
