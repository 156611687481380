<template>  
  <!-- View Detail Main Start -->
  <b-modal
    v-model="showModal"
    size="lg"
    scrollable
    title-class="font-18"
    hide-footer
    @hidden="onModalHide"
  >
    <template #modal-header="{ close }">
      <!-- Emulate built in modal header close button action -->
      <h5>
        {{trans.get('__JSON__.Order No')+' '+(order && order.hash_id || '000')}}
        <span v-if="order && order.sipment1_orderId != null">({{'Sub Order of' +' '+'#'+ (order && order.sipment1_orderId || '000')}})</span>
        <span class="badge badge-pill badge-soft-primary font-size-12" v-if="order && order.delivery_pickup_types == 'Delivery'">
          {{trans.get('__JSON__.'+order && order.delivery_pickup_types)}}
        </span>
        <span class="badge badge-pill badge-soft-danger font-size-12" v-if="order && order.delivery_pickup_types == 'Takeaway'">
          {{trans.get('__JSON__.'+order && order.delivery_pickup_types)}}
        </span>
        <span class="badge badge-pill badge-soft-danger font-size-12" v-if="order && order.delivery_pickup_types == 'Pickup'">
          {{trans.get('__JSON__.'+order && order.delivery_pickup_types)}}
        </span>
        <span class="badge badge-pill font-size-12" :class="{'badge-soft-success': order && (order.payment_method == 'Cash On Delivery'),'badge-soft-warning':order && (order.payment_method != 'Cash On Delivery')}">
              {{order && (order.payment_method == 'Cash On Delivery'?'COD':order.payment_method)}}
        </span>
        <span v-if="(order && order.order_status == 'PaymentPending') || (order && order.order_status == 'PaymentFailure')" class="badge badge-pill font-size-12"  :class="{'badge-soft-warning': (order && order.order_status == 'PaymentPending'),'badge-soft-danger':(order && order.order_status == 'PaymentFailure')}">
          {{order && order.order_status == 'PaymentPending' ? 'Pending': 'Failed'}}
        </span>
        <span class="badge badge-pill badge-soft-secondary font-size-12" v-if="order && order.access">
          <i class="bx bx-phone-call"></i> {{trans.get('__JSON__.'+order && order.access && order.access.vendor_name)}}
        </span>
      </h5>
      <div>
        <!-- <b-button v-if="$auth.hasPermission(config.permissions.order_write) && order &&
        order.order_status == statuses.placed && order.consultation_request == null" variant="primary" size="sm" class="sent-To-Doctor" @click="sentToDoctor(order.id,order.customer_name)">
          Sent to Doctor
        </b-button>
        <b-button v-if="$auth.hasPermission(config.permissions.order_write) && order &&
        order.order_status == statuses.placed && order.consultation_request != null" variant="primary" size="sm" class="sent-To-Doctor" style="cursor: auto;">
          {{ order.consultation_request.request_status == 'NEWREQUEST' ? 'Pending on doctor' 
          : order.consultation_request.request_status == 'APPROVED' ? 'Approved by doctor' :
          order.consultation_request.request_status == 'REJECTED' ? 'Rejected by doctor' : 'Null'}}
        </b-button> -->
      <button 
        v-if="$auth.hasPermission(config.permissions.order_write) && order &&
        order.order_status == statuses.placed" 
        class="btn removeitem-confirm-btn"
        v-b-tooltip.hover.v-light :title="trans.get('__JSON__.Confirm Order')"
        @click="checkOrderItem(order.id,statuses.inkitchen)"
        :disabled="isAcceptbtnDisabled || disableSubmitBtn || disableSubmitBtnMRP">
        <i class="fas fa-check" ></i>
      </button>
      <a class="print-btn btn" v-if="$auth.partner_login" v-b-tooltip.hover.v-light :title="trans.get('__JSON__.A4')" :href="config.appurl+'store-print-order-new/'+base64(order && order.id+','+$auth.getVendorId())" target="_blank"><i class="mdi mdi-printer font-size-15"></i></a>
      <a class="print-btn btn" v-else v-b-tooltip.hover.v-light :title="trans.get('__JSON__.A4')" :href="config.appurl+'print-order-new/'+base64(order && order.id+','+$auth.getVendorId())" target="_blank"><i class="mdi mdi-printer font-size-15"></i></a>

      <a class="print-btn btn" v-if="$auth.partner_login" v-b-tooltip.hover.v-light :title="trans.get('__JSON__.Thermal Print')" :href="config.appurl+'store-print-order/'+base64(order && order.id+','+$auth.getVendorId())" target="_blank"><i class="mdi mdi-printer font-size-15"></i></a>
      <a class="print-btn btn" v-else v-b-tooltip.hover.v-light :title="trans.get('__JSON__.Thermal Print')" :href="config.appurl+'print-order/'+base64(order && order.id+','+$auth.getVendorId())" target="_blank"><i class="mdi mdi-printer font-size-15"></i></a>
      <button @click="close()" class="close">
        <i class="fas fa-times"></i>
      </button>
      </div>
    </template>
    <b-tabs
      justified
      nav-class="nav-tabs-custom mt-0 order-tabs"
      content-class="p-3 text-muted"
    >
      <b-tab :active="showModalTab==0 ? true : false">
        <template v-slot:title>
          <span class="d-inline-block d-sm-none">
            <i class="fas fa-receipt"></i>
          </span>
          <span class="d-none d-sm-inline-block">{{trans.get('__JSON__.Order Details')}}</span
          >
        </template>
        <order-details :order="order" :loading="orderLoading" /> 
      </b-tab>
      <b-tab :active="showModalTab==1 ? true : false">
        <template v-slot:title>
          <span class="d-inline-block d-sm-none">
            <i class="fas fa-list-ul"></i>
          </span>
          <span class="d-none d-sm-inline-block">{{trans.get('__JSON__.Order Item Details')}}</span>
        </template>
        <item-order-details :order="order" :loading="orderLoading" ref="removeItemDetails" @disableSubmit="updateparent" @disableSubmitMRP="updateparentMRP" @closeItemDetailsEmit="closeAndEmitChange"/> 
      </b-tab>
    </b-tabs>
  </b-modal>
</template>

<script>
import OrderDetails from './order-details'
import ItemOrderDetails from './item-order-detail'
import { orderService } from '../../../services'
import config from '../../../config'
import {debounce, order_statuses} from './../../../Helper/helper'
export default {
    components : {OrderDetails, ItemOrderDetails},  
    props :['order-id', 'showModalTab'],
    data(){
      return {
        config:config,
        orderLoading: false,
        showModal: false,
        order: null,
        statuses: order_statuses,
        isAcceptbtnDisabled: false,
        disableSubmitBtn: false,
        disableSubmitBtnMRP: false,
      }
    },
    mounted(){
      this.showModalTab = this.showModalTab??0;
      this.viewDetails();
      
    },
    methods : {
      updateparent(variable) {
        this.disableSubmitBtn = variable
    },
    updateparentMRP(variable) {
        this.disableSubmitBtnMRP = variable
    },
      viewDetails(){
        this.showModal = true;
        this.orderLoading = true;
        orderService.getOrder(this.orderId)
          .then(res => {
            this.order = res.data.result;
            this.orderLoading = false;
          })
      },
      onModalHide(){
        this.showModal = false;
        this.$emit('hidden');
      },
      checkOrderItem(id, status) {
        if(this.$refs.removeItemDetails.disableSubmit = true){
          this.isAcceptbtnDisabled = true;
        }
        //Call api to remove item and then confirm item
        if (this.$refs.removeItemDetails && this.$refs.removeItemDetails.remove_items) {
          if (this.$refs.removeItemDetails.update_items.items != "") { //call remove item api
            this.updatePriceWeb(id,status,this.$refs.removeItemDetails.update_items)
          }
          else if(this.$refs.removeItemDetails.remove_items.items != ""){
            this.removeOrderItem(id,status,this.$refs.removeItemDetails.remove_items);
            } else { //confirm order
            this.confirmOrder(id,status);
          }
        } else { //confirm order
          this.confirmOrder(id,status);
        }
      },
      removeOrderItem(id,status,data) {
        this.isAcceptbtnDisabled = true;
        orderService.removeOrderItem(data)
        .then(response => {
          if(response.data.code == 200){
            this.confirmOrder(id,status);
          } else {
            this.isAcceptbtnDisabled = false;
            this.$toasted.error(response.data.msg);
          }
        });
      },
      updatePriceWeb(id,status,data) {
        this.isAcceptbtnDisabled = true;
        orderService.updatePriceWeb(data)
        .then(response => {
          if(response.data.code == 200){
            if (this.$refs.removeItemDetails.remove_items.items != "") { //call remove item api
              const data =this.$refs.removeItemDetails.remove_items;
              orderService.removeOrderItem(data)
                .then(response => {
               if(response.data.code == 200){
                 this.confirmOrder(id,status);
                 } else {
                this.isAcceptbtnDisabled = false;
                this.$toasted.error(response.data.msg);
          }
        });
          }else{
            this.confirmOrder(id,status);
          }
          } else {
            this.isAcceptbtnDisabled = false;
            this.$toasted.error(response.data.msg);
          }
        });
      },
      confirmOrder(id,status){
        this.isAcceptbtnDisabled = true;
        const data = {order_id:id,status_name:status,vendor_id:this.$auth.getVendorId(),is_langauge: this.$auth.setting.primary_lang};
        orderService.changeOrderStatus(data)
        .then(response => {
          if (response.data.code == 200) {
            this.isAcceptbtnDisabled = false;
            this.$toasted.success(response.data.msg);
            this.$emit('actionChanged');
            this.onModalHide();
          } else {
            this.isAcceptbtnDisabled = false;
            this.$toasted.error(response.data.msg);
          }
        });
      },
      closeAndEmitChange() {
        this.$emit('actionChanged');
        this.onModalHide();
      },
      sentToDoctor(id,name){
        const data = {
          order_id: id,
          patient_name: name
        }
        orderService.doctorConsultation(data)
        .then(response => {
          if(response.data.code == 200){
            this.$toasted.success(response.data.msg);
            this.onModalHide();
          }else{
            this.$toasted.error(response.data.msg);
          }
        })
      }
    }
};
</script>

<style>
.print-btn {
  padding: 0px;
  color: #556ee6;
  border-radius: 50%;
  border: 1px solid #556ee6;
  height: 35px;
  width: 35px;
  line-height: 35px;
  text-align: center;
}
.print-btn:hover {
  color: #556ee6;
}
button.close {
  position: relative;
  top: 8px;
}
.removeitem-confirm-btn {
  padding: 0px;
  color: #fff;
  border-radius: 50%;
  background-color: #34c38f;
  height: 35px;
  width: 35px;
  line-height: 35px;
  text-align: center;
}
.removeitem-confirm-btn:hover {
  color: #fff;
}
.sent-To-Doctor{
  border-radius: 30px;
}
</style>