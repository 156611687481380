<script>
import vue2Dropzone from "vue2-dropzone";
import Multiselect from "vue-multiselect";
import { defaultSettingService, businessTypeService } from "../../../services";
import { required, numeric, requiredIf } from "vuelidate/lib/validators";
import { success_message } from "../../../Helper/helper";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import Swal from "sweetalert2";
import config from "../../../config";

/**
 * Starter component
 */
export default {
    components: { vueDropzone: vue2Dropzone, Multiselect, DatePicker },
    data() {
        return {
            config: config,
            checked: true,
            id: "",
            app_setting_id : "",
            setting: {
                wallet_status:  'active',
                wallet_recharge_limit:  0,
                maximum_wallet_amount:  0,
                customer_wallet_recharge: 'active',

                loyalty_cash_status: 'active',
                loyalty_cash_minimum_item_total: 0,
                loyalty_cash_percentage:0
            },
        }
    },
    validations: {
        setting: {
        },
    },
    mounted() {
        this.getBusinessTypeById();
    },
    methods: {
        getBusinessTypeById() {
            businessTypeService
                .getBusinessTypeById({ id: this.$route.params.id })
                .then((response) => {
                    this.id = response.data.result.business.id;
                    this.app_setting_id = response.data.result.business.app_setting_id;
                    this.setting = response.data.result.settings;
                });
        },
        generalIt() {
            this.$v.$touch();
            if (this.$v.setting.$invalid) {
                return;
            } else {
                const fd = new FormData();
                //Wallet
                fd.append("setting_id", this.id);
                fd.append("app_setting_id",this.app_setting_id);
                fd.append("wallet_status", this.setting.wallet_status);
                fd.append("wallet_recharge_limit", this.setting.wallet_recharge_limit || 0);
                fd.append("maximum_wallet_amount", this.setting.maximum_wallet_amount || 0);
                fd.append("customer_wallet_recharge", this.setting.customer_wallet_recharge);
                //Loyalty Cash
                fd.append("loyalty_cash_status", this.setting.loyalty_cash_status);
                fd.append("loyalty_cash_minimum_item_total", this.setting.loyalty_cash_minimum_item_total);
                fd.append("loyalty_cash_percentage", this.setting.loyalty_cash_percentage);
                fd.append("type",4);
                defaultSettingService.updateDefaultSetting(fd).then((response) => {
                    if (response.data.code === 200) {
                        success_message(response.data.message);
                    } else {
                        error_message(response.data.message);
                    }
                });
            }
        },
        decNumberOnly(evt) {
            let val1 = evt.target.value;
            if (!(evt.keyCode == 46 || (evt.keyCode >= 48 && evt.keyCode <= 57))) {
                evt.preventDefault();
            }
            let partlen = val1.split(".").length - 1;
            if (evt.keyCode == 46 && partlen > 0) {
                evt.preventDefault();
            }
        }
    }
};
</script>

<template>
    <b-form @submit.prevent="generalIt">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body setting-main">
                        <div class="row section-main">
                            <div class="col-md-4">
                                <div class="setting-heading">
                                    <h4>{{trans.get('__JSON__.Wallet')}}</h4>
                                </div>
                            </div>
                            <div class="col-md-8">
                                <div class="setting-data">
                                    <div class="row">
                                        <div class="col-md-6 vendor-status">
                                            <div class="swtich-data" >
                                                <b-form-group>
                                                    <label class="switch">
                                                        <input
                                                            type="checkbox"
                                                            id="wallet_status"
                                                            name="wallet_status"
                                                            v-model="setting.wallet_status"
                                                            class="switch-on"
                                                            true-value="active"
                                                            false-value="inactive"
                                                        />
                                                        <div class="slider round">
                                                            <span class="on">{{ trans.get('__JSON__.Active') }}</span>
                                                            <span class="off">{{ trans.get('__JSON__.Inactive') }}</span>
                                                        </div>
                                                    </label>
                                                </b-form-group>
                                            </div>
                                            <div class="col-md-12">
                                                <p>{{ trans.get('__JSON__.Customer can use wallet to do payment and manage it from Profile in application.') }}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="wallet-main">
                                        <div class="row mt-4">
                                            <div class="col-md-12">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <h6>{{trans.get('__JSON__.Maximum wallet amount limit')}}</h6>
                                                    </div>
                                                    <div class="col-md-6 order-value">
                                                        <input
                                                            id="maximum_wallet_amount"
                                                            name="maximum_wallet_amount"
                                                            type="number"
                                                            class="form-control"
                                                            v-model="setting.maximum_wallet_amount"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="row mt-4">
                                                    <div class="col-md-12">
                                                        <h6>{{trans.get('__JSON__.Maximum wallet recharge limit')}}</h6>
                                                    </div>
                                                    <div class="col-md-6 order-value">
                                                        <input
                                                            id="wallet_recharge_limit"
                                                            name="wallet_recharge_limit"
                                                            type="number"
                                                            class="form-control"
                                                            v-model="setting.wallet_recharge_limit"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-4">
                                        <div class="col-md-6 vendor-status">
                                            <div class="swtich-data">
                                                <b-form-group>
                                                    <label class="switch">
                                                        <input
                                                            type="checkbox"
                                                            id="customer_wallet_recharge"
                                                            name="customer_wallet_recharge"
                                                            v-model="setting.customer_wallet_recharge"
                                                            class="switch-on"
                                                            true-value="active"
                                                            false-value="inactive"
                                                        />
                                                        <div class="slider round">
                                                            <span class="on">{{ trans.get('__JSON__.Active') }}</span>
                                                            <span class="off">{{ trans.get('__JSON__.Inactive') }}</span>
                                                        </div>
                                                    </label>
                                                </b-form-group>
                                            </div>
                                            <div class="col-md-12">
                                                <p>{{ trans.get('__JSON__.This will enable wallet recharge to all customers.') }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row section-main">
                            <div class="col-md-4">
                                <div class="setting-heading">  
                                    <h4>{{ trans.get('__JSON__.Loyalty Cash') }}</h4>
                                </div>
                            </div>
                            <div class="col-md-8">
                                <div class="setting-data">
                                    <div class="row">
                                        <div class="col-md-6 vendor-status">
                                            <div class="swtich-data">
                                                <b-form-group>
                                                    <label class="switch">
                                                        <input
                                                            type="checkbox"
                                                            id="loyalty_cash_status"
                                                            name="loyalty_cash_status"
                                                            v-model="setting.loyalty_cash_status"
                                                            class="switch-on"
                                                            true-value="active"
                                                            false-value="inactive"
                                                        />
                                                        <div class="slider round">
                                                            <span class="on">{{ trans.get('__JSON__.Active') }}</span>
                                                            <span class="off">{{ trans.get('__JSON__.Inactive') }}</span>
                                                        </div>
                                                    </label>
                                                </b-form-group>
                                            </div>
                                            <div class="col-md-12">
                                                <h6>
                                                    {{ trans.get('__JSON__.Customer will get Cash rewards in wallet when order get delivered.') }}
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tax-main">
                                        <div class="row mt-4" v-if="setting.loyalty_cash_status == 'active'">
                                            <div class="col-md-12">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <h6>{{ trans.get('__JSON__.Minimum item total in order for earning') }}</h6>
                                                    </div>
                                                    <div class="col-md-6 order-value">
                                                        <input
                                                            id="loyalty_cash_minimum_item_total"
                                                            name="loyalty_cash_minimum_item_total"
                                                            type="text"
                                                            class="form-control"
                                                            v-model="setting.loyalty_cash_minimum_item_total"
                                                            @keypress="decNumberOnly($event)"
                                                            @paste.prevent
                                                        />
                                                    </div>
                                                </div>
                                                <div class="row mt-2">
                                                    <div class="col-md-12">
                                                        <h6>{{ trans.get('__JSON__.Loyalty Cash Value (In Percentage)') }}</h6>
                                                    </div>
                                                    <div class="col-md-6 order-value">
                                                        <input
                                                            id="loyalty_cash_percentage"
                                                            name="loyalty_cash_percentage"
                                                            type="text"
                                                            class="form-control"
                                                            v-model="setting.loyalty_cash_percentage"
                                                            @keypress="decNumberOnly($event)"
                                                            @paste.prevent
                                                        />
                                                    </div>
                                                </div>
                                                <div class="row mt-2">
                                                    <div class="col-md-12">
                                                        So How it will work ? :
                                                        <br><br>
                                                        If Customer will Place an order with 500 $, Customer will get 10 $ Loyalty cash in wallet after Delivery of order & They can use that Loyalty cash for next order.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="btn-update">
                            <div class="col-sm-6">
                                <button
                                    type="submit"
                                    class="btn btn-primary mr-1 mt-3"
                                >Update</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </b-form>
</template>