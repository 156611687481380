<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import { clientService } from "../../services";
import { required, email, sameAs, numeric } from "vuelidate/lib/validators";
import { error_message } from "../../Helper/helper";
import { success_message } from "../../Helper/helper";
import DatePicker from "vue2-datepicker";
/**
 * Add-product component
 */
export default {
  components: { Layout, PageHeader, DatePicker, clientService },
  data() {
    return {
      error: "",
      status: "",
      vendor_id: "",
      typeform: {
        app_name: "",
        vendor_name: "",
        vendor_number: "",
        email: "",
        address: "",
        subscription_plan_type: "",
        subscription_date: "",
        payment_link: "",
        is_single_restaurant: "",
        business_category: "",
        subscription_due_date: "",
        max_store_count: "",
        wallet_status: "inactive",
        call_center_status: "inactive",
        web_ordering_status: "inactive",
        allow_order_image_status: "inactive",
        product_type_id: "",
        business_type_id: "",
        store_selection: 0
      },
      product_type_list: [],
      business_type_list: []
    };
  },
  validations: {
    typeform: {
      app_name: {
        required,
      },
      vendor_name: {
        required,
      },
      vendor_number: {
        required,
        numeric
      },
      email: {
        required,
        email,
      },
      address:{
        required,
      },
      subscription_plan_type:{
        required,
      },
      subscription_date:{
        required,
      },
      payment_link:{
        required,
      },
      is_single_restaurant:{
        required,
      },
      business_category:{
        required,
      },
      subscription_due_date:{
        required,
      },
      max_store_count: {
        required,
      },
      product_type_id: {
        required,
      },
      business_type_id: {
        required,
      }
    },
  },
  mounted() {
    this.getProductBusinessTypes();
    this.getClientById();
  },
  methods: {
    getProductBusinessTypes() {
      clientService.getProductBusinessTypes().then((response) => {
        if (response.data.result.product_type_list) {
          this.product_type_list = response.data.result.product_type_list;
        }
        if (response.data.result.business_type_list) {
          this.business_type_list = response.data.result.business_type_list;
        }
      });
    },
    changeStatus(){
      this.typeform.status = this.status;
      clientService.updateClientStatus({
        vendor_id:this.vendor_id,
        status: this.status
      }).then(response => {
        if(response.data.code == 200){
          success_message(response.data.message)
        }
      })
    },
    getClientById() {
      clientService
        .getClientById({ vendor_id: this.$route.params.vendor_id })
        .then((response) => {
          const { vendor_id, vendor_name, email, vendor_number, address, subscription_plan_type, subscription_date, payment_link, app_name, is_single_restaurant, business_category, status, wallet_status, subscription_due_date, max_store_count, call_center_status, web_ordering_status, product_type_id, business_type_id, store_selection, allow_order_image_status } = response.data.result;
          this.status = status;
          this.vendor_id = vendor_id;
          this.typeform = {
            vendor_id, 
            vendor_name, 
            email, 
            vendor_number, 
            address, 
            subscription_plan_type,
            subscription_date, 
            payment_link,
            app_name,
            is_single_restaurant,
            business_category,
            status,
            wallet_status,
            subscription_due_date,
            max_store_count,
            call_center_status,
            web_ordering_status,
            product_type_id,
            business_type_id,
            store_selection,
            allow_order_image_status
          };
        });
    },
    updateClient(){
      this.$v.$touch();
      if (this.$v.typeform.$invalid) {
        return;
      } else {
        clientService.updateClient(this.typeform)
        .then(response => {
          if (response.data.code === 200) {
            success_message(response.data.message);
            this.$router.push({name:'client-list'});
          } else {
            error_message(response.data.message);
          }
        });
      }
    },
    onProductChange(ev) {
      this.typeform.business_type_id = "";
    },
    numberOnly(evt) {
      if (!(evt.keyCode >= 48 && evt.keyCode <= 57)) {
        evt.preventDefault();
      }
    }
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
        >
          <div class="page-title-left avatar-main-icon">
            <div class="avatar-xs mr-3">
              <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                <i class="bx bx-user"></i>
              </span>
            </div>
            <div class="report-tag">
              <h4 class="mb-0 font-size-18">{{ trans.get('__JSON__.Edit Client') }}</h4>
              <p><router-link :to="{ name: 'client-list'}">{{ trans.get('__JSON__.Client') }}</router-link> > {{ trans.get('__JSON__.Edit Client') }}</p>
          </div>
          </div>
          <div class="page-title-left avatar-main-icon">
            <div class="row">
              <div class="col-md-10 ml-auto">
                <label class="control-label font-weight-bold">{{ trans.get('__JSON__.Profile Status') }}</label> 
                  <select class="custom-select" v-bind:value="status" v-model="status" name="status" @change="changeStatus"> 
                    <option value="0">{{ trans.get('__JSON__.Inactive') }}</option>
                    <option value="1">{{ trans.get('__JSON__.Active') }}</option>
                    <option value="2">{{ trans.get('__JSON__.Waiting For Approval') }}</option>
                  </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b-form @submit.prevent="updateClient">
        <!-- Client Info Start -->
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">{{ trans.get('__JSON__.Client Info') }}</h4>
              <p class="card-title-desc"></p>
              <div class="row">
                <div class="col-sm-6">
                    <b-form-group
                        id="input-group-1"
                        :label="this.trans.get('__JSON__.Business Name')"
                        label-for="input-1"
                      >
                        <b-form-input
                          id="input-1"
                          name="app_name"
                          ref="app_name"
                          v-model="$v.typeform.app_name.$model"
                          type="text"
                          :placeholder="this.trans.get('__JSON__.Enter Business Name')"
                          :class="{ 'is-invalid':  $v.typeform.app_name.$error }"
                        ></b-form-input>
                        <div v-if="$v.typeform.app_name.$error" class="invalid-feedback">
                          <span
                            v-if="!$v.typeform.app_name.required"
                          >{{ trans.get('__JSON__.Please enter business name') }}</span>
                        </div>
                  </b-form-group>
                </div>

                <div class="col-sm-6">
                  <b-form-group
                    id="input-group-1"
                    :label="this.trans.get('__JSON__.Business Person Name')"
                    label-for="input-1"
                  >
                    <b-form-input
                      id="input-1"
                      name="vendor_name"
                      ref="vendor_name"
                      v-model="$v.typeform.vendor_name.$model"
                      type="text"
                      :placeholder="this.trans.get('__JSON__.Enter Business Person name')"
                      :class="{ 'is-invalid':  $v.typeform.vendor_name.$error }"
                    ></b-form-input>
                    <div v-if="$v.typeform.vendor_name.$error" class="invalid-feedback">
                      <span
                        v-if="!$v.typeform.vendor_name.required"
                      >{{ trans.get('__JSON__.Please enter business person name') }}</span>
                    </div>
                  </b-form-group>
                </div>

                <div class="col-sm-6">
                  <b-form-group
                    id="input-group-1"
                    :label="this.trans.get('__JSON__.Email')"
                    label-for="input-1"
                  >
                    <b-form-input
                      id="input-1"
                      name="email"
                      ref="email"
                      v-model="$v.typeform.email.$model"
                      type="text"
                      :placeholder="this.trans.get('__JSON__.Enter email')"
                      :class="{ 'is-invalid':  $v.typeform.email.$error }"
                    ></b-form-input>
                    <div v-if="$v.typeform.email.$error" class="invalid-feedback">
                      <span
                        v-if="!$v.typeform.email.required"
                      >{{ trans.get('__JSON__.Please enter email') }}</span>
                      <span
                        v-if="!$v.typeform.email.email"
                      >{{ trans.get('__JSON__.This value should be a valid email') }}</span>
                    </div>
                  </b-form-group>
                </div>

                <div class="col-sm-6">
                  <b-form-group
                    id="input-group-1"
                    :label="this.trans.get('__JSON__.Contact Number')"
                    label-for="input-1"
                  >
                    <b-form-input
                      id="input-1"
                      name="vendor_number"
                      ref="vendor_number"
                      v-model="$v.typeform.vendor_number.$model"
                      type="text"
                      :placeholder="this.trans.get('__JSON__.Enter Contact Number')"
                      :class="{ 'is-invalid':  $v.typeform.vendor_number.$error }"
                    ></b-form-input>
                    <div v-if="$v.typeform.vendor_number.$error" class="invalid-feedback">
                      <span
                        v-if="!$v.typeform.vendor_number.required"
                      >{{ trans.get('__JSON__.Please enter contact number') }}</span>
                      <span
                        v-if="!$v.typeform.vendor_number.numeric"
                      >{{ trans.get('__JSON__.This value should be a valid number') }}</span>
                    </div>
                  </b-form-group>
                </div>

                <div class="col-sm-6">
                  <b-form-group
                    id="input-group-1"
                    :label="this.trans.get('__JSON__.Address')"
                    label-for="input-1"
                  >
                    <b-form-input
                      id="input-1"
                      name="address"
                      ref="address"
                      v-model="$v.typeform.address.$model"
                      type="text"
                      :placeholder="this.trans.get('__JSON__.Enter Address')"
                      :class="{ 'is-invalid':  $v.typeform.address.$error }"
                    ></b-form-input>
                    <div v-if="$v.typeform.address.$error" class="invalid-feedback">
                      <span
                        v-if="!$v.typeform.address.required"
                      >{{ trans.get('__JSON__.Please enter address') }}</span>
                    </div>
                  </b-form-group>
                </div>

              </div>
            </div>
          </div>
        <!-- Client Info End -->

        <!-- Plan Details Start -->
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">{{ trans.get('__JSON__.Plan Details') }}</h4>
              <p class="card-title-desc"></p>
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="control-label">{{ trans.trans('__JSON__.Plan Type') }}</label>
                    <select
                      class="custom-select"
                      v-model="$v.typeform.subscription_plan_type.$model"
                      id="subscription_plan_type"
                      name="subscription_plan_type"
                      :class="{ 'is-invalid':  $v.typeform.subscription_plan_type.$error  }"
                    >
                      <option value="" selected>{{ trans.get('__JSON__.Select Plan Type') }}</option>
                      <option value="basic">{{ trans.get('__JSON__.Basic') }}</option>
                      <option value="plus">{{ trans.get('__JSON__.Plus') }}</option>
                      <option value="premium">{{ trans.get('__JSON__.Premium') }}</option>
                    </select>
                    <div v-if=" $v.typeform.subscription_plan_type.$error" class="invalid-feedback">
                      <span
                        v-if="!$v.typeform.subscription_plan_type.required"
                      >{{ trans.trans('__JSON__.please select at least one type.') }}.</span>
                    </div>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <b-form-group
                      id="input-group-1"
                      :label="this.trans.get('__JSON__.Number OF Vendor')"
                      label-for="input-1"
                    >
                      <b-form-input
                        id="input-1"
                        name="payment_link"
                        v-model="$v.typeform.max_store_count.$model"
                        type="text"
                        :placeholder="
                          this.trans.get('__JSON__.Enter Number OF Vendor')
                        "
                        :class="{
                          'is-invalid': $v.typeform.max_store_count.$error,
                        }"
                        @keypress="numberOnly($event)" @paste.prevent
                      ></b-form-input>
                      <div
                        v-if="$v.typeform.max_store_count.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.typeform.max_store_count.required">{{
                          trans.get("__JSON__.Please enter number of vendor")
                        }}</span>
                      </div>
                    </b-form-group>
                  </div>
                </div>

                <div class="col-sm-6">
                  <b-form-group
                    id="input-group-1"
                    :label="this.trans.get('__JSON__.Subscription Start Date')"
                    label-for="input-1"
                  >
                  <date-picker v-model="$v.typeform.subscription_date.$model" value-type="format" :class="{ 'is-invalid':  $v.typeform.subscription_date.$error  }"></date-picker>
                    <div v-if="$v.typeform.subscription_date.$error" class="invalid-feedback">
                      <span
                        v-if="!$v.typeform.subscription_date.required"
                      >{{ trans.get('__JSON__.Please enter subscription date') }}</span>
                    </div>
                  </b-form-group>
                </div>

                <div class="col-sm-6">
                  <b-form-group
                    id="input-group-1"
                    :label="this.trans.get('__JSON__.Payment Link')"
                    label-for="input-1"
                  >
                    <b-form-input
                      id="input-1"
                      name="payment_link"
                      v-model="$v.typeform.payment_link.$model"
                      type="text"
                      :placeholder="this.trans.get('__JSON__.Enter payment link')"
                      :class="{ 'is-invalid':  $v.typeform.payment_link.$error }"
                    ></b-form-input>
                    <div v-if="$v.typeform.payment_link.$error" class="invalid-feedback">
                      <span
                        v-if="!$v.typeform.payment_link.required"
                      >{{ trans.get('__JSON__.Please enter payment link') }}</span>
                    </div>
                  </b-form-group>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="control-label">{{ trans.trans('__JSON__.Business Type') }}</label>
                    <select
                      class="custom-select"
                      v-model="$v.typeform.is_single_restaurant.$model"
                      id="is_single_restaurant"
                      name="is_single_restaurant"
                      :class="{ 'is-invalid':  $v.typeform.is_single_restaurant.$error  }"
                      disabled="disabled"
                    >
                      <option value="" selected>{{ trans.get('__JSON__.Select Business Type') }}</option>
                      <option value="Yes">{{ trans.get('__JSON__.Single Vendor') }}</option>
                      <option value="No">{{ trans.get('__JSON__.Multivendor') }}</option>
                    </select>
                    <div v-if=" $v.typeform.is_single_restaurant.$error" class="invalid-feedback">
                      <span
                        v-if="!$v.typeform.is_single_restaurant.required"
                      >{{ trans.trans('__JSON__.please select at least one business type.') }}.</span>
                    </div>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="control-label">{{ trans.trans('__JSON__.Business Category') }}</label>
                    <select
                      class="custom-select"
                      v-model="$v.typeform.business_category.$model"
                      id="business_category"
                      name="business_category"
                      :class="{ 'is-invalid':  $v.typeform.business_category.$error  }"
                      disabled="disabled"
                    >
                      <option value="" selected>{{ trans.get('__JSON__.Select Business Category') }}</option>
                      <option value="Food">{{ trans.get('__JSON__.Food Delivery Business') }}</option>
                      <option value="Grocery">{{ trans.get('__JSON__.Grocery Delivery Business') }}</option>
                      <option value="Both">{{  trans.get('__JSON__.Multi Delivery Business') }}</option>
                    </select>
                    <div v-if=" $v.typeform.business_category.$error" class="invalid-feedback">
                      <span
                        v-if="!$v.typeform.business_category.required"
                      >{{ trans.trans('__JSON__.please select at least one business category.') }}.</span>
                    </div>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="control-label">{{ trans.trans('__JSON__.Subscription Due Date') }}</label>
                    <select
                      class="custom-select"
                      v-model="$v.typeform.subscription_due_date.$model"
                      id="subscription_due_date"
                      name="subscription_due_date"
                      :class="{ 'is-invalid':  $v.typeform.subscription_due_date.$error  }"
                    >
                      <option value="" selected>{{ trans.get('__JSON__.Select Subscription Due Date') }}</option>
                      <option v-bind:value="1">1</option>
                      <option v-bind:value="15">15</option>
                    </select>
                    <div v-if=" $v.typeform.subscription_plan_type.$error" class="invalid-feedback">
                      <span
                        v-if="!$v.typeform.subscription_plan_type.required"
                      >{{ trans.trans('__JSON__.please select at least one type.') }}.</span>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <!-- Market/Ressto -->
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">{{ trans.get('__JSON__.Market') }} / {{ trans.get('__JSON__.Ressto') }}</h4>
              <p class="card-title-desc"></p>
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="control-label">{{ trans.trans('__JSON__.Product You are Interested In') }}</label>
                    <select
                      class="custom-select"
                      v-model="$v.typeform.product_type_id.$model"
                      id="product_type_id"
                      name="product_type_id"
                      :class="{ 'is-invalid':  $v.typeform.product_type_id.$error  }"
                      @change="onProductChange($event)"
                    >
                      <option value="" selected>{{ trans.get('__JSON__.Select Product You are Interested In') }}</option>
                      <option v-for="product in product_type_list" :value="product.id" :key="product.id">{{product.name}} {{product.extratext}}</option>
                    </select>
                    <div v-if=" $v.typeform.product_type_id.$error" class="invalid-feedback">
                      <span
                        v-if="!$v.typeform.product_type_id.required"
                      >{{ trans.trans('__JSON__.Please select at least one product.') }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="control-label">{{ trans.trans('__JSON__.Business Type') }}</label>
                    <select
                      class="custom-select"
                      v-model="$v.typeform.business_type_id.$model"
                      id="business_type_id"
                      name="business_type_id"
                      :class="{ 'is-invalid':  $v.typeform.business_type_id.$error  }"
                    >
                      <option value="" selected>{{ trans.get('__JSON__.Select Business Type') }}</option>
                      <template v-for="business in business_type_list">
                        <template v-if="typeform.product_type_id == 1">
                          <option :value="business.id" :key="business.id">{{business.name}} {{(typeform.product_type_id == 1) ? business.extratext : ''}}</option>
                        </template>
                        <template v-if="typeform.product_type_id == 2">
                          <option v-if="business.id != 3" :value="business.id" :key="business.id">{{business.name}} {{(typeform.product_type_id == 1) ? business.extratext : ''}}</option>
                        </template>
                      </template>
                    </select>
                    <div v-if=" $v.typeform.business_type_id.$error" class="invalid-feedback">
                      <span
                        v-if="!$v.typeform.business_type_id.required"
                      >{{ trans.trans('__JSON__.Please select at least one business type') }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-sm-3">
                  <b-form-group 
                    label="Store selection"
                    label-for="input-2"
                  >
                    <label class="switch">
                      <input
                        type="checkbox"
                        id="store_selection"
                        name="store_selection"
                        v-model="typeform.store_selection"
                        class="switch-on"
                        true-value="1"
                        false-value="0"
                      />
                      <div class="slider round">
                        <span class="on">{{ trans.get('__JSON__.Yes') }}</span>
                        <span class="off">{{ trans.get('__JSON__.No') }}</span>
                      </div>
                    </label>
                  </b-form-group>
                </div>
              </div>
            </div>
          </div>
          
        <!-- Plan Details End -->

          <!-- Features Start -->
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">
                {{ trans.get("__JSON__.Features") }}
              </h4>
              <p class="card-title-desc"></p>
              <div class="row">
                <div class="col-sm-3 vendor-status">
                  <b-form-group
                      id="input-group-1"
                      :label="trans.get('__JSON__.Wallet')"
                      label-for="wallet"
                    >
                    <label class="switch">
                        <input
                          type="checkbox"
                          id="togBtn"
                          name="wallet"
                          v-model="typeform.wallet_status"
                          class="switch-on"
                          true-value="active"
                          false-value="inactive"
                        />
                        <div class="slider round">
                          <span class="on">{{ trans.get('__JSON__.Active') }}</span>
                          <span class="off">{{ trans.get('__JSON__.Inactive') }}</span>
                        </div>
                      </label>
                  </b-form-group>
                </div>

                <div class="col-sm-3 vendor-status">
                  <b-form-group
                      id="input-group-1"
                      :label="trans.get('__JSON__.Call Center Dashboard')"
                      label-for="wallet"
                    >
                    <label class="switch">
                        <input
                          type="checkbox"
                          id="togBtn"
                          name="wallet"
                          v-model="typeform.call_center_status"
                          class="switch-on"
                          true-value="active"
                          false-value="inactive"
                        />
                        <div class="slider round">
                          <span class="on">{{ trans.get('__JSON__.Active') }}</span>
                          <span class="off">{{ trans.get('__JSON__.Inactive') }}</span>
                        </div>
                      </label>
                  </b-form-group>
                </div>
                

                <div class="col-sm-3 vendor-status">
                  <b-form-group
                      id="input-group-1"
                      :label="trans.get('__JSON__.Web Ordering')"
                      label-for="wallet"
                    >
                    <label class="switch">
                        <input
                          type="checkbox"
                          id="togBtn"
                          name="wallet"
                          v-model="typeform.web_ordering_status"
                          class="switch-on"
                          true-value="active"
                          false-value="inactive"
                        />
                        <div class="slider round">
                          <span class="on">{{ trans.get('__JSON__.Active') }}</span>
                          <span class="off">{{ trans.get('__JSON__.Inactive') }}</span>
                        </div>
                      </label>
                  </b-form-group>
                </div>

                <div class="col-sm-3 vendor-status">
                  <b-form-group
                      id="input-group-1"
                      :label="trans.get('__JSON__.Upload Image with Order')"
                      label-for="allow_order_image_status"
                    >
                    <label class="switch">
                        <input
                          type="checkbox"
                          id="allow_order_image_status"
                          name="allow_order_image_status"
                          v-model="typeform.allow_order_image_status"
                          class="switch-on"
                          true-value="active"
                          false-value="inactive"
                        />
                        <div class="slider round">
                          <span class="on">{{ trans.get('__JSON__.Active') }}</span>
                          <span class="off">{{ trans.get('__JSON__.Inactive') }}</span>
                        </div>
                      </label>
                  </b-form-group>
                </div>

              </div>
            </div>
          </div>

          <!-- Features End -->

          <div class="row">
            <div class="col-sm-6">
              <button
                class="btn btn-primary mr-1 mt-3"
              >{{ trans.get('__JSON__.Update Client') }}</button>
              <b-button @click="$router.back()" type="button" variant="secondary" class="mt-3">{{ trans.get('__JSON__.Cancel') }}</b-button>
            </div>
          </div>
        </b-form>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
