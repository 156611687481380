<script>
import vue2Dropzone from 'vue2-dropzone'
import Multiselect from 'vue-multiselect'
import { settingService, businessTypeService } from "../../../services";
import { required } from "vuelidate/lib/validators";
import { numeric } from "vuelidate/lib/validators";
import { error_message, success_message } from '../../../Helper/helper';
import DropzonePopup from "../../../components/widgets/dropzone-popup";
import { routes } from '../../../config/api_routes';
import config from '../../../config';


/**
 * Starter component
 */
export default {
    components: { vueDropzone: vue2Dropzone, Multiselect, DropzonePopup },
    data() {
        return {
            config: config,
            checked: true,
            pem_file_path:"",
            owner_pem_delete_url: routes.removeIOSPemApi,
            owner_pem:"",
            customer_pem:"",
            driver_pem:"",
            setting:{
                customer_pem :'',
                owner_pem:'',
                driver_pem:'',
            },
        };
    },
    validations: {
        setting: {
            // customer_pem: {
            //     required
            // },
            // owner_pem: {
            //     required
            // },
            // driver_pem: {
            //     required
            // },
            notification_server_key:{
                required
            },
        }
    },
    mounted() {
        this.getSetting();
        this.getBusinessTypeById();
    },
    methods: {
         getBusinessTypeById() {
            businessTypeService
                .getBusinessTypeById({ id: this.$route.params.id })
                .then((response) => {
                const { id, name, status } = response.data.result;
                this.id = response.data.result.id;
                this.setting = response.data.result.settings;
                console.log(this.setting);
                this.typeform = {
                    id,
                    name,
                    status,
                };
                });
        },
        onOwnerPemdeleted(){
            this.setting.owner_pem = "";
        },
        onCustomerPemdeleted(){
            this.setting.customer_pem = "";
        },
        onDriverPemdeleted(){
            this.setting.driver_pem = "";
        },
        getSetting(){
            settingService.getSetting().then(response => {
                this.setting = response.data.result;
            });
        },
        pushNotificationInIt(){
            this.$v.$touch();
            if (this.$v.setting.$invalid) {
                // console.log(this.$v.setting.$invalid);
                return;
            } else {
                // console.log(this.$v.setting.$invalid);
                const fd = new FormData();
                if(!!this.setting.customer_pem){
                    fd.append("customer_pem", this.setting.customer_pem);
                }
                if(!!this.setting.owner_pem){
                    fd.append("owner_pem", this.setting.owner_pem);
                }
                if(!!this.setting.driver_pem){
                    fd.append("driver_pem", this.setting.driver_pem);
                }
                fd.append("notification_server_key",this.setting.notification_server_key);
                //fd.append("customer_pem", this.setting.customer_pem);
                //fd.append("owner_pem", this.setting.owner_pem);
                //fd.append("driver_pem",this.setting.driver_pem);
                fd.append("type",4);
                settingService.updateSetting(fd).then((response) => {
                    if (response.data.code === 200) {
                        if(response.data.result){
                            this.$auth.updateSetting(response.data.result.settings)
                        }
                        success_message(response.data.message);
                    } else {
                        error_message(response.data.message);
                    }
                });
            }
        }
    }
};
</script>

<template>
    <b-form @submit.prevent="pushNotificationInIt">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body setting-main">
                        <div class="row section-main">
                            <div class="col-md-4">
                                <div class="setting-heading">  
                                    <h4>Push Notifications</h4>
                                </div>
                            </div>
                            <div class="col-md-8">
                                <div class="setting-data">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <h6>Pem Files (iOS Application)</h6>  
                                        </div>
                                        <div class="col-md-4 mt-3 customer-app">
                                            <p>Customer Application</p>
                                            <dropzone-popup
                                                :readonly="!$auth.hasPermission(config.permissions.terminology_write)"
                                                v-model="setting.customer_pem"
                                                id="customer_pem"
                                                acceptedFiles=".pem"
                                                :media-path="setting.pem_url"
                                                :delete-path="owner_pem_delete_url"
                                                @image-deleted="this.onCustomerPemdeleted"
                                            />
                                        </div>
                                        <div class="col-md-4 mt-3 customer-app">
                                            <p>Merchant Application</p>
                                            <dropzone-popup
                                                :readonly="!$auth.hasPermission(config.permissions.terminology_write)"
                                                v-model="setting.owner_pem"
                                                id="owner_pem"
                                                acceptedFiles=".pem"
                                                :media-path="setting.pem_url"
                                                :delete-path="owner_pem_delete_url"
                                                @image-deleted="this.onOwnerPemdeleted"
                                            />
                                        </div>
                                        <div class="col-md-4 mt-3 customer-app">
                                            <p>Driver Application</p>
                                            <dropzone-popup
                                                :readonly="!$auth.hasPermission(config.permissions.terminology_write)"
                                                v-model="setting.driver_pem"
                                                id="driver_pem"
                                                acceptedFiles=".pem"
                                                :media-path="setting.pem_url"
                                                :delete-path="owner_pem_delete_url"
                                                @image-deleted="this.onDriverPemdeleted"
                                            />
                                        </div>
                                    </div>
                                    <div class="row mt-4">
                                        <div class="col-md-6">
                                            <b-form-group id="input-group-1" :label="trans.get('__JSON__.Notification Server Key')" label-for="name">
                                                <b-form-input
                                                    id="notification_server_key"
                                                    name="notification_server_key"
                                                    v-model="$v.setting.notification_server_key.$model"
                                                    type="text"
                                                    :placeholder="trans.get('__JSON__.Notification Server Key')"
                                                    :class="{ 'is-invalid':  $v.setting.notification_server_key.$error }"
                                                ></b-form-input>
                                                <div v-if=" $v.setting.notification_server_key.$error" class="invalid-feedback">
                                                    <span v-if="!$v.setting.notification_server_key.required"
                                                    >{{ trans.get('__JSON__.Notification Server Key') }}.</span>
                                                </div>
                                            </b-form-group>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="btn-update">
                            <div class="col-sm-6">
                                <button type="submit" class="btn btn-primary mr-1 mt-3">Update</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </b-form>
</template>