<script>
import { required, requiredIf } from "vuelidate/lib/validators";
import { settingService } from "../../../services";
import config from "../../../config";
import { success_message, error_message } from "../../../Helper/helper";
import Multiselect from "vue-multiselect";

/**
 * Starter component
 */
export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      config: config,
      payment_gateway_list:[],
      setting: [],
      setting: {
        vendor_gateway_list : null,
        payment_gateway : '',
        merchant_id: '',
        access_code: '',
        working_key: '',
        payumoney_header: '',
        payumoney_salt: '',
        payumoney_key: '',
        key_id: '',
        key_secret: '',
        stripe_publishable_key: '',
        stripe_secret_key: '',
        publishable_key : '',
        secret_key: '',
        wompi_client_id: '',
        wompi_client_secret: '',
        paytab_merchant_email: '',
        paytab_client_secret: '',
        sslcommerz_store_id: '',
        sslcommerz_store_password: '',
        paytm_merchant_id: '',
        paytm_merchant_key: '',
        xente_api_key: '',
        xente_password: '',
        kina_terminal: '',
        kina_merchant_id: '',
        kina_merchant_name: '',
        kina_secret_key: '',
        paypal_client_key: '',
        paypal_secret_key: '',
        paypal_mode: false,
        cashfree_client_secret: '',
        cashfree_client_id: '',
        peach_entity_id: '',
        peach_authorization_id: '',
        pesapal_client_key: '',
        pesapal_secret_key: '',
        omise_public_key: '',
        omise_secret_key: '',
        yoco_public_key: '',
        yoco_secret_key: ''
      }
    };
  },
  mounted() {
    this.getPaymentGatewayList();
    this.getAppSetting();
  },
  methods: {
    getPaymentGatewayList() {
      settingService.getPaymentGatewayList().then((response) => {
        this.payment_gateway_list = response.data.result;
      });
    },
    getAppSetting() {
      settingService.getAppSettingApi().then((response) => {
        this.setting = response.data.result.settings;
      });
    },
    generalIt(){
      // this.$v.$touch();
      // if (this.$v.setting.$invalid) {
      //   return;
      // } else {

      const fd = new FormData();
      fd.append("vendor_gateway_list",JSON.stringify(this.setting.vendor_gateway_list));

      this.setting.vendor_gateway_list.forEach((el) => {
        if (el.gateway_name == 'ccavenue') {
          fd.append("merchant_id", this.setting.merchant_id);
          fd.append("access_code", this.setting.access_code);
          fd.append("working_key", this.setting.working_key);
        }
        if (el.gateway_name == 'payumoney') {
          fd.append("payumoney_header", this.setting.payumoney_header);
          fd.append("payumoney_salt", this.setting.payumoney_salt);
          fd.append("payumoney_key", this.setting.payumoney_key);
        }
        if (el.gateway_name == 'razorpay') {
          fd.append("merchant_id", this.setting.merchant_id);
          fd.append("key_id", this.setting.key_id);
          fd.append("key_secret", this.setting.key_secret);
        }
        if (el.gateway_name == 'stripe') {
          fd.append("stripe_publishable_key", this.setting.stripe_publishable_key);
          fd.append("stripe_secret_key", this.setting.stripe_secret_key);
        }
        if (el.gateway_name == 'stripe') {
          fd.append("publishable_key", this.setting.publishable_key);
          fd.append("secret_key", this.setting.secret_key);
        }
        if (el.gateway_name == 'wompi') {
          fd.append("wompi_client_id", this.setting.wompi_client_id);
          fd.append("wompi_client_secret", this.setting.wompi_client_secret);
        }
        if (el.gateway_name == 'paytab') {
          fd.append("paytab_merchant_email", this.setting.paytab_merchant_email);
          fd.append("paytab_client_secret", this.setting.paytab_client_secret);
        }
        if (el.gateway_name == 'sslcommerz') {
          fd.append("sslcommerz_store_id", this.setting.sslcommerz_store_id);
          fd.append("sslcommerz_store_password", this.setting.sslcommerz_store_password);
        }
        if (el.gateway_name == 'paytm') {
          fd.append("paytm_merchant_id", this.setting.paytm_merchant_id);
          fd.append("paytm_merchant_key", this.setting.paytm_merchant_key);
        }
        if (el.gateway_name == 'nmi') {
          fd.append("nmi_security_key", this.setting.nmi_security_key);
        }
        if (el.gateway_name == 'xente') {
          fd.append("xente_api_key", this.setting.xente_api_key);
          fd.append("xente_password", this.setting.xente_password);
        }
        if (el.gateway_name == 'kina') {
          fd.append("kina_terminal", this.setting.kina_terminal);
          fd.append("kina_merchant_id", this.setting.kina_merchant_id);
          fd.append("kina_merchant_name", this.setting.kina_merchant_name);
          fd.append("kina_secret_key", this.setting.kina_secret_key);
        }
        if (el.gateway_name == 'paypal') {
          fd.append("paypal_client_key", this.setting.paypal_client_key);
          fd.append("paypal_secret_key", this.setting.paypal_secret_key);
          fd.append("paypal_mode", this.setting.paypal_mode);
        }
        if (el.gateway_name == 'cashfree') {
          fd.append("cashfree_client_secret", this.setting.cashfree_client_secret);
          fd.append("cashfree_client_id", this.setting.cashfree_client_id);
        }
        if (el.gateway_name == 'peach') {
          fd.append("peach_entity_id", this.setting.peach_entity_id);
          fd.append("peach_authorization_id", this.setting.peach_authorization_id);
        }
        if (el.gateway_name == 'pesapal') {
          fd.append("pesapal_client_key", this.setting.pesapal_client_key);
          fd.append("pesapal_secret_key", this.setting.pesapal_secret_key);
        }
        if (el.gateway_name == 'omise') {
          fd.append("omise_public_key", this.setting.omise_public_key);
          fd.append("omise_secret_key", this.setting.omise_secret_key);
        }
        if (el.gateway_name == 'yoco') {
          fd.append("yoco_public_key", this.setting.yoco_public_key);
          fd.append("yoco_secret_key", this.setting.yoco_secret_key);
        }
      });
      settingService.updatepaymentgateway(fd).then((response) => {
        if (response.data.code === 200) {
          success_message(response.data.message);
        } else {
          error_message(response.data.message);
        }
      });
    }
  }
};
</script>
<template>
  <b-form @submit.prevent="generalIt">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label class="control-label">{{ trans.get('__JSON__.Payment Method') }}</label>
                  <multiselect 
                    label="gateway_name"
                    track-by="gateway_name"
                    placeholder="Select Payment Method"
                    v-model="setting.vendor_gateway_list" 
                    :options="payment_gateway_list" 
                    :multiple="true"
                    :searchable="true"
                    :internal-search="true"
                    :clear-on-select="true"
                    :close-on-select="false"
                    :max-height="600"
                    :show-no-results="false"
                    :hide-selected="true"
                  >
                    <!-- <template slot="tag" slot-scope="{ option, remove }">
                      <span class="custom__tag">
                      <span>{{ option.gateway_name }}</span>
                      <span class="custom__remove" @click="remove(option)">❌</span>
                      </span>
                    </template> -->
                  </multiselect>
                </div>
              </div>
            </div>

            <div v-for="gateway in setting.vendor_gateway_list" :key="gateway.id">
              <div v-if="gateway.gateway_name == 'ccavenue'">
                <div class="row">
                  <div class="col-sm-12">
                    <h5 style="text-transform: capitalize;">{{gateway.gateway_name}} :-</h5>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <b-form-group 
                      id="input-group-1"
                      :label="trans.get('__JSON__.Merchant ID')"
                      label-for="name"
                    >
                      <b-form-input
                        id="merchant_id"
                        name="merchant_id"
                        v-model="setting.merchant_id"
                        type="text"
                        :placeholder="trans.get('__JSON__.Merchant ID')"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-sm-4">
                    <b-form-group
                      id="input-group-1"
                      :label="trans.get('__JSON__.Access Code')"
                      label-for="name"
                    >
                      <b-form-input
                        id="access_code"
                        name="access_code"
                        v-model="setting.access_code"
                        type="text"
                        :placeholder="trans.get('__JSON__.Access Code')"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-sm-4">
                    <b-form-group
                      id="input-group-1"
                      :label="trans.get('__JSON__.Working Key')"
                      label-for="name"
                    >
                      <b-form-input
                        id="working_key"
                        name="working_key"
                        v-model="setting.working_key"
                        type="text"
                        :placeholder="trans.get('__JSON__.Working Key')"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </div>
              </div>
              <div v-if="gateway.gateway_name == 'payumoney'">
                <div class="row">
                  <div class="col-sm-12">
                    <h5 style="text-transform: capitalize;">{{gateway.gateway_name}} :-</h5>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <b-form-group
                      id="input-group-1"
                      :label="trans.get('__JSON__.PayUMoney Header')"
                      label-for="name"
                    >
                      <b-form-input
                        id="payumoney_header"
                        name="payumoney_header"
                        v-model="setting.payumoney_header"
                        type="text"
                        :placeholder="trans.get('__JSON__.PayUMoney Header')"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-sm-4">
                    <b-form-group
                      id="input-group-1"
                      :label="trans.get('__JSON__.PayUMoney Salt')"
                      label-for="name"
                    >
                      <b-form-input
                        id="payumoney_salt"
                        name="payumoney_salt"
                        v-model="setting.payumoney_salt"
                        type="text"
                        :placeholder="trans.get('__JSON__.PayUMoney Salt')"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-sm-4">
                    <b-form-group
                      id="input-group-1"
                      :label="trans.get('__JSON__.PayUMoney Key')"
                      label-for="name"
                    >
                      <b-form-input
                        id="payumoney_key"
                        name="payumoney_key"
                        v-model="setting.payumoney_key"
                        type="text"
                        :placeholder="trans.get('__JSON__.PayUMoney Key')"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </div>
              </div>
              <div v-if="gateway.gateway_name == 'razorpay'">
                <div class="row">
                  <div class="col-sm-12">
                    <h5 style="text-transform: capitalize;">{{gateway.gateway_name}} :-</h5>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <b-form-group
                      id="input-group-1"
                      :label="trans.get('__JSON__.Merchant ID')"
                      label-for="name"
                    >
                     <b-form-input
                        id="merchant_id"
                        name="merchant_id"
                        v-model="setting.merchant_id"
                        type="text"
                        :placeholder="trans.get('__JSON__.Merchant ID')"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-sm-4">
                    <b-form-group
                      id="input-group-1"
                      :label="trans.get('__JSON__.Key Id')"
                      label-for="name"
                    >
                      <b-form-input
                        id="key_id"
                        name="key_id"
                        v-model="setting.key_id"
                        type="text"
                        :placeholder="trans.get('__JSON__.Key Id')"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-sm-4">
                    <b-form-group
                      id="input-group-1"
                      :label="trans.get('__JSON__.Key Secret')"
                      label-for="name"
                    >
                      <b-form-input
                        id="key_secret"
                        name="key_secret"
                        v-model="setting.key_secret"
                        type="text"
                        :placeholder="trans.get('__JSON__.Key Secret')"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </div>
              </div>
              <div v-if="gateway.gateway_name == 'stripe'">
                <div class="row">
                  <div class="col-sm-12">
                    <h5 style="text-transform: capitalize;">{{gateway.gateway_name}} :-</h5>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <b-form-group
                      id="input-group-1"
                      :label="trans.get('__JSON__.Publishable key')"
                      label-for="name"
                    >
                      <b-form-input
                        id="stripe_publishable_key"
                        name="stripe_publishable_key"
                        v-model="setting.stripe_publishable_key"
                        type="text"
                        :placeholder="trans.get('__JSON__.Publishable key')"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-sm-4">
                    <b-form-group
                      id="input-group-1"
                      :label="trans.get('__JSON__.Secret Key')"
                      label-for="name"
                    >
                      <b-form-input
                        id="stripe_secret_key"
                        name="stripe_secret_key"
                        v-model="setting.stripe_secret_key"
                        type="text"
                        :placeholder="trans.get('__JSON__.Secret Key')"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </div>
              </div>
              <div v-if="gateway.gateway_name == 'omise'">
                <div class="row">
                  <div class="col-sm-12">
                    <h5 style="text-transform: capitalize;">{{gateway.gateway_name}} :-</h5>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <b-form-group
                      id="input-group-1"
                      :label="trans.get('__JSON__.Publishable key')"
                      label-for="name"
                    >
                      <b-form-input
                        id="omise_public_key"
                        name="omise_public_key"
                        v-model="setting.omise_public_key"
                        type="text"
                        :placeholder="trans.get('__JSON__.Publishable key')"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-sm-4">
                    <b-form-group
                      id="input-group-1"
                      :label="trans.get('__JSON__.Secret Key')"
                      label-for="name"
                    >
                      <b-form-input
                        id="omise_secret_key"
                        name="omise_secret_key"
                        v-model="setting.omise_secret_key"
                        type="text"
                        :placeholder="trans.get('__JSON__.Secret Key')"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </div>
              </div>
              <div v-if="gateway.gateway_name == 'wompi'">
                <div class="row">
                  <div class="col-sm-12">
                    <h5 style="text-transform: capitalize;">{{gateway.gateway_name}} :-</h5>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <b-form-group
                      id="input-group-1"
                      :label="trans.get('__JSON__.Client ID')"
                      label-for="name"
                    >
                      <b-form-input
                        id="wompi_client_id"
                        name="wompi_client_id"
                        v-model="setting.wompi_client_id"
                        type="text"
                        :placeholder="trans.get('__JSON__.Client ID')"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-sm-4">
                    <b-form-group
                      id="input-group-1"
                      :label="trans.get('__JSON__.Client Secret')"
                      label-for="name"
                    >
                      <b-form-input
                        id="wompi_client_secret"
                        name="wompi_client_secret"
                        v-model="setting.wompi_client_secret"
                        type="text"
                        :placeholder="trans.get('__JSON__.Client Secret')"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </div>
              </div>
              <div v-if="gateway.gateway_name == 'paytab'">
                <div class="row">
                  <div class="col-sm-12">
                    <h5 style="text-transform: capitalize;">{{gateway.gateway_name}} :-</h5>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <b-form-group
                      id="input-group-1"
                      :label="trans.get('__JSON__.Paytab Merchant Email')"
                      label-for="name"
                    >
                      <b-form-input
                        id="paytab_merchant_email"
                        name="paytab_merchant_email"
                        v-model="setting.paytab_merchant_email"
                        type="text"
                        :placeholder="trans.get('__JSON__.Paytab Merchant Email')"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-sm-4">
                    <b-form-group
                      id="input-group-1"
                      :label="trans.get('__JSON__.Paytab Secret Key')"
                      label-for="name"
                    >
                      <b-form-input
                        id="paytab_client_secret"
                        name="paytab_client_secret"
                        v-model="setting.paytab_client_secret"
                        type="text"
                        :placeholder="trans.get('__JSON__.Paytab Secret Key')"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </div>
              </div>
              <div v-if="gateway.gateway_name == 'sslcommerz'">
                <div class="row">
                  <div class="col-sm-12">
                    <h5 style="text-transform: capitalize;">{{gateway.gateway_name}} :-</h5>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <b-form-group
                      id="input-group-1"
                      :label="trans.get('__JSON__.SSLCommerz Store Id')"
                      label-for="name"
                    >
                      <b-form-input
                        id="sslcommerz_store_id"
                        name="sslcommerz_store_id"
                        v-model="setting.sslcommerz_store_id"
                        type="text"
                        :placeholder="trans.get('__JSON__.SSLCommerz Store Id')"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-sm-4">
                    <b-form-group
                      id="input-group-1"
                      :label="trans.get('__JSON__.SSLCommerz Store Password')"
                      label-for="name"
                    >
                      <b-form-input
                        id="sslcommerz_store_password"
                        name="sslcommerz_store_password"
                        v-model="setting.sslcommerz_store_password"
                        type="text"
                        :placeholder="trans.get('__JSON__.SSLCommerz Store Password')"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </div>
              </div>
              <div v-if="gateway.gateway_name == 'paytm'">
                <div class="row">
                  <div class="col-sm-12">
                    <h5 style="text-transform: capitalize;">{{gateway.gateway_name}} :-</h5>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <b-form-group
                      id="input-group-1"
                      :label="trans.get('__JSON__.Merchant ID')"
                      label-for="name"
                    >
                     <b-form-input
                        id="paytm_merchant_id"
                        name="paytm_merchant_id"
                        v-model="setting.paytm_merchant_id"
                        type="text"
                        :placeholder="trans.get('__JSON__.Merchant ID')"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-sm-4">
                    <b-form-group
                      id="input-group-1"
                      :label="trans.get('__JSON__.Key Id')"
                      label-for="name"
                    >
                      <b-form-input
                        id="paytm_merchant_key"
                        name="paytm_merchant_key"
                        v-model="setting.paytm_merchant_key"
                        type="text"
                        :placeholder="trans.get('__JSON__.Key Id')"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </div>
              </div>
              <div v-if="gateway.gateway_name == 'xente'">
                <div class="row">
                  <div class="col-sm-12">
                    <h5 style="text-transform: capitalize;">{{gateway.gateway_name}} :-</h5>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <b-form-group
                      id="input-group-1"
                      :label="trans.get('__JSON__.API Key')"
                      label-for="name"
                    >
                     <b-form-input
                        id="xente_api_key"
                        name="xente_api_key"
                        v-model="setting.xente_api_key"
                        type="text"
                        :placeholder="trans.get('__JSON__.API Key')"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-sm-4">
                    <b-form-group
                      id="input-group-1"
                      :label="trans.get('__JSON__.Password')"
                      label-for="name"
                    >
                     <b-form-input
                        id="xente_password"
                        name="xente_password"
                        v-model="setting.xente_password"
                        type="text"
                        :placeholder="trans.get('__JSON__.Password')"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </div>
              </div>
              <div v-if="gateway.gateway_name == 'kina'">
                <div class="row">
                  <div class="col-sm-12">
                    <h5 style="text-transform: capitalize;">{{gateway.gateway_name}} :-</h5>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <b-form-group
                      id="input-group-1"
                      :label="trans.get('__JSON__.Terminal Number')"
                      label-for="name"
                    >
                     <b-form-input
                        id="kina_terminal"
                        name="kina_terminal"
                        v-model="setting.kina_terminal"
                        type="text"
                        :placeholder="trans.get('__JSON__.Terminal Number')"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-sm-4">
                    <b-form-group
                      id="input-group-1"
                      :label="trans.get('__JSON__.Merchant ID')"
                      label-for="name"
                    >
                     <b-form-input
                        id="kina_merchant_id"
                        name="kina_merchant_id"
                        v-model="setting.kina_merchant_id"
                        type="text"
                        :placeholder="trans.get('__JSON__.Merchant ID')"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <b-form-group
                      id="input-group-1"
                      :label="trans.get('__JSON__.Merchant Name')"
                      label-for="name"
                    >
                     <b-form-input
                        id="kina_merchant_name"
                        name="kina_merchant_name"
                        v-model="setting.kina_merchant_name"
                        type="text"
                        :placeholder="trans.get('__JSON__.Merchant Name')"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-sm-4">
                    <b-form-group
                      id="input-group-1"
                      :label="trans.get('__JSON__.Key Secret')"
                      label-for="name"
                    >
                     <b-form-input
                        id="kina_secret_key"
                        name="kina_secret_key"
                        v-model="setting.kina_secret_key"
                        type="text"
                        :placeholder="trans.get('__JSON__.Key Secret')"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </div>
              </div>
              <div v-if="gateway.gateway_name == 'paypal'">
                <div class="row">
                  <div class="col-sm-12">
                    <h5 style="text-transform: capitalize;">{{gateway.gateway_name}} :-</h5>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <b-form-group
                      id="input-group-1"
                      :label="trans.get('__JSON__.Client ID')"
                      label-for="name"
                    >
                     <b-form-input
                        id="paypal_client_key"
                        name="paypal_client_key"
                        v-model="setting.paypal_client_key"
                        type="text"
                        :placeholder="trans.get('__JSON__.Client ID')"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-sm-4">
                    <b-form-group
                      id="input-group-1"
                      :label="trans.get('__JSON__.Client Secret')"
                      label-for="name"
                    >
                     <b-form-input
                        id="paypal_secret_key"
                        name="paypal_secret_key"
                        v-model="setting.paypal_secret_key"
                        type="text"
                        :placeholder="trans.get('__JSON__.Client Secret')"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <!--
                  To change payment mode
                  -->
                  <!-- <div class="col-sm-4">
                    <h6>{{trans.get('__JSON__.Is Production Mode')}}</h6>
                    <b-form-group>
                      <label class="switch">
                        <input
                          type="checkbox"
                          id="paypal_mode"
                          name="paypal_mode"
                          v-model="setting.paypal_mode"
                          class="switch-on"
                          true-value="true"
                          false-value="false"
                        />
                        <div class="slider round">
                          <span class="on">{{ trans.get('__JSON__.On') }}</span>
                          <span class="off">{{ trans.get('__JSON__.Off') }}</span>
                        </div>
                      </label>
                    </b-form-group>
                  </div> -->
                </div>
              </div>
              <div v-if="gateway.gateway_name == 'cashfree'">
                <div class="row">
                  <div class="col-sm-12">
                    <h5 style="text-transform: capitalize;">{{gateway.gateway_name}} :-</h5>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <b-form-group
                      id="input-group-1"
                      :label="trans.get('__JSON__.Client ID')"
                      label-for="name"
                    >
                     <b-form-input
                        id="cashfree_client_id"
                        name="cashfree_client_id"
                        v-model="setting.cashfree_client_id"
                        type="text"
                        :placeholder="trans.get('__JSON__.Client ID')"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-sm-4">
                    <b-form-group
                      id="input-group-1"
                      :label="trans.get('__JSON__.Client Secret')"
                      label-for="name"
                    >
                     <b-form-input
                        id="cashfree_client_secret"
                        name="cashfree_client_secret"
                        v-model="setting.cashfree_client_secret"
                        type="text"
                        :placeholder="trans.get('__JSON__.Client Secret')"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </div>
              </div>
              <div v-if="gateway.gateway_name == 'peach'">
                <div class="row">
                  <div class="col-sm-12">
                    <h5 style="text-transform: capitalize;">{{gateway.gateway_name}} :-</h5>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <b-form-group
                      id="input-group-1"
                      :label="trans.get('__JSON__.Entity Id')"
                      label-for="name"
                    >
                     <b-form-input
                        id="peach_entity_id"
                        name="peach_entity_id"
                        v-model="setting.peach_entity_id"
                        type="text"
                        :placeholder="trans.get('__JSON__.Entity Id')"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-sm-4">
                    <b-form-group
                      id="input-group-1"
                      :label="trans.get('__JSON__.Authorization Id')"
                      label-for="name"
                    >
                     <b-form-input
                        id="peach_authorization_id"
                        name="peach_authorization_id"
                        v-model="setting.peach_authorization_id"
                        type="text"
                        :placeholder="trans.get('__JSON__.Authorization Id')"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </div>
              </div>
              <div v-if="gateway.gateway_name == 'pesapal'">
                <div class="row">
                  <div class="col-sm-12">
                    <h5 style="text-transform: capitalize;">{{gateway.gateway_name}} :-</h5>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <b-form-group
                      id="input-group-1"
                      :label="trans.get('__JSON__.Client ID')"
                      label-for="name"
                    >
                     <b-form-input
                        id="pesapal_client_key"
                        name="pesapal_client_key"
                        v-model="setting.pesapal_client_key"
                        type="text"
                        :placeholder="trans.get('__JSON__.Client ID')"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-sm-4">
                    <b-form-group
                      id="input-group-1"
                      :label="trans.get('__JSON__.Client Secret')"
                      label-for="name"
                    >
                     <b-form-input
                        id="pesapal_secret_key"
                        name="pesapal_secret_key"
                        v-model="setting.pesapal_secret_key"
                        type="text"
                        :placeholder="trans.get('__JSON__.Client Secret')"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </div>
              </div>

              <div v-if="gateway.gateway_name == 'yoco'">
                <div class="row">
                  <div class="col-sm-12">
                    <h5 style="text-transform: capitalize;">{{gateway.gateway_name}} :-</h5>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <b-form-group
                      id="input-group-1"
                      :label="trans.get('__JSON__.Publishable key')"
                      label-for="name"
                    >
                      <b-form-input
                        id="yoco_public_key"
                        name="yoco_public_key"
                        v-model="setting.yoco_public_key"
                        type="text"
                        :placeholder="trans.get('__JSON__.Publishable key')"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-sm-4">
                    <b-form-group
                      id="input-group-1"
                      :label="trans.get('__JSON__.Secret Key')"
                      label-for="name"
                    >
                      <b-form-input
                        id="yoco_secret_key"
                        name="yoco_secret_key"
                        v-model="setting.yoco_secret_key"
                        type="text"
                        :placeholder="trans.get('__JSON__.Secret Key')"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </div>
              </div>
            </div>

            <div class="btn-update">
              <div class="col-sm-6">
                <button
                  type="submit"
                  v-if="$auth.hasPermission(config.permissions.configuration_write)" 
                  class="btn btn-primary mr-1 mt-3"
                >{{ trans.get('__JSON__.Update') }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-form>
</template>