<script>

  import Layout from "../../layouts/main";
  import PageHeader from "../../components/page-header";
  import pagination from "laravel-vue-pagination";
  import driverList from './driver-list';
  /**
   * Customers component
   */
  export default {
    components: { Layout, PageHeader, pagination, driverList},
    data() {
      return {};
    }, 
  };

</script>

<template>
  <Layout>
    <driver-list/>
  </Layout>
</template>
