<script>
import config from "../../config";
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import { commonService, vendorService, userService,driverService,settingService} from "../../services";
import { error_message, success_message } from "../../Helper/helper";
import { required, email, sameAs, numeric, requiredIf } from "vuelidate/lib/validators";
import DropzonePopup from "../../components/widgets/dropzone-popup";
import { routes } from "../../config/api_routes";
import Multiselect from "vue-multiselect";

export default {
  components: { vueDropzone: vue2Dropzone,DropzonePopup , Multiselect },
  data() {
        return {
            driver_name:"",
            files: [],
            baseUrl:'',
            multiple: Boolean,
            config: config,
            isLoading: false,
            error: "",
            vehichleName: [],
            is_licence_required: false,
            vehicle_document:"",
            licence_priview_link:"",
            
            
            // new logic
            ProfileImageread:0,
            LicenceDocumentread:0,
            RemoveIdentyLink:[],
            btndisabled:false,
            // new logic

            typeform: {
                firstname:"",
                lastname:"",
                email:"",
                password: "",
                confirm_password: "",
                contactno:"",
                address:"",
                vendor_id:"",
                vehicle_company_name:"Honda",   
                vehicle_type:"",
                vehicle_model:"Activa 5G",
                vehilcle_year:"2020",
                licence_number:"",
                vehicle_number:"",
                profileimage: "",
                document_type:'licence'+','+'document',
                document:"",
                Identity_document: [],
                is_langauge: 'en'
            },
            profileimagepath: "",
            app_name:"",
            website_url: "",
            logo: "",
            default_appname: "",
            frontUrl: "",         
            termscondition: "",  
            termscondition_error: false,
            profile_image: "",
            Identity_document_image:"",
            profile_image_path: "",

            terms_condition_url: "",
            terms_condition_view:false,
            privacypolicy_url:"",
            privacypolicy_view:false,

            isSubmitDisable: false,
            ProfileimageOptions: {
                url: routes.uploadTmpImageApi,
                headers: window.axios.defaults.headers.common,
                thumbnailHeight: 100,
                maxFilesize: 10,
                maxFiles: 1,
                acceptedFiles: ".jpeg,.jpg,.png,.webp,image/webp",
                addRemoveLinks: true,
                dictDefaultMessage : '<i class="bx bx-plus plus"></i>',
                success: (response) => {
                    // this.btndisabled = true;
                    this.typeform.profileimage = response;
                    const data = JSON.parse(response.xhr.response);
                    let remove_link = data.result.base_url+'/'+data.result.file;
                    this.ProfileImageread = 1;
                    setTimeout(()=>{
                        var profilepreview = document.getElementById('profile_image_priview')
                        profilepreview.setAttribute("src",remove_link);
                        this.btndisabled = false;
                    },500);                                       
                }    
            },
            vehicledocumentOptions:{
                url: routes.uploadTmpImageApi,
                maxFiles: 1,
                thumbnailHeight: 100,
                maxFilesize: 10,
                addRemoveLinks: true,
                acceptedFiles: ".jpeg,.jpg,.png",
                dictDefaultMessage : '<i class="bx bx-plus plus"></i>',
                success: (response) => {
                    this.vehicle_document = response;
                    const data = JSON.parse(response.xhr.response);
                    this.licence_priview_link = data.result.base_url+'/'+data.result.file;
                    this.LicenceDocumentread = 1;
                    setTimeout(()=>{
                        this.btndisabled = false;
                    },500);
                }
            },
            Identity_documentOptions:{
                url: routes.uploadTmpImageApi,
                thumbnailHeight: 100,
                maxFilesize: 10,
                addRemoveLinks: true,
                acceptedFiles: ".jpeg,.jpg,.png",
                dictDefaultMessage : '<i class="bx bx-plus plus"></i>',
                success: (response) => {
                    this.typeform.Identity_document.push(response);
                    const data = JSON.parse(response.xhr.response);
                    let remove_link = data.result.base_url+'/'+data.result.file;
                    this.RemoveIdentyLink.push(remove_link);
                    let preview = document.getElementsByClassName("dz-preview");
                    for(var i = 0; i < preview.length; i++){
                        preview[i].style.display = "none";
                         $('#Identity_document_dropzone .dz-default').css('display','block');
                        this.btndisabled = false;
                    }
                },
            }
        }
    },
    validations: {
        typeform: {
            firstname: {
                required,
            },
            lastname: {
                required,
            },
            contactno: {
                required,
                numeric
            },
            email: {
                required,
                email
            },
            address:{
                required,
            },
            password:{  
                required,
            },
            vehicle_number:{
                required:requiredIf(function (nestedModel) {
                    return !(!!this.is_licence_required == false || !!this.typeform.vehicle_number)
                }),
                // numeric
            },
            licence_number:{
                 required:requiredIf(function (nestedModel) {
                    return !(!!this.is_licence_required == false || !!this.typeform.licence_number)
                }),
                // numeric
            },
            vehicle_type:{
                required,
            },
            profileimage: {
                required:requiredIf(function (nestedModel) {
                    return !(!!this.profile_image || !!this.typeform.profileimage)
                })
            },
            confirm_password: {
                required,
                sameAsPassword: sameAs("password"),
            },
            Identity_document: {
                required,
            },
            
        },
        vehicle_document:{
            required:requiredIf(function (nestedModel) {

                return !(!!this.is_licence_required == false || !!this.vehicle_document)
            })
        },

    },    
    mounted(){
        this.getVendorSettingById();
    },     
    methods: {
        base64_decode(id){
            return atob(id);
        },
        IdentybeforeComplete(){
            $('#Identity_document_dropzone .dz-default').css('display','none');
            this.btndisabled = true;
        },
        LicencebeforeComplete(){
            this.btndisabled = true;
        },
        ProfilebeforeComplete(){
            $('#profileimagedropzone .dz-default').css('display','none');
            this.btndisabled = true;
        },
        addDriver(e){
            this.$v.$touch();
            if (this.$v.typeform.$invalid) {
                if (this.termscondition != 'accepted') {
                    this.termscondition_error = true;
                    return;
                }   
            }else{
                if(this.is_licence_required == true && this.vehicle_document === ""){
                    return;
                }else if(this.termscondition != 'accepted'){
                    this.termscondition_error = true;
                    return;
                }
                else{
                    let formData = new FormData();
                    formData.append('firstname',this.typeform.firstname);
                    formData.append('lastname',this.typeform.lastname);
                    formData.append('email',this.typeform.email);
                    formData.append('password',this.typeform.password);
                    formData.append('contactno',this.typeform.contactno);
                    formData.append('address',this.typeform.address);
                    formData.append('vendor_id',this.base64_decode(this.$route.params.vendor_id));              
                    // formData.append('vehicle_company_name',"Honda");
                    formData.append('vehicle_type',this.typeform.vehicle_type);
                    // formData.append('vehicle_model',"Activa 5G");  
                    // formData.append('vehilcle_year',"2020");  
                    formData.append('licence_number',this.typeform.licence_number);  
                    formData.append('vehicle_number',this.typeform.vehicle_number); 
                    formData.append('profileimage',this.typeform.profileimage);
                    formData.append('is_langauge',this.typeform.is_langauge);
                    var type = "";
                    if(this.vehicle_document != ""){
                        for(let i = 0;i<1;i++){
                            formData.append('document['+0+']',this.vehicle_document); 
                        }
                        this.typeform.Identity_document.forEach(function(file,index){
                            formData.append('document['+(index+1)+']',file); 
                            type+="document"+",";
                        });
                        formData.append('document_type',"licence"+','+type);
                    }else{
                        this.typeform.Identity_document.forEach(function(file,index){
                            formData.append('document['+(index)+']',file); 
                            type+="document"+",";
                        });
                        formData.append('document_type',type);
                    }
                    this.btndisabled = true;
                    driverService.signup(formData,
                        {
                            'Content-Type': 'multipart/form-data'
                        }
                    ).then((response) =>{
                        if (response.data.code == 200) {
                            this.btndisabled = false;
                            success_message(response.data.msg);
                            e.target.reset();
                            $('#vehicle_type').val("");
                            this.typeform.firstname = "";
                            this.typeform.lastname = "";
                            this.typeform.email = "";
                            this.typeform.contactno = "";
                            this.typeform.password = "";
                            this.typeform.confirm_password = "";
                            this.typeform.address="";
                            this.typeform.profileimage="";
                            this.typeform.vehicle_number="";
                            this.typeform.licence_number="";
                            this.typeform.vehicle_type = "";
                            this.is_licence_required = false;
                            this.termscondition_error = false;
                            this.termscondition = "";
                            this.ProfileImageread = 0;
                            this.profileimage="";
                            this.LicenceDocumentread = 0;
                            this.vehicle_document = "";
                            this.typeform.Identity_document= [];
                            this.RemoveIdentyLink= [];
                            this.$v.typeform.$reset();
                            this.$v.$reset();
                        }else if(response.data.code == 101){  
                            this.btndisabled = false;
                            error_message(response.data.msg);
                        }
                    });
                }
            }
        },
        getVendorSettingById(){
          settingService.getVendorSettingById({vendor_id : this.$route.params.vendor_id})
          .then(response => {
            this.default_appname = response.data.result.app_name;
            document.querySelector('head title').textContent = response.data.result.app_name;
            this.logo = this.config.foodicon+response.data.result.flat_icon;
            document.querySelector('[type="image/x-icon"]').setAttribute('href', this.logo);
            if (response.data.result.primary_lang) {
                this.typeform.is_langauge = (response.data.result.primary_lang != '') ? response.data.result.primary_lang : '';
            }
            this.vehichleName = response.data.result.vehicle_name;
            this.driver_name = response.data.result.driver_name;
            let page = response.data.result.pages;
            for(let i = 0;i<page.length;i++){
                for(let x in  page[i]){
                    if(x == "privacypolicy_url"){
                        let new_privacypolicy_data = page[i][x].split('/');
                        let privacypolicy_link_url = "";
                        new_privacypolicy_data.forEach((value,index)=>{
                            if(index == 0){
                              privacypolicy_link_url += value+"//";
                            }else{
                                if(value != ""){
                                    privacypolicy_link_url += value+"/";
                                }
                            }
                        });
                        this.privacypolicy_url = privacypolicy_link_url ;
                        this.privacypolicy_view = true;
                    }
                    if(x == "terms_url"){
                        let terms_url_data = page[i][x].split('/');
                        let terms_url = "";
                        terms_url_data.forEach((value,index)=>{
                            if(index == 0){
                              terms_url += value+"//";
                            }else{
                                if(value != ""){
                                    terms_url += value+"/";
                                }
                            }
                        });
                        this.terms_condition_url = terms_url ;
                        this.terms_condition_view = true;
                    }
                }
            }
          });
        },
        termsconditionfun(term) {
            this.termscondition_error = false;
            if (term != 'accepted') {
                this.termscondition_error = true;
            }
        },
        vehicleChange(event){
            let options = event.target.options
            if (options.selectedIndex > -1) {
                var is_licence_required = options[options.selectedIndex].getAttribute('is_licence_required');
                if(is_licence_required == '1')
                {
                    this.is_licence_required = true;
                }
                else
                {
                    this.is_licence_required = false;
                }
            }
        },
        remove_profile(){

            let image_priview_link = document.getElementById('profile_image_priview').getAttribute("src");
            let image_final_link = image_priview_link.split('/');
            let payload = {
                file:image_final_link[image_final_link.length -1]
            };
            commonService.removeTmpImage(payload)
            .then((response) => {
                if(response.data.code == 200) {
                    this.ProfileImageread = 0;
                    this.typeform.profileimage = "";
                    payload.file = "";
                }
            })
        },
        remove_licence_document(){
            let image_priview_link = document.getElementById('licence_image_priview').getAttribute("src");
            console.log(image_priview_link);
            let image_final_link = image_priview_link.split('/');
            let payload = {
                file:image_final_link[image_final_link.length -1]
            };
            commonService.removeTmpImage(payload)
            .then((response) => {
                if(response.data.code == 200) {
                    this.LicenceDocumentread = 0;
                    this.vehicle_document = "";
                    this.licence_priview_link = "";
                    payload.file = "";
                }
            })
        },
        remove_indenty_document(id){
            let remove_link = this.RemoveIdentyLink[id];
            let image_final_link = remove_link.split('/');
            let payload = {
                file:image_final_link[image_final_link.length -1]
            };
            let data = document.getElementsByClassName('indenty_document_inner_'+id)[id];
            commonService.removeTmpImage(payload)
            .then((response) => {
                if(response.data.code == 200) {
                    // this.vehicle_document = "";
                    this.typeform.Identity_document.splice(id,1);
                    this.RemoveIdentyLink.splice(id,1);
                    payload.file = "";
                }
            });
            if(this.typeform.Identity_document.length > 0){
               this.typeform.Identity_document.forEach((value,index)=>{
                    document.getElementsByClassName('indenty_document_inner_'+index)[index].style.display = "block";
               });
            }
            // $('#i_document_inner'+id).hide(); 
        },
        deleteProfileImage(){
            $('#profileimagedropzone .dz-default').css('display','block');
        },
        ProfileFileAdded(){
            $('#profileimagedropzone .dz-default').css('display','none');
        },
        deletelicenceImage(){
            $('#vehicle_document_dropzone .dz-default').css('display','block');
        },
        LicenceFileAdded(){
            $('#vehicle_document_dropzone .dz-default').css('display','none');
        },
        deleteidentyImage(){
            $('#Identity_document_dropzone .dz-default').css('display','block');
        },
        IdentyFileAdded(){
            $('#Identity_document_dropzone .dz-default').css('display','none');
        }
    },
}
</script>
<template>
    <div>
        <div class="account-pages my-5 pt-5">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-8 col-lg-6 col-xl-7 login-main">
                        <div class="card overflow-hidden">
                            <div class="bg-soft-primary">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="text-primary p-4">
                                            <h5 class="text-primary">{{trans.get('__JSON__.Sign up for')}} {{ default_appname }} !</h5>
                                            <p>{{trans.get('__JSON__.Fill all the details to setup account with')}} {{ default_appname }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body pt-0">
                                <div>
                                    <div class="avatar-md profile-user-wid mb-4 avatar-logo-main">
                                        <span class="avatar-title rounded-circle bg-light">
                                        <img :src="logo" alt />
                                        </span>
                                    </div>
                                </div>
                                <div class="row d-none">
                                    <h4 class="card-title pl-3 ml-1">{{ trans.get('__JSON__.Client Info') }}</h4>
                                </div>
                                <b-form class="p-2 mt-4" @submit.prevent="addDriver">
                                    <slot />
                                    <div class="row" :class="{ 'businessbm': website_url != '' && frontUrl != '' }">
                                        <div class="col-md-6">
                                            <b-form-group
                                                id="input-group-1"
                                                :label="this.trans.get('__JSON__.First Name')"
                                                label-for="input-1"
                                                label-class="required"
                                            >
                                                <b-form-input
                                                    id="input-1"
                                                    name="first_name"
                                                    ref="first_name"
                                                    v-model="$v.typeform.firstname.$model"
                                                    type="text"
                                                    autocomplete="off"
                                                    :placeholder="this.trans.get('__JSON__.Enter First Name')"
                                                    :class="{ 'is-invalid':  $v.typeform.firstname.$error}"
                                                ></b-form-input>
                                                <div v-if="$v.typeform.firstname.$error" class="invalid-feedback">
                                                    <span
                                                        v-if="!$v.typeform.firstname.required"
                                                    >{{ trans.get('__JSON__.Please enter first name') }}</span>
                                                </div>
                                            </b-form-group>
                                            <span v-if="website_url != '' && frontUrl != ''">
                                                <a :href="frontUrl" target="_blank">{{website_url+config.orderWebDefaultUrl}}</a>
                                            </span>
                                        </div>
                                        <div class="col-md-6">
                                            <b-form-group
                                                id="input-group-1"
                                                :label="this.trans.get('__JSON__.Last Name')"
                                                label-for="input-1"
                                                label-class="required"
                                            >
                                            <b-form-input
                                                    id="input-1"
                                                    name="last_name"
                                                    ref="last_name"
                                                    v-model="$v.typeform.lastname.$model"
                                                    type="text"
                                                    autocomplete="off"
                                                    :placeholder="this.trans.get('__JSON__.Enter Last Name')"
                                                    :class="{ 'is-invalid':  $v.typeform.lastname.$error}"
                                            ></b-form-input>
                                            <div v-if="$v.typeform.lastname.$error" class="invalid-feedback">
                                                    <span
                                                        v-if="!$v.typeform.lastname.required"
                                                    >{{ trans.get('__JSON__.Please enter last name') }}</span>
                                                </div>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                                <b-form-group
                                                    id="input-group-1"
                                                    :label="this.trans.get('__JSON__.Email')"
                                                    label-for="input-1"
                                                    label-class="required"
                                                >
                                                    <b-form-input
                                                        id="input-1"
                                                        name="email"
                                                        ref="email"
                                                        v-model="$v.typeform.email.$model"
                                                        type="text"
                                                        autocomplete="off"
                                                        :placeholder="this.trans.get('__JSON__.Enter email')"
                                                        :class="{ 'is-invalid':  $v.typeform.email.$error }"
                                                    ></b-form-input>
                                                    <div v-if="$v.typeform.email.$error" class="invalid-feedback">
                                                        <span
                                                            v-if="!$v.typeform.email.required"
                                                        >{{ trans.get('__JSON__.Please enter email') }}</span>
                                                        <span
                                                            v-if="!$v.typeform.email.email"
                                                        >{{ trans.get('__JSON__.This value should be a valid email') }}</span>
                                                    </div>
                                                </b-form-group>
                                        </div>
                                        <div class="col-md-6">
                                            <b-form-group
                                                id="input-group-1"
                                                :label="this.trans.get('__JSON__.Phone Number')"
                                                label-for="input-1"
                                                label-class="required"
                                            >
                                                <b-form-input
                                                    maxlength="15"
                                                    id="input-1"
                                                    name="phone_number"
                                                    ref="phone_number"
                                                    v-model="$v.typeform.contactno.$model"
                                                    type="text"
                                                    autocomplete="off"
                                                    :placeholder="this.trans.get('__JSON__.Enter Phone Number')"
                                                    :class="{ 'is-invalid':  $v.typeform.contactno.$error }"
                                                ></b-form-input>
                                                    <div v-if="$v.typeform.contactno.$error" class="invalid-feedback">
                                                        <span
                                                            v-if="!$v.typeform.contactno.required"
                                                        >{{ trans.get('__JSON__.Please enter mobile number') }}</span>
                                                        <span
                                                            v-if="!$v.typeform.contactno.numeric"
                                                        >{{ trans.get('__JSON__.This value should be a valid number') }}</span>
                                                    </div>
                                                </b-form-group>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <b-form-group
                                                    id="input-group-1"
                                                    :label="this.trans.get('__JSON__.Enter Password')"
                                                    label-for="input-1"
                                                    label-class="required"
                                                >
                                                    <b-form-input
                                                        id="input-1"
                                                        name="password"
                                                        ref="password"
                                                        v-model="$v.typeform.password.$model"
                                                        type="password"
                                                        autocomplete="off"
                                                        :placeholder="this.trans.get('__JSON__.Enter password')"
                                                        :class="{ 'is-invalid':  $v.typeform.password.$error }"
                                                    ></b-form-input>
                                                    <div v-if="$v.typeform.password.$error" class="invalid-feedback">
                                                        <span
                                                            v-if="!$v.typeform.password.required"
                                                        >{{ trans.get('__JSON__.Please enter password') }}</span>
                                                    </div>
                                            </b-form-group>
                                        </div>
                                        <div class="col-md-6">
                                            <b-form-group
                                                id="input-group-1"
                                                :label="this.trans.get('__JSON__.Enter Confirm Password')"
                                                label-for="input-1"
                                                label-class="required"
                                                autocomplete="off"
                                            >
                                                <b-form-input
                                                    id="input-1"
                                                    name="confirm_password"
                                                    ref="confirm_password"
                                                    v-model="$v.typeform.confirm_password.$model"
                                                    type="password"
                                                    autocomplete="off"
                                                    :placeholder="this.trans.get('__JSON__.Enter Confirm Password')"
                                                    :class="{ 'is-invalid':  $v.typeform.confirm_password.$error }"
                                                ></b-form-input>
                                                    <div v-if="$v.typeform.confirm_password.$error" class="invalid-feedback">
                                                        <span
                                                            v-if="!$v.typeform.confirm_password.required"
                                                        >{{ trans.get('__JSON__.Please enter confirm password') }}</span>
                                                        <span
                                                            v-else-if="!$v.typeform.confirm_password.sameAsPassword"
                                                        >{{ trans.get('__JSON__.Passwords must match') }}</span>
                                                    </div>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <b-form-group
                                                id="input-group-1"
                                                :label="this.trans.get('__JSON__.Enter Address')"
                                                label-for="input-1"
                                                label-class="required"
                                            >
                                                <b-form-input
                                                    id="input-1"
                                                    name="address"
                                                    ref="address"
                                                    v-model="$v.typeform.address.$model"
                                                    type="text"
                                                    autocomplete="off"
                                                    :placeholder="this.trans.get('__JSON__.Enter Address')"
                                                    :class="{ 'is-invalid':  $v.typeform.address.$error }"
                                                ></b-form-input>
                                                <div v-if="$v.typeform.address.$error" class="invalid-feedback">
                                                    <span
                                                        v-if="!$v.typeform.address.required"
                                                    >{{ trans.get('__JSON__.Please enter address') }}</span>
                                                </div>
                                            </b-form-group>
                                        </div>
                                        <div class="col-sm-3">
                                            <div class="form-group cust-uploadbox">
                                                 <label class="control-label">{{ trans.get('__JSON__.Profile Image') }}</label>
                                                <vueDropzone 
                                                    @vdropzone-processing="ProfilebeforeComplete"
                                                    @vdropzone-removed-file="deleteProfileImage"
                                                    @vdropzone-file-added="ProfileFileAdded"
                                                    v-if="ProfileImageread != 1"
                                                    v-model="typeform.profileimage"
                                                    id="profileimagedropzone"
                                                    :options="ProfileimageOptions"
                                                    acceptedFiles=".jpeg,.jpg,.png,.webp,image/webp" 
                                                    :class="{ 'is-invalid':  $v.typeform.profileimage.$error }"
                                                >
                                                </vueDropzone>
                                                <div v-if="$v.typeform.profileimage.$error" class="invalid-feedback">
                                                    <span
                                                        v-if="!$v.typeform.profileimage.required"
                                                    >{{ trans.get('__JSON__.Please upload Profile Image') }}</span>
                                                </div>
                                            </div>
                                            <div id="profile_priview" v-if="ProfileImageread == 1">
                                                <div class='profile_priview_inner priview_inner'>
                                                    <div class='removeclick'>
                                                        <i class='bx bx-x' @click="remove_profile()"></i>
                                                    </div>
                                                    <img src="" class="h-50 w-75 mb-3" id='profile_image_priview'/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                               <label class="control-label">{{ trans.get('__JSON__.Vehicle Type') }}</label>
                                                <select 
                                                :class="{'custom-select':true,'is-invalid':  $v.typeform.vehicle_type.$error}" 
                                                v-model="typeform.vehicle_type"  
                                                id="vehicle_type" 
                                                name="vehicle_type" 
                                                @change="vehicleChange($event)"
                                                >
                                                    <option value="">{{trans.get('__JSON__.Select Vehicle Type')}}</option>
                                                    <option v-for="vec in vehichleName" :is_licence_required="vec.is_licence_required" :value="vec.vehicle_name" :key="vec.vehicle_name">{{ vec.vehicle_name }}</option>
                                                </select>
                                                <div v-if="$v.typeform.vehicle_type.$error" class="invalid-feedback">
                                                    <span
                                                        v-if="!$v.typeform.vehicle_type.required"
                                                    >{{ trans.get('__JSON__.Please select vehicle type') }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-6" v-if="is_licence_required">
                                            <b-form-group
                                                id="input-group-1"
                                                :label="this.trans.get('__JSON__.Vehicle Number')"
                                                label-for="input-1"
                                                label-class="required"
                                            >
                                                <b-form-input
                                                    id="input-1"
                                                    name="vehical_name"
                                                    ref="vehical_name"
                                                    v-model="$v.typeform.vehicle_number.$model"
                                                    type="text"
                                                    autocomplete="off"
                                                    :placeholder="this.trans.get('__JSON__.Enter Vehicle Name')"
                                                    :class="{ 'is-invalid':  $v.typeform.vehicle_number.$error}"
                                                ></b-form-input>
                                               <div v-if="$v.typeform.vehicle_number.$error" class="invalid-feedback">
                                                    <span
                                                        v-if="!$v.typeform.vehicle_number.required"
                                                    >{{ trans.get('__JSON__.Please enter vehicle number') }}</span>
                                                    <!-- <span
                                                        v-if="!$v.typeform.vehicle_number.numeric"
                                                    >{{ trans.get('__JSON__.This value should be a valid number') }}</span> -->
                                                </div>
                                            </b-form-group>
                                        </div>
                                        <div class="col-sm-6" v-if="is_licence_required">
                                            <b-form-group
                                                id="input-group-1"
                                                :label="this.trans.get('__JSON__.Licence Number')"
                                                label-for="input-1"
                                                label-class="required"
                                            >
                                                <b-form-input
                                                    id="input-1"
                                                    name="licence_number"
                                                    ref="licence_number"
                                                    v-model="$v.typeform.licence_number.$model"
                                                    type="text"
                                                    autocomplete="off"
                                                    :placeholder="this.trans.get('__JSON__.Enter Licence Number')"
                                                    :class="{ 'is-invalid':  $v.typeform.licence_number.$error}"
                                                ></b-form-input>
                                                <div v-if="$v.typeform.licence_number.$error" class="invalid-feedback">
                                                    <span
                                                        v-if="!$v.typeform.licence_number.required"
                                                    >{{ trans.get('__JSON__.Please enter licence number') }}</span>
                                                    <!-- <span
                                                        v-if="!$v.typeform.licence_number.numeric"
                                                    >{{ trans.get('__JSON__.This value should be a valid number') }}</span> -->
                                                </div>
                                            </b-form-group>    
                                        </div>
                                        <div class="col-sm-3" v-if="is_licence_required">
                                            <div class="form-group cust-uploadbox">
                                                <label>{{ trans.trans('__JSON__.Driver Licence Documents',{ driver:this.driver_name }) }}</label>
                                                <vueDropzone 
                                                    @vdropzone-removed-file="deletelicenceImage"
                                                    @vdropzone-file-added="LicenceFileAdded"
                                                    @vdropzone-processing="LicencebeforeComplete"
                                                    v-if="LicenceDocumentread != 1"
                                                    v-model="vehicle_document"
                                                    id="vehicle_document_dropzone"
                                                    :options="vehicledocumentOptions"
                                                    :class="{ 'is-invalid':$v.vehicle_document.$error }"
                                                >
                                                </vueDropzone>
                                                <div v-if="$v.vehicle_document.$error" class="invalid-feedback">
                                                    <span
                                                        v-if="!$v.vehicle_document.$required"
                                                    >{{ trans.get('__JSON__.Please upload document') }}</span>
                                                </div>
                                            </div>
                                            <div v-if="LicenceDocumentread == 1">
                                                <div class='licence_document_inner priview_inner'>
                                                    <div class='removeclick'>
                                                        <i class='bx bx-x' @click="remove_licence_document()"></i>
                                                    </div>
                                                    <img :src="licence_priview_link" class="w-100 mb-3" id='licence_image_priview' style="105px"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-3">
                                            <div class="form-group cust-uploadbox">
                                                <label>{{ trans.get('__JSON__.National Identity Document') }}</label>
                                                <vueDropzone 
                                                    @vdropzone-removed-file="deleteidentyImage"
                                                    @vdropzone-file-added="IdentyFileAdded"
                                                    @vdropzone-processing="IdentybeforeComplete"
                                                    ref="IdentyDropzone"
                                                    v-model="typeform.Identity_document"
                                                    id="Identity_document_dropzone"
                                                    :options="Identity_documentOptions"
                                                    acceptedFiles=".jpeg,.jpg,.png"
                                                    :class="{ 'is-invalid':  $v.typeform.Identity_document.$error }"
                                                >
                                                </vueDropzone>
                                                <div v-if="$v.typeform.Identity_document.$error" class="invalid-feedback">
                                                    <span
                                                        v-if="!$v.typeform.Identity_document.required"
                                                    >{{ trans.get('__JSON__.Please upload document') }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <template v-if="this.typeform.Identity_document.length > 0">
                                        <!-- <div class="doc-images-main"> -->
                                            <div id="indenty_document_main" class="col-sm-3 doc-img" v-for="(document,index) in this.typeform.Identity_document" :key="index">
                                                <div class="mt-5" :class="'indenty_document_inner_'+index" :id="'i_document_inner'+index" :data-indenty_document="index">
                                                    <div class='removeclick mt-5'>
                                                        <i class='bx bx-x ' @click="remove_indenty_document(index)"></i>
                                                    </div>
                                                    <img :src="RemoveIdentyLink[index]" class="w-100 mb-3" id='identy_image_priview' style="height: 105px;"/>
                                                </div>
                                            </div>
                                        <!-- </div> -->
                                        </template>
                                    </div>

                                    <!-- term & condition and submit-->
                                    <div>
                                        <b-form-checkbox
                                            id="terms"
                                            v-model="termscondition"
                                            name="terms"
                                            value="accepted"
                                            unchecked-value=""
                                            :class="{ 'is-invalid': termscondition_error }"
                                            @change="termsconditionfun"
                                        >
                                        {{trans.get('__JSON__.I Agree')}} 
                                        <a v-if="terms_condition_view" :href="terms_condition_url" target="_blank">{{ trans.get('__JSON__.Terms of Service') }}</a> 
                                        <span v-if="privacypolicy_view == terms_condition_view">&</span> <a v-if="privacypolicy_view" :href="privacypolicy_url" target="_blank">{{trans.get('__JSON__.Privacy policy')}}</a> 
                                        {{trans.get('__JSON__.of')}} {{ default_appname }}.
                                        </b-form-checkbox>
                                        <div v-if="termscondition_error" class="invalid-feedback">
                                            <span v-if="termscondition_error">{{ trans.get('__JSON__.Please select the checkbox')}}</span>
                                        </div>
                                    </div><br><br>
                                    <div v-if="btndisabled">
                                        <button id="submit"
                                            class="btn btn-primary btn-block"
                                            @keyup.enter="addDriver"
                                            disabled
                                            >{{ trans.get('__JSON__.Please Wait...') }}
                                        </button>
                                    </div>
                                    <div v-else>
                                        <button id="submit"
                                            class="btn btn-primary btn-block"
                                            @keyup.enter="addDriver"
                                            :disabled="isSubmitDisable"
                                            >{{ trans.get('__JSON__.Signup') }}
                                        </button>
                                    </div>
                                    <!-- term & condition and submit-->
                                </b-form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
.removeclick{
    position: absolute;
    cursor: pointer;
    text-align: center;
    left: 100px;
    top: 0;
    color: white;
    background: red;
    height: 20px;
    padding-top: 0px;
    border-radius: 50%;
    width: 20px;
    font-size: 16px;
    box-shadow: 0px 0px 5px black;
}
.priview_inner {
    position: relative;
    display: inline-block;
}
.doc-images-main{
    display: flex;
    flex-wrap: wrap;
}
.doc-images-main .doc-img{
    width: 33%;
    position: relative;
}
.plus{
    height:50px;
}
.cust-uploadbox .dropzone {
    min-height: 110px !important;
    height: 110px;
    width: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ced4da !important;
}
.cust-uploadbox .dz-message i {
    font-size: 60px;
}   
.cust-uploadbox .dropzone.dz-clickable .dz-message {
    margin: 0 !important;
}   
</style>