<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import { debounce, error_message, order_statuses, success_message, toastr_message } from '../../Helper/helper';
import DateRangePicker from "vue2-daterange-picker";
import OrderList from "../orders/order_tabs/order-list";
import pagination from "laravel-vue-pagination";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import AffiliateAdd from '../../views/affiliate/affiliate-add.vue';
import InstamedcashHistory from '../../views/affiliate/instamedcash-history.vue';
import {
  required,
  numeric,
  minLength,
  maxLength
}from "vuelidate/lib/validators";
import { driverService, customerService} from "../../services";
/**
 * Products-order component
 */
export default {
  components: { Layout, PageHeader, OrderList , DateRangePicker, pagination, AffiliateAdd,InstamedcashHistory},
  data() {
    
    return {
      affiliates: {
        restaurant_id:"",
        name: "",
        contact_number: "",
        full_address: "",
        referral_code: "",
        affiliate_id: "",
        restaurant_name:""
      },
      referredCount: '',
      statuses: order_statuses,
      loading: false,
      affiliate_status: 0,
      restaurant:[],
      debounce,
    };
  },
  validations: {
    affiliates: {
      name: {
        required
      },
      restaurant_id: {
        required,
      },
      full_address: {
        required,
      },
      contact_number: {
        required,
        numeric,
        minLength: minLength(10),
        maxLength: maxLength(10)
      },
      referral_code: {
        required
      }
    }
  },
  mounted() {
    this.getRestaurant();
  },
  methods: {
    getRestaurant(){
      var filters = {
            search: '',
            };
      driverService.getRestaurant(filters)
      .then(response => {
        this.restaurant =  response.data.result;
      });
    },
    typeForm(e) {
      this.$v.$touch();
      if (this.$v.affiliates.$invalid) {
        console.log("hi");
        return;
      }
      else{
        console.log(this.affiliates.restaurant_id, "this.affiliates");
        console.log(this.restaurant, "restbdfhbd");

        const affiliateRest = this.restaurant.find(item => item.restaurant_id === this.affiliates.restaurant_id);
        let data = {
          restaurant_id: this.affiliates.restaurant_id,
          vendor_id:this.$auth.getVendorId(),
          name: this.affiliates.name,
          restaurant_name: affiliateRest.name,
          full_address: this.affiliates.full_address,
          contact_number: this.affiliates.contact_number,
          country_code:"+91",
          referral_code: this.affiliates.referral_code,
          status:1
        }
        customerService.addAffiliateDetails(data)
        .then(response => {
          if (response.data.code == 200) {
            success_message(response.data.msg);
            this.$router.back()
          } else {
            error_message(response.data.msg);
          }
        });
      }  
    }, 
    generateCode(e){
      e.preventDefault();
      const alphabet = '1234567890';
      let randomString = '';

      for (let i = 0; i < 2; i++) {
        const randomIndex = Math.floor(Math.random() * alphabet.length);
        randomString += alphabet.charAt(randomIndex);
      }
      const extractedChars = this.affiliates.name.substring(0, 2).toUpperCase();
      this.affiliates.referral_code = "INSTA" + "-" + randomString + extractedChars + this.$auth.setting.instamed_cash_value_affiliate;
    }
  },
  
};
</script>

<template>
  <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
      <div class="col-12">
        <form action="#" @submit.prevent="typeForm">  
      <!-- profile Info Start -->
        <div>
            <h4 class="card-title"></h4>
            <p class="card-title-desc"></p>

              <div class="row">
                <div class="col-sm-12 affiliate-margin">
                  <h4 class="card-title">{{trans.get('__JSON__.Personal Details')}}</h4>
                </div>
                <hr>
                <div class="col-sm-6">
                  <div class="form-group">
                      <label>{{ trans.get('__JSON__.Store Name') }}(English)</label>
                    <select class="custom-select" v-model="affiliates.restaurant_id" id="restaurant_id" name="restaurant_id" :class="{ 'is-invalid':$v.affiliates.restaurant_id.$error }"> 
                        <option value="">Select Pharmacy </option>
                        <option v-for="restaurants in restaurant" :value="restaurants.restaurant_id" :key="restaurants.restaurant_id">{{restaurants.name}}</option>
                    </select>
                    <div v-if="$v.affiliates.restaurant_id.$error" class="invalid-feedback">
                        <span v-if="!$v.affiliates.restaurant_id.required">{{ trans.get('__JSON__.Please enter Store name') }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label>{{ trans.get('__JSON__.Affiliate Name') }}(English)</label>
                    <input id="name" type="text" class="form-control" :placeholder="trans.get('__JSON__.Enter Affiliate Name')" v-model="affiliates.name" name="name" :class="{ 'is-invalid':$v.affiliates.name.$error }"/>
                    <div v-if="$v.affiliates.name.$error" class="invalid-feedback">
                        <span v-if="!$v.affiliates.name.required">{{ trans.get('__JSON__.Please enter Affiliate name') }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="control-label">{{ trans.get('__JSON__.Contact Number') }}</label>
                    <input id="contact_number" type="text" class="form-control" :placeholder="trans.get('__JSON__.Enter Contact Number')" v-model="affiliates.contact_number" name="contact_number" :class="{ 'is-invalid':$v.affiliates.contact_number.$error }"/>
                      <div v-if="$v.affiliates.contact_number.$error" class="invalid-feedback">
                        <span v-if="!$v.affiliates.contact_number.required">{{ trans.get('__JSON__.Please enter contact number') }}</span>
                        <span v-if="!$v.affiliates.contact_number.numeric">{{ trans.get('__JSON__.This value should be a valid number') }}</span>
                        <span v-else-if="!$v.affiliates.contact_number.minLength">{{ trans.get('__JSON__.The phone number should have at least 10 digits') }}</span>
                        <span v-else-if="!$v.affiliates.contact_number.maxLength">{{ trans.get('__JSON__.The phone number should have at most 10 digits') }}</span>
                      </div>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label>{{ trans.get('__JSON__.Full Address') }}</label>
                    <input id="full_address" type="text" class="form-control" :placeholder="trans.get('__JSON__.Enter Full Address')" v-model="affiliates.full_address" name="full_address" :class="{ 'is-invalid':$v.affiliates.full_address.$error }"/>
                      <div v-if="$v.affiliates.full_address.$error" class="invalid-feedback">
                        <span v-if="!$v.affiliates.full_address.required">{{ trans.get('__JSON__.Please enter Full Address') }}</span>
                      </div>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="control-label">{{ trans.get('__JSON__.Affiliate Refer Code') }}</label>
                    <div style="display: flex;">
                        <input id="referral_code" type="text" class="form-control" disabled v-model="affiliates.referral_code" name="referral_code" style="width: 20% !important; background-color : #E8E7E8;" :class="{ 'is-invalid':$v.affiliates.referral_code.$error }"/>
                        <button class="generate-code-btn" :disabled="!this.affiliates.name" @click="generateCode">Generate Code</button>
                    </div>
                    <div v-if="$v.affiliates.referral_code.$error" class="invalid-feedback" style="display: block !important">
                      <span v-if="!$v.affiliates.referral_code.required">{{ trans.get('__JSON__.Please generate refer code') }}</span>
                    </div>
                </div>
                </div>
              </div>
              <div class="row affiliate-margin">
                <div class="col-sm-6 mb-5">
                  <button type="submit" class="btn btn-primary mr-1 mt-3">{{ trans.get('__JSON__.Add Affiliate') }}</button>
                  <b-button @click="$router.back()" type="button" variant="secondary" class="mt-3">{{ trans.get('__JSON__.Cancel') }}</b-button>
                </div>
              </div>
        </div>
      <!-- profile Info End -->
      </form>
      </div>
    </div>
            </div>
          </div>
        </div>
      </div>


</template>

<style>
  .generate-code-btn{
    border-radius: 5px; 
    background: #8C44DA; 
    color: #fff; 
    border-color: #8C44DA;
    margin-left: 15px;
  }
  .affiliate-margin{
    margin-bottom: 40px;
  }
</style>