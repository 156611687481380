<script>
import { required, requiredIf } from "vuelidate/lib/validators";
import { webLayoutService } from "../../../services";
import { success_message, error_message } from "../../../Helper/helper";
import config from "../../../config";
import DropzonePopup from "../../../components/widgets/dropzone-popup";
import { routes } from "../../../config/api_routes";
/**
 * Starter component
 */
export default {
  components: { DropzonePopup },
  data() {
    return {
      config: config,
      web: [],
      web: {
        fb_app_id: "",
        google_client_id: "",
        firebase_apiKey: "",
        firebase_authDomain: "",
        firebase_databaseURL: "",
        firebase_projectId: "",
        firebase_storageBucket: "",
        firebase_messagingSenderId: "",
        firebase_appId: "",
        firebase_measurementId: "",
      },
    };
  },
  validations: {
    web: {},
  },
  mounted() {
    this.getWebSetting();
  },
  methods: {
    getWebSetting() {
      webLayoutService.getWebSetting().then((response) => {
        const { fb_app_id, google_client_id, firebase_apiKey, firebase_authDomain, firebase_databaseURL , firebase_projectId, firebase_storageBucket, firebase_messagingSenderId, firebase_appId, firebase_measurementId} = response.data.result;
        this.web = {
          fb_app_id: fb_app_id || "",
          google_client_id: google_client_id || "",
          firebase_apiKey: firebase_apiKey || "",
          firebase_authDomain: firebase_authDomain || "",
          firebase_databaseURL: firebase_databaseURL || "",
          firebase_projectId: firebase_projectId || "",
          firebase_storageBucket: firebase_storageBucket || "",
          firebase_messagingSenderId: firebase_messagingSenderId || "",
          firebase_appId: firebase_appId || "",
          firebase_measurementId: firebase_measurementId || "",
        };
      });
    },
    saveSettings() {
      this.$v.$touch();
      if (this.$v.web.$invalid) {
        return;
      } else {
        const fd = new FormData();
        fd.append("fb_app_id", this.web.fb_app_id);
        fd.append("google_client_id", this.web.google_client_id);
        fd.append("firebase_apiKey", this.web.firebase_apiKey);
        fd.append("firebase_authDomain", this.web.firebase_authDomain);
        fd.append("firebase_databaseURL", this.web.firebase_databaseURL);
        fd.append("firebase_projectId", this.web.firebase_projectId);
        fd.append("firebase_storageBucket", this.web.firebase_storageBucket);
        fd.append("firebase_messagingSenderId", this.web.firebase_messagingSenderId);
        fd.append("firebase_appId", this.web.firebase_appId);
        fd.append("firebase_measurementId", this.web.firebase_measurementId);
        fd.append("type", 4);
        webLayoutService.updateWebSetting(fd).then((response) => {
          if (response.data.code === 200) {
            success_message(response.data.message);
          } else {
            error_message(response.data.message);
          }
        });
      }
    },
  },
};
</script>
<template>
  <b-form @submit.prevent="saveSettings">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body setting-main">
            <div class="row section-main">
              <div class="col-md-4">
                <div class="setting-heading">
                  <h4>{{ trans.get("__JSON__.Facebook Login") }}</h4>
                </div>
              </div>
              <div class="col-md-8">
                <div class="setting-data">
                  <div class="row">
                    <div class="col-md-6">
                      <b-form-group
                            id="fb_app_id"
                            :label="trans.get('__JSON__.AppID')"
                            label-for="name"
                          >
                            <b-form-input
                              id="fb_app_id"
                              name="fb_app_id"
                              v-model="web.fb_app_id"
                              type="text"
                              :placeholder="trans.get('__JSON__.AppID')"
                            />
                      </b-form-group>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div class="row section-main">
              <div class="col-md-4 mt-2">
                <div class="setting-heading">
                  <h4>{{ trans.get("__JSON__.Google Login") }}</h4>
                </div>
              </div> 
               <div class="col-md-8 mt-2">
                <div class="setting-data">
                  <div class="row">
                    <div class="col-md-6">
                      <b-form-group
                            id="google_client_id"
                            :label="trans.get('__JSON__.ClientId')"
                            label-for="name"
                          >
                            <b-form-input
                              id="google_client_id"
                              name="google_client_id"
                              v-model="web.google_client_id"
                              type="text"
                              :placeholder="trans.get('__JSON__.ClientId')"
                            />
                      </b-form-group>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div class="row section-main">
              <div class="col-md-4">
                <div class="setting-heading">
                  <h4>
                    {{ trans.get("__JSON__.Mobile Number Login") }} ({{ trans.get("__JSON__.Firebase") }})
                  </h4>
                </div>
              </div>
              <div class="col-md-8">
                <div class="setting-data">
                  <div class="row">
                    <div class="col-md-6">
                      <h6>{{ trans.get("__JSON__.API Key") }}</h6>
                      <b-form-group>
                        <b-form-input
                          id="firebase_apiKey"
                          name="firebase_apiKey"
                          v-model="web.firebase_apiKey"
                          type="text"
                          :placeholder="trans.get('__JSON__.API Key')"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="col-md-6">
                      <h6>{{ trans.get("__JSON__.Auth Domain") }}</h6>
                      <b-form-group>
                        <b-form-input
                          id="firebase_authDomain"
                          name="firebase_authDomain"
                          v-model="web.firebase_authDomain"
                          type="text"
                          :placeholder="trans.get('__JSON__.Auth Domain')"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="row mt-4">
                    <div class="col-md-6">
                      <h6>{{ trans.get("__JSON__.Database URL") }}</h6>
                      <b-form-group id="input-group-1" label-for="name">
                        <b-form-input
                          id="firebase_databaseURL"
                          name="firebase_databaseURL"
                          v-model="web.firebase_databaseURL"
                          type="text"
                          :placeholder="trans.get('__JSON__.Database URL')"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="col-md-6">
                      <h6>{{ trans.get("__JSON__.Project Id") }}</h6>
                      <b-form-group>
                        <b-form-input
                          id="firebase_projectId"
                          name="firebase_projectId"
                          v-model="web.firebase_projectId"
                          type="text"
                          :placeholder="trans.get('__JSON__.Project Id')"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="row mt-4">
                    <div class="col-md-6">
                      <h6>{{ trans.get("__JSON__.Storage Bucket") }}</h6>
                      <b-form-group id="input-group-1" label-for="name">
                        <b-form-input
                          id="firebase_storageBucket"
                          name="firebase_storageBucket"
                          v-model="web.firebase_storageBucket"
                          type="text"
                          :placeholder="trans.get('__JSON__.Storage Bucket')"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="col-md-6">
                      <h6>{{ trans.get("__JSON__.Messaging Sender Id") }}</h6>
                      <b-form-group>
                        <b-form-input
                          id="firebase_messagingSenderId"
                          name="firebase_messagingSenderId"
                          v-model="web.firebase_messagingSenderId"
                          type="text"
                          :placeholder="
                            trans.get('__JSON__.Messaging Sender Id')
                          "
                        ></b-form-input>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="row mt-4">
                    <div class="col-md-6">
                      <h6>{{ trans.get("__JSON__.App Id") }}</h6>
                      <b-form-group id="input-group-1" label-for="name">
                        <b-form-input
                          id="firebase_appId"
                          name="firebase_appId"
                          v-model="web.firebase_appId"
                          type="text"
                          :placeholder="trans.get('__JSON__.App Id')"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="col-md-6">
                      <h6>{{ trans.get("__JSON__.Measurement Id") }}</h6>
                      <b-form-group>
                        <b-form-input
                          id="firebase_measurementId"
                          name="firebase_measurementId"
                          v-model="web.firebase_measurementId"
                          type="text"
                          :placeholder="trans.get('__JSON__.Measurement Id')"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="btn-update">
              <div class="col-sm-6">
                <button
                  type="submit"
                  v-if="
                    $auth.hasPermission(config.permissions.configuration_write)
                  "
                  class="btn btn-primary mr-1 mt-3"
                >
                  {{ trans.get("__JSON__.Update") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-form>
</template>