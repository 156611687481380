
// Default Setting services rest api calling

import { routes } from '../config/api_routes'

export const defaultSettingService = {

    // update Default Settings
    updateDefaultSetting: (formData) => window.axios.post(routes.updateDefaultSettingApi, formData),

    // update WebLayoutSetting
    updateWebSetting: (formData) => window.axios.post(routes.updateWebSettingsApi, formData),

    // delete Section
    deleteFrontImage: (formData) => window.axios.post(routes.deleteFrontSettingsImageApi, formData)

}