<script>

import Multiselect from 'vue-multiselect'
import PageHeader from "../../../components/page-header";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import Layout from "../../../layouts/main";
import { vendorFinanceService, settingService } from '../../../services';
import { date_ranges, generate_csv, get_currency, get_decimal } from '../../../Helper/helper';
import pagination from "laravel-vue-pagination";
/**
 * Contacts-list component
 */ 

export default {
  components: { Layout, PageHeader,Multiselect,DateRangePicker,pagination },
  props: ['restaurant_id'],
  data() {
    let startDate = this.moment().startOf('day').toDate();
    let endDate = this.moment().endOf('day').toDate();
    return {
      dateRange: { startDate, endDate },
      decimal:get_decimal(),
      month_data: {},
      limit:1,
      month: this.moment().format('MMMM'),
      selected_date: this.moment().format('YYYY-MM-DD'),
      sortBy: "date",
      sortDesc: true,
      setting: [],
      years: [],
      currency: get_currency(),
      months: Array.apply(0, Array(12)).map((_,i) => this.moment().month(i).format('MMMM')).reverse(),
      fields: [
        { key: "hash_id", label: '#', sortable: false },
        { key: "date", label: this.trans.get("__JSON__.Date"), sortable: false },
        { key: "sum_sub_mrp_total", label: this.trans.get("__JSON__.Item MRP"), sortable: false },
        { key: "sum_sub_total", label: this.trans.get("__JSON__.Item Price"), sortable: false },
        { key: "sum_itemtax_total", label: this.trans.get("__JSON__.Item")+ " " + this.$auth.getTaxName(), sortable: false },
        { key: "sum_delivery_fee", label: this.trans.get("__JSON__.Delivery Charges"), sortable: false },
        { key: "sum_service_fee", label: this.trans.get("__JSON__.Cart")+ " " + this.$auth.getTaxName(), sortable: false },
        { key: "sum_discount", label: this.trans.get("__JSON__.Coupon Discount"), sortable: false },
        { key: "sum_amount", label: this.trans.get("__JSON__.Total Amount"), sortable: false },
        { key: "sum_admin_commision", label: this.trans.get("__JSON__.Admin Commission") + " (Order)", sortable: false },
        { key: "sum_driver_eanring", label: this.trans.get("__JSON__.Admin Commission") + " (Delivery)", sortable: false},
        { key: "chef_earning", label: this.trans.get("__JSON__.Vendor Earning",{vendor: this.$auth.getRestaurantName()}), sortable: false },
        { key: "sum_admin_earning", label: this.trans.get("__JSON__.Admin Earning"), sortable: false , requiresAdmin: true},
      ],
      date_ranges: date_ranges
    };
  },
  filters: {
    date(val) {
      return val ? val.toLocaleString() : "";
    },
  },
  computed: {
    computedFields() {
      // If the user isn't an admin, filter out fields that require auth.
      if(this.$auth.partner_login)
        return this.fields.filter(field => !field.requiresAdmin);
      // If the user IS an admin, return all fields.
      else
        return this.fields;
    }
  },
  mounted(){
    this.orderWiseRestaurantEarnings();
  },
  methods:{
    orderWiseRestaurantEarnings(page = 1){
      vendorFinanceService.getOrderwiseRestaurantEarnings({
        start_date: this.moment(this.dateRange.startDate).format('YYYY-MM-DD'),
        end_date: this.moment(this.dateRange.endDate).format('YYYY-MM-DD'),
        restaurant_id: this.restaurant_id,
        sortBy: this.sortBy,
        orderBy: this.sortDesc ? "desc" : "asc",
        page: page ? page : 1,
      }).then(res => {
        this.month_data = res.data.result.data.data;
        const total = res.data.result.data.total||{};
        if(this.month_data.total!==0){
          total.hash_id = this.trans.get('__JSON__.Total');
          total.date = '-';
          this.month_data.data = [...this.month_data.data,(total||{})]
          this.currency = res.data.result.currency
        }
      });
    },
    downloadsCSV(page = 1){
      vendorFinanceService.getOrderwiseRestaurantEarningsExport({
        start_date: this.moment(this.dateRange.startDate).format('YYYY-MM-DD'),
        end_date: this.moment(this.dateRange.endDate).format('YYYY-MM-DD'),
        restaurant_id: this.restaurant_id,
        sortBy: this.sortBy,
        orderBy: this.sortDesc ? "desc" : "asc",
        page: page ? page : 1,  
      }).then(res => {
        let export_data = res.data.result.data.data;
        const total = res.data.result.data.total || {};
        if(export_data.length > 0){
          total.hash_id = this.trans.get('__JSON__.Total');
          total.date = '-';
          export_data = [...export_data,(total||{})]
          this.currency = res.data.result.currency
        }
        let vendorFinance = '\ufeff' +
        ','
        +','
        +'IM'+','
        +'IP'+','
        +'IT'+','
        +'DC'+','
        +'CT'+','
        +'CD'+','
        +'TA = IP + DC - CD'+','
        +'ACO = (IM % Commission/ Fix)'+','
        +'ACD = DC - FME'+','
        +'VE = IP - ACO'+','
        +'AE = ACO + ACD - CD'+','+'\n'

        vendorFinance += '#,'
        +this.trans.get("__JSON__.Date")+','
        +this.trans.get("__JSON__.Item MRP")+','
        +this.trans.get("__JSON__.Item Price")+','
        +this.trans.get("__JSON__.Item")+ " " + this.$auth.getTaxName()+','
        +this.trans.get("__JSON__.Delivery Charges")+','  
        +this.trans.get("__JSON__.Cart")+ " " + this.$auth.getTaxName()+','
        +this.trans.get("__JSON__.Coupon Discount")+','
        +this.trans.get("__JSON__.Total Amount")+','
        +this.trans.get("__JSON__.Admin Commission")+' (Order),'
        +this.trans.get("__JSON__.Admin Commission")+' (Delivery),'
        +this.trans.get("__JSON__.Vendor Earning",{vendor: this.$auth.getRestaurantName()})+','
        +this.trans.get("__JSON__.Admin Earning")
        +'\n';
        export_data.map(el=> {
          let orderIdText = el['hash_id'];
          if (orderIdText != 'Total') {
            if (el.parent_id && el.parent_id > 0) {
              orderIdText = '#'+el.parent_id+' Ship. 1';
            } else if (el.suborder && el.suborder.id && el.suborder.id > 0) {
              orderIdText = el['hash_id']+' Ship. 2';
            }
          } else {
            orderIdText = el['hash_id'];
          }
          var line = ''+orderIdText + ',' 
          + el['date'] + ',' 
          + el['sum_sub_mrp_total'].toFixed(this.decimal) + ',' 
          + el['sum_sub_total'].toFixed(this.decimal) + ',' 
          + el['sum_itemtax_total'].toFixed(this.decimal) + ',' 
          + el['sum_delivery_fee'].toFixed(this.decimal) + ',' 
          + el['sum_service_fee'].toFixed(this.decimal) + ',' 
          + el['sum_discount'].toFixed(this.decimal) + ',' 
          + el['sum_amount'].toFixed(this.decimal) + ',' 
          + el['sum_admin_commision'].toFixed(this.decimal) + ',' 
          + el['sum_driver_eanring'].toFixed(this.decimal) + ',' 
          + el['chef_earning'].toFixed(this.decimal) + ',' 
          + el['sum_admin_earning'].toFixed(this.decimal) +'\n'
          vendorFinance +=line;
        })
        generate_csv(vendorFinance,this.$auth.getRestaurantName() + "-finance-"
        +this.moment(this.dateRange.startDate).format('YYYY-MM-DD')+'-'+
        this.moment(this.dateRange.endDate).format('YYYY-MM-DD')+
        '.csv');
      });
    },
    disabledDate(date){
      return this.moment(date).isAfter(this.moment())
    }
  }
};
</script>

<template>
  <div class="row">
    <div class="col-12">
      <div class="justify-content-end mb-3 row">
        <div class="finance-order-month">
          <label for="">{{trans.get('__JSON__.Date')}}</label>
          <div class="text-sm-right">
            <div class="mr-2 ">
              <date-range-picker
                ref="picker"
                :locale-data="{
                  firstDay: 1,
                  format: 'dd-mm-yyyy',
                }"
                :showDropdowns="true"
                :showWeekNumbers="true"
                opens="left"
                v-model="dateRange"
                :ranges="date_ranges()"
                @update="orderWiseRestaurantEarnings"
              >
                <template
                  v-slot:input="picker"
                  style="min-width: 350px"
                >
                  {{ moment(picker.startDate.toISOString()).format('YYYY-MM-DD') | date }} -
                  {{ moment(picker.endDate.toISOString()).format('YYYY-MM-DD') | date }}
                </template>
              </date-range-picker>
            </div>
          </div>
        </div>
        <div class="mt-4 pt-1 mr-3">
          <div class="text-sm-right">
            <button @click="downloadsCSV" class="btn btn-primary btn-rounded export-btn">
              {{trans.get('__JSON__.Export To CSV')}}
            </button>
            <button @click="downloadsCSV" class="btn btn-primary btn-rounded m-export-btn">
              <i class="bx bx-archive-in"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="table-responsive table-border">
        <b-table
          :items="month_data && month_data.data"
          :fields="fields"
          :sort-by.sync="sortBy"
          :no-local-sorting="true"
          show-empty
          class="table-centered table-nowrap table-hover order-menu"
        >
          <template #thead-top>
            <b-tr class="formulas">
              <b-th></b-th>
              <b-th></b-th>
              <b-th>IM</b-th>
              <b-th>IP</b-th>
              <b-th>IT</b-th>
              <b-th>DC</b-th>
              <b-th>CT</b-th>
              <b-th>CD</b-th>
              <b-th>TA = IP + DC - CD</b-th>
              <b-th>ACO = (IM % Commission/ Fix)</b-th>
              <b-th>ACD = DC - FME</b-th>
              <b-th>VE = IP - ACO</b-th>
              <b-th>AE = ACO + ACD - CD</b-th>
            </b-tr>
          </template>
          <template #cell(hash_id)="data">
            <!-- <span class="font-weight-bold">{{data.value}}</span> -->
            <template v-if="data.value != 'Total'">
              <template v-if="data.item.parent_id && data.item.parent_id > 0">
                <span class="font-weight-bold">{{'#'+data.item.parent_id}}</span><br> Ship. 1
              </template>
              <template v-else-if="data.item.suborder && data.item.suborder.id && data.item.suborder.id > 0">
                <span class="font-weight-bold">{{data.value}}</span><br> Ship. 2
              </template>
              <template v-else>
                <span class="font-weight-bold">{{data.value}}</span>
              </template>
            </template>
            <template v-else>
              <span class="font-weight-bold">{{data.value}}</span>
            </template>
          </template>
          <template #cell(date)="data">
            {{ data.value }}
          </template>
          <template #cell(sum_sub_mrp_total)="data">
            <span v-html="currency"></span> {{ data.value.toFixed(decimal) }}
          </template>
          <template #cell(sum_sub_total)="data">
            <span v-html="currency"></span> {{ data.value.toFixed(decimal) }}
          </template>
          <template #cell(sum_itemtax_total)="data">
            <span v-html="currency"></span> {{ data.value.toFixed(decimal) }}
          </template>
          <template #cell(sum_delivery_fee)="data">
            <span v-html="currency"></span> {{ data.value.toFixed(decimal) }}
          </template>
          <template #cell(sum_service_fee)="data">
            <span v-html="currency"></span> {{ data.value.toFixed(decimal) }}
          </template>
          <template #cell(sum_discount)="data">
            <span v-html="currency"></span> {{ data.value && data.value.toFixed(decimal) || '0.00'}}
          </template>
          <template #cell(sum_amount)="data">
            <span v-html="currency"></span> {{ data.value.toFixed(decimal) }}
          </template>
          <template #cell(sum_admin_commision)="data">
            <span v-html="currency"></span> {{ data.value.toFixed(decimal) }}
          </template>
          <template #cell(sum_driver_eanring)="data">
            <span v-html="currency"></span> {{ data.value.toFixed(decimal) }}
          </template>
          <template #cell(chef_earning)="data">
            <span v-html="currency"></span> {{ data.value && data.value.toFixed(decimal) || '0.00'}}
          </template>
          <template #cell(sum_admin_earning)="data">
            <span v-html="currency"></span> {{ data.value && data.value.toFixed(decimal) || '0.00'}}
          </template>
        </b-table>
      </div>
    </div>
    <div class="col-12" v-if="month_data && month_data.total > 0">
      <div class="dataTables_paginate paging_simple_numbers float-right">
        <ul class="pagination pagination-rounded mb-0">
          <!-- pagination -->
          <pagination
            :data="month_data"
            :limit="limit"
            @pagination-change-page="orderWiseRestaurantEarnings"
          ></pagination>
        </ul>
      </div>
    </div>
  </div>
</template>
<style scoped>
.formulas th{
  font-size: 10px;
}
</style>