<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import { clientService } from "../../services";
import pagination from "laravel-vue-pagination";
export default {
  components: {
    Layout,
    PageHeader,
    clientService,
    pagination
  },
  data() {
    return {
      client: {},
      clientCount: '',
      paginations: {},
      sortBy: 'vendor_id',
      search: '',
      sortDesc : false,
      limit: 1,
      status: "1",
      subscription_due_date : "",
      loading: true,
      fields: [
        { key: "subscription_date", label: "#", sortable: false },
        { key: "vendor_id", label: "Client ID", sortable: false },
        { key: "app_name", label: "Business Name", sortable: false },
        { key: "vendor_name", label: "Business Person Name", sortable: true },
        { key: "email", label: "Email", sortable: true},
        { key: "vendor_number", label: "Phone Number", sortable: true },
        {
          key: "status",
          label: this.trans.get("__JSON__.Status"),
          sortable: false,
        },
        {
          key: "action",
          label: this.trans.get("__JSON__.Action"),
          sortable: false,
        },
      ],
    };
  },
  mounted() {
    this.getClient();
  },
  methods: {
     getClient(page){
      this.loading = true;
      clientService.getClientListing({
        status: this.status,
        search: this.search,
        sortBy: this.sortBy,
        orderBy: this.sortDesc ? "asc" : "desc",
        subscription_due_date: this.subscription_due_date,
        page: page && page > 0 ? page : 1,
      })
        .then((response) => {
          this.loading = false;
          this.client = response.data.result.data;
          // this.paginations = response.data.result;
          this.clientCount = response.data.result.clientCount;
        });
    },
    searchData() {
        this.getClient();
    },
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      this.getClient();
    }
  },
};
</script>
<template>
 <Layout>
  <div>
   <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
        >
          <div class="page-title-left">
            <div class="avatar-xs mr-3">
              <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                <i class="bx bx-user"></i>
              </span>
            </div>
             <h4 class="mb-0 font-size-18">{{ trans.trans('__JSON__.Clients') }}({{clientCount}})</h4>
          </div>
          <div class="page-title-right">
            <router-link :to="{name: 'client-add'}" class="btn btn-success btn-rounded mr-2 add-btn">
              <i class="mdi mdi-plus mr-1"></i>
              {{ trans.get('__JSON__.Add Client') }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 list-table-main">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-3 offset-sm-5">
                <div class="form-group">
                  <div class="date-range-list">
                    <label>{{ trans.get('__JSON__.Subscription Due Date') }} :</label>
                    <select class="custom-select" v-model="subscription_due_date" @change="searchData()">
                        <option value="">All</option>
                        <option value="1">1</option>
                        <option value="15">15</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="form-group">
                  <div class="date-range-list">
                    <label>{{ trans.get('__JSON__.Status') }} :</label>
                    <select class="custom-select" v-model="status" @change="searchData()">
                        <option value="1" selected>{{ trans.get('__JSON__.Active') }}</option>
                        <option value="0">{{ trans.get('__JSON__.Inactive') }}</option>
                        <option value="2">{{ trans.get('__JSON__.Waiting For Approval') }}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="date-range-list">
                  <label>{{ trans.get('__JSON__.Search') }} :</label>
                  <div class="text-sm-right">
                    <div class="search-box mr-2 d-inline-block">
                      <div class="position-relative">
                        <input
                          type="text"
                          class="form-control"
                          id="search"
                          @input="searchData()"
                          v-model="search"
                          :placeholder="trans.get('__JSON__.Search Placeholder')"
                        />
                        <i class="bx bx-search-alt search-icon"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 table-main-list banner-table table-responsive">
                <b-table
                  :items="client.data"
                  :fields="fields"
                  :sort-by.sync="sortBy"
                  :no-local-sorting="true"
                  @sort-changed="sortingChanged"
                  :busy="loading"
                  show-empty
                >
                  <template #table-busy>
										<div class="text-center text-danger my-2">
										<b-spinner class="align-middle"></b-spinner>
										<strong>{{trans.get('__JSON__.Loading...')}}</strong>
										</div>
									</template>

                  <template v-slot:cell(subscription_date)="data">
                    {{ (client.per_page * (client.current_page-1)) + data.index + 1}}
                  </template>

                  <template v-slot:cell(status)="data">
                    <div
                      v-if="data.item.status == '1'"
                      class="badge badge-pill badge-soft-success font-size-12"
                    >{{ trans.get('__JSON__.Active') }}</div>
                    <div
                      v-else-if="data.item.status == '2'"
                      class="badge badge-pill badge-soft-warning font-size-12"
                    >{{ trans.get('__JSON__.Waiting For Approval') }}</div>
                    <div
                      v-else
                      class="badge badge-pill badge-soft-danger font-size-12"
                    >{{ trans.get('__JSON__.Inactive') }}</div>
                  </template>

                  <template v-slot:cell(action)="data">
                    <div class="vendor-btn">
                      <router-link
                        :to="{ name: 'client-edit',params: { vendor_id: base64(data.item.vendor_id) } }"
                        class="btn btn-success btn-sm btn-rounded"
                      >{{ trans.get('__JSON__.Edit') }}</router-link>
                    </div>
                  </template>

                  <template #empty>
                    <p class="text-center">{{ trans.get('__JSON__.No Client Found') }}</p>
                  </template>
                </b-table>
              </div>
              <div class="row col-sm-5 offset-sm-7">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <pagination
                        :data="client"
                        :limit="limit"
                        @pagination-change-page="getClient"
                      ></pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </div>
 </Layout>
</template>
