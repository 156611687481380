<script>
import Layout from "../../layouts/main";
import config from "../../config";
import pagination from "laravel-vue-pagination";
import moment from "moment";
import { discountService } from "../../services";
import vue2Dropzone from "vue2-dropzone";
import Multiselect from "vue-multiselect";
import PageHeader from "../../components/page-header";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { error_message, success_message } from "../../Helper/helper";
import { routes } from "../../config/api_routes";
import DropzonePopup from "../../components/widgets/dropzone-popup";
import { required, numeric, requiredIf } from "vuelidate/lib/validators";

export default {
  name: "Range",
  components: {
    Layout,
    PageHeader,
    pagination,
    DatePicker,
    discountService,
    DropzonePopup,
    moment,
    vueDropzone: vue2Dropzone,
    Multiselect,
  },
  data() {
    return {
      title: this.trans.get('__JSON__.Discount'),
      sortBy: "id",
      sortDesc: true,
      addModal: false,
      editModal: false,
      discount: [],
      paginations: {},
      limit: 2,
      search: "",
      status: "1",
      config: config,
      moment: moment,
      loading: true,
      tmpDiscountImage: "",
      showTimeRangePanel: false,
      rangeSeparator: "-",
      format: "YYYY/MM/DD HH:s",
      typeform: {
        restaurant_id: this.$route.params.restaurant_id,
        discount: "",
        maximum_discount_amount: "",
        minimum_order_amount: "",
        discount_image: "",
        description: "",
        show_display_text: "",
        show_display_text_another_lang: "",
        start_datetime: "",
        end_datetime: "",
      },
      vendordiscount: {
        id: "",
        restaurant_id: this.$route.params.restaurant_id,
        discount: "",
        maximum_discount_amount: "",
        minimum_order_amount: "",
        discount_image: "",
        description: "",
        show_display_text: "",
        show_display_text_another_lang: "",
        datetime: "",
        status: "",
      },
      discount_image: "",
      discount_image_path: "",
      discount_image_delete_url: routes.discountImageDeleteUrl,
      id: "",
      fields: [
        { key: "discount_image", label: "#", sortable: false },
        { key: "discount", label: "Discount (%)", sortable: true },
        { key: "start_datetime", label: "Start Date", sortable: true },
        { key: "end_datetime", label: "End Date", sortable: true },
        { key: "status", label: "Status", sortable: false },
        { key: "action", label: "Action", sortable: false },
      ],
    };
  },
  validations: {
    typeform: {
      discount: {
        required,
        numeric,
      },
      maximum_discount_amount: {
        required,
        numeric,
      },
      minimum_order_amount: {
        required,
        numeric,
      },
      start_datetime: {
        required,
      },
      description: {
        required,
      },
    },
    vendordiscount: {
      discount: {
        required,
        numeric,
      },
      maximum_discount_amount: {
        required,
        numeric,
      },
      minimum_order_amount: {
        required,
        numeric,
      },
      datetime: {
        required,
      },
      description: {
        required,
      },
    },
  },
  mounted() {
    this.getDiscount();
    this.getUser();
  },
  methods: {
    onDiscountImagedeleted(value) {
      this.discount_image = "";
    },
    getUser() {
      this.users = this.$auth.user;
      this.userdetail = this.$auth.setting;
    },
    toggleTimeRangePanel() {
      this.showTimeRangePanel = !this.showTimeRangePanel;
    },
    handleRangeClose() {
      this.showTimeRangePanel = false;
    },
    disabledBeforeTodayAndAfterAWeek(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0));
    },
    discountInIt() {
      this.$v.$touch();
      if (this.$v.typeform.$invalid) {
        return;
      } else {
        const fd = new FormData();
        fd.append("restaurant_id", this.typeform.restaurant_id);
        fd.append("discount", this.typeform.discount);
        fd.append(
          "maximum_discount_amount",
          this.typeform.maximum_discount_amount
        );
        fd.append("minimum_order_amount", this.typeform.minimum_order_amount);
        fd.append("discount_image", this.typeform.discount_image);
        fd.append("description", this.typeform.description);
        fd.append("start_datetime", this.typeform.start_datetime);
        fd.append("show_display_text", this.typeform.show_display_text);
        fd.append(
          "show_display_text_another_lang",
          this.typeform.show_display_text_another_lang
        );
        discountService.addDiscount(fd).then((response) => {
          this.typeform = {
            restaurant_id: this.$route.params.restaurant_id,
            discount: "",
            maximum_discount_amount: "",
            minimum_order_amount: "",
            discount_image: "",
            description: "",
            start_datetime: "",
            show_display_text: "",
            show_display_text_another_lang: "",
          };
          this.$v.typeform.$reset();
          if (response.data.code === 200) {
            success_message(response.data.message);
            this.$bvModal.hide("addModal");
            // this.$emit('category-added');
            this.getDiscount();
          } else {
            error_message(response.data.message);
          }
        });
      }
    },
    getDiscount(page) {
      this.loading = true;
      discountService
        .getDiscount({
          search: this.search,
          status: this.status,
          sortBy: this.sortBy,
          orderBy: this.sortDesc ? "desc" : "asc",
          page: page ? page : 1,
          restaurant_id: this.$route.params.restaurant_id,
        })
        .then((response) => {
          this.loading = false;
          this.paginations = response.data.result;
          this.discount = response.data.result.data;
        });
    },
    searchData() {
      this.getDiscount();
    },
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      this.getDiscount();
    },
    editDiscount(id) {
      this.fetchDiscount(id);
    },
    fetchDiscount(id) {
      return discountService.getDiscountById({ id: id }).then((response) => {
        const {
          id,
          restaurant_id,
          discount,
          maximum_discount_amount,
          minimum_order_amount,
          description,
          show_display_text,
          show_display_text_another_lang,
          datetime,
          status,
        } = response.data.result;

        this.discount_image_path = response.data.result.discount_image_path;
        this.discount_image = response.data.result.discount_image;
        this.id = response.data.result.id;

        this.vendordiscount = {
          id,
          restaurant_id,
          discount,
          maximum_discount_amount,
          minimum_order_amount,
          description,
          show_display_text,
          show_display_text_another_lang,
          datetime,
          status,
        };
        this.vendordiscount.datetime = response.data.result.datetime.split(
          " - "
        );
        this.editModal = true;
      });
    },
    updatediscount() {
      this.$v.$touch();
      if (this.$v.vendordiscount.$invalid) {
        return;
      } else {
        const fd = new FormData();
        fd.append("id", this.vendordiscount.id);
        fd.append("restaurant_id", this.vendordiscount.restaurant_id);
        fd.append("discount", this.vendordiscount.discount);
        fd.append(
          "maximum_discount_amount",
          this.vendordiscount.maximum_discount_amount
        );
        fd.append(
          "minimum_order_amount",
          this.vendordiscount.minimum_order_amount
        );
        if (!!this.vendordiscount.image) {
          fd.append("discount_image", this.vendordiscount.image);
        }
        fd.append("description", this.vendordiscount.description);
        fd.append("status", this.vendordiscount.status);
        fd.append("datetime", this.vendordiscount.datetime);
        fd.append("show_display_text", this.vendordiscount.show_display_text);
        fd.append(
          "show_display_text_another_lang",
          this.vendordiscount.show_display_text_another_lang
        );
        discountService.updateDiscountById(fd).then((response) => {
          if (response.data.code === 200) {
            success_message(response.data.message);
            this.$bvModal.hide("editModal");
            this.getDiscount();
            this.resetValidation();
          } else {
            error_message(response.data.message);
          }
        });
      }
    },
    resetValidation() {
      this.$v.$reset();
    }
  },
};
</script>

<template>
<div v-if="$auth.partner_login"> 
  <Layout>
    <PageHeader :title="title" />
      <!-- end row -->
      <div class="row">
        <div class="col-12 list-table-main">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-sm-6 offset-sm-6 category-search">
                  <div class="d-flex justify-content-between align-items-end">
                    <div class="col-sm-4">
                      <label>{{ trans.get('__JSON__.Status') }} :</label>
                      <select class="custom-select" v-model="status" @change="searchData()">
                        <option value>{{ trans.get('__JSON__.All') }}</option>
                        <option value="1" selected>{{ trans.get('__JSON__.Active') }}</option>
                        <option value="0">{{ trans.get('__JSON__.Inactive') }}</option>
                        <option value="-1">{{ trans.get('__JSON__.Expired') }}</option>
                      </select>
                    </div>
                    <div class="col-sm-4">
                      <div class="search-box d-inline-block">
                        <div class="date-range-list">
                          <label>{{ trans.get('__JSON__.Search') }} :</label>
                          <div class="position-relative">
                            <input
                              type="text"
                              class="form-control"
                              @keyup="searchData()"
                              v-model="search"
                              :placeholder="trans.get('__JSON__.Search...')"
                            />
                            <i class="bx bx-search-alt search-icon"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4 pr-0">
                      <div class="text-sm-right">
                        <button
                          type="button"
                          class="btn btn-success btn-rounded mr-2"
                          v-b-modal.addModal
                        >
                          <i class="mdi mdi-plus mr-1"></i>
                          {{ trans.get('__JSON__.Add Discount') }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 table-main-list table-responsive">
                  <b-table
                    :items="discount"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :no-local-sorting="true"
                    @sort-changed="sortingChanged"
                    :busy="loading"
                    show-empty
                  >
                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>{{trans.get('__JSON__.Loading...')}}</strong>
                      </div>
                    </template>

                    <template v-slot:cell(discount_image)="data">
                      <div v-if="data.item.discount_image">
                        <img
                          alt
                          title="product-img"
                          class="avatar-md rounded-circle-banner"
                          :src="config.discounticon+data.item.discount_image"
                          @error="$event.target.src=config.no_image"
                        />
                      </div>
                      <div v-else>
                        <img
                          class="avatar-md rounded-circle-banner"
                          :src="config.no_image"
                          alt
                        />
                      </div>
                    </template>

                    <template v-slot:cell(start_datetime)="data">
                      <div
                        v-if="data.item.start_datetime"
                      >{{moment(data.item.start_datetime).format('D MMM,Y HH:mm A')}}</div>
                    </template>

                    <template v-slot:cell(end_datetime)="data">
                      <div
                        v-if="data.item.end_datetime"
                      >{{moment(data.item.end_datetime).format('D MMM,Y HH:mm A')}}</div>
                    </template>

                    <template v-slot:cell(status)="data">
                       <div 
                        v-if="moment(data.item.end_datetime).isBefore(moment())"
                        class="badge badge-pill badge-soft-warning font-size-12"
                        >
                        {{ trans.get('__JSON__.Expired') }}
                        </div>
                        <div
                          v-else-if="data.item.status === '0'"
                          class="badge badge-pill badge-soft-danger font-size-12"
                        >
                        {{ trans.get('__JSON__.Inactive') }}
                        </div>
                        <div
                          v-else
                          class="badge badge-pill badge-soft-success font-size-12"
                        >
                        {{ trans.get('__JSON__.Active') }}
                        </div>
                    </template>

                    <template v-slot:cell(action)="data">
                      <button
                        type="button"
                        @click="editDiscount(data.item.id)"
                        class="btn btn-primary btn-sm btn-rounded"
                      >{{ trans.get('__JSON__.Edit')}}</button>
                    </template>

                    <template #empty>
                      <p class="text-center">{{ trans.get('__JSON__.No Discount Found') }}</p>
                    </template>
                  </b-table>
                </div>
                <div class="row col-sm-5 offset-sm-7">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <pagination
                          :data="paginations"
                          :limit="limit"
                          @pagination-change-page="getDiscount"
                        ></pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <b-modal
          id="addModal"
          v-model="addModal"
          scrollable
          :title="trans.get('__JSON__.Add Discount')"
          title-class="font-18"
        >
          <b-form @submit.prevent="discountInIt">
            <div class="form-group">
              <input
                id="restaurant_id"
                name="restaurant_id"
                type="hidden"
                v-model="typeform.restaurant_id"
                class="form-control"
              />
            </div>
            <div class="row">
              <div class="col-sm-12 imgUp">
                <label class="card-title">{{ trans.get('__JSON__.Discount Image') }}</label>
                <dropzone-popup
                  v-model="typeform.discount_image"
                  type="file"
                  id="discount_image"
                  name="discount_image"
                  acceptedFiles=".jpeg,.jpg,.png,.webp,image/webp"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="control-label">{{ trans.get('__JSON__.Discount') }} (%)</label>
                  <input
                    id="discount"
                    name="discount"
                    v-model="$v.typeform.discount.$model"
                    type="number"
                    @wheel="$event.target.blur()"
                    :placeholder="this.trans.get('__JSON__.Enter Discount in Percentage')"
                    class="form-control"
                    :class="{ 'is-invalid': $v.typeform.discount.$error }"
                  />
                  <div v-if="$v.typeform.discount.$error" class="invalid-feedback">
                    <span
                      v-if="!$v.typeform.discount.required"
                    >{{ trans.get('__JSON__.Please enter discount')}}.</span>
                    <span
                      v-if="!$v.typeform.discount.numeric"
                    >{{ trans.get('__JSON__.This value should be digits')}}.</span>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label
                    class="control-label"
                  >{{ trans.get('__JSON__.Minimum Item Amount') }} ({{$auth.setting.currency}})</label>
                  <input
                    id="minimum_order_amount"
                    name="minimum_order_amount"
                    type="number"
                    @wheel="$event.target.blur()"
                    v-model="$v.typeform.minimum_order_amount.$model"
                    :placeholder="this.trans.get('__JSON__.Enter Minimum Item Amount')"
                    class="form-control"
                    :class="{ 'is-invalid': $v.typeform.minimum_order_amount.$error }"
                  />
                  <div v-if="$v.typeform.minimum_order_amount.$error" class="invalid-feedback">
                    <span
                      v-if="!$v.typeform.minimum_order_amount.required"
                    >{{ trans.get('__JSON__.Please enter minimum item amount')}}.</span>
                    <span
                      v-if="!$v.typeform.minimum_order_amount.numeric"
                    >{{ trans.get('__JSON__.This value should be digits')}}.</span>
                  </div>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="form-group" v-if="$auth.setting">
                  <label
                    class="control-label"
                  >{{ trans.get('__JSON__.Maximum Discount Amount') }} ({{$auth.setting.currency}})</label>
                  <input
                    id="maximum_discount_amount"
                    name="maximum_discount_amount"
                    @wheel="$event.target.blur()"
                    v-model="$v.typeform.maximum_discount_amount.$model"
                    type="number"
                    :placeholder="this.trans.get('__JSON__.Enter Maximum Discount Amount')"
                    class="form-control"
                    :class="{ 'is-invalid': $v.typeform.maximum_discount_amount.$error }"
                  />
                  <div v-if="$v.typeform.maximum_discount_amount.$error" class="invalid-feedback">
                    <span
                      v-if="!$v.typeform.maximum_discount_amount.required"
                    >{{ trans.get('__JSON__.Please enter maximum discount amount')}}.</span>
                    <span
                      v-if="!$v.typeform.maximum_discount_amount.numeric"
                    >{{ trans.get('__JSON__.This value should be digits')}}.</span>
                  </div>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="form-group">
                  <label class="control-label">{{ trans.get('__JSON__.Discount start/end Period') }}</label>
                  <br />
                  <date-picker
                    v-model="$v.typeform.start_datetime.$model"
                    :disabled-date="disabledBeforeTodayAndAfterAWeek"
                    type="datetime"
                    value-type="format"
                    :rangeSeparator="rangeSeparator"
                    placeholder="Select datetime range"
                    range
                    :show-time-panel="showTimeRangePanel"
                    @close="handleRangeClose"
                    :class="{ 'is-invalid': $v.typeform.start_datetime.$error }"
                  >
                    <template v-slot:footer>
                      <button
                        class="mx-btn mx-btn-text"
                        @click="toggleTimeRangePanel"
                      >{{ showTimeRangePanel ? 'select date' : 'select time' }}</button>
                    </template>
                  </date-picker>
                  <div v-if="$v.typeform.start_datetime.$error" class="invalid-feedback">
                    <span
                      v-if="!$v.typeform.start_datetime.required"
                    >{{ trans.get('__JSON__.Please select date') }}.</span>
                  </div>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="form-group">
                  <label class="control-label">{{ trans.get('__JSON__.Discount Description') }}</label>
                  <textarea
                    rows="5"
                    id="description"
                    v-model="$v.typeform.description.$model"
                    type="text"
                    class="form-control"
                    :placeholder="this.trans.get('__JSON__.Enter discount Description')"
                    name="description"
                    :class="{ 'is-invalid': $v.typeform.description.$error }"
                  ></textarea>
                  <div v-if="$v.typeform.description.$error" class="invalid-feedback">
                    <span
                      v-if="!$v.typeform.description.required"
                    >{{ trans.get('__JSON__.Please enter discount description') }}.</span>
                  </div>
                </div>
              </div>
              <div class="col-sm-12" v-if="$auth.setting">
                <div class="form-group">
                  <label
                    class="control-label"
                  >{{ trans.trans('__JSON__.Display Text Language', { lang: $auth.langauges[0].name }) }}</label>
                  <textarea
                    rows="5"
                    id="show_display_text"
                    v-model="typeform.show_display_text"
                    type="text"
                    class="form-control"
                    :placeholder="trans.trans('__JSON__.Display Text Language', { lang: $auth.langauges[0].name })"
                    name="show_display_text"
                  ></textarea>
                </div>
              </div>
              <div class="col-sm-12 d-none" v-if="$auth.langauges[1].name === 'No Secondary Language'"></div>
              <div class="col-sm-12" v-else>
                <div class="form-group">
                  <label
                    class="control-label"
                  >{{ trans.trans('__JSON__.Display Text Secondary Language(Optional)', { lang: $auth.langauges[1].name }) }}</label>
                  <textarea
                    rows="5"
                    id="show_display_text_another_lang"
                    v-model="typeform.show_display_text_another_lang"
                    type="text"
                    class="form-control"
                    :placeholder="trans.trans('__JSON__.Display Text Secondary Language(Optional)', { lang: $auth.langauges[1].name })"
                    name="show_display_text_another_lang"
                  ></textarea>
                </div>
              </div>
            </div>
          </b-form>
          <template slot="modal-footer">
            <div class="w-100">
              <b-button
                variant="primary"
                @click="discountInIt"
                class="float-right"
              >{{ trans.get('__JSON__.Add Discount') }}</b-button>
              <b-button class="float-left" @click="addModal = false">{{trans.get('__JSON__.Close')}}</b-button>
            </div>
          </template>
        </b-modal>

        <!-- add modal end -->
        <!-- edit modal start -->
        <b-modal
          id="editModal"
          v-model="editModal"
          scrollable
          :title="trans.get('__JSON__.Edit Discount')"
          title-class="font-18"
        >
          <b-form @submit.prevent="updatediscount">
            <div class="form-group">
              <input id="id" name="id" type="hidden" v-model="vendordiscount.id" class="form-control" />
            </div>
            <div class="form-group">
              <input
                id="restaurant_id"
                name="restaurant_id"
                type="hidden"
                v-model="vendordiscount.restaurant_id"
                class="form-control"
              />
            </div>
            <div class="row">
              <div class="col-sm-12 imgUp">
                <label class="card-title">{{ trans.get('__JSON__.Discount Image') }}</label>
                <dropzone-popup
                  v-model="vendordiscount.image"
                  type="file"
                  id="discount_image"
                  name="discount_image"
                  acceptedFiles=".jpeg,.jpg,.png,.webp,image/webp"
                  :editable-images="discount_image"
                  :media-path="discount_image_path+''"
                  :delete-path="discount_image_delete_url"
                  @image-deleted="this.onDiscountImagedeleted"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="control-label">{{ trans.get('__JSON__.Discount') }} (%)</label>
                  <input
                    id="discount"
                    name="discount"
                    v-model="$v.vendordiscount.discount.$model"
                    type="number"
                    @wheel="$event.target.blur()"
                    :placeholder="this.trans.get('__JSON__.Enter Discount in Percentage')"
                    class="form-control"
                    :class="{ 'is-invalid': $v.vendordiscount.discount.$error }"
                  />
                  <div v-if="$v.vendordiscount.discount.$error" class="invalid-feedback">
                    <span
                      v-if="!$v.vendordiscount.discount.required"
                    >{{ trans.get('__JSON__.Please enter discount')}}.</span>
                    <span
                      v-if="!$v.vendordiscount.discount.numeric"
                    >{{ trans.get('__JSON__.This value should be digits')}}.</span>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label
                    class="control-label"
                  >{{ trans.get('__JSON__.Minimum Item Amount') }} ({{$auth.setting.currency}})</label>
                  <input
                    id="minimum_order_amount"
                    name="minimum_order_amount"
                    type="number"
                    @wheel="$event.target.blur()"
                    v-model="$v.vendordiscount.minimum_order_amount.$model"
                    :placeholder="this.trans.get('__JSON__.Enter Minimum Item Amount')"
                    class="form-control"
                    :class="{ 'is-invalid': $v.vendordiscount.minimum_order_amount.$error }"
                  />
                  <div v-if="$v.vendordiscount.minimum_order_amount.$error" class="invalid-feedback">
                    <span
                      v-if="!$v.vendordiscount.minimum_order_amount.required"
                    >{{ trans.get('__JSON__.Please enter minimum item amount')}}.</span>
                    <span
                      v-if="!$v.vendordiscount.minimum_order_amount.numeric"
                    >{{ trans.get('__JSON__.This value should be digits')}}.</span>
                  </div>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="form-group" v-if="$auth.setting">
                  <label
                    class="control-label"
                  >{{ trans.get('__JSON__.Maximum Discount Amount') }} ({{$auth.setting.currency}})</label>
                  <input
                    id="maximum_discount_amount"
                    name="maximum_discount_amount"
                    @wheel="$event.target.blur()"
                    v-model="$v.vendordiscount.maximum_discount_amount.$model"
                    type="number"
                    :placeholder="this.trans.get('__JSON__.Enter Maximum Discount Amount')"
                    class="form-control"
                    :class="{ 'is-invalid': $v.vendordiscount.maximum_discount_amount.$error }"
                  />
                  <div v-if="$v.vendordiscount.maximum_discount_amount.$error" class="invalid-feedback">
                    <span
                      v-if="!$v.vendordiscount.maximum_discount_amount.required"
                    >{{ trans.get('__JSON__.Please enter maximum discount amount')}}.</span>
                    <span
                      v-if="!$v.vendordiscount.maximum_discount_amount.numeric"
                    >{{ trans.get('__JSON__.This value should be digits')}}.</span>
                  </div>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="form-group">
                  <label class="control-label">{{ trans.get('__JSON__.Discount start/end Period') }}</label>
                  <br />
                  <date-picker
                    v-model="$v.vendordiscount.datetime.$model"
                    :disabled-date="disabledBeforeTodayAndAfterAWeek"
                    type="datetime"
                    value-type="format"
                    :rangeSeparator="rangeSeparator"
                    placeholder="Select datetime range"
                    range
                    :show-time-panel="showTimeRangePanel"
                    @close="handleRangeClose"
                    :class="{ 'is-invalid': $v.vendordiscount.datetime.$error }"
                  >
                    <template v-slot:footer>
                      <button
                        class="mx-btn mx-btn-text"
                        @click="toggleTimeRangePanel"
                      >{{ showTimeRangePanel ? 'select date' : 'select time' }}</button>
                    </template>
                  </date-picker>
                  <div v-if="$v.vendordiscount.datetime.$error" class="invalid-feedback">
                    <span
                      v-if="!$v.vendordiscount.datetime.required"
                    >{{ trans.get('__JSON__.Please select date') }}.</span>
                  </div>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="form-group">
                  <label class="control-label">{{ trans.get('__JSON__.Discount Description') }}</label>
                  <textarea
                    rows="5"
                    id="description"
                    v-model="$v.vendordiscount.description.$model"
                    type="text"
                    class="form-control"
                    :placeholder="this.trans.get('__JSON__.Enter discount Description')"
                    name="description"
                    :class="{ 'is-invalid': $v.vendordiscount.description.$error }"
                  ></textarea>
                  <div v-if="$v.vendordiscount.description.$error" class="invalid-feedback">
                    <span
                      v-if="!$v.vendordiscount.description.required"
                    >{{ trans.get('__JSON__.Please enter discount description') }}.</span>
                  </div>
                </div>
              </div>
              <div class="col-sm-12" v-if="$auth.setting">
                <div class="form-group">
                  <label
                    class="control-label"
                  >{{ trans.trans('__JSON__.Display Text Language', { lang: $auth.langauges[0].name }) }}</label>
                  <textarea
                    rows="5"
                    id="show_display_text"
                    v-model="vendordiscount.show_display_text"
                    type="text"
                    class="form-control"
                    :placeholder="trans.trans('__JSON__.Display Text Language', { lang: $auth.langauges[0].name })"
                    name="show_display_text"
                  ></textarea>
                </div>
              </div>
              <div class="col-sm-12 d-none" v-if="$auth.langauges[1].name === 'No Secondary Language'"></div>
              <div class="col-sm-12" v-else>
                <div class="form-group">
                  <label
                    class="control-label"
                  >{{ trans.trans('__JSON__.Display Text Secondary Language(Optional)', { lang: $auth.langauges[1].name }) }}</label>
                  <textarea
                    rows="5"
                    id="show_display_text_another_lang"
                    v-model="vendordiscount.show_display_text_another_lang"
                    type="text"
                    class="form-control"
                    :placeholder="trans.trans('__JSON__.Display Text Secondary Language(Optional)', { lang: $auth.langauges[1].name })"
                    name="show_display_text_another_lang"
                  ></textarea>
                </div>
              </div>
              <div class="col-md-12">
                <b-form-group
                  id="input-group-2"
                  :label="trans.get('__JSON__.Status')"
                  label-for="Status"
                >
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="swtich-data">
                        <label class="switch">
                          <input
                            type="checkbox"
                            id="togBtn"
                            class="switch-on"
                            name="status"
                            v-model="vendordiscount.status"
                            true-value="1"
                            false-value="0"
                          />
                          <div class="slider round">
                            <!--ADDED HTML -->
                            <span class="on">{{ trans.get('__JSON__.On') }}</span>
                            <span class="off">{{ trans.get('__JSON__.Off') }}</span>
                            <!--END-->
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                </b-form-group>
              </div>
            </div>
          </b-form>
          <template slot="modal-footer">
            <div class="w-100">
              <b-button
                @click="updatediscount"
                variant="primary"
                class="float-right"
              >{{ trans.get('__JSON__.Update Discount') }}</b-button>
              <b-button class="float-left" @click="editModal = false">{{trans.get('__JSON__.Close')}}</b-button>
            </div>
          </template>
        </b-modal>

        <!-- edit modal end -->
      </div>
  </Layout>
 </div>
 <div v-else>
    <div class="row">
      <div class="col-12 list-table-main">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-6 offset-sm-6 category-search">
                <div class="d-flex justify-content-between align-items-end">
                  <div class="col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>{{ trans.get('__JSON__.Status') }} :</label>
                    <select class="custom-select" v-model="status" @change="searchData()">
                      <option value>{{ trans.get('__JSON__.All') }}</option>
                      <option value="1" selected>{{ trans.get('__JSON__.Active') }}</option>
                      <option value="0">{{ trans.get('__JSON__.Inactive') }}</option>
                      <option value="-1">{{ trans.get('__JSON__.Expired') }}</option>
                    </select>
                  </div>
                  <div class="col-lg-4 col-md-8 col-sm-8 col-8">
                    <div class="search-box d-inline-block">
                      <div class="date-range-list">
                        <label>{{ trans.get('__JSON__.Search') }} :</label>
                        <div class="position-relative">
                          <input
                            type="text"
                            class="form-control"
                            @keyup="searchData()"
                            v-model="search"
                            :placeholder="trans.get('__JSON__.Search...')"
                          />
                          <i class="bx bx-search-alt search-icon"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4 col-sm-4 col-4 pr-0">
                    <div class="text-sm-right">
                      <button
                        type="button"
                        class="btn btn-success btn-rounded mr-2 add-btn"
                        v-b-modal.addModal
                      >
                        <i class="mdi mdi-plus mr-1"></i>
                        {{ trans.get('__JSON__.Add Discount') }}
                      </button>
                       <button
                        type="button"
                        class="btn btn-success btn-rounded mr-2 m-add-btn"
                        v-b-modal.addModal
                      >
                        <i class="mdi mdi-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 table-main-list table-responsive">
                <b-table
                  :items="discount"
                  :fields="fields"
                  :sort-by.sync="sortBy"
                  :no-local-sorting="true"
                  @sort-changed="sortingChanged"
                  :busy="loading"
                  show-empty
                >
                  <template #table-busy>
                    <div class="text-center text-danger my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>{{trans.get('__JSON__.Loading...')}}</strong>
                    </div>
                  </template>

                  <template v-slot:cell(discount_image)="data">
                    <div v-if="data.item.discount_image">
                      <img
                        alt
                        title="product-img"
                        class="avatar-md rounded-circle-banner"
                        :src="config.discounticon+data.item.discount_image"
                        @error="$event.target.src=config.no_image"
                      />
                    </div>
                    <div v-else>
                      <img
                        class="avatar-md rounded-circle-banner"
                        :src="config.no_image"
                        alt
                      />
                    </div>
                  </template>

                  <template v-slot:cell(start_datetime)="data">
                    <div
                      v-if="data.item.start_datetime"
                    >{{moment(data.item.start_datetime).format('D MMM,Y HH:mm A')}}</div>
                  </template>

                  <template v-slot:cell(end_datetime)="data">
                    <div
                      v-if="data.item.end_datetime"
                    >{{moment(data.item.end_datetime).format('D MMM,Y HH:mm A')}}</div>
                  </template>

                  <template v-slot:cell(status)="data">
                     <div 
                        v-if="moment(data.item.end_datetime).isBefore(moment())"
                        class="badge badge-pill badge-soft-warning font-size-12"
                        >
                        {{ trans.get('__JSON__.Expired') }}
                        </div>
                        <div
                          v-else-if="data.item.status === '0'"
                          class="badge badge-pill badge-soft-danger font-size-12"
                        >
                        {{ trans.get('__JSON__.Inactive') }}
                        </div>
                        <div
                          v-else
                          class="badge badge-pill badge-soft-success font-size-12"
                        >
                        {{ trans.get('__JSON__.Active') }}
                      </div>
                  </template>

                  <template v-slot:cell(action)="data">
                    <button
                      type="button"
                      @click="editDiscount(data.item.id)"
                      class="btn btn-primary btn-sm btn-rounded"
                    >{{ trans.get('__JSON__.Edit')}}</button>
                  </template>

                  <template #empty>
                    <p class="text-center">{{ trans.get('__JSON__.No Discount Found') }}</p>
                  </template>
                </b-table>
              </div>
              <div class="row col-sm-5 offset-sm-7">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <pagination
                        :data="paginations"
                        :limit="limit"
                        @pagination-change-page="getDiscount"
                      ></pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <b-modal
        id="addModal"
        v-model="addModal"
        scrollable
        :title="trans.get('__JSON__.Add Discount')"
        title-class="font-18"
      >
        <b-form @submit.prevent="discountInIt">
          <div class="form-group">
            <input
              id="restaurant_id"
              name="restaurant_id"
              type="hidden"
              v-model="typeform.restaurant_id"
              class="form-control"
            />
          </div>
          <div class="row">
            <div class="col-sm-12 imgUp">
              <label class="card-title">{{ trans.get('__JSON__.Discount Image') }}</label>
              <dropzone-popup
                v-model="typeform.discount_image"
                type="file"
                id="discount_image"
                name="discount_image"
                acceptedFiles=".jpeg,.jpg,.png,.webp,image/webp"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label class="control-label">{{ trans.get('__JSON__.Discount') }} (%)</label>
                <input
                  id="discount"
                  name="discount"
                  v-model="$v.typeform.discount.$model"
                  type="number"
                  @wheel="$event.target.blur()"
                  :placeholder="this.trans.get('__JSON__.Enter Discount in Percentage')"
                  class="form-control"
                  :class="{ 'is-invalid': $v.typeform.discount.$error }"
                />
                <div v-if="$v.typeform.discount.$error" class="invalid-feedback">
                  <span
                    v-if="!$v.typeform.discount.required"
                  >{{ trans.get('__JSON__.Please enter discount')}}.</span>
                  <span
                    v-if="!$v.typeform.discount.numeric"
                  >{{ trans.get('__JSON__.This value should be digits')}}.</span>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label
                  class="control-label"
                >{{ trans.get('__JSON__.Minimum Item Amount') }} ({{$auth.setting.currency}})</label>
                <input
                  id="minimum_order_amount"
                  name="minimum_order_amount"
                  type="number"
                  @wheel="$event.target.blur()"
                  v-model="$v.typeform.minimum_order_amount.$model"
                  :placeholder="this.trans.get('__JSON__.Enter Minimum Item Amount')"
                  class="form-control"
                  :class="{ 'is-invalid': $v.typeform.minimum_order_amount.$error }"
                />
                <div v-if="$v.typeform.minimum_order_amount.$error" class="invalid-feedback">
                  <span
                    v-if="!$v.typeform.minimum_order_amount.required"
                  >{{ trans.get('__JSON__.Please enter minimum item amount')}}.</span>
                  <span
                    v-if="!$v.typeform.minimum_order_amount.numeric"
                  >{{ trans.get('__JSON__.This value should be digits')}}.</span>
                </div>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="form-group" v-if="$auth.setting">
                <label
                  class="control-label"
                >{{ trans.get('__JSON__.Maximum Discount Amount') }} ({{$auth.setting.currency}})</label>
                <input
                  id="maximum_discount_amount"
                  name="maximum_discount_amount"
                  @wheel="$event.target.blur()"
                  v-model="$v.typeform.maximum_discount_amount.$model"
                  type="number"
                  :placeholder="this.trans.get('__JSON__.Enter Maximum Discount Amount')"
                  class="form-control"
                  :class="{ 'is-invalid': $v.typeform.maximum_discount_amount.$error }"
                />
                <div v-if="$v.typeform.maximum_discount_amount.$error" class="invalid-feedback">
                  <span
                    v-if="!$v.typeform.maximum_discount_amount.required"
                  >{{ trans.get('__JSON__.Please enter maximum discount amount')}}.</span>
                  <span
                    v-if="!$v.typeform.maximum_discount_amount.numeric"
                  >{{ trans.get('__JSON__.This value should be digits')}}.</span>
                </div>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="form-group">
                <label class="control-label">{{ trans.get('__JSON__.Discount start/end Period') }}</label>
                <br />
                <date-picker
                  v-model="$v.typeform.start_datetime.$model"
                  :disabled-date="disabledBeforeTodayAndAfterAWeek"
                  type="datetime"
                  value-type="format"
                  :rangeSeparator="rangeSeparator"
                  placeholder="Select datetime range"
                  range
                  :show-time-panel="showTimeRangePanel"
                  @close="handleRangeClose"
                  :class="{ 'is-invalid': $v.typeform.start_datetime.$error }"
                >
                  <template v-slot:footer>
                    <button
                      class="mx-btn mx-btn-text"
                      @click="toggleTimeRangePanel"
                    >{{ showTimeRangePanel ? 'select date' : 'select time' }}</button>
                  </template>
                </date-picker>
                <div v-if="$v.typeform.start_datetime.$error" class="invalid-feedback">
                  <span
                    v-if="!$v.typeform.start_datetime.required"
                  >{{ trans.get('__JSON__.Please select date') }}.</span>
                </div>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="form-group">
                <label class="control-label">{{ trans.get('__JSON__.Discount Description') }}</label>
                <textarea
                  rows="5"
                  id="description"
                  v-model="$v.typeform.description.$model"
                  type="text"
                  class="form-control"
                  :placeholder="this.trans.get('__JSON__.Enter discount Description')"
                  name="description"
                  :class="{ 'is-invalid': $v.typeform.description.$error }"
                ></textarea>
                <div v-if="$v.typeform.description.$error" class="invalid-feedback">
                  <span
                    v-if="!$v.typeform.description.required"
                  >{{ trans.get('__JSON__.Please enter discount description') }}.</span>
                </div>
              </div>
            </div>
            <div class="col-sm-12" v-if="$auth.setting">
              <div class="form-group">
                <label
                  class="control-label"
                >{{ trans.trans('__JSON__.Display Text Language', { lang: $auth.langauges[0].name }) }}</label>
                <textarea
                  rows="5"
                  id="show_display_text"
                  v-model="typeform.show_display_text"
                  type="text"
                  class="form-control"
                  :placeholder="trans.trans('__JSON__.Display Text Language', { lang: $auth.langauges[0].name })"
                  name="show_display_text"
                ></textarea>
              </div>
            </div>
            <div class="col-sm-12 d-none" v-if="$auth.langauges[1].name === 'No Secondary Language'"></div>
            <div class="col-sm-12" v-else>
              <div class="form-group">
                <label
                  class="control-label"
                >{{ trans.trans('__JSON__.Display Text Secondary Language(Optional)', { lang: $auth.langauges[1].name }) }}</label>
                <textarea
                  rows="5"
                  id="show_display_text_another_lang"
                  v-model="typeform.show_display_text_another_lang"
                  type="text"
                  class="form-control"
                  :placeholder="trans.trans('__JSON__.Display Text Secondary Language(Optional)', { lang: $auth.langauges[1].name })"
                  name="show_display_text_another_lang"
                ></textarea>
              </div>
            </div>
          </div>
        </b-form>
        <template slot="modal-footer">
          <div class="w-100">
            <b-button
              variant="primary"
              @click="discountInIt"
              class="float-right"
            >{{ trans.get('__JSON__.Add Discount') }}</b-button>
            <b-button class="float-left" @click="addModal = false">{{trans.get('__JSON__.Close')}}</b-button>
          </div>
        </template>
      </b-modal>

    <!-- add modal end -->
    <!-- edit modal start -->
      <b-modal
        id="editModal"
        v-model="editModal"
        scrollable
        :title="trans.get('__JSON__.Edit Discount')"
        title-class="font-18"
      >
        <b-form @submit.prevent="updatediscount">
          <div class="form-group">
            <input id="id" name="id" type="hidden" v-model="vendordiscount.id" class="form-control" />
          </div>
          <div class="form-group">
            <input
              id="restaurant_id"
              name="restaurant_id"
              type="hidden"
              v-model="vendordiscount.restaurant_id"
              class="form-control"
            />
          </div>
          <div class="row">
            <div class="col-sm-12 imgUp">
              <label class="card-title">{{ trans.get('__JSON__.Discount Image') }}</label>
              <dropzone-popup
                v-model="vendordiscount.image"
                type="file"
                id="discount_image"
                name="discount_image"
                acceptedFiles=".jpeg,.jpg,.png,.webp,image/webp"
                :editable-images="discount_image"
                :media-path="discount_image_path+''"
                :delete-path="discount_image_delete_url"
                @image-deleted="this.onDiscountImagedeleted"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label class="control-label">{{ trans.get('__JSON__.Discount') }} (%)</label>
                <input
                  id="discount"
                  name="discount"
                  v-model="$v.vendordiscount.discount.$model"
                  type="number"
                  @wheel="$event.target.blur()"
                  :placeholder="this.trans.get('__JSON__.Enter Discount in Percentage')"
                  class="form-control"
                  :class="{ 'is-invalid': $v.vendordiscount.discount.$error }"
                />
                <div v-if="$v.vendordiscount.discount.$error" class="invalid-feedback">
                  <span
                    v-if="!$v.vendordiscount.discount.required"
                  >{{ trans.get('__JSON__.Please enter discount')}}.</span>
                  <span
                    v-if="!$v.vendordiscount.discount.numeric"
                  >{{ trans.get('__JSON__.This value should be digits')}}.</span>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label
                  class="control-label"
                >{{ trans.get('__JSON__.Minimum Item Amount') }} ({{$auth.setting.currency}})</label>
                <input
                  id="minimum_order_amount"
                  name="minimum_order_amount"
                  type="number"
                  @wheel="$event.target.blur()"
                  v-model="$v.vendordiscount.minimum_order_amount.$model"
                  :placeholder="this.trans.get('__JSON__.Enter Minimum Item Amount')"
                  class="form-control"
                  :class="{ 'is-invalid': $v.vendordiscount.minimum_order_amount.$error }"
                />
                <div v-if="$v.vendordiscount.minimum_order_amount.$error" class="invalid-feedback">
                  <span
                    v-if="!$v.vendordiscount.minimum_order_amount.required"
                  >{{ trans.get('__JSON__.Please enter minimum item amount')}}.</span>
                  <span
                    v-if="!$v.vendordiscount.minimum_order_amount.numeric"
                  >{{ trans.get('__JSON__.This value should be digits')}}.</span>
                </div>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="form-group" v-if="$auth.setting">
                <label
                  class="control-label"
                >{{ trans.get('__JSON__.Maximum Discount Amount') }} ({{$auth.setting.currency}})</label>
                <input
                  id="maximum_discount_amount"
                  name="maximum_discount_amount"
                  @wheel="$event.target.blur()"
                  v-model="$v.vendordiscount.maximum_discount_amount.$model"
                  type="number"
                  :placeholder="this.trans.get('__JSON__.Enter Maximum Discount Amount')"
                  class="form-control"
                  :class="{ 'is-invalid': $v.vendordiscount.maximum_discount_amount.$error }"
                />
                <div v-if="$v.vendordiscount.maximum_discount_amount.$error" class="invalid-feedback">
                  <span
                    v-if="!$v.vendordiscount.maximum_discount_amount.required"
                  >{{ trans.get('__JSON__.Please enter maximum discount amount')}}.</span>
                  <span
                    v-if="!$v.vendordiscount.maximum_discount_amount.numeric"
                  >{{ trans.get('__JSON__.This value should be digits')}}.</span>
                </div>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="form-group">
                <label class="control-label">{{ trans.get('__JSON__.Discount start/end Period') }}</label>
                <br />
                <date-picker
                  v-model="$v.vendordiscount.datetime.$model"
                  :disabled-date="disabledBeforeTodayAndAfterAWeek"
                  type="datetime"
                  value-type="format"
                  :rangeSeparator="rangeSeparator"
                  placeholder="Select datetime range"
                  range
                  :show-time-panel="showTimeRangePanel"
                  @close="handleRangeClose"
                  :class="{ 'is-invalid': $v.vendordiscount.datetime.$error }"
                >
                  <template v-slot:footer>
                    <button
                      class="mx-btn mx-btn-text"
                      @click="toggleTimeRangePanel"
                    >{{ showTimeRangePanel ? 'select date' : 'select time' }}</button>
                  </template>
                </date-picker>
                <div v-if="$v.vendordiscount.datetime.$error" class="invalid-feedback">
                  <span
                    v-if="!$v.vendordiscount.datetime.required"
                  >{{ trans.get('__JSON__.Please select date') }}.</span>
                </div>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="form-group">
                <label class="control-label">{{ trans.get('__JSON__.Discount Description') }}</label>
                <textarea
                  rows="5"
                  id="description"
                  v-model="$v.vendordiscount.description.$model"
                  type="text"
                  class="form-control"
                  :placeholder="this.trans.get('__JSON__.Enter discount Description')"
                  name="description"
                  :class="{ 'is-invalid': $v.vendordiscount.description.$error }"
                ></textarea>
                <div v-if="$v.vendordiscount.description.$error" class="invalid-feedback">
                  <span
                    v-if="!$v.vendordiscount.description.required"
                  >{{ trans.get('__JSON__.Please enter discount description') }}.</span>
                </div>
              </div>
            </div>
            <div class="col-sm-12" v-if="$auth.setting">
              <div class="form-group">
                <label
                  class="control-label"
                >{{ trans.trans('__JSON__.Display Text Language', { lang: $auth.langauges[0].name }) }}</label>
                <textarea
                  rows="5"
                  id="show_display_text"
                  v-model="vendordiscount.show_display_text"
                  type="text"
                  class="form-control"
                  :placeholder="trans.trans('__JSON__.Display Text Language', { lang: $auth.langauges[0].name })"
                  name="show_display_text"
                ></textarea>
              </div>
            </div>
            <div class="col-sm-12 d-none" v-if="$auth.langauges[1].name === 'No Secondary Language'"></div>
            <div class="col-sm-12" v-else>
              <div class="form-group">
                <label
                  class="control-label"
                >{{ trans.trans('__JSON__.Display Text Secondary Language(Optional)', { lang: $auth.langauges[1].name }) }}</label>
                <textarea
                  rows="5"
                  id="show_display_text_another_lang"
                  v-model="vendordiscount.show_display_text_another_lang"
                  type="text"
                  class="form-control"
                  :placeholder="trans.trans('__JSON__.Display Text Secondary Language(Optional)', { lang: $auth.langauges[1].name })"
                  name="show_display_text_another_lang"
                ></textarea>
              </div>
            </div>
            <div class="col-md-12">
              <b-form-group
                id="input-group-2"
                :label="trans.get('__JSON__.Status')"
                label-for="Status"
              >
                <div class="row">
                  <div class="col-sm-6">
                    <div class="swtich-data">
                      <label class="switch">
                        <input
                          type="checkbox"
                          id="togBtn"
                          class="switch-on"
                          name="status"
                          v-model="vendordiscount.status"
                          true-value="1"
                          false-value="0"
                        />
                        <div class="slider round">
                          <!--ADDED HTML -->
                          <span class="on">{{ trans.get('__JSON__.On') }}</span>
                          <span class="off">{{ trans.get('__JSON__.Off') }}</span>
                          <!--END-->
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </b-form-group>
            </div>
          </div>
        </b-form>
        <template slot="modal-footer">
          <div class="w-100">
            <b-button
              @click="updatediscount"
              variant="primary"
              class="float-right"
            >{{ trans.get('__JSON__.Update Discount') }}</b-button>
            <b-button class="float-left" @click="editModal = false">{{trans.get('__JSON__.Close')}}</b-button>
          </div>
        </template>
      </b-modal>

    <!-- edit modal end -->
    </div>
 </div>
      <!-- end row -->
</template>

<style>
</style>