<script>
import { vendorsSettlement } from './vendor-settlement'
/**
 * Starter component
 */
export default {
    data() {
        return {
            vendorsSettlement: vendorsSettlement,
            showModal: false
        };
    }
};
</script>
<template>
 <div class="table-responsive mt-4 table-border vendor-settlement">
    <table class="table table-centered table-nowrap">
      <thead>
        <tr>
          <th>Duration</th>
          <th>Order Counts</th>
          <th>Total Order Cost</th>
          <th>Admin Comission</th>
          <th>Amount</th>
          <th>Status</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody class="bold-text">
        <tr v-for="data in vendorsSettlement" :key="data.id">
          <td>{{data.duration}}</td>
          <td>{{data.count}}</td>
          <td>{{data.total}}</td>
          <td>{{data.admin}}</td>
          <td>{{data.vendor}}</td>
          <td>
            <span
                class="badge badge-pill badge-soft-success font-size-12"
                :class=" { 'badge-soft-success': `${data.status}` === 'Paid',
                          'badge-soft-danger': `${data.status}` === 'Pending' }"
              >{{data.status}}</span>
          </td>
          <td>
            <b-dropdown class="card-drop payment-info" variant="white" right toggle-class="p-0">
              <template v-slot:button-content>
                <i class="mdi mdi-dots-horizontal font-size-18"></i>
              </template>

              <b-dropdown-item @click="showModal = true">
                <i class="fas fa-money-check-alt text-success"></i> Payment Info
              </b-dropdown-item>

              <b-dropdown-item>
                <i class="bx bx-show-alt text-danger"></i> View
              </b-dropdown-item>
            </b-dropdown>
          </td>
        </tr>
      </tbody>
     </table>
     <b-modal v-model="showModal" title="Payment Info" hide-footer centered>
      <p class="mb-2">
        Date Of Payment:
        <span class="text-primary">2020-09-02</span>
      </p>
      <p class="mt-4">
        Payment Mode:
        <span class="text-primary">Cheque</span>
      </p>
      <p class="mt-4">
        Transaction Id / Cheque No:
        <span class="text-primary">2151458</span>
      </p>
      <p class="mt-4">
        Notes:
        <span class="text-primary">under process</span>
      </p>
    </b-modal>
  </div>
</template>