<script>
import { required, requiredIf } from "vuelidate/lib/validators";
import { defaultSettingService, businessTypeService } from "../../../services";
import config from "../../../config";
import { success_message, error_message } from "../../../Helper/helper";

/**
 * Starter component
 */
export default {
  data() {
    return {
      config: config,
      id: "",
      app_setting_id: "",
      setting: [],
      setting: {
        master_menu_category: "",
      },
    };
  },
  validations: {
    setting: {
      master_menu_category: {
        required: requiredIf(function (form) {
          return !this.setting.master_menu_category;
        }),
      },
    },
  },
  mounted() {
    this.getBusinessTypeById();
  },
  methods: {
    getBusinessTypeById() {
      businessTypeService
        .getBusinessTypeById({ id: this.$route.params.id })
        .then((response) => {
          const { master_menu_category } = response.data.result.settings;
          this.id = response.data.result.business.id;
          this.app_setting_id = response.data.result.business.app_setting_id;
          this.setting = {
            master_menu_category: master_menu_category || "1",
          };
        });
    },
    //  getAppSetting(){
    //   settingService.getAppSettingApi().then((response) => {
    //     const {
    //         master_menu_category
    //     } = response.data.result.settings;
    //         this.setting = {
    //            master_menu_category: master_menu_category || '1',
    //         };
    //   })
    // },
    generalIt() {
      this.$v.setting.$touch();
      if (this.$v.setting.$invalid) {
        return;
      } else {
        const fd = new FormData();
        fd.append("setting_id",this.id);
        fd.append("app_setting_id",this.app_setting_id);
        fd.append("master_menu_category", this.setting.master_menu_category);
        fd.append("type", 10);
        defaultSettingService.updateDefaultSetting(fd).then((response) => {
          if (response.data.code === 200) {
            success_message(response.data.message);
          } else {
            error_message(response.data.message);
          }
        });
      }
    },
  },
};
</script>
<template>
  <b-form @submit.prevent="generalIt">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label class="control-label">{{
                    trans.get("__JSON__.Category Page Layout")
                  }}</label>
                  <select
                    class="custom-select"
                    id="master_menu_category"
                    name="master_menu_category"
                    v-model="setting.master_menu_category"
                  >
                    <option v-bind:value="1" selected>
                      {{ trans.get("__JSON__.Layout 1") }}
                    </option>
                    <option v-bind:value="2">
                      {{ trans.get("__JSON__.Layout 2") }}
                    </option>
                    <option v-bind:value="3">
                      {{ trans.get("__JSON__.Layout 3") }}
                    </option>
                    <option v-bind:value="4">
                      {{ trans.get("__JSON__.Layout 4") }}
                    </option>
                    <option v-bind:value="5">
                      {{ trans.get("__JSON__.Layout 5") }}
                    </option>
                    <option v-bind:value="6">
                      {{ trans.get("__JSON__.Layout 6") }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-6 offset-sm-3">
                <div class="form-group">
                  <label class="control-label">{{
                    trans.get("__JSON__.Preview")
                  }}</label>
                  <div
                    v-if="setting.master_menu_category == '1'"
                    class="home-layout"
                  >
                    <img :src="config.categorypageimage + '1.png'" />
                  </div>
                  <div
                    v-else-if="setting.master_menu_category == '2'"
                    class="home-layout"
                  >
                    <img :src="config.categorypageimage + '2.png'" />
                  </div>
                  <div
                    v-else-if="setting.master_menu_category == '3'"
                    class="home-layout"
                  >
                    <img :src="config.categorypageimage + '3.png'" />
                  </div>
                  <div
                    v-else-if="setting.master_menu_category == '4'"
                    class="home-layout"
                  >
                    <img :src="config.categorypageimage + '4.png'" />
                  </div>
                  <div
                    v-else-if="setting.master_menu_category == '5'"
                    class="home-layout"
                  >
                    <img :src="config.categorypageimage + '5.png'" />
                  </div>
                  <div
                    v-else-if="setting.master_menu_category == '6'"
                    class="home-layout"
                  >
                    <img :src="config.categorypageimage + '6.png'" />
                  </div>
                </div>
              </div>
            </div>

            <div class="btn-update">
              <div class="col-sm-6">
                <button
                  type="submit"
                  class="btn btn-primary mr-1 mt-3"
                >
                  {{ trans.get("__JSON__.Update") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-form>
</template>