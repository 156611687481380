import { routes } from '../config/api_routes'

export const deliveryZoneService = {
    
    getDeliveryZone: (formData) => window.axios.post(routes.getDeliveryZoneApi,formData),

    getVendorCountry: () => window.axios.get(routes.getVendorCountryApi),

    getVendorsCountry: (formData) => window.axios.post(routes.getVendorsCountryApi,formData),

    getVendorState: (formData) => window.axios.post(routes.getVendorStateApi,formData),

    getVendorStates: (formData) => window.axios.post(routes.getVendorStatesApi,formData),

    getVendorCity: (formData) => window.axios.post(routes.getVendorCityApi,formData),

    getVendorsCity: (formData) => window.axios.post(routes.getVendorsCityApi,formData),

    getAllState: () => window.axios.get(routes.getAllStateApi),

    getAllCity: () => window.axios.get(routes.getAllCityApi),

    addDeliveryZone: (formData) => window.axios.post(routes.addDeliveryZoneApi,formData),

    getDeliveryInfo: (formData) => window.axios.post(routes.getDeliveryInfoApi,formData),

    editDeliveryZone: (formData) => window.axios.post(routes.editDeliveryZoneApi,formData),

    zoneExportCsv: (formData) => window.axios.post(routes.zoneExportCsvApi, formData),

    getZoneRestaurant: (formData) => window.axios.post(routes.getZoneRestaurantApi, formData),

    deleteFranchiseRestById: (formData) => window.axios.post(routes.deleteFranchiseRestByIdApi, formData),

    addDeliveryZoneArea: (formData) => window.axios.post(routes.addDeliveryZoneAreaApi, formData),
    
    deleteRestAreaId: (formData) => window.axios.post(routes.deleteRestAreaIdApi, formData),

    editDeliveryZoneArea: (formData) => window.axios.post(routes.editDeliveryZoneAreaApi, formData),

    getRestAreaById: (formData) => window.axios.post(routes.getRestAreaByIdApi, formData),

    getSearchRest: (formData) => window.axios.post(routes.getSearchRestApi, formData),

    addFranchiseRest: (formData) => window.axios.post(routes.addFranchiseRestApi, formData),
}