import Vue from "vue";
import Router from "vue-router";
import VueSocketIO from "vue-socket.io";
import Login from "../views/account/login.vue";
import ForgotPassword from "../views/account/forgot-password.vue";
import Dashboard from "../views/dashboard.vue";
import OrderList from "../views/orders/list.vue";
import Order from "../views/orders/main.vue";
import VendorList from "../views/restaurant/list.vue";
import Vendorstoreslocation from "../views/restaurant/Vendorstores-location.vue"
import VendorAdd from "../views/restaurant/add.vue";
import VendorDetails from "../views/restaurant/details.vue";
import CustomerList from "../views/customer/list.vue";
import CustomerDetails from "../views/customer/details.vue";
import DriverList from "../views/driver/list";
import DriverAdd from "../views/driver/add";
import DriverDetails from "../views/driver/details";
import CountryList from "../views/location/list";
import StateList from "../views/location/state-list";
import CityList from "../views/location/city-list";
import DeliveryList from "../views/delivery_zone/list";
import DeliveryAdd from "../views/delivery_zone/add";
import DeliveryEdit from "../views/delivery_zone/edit";
import AffiliateList from "../views/affiliate/list.vue";

// driver signup
import DriverSignup from "../views/driver/signup.vue";
// driver signup
import DeliveryZoneDetails from "../views/delivery_zone/details";
import DeliveryAddArea from "../views/delivery_zone/add-area";
import DeliveryEditArea from "../views/delivery_zone/edit-area";
import CouponList from "../views/coupon/list";
import CouponAdd from "../views/coupon/add";
import CouponEdit from "../views/coupon/edit";
import BannerList from "../views/banner/list";
import BannerAdd from "../views/banner/add";
import BannerEdit from "../views/banner/edit";
import CategoryList from "../views/category/list";
import CategoryAdd from "../views/category/add";
import CategoryEdit from "../views/category/edit";
import FaqList from "../views/faq/list";
import FaqAdd from "../views/faq/add";
import FaqEdit from "../views/faq/edit";
import SystemList from "../views/system/list";
import CuisinesList from "../views/cuisines/list";
import CuisinesAdd from "../views/cuisines/add";
import CuisinesEdit from "../views/cuisines/edit";
import Configuration from "../views/settings/configurations";
import Themes from "../views/settings/themes";
import EagleView from "../views/eagle-view/list";
import ContentPage from "../views/content-page/list";
import Reports from "../views/reports/list";
import Terminology from "../views/settings/terminology";
import Invoice from "../views/orders/invoice";
import VendorFinance from "../views/finance/vendor";
import VendorFinanceMonth from "../views/finance/vendor-finance-month";
import VendorFinanceList from "../views/finance/vendor-finance-list";
import RestaurantFinance from "../views/finance/restaurant/restaurant-finance";
import RestaurantFinanceTransaction from "../views/finance/restaurant/restaurant-transaction";
import DriverDashboard from "../views/driver/dashboard";
import DriverFinance from "../views/finance/driver";
import DriverFinanceDetail from "../views/finance/driver/driver-finance-detail";
import DriverFinanceMonth from "../views/finance/driver/driver-finance-month";
import DriverTransaction from "../views/finance/driver/driver-transaction";
import Profile from "../views/profile/subscription_info";
import UserAccess from "../views/settings/user-access/user-list";
import UserAccessAdd from "../views/settings/user-access/add-user";
import UserAccessEdit from "../views/settings/user-access/edit-user";
import UserAccessPermission from "../views/settings/user-access/user-permission";
import AddBlog from "../views/settings/blogs/blog.vue";
import BlogList from "../views/settings/blogs/blog-list.vue";
import BlogEdit from "../views/settings/blogs/blog-edit.vue";

import Catalog from "../views/restaurant/catalog.vue";
import ChangePassword from "../views/profile/change-password.vue";
import DiscountList from "../views/discount/discount-list.vue";
import DiscountAdd from "../views/discount/add.vue";
import DiscountEdit from "../views/discount/edit.vue";
import BusinessProfile from "../views/profile/business-profile.vue";
import SubVendorProfile from "../views/restaurant/profile";
import TimeSlot from "../views/timeslot/timeslots.vue";
import SellingTimeSlot from "../views/timeslot/selling-timeslot.vue";
import Reviews from "../views/reviews/list.vue";
import Category from "../views/restaurant/category.vue";
import SalesReport from "../views/reports/sales_report.vue";
import SummaryReport from "../views/reports/summary_report.vue";
import PharmacyCustomerReport from "../views/reports/pharmacy_customer_report.vue";
import ProductReport from "../views/reports/product_report";
import StoreGstReport from "../views/reports/store_gst_report";
import StoreWiseGstReport from "../views/reports/store_wise_gst_report";
import ProductMrpUpdateReport from "../views/reports/product_mrp_update_report";
import WalletReport from "../views/reports/wallet_report.vue";
import ItemReport from "../views/reports/item_report.vue";
import CustomerReport from "../views/reports/customer_report.vue";
import TotalSaleEarning from "../views/reports/total_sales_earning.vue";
import TaxReport from "../views/reports/tax_report.vue";
import DriverReport from "../views/reports/driver_report";
import InactiveCustomerReport from "../views/reports/inactive_customer_report";
import CODOnlineOrderReport from "../views/reports/cod_online_order_report";
import AvgRatingReport from "../views/reports/avg_rating_report";
import PromoCodeReport from "../views/reports/promo_code_report";
import DriverReportDetailsReport from "../views/reports/driver_report_details";
import AppLayoutSetting from "../views/settings/app-layout-setting";
import WebLayoutSetting from "../views/settings/web-layout-setting";
import AddCustomization from "../views/restaurant/add-customization";
import AddSubCustomization from "../views/restaurant/add-sub-customization";
import AddContentPage from "../views/content-page/add";
import EditContentPage from "../views/content-page/edit";
import ViewContentPage from "../views/content-page/view-page";
import config from "../config";
import { orderService } from "../services";
import OperationTime from "../views/restaurant/operation-time";
import VendorCategory from "../views/restaurant/vendor-category";
import VendorDiscount from "../views/restaurant/vendor-discount";
import VendorPage from "../views/restaurant/terms-condition";
import VendorProfile from "../views/restaurant/vendor-profile";
import VendorRating from "../views/restaurant/vendor-rating";
import Signup from "../views/account/signup.vue";
import HelpList from "../views/help/list";
import { menuItems } from "../components/menu";
import WhatsNew from "../views/whatsnew/whats-new";
import { error_message } from "../Helper/helper";
import SubVendorSignup from "../views/account/sub-vendor-signup.vue";
import ClientList from "../views/client/list";
import ClientAdd from "../views/client/add";
import ClientEdit from "../views/client/edit";
import CurrencyList from "../views/currency/list";
import ChangeLog from "../views/change-log";
import VendorRatingReviewReport from "../views/reports/vendor_rating_review_report";
import DriverRatingReviewReport from "../views/reports/driver_rating_review_report";
import DriverSettlementReport from "../views/reports/driver_settlement_report";
import CallCenterDashboard from "../views/call-center/dashboard";
import Analytics from "../views/analytics/analytics";
import RestaurantQRFeedbackReport from "../views/reports/feedback_report";
import BusinessTypeList from "../views/business_type/list";
import BusinessTypeEdit from "../views/business_type/edit";
import MasterDepotCatalog from "../views/master_depot/depot_catalog";
import MasterDepotAddCustomization from "../views/master_depot/add-customization";
import MasterDepotAddSubCustomization from "../views/master_depot/add-sub-customization";
import AffiliateDetails from "../views/affiliate/details";
import AffiliateAdd from "../views/affiliate/add";
import SettlementReport from "../views/reports/settlement_report";
import SettlementReportDetails from "../views/reports/settlement_details_report";
import AllStoreSettlementReportDetails from "../views/reports/all_stores_settlement_report";
import StorewiseProductDetailsReport from "../views/reports/storewise_product_details_report";
import whatsappMessageSentReport from "../views/reports/whatsapp_message_report";
import idporderreport from "../views/reports/idp_order_report";
import dynamicmenuapp from "../views/settings/dynamicmenuapp/dynamicmenuapp";
import profilemenuedit from "../views/settings/dynamicmenuapp/profilemenuedit";
import RequestPrice from "../views/customer/request-price.vue";
import BankList from "../views/restaurant/bank-list.vue";
import SettlementList from "../views/restaurant/settlement-list.vue";
import BrandList from "./brands/brand-list.vue";
import BrandAdd from "./brands/add.vue";
import BrandEdit from "./brands/edit.vue";

Vue.use(Router);

const urls = menuItems.reduce(
    (m, i) => (i.subItems ? m.concat(i.subItems) : m),
    []
);

const base = config.base_folder;

const vendorRoutes = [
    {
        path: base + "login",
        name: "login",
        component: Login
    },
    {
        path: base + "signup",
        name: "signup",
        component: Signup
    },
    {
        path: base + "dashboard",
        name: "dashboard",
        component: Dashboard
    },
    {
        path: base + "orders/list",
        name: "orders-list",
        component: Order
    },
    {
        path: base + "call-center/dashboard",
        name: "call-center-dashboard",
        component: CallCenterDashboard
    },
    {
        path: base + "vendors",
        name: "vendor-list",
        component: VendorList
    },
    {
        path: base + "vendors-stores-location",
        name: "vendors-stores-location",
        component: Vendorstoreslocation
    },
    {
        path: base + "vendors/add",
        name: "vendor-add",
        component: VendorAdd
    },
    {
        path: base + "vendors/details/:restaurant_id",
        name: "vendor-detail",
        component: VendorDetails
    },
    {
        path: base + "vendors/catalog/:restaurant_id",
        name: "vendor-catalog",
        component: Catalog
    },
    {
        path: base + "vendors/add-customization/:menu_item_id",
        name: "vendor-add-customization",
        component: AddCustomization
    },
    {
        path: base + "vendors/add-sub-customization/:menu_item_selection_id",
        name: "vendor-add-sub-customization",
        component: AddSubCustomization
    },
    {
        path: base + "customer",
        name: "customer-list",
        component: CustomerList
    },
    {
        path: base + "customer/details/:user_id",
        name: "customer-detail",
        component: CustomerDetails
    },
    {
        path: base + "driver/:vendor_id/signup",
        name: "driver-signup",
        component: DriverSignup
    },
    {
        path: base + "driver/dashboard",
        name: "driver-dashboard",
        component: DriverDashboard
    },
    {
        path: base + "driver",
        name: "driver-list",
        component: DriverList
    },
    {
        path: base + "driver/add",
        name: "driver-add",
        component: DriverAdd
    },
    {
        path: base + "driver/details/:driver_id",
        name: "driver-details",
        component: DriverDetails
    },
    {
        path: base + "location",
        name: "country-list",
        component: CountryList
    },
    {
        path: base + "location/state-list/:country_id",
        name: "state-list",
        component: StateList
    },
    {
        path: base + "location/city-list/:state_id",
        name: "city-list",
        component: CityList
    },
    {
        path: base + "delivery_zone",
        name: "list",
        component: DeliveryList
    },
    {
        path: base + "delivery_zone/add",
        name: "delivery-zone-add",
        component: DeliveryAdd
    },
    {
        path: base + "delivery_zone/edit/:user_id",
        name: "delivery-zone-edit",
        component: DeliveryEdit
    },
    {
        path: base + "delivery_zone/details/:user_id",
        name: "delivery-zone-details",
        component: DeliveryZoneDetails
    },
    {
        path: base + "delivery_zone/add-area/:frenchiese_id",
        name: "add-area",
        component: DeliveryAddArea
    },
    {
        path: base + "delivery_zone/edit-area/:area_id",
        name: "edit-area",
        component: DeliveryEditArea
    },
    {
        path: base + "coupon",
        name: "coupon-list",
        component: CouponList
    },
    {
        path: base + "coupon/add",
        name: "coupon-add",
        component: CouponAdd
    },
    {
        path: base + "coupon/edit/:id",
        name: "coupon-edit",
        component: CouponEdit
    },
    {
        path: base + "banner",
        name: "banner-list",
        component: BannerList
    },
    {
        path: base + "banner/add",
        name: "banner-add",
        component: BannerAdd
    },
    {
        path: base + "banner/edit/:id",
        name: "banner-edit",
        component: BannerEdit
    },
    {
        path: base + "finance/driver",
        name: "driver",
        component: DriverFinance
    },
    {
        path: base + "finance/driver/month/:month",
        name: "driver-finance-month",
        component: DriverFinanceMonth
    },
    {
        path: base + "finance/driver/month/:month/:driverid/dashboard",
        name: "driver-finance",
        component: DriverFinanceDetail
    },
    {
        path: base + "finance/driver/:driverid/transactions",
        name: "driver-finance-transactions",
        component: DriverTransaction
    },
    {
        path: base + "finance/vendor",
        name: "vendor",
        component: VendorFinance
    },
    {
        path: base + "finance/vendor/month/:month",
        name: "vendor-finance-month",
        component: VendorFinanceMonth
    },
    {
        path: base + "finance/vendor-finance-list",
        name: "vendor-finance-list",
        component: VendorFinanceList
    },
    {
        path: base + "finance/vendor/month/:month/:id/dashboard",
        name: "restaurant-finance-list",
        component: RestaurantFinance
    },
    {
        path: base + "finance/vendor/:id/transactions",
        name: "restaurant-transaction-list",
        component: RestaurantFinanceTransaction
    },
    {
        path: base + "category",
        name: "category-list",
        component: CategoryList
    },
    {
        path: base + "category/add",
        name: "category-add",
        component: CategoryAdd
    },
    {
        path: base + "category/edit/:category_id",
        name: "category-edit",
        component: CategoryEdit
    },
    {
        path: base + "faq",
        name: "faq-list",
        component: FaqList
    },
    {
        path: base + "faq/add",
        name: "faq-add",
        component: FaqAdd
    },
    {
        path: base + "faq/edit/:id",
        name: "faq-edit",
        component: FaqEdit
    },
    {
        path: base + "system/list",
        name: "system-list",
        component: SystemList
    },
    {
        path: base + "cuisines",
        name: "cuisines-list",
        component: CuisinesList
    },
    {
        path: base + "cuisines/add",
        name: "cuisines-add",
        component: CuisinesAdd
    },
    {
        path: base + "cuisines/edit/:cuisine_id",
        name: "cuisines-edit",
        component: CuisinesEdit
    },
    {
        path: base + "settings/configurations",
        name: "configurations",
        component: Configuration
    },
    {
        path: base + "settings/app-layout-setting",
        name: "app-layout-setting",
        component: AppLayoutSetting
    },
    {
        path: base + "settings/web-layout-setting",
        name: "web-layout-setting",
        component: WebLayoutSetting
    },
    {
        path: base + "settings/themes",
        name: "themes",
        component: Themes
    },
    {
        path: base + "eagle-view/list",
        name: "eagle-list",
        component: EagleView
    },
    {
        path: base + "content-page",
        name: "content-list",
        component: ContentPage
    },
    {
        path: base + "reports",
        name: "reports-list",
        component: Reports
    },
    {
        path: base + "reports/sales_report",
        name: "reports.sale.report",
        component: SalesReport
    },
    {
        path: base + "reports/summary_report",
        name: "reports.summary.report",
        component: SummaryReport
    },
    {
        path: base + "reports/pharmacy_customer_report",
        name: "reports.pharmacy.customer.report",
        component: PharmacyCustomerReport
    },
    {
        path: base + "reports/product_report",
        name: "reports.product.report",
        component: ProductReport
    },
    {
        path: base + "reports/store_gst_report",
        name: "reports.store.gst.report",
        component: StoreGstReport
    },
    {
        path: base + "reports/store_wise_gst_report/:restaurantID",
        name: "reports.store.wise.gst.report",
        component: StoreWiseGstReport
    },
    {
        path: base + "reports/product_mrp_update_report",
        name: "reports.product.mrp.update.report",
        component: ProductMrpUpdateReport
    },
    {
        path: base + "reports/customer_report",
        name: "reports.customer.report",
        component: CustomerReport
    },
    {
        path: base + "reports/total_sales_earning",
        name: "reports.total.earning",
        component: TotalSaleEarning
    },
    {
        path: base + "reports/tax_report",
        name: "reports.tax.report",
        component: TaxReport
    },
    {
        path: base + "reports/driver_report",
        name: "reports.driver.report",
        component: DriverReport
    },
    {
        path: base + "reports/inactive_customer_report",
        name: "reports.inactive.customer.report",
        component: InactiveCustomerReport
    },
    {
        path: base + "reports/cod_online_order_report",
        name: "reports.cod.online.order.report",
        component: CODOnlineOrderReport
    },
    {
        path: base + "reports/avg_rating_report",
        name: "reports.avg.rating.report",
        component: AvgRatingReport
    },
    {
        path: base + "reports/promo_code_report",
        name: "reports.promo.code.report",
        component: PromoCodeReport
    },
    {
        path: base + "reports/driver_report_details",
        name: "driver.reports.details",
        component: DriverReportDetailsReport
    },
    {
        path: base + "reports/wallet_report",
        name: "report.wallet",
        component: WalletReport
    },
    {
        path: base + "settings/terminology",
        name: "terminology-list",
        component: Terminology
    },
    {
        path: base + "settings/profilemenu",
        name: "profilemenu",
        component: dynamicmenuapp
    },
    {
        path: base + "settings/profilemenuedit/:id",
        name: "profilemenuedit",
        component: profilemenuedit
    },
    {
        path: base + "profile/subscription_info",
        name: "subscription-info",
        component: Profile,
        access: true
    },
    {
        path: base + "profile/change-password",
        name: "change-password",
        component: ChangePassword,
        access: true
    },
    {
        path: base + "profile/business-profile",
        name: "business-profile",
        component: BusinessProfile,
        access: true
    },
    {
        path: base + "settings/user-access",
        name: "user-access",
        component: UserAccess,
        access: true
    },
    {
        path: base + "settings/user-access/add",
        name: "user-access-add",
        component: UserAccessAdd
    },
    {
        path: base + "settings/user-access/:id/edit",
        name: "user-access-edit",
        component: UserAccessEdit
    },
    {
        path: base + "settings/user-access/:id/permissions",
        name: "user-access.permissions",
        component: UserAccessPermission
    },
    {
        path: base + "content-page/add",
        name: "content-page-add",
        component: AddContentPage
    },
    {
        path: base + "content-page/edit/:id",
        name: "content-page-edit",
        component: EditContentPage
    },
    {
        path: base + "content-page/view/:id",
        name: "content-page-view",
        component: ViewContentPage
    },
    {
        path: base + "help/list",
        name: "help",
        component: HelpList,
        access: true
    },
    {
        path: base + "whatsnew",
        name: "whats-new",
        component: WhatsNew,
        access: true
    },
    {
        path: base + "client",
        name: "client-list",
        component: ClientList,
        access: true
    },
    {
        path: base + "client/add",
        name: "client-add",
        component: ClientAdd,
        access: true
    },
    {
        path: base + "client/edit/:vendor_id",
        name: "client-edit",
        component: ClientEdit,
        access: true
    },
    {
        path: base + "currency",
        name: "currency-list",
        component: CurrencyList,
        access: true
    },
    {
        path: base + "changelog",
        name: "change-log",
        component: ChangeLog,
        access: true
    },
    {
        path: base + "reports/vendor_rating_review_report",
        name: "reports.vendor.rating.review.report",
        component: VendorRatingReviewReport
    },
    {
        path: base + "reports/driver_rating_review_report",
        name: "reports.driver.rating.review.report",
        component: DriverRatingReviewReport
    },
    {
        path: base + "reports/driver_settlement_report",
        name: "reports.driver.settlement",
        component: DriverSettlementReport
    },
    {
        path: base + "analytics",
        name: "analytics",
        component: Analytics,
        access: true
    },
    {
        path: base + "reports/feedback_report",
        name: "reports.feedback.report",
        component: RestaurantQRFeedbackReport
    },
    {
        path: base + "business-type",
        name: "business-type-list",
        component: BusinessTypeList,
        access: true
    },
    {
        path: base + "business-type/edit/:id",
        name: "business-type-edit",
        component: BusinessTypeEdit,
        access: true
    },
    {
        path: base + "master/depot-catalog",
        name: "master.depot-catalog",
        component: MasterDepotCatalog
    },
    {
        path: base + "master/depot-catalog/add-customization/:menu_item_id",
        name: "master-depot-catalog-add-customization",
        component: MasterDepotAddCustomization
    },
    {
        path:
            base +
            "master/depot-catalog/add-sub-customization/:menu_item_selection_id",
        name: "master-depot-catalog-add-sub-customization",
        component: MasterDepotAddSubCustomization
    },
    {
        path: base + "affiliate",
        name: "affiliate-list",
        component: AffiliateList
    },
    {
        path: base + "affiliate/details/:affiliate_id",
        name: "affiliate-detail",
        component: AffiliateDetails
    },
    {
        path: base + "affiliate/add",
        name: "affiliate-add",
        component: AffiliateAdd
    },
    {
        path: base + "reports/settlement_report",
        name: "reports.settlement.report",
        component: SettlementReport
    },
    {
        path:
            base +
            "reports/settlement_details_report/:restaurantID/:userID/:startDate/:endDate",
        name: "reports.settlement.details.report",
        component: SettlementReportDetails
    },
    {
        path: base + "reports/all_stores_settlement_report/:startDate/:endDate",
        name: "reports.all.stores.settlement.report",
        component: AllStoreSettlementReportDetails
    },
    {
        path: base + "reports/storewise_product_details_report",
        name: "reports.storewise.product.details.report",
        component: StorewiseProductDetailsReport
    },
    {
        path: base + "reports/whatsapp_engagement_track_report",
        name: "reports.whatsapp.message.report",
        component: whatsappMessageSentReport
    },
    {
        path: base + "reports/idp_order_report",
        name: "reports.idp.order.report",
        component: idporderreport
    },
    {
        path: base + "customer/request_price",
        name: "request-price",
        component: RequestPrice
    },
    {
        path: base + "blog/add",
        name: "blog-add",
        component: AddBlog
    },
    {
        path: base + "blog",
        name: "blog",
        component: BlogList
    },
    {
        path: base + "blog/edit/:id",
        name: "blog-edit",
        component: BlogEdit
    },
    {
        path: base + "brands",
        name: "brand-list",
        component: BrandList
    },
    {
        path: base + "brands/add",
        name: "brand-add",
        component: BrandAdd
    },
    {
        path: base + "brands/edit/:id",
        name: "brand-edit",
        component: BrandEdit
    },
];

const restaurantRoutes = [
    {
        path: "*",
        redirect: base + "login"
    },
    {
        path: base + "vendor/login",
        name: "vendor.login",
        component: Login
    },
    {
        path: base + "vendor/:vendor_id/signup",
        name: "vendor.signup",
        component: SubVendorSignup
    },
    {
        path: base + "vendor/dashboard",
        name: "vendor.dashboard",
        component: Dashboard
    },
    {
        path: base + "vendor/orders/list",
        name: "vendor.order.list",
        component: Order
    },
    {
        path: base + "vendor/vendors/catalog",
        name: "vendor.vendor.catalog",
        component: Catalog
    },
    {
        path: base + "vendor/vendors/add-customization/:menu_item_id",
        name: "vendor.vendor.add.customization",
        component: AddCustomization
    },
    {
        path:
            base +
            "vendor/vendors/add-sub-customization/:menu_item_selection_id",
        name: "vendor.vendor.add.sub.customization",
        component: AddSubCustomization
    },
    {
        path: base + "vendor/coupon/list",
        name: "vendor.coupon.list",
        component: CouponList
    },
    {
        path: base + "vendor/vendor-discount",
        name: "vendor.discount",
        component: VendorDiscount
    },
    {
        path: base + "vendor/vendor-terms",
        name: "vendor.terms",
        component: VendorPage
    },
    {
        path: base + "vendor/finance-list",
        name: "vendor.finance.list",
        component: RestaurantFinance
    },
    {
        path: base + "vendor/reports",
        name: "vendor.reports.list",
        component: Reports
    },
    {
        path: base + "vendor/reports/sales_report",
        name: "vendor.reports.sale.report",
        component: SalesReport
    },
    {
        path: base + "vendor/reports/item_report",
        name: "vendor.reports.item.report",
        component: ItemReport
    },
    {
        path: base + "vendor/profile",
        name: "vendor.profile",
        component: VendorProfile
    },
    {
        path: base + "vendor/operation-time",
        name: "vendor.timeslot",
        component: OperationTime
    },
    {
        path: base + "vendor/selling-timeslot",
        name: "vendor.selling.timeslot",
        component: SellingTimeSlot
    },
    {
        path: base + "vendor/vendor-rating",
        name: "vendor.review",
        component: VendorRating
    },
    {
        path: base + "vendor/vendor-category",
        name: "vendor.restaurant.category",
        component: VendorCategory
    },
    {
        path: base + "vendor/finance/month",
        name: "vendor.restaurant.finance.list",
        component: RestaurantFinance
    },
    {
        path: base + "vendor/finance",
        name: "vendor.finance",
        component: VendorFinance
    },
    {
        path: base + "vendor/forgot-password",
        name: "forgot-password",
        component: ForgotPassword
    },
    {
        path: base + "vendor/request_price",
        name: "vendor.request-price",
        component: RequestPrice
    },
    {
        path: base + "vendor/banks-list",
        name: "vendor.banks-list",
        component: BankList
    },
    {
        path: base + "vendor/settlements",
        name: "vendor.settlements",
        component: SettlementList
    },
];

const router = new Router({
    mode: "history",
    // base,
    routes: [
        ...vendorRoutes,
        ...restaurantRoutes
        // ...resturantRoutes
    ],
    scrollBehavior() {
        return { x: 0, y: 0 };
    }
});
router.beforeEach((to, from, next) => {
    $("body")
        .addClass("sidebar-disable")
        .removeClass("sidebar-enable");
    var noauth = [
        "login",
        "forgot-password",
        "vendor.login",
        "signup",
        "vendor.signup",
        "change-log",
        "driver-signup"
    ];
    const menu = vendorRoutes.find(u => u.name == to.name);
    const sidemenu = urls.find(u => menu && menu.path.includes(u.link));
    if (!noauth.includes(to.name)) {
        if (!Vue.prototype.$auth || !Vue.prototype.$auth.setting) {
            Vue.prototype.$auth.setUser(type => {
                if (noauth.includes(type)) next({ name: type });
                else {
                    if (
                        Vue.prototype.$auth.user.login_type != "vendor" ||
                        menu.access ||
                        Vue.prototype.$auth.user.access.some(s =>
                            sidemenu.permissions.some(p => p.includes(s))
                        )
                    ) {
                        next();
                    } else {
                        error_message("Permission required");
                        next({ name: from.name });
                    }
                    // next()
                }
            });
        } else {
            if (
                Vue.prototype.$auth.user.login_type != "vendor" ||
                menu.access ||
                Vue.prototype.$auth.user.access.some(s =>
                    sidemenu.permissions.some(p => p.includes(s))
                )
            ) {
                next();
            } else {
                error_message("Permission required");
                next({ name: from.name });
            }
        }
    } else {
        if (from.name == "signup") {
            next({ name: from.name });
        } else {
            next();
        }
    }
});
export default router;
