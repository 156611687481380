
<script>
import config from "../../config";
import pagination from "laravel-vue-pagination";
import { cuisineService } from "../../services";
import { error_message,debounce } from "../../Helper/helper";

export default {
  components: { pagination, cuisineService },
  data() {
    const { status,orderBy,sortBy} = this.$route.query;
    return {
      sortBy: sortBy || "cuisine_id",
      sortDesc: orderBy && orderBy != '' ? true : orderBy,
      cuisines: {},
      cuisineData: [],
      paginations: {},
      limit: 1,
      search: "",
      status: status == undefined ? 1 :status,
      config: config,
      loading: true,
      isDownloading: false,
      searchData:debounce(()=>{
        if(!this.init){
          const {status,orderBy,sortBy} = this.$data;
          this.$router.replace({name: 'cuisines-list', query: {status,orderBy,sortBy}})
        }
        this.getCuisine();
        // this.getCuisineList();
        this.init = false;
      },500),
      fields: [
        {
          key: "cuisine_id",
          label: this.trans.get("__JSON__.Id"),
          sortable: true,
        },
        {
          key: "cuisine_name",
          label: this.trans.get("__JSON__.Title"),
          sortable: true,
        },
        {
          key: "cuisine_active",
          label: this.trans.get("__JSON__.Status"),
          sortable: true,
        },
        {
          key: "action",
          label: this.trans.get("__JSON__.Action"),
          sortable: false,
        },
      ],
    };
  },
  mounted() {
    this.getCuisine();
    // this.getCuisineList();
  },
  methods: {
    downloadsCSV: function () {
      let cuisineData = "\ufeff" + "Title,Status\n";
      this.cuisineData.forEach((el) => {
        var line =
          (el["cuisine_name"] ? el["cuisine_name"] : "").replaceAll(",", " ") +
          "," +
          (el["cuisine_active"] == 1 ? 'Active' : 'InActive') +
          "\n";
        cuisineData += line;
      });

      var blob = new Blob([cuisineData], { type: "csv/plain" });
      var currentDate = new Date();
      var date = new Date().toLocaleString();
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "Cuisines" + date + ".csv";
      link.click();
    },
    getCuisine(page) {
      this.loading = true;
      var filters = {
        search: this.search,
        status: this.status,
        sortBy: this.sortBy,
        orderBy: this.sortDesc ? "desc" : "asc",
        page: page && page > 0 ? page : 1,
      }
      cuisineService
        .getCuisine(filters)
        .then((response) => {
          this.loading = false;
          // this.paginations = response.data.result;
          this.cuisines = response.data.result;
        });
    },
    getCuisineList() {
      this.isDownloading = true;
      cuisineService.exportToCsv({status: this.status}).then((response) => {
        this.isDownloading = false;
        this.cuisineData = response.data.result;
        this.downloadsCSV();
      });
    },
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      this.getCuisine();
    },
    deleteById(cuisine_id) {
      if (confirm("Are you sure you want to delete?")) {
        cuisineService
          .deleteCuisine({ cuisine_id: cuisine_id })
          .then((response) => {
            error_message(response.data.message);
            this.getCuisine();
          });
      }
    },
  },
};
</script>

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
        >
          <div class="page-title-left">
            <div class="avatar-xs mr-3">
              <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                <i class="bx bx-tag"></i>
              </span>
            </div>
             <h4 class="mb-0 font-size-18">{{ trans.trans('__JSON__.Cuisines',{cuisine: $auth.setting.cuisine_name}) }}</h4>
          </div>
          <div class="page-title-right d-flex">
             <router-link :to="{name: 'cuisines-add'}" class="btn btn-success btn-rounded mr-2 add-btn"
              v-if="$auth.hasPermission(config.permissions.cuisine_write)">
              <i class="mdi mdi-plus mr-1"></i>
              {{ trans.get('__JSON__.Add Cuisines',{cuisine: $auth.setting.cuisine_name}) }}
            </router-link>
            <button
              @click="getCuisineList"
              class="btn btn-primary btn-rounded export-btn"
              v-if="isDownloading" 
              disabled>{{ trans.get('__JSON__.Exporting...') }}</button>
            <button
              @click="getCuisineList"
              class="btn btn-primary btn-rounded export-btn"
              v-else>{{ trans.get('__JSON__.Export To CSV') }}</button>
            <div class="d-flex m-vendor-btn">
              <router-link :to="{name: 'cuisines-add'}" class="btn btn-success btn-rounded mr-2 m-add-btn">
                <i class="mdi mdi-plus"></i>
              </router-link>
              <button
                @click="getCuisineList"
                class="btn btn-primary btn-rounded m-export-btn"
              ><i class="bx bx-archive-in"></i></button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
    <div class="row">
      <div class="col-12 list-table-main">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-2 offset-sm-8">
                <div class="form-group">
                  <div class="date-range-list">
                    <label>{{ trans.get('__JSON__.Status') }} :</label>
                    <select class="custom-select" v-model="status" @change="searchData()">
                      <option value="">{{ trans.get('__JSON__.All') }}</option>
                      <option value="1" selected>{{ trans.get('__JSON__.Active') }}</option>
                      <option value="0">{{ trans.get('__JSON__.Inactive') }}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="date-range-list">
                  <label>{{ trans.get('__JSON__.Search') }} :</label>
                  <div class="text-sm-right">
                    <div class="search-box mr-2 d-inline-block">
                      <div class="position-relative">
                        <input
                          type="text"
                          class="form-control"
                          id="search"
                          @input="searchData()"
                          v-model="search"
                          :placeholder="trans.get('__JSON__.Search Placeholder')"
                        />
                        <i class="bx bx-search-alt search-icon"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 table-main-list cuisine-table table-responsive">
                <b-table
                  :items="cuisines.data"
                  :fields="fields"
                  :sort-by.sync="sortBy"
                  :no-local-sorting="true"
                  @sort-changed="sortingChanged"
                  :busy="loading"
                  show-empty
                >
                  <template #table-busy>
										<div class="text-center text-danger my-2">
										<b-spinner class="align-middle"></b-spinner>
										<strong>{{trans.get('__JSON__.Loading...')}}</strong>
										</div>
									</template>

                  <template v-slot:cell(cuisine_id)="data">{{ (cuisines.per_page * (cuisines.current_page-1)) + data.index + 1}}</template>

                  <template v-slot:cell(cuisine_name)="data">
                    <h5 class="font-size-14 mb-1">
                      <div
                        v-if="data.item.cuisine_name.length > 25"
                      >{{data.item.cuisine_name.substr(0, 25)+ '...'}}</div>
                      <div v-else>{{data.item.cuisine_name}}</div>
                    </h5>
                  </template>

                  <template v-slot:cell(cuisine_active)="data">
                    <div
                      v-if="data.item.cuisine_active == '1'"
                      class="badge badge-pill badge-soft-success font-size-12"
                    >{{ trans.get('__JSON__.Active') }}</div>
                    <div
                      v-else
                      class="badge badge-pill badge-soft-danger font-size-12"
                    >{{ trans.get('__JSON__.Inactive') }}</div>
                  </template>

                  <template v-slot:cell(action)="data">
                    <div class="vendor-btn">
                      <router-link
                        v-if="$auth.hasPermission(config.permissions.cuisine_write)"
                        :to="{name: 'cuisines-edit',params: { cuisine_id:  base64(data.item.cuisine_id) }}"
                        class="btn btn-success btn-sm btn-rounded"
                      >{{ trans.get('__JSON__.Edit') }}</router-link>
                      <button
                        type="submit"
                        v-if="$auth.hasPermission(config.permissions.cuisine_write)"
                        class="btn btn-danger btn-sm btn-rounded ml-1"
                        @click="deleteById(base64(data.item.cuisine_id))"
                      >{{ trans.get('__JSON__.Delete') }}</button>
                    </div>
                  </template>

                  <template #empty>
                    <p class="text-center">{{ trans.get('__JSON__.No Cuisine',{cuisine: $auth.setting.cuisine_name}) }}</p>
                  </template>

                </b-table>
              </div>
              <div class="row col-sm-5 offset-sm-7">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <pagination
                        :data="cuisines"
                        :limit="limit"
                        @pagination-change-page="getCuisine"
                      ></pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </div>
</template>

<style>
.cuisine-table .table thead th:nth-child(4){
    pointer-events: none;
}
</style>