<template>
    <router-view></router-view>
</template>

<script>
import config from '../config';
import { orderService, reportService } from '../services';
export default {
  name: 'app',
  data(){
    return {
      isConnected: false,
      login_id: null
    }
  },
  watch:{
    "$auth.logging_done":function(){
      if(this.$auth.logging_done && this.$auth.user.is_white_labeling == '0'){ 
        this.addEvents();
        // this.$auth.hideshowBeamer(true);
      }else{
        if(this.login_id){
          this.sockets.unsubscribe('order_placed_'+this.login_id)
          this.sockets.unsubscribe('order_confirmed_'+this.login_id)
          this.sockets.unsubscribe('order_cancelled_'+this.login_id)
          this.sockets.unsubscribe('order_placed_partner_'+this.login_id)
          this.sockets.unsubscribe('order_confirmed_partner_'+this.login_id)
          this.sockets.unsubscribe('order_cancelled_partner_'+this.login_id)
          this.sockets.unsubscribe('update_price_'+this.login_id)
          this.login_id = null;
        }
      }
    }
  },
  mounted() {
    HTMLMediaElement.autoplay = true;
    if(this.$auth.logging_done){
      this.getOrdersCount();
      this.getUpdatedPrice();
      setTimeout(() => {
        if(this.$auth.logging_done){
          this.addEvents();
        }
      },5000)
    }
  },
  created(){
      Vue.prototype.base64 = (i)  => btoa(i);
      // let token = localStorage.getItem('token');
      // if(!token && window.location.href.indexOf('login') === -1){
      //     this.$router.push({name:'login'});
      // }else{
      //   axios.defaults.headers.common = {
      //     'Accept': 'application/json',
      //     'Authorization': 'Bearer '+ token
      //   };
      // }
      
      this.$auth.setUser((type)=> {
        if(type) {
          if(this.$route && this.$route.name.includes('login')){
            this.$router.push({ name: type }).catch(()=>{});
          }
        }
      });
  },
  sockets:{
    connect() {
      this.isConnected = true;
    },
    disconnect() {
      this.isConnected = false;

    },
    // message(data){      
    //   this.getOrdersCount();
    //   var myaudio = new Audio(config.appurl+this.$auth.setting.order_notification_sound);
    //   myaudio.play();
    //   myaudio.autoplay;
    // }
  },
  methods:{
    addEvents(){
      if(this.$auth.user && this.$auth.user.login_type == 'vendor'){
        this.login_id = this.$auth.getVendorId()
        this.sockets.subscribe('order_placed_'+this.$auth.getVendorId(),
          (data) => {
            this.getOrdersCount();
            if(data.type =="Delivery"){
              var myaudio = new Audio(config.appurl+this.$auth.setting.order_notification_sound);
              myaudio.play();
              myaudio.autoplay;
            }
          })
        this.sockets.subscribe('order_confirmed_'+this.$auth.getVendorId(),
          () => {
            this.getOrdersCount();
          })
        this.sockets.subscribe('order_cancelled_'+this.$auth.getVendorId(),
          () => {
            this.getOrdersCount();
          })
        this.sockets.subscribe('update_price_'+this.$auth.getVendorId(),
          (data) => {  
            let message = `Medicine price updated for order ${data.orderId}`;          
            this.$toasted.success(message);
            console.log("update price socket");           
            this.getUpdatedPrice();
        })
      }else{
        if(!this.$auth.user ){
          return false;
        }
        this.login_id = this.$auth.user.user_id
        this.sockets.subscribe('order_placed_partner_'+this.login_id,
          () => {
            this.getOrdersCount();
            if(data.type =="Delivery"){
              var myaudio = new Audio(config.appurl+this.$auth.setting.order_notification_sound);
              myaudio.play();
              myaudio.autoplay;
            }
          })
        this.sockets.subscribe('order_confirmed_partner_'+this.login_id,
          () => {
            this.getOrdersCount();
          })
        this.sockets.subscribe('order_cancelled_partner_'+this.login_id,
          () => {
            this.getOrdersCount();
          })
      }
    },
    getOrdersCount(){
      console.log("log get order count");
      orderService
        .getNewOrders()
        .then((response) => {
          this.$auth.updateOrders(response.data.result)
        })
        .catch(function (error) {
        });
    },
    getUpdatedPrice(){
      const today = new Date().toISOString().split('T')[0];
      reportService
        .getUpdateItemPriceReport({
          sortBy: "id",
          orderBy: "desc",
          total_record:10,
          search:"",
          page: 1,
          date: today,
          orderId: ""
        })
        .then((response) => {
          this.$auth.updateOtherNotifications(response.data.result.data)
        })
        .catch(function (error) {
        });
    },
  }
}
</script>