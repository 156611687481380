
// Restaurant services rest api calling

import { multipart_headers } from '../Helper/helper';
import { routes } from '../config/api_routes'

export const restaurantService = {
    // get restaurant 
    // info: sortDesc : Boolean

    getRestaurantList: (formData) => {    
        return window.axios.post(routes.getRestaurantListApi,formData)
    },

    // restaurant export to csv
    exportToCsv(){
        return window.axios.get(routes.getRestaurantDetailApi)
    },

    // get rating
    getRatingList: (formData) => {
        return window.axios.post(routes.getRatingApi,formData)
    },

    // get category
    getCategoryList: (formData) => {
        return window.axios.post(routes.getRestaurantCategoryApi,formData)
    },

    // add menu category
    addMenuCategory: (formData) => {
        return window.axios.post(routes.addRestaurantCategoryApi,formData,{headers: multipart_headers() });
    },

     // get menucategorybyID

     getMenuCategoryById: (formData) => {
        return window.axios.post(routes.getMenuCategoryByIdApi,formData)
    },

     // update menucategoryByID
     
     updateMenuCategoryById: (formData) => {
         return window.axios.post(routes.updateRestaurantCategoryApi,formData,{headers: multipart_headers() });
     },

     // delete menucategory

    deleteMenuCategory: (formData) => {
        return window.axios.post(routes.deleteMenuCategoryApi,formData)
    },

    // add restaurant

    addRestaurant: (formData) => {
        return window.axios.post(routes.addRestaurantApi,formData,{headers: multipart_headers()});
    },


    // get restaurantcuisine

    getRestaurantCuisine: (query) => {
        return window.axios.post(routes.getRestaurantCuisineApi,query)
    },

    // get resCategory

    getResCategory: () => window.axios.get(routes.getRestaurantCategoryListApi),

    // get restaurantbyID

    getRestaurantById: (formData) => {
        return window.axios.post(routes.getRestaurantbyIdApi,formData)
    },
    // update restaurantInfo

    updateRestaurantInfoById: (formData) => {
        return window.axios.post(routes.updateVendorInfoApi,formData,{headers: multipart_headers()});
    },

    getRestaurantRating: (restaurant_id, base64 = false) => window.axios.post(routes.getRestaurantRatingApi, {restaurant_id, base64}),
    getRestaurantAvgRating: (restaurant_id, base64 = false) => window.axios.post(routes.getRestaurantAvgRatingApi, {restaurant_id, base64}),
    getBankInfo: (restaurant_id) => window.axios.post(routes.getBankInfoApi, {restaurant_id}),

    // Add TimeSlot
    addTimeSlot: (formData) => {
        return window.axios.post(routes.addTimeSlotApi,formData)
    },
    // get Selling TimeSlot
    getSelingTimes: (restaurant_id) => {
        return window.axios.post(routes.getSelingTimes,{restaurant_id});
    },
    removeSellingTime: (id) => {
        return window.axios.post(routes.removeSellingTime,{id});
    },
    getRestaurantOperationTime: (data) => window.axios.post(routes.getRestaurantOperationTimeApi,data),
    saveRestaurantOperationTime: (data) => window.axios.post(routes.saveRestaurantOperationTimeApi,data),
    restaurantOnOff: (data) => window.axios.post(routes.restaurantOnOffApi,data),
    updateStatus: (data) => window.axios.post(routes.updateStatusApi,data),
    deleteRestRating: (data) => window.axios.post(routes.deleteRestRatingApi,data),

    // get restaurant category
    getRestaurantMenuCategory: (formData) => {
        return window.axios.post(routes.getRestaurantMenuCategory,formData)
    }
}