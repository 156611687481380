<template>
<div class="row">
    <div class="col order-csv">        
        <div class="mb-2 mt-2 text-sm-right ">
            <router-link :to="{name:'restaurant-finance-month',params: {id : restaurant_id }}" class="btn btn-primary btn-sm btn-rounded">
                {{trans.get('__JSON__.View All')}}
            </router-link>
        </div>    
    </div>
    <div class="table-rep-plugin">
        <div class="table-responsive mb-0 table-border" data-pattern="priority-columns">
            <b-table
                class="table-centered"
                :items="pastdayWise.data"
                :fields="fields"
                :sort-by.sync="sortBy"
                :no-local-sorting="true"
                @sort-changed="sortingChanged"
                show-empty
            >
                <template #cell(sum_sub_total)="data">
                    <span v-html="currency"></span> {{ data.value.toFixed(2) }}
                </template>
                <template #cell(sum_admin_commision)="data">
                    <span v-html="currency"></span> {{ data.value.toFixed(2) }}
                </template>
                <template #cell(sum_amount)="data">
                    <span v-html="currency"></span> {{ data.value.toFixed(2) }}
                </template>
                <template #cell(chef_earning)="data">
                    <span v-html="currency"></span> {{ data.value.toFixed(2) }}
                </template>
                <template #cell(sum_admin_earning)="data">
                    <span v-html="currency"></span> {{ data.value.toFixed(2) }}
                </template>
            </b-table>
            <!-- <table id="tech-companies-1" class="table table-striped">
                <thead>
                    <tr>
                        <th>{{trans.get('__JSON__.Date')}}</th>
                        <th>{{trans.get('__JSON__.Item Price')}}</th>
                        <th>{{trans.get('__JSON__.Admin Commission')}}</th>
                        <th>{{trans.get('__JSON__.Total Amount')}}</th>
                        <th>{{trans.get('__JSON__.Admin Earning')}}</th>
                        <th>{{trans.get('__JSON__.Vendor Earning')}}</th>
                    </tr>
                </thead>
                <tbody v-if="Object.keys(finance).length > 0 && finance.dayWiseFinance">
                    <tr v-for="(k, index) in Object.keys(finance.dayWiseFinance)" :key="index">
                        <td>{{k}}</td>
                        <td>{{finance.dayWiseFinance[k].item_total}}</td>
                        <td>{{finance.dayWiseFinance[k].admin_commision}}</td>
                        <td>{{finance.dayWiseFinance[k].amount}}</td>
                        <td>{{finance.dayWiseFinance[k].adminEarning}}</td>
                        <td>{{finance.dayWiseFinance[k].chefEarning}}</td>
                    </tr>
                </tbody>
            </table> -->
        </div>    
    </div>
     <div class="col">
            <div class="dataTables_paginate paging_simple_numbers float-right">
            <ul class="pagination pagination-rounded mb-0">
                <!-- pagination -->
                <pagination
                :data="pastdayWise"
                :limit="limit"
                @pagination-change-page="getPastDayWiseFinance"
                ></pagination>
            </ul>
            </div>
        </div>
</div>
</template>

<script>
/* 
 * INFO: Not used anywhere yet
*/
import pagination from "laravel-vue-pagination";
import { vendorFinanceService } from '../../../services';
import config from "../../../config";
export default {
    components:{pagination},
    props: ['restaurant_id'],
    data() {
        return {
            limit: 1,
            sortBy: "date",
            sortDesc: true,
            pastdayWise: {},
            currency: '',
            fields: [
                { key: "date", label: this.trans.get("__JSON__.Date"), sortable: true },
                { key: "sum_sub_total", label: this.trans.get("__JSON__.Item Price"), sortable: false },
                { key: "sum_admin_commision", label: this.trans.get("__JSON__.Admin Commission"), sortable: false },
                { key: "sum_amount", label: this.trans.get("__JSON__.Total Amount"), sortable: false },
                { key: "sum_admin_earning", label: this.trans.get("__JSON__.Admin Earning"), sortable: false },
                { key: "chef_earning", label: this.trans.get("__JSON__.Vendor Earning",{vendor:this.$auth.getRestaurantName()}), sortable: false },
            ],
            
        }
    },
    mounted(){
        this.$data.fields[5].label = this.$auth.setting.restaurant_name;
    },
    created(){
        this.getPastDayWiseFinance();
    },
    methods: {
        sortingChanged(ctx) {
            this.sortBy = ctx.sortBy;
            this.sortDesc = ctx.sortDesc;
            this.getPastDayWiseFinance();
        },
        getPastDayWiseFinance(page = 1){
            vendorFinanceService.getPastDayWiseFinance(
                {
                    restaurant_id: this.restaurant_id,
                    sortBy: this.sortBy,
                    orderBy: this.sortDesc ? "desc" : "asc",
                    page: page ? page : 1,
                })
                .then(res => {
                    this.pastdayWise = res.data.result.data;
                    this.currency = res.data.result.currency;
                })
        },
    }
}
</script>

<style>

</style>