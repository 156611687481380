<script>
import pagination from "laravel-vue-pagination";
import { customerService } from "../../services";
import { debounce} from "../../Helper/helper";
import config from '../../config';

export default {
  components: { pagination },
  data() {
    const { status,orderBy,sortBy} = this.$route.query;
    return {
      config: config,
      sortBy: sortBy || "created_at",
      sortDesc: orderBy && orderBy != '' ? orderBy : true,
      affiliates: [],
      affiliateData: [],
      affiliateCount: '',
      paginations: {},
      limit: 2,
      search: "",
      status: status == undefined ? "1" :status,
      loading: true,
      isDownloading: false,
      searchData:debounce(()=>{
          const {status,orderBy,sortBy} = this.$data;
          this.$router.replace({name: 'affiliate-list', query: {status,orderBy,sortBy}})
          this.getAffiliateList();
      },500),
      fields: [
        {
          key: "affiliate_id",
          label: this.trans.get("__JSON__.Affiliate ID"),
          sortable: false,
        },
        {
          key: "name",
          label: this.trans.get("__JSON__.Name"),
          sortable: false,
        },
        {
          key: "restaurant_name",
          label: this.trans.get("__JSON__.Store Name"),
          sortable: false,
        },
        {
          key: "full_address",
          label: this.trans.get("__JSON__.Address"),
          sortable: false,
        },
        {
          key: "contact_number",
          label: this.trans.get("__JSON__.Phone Number"),
          sortable: false,
        },
        // {
        //   key: "instamed_cash",
        //   label: this.trans.get("__JSON__.InstamedCash"),
        //   sortable: false,
        // },
        {
          key: "customers_count",
          label: this.trans.get("__JSON__.Reffered Customers"),
          sortable: false,
        },
        {
          key: "referral_code",
          label: this.trans.get("__JSON__.Coupon Code"),
          sortable: false,
        },
        {
          key: "status",
          label: this.trans.get("__JSON__.Status"),
          sortable: false,
        },
        {
          key: "action",
          label: this.trans.get("__JSON__.Action"),
          sortable: false,
        },

      ],
    };
  },
  mounted() {
    this.getAffiliateList();
    this.getAffiliateCount();
  },
  methods: {
    onRowClicked(item) {
        this.$router.push({name: 'affiliate-detail',params: { affiliate_id: this.base64(item.affiliate_id)}})
    },
    downloadsCSV: function () {
      let affiliateData = "\ufeff" + "S. No.,Affiliate Id,Name,Store Name,Address,Phone Number,Reffered Customers,Coupon Code,Status\n";
      this.affiliateData.forEach((el, ind) => {
        let Sno = ind + 1;
        let affiliateID = "INSTA" + el["affiliate_id"]
        var line = Sno + "," +
            affiliateID +
          "," +
          el["name"] +
          "," +
          el["restaurant_name"] +
          "," +
          el["full_address"] +
          "," +
          el["contact_number"] +
          "," +
          // (el["instamed_cash"] ? el["instamed_cash"] : "-") +
          (el["customers_count"] ? el["customers_count"] : "-") +
          "," +
          el["referral_code"] +
          "," +
          (el["status"] == 1 ? "Active" : "Inactive") +
          "\n";
        affiliateData += line;
      });
      var blob = new Blob([affiliateData], { type: "csv/plain" });
      var date = new Date().toLocaleString();
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "affiliates" + date + ".csv";
      link.click();
    },
    getAffiliateList(page) {
      this.loading = true;
      var filters = {
          search: this.search,
          status: this.status,
          vendor_id: this.$auth.getVendorId(),
          pagination: true,
          page: page && page > 0 ? page : 1
      }
      customerService
        .getAffiliates(filters)
        .then((response) => {
          this.loading = false;
          this.paginations = response.data.Result;
          this.affiliates = response.data.Result.data;
        });
    },
    affiliateExportCsv() {
      this.isDownloading = true;
      customerService
        .getAffiliates({ status: this.status, sortBy: this.sortBy, orderBy: this.sortDesc ? "desc" : "asc", vendor_id: this.$auth.getVendorId(),
          pagination: false, search: this.search})
        .then((response) => {
          this.isDownloading = false;
          this.affiliateData = response.data.Result;
          this.downloadsCSV();
        });
    },
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      this.getAffiliateList();
    },
    getAffiliateCount(){
        customerService.getAffiliatesCount({vendor_id: this.$auth.getVendorId()})
        .then((response) =>{
            this.affiliateCount = response.data.result;
        })
    },
  },
};
</script>

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
        >
          <div class="page-title-left">
            <div class="avatar-xs mr-3">
              <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                <i class="bx bx-user-circle"></i>
              </span>
            </div>
            <h4 class="mb-0 font-size-18">{{ trans.get('__JSON__.AFFILIATES') }}({{affiliateCount}})</h4>
          </div>
          <div class="page-title-right">
            <div style="display: flex;">
              <router-link
                class="btn btn-success btn-rounded mr-2 add-btn"
                      :to="{name: 'affiliate-add'}"
                    >+ Add Affiliate
              </router-link>
            <button
              @click="affiliateExportCsv"
              class="btn btn-primary btn-rounded export-btn"
              v-if="isDownloading"
              disabled
            >{{ trans.get('__JSON__.Exporting...') }}</button>
            <button
              @click="affiliateExportCsv"
              class="btn btn-primary btn-rounded export-btn"
              v-else
            >{{ trans.get('__JSON__.Export To CSV') }}</button>
            <button
              @click="affiliateExportCsv"
              class="btn btn-primary btn-rounded m-export-btn"
            ><i class="bx bx-archive-in"></i></button>
          </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row list-table-main">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-2 offset-sm-8">
                <div class="form-group">
                  <div class="date-range-list">
                    <label>{{ trans.get('__JSON__.Status') }} :</label>
                    <select class="custom-select" v-model="status" @change="searchData()">
                      <option value="">{{ trans.get('__JSON__.All') }}</option>
                      <option value="1" selected>{{ trans.get('__JSON__.Active') }}</option>
                      <option value="0">{{ trans.get('__JSON__.Inactive') }}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="date-range-list">
                  <label>{{ trans.get('__JSON__.Search') }} :</label>
                  <div class="text-sm-right">
                    <div class="search-box mr-2 d-inline-block">
                      <div class="position-relative">
                        <input
                          type="text"
                          class="form-control"
                          id="search"
                          @input="searchData()"
                          v-model="search"
                          :placeholder="trans.get('__JSON__.Search by name')"
                        />
                        <i class="bx bx-search-alt search-icon"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 table-main-list customer-table table-responsive">
                <b-table
                  :items="affiliates"
                  :fields="fields"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :no-local-sorting="true"
                  @sort-changed="sortingChanged"
                  :busy="loading"
                  show-empty
                  @row-clicked="onRowClicked"
                >
                  <template #table-busy>
                    <div class="text-center text-danger my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>{{trans.get('__JSON__.Loading...')}}</strong>
                    </div>
                  </template>

                  <template v-slot:cell(affiliate_id)="data">
                      <h5 class="font-size-14">{{"INSTA" + data.item.affiliate_id }}</h5>
                  </template>

                  <template v-slot:cell(name)="data">
                    <h5 class="font-size-14">{{ data.item.name }}</h5>
                  </template>

                  <!-- <template v-slot:cell(instamed_cash)="data">
                    <div class="instamed-cash-value">
                    <div class="font-size-14">{{ data.item.instamed_cash || "0" }}</div>
                    <img :src="config.basepath+'Instamedcash.svg'" alt="insatmedcash" class="instamed-cash">
                  </div>
                  </template> -->

                  <template v-slot:cell(customers_count)="data">
                    <h5 class="font-size-14">{{ data.item.customers_count ?  data.item.customers_count : "-"}}</h5>
                  </template>

                  <template v-slot:cell(referral_code)="data">
                    <h5 class="font-size-14" style="color: #546DE4;">{{ data.item.referral_code}}</h5>
                  </template>

                  <template v-slot:cell(status)="data">
                    <div
                      v-if="data.item.status === 1"
                      class="badge badge-pill badge-soft-success font-size-12"
                    >{{ trans.get('__JSON__.Active') }}</div>
                    <div
                      v-else
                      class="badge badge-pill badge-soft-danger font-size-12"
                    >{{ trans.get('__JSON__.Inactive') }}</div>
                  </template>

                  <template v-slot:cell(action)="data">
                    <router-link
                      :to="{name: 'affiliate-detail',params: { affiliate_id:base64(data.item.affiliate_id)}}"
                      class="btn btn-primary btn-sm btn-rounded"
                    >View</router-link>
                  </template>

                  <template #empty>
                    <p class="text-center">{{ trans.get('__JSON__.No Affiliate') }}</p>
                  </template>
                </b-table>
              </div>
              <div class="row col-sm-5 offset-sm-7">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <pagination
                        :data="paginations"
                        :limit="limit"
                        @pagination-change-page="getAffiliateList"
                      ></pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
  .instamed-cash{
    width: 20px;
    height: 20px;
    margin-left: 5px;
  }
  .instamed-cash-value{
    display: flex; 
    align-items: center;
  }
</style>

