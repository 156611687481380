var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"display":"flex","align-items":"center"}},[(
        _vm.$auth.hasPermission(_vm.config.permissions.order_write) &&
        _vm.order.order_status == _vm.statuses.placed
      )?_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-light",modifiers:{"hover":true,"v-light":true}}],staticClass:"btn accept-btn",staticStyle:{"margin-right":"4px"},attrs:{"title":_vm.trans.get('__JSON__.Confirm Order'),"disabled":_vm.isAcceptbtnDisabled},on:{"click":function($event){return _vm.changeStatus(_vm.order.base_id, _vm.statuses.inkitchen)}}},[_c('i',{staticClass:"fas fa-check"})]):_vm._e(),_vm._v(" "),(
        _vm.$auth.hasPermission(_vm.config.permissions.order_write) &&
        [
          _vm.statuses.placed,
          _vm.statuses.inkitchen,
          _vm.statuses.readytoserve,
          _vm.statuses.scheduled ].includes(_vm.order.order_status)
      )?_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-light",modifiers:{"hover":true,"v-light":true}}],staticClass:"btn reject-btn",staticStyle:{"margin-right":"4px"},attrs:{"title":_vm.trans.get('__JSON__.Cancel Order')},on:{"click":function($event){return _vm.cancelOrderPopup(_vm.order.base_id, _vm.statuses.cancelled)}}},[_c('i',{staticClass:"fas fa-times"})]):_vm._e(),_vm._v(" "),(_vm.$auth.hasPermission(_vm.config.permissions.order_write))?_c('b-dropdown',{staticClass:"order-option-dropdown",attrs:{"lazy":"","id":"dropdown-1","variant":"link","right":"","role":"navigation"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-light",modifiers:{"hover":true,"v-light":true}}],staticClass:"bx bx-dots-vertical-rounded",attrs:{"title":"More"}})]},proxy:true}],null,false,3713944995)},[_vm._v(" "),(
          [_vm.statuses.paymentfailure, _vm.statuses.paymentpending].includes(
            _vm.order.order_status
          ) && _vm.isCODEnable()
        )?_c('b-dropdown-item',{staticClass:"d-none",on:{"click":function($event){return _vm.changeToCODPopup(_vm.order.base_id)}}},[_vm._v("\n        "+_vm._s(_vm.trans.get("__JSON__.Change to COD"))+"\n      ")]):_vm._e(),_vm._v(" "),_c('b-dropdown-item',{on:{"click":function($event){return _vm.viewDetails(_vm.order.base_id)}}},[_vm._v(_vm._s(_vm.trans.get("__JSON__.View")))]),_vm._v(" "),(_vm.order.order_status == _vm.statuses.placed)?_c('b-dropdown-item',{on:{"click":function($event){return _vm.changeStatus(_vm.order.base_id, _vm.statuses.inkitchen)}}},[_vm._v(_vm._s(_vm.trans.get("__JSON__.Confirm Order")))]):_vm._e(),_vm._v(" "),(_vm.order.order_status == _vm.statuses.inkitchen && !_vm.$auth.partner_login && _vm.order.delivery_pickup_types == 'Delivery')?_c('b-dropdown-item',{style:({ cursor: _vm.order.balance_amount != null && _vm.order.balance_amount > 0 && _vm.order.payment_method && _vm.order.payment_method.toLowerCase() != 'cash on delivery' ? 'not-allowed' : 'pointer' }),attrs:{"disabled":_vm.order.balance_amount != null && _vm.order.balance_amount > 0 && _vm.order.payment_method && _vm.order.payment_method.toLowerCase() != 'cash on delivery'},on:{"click":function($event){return _vm.changeStatus(_vm.order.base_id, _vm.statuses.readytoserve)}}},[_vm._v(_vm._s(_vm.trans.get("__JSON__.Ready To Delivery Order")))]):_vm._e(),_vm._v(" "),(_vm.order.order_status == _vm.statuses.inkitchen && _vm.$auth.partner_login && _vm.order.delivery_pickup_types == 'Delivery')?_c('b-dropdown-item',{on:{"click":function($event){return _vm.changeStatus(_vm.order.base_id, _vm.statuses.readytoserve)}}},[_vm._v(_vm._s(_vm.trans.get("__JSON__.Ready To Delivery Order")))]):_vm._e(),_vm._v(" "),(
          ![
            _vm.statuses.placed,
            _vm.statuses.cancelled,
            _vm.statuses.paymentpending,
            _vm.statuses.paymentfailure ].includes(_vm.order.order_status) && _vm.$auth.partner_login
        )?_c('b-dropdown-item',{on:{"click":function($event){return _vm.uploadInvoice(_vm.order.order_id, _vm.statuses.inkitchen)}}},[_vm._v(_vm._s(_vm.trans.get("__JSON__.Upload Invoice")))]):_vm._e(),_vm._v(" "),(
          [
            _vm.statuses.placed,
            _vm.statuses.inkitchen,
            _vm.statuses.readytoserve,
            _vm.statuses.ontheway,
            _vm.statuses.scheduled ].includes(_vm.order.order_status)
        )?_c('b-dropdown-divider'):_vm._e(),_vm._v(" "),(
          [_vm.statuses.readytoserve].includes(_vm.order.order_status) &&
          _vm.order.driver &&
          _vm.order.driver.length == 0 &&
          _vm.order.delivery_pickup_types == 'Delivery' &&
          (_vm.order.task_id == null ||
            (_vm.order.delivery_partner_status != null
              ? _vm.order.delivery_partner_status.toLowerCase() == 'cancelled'
              : _vm.order.delivery_partner_status == 'cancelled'))
        )?_c('b-dropdown-item',{on:{"click":function($event){return _vm.pingOrderDrivers(_vm.order.base_id)}}},[_vm._v("\n        Ping "+_vm._s(_vm.$auth.getDriverName())+"\n      ")]):_vm._e(),_vm._v(" "),(
          ([_vm.statuses.readytoserve, _vm.statuses.ontheway].includes(
            _vm.order.order_status
          ) &&
          (_vm.order.task_id == null ||
            (_vm.order.delivery_partner_status != null
              ? _vm.order.delivery_partner_status.toLowerCase() == 'cancelled'
              : _vm.order.delivery_partner_status == 'cancelled')) || ([_vm.statuses.inkitchen].includes(
            _vm.order.order_status
          ) && _vm.order.delivery_pickup_types == 'Pickup'))
        )?_c('b-dropdown-item',{on:{"click":function($event){return _vm.deliveredOrderPopup(_vm.order.base_id, _vm.statuses.delivered)}}},[_vm._v(_vm._s(_vm.trans.get("__JSON__.Deliver Order")))]):_vm._e(),_vm._v(" "),([_vm.statuses.placed].includes(_vm.order.order_status))?_c('b-dropdown-item',{on:{"click":function($event){return _vm.cancelOrderPopup(_vm.order.base_id, _vm.statuses.cancelled)}}},[_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.trans.get("__JSON__.Cancel Order")))])]):_vm._e(),_vm._v(" "),(
          [_vm.statuses.delivered].includes(_vm.order.order_status) &&
          _vm.order.settled === 1 &&
          !_vm.$auth.partner_login
        )?_c('b-dropdown-item',{on:{"click":function($event){return _vm.unsettleOrderPopup(_vm.order.base_id, _vm.order.settled)}}},[_vm._v(_vm._s(_vm.trans.get("__JSON__.Unsettle")))]):_vm._e(),_vm._v(" "),(
          [_vm.statuses.delivered].includes(_vm.order.order_status) &&
          _vm.order.settled === 0 &&
          !_vm.$auth.partner_login
        )?_c('b-dropdown-item',{on:{"click":function($event){return _vm.unsettleOrderPopup(_vm.order.base_id, _vm.order.settled)}}},[_vm._v(_vm._s(_vm.trans.get("__JSON__.Settle")))]):_vm._e()],1):_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:('Need Permission'),expression:"'Need Permission'"}],staticClass:"mdi mdi-information h4 text-danger"}),_vm._v(" "),(
        _vm.order.suborder &&
        _vm.order.suborder.id &&
        _vm.order.suborder.id > 0 &&
        [_vm.statuses.inkitchen].includes(_vm.order.order_status) &&
        !_vm.$auth.partner_login &&
        _vm.order.depot_item_delivered == 0
      )?[(_vm.$auth.hasPermission(_vm.config.permissions.order_write))?_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button","disabled":_vm.sentToStoreLoader},on:{"click":function($event){$event.stopPropagation();return _vm.sentToStore(_vm.order.order_id)}}},[(_vm.sentToStoreLoader)?_c('b-spinner',{staticClass:"align-middle"}):_vm._e(),_vm._v("\n        Sent to store\n      ")],1):_vm._e()]:(
        [_vm.statuses.inkitchen].includes(_vm.order.order_status) &&
        !_vm.$auth.partner_login &&
        !_vm.order.suborder &&
        _vm.order.is_depot_store == 1 &&
        _vm.order.depot_item_delivered == 0
      )?[(_vm.$auth.hasPermission(_vm.config.permissions.order_write))?_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button","disabled":_vm.sentToStoreLoader},on:{"click":function($event){$event.stopPropagation();return _vm.sentToStore(_vm.order.order_id)}}},[(_vm.sentToStoreLoader)?_c('b-spinner',{staticClass:"align-middle"}):_vm._e(),_vm._v("\n        Sent to store\n      ")],1):_vm._e()]:_vm._e(),_vm._v(" "),(
        [_vm.statuses.readytoserve].includes(
          _vm.order.order_status
        ) &&
        !_vm.$auth.partner_login &&
        _vm.order.task_id != null &&
        (_vm.order.delivery_partner_status != null
          ? _vm.order.delivery_partner_status.toLowerCase() != 'cancelled'
          : _vm.order.delivery_partner_status != 'cancelled')
      )?[_c('button',{staticClass:"btn btn-primary",staticStyle:{"margin-left":"4px"},attrs:{"type":"button"},on:{"click":function($event){return _vm.cancelDunzoTask(_vm.order.order_id, _vm.order.task_id)}}},[(_vm.cancelDunzoTaskLoader)?_c('b-spinner',{staticClass:"align-middle"}):_vm._e(),_vm._v("\n        Cancel "+_vm._s(_vm.order.delivery_partner_name)+" Task\n      ")],1)]:_vm._e(),_vm._v(" "),(_vm.showModal)?_c('OrderShow',{attrs:{"order-id":_vm.order.base_id,"showModalTab":_vm.showModalTab},on:{"hidden":function($event){_vm.showModal = false},"actionChanged":function($event){return _vm.$emit('refreshData')}}}):_vm._e(),_vm._v(" "),_c('b-modal',{ref:"cancel-modal",attrs:{"id":"modal-sm","size":"lg","title-class":"font-18","ok-disabled":_vm.loading,"ok-title":_vm.loading
          ? _vm.trans.get('__JSON__.Cancelling')
          : _vm.trans.get('__JSON__.Cancel Order'),"ok-only":"","ok-variant":"danger","lazy":""},on:{"hidden":_vm.resetModal,"ok":_vm.cancelOrder},scopedSlots:_vm._u([{key:"modal-header",fn:function(ref){
          var close = ref.close;
return [_c('div',{staticClass:"d-flex justify-content-between w-100"},[_c('h4',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.trans.get("__JSON__.Cancel Order")))]),_vm._v(" "),_c('h4',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.order.order_id))])]),_vm._v(" "),_c('button',{staticClass:"close",attrs:{"type":"button","aria-label":"Close"},on:{"click":function($event){return close()}}},[_vm._v("\n          ×\n        ")])]}}])},[_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('b-form',{attrs:{"role":"form"}},[_c('div',{staticClass:"row"},_vm._l((_vm.cancel_reasons),function(reason,index){return _c('div',{key:index,staticClass:"col"},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.onReasonSelect(reason)}}},[_c('b-card',{staticClass:"border border-primary",attrs:{"header-class":"bg-transparent border-primary","bg-variant":(_vm.other_selected && reason == 'Other') ||
                      reason == _vm.cancelled_desc
                        ? 'primary'
                        : 'default',"body-class":"d-flex align-items-center justify-content-center height-100"}},[_c('h5',{staticClass:"card-title mt-0",class:{
                        'text-white':
                          (_vm.other_selected && reason == 'Other') ||
                          reason == _vm.cancelled_desc,
                      }},[_vm._v("\n                      "+_vm._s(reason)+"\n                    ")])])],1)])}),0),_vm._v(" "),(_vm.other_selected)?_c('b-form-group',{attrs:{"label":"Cancel Description"}},[_c('b-form-textarea',{attrs:{"id":"textarea","placeholder":"Enter Cancel Order Description","rows":"3","max-rows":"6"},model:{value:(_vm.cancelled_desc),callback:function ($$v) {_vm.cancelled_desc=$$v},expression:"cancelled_desc"}})],1):_vm._e()],1)],1)])]),_vm._v(" "),_c('b-modal',{ref:"invoice-modal",attrs:{"id":"modal-invoice","size":"lg","title-class":"font-18","ok-disabled":_vm.loading || _vm.typeform.vendor_invoice == '',"ok-title":_vm.loading
          ? _vm.trans.get('__JSON__.Uploading')
          : _vm.trans.get('__JSON__.Upload Invoice'),"ok-only":"","ok-variant":"danger","lazy":""},on:{"hidden":_vm.resetModalInvoice,"ok":_vm.uploadInvoiceApi},scopedSlots:_vm._u([{key:"modal-header",fn:function(ref){
          var close = ref.close;
return [_c('div',{staticClass:"d-flex justify-content-between w-100"},[_c('h4',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.trans.get("__JSON__.Upload Invoice")))])]),_vm._v(" "),_c('button',{staticClass:"close",attrs:{"type":"button","aria-label":"Close"},on:{"click":function($event){return close()}}},[_vm._v("\n          ×\n        ")])]}}])},[_vm._v(" "),_c('div',{staticClass:"d-flex"},[_c('label',{staticClass:"filelabel"},[_c('i',{staticClass:"bx bx-plus plus",staticStyle:{"cursor":"pointer"}},[_c('input',{staticClass:"FileUpload1",attrs:{"accept":"image/jpeg,image/png,image/jpg,application/pdf","id":"FileInput","type":"file"},on:{"change":_vm.uploadImage}})])]),_vm._v(" "),_c('div',{staticClass:"uploaded-img-main"},[(_vm.url)?_c('div',{staticClass:"prvie-img uploaded-img"},[_c('p',{staticClass:"remove-flg",on:{"click":_vm.removeUploadImage}},[_vm._v("X")]),_vm._v(" "),(
                this.selectedInvoice.type == 'image/jpeg' ||
                this.selectedInvoice.type == 'image/png' ||
                this.selectedInvoice.type == 'image/jpg'
              )?_c('img',{staticClass:"dis-img",attrs:{"src":_vm.url}}):_vm._e(),_vm._v(" "),(this.selectedInvoice.type == 'application/pdf')?_c('img',{staticClass:"dis-img",attrs:{"src":_vm.config.basepath + 'pdfimage.png'}}):_vm._e()]):_vm._e()])])]),_vm._v(" "),_c('b-modal',{ref:"delivered-modal",attrs:{"id":"modal-sm","size":"lg","title-class":"font-18","ok-disabled":_vm.loading,"ok-title":_vm.loading
          ? _vm.trans.get('__JSON__.Delivering')
          : _vm.trans.get('__JSON__.Deliver Order'),"ok-only":"","ok-variant":"danger","lazy":""},on:{"hidden":_vm.resetModal,"ok":_vm.deliveredOrder},scopedSlots:_vm._u([{key:"modal-header",fn:function(ref){
          var close = ref.close;
return [_c('div',{staticClass:"d-flex justify-content-between w-100"},[_c('h4',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.trans.get("__JSON__.Deliver Order")))]),_vm._v(" "),_c('h4',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.order.order_id))])]),_vm._v(" "),_c('button',{staticClass:"close",attrs:{"type":"button","aria-label":"Close"},on:{"click":function($event){return close()}}},[_vm._v("\n          ×\n        ")])]}}])},[_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('b-form',{attrs:{"role":"form"}},[_c('b-form-group',{attrs:{"label":"Notes"}},[_c('b-form-textarea',{attrs:{"id":"textarea","placeholder":"Enter something...","rows":"3","max-rows":"6"},model:{value:(_vm.delivered_note),callback:function ($$v) {_vm.delivered_note=$$v},expression:"delivered_note"}})],1)],1)],1)])]),_vm._v(" "),_c('b-modal',{ref:"changeto-cod-modal",attrs:{"id":"modal-sm","size":"lg","title-class":"font-18","ok-disabled":_vm.loading,"ok-title":_vm.loading
          ? _vm.trans.get('__JSON__.Change to COD')
          : _vm.trans.get('__JSON__.Change to COD'),"ok-only":"","ok-variant":"danger","lazy":""},on:{"hidden":_vm.resetModal,"ok":_vm.changeToCODOrder},scopedSlots:_vm._u([{key:"modal-header",fn:function(ref){
          var close = ref.close;
return [_c('div',{staticClass:"d-flex justify-content-between w-100"},[_c('h4',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.trans.get("__JSON__.Change to COD")))]),_vm._v(" "),_c('h4',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.order.order_id))])]),_vm._v(" "),_c('button',{staticClass:"close",attrs:{"type":"button","aria-label":"Close"},on:{"click":function($event){return close()}}},[_vm._v("\n          ×\n        ")])]}}])},[_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('b-form',{attrs:{"role":"form"}},[_c('b-form-group',{attrs:{"label":"Notes"}},[_c('b-form-textarea',{attrs:{"id":"textarea","placeholder":"Enter something...","rows":"3","max-rows":"6"},model:{value:(_vm.changeto_cod_note),callback:function ($$v) {_vm.changeto_cod_note=$$v},expression:"changeto_cod_note"}})],1)],1)],1)])]),_vm._v(" "),_c('b-modal',{ref:"unsettle-modal",attrs:{"id":"modal-sm","size":"lg","title-class":"font-18","ok-disabled":_vm.loading,"ok-title":_vm.loading ? _vm.trans.get('__JSON__.Loading') : _vm.trans.get('__JSON__.Submit'),"ok-only":"","ok-variant":"danger","lazy":""},on:{"hidden":_vm.resetModal,"ok":_vm.unsettleOrder},scopedSlots:_vm._u([{key:"modal-header",fn:function(ref){
          var close = ref.close;
return [_c('div',{staticClass:"d-flex justify-content-between w-100"},[_c('h4',{staticClass:"mb-0"},[_vm._v("\n            "+_vm._s(_vm.trans.get("__JSON__.Mark Order Settle/Unsettle"))+"\n          ")]),_vm._v(" "),_c('h4',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.order.order_id))])]),_vm._v(" "),_c('button',{staticClass:"close",attrs:{"type":"button","aria-label":"Close"},on:{"click":function($event){return close()}}},[_vm._v("\n          ×\n        ")])]}}])},[_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('b-form',{attrs:{"role":"form"}},[_c('b-form-group',{attrs:{"label":"Notes"}},[_c('b-form-textarea',{attrs:{"id":"textarea","placeholder":"Enter something...","rows":"3","max-rows":"6"},model:{value:(_vm.unsettle_note),callback:function ($$v) {_vm.unsettle_note=$$v},expression:"unsettle_note"}})],1)],1)],1)])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }