<script>
import Multiselect from "vue-multiselect";
import { required, requiredIf } from "vuelidate/lib/validators";
import { defaultSettingService, businessTypeService } from "../../../services";
import { success_message, error_message } from "../../../Helper/helper";
import config from "../../../config";

/**
 * Starter component
 */
export default {
  components: { Multiselect },
  data() {
    return {
      config: config,
      id: "",
      web_setting_id: "",
      // web: [],
      web: {
        meta_description: "",
        keywords: "",
        is_custom_domain: 0, //Default
      },
      domain_options: [
        {
          text: "Default",
          value: 0,
        },
        {
          text: "Custom",
          value: 1,
        },
      ],
    };
  },
  validations: {
    web: {},
  },
  mounted() {
    this.getBusinessTypeById();
  },
  methods: {
    getBusinessTypeById() {
      businessTypeService
        .getBusinessTypeById({ id: this.$route.params.id })
        .then((response) => {
          const {
            website_url,
            title,
            meta_description,
            keywords,
            is_custom_domain,
          } = response.data.result.web_setting;
          this.id = response.data.result.business.id;
          this.web_setting_id = response.data.result.business.web_setting_id;
          this.web = {
            website_url: website_url || "",
            title: title || "",
            meta_description: meta_description || "",
            keywords: keywords || "",
            is_custom_domain: is_custom_domain || 0,
          };
        });
    },
    // getSetting(){
    //   webLayoutService.getWebSetting().then((response) => {
    //     const { website_url,
    //       title,
    //       meta_description,
    //       keywords,
    //       is_custom_domain
    //     } = response.data.result;
    //     this.web = {
    //       website_url: website_url || '',
    //       title: title || '',
    //       meta_description: meta_description || '',
    //       keywords: keywords || '',
    //       is_custom_domain: is_custom_domain || 0
    //     };
    //   })
    // },
    generalIt() {
      this.$v.$touch();
      if (this.$v.web.$invalid) {
        return;
      } else {
        const fd = new FormData();
        fd.append("setting_id",this.id);
        fd.append("web_setting_id",this.web_setting_id);
        fd.append("meta_description", this.web.meta_description);
        fd.append("keywords", this.web.keywords);
        fd.append("is_custom_domain", this.web.is_custom_domain);
        fd.append("type", 1);
        defaultSettingService.updateWebSetting(fd).then((response) => {
          if (response.data.code === 200) {
            success_message(response.data.message);
          } else {
            error_message(response.data.message);
          }
        });
      }
    },
  },
};
</script>
<template>
  <b-form @submit.prevent="generalIt">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="control-label">{{
                    trans.get("__JSON__.Meta Description For SEO")
                  }}</label>
                  <textarea
                    rows="5"
                    id="meta_description"
                    name="meta_description"
                    type="text"
                    v-model="web.meta_description"
                    :placeholder="trans.get('Enter Meta Description For SEO')"
                    class="form-control"
                  />
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label class="control-label">{{
                    trans.get("__JSON__.Keywords For SEO")
                  }}</label>
                  <textarea
                    rows="5"
                    id="keywords"
                    name="keywords"
                    type="text"
                    v-model="web.keywords"
                    :placeholder="trans.get('Enter Keywords For SEO')"
                    class="form-control"
                  />
                </div>
              </div>
            </div>

            <div class="btn-update">
              <div class="col-sm-6">
                <button
                  type="submit"
                  class="btn btn-primary mr-1 mt-3"
                >
                  {{ trans.get("__JSON__.Update") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-form>
</template>

<style type="text/css">
.infodetail {
  color: red;
}
.defaulturl {
  background-color: #e9ecef !important;
}
</style>