<template>
  <div class="pt-2">
    <div class="col-md-12">
      <div class="row justify-content-center">
        <div class="col-md-12">
          <div class="card overflow-hidden">
            <div class="card-body pt-0 change-log">
              <h4 class="card-title pt-4">Change Log</h4>
              <div class="table-responsive change-data-log">
                <table class="table mb-0">
                  <thead>
                    <tr>
                      <th>Date/Time</th>
                      <th>Functionality</th>
                      <th>Developer Name</th>
                    </tr>
                  </thead>
                  <tbody>
                   <tr>
                      <td>01-06-21 15:45</td>
                      <td>edit PromoCode (PromocodeController.php)</td>
                      <td>Mili Shah</td>
                    </tr>
                    <tr>
                      <td>01-06-21 16:00</td>
                      <td>PromoCode Listing (API CouponService.php)</td>
                      <td>Mili Shah</td>
                    </tr>
                    <tr>
                      <td>01-06-21 14:30</td>
                      <td>Item Image upload not working in vendor panel (catalog.vue and RestaurantController.php)</td>
                      <td>Linsa Prajapati </td>
                    </tr>
                    <tr>
                      <td>01-06-21 17:17</td>
                      <td>Customisation: add record when click on Save As Template (CustomizationController.php)</td>
                      <td>Linsa Prajapati</td>
                    </tr>
                     <tr>
                      <td>01-06-21 12:00</td>
                      <td>Add and Edit Promocode : Flat discount and customer App issue (coupon-add.vue and coupon_edit.vue and PromocodeController.php)</td>
                      <td>Sanjay Vadher</td>
                    </tr>
                    <tr>
                      <td>01-06-21 19:00</td>
                      <td>On cancel order driver is not getting free (Inside api -> OrderController cancelOrder function)</td>
                      <td>Jay Dhakan</td>
                    </tr>
                     <tr>
                      <td>02-06-21 13:30</td>
                      <td>Horizontal Layout remove video Guide for now (custom.scss)</td>
                      <td>Sanjay Vadher</td>
                    </tr>
                     <tr>
                      <td>02-06-21 13:30</td>
                      <td>Add Promocode : Make percentage select Default in Discount Type dropdown (coupon-add.vue)</td>
                      <td>Sanjay Vadher</td>
                    </tr>
                     <tr>
                      <td>02-06-21 16:45</td>
                      <td>Vendor Finance : Last months Graph and Summary not matching (DashboardService.php)</td>
                      <td>Sanjay Vadher</td>
                    </tr>
                     <tr>
                      <td>02-06-21 18:17</td>
                      <td>Country state city issue (vendor-add.vue, vendor-profile.vue, add-delivery-zone.vue, edit-delivery-zone.vue)</td>
                      <td>Sanjay Vadher</td>
                    </tr>
                    <tr>
                      <td>02-06-21 16:08</td>
                      <td>Change Order functionality for Business category & Store Category</td>
                      <td>Linsa Prajapati</td>
                    </tr>
                    <tr>
                      <td>02-06-21 16:08</td>
                      <td>Any Vendor Tax,Commission should be allowed Decimals(vendor-add.vue,vendor-profile.vue,profile.vue)</td>
                      <td>Linsa Prajapati</td>
                    </tr>
                    <tr>
                      <td>02-06-21 10:30</td>
                      <td>Country state city issue (Vue changes, inside coupon folder -> coupon-add.vue, counpon-edit.vue)</td>
                      <td>Jay Dhakan</td>
                    </tr>
                    <tr>
                      <td>02-06-21 18:00</td>
                      <td>Business category list as per the reordering restaurant_categorylisting api changes (PHP changes, Inside services -> CategoryService -> getCategory function)</td>
                      <td>Jay Dhakan</td>
                    </tr>
                    <tr>
                      <td>02-06-21 18:15</td>
                      <td>Banner list, Banner with category as per the reordering get_banner api changes (PHP changes, Inside services -> BannerService -> getBanner function)</td>
                      <td>Jay Dhakan</td>
                    </tr>
                    <tr>
                      <td>02-06-21 19:00</td>
                      <td>Store category list as per the reordering restaurant_menu_category api changes (PHP changes, Inside services -> RestaurantService -> getRestaurantMenuCategory function)</td>
                      <td>Jay Dhakan</td>
                    </tr>
                    <tr>
                      <td>02-06-21 19:50</td>
                      <td>Remove nifty icon (Vue changes, Inside component folder -> horizontal-topbar.vue, nav-bar.vue)</td>
                      <td>Jay Dhakan</td>
                    </tr>
                     <tr>
                      <td>03-06-21 16:20</td>
                      <td>Country, State, City Filter issue on listing (vendor-list.vue, driver-list.vue, delivery-zone-list.vue, coupon-list.vue, total_sales_earning.vue, tax_report.vue)</td>
                      <td>Sanjay Vadher</td>
                    </tr>
                    <tr>
                      <td>04-06-21 12:30</td>
                      <td>Xente payment gateway (PHP changes, inside Services folder -> SettingService.php updatePaymentGateway function)</td>
                      <td>Jay Dhakan</td>
                    </tr>
                    <tr>
                      <td>04-06-21 14:45</td>
                      <td>Xente payment gateway (Vue changes, inside settings folder -> payment-gateway.vue, inside lang folder en.json)</td>
                      <td>Jay Dhakan</td>
                    </tr>
                    <tr>
                      <td>04-06-21 14:50</td>
                      <td>Add Bundle ID in Setting (version.vue, en.json and SettingService.php, VendorSettingSevice.php, SettingController.php)</td>
                      <td>Sanjay Vadher</td>
                    </tr>
                    <tr>
                      <td>04-06-21 14:50</td>
                      <td>Order Notification text update issue fixed (SettingController.php)</td>
                      <td>Sanjay Vadher</td>
                    </tr>
                     <tr>
                      <td>04-06-21 15:55</td>
                      <td>Remove validation from setting version tab (version.vue)</td>
                      <td>Sanjay Vadher</td>
                    </tr>
                    <tr>
                      <td>04-06-21 18:19</td>
                      <td>Support Widget show in Admin panel & hide in Vendor Panel (app.blade.php and authPlugin.js)</td>
                      <td>Sanjay Vadher</td>
                    </tr>
                     <tr>
                      <td>07-06-21 12:08</td>
                      <td>Replace SendGrid Key (.env)</td>
                      <td>Sanjay Vadher</td>
                    </tr>
                    <tr>
                      <td>07-06-21 12:08</td>
                      <td>Add Change password option for Driver in admin panel (driver/details.vue, driver/change-password.vue, DriverService.js, api_routes.js, api.php, DriverController.php)</td>
                      <td>Sanjay Vadher</td>
                    </tr>
                    <tr>
                      <td>08-06-21 12:00</td>
                      <td>Add Device Type and version on order details (order-details.vue)</td>
                      <td>Sanjay Vadher</td>
                    </tr>
                    <tr>
                      <td>10-06-21 16:00</td>
                      <td>Remove validation in vendor discount (vendor-discount.vue)</td>
                      <td>Sanjay Vadher</td>
                    </tr>
                     <tr>
                      <td>11-06-21 11:00</td>
                      <td>Payment method Restaurant select method option text change in setting (settings/configuration/general.vue.vue)</td>
                      <td>Sanjay Vadher</td>
                    </tr>
                     <tr>
                      <td>11-06-21 11:33</td>
                      <td>Add and edit payment option on each vendor (vendor-add.vue, vendor-profile.vue and RestaurantController.php)</td>
                      <td>Sanjay Vadher</td>
                    </tr>
                    <tr>
                      <td>11-06-21 11:45</td>
                      <td>Remove contact number and email from delivery zone (add-delivery-zone.vue, edit-delivery-zone.vue and DeliveryZoneController.php)</td>
                      <td>Sanjay Vadher</td>
                    </tr>
                    <tr>
                      <td>11-06-21 16:28</td>
                      <td>Order Print Formatting issue fixed (order.blade.php, custom.scss)</td>
                      <td>Sanjay Vadher</td>
                    </tr>
                    <tr>
                      <td>11-06-21 16:28</td>
                      <td>Check Write permission on driver finance show receive button (driver/driver-settlement)</td>
                      <td>Sanjay Vadher</td>
                    </tr>
                     <tr>
                      <td>14-06-21 12:51</td>
                      <td>Item Image issue on sub user (Models/RestaurantMenuItemImage.php)</td>
                      <td>Sanjay Vadher</td>
                    </tr>
                    <tr>
                      <td>14-06-21 13:00</td>
                      <td>Export to csv in order special instruction field issue fixed not showing text (orders/main.vue)</td>
                      <td>Sanjay Vadher</td>
                    </tr>
                    <tr>
                      <td>14-06-21 18:00</td>
                      <td>Create PromoCode add discount with percentage & flat issue fixed (coupon-add.vue & PromocodeController.php)</td>
                      <td>Sanjay Vadher</td>
                    </tr>
                    <tr>
                      <td>15-06-21 16:00</td>
                      <td>Printing funcationality (order-show.vue & HomeController.php, web.php, order.blade.php, order_new.blade.php, en.json, print.css)</td>
                      <td>Sanjay Vadher</td>
                    </tr>
                    <tr>
                      <td>16-06-21 12:00</td>
                      <td>Export to csv button text changes on data loading (customer_report.vue,sales_report.vue,total_sales_earning.vue,tax_report.vue,,inactive_customer_report.vue,avg_rating_report.vue,driver-list.vue,vendor-list.vue,customer-list.vue,delivery-zone-list.vue,category-list.vue,cuisine-list.vue)</td>
                      <td>Sanjay Vadher</td>
                    </tr>
                    <tr>
                      <td>16-06-21 18:00</td>
                      <td>Add customer name & mobile number on vendor and driver rating & review tab (vendor-rating.vue,driver-rating.vue,en.json & RestaurantService.php,DriverService.php)</td>
                      <td>Sanjay Vadher</td>
                    </tr>
                    <tr>
                      <td>17-06-21 15:00</td>
                      <td>Vendor & Driver Rating & Review Report (vendor_rating_review_report.vue,driver_rating_review_report.vue,report-list.vue,index.js,api_routes.js,en.json,ReportService.js & ReportService.php,ReportController.php,api.php,Models/Rating.php)</td>
                      <td>Sanjay Vadher</td>
                    </tr>
                    <tr>
                      <td>17-06-21 18:00</td>
                      <td>Add & Edit driver dynamic for vehicle type (add-driver.vue,driver-profile.vue)</td>
                      <td>Sanjay Vadher</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- end card-body -->
          </div>
          <!-- end card -->
        </div>
        <!-- end col -->
      </div>
    </div>
  </div>
  <!-- end row -->
</template>

<script>
export default {};
</script>