<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import DateRangePicker from "vue2-daterange-picker";
import pagination from "laravel-vue-pagination";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import {
  get_partner_login,
  set_partner,
  set_partner_details,
  set_user,
  set_user_detail,
  debounce,
  get_decimal,
  get_currency,
  date_ranges,
  get_user
} from "../../Helper/helper";
import moment from "moment";
import { reportService } from "../../services";
/**
 * Report component
 */
export default {
  components: { Layout, PageHeader, pagination, DateRangePicker },
  data() {
    let startDate = this.moment().startOf("day").subtract(6, "days");
    let endDate = this.moment().endOf("day").toDate();
    return {
      decimal: get_decimal(),
      totalSale: {},
      previousPageData: {},
      totalSaleReport: [],
      restaurant_count: "",
      restaurants_id: "",
      sortBy: "restaurant_menu_item_id",
      sortDesc: true,
      paginations: {},
      limit: 2,
      perPage: 0,
      currentPage: 1,
      currency: get_currency(),
      country: 0,
      state: 0,
      country_count: "",
      state_count: "",
      city_count: "",
      search: "",
      loading: true,
      isDownloading: false,
      dateRange: { startDate, endDate },
      date_ranges: date_ranges,
      fields: [
        { key: "serial_no", label: "S. No.", sortable: false },
        { key: "product_name", label: "Product Name", sortable: false },
        { key: "category_name", label: "Category(Chronic)", sortable: false },
        { key: "name", label: "Store Name", sortable: false },
        { key: "order_count", label: "No. of orders", sortable: false },
        { key: "total_quantity_ordered", label: "No. of units", sortable: false },
      ],
    };
  },
  mounted() {
    this.previousPageData = JSON.parse(this.$route.query.data);
    console.log(this.previousPageData);
    this.getTotalSalesReports();
  },
  methods: {
    downloadsCSV: function () {
      let totalSaleReport =
        '\ufeff' +
        'S.No.,Product Name,Category(Chronic),Store Name,No. of orders,No. of units, \n'
      this.totalSaleReport.forEach((el, ind) => {
        let sno = ind + 1;
        var line = sno + "," +
        // el["restaurant_menu_item_id"] +
        //   "," +
          this.previousPageData.product_name.split(',').join('') +
          "," +
          this.previousPageData.category_name.split(',').join('') +
          "," +
          (el["name"] ? el["name"] : "-") +
          "," +
          el["order_count"] +
          "," +
          el["total_quantity_ordered"] +
          "\n"
        totalSaleReport += line;
      });

      var blob = new Blob([totalSaleReport], { type: "csv/plain" });
      var date = new Date().toLocaleString();
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "storewise_product_" + date + ".csv";
      link.click();
    },
    getTotalSalesReports(page) {
      this.loading = true;
      reportService
      .getStorewiseProductReport({
        sortBy: this.sortBy,
        orderBy: this.sortDesc ? "asc" : "desc",
        page: page ? page : 1,
        id: this.previousPageData.id,
        pagination: true
      })
      .then((response) => {
        this.loading = false;
        // this.paginations = response.data.result;
        this.totalSale = response.data.result;    
      });
    },
    totalSaleExportCsv() {
      this.isDownloading = true;
      reportService
      .getStorewiseProductReport({
        sortBy: this.sortBy,
        orderBy: this.sortDesc ? "asc" : "desc",
        id: this.previousPageData.id,
        pagination: false
      })
      .then((response) => {
        this.isDownloading = false;
        this.totalSaleReport = response.data.result;
        this.downloadsCSV();
      });
    },
    searchData() {
      this.getTotalSalesReports();
    },
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      this.getTotalSalesReports();
    },
  },
};
</script>

<template>
  <Layout>
    <div>
      <div class="row">
          <div class="col-12">
              <div
              class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
              >
              <div class="page-title-left avatar-main-icon">
                  <div class="avatar-xs mr-3">
                  <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                      <i class="bx bx-purchase-tag"></i>
                  </span>
                  </div>
                  <div class="report-tag">
                    <h4 class="mb-0 font-size-18">{{ trans.get('__JSON__.Product Report') }}</h4>
                    <p><router-link :to="{ name: 'reports-list'}">{{ trans.get('__JSON__.Reports') }}</router-link> > <router-link :to="{ name: 'reports.product.report'}">{{ trans.get('__JSON__.Product Reports') }}</router-link> > {{ trans.get('__JSON__.Storewise Product Report') }}</p>
                  </div>
              </div>
              <div class="page-title-right">
                <button @click="totalSaleExportCsv" class="btn btn-primary btn-rounded export-btn" v-if="isDownloading" disabled>{{ trans.get('__JSON__.Exporting...') }}</button>
                <button @click="totalSaleExportCsv" class="btn btn-primary btn-rounded export-btn" v-else>{{ trans.get('__JSON__.Export To CSV') }}</button>
                <button @click="totalSaleExportCsv" class="btn btn-primary btn-rounded m-export-btn"><i class="bx bx-archive-in"></i></button>
              </div>
              </div>
          </div>
      </div>

      <!-- end row -->
      <div class="row">
        <div class="col-12 list-table-main">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-sm-2 offset-sm-10">
                  <div class="search-box mr-2 mb-0 d-block form-group">
                    <div class="date-range-list">
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 table-main-list total-sales-report-table table-responsive">
                  <b-table
                    :items="totalSale.data"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :no-local-sorting="true"
                    @sort-changed="sortingChanged"
                    :busy="loading"
                    show-empty
                  >
                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>{{trans.get('__JSON__.Loading...')}}</strong>
                      </div>
                    </template>

                    <template v-slot:cell(serial_no)="data">
                      <div>{{ (totalSale.per_page * (totalSale.current_page-1)) + data.index + 1 }}</div>
                    </template>

                    <template v-slot:cell(product_name)="data">
                      <div>{{ previousPageData.product_name}}</div>
                    </template>

                    <template v-slot:cell(category_name)="data">
                      <span>{{ previousPageData.category_name }}</span>
                    </template>

                    <template v-slot:cell(name)="data">
                      <span>{{ (data.item.name ? data.item.name : "-") }}</span>
                    </template>

                    <template v-slot:cell(order_count)="data">
                      <span>{{ data.item.order_count }}</span>
                    </template>

                    <template v-slot:cell(total_quantity_ordered)="data">
                      {{data.item.total_quantity_ordered}}
                    </template>

                    <template #empty>
                      <p class="text-center">{{ trans.get('__JSON__.No Storewise Product Report Found') }}</p>
                    </template>
                  </b-table>
                </div>
                <div class="row col-sm-5 offset-sm-7">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <pagination
                          :data="totalSale"
                          :limit="limit"
                          @pagination-change-page="getTotalSalesReports"
                        ></pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>


<style>
.total-sales-report-table .table thead th:nth-child(1),.total-sales-report-table .table thead th:nth-child(3),.total-sales-report-table .table thead th:nth-child(4){
    pointer-events: none;
}
</style>