import { routes } from "../config/api_routes";

export const vendorFinanceService = {
    getVendorFinance: (data) => window.axios.post(routes.getVendorFinanceApi,data),
    getVendorFinanceCsv: (data) => window.axios.post(routes.getVendorFinanceCsvApi,data),
    getDayWiseFinance: (restaurant_id) => window.axios.post(routes.getDayWiseFinanceApi, {restaurant_id}),
    getPastDayWiseFinance: (data) => window.axios.post(routes.getPastDayWiseFinanceApi, data),
    getMonthwiseRestaurantEarnings: (data) => window.axios.post(routes.getMonthwiseRestaurantEarningsApi, data),
    getOrderwiseRestaurantEarnings: (data) => window.axios.post(routes.getOrderwiseRestaurantEarningsApi, data),
    getOrderwiseRestaurantEarningsExport: (data) => window.axios.post(routes.getOrderwiseRestaurantEarningsExportApi, data),
    getVendorFinanceMonth: (month) => window.axios.get(routes.getVendorFinanceMonthApi,{params: {month}}),
    getVendorFinanceMonthCsv: (month) => window.axios.get(routes.getVendorFinanceMonthCsvApi,{params: {month}}),
    getRestaurantSettlement: (data) => window.axios.post(routes.getRestaurantSettlementApi, data),
    paySettlement: (data) => window.axios.post(routes.paySettlementApi, data),
    getTransactions: (data) => window.axios.get(routes.getTransactionsApi, {params:data}),
}