<script>
import Multiselect from 'vue-multiselect'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import DatePicker from "vue2-datepicker";

import { vendorsEarning } from './vendor-earning-data'
/**
 * Starter component
 */
export default {
    components: {   Multiselect, DatePicker },
    data() {
        return {
            months: "October",
            demoYear: "2020",
            vendorsEarning: vendorsEarning,
            options: {
                months: [
                    'January',
                    'February',
                    'March',
                    'April',
                    'May',
                    'June',
                    'July',
                    'August',
                    'September',
                    'October',
                    'November',
                    'December'
                ]
            }
        };
    }
};
</script>
<template>
  <div class="row mt-3">
    <div class="date-main months-main order-list-main">
        <multiselect v-model="months" :options="options.months"></multiselect>
          <div class="year-main">
             <div class="search-box mr-2 d-inline-block">
                <date-picker v-model="demoYear" value="demoYear" type="year" lang="en" confirm placeholder="Select Year"></date-picker>
             </div>
           </div>
    </div>
    <div class="table-rep-plugin">
      <div class="table-responsive mb-0 table-border" data-pattern="priority-columns">
        <table id="tech-companies-1" class="table table-striped">
            <thead>
              <tr>
                  <th>Date</th>
                  <th data-priority="1">Item Price</th>
                  <th data-priority="3">Delivery Charge</th>
                  <th data-priority="1">Coupon Discount</th>
                  <th data-priority="3">Admin Commission</th>
                  <th data-priority="3">Total Amount</th>
                  <th data-priority="6">Admin Earning</th>
                  <th data-priority="6">Vendor Earning</th>
              </tr>
            </thead>
            <tbody class="bold-text">
              <tr v-for="data in vendorsEarning" :key="data.id">
                <td>{{data.month}}</td>
                <td>{{data.item}}</td>
                <td>{{data.delivery}}</td>
                <td>{{data.coupon}}</td>
                <td>{{data.total}}</td>
                <td>{{data.admin}}</td>
                <td>{{data.earning}}</td>
                <td>{{data.vendor}}</td>
              </tr>
              <tr>
                <td>31 Days</td>
                <td>₹ 0.00</td>
                <td>₹ 0.00</td>
                <td>₹ 0.00</td>
                <td>₹ 0.00</td>
                <td>₹ 0.00</td>
                <td>₹ 0.00</td>
                <td>₹ 0.00</td>
              </tr>
            </tbody>
        </table>
      </div>
    </div>
    <!-- end col-->
   </div>

</template>