const vendorsSettlement = [
    {
        id: 1,
        duration: '19 May 2020 - 25 Sep 2020',
        count: '2',
        total: '₹ 7,860.72',
        admin: '₹ 247.80',
        vendor: '₹ 6,708.20',
        status: 'Paid'
    },
    {
        id: 1,
        duration: '28 Mar 2020 - 18 May 2020',
        count: '131',
        total: '₹ 89,949.25',
        admin: '₹ 16,576.66',
        vendor: '₹ 68,148.64',
        status: 'Pending'
    }
];

export { vendorsSettlement };