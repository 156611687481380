import { routes } from '../config/api_routes'

export const pageService = {

    getContentPage: (formData) => {    
        return window.axios.post(routes.getContentPageApi,formData)
    },
    
    addContentPage: (formData) => {    
        return window.axios.post(routes.addContentPageApi,formData)
    },
    
    getContentPageById: (formData) => {    
        return window.axios.post(routes.getContentPageByIdApi,formData)
    },
    
    editContentPage: (formData) => {    
        return window.axios.post(routes.editContentPageApi,formData)
    },
    
    viewContentPage: (formData) => {    
        return window.axios.post(routes.viewContentPageApi,formData)
    },
    
    deleteContentPage: (formData) => {    
        return window.axios.post(routes.deleteContentPageApi,formData)
    },
    
    getStaticPage: (formData) => {    
        return window.axios.post(routes.getStaticPageApi,formData)
    },

    getVendorTerm: (formData) => {    
        return window.axios.post(routes.getVendorTermApi,formData)
    },

    getBlogList: (formData) => {    
        return window.axios.post(routes.getBlogListApi,formData)
    },

    storeBlog: (formData) => {
        return window.axios.post(routes.storeBlogApi, formData)
    },

    deleteBlogById: (id) => {
        return window.axios.get(`${routes.deleteBlogByIdApi}${id}`)
    },

    getBlogById: (id) => {
        return window.axios.get(`${routes.getBlogByIdApi}${id}`)
    },

    updateBlogById: (id, formData) => {
        return window.axios.post(`${routes.updateBlogApi}${id}`, formData)
    }

}