
<script>
import config from "../../config";
import pagination from "laravel-vue-pagination";
import {
  vendorService,
  deliveryZoneService,
  settingService,
} from "../../services";
import { debounce, error_message, replaceByDefault,get_user } from "../../Helper/helper";
import Swal from "sweetalert2";

export default {
  components: { pagination, vendorService, deliveryZoneService },
  data() {
    const { is_confirm,country,state,city,orderBy,sortBy,status} = this.$route.query;
    return {
      media:[],
      replaceByDefault:replaceByDefault,
      vendorData: [],
      config: config,
      sortBy: sortBy || "created_date",
      vendor_currency: "",
      // sortDesc: orderBy && orderBy != '' ? true : orderBy,
      sortDesc: orderBy && orderBy != '' ? (orderBy == 'asc' ? false : true) : (sortBy && sortBy == 'name' ? false : true), //true,
      vendors: null,
      vendorCount: '',
      vendorActive :'',
      paginations: {},
      limit: 2,
      search: "",
      is_confirm: is_confirm == undefined ? 1 :is_confirm ,
      countries: [],
      states: [],
      cities: [],
      country: country || 0,
      state: state || 0,
      city: city || 0,
      init: true,
      isDownloading: false,
      searchData:debounce(()=>{
        if(!this.init){
          const {is_confirm,country,state,city,orderBy,sortBy} = this.$data;
          this.$router.replace({name: 'vendor-list', query: {is_confirm,country,state,city,orderBy,sortBy}})
        }
        this.getVendor();
        // this.getVendorDetail();
        this.init = false;
      },500),
      setting: [],
      vendor_id: "",
      loading: true,
      fields: [
        {
          key: "icon_image",
          label: "#",
          sortable: false,
        },
        {
          key: "name",
          label: this.trans.get("__JSON__.Vendor Name"),
          sortable: true,
        },
        {
          key: "unique",
          label: this.trans.trans("__JSON__.Vendor Id"),
          sortable: false,
        },
        {
          key: "address",
          label: this.trans.get("__JSON__.Location"),
          sortable: false,
        },
        {
          key: "order_count",
          label: this.trans.get("__JSON__.Orders"),
          sortable: true,
        },
        {
          key: "is_confirm",
          label: this.trans.get("__JSON__.Status"),
          sortable: false,
        },
        {
          key: "action",
          label: this.trans.get("__JSON__.Action"),
          sortable: false,
        },
      ],
    };
  },

  mounted() {
    this.getVendorCountry();
    this.getAppSetting();
    this.vendor_id = this.$auth.getVendorId();
    // console.log(this.vendor_id);
  },
  methods: {
    onRowClicked(item) {
        this.$router.push({name: 'vendor-detail',params: { restaurant_id: this.base64(item.restaurant_id)}})
      },
    getAppSetting() {
        this.fields[1].label = this.$auth.getRestaurantName();
        this.fields[2].label = this.$auth.getRestaurantName()+' Id';
    },
    getSetting() {
      settingService.getSetting().then((response) => {
        this.vendor_currency = response.data.result.currency;
      });
    },
    downloadsCSV: function () {
      let vendorData = "\ufeff" +this.$auth.getRestaurantName()+' ('+this.$auth.langauges[0].name+'),'+this.$auth.getRestaurantName()+' ('+this.$auth.langauges[1].name+'),'+this.$auth.getRestaurantName()+" Id,Email,Contact Number,Category,Cuisine,Country,State,City,Location,"+this.$auth.getRestaurantName()+" Full Address,"+this.$auth.getRestaurantName()+" Full Address ("+this.$auth.langauges[1].name+"),Minimun Order Amount,Cost of Two Person,Avg Processing Time,Business Licence Number,Admin Commission Type,Admin Commission Value,"+this.$auth.getRestaurantName()+" Type,Time Slot,Delivery Type,GST(%),"+this.$auth.getRestaurantName()+" Details Page Layout,Phone Support,Order,Status,Radius\n";
      this.vendorData.map((el) => {
        var line =
          el["name"] +
          "," +
          (el["name_thai"] ? el["name_thai"] : "-").replaceAll("null","-") +
          "," +
          el["unique"] +
          "," +
          el["email"] +
          "," +
          el["phone"] +
          "," +
          el["category_name"] +
          "," +
          el["cuisines"].map(p => p.cuisine_name).join(' | ') +
          "," +
          el["country_name"] +
          "," +
          el["state_name"] +
          "," +
          el["cityname"] +
          "," +
          (el["address"] ? el["address"] : "").replaceAll(
            ",",
            " "
          ) +
          "," +
          (el["location_address"] ? el["location_address"] : "").replaceAll(
            ",",
            " "
          ) +
          "," +
          (el["address_thai"] ? el["address_thai"] : "-").replaceAll(
            ",",
            "-"
          ) +
          "," +
          el["minimum_order_value"] +
          "," +
          el["two_person_price"] +
          "," +
          el["delivery_time"] +
          "," +
          (el["fssai_licence"] ? el["fssai_licence"] : "-").replaceAll("null","-") +
          "," +
          (el["admin_commision_type"] == 'commission_in' ?  "Fixed "+this.vendor_currency+"" : "Percentage (%)") +
          "," +
          el["admin_commision"] +
          "," +
          (el["restaurant_type"] == 0 ? "All" : el["restaurant_type"] == 1 ? "Pure Veg" : el["restaurant_type"] == 2 ? "Non Veg" : "Not Applicable") +
          "," +
          el["time_slots"] +
          "," +
          (el["delivery_type_time_slots"] == 0 ? "Pickup" : el["delivery_type_time_slots"] == 1 ? "Delivery" : "Both") +
          "," +
          el["restaurant_tax"] +
          "," +
          (el["restaurant_item_layout"] == 1 ? "Layout 1" : el["restaurant_item_layout"] == 2 ? "Layout 2" : "Layout 3") +
          "," +
          (el["support_contact_number"] ? el["support_contact_number"] : "-").replaceAll("null","-") + 
          "," +
          el["order_count"] +
          "," +
          (el["is_confirm"] == 1 ? "Active" : el["is_confirm"] == 0 ? "Inactive" : "Waiting For Approval") +
          "," +
          (el["radius"] ? el["radius"] : "-") + 
          "\n";
        vendorData += line;
      });
      var blob = new Blob([vendorData], { type: "text/csv;charset=utf-8;" });
      var date = new Date().toLocaleString();
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = this.$auth.getRestaurantName()+ "_" + date + ".csv";
      link.click();
    },
    getVendorCount()
    {
      this.loading = true;
      vendorService
        .getVendorCount({
          search: this.search,
          is_confirm: this.is_confirm,
          sortBy: this.sortBy,
          orderBy: this.sortDesc ? "desc" : "asc",
          country: this.country,
          state: this.state,
          city: this.city,
        })
        .then((response) => {
          this.loading = false;
          this.vendorCount = response.data.result.data;
        });
    },
    getVendor(page) {
      this.loading = true;
      // if (this.sortBy == "name") {
      //   this.sortDesc = false;
      // } else {
      //   this.sortDesc = true;
      // };
      var filters = {
          search: this.search,
          is_confirm: this.is_confirm,
          sortBy: this.sortBy,
          orderBy: this.sortDesc ? "desc" : "asc",
          country: this.country,
          state: this.state,
          city: this.city,
          page: page && page > 0 ? page : 1,
      }
      vendorService
        .getVendorList(filters)
        .then((response) => {
          this.loading = false;
          this.paginations = response.data.result.data;
          this.vendors = response.data.result.data.data;
          this.vendorCount = response.data.result.data.total;
          this.vendorActive = response.data.result.restaurantActive;
        });
    },
    maxVendor() {
      if(this.$auth.setting.max_store_count <= this.vendorActive){
          Swal.fire({
            text: this.trans.trans('__JSON__.You have exceed limit for Adding vendor. Please upgrade your plan or contact support for same.',{ vendor: this.$auth.setting.restaurant_name }),
            imageHeight: 20,
            confirmButtonColor: "#556ee6"
          });
      } else {
          this.$router.push({ name: 'vendor-add' });
      }
    },
    getVendorDetail() {
      this.isDownloading = true;
      vendorService
        .exportToCsv({ is_confirm: this.is_confirm, sortBy: this.sortBy, orderBy: this.sortDesc ? "desc" : "asc" })
        .then((response) => {
          this.isDownloading = false;
          this.vendorData = response.data.result;
          this.downloadsCSV();
        });
    },
    getVendorCountry() {
      this.country = 0;
      this.city = 0;
      this.state = 0;
      this.countries = [];
      this.states = [];
      this.cities = [];
      deliveryZoneService.getVendorCountry().then((response) => {
        this.countries = response.data.result;
        if (this.countries.length == 1) {
          this.country = this.countries[0].id;
        }
        this.getVendorState();
      });
    },
    getVendorState() {
      this.city = 0;
      this.state = 0;
      this.states = [];
      this.cities = [];
      this.country = parseInt(this.country);
      if(this.country > 0) {
        deliveryZoneService.getVendorState({country_id: this.country})
        .then((response) => {
          this.states = response.data.result;
          if (this.states.length == 1) {
            this.state = this.states[0].id;
          }
          this.getVendorCity();
        });
      } else {
        this.searchData();
      }
    },
    getVendorCity() {
      this.city = 0;
      this.cities = [];
      this.state = parseInt(this.state);
      if(this.state > 0) {
        deliveryZoneService.getVendorCity({state_id:this.state ? this.state : 0,country_id: this.country})
        .then((response) => {
          this.cities = response.data.result;
          if (this.cities.length == 1) {
            this.city = this.cities[0].cityid;
          }
          this.searchData();
        });
      } else {
        this.searchData();
      }
    },
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      this.getVendor();
      // this.getVendorDetail();
    },
    sortbyChange() {
      if (this.sortBy == 'name') {
        this.sortDesc = false;
      } else {
        this.sortDesc = true;
      }
      this.searchData();
    }
  },
};
</script>

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
        >
          <div class="page-title-left">
            <div class="avatar-xs mr-3">
              <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                <i class="bx bx-store"></i>
              </span>
            </div>
            <h4
              class="mb-0 font-size-18"
            >{{ trans.trans('__JSON__.Vendors',{ vendor: $auth.setting.restaurant_name }) }}({{vendorCount}})</h4>
          </div>
          <div class="page-title-right d-flex">
            <router-link :to="{name: 'vendors-stores-location'}" class="btn btn-success btn-rounded mr-2 add-btn" target="_blank">View Stores Locations</router-link>
            <router-link :to="{name: 'vendor.signup',params: { vendor_id: this.base64(vendor_id)} }" class="btn btn-success btn-rounded mr-2 add-btn" target="_blank">{{ trans.get('__JSON__.Signup Link') }}</router-link>
             <!-- && $auth.setting.is_single_restaurant == 'No' -->
            <button v-if="$auth.hasPermission(config.permissions.merchant_write)" class="btn btn-success btn-rounded mr-2 add-btn" @click="maxVendor">
              <i class="mdi mdi-plus mr-1"></i>
              {{ trans.get('__JSON__.Add Vendor',{ vendor: $auth.setting.restaurant_name }) }}
            </button>
            <button
              @click="getVendorDetail"
              class="btn btn-primary btn-rounded export-btn"
              v-if="isDownloading"
              disabled
            >{{ trans.get('__JSON__.Exporting...') }}</button>
            <button
              @click="getVendorDetail"
              class="btn btn-primary btn-rounded export-btn"
              v-else
            >{{ trans.get('__JSON__.Export To CSV') }}</button>
            <div class="d-flex m-vendor-btn">
              <router-link :to="{name: 'vendor-add'}"  v-if="$auth.hasPermission(config.permissions.merchant_write) && $auth.setting.is_single_restaurant == 'No'" class="btn btn-success btn-rounded mr-2 m-add-btn">
                <i class="mdi mdi-plus"></i>
              </router-link>
              <button
                @click="getVendorDetail"
                class="btn btn-primary btn-rounded m-export-btn"
              ><i class="bx bx-archive-in"></i></button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
    <div class="row">
      <div class="col-12 list-table-main">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div v-if="countries.length >1" class="col-sm-2">
                <div class="form-group">
                  <div class="date-range-list">
                    <label>{{ trans.get('__JSON__.Country') }} :</label>
                    <select class="custom-select" v-model="country" @change="getVendorState();">
                      <option value="0" selected>All</option>
                      <option
                        v-for="con in countries"
                        :value="con.id"
                        :key="con.id"
                      >{{con.country_name}}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div v-else class="offset-sm-2">
              </div>
              <div v-if="states.length > 1" class="col-sm-2">
                <div class="form-group">
                  <div class="date-range-list">
                    <label>{{ trans.get('__JSON__.State') }} :</label>
                    <select class="custom-select" v-model="state" @change="getVendorCity">
                      <option value="0" selected>All</option>
                      <option v-for="sat in states" :value="sat.id" :key="sat.id">{{sat.state_name}}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div v-else class="offset-sm-2">
              </div>
              <div v-if="cities.length > 1" class="col-sm-2">
                <div class="form-group">
                  <div class="date-range-list">
                    <label>{{ trans.get('__JSON__.City') }} :</label>
                    <select class="custom-select" v-model="city" @change="searchData">
                      <option value="0" selected>All</option>
                      <option
                        v-for="cit in cities"
                        :value="cit.cityid"
                        :key="cit.cityid"
                      >{{cit.cityname}}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div v-else class="offset-sm-2">
              </div>
              <div class="col-sm-2">
                <div class="form-group">
                  <div class="date-range-list">
                    <label>{{ trans.get('__JSON__.SortBy') }} :</label>
                    <!-- <select class="custom-select" v-model="sortBy" @change="searchData()"> -->
                    <select class="custom-select" v-model="sortBy" @change="sortbyChange()">
                      <!-- <option value="status">{{ trans.get('__JSON__.Default') }}</option> -->
                      <option value="created_date">{{ trans.get('__JSON__.Date Added') }}</option>
                      <option value="order_count">{{ trans.get('__JSON__.Most Orders') }}</option>
                      <option value="name">{{ trans.get('__JSON__.Alphabetically') }}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="form-group">
                  <div class="date-range-list">
                    <label>{{ trans.get('__JSON__.Status') }} :</label>
                    <select class="custom-select" v-model="is_confirm" @change="searchData()">
                      <option value="">All</option>
                      <option value="1" selected="selected">Active</option>
                      <option value="0">Inactive</option>
                      <option value="2">Waiting For Approval</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="search-box mr-2 mb-2 d-inline-block">
                  <div class="date-range-list">
                    <label>{{ trans.get('__JSON__.Search') }} :</label>
                    <div class="position-relative">
                      <input
                        type="text"
                        class="form-control"
                        @input="searchData()"
                        v-model="search"
                        :placeholder="trans.get('__JSON__.Search...')"
                      />
                      <i class="bx bx-search-alt search-icon"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 table-main-list vendor-table table-responsive">
                <b-table
                  v-if="vendors"
                  :items="vendors"
                  :fields="fields"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :no-local-sorting="true"
                  @sort-changed="sortingChanged"
                  :busy="loading"
                  show-empty
                  @row-clicked="onRowClicked"
                >
                  <template #table-busy>
                    <div class="text-center text-danger my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>{{trans.get('__JSON__.Loading...')}}</strong>
                    </div>
                  </template>

                  <template v-slot:cell(icon_image)="data">
                    <div v-if="data.item.icon_image">
                      <img
                        alt
                        class="vendor-img avatar-xs"
                        v-lazy="(config.restauranticon+data.item.icon_image) || config.no_image"
                      />
                    </div>
                    <div v-else>
                      <img class="vendor-img avatar-xs" :src="config.no_image" alt />
                    </div>
                  </template>

                  <template v-slot:cell(name)="data">
                    <h5 class="font-size-14">
                      <div v-if="data.item.name && data.item.name.length > 25">{{data.item.name.substr(0, 25)+ '...'}}</div>
                      <div v-else>{{data.item.name}}</div>
                    </h5>
                  </template>

                  <template v-slot:cell(unique)="data">
                    <h5 class="font-size-14"><div>{{data.item.unique}}</div></h5>
                  </template>

                  <template v-slot:cell(address)="data">
                    <div v-if="data.item.address">
                      <div
                        v-if="data.item.address.length > 60"
                      >{{data.item.address.substr(0, 60)+ '...'}}</div>
                      <div v-else>{{data.item.address}}</div>
                    </div>
                  </template>

                  <template v-slot:cell(is_confirm)="data">
                    <div
                      v-if="data.item.is_confirm == '0'"
                      class="badge badge-pill badge-soft-danger font-size-12"
                    >{{ trans.get('__JSON__.Inactive') }}</div>
                    <div
                      v-if="data.item.is_confirm == '1'"
                      class="badge badge-pill badge-soft-success font-size-12"
                    >{{ trans.get('__JSON__.Active') }}</div>
                    <div
                      v-if="data.item.is_confirm == '2'"
                      class="badge badge-pill badge-soft-warning font-size-12"
                    >{{ trans.get('__JSON__.Waiting For Approval') }}</div>
                  </template>

                  <template v-slot:cell(action)="data">
                    <div class="vendor-btn">
                      <router-link
                        :to="{name: 'vendor-detail',params: { restaurant_id: base64(data.item.restaurant_id)}}"
                        class="btn btn-primary btn-sm btn-rounded"
                      >{{ trans.get('__JSON__.View') }}</router-link>
                      <router-link
                        :to="{name: 'vendor-catalog',params: { restaurant_id: base64(data.item.restaurant_id)}}"
                        class="btn btn-success btn-sm btn-rounded"
                      >{{ trans.get('__JSON__.Catalog') }}</router-link>
                    </div>
                  </template>

                  <template #empty>
                    <p class="text-center">{{ trans.get('__JSON__.No Vendor Found') }}</p>
                  </template>
                </b-table>
              </div>
              <div class="row col-sm-5 offset-sm-7">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <pagination
                        :data="paginations"
                        :limit="limit"
                        @pagination-change-page="getVendor"
                      ></pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
