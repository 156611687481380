<script>
import Layout from "../../layouts/main.vue";
import { settingService } from "../../services";
import config from "../../config";
// import VueGoogleAutocomplete from "vue-google-autocomplete";

export default {
  components: { Layout },
  data() {
    return {
      markers: [],
      map: null,
      config: config,
    };
  },
  mounted() {
    this.getStoresloaction();
  },
  methods: {
    getStoresloaction() {
      // this.loading = true;
      settingService.getStoresLocationAPI().then((response) => {
        // console.log("response", response, response?.data.result);
        this.markers = response?.data.result;
        this.initMap();
        //   this.loading = false;
        //   console.log("ppppp", this.profilemenulist);
      });
    },

    initMap() {
      this.map = new google.maps.Map(this.$refs.map, {
        center: { lat: 19.076, lng: 72.8777 },
        zoom: 9,
      });

      //   const markers = [
      //     // Example array of markers
      //     {
      //       latitude: 19.076074827582,
      //       longitude: 72.877772736,
      //       description: "Mumbai Somewhere",
      //     },
      //     {
      //       latitude: 19.2183,
      //       longitude: 72.9781,
      //       description: "Thane",
      //     },
      //     // Add more markers as needed
      //   ];
      this.addMultipleMarkers(this.markers);
    },

    addMarker(location, description, color, radius) {

      // console.log("location add marker", location, description, color);

      if (!this.map) return;

      const icon = {
        url: color == 'grey' ? `${config.basepath}grey-icon.png` : `https://maps.google.com/mapfiles/ms/icons/${color}-dot.png`,
        scaledSize: new google.maps.Size(30, 30),
      };
      
      const marker = new google.maps.Marker({
        position: location,
        map: this.map,
        icon: icon,
        description: description,
      });

      const infoWindow = new google.maps.InfoWindow({
        content: description,
      });

      marker.circle = null;

      marker.addListener("click", () => {
        infoWindow.open(this.map, marker);
        console.log(marker.circle, "marker.circle");
        if (marker.circle) {
          console.log("inside");
          marker.circle.setMap(null);
          marker.circle = null; 
        } else {
          marker.circle = this.addCircle(location, radius);
        }
      });

      marker.setDraggable(false);
      this.markers.push(marker);
    },

    addCircle(location, radius) {
      
      if (!this.map) return;

      const circle = new google.maps.Circle({
        strokeColor: '#ADD8E6',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#ADD8E6',
        fillOpacity: 0.35,
        map: this.map,
        center: location,
        radius: radius
      });

      return circle;
    },

    addMultipleMarkers(markers) {
      markers.forEach((marker) => {
        const location = {
          lat: parseFloat(marker.latitude),
          lng: parseFloat(marker.longitude),
        };

        const radius = marker.radius * 1000;
        const color = marker.restaurant_grades?.color || 'grey';

        this.addMarker(location, marker.name, color, radius);
        // this.addCircle(location, radius);
      });
    },
  },
};
</script>


<template>
  <Layout>
    <!-- <div> -->
    <!-- <vue-google-autocomplete
        ref="autocomplete"
        @placechanged="onPlaceChanged"
        id="autocomplete"
        placeholder="Enter your address"
        types="geocode"
      ></vue-google-autocomplete> -->
    <div ref="map" style="height: 70vh; width: 100%"></div>
    <!-- </div> -->
  </Layout>
</template>


