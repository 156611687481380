<script>
import { settingService } from "../../../services";
import Multiselect from 'vue-multiselect'

import { error_message } from '../../../Helper/helper';
import { success_message } from '../../../Helper/helper';
import config from '../../../config';

/** 
 * Starter component
 */
export default {
  components: { Multiselect },
  data() {
    return {
      config: config,
      setting:{},
      errors: '',
      setting: {
        ios_customer_version: "",
        ios_customer_app_url: "",
        ios_chefs_version: "",
        ios_chefs_app_url: "",
        ios_driver_version: "",
        ios_driver_app_url: "",
        android_customer_version: "",
        android_customer_app_url: "",
        android_chefs_version: "",
        android_chefs_app_url: "",
        android_driver_version: "",
        android_driver_app_url: "",
        user_bundle_id: "",
        partner_bundle_id: "",
        driver_bundle_id: ""
      }, 
    };
  }, 
  mounted() {
    this.getSetting();
  },
   methods: {
   getSetting(){
      settingService.getSetting()
      .then(response => { 
        this.setting = response.data.result;
      });
    },
    versionInIt(){
       const fd = new FormData();
       fd.append("ios_customer_version", this.setting.ios_customer_version);
       fd.append("ios_customer_app_url", this.setting.ios_customer_app_url);
       fd.append("ios_chefs_version", this.setting.ios_chefs_version);
       fd.append("ios_chefs_app_url",this.setting.ios_chefs_app_url);
       fd.append("ios_driver_version",this.setting.ios_driver_version);
       fd.append("ios_driver_app_url",this.setting.ios_driver_app_url);
       fd.append("android_customer_version",this.setting.android_customer_version);
       fd.append("android_customer_app_url",this.setting.android_customer_app_url);
       fd.append("android_chefs_version",this.setting.android_chefs_version);
       fd.append('android_chefs_app_url',this.setting.android_chefs_app_url);
       fd.append('android_driver_app_url',this.setting.android_driver_app_url);
       fd.append('android_driver_version',this.setting.android_driver_version);
        fd.append("user_bundle_id",this.setting.user_bundle_id);
       fd.append("partner_bundle_id",this.setting.partner_bundle_id);
       fd.append("driver_bundle_id",this.setting.driver_bundle_id);
        fd.append("type",6);
       settingService.updateSetting(fd).then((response) => {
          if (response.data.code === 200) {
              if(response.data.result){
                this.$auth.updateSetting(response.data.result.settings)
              }
              success_message(response.data.message);
            } else {
                error_message(response.data.message);
            }
        });
      }
  }
};
</script>
<template>
  <b-form @submit.prevent="versionInIt">
     <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body setting-main">
                    <div class="row section-main">
                        <div class="col-md-4">
                            <div class="setting-heading">  
                                <h4>{{ trans.get('__JSON__.IOS Versions') }}</h4>
                                <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                tempor.</p> -->
                            </div>
                        </div>
                        <div class="col-md-8">
                            <div class="setting-data">
                                <div class="row">
                                    <div class="col-md-6 customer-app">
                                        <!-- <p>{{ trans.get('__JSON__.User Version') }}</p> -->
                                          <b-form-group id="input-group-1" :label="trans.get('__JSON__.User Version')" label-for="name">
                                          <b-form-input
                                            id="ios_customer_version"
                                            name="ios_customer_version"
                                            v-model="setting.ios_customer_version"
                                            type="text"
                                            :placeholder="trans.get('__JSON__.IOS User App Version')"
                                          ></b-form-input>
                                        </b-form-group>
                                     </div>
                                    <div class="col-md-6 customer-app">
                                        <!-- <p>{{ trans.get('__JSON__.User App URL') }}</p> -->
                                         <b-form-group id="input-group-1" :label="trans.get('__JSON__.User App URL')" label-for="name">
                                          <b-form-input
                                            id="ios_customer_app_url"
                                            name="ios_customer_app_url"
                                            v-model="setting.ios_customer_app_url"
                                            type="text"
                                            :placeholder="trans.get('__JSON__.IOS User App URL')"
                                          ></b-form-input>
                                        </b-form-group>
                                        <!-- <input id="name" name="name" type="text" class="form-control application"/> -->
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6 mt-3 customer-app">
                                        <!-- <p>{{ trans.get('__JSON__.Vendor Version') }}</p> -->
                                       <b-form-group id="input-group-1" :label="trans.trans('__JSON__.Vendor Version', { vendor: $auth.setting.restaurant_name })" label-for="name">
                                          <b-form-input
                                            id="ios_chefs_version"
                                            name="ios_chefs_version"
                                            v-model="setting.ios_chefs_version"
                                            type="text"
                                            :placeholder="trans.trans('__JSON__.IOS Vendor App Version',{ vendor: $auth.setting.restaurant_name })"
                                          ></b-form-input>
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-6 mt-3 customer-app">
                                        <!-- <p>{{ trans.get('__JSON__.Vendor App URL') }}</p> -->
                                        <b-form-group id="input-group-1" :label="trans.trans('__JSON__.Vendor App URL',{ vendor: $auth.setting.restaurant_name })" label-for="name">
                                          <b-form-input
                                            id="ios_chefs_app_url"
                                            name="ios_chefs_app_url"
                                            v-model="setting.ios_chefs_app_url"
                                            type="text"
                                            :placeholder="trans.trans('__JSON__.IOS Vendor App URL',{ vendor: $auth.setting.restaurant_name })"
                                          ></b-form-input>
                                        </b-form-group>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6 mt-3 customer-app">
                                        <!-- <p>{{ trans.get('__JSON__.Driver Version') }}</p> -->
                                        <b-form-group id="input-group-1" :label="trans.trans('__JSON__.Driver Version',{ driver: $auth.setting.driver_name })" label-for="name">
                                          <b-form-input
                                            id="ios_driver_version"
                                            name="ios_driver_version"
                                            v-model="setting.ios_driver_version"
                                            type="text"
                                            :placeholder="trans.trans('__JSON__.IOS Delivery Boy App Version',{ driver: $auth.setting.driver_name })"
                                          ></b-form-input>
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-6 mt-3 customer-app">
                                        <!-- <p>{{ trans.get('__JSON__.Driver App URL') }}</p> -->
                                         <b-form-group id="input-group-1" :label="trans.trans('__JSON__.Driver App URL',{ driver: $auth.setting.driver_name })" label-for="name">
                                          <b-form-input
                                            id="ios_driver_app_url"
                                            name="ios_driver_app_url"
                                            v-model="setting.ios_driver_app_url"
                                            type="text"
                                            :placeholder="trans.trans('__JSON__.IOS Delivery Boy App URL',{ driver: $auth.setting.driver_name })"
                                          ></b-form-input>
                                        </b-form-group>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                      <div class="row section-main">
                        <div class="col-md-4">
                            <div class="setting-heading">  
                                <h4>{{ trans.get('__JSON__.Android Versions') }}</h4>
                                <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                tempor.</p> -->
                            </div>
                        </div>
                        <div class="col-md-8">
                            <div class="setting-data">
                                <div class="row">
                                    <div class="col-md-6 customer-app">
                                        <!-- <p>{{ trans.get('__JSON__.User Version') }}</p> -->
                                        <b-form-group id="input-group-1" :label="trans.get('__JSON__.User Version')" label-for="name">
                                          <b-form-input
                                            id="android_customer_version"
                                            name="android_customer_version"
                                            v-model="setting.android_customer_version"
                                            type="text"
                                            :placeholder="trans.get('__JSON__.Android Customer Version')"
                                          ></b-form-input>
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-6 customer-app">
                                        <!-- <p>{{ trans.get('__JSON__.User App URL') }}</p> -->
                                        <b-form-group id="input-group-1" :label="trans.get('__JSON__.User App URL')" label-for="name">
                                          <b-form-input
                                            id="android_customer_app_url"
                                            name="android_customer_app_url"
                                            v-model="setting.android_customer_app_url"
                                            type="text"
                                            :placeholder="trans.get('__JSON__.Android Customer App URL')"
                                          ></b-form-input>
                                        </b-form-group>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6 mt-3 customer-app">
                                        <!-- <p>{{ trans.get('__JSON__.Vendor Version') }}</p> -->
                                        <b-form-group id="input-group-1" :label="trans.get('__JSON__.Vendor Version',{ vendor: $auth.setting.restaurant_name })" label-for="name">
                                          <b-form-input
                                            id="android_chefs_version"
                                            name="android_chefs_version"
                                            v-model="setting.android_chefs_version"
                                            type="text"
                                            :placeholder="trans.get('__JSON__.Android Vendor App Version',{ vendor: $auth.setting.restaurant_name })"
                                          ></b-form-input>
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-6 mt-3 customer-app">
                                        <!-- <p>{{ trans.get('__JSON__.Vendor App URL') }}</p> -->
                                        <b-form-group id="input-group-1" :label="trans.get('__JSON__.Vendor App URL',{ vendor: $auth.setting.restaurant_name })" label-for="name">
                                          <b-form-input
                                            id="android_chefs_app_url"
                                            name="android_chefs_app_url"
                                            v-model="setting.android_chefs_app_url"
                                            type="text"
                                            :placeholder="trans.get('__JSON__.Android Vendor App URL',{ vendor: $auth.setting.restaurant_name })"
                                          ></b-form-input>
                                        </b-form-group>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6 mt-3 customer-app">
                                        <!-- <p>{{ trans.get('__JSON__.Driver Version') }}</p> -->
                                        <b-form-group id="input-group-1" :label="trans.get('__JSON__.Driver Version',{ driver: $auth.setting.driver_name })" label-for="name">
                                          <b-form-input
                                            id="android_driver_version"
                                            name="android_driver_version"
                                            v-model="setting.android_driver_version"
                                            type="text"
                                            :placeholder="trans.get('__JSON__.Android Delivery Boy App Version',{ driver: $auth.setting.driver_name })"
                                          ></b-form-input>
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-6 mt-3 customer-app">
                                        <!-- <p>{{ trans.get('__JSON__.Driver App URL') }}</p> -->
                                         <b-form-group id="input-group-1" :label="trans.get('__JSON__.Driver App URL',{ driver: $auth.setting.driver_name })" label-for="name">
                                          <b-form-input
                                            id="android_driver_app_url"
                                            name="android_driver_app_url"
                                            v-model="setting.android_driver_app_url"
                                            type="text"
                                            :placeholder="trans.get('__JSON__.Android Delivery Boy App URL',{ driver: $auth.setting.driver_name })"
                                          ></b-form-input>
                                        </b-form-group>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                      <div class="row section-main">
                        <div class="col-md-4">
                            <div class="setting-heading">  
                                <h4>{{ trans.get('__JSON__.Bundle ID') }}</h4>
                                <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                tempor.</p> -->
                            </div>
                        </div>
                        <div class="col-md-8">
                            <div class="setting-data">
                                <div class="row">
                                    <div class="col-md-4 customer-app">
                                        <!-- <p>{{ trans.get('__JSON__.User Version') }}</p> -->
                                         <b-form-group id="input-group-1" :label="trans.get('__JSON__.User Bundle ID')" label-for="name">
                                          <b-form-input
                                            id="user_bundle_id"
                                            name="user_bundle_id"
                                            v-model="setting.user_bundle_id"
                                            type="text"
                                            :placeholder="trans.get('__JSON__.User Bundle ID')"
                                          ></b-form-input>
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-4 customer-app">
                                        <!-- <p>{{ trans.get('__JSON__.User App URL') }}</p> -->
                                        <b-form-group id="input-group-1" :label="trans.trans('__JSON__.Partner Bundle ID',{ vendor:  $auth.setting && $auth.setting.restaurant_name })" label-for="name">
                                          <b-form-input
                                            id="partner_bundle_id"
                                            name="partner_bundle_id"
                                            v-model="setting.partner_bundle_id"
                                            type="text"
                                            :placeholder="trans.trans('__JSON__.Partner Bundle ID',{ vendor:  $auth.setting && $auth.setting.restaurant_name })"
                                          ></b-form-input>
                                        </b-form-group>
                                    </div>
                                     <div class="col-md-4 customer-app">
                                        <!-- <p>{{ trans.get('__JSON__.Vendor Version') }}</p> -->
                                        <b-form-group id="input-group-1" :label="trans.trans('__JSON__.Driver Bundle ID',{ driver: $auth.setting && $auth.setting.driver_name })" label-for="name">
                                          <b-form-input
                                            id="driver_bundle_id"
                                            name="driver_bundle_id"
                                            v-model="setting.driver_bundle_id"
                                            type="text"
                                            :placeholder="trans.trans('__JSON__.Driver Bundle ID',{ driver: $auth.setting && $auth.setting.driver_name })"
                                          ></b-form-input>
                                        </b-form-group>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                
                    <div class="btn-update">
                        <div class="col-sm-6">
                        <button type="submit" v-if="$auth.hasPermission(config.permissions.configuration_write)"  class="btn btn-primary mr-1 mt-3">{{ trans.get('__JSON__.Update') }}</button>
                        </div>
                    </div>

            </div>
        </div>
      </div>
    </div>
</b-form>
</template>