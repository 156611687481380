<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import Theme from "./web-layout-setting/theme.vue";
import General from './web-layout-setting/general.vue';
import Widget from './web-layout-setting/widget.vue';
import Keys from './web-layout-setting/keys.vue';
import Layouts from './web-layout-setting/layouts.vue';
import HomePage from './web-layout-setting/home-page.vue';
import config from '../../config';

/** 
 * Tabs & accordions component
 */
export default {
  components: { Layout, PageHeader, Theme, General, Widget, Keys, Layouts, HomePage },
  data() {
    return {
      config: config
    };
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between icon-main-page">
          <div class="page-title-left">
            <div class="avatar-xs mr-3">
              <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                <i class="bx bx-layout"></i>
              </span>
            </div>
             <h4 class="mb-0 font-size-18">{{ trans.trans('__JSON__.Web Configurations') }}</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <!-- Tab panel start-->
            <b-tabs justified lazy nav-class="nav-tabs-custom mt-3 order-tabs" content-class="p-3 text-muted">
              <b-tab active>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-file"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">{{ trans.get('__JSON__.General') }}</span>
                </template>
                <General/>
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-file"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">{{ trans.get('__JSON__.Theme') }}</span>
                </template>
                <Theme/>
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-home"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">{{ trans.get('__JSON__.Ordering Widget') }}</span>
                </template>
                <Widget/>
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-file"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">{{ trans.get('__JSON__.Integrations') }}</span>
                </template>
                <Keys/>
              </b-tab>
              <!-- <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-home"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">{{ trans.get('__JSON__.Layout') }}</span>
                </template>
                <Layouts/>
              </b-tab> -->
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-home"></i>
                  </span>
                  <span class="d-none d-sm-inline-block d-none">{{ trans.get('__JSON__.Landing Page') }}</span>
                </template>
                <HomePage/>
              </b-tab>
            </b-tabs>
            <!-- Tab panel end -->
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>