import { routes } from '../config/api_routes'

export const customerService = {

    getCustomer: (formData) => window.axios.post(routes.getCustomerApi,formData),

    customerExportCsv: (formData) => window.axios.post(routes.customerExportToCsvApi, formData),

    getCustomerProfile: (formData) => window.axios.post(routes.getCustomerProfileApi,formData),

    updateCustomerProfile: (formData) => window.axios.post(routes.updateCustomerProfileApi,formData),

    getCustomerOrder: (formData) => window.axios.post(routes.getCustomerOrderApi,formData),

    changeCustPassword: (formData) => window.axios.post(routes.changeCusPasswordApi,formData),

    getAffiliates: (formData) => window.axios.post(routes.getAffiliatesApi,formData),

    getRefferalHistoryAffiliate: (formData) => window.axios.post(routes.getRefferalHistoryAffiliateApi,formData),

    getAffiliatesCount: (formData) => window.axios.post(routes.getAffiliatesCountApi,formData),

    getAffiliateDetails: (formData) => window.axios.post(routes.getAffiliateDetailsApi,formData),

    changeAffiliateStatus: (formData) => window.axios.post(routes.changeAffiliateStatusApi,formData),

    updateAffiliateDetails: (formData) => window.axios.post(routes.updateAffiliateDetailsApi,formData),

    addAffiliateDetails: (formData) => window.axios.post(routes.addAffiliateDetailsApi,formData),

    getInstamedCashDetails: (formData) => window.axios.post(routes.getInstamedCashApi,formData),

    storeInstamedCashDetails: (formData) => window.axios.post(routes.storeInstamedCashApi,formData),

    getPriceRequest: (formData) => window.axios.post(routes.getPriceRequestApi, formData),

    updatePriceRequest: (formData) => window.axios.post(routes.updateRequestPriceApi, formData),

    getBrandDetails: (formData) => window.axios.post(routes.getBrandDetailsApi, formData),    

    getBrandCount: (formData) => window.axios.get(routes.getBrandCountApi, formData),    

    postBrandDetails: (formData) => window.axios.post(routes.postBrandDetailsApi, formData),    

    updateBrandDetails: (formData) => window.axios.post(routes.updateBrandDetailsApi, formData),    

    getBrandDetailsById: (id) => window.axios.get(`${routes.getBrandDetailsByIdApi}${id}`),  
}