import config from "../config";
import { routes } from "../config/api_routes";

const {
    getUserRolesApi,
    addPermissionApi,
    addUserRoleApi,
    getPermissionsByGuardApi,
    changePermissionsByRoleApi,
    getAccessUsersApi,
    addUserApi,
    getAllPermissionsApi,
    getUserPermissionsApi,
    getUserDetailApi,
    updateUserPermissionsApi,
    updateUserApi,
    getAuthPermissionsApi
 } = routes;

export const userAccessService = {
    getRoles: () => window.axios.get(getUserRolesApi),
    addRole: (data) => window.axios.post(addUserRoleApi, data),
    addPermission: (data) => window.axios.post(addPermissionApi, data),
    getPermissionsByGuard: (data) => window.axios.post(getPermissionsByGuardApi, data),
    changePermissionsByRole: (data) => window.axios.post(changePermissionsByRoleApi, data),
    getAccessUsers: (data) => window.axios.get(getAccessUsersApi, {params: data}),
    addUserApi: (data) => window.axios.post(addUserApi,data),
    getAllPermissions: () => window.axios.get(getAllPermissionsApi),
    getUserPermissions: (user_id) => window.axios.get(getUserPermissionsApi,{params: {user_id}}),
    getAuthPermissions: () => window.axios.get(getAuthPermissionsApi),
    getUserDetail: (user_id) => window.axios.get(getUserDetailApi,{params: {user_id}}),
    updateUserPermissions: (user_id, permissions) => window.axios.post(updateUserPermissionsApi,{user_id, permissions}),
    updateUserApi: (data) => window.axios.post(updateUserApi,data),
}