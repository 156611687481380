
import { routes } from '../config/api_routes'

export const customizationService = {

    getItemCustmization: (formData) => {
        return window.axios.post(routes.getItemCustmizationApi, formData)
    },
    addCustmization: (formData) => {
        return window.axios.post(routes.addCustmizationApi, formData)
    },
    getCustmizationType: (formData) => {
        return window.axios.post(routes.getCustmizationTypeApi, formData)
    },
    customizationEdit: (formData) => {
        return window.axios.post(routes.customizationEditApi, formData)
    },
    deleteCustmizationType: (formData) => {
        return window.axios.post(routes.deleteCustmizationTypeApi, formData)
    },
    saveTemplate: (formData) => {
        return window.axios.post(routes.saveTemplateApi, formData)
    },
    deleteitemCustmization: (formData) => {
        return window.axios.post(routes.deleteItemCustmizationApi, formData)
    },
    getTemplate: (formData) => {
        return window.axios.post(routes.getTemplateApi, formData)
    },
    addTemplate: (formData) => {
        return window.axios.post(routes.addTemplateApi, formData)
    },
    addMenuSelection: (formData) => {
        return window.axios.post(routes.addMenuSelectionApi, formData)
    },
    addSubMenuSelection: (formData) => {
        return window.axios.post(routes.addSubMenuSelectionApi, formData)
    },
    getSubCustomization: (formData) => {
        return window.axios.post(routes.getSubCustomizationApi, formData)
    },
    minSelection: (formData) => {
        return window.axios.post(routes.minSelectionApi, formData)
    },
    maxSelection: (formData) => {
        return window.axios.post(routes.maxSelectionApi, formData)
    }
}
