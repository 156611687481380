<template>
    <Layout>
      <div class="row">
        <div class="col-12">
          <div
            class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
          >
            <div class="page-title-left">
              <div class="avatar-xs mr-3 pmenuavatar">
                <span
                  class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18"
                >
                  <i class="bx bx-briefcase"></i>
                </span>
              </div>
              <div class="report-tag">
                <h4 class="mb-0 font-size-18">
                  {{ trans.trans("__JSON__.Add Blog") }}
                </h4>
                <p><router-link :to="{ name: 'blog'}">{{ trans.get('__JSON__.Blogs') }}</router-link> > {{ trans.get('__JSON__.Add Blog') }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <form action="#" @submit.prevent="typeForm">
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="formgroup">
                          <label class="required"> Blog Url
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            id="sort_order"
                            v-model="blog_url"
                            :class="{ 'is-invalid':$v.blog_url.$error }"
                          />
                          <div v-if="$v.blog_url.$error" class="invalid-feedback">
                            <span v-if="!$v.blog_url.required">{{ trans.get('__JSON__.Please Enter Blog Url') }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="formgroup">
                          <label class="required"> Blog Title
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            id="sort_order"
                            v-model="blog_title"
                            :class="{ 'is-invalid':$v.blog_title.$error }"
                          />
                          <div v-if="$v.blog_title.$error" class="invalid-feedback">
                            <span v-if="!$v.blog_title.required">{{ trans.get('__JSON__.Please Enter Blog Title') }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-sm-6">
                        <div class="formgroup">
                          <label> Read Time </label>
                          <input
                            type="text"
                            class="form-control"
                            id="sort_order"
                            v-model="read_time"
                          />
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="formgroup">
                          <label>Blog Written By</label>
                          <input
                            type="text"
                            class="form-control"
                            id="sort_order"
                            v-model="blog_written_by"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row mt-3">
                    <!-- <div class="col-sm-6">
                      <div class="form-group">
                      <label
                        class="control-label"
                      >Select Medicines</label>
                      <multiselect
                        v-model="menuIds"
                        id="ajax"
                        label="cuisine_name"
                        track-by="cuisine_id"
                        :placeholder="trans.get('__JSON__.Search...')"
                        open-direction="bottom"
                        :options="cuisine"
                        :multiple="true"
                        :searchable="true"
                        :loading="isLoading"
                        :internal-search="true"
                        :clear-on-select="true"
                        :close-on-select="false"
                        :max-height="600"
                        :show-no-results="false"
                        :hide-selected="true"
                      >
                        <template slot="tag" slot-scope="{ option, remove }">
                          <span class="custom__tag">
                            <span>{{ option.cuisine_name }}</span>
                            <span> Dolo </span>
                            <span class="custom__remove" @click="remove(option)">❌</span>
                          </span>
                        </template>
                        <template slot="clear" slot-scope="props">
                          <div
                            class="multiselect__clear"
                            v-if="cuisine.length"
                            @mousedown.prevent.stop="clearAll(props.search)"
                          ></div>
                        </template>
                        <span
                          slot="noResult"
                        >{{trans.get('__JSON__Oops! No elements found. Consider changing the search query')}}</span>
                      </multiselect>
                    </div>
                    </div> -->
                    <div class="col-sm-3">
                      <div class="form-group">
                        <label class="control-label">Category</label>
                        <select class="custom-select" v-model="category" id="category" name="category" style="width: 80% !important;"> 
                          <option value="medicine">Medicine</option>
                          <option value="weight_loss">Weight Loss</option>
                          <option value="chronic_diseases">Chronic Diseases</option>
                          <option value="diabetic_care">Diabetic Care</option>
                          <option value="others">Others</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div class="make-defualt-switch">
                        <b-form-group
                                class="text-end"
                                id="input-group-1"
                                :label="trans.get('__JSON__.Status')"
                                label-for="status"
                            >
                            <label class="switch rightmargin">
                            <input
                                type="checkbox"
                                id="status"
                                name="status"
                                v-model="status"
                                class="switch-on"
                                true-value="1"
                                false-value="0"
                            />
                            <div class="slider round">
                                <span class="on">{{trans.get("__JSON__.Active")}}</span>
                                <span class="off">{{ trans.get("__JSON__.Inactive")}}</span>
                            </div>
                            </label>
                        </b-form-group>
                      </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="formgroup">
                          <label class="required"> Blog Image </label>
                            <div class="d-flex">
                                <label
                                class="filelabel"
                                :style="{
                                    display:
                                      menuimageurl ? 'none' : 'block',
                                }"
                                ><i class="bx bx-plus plus" style="cursor: pointer; line-height: 1 !important;"
                                    ><input
                                    accept="image/jpeg,image/png,image/jpg,image/svg+xml,.webp,image/webp"
                                    @change="uploadmenuImage"
                                    class="FileUpload1"
                                    id="FileInput"
                                    ref="menuimagefileInput"
                                    type="file" 
                                    :class="{ 'is-invalid': $v.previewmenuimage.$error }"
                                    /></i
                                ></label>
                                <div class="uploaded-img-main">
                                <div
                                    v-if="menuimageurl"
                                    class="prvie-img uploaded-img"
                                >
                                    <p
                                    class="remove-flg"
                                    @click="removeUploadmenuImage"
                                    >
                                    X
                                    </p>
                                    <img
                                    v-if="
                                        this.selectedmenuimage.type == 'image/jpeg' ||
                                        this.selectedmenuimage.type == 'image/png' ||
                                        this.selectedmenuimage.type == 'image/jpg' ||
                                        this.selectedmenuimage.type == 'image/svg+xml'
                                    "
                                    :src="menuimageurl"
                                    class="dis-img"
                                    />
                                </div>
                                
                                </div>
                            </div>
                            <div v-if="$v.previewmenuimage.$error" style="font-size: 80%; color: #f46a6a;">
                              <span v-if="!$v.previewmenuimage.required">{{ trans.get('__JSON__.Please Select Blog Image') }}</span>
                            </div>
                        </div>
                    </div>                    
                  </div> 
                  <div class="row mt-2">
                    <div class="col-sm-12">
                        <div class="form-group">
                          <label class="required"> Blog Content </label>
                            <ckeditor
                                id="blog_content"
                                v-model="blog_content"
                                type="text"
                                class="form-control"
                                :placeholder="trans.trans('__JSON__.Blog Content')"
                                name="blog_content"
                                :editor="editor"
                                :class="{ 'is-invalid':$v.blog_content.$error }"
                            ></ckeditor>
                            <div v-if="$v.blog_content.$error" class="invalid-feedback">
                                <span v-if="!$v.blog_content.required">{{ trans.get('__JSON__.Please Enter Blog Content') }}</span>
                            </div>
                        </div>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col-sm-6">
                      <div class="formgroup">
                        <label>Meta Tag</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="meta_tag"
                          @input="checkMetaTagLength"
                        />
                        <small v-if="metaTitleWarning" class="text-danger">
                          Meta Title should be between 50-60 characters.
                        </small>
                        <small class="text-muted">{{ meta_tag.length }}/60</small>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="formgroup">
                        <label>Meta Description</label>
                        <textarea
                          class="form-control"
                          v-model="meta_description"
                          @input="checkMetaDescriptionLength"
                          rows="4"
                        ></textarea>
                        <small v-if="metaDescriptionWarning" class="text-danger">
                          Meta Description should be between 150-160 characters.
                        </small>
                        <small class="text-muted">{{ meta_description.length }}/160</small>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="formgroup">
                        <label>Meta Keywords</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="keywords"
                          @input="checkMetaKeywordsLength"
                        />
                        <small v-if="metaKeywordsWarning" class="text-danger">
                          Meta Keywords should be one word.
                        </small>
                        <small class="text-muted">{{ keywords.length }}/1</small>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-2">
                      <div style="font-size: 15px; font-weight: 600">Add FAQs</div>
                      <hr style="width: 100%; color: grey;">
                  </div>

                  <!-- FAQ List -->
                  <div v-for="(faq, index) in faqs" :key="index" class="row mt-3">
                      <div class="col-sm-4">
                          <div class="formgroup">
                              <label>FAQ Question</label>
                              <input
                                  type="text"
                                  class="form-control"
                                  v-model="faq.question"
                              />
                          </div>
                      </div>
                      <div class="col-sm-4">
                          <div class="formgroup">
                              <label>FAQ Answer</label>
                              <textarea
                                  class="form-control"
                                  v-model="faq.answer"
                                  rows="3"
                              ></textarea>
                          </div>
                      </div>
                      <div class="col-sm-4 d-flex align-items-center">
                        <button type="button" class="btn all-btn reject-btn" @click.prevent="removeFaq(index)" :disabled="index === 0"><i class="fas fa-times"></i></button>
                        <button type="button" class="btn all-btn accept-btn" style="margin-left: 6px;" @click.prevent="addFaq"><i class="fas fa-plus"></i></button>
                      </div>
                  </div>

                  <div class="row mt-3">
                    <button class="btn btn-primary mr-1 mt-3">Add Blog</button>
                    <button class="btn mt-3 btn-secondary" @click="cancelBlog"> Cancel</button>
                  </div>                
                </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  </template>
  
  <script>
  import Layout from "../../../layouts/main";
  import DropzonePopup from "../../../components/widgets/dropzone-popup";
  import vue2Dropzone from "vue2-dropzone";
  import { settingService, pageService } from "../../../services";
  import Multiselect from "vue-multiselect";
  import CKEditor from "@ckeditor/ckeditor5-vue";
  import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
  import { required } from "vuelidate/lib/validators";
  import { error_message, success_message } from "../../../Helper/helper";
  
  export default {
    components: { Layout, DropzonePopup, vueDropzone: vue2Dropzone, Multiselect,ckeditor: CKEditor.component},
    data() {
      return {
        blog_title: "",
        blog_url: "",
        status: "1",
        loading: false,
        menuimageurl: "",
        selectedmenuimage: "",
        previewmenuimage: "",
        menuimageformdata: "",
        menuIds:"",
        cuisine:[],
        isLoading: false,
        editor: ClassicEditor,
        blog_content:"",
        meta_tag:"",
        keywords:"",
        meta_description:"",
        category:"",
        read_time:"",
        blog_written_by:"",
        metaTitleWarning: false,
        metaDescriptionWarning: false,
        metaKeywordsWarning: false,
        faqs: [{ question: '', answer: '' }], 
      };
    },
    validations: {
      blog_title:{
        required,
      },
      blog_url:{
        required,
      },
      blog_content:{
        required,
      },
      previewmenuimage:{
        required,
      }
    },
    mounted() {
      
    },
    methods: {
        typeForm(e){
          this.$v.$touch();
          if(this.$v.$invalid){
            console.log(!this.$v.previewmenuimage.required, this.$v.previewmenuimage.$error, "validation");
            console.log("hiiii");
            return;
          }else{
            console.log(this.faqs, "faqs");
            this.loading = true;
            let data = {
              "blog_url": this.blog_url, 
              "blog_title" : this.blog_title,
              "blog_image" : this.previewmenuimage,
              "meta_tag" : this.meta_tag,
              "keywords" : this.keywords,
              "blog_content" : this.blog_content,
              "status" : this.status,
              "read_time" : this.read_time,
              "blog_written_by" : this.blog_written_by, 
              "category" : this.category,
              "read_time" : this.read_time,
              "blog_written_by" : this.blog_written_by,
              "meta_description": this.meta_description,
              "faqs": this.faqs
            };
            pageService.storeBlog(data).then((response) => {
              this.loading = false;
              console.log("nonon", response);
              if(response.data.code = 200){
                success_message(response.data.msg);
                this.blog_url = "";
                this.blog_title = "";
                this.previewmenuimage = "";
                this.meta_tag = "";
                this.keywords = "";
                this.blog_content = "";
                this.status = 1;
                this.read_time = "";
                this.blog_written_by = "";
                this.category = "";
                this.selectedmenuimage = "";
                this.menuimageurl = "";
                this.read_time = "";
                this.blog_written_by = "";
                this.meta_description = "";
                this.faqs = [{ question: '', answer: '' }];
                this.$v.$reset();
              }else{
                error_message(response.data.msg);
              }
              
            })
          }
        },
        uploadmenuImage(e){
          this.selectedmenuimage = e.target.files[0];
          console.log(this.selectedmenuimage, "sele");
          this.menuimageformdata = new FormData();
          this.menuimageformdata.append("blog_image", this.selectedmenuimage);
          this.menuimageurl = URL.createObjectURL(e.target.files[0]);
          if (this.selectedmenuimage) {
            settingService.Uploadprofilemenuimageicon(this.menuimageformdata)
            .then((response) => {
            console.log("responsemenuimageupload", response.data.result);
            this.previewmenuimage = response.data.result;
          });
          }
          if (!this.selectedmenuimage) {
            return;
          }
          e.target.value = null;
        },
        openmenuimageFileInput(){

        },
        removeUploadmenuImage(){
          if (this.previewmenuimage) {
            let obj = {
              blog_image: this.previewmenuimage,
            };
          console.log("image remove api here");
          settingService.Removeprofilemenuiconimage(obj).then((response) => {
            console.log("response", response.data);
          });
          }
          this.selectedmenuimage = "";
          this.menuimageurl = "";
          this.previewmenuimage = "";
        },
        remove(){

        },
        clearAll(){

        },
        cancelBlog(){
          this.$router.push({ name: "blog" });
        },
        checkMetaTagLength() {
          this.metaTitleWarning = this.meta_tag.length < 50 || this.meta_tag.length > 60;
        },
        checkMetaDescriptionLength() {
          this.metaDescriptionWarning = this.meta_description.length < 150 || this.meta_description.length > 160;
        },
        checkMetaKeywordsLength() {
          this.metaKeywordsWarning = this.keywords.split(' ').length > 1;
        },
        addFaq() {
            this.faqs.push({ question: '', answer: '' });
        },
        removeFaq(index) {
            this.faqs.splice(index, 1);
        },
    },
  };
  </script>
  
  
  <style>

  .status label:first-child {
    text-align: end !important;
  }
  .status div:first-child {
    text-align: end !important;
  }
  .pmenuavatar {
    margin-bottom: 15px !important;
  }
  
  .remove2 {
    position: absolute;
    cursor: pointer;
    text-align: center;
    left: 3px;
    top: 3px;
    color: white;
    background: red;
    height: 20px;
    padding-top: 0px;
    border-radius: 50%;
    width: 20px;
    font-size: 16px;
    box-shadow: 0px 0px 5px black;
  }
  
  .filelabel {
    background: var(--secondarylight);
    min-height: 85px;
    min-width: 85px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    margin-bottom: 0;
    margin-right: 10px;
  }
  
  #FileInput {
    display: none;
  }
  
  .uploaded-img-main {
    display: flex;
    align-items: center;
    gap: 15px;
    flex-wrap: wrap;
    position: relative;
  }
  .uploaded-img {
    position: relative;
  }
  
  .uploaded-img p {
    margin-bottom: 0;
    position: absolute;
    color: #fff;
    right: -8px;
    top: -8px;
    background: red;
    z-index: 2;
    height: 20px;
    width: 20px;
    object-fit: contain;
    font-size: 14px;
    display: flex;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
  }
  
  p.remove-flg {
    cursor: pointer;
  }
  .dis-img {
    height: 100px;
    display: flex;
    margin: 15px;
    cursor: pointer;
  }
  .make-defualt-switch input.switch-on:checked + .slider:before {
    transform: translateX(47px) !important;
  }
  .make-defualt-switch .switch {
      width: 77px !important;
  }
  .all-btn {
    color: #fff;
    font-size: 16px;
    font-weight: 100;
    line-height: 0;
    border-radius: 50%;
  }
  .accept-btn {
    background-color: #556ee6;
    padding: 5px;
  }
  .reject-btn {
    background-color: #f46a6a;
    padding: 5px 7px 5px 7px;
  }
  </style>

  