<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";

import FaqList from "./faq-list";

/**
 * FAQs component
 */
export default {
  components: { Layout, PageHeader, FaqList },
  data() {
    return {};
  },
};
</script>
<template>
  <Layout>
    <faq-list />
  </Layout>
</template>