<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import pagination from "laravel-vue-pagination";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import {
  get_partner_login,
  set_partner,
  set_partner_details,
  set_user,
  set_user_detail,
  debounce,
  get_decimal,
  get_currency,
  date_ranges
} from "../../Helper/helper";
import moment from "moment";
import { reportService } from "../../services";
/**
 * Report component
 */
export default {
  components: { Layout, PageHeader, pagination, DateRangePicker },
  data() {
    let startDate = this.moment().startOf('day').subtract(29, "days");
    let endDate = this.moment().endOf("day").toDate();
    return {
      decimal: get_decimal(),
      dateRange: { startDate, endDate },
      driverReport: [],
      users: [],
      date_ranges: date_ranges,
      user_count: "",
      frenchise_id: "",
      users_id: "",
      sortBy: "driverid",
      sortDesc: true,
      paginations: {},
      limit: 2,
      perPage: 0,
      currentPage: 1,
      currency: get_currency(),
      loading: true,
      filters: {
        start_date: this.moment().subtract(6,'days').format('YYYY-MM-DD'),
        end_date: this.moment().format('YYYY-MM-DD'),
      },
      fields: [
        { key: "driverid", label: "Driver ID", sortable: true },
        { key: "driver_name", label: "Name", sortable: true },
        { key: "email", label: "Email", sortable: true },
        { key: "contactno", label: "Conatct No", sortable: true },
        { key: "order_count", label: "# Of Order",sortable: true },
        { key: "total_wallet", label: "Wallet", sortable: true },
        { key: "driver_earning", label: "Earning", sortable: true }
      ],
      isDownloading: false,
      reportsToCSVData: []
    };
  },
  mounted() {
    this.$data.fields[0].label = this.$auth.getDriverName() +' ID';
    this.getDriverReport();
    this.getAllUserList();
  },
  methods: {
    updateValues(date) {
      this.filters = {
        ...this.filters,
        start_date :  this.moment(date.startDate.toISOString()).format('YYYY-MM-DD'),
        end_date :  this.moment(date.endDate.toISOString()).format('YYYY-MM-DD'),
      }
      this.getDriverReport();
    },
    dateFormat(classes, date) {
      this.filters = {
        ...this.filters,
        start_date: this.moment(date.startDate.toISOString()).format(
          "YYYY-MM-DD"
        ),
        end_date: this.moment(date.endDate.toISOString()).format("YYYY-MM-DD")
      };
    },
    getDriverReport(page) {
      this.loading = true;
      reportService
        .getDriverReportListing({
          ...this.filters,
          sortBy: this.sortBy,
          orderBy: this.sortDesc ? "asc" : "desc",
          frenchise_id : this.users_id,
          page: page ? page : 1,
        })
        .then((response) => {
          this.loading = false;
          this.paginations = response.data.result;
          this.driverReport = response.data.result.data;
        });
    },
    getAllUserList(){
      reportService.getAllUser().then((response) => {
            this.user_count = response.data.result.length;
            this.users = response.data.result;
            if (this.user_count == 1) {
              this.users_id = this.users[0].user_id;
            }
            this.searchData();
          });
    },
    searchData() {
      this.getDriverReport();
    },
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      this.getDriverReport();
    },
    driverExportCsv() {
      this.isDownloading = true;
      reportService
      .driverReportExportToCsv({ 
        ...this.filters,
        sortBy: this.sortBy,
        orderBy: this.sortDesc ? "asc" : "desc",
        frenchise_id : this.users_id,
      })
      .then((response) => {
        this.isDownloading = false;
        this.reportsToCSVData = response.data.result;
        this.downloadsCSV();
      });
    },
    downloadsCSV: function () {
      let reportsData = '\ufeff' + 'Driver ID,Name,Email,Conatct No,# Of Order,Wallet,Earning\n';
      this.reportsToCSVData.forEach((el) => {
        var line = el["driverid"] + "," + el["driver_name"] + "," + el["email"] + "," + el["contactno"] + "," + el["order_count"] +
          "," + el["total_wallet"].toFixed(this.decimal) + "," +  el["driver_earning"].toFixed(this.decimal) + "\n";
        reportsData += line;
      });
      var blob = new Blob([reportsData], { type: "csv/plain" });
      var date = new Date().toLocaleString();
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "driver_" + date + ".csv";
      link.click();
    }
  },
  filters: {
      date(val) {
        return val ? val.toLocaleString() : "";
      },
    },
};
</script>

<template>
  <Layout>
    <div>
      <div class="row">
        <div class="col-12">
          <div class="page-title-box d-flex align-items-center justify-content-between icon-main-page">
            <div class="page-title-left avatar-main-icon">
              <div class="avatar-xs mr-3">
                <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                    <i class="bx bx-purchase-tag"></i>
                </span>
              </div>
              <div class="report-tag">
                <h4 class="mb-0 font-size-18">{{ trans.trans('__JSON__.Driver Report',{ driver: $auth.setting.driver_name}) }}</h4>
                <p><router-link :to="{ name: 'reports-list'}">{{ trans.get('__JSON__.Reports') }}</router-link> > {{ trans.trans('__JSON__.Driver Report',{ driver: $auth.setting.driver_name}) }}</p>
              </div>
            </div>
            <div class="page-title-right">
              <button @click="driverExportCsv" class="btn btn-primary btn-rounded export-btn" v-if="isDownloading" disabled>{{ trans.get('__JSON__.Exporting...') }}</button>
              <button @click="driverExportCsv" class="btn btn-primary btn-rounded export-btn" v-else>{{ trans.get('__JSON__.Export To CSV') }}</button>
              <button @click="driverExportCsv" class="btn btn-primary btn-rounded m-export-btn"><i class="bx bx-archive-in"></i></button>
            </div>
          </div>
        </div>
      </div>

      <!-- end row -->
      <div class="row">
        <div class="col-12 list-table-main">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="main-sales-report m-driver-report">
                  <div v-if="user_count > 1" class="v-sales-report m-sales-driver-report">
                    <div class="form-group">
                      <div class="date-range-list">
                        <label>{{ trans.get('__JSON__.Delivery Zone',{ vendor: $auth.setting.restaurant_name }) }} :</label>
                        <select class="custom-select" v-model="users_id" @change="searchData()">
                          <option value selected>{{ trans.get('__JSON__.Delivery Zone',{ vendor: $auth.setting.restaurant_name }) }}</option>
                          <option v-for="res in users" :value="res.user_id" :key="res.user_id">{{res.last_name}}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="v-driver-report-date">
                    <div class="form-group">
                      <div class="date-range-list">
                        <label>{{trans.get('__JSON__.Date')}}:</label>
                        <date-range-picker
                          ref="picker"
                          :locale-data="{
                            firstDay: 1,
                            format: 'dd-mm-yyyy',
                          }"
                          :showDropdowns="true"
                          :showWeekNumbers="true"
                          opens="left"
                          v-model="dateRange"
                          @update="updateValues"
                        >
                          <template v-slot:input="picker" style="min-width: 350px">
                            {{ moment(picker.startDate.toISOString()).format('MMMM DD, YYYY') | date }} -
                            {{ moment(picker.endDate.toISOString()).format('MMMM DD, YYYY') | date }}
                          </template>
                        </date-range-picker>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 table-main-list driver-report-table table-responsive">
                  <b-table
                    :items="driverReport"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :no-local-sorting="true"
                    @sort-changed="sortingChanged"
                    :busy="loading"
                    show-empty
                  >
                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>{{trans.get('__JSON__.Loading...')}}</strong>
                      </div>
                    </template>

                    <template v-slot:cell(driverid)="data">
                        <div class="text-center">{{ data.item.driverid }}</div>
                    </template>

                    <template v-slot:cell(firstname)="data">
                      <h5 class="font-size-14">{{ data.item.firstname }} {{ data.item.lastname }}</h5>
                    </template>

                    <template #cell(total_wallet)="data">
                        <span v-html="currency"></span> {{ data.item.total_wallet.toFixed(decimal) }}
                    </template>

                    <template #cell(driver_earning)="data">
                        <span v-html="currency"></span> {{ data.item.driver_earning.toFixed(decimal) }}
                    </template>

                    <template #empty>
                      <p class="text-center">{{ trans.get('__JSON__.No Driver Found') }}</p>
                    </template>
                  </b-table>
                </div>
                <div class="row col-sm-5 offset-sm-7">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <pagination
                          :data="paginations"
                          :limit="limit"
                          @pagination-change-page="getDriverReport"
                        ></pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style>
</style>