<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import DateRangePicker from "vue2-daterange-picker";
import pagination from "laravel-vue-pagination";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import {
  get_partner_login,
  set_partner,
  set_partner_details,
  set_user,
  set_user_detail,
  debounce,
  get_decimal,
  get_currency,
  date_ranges
} from "../../Helper/helper";
import moment from "moment";
import { reportService, deliveryZoneService } from "../../services";
/**
 * Report component
 */
export default {
  components: { Layout, PageHeader, pagination, DateRangePicker },
  data() {
    let startDate = this.moment().startOf("day").subtract(6, "days");
    let endDate = this.moment().endOf("day").toDate();
    return {
      decimal: get_decimal(),
      totalSale: {},
      restaurant: [],
      totalSaleReport: [],
      restaurant_count: "",
      restaurants_id: "",
      sortBy: "id",
      sortDesc: true,
      paginations: {},
      limit: 2,
      perPage: 0,
      currentPage: 1,
      currency: get_currency(),
      countries: [],
      country: 0,
      states: [],
      state: 0,
      cities: [],
      city: 0,
      country_count: "",
      state_count: "",
      city_count: "",
      loading: true,
      isDownloading: false,
      dateRange: { startDate, endDate },
      filters: {
        start_date: this.moment().subtract(6,'days').format('YYYY-MM-DD'),
        end_date: this.moment().format('YYYY-MM-DD'),
      },
      date_ranges: date_ranges,
      fields: [
        { key: "user_id", label: "#", sortable: false },
        { key: "id", label: "Order ID", sortable: true },
        { key: "user_name", label: "First Name", sortable: false },
        { key: "name", label: "Vendor Name", sortable: false },
        { key: "total_amount", label: "Total Amount", sortable: true },
        { key: "admin_earning", label: "Admin Earning", sortable: true },
        { key: "earning", label: "Vendor Earning", sortable: true },
      ],
    };
  },
  mounted() {
    this.$data.fields[3].label = this.$auth.getRestaurantName() +' Name';
    this.$data.fields[6].label = this.$auth.getRestaurantName() +' Earning';
    // this.getTotalSalesReports();
    this.getVendorCountry();
    // this.getRestaurants();
    // this.totalSaleExportCsv();
  },
  methods: {
    downloadsCSV: function () {
      let totalSaleReport =
        '\ufeff' +
        'Order ID,First Name,'+this.$auth.getRestaurantName()+' Name,Total Amount,Admin Earning,'+this.$auth.getRestaurantName()+' Earning\n'
      this.totalSaleReport.forEach((el) => {
        let orderIdText = el["id"];
        if (el.parent_id && el.parent_id > 0) {
          orderIdText = el.parent_id+' Ship. 1';
        } else if (el.suborder && el.suborder.id && el.suborder.id > 0) {
          orderIdText = el["id"]+' Ship. 2';
        }
        var line =
          orderIdText +
          "," +
          el["customer_name"] +
          "," +
          el["name"] +
          "," +
          el["total_amount"].toFixed(2) +
          "," +
          el["admin_earning"].toFixed(2) +
          "," +
          el["earning"].toFixed(2) +
          "\n"
        totalSaleReport += line;
      });

      var blob = new Blob([totalSaleReport], { type: "csv/plain" });
      var date = new Date().toLocaleString();
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "sales_" + date + ".csv";
      link.click();
    },
    updateValues(date) {
      this.filters = {
        ...this.filters,
        start_date: this.moment(date.startDate.toISOString()).format(
          "YYYY-MM-DD"
        ),
        end_date: this.moment(date.endDate.toISOString()).format("YYYY-MM-DD"),
      };
      this.getTotalSalesReports();
      // this.totalSaleExportCsv();
    },
    dateFormat(classes, date) {
      this.filters = {
        ...this.filters,
        start_date: this.moment(date.startDate.toISOString()).format(
          "YYYY-MM-DD"
        ),
        end_date: this.moment(date.endDate.toISOString()).format("YYYY-MM-DD"),
      };
    },
    getTotalSalesReports(page) {
      this.loading = true;
      reportService
      .gettotalSaleReport({
        ...this.filters,
        sortBy: this.sortBy,
        orderBy: this.sortDesc ? "asc" : "desc",
        country: this.country,
        state: this.state,
        city: this.city,
        restaurants_id: this.restaurants_id,
        page: page ? page : 1,
      })
      .then((response) => {
        this.loading = false;
        // this.paginations = response.data.result;
        this.totalSale = response.data.result;
      });
    },
    totalSaleExportCsv() {
      this.isDownloading = true;
      reportService
      .totalSalesExportToCSV({ 
          ...this.filters,
        restaurants_id: this.restaurants_id,
        sortBy: this.sortBy,
        orderBy: this.sortDesc ? "asc" : "desc",
      })
      .then((response) => {
        this.isDownloading = false;
        this.totalSaleReport = response.data.result;
        this.downloadsCSV();
      });
    },
    getVendorCountry() {
      this.country = 0;
      this.city = 0;
      this.state = 0;
      this.countries = [];
      this.states = [];
      this.cities = [];
      deliveryZoneService.getVendorCountry().then((response) => {
        this.countries = response.data.result;
        if (this.countries.length == 1) {
          this.country = this.countries[0].id;
        }
        this.getVendorState();
      });
    },
    getRestaurants(){
      this.restaurant = [];
      reportService.getAllRestaurantName({
        country_id: this.country,
        state_id: this.state,
        city_id: this.city
      }).then((response) => {
        this.restaurant = response.data.result;
        this.restaurant_id = this.restaurant[0].restaurant_id;
        this.searchData();
      });
    },
    getVendorState() {
      this.city = 0;
      this.state = 0;
      this.states = [];
      this.cities = [];
      this.country = parseInt(this.country);
      if(this.country > 0) {
        deliveryZoneService.getVendorState({country_id: this.country})
        .then((response) => {
          this.states = response.data.result;
          if (this.states.length == 1) {
            this.state = this.states[0].id;
          }
          this.getVendorCity();
        });
      } else {
        this.searchData();
      }
    },
    getVendorCity() {
      this.city = 0;
      this.cities = [];
      this.state = parseInt(this.state);
      if(this.state > 0) {
        deliveryZoneService.getVendorCity({state_id:this.state ? this.state : 0,country_id: this.country})
        .then((response) => {
          this.cities = response.data.result;
          if (this.cities.length == 1) {
            this.city = this.cities[0].cityid;
          }
          this.searchData();
          this.getRestaurants();
        });
      } else {
        this.searchData();
        this.getRestaurants();
      }
    },
    citychange() {
      this.searchData();
      this.getRestaurants();
    },
    searchData() {
      this.getTotalSalesReports();
      // this.totalSaleExportCsv();
    },
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      this.getTotalSalesReports();
      // this.totalSaleExportCsv();
    },
  },
  filters: {
    date(val) {
      return val ? val.toLocaleString() : "";
    },
  },
};
</script>

<template>
  <Layout>
    <div>
      <div class="row">
          <div class="col-12">
              <div
              class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
              >
              <div class="page-title-left avatar-main-icon">
                  <div class="avatar-xs mr-3">
                  <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                      <i class="bx bx-purchase-tag"></i>
                  </span>
                  </div>
                  <div class="report-tag">
                    <h4 class="mb-0 font-size-18">{{ trans.get('__JSON__.Total Sales Vs Earning Report') }}</h4>
                    <p><router-link :to="{ name: 'reports-list'}">{{ trans.get('__JSON__.Reports') }}</router-link> > {{ trans.get('__JSON__.Total Sales Vs Earning Report') }}</p>
                  </div>
              </div>
              <div class="page-title-right">
                <button @click="totalSaleExportCsv" class="btn btn-primary btn-rounded export-btn" v-if="isDownloading" disabled>{{ trans.get('__JSON__.Exporting...') }}</button>
                <button @click="totalSaleExportCsv" class="btn btn-primary btn-rounded export-btn" v-else>{{ trans.get('__JSON__.Export To CSV') }}</button>
                <button @click="totalSaleExportCsv" class="btn btn-primary btn-rounded m-export-btn"><i class="bx bx-archive-in"></i></button>
              </div>
              </div>
          </div>
      </div>

      <!-- end row -->
      <div class="row">
        <div class="col-12 list-table-main">
          <div class="card">
            <div class="card-body">
              <div class="row justify-content-between">
                <div v-if="countries.length >1" class="total-sales-report-earning">
                  <div class="form-group">
                    <div class="date-range-list">
                      <label>{{ trans.get('__JSON__.Country') }} :</label>
                      <select class="custom-select" v-model="country" @change="getVendorState">
                        <option value="0" selected>All</option>
                        <option
                          v-for="con in countries"
                          :value="con.id"
                          :key="con.id"
                        >{{con.country_name}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div v-else class="offset-sm-2">
                </div>
                <div v-if="states.length >1" class="total-sales-report-earning">
                  <div class="form-group">
                    <div class="date-range-list">
                      <label>{{ trans.get('__JSON__.State') }} :</label>
                      <select class="custom-select" v-model="state" @change="getVendorCity">
                        <option value="0" selected>All</option>
                        <option
                          v-for="sat in states"
                          :value="sat.id"
                          :key="sat.id"
                        >{{sat.state_name}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div v-else class="offset-sm-2">
                </div>
                <div v-if="cities.length >1" class="total-sales-report-earning">
                  <div class="form-group">
                    <div class="date-range-list">
                      <label>{{ trans.get('__JSON__.City') }} :</label>
                      <select class="custom-select" v-model="city" @change="citychange">
                        <option value="0" selected>All</option>
                        <option
                          v-for="cit in cities"
                          :value="cit.cityid"
                          :key="cit.cityid"
                        >{{cit.cityname}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div v-else class="offset-sm-2">
                </div>
                 <div v-if="(countries.length >1 || states.length > 1 || cities.length > 1) && restaurant.length > 1" class="total-sales-report-earning">
                  <div class="form-group">
                    <div class="date-range-list">
                      <label>{{ trans.trans('__JSON__.Vendor',{ vendor: $auth.setting.restaurant_name }) }} :</label>
                      <select class="custom-select" v-model="restaurants_id" @change="searchData()">
                        <option value selected>{{ trans.trans('__JSON__.All Vendor',{ vendor: $auth.setting.restaurant_name }) }}</option>
                        <option v-for="res in restaurant" :value="res.restaurant_id" :key="res.restaurant_id">{{res.name}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="total-sales-report-earning">
                  <div class="form-group">
                    <div class="date-range-list">
                      <label>{{trans.get('__JSON__.Date')}}:</label>
                         <date-range-picker
                        ref="picker"
                        :locale-data="{
                          firstDay: 1,
                          format: 'dd-mm-yyyy',
                        }"
                        :showDropdowns="true"
                        :showWeekNumbers="true"
                        opens="left"
                        v-model="dateRange"
                        :ranges="date_ranges()"
                        @update="updateValues"
                      >
                        <template
                          v-slot:input="picker"
                          style="min-width: 350px"
                        >
                          {{ moment(picker.startDate.toISOString()).format('MMMM DD, YYYY') | date }} -
                          {{ moment(picker.endDate.toISOString()).format('MMMM DD, YYYY') | date }}
                        </template>
                      </date-range-picker>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 table-main-list total-sales-report-table table-responsive">
                  <b-table
                    :items="totalSale.data"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :no-local-sorting="true"
                    @sort-changed="sortingChanged"
                    :busy="loading"
                    show-empty
                  >
                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>{{trans.get('__JSON__.Loading...')}}</strong>
                      </div>
                    </template>

                    <template v-slot:cell(user_id)="data">
                      <div>{{ (totalSale.per_page * (totalSale.current_page-1)) + data.index + 1 }}</div>
                    </template>

                    <template v-slot:cell(id)="data">
                      <!-- <div>{{ data.item.id }}</div> -->
                      <template v-if="data.item.parent_id && data.item.parent_id > 0">
                        <div>{{data.item.parent_id}}</div> Ship. 1
                      </template>
                      <template v-else-if="data.item.suborder && data.item.suborder.id && data.item.suborder.id > 0">
                        <div>{{data.item.id}}</div> Ship. 2
                      </template>
                      <template v-else>
                        <div>{{data.item.id}}</div>
                      </template>
                    </template>

                    <template v-slot:cell(user_name)="data">
                      <span>{{ data.item.user_name }} {{ data.item.last_name }}</span>
                    </template>

                    <template v-slot:cell(name)="data">
                      <span>{{ data.item.name }}</span>
                    </template>

                    <template v-slot:cell(total_amount)="data">
                      <span v-html="currency"></span>
                      {{data.item.total_amount.toFixed(2) || '0.00' }}
                    </template>

                    <template v-slot:cell(admin_earning)="data">
                      <span v-html="currency"></span>
                      {{data.item.admin_earning.toFixed(2) || '0.00' }}
                    </template>

                    <template v-slot:cell(earning)="data">
                      <span v-html="currency"></span>
                      {{data.item.earning.toFixed(2) || '0.00' }}
                    </template>

                    <template #empty>
                      <p class="text-center">{{ trans.get('__JSON__.No Sales Report Found') }}</p>
                    </template>
                  </b-table>
                </div>
                <div class="row col-sm-5 offset-sm-7">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <pagination
                          :data="totalSale"
                          :limit="limit"
                          @pagination-change-page="getTotalSalesReports"
                        ></pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>


<style>
.total-sales-report-table .table thead th:nth-child(1),.total-sales-report-table .table thead th:nth-child(3),.total-sales-report-table .table thead th:nth-child(4){
    pointer-events: none;
}
</style>