<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";

import EditFaq from "./edit-faq";
/**
 * FAQ component
 */
export default {
  components: { Layout, PageHeader, EditFaq },
  data() {
    return {};
  },
};
</script>

<template>
  <Layout>
     <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
        >
          <div class="page-title-left avatar-main-icon">
            <div class="avatar-xs mr-3">
              <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                <i class="bx bx-question-mark"></i>
              </span>
            </div>
             <div class="report-tag">
              <h4 class="mb-0 font-size-18">{{ trans.trans('__JSON__.Edit FAQ') }}</h4>
              <p><router-link :to="{ name: 'faq-list'}">{{ trans.get('__JSON__.FAQs') }}</router-link> > {{ trans.get('__JSON__.Edit FAQ') }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <edit-faq />
  </Layout>
</template>
