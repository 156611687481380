<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import { businessTypeService } from "../../services";
import { required } from "vuelidate/lib/validators";
import { success_message, error_message } from "../../Helper/helper";
import General from "./general.vue";
import App from "./app.vue";
import Web from "./web.vue";
import Terminology from "./terminology/general.vue";

/**
 * Add-product component
 */
export default {
  components: {
    Layout,
    PageHeader,
    businessTypeService,
    General,
    App,
    Web,
    Terminology
  },
  data() {
    return {
      typeform: {
        id: "",
        name: "",
        status: "",
      },
      id: "",
      business: {},
    };
  },
  validations: {
    typeform: {
      name: {
        required,
      },
    },
  },
  mounted() {
    this.getBusinessTypeById();
  },
  methods: {
    getBusinessTypeById() {
      businessTypeService
        .getBusinessTypeById({ id: this.$route.params.id })
        .then((response) => {
          const { id, name, status } = response.data.result.business;
          this.id = response.data.result.business.id;
          this.business = response.data.result.business;
          this.typeform = {
            id,
            name,
            status,
          };
        });
    },
    businessInIt() {
      this.$v.typeform.$touch();

      if (this.$v.typeform.$invalid) {
        return;
      } else {
        const fd = new FormData();
        fd.append("id", this.typeform.id);
        fd.append("name", this.typeform.name);
        fd.append("status", this.typeform.status);
        businessTypeService.updateBusinessTypeById(fd).then((response) => {
          if (response.data.code === 200) {
            success_message(response.data.message);
          } else {
            error_message(response.data.message);
          }
        });
      }
    },
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div
          class="
            page-title-box
            d-flex
            align-items-center
            justify-content-between
            icon-main-page
          "
        >
          <div class="page-title-left avatar-main-icon">
            <div class="avatar-xs mr-3">
              <span
                class="
                  avatar-title
                  rounded-circle
                  bg-soft-primary
                  text-primary
                  font-size-18
                "
              >
                <i class="bx bx-user"></i>
              </span>
            </div>
            <div class="report-tag">
              <h4 class="mb-0 font-size-18">
                {{ trans.get("__JSON__.Edit Business Type") }}
              </h4>
              <p>
                <router-link :to="{ name: 'business-type-list' }">{{
                  trans.get("__JSON__.Business Type")
                }}</router-link>
                > {{ business.name }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="">
          <div class="">
            <form @submit.prevent="businessInIt">
              <!-- Business Info Section Start -->
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title">
                    {{ trans.get("__JSON__.Business Type Info") }}
                  </h4>
                  <p class="card-title-desc"></p>
                  <div class="row">
                    <input type="hidden" name="id" v-model="typeform.id" />
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label class="control-label">{{
                          trans.get("__JSON__.Business Type Name")
                        }}</label>
                        <input
                          id="name"
                          name="name"
                          v-model="$v.typeform.name.$model"
                          type="text"
                          :placeholder="
                            this.trans.get(
                              '__JSON__.Business Type Name Placeholder'
                            )
                          "
                          class="form-control"
                          :class="{ 'is-invalid': $v.typeform.name.$error }"
                        />
                        <div
                          v-if="$v.typeform.name.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.typeform.name.required"
                            >{{
                              trans.get(
                                "__JSON__.Please enter business type name"
                              )
                            }}.</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <b-form-group
                        id="input-group-1"
                        :label="trans.get('__JSON__.Status')"
                        label-for="status"
                      >
                        <label class="switch">
                          <input
                            type="checkbox"
                            id="togBtn"
                            name="status"
                            v-model="typeform.status"
                            class="switch-on"
                            true-value="0"
                            false-value="1"
                          />
                          <div class="slider round">
                            <span class="on">{{
                              trans.get("__JSON__.On")
                            }}</span>
                            <span class="off">{{
                              trans.get("__JSON__.Off")
                            }}</span>
                          </div>
                        </label>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6">
                      <button class="btn btn-primary mr-1 mt-3">
                        {{ trans.get("__JSON__.Update Business Type") }}
                      </button>
                      <b-button
                        @click="$router.back()"
                        type="button"
                        variant="secondary"
                        class="mt-3"
                        >{{ trans.get("__JSON__.Cancel") }}</b-button
                      >
                    </div>
                  </div>
                </div>
              </div>
              <!-- Business Info Section End -->
            </form>
          </div>

          <div class="row">
            <div class="col-lg-12">
              <div class="card">
                <div class="card-body">
                  <!-- Tab panel start-->
                  <b-tabs pills justified content-class="p-3 text-muted">
                    <b-tab active class="border-0">
                      <template v-slot:title>
                        <span class="d-inline-block d-sm-none">
                          <i class="fas fa-home"></i>
                        </span>
                        <span class="d-none d-sm-inline-block">{{trans.get('__JSON__.General Setting')}}</span>
                      </template>
                      <General />
                    </b-tab>
                    <b-tab>
                      <template v-slot:title>
                        <span class="d-inline-block d-sm-none">
                          <i class="far fa-user"></i>
                        </span>
                        <span class="d-none d-sm-inline-block">{{trans.get('__JSON__.App Layout Settings')}}</span>
                      </template>
                      <App />
                    </b-tab>
                    <b-tab>
                      <template v-slot:title>
                        <span class="d-inline-block d-sm-none">
                          <i class="far fa-envelope"></i>
                        </span>
                        <span class="d-none d-sm-inline-block">{{trans.get('__JSON__.Web Layout Settings')}}</span>
                      </template>
                      <Web />
                    </b-tab>
                    <b-tab>
                      <template v-slot:title>
                        <span class="d-inline-block d-sm-none">
                          <i class="fas fa-cog"></i>
                        </span>
                        <span class="d-none d-sm-inline-block">{{trans.get('__JSON__.Terminology')}}</span>
                      </template>
                      <Terminology />
                    </b-tab>
                  </b-tabs>
                  <!-- Tab panel end -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
