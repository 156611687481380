import config from "./index";

const routes = {
    //login
    login: config.api_url + "login",

    //signup
    signupApi: config.api_url + "signup",

    //logout
    logout: config.api_url + "logout",

    //UploadDocument
    uploadImageApi: config.api_url + "upload-image",
    removeFileApi: config.api_url + "remove-File",
    uploadDocumentApi: config.api_url + "upload-document",
    uploadApi: config.api + "upload",
    uploadDriveProfileApi: config.api_url + "update-driver-profile",
    uploadProfileImageApi: config.api + "update-driver-profile",

    //Setting
    getSettingApi: config.api_url + "get-setting",
    updateSettingApi: config.api_url + "update-setting",
    getAppLayoutSettingApi: config.api_url + "get-app-layout-setting",
    updateAppLayoutApi: config.api_url + "update-app-layout-setting",
    updateGeneralTerminologyApi:
        config.api_url + "update-general-terminology-setting",
    getOrderNotificationTextApi: config.api_url + "get-order-notification",
    updateOrderTextApi: config.api_url + "update-text-notification",
    updatepaymentgatewayApi: config.api_url + "update-payment-gateway",
    getVendorSettingByIdApi: config.api_url + "get-setting-by-id",
    getTaxSlabApi: config.api_url + "get-taxslab",
    getPaymentGatewayList: config.api_url + "get-payment-gatewaylist",

    //UserDetail
    userDetailApi: config.api_url + "user-detail",
    userCheckEmailApi: config.api_url + "user-check-email",

    //ApiText
    getApiTextApi: config.api_url + "get-api-text",
    addApiTextApi: config.api_url + "add-api-text",
    updateApiTextApi: config.api_url + "update-api-text",

    //Customer
    getCustomerApi: config.api_url + "get-customer",
    customerExportToCsvApi: config.api_url + "customer-export-csv",
    getCustomerProfileApi: config.api_url + "get-customer-profile",
    updateCustomerProfileApi: config.api_url + "update-customer-profile",
    getCustomerOrderApi: config.api_url + "get-customer-order",
    changeCusPasswordApi: config.api_url + "change-cust-password",
    getPriceRequestApi: config.app_api_url + "getRequestListWeb",
    updateRequestPriceApi: config.app_api_url + "updateRequest",

    //affiliate
    getAffiliatesApi: config.app_api_url + "get_affiliates",
    getRefferalHistoryAffiliateApi: config.app_api_url + "get-referred-history-affiliate",
    getAffiliatesCountApi: config.app_api_url + "affiliate-count",
    getAffiliateDetailsApi: config.app_api_url + "view_affiliate_by_id",
    changeAffiliateStatusApi: config.app_api_url + "change_affiliate_status",
    updateAffiliateDetailsApi: config.app_api_url + "affiliates",
    addAffiliateDetailsApi: config.app_api_url + "add_affiliate",
    getInstamedCashApi: config.app_api_url + "get-instamed-cash-affiliate",
    storeInstamedCashApi: config.app_api_url + "store-instamed-cash-affiliate",

    //brands
    getBrandDetailsApi: config.app_api_url + "get-brand-details",
    getBrandCountApi: config.app_api_url + "brand-count",
    postBrandDetailsApi: config.app_api_url + "add-brand-details",
    updateBrandDetailsApi: config.app_api_url + "update-brand-details",
    getBrandDetailsByIdApi: config.app_api_url + "get-brand-details-by-id/",

    //Driver
    getDriverApi: config.api_url + "get-driver",
    driverExportToCsvApi: config.api_url + "driver-export-csv",
    getDriverInfoApi: config.api_url + "get-driver-info",
    getFrenchiseApi: config.api_url + "get-frenchisee",
    addDriverApi: config.api_url + "add-driver",
    getDriverRatingApi: config.api_url + "get-driver-rating",
    updateDriveProfileApi: config.api_url + "update-driver-profile",
    driverProfileDeleteUrl: config.api_url + "driver/delete-profile-by-name",
    driverDocumentDeleteUrl: config.api_url + "driver/delete-document-by-name",
    driverLicenceDeleteUrl: config.api_url + "driver/delete-licence-by-name",
    driverByAvailabilityApi: config.api_url + "driver/availablilty/get",
    updateDriverStatusApi: config.api_url + "update-driver-status",
    updateAvailbilityStatusApi:
        config.api_url + "update-driver-availbilty-status",
    changeDriverPasswordApi: config.api_url + "change-driver-password",
    deleteDriverRatingApi: config.api_url + "delete-driver-rating",

    // getDriverDashboardApi: config.api_url+'get-driver-dashboard',
    // Eagleview
    eagleViewDriverListApi: config.api_url + "get-eagleview",

    //Vendor
    getVendorApi: config.api_url + "get-vendor",
    getVendorDetailApi: config.api_url + "restaurant-export-csv",
    getVendorCategoryApi: config.api_url + "get-vendor-category",
    getRestaurantCategoryApi: config.api_url + "get-res-category",
    getRestaurantCuisineApi: config.api_url + "get-res-cuisine",
    getRestaurantbyIdApi: config.api_url + "get-restaurant-detail-by-id",
    getRestaurantMenuCategory: config.api_url + "get-restaurant-category",
    changePasswordApi: config.api_url + "change-password",
    addVendorApi: config.api_url + "add-restaurant",
    addRestaurantCategoryApi: config.api_url + "add-menu-category",
    getMenuCategoryByIdApi: config.api_url + "get-category-menu-by-id",
    updateRestaurantCategoryApi: config.api_url + "update-menu-category",
    deleteMenuCategoryApi: config.api_url + "delete-menu-category",
    vendorImageDeleteUrl: config.api_url + "restaurant/delete-icon-by-name",
    categroyImageDeleteUrl:
        config.api_url + "restaurant/delete-category-image-by-name",
    getBusinessProfileApi: config.api_url + "get-business-profile",
    getLanguageApi: config.api_url + "get-language",
    updateBusinessProfileApi: config.api_url + "update-business-profile",
    getVendorLanguageApi: config.api_url + "get-vendor-language",
    getRestaurantMenuApi: config.api_url + "restaurant/menu",
    getItemByMenuIDApi: config.api_url + "restaurant/menu/item",
    deleteAppLogoApi: config.api_url + "delete-app-logo",
    deleteFlatIconApi: config.api_url + "delete-flat-icon",
    addMenuApi: config.api_url + "add-menu",
    editMenuApi: config.api_url + "edit-menu",
    getMenuByIdApi: config.api_url + "get-menu-by-id",
    resImageDeleteUrl: config.api_url + "delete-menu-image",
    updateMenuStatusApi: config.api_url + "update-menu-status",
    addItmeMenuApi: config.api_url + "add-menu-item",
    getResTimeSlotApi: config.api_url + "get-res-time-slot",
    getMenuCategoriesApi: config.api_url + "get-menu-categrory-list",
    addTimeSlotApi: config.api_url + "add-time-slot",
    getSelingTimes: config.api_url + "get-sellingtime-slot",
    removeSellingTime: config.api_url + "remove-sellingtime-slot",
    getRestaurantOperationTimeApi: config.api_url + "vendor/get-operation-time",
    saveRestaurantOperationTimeApi:
        config.api_url + "vendor/save-operation-time",
    restaurantOnOffApi: config.api_url + "vendor/restaurant-on-off",
    getMenuItemApi: config.api_url + "get-menu-item",
    deleteMenuApi: config.api_url + "delete-menu",
    deleteMenuWithItemApi: config.api_url + "delete-menu-with-item",
    menuItemEditApi: config.api_url + "update-menu-item",
    deleteMenuItemApi: config.api_url + "delete-menu-item",
    getCustomizationApi: config.api_url + "get-item-customization",
    getMenuImageApi: config.api_url + "get-menu-item-image",
    menuImageDeleteUrl: config.api_url + "delete-menu-image-by-id",
    changeResPasswordApi: config.api_url + "change-res-password",
    importMenuItemApi: config.api_url + "import-menu-item",
    getDeliveryZonesApi: config.api_url + "vendor/delivery_zones",
    updateUserPasswordApi: config.api_url + "vendor/user/change-password",
    updateStatusApi: config.api_url + "update-status",
    updateMenuOrderApi: config.api_url + "update-menu-order",
    updateItemOrderApi: config.api_url + "update-item-order",
    updateItemAvailabilityApi: config.api_url + "update-item-availability",
    updateMenuCatOrderApi: config.api_url + "category-order-update",
    largedataimportMenuItemApi: config.api_url + "large-data-import-menu-item",
    getVendorBatchListApi: config.api_url + "get-vendor-batch-list",
    updateVendorBatchStatusOrDeleteApi:
        config.api_url + "update-vendor-batch-statusordelete",
    gradeDetailsListApi: config.api_url + "get-grade-list",

    //Cuisine
    getCuisineApi: config.api_url + "get-cuisine",
    getCuisineListApi: config.api_url + "cuisine-export-csv",
    addCuisineApi: config.api_url + "add-cuisine",
    getCuisineByIdApi: config.api_url + "get-cuisine-by-id",
    updateCuisineApi: config.api_url + "update-cuisine",
    deleteCuisineApi: config.api_url + "delete-cuisine",
    cuisineImageDeleteUrl: config.api_url + "cuisine/delete-cuisine-by-name",

    //Location
    getCountryListApi: config.api_url + "get-country-list",
    getStateListApi: config.api_url + "get-state-list",
    getCityListApi: config.api_url + "get-city-list",
    addCountryApi: config.api_url + "add-country",
    addStateApi: config.api_url + "add-state",
    addCityApi: config.api_url + "add-city",
    updateCountryApi: config.api_url + "update-country",
    updateStateApi: config.api_url + "update-state",
    updateCityApi: config.api_url + "update-city",
    getCountryByIdApi: config.api_url + "get-country-by-id",
    getStateByIdApi: config.api_url + "get-state-by-id",
    getCityByIdApi: config.api_url + "get-city-by-id",

    //Banners
    getBannerApi: config.api_url + "get-banner",
    addbannerApi: config.api_url + "add-banner",
    getRestaurantApi: config.api_url + "get-restaurant",
    getBannerByIdApi: config.api_url + "get-banner-by-id",
    updateBannerApi: config.api_url + "update-banner",
    deleteBannerApi: config.api_url + "delete-banner",
    bannerImageDeleteUrl: config.api_url + "banner/delete-banner-by-name",
    updateBannerOrderApi: config.api_url + "update-banner-order",

    //Category
    getCategoryApi: config.api_url + "get-category",
    categoryExportToCsvApi: config.api_url + "category-export-csv",
    addCategoryApi: config.api_url + "add-category",
    getCategoryByIdApi: config.api_url + "get-category-by-id",
    updateCategoryApi: config.api_url + "update-category",
    deleteCategoryApi: config.api_url + "delete-category",
    categoryImageDeleteUrl: config.api_url + "category/delete-category-by-name",
    updateCategoryOrderApi: config.api_url + "update-category-order",

    //PromoCode
    getPromoCodeApi: config.api_url + "get-promocode",
    promoCodeExportToCsvApi: config.api_url + "promocode-export-csv",
    addPromoCodeApi: config.api_url + "add-promocode",
    getCustomerLisingApi: config.api_url + "get-customer-listing",
    getPromoCodeByIdApi: config.api_url + "get-promocode-by-id",
    promoCodeImageDeleteUrl:
        config.api_url + "promocode/delete-promocode-by-name",
    updatePromoCodeApi: config.api_url + "update-promocode",
    applyPromoCodeApi: config.app_api_url + "coupon_redeem",

    //DeliveryZone
    getDeliveryZoneApi: config.api_url + "get-delivery-zone",
    getVendorCountryApi: config.api_url + "get-vendor-country",
    getVendorsCountryApi: config.api_url + "get-vendors-country",
    getVendorStateApi: config.api_url + "get-vendor-state",
    getVendorStatesApi: config.api_url + "get-vendor-states",
    getVendorCityApi: config.api_url + "get-vendor-city",
    getVendorsCityApi: config.api_url + "get-vendors-city",
    getAllStateApi: config.api_url + "get-all-state",
    getAllCityApi: config.api_url + "get-all-city",
    addDeliveryZoneApi: config.api_url + "add-delivery-zone",
    getDeliveryInfoApi: config.api_url + "get-delivery-info",
    editDeliveryZoneApi: config.api_url + "edit-delivery-zone",
    zoneExportCsvApi: config.api_url + "zoneExportCsv",
    getZoneRestaurantApi: config.api_url + "getZoneRestaurant",
    deleteFranchiseRestByIdApi: config.api_url + "deleteFranchiseRestById",
    addDeliveryZoneAreaApi: config.api_url + "add-delivery-zone-area",
    deleteRestAreaIdApi: config.api_url + "delete-rest-area-id",
    getRestAreaByIdApi: config.api_url + "get-rest-area-by-id",
    editDeliveryZoneAreaApi: config.api_url + "edit-delivery-zone-area",
    getSearchRestApi: config.api_url + "get-search-rest",
    addFranchiseRestApi: config.api_url + "add-franchise-rest",

    //Restaurant
    getRestaurantListApi: config.api_url + "get-restaurant-list",
    getRestaurantDetailApi: config.api_url + "restaurant-export-csv",
    getRestaurantCategoryListApi: config.api_url + "get-res-category",
    addRestaurantApi: config.api_url + "add-restaurant",
    getRestaurantById: config.api_url + "restaurant/get",
    getRatingApi: config.api_url + "get-rating",
    getBankInfoApi: config.api_url + "restaurant/bank-info",

    getDayWiseFinanceApi: config.api_url + "restaurant/finance/daywise",
    getPastDayWiseFinanceApi: config.api_url + "restaurant/finance/pastdaywise",
    getMonthwiseRestaurantEarningsApi:
        config.api_url + "restaurant/finance/monthwise",
    getOrderwiseRestaurantEarningsApi:
        config.api_url + "restaurant/finance/orderwise",
    getOrderwiseRestaurantEarningsExportApi:
        config.api_url + "restaurant/finance/orderwise/export",
    getRestaurantSettlementApi:
        config.api_url + "restaurant/finance/settlements",
    paySettlementApi: config.api_url + "restaurant/finance/pay-settlements",
    getTransactionsApi: config.api_url + "restaurant/finance/get-transactions",
    menuItemExportApi: config.api_url + "menu-item-export-csv",
    getQrCodeApi: config.api_url + "get-qr-code",

    //Rating
    getRestaurantRatingApi: config.api_url + "get-restaurant-rating",
    getRestaurantAvgRatingApi: config.api_url + "get-restaurant-avg-rating",
    deleteRestRatingApi: config.api_url + "delet-res-rating",

    //VendorInfo
    updateVendorInfoApi: config.api_url + "update-vendor-info",
    vendorSignupApi: config.api_url + "vendor/signup",

    //user-access
    getUserRolesApi: config.api_url + "user-access/get-all-roles",
    addUserRoleApi: config.api_url + "user-access/add-role",
    addPermissionApi: config.api_url + "user-access/add-permission",
    getPermissionsByGuardApi:
        config.api_url + "user-access/get-permission-by-user",
    changePermissionsByRoleApi:
        config.api_url + "user-access/change-permissions-by-role",
    getAccessUsersApi: config.api_url + "user-access/get-access-users",
    addUserApi: config.api_url + "user-access/add-user",
    getAllPermissionsApi: config.api_url + "user-access/get-all-permissions",
    getUserPermissionsApi: config.api_url + "user-access/get-user-permissions",
    getUserDetailApi: config.api_url + "user-access/get-user-details",
    updateUserPermissionsApi:
        config.api_url + "user-access/update-user-permissions",
    updateUserApi: config.api_url + "user-access/update-user",
    getAuthPermissionsApi: config.api_url + "user-access/get-auth-permissions",

    // Vendor Finance
    getVendorFinanceApi: config.api_url + "finance/vendor",
    getVendorFinanceCsvApi: config.api_url + "finance/csv/vendor",
    getVendorFinanceMonthApi: config.api_url + "finance-month/vendor",
    getVendorFinanceMonthCsvApi: config.api_url + "finance-month/vendor/csv",

    //upload-image
    uploadTmpImageApi: config.api_url + "upload-temp-image",
    removeTmpImageApi: config.api_url + "remove-temp-image",

    // Faq
    getFaqListingApi: config.api_url + "get-faq-listing",
    addFaqApi: config.api_url + "add-faq",
    getFaqByIdApi: config.api_url + "get-faq-by-id",
    updateFaqApi: config.api_url + "update-faq",
    deleteFaqApi: config.api_url + "delete-faq",

    // LogActivity
    getLogActivityApi: config.api_url + "get-log-activity",

    //Driver Finance
    getDriverFinanceApi: config.api_url + "driver/finance",
    getDriverFinanceMonthApi: config.api_url + "driver/finance-month",
    getDriverFinanceDaysApi: config.api_url + "driver/finance/days",
    getDriverDayWiseDataApi: config.api_url + "driver/finance/day-wise",
    getDriverSevenDayEarningApi:
        config.api_url + "driver/finance/last-seven-day",
    getDriverMonthWiseEarningApi:
        config.api_url + "driver/finance/month-earning",
    getDriverOrderWiseEarningApi:
        config.api_url + "driver/finance/order-earning",
    getDriverSettlementsApi: config.api_url + "driver/finance/settlements",
    getDriverPendingSettlementApi: config.api_url + "driver/finance/pending",
    receiveDriverSettlementApi: config.api_url + "driver/finance/receive",
    driverTransactionsApi: config.api_url + "driver/finance/get-transactions",

    //upload-ios-pushnotification-pem file
    uploadIOSPemApi: config.api_url + "upload-ios-pem",
    removeIOSPemApi: config.api_url + "remove-ios-pem",

    //Orders
    getOrdersApi: config.api_url + "orders/get",
    getNewOrdersApi: config.api_url + "orders/new/get",
    exportOrdersApi: config.api_url + "orders/export",
    getOrderApi: config.api_url + "order/get",
    getOrdersCountApi: config.api_url + "order/get/count",
    checkAvailableDriversApi: config.api_url + "delivery_zone/drivers",
    assignDriverApi: config.api_url + "order/assign_driver",
    exportAllOrdersApi: config.api_url + "orders/exportAll",

    //Discount
    getDiscountApi: config.api_url + "get-discount",
    addDiscountApi: config.api_url + "add-discount",
    getDiscountByIdApi: config.api_url + "get-discount-by-id",
    discountImageDeleteUrl: config.api_url + "discount/delete-discount-by-name",
    updateDiscountApi: config.api_url + "update-discount",

    //Reports
    // getSalesReportApi : config.api_url+'get-sale-report',
    // saleReportExportToCsvApi: config.api_url+'sale-report-export-csv',
    getSalesReportApi: config.api_url + "get-sale-report-new",
    saleReportExportToCsvApi: config.api_url + "get-sale-report-export-new",
    getItemReportApi: config.api_url + "get-item-report",
    getCustomerReportApi: config.api_url + "get-customer-report",
    reportcustomerExportToCsvApi:
        config.api_url + "report-customer-export-to-csv",
    totalSaleReportApi: config.api_url + "get-total-sale-report",
    totalSummaryReportApi: config.api_url + "get-summary-report",
    totalProductReportApi: config.api_url + "get-product-report",
    storewiseProductReportApi: config.api_url + "get-storewise-product-report",
    storeListReportApi: config.api_url + "get_restaurants",
    vendorMonthListReportApi: config.app_api_url + "getVendorMonthList",
    generateGstReportApi: config.app_api_url + "AdminGstReport",
    totalgetUpdateItemPriceReportApi:
        config.api_url + "getUpdateItemPriceReport",
    updateItemPriceReportExportToCsvApi:
        config.api_url + "ExportUpdateItemPrice",
    getAllRestuarntApi: config.api_url + "get-all-restaurant",
    taxReportReportApi: config.api_url + "get-tax-report",
    getDriverReportApi: config.api_url + "get-driver-report-listing",
    getDriverReportCsvApi: config.api_url + "driver-report-export-to-csv",
    getInactiveCustomerReportApi:
        config.api_url + "get-inactive-customer-report",
    getCODOnlineOrderReportApi: config.api_url + "get-cod-online-order-report",
    getVendorRatingReportApi: config.api_url + "get-vendor-avg-rating-report",
    getGSTDeliveryChargeReportApi:
        config.api_url + "get-gst-delivery-charge-report",
    getRestaurantsApi: config.api_url + "get-all-restuarants",
    getRestaurantsSpecialApi: config.api_url + "get-all-restuarants-special",
    totalSaleReportExportToCsvApi:
        config.api_url + "total-sale-report-export-csv",
    totalSummaryReportExportToCsvApi:
        config.api_url + "exportToCsvVendorSummary",
    totalProductReportExportToCsvApi: config.api_url + "export-product-report",
    getRestaurantsConfirmApi: config.api_url + "get-restaurants-confirm",
    taxReportExportToCsvApi: config.api_url + "tax-report-export-to-csv",
    getAllUserApi: config.api_url + "get-all-user",
    exportInactiveCustomerApi: config.api_url + "export-inactive-customer",
    exportVendorAvgRatingApi: config.api_url + "vendor-avg-export-to-csv",
    exportGSTDelivertReportApi: config.api_url + "gst-delivery-export-to-csv",
    exportCODOnlineReportApi: config.api_url + "export-to-cod-online",
    getPromoCodereportApi: config.api_url + "get-promo-code-report",
    getVendorRatingReviewReportApi:
        config.api_url + "get-vendor-rating-review-report",
    exportVendorRatingReviewReportApi:
        config.api_url + "export-vendor-rating-review-report",
    getDriverRatingReviewReportApi:
        config.api_url + "get-driver-rating-review-report",
    exportDriverRatingReviewReportApi:
        config.api_url + "export-driver-rating-review-report",
    getWalletReportApi: config.api_url + "wallet-report",
    getWalletReportExportApi: config.api_url + "export-wallet-report",
    getDriverSettlementApi: config.api_url + "get-driver-settlement",
    driveSettlementExportToCsvApi:
        config.api_url + "driver-settlement-report-export-csv",
    getVendorQrFeedbackReportApi: config.api_url + "get-feedback-report",
    getVendorQrFeedbackReportExportApi:
        config.api_url + "export-feedback-report",
    getUserListByResturantApi: config.api_url + "get-user-report",
    getSettlementReportApi: config.api_url + "get-settlement-report",
    getAllStoresSettlementReportApi:
        config.api_url + "get-settlement-report-new",
    getPharmacyCustomersApi: config.api_url + "get-pharmacy-customer-report",
    getWhatsappMessageSentApi:
        config.api_url + "get-whatsapp-message-sent-report",
    getIDPAgentApi: config.api_url + "get-idp-agent-report",
    getIDPOrderDetailsApi: config.api_url + "get-idp-order-report",

    //wallet
    getHistoryApi: config.api_url + "wallet/get-history",
    getBalanceApi: config.api_url + "wallet/get-balance",
    changeWalletStatusApi: config.api_url + "wallet/change-status",

    //Dashboard
    getDashboardApi: config.api_url + "get-dashboard",
    getMonthWiseOrderApi: config.api_url + "get-month-wise",
    getMonthWiseSalesApi: config.api_url + "get-month-wise-sales",
    getMonthWiseEarning: config.api_url + "get-month-wise-earning",
    getMonthWiseCancelOrderApi: config.api_url + "get-month-wise-cancel-order",
    getMonthWiseCustomerApi: config.api_url + "get-month-wise-customer",
    getMonthWiseDeliveredOrderApi:
        config.api_url + "get-month-wise-delivered-order",
    getThirtyDayDeliveredOrderApi:
        config.api_url + "get-thirty-day-delivered-order",
    getThirtyDayCustomerApi: config.api_url + "get-thirty-day-customer",
    getLastTwelveMonthDeliveredOrderApi:
        config.api_url + "get-last-twelve-month-delivered-order",
    linktoMasterDepotItemApi: config.api_url + "linkto-master-depot-item",
    deleteAllMenuItemApi: config.api_url + "delete-all-menu-item",
    getStoreTermConPageApi: config.api_url + "get-store-term-con-page",
    updateStoreTermConPageApi: config.api_url + "update-store-term-con-page",

    //Menu Image
    addMenuImageApi: config.api_url + "add-menu-image",
    cancelOrderApi: config.api_url + "order/cancel",
    deliverOrderApi: config.api_url + "order/deliver",
    changeToCodOrderApi: config.api_url + "order/change-to-cod",
    /// APP APIS STARTED FROM HERE

    changeOrderStatusApi: config.app_api_url + "edit_order_status",
    ownerOrderStatusApi: config.app_api_url + "owner_order_status",
    pingDriversApi: config.app_api_url + "ping_drivers",
    removeOrderItemApi: config.app_api_url + "remove-order-item",
    updatePriceWebApi: config.app_api_url + "updatePriceWeb",
    sentToStoreApi: config.app_api_url + "sent-to-store",
    cancelOrderDunzoApi: config.app_api_url + "cancelOrderByDunzo",
    uploadInvoiceApi: config.app_api_url + "vendor_invoice",
    markOrderUnsettleApi: config.app_api_url + "mark_order_unsettle",

    //Customization
    getItemCustmizationApi: config.api_url + "get-item-customization",
    addCustmizationApi: config.api_url + "add-item-customization",
    getCustmizationTypeApi: config.api_url + "get-customization-type",
    customizationEditApi: config.api_url + "update-customization-type",
    deleteCustmizationTypeApi: config.api_url + "delete-customization-type",
    saveTemplateApi: config.api_url + "save-template",
    deleteItemCustmizationApi: config.api_url + "delete-item-customization",
    getTemplateApi: config.api_url + "get-template",
    addTemplateApi: config.api_url + "add-template",
    addMenuSelectionApi: config.api_url + "add-menu-selection",
    getSubCustomizationApi: config.api_url + "get-sub-customization",
    minSelectionApi: config.api_url + "min-selection",
    maxSelectionApi: config.api_url + "max-selection",
    addSubMenuSelectionApi: config.api_url + "add-sub-menu-selection",

    //ContectPage
    getContentPageApi: config.api_url + "get-content-page",
    addContentPageApi: config.api_url + "add-content-page",
    getContentPageByIdApi: config.api_url + "get-content-page-by-id",
    editContentPageApi: config.api_url + "edit-content-page",
    viewContentPageApi: config.api_url + "view-content-page",
    deleteContentPageApi: config.api_url + "delete-content-page",
    getStaticPageApi: config.api_url + "get-static-page",
    getVendorTermApi: config.api_url + "get-vendor-term-page",

    //ForgotPassword
    forgotPasswordApi: config.api_url + "forgot-password",

    //HelpPage
    getHelpPageApi: config.api_url + "get-help-page",

    //Update
    getUpdateApi: config.api_url + "get-updates",

    //Client
    getClientApi: config.api_url + "get-client",
    addClientApi: config.api_url + "add-client",
    getClientByIdApi: config.api_url + "get-client-by-id",
    updateClientApi: config.api_url + "update-client",
    updateClientStatusApi: config.api_url + "update-client-status",
    getProductBusinessTypes: config.api_url + "get-product-business-types",

    //Currency
    getCurrencyApi: config.api_url + "get-currency",
    addCurrencyApi: config.api_url + "add-currency",
    getCurrencyByIdApi: config.api_url + "get-currency-by-id",
    updateCurrencyApi: config.api_url + "update-currency",

    //CallCenterDashboard
    getCallCenterRestaurant: config.api_url + "call-center-restaurant",
    checkCustomerNumberApi: config.api_url + "check-customer-number",
    getCustomerAddressApi: config.api_url + "get-customer-address",
    addCustomerAddressApi: config.api_url + "add-customer-address",
    placeOrderApi: config.api_url + "call-center/place_order",
    checkEmailApi: config.api_url + "check_email",
    userCreateApi: config.api_url + "user_create",

    //Analytics
    getAnalyticsApi: config.api_url + "get-analytics",
    getMonthWiseTotalDeliveredOrderApi:
        config.api_url + "get-total-delivered-order",
    getMonthWiseTotalSalesDeliveredOrderApi:
        config.api_url + "get-month-wise-totalsales-delivered-order",
    getItemOrderStaticsApi: config.api_url + "get-order-item-statics",
    getOrderCountTimingWiseStaticsApi:
        config.api_url + "get-order-count-timing-wise-statics",
    getOderCountsStaticsApi: config.api_url + "get-order-count-statics",
    getAnalyticsCountDataApi: config.api_url + "get-analtics-count-statics",

    //WebLayoutSetting
    getWebSettingApi: config.api_url + "get-web-layout",
    updateWebSettingApi: config.api_url + "update-web-layout",
    deleteWebLogoApi: config.api_url + "delete-web-logo",
    deleteWebHomePageApi: config.api_url + "delet-home-page-image",
    deleteSectionImageApi: config.api_url + "delete-section-image",
    deleteFrontImageApi: config.api_url + "delete-front-image",

    //Business Type
    getBusinessTypeApi: config.api_url + "get-business-type",
    addBusinessTypeApi: config.api_url + "add-business-type",
    getBusinessTypeByIdApi: config.api_url + "get-business-type-by-id",
    updateBusinessTypeByIdApi: config.api_url + "update-business-type",

    // Default Setting
    updateDefaultSettingApi: config.api_url + "update-default-setting",
    updateWebSettingsApi: config.api_url + "update-default-web-setting",
    deleteWebSettingsLogoApi: config.api_url + "delete-default-web-logo",
    deleteWebSettingsHomePageApi:
        config.api_url + "delet-default-home-page-image",
    deleteSectionSettingsImageApi:
        config.api_url + "delete-default-section-image",
    deleteFrontSettingsImageApi: config.api_url + "delete-default-front-image",

    // driver signup for live server api
    signupDriver: config.app_api_url + "driver_signup",
    // driver signup for dev server api
    signupDriverAdd: config.dev_api_url + "driver_signup",

    //Master Depot Routes
    getDepotMenuApi: config.api_url + "depot/menu",
    getDepotItemByMenuIDApi: config.api_url + "depot/menu/item",
    addDepotMenuApi: config.api_url + "depot/add-menu",
    getDepoteMenuByIdApi: config.api_url + "depot/get-menu-by-id",
    updateDepoteMenuApi: config.api_url + "depote/update-depote-menu",
    updateDepoteMenuStatusApi: config.api_url + "depote/update-menu-status",
    deleteDepoteMenuApi: config.api_url + "depote/delete-menu",
    addDepoteItemMenuApi: config.api_url + "depote/add-menu-item",
    getDepoteMenuItemApi: config.api_url + "depote/get-menu-item",
    updateDepoteMenuItemApi: config.api_url + "depote/update-menu-item",
    deleteDepoteMenuItemApi: config.api_url + "depote/delete-menu-item",
    updateDepoteItemAvailabilityApi:
        config.api_url + "depote/update-item-availability",
    updateDepoteItemOrderApi: config.api_url + "depote/update-item-order",
    updateDepoteMenuOrderApi: config.api_url + "depote/update-menu-order",
    deleteDepoteMenuWithItemApi:
        config.api_url + "depote/delete-menu-with-item",
    getDepoteMenuImageApi: config.api_url + "depote/get-menu-item-image",
    addDepoteMenuImageApi: config.api_url + "depote/add-menu-image",
    resDepoteImageDeleteUrl: config.api_url + "depote/delete-menu-image",
    menuDepoteImageDeleteUrl: config.api_url + "depote/delete-menu-image-by-id",
    importDepoteMenuItemApi: config.api_url + "depote/import-menu-item",
    deleteDepoteAllMenuItemApi: config.api_url + "depote/delete-all-menu-item",
    getBatchListApi: config.api_url + "depote/get-batch-list",
    updateBatchStatusOrDeleteApi:
        config.api_url + "depote/update-batch-statusordelete",
    getMasterDepotItemApi: config.api_url + "depote/item-list",
    //Large data import menu & item
    largedataimportDepoteMenuItemApi:
        config.api_url + "depote/large-data-import-menu-item",
    //Customization
    getDepoteItemCustmizationApi:
        config.api_url + "depote/get-item-customization",
    addDepoteCustmizationApi: config.api_url + "depote/add-item-customization",
    addDepoteMenuSelectionApi: config.api_url + "depote/add-menu-selection",
    saveDepoteTemplateApi: config.api_url + "depote/save-template",
    getDepoteCustmizationTypeApi:
        config.api_url + "depote/get-customization-type",
    updateDepoteCustomizationTypeApi:
        config.api_url + "depote/update-customization-type",
    deleteDepoteCustmizationTypeApi:
        config.api_url + "depote/delete-customization-type",
    getDepoteTemplateApi: config.api_url + "depote/get-template",
    addDepoteTemplateApi: config.api_url + "depote/add-template",
    deleteDepoteItemCustmizationApi:
        config.api_url + "depote/delete-item-customization",
    deleteDepoteSubItemCustmizationApi:
        config.api_url + "depote/delete-subitem-customization",
    getDepoteSubCustomizationApi:
        config.api_url + "depote/get-sub-customization",
    addDepoteSubMenuSelectionApi:
        config.api_url + "depote/add-sub-menu-selection",
    minDepoteSelectionApi: config.api_url + "depote/min-selection",
    maxDepoteSelectionApi: config.api_url + "depote/max-selection",

    //doctor consultation

    doctorConsultationApi: config.app_api_url + "create_consultation_request",

    //profile menu

    getprofilemenus: config.app_api_url + "get_profile_menu",
    getprofilemenubyId: config.app_api_url + "get_profile_menu_by_Id/",
    uploadmenuimageicon: config.app_api_url + "upload_image_icon",
    updateprofilemenu: config.app_api_url + "update_profile_menu",
    removeprofilemenuiconimage: config.app_api_url + "remove_image_icon",

    storelocationapi: config.api_url + "get-restaurants",

    //blog APIs

    getBlogListApi: config.app_api_url + "get-blogs",
    storeBlogApi: config.app_api_url + "add-blog",
    deleteBlogByIdApi: config.app_api_url + "delete-blog-by-id/",
    getBlogByIdApi: config.app_api_url + "get-blog-by-id/",
    updateBlogApi: config.app_api_url + "update-blog-by-id/",

    //payout API

    getrestaurantFundAccountsApi: config.app_api_url + "get-restaurant-fund-accounts/",
    getBankNameListApi: config.app_api_url + "get-bank-name-list",
    cretaeRazorPayContactApi: config.app_api_url + "create-razorpay-contact",
    fundAccountStatusApi: config.app_api_url + "fund-account-status",
    fundAccountDeleteApi: config.app_api_url + "delete-restaurant-fund-account/",
    getTransactionsByResturantIdApi: config.app_api_url + "get-transaction-by-restaurantId",
    getTransactionsDatewiseApi: config.app_api_url + "get-transaction-datewise"
};

export { routes };
