<script>

import Layout from '../../layouts/main'

import DiscountAdd from './discount-add'

/**
 * Promo-code component
 */
export default {
  components: { Layout, DiscountAdd },
  data() {
    return {};
  }
}
</script>

<template>
<Layout>
  <discount-add/>
</Layout>
</template>
