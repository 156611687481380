
// ApiMessageText services rest api calling

import { routes } from '../config/api_routes'

export const apiMessageTextService = {
    // get ApiMessageText 

    getApiMessageTextListing: (formData) => {    
        return window.axios.post(routes.getApiTextApi,formData)
    },

    // add ApiMessageText

    addApiMessageText: (formData) => {
        return window.axios.post(routes.addApiTextApi,formData)
    },

    // update ApiMessageText
    updateApiMessageText: (formData) => {
        return window.axios.post(routes.updateApiTextApi,formData)
    }

}