<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import { changePasswordService } from "../../services";
import {
  error_message,
  get_partner_login,
  set_partner,
  set_partner_details,
  set_user,
  set_user_detail,
  success_message,
} from "../../Helper/helper";
import {
  required,
  email,
  minLength,
  sameAs,
  maxLength,
  minValue,
  maxValue,
  numeric,
  url,
  alphaNum,
} from "vuelidate/lib/validators";

export default {
  components: { Layout, PageHeader },
  data() {
    return {
      title: this.trans.get("__JSON__.Change Password"),
      typeform: {
        old_password: "",
        new_password: "",
        confirm_password: "",
      },
    };
  },
  validations: {
    typeform: {
      old_password: {
        required,
      },
      new_password: {
        required,
        minLength: minLength(6),
      },
      confirm_password: {
        required,
        sameAsPassword: sameAs("new_password"),
      },
    },
  },
  methods: {
    getUser() {
      this.users = this.$auth.user;
      this.userdetail = this.$auth.setting;
    },
    typeForm(e) {
      this.$v.$touch();
      if (this.$v.typeform.$invalid) {
        return;
      } else {
        changePasswordService.changePassword(this.typeform).then((response) => {
          if (response.data.code === 200) {
            success_message(response.data.message);
            if (!this.$auth.partner_login) {
              this.$router.push({ name: "login" });
            } else {
              this.$router.push({ name: "vendor.login" });
            }
          } else {
            error_message(response.data.message);
          }
        });
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" />
    <div class="row">
      <div class="col-12">
        <form action="#" @submit.prevent="typeForm">
          <!-- Change Password Start -->
          <div class="card">
            <div class="card-body">
              <p class="card-title-desc"></p>
              <div class>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label class="control-label">{{ trans.get('__JSON__.Old password') }}</label>
                      <input
                        id="old_password"
                        name="old_password"
                        type="password"
                        v-model="$v.typeform.old_password.$model"
                        placeholder="Enter Old Password"
                        class="form-control"
                        :class="{ 'is-invalid':$v.typeform.old_password.$error }"
                      />
                      <div v-if="$v.typeform.old_password.$error" class="invalid-feedback">
                        <span
                          v-if="!$v.typeform.old_password.required"
                        >{{ trans.get('__JSON__.Please enter old password') }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label class="control-label">{{ trans.get('__JSON__.New password') }}</label>
                      <input
                        id="new_password"
                        name="new_password"
                        type="password"
                        v-model="$v.typeform.new_password.$model"
                        placeholder="Enter New Password"
                        class="form-control"
                        :class="{ 'is-invalid':$v.typeform.new_password.$error }"
                      />
                      <div v-if="$v.typeform.new_password.$error" class="invalid-feedback">
                        <span
                          v-if="!$v.typeform.new_password.required"
                        >{{ trans.get('__JSON__.Please enter new password') }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label class="control-label">{{ trans.get('__JSON__.Re-enter new password') }}</label>
                      <input
                        id="confirm_password"
                        name="confirm_password"
                        type="password"
                        v-model="$v.typeform.confirm_password.$model"
                        placeholder="Re enter New password"
                        class="form-control"
                        :class="{ 'is-invalid':$v.typeform.confirm_password.$error }"
                      />
                      <div v-if="$v.typeform.confirm_password.$error" class="invalid-feedback">
                        <span
                          v-if="!$v.typeform.confirm_password.required"
                        >{{ trans.get('__JSON__.Please re enter your password') }}</span>
                        <span
                          v-else-if="!$v.typeform.confirm_password.sameAsPassword"
                        >{{ trans.get('__JSON__.Passwords must match') }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-6">
                  <button
                    type="submit"
                    class="btn btn-primary mr-1 mt-3"
                  >{{ trans.get('__JSON__.Update') }}</button>
                </div>
              </div>
            </div>
          </div>
          <!-- Change Password End -->
        </form>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
