<script>
import Layout from "../../../layouts/main";
import pagination from "laravel-vue-pagination";
import PageHeader from "../../../components/page-header";
import { userAccessService } from "../../../services";
import AddUser from "./add-user";
import EditUser from "./edit-user";
import ChangePassword from "./change-password";
import { check_permission, get_user, set_user, updated_permissions } from '../../../Helper/helper';
import config from '../../../config';
import Multiselect from 'vue-multiselect';

export default {
  components: { Layout, PageHeader, AddUser, pagination, EditUser,ChangePassword, Multiselect },
  data() {
    return {
      config:config,
      users: [],
      paginations: {},
      limit: 1,
      search: '',
      sortBy: 'vendor_id',
      loading:false,
      sortDesc: true,
      status: 1,
      fields: [
        { key: 'vendor_id',label: "#", sortable: true },
        { key: 'vendor_name',label: (this.trans.get("__JSON__.Users")+' '+this.trans.get("__JSON__.Name")), sortable: true },
        { key: 'email',label: this.trans.get("__JSON__.Email"), sortable: true },
        { key: 'status',label: this.trans.get("__JSON__.Status"), sortable: true },
        { key: 'action',label: this.trans.get("__JSON__.Action"), sortable: false }
      ],
      editing: false,
      editId: null,
      write_permission: false,
      read_permission: false,
      // options: [
      //   { value: '-', label: this.trans.get('__JSON__.All')},
      //   { value: 1, label: this.trans.get('__JSON__.Active')},
      //   { value: 0, label: this.trans.get('__JSON__.Inactive')},
      // ],
      
    };
  },
  mounted(){
    this.getUsers()
    userAccessService.getAuthPermissions()
    .then(response => {
      const user = get_user();
      if(user){
        get_user().access = response.data.result.map(p=>p.name);
        this.write_permission = get_user().access.includes('User & Access Write');
        this.read_permission = get_user().access.includes('User & Access Read');
        set_user(user);
      }
      
    });
  },

  methods: {
    searchData(search){
      this.getUsers();
    },
    getUsers(page) {
      this.loading = true;
      const data = {
        search: this.search,
        status: this.status,
        page: page?page:1,
        sortBy: this.sortBy, 
        orderBy: this.sortDesc ? 'desc':'asc',
      };
      userAccessService.getAccessUsers(data)
        .then(res => {
          this.loading = false;
          this.paginations = res.data.result;
          this.users = res.data.result.data;
        })
    },
    changePassword(user_id){
      this.editing = true;
      this.editId = user_id;
    },
    sortingChanged(ctx){
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      this.getUsers ();
    },
    editDone(refresh){
      this.editing = false;
      if(refresh) this.getUsers();
    }
  },
};
</script>
<template>
  <Layout>
     <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
        >
          <div class="page-title-left">
            <div class="avatar-xs mr-3">
              <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                <i class="bx bx-group"></i>
              </span>
            </div>
             <h4 class="mb-0 font-size-18">{{ trans.trans('__JSON__.User & Access') }}</h4>
          </div>
          <div class="page-title-right" v-if="write_permission">
            <router-link :to="{name: 'user-access-add'}" v-if="$auth.hasPermission(config.permissions.user_access_write)" class="btn btn-success btn-rounded mr-2 add-btn">
              <i class="mdi mdi-plus mr-1"></i>
              {{ trans.get("__JSON__.Add User") }}
            </router-link>
             <router-link :to="{name: 'user-access-add'}"  v-if="$auth.hasPermission(config.permissions.user_access_write)" class="btn btn-success btn-rounded mr-2 m-add-btn m-vendor-btn">
              <i class="mdi mdi-plus"></i>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 list-table-main">
        <div class="card">
          <div class="card-body">
             <div class="row">
              <div class="col-sm-2 offset-sm-8">
                <div class="form-group">
                  <div class="date-range-list">
                    <label>{{ trans.get('__JSON__.Status') }} :</label>
                    <select class="custom-select" v-model="status" @change="searchData()">
                      <option value="">{{ trans.get('__JSON__.All') }}</option>
                      <option value="1" selected>{{ trans.get('__JSON__.Active') }}</option>
                      <option value="0">{{ trans.get('__JSON__.Inactive') }}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="date-range-list">
                  <label>{{ trans.get('__JSON__.Search') }} :</label>
                  <div class="text-sm-right">
                    <div class="search-box mr-2 d-inline-block w-100">
                      <div class="position-relative">
                        <input
                          type="text"
                          class="form-control"
                          id="search"
                          @keyup="searchData()"
                          v-model="search"
                          :placeholder="trans.trans('__JSON__.Search Placeholder')"
                        />
                        <i class="bx bx-search-alt search-icon"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
             <div class="col-12 table-main-list user-access-table table-responsive">
              <b-table
                  :items="users"
                  :fields="fields"
                  :sort-by.sync="sortBy"
                  :no-local-sorting="true"
                  @sort-changed="sortingChanged"
                  :busy="loading"
                  show-empty
                  class="table-centered table-nowrap table-hover order-menu"
                >
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>{{trans.get('__JSON__.Loading...')}}</strong>
                  </div>
                </template>
                <template #empty>
                    <p class="text-center">{{ trans.get('__JSON__.No Users Found') }}</p>
                </template>
                <template v-slot:cell(status)="data" >
                    <div v-if="data.item.status == '0'" class="badge badge-pill badge-soft-danger font-size-12">
                      Inactive
                    </div>
                    <div v-else class="badge badge-pill badge-soft-success font-size-12">
                      Active
                    </div>
                </template>
                
                <template v-slot:cell(action)="data">
                  <div>
                    <b-button pill size="sm" variant="primary"
                      v-if="$auth.hasPermission(config.permissions.user_access_write)"
                      :to="{name: 'user-access-edit', params: {id: data.item.vendor_id}}">{{ trans.get('__JSON__.Edit') }}</b-button>
                    <router-link :to="{name: 'user-access.permissions',params: { id:data.item.vendor_id} }" class="btn btn-success btn-sm btn-rounded">{{ trans.get('__JSON__.Permissions') }}</router-link>
                    <b-button pill size="sm" variant="success"
                      v-if="$auth.hasPermission(config.permissions.user_access_write)"
                      @click="changePassword(data.item.vendor_id)">{{ trans.get('__JSON__.Change Password') }}</b-button>
                  </div>
                </template>
              </b-table>
            </div>
            <div class="row col-sm-5 offset-sm-7">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <pagination
                      :data="paginations"
                      :limit="limit"
                      @pagination-change-page="getUsers"
                    ></pagination>
                  </ul>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="editing">
      <change-password :user_id="editId" @hidden="editDone(false)" />
    </div>
    <!-- <div v-if="editing">
    <edit-user :user-id="editId" @user-updated="editDone" />
    </div> -->
  </Layout>
</template>

<style>
.user-access-table .table thead th:nth-child(5){
    pointer-events: none;
}
.w-200px{
  width: 200px;
}
</style>