<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import deliveryZoneList from './delivery-zone-list';
/**
 * Contacts-list component
 */
export default {
  components: { Layout, PageHeader,deliveryZoneList},
  data() {
    return {};
  }
};

</script>
<template>
  <Layout>
    <delivery-zone-list/>
  </Layout>
</template>