<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import ChartData from "../../views/data-saas";
import config from "../../config";
import { Pie } from 'vue-chartjs';
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";

import { dashboardService, orderService, userService,reportService } from "../../services";
import {
  get_partner_login,
  set_partner,
  set_partner_details,
  set_user,
  set_user_detail,
  get_currency,
  replaceByDefault,
  date_ranges

} from "../../Helper/helper";
import {
  columnDeliveredOrderDataChart,
  columnOrderStaticPieChart
  // columnCustomerDataChart,
} from "../data-apex";

export default {
  components: {
    Layout,
    PageHeader,
    DateRangePicker
  },
  data() {
    let startDate = this.moment().startOf("day").subtract(29, "days");
    let endDate = this.moment().endOf("day").toDate();
    let orderStaticStartDate = this.moment().startOf("day").subtract(29, "days");
    let orderStaticEndDate = this.moment().endOf("day").toDate();
    return {
      ChartData,
      columnDeliveredOrderDataChart: columnDeliveredOrderDataChart,
      columnCustomerDataChart: columnDeliveredOrderDataChart,
      orderStatics: columnOrderStaticPieChart,
      restaurant: [],
      restaurant_count: "",
      restaurants_id: "",
      currency: get_currency(),
      config,
      chart: 'sales',
      dashboard: [],
      series: [],
      mseries: [],
      eseries: [],
      orderstaticseries: null,
      orderitemstaticseries:null,
      ordercounttimingseries:null,
      totalsalesseries:[],
      selling_count: "",
      item_count: "",
      thirty_vendor: "",
      thirty_driver: "",
      ninty_customer: "",
      top_promocodes:"",
      top_thirty_vendor: "",
      top_ninty_customer: "",
      top_client: "",
      dateRange: { startDate, endDate },
      orderStaticsdateRange: { orderStaticStartDate, orderStaticEndDate },
      filters: {
        start_date: this.moment().subtract(29,'days').format('YYYY-MM-DD'),
        end_date: this.moment().format('YYYY-MM-DD'),
      },
      orderStaticsfilters:{
        order_statics_start_date: this.moment().subtract(29,'days').format('YYYY-MM-DD'),
        order_statics_end_date: this.moment().format('YYYY-MM-DD'),
      },
      date_ranges: date_ranges,
      chartOptions: {
        chart: {
            toolbar: {
                show: false,
            }
        },
        plotOptions: {
            bar: {
                dataLabels: {
                    position: 'top', // top, center, bottom
                },
            }
        },
        dataLabels: {
            enabled: true,
            offsetY: -20,
            style: {
                fontSize: '12px',
                colors: ["#34c38f"]
            }
        },
        colors: ['#34c38f'],
        grid: {
            borderColor: '#f1f1f1',
        },
        xaxis: {
         title : {
                formatter: function(i) {
                    let start = moment().startOf('month');
                    for (i = 0; i < 12; i++)
                    {
                        return start.subtract(1, 'month').format('MM/YY')
                    }
                },
            },
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            },
            crosshairs: {
                fill: {
                    type: 'gradient',
                    gradient: {
                        colorFrom: '#D8E3F0',
                        colorTo: '#BED1E6',
                        stops: [0, 100],
                        opacityFrom: 0.4,
                        opacityTo: 0.5,
                    }
                }
            },
            tooltip: {
                enabled: true,
                offsetY: -35,

            }
        },
        fill: {
            gradient: {
                shade: 'light',
                type: "horizontal",
                shadeIntensity: 0.25,
                gradientToColors: undefined,
                inverseColors: true,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [50, 0, 100, 100]
            },
        },
        yaxis: {
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false,
            },
            labels: {
                show: false
            }

        },
        title: {
            floating: false,
            offsetY: 100,
            align: 'center',
            style: {
                color: '#444'
            }
        },
     },
    schartOptions: {
        chart: {
            toolbar: {
                show: false,
            }
        },
        plotOptions: {
            bar: {
                dataLabels: {
                    position: 'top', // top, center, bottom
                },
            }
        },
        dataLabels: {
            enabled: true,
            offsetY: -20,
            style: {
                fontSize: '12px',
                colors: ["#34c38f"]
            }
        },
        colors: ['#34c38f'],
        grid: {
            borderColor: '#f1f1f1',
        },
        xaxis: {
         title : {
                formatter: function(i) {
                    let start = moment().startOf('month');
                    for (i = 0; i < 12; i++)
                    {
                        return start.subtract(1, 'month').format('MM/YY')
                    }
                },
            },
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            },
            crosshairs: {
                fill: {
                    type: 'gradient',
                    gradient: {
                        colorFrom: '#D8E3F0',
                        colorTo: '#BED1E6',
                        stops: [0, 100],
                        opacityFrom: 0.4,
                        opacityTo: 0.5,
                    }
                }
            },
            tooltip: {
                enabled: true,
                offsetY: -35,

            }
        },
        fill: {
            gradient: {
                shade: 'light',
                type: "horizontal",
                shadeIntensity: 0.25,
                gradientToColors: undefined,
                inverseColors: true,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [50, 0, 100, 100]
            },
        },
        yaxis: {
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false,
            },
            labels: {
                show: false
            }

        },
        title: {
            floating: false,
            offsetY: 100,
            align: 'center',
            style: {
                color: '#444'
            }
        },
     },
      replaceByDefault:replaceByDefault
    };
  },
  mounted() {
     history.pushState(null,null,location.href);
     reportService.getAllRestaurantName().then((response) => {
        this.restaurant_count = response.data.result.length;
        this.restaurant = response.data.result;
        if (this.restaurant_count > 0) {
          this.restaurant_id = this.restaurant[0].restaurant_id;
        }
      });
     this.getDashboardData();
    
  },
  methods: {
   
    getUser() {
      this.users = this.$auth.user;
      this.userdetail = this.$auth.setting;
    },
    getOrdersCount(){
      orderService
        .getNewOrders()
        .then((response) => {
          this.$auth.updateOrders(response.data.result)
        })
        .catch(function (error) {
        });
    },
     updateValues(date) {
      this.filters = {
        ...this.filters,
        start_date: this.moment(date.startDate.toISOString()).format(
          "YYYY-MM-DD"
        ),
        end_date: this.moment(date.endDate.toISOString()).format("YYYY-MM-DD"),
      };
          this.getItemOrderStatics()
      // this.taxReportExportCsv();
    },
    orderStaticsDate(date){
       this.orderStaticsfilters = {
        ...this.orderStaticsfilters,
        order_statics_start_date: this.moment(date.orderStaticStartDate).format(
          "YYYY-MM-DD"
        ),
        order_statics_end_date: this.moment(date.orderStaticEndDate).format("YYYY-MM-DD"),
      };
      this.getOrderCounts();
    },
    getDeliveredOrders() {
        dashboardService.getMonthWiseTotalDeliveredOrder({
          showDates : "Monthly", 
          restaurants_id: this.restaurants_id,
        }).then((response)=>{
          console.log(response.data.result);
              this.series = [{
                name: 'Delivered Order',
                data: response.data.result
            }];
          });
            
     this.getOrdersTotalSales();
    },
     getOrdersTotalSales() {
        dashboardService.getMonthWiseTotalSalesDeliveredOrder({
                  chart: this.chart,
                  restaurants_id: this.restaurants_id,
        }).then((response)=>{

              this.totalsalesseries = [{
                name: 'Total Sales Order',
                data: response.data.result
            }];
          })
    },
   
    getOrderCounts(){
       dashboardService.getOderCountsStatics({
        showDates : "Monthly", 
        restaurants_id: this.restaurants_id,
        ...this.orderStaticsfilters,
      }).then((response) => {
        if(response.data.result.total_month_delivered_order_count > 0 || response.data.result.total_month_cancelled_order_count > 0 || response.data.result.total_month_paymentfailed_order_count > 0){
          if(response.data.result.total_month_delivered_order_count > 0 && response.data.result.total_month_cancelled_order_count > 0 && response.data.result.total_month_paymentfailed_order_count > 0){
            this.orderstaticseries ={
                  labels: ['Delivered', 'Cancelled','Abandoned'],
                  series: [response.data.result.total_month_delivered_order_count,response.data.result.total_month_cancelled_order_count,response.data.result.total_month_paymentfailed_order_count]
           
            };            
          }else if (response.data.result.total_month_delivered_order_count > 0 && response.data.result.total_month_cancelled_order_count > 0){
            this.orderstaticseries ={
                  labels: ['Delivered', 'Cancelled'],
                  series: [response.data.result.total_month_delivered_order_count,response.data.result.total_month_cancelled_order_count]
           
            };
          }else if(response.data.result.total_month_delivered_order_count > 0 && response.data.result.total_month_paymentfailed_order_count > 0){
            this.orderstaticseries ={
                  labels: ['Delivered','Abandoned'],
                  series: [response.data.result.total_month_delivered_order_count,response.data.result.total_month_paymentfailed_order_count]
           
            };            
          }else if(response.data.result.total_month_cancelled_order_count > 0 && response.data.result.total_month_paymentfailed_order_count > 0){
            this.orderstaticseries ={
                  labels: ['Cancelled','Abandoned'],
                  series: [response.data.result.total_month_cancelled_order_count,response.data.result.total_month_paymentfailed_order_count]
           
            };            
          }
          else if(response.data.result.total_month_cancelled_order_count > 0){
            this.orderstaticseries ={
                  labels: ['Cancelled'],
                  series: [response.data.result.total_month_cancelled_order_count]
           
            };            
          }else if(response.data.result.total_month_paymentfailed_order_count > 0){
            this.orderstaticseries ={
                  labels: ['Abandoned'],
                  series: [response.data.result.total_month_paymentfailed_order_count]
           
            };            
          }
          else if (response.data.result.total_month_delivered_order_count > 0){
            this.orderstaticseries ={
                  labels: ['Delivered'],
                  series: [response.data.result.total_month_delivered_order_count]
           
            };
          }
          
     
        }else{
           this.orderstaticseries ={
             labels: [''],
           series: ['']
           
          };
        }
      });
    },
    getDashboardData() {
       
      dashboardService.getAnalytics({
          showDates : "Monthly", 
        restaurants_id: this.restaurants_id,
      }).then((response) => {
        this.dashboard = response.data.result;
        this.top_thirty_vendor = response.data.result.top_thirty_vendor ? response.data.result.top_thirty_vendor.length : 0;
        this.thirty_vendor = response.data.result.thirty_vendor ? response.data.result.thirty_vendor.length : 0;
        this.top_promocodes = response.data.result.top_promocodes ? response.data.result.top_promocodes.length : 0;
         this.getDeliveredOrders();
        this.getOrderCounts();
         this.getItemOrderStatics();
      });
    },
    searchData(){
              
      dashboardService.getAnalytics({
          showDates : "Monthly", 
        restaurants_id: this.restaurants_id,
      }).then((response) => {
        this.dashboard = response.data.result;
      });
        this.getOrderCounts();
        this.getItemOrderStatics();
        this.getOrdersTotalSales();
    },
  
    getItemOrderStatics(){
      dashboardService.getItemOrderStatics({
        showDates : "Monthly", 
        restaurants_id: this.restaurants_id,
          ...this.filters,
      }).then((response) => {
        if(response.data.result.items_name.length > 0){
        this.orderitemstaticseries ={
             labels: response.data.result.items_name,
           series: response.data.result.items_count
           
          };
        }else{
          this.orderitemstaticseries ={
             labels: "",
           series: ""
           
          };
        }
      });
      this.getOrderCountTimingWiseStatics();
    },
    getOrderCountTimingWiseStatics(){
      dashboardService.getOrderCountTimingWiseStatics({
        showDates : "Monthly",
        restaurants_id: this.restaurants_id,
      }).then((response) => {
        if(response.data.result.breakFastOrder > 0 || esponse.data.result.lunchOrder > 0 || esponse.data.result.dinnerOrder > 0 || response.data.result.nightOrder > 0){
            this.ordercounttimingseries ={
                labels: ['BreakFast','Lunch','Dinner','Night'],
                series: [response.data.result.breakFastOrder,response.data.result.lunchOrder,response.data.result.dinnerOrder,response.data.result.nightOrder]
            };
        }else{
            this.ordercounttimingseries = null;
        }
      });
    }
  },
};
</script>
<template>
  <Layout>

    <div class="row">
      <div class="col-4">
        <div
          class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
        >
          <div class="page-title-left">
            <div class="avatar-xs mr-3">
              <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                <i class="mdi mdi-speedometer"></i>
              </span>
            </div>
             <h4 class="mb-0 font-size-18">{{ trans.get('__JSON__.Analytics') }}</h4>
          </div>
        </div>
        </div>
      
            <div class="col-8">
                    <div v-if="restaurant_count > 0" class="v-avg-rating-report float-right">
                    <div class="form-group">
                      <div class="date-range-list">
                        <label>{{ trans.trans('__JSON__.Vendor',{ vendor: $auth.setting.restaurant_name }) }} :</label>
                        <select class="custom-select" v-model="restaurants_id" @change="searchData()">
                          <option value selected>{{ trans.trans('__JSON__.All Vendor',{ vendor: $auth.setting.restaurant_name }) }}</option>
                          <option
                            v-for="res in restaurant"
                            :value="res.restaurant_id"
                            :key="res.restaurant_id"
                          >{{res.name}}</option>
                        </select>
                      </div>
                    </div>
                  </div>
      </div>
    </div>
    <div class="row" v-if="$auth.user.vendor_role != '1'">
      <div class="col-xl-12">
        <div class="row">
          <div class="col-sm-4">
            <div class="card">
              <div class="card-body">
                <div class="d-flex align-items-center mb-3">
                  <div class="avatar-xs mr-3">
                    <span
                      class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18"
                    >
                      <i class="bx bx-copy-alt"></i>
                    </span>
                  </div>
                  <h5 class="font-size-14 mb-0">{{trans.get('__JSON__.Today')}}</h5>
                </div>
                <div class="text-muted mt-4">
                  <h4>{{ dashboard.total_today_order_amount || 0}}</h4>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-4">
            <div class="card">
              <div class="card-body">
                <div class="d-flex align-items-center mb-3">
                  <div class="avatar-xs mr-3">
                    <span
                      class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18"
                    >
                      <i class="bx bx-archive-in"></i>
                    </span>
                  </div>
                  <h5 class="font-size-14 mb-0">{{trans.get('__JSON__.7 Days')}}</h5>
                </div>
                <div class="text-muted mt-4">
                  <h4><span v-html="currency"></span> {{ dashboard.total_week_order_amount || 0 }}</h4>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-4">
            <div class="card">
              <div class="card-body">
                <div class="d-flex align-items-center mb-3">
                  <div class="avatar-xs mr-3">
                    <span
                      class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18"
                      v-html="currency"></span>
                  </div>
                  <h5 class="font-size-14 mb-0">{{trans.get('__JSON__.This Month')}}</h5>
                </div>
                <div class="text-muted mt-4">
                  <h4><span v-html="currency"></span> {{dashboard.total_month_order_amount}}</h4>
                </div>
              </div>
            </div>
          </div>

          
        </div>
        <!-- end row -->
      </div>
    </div>

    <div class="row d-none">
      <div class="col-xl-8">
        <div class="card">
          <div class="card-body">
            <div class="clearfix">
              <div class="float-right">
                <div class="input-group input-group-sm">
                  <select class="custom-select custom-select-sm">
                    <option selected>Jan</option>
                    <option value="1">Dec</option>
                    <option value="2">Nov</option>
                    <option value="3">Oct</option>
                  </select>
                  <div class="input-group-append">
                    <label class="input-group-text">Month</label>
                  </div>
                </div>
              </div>
              <h4 class="card-title mb-4">Earning</h4>
            </div>

            <div class="row">
              <div class="col-lg-4">
                <div class="text-muted">
                  <div class="mb-4" v-if="$auth.setting">
                    <p>This month</p>
                    <h4>{{$auth.setting.currency}} 2453.35</h4>
                    <div>
                      <span class="badge badge-soft-success font-size-12 mr-1">+ 0.2%</span> From previous period
                    </div>
                  </div>

                  <div>
                    <a href="#" class="btn btn-primary waves-effect waves-light btn-sm">
                      View Details
                      <i class="mdi mdi-chevron-right ml-1"></i>
                    </a>
                  </div>

                  <div class="mt-4" v-if="$auth.setting">
                    <p class="mb-2">Last month</p>
                    <h5>{{$auth.setting.currency}} 2281.04</h5>
                  </div>
                </div>
              </div>

              <div class="col-lg-8">
                <apexchart
                  class="apex-charts"
                  :options="ChartData.LineChart.chartOptions"
                  :series="ChartData.LineChart.series"
                  dir="ltr"
                  height="380"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-4">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Sales Analytics</h4>

            <div>
              <apexchart
                class="apex-charts"
                :options="ChartData.DonutChart.chartOptions"
                :series="ChartData.DonutChart.series"
                dir="ltr"
                height="300"
              />
            </div>

            <div class="text-center text-muted">
              <div class="row">
                <div class="col-4">
                  <div class="mt-4">
                    <p class="mb-2 text-truncate" v-if="$auth.setting">
                      <i class="mdi mdi-circle text-primary mr-1"></i>COD
                    </p>
                    <h5>2,132</h5>
                  </div>
                </div>
                <div class="col-4 offset-4">
                  <div class="mt-4">
                    <p class="mb-2 text-truncate" v-if="$auth.setting">
                      <i class="mdi mdi-circle text-success mr-1"></i>Online
                    </p>
                    <h5>1,763</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Chart -->
    
      <div class="row chart-loader" v-if="!$auth.partner_login">
          <div class="col-lg-6">
            <div data-v-1d114854 class="is-loading">
              <div data-v-1d114854="" class="preloader-component">
                <div data-v-1d114854="" class="status">
                  <div data-v-1d114854="" class="spinner-chase">
                    <div data-v-1d114854="" class="chase-dot"></div>
                    <div data-v-1d114854="" class="chase-dot"></div>
                    <div data-v-1d114854="" class="chase-dot"></div>
                    <div data-v-1d114854="" class="chase-dot"></div>
                    <div data-v-1d114854="" class="chase-dot"></div>
                    <div data-v-1d114854="" class="chase-dot"></div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-body">
                     <h4 class="card-title mb-4">{{ trans.get('__JSON__.Delivered Orders Trend') }}</h4>
                  <!-- Column with Data Labels -->
                    <apexchart
                      class="apex-charts"
                      height="350"
                      type="bar"
                      dir="ltr"
                      :series="series"
                      :options="columnDeliveredOrderDataChart.chartOptions"
                    ></apexchart>
                </div>
              </div>


  
              <div class="card" v-if="orderstaticseries">
                <div class="card-body">
                  <h4 class="card-title mb-4">{{ trans.get('__JSON__.Order Statics') }}
                      <div class="date-range-list float-right">
                  
                      <date-range-picker
                        ref="picker1"
                        :locale-data="{
                          firstDay: 1,
                          format: 'dd-mm-yyyy',
                        }"
                        :showDropdowns="true"
                        :showWeekNumbers="true"
                        opens="left"
                        v-model="orderStaticsdateRange"
                        :ranges="date_ranges()"
                        @update="orderStaticsDate"
                      >
                        <template
                          v-slot:input="picker1"
                          style="min-width: 350px"
                        >
                          {{ moment(picker1.orderStaticStartDate).format('MMMM DD, YYYY')}} -
                          {{ moment(picker1.orderStaticEndDate).format('MMMM DD, YYYY')}}

                        </template>
                      </date-range-picker>
                    </div>
                  </h4>
                  <!-- Simple pie chart -->
                    <chartist 
                      ratio="ct-chart"
                      :data="orderstaticseries"
                      :options="orderStatics.options"
                      type="Pie"
                    ></chartist>
                </div>
              </div>
              <div class="card" v-if="ordercounttimingseries">
                <div class="card-body">
                  <h4 class="card-title mb-4">{{ trans.get('__JSON__.Order Statics') }}(Monthly)</h4>
                  <!-- Simple pie chart -->
                    <chartist 
                      ratio="ct-chart"
                      :data="ordercounttimingseries"
                      :options="orderStatics.options"
                      type="Pie"
                    ></chartist>
                </div>
              </div>
            </div>
          </div>
             <div class="col-lg-6">
            <div data-v-1d114854 class="is-loading">
              <div data-v-1d114854="" class="preloader-component">
                <div data-v-1d114854="" class="status">
                  <div data-v-1d114854="" class="spinner-chase">
                    <div data-v-1d114854="" class="chase-dot"></div>
                    <div data-v-1d114854="" class="chase-dot"></div>
                    <div data-v-1d114854="" class="chase-dot"></div>
                    <div data-v-1d114854="" class="chase-dot"></div>
                    <div data-v-1d114854="" class="chase-dot"></div>
                    <div data-v-1d114854="" class="chase-dot"></div>
                  </div>
                </div>
              </div>

                <div class="card">
                <div class="card-body">
                     <h4 class="card-title mb-4">{{ trans.get('__JSON__.Total Sales') }}</h4>
                  <!-- Column with Data Labels -->
                    <apexchart
                      class="apex-charts"
                      height="350"
                      type="bar"
                      dir="ltr"
                      :series="totalsalesseries"
                      :options="columnDeliveredOrderDataChart.chartOptions"
                    ></apexchart>
                </div>
              </div>
            
              <div class="card" v-if="orderitemstaticseries">
                <div class="card-body">
                  <h4 class="card-title mb-4">{{ trans.get('__JSON__.Order Item Statics') }}
                      <div class="date-range-list float-right">
                  
                      <date-range-picker
                        ref="picker"
                        :locale-data="{
                          firstDay: 1,
                          format: 'dd-mm-yyyy',
                        }"
                        :showDropdowns="true"
                        :showWeekNumbers="true"
                        opens="left"
                        v-model="dateRange"
                        :ranges="date_ranges()"
                        @update="updateValues"
                      >
                        <template
                          v-slot:input="picker"
                          style="min-width: 350px"
                        >
                          {{ moment(picker.startDate.toISOString()).format('MMMM DD, YYYY') }} -
                          {{ moment(picker.endDate.toISOString()).format('MMMM DD, YYYY') }}
                        </template>
                      </date-range-picker>
                    </div>
                    </h4>
                  
                  <!-- Simple pie chart -->
                    <chartist 
                      ratio="ct-chart"
                      :data="orderitemstaticseries"
                      :options="orderStatics.options"
                      type="Pie"
                    ></chartist>
                </div>
              </div>
             
            </div>
          </div>
      </div>
       <div class="row chart-loader" v-else-if="$auth.partner_login">
          <div class="col-lg-6">
            <div data-v-1d114854 class="is-loading">
              <div data-v-1d114854="" class="preloader-component">
                <div data-v-1d114854="" class="status">
                  <div data-v-1d114854="" class="spinner-chase">
                    <div data-v-1d114854="" class="chase-dot"></div>
                    <div data-v-1d114854="" class="chase-dot"></div>
                    <div data-v-1d114854="" class="chase-dot"></div>
                    <div data-v-1d114854="" class="chase-dot"></div>
                    <div data-v-1d114854="" class="chase-dot"></div>
                    <div data-v-1d114854="" class="chase-dot"></div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-body">
                     <h4 class="card-title mb-4">{{ trans.get('__JSON__.Last 6 Months Orders') }}</h4>
                  <!-- Column with Data Labels -->
                    <apexchart
                      class="apex-charts"
                      height="350"
                      type="bar"
                      dir="ltr"
                      :series="series"
                      :options="columnDeliveredOrderDataChart.chartOptions"
                    ></apexchart>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div data-v-1d114854 class="is-loading">
              <div data-v-1d114854="" class="preloader-component">
                <div data-v-1d114854="" class="status">
                  <div data-v-1d114854="" class="spinner-chase">
                    <div data-v-1d114854="" class="chase-dot"></div>
                    <div data-v-1d114854="" class="chase-dot"></div>
                    <div data-v-1d114854="" class="chase-dot"></div>
                    <div data-v-1d114854="" class="chase-dot"></div>
                    <div data-v-1d114854="" class="chase-dot"></div>
                    <div data-v-1d114854="" class="chase-dot"></div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-body">
                     <h4 class="card-title mb-4">{{ trans.get('__JSON__.Last 6 Months Earning') }}</h4>
                  <!-- Column with Data Labels -->
                    <apexchart
                      class="apex-charts"
                      height="350"
                      type="bar"
                      dir="ltr"
                      :series="eseries"
                      :options="schartOptions"
                    ></apexchart>
                </div>
              </div>
            </div>
          </div>
      </div>
    <!-- end row -->
    <div v-if="$auth.partner_login">
      <div class="row list-table-main">
        <div class="col-xl-4">
          <div class="card">
            <div class="card-body">
              <div class="clearfix">
                <div class="float-right">
                  <div class="input-group input-group-sm">
                    <h4 class="card-title mb-4">(30 Days)</h4>
                  </div>
                </div>
                <h4 class="card-title mb-4">Top Selling Items</h4>
              </div>

              <div v-if="selling_count > 0">
                <div class="text-muted text-center">
                  <!-- <p class="mb-2">{{ dashboard.items[0].item_name }}</p> -->
                </div>

                <div class="table-responsive mb-0 item-menu">
                  <table
                    class="table table-centered mb-0"
                    v-for="item in dashboard.items"
                    :key="item.id"
                  >
                    <tbody>
                      <tr>
                        <td>
                          <img
                            :src="item.image"
                            @error="$event.target.src=config.no_image"
                            alt
                            class="avatar-md rounded-circle img-thumbnail"
                          />
                        </td>
                        <td>
                          <h5 class="font-size-14 mb-1">{{ item.item_name }}</h5>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div v-else>
                <p class="text-center">{{ trans.get('__JSON__.No Selling Item Found') }}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-4">
          <div class="card">
            <div class="card-body">
              <div class="clearfix">
                <div class="float-right"></div>
                <h4 class="card-title mb-4">Items Low In Stock</h4>
              </div>

              <div v-if="item_count > 0">
                <div class="text-muted text-center">
                  <!-- <p class="mb-2">{{ dashboard.items_menu[0].submenu }}</p> -->
                </div>

                <div class="table-responsive mt-4 mb-0 item-menu">
                  <table
                    class="table table-centered mb-0"
                    v-for="item_menu in dashboard.items_menu"
                    :key="item_menu.id"
                  >
                    <tbody>
                      <tr>
                        <td>
                          <img
                            :src="item_menu.image"
                            @error="$event.target.src=config.no_image"
                            alt
                            class="avatar-md rounded-circle img-thumbnail"
                          />
                        </td>
                        <td>
                          <h5 class="font-size-14 mb-1">{{ item_menu.submenu }}</h5>
                          <div v-if="item_menu.status == '1'">
                            <p class="text-muted mb-0">{{ item_menu.count }} Available</p>
                          </div>
                          <div v-else>
                            <p class="text-muted mb-0">{{ item_menu.count }} Not Available</p>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div v-else>
                <p class="text-center">{{ trans.get('__JSON__.No Item Found') }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
     <div>
      <div class="row list-table-main">
        <div class="col-xl-4">
          <div class="card">
            <div class="card-body">
              <div class="clearfix">
                <div class="float-right">
                  <div class="input-group input-group-sm">
                    <h4 class="card-title mb-4">(30 Days)</h4>
                  </div>
                </div>
                <h4 class="card-title mb-4">Top {{ $auth.setting.restaurant_name }}</h4>
              </div>

              <div v-if="thirty_vendor > 0">
                <div class="table-responsive mt-4 mb-0 item-menu m-item-menu">
                  <table
                    class="table table-centered mb-0"
                    v-for="vendors in dashboard.thirty_vendor"
                    :key="vendors.id"
                  >
                    <tbody>
                      <tr>
                        <td>
                          <img
                            :src="config.restauranticon+vendors.icon_image"
                            @error="replaceByDefault"
                            alt
                            class="avatar-md rounded-circle img-thumbnail"
                          />
                        </td>
                        <td>
                          <h5 class="font-size-14 mb-1 dashboard-thirty">
                            <router-link
                              :to="{ name: 'vendor-detail',params: { restaurant_id: base64(vendors.restaurant_id) }}"
                            >{{ vendors.name }}</router-link>
                          </h5>
                          <p class="text-muted mb-0">{{ vendors.vendor_order_count }} Orders</p>
                          <p class="text-muted mb-0">{{ vendors.vendor_total_count }} Total Amount</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div v-else>
                <p class="text-center">{{ trans.get('__JSON__.No Vendor Found') }}</p>
              </div>
            </div>
          </div>
        </div>
                <div class="col-xl-4">
          <div class="card">
            <div class="card-body">
              <div class="clearfix">
                <div class="float-right">
                  <div class="input-group input-group-sm">
                    <h4 class="card-title mb-4">(120 Days)</h4>
                  </div>
                </div>
                <h4 class="card-title mb-4">Top Coupons</h4>
              </div>

              <div v-if="top_promocodes > 0">
                <div class="table-responsive mt-4 mb-0 item-menu m-item-menu">
                  <table
                    class="table table-centered mb-0"
                    v-for="promocodes in dashboard.top_promocodes"
                    :key="promocodes.id"
                  >
                    <tbody>
                      <tr>
                        <td>
                          <img
                            :src="config.couponicon+promocodes.coupon_image"
                            @error="replaceByDefault"
                            alt
                            class="avatar-md rounded-circle img-thumbnail"
                          />
                        </td>
                        <td>
                          <h5 class="font-size-14 mb-1 dashboard-thirty">
                           {{ promocodes.coupon_code }}
                          </h5>
                          <p class="text-muted mb-0">{{ promocodes.coupon_count }} Orders</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div v-else>
                <p class="text-center">{{ trans.get('__JSON__.No Coupons Found') }}</p>
              </div>
            </div>
          </div>
        </div>
    
        
        </div>
      </div>
    <!-- end row -->
  </Layout>
</template>
