
<script>
import DateRangePicker from "vue2-daterange-picker";
import OrderList from "./order_tabs/order-list";
import pagination from "laravel-vue-pagination";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import { orderService } from '../../services';
import { date_ranges, debounce, order_statuses } from '../../Helper/helper';
export default {
  components: { DateRangePicker, OrderList,pagination },
  props:['filterChange'],
  data() {
    let startDate = this.$route.query.date && this.moment(this.$route.query.date).startOf('day').toDate() || this.moment().startOf('day').toDate();
    let endDate = this.$route.query.date && this.moment(this.$route.query.date).startOf('day').toDate() || this.moment().endOf('day').toDate();
    return {
      dateRange: { startDate, endDate },
      statuses: order_statuses,
      pending_invoice: "",
      delivery_pickup_types:"",
      orders: null,
      ordersCount: null,
      loading: false,
      limit: 1,
      tabData: {
        placed: null,
        scheduled: null,
        inkitchen: null,
        readytoserve: null,
        ontheway: null,
        delivered: null,
        cancelled: null,
        paymentfailure: null
      },
      filters: {
        status: order_statuses.placed,
        search: '',
        start_date: this.moment().format('YYYY-MM-DD'),
        end_date: this.moment().format('YYYY-MM-DD'),
        abandonedstatus: '',
        pending_invoice: '',
        delivery_pickup_types: ''
      },
      driverColumns: [
        { key: "driver", label: this.trans.get("__JSON__.Driver Name",{driver: this.$auth.getDriverName()}), sortable: false},
        { key: "invoice_date", label:"Invoice Date" , sortable: false}
        // formatter: value => (value.length > 0 ?`${value[0].firstname+' '+value[0].lastname}`:'-')
      ],
      scheduleColumns: [
        { key: "future_delivery_date", label: this.trans.get("__JSON__.Schedule Time"), sortable: false }
      ],
      cancelledColumns: [
        { key: "cancelled_date", label: this.trans.get("__JSON__.Cancel Time"), sortable: false },
      ],
      confirmColumns:[
        { key: "assign", label: this.trans.get("__JSON__.Assign"), sortable: false , requiresAdmin: true},
        { key: "track", label: this.trans.get("__JSON__.Track"), sortable: false , requiresAdmin: true},
        { 
          key: "driver", 
          label: this.trans.get("__JSON__.Driver Name",{driver: this.$auth.getDriverName()}),
          sortable: false,
          // formatter: value => (value.length > 0 ?`${value[0].firstname+' '+value[0].lastname}`:'-')
        }
      ],
      readyToDeliveryColumns: [
        { key: "assign", label: this.trans.get("__JSON__.Assign"), sortable: false , requiresAdmin: true},
        { key: "track", label: this.trans.get("__JSON__.Track"), sortable: false , requiresAdmin: true},
        { 
          key: "driver", 
          label: this.trans.get("__JSON__.Driver Name",{driver: this.$auth.getDriverName()}),
          sortable: false,
          // formatter: value => (value.length > 0 ?`${value[0].firstname+' '+value[0].lastname}`:'-')
        }
      ],
      onTheWayColumns: [
        { key: "track", label: this.trans.get("__JSON__.Track"), sortable: false , requiresAdmin: true},
        { key: "driver", label: this.trans.get("__JSON__.Driver Name",{driver: this.$auth.getDriverName()}), sortable: false,
        // formatter: value => (value.length > 0 ?`${value[0].firstname+' '+value[0].lastname}`:'-')
      }
      ],
      debounce,
      searchChanged:debounce(()=>{
          this.getOrders()
          this.getOrdersCount();
      },500),
      date_ranges: date_ranges
    };
  },
  watch:{
    "$auth.orders": function(){
      this.getData();
    },
    "$route.query": function(){
      this.dateRange =this.changeSettingRange();
      this.filters = {
        ...this.filters,
        status: this.$route.query.status?this.$route.query.status:order_statuses.placed,
        start_date: this.$route.query.date || this.moment(this.dateRange.startDate).format('YYYY-MM-DD'),
        end_date: this.$route.query.date || this.moment(this.dateRange.endDate).format('YYYY-MM-DD')
      }
      
      this.getOrders();
      this.getOrdersCount();
    }
  },
  mounted() {
    console.log(this.$auth.user,"this.$auth.userthis.$auth.user", this.$auth);
    this.dateRange =this.changeSettingRange();
    this.filters = {
      ...this.filters,
      status: this.$route.query.status?this.$route.query.status:order_statuses.placed,
      start_date: this.$route.query.date || this.moment(this.dateRange.startDate).format('YYYY-MM-DD'),
      end_date: this.$route.query.date || this.moment(this.dateRange.endDate).format('YYYY-MM-DD')
    }
    this.getOrders();
    this.getOrdersCount();
  },
  computed:{
    getSelected:function(){
      const { start_date, end_date } = this.filters;
      const date_ranges = this.date_ranges();
      return Object.keys(date_ranges).reduce((accu, day) => {
        if(this.moment(date_ranges[day][0]).format('YYYY-MM-DD') == start_date && this.moment(date_ranges[day][1]).format('YYYY-MM-DD') == end_date){
          return day;
        }
        return accu;
      },'');
    }
  },
  methods: {
    updateValues(date) {
      this.filters = {
        ...this.filters,
        start_date :  this.moment(date.startDate.toISOString()).format('YYYY-MM-DD'),
        end_date :  this.moment(date.endDate.toISOString()).format('YYYY-MM-DD') 
      }
      this.getNewOrders()
      this.getData()
    },
    getData(){
      this.getOrders();
      this.getOrdersCount();
    },
    getNewOrders(){
      orderService
        .getNewOrders()
        .then((response) => {
          if(typeof response.data.result == 'object'){
            // if(response.data.result.length !== this.$auth.orders.length){
              if(response.data.result.length !== this.tabData[this.filters.status].length){
              // this.$auth.updateOrders(response.data.result)
              this.tabData.placed = response.data.result;
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
        });
    },
    dateFormat(classes, date) {
      this.filters = {
        ...this.filters,
        start_date :  this.moment(date.startDate.toISOString()).format('YYYY-MM-DD'),
        end_date :  this.moment(date.endDate.toISOString()).format('YYYY-MM-DD') 
      }
    },
    getOrders(page = 1) {
      this.loading = true;
      orderService
        // .getOrders({
          .exportAllOrders({
          ...this.filters,
          page,
          paginate:true,
        })
        .then((response) => {
          // this.orders = response.data.result;
          switch (this.filters.status) {
           case "Placed":
             this.tabData.placed = response.data.result;
            break;
           case "Scheduled":
             this.tabData.scheduled = response.data.result;
            break;
           case "InKitchen":
             this.tabData.inkitchen = response.data.result;
            break;
           case "ReadyToServe":
             this.tabData.readytoserve = response.data.result;
            break;
           case "OnTheWay":
            this.tabData.ontheway = response.data.result;
            break;
           case "Delivered":
            this.tabData.delivered = response.data.result;
            break;
           case "Cancelled":
            this.tabData.cancelled = response.data.result;
            break;
           case "PaymentFailure":
           this.tabData.paymentfailure = response.data.result;
            break;
         }
           setTimeout(() => {
            this.loading = false;
          }, 500);
          this.$emit('filterChange',this.filters);
        })
        .catch(function (error) {
          this.loading = false;
          // console.log(error);
        });
    },
    onTabChanged(tabIndex){
      const { placed, scheduled, inkitchen, readytoserve, ontheway, delivered, cancelled, paymentfailure } = order_statuses;
      var statuses = [placed, scheduled, inkitchen, readytoserve, ontheway, delivered, cancelled, paymentfailure];
      this.filters.status = statuses[tabIndex];
      if (this.filters.status != 'PaymentFailure') {
        this.filters.abandonedstatus = '';
      }
      this.getData();
      this.getNewOrders();
    },
    getOrdersCount(){
      this.loading = true;
      orderService
        .getOrdersCount({
          ...this.filters
        })
        .then((response) => {
          this.loading = false;
          this.ordersCount = response.data.result;
        })
        .catch(function (error) {
          this.loading = false;
          // console.log(error);
        });
    },
    changeSettingRange(){
      var now = this.moment();
      var then = this.moment();
      if(this.$auth && this.$auth.setting){
        var range = this.$auth.setting.order_dashboard_default_time;
        var date_ranges = this.date_ranges();
        var keys = Object.keys(date_ranges)
        switch(range){
          case "0":
            return {startDate: date_ranges[keys[0]][0],endDate:date_ranges[keys[0]][1]};
          case "1":
            return {startDate: date_ranges[keys[1]][0],endDate:date_ranges[keys[1]][1]};
          case "6":
            return {startDate: date_ranges[keys[2]][0],endDate:date_ranges[keys[2]][1]};
          case "29":
            return {startDate: date_ranges[keys[3]][0],endDate:date_ranges[keys[3]][1]};
          case "365":
            return {startDate: date_ranges[keys[4]][0],endDate:date_ranges[keys[4]][1]};
          case "101":
            return {startDate: date_ranges[keys[5]][0],endDate:date_ranges[keys[5]][1]};
          default:
            return { startDate: now.startOf('day').toDate(), endDate: then.endOf('day').toDate() }
        }
      }
      return { startDate: now.startOf('day').toDate(), endDate: then.endOf('day').toDate() }
    },
    emitChange() {
      this.getNewOrders();
      this.getData();
    },
    abandonedstatusEmitchanged(val) {
      this.filters = {
        ...this.filters,
        abandonedstatus : val
      }
      this.getNewOrders();
      this.getData();
    },
    vendorInvoice(){
      this.filters = {
        ...this.filters,
        pending_invoice : this.pending_invoice
      }
      this.getNewOrders();
      this.getData();
    },
    orderType(){
      this.filters = {
        ...this.filters,
        delivery_pickup_types : this.delivery_pickup_types
      }
      this.getNewOrders();
      this.getData();
    },
    requestPrice(){
      this.$router.push({ name: "request-price" });
    }
  },
  filters: {
    date(val) {
      return val ? val.toLocaleString() : "";
    },
  },
  
};
</script>
<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <div class="justify-content-end row m-order-list">
                <div v-if="!$auth.partner_login || $auth.user.restaurant.restaurant_category == 'SUPER_SPECIALITY'"class="ml-2" style="display: flex; align-items: end; margin-bottom: 17px;">
                  <button class="btn btn-primary btn-rounded export-btn" @click="requestPrice">Price Request</button>
                </div>
                <div class="ml-2">
                  <label>{{ trans.get("__JSON__.Order Type") }} :</label>
                    <select
                      class="custom-select"
                      v-model="delivery_pickup_types"
                      @change="orderType()"
                    >
                    <option value="" selected>{{ trans.get("__JSON__.All") }}</option>
                      <option value="Delivery">
                        {{ trans.get("__JSON__.Delivery") }}
                      </option>
                      <option value="Pickup">
                        {{ trans.get("__JSON__.Pickup") }}
                      </option>
                    </select>
                </div>
                <div class="ml-2">
                  <label>{{ trans.get("__JSON__.Vendor Invoice") }} :</label>
                    <select
                      class="custom-select"
                      v-model="pending_invoice"
                      @change="vendorInvoice()"
                    >
                    <option value="" selected>{{ trans.get("__JSON__.All") }}</option>
                      <option value="yes">
                        {{ trans.get("__JSON__.Pending") }}
                      </option>
                      <option value="no">
                        {{ trans.get("__JSON__.Uploaded") }}
                      </option>
                    </select>
                </div>
                <div class="" v-if="filters.status != 'Scheduled'">
                  <div class="form-group ml-2">
                       <!-- <b-form-group id="example-date-time" label-cols-sm="2" label-cols-lg="2" label="Date and time" label-for="date-time">
                                    <b-form-input id="date-time" value="2019-08-19T13:45:00" type="datetime-local"></b-form-input>
                                </b-form-group> -->
                    <div class="date-range-list">
                      <label>{{trans.get('__JSON__.Date')}} {{(getSelected!=''?('('+getSelected+')'):'')}} :</label>
                      <date-range-picker
                        ref="picker"
                        :locale-data="{
                          firstDay: 1,
                          format: 'dd-mm-yyyy',
                        }"
                        :showDropdowns="true"
                        :showWeekNumbers="true"
                        opens="left"
                        v-model="dateRange"
                        class="w-100"
                        :ranges="date_ranges()"
                        @update="updateValues"
                      >
                        <template
                          v-slot:input="picker"
                          style="min-width: 350px"
                        >
                          {{ moment(picker.startDate.toISOString()).format('DD-MM-YYYY') | date }} -
                          {{ moment(picker.endDate.toISOString()).format('DD-MM-YYYY') | date }}
                        </template>
                      </date-range-picker>
                    </div>
                  </div>
                </div>
                <div class="ml-2">
                  <div class="search-box mr-2 mb-0 d-inline-block form-group">
                    <div class="date-range-list">
                      <label>{{trans.get('__JSON__.Search')}} :</label>
                      <div class="position-relative">
                        <input
                          type="text"
                          class="form-control"
                          v-model="filters.search"
                          @keyup="searchChanged"
                          :placeholder="trans.get('__JSON__.Search...')"
                        />
                        <i class="bx bx-search-alt search-icon"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <b-tabs
                justified
                nav-class="nav-tabs-custom mt-0 order-tabs"
                content-class="p-3 text-muted"
                lazy
                @activate-tab="onTabChanged"
              >
                <b-tab :button-id="statuses.placed" >
                  <template v-slot:title>
                    <span class="d-inline-block d-sm-none">
                      <i class="fas fa-plus"></i>
                    </span>
                    <span class="d-none d-sm-inline-block">{{
                      trans.get("__JSON__.New Requests") 
                    }}</span>
                    <span v-if="ordersCount && ordersCount.placed_orders && ordersCount.placed_orders !== '0'" class="badge badge-success font-size-12">
                      {{ ordersCount && ordersCount.placed_orders || 0 }}
                    </span>
                  </template>
                  <OrderList :status="filters.status" :orders="tabData.placed" :loading="loading" @refreshData="emitChange" />
                  <!-- <OrderList :status="filters.status" :orders="orders" :loading="loading" @refreshData="emitChange"/> -->
                </b-tab>
                <b-tab :button-id="statuses.scheduled" :active="$route.query.status == 'Scheduled'">
                  <template v-slot:title>
                    <span class="d-inline-block d-sm-none">
                      <i class="fas fa-calendar"></i>
                    </span>
                    <span class="d-none d-sm-inline-block">{{
                      trans.get("__JSON__.Scheduled")
                    }}</span>
                    <span v-if="ordersCount && ordersCount.scheduled_orders && ordersCount.scheduled_orders !== '0'" class="badge badge-info font-size-12">
                      {{ ordersCount && ordersCount.scheduled_orders || 0 }}
                    </span>
                  </template>
                  <OrderList :status="filters.status" :orders="tabData.scheduled" :loading="loading" :extra-columns="scheduleColumns" @refreshData="emitChange" />
                  <!-- <OrderList :status="filters.status" :orders="orders" :loading="loading" :extra-columns="scheduleColumns" @refreshData="emitChange" /> -->
                </b-tab>
                <b-tab :button-id="statuses.inkitchen">
                  <template v-slot:title>
                    <span class="d-inline-block d-sm-none">
                      <i class="fas fa-check"></i>
                    </span>
                    <span class="d-none d-sm-inline-block">{{
                      trans.get("__JSON__.Confirmed")
                    }}</span>
                    <span v-if="ordersCount && ordersCount.kitchen_orders && ordersCount.kitchen_orders !== '0'" class="badge badge-primary font-size-12">
                      {{ ordersCount && ordersCount.kitchen_orders || 0 }}
                    </span>
                  </template>
                  <OrderList :status="filters.status" :orders="tabData.inkitchen" :loading="loading" @refreshData="emitChange" :extra-columns="confirmColumns" />
                  <!-- <OrderList :status="filters.status" :orders="orders" :loading="loading" @refreshData="emitChange" :extra-columns="confirmColumns"/> -->
                </b-tab>
                <b-tab :button-id="statuses.readytoserve">
                  <template v-slot:title>
                    <span class="d-inline-block d-sm-none">
                      <i class="fas fa-bus"></i>
                    </span>
                    <span class="d-none d-sm-inline-block">{{
                      trans.get("__JSON__.Ready to Delivery")
                    }}</span>
                    <span v-if="ordersCount && ordersCount.ready_orders && ordersCount.ready_orders !== '0'" class="badge badge-info font-size-12">
                      {{ ordersCount && ordersCount.ready_orders || 0 }}
                    </span>
                  </template>
                  <OrderList :status="filters.status" :orders="tabData.readytoserve" :loading="loading" @refreshData="emitChange" :extra-columns="readyToDeliveryColumns" />
                  <!-- <OrderList :status="filters.status" :orders="orders" :loading="loading" @refreshData="emitChange" :extra-columns="readyToDeliveryColumns" /> -->
                </b-tab>
                <b-tab :button-id="statuses.ontheway">
                  <template v-slot:title>
                    <span class="d-inline-block d-sm-none">
                      <i class="fas fa-bus"></i>
                    </span>
                    <span class="d-none d-sm-inline-block">{{
                      trans.get("__JSON__.On The Way")
                    }}</span>
                    <span v-if="ordersCount && ordersCount.ontheway_orders && ordersCount.ontheway_orders !== '0'" class="badge badge-warning font-size-12">
                      {{ ordersCount && ordersCount.ontheway_orders || 0 }}
                    </span>
                  </template>
                  <OrderList :status="filters.status" :orders="tabData.ontheway" :loading="loading" @refreshData="emitChange" :extra-columns="onTheWayColumns" />
                  <!-- <OrderList :status="filters.status" :orders="orders" :loading="loading" :extra-columns="onTheWayColumns" @refreshData="emitChange" /> -->
                </b-tab>
                <b-tab :button-id="statuses.delivered">
                  <template v-slot:title>
                    <span class="d-inline-block d-sm-none">
                      <i class="fas fa-bus"></i>
                    </span>
                    <span class="d-none d-sm-inline-block">{{
                      trans.get("__JSON__.Delivered")
                    }}</span>
                    <span v-if="ordersCount && ordersCount.delivered_orders && ordersCount.delivered_orders !== '0'" class="badge badge-info font-size-12">
                      {{ ordersCount && ordersCount.delivered_orders || 0 }}
                    </span>
                  </template>
                  <OrderList :status="filters.status" :orders="tabData.delivered" :loading="loading" @refreshData="emitChange" :extra-columns="driverColumns" />
                  <!-- <OrderList :status="filters.status" :orders="orders" :loading="loading" :extra-columns="driverColumns" @refreshData="emitChange"/> -->
                </b-tab>
                <b-tab :button-id="statuses.cancelled">
                  <template v-slot:title>
                    <span class="d-inline-block d-sm-none">
                      <i class="fas fa-times"></i>
                    </span>
                    <span class="d-none d-sm-inline-block">{{
                      trans.get("__JSON__.Cancelled")
                    }}</span>
                    <span v-if="ordersCount && ordersCount.cancelled_orders && ordersCount.cancelled_orders !== '0'" class="badge badge-danger font-size-12">
                      {{ ordersCount && ordersCount.cancelled_orders || 0 }}
                    </span>
                  </template>
                  <OrderList :status="filters.status" :orders="tabData.cancelled" :loading="loading" @refreshData="emitChange" :extra-columns="cancelledColumns" />
                  <!-- <OrderList :status="filters.status" :orders="orders"  :loading="loading" :extra-columns="cancelledColumns" @refreshData="emitChange" /> -->
                </b-tab>
                <b-tab :button-id="statuses.paymentfailure" v-if="!$auth.partner_login">
                  <template v-slot:title>
                    <span class="d-inline-block d-sm-none">
                      <i class="fas fa-credit-card"></i>
                    </span>
                    <span class="d-none d-sm-inline-block">{{
                      trans.get("__JSON__.Abandoned")
                    }}</span>
                    <span v-if="ordersCount && ordersCount.failed_orders && ordersCount.failed_orders !== '0'" class="badge badge-danger font-size-12">
                      {{ ordersCount && ordersCount.failed_orders || 0 }}
                    </span>
                  </template>
                  <OrderList :status="filters.status" :orders="tabData.paymentfailure" :loading="loading" @refreshData="emitChange" @abandonedstatusEmitchanged="abandonedstatusEmitchanged($event)"/>
                  <!-- <OrderList :status="filters.status" :orders="orders"  :loading="loading" @refreshData="emitChange" @abandonedstatusEmitchanged="abandonedstatusEmitchanged($event)"/> -->
                </b-tab>
              </b-tabs>
            </div>
            <div class="col-12">
              <div class="dataTables_paginate paging_simple_numbers float-right">
                <ul class="pagination pagination-rounded mb-0" v-if="filters.status == 'Placed'">
                  <pagination
                      :data="tabData.placed"
                      :limit="limit"
                      @pagination-change-page="getOrders"
                  ></pagination>
                </ul>
                <ul class="pagination pagination-rounded mb-0" v-if="filters.status == 'Scheduled'">
                  <pagination
                      :data="tabData.scheduled"
                      :limit="limit"
                      @pagination-change-page="getOrders"
                  ></pagination>
                </ul>
                <ul class="pagination pagination-rounded mb-0" v-if="filters.status == 'InKitchen'">
                  <pagination
                      :data="tabData.inkitchen"
                      :limit="limit"
                      @pagination-change-page="getOrders"
                  ></pagination>
                </ul>
                <ul class="pagination pagination-rounded mb-0" v-if="filters.status == 'ReadyToServe'">
                  <pagination
                      :data="tabData.readytoserve"
                      :limit="limit"
                      @pagination-change-page="getOrders"
                  ></pagination>
                </ul>
                <ul class="pagination pagination-rounded mb-0" v-if="filters.status == 'OnTheWay'">
                  <pagination
                      :data="tabData.ontheway"
                      :limit="limit"
                      @pagination-change-page="getOrders"
                  ></pagination>
                </ul>
                <ul class="pagination pagination-rounded mb-0" v-if="filters.status == 'Delivered'">
                  <pagination
                      :data="tabData.delivered"
                      :limit="limit"
                      @pagination-change-page="getOrders"
                  ></pagination>
                </ul>
                <ul class="pagination pagination-rounded mb-0" v-if="filters.status == 'Cancelled'">
                  <pagination
                      :data="tabData.cancelled"
                      :limit="limit"
                      @pagination-change-page="getOrders"
                  ></pagination>
                </ul>
                <ul class="pagination pagination-rounded mb-0" v-if="filters.status == 'PaymentFailure'">
                  <pagination
                      :data="tabData.paymentfailure"
                      :limit="limit"
                      @pagination-change-page="getOrders"
                  ></pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
</style>