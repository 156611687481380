<script>
import pagination from "laravel-vue-pagination";
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header.vue";
import config from "../../config";
import { customerService } from "../../services";
import moment from "moment";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
export default {
  components: { pagination, Layout, PageHeader, DateRangePicker},
  data() {
    let startDate = this.moment().startOf("day").subtract(6, "days");
    let endDate = this.moment().endOf("day").toDate();
    return {
      moment: moment,
      dateRange: { startDate, endDate },
      config: config,
      history: null,
      loading:false,
      fields: [
        { label: "#", key: "recharge_id", sortable: false },
        { label: "Date", key: "created_at", sortable: false },
        { label: "Time", key: "time", sortable: false },
        { label: "Customer Name", key: "customer_name", sortable: false },
        { label: "Customer Number", key: "mobile_number", sortable: false },
        { label: "Email", key: "email", sortable: false },
      ],
      isDownloading:'',
      referralData:'',
      filters: {
        start_date: this.moment()
              .startOf("day")
              .subtract(6, "days")
              .format("YYYY-MM-DD"),
        end_date: this.moment().format("YYYY-MM-DD"),
      },
    };
  },
  mounted(){
      this.getHistory();
  },
  methods: {
    updateValues(date) {
      this.filters = {
        ...this.filters,
        start_date: this.moment(date.startDate.toISOString()).format(
          "YYYY-MM-DD"
        ),
        end_date: this.moment(date.endDate.toISOString()).format("YYYY-MM-DD"),
      };
      this.getHistory();
    },
    getHistory(page = 1) {
      console.log(this.filters, "filters");
      this.loading = true;
      let data = {
       affiliate_id: parseInt(atob(this.$route.params.affiliate_id)),
       pagination:true,
       page: page && page > 0 ? page : 1,
       start_date: this.filters.start_date,
       end_date: this.filters.end_date
      }
      customerService.getRefferalHistoryAffiliate(data)
      .then(response => {
        console.log(response, "affiliate history");
        if(response.data.code ==200){
          console.log("hiiiii");
          this.loading = false;
          this.history = response.data.result;
        }
		});      
    },
    affiliateExportCsv(){
      this.isDownloading = true;
      let data = {
       affiliate_id: parseInt(atob(this.$route.params.affiliate_id)),
       pagination:false,
       start_date: this.filters.start_date,
       end_date: this.filters.end_date
      }
      customerService
        .getRefferalHistoryAffiliate(data)
        .then((response) => {
          this.isDownloading = false;
          this.referralData = response.data.result;
          this.downloadsCSV();
        });
    },
    downloadsCSV: function () {
      let referralData = "\ufeff" + "S. No.,Date,Time,Customer Name,Customer Number, Email\n";
      this.referralData.forEach((el, ind) => {
        let Sno = ind + 1;
        var line = Sno + 
          "," +
          (el["created_at"] ? moment(el["created_at"]).format('DD-MM-YYYY') : "-") +
          "," +
          (el["created_at"] ? moment(el["created_at"]).format('h:mm A') : "-") +
          "," +
          (el["user_name"] ? el["user_name"] : "-") + " " + (el["last_name"] && el["last_name"]) + 
          "," +
          el["mobile_number"] +
          "," +
          (el["email"] ? el["email"] : "-") +
          "\n";
          referralData += line;
      });
      var blob = new Blob([referralData], { type: "csv/plain" });
      var date = new Date().toLocaleString();
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "Referred-History" + date + ".csv";
      link.click();
    },
  },
  filters: {
    date(val) {
      return val ? val.toLocaleString() : "";
    },
  },
};
</script>
<template>
    <div>
        <div class="row" style="justify-content: flex-end;">
            <div style="display: flex;">
                <div style="margin: 2px 20px 0 0;">
                    <label>{{ trans.get("__JSON__.Date") }}:</label>
                    <date-range-picker ref="picker" 
                    :locale-data="{
                        firstDay: 1,
                        format: 'dd-mm-yyyy',
                    }" 
                    :showDropdowns="true" 
                    :showWeekNumbers="true" 
                    opens="left" 
                    v-model="dateRange"
                    @update="updateValues">
                    <template v-slot:input="picker" style="min-width: 350px">
                      {{ moment(picker.startDate.toISOString()).format("MMMM DD, YYYY") | date }}
                        -
                      {{ moment(picker.endDate.toISOString()).format("MMMM DD, YYYY") | date }}
                        </template>
                    </date-range-picker>
                </div>
                <div>
                    <button @click="affiliateExportCsv" class="btn btn-primary btn-rounded export-btn affiliate-export"
                        v-if="isDownloading" disabled>{{ trans.get('__JSON__.Exporting...') }}</button>
                    <button @click="affiliateExportCsv" class="btn btn-primary btn-rounded export-btn affiliate-export"
                        v-else>{{ trans.get('__JSON__.Export To CSV') }}</button>
                    <button @click="affiliateExportCsv"
                        class="btn btn-primary btn-rounded m-export-btn affiliate-export"><i
                            class="bx bx-archive-in"></i></button>
                </div>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-12 table-responsive">
                <b-table :items="history && history.data || []" :fields="fields" :busy="loading" show-empty>
                    <template #table-busy>
                        <div class="text-center text-danger my-2">
                            <b-spinner class="align-middle"></b-spinner>
                            <strong>{{trans.get('__JSON__.Loading...')}}</strong>
                        </div>
                    </template>
                    <template v-slot:cell(recharge_id)="data">
                        <span>{{ data.index + 1 + ((history.current_page-1) * history.per_page) }}</span>
                    </template>
                    <template v-slot:cell(created_at)="data">
                        {{moment(data.value).format('DD-MM-YYYY')}}
                    </template>
                    <template v-slot:cell(time)="data">
                        {{moment(data.item.created_at).format('h:mm A')}}
                    </template>
                    <template v-slot:cell(customer_name)="data">
                        {{ data.item.user_name + " " + data.item.last_name }}
                    </template>
                    <template v-slot:cell(mobile_number)="data">
                        {{ data.value || "-"}}
                    </template>
                    <template v-slot:cell(email)="data">
                        {{ data.value || "-"}}
                    </template>
                </b-table>
            </div>
            <div class="row col-sm-5 offset-sm-7" v-if="history">
                <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                        <ul class="pagination pagination-rounded mb-0">
                            <!-- pagination -->
                            <pagination :data="history" :limit="1" @pagination-change-page="getHistory"></pagination>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
 .affiliate-export{
  height: 40px;
 }

</style>