var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.FaqInIt($event)}}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('h4',{staticClass:"card-title"},[_vm._v(_vm._s(_vm.trans.get("__JSON__.FAQ Info")))]),_vm._v(" "),_c('p',{staticClass:"card-title-desc"}),_vm._v(" "),_c('div',{staticClass:"row"},[(_vm.$auth.setting)?_c('div',{staticClass:"col-sm-6"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.typeform.id),expression:"typeform.id"}],attrs:{"type":"hidden","id":"id","name":"id"},domProps:{"value":(_vm.typeform.id)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.typeform, "id", $event.target.value)}}}),_vm._v(" "),_c('b-form-group',{attrs:{"id":"input-group-1","label":_vm.trans.trans('__JSON__.Question Primary language', {
                lang: _vm.$auth.langauges[0].name,
              }),"label-for":"question"}},[_c('b-form-input',{class:{ 'is-invalid': _vm.$v.typeform.question.$error },attrs:{"id":"question","name":"question","type":"text","placeholder":_vm.trans.trans('__JSON__.Question Primary language', {
                  lang: _vm.$auth.langauges[0].name,
                })},model:{value:(_vm.$v.typeform.question.$model),callback:function ($$v) {_vm.$set(_vm.$v.typeform.question, "$model", $$v)},expression:"$v.typeform.question.$model"}}),_vm._v(" "),(_vm.$v.typeform.question.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.question.required)?_c('span',[_vm._v(_vm._s(_vm.trans.get("__JSON__.Please enter Question"))+".")]):_vm._e()]):_vm._e()],1)],1):_vm._e(),_vm._v(" "),(_vm.$auth.langauges[1].name === 'No Secondary Language')?_c('div',{staticClass:"col-sm-6 d-none"}):_c('div',{staticClass:"col-sm-6"},[_c('b-form-group',{attrs:{"id":"input-group-1","label":_vm.trans.trans('__JSON__.Question Secondary Language', {
                lang: _vm.$auth.langauges[1].name,
              }),"label-for":"question_another_lang"}},[_c('b-form-input',{attrs:{"id":"question_another_lang","name":"question_another_lang","type":"text","placeholder":_vm.trans.trans('__JSON__.Question Secondary Language', {
                  lang: _vm.$auth.langauges[1].name,
                })},model:{value:(_vm.typeform.question_another_lang),callback:function ($$v) {_vm.$set(_vm.typeform, "question_another_lang", $$v)},expression:"typeform.question_another_lang"}})],1)],1),_vm._v(" "),_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"control-label"},[_vm._v(_vm._s(_vm.trans.get("__JSON__.Type")))]),_vm._v(" "),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.typeform.type),expression:"typeform.type"}],staticClass:"custom-select",attrs:{"id":"type","name":"type"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.typeform, "type", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"customer"}},[_vm._v("\n                "+_vm._s(_vm.trans.get("__JSON__.Customer"))+"\n              ")]),_vm._v(" "),_c('option',{attrs:{"value":"store"}},[_vm._v("\n                "+_vm._s(_vm.trans.trans("__JSON__.Vendors Faq", {
                    vendor: _vm.$auth.setting.restaurant_name,
                  }))+"\n              ")]),_vm._v(" "),_c('option',{attrs:{"value":"driver"}},[_vm._v("\n                "+_vm._s(_vm.trans.get("__JSON__.Driver Faq", {
                    driver: _vm.$auth.setting.driver_name,
                  }))+"\n              ")])])])]),_vm._v(" "),_c('div',{staticClass:"col-sm-6"},[_c('b-form-group',{attrs:{"id":"input-group-1","label":_vm.trans.trans('__JSON__.Answer Primary Language', {
                lang: _vm.$auth.langauges[0].name,
              }),"label-for":"answer"}},[_c('b-form-textarea',{staticClass:"form-control",class:{ 'is-invalid': _vm.$v.typeform.answer.$error },attrs:{"rows":"5","id":"answer","type":"text","placeholder":_vm.trans.trans('__JSON__.Answer Primary Language', {
                  lang: _vm.$auth.langauges[0].name,
                }),"name":"answer"},model:{value:(_vm.$v.typeform.answer.$model),callback:function ($$v) {_vm.$set(_vm.$v.typeform.answer, "$model", $$v)},expression:"$v.typeform.answer.$model"}}),_vm._v(" "),(_vm.$v.typeform.answer.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.answer.required)?_c('span',[_vm._v(_vm._s(_vm.trans.get("__JSON__.Please enter Answer"))+".")]):_vm._e()]):_vm._e()],1)],1),_vm._v(" "),(_vm.$auth.langauges[1].name === 'No Secondary Language')?_c('div',{staticClass:"col-sm-6 d-none"}):_c('div',{staticClass:"col-sm-6"},[_c('b-form-group',{attrs:{"id":"input-group-1","label":_vm.trans.trans('__JSON__.Answer Secondary Language', {
                lang: _vm.$auth.langauges[1].name,
              }),"label-for":"answer_another_lang"}},[_c('b-form-textarea',{staticClass:"form-control",attrs:{"rows":"5","id":"answer_another_lang","type":"text","placeholder":_vm.trans.trans('__JSON__.Answer Secondary Language', {
                  lang: _vm.$auth.langauges[1].name,
                }),"name":"answer_another_lang"},model:{value:(_vm.typeform.answer_another_lang),callback:function ($$v) {_vm.$set(_vm.typeform, "answer_another_lang", $$v)},expression:"typeform.answer_another_lang"}})],1)],1),_vm._v(" "),_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("\n              FAQ Icon\n            ")]),_vm._v(" "),_c('div',{staticClass:"d-flex"},[_c('label',{staticClass:"filelabel",style:({
                      display:
                      _vm.faqimageurl || _vm.faq_icon_path ? 'none' : 'block',
                    })},[_c('i',{staticClass:"bx bx-plus plus",staticStyle:{"cursor":"pointer"}},[_c('input',{ref:"faq_icon",staticClass:"FileUpload1",attrs:{"accept":"image/jpeg,image/png,image/jpg,image/svg+xml","id":"faq_icon","type":"file"},on:{"change":_vm.uploadImage}})])])]),_vm._v(" "),_c('div',{staticClass:"uploaded-img-main"},[(!_vm.selectedimage)?_c('div',{staticClass:"prvie-img uploaded-img"},[(_vm.faq_icon_path)?_c('img',{staticClass:"dis-img1",attrs:{"src":_vm.faq_icon_path},on:{"click":_vm.openimagefileinput}}):_vm._e()]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"uploaded-img-main1"},[(
                  this.selectedimage.type == 'image/jpeg' ||
                  this.selectedimage.type == 'image/png' ||
                  this.selectedimage.type == 'image/jpg' ||
                  this.selectedimage.type == 'image/svg+xml'
                )?_c('img',{staticClass:"dis-img1",attrs:{"src":_vm.faqimageurl}}):_vm._e(),_vm._v(" "),(_vm.selectedimage)?_c('div',{staticClass:"prvie-img uploaded-img",staticStyle:{"position":"absolute","top":"0","right":"-5px"}},[_c('p',{staticClass:"remove-flg",on:{"click":_vm.removeUploadImage}},[_vm._v("X")])]):_vm._e()])])])])])]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[_c('button',{staticClass:"btn btn-primary mr-1 mt-3"},[_vm._v("\n            "+_vm._s(_vm.trans.get("__JSON__.Update FAQ"))+"\n          ")]),_vm._v(" "),_c('b-button',{staticClass:"mt-3",attrs:{"type":"button","variant":"secondary"},on:{"click":function($event){return _vm.$router.back()}}},[_vm._v(_vm._s(_vm.trans.get("__JSON__.Cancel"))+"\n            ")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }