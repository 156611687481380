
// Banner services rest api calling

import { multipart_headers } from '../Helper/helper';
import { routes } from '../config/api_routes'

export const bannerService = {
    // get banner 
    // info: sortDesc : Boolean

    getBanner: (formData) => {    
        return window.axios.post(routes.getBannerApi,formData)
    },   
    
    // banner export to csv
    exportToCsv: (formData) => window.axios.post(routes.bannerExportToCsvApi, formData),

    // banner add

    addBanner: (formData) => {
        return window.axios.post(routes.addbannerApi,formData,{headers: multipart_headers() });
    },

    // get restaurant

    getRestaurant: (query) => {
        return window.axios.post(routes.getRestaurantApi,query)
    },

    // get bannerbyID
    getBannerById: (formData) => {
         return window.axios.post(routes.getBannerByIdApi,formData)
    },

    // update bannerByID

    updateBannerById: (formData) => window.axios.post(routes.updateBannerApi,formData,{headers: multipart_headers() }),

    // delete banner

    deletebanner: (formData) => {
        return window.axios.post(routes.deleteBannerApi,formData)
    },

    // Reorder Banner

    updateBannerOrder: (formData) => {
        return window.axios.post(routes.updateBannerOrderApi,formData)
    }
}