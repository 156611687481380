
// PromoCode services rest api calling

import { multipart_headers } from '../Helper/helper';
import { routes } from '../config/api_routes'

export const promoCodeService = {
    // get promoCode 
    // info: sortDesc : Boolean

    getPromoCode: (formData) => window.axios.post(routes.getPromoCodeApi,formData),
    
    // promoCode export to csv
    exportToCsv: () => window.axios.get(routes.promoCodeExportToCsvApi),

    // promoCode add
    addPromoCode: (formData) => window.axios.post(routes.addPromoCodeApi,formData,{headers: multipart_headers() }),

    // get customer
    getCustomer: (query) => window.axios.post(routes.getCustomerLisingApi,query),

    // get promoCodeById
    getPromoCodeById: (formData) => window.axios.post(routes.getPromoCodeByIdApi,formData),

    // update promoCodeById
    updatePromoCodeById: (formData) => window.axios.post(routes.updatePromoCodeApi,formData,{headers: multipart_headers() }),

    // apply promo code
    applyPromoCode: (data) => window.axios.post(routes.applyPromoCodeApi,data ),

}