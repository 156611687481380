<template>
  <Layout>
  <div class="row">
    <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
        >
          <div class="page-title-left avatar-main-icon">
            <div class="avatar-xs mr-3">
              <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                <i class="bx bx-store"></i>
              </span>
            </div>
            <div class="report-tag">
              <h4 class="mb-0 font-size-18">{{ restaurant && restaurant.name || '' }}</h4>
              <p><router-link :to="{ name: 'vendor-list'}">{{ trans.trans('__JSON__.Vendor',{ vendor: $auth.setting.restaurant_name }) }}</router-link> > {{ restaurant && restaurant.name || '' }}</p>
            </div>
            <a class="avatar-xs ml-2 custom-qr" v-if="(feedbackQr != '' || qrCode != '') && $auth.setting && $auth.isWebOrderingActive() && vendor_id == 40818" @click="qrModal = true">
              <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                <i class="mdi mdi-qrcode"></i>
              </span>
            </a>
          </div>
          <div class="page-title-left avatar-main-icon">
            <div class="row">
              <div class="col-md-3 ml-auto" v-if="!$auth.partner_login && restaurant_id" style="margin-top:25px;">
                <router-link
                  :to="{name: 'vendor-catalog',params: { restaurant_id: base64(restaurant_id)}}"
                  class="btn btn-success btn-rounded"
                >{{ trans.get('__JSON__.Catalog') }}</router-link>
              </div>
              <div class="col-md-3 ml-auto" v-if="$auth.partner_login" style="margin-top:25px;">
                <router-link
                  :to="{name: 'vendor.vendor.catalog'}"
                  class="btn btn-success btn-rounded"
                >{{ trans.get('__JSON__.Catalog') }}</router-link>
              </div>
              <div class="col-md-5">
                <label class="control-label font-weight-bold">{{ trans.get('__JSON__.Profile Status') }}</label> 
                  <select class="custom-select" v-bind:value="is_confirm" v-model="is_confirm"  id="is_confirm" name="is_confirm" @change="changeConfirm"> 
                    <option value="0">{{ trans.get('__JSON__.Inactive') }}</option>
                    <option value="1">{{ trans.get('__JSON__.Active') }}</option>
                    <option value="2">{{ trans.get('__JSON__.Waiting For Approval') }}</option>
                  </select>
              </div>
              <div class="col-md-4">
                <label class="control-label font-weight-bold">{{ trans.get('__JSON__.Accepting Order') }}</label>
                <div class="swtich-data">
                  <b-form-group>
                    <label class="switch">
                      <input
                        type="checkbox"
                        id="status"
                        name="status"
                        v-model="restaurant_on_off"
                        class="switch-on"
                        true-value="1"
                        false-value="0"
                        @change="onStatusChange"
                      />
                      <div class="slider round">
                        <span class="on">{{ trans.get('__JSON__.Yes') }}</span>
                        <span class="off">{{ trans.get('__JSON__.No') }}</span>
                      </div>
                    </label>
                  </b-form-group>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="nav-tabs-main">
        <div class="card">
          <div class="card-body">
            <!-- Tab panel start-->
            <b-tabs
              justified
              nav-class="nav-tabs-custom mt-3 order-tabs"
              content-class="p-3 text-muted"
            >
              <b-tab active lazy>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-user"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">{{ trans.get('__JSON__.Profile') }}</span>
                </template>
                <vendor-profile v-if="!!restaurant" :rest_data="restaurant" />
              </b-tab>
              <b-tab lazy>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-clock"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">{{ trans.get('__JSON__.Operation Time') }}</span>
                </template>
                <operation-time v-if="!!restaurant" :restaurant="restaurant"/>
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-star"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">{{ trans.get('__JSON__.Rating & Reviews') }}</span>
                </template>
                <vendor-rating />
              </b-tab>
              <!-- <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-times-circle"></i>
                  </span>
                  <span class="d-none d-sm-inline-block text-capitalize">{{ trans.get('__JSON__.Menu time-slot') }}</span>
                </template>
                <selling-timeslot/>
              </b-tab> -->
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-user"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">{{ trans.get('__JSON__.Category') }}</span>
                </template>
                <vendor-category />
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-percentage"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">{{ trans.get('__JSON__.Discount') }}</span>
                </template>
                <vendor-discount/>
              </b-tab>
              <b-tab lazy v-if="$auth.hasPermission(config.permissions.merchant_write)">
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-lock"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">{{ trans.get('__JSON__.Change Password') }}</span>
                </template>
                <change-password v-if="!!restaurant" :rest_data="restaurant"/>
              </b-tab>
              <b-tab lazy v-if="$auth.hasPermission(config.permissions.merchant_write)">
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-lock"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">{{ trans.get('__JSON__.T&C') }}</span>
                </template>
                <terms-condition />
              </b-tab>
            </b-tabs>
            <!-- Tab panel end -->
          </div>
        </div>
        </div>
      </div>
    </div>

    <b-modal id="modal-standard" title="QR Code" title-class="font-18" v-model="qrModal" hide-footer>
      <div class='col-md-12 mt-2'> 
        <div class='row qr-code-menu'> 
          <b-tabs content-class="p-3 text-muted">
            <b-tab active class="border-0">
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="fas fa-home"></i>
                </span>
                <span class="d-none d-sm-inline-block">{{ trans.get('__JSON__.Dine In Menu') }}</span>
              </template>
              <img :src="qrCode" /> 
              <a class="mt-3 d-block btn btn-primary" :href="qrCode" download>{{ trans.get('__JSON__.Download') }}</a>
            </b-tab>
            <b-tab>
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="far fa-user"></i>
                </span>
                <span class="d-none d-sm-inline-block">{{ trans.get('__JSON__.Feedback') }}</span>
              </template>
              <img :src="feedbackQr" />
              <a class="mt-3 d-block btn btn-primary" :href="feedbackQr" download>{{ trans.get('__JSON__.Download') }}</a> 
            </b-tab>
          </b-tabs>
        </div> 
      </div>
    </b-modal>
  </Layout>
</template>

<style >
  .custom-qr{
    cursor: pointer;
  }
  .qr-code-menu .tabs{
    width: 100%;
  }
  .qr-code-menu .nav-tabs li.nav-item {
    width: 50%;
    text-align: center;
  }
  .qr-code-menu .tabs .nav-tabs {
    border-bottom: 1px solid #ced4da;
    justify-content: center;
  }
  .qr-code-menu .tab-pane img{
    width: 100%;
    height: auto;
  }
  #modal-standard .modal-dialog{
    max-width: 400px;
  }
</style>

<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";

import vendorRating from "./vendor-rating";
import vendorCategory from "./vendor-category";
import vendorProfile from "./vendor-profile";
import { restaurantService, vendorService } from "../../services";
import vendorDiscount from "./vendor-discount";
import changePassword from "./change-password";
import termsCondition from "./terms-condition.vue";
import SellingTimeslot from "./selling-timeslot";
import OperationTime from "./operation-time";
import config from '../../config';
import { error_message, success_message } from '../../Helper/helper';
import Swal from "sweetalert2";

/**
 * Tabs & accordions component
 */
export default {
  components: { Layout, PageHeader, vendorRating, vendorCategory,vendorProfile, vendorDiscount, changePassword ,SellingTimeslot, OperationTime, termsCondition},
  data() {
    return {
      restaurant_id: "",
      restaurant: null,
      config: config,
      restaurant_on_off: "0",
      is_confirm: '',
      qrCode: '',
      feedbackQr: '',
      qrModal: false,
      vendor_id: 0,
      old_is_confirm:''
    };
  },
  created() {
    this.getRestaurantById();
  },
  mounted() {
    this.vendor_id = this.$auth.getVendorId();
    this.getResturantDetail();
  },
  methods: {
    onStatusChange($event){
      this.restaurant_on_off = ($event.target.checked?'1':'0')
      restaurantService.restaurantOnOff({
        restaurant_id:this.restaurant_id,
        restaurant_on_off: ($event.target.checked?'1':'0')
      }).then(response => {
        if(response.data.code == 200){
          success_message(response.data.message)
        }
      })
    },
    changeConfirm(){
      restaurantService.updateStatus({
        restaurant_id:this.restaurant_id,
        is_confirm: this.is_confirm
      }).then(response => {
        if (response.data.code == 200) {
          this.old_is_confirm = this.is_confirm;
          success_message(response.data.message)
        } else if (response.data.code == 101 && response.data.result.exceeding_limit == 1) {
          this.is_confirm = this.old_is_confirm;
          Swal.fire({
            text: this.trans.trans('__JSON__.You have exceed limit for Adding vendor. Please upgrade your plan or contact support for same.',{ vendor: this.$auth.setting.restaurant_name }),
            imageHeight: 20,
            confirmButtonColor: "#556ee6"
          });
        }
      })
    },
    getRestaurantById() {
      vendorService
        .getRestaurantById({ restaurant_id: this.$route.params.restaurant_id })
        .then((response) => {
          const {
            name,
            restaurant_id,
            is_confirm,
            delivery_type_time_slots,
            restaurant_on_off
          } = response.data.result;
          this.restaurant_id = restaurant_id;
          this.restaurant_on_off = restaurant_on_off;
          this.is_confirm = is_confirm;
          this.old_is_confirm = is_confirm;
          this.restaurant = {
            ...response.data.result,
            name,
            restaurant_id,
            is_confirm,
            delivery_type_time_slots
          };
        });
    },
    getResturantDetail() {
      if (
        this.$auth.isWebOrderingActive() &&
        this.vendor_id == 40818
      ) {
        vendorService
        .getQrCode({ restaurant_id: this.$route.params.restaurant_id })
        .then((response) => {
            this.qrCode = response.data.result.path;
            this.feedbackQr = response.data.result.feedback;
        });
      }
    },
  }
};
</script>