<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import EditBanner from "./edit-banner";
import { bannerService } from "../../services";
/**
 * Add-product component
 */
export default {
  components: { Layout, PageHeader, EditBanner },
  data() {
    return {
      banner: []
    };
  },
   mounted() {
    this.getBannerById();
  },
  methods: {
    getBannerById() {
      bannerService
        .getBannerById({ id: this.$route.params.id })
        .then((response) => {
          this.banner = response.data.result;
        });
    },
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <div class="report-tag">
            <h4 class="mb-0 font-size-18">{{ banner.name }}</h4>
            <p><router-link :to="{ name: 'banner-list'}">{{ trans.get('__JSON__.Banners') }}</router-link> > {{ banner.name }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <edit-banner />
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
