
<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";
import config from '../../config';
import { restaurantService } from '../../services';
import { error_message, success_message } from '../../Helper/helper';
/**
 * update-profile component
 */
export default {
  name: "OprationTime",
  props:['restaurant'],
  components: { Layout, PageHeader, DatePicker, moment },
  data() {
    return {
      title: this.trans.get('__JSON__.Operation Time'),
      config: config,
      restaurant_id: this.$route.params.restaurant_id,
      type: 'delivery',
      types: [
        {
          key: 'delivery',
          value: this.trans.get('__JSON__.Delivery'),
        },
        {
          key: 'pickup',
          value: this.trans.get('__JSON__.TakeAway'),
        }
      ],
      slots:{
        delivery:{
          monday: [{start_time: '12:00 AM',end_time: '12:00 AM', day: 'Monday',whole_day:1,status: 1 }],
          tuesday: [{start_time: '12:00 AM',end_time: '12:00 AM', day: 'Tuesday',whole_day:1,status: 1 }],
          wednesday: [{start_time: '12:00 AM',end_time: '12:00 AM', day: 'Wednesday',whole_day:1,status: 1 }],
          thursday: [{start_time: '12:00 AM',end_time: '12:00 AM', day: 'Thursday',whole_day:1,status: 1 }],
          friday: [{start_time: '12:00 AM',end_time: '12:00 AM', day: 'Friday',whole_day:1,status: 1 }],
          saturday: [{start_time: '12:00 AM',end_time: '12:00 AM', day: 'Saturday',whole_day:1,status: 1 }],
          sunday: [{start_time: '12:00 AM',end_time: '12:00 AM', day: 'Sunday',whole_day:1,status: 1 }],
        },
        pickup:{
          monday: [{start_time: '12:00 AM',end_time: '12:00 AM', day: 'Monday',whole_day:1,status: 1}],
          tuesday: [{start_time: '12:00 AM',end_time: '12:00 AM', day: 'Tuesday',whole_day:1,status: 1}],
          wednesday: [{start_time: '12:00 AM',end_time: '12:00 AM', day: 'Wednesday',whole_day:1,status: 1}],
          thursday: [{start_time: '12:00 AM',end_time: '12:00 AM', day: 'Thursday',whole_day:1,status: 1}],
          friday: [{start_time: '12:00 AM',end_time: '12:00 AM', day: 'Friday',whole_day:1,status: 1}],
          saturday: [{start_time: '12:00 AM',end_time: '12:00 AM', day: 'Saturday',whole_day:1,status: 1}],
          sunday: [{start_time: '12:00 AM',end_time: '12:00 AM', day: 'Sunday',whole_day:1,status: 1}],
        }
      },
      days: {
        monday: 'Monday',
        tuesday: 'Tuesday',
        wednesday: 'Wednesday',
        thursday: 'Thursday',
        friday: 'Friday',
        saturday: 'Saturday',
        sunday: 'Sunday',
      }
    };
  },
  mounted(){
    this.getOperationTimes()
    if(this.$auth.partner_login)
    {
      if(this.$auth.user.restaurant.delivery_type_time_slots == '0'){
        this.type = 'pickup';
      }
      if(!this.$auth.setting.is_pickup_enable){
        this.types = this.types.filter(i => i.key !== 'pickup'); //only delivery
        this.type = 'delivery';
      }else if(this.$auth.setting.is_pickup_enable && this.$auth.user.restaurant.delivery_type_time_slots == '0'){
        this.types = this.types.filter(i => i.key === 'pickup');//only pickup
        this.type = 'pickup';
      }else if(this.$auth.setting.is_pickup_enable && this.$auth.user.restaurant.delivery_type_time_slots == '1'){
        this.types = this.types.filter(i => i.key !== 'pickup');//only delivery
        this.type = 'delivery';
      }
    }
    else
    {
      if(this.restaurant.delivery_type_time_slots == '0'){
        this.type = 'pickup';
      }
      if(!this.$auth.setting.is_pickup_enable){
        this.types = this.types.filter(i => i.key !== 'pickup'); //only delivery
        this.type = 'delivery';
      }else if(this.$auth.setting.is_pickup_enable && this.restaurant.delivery_type_time_slots == '0'){
        this.types = this.types.filter(i => i.key === 'pickup');//only pickup
        this.type = 'pickup';
      }else if(this.$auth.setting.is_pickup_enable && this.restaurant.delivery_type_time_slots == '1'){
        this.types = this.types.filter(i => i.key !== 'pickup');//only delivery
        this.type = 'delivery';
      }
    }
  },
  methods: {
    getOperationTimes(){
      restaurantService.getRestaurantOperationTime({
        type: this.type,
        restaurant_id: this.$route.params.restaurant_id,
      })
      .then(response => {
        if(response.data.code == 200){
          var data = response.data.result;
          var delivery = data.delivery_slots;
          var pickup = data.pickup_slots;
          if(delivery.length > 0){
            Object.values(this.days).forEach((day, dindex) => {
              var sl = delivery.filter(d => d.day == day);
              if(sl.length>0){
                this.slots.delivery[day.toLowerCase()] = sl.reduce((a,s,i) => {
                  return a.concat([
                    {
                      start_time: this.moment(s.open_time,'HH:mm:ss').format('hh:mm A'),
                      end_time: this.moment(s.close_time,'HH:mm:ss').format('hh:mm A'),
                      day: day,
                      whole_day:(s.open_time == '00:00:00' && s.close_time == '00:00:00'?1:0),
                      status: (s.status == 1?'1':'0')
                    }
                  ]);
                },[]);
              }
            })
          }
          if(pickup.length > 0){
            Object.values(this.days).forEach((day, dindex) => {
              var sl = pickup.filter(d => d.day == day);
              if(sl.length>0){
                this.slots.pickup[day.toLowerCase()] = sl.reduce((a,s,i) => {
                  return a.concat([
                    {
                      start_time: this.moment(s.open_time,'HH:mm:ss').format('hh:mm A'),
                      end_time: this.moment(s.close_time,'HH:mm:ss').format('hh:mm A'),
                      day: day,
                      whole_day:(s.open_time == '00:00:00' && s.close_time == '00:00:00'?1:0),
                      status: (s.status == 1?'1':'0')
                    }
                  ]);
                },[]);
              }
            })
          }

        }else{
          error_message(response.data.message)
        }
      })
    },
    saveOperationTimes(){
      restaurantService.saveRestaurantOperationTime({
        type: this.type,
        restaurant_id: this.$route.params.restaurant_id,
        slots: this.slots[this.type]
      })
      .then(response => {
        if(response.data.code == 200){
          success_message(response.data.message)
        }
      })
    },
    addSlot: function (type,index) {
      
      var slot = this.slots[type][index].filter(slot => slot.start_time == '' || slot.end_time == '');
      if(slot.length == 0){
        this.slots[type][index].push({ start_time: '',end_time: '',day: this.days[index], whole_day: 0, status: 1});
      }else{
        alert('Please select available slots first.')
      }
    },
    remove_slot(type,index,sindex){
      this.slots[type][index].splice(sindex,1);
    },
    getDisabledStartTime(date,type, sindex, index){
      var times = this.slots[type][sindex].filter((time,ind) => ind !== index && (time.start_time != '' && time.end_time != '') );
      var rs = false
      times.forEach(time => {
        if(this.moment(date).isSameOrAfter(this.moment(time.start_time,'hh:mm A'))
        && this.moment(date).isBefore(this.moment(time.end_time,'hh:mm A'))){
          rs = true;
        }
        if(this.slots[type][sindex][index].end_time !== '' && !rs){
          if(!rs && this.moment(this.slots[type][sindex][index].end_time,'hh:mm A').isSameOrAfter(this.moment(time.end_time,'hh:mm A')) && this.moment(date).isBefore(this.moment(time.end_time,'hh:mm A'))){
            rs = true;
          }
        }
      });
      if(this.slots[type][sindex][index].end_time !== '' && !rs){
        if(this.moment(date).isSameOrAfter(this.moment(this.slots[type][sindex][index].end_time,'hh:mm A'))){
          rs = true;
        }
      }
      return rs;
    },
    getDisabledEndTime(date, type,sindex, index){
      var times = this.slots[type][sindex].filter((time,ind) => ind !== index && (time.start_time != '' && time.end_time != '') );
      var rs = false
      times.forEach(time => {
        if(this.moment(date).isAfter(this.moment(time.start_time,'hh:mm A'))
        && this.moment(date).isSameOrBefore(this.moment(time.end_time,'hh:mm A'))){
          rs = true;
        }
        if(this.slots[type][sindex][index].start_time !== '' && !rs){
          if(!rs && this.moment(this.slots[type][sindex][index].start_time,'hh:mm A').isSameOrBefore(this.moment(time.start_time,'hh:mm A')) && this.moment(date).isAfter(this.moment(time.start_time,'hh:mm A'))){
            rs = true;
          }
        }
      });
      if(this.slots[type][sindex][index].start_time !== '' && !rs){
        if(this.moment(date).isSameOrBefore(this.moment(this.slots[type][sindex][index].start_time,'hh:mm A'))){
          rs = true;
        }
      }
      return rs;
    },
    onTabChanged(tabIndex){
      this.type = tabIndex == 0?'delivery':'pickup';
    },
    onWholeDayChange($event,type, day){
      this.slots[type][day] = [{
        start_time: $event.target.checked?'12:00 AM':'', end_time: $event.target.checked?'12:00 AM':'', whole_day: $event.target.checked?'1':'0', status: 1,day:this.days[day]
      }]
    },
    onStatusChange($event,type, day){
      this.slots[type][day].forEach((item,index) => {
        this.slots[type][day][index].status = ($event.target.checked?"1":"0")
      })      
    },
  }
}

</script>


<template>
    <div v-if="$auth.partner_login">
      <Layout>
        <PageHeader :title="title" />
      <div class="card">
        <div class="card-body">
      <b-tabs
        nav-class="nav-tabs-custom mt-3 order-tabs"
        content-class="p-3 text-muted"
        @activate-tab="onTabChanged"
        v-if="types.length > 1"
      >
        <b-tab v-for="(type,tindex) in types" :key="tindex" :title="type.value">
          <b-form @submit.prevent="saveOperationTimes">
            <div class="table-responsive operation-time opt-time">
              <table class="table table-centered table-nowrap">
                <tbody>               
                  <tr>
                    <td>
                      <span class="font-weight-bold">{{trans.get("__JSON__.Day")}}</span>
                    </td>
                    <td class="wp-10">
                      <span class="font-weight-bold">{{trans.get("__JSON__.Status")}}</span>
                    </td>
                    <td class="wp-10">
                      <span class="font-weight-bold">{{trans.get("__JSON__.24 Hours")}}</span>
                    </td>
                    <td class="selling-time-slot">
                      <span class="font-weight-bold">{{trans.get("__JSON__.Open Time")}}</span>
                    </td>
                    <td class="selling-time-slot">
                      <span class="font-weight-bold">{{trans.get("__JSON__.Close Time")}}</span>
                    </td>
                    <td>
                      <span class="font-weight-bold">{{trans.get("__JSON__.Action")}}</span>                  
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="table-responsive operation-time" v-for="(day,dindex) in days" :key="dindex">
              <table class="table table-centered table-nowrap">
                <tbody> 
                  <template v-for="(slot,sindex) in slots[type.key][dindex]">
                  <tr  :key="sindex" v-if="!(slots[type.key][dindex][sindex].status == '0' && sindex > 0)">
                    <td><span class="font-weight-bold">{{sindex == 0 ? day : ''}}</span></td>
                    <td>
                      <label class="switch middle" v-if="sindex == 0">
                        <input
                          type="checkbox"
                          id="togBtn"
                          name="status"
                          v-model="slots[type.key][dindex][sindex].status"
                          class="switch-on"
                          true-value="1"
                          false-value="0"                      
                          @change="onStatusChange($event, type.key, dindex)"
                        />
                        <div class="slider round">
                          <span class="on">{{ trans.get('__JSON__.On') }}</span>
                          <span class="off">{{ trans.get('__JSON__.Off') }}</span>
                        </div>
                      </label>
                    </td>
                    <td>
                      <label class="switch middle" v-if="slots[type.key][dindex][sindex].status != '0'">
                      <input
                          type="checkbox"
                          id="togBtn"
                          name="status"
                          v-if="slots[type.key][dindex][sindex].status != '0' && sindex == 0"
                          v-model="slots[type.key][dindex][sindex].whole_day"
                          class="switch-on"
                          true-value="1"
                          false-value="0"                      
                          @change="onWholeDayChange($event, type.key, dindex)"
                        />
                        <div class="slider round">
                          <span class="on">{{ trans.get('__JSON__.On') }}</span>
                          <span class="off">{{ trans.get('__JSON__.Off') }}</span>
                        </div>
                      </label>
                    </td>
                    <td class="selling-time-slot">
                      <date-picker
                        v-model="slots[type.key][dindex][sindex].start_time"
                        v-if="slots[type.key][dindex][sindex].status != '0'"
                        :time-picker-options="{
                            start: '00:00',
                            step: '00:15',
                            end: '23:30',
                        }"
                        format="hh:mm A"
                        type="time"
                        class="date-time"
                        :placeholder="trans.get('Select start time')"
                        name="start_time"
                        :disabled="slots[type.key][dindex][sindex].whole_day == 1"
                        :clearable="false"
                        :disabled-time="function(date){return getDisabledStartTime(date, type.key ,dindex, sindex)}"
                        valueType="format"
                      ></date-picker>
                    </td>
                    <td class="selling-time-slot">
                      <date-picker
                        :clearable="false"
                        v-if="slots[type.key][dindex][sindex].status != '0'"
                        v-model="slots[type.key][dindex][sindex].end_time"
                        :time-picker-options="{
                            start: '00:00',
                            step: '00:15',
                            end: '23:30',
                        }"

                        format="hh:mm A"
                        type="time"
                        class="date-time"
                        :disabled="slots[type.key][dindex][sindex].whole_day == 1"
                        :placeholder="trans.get('Select close time')"
                        name="end_time"
                        valueType="format"
                        :disabled-time="function(date){return getDisabledEndTime(date, type.key ,dindex, sindex)}"
                      ></date-picker>
                    </td>
                    <td>
                      <button  @click.prevent="addSlot(type.key,dindex)" v-if="sindex == 0 && slots[type.key][dindex][sindex].status != '0'" class="btn btn-primary btn-sm btn-rounded" :disabled="slots[type.key][dindex][sindex].whole_day == 1"><i class="fas fa-plus"></i></button>
                      <button  @click.prevent="remove_slot(type.key,dindex,sindex)" v-if="sindex != 0 && slots[type.key][dindex][sindex].status != '0'"  class="btn btn-danger btn-sm btn-rounded"><i class="fas fa-minus"></i></button>
                    </td>
                  </tr>
                  </template>
                </tbody>
              </table>
            </div>
            <div class="row">
              <div class="col-sm-6 operation-btn">
                <button type="submit" v-if="$auth.hasPermission(config.permissions.merchant_write)" class="btn btn-primary mr-1 mt-3">{{trans.get('__JSON__.Update')}}</button>
              </div>
            </div>
          </b-form>
        </b-tab>
      </b-tabs>
      <div v-else v-for="(type,tindex) in types" :key="tindex" :title="type.value">
          <b-form @submit.prevent="saveOperationTimes">
            <div class="table-responsive operation-time opt-time">
              <table class="table table-centered table-nowrap">
                <tbody>               
                  <tr>
                    <td>
                      <span class="font-weight-bold">{{trans.get("__JSON__.Day")}}</span>
                    </td>
                    <td class="wp-10">
                      <span class="font-weight-bold">{{trans.get("__JSON__.Status")}}</span>
                    </td>
                    <td class="wp-10">
                      <span class="font-weight-bold">{{trans.get("__JSON__.24 Hours")}}</span>
                    </td>
                    <td class="selling-time-slot">
                      <span class="font-weight-bold">{{trans.get("__JSON__.Open Time")}}</span>
                    </td>
                    <td class="selling-time-slot">
                      <span class="font-weight-bold">{{trans.get("__JSON__.Close Time")}}</span>
                    </td>
                    <td>
                      <span class="font-weight-bold">{{trans.get("__JSON__.Action")}}</span>                  
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="table-responsive operation-time" v-for="(day,dindex) in days" :key="dindex">
              <table class="table table-centered table-nowrap">
                <tbody> 
                  <template v-for="(slot,sindex) in slots[type.key][dindex]">
                  <tr  :key="sindex" v-if="!(slots[type.key][dindex][sindex].status == '0' && sindex > 0)">
                    <td><span class="font-weight-bold">{{sindex == 0 ? day : ''}}</span></td>
                    <td>
                      <label class="switch middle" v-if="sindex == 0">
                        <input
                          type="checkbox"
                          id="togBtn"
                          name="status"
                          v-model="slots[type.key][dindex][sindex].status"
                          class="switch-on"
                          true-value="1"
                          false-value="0"                      
                          @change="onStatusChange($event, type.key, dindex)"
                        />
                        <div class="slider round">
                          <span class="on">{{ trans.get('__JSON__.On') }}</span>
                          <span class="off">{{ trans.get('__JSON__.Off') }}</span>
                        </div>
                      </label>
                    </td>
                    <td>
                      <label class="switch middle" v-if="slots[type.key][dindex][sindex].status != '0'">
                      <input
                          type="checkbox"
                          id="togBtn"
                          name="status"
                          v-if="slots[type.key][dindex][sindex].status != '0' && sindex == 0"
                          v-model="slots[type.key][dindex][sindex].whole_day"
                          class="switch-on"
                          true-value="1"
                          false-value="0"                      
                          @change="onWholeDayChange($event, type.key, dindex)"
                        />
                        <div class="slider round">
                          <span class="on">{{ trans.get('__JSON__.On') }}</span>
                          <span class="off">{{ trans.get('__JSON__.Off') }}</span>
                        </div>
                      </label>
                    </td>
                    <td class="selling-time-slot">
                      <date-picker
                        v-model="slots[type.key][dindex][sindex].start_time"
                        v-if="slots[type.key][dindex][sindex].status != '0'"
                        :time-picker-options="{
                            start: '00:00',
                            step: '00:15',
                            end: '23:30',
                        }"
                        format="hh:mm A"
                        type="time"
                        class="date-time"
                        :placeholder="trans.get('Select start time')"
                        name="start_time"
                        :disabled="slots[type.key][dindex][sindex].whole_day == 1"
                        :clearable="false"
                        :disabled-time="function(date){return getDisabledStartTime(date, type.key ,dindex, sindex)}"
                        valueType="format"
                      ></date-picker>
                    </td>
                    <td class="selling-time-slot">
                      <date-picker
                        :clearable="false"
                        v-if="slots[type.key][dindex][sindex].status != '0'"
                        v-model="slots[type.key][dindex][sindex].end_time"
                        :time-picker-options="{
                            start: '00:00',
                            step: '00:15',
                            end: '23:30',
                        }"

                        format="hh:mm A"
                        type="time"
                        class="date-time"
                        :disabled="slots[type.key][dindex][sindex].whole_day == 1"
                        :placeholder="trans.get('Select close time')"
                        name="end_time"
                        valueType="format"
                        :disabled-time="function(date){return getDisabledEndTime(date, type.key ,dindex, sindex)}"
                      ></date-picker>
                    </td>
                    <td>
                      <button  @click.prevent="addSlot(type.key,dindex)" v-if="sindex == 0 && slots[type.key][dindex][sindex].status != '0'" class="btn btn-primary btn-sm btn-rounded" :disabled="slots[type.key][dindex][sindex].whole_day == 1"><i class="fas fa-plus"></i></button>
                      <button  @click.prevent="remove_slot(type.key,dindex,sindex)" v-if="sindex != 0 && slots[type.key][dindex][sindex].status != '0'"  class="btn btn-danger btn-sm btn-rounded"><i class="fas fa-minus"></i></button>
                    </td>
                  </tr>
                  </template>
                </tbody>
              </table>
            </div>
            <div class="row">
              <div class="col-sm-6 operation-btn">
                <button type="submit" v-if="$auth.hasPermission(config.permissions.merchant_write)" class="btn btn-primary mr-1 mt-3">{{trans.get('__JSON__.Update')}}</button>
              </div>
            </div>
          </b-form>
      </div>
        </div>
      </div>
      </Layout>
    </div>
    <!-- end row -->
    <div v-else>
      <b-tabs
        nav-class="nav-tabs-custom mt-3 order-tabs"
        content-class="p-3 text-muted"
        @activate-tab="onTabChanged"
        v-if="types.length > 1"
      >
        <b-tab v-for="(type,tindex) in types" :key="tindex" :title="type.value">
          <b-form @submit.prevent="saveOperationTimes">
            <div class="table-responsive operation-time opt-time">
              <table class="table table-centered table-nowrap">
                <tbody>               
                  <tr>
                    <td>
                      <span class="font-weight-bold">{{trans.get("__JSON__.Day")}}</span>
                    </td>
                    <td class="wp-10">
                      <span class="font-weight-bold">{{trans.get("__JSON__.Status")}}</span>
                    </td>
                    <td class="wp-10">
                      <span class="font-weight-bold">{{trans.get("__JSON__.24 Hours")}}</span>
                    </td>
                    <td class="selling-time-slot">
                      <span class="font-weight-bold">{{trans.get("__JSON__.Open Time")}}</span>
                    </td>
                    <td class="selling-time-slot">
                      <span class="font-weight-bold">{{trans.get("__JSON__.Close Time")}}</span>
                    </td>
                    <td>
                      <span class="font-weight-bold">{{trans.get("__JSON__.Action")}}</span>                  
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="table-responsive operation-time" v-for="(day,dindex) in days" :key="dindex">
              <table class="table table-centered table-nowrap">
                <tbody> 
                  <template v-for="(slot,sindex) in slots[type.key][dindex]">
                  <tr  :key="sindex" v-if="!(slots[type.key][dindex][sindex].status == '0' && sindex > 0)">
                    <td><span class="font-weight-bold">{{sindex == 0 ? day : ''}}</span></td>
                    <td>
                      <label class="switch middle" v-if="sindex == 0">
                        <input
                          type="checkbox"
                          id="togBtn"
                          name="status"
                          v-model="slots[type.key][dindex][sindex].status"
                          class="switch-on"
                          true-value="1"
                          false-value="0"                      
                          @change="onStatusChange($event, type.key, dindex)"
                        />
                        <div class="slider round">
                          <span class="on">{{ trans.get('__JSON__.On') }}</span>
                          <span class="off">{{ trans.get('__JSON__.Off') }}</span>
                        </div>
                      </label>
                    </td>
                    <td>
                      <label class="switch middle" v-if="slots[type.key][dindex][sindex].status != '0'">
                      <input
                          type="checkbox"
                          id="togBtn"
                          name="status"
                          v-if="slots[type.key][dindex][sindex].status != '0' && sindex == 0"
                          v-model="slots[type.key][dindex][sindex].whole_day"
                          class="switch-on"
                          true-value="1"
                          false-value="0"                      
                          @change="onWholeDayChange($event, type.key, dindex)"
                        />
                        <div class="slider round">
                          <span class="on">{{ trans.get('__JSON__.On') }}</span>
                          <span class="off">{{ trans.get('__JSON__.Off') }}</span>
                        </div>
                      </label>
                    </td>
                    <td class="selling-time-slot">
                      <date-picker
                        v-model="slots[type.key][dindex][sindex].start_time"
                        v-if="slots[type.key][dindex][sindex].status != '0'"
                        :time-picker-options="{
                            start: '00:00',
                            step: '00:15',
                            end: '23:30',
                        }"
                        format="hh:mm A"
                        type="time"
                        class="date-time"
                        :placeholder="trans.get('Select start time')"
                        name="start_time"
                        :disabled="slots[type.key][dindex][sindex].whole_day == 1"
                        :clearable="false"
                        :disabled-time="function(date){return getDisabledStartTime(date, type.key ,dindex, sindex)}"
                        valueType="format"
                      ></date-picker>
                    </td>
                    <td class="selling-time-slot">
                      <date-picker
                        :clearable="false"
                        v-if="slots[type.key][dindex][sindex].status != '0'"
                        v-model="slots[type.key][dindex][sindex].end_time"
                        :time-picker-options="{
                            start: '00:00',
                            step: '00:15',
                            end: '23:30',
                        }"

                        format="hh:mm A"
                        type="time"
                        class="date-time"
                        :disabled="slots[type.key][dindex][sindex].whole_day == 1"
                        :placeholder="trans.get('Select close time')"
                        name="end_time"
                        valueType="format"
                        :disabled-time="function(date){return getDisabledEndTime(date, type.key ,dindex, sindex)}"
                      ></date-picker>
                    </td>
                    <td>
                      <button  @click.prevent="addSlot(type.key,dindex)" v-if="sindex == 0 && slots[type.key][dindex][sindex].status != '0'" class="btn btn-primary btn-sm btn-rounded" :disabled="slots[type.key][dindex][sindex].whole_day == 1"><i class="fas fa-plus"></i></button>
                      <button  @click.prevent="remove_slot(type.key,dindex,sindex)" v-if="sindex != 0 && slots[type.key][dindex][sindex].status != '0'"  class="btn btn-danger btn-sm btn-rounded"><i class="fas fa-minus"></i></button>
                    </td>
                  </tr>
                  </template>
                </tbody>
              </table>
            </div>
            <div class="row">
              <div class="col-sm-6 operation-btn">
                <button type="submit" v-if="$auth.hasPermission(config.permissions.merchant_write)" class="btn btn-primary mr-1 mt-3">{{trans.get('__JSON__.Update')}}</button>
              </div>
            </div>
          </b-form>
        </b-tab>
      </b-tabs>
       <div v-else v-for="(type,tindex) in types" :key="tindex" :title="type.value">
          <b-form @submit.prevent="saveOperationTimes">
            <div class="table-responsive operation-time opt-time">
              <table class="table table-centered table-nowrap">
                <tbody>               
                  <tr>
                    <td>
                      <span class="font-weight-bold">{{trans.get("__JSON__.Day")}}</span>
                    </td>
                    <td class="wp-10">
                      <span class="font-weight-bold">{{trans.get("__JSON__.Status")}}</span>
                    </td>
                    <td class="wp-10">
                      <span class="font-weight-bold">{{trans.get("__JSON__.24 Hours")}}</span>
                    </td>
                    <td class="selling-time-slot">
                      <span class="font-weight-bold">{{trans.get("__JSON__.Open Time")}}</span>
                    </td>
                    <td class="selling-time-slot">
                      <span class="font-weight-bold">{{trans.get("__JSON__.Close Time")}}</span>
                    </td>
                    <td>
                      <span class="font-weight-bold">{{trans.get("__JSON__.Action")}}</span>                  
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="table-responsive operation-time" v-for="(day,dindex) in days" :key="dindex">
              <table class="table table-centered table-nowrap">
                <tbody> 
                  <template v-for="(slot,sindex) in slots[type.key][dindex]">
                  <tr  :key="sindex" v-if="!(slots[type.key][dindex][sindex].status == '0' && sindex > 0)">
                    <td><span class="font-weight-bold">{{sindex == 0 ? day : ''}}</span></td>
                    <td>
                      <label class="switch middle" v-if="sindex == 0">
                        <input
                          type="checkbox"
                          id="togBtn"
                          name="status"
                          v-model="slots[type.key][dindex][sindex].status"
                          class="switch-on"
                          true-value="1"
                          false-value="0"                      
                          @change="onStatusChange($event, type.key, dindex)"
                        />
                        <div class="slider round">
                          <span class="on">{{ trans.get('__JSON__.On') }}</span>
                          <span class="off">{{ trans.get('__JSON__.Off') }}</span>
                        </div>
                      </label>
                    </td>
                    <td>
                      <label class="switch middle" v-if="slots[type.key][dindex][sindex].status != '0'">
                      <input
                          type="checkbox"
                          id="togBtn"
                          name="status"
                          v-if="slots[type.key][dindex][sindex].status != '0' && sindex == 0"
                          v-model="slots[type.key][dindex][sindex].whole_day"
                          class="switch-on"
                          true-value="1"
                          false-value="0"                      
                          @change="onWholeDayChange($event, type.key, dindex)"
                        />
                        <div class="slider round">
                          <span class="on">{{ trans.get('__JSON__.On') }}</span>
                          <span class="off">{{ trans.get('__JSON__.Off') }}</span>
                        </div>
                      </label>
                    </td>
                    <td class="selling-time-slot">
                      <date-picker
                        v-model="slots[type.key][dindex][sindex].start_time"
                        v-if="slots[type.key][dindex][sindex].status != '0'"
                        :time-picker-options="{
                            start: '00:00',
                            step: '00:15',
                            end: '23:30',
                        }"
                        format="hh:mm A"
                        type="time"
                        class="date-time"
                        :placeholder="trans.get('Select start time')"
                        name="start_time"
                        :disabled="slots[type.key][dindex][sindex].whole_day == 1"
                        :clearable="false"
                        :disabled-time="function(date){return getDisabledStartTime(date, type.key ,dindex, sindex)}"
                        valueType="format"
                      ></date-picker>
                    </td>
                    <td class="selling-time-slot">
                      <date-picker
                        :clearable="false"
                        v-if="slots[type.key][dindex][sindex].status != '0'"
                        v-model="slots[type.key][dindex][sindex].end_time"
                        :time-picker-options="{
                            start: '00:00',
                            step: '00:15',
                            end: '23:30',
                        }"

                        format="hh:mm A"
                        type="time"
                        class="date-time"
                        :disabled="slots[type.key][dindex][sindex].whole_day == 1"
                        :placeholder="trans.get('Select close time')"
                        name="end_time"
                        valueType="format"
                        :disabled-time="function(date){return getDisabledEndTime(date, type.key ,dindex, sindex)}"
                      ></date-picker>
                    </td>
                    <td>
                      <button  @click.prevent="addSlot(type.key,dindex)" v-if="sindex == 0 && slots[type.key][dindex][sindex].status != '0'" class="btn btn-primary btn-sm btn-rounded" :disabled="slots[type.key][dindex][sindex].whole_day == 1"><i class="fas fa-plus"></i></button>
                      <button  @click.prevent="remove_slot(type.key,dindex,sindex)" v-if="sindex != 0 && slots[type.key][dindex][sindex].status != '0'"  class="btn btn-danger btn-sm btn-rounded"><i class="fas fa-minus"></i></button>
                    </td>
                  </tr>
                  </template>
                </tbody>
              </table>
            </div>
            <div class="row">
              <div class="col-sm-6 operation-btn">
                <button type="submit" v-if="$auth.hasPermission(config.permissions.merchant_write)" class="btn btn-primary mr-1 mt-3">{{trans.get('__JSON__.Update')}}</button>
              </div>
            </div>
          </b-form>
      </div>
    </div>
</template>
<style scoped>
.wp-10{
  width: 10% !important;
}
.middle{
  vertical-align: middle;
  margin: 0;
}
</style>
