<script>
import Layout from "../../../layouts/main";
import { settingService } from "../../../services";

export default {
  components: { Layout },
  data() {
    return {
      token: localStorage.getItem("token"),
      loading: false,
      profilemenulist: [],
      fields: [
        { key: "id", label: "#", sortable: false },
        { key: "sort_order", label: "Sort Order", sortable: false },
        { key: "menu_name", label: "Menu Name", sortable: false },
        { key: "menu_description", label: "Menu Description", sortable: false },
        { key: "menu_icons", label: "Menu Icon", sortable: false },
        { key: "menu_images", label: "Menu Image", sortable: false },
        { key: "status", label: "Status", sortable: false },
        { key: "action", label: "Action", sortable: false },
      ],
    };
  },
  mounted() {
    this.getprofilemenu();
  },
  methods: {
    getprofilemenu() {
      this.loading = true;
      settingService.getprofilemenulist().then((response) => {
        console.log("response", response, response?.data.result);
        this.loading = false;
        this.profilemenulist = response.data.result;
        console.log("ppppp", this.profilemenulist);
      });
    },
  },
};
</script>

<template>
  <Layout>
    <div>
      <div class="row">
        <div class="col-12">
          <div
            class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
          >
            <div class="page-title-left">
              <div class="avatar-xs mr-3">
                <span
                  class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18"
                >
                  <i class="bx bx-briefcase"></i>
                </span>
              </div>
              <h4 class="mb-0 font-size-18">
                {{ trans.trans("__JSON__.Profilemenu") }}
              </h4>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 list-table-main">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div
                  class="col-12 table-main-list total-sales-report-table table-responsive"
                >
                  <b-table :fields="fields" :items="profilemenulist">
                    <template v-slot:cell(status)="data">
                      <div
                        v-if="data.item.status === 1"
                        class="badge badge-pill badge-soft-success font-size-12"
                      >
                        {{ trans.get("__JSON__.Active") }}
                      </div>
                      <div
                        v-else
                        class="badge badge-pill badge-soft-danger font-size-12"
                      >
                        {{ trans.get("__JSON__.Inactive") }}
                      </div>
                    </template>
                    <!-- Custom rendering for the 'action' column -->
                    <template v-slot:cell(action)="data">
                      <div class="vendor-btn">
                        <router-link
                          :to="{
                            name: 'profilemenuedit',
                            params: { id: base64(data.item.id) },
                          }"
                          class="btn btn-success btn-sm btn-rounded"
                        >
                          {{ trans.get("__JSON__.Edit") }}
                        </router-link>
                      </div>
                    </template>

                    <!-- Custom rendering for the 'menu_icons' column -->
                    <template v-slot:cell(menu_icons)="data">
                      <div>
                        <img
                          title="menu_icons"
                          class="avatar-xxs rounded-circle-banner"
                          :src="data.item.menu_icons"
                          alt="menuicon"
                          height="30"
                        />
                      </div>
                    </template>

                    <!-- Custom rendering for the 'menu_images' column -->
                    <template v-slot:cell(menu_images)="data">
                      <div v-if="data.item.menu_images">
                        <img
                          title="menu_images"
                          class="avatar-md rounded-circle-banner"
                          :src="data.item.menu_images"
                          alt="menuimages"
                          height="30"
                        />
                      </div>
                      <div v-else>
                        <p>-</p>
                      </div>
                    </template>
                    <template v-slot:cell(menu_description)="data">
                      <div v-if="data.item.menu_description">
                        {{ data.item.menu_description }}
                      </div>
                      <div v-else>-</div>
                    </template>
                  </b-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>