<script>
import pagination from "laravel-vue-pagination";
import config from "../../config";
import { driverService } from '../../services';
import moment from "moment";
import { error_message, success_message } from "../../Helper/helper";

export default {
    components: { pagination},
    data() {
        return {
            sortBy: "order_id",
            sortDesc: true,
            ratings:[],
            ratingData:[],
            paginations:{},
            limit:1,
            search:'',
            status:1,
            config:config,
            moment:moment,
            fields: [
                { key: 'order_id',label: this.trans.get('__JSON__.Order Id'), sortable: true },
                { key: 'user_id',label: this.trans.get('__JSON__.Customer Id'), sortable: true },
                { key: 'user_name',label: this.trans.get('__JSON__.Customer Name'), sortable: true },
                { key: 'mobile_number',label: this.trans.get('__JSON__.Mobile Number'), sortable: true },
                { key: 'rating',label: this.trans.get('__JSON__.Rating'), sortable: true },
                { key: 'description',label: this.trans.get('__JSON__.Review'), sortable: true },
                { key: 'created_at',label: this.trans.get('__JSON__.Date'), sortable: true },
                { key: "action", label: "Action", sortable: false }
            ]
        };
    },
    mounted() {
        this.getDriverRating();
    },
    methods:{
        getDriverRating(page){
            driverService.getDriverRating({
                search: this.search,
                status: this.status,
                sortBy: this.sortBy,
                orderBy: this.sortDesc ? 'desc':'asc',
                page: page?page:1,
                driver_id: this.$route.params.driver_id
            })
            .then(response => {
                this.paginations = response.data.result;
                this.ratings = response.data.result.data;
            });
        },
        searchData() {
            this.getDriverRating();     
        },
        sortingChanged(ctx){
            this.sortBy = ctx.sortBy;
            this.sortDesc = ctx.sortDesc;
            this.getDriverRating();
        },
        deleteById(id) {
            if (confirm("Are you sure you want to delete rating? Once it delete. it will not retrieve")) {
                driverService.deleteDriverRating({ id: id }).then((response) => {
                    success_message(response.data.message);
                    this.getDriverRating();
                });
            }
        }
    }
};
</script>

<template>
   <div class="row">
      <div class="col-12 list-table-main">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-2 offset-sm-10">
                        <div class="search-box mb-3 d-inline-block">
                            <div class="date-range-list">
                            <label>{{ trans.get('__JSON__.Search') }} :</label>
                            <div class="position-relative">
                                <input
                                type="text"
                                class="form-control"
                                @keyup="searchData()"
                                v-model="search"
                                :placeholder="trans.get('__JSON__.Search...')"
                                />
                                <i class="bx bx-search-alt search-icon"></i>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 table-main-list table-responsive">
                    <b-table
                        :items="ratings"
                        :fields="fields"
                        :sort-by.sync="sortBy"
                        :no-local-sorting="true"
                        @sort-changed="sortingChanged"
                        show-empty
                    >
                        <template v-slot:cell(user_id)="data">
                           <h5 class="font-size-14">{{ data.item.user_id}}</h5>  
                        </template>
                        <template v-slot:cell(rating)="data">
                            <div v-if="data.item.rating <= 2">
                                <span class="badge badge-danger font-size-12">
                                    <i class="mdi mdi-star mr-1"></i>
                                    {{parseFloat(data.item.rating).toFixed(2)}}
                                </span>
                                </div>
                                <div v-else>
                                    <span class="badge badge-success font-size-12">
                                    <i class="mdi mdi-star mr-1"></i>
                                    {{parseFloat(data.item.rating).toFixed(2)}}
                                </span>
                            </div>
                        </template>

                        <template v-slot:cell(description)="data">
                             <div v-if="data.item.description">
                                <div
                                    v-if="data.item.description.length > 140"
                                >{{data.item.description.substr(0, 140)+ '...'}}</div>
                                <div v-else>{{data.item.description}}</div>
                                </div>
                                <div v-else class="mt-3">
                                <p>{{ trans.get('__JSON__.Description not added by user') }}</p>
                             </div>
                        </template>

                        <template v-slot:cell(created_at)="data">
                            <div v-if="data.item.created_at">
                                {{moment(data.item.created_at).format('D MMM,Y')}}
                            </div>
                        </template>

                        <template v-slot:cell(action)="data">
                            <div class="vendor-btn">
                                <button
                                    type="submit"
                                    class="btn btn-danger btn-sm btn-rounded ml-1"
                                    @click="deleteById(base64(data.item.rating_id))"
                                    v-if="$auth.hasPermission(config.permissions.driver_write)"
                                >{{ trans.get('__JSON__.Delete') }}</button>
                                <i v-else v-b-tooltip="'Need Permission'" class="mdi mdi-information h4 text-danger"></i>
                            </div>
                        </template>


                        <template #empty>
                          <p class="text-center">{{ trans.get('__JSON__.No Rating') }}</p>
                        </template>

                    </b-table>  
                </div>
                <div class="row col-sm-5 offset-sm-7">
                    <div class="col">
                        <div class="dataTables_paginate paging_simple_numbers float-right">
                            <ul class="pagination pagination-rounded mb-0">
                            <!-- pagination -->
                            <pagination :data="paginations" :limit="limit" @pagination-change-page="getDriverRating"></pagination>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  <!-- end table -->
</template>
