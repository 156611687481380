
// Faq services rest api calling

import { routes } from '../config/api_routes'

export const faqService = {
    // get faq 

    getFaqListing: (formData) => {    
        return window.axios.post(routes.getFaqListingApi,formData)
    },

    // Add Faq
    addFaq: (formData) => {
        return window.axios.post(routes.addFaqApi,formData)
    },

    // get FaqById

    getFaqById: (formData) => {
        return window.axios.post(routes.getFaqByIdApi,formData)
    },

    // update FaqById

    updateFaqById: (formData) => {
        return window.axios.post(routes.updateFaqApi,formData)
    },

    // delete FaqById

    deleteFaqById: (formData) => {
        return window.axios.post(routes.deleteFaqApi,formData)
    }
}