<script>
import vue2Dropzone from 'vue2-dropzone'
import Multiselect from 'vue-multiselect'
import { defaultSettingService, businessTypeService} from "../../../services";
import { required, requiredIf, maxValue } from "vuelidate/lib/validators";
import { numeric } from "vuelidate/lib/validators";
import { error_message, success_message } from '../../../Helper/helper';
import config from '../../../config';


/**
 * Starter component
 */
export default {
  components: { vueDropzone: vue2Dropzone, Multiselect },
  data() {
    return {
      config: config,
      app_setting_id : "",
      id: "",
      checked: true,
      value: null,
      order_assign_class:'switch-on',
      // setting:[],
      deliverycharges:[],
      delivery_charges: [
          {id:'Order Amount',text:'Amount', show: 'Value'},
          {id:'Delivery Distance ('+this.$auth.getVendorDistanceMetric()+')',text:'Distance', show: 'Distance'}
      ],
      deliverycharges: [{ min_value: '',max_value: '',delivery_charges: '' }],
      errors: '',
      setting: {
        order_commission: "",
        order_auto_refresh: "",
        is_order_assigned_manually: "",
        is_enable_live_tracking_app:0,
        vendor_max_time: "",
        driver_max_time: "",
        charge:[],
        order_dashboard_default_time: "0",
        order_notification_sound: 'notification.mp3',
        is_upload_order_image: "No",
        image_upload_text: "",
        order_image_upload_limit: 0,
        // wallet_status: 'inactive'
      },
      default_time_options:config.default_time_options,
      default_notification_sounds:config.default_notification_sounds,
      playing: false,
      sound: null
    };
  },
  validations: {
    setting: {
      order_commission: {
        required,
        numeric,
      },
      order_auto_refresh: {
        required,
        numeric,
      },
      vendor_max_time: {
        required,
        numeric,
      },
      order_notification_sound: {
        required
      },
      driver_max_time: {
        required,
        numeric,
      },
      charges: {
        $each: {
          min_value: {
            required,
          }
        }
      },
      is_upload_order_image: {},
      image_upload_text: {
        required: requiredIf(function(form){
          return this.setting.is_upload_order_image == 'Yes';
        }),
      },
      order_image_upload_limit: {
        required: requiredIf(function(form){
          return this.setting.is_upload_order_image == 'Yes';
        }),
        numeric: requiredIf(function(form){
          return this.setting.is_upload_order_image == 'Yes';
        }),
        maximumValue: function(value){ return this.setting.is_upload_order_image == 'Yes' ? value <= 15 : true } 
      }
    },
    deliverycharges:{
      $each: {
        min_value: {
          required,
         }
      },
    },
  },mounted() {
    this.getBusinessTypeById();
    this.value = this.delivery_charges.find(d => d.text == 'Amount');
  },
  methods: {
    getBusinessTypeById() {
      businessTypeService
        .getBusinessTypeById({ id: this.$route.params.id })
        .then((response) => {
          this.id = response.data.result.id;
          this.setting = response.data.result.settings;
          this.value = this.delivery_charges.find(d => d.text == response.data.result.settings.delivery_charges_type);
           this.deliverycharges = response.data.result.settings.charges;
            this.id = response.data.result.business.id;
          this.app_setting_id = response.data.result.business.app_setting_id;
        });
    },
    AddField: function () {
      this.deliverycharges.push({ min_value: '',max_value: '',delivery_charges: '' });
    },
    is_order_assigned($event){
      this.setting[$event.target.name] = $event.target.checked?'Yes':'No';
      //console.log($event);
    },
    orderInIt(){
      this.$v.$touch();
      if (this.$v.setting.$invalid) {
        return;
      } else {
        const fd = new FormData();
        fd.append("setting_id",this.id);
        fd.append("app_setting_id",this.app_setting_id);
        fd.append("order_commission", this.setting.order_commission);
        fd.append("order_auto_refresh", this.setting.order_auto_refresh);
        fd.append("vendor_max_time",this.setting.vendor_max_time);
        fd.append("driver_max_time",this.setting.driver_max_time);
        fd.append("delivery_charges_type",this.value.text);
        fd.append("is_order_assigned_manually",this.setting.is_order_assigned_manually);
        fd.append("is_enable_live_tracking_app",this.setting.is_enable_live_tracking_app);
        fd.append("deliverycharges",JSON.stringify(this.setting.charges));
        fd.append("order_dashboard_default_time",this.setting.order_dashboard_default_time);
        fd.append("order_notification_sound",this.setting.order_notification_sound);
        fd.append("is_upload_order_image",this.setting.is_upload_order_image);
        fd.append("image_upload_text",this.setting.image_upload_text);
        if (this.setting.is_upload_order_image == "No" && this.setting.order_image_upload_limit > 15) {
          this.setting.order_image_upload_limit = 0;
        }
        fd.append("order_image_upload_limit",this.setting.order_image_upload_limit);        
        //fd.append("wallet_status",this.setting.wallet_status);
        fd.append("type",2);
        defaultSettingService.updateDefaultSetting(fd).then((response) => {
          if (response.data.code === 200) {
            success_message(response.data.message);
          } else {
            error_message(response.data.message);
          }
        });
      }
    },
    remove_delivery_charges(index){
      this.deliverycharges.splice(index,1);
    },
    playSound(){
      if(!this.playing){
        if(!this.sound){
          this.sound = new Audio(this.config.appurl+this.setting.order_notification_sound)
          this.sound.onended = () => this.playSound()
        } else {
          this.sound = new Audio(this.config.appurl+''+this.setting.order_notification_sound)
          this.sound.onended = () => this.playSound()
        }
          this.sound.play()
          this.playing = true  
      }else{
        this.sound.pause();
        this.sound.currentTime = 0;
        this.playing = false
      }
    },
    numberOnly(evt) {
      let val1 = evt.target.value;
      if (!(evt.keyCode >= 48 && evt.keyCode <= 57)) {
        evt.preventDefault();
      }
    }
  }
};
</script>
<template>
  <b-form @submit.prevent="orderInIt">
       <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body setting-main">
                    <div class="row section-main">
                        <div class="col-md-4">
                            <div class="setting-heading">  
                                <h4>{{trans.get('__JSON__.Basic Settings')}}</h4>
                                <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                tempor.</p> -->
                            </div>
                        </div>
                        <div class="col-md-8">
                            <div class="setting-data">
                                <div class="row">
                                    <!-- <div class="col-md-12">
                                        <h6>Order Commission (Percentage)</h6>  
                                    </div> -->
                                    <div class="col-md-6 order-value">
                                        <!-- <input id="order_commission" name="order_commission" type="number" v-model="setting.order_commission" class="form-control"/> -->
                                         <b-form-group id="input-group-1" :label="trans.get('__JSON__.Order Commission (Percentage)')" label-for="name">
                                          <b-form-input
                                            id="order_commission"
                                            name="order_commission"
                                            v-model="$v.setting.order_commission.$model"
                                            type="number"
                                            @keypress="numberOnly($event)" @paste.prevent
                                            :placeholder="trans.get('__JSON__.IOS User App Version')"
                                            :class="{ 'is-invalid':  $v.setting.order_commission.$error }"
                                          ></b-form-input>
                                          <div v-if=" $v.setting.order_commission.$error" class="invalid-feedback">
                                            <span
                                              v-if="!$v.setting.order_commission.required"
                                            >{{ trans.get('__JSON__.IOS User App Version') }}.</span>
                                              <span
                                              v-if="!$v.setting.order_commission.numeric"
                                            >{{ trans.get('__JSON__.Enter Numeric Value') }}.</span>
                                          </div>
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-12">
                                        <p>Commission Value for Each Order. It will be Applicable for All {{ $auth.setting.restaurant_name }}.</p>
                                     </div>
                                </div>
                                <!-- <div class="row mt-4">
                                  <div class="col-md-12">
                                        <h6>Order Auto Refresh (Seconds)</h6>  
                                    </div>
                                    <div class="col-md-6 order-value">
                                      <b-form-group id="input-group-1" :label="trans.get('__JSON__.Order Auto Refresh (Seconds)')" label-for="name">
                                          <b-form-input
                                            id="order_auto_refresh"
                                            name="order_auto_refresh"
                                            v-model="$v.setting.order_auto_refresh.$model"
                                            type="text"
                                            :placeholder="trans.get('__JSON__.Order Auto Refresh')"
                                            :class="{ 'is-invalid':  $v.setting.order_auto_refresh.$error }"
                                          ></b-form-input>
                                          <div v-if=" $v.setting.order_auto_refresh.$error" class="invalid-feedback">
                                            <span
                                              v-if="!$v.setting.order_auto_refresh.required"
                                            >{{ trans.get('__JSON__.Order Auto Refresh') }}.</span>
                                              <span
                                              v-if="!$v.setting.order_auto_refresh.numeric"
                                            >{{ trans.get('__JSON__.Enter Numeric Value') }}.</span>
                                          </div>
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-12">
                                        <p>Order will be Cancelled if {{ $auth.setting.restaurant_name }} will not Accept or confirm order with this seconds.</p>
                                     </div>
                                </div> -->
                                <div class="row mt-4">
                                   <div class="col-md-12">
                                        <h6>{{trans.get('__JSON__.Is Order Assigned Manually')}}</h6>  
                                    </div>
                                    <div class="col-md-6 order-value">
                                      <div class="swtich-data">
                                        <!-- <label class="switch">
                                          <input type="checkbox" @change="is_order_assigned" class="switch-on" name="is_order_assigned_manually" id="is_order_assigned_manually">
                                      
                                        <div class="slider round">
                                        <span class="on" >ON</span>
                                        <span class="off">OFF</span>
                                        </div></label> -->
                                         <b-form-group>
                                            <label class="switch">
                                                <input
                                                type="checkbox"
                                                id="is_order_assigned_manually"
                                                name="is_order_assigned_manually"
                                                v-model="setting.is_order_assigned_manually"
                                                class="switch-on"
                                                true-value="Yes"
                                                false-value="No"
                                                />
                                                <div class="slider round">
                                                <span class="on">{{ trans.get('__JSON__.On') }}</span>
                                                <span class="off">{{ trans.get('__JSON__.Off') }}</span>
                                                </div>
                                            </label>
                                            </b-form-group>
                                    </div>
                                    </div>
                                    <div class="col-md-12">
                                        <!-- <p>Order will be Cancelled if {{ $auth.setting.restaurant_name }} will not Accept or confirm order with this seconds.</p> -->
                                        <p>{{ trans.get('__JSON__.By Enabling this Option orders will be assign to Delivery Boys automatically') }}.</p>
                                     </div>
                                </div>
                                <div class="row mt-4">
                                   <div class="col-md-12">
                                        <h6>{{trans.get('__JSON__.Live Tracking Enabled in App')}}</h6>  
                                    </div>
                                    <div class="col-md-6 order-value">
                                      <div class="swtich-data">
                                         <b-form-group>
                                            <label class="switch">
                                                <input
                                                type="checkbox"
                                                id="is_enable_live_tracking_app"
                                                name="is_enable_live_tracking_app"
                                                v-model="setting.is_enable_live_tracking_app"
                                                class="switch-on"
                                                true-value="1"
                                                false-value="0"
                                                />
                                                <div class="slider round">
                                                <span class="on">{{ trans.get('__JSON__.On') }}</span>
                                                <span class="off">{{ trans.get('__JSON__.Off') }}</span>
                                                </div>
                                            </label>
                                            </b-form-group>
                                    </div>
                                    </div>
                                    <div class="col-md-12">
                                        <!-- <p>Order will be Cancelled if {{ $auth.setting.restaurant_name }} will not Accept or confirm order with this seconds.</p> -->
                                        <p>{{ trans.get('__JSON__.By Enabling this Option customer will able to live track orders on Google map') }}.</p>
                                     </div>
                                </div>
                                <!-- <div class="row mt-4" v-if="$auth.getVendorId() == 40818">
                                  <div class="col-md-12">
                                      <h6>{{trans.get('__JSON__.Wallet')}}</h6>  
                                  </div>
                                  <div class="col-md-6 vendor-status" v-if="$auth.getVendorId() == 40818">
                                    <div class="swtich-data">
                                        <b-form-group>
                                          <label class="switch">
                                              <input
                                              type="checkbox"
                                              id="wallet_status"
                                              name="wallet_status"
                                              v-model="setting.wallet_status"
                                              class="switch-on"
                                              true-value="active"
                                              false-value="inactive"
                                              />
                                              <div class="slider round">
                                              <span class="on">{{ trans.get('__JSON__.Active') }}</span>
                                              <span class="off">{{ trans.get('__JSON__.Inactive') }}</span>
                                              </div>
                                          </label>
                                        </b-form-group>
                                      </div>
                                  </div>
                                  <div class="col-md-12">
                                    <p>{{ trans.get('__JSON__.This will enable wallet feature') }}.</p>
                                  </div>
                                </div> -->
                                <div class="row mt-4">
                                   <!-- <div class="col-md-12">
                                        <h6>Autocancel Order For Vendors (In Seconds)</h6>  
                                    </div> -->
                                    <div class="col-md-6 order-value">
                                     <b-form-group id="input-group-1" :label="trans.trans('__JSON__.Auto cancel Order For (In Minutes)',{ vendor: $auth.setting.restaurant_name })" label-for="name">
                                          <b-form-input
                                            id="vendor_max_time"
                                            name="vendor_max_time"
                                            v-model="$v.setting.vendor_max_time.$model"
                                            type="number"
                                            @keypress="numberOnly($event)" @paste.prevent
                                            :placeholder="trans.trans('__JSON__.Auto cancel Order For (In Minutes)',{ vendor: $auth.setting.restaurant_name })"
                                            :class="{ 'is-invalid':  $v.setting.vendor_max_time.$error }"
                                          ></b-form-input>
                                          <div v-if=" $v.setting.vendor_max_time.$error" class="invalid-feedback">
                                            <span
                                              v-if="!$v.setting.vendor_max_time.required"
                                            >{{ trans.trans('__JSON__.Auto cancel Order For (In Minutes)',{ vendor: $auth.setting.restaurant_name }) }}.</span>
                                              <span
                                              v-if="!$v.setting.vendor_max_time.numeric"
                                            >{{ trans.get('__JSON__.Enter Numeric Value') }}.</span>
                                          </div>
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-12">
                                        <p>Order will be Cancelled if {{ $auth.setting.restaurant_name }} will not Accept or confirm order with this minutes.</p>
                                     </div>
                                </div>
                                <div class="row mt-4 d-none">
                                    <div class="col-md-6 order-value">
                                       <b-form-group id="input-group-1" :label="trans.trans('__JSON__.Auto cancel Order For Driver (In Minutes)', { driver: $auth.setting.driver_name })" label-for="name">
                                          <b-form-input
                                            id="driver_max_time"
                                            name="driver_max_time"
                                            v-model="$v.setting.driver_max_time.$model"
                                            type="number"
                                            @keypress="numberOnly($event)" @paste.prevent
                                            :placeholder="trans.trans('__JSON__.Auto cancel Order For Driver (In Minutes)', { driver: $auth.setting.driver_name })"
                                            :class="{ 'is-invalid':  $v.setting.driver_max_time.$error }"
                                          ></b-form-input>
                                          <div v-if=" $v.setting.driver_max_time.$error" class="invalid-feedback">
                                            <span
                                              v-if="!$v.setting.driver_max_time.required"
                                            >{{ trans.trans('__JSON__.Auto cancel Order For Driver (In Minutes)', { driver: $auth.setting.driver_name }) }}.</span>
                                              <span
                                              v-if="!$v.setting.driver_max_time.numeric"
                                            >{{ trans.get('__JSON__.Enter Numeric Value') }}.</span>
                                          </div>
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-12">
                                        <p>Order will be Cancelled if {{ $auth.setting.driver_name }} will not Accept or confirm order with this minutes.</p>
                                     </div>
                                </div>
                                <div class="row mt-4 d-none">
                                    <div class="col-md-6 order-value">
                                       <b-form-group id="input-group-1" :label="trans.get('__JSON__.Order Dashboard Default Date')" label-for="name">
                                          <b-form-select v-model="setting.order_dashboard_default_time" :options="default_time_options"></b-form-select>
                                          <div v-if=" $v.setting.driver_max_time.$error" class="invalid-feedback">
                                            <span
                                              v-if="!$v.setting.driver_max_time.required"
                                            >{{ trans.trans('__JSON__.Autocancel Order For Driver (In Seconds)', { driver: $auth.setting.driver_name }) }}.</span>
                                              <span
                                              v-if="!$v.setting.driver_max_time.numeric"
                                            >{{ trans.get('__JSON__.Enter Numeric Value') }}.</span>
                                          </div>
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-12">
                                        <p>{{ trans.get('__JSON__.Set the default date range for the Ordering Dashboard page') }}.</p>
                                     </div>
                                </div>
                                <div class="row mt-4">
                                    <div class="col-md-6 order-value">
                                      <b-form-group id="input-group-1" :label="trans.get('__JSON__.Order Notification Sound')" label-for="name">
                                        <b-form-select v-model="setting.order_notification_sound" :options="default_notification_sounds"></b-form-select>
                                      </b-form-group>
                                      <div v-if=" $v.setting.order_notification_sound.$error" class="invalid-feedback">
                                        <span v-if="!$v.setting.order_notification_sound.required">
                                          {{ trans.get('__JSON__.Please select notification sound')}}.
                                        </span>
                                      </div>
                                    </div>
                                    <div class="col-md-6 order-value">
                                      <b-button variant="link" @click="playSound()" class="top-26"><i class="fas" :class="{'fa-play':!playing, 'fa-stop':playing}"></i></b-button>
                                    </div>
                                    <div class="col-md-12">
                                        <p>{{trans.get('__JSON__.New order notification sound')}}</p>
                                     </div>
                                </div>
                                <div class="row mt-4">
                                  <div class="col-md-12">
                                    <div class="row">
                                      <div class="col-md-12">
                                        <h6>Should upload image with order?</h6>
                                      </div>
                                    </div>
                                    <div class="row">
                                      <div class="col-md-12">
                                        <div class="swtich-data">
                                          <b-form-group>
                                            <label class="switch">
                                              <input
                                                type="checkbox"
                                                id="is_upload_order_image"
                                                name="is_upload_order_image"
                                                v-model="setting.is_upload_order_image"
                                                class="switch-on"
                                                true-value="Yes"
                                                false-value="No"
                                              />
                                              <div class="slider round">
                                                <span class="on">{{ trans.get('__JSON__.On') }}</span>
                                                <span class="off">{{ trans.get('__JSON__.Off') }}</span>
                                              </div>
                                            </label>
                                          </b-form-group>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="row" v-if="setting.is_upload_order_image == 'Yes'">
                                      <div class="col-md-6 order-value">
                                        <b-form-group id="input-group-1" label="Image Upload Limit" label-for="name">
                                          <b-form-input
                                            id="order_image_upload_limit"
                                            name="order_image_upload_limit"
                                            v-model="$v.setting.order_image_upload_limit.$model"
                                            type="text"
                                            placeholder="Please enter limit"
                                            maxlength="10"
                                            autocomplete="off"
                                            :class="{ 'is-invalid': $v.setting.order_image_upload_limit.$error }"
                                            @keypress="numberOnly($event)" @paste.prevent
                                          ></b-form-input>
                                          <div v-if="$v.setting.order_image_upload_limit.$error" class="invalid-feedback">
                                            <span v-if="!$v.setting.order_image_upload_limit.required">Please enter limit.</span>
                                            <span v-if="!$v.setting.order_image_upload_limit.numeric">{{trans.get('__JSON__.Enter Numeric Value')}}.</span>
                                            <span v-if="!$v.setting.order_image_upload_limit.maximumValue">Maximum limit is 15</span>
                                          </div>
                                        </b-form-group>
                                      </div>
                                    </div>
                                    <div class="row" v-if="setting.is_upload_order_image == 'Yes'">
                                      <div class="col-md-12">
                                        <div class="row">
                                          <div class="col-md-12">
                                            <h6>Image Upload Text</h6>
                                          </div>
                                          <div class="col-md-6 order-value">
                                            <textarea rows="3" placeholder="Image Upload Text" id="image_upload_text" type="text" class="form-control" v-model="$v.setting.image_upload_text.$model" name="image_upload_text" :class="{ 'is-invalid':  $v.setting.image_upload_text.$error }"></textarea>
                                            <div
                                              v-if=" $v.setting.image_upload_text.$error"
                                              class="invalid-feedback">
                                              <span v-if="!$v.setting.image_upload_text.required">Please enter text.</span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                    </div>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="row section-main">
                        <div class="col-md-4">
                            <div class="setting-heading">  
                                <h4>Delivery Charges</h4>
                                 <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                tempor.</p> -->
                            </div>
                        </div>
                        <div class="col-md-8">
                          <div class="setting-data">
                            <div class="row">
                                <div class="col-md-12">
                                    <h6>Delivery Charge Based On</h6>
                                </div>
                                 <div class="col-md-6 order-value">
                                    <multiselect v-model="value"
                                      :options="delivery_charges"
                                      track-by="id"
                                      label="id" 
                                      value="text" 
                                      name="delivery_charges"
                                      placeholder="Select one"
                                      id="delivery_charges"
                                      :searchable="false"
                                      :allow-empty="false"></multiselect>
                                </div>
                                <div class="col-md-12">
                                    <p>Select How you wish to set Delivery Charge to Customer.</p>
                                </div>
                             </div>
                             <div class="row mt-3">
                                <div class="col-md-12" v-for="(v,index) in deliverycharges" :key="index">
                                    <div class="row mt-2">
                                       <div class="col-md-3">
                                            <h6> Order {{ value && value.show}} From</h6>
                                            <input v-model="v.min_value" name = "min_value[]" type="number" class="form-control"/>
                                          <!-- <b-form-group >
                                          <b-form-input
                                            name="min_value[]"
                                            v-model="v.min_value"
                                            type="text"
                                            :placeholder="trans.get('__JSON__.Delivery Charge Minimum Order')"
                                              :class="{ 'is-invalid':  $v.deliverycharges.v.min_value.$error }"
                                          ></b-form-input>
                                          <div v-if="$v.deliverycharges.v.min_value.$error" class="invalid-feedback">
                                            <span v-if="!$v.deliverycharges.v.min_value.required"
                                            >{{ trans.get('__JSON__.Delivery Charge Minimum Order') }}.</span>
                                              <span
                                              v-if="!$v.deliverycharges.min_value.numeric"
                                            >{{ trans.get('__JSON__.Enter Numeric Value') }}.</span>
                                          </div>
                                        </b-form-group> -->
                                        </div>
                                         <div class="col-md-3">
                                            <h6> Order {{ value && value.show}} To</h6>
                                            <input v-model="v.max_value" name = "max_value[]" type="number" class="form-control"/>
                                         </div>
                                         <div class="col-md-3">
                                            <h6>Charges</h6>
                                            <input v-model="v.delivery_charges" name = "delivery_charges[]"  type="number" class="form-control"/>
                                        </div>
                                        <div class="col-md-3">
                                            <h6>&nbsp;</h6>
                                            <button class="btn btn-primary btn-sm btn-rounded" @click.prevent="AddField">+</button>&nbsp;&nbsp;
                                            <button class="btn btn-danger btn-sm btn-rounded" v-if="index != 0 || index!=''" @click.prevent="remove_delivery_charges(index)">-</button>
                                        </div>
                                    </div>
                                </div>
                             </div>
                          </div>
                        </div>
                    </div>
                    

                
                <div class="btn-update">
                    <div class="col-sm-6">
                    <button type="submit" class="btn btn-primary mr-1 mt-3">Update</button>
                    </div>
                </div>

            </div>
        </div>
      </div>
    </div>
</b-form>
</template>
<style scoped>
.top-26{
  margin-top: 26px;
}
</style>