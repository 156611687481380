<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import pagination from "laravel-vue-pagination";
import { reportService } from "../../services";
import moment from "moment";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  components: { Layout, PageHeader, pagination, DatePicker },
  data() {
    return {
      totalSale: {},
      totalSaleReport: [],
      restaurants_id: this.$route.params.restaurantID,
      user_id: this.$route.params.userID,
      selectedStartDate: this.$route.params.startDate,
      selectedEndDate: this.$route.params.endDate,
      sortBy: "created_at",
      sortDesc: true,
      paginations: {},
      limit: 2,
      loading: false,
      isDownloading: false,
      fields: [
        { key: "user_id", label: "#", sortable: false },
        { key: "id", label: "Order ID", sortable: false },
        { key: "created_at", label: "Order Date", sortable: false },
        { key: "customer_name", label: "Patient Name", sortable: false },
        {
          key: "customer_mobile_number",
          label: "Patient Phone Number",
          sortable: false,
        },
        { key: "name", label: "Stores Name", sortable: false },
        {
          key: "promocode_applicable_mrp_sub_total",
          label: "Medicine MRP",
          sortable: false,
        },
        {
          key: "mrp_sub_total",
          label: "Total MRP",
          sortable: false,
        },
        { key: "itemtaxslab", label: "Item GST", sortable: false },
        { key: "delivery_fee", label: "Delivery Charge", sortable: false },
        { key: "total_amount", label: "Total Amount", sortable: false },
        { key: "payable_amount", label: "Payable Amount", sortable: false },
        { key: "admin_commision", label: "Admin Commission", sortable: false },
        {
          key: "admin_commission_gst",
          label: "Admin Commission GST",
          sortable: false,
        },
        { key: "payment_method", label: "Payment Method", sortable: false },
        { key: "order_status", label: "Order Status", sortable: false },
        {
          key: "settlement_amount",
          label: "Settlement Amount Value",
          sortable: false,
        },
        { key: "earning", label: "Settlement Amount", sortable: false },
        { key: "instamed_cash", label: "Instamed Cash", sortable: false },
        {
          key: "referral_discount",
          label: "Referral Discount",
          sortable: false,
        },
        { key: "start_created_at", label: "Start Time", sortable: false },
        { key: "delivered_date", label: "End Time", sortable: false },
        { key: "Duration", label: "Duration", sortable: false },
        { key: "source_type", label: "Lead Source", sortable: false },
        { key: "device_type", label: "Device Type", sortable: false },
        { key: "settled", label: "Settlement Status", sortable: false },
        { key: "un_settle_reason", label: "Reason", sortable: false },
        { key: "hdp_Id", label: "HDP Order", sortable: false },
        { key: "invoice_date", label: "Invoice Date", sortable: false },
        {
          key: "order_placed_by_name",
          label: "Order Placed By Name",
          sortable: false,
        },
        {
          key: "order_placed_by_number",
          label: "Order Placed By Number",
          sortable: false,
        },
      ],
      moment: moment,
      reportsData: [],
    };
  },
  mounted() {
    this.getTotalSettlementReport();
  },
  methods: {
    getTotalSettlementReport(page) {
      this.loading = true;
      reportService
        .getAllStoresSettlementReport({
          start_date: this.selectedStartDate,
          end_date: this.selectedEndDate,
          page: page ? page : 1,
          pagination: true,
        })
        .then((response) => {
          this.loading = false;
          this.paginations = response.data.result;
          this.totalSale = response.data.result;
          console.log(response.data.result, "user list", response);
        });
    },
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      this.getTotalSettlementReport();
    },
    disableEndDate(date) {
      return moment(date).isBefore(this.selectedStartDate, "day");
    },
    disableStartDate(date) {
      return moment(date).day() !== 1;
    },
    updateEndDate() {
      if (this.selectedStartDate) {
        const nextSunday = moment(this.selectedStartDate)
          .add(6, "days")
          .endOf("isoWeek");
        this.selectedEndDate = nextSunday.format("YYYY-MM-DD");
      } else {
        this.selectedEndDate = "";
      }
    },
    getPreviousMonday(d) {
      d = new Date(d);
      var day = d.getDay(),
        diff = d.getDate() - day + (day === 0 ? -6 : 1) - 7;
      let monday = new Date(d.setDate(diff));
      return moment(monday).format("YYYY-MM-DD");
    },
    getPreviousSunday(d) {
      d = new Date(d);
      var day = d.getDay(),
        diff = d.getDate() - day + (day === 0 ? 0 : 7) - 7;
      let sunday = new Date(d.setDate(diff));
      return moment(sunday).format("YYYY-MM-DD");
    },
    saleExportCsv() {
      console.log("export");
      this.isDownloading = true;
      reportService
        .getAllStoresSettlementReport({
          start_date: this.selectedStartDate,
          end_date: this.selectedEndDate,
          pagination: false,
        })
        .then((response) => {
          this.isDownloading = false;
          this.reportsData = response.data.result;
          console.log(response.data.result, "user list", response);
          this.downloadCSV();
        });
    },
    getDuration(created_at, delivered_date) {
      let date1 = moment(created_at);
      let date2 = moment(delivered_date);
      return date2.diff(date1, "minutes");
    },
    downloadCSV() {
      let reportsData =
        "\ufeff" +
        "Order ID,Order Date,Patient Name,Patient Phone Number,Stores Name,Medicine MRP,Total MRP,Item GST,Delivery Charge,Total Amount,Payable Amount,Admin Commission,Admin Commission GST,Payment Method,Order Status,Settlement Amount Value,Settlement Amount,Instamed Cash,Referral Discount,Start Time, End Time, Duration,Lead Source,Device Type,Settlement Status,Reason,HDP Order,Invoice Status,Invoice Date,Order placed by name,Order placed by number\n";
      this.reportsData.forEach((el) => {
        let orderIdText = el["id"];
        if (el["sipment1_orderId"]) {
          if (el["is_depot_store"] == 1) {
            orderIdText = `Depot ${el["id"]} (Sub Order of ${el["sipment1_orderId"]})`;
          }
        }
        var line =
          orderIdText +
          "," +
          moment(el["created_at"]).format("DD-MM-YY hh:mm A") +
          "," +
          el["customer_name"].split(",").join("") +
          "," +
          el["customer_mobile_number"] +
          "," +
          el["name"] +
          "," +
          (el["promocode_applicable_mrp_sub_total"]
            ? el["promocode_applicable_mrp_sub_total"]
            : "0.00") +
          "," +
          (el["mrp_sub_total"]
            ? el["mrp_sub_total"]
            : "0.00") +
          "," +
          el["itemtaxslab"] +
          "," +
          el["delivery_fee"] +
          "," +
          el["total_amount"] +
          "," +
          el["payable_amount"] +
          "," +
          el["admin_commision"] +
          "," +
          el["admin_commission_gst"] +
          "," +
          el["payment_method"] +
          "," +
          (el["order_status"] == "Placed"
            ? "New Request"
            : el["order_status"] == "InKitchen"
            ? "Confirmed"
            : el["order_status"]) +
          "," +
          el["settlement_amount"] +
          "," +
          el["earning"] +
          "," +
          el["instamed_cash"] +
          "," +
          el["referral_discount"] +
          "," +
          el["created_at"] +
          "," +
          (el["delivered_date"]
            ? el["delivered_date"]
            : "0000-00-00 00:00:00") +
          "," +
          this.getDuration(el["created_at"], el["delivered_date"]) +
          "," +
          (el["source_type"] ? el["source_type"] : "-") +
          "," +
          el["device_type"] +
          "," +
          (el["settled"] == 0 ? "Unsettled" : "Settled") +
          "," +
          (el["un_settle_reason"] ? el["un_settle_reason"] : "-") +
          "," +
          (el["hdp_Id"] ? "Yes" : "No") +
          "," +
          (el["order_status"] && el["order_status"].toLowerCase() == "delivered"
            ? el["vendor_invoice"] == null
              ? "Pending"
              : "Uploaded"
            : "-") +
          "," +
          (el["order_status"] &&
          el["order_status"].toLowerCase() == "delivered" &&
          el["invoice_date"]
            ? moment(el["invoice_date"]).format("DD-MM-YY hh:mm A")
            : "-") +
          "," +
          el["order_placed_by_name"] +
          "," +
          el["order_placed_by_number"] +
          "\n";
        reportsData += line;
      });

      var blob = new Blob([reportsData], { type: "csv/plain" });
      var date = new Date().toLocaleString();
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "settlement_" + date + ".csv";
      link.click();

      console.log("csv");
    },
  },
  watch: {
    selectedStartDate() {
      this.updateEndDate();
      if (this.selectedStartDate) {
        this.getTotalSettlementReport();
      } else {
        this.totalSale = [];
      }
    },
  },
};
</script>

<template>
  <Layout>
    <div>
      <div class="row">
        <div class="col-12">
          <div
            class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
          >
            <div class="page-title-left avatar-main-icon">
              <div class="avatar-xs mr-3">
                <span
                  class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18"
                >
                  <i class="bx bx-purchase-tag"></i>
                </span>
              </div>
              <div class="report-tag">
                <h4 class="mb-0 font-size-18">
                  {{ trans.get("__JSON__.All Stores Settlement Report") }}
                </h4>
                <p>
                  <router-link :to="{ name: 'reports-list' }">{{
                    trans.get("__JSON__.Reports")
                  }}</router-link
                  >>
                  <router-link :to="{ name: 'reports.settlement.report' }">{{
                    trans.get("__JSON__.Store Wise Settlement Report")
                  }}</router-link>
                  > {{ trans.get("__JSON__.All Stores Settlement Report") }}
                </p>
              </div>
            </div>
            <div class="page-title-right">
              <button
                @click="saleExportCsv"
                class="btn btn-primary btn-rounded export-btn"
                v-if="isDownloading"
                disabled
              >
                {{ trans.get("__JSON__.Exporting...") }}
              </button>
              <button
                @click="saleExportCsv"
                class="btn btn-primary btn-rounded export-btn"
                v-else
              >
                {{ trans.get("__JSON__.Export To CSV") }}
              </button>
              <button
                @click="saleExportCsv"
                class="btn btn-primary btn-rounded m-export-btn"
              >
                <i class="bx bx-archive-in"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 list-table-main">
          <div class="card">
            <div class="card-body">
              <div class="row justify-content-end">
                <div class="form-group">
                  <div style="display: flex">
                    <div
                      style="
                        display: flex;
                        align-items: end;
                        margin-right: 15px;
                      "
                    >
                      <div style="margin: 5px 10px">From</div>
                      <date-picker
                        v-model="selectedStartDate"
                        format="YYYY-MM-DD"
                        value-type="YYYY-MM-DD"
                        type="date"
                        class="sales-date"
                        :disabled-date="disableStartDate"
                      ></date-picker>
                      <div style="margin: 5px 10px">To</div>
                      <date-picker
                        v-model="selectedEndDate"
                        :disabled-date="disableEndDate"
                        format="YYYY-MM-DD"
                        :disabled="true"
                        value-type="YYYY-MM-DD"
                        type="date"
                      ></date-picker>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div
                  class="col-12 table-main-list total-sales-report-table table-responsive"
                >
                  <b-table
                    :items="totalSale.data"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :no-local-sorting="true"
                    @sort-changed="sortingChanged"
                    :busy="loading"
                    show-empty
                  >
                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>{{ trans.get("__JSON__.Loading...") }}</strong>
                      </div>
                    </template>

                    <template v-slot:cell(user_id)="data">
                      <div>
                        {{
                          totalSale.per_page * (totalSale.current_page - 1) +
                          data.index +
                          1
                        }}
                      </div>
                    </template>

                    <template v-slot:cell(id)="data">
                      <span
                        v-if="data.item.is_depot_store == 1"
                        class="badge badge-pill badge-soft-primary font-size-12 depot-badge"
                        >Depot</span
                      ><br />
                      <span
                        v-if="
                          !data.item.vendor_invoice &&
                          data.item.order_status &&
                          data.item.order_status.toLowerCase() == 'delivered'
                        "
                        class="badge badge-pill badge-soft-primary font-size-12 depot-badge"
                        style="background-color: #f46a6a !important"
                        >Bill Upload Pending</span
                      >
                      <div>{{ data.item.id }}</div>
                      <div
                        class="font-weight-bold"
                        v-if="data.item.sipment1_orderId"
                      >
                        ({{
                          "Sub Order of" +
                          " " +
                          "#" +
                          data.item.sipment1_orderId
                        }})
                      </div>
                    </template>

                    <template v-slot:cell(created_at)="data">
                      {{
                        moment(data.item.created_at).format("DD-MM-YY hh:mm A")
                      }}
                    </template>
                    <template v-slot:cell(invoice_date)="data">
                      {{
                        data.item.order_status &&
                        data.item.order_status.toLowerCase() == "delivered" &&
                        data.item.invoice_date
                          ? moment(data.item.invoice_date).format(
                              "DD-MM-YY hh:mm A"
                            )
                          : "-"
                      }}
                    </template>

                    <template
                      v-slot:cell(promocode_applicable_mrp_sub_total)="data"
                    >
                      <span>{{
                        data.item.promocode_applicable_mrp_sub_total
                          ? parseFloat(
                              data.item.promocode_applicable_mrp_sub_total
                            ).toFixed(2)
                          : "0.00"
                      }}</span>
                    </template>

                    <template
                      v-slot:cell(mrp_sub_total)="data"
                    >
                      <span>{{
                        data.item.mrp_sub_total
                          ? parseFloat(
                              data.item.mrp_sub_total
                            ).toFixed(2)
                          : "0.00"
                      }}</span>
                    </template>

                    <template v-slot:cell(earning)="data">
                      <span>{{
                        parseFloat(data.item.earning).toFixed(2) || "0.00"
                      }}</span>
                    </template>

                    <template v-slot:cell(start_created_at)="data">
                      {{ data.item.created_at }}
                    </template>

                    <template v-slot:cell(delivered_date)="data">
                      {{ data.item.delivered_date || "0000-00-00 00:00:00" }}
                    </template>

                    <template v-slot:cell(Duration)="data">
                      <div
                        v-if="
                          data.item.order_status &&
                          data.item.order_status.toLowerCase() === 'delivered'
                        "
                      >
                        {{
                          getDuration(
                            data.item.created_at,
                            data.item.delivered_date
                          )
                        }}
                      </div>
                      <div v-else>
                        {{ "NA" }}
                      </div>
                    </template>

                    <template v-slot:cell(source_type)="data">
                      <span>{{ data.item.source_type || "-" }}</span>
                    </template>

                    <template v-slot:cell(hdp_Id)="data">
                      {{ data.item.hdp_Id ? "Yes" : "No" }}
                    </template>

                    <template v-slot:cell(settled)="data">
                      <span v-if="data.item.settled == 0">Unsettled</span>
                      <span v-else>Settled</span>
                    </template>

                    <template v-slot:cell(un_settle_reason)="data">
                      <span v-if="data.item.un_settle_reason">{{
                        data.item.un_settle_reason
                      }}</span>
                      <span v-else>-</span>
                    </template>

                    <template #empty>
                      <p class="text-center">
                        {{ trans.get("__JSON__.No record found") }}
                      </p>
                    </template>
                  </b-table>
                </div>
                <div class="row col-sm-5 offset-sm-7">
                  <div class="col">
                    <div
                      class="dataTables_paginate paging_simple_numbers float-right"
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <pagination
                          :data="totalSale"
                          :limit="limit"
                          @pagination-change-page="getTotalSettlementReport"
                        ></pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>


<style>
.total-sales-report-table .table thead th:nth-child(1),
.total-sales-report-table .table thead th:nth-child(3),
.total-sales-report-table .table thead th:nth-child(4) {
  pointer-events: none;
}

.store-gst {
  width: 96% !important;
}

.mx-input:disabled,
.mx-input.disabled {
  color: #555;
  background-color: #fff;
  border-color: #ccc;
  cursor: not-allowed !important;
}

.mx-input {
  height: calc(1.5em + 0.94rem + 1px) !important;
}

.sales-date.mx-datepicker svg {
  cursor: pointer !important;
}

.mx-datepicker svg {
  cursor: not-allowed;
}
</style>