<template>
  <b-modal :id="'modal-track-'+order.order_id"
    lazy
    ok-title="Cancel"
    ok-only
    ok-variant="secondary"
    scrollable
    size="lg"
    v-model="showModel"
    @shown="initMap"
    @hidden="removeSocket"
    >
    <!-- @show="getDeliveryZones"
    @ok="assignDriver" -->
    <template #modal-header="{ close }">
      <div class="align-self-center">
        <h4 class="mb-0">{{trans.get('__JSON__.Track Driver')}}</h4>
      </div>
      <div class="align-items-center d-flex">
        <h4 class="mb-0">{{modelTitle}}</h4>
        <b-button variant="link" aria-label="Close"  @click="close()"><i class="fas fa-times text-black-50"></i></b-button>
      </div>
    </template>
    <div class="row">
      <div class="col-12">
        <div class="h-500" ref="track_map">
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import config from '../../../config';
export default {
  props: ['order'],
  data(){
      return {
        config: config,
        map: null,
        driver: null,
        latlng: null,
        marker: null,
        showModel:true,
        modelTitle:'',
      }
  },
  mounted(){
    // this.initMap();
    if (this.order.suborder) {
      this.modelTitle = this.order.is_depot_store == 0 ? this.order.order_id+' Shipment 1' : this.order.order_id+' Shipment 2';
    } else if (this.order.parent_id && this.order.parent_id > 0) {
      this.modelTitle = this.order.is_depot_store == 0 ? '#'+this.order.parent_id+' Shipment 1' : '#'+this.order.parent_id+' Shipment 2';
    } else {
      this.modelTitle = this.order.order_id;
    }
  },
  methods: {
    trackDriver(){
      this.$bvModal.show('modal-track-'+this.order.order_id);
    },
    initMap(){
      // this.driver = this.order.driver[0];
      this.driver = this.order.driver;
      this.map = new window.google.maps.Map(this.$refs.track_map,{
        center: {
          lat: parseFloat(this.driver.latitude),
          lng: parseFloat(this.driver.longitude)
        },
        zoom: 16
      })
      this.setLatLng()
      this.initSocket();
    },
    setLatLng(){
      this.latlng = new window.google.maps.LatLng(parseFloat(this.driver.latitude), parseFloat(this.driver.longitude));
      if(this.marker) {
        this.marker.setMap(null);
      }
      this.marker = new window.google.maps.Marker({
        position: this.latlng,
        title: (this.driver.firstname??'' +' '+this.driver.lastname??'')
      });
      const infowindow = new google.maps.InfoWindow({
        content: '<div class="list-group" style="max-width: 200px;">'
        +'<div class="list-group-item d-flex align-items-center">'
        +'<span class="b-avatar mr-3 badge-info rounded-circle">'
        +'<span class="b-avatar-img">'
        +'<img src="'+this.driver.profile_path+'" alt="avatar" >'
        +'</span></span>'
        +'<span class="mr-auto">'+(this.driver.firstname??'' +' '+this.driver.lastname??'')+'</span>'
        +'</div></div>',
      });
      this.marker.setMap(this.map);
      this.marker.addListener("click", () => {
        infowindow.open({
          anchor: this.marker,
          map: this.map,
          shouldFocus: false,
        });
      });
      this.map.setCenter(this.latlng);
    },
    initSocket(){
      let roomID = "R"+this.$auth.getVendorId()+"S"+this.driver.driverid;
      this.$socket.emit('room', roomID);
      this.sockets.subscribe('driver_location', function(data){
        if(data.driverid == this.driver.driverid){
          this.driver = {
            ...this.driver,
            latitude: data.latitude,
            longitude: data.longitude
          }
          this.setLatLng();
        }
      })
    },
    removeSocket(){
      this.sockets.unsubscribe('driver_location')
      this.$emit('hidden');
    }
  }
}
</script>
<style scoped>
.h-500{
  height: 62vh;
}
</style>