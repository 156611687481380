<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import pagination from "laravel-vue-pagination";
import {
  get_decimal,
  get_currency
} from "../../Helper/helper";
import { reportService } from "../../services";
/**
 * Report component
 */
export default {
  components: { Layout, PageHeader, pagination },
  data() {
    return {
      decimal: get_decimal(),
      customerReport: [],
      user_details: {},
      signup_details: {},
      sortBy: "created_at",
      search: "",
      sortDesc: true,
      loading: true,
      isDownloading: false,
      token: localStorage.getItem('token'),
      fields: [
        { key: "particular", label: "", sortable: false },
        { key: "app_order_online", label: "App order", sortable: false },
        { key: "app_order_call", label: "Call order", sortable: false },
        { key: "app_order_wap", label: "WAP order", sortable: false },
        { key: "web", label: "Web", sortable: false },
        { key: "total", label: "Total", sortable: false },

      ],
    };
  },
  mounted() {
    if(this.token){
      this.getCustomerReport();
    }
  },
  methods: {
    downloadsCSV: function () {
      let customerExportReport =
        '\ufeff' +
        ' ,App order,Call order,WAP order,Web,Total \n'
      this.customerReport.forEach((el) => {
        var line =
          el["order"] +
          "," + 
          el["online"] +
          "," +
          el["call"] +
          "," +
          el["wap"] +
          "," + 
          el["web"] +
          "," +
          el["total"] +
          "," +
          "\n"
        customerExportReport += line;
      });

      var blob = new Blob([customerExportReport], { type: "csv/plain" });
      var date = new Date().toLocaleString();
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "pharmacy_customer_" + date + ".csv";
      link.click();
    },
    getCustomerReport() {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+ this.token
      }
      this.loading = true;
      reportService
        .getPharmacyCustomers({
          headers: headers,
        })
        .then((response) => {
          this.loading = false;
          this.user_details = response.data.result.user_count_details[0];
          this.signup_details = response.data.result.sign_up_data[0];
        this.customerReport = [
           {"order": "Order Number", "online": "", "call": "", "wap": "", "web" : "", "total": "" , "color": "yes"},
           {"order": "Total Order", "online": this.user_details.app_total_order, "call": this.user_details.call_total_order, "wap": this.user_details.whatsapp_total_order, "web" : this.user_details.web_total_order, "total": this.user_details.total_order}, 
           {"order": "First time Orders", "online": this.user_details.app_customers_with_one_order, "call": this.user_details.call_customers_with_one_order, "wap": this.user_details.whatsapp_customers_with_one_order, "web" : this.user_details.web_customers_with_one_order, "total": this.user_details.total_customers_with_one_order},
           {"order": "Repeat orders", "online": this.user_details.app_customers_with_multiple_order, "call": this.user_details.call_customers_with_multiple_order, "wap": this.user_details.whatsapp_customers_with_multiple_order, "web" : this.user_details.web_customers_with_multiple_order, "total": this.user_details.total_customers_with_multiple_order }, 
           {"order": "Take away Repeat Orders", "online": this.user_details.app_repeat_takeaway_orders, "call": this.user_details.call_repeat_takeaway_orders, "wap": this.user_details.whatsapp_repeat_takeaway_orders, "web" : this.user_details.web_repeat_takeaway_orders, "total": this.user_details.total_repeat_takeaway_orders },
           {"order": "Delivery Repeat Orders", "online": this.user_details.app_repeat_delivery_orders, "call": this.user_details.call_repeat_delivery_orders, "wap": this.user_details.whatsapp_repeat_delivery_orders, "web" : this.user_details.web_repeat_delivery_orders, "total": this.user_details.total_repeat_delivery_orders },
           {"order": "Order Value", "online": "", "call": "", "wap": "", "web" : "", "total": "", "color": "yes"},
           {"order": "Total Order", "online": this.user_details.app_total_order_value.toFixed(2), "call": this.user_details.call_total_order_value.toFixed(2), "wap": this.user_details.whatsapp_total_order_value.toFixed(2), "web" : this.user_details.web_total_order_value.toFixed(2), "total": this.user_details.total_order_value.toFixed(2)}, 
           {"order": "First time Orders", "online": this.user_details.app_customers_with_one_order_value.toFixed(2), "call": this.user_details.call_customers_with_one_order_value.toFixed(2), "wap": this.user_details.whatsapp_customers_with_one_order_value.toFixed(2), "web" : this.user_details.web_customers_with_one_order_value.toFixed(2), "total": this.user_details.total_customers_with_one_order_value.toFixed(2)},
           {"order": "Repeat orders", "online": this.user_details.app_customers_with_multiple_order_value.toFixed(2), "call": this.user_details.call_customers_with_multiple_order_value.toFixed(2), "wap": this.user_details.whatsapp_customers_with_multiple_order_value.toFixed(2), "web" : this.user_details.web_customers_with_multiple_order_value.toFixed(2), "total": this.user_details.total_customers_with_multiple_order_value.toFixed(2) }, 
           {"order": "Take away Repeat Orders", "online": this.user_details.app_repeat_takeaway_orders_value.toFixed(2), "call": this.user_details.call_repeat_takeaway_orders_value.toFixed(2), "wap": this.user_details.whatsapp_repeat_takeaway_orders_value.toFixed(2), "web" : this.user_details.web_repeat_takeaway_orders_value.toFixed(2), "total": this.user_details.total_repeat_takeaway_orders_value.toFixed(2) },
           {"order": "Delivery Repeat Orders", "online": this.user_details.app_repeat_delivery_orders_value.toFixed(2), "call": this.user_details.call_repeat_delivery_orders_value.toFixed(2), "wap": this.user_details.whatsapp_repeat_delivery_orders_value.toFixed(2), "web" : this.user_details.web_repeat_delivery_orders_value.toFixed(2), "total": this.user_details.total_repeat_delivery_orders_value.toFixed(2) },
           {"order": "New Signups", "online": "", "call": "", "wap": "", "web" : "", "total": "", "color": "yes" },
           {"order": "Delivery", "online": this.signup_details.app_delivery_first_sign, "call": this.signup_details.call_delivery_first_sign, "wap": this.signup_details.whatsapp_delivery_first_sign, "web" : this.signup_details.web_delivery_first_sign, "total": this.signup_details.total_delivery_first_sign }, 
           {"order": "Takeaway", "online": this.signup_details.app_takeaway_first_sign, "call": this.signup_details.call_takeaway_first_sign, "wap": this.signup_details.whatsapp_takeaway_first_sign, "web" : this.signup_details.web_takeaway_first_sign, "total": this.signup_details.total_takeaway_first_sign }];

        console.log(this.customerReport, "this.customerReport");

        });
    },
    exportCustomerReport()
    {
        this.isDownloading = true;
        this.downloadsCSV();
    },
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      if(token){
      this.getCustomerReport();
      }
    },
  }
};
</script>

<template>
  <Layout>
    <div>
      <div class="row">
          <div class="col-12">
              <div
              class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
              >
              <div class="page-title-left avatar-main-icon">
                  <div class="avatar-xs mr-3">
                  <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                      <i class="bx bx-purchase-tag"></i>
                  </span>
                  </div>
                  <div class="report-tag">
                    <h4 class="mb-0 font-size-18">{{ trans.get('__JSON__.Pharmacy Customer Report') }}</h4>
                    <p><router-link :to="{ name: 'reports-list'}">{{ trans.get('__JSON__.Reports') }}</router-link> > {{ trans.get('__JSON__.Pharmacy Customer Report') }}</p>
                  </div>
              </div>
              <div class="page-title-right">
                <button @click="exportCustomerReport" class="btn btn-primary btn-rounded export-btn" v-if="isDownloading" disabled>{{ trans.get('__JSON__.Exporting...') }}</button>
                <button @click="exportCustomerReport" class="btn btn-primary btn-rounded export-btn" v-else>{{ trans.get('__JSON__.Export To CSV') }}</button>
                <button @click="exportCustomerReport" class="btn btn-primary btn-rounded m-export-btn mb-4"><i class="bx bx-archive-in"></i></button>
              </div>
              </div>
          </div>
      </div>

      <!-- end row -->
      <div class="row">
        <div class="col-12 list-table-main">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12 table-main-list inactive-customer-report-table table-responsive">
                  <b-table
                    :items="customerReport"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :no-local-sorting="true"
                    @sort-changed="sortingChanged"
                    :busy="loading"
                    show-empty
                  >

                  <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>{{trans.get('__JSON__.Loading...')}}</strong>
                      </div>
                    </template>

                    <template v-slot:cell(particular)="data">
                        <p v-if="data.item.color" style="font-weight: 600; font-size: 14px;">{{ data.item.order }}</p>
                        <p v-else>{{ data.item.order }}</p>
                    </template>
                    <template v-slot:cell(app_order_online)="data">
                        <p>{{ data.item.online }}</p>
                    </template>
                    <template v-slot:cell(app_order_call)="data">
                        <p>{{ data.item.call }}</p>
                    </template>
                    <template v-slot:cell(app_order_wap)="data">
                        <p>{{ data.item.wap }}</p>
                    </template>
                    <template v-slot:cell(web)="data">
                        <p>{{ data.item.web }}</p>
                    </template>
                    <template v-slot:cell(total)="data">
                        <p>{{ data.item.total }}</p>
                    </template>

                     <template #empty>
                      <p class="text-center">{{ trans.get('__JSON__.No pharmacy data customer Found') }}</p>
                    </template>
                  </b-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style>
.inactive-customer-report-table .table thead th:nth-child(1){
    pointer-events: none;
}
</style>