
<script>
import { required } from "vuelidate/lib/validators";
import config from "../../config";
import { success_message, get_user, error_message } from "../../Helper/helper";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { pageService } from "../../services";

export default {
    components: {config,ckeditor: CKEditor.component,pageService},
    data() {
        return {
        config: config,
        editor: ClassicEditor,
        typeform: {
            page_name: "",
            page_title: "",
            page_content: "",
            meta_tag:"",
            keywords:"",
            meta_description:"",
            page_name_hindi:"",
            page_title_hindi:"",
            page_content_hindi:""
        },        
        metaTitleWarning: false,
        metaDescriptionWarning: false,
        metaKeywordsWarning: false,
        };
    },
    validations: {
        typeform: {
        page_name: {
            required,
        },
        page_title: {
            required,
        },
        page_content: {
            required,
        },
        page_name_hindi: {
            required,
        },
        page_title_hindi: {
            required
        },
        page_content_hindi: {
            required
        }
        },
    },
    mounted(){
        this.getContentPageById();
        this.vendor_id = get_user().vendor_id || 40818;
    },
    methods: {
        getContentPageById(){
            pageService.getContentPageById({id: this.$route.params.id})
                .then(response => {
                    const { page_name, page_title, page_content, page_name_hindi, page_title_hindi, page_content_hindi, meta_tag, keywords, meta_description, id, vendor_id} = response.data.result;
                    this.typeform = { page_name, page_title, page_content, page_name_hindi, page_title_hindi, page_content_hindi, meta_tag, keywords, meta_description, id, vendor_id }
            });
        },
        editPage(e){
        this.$v.$touch();
        this.checkMetaKeywordsLength();
        this.checkMetaDescriptionLength();
        this.checkMetaTagLength();
        if (this.$v.typeform.$invalid || this.metaTitleWarning || this.metaDescriptionWarning || this.metaKeywordsWarning) {
            return;
        }
        else{       
            pageService.editContentPage(this.typeform)
            .then(response => {
                if (response.data.code === 200) {
                    success_message(response.data.message);
                    //this.$router.push({ name: "content-list" });
                } else {
                    error_message(response.data.message);
                }
            });
        }
        },
        checkMetaTagLength() {
          this.metaTitleWarning = !this.typeform.meta_tag || this.typeform.meta_tag.length < 50 || this.typeform.meta_tag.length > 60;
        },
        checkMetaDescriptionLength() {
            this.metaDescriptionWarning = !this.typeform.meta_description || this.typeform.meta_description.length < 150 || this.typeform.meta_description.length > 160;
        },
        checkMetaKeywordsLength() {
            this.metaKeywordsWarning = !this.typeform.keywords || this.typeform.keywords.split(' ').length > 1;
        },
    },
};
</script>

<template>
    <form @submit.prevent="editPage">
        <div class="card">
            <div class="card-body">
                <p class="card-title-desc"></p>
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label class="required">{{ trans.trans('__JSON__.Page Name') }}</label>
                            <input
                                id="page_name"
                                v-model="$v.typeform.page_name.$model"
                                type="text"
                                class="form-control"
                                :placeholder="trans.trans('__JSON__.Page Name')"
                                name="page_name"
                                :class="{ 'is-invalid': $v.typeform.page_name.$error }"
                            />
                            <div v-if="$v.typeform.page_name.$error" class="invalid-feedback">
                                <span v-if="!$v.typeform.page_name.required">{{ trans.get('__JSON__.Please Enter Page Name') }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label class="required">{{ trans.trans('__JSON__.Page Name') }} (Hindi)</label>
                            <input
                                id="page_name_hindi"
                                v-model="$v.typeform.page_name_hindi.$model"
                                type="text"
                                class="form-control"
                                :placeholder="trans.trans('__JSON__.Page Name')"
                                name="page_name_hindi"
                                :class="{ 'is-invalid': $v.typeform.page_name_hindi.$error }"
                            />
                            <div v-if="$v.typeform.page_name_hindi.$error" class="invalid-feedback">
                                <span v-if="!$v.typeform.page_name_hindi.required">{{ trans.get('__JSON__.Please Enter Page Name Hindi') }}</span>
                            </div>
                        </div>
                    </div>        
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label class="required">{{ trans.trans('__JSON__.Page Title') }}</label>
                            <input
                                id="page_title"
                                v-model="$v.typeform.page_title.$model"
                                type="text"
                                class="form-control"
                                :placeholder="trans.trans('__JSON__.Page Title')"
                                name="page_title"
                                :class="{ 'is-invalid': $v.typeform.page_title.$error }"
                            />
                            <div v-if="$v.typeform.page_title.$error" class="invalid-feedback">
                                <span v-if="!$v.typeform.page_title.required">{{ trans.get('__JSON__.Please Enter Page Title') }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label class="required">{{ trans.trans('__JSON__.Page Title') }} (Hindi)</label>
                            <input
                                id="page_title_hindi"
                                v-model="$v.typeform.page_title_hindi.$model"
                                type="text"
                                class="form-control"
                                :placeholder="trans.trans('__JSON__.Page Title')"
                                name="page_title_hindi"
                                :class="{ 'is-invalid': $v.typeform.page_title_hindi.$error }"
                            />
                            <div v-if="$v.typeform.page_title_hindi.$error" class="invalid-feedback">
                                <span v-if="!$v.typeform.page_title_hindi.required">{{ trans.get('__JSON__.Please Enter Page Title Hindi') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="form-group" v-if="$auth.setting">
                            <label class="required">{{ trans.trans('__JSON__.Page Content') }}</label>
                            <ckeditor
                                id="page_content"
                                v-model="$v.typeform.page_content.$model"
                                type="text"
                                class="form-control"
                                :placeholder="trans.trans('__JSON__.Page Name')"
                                name="page_content"
                                :editor="editor"
                                :class="{ 'is-invalid': $v.typeform.page_content.$error }"
                            ></ckeditor>
                            <div v-if="$v.typeform.page_content.$error" class="invalid-feedback">
                                <span v-if="!$v.typeform.page_content.required">{{ trans.get('__JSON__.Please Enter Page Content') }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <div class="form-group" v-if="$auth.setting">
                            <label class="required">{{ trans.trans('__JSON__.Page Content') }} (Hindi)</label>
                            <ckeditor
                                id="page_content_hindi"
                                v-model="$v.typeform.page_content_hindi.$model"
                                type="text"
                                class="form-control"
                                :placeholder="trans.trans('__JSON__.Page Name')"
                                name="page_content_hindi"
                                :editor="editor"
                                :class="{ 'is-invalid': $v.typeform.page_content_hindi.$error }"
                            ></ckeditor>
                            <div v-if="$v.typeform.page_content_hindi.$error" class="invalid-feedback">
                                <span v-if="!$v.typeform.page_content_hindi.required">{{ trans.get('__JSON__.Please Enter Page Content Hindi') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-sm-6">
                      <div class="formgroup">
                        <label class="required">Meta Tag</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="typeform.meta_tag"
                          @input="checkMetaTagLength"
                          :class="{ 'is-invalid': metaTitleWarning}"
                        />
                        <small v-if="metaTitleWarning" class="text-danger">
                          Meta Title should be between 50-60 characters.
                        </small>
                        <small class="text-muted">{{ typeform.meta_tag && typeform.meta_tag.length }}/60</small>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="formgroup">
                        <label class="required">Meta Description</label>
                        <textarea
                          class="form-control"
                          v-model="typeform.meta_description"
                          @input="checkMetaDescriptionLength"
                          rows="4"
                          :class="{ 'is-invalid': metaDescriptionWarning}"
                        ></textarea>
                        <small v-if="metaDescriptionWarning" class="text-danger">
                          Meta Description should be between 150-160 characters.
                        </small>
                        <small class="text-muted">{{ typeform.meta_description && typeform.meta_description.length }}/160</small>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="formgroup">
                        <label class="required">Meta Keywords</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="typeform.keywords"
                          @input="checkMetaKeywordsLength"
                          :class="{ 'is-invalid': metaKeywordsWarning}"
                        />
                        <small v-if="metaKeywordsWarning" class="text-danger">
                          Meta Keywords should be one word.
                        </small>
                        <small class="text-muted">{{ typeform.keywords && typeform.keywords.length }}/1</small>
                      </div>
                    </div>
                  </div>
            </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <button class="btn btn-primary mr-1 mt-3">{{ trans.get('__JSON__.Edit Content Page') }}</button>
            <b-button @click="$router.back()" type="button" variant="secondary" class="mt-3">{{ trans.get('__JSON__.Cancel') }}</b-button>
          </div>
        </div>
    </form>
</template>