<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";

import CityLocationList from "./city-location-list";
/**
 * City component
 */
export default {
  components: { Layout, PageHeader, CityLocationList },
};
</script>

<template>
  <Layout>
    <city-location-list />
  </Layout>
</template>
