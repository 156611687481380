
<script>
import vue2Dropzone from 'vue2-dropzone'
import { cuisineService } from "../../services";
import { required } from "vuelidate/lib/validators";
import config from "../../config";
import { error_message } from "../../Helper/helper";
import { success_message } from "../../Helper/helper";
import DropzoneSingle from '../../components/widgets/dropzone-single';
import DropzonePopup from "../../components/widgets/dropzone-popup";

export default {
  components: { vueDropzone: vue2Dropzone, DropzoneSingle, DropzonePopup },
  data() {
    return {
      file1: null,
      tmpCuisineImage: '',
      url: null,
      config: config,
      error: "",
      typeform: {
        cuisine_name: "",
        cuisine_name_other_lang: "",
        cuisine_desc: "",
        cuisine_desc_thai: "",
        cuisine_active: "1",
        image: null,
      },
    };
  },
  validations: {
    typeform: {
      cuisine_name: {
        required,
      },
      cuisine_desc: {
        required,
      }
      // ,
      // image: {
      //   required,
      // },
    },
  },
  mounted(){
  },
  methods: {
    onFileChange(e) {
      const file = e.target.files[0];
      this.url = URL.createObjectURL(file);
      this.typeform.image = file;
    },
    cuisineInIt() {
      // stop here if form is invalid
      this.$v.typeform.$touch();

      if (this.$v.typeform.$invalid) {
        return;
      } else {
        const fd = new FormData();
        fd.append("image", this.typeform.image);
        fd.append("cuisine_name", this.typeform.cuisine_name);
        fd.append(
          "cuisine_name_other_lang",
          this.typeform.cuisine_name_other_lang
        );
        fd.append("cuisine_description", this.typeform.cuisine_desc);
        fd.append("cuisine_desc_thai", this.typeform.cuisine_desc_thai);
        fd.append("cuisine_active", this.typeform.cuisine_active);
        cuisineService.addCuisine(fd).then((response) => {
          if (response.data.code === 200) {
            success_message(response.data.message);
            this.$router.push({ name: "cuisines-list" });
          } else {
            error_message(response.data.message);
          }
        });
      }
    },
  },
};
</script>

<template>
  <form @submit.prevent="cuisineInIt">
    <!-- Cuisine Info Start -->
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">{{ trans.get('__JSON__.Cuisine Info',{cuisine: $auth.setting.cuisine_name}) }}</h4>
        <p class="card-title-desc"></p>
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group" v-if="$auth.setting">
              <label class="required">{{ trans.trans('__JSON__.Cuisine Name Language', { cuisine: $auth.setting.cuisine_name,lang: $auth.langauges[0].name }) }}</label>
              <input
                id="cuisine_name"
                v-model="$v.typeform.cuisine_name.$model"
                type="text"
                class="form-control"
                :placeholder="trans.trans('__JSON__.Cuisine Name Language', { cuisine: $auth.setting.cuisine_name,lang: $auth.langauges[0].name })"
                name="cuisine_name"
                :class="{ 'is-invalid': $v.typeform.cuisine_name.$error }"
              />
              <div v-if="$v.typeform.cuisine_name.$error" class="invalid-feedback">
                <span v-if="!$v.typeform.cuisine_name.required">{{ trans.trans('__JSON__.Please Enter Cuisine Name Error',{cuisine: $auth.setting.cuisine_name}) }}</span>
              </div>
            </div>
          </div>
          <div class="col-sm-6" v-if="$auth.langauges[1].name != 'No Secondary Language'">
            <div class="form-group">
              <label>{{ trans.trans('__JSON__.Cuisine Name Language', { cuisine: $auth.setting.cuisine_name_sec_lang,lang: $auth.langauges[1].name }) }}</label>
              <input
                id="cuisine_name_other_lang"
                name="cuisine_name_other_lang"
                type="text"
                v-model="typeform.cuisine_name_other_lang"
                :placeholder="trans.trans('__JSON__.Cuisine Name Language', { cuisine: $auth.setting.cuisine_name_sec_lang,lang: $auth.langauges[1].name })"
                class="form-control"
              />
              </div>
          </div>

          <div class="col-sm-6">
            <div class="form-group" v-if="$auth.setting">
                <label class="required">{{ trans.trans('__JSON__.Cuisine Description Language', { cuisine: $auth.setting.cuisine_name,lang: $auth.langauges[0].name }) }}</label>
                <input
                  rows="5"
                  id="cuisine_desc"
                  name="cuisine_desc"
                  type="text"
                  v-model="$v.typeform.cuisine_desc.$model"
                  :placeholder="trans.trans('__JSON__.Cuisine Description Language', { cuisine: $auth.setting.cuisine_name,lang: $auth.langauges[0].name })"
                  class="form-control"
                  :class="{ 'is-invalid': $v.typeform.cuisine_desc.$error }"
                />
                <div v-if="$v.typeform.cuisine_desc.$error" class="invalid-feedback">
                <span
                  v-if="!$v.typeform.cuisine_desc.required"
                >{{ trans.trans('__JSON__.Please Enter Cuisine Description',{ cuisine: $auth.setting.cuisine_name}) }}.</span>
              </div>
               </div>
          </div>
          <div class="col-sm-6" v-if="$auth.langauges[1].name != 'No Secondary Language'">
            <div class="form-group">
                <label>{{ trans.trans('__JSON__.Cuisine Description Language',{cuisine: $auth.setting.cuisine_name_sec_lang,lang: $auth.langauges[1].name }) }}</label>
                <input
                  rows="5"
                  id="cuisine_desc_thai"
                  name="cuisine_desc_thai"
                  type="text"
                  v-model="typeform.cuisine_desc_thai"
                  :placeholder="trans.trans('__JSON__.Cuisine Description Language',{cuisine: $auth.setting.cuisine_name_sec_lang,lang: $auth.langauges[0].name })"
                  class="form-control"
                />
              </div>
          </div>

        <div class="col-md-6 vendor-status">
            <b-form-group
              id="input-group-2"
              :label="trans.get('__JSON__.Status')"
              label-for="Status"
            >
              <div class="row">
                <div class="col-sm-6">
                  <div class="swtich-data">
                    <label class="switch">
                      <input
                        type="checkbox"
                        id="togBtn"
                        class="switch-on"
                        name="cuisine_active"
                        v-model="typeform.cuisine_active"
                        true-value="1"
                        false-value="0"
                      />
                      <div class="slider round">
                        <!--ADDED HTML -->
                        <span class="on">{{ trans.get('__JSON__.Active') }}</span>
                        <span class="off">{{ trans.get('__JSON__.Inactive') }}</span>
                        <!--END-->
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </b-form-group>
          </div>
        </div>
      </div>
    </div>
    <!-- Cuisine Info End -->

    <!-- Cuisine Image Start -->
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">{{ trans.get('__JSON__.Cuisine Image',{cuisine: $auth.setting.cuisine_name}) }}</h4>
        <p class="card-title-desc"></p>

        <div class="row">
          <div class="col-sm-3">
           <dropzone-popup
              v-model="typeform.image"
              type="file"
              id="cuisine_image"
              name="image"
              acceptedFiles=".jpeg,.jpg,.png,.webp,image/webp"
            />
            <!-- :class="{ 'is-invalid': $v.typeform.image.$error }" -->
            <!-- <div v-if="$v.typeform.image.$error" class="invalid-feedback cuisine-img">
              <span
                v-if="!$v.typeform.image.required"
              >{{ trans.get('__JSON__.Please Select Cuisine Image') }}.</span>
            </div> -->
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <button class="btn btn-primary mr-1 mt-3">{{ trans.get('__JSON__.Add Cuisines',{cuisine: $auth.setting.cuisine_name}) }}</button>
            <b-button @click="$router.back()" type="button" variant="secondary" class="mt-3">{{ trans.get('__JSON__.Cancel') }}</b-button>
          </div>
        </div>
      </div>
    </div>
    <!-- Cuisine Image End -->
  </form>
</template>

<style>
</style>