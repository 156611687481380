<script>
import { get_currency, get_decimal, order_statuses } from '../../../Helper/helper';
import OrderImages from './order-images';
import OrderInvoice from './order-invoice'
import config from '../../../config';
import { orderService } from '../../../services'
export default {
  components : {OrderImages, OrderInvoice},
  props:['order','loading'],
  data(){
    return {
      config:config,
      statuses: order_statuses,
      currency: get_currency(),
      decimal: get_decimal(),
      newOrderArray: [],
      item_ids: [],
      items:[],
      changed_mrp:'',
      discounted_price:'',
      enableupdatedmrp:false,
      quantity_count: 0,
      sentToStoreLoader:false,
      remove_item: {
        is_langauge:"en",
        depot_sub_total:0,
        depot_mrp_sub_total:0,
        order_id:0,
        depot_discount_price:0,
        depot_total_amount:0,
        depot_saved_amount:0,
        remaining_amount:0,
        original_price:0,
        sub_total:0,
        mrp_sub_total:0,
        wallet_amount:0,
        vendor_id:0,
        total_amount:0,
        depot_remaining_amount:0,
        store_has_item:0,
        saved_amount:0,
        discount_price:0,
        depot_wallet_amount:0,
        depot_service_fee:0,
        restaurant_id:0,
        item_id:"",
        depot_original_price:0,
        service_fee:0,
        refund_amount:0,
        device_type:'Admin'
      },
      remove_items: {
        order_id: 0,
        restaurant_id: 0,
        vendor_id: 0,
        items: []
      },
      update_items: {
        order_id: 0,
        restaurant_id: 0,
        vendor_id: 0,
        items: []
      },
      allItem: [],
      disableSubmit:false,
      disableSubmitMRP:false,
      old_mrp:'',
      old_price:'',
      unchanged_itemarray: []
    }
  },
  watch:{
    "order": function(){
      this.generateRemoveItemObj();
    }
  },
  methods:{
    getSelections(items){
      return items.length > 0 ? items.reduce((accu, item) => {
        return accu.concat(item.selection_name +' x '+ parseFloat(item.selection_price).toFixed(this.decimal));
      },[]).join(', '): '';
    },
    getSelectionPrices(items){
      return parseFloat(items.length > 0 ? items.reduce(function(accu, item){
        return accu + parseFloat(item.selection_price);
      },0): 0);
    },
    mrpPrice(item){
      return parseFloat((parseInt(item.quantity) * this.getSelectionPrices(item.menu_item_selections)) + (parseInt(item.quantity) * parseFloat(item.item_mrp || 0)))
    },
    totalPrice(item){
      return parseFloat((parseInt(item.quantity) * this.getSelectionPrices(item.menu_item_selections)) + (parseInt(item.quantity) * parseFloat(item.item_price || 0)))
    },
    discountPrice(item){
      var itemp = parseFloat((this.getSelectionPrices(item.menu_item_selections)) + (parseFloat(item.item_price || 0)));
      var itemmrp = parseFloat((this.getSelectionPrices(item.menu_item_selections)) + (parseFloat(item.item_mrp || 0)));
      var discoutpercentage = (itemp/itemmrp) * 100;
      var itemdis = (discoutpercentage * itemmrp)/100;
      return (itemp !== itemmrp ? itemdis.toFixed(this.decimal) : ' - ');
      //return (this.mrpPrice(item).toFixed(this.decimal) == this.totalPrice(item).toFixed(this.decimal) ? ' - ' : (this.mrpPrice(item) - this.totalPrice(item)).toFixed(this.decimal))
    },

    discountPriceNew(item){
      this.update_items.order_id = this.order.id;
      this.update_items.restaurant_id = this.order.restaurant_id;
      this.update_items.vendor_id = this.$auth.getVendorId();
      this.enableupdatedmrp = true;
      var discoutpercentage = (parseFloat(item.item_price)/parseFloat(item.item_mrp)) * 100;
      var itemdis = (discoutpercentage * parseFloat(item.item_mrp))/100;
      if(this.changed_mrp){
        if(this.changed_mrp != item.item_mrp){
          this.itemdis = ((discoutpercentage * this.changed_mrp)/100).toFixed(2);
        this.update_items.items.push({item_id: item.id, item_price: parseFloat(this.itemdis), item_mrp: parseFloat(this.changed_mrp)})
        this.update_items.items = this.update_items.items.filter((e) => e.item_id != item.id)
        this.update_items.items.push({item_id: item.id, item_price: parseFloat(this.itemdis), item_mrp: parseFloat(this.changed_mrp)})
        // console.log(this.update_items.items, "this.update_items.items");
        this.discounted_price = parseFloat(this.itemdis);
        // console.log(this.order.order_item, "discout order item");
        var index = this.newOrderArray.map(e => e.id).indexOf(item.id);
        this.newOrderArray[index].item_mrp = this.changed_mrp;
        this.newOrderArray[index].item_price = this.itemdis;
        this.newOrderArray[index].quantity = this.$refs.fileInput[index].value;
        }
      }
      return (item.item_price !== item.item_mrp ? itemdis.toFixed(this.decimal) : ' - ');
    },
    generateRemoveItemObj() {
      // console.log('order:- ', this.order);
      if (this.order && this.order.id && this.order.id > 0) {
        if (this.order.order_status == "Placed") {
          this.remove_item.order_id = this.order.id;
          this.remove_items.order_id = this.order.id;
          this.remove_item.restaurant_id = this.order.restaurant_id;
          this.remove_items.restaurant_id = this.order.restaurant_id;
          this.remove_item.vendor_id = this.$auth.getVendorId();
          this.remove_items.vendor_id = this.$auth.getVendorId();
          //is_depot_store, 0:Store,1:Depot
          if (this.order.is_depot_store == 1) {
            this.remove_item.depot_service_fee = parseFloat(this.order.main_service_fee) ?? 0;
            this.remove_item.depot_sub_total = parseFloat(this.order.main_sub_total) ?? 0;
            this.remove_item.depot_mrp_sub_total = parseFloat(this.order.mrp_sub_total) ?? 0;
            this.remove_item.depot_total_amount = parseFloat(this.order.main_total_amount) ?? 0;
            this.remove_item.depot_saved_amount = parseFloat(this.order.main_saved_amount) ?? 0;
            this.remove_item.depot_remaining_amount = parseFloat(this.order.amount) ?? 0;
            this.remove_item.depot_wallet_amount = parseFloat(this.order.main_wallet_amount) ?? 0;
            this.remove_item.depot_discount_price = parseFloat(this.order.discount_price) ?? 0;
            this.remove_item.depot_original_price = parseFloat(this.order.original_price) ?? 0;
          } else if (this.order.is_depot_store == 0) {
            this.remove_item.service_fee = parseFloat(this.order.main_service_fee) ?? 0;
            this.remove_item.sub_total = parseFloat(this.order.main_sub_total) ?? 0;
            this.remove_item.mrp_sub_total = parseFloat(this.order.mrp_sub_total) ?? 0;
            this.remove_item.total_amount = parseFloat(this.order.main_total_amount) ?? 0;
            this.remove_item.saved_amount = parseFloat(this.order.main_saved_amount) ?? 0;
            this.remove_item.remaining_amount = parseFloat(this.order.amount) ?? 0;
            this.remove_item.wallet_amount = parseFloat(this.order.main_wallet_amount) ?? 0;
            this.remove_item.discount_price = parseFloat(this.order.discount_price) ?? 0;
            this.remove_item.original_price = parseFloat(this.order.original_price) ?? 0;
          }
          if (this.order.suborder && this.order.suborder.id && this.order.suborder.id > 0) {
            if (this.order.suborder.is_depot_store == 1) {
              this.remove_item.depot_service_fee = parseFloat(this.order.suborder.service_fee) ?? 0;
              this.remove_item.depot_sub_total = parseFloat(this.order.suborder.sub_total) ?? 0;
              this.remove_item.depot_mrp_sub_total = parseFloat(this.order.suborder.mrp_sub_total) ?? 0;
              this.remove_item.depot_total_amount = parseFloat(this.order.suborder.total_amount) ?? 0;
              this.remove_item.depot_saved_amount = parseFloat(this.order.suborder.saved_amount) ?? 0;
              this.remove_item.depot_remaining_amount = parseFloat(this.order.suborder.amount) ?? 0;
              this.remove_item.depot_wallet_amount = parseFloat(this.order.suborder.wallet_amount) ?? 0;
              this.remove_item.depot_discount_price = parseFloat(this.order.suborder.discount_price) ?? 0;
              this.remove_item.depot_original_price = parseFloat(this.order.suborder.original_price) ?? 0;
            } else if (this.order.suborder.is_depot_store == 0) {
              this.remove_item.service_fee = parseFloat(this.order.suborder.service_fee) ?? 0;
              this.remove_item.sub_total = parseFloat(this.order.suborder.sub_total) ?? 0;
              this.remove_item.mrp_sub_total = parseFloat(this.order.suborder.mrp_sub_total) ?? 0;
              this.remove_item.total_amount = parseFloat(this.order.suborder.total_amount) ?? 0;
              this.remove_item.saved_amount = parseFloat(this.order.suborder.saved_amount) ?? 0;
              this.remove_item.remaining_amount = parseFloat(this.order.suborder.amount) ?? 0;
              this.remove_item.wallet_amount = parseFloat(this.order.suborder.wallet_amount) ?? 0;
              this.remove_item.discount_price = parseFloat(this.order.suborder.discount_price) ?? 0;
              this.remove_item.original_price = parseFloat(this.order.suborder.original_price) ?? 0;
            }
          }
        }
      }
      // console.log('remove_item:- ', this.remove_item);
    },
    removeOrderItem(item, event) {
      // console.log(item, event);
      if (event) {
        this.disableSubmit = false;
          this.$emit('disableSubmit',this.disableSubmit);
        let hasMapItem = 0;
        let itemPrice = 0;
        let itemMrp = 0;
        let itemQty = 0;
        let couponDiscount = 0;
        let restaurantServiceFee = (this.order) ? (this.order.restaurant_service_fee ?? 0) : 0;
        if (item.menu_item && item.menu_item.master_menu_item_id && item.menu_item.master_menu_item_id > 0) {
          hasMapItem = 1;
        }
        if (this.order && this.order.reddemed_coupon_order) {
          couponDiscount = parseFloat((parseFloat(this.order.reddemed_coupon_order.original_price) - parseFloat(this.order.reddemed_coupon_order.discount_price)) || 0);
        }
        
        let isMasterDepotAvailable = 0;
        let isRestAvaiable = 0;
        let suborderitemcount = 0;
        let storeTotalitemCount = 0;
        if (this.order.is_depot_store == 1) {
          if (this.order.suborder && this.order.suborder.is_depot_store == 0 && this.order.suborder.order_item) {
            this.order.suborder.order_item.filter((c, index) => {
              // if (c.menu_item.master_menu_item_id > 0) {
              //   suborderitemcount++;
              // }
              if (c.menu_item) {
                suborderitemcount++;
              }
              if (c.menu_item) {
                storeTotalitemCount++;
              }
            });
            if (!event.target.checked && event.target.value && event.target.value > 0) {
              if (suborderitemcount !== (this.item_ids.length + 1)) {
                isMasterDepotAvailable = 1;
                isRestAvaiable = 1;
              } else {
                isMasterDepotAvailable = 1;
              }
            } else {
              if (suborderitemcount !== (this.item_ids.length - 1)) {
                isMasterDepotAvailable = 1;
                isRestAvaiable = 1;
              } else {
                isMasterDepotAvailable = 1;
              }
            }
          }
        } else {
          if (this.order && this.order.order_item) {
            this.order.order_item.filter((c, index) => {
              // if (c.menu_item.master_menu_item_id > 0) {
              //   suborderitemcount++;
              // }
              if (c.menu_item) {
                suborderitemcount++;
              }
              if (c.menu_item) {
                storeTotalitemCount++;
              }
            });
            if (!event.target.checked && event.target.value && event.target.value > 0) {
              if (suborderitemcount === (this.item_ids.length + 1)) {
                isMasterDepotAvailable = 1;
                isRestAvaiable = 0;
              } else {
                isRestAvaiable = 1;
              }
            } else {
              if (suborderitemcount === (this.item_ids.length - 1)) {
                isMasterDepotAvailable = 1;
                isRestAvaiable = 0;
              } else {
                isRestAvaiable = 1;
              }
            }
            if (isMasterDepotAvailable == 0 && hasMapItem == 1) {
              isMasterDepotAvailable = 1;
            }
          }
        }
        // console.log('suborderitemcount:- ', suborderitemcount);
        // console.log('storeTotalitemCount:- ', storeTotalitemCount);
        // console.log('couponDiscount:- ', couponDiscount);
        // console.log('isMasterDepotAvailable:- ', isMasterDepotAvailable);
        // console.log('isRestAvaiable:- ', isRestAvaiable);
        // console.log('hasMapItem:- ', hasMapItem);
        itemPrice = item.item_price ?? 0;
        itemMrp = item.item_mrp ?? 0;
        itemQty = item.quantity ?? 0;
        if (!event.target.checked && event.target.value && event.target.value > 0) {
          let newdepotsubtotal = parseFloat(parseFloat(itemPrice) * itemQty).toFixed(2);
          let newdepotsubtotalmrp = parseFloat(parseFloat(itemMrp) * itemQty).toFixed(2);
          // console.log('newdepotsubtotalmrp:- ', newdepotsubtotalmrp);
          // console.log('newdepotsubtotal:- ', newdepotsubtotal);
          let newdepotsavedamount = parseFloat(parseFloat(parseFloat(itemMrp) * itemQty) - parseFloat(parseFloat(itemPrice) * itemQty)).toFixed(2);
          // console.log('newdepotsavedamount:- ', newdepotsavedamount);
          if (hasMapItem == 1) {
            // console.log(this.remove_item.depot_sub_total, newdepotsubtotal);
            this.remove_item.depot_mrp_sub_total = parseFloat(parseFloat(this.remove_item.depot_mrp_sub_total) + parseFloat(newdepotsubtotalmrp)).toFixed(2);
            this.remove_item.depot_sub_total = parseFloat(parseFloat(this.remove_item.depot_sub_total) + parseFloat(newdepotsubtotal)).toFixed(2);
            // console.log('depot_sub_total:- ', this.remove_item.depot_sub_total);
            this.remove_item.depot_saved_amount = parseFloat(parseFloat(this.remove_item.depot_saved_amount) + parseFloat(newdepotsavedamount)).toFixed(2);
            // console.log('depot_saved_amount:- ', this.remove_item.depot_saved_amount);
            this.remove_item.depot_service_fee = parseFloat(parseFloat(parseFloat(this.remove_item.depot_sub_total) * parseFloat(restaurantServiceFee)) / 100).toFixed(2);
            // console.log('depot_service_fee:- ', this.remove_item.depot_service_fee);
          }
          // console.log(this.remove_item);
          // return false;
          this.remove_item.mrp_sub_total = parseFloat(parseFloat(this.remove_item.mrp_sub_total) - parseFloat(newdepotsubtotalmrp)).toFixed(2);
          this.remove_item.sub_total = parseFloat(parseFloat(this.remove_item.sub_total) - parseFloat(newdepotsubtotal)).toFixed(2);
          // console.log('sub_total:- ', this.remove_item.sub_total);
          this.remove_item.saved_amount = parseFloat(parseFloat(this.remove_item.saved_amount) - parseFloat(newdepotsavedamount)).toFixed(2);
          // console.log('saved_amount:- ', this.remove_item.saved_amount);
          this.remove_item.service_fee = parseFloat(parseFloat(parseFloat(this.remove_item.sub_total) * parseFloat(restaurantServiceFee)) / 100).toFixed(2);
          // console.log('service_fee:- ', this.remove_item.service_fee);

          // console.log(this.remove_item);
          // return false;
          
          //Total without order discount
          let restDepotOrderWithoutDiscount = parseFloat(parseFloat(this.remove_item.sub_total) + parseFloat(this.remove_item.depot_sub_total)).toFixed(2);
          // console.log('restDepotOrderWithoutDiscount:- ', restDepotOrderWithoutDiscount);
          let restOrderPerWithoutDiscount = (restDepotOrderWithoutDiscount <= 0) ? 0 : parseFloat(parseFloat(100 * parseFloat(this.remove_item.sub_total)) / restDepotOrderWithoutDiscount).toFixed(2);
          // console.log('restOrderPerWithoutDiscount:- ', restOrderPerWithoutDiscount);
          let depotOrderPerWithoutDiscount = (restDepotOrderWithoutDiscount <= 0) ? 0 : parseFloat(parseFloat(100 * parseFloat(this.remove_item.depot_sub_total)) / restDepotOrderWithoutDiscount).toFixed(2);
          // console.log('depotOrderPerWithoutDiscount:- ', depotOrderPerWithoutDiscount);

          // console.log(this.remove_item);
          // return false;

          //Final calculated discounted as per order percentage
          let totalWithItemRemovedPer = (restDepotOrderWithoutDiscount * 100) / this.order.sub_total;
          let newDiscountPrice = (couponDiscount * totalWithItemRemovedPer) / 100;
          let restDiscountAmount = parseFloat(parseFloat(restOrderPerWithoutDiscount * newDiscountPrice) / 100).toFixed(2);
          // console.log('restDiscountAmount:- ', restDiscountAmount);
          let depotDiscountAmount = parseFloat(newDiscountPrice - restDiscountAmount).toFixed(2);
          // console.log('depotDiscountAmount:- ', depotDiscountAmount);
          
          //Divide delivery charges if master and depot order
          let dividedDeliveryCharge = (isMasterDepotAvailable && isRestAvaiable) ? parseFloat(parseFloat(this.order.delivery_fee) / 2).toFixed(2) : parseFloat(this.order.delivery_fee).toFixed(2);
          // console.log('dividedDeliveryCharge:- ', dividedDeliveryCharge);
          // Calculate rest total amount, sub total and cashback
          // console.log('isRestAvaiable:- ', isRestAvaiable);
          if (isRestAvaiable) {
            this.remove_item.original_price = parseFloat(this.remove_item.sub_total) + parseFloat(this.remove_item.service_fee) + parseFloat(dividedDeliveryCharge);
            // console.log('original_price:- ', original_price);
            this.remove_item.total_amount = parseFloat(this.remove_item.sub_total) + parseFloat(this.remove_item.service_fee) + parseFloat(dividedDeliveryCharge) - parseFloat(restDiscountAmount);
            // console.log('total_amount:- ', this.remove_item.total_amount);
            this.remove_item.discount_price = parseFloat(this.remove_item.sub_total) + parseFloat(this.remove_item.service_fee) + parseFloat(dividedDeliveryCharge) - parseFloat(restDiscountAmount);
            // console.log('discount_price:- ', this.remove_item.discount_price);
          } else {
            this.remove_item.original_price = 0;
            this.remove_item.total_amount = 0;
            this.remove_item.discount_price = 0;
          }
          // console.log('remove_item:- ', this.remove_item);
          // return false;

          // If master order available then calculate this other wise all is 0
          // console.log('isMasterDepotAvailable:- ', isMasterDepotAvailable);
          if (isMasterDepotAvailable) {
            this.remove_item.depot_total_amount = parseFloat(this.remove_item.depot_sub_total) + parseFloat(this.remove_item.depot_service_fee) + parseFloat(dividedDeliveryCharge) - parseFloat(depotDiscountAmount);
            // console.log('depot_total_amount:- ', this.remove_item.depot_total_amount);
            this.remove_item.depot_original_price = parseFloat(this.remove_item.depot_sub_total) + parseFloat(this.remove_item.depot_service_fee) + parseFloat(dividedDeliveryCharge);
            // console.log('depot_original_price:- ', this.remove_item.depot_original_price);
            this.remove_item.depot_discount_price = parseFloat(this.remove_item.depot_sub_total) + parseFloat(this.remove_item.depot_service_fee) + parseFloat(dividedDeliveryCharge) - parseFloat(depotDiscountAmount);
            // console.log('depot_discount_price:- ', this.remove_item.depot_discount_price);
          } else {
            this.remove_item.depot_total_amount = 0;
            this.remove_item.depot_original_price = 0;
            this.remove_item.depot_discount_price = 0;
          }

          // console.log('remove_item:- ', this.remove_item);
          // return false;

          // Total amount of rest and depot with minus discount
          let restOrderTotal = parseFloat(this.remove_item.total_amount) + parseFloat(this.remove_item.depot_total_amount);
          // console.log('restOrderTotal:- ', restOrderTotal);
          
          // Percentage of restaurant order
          let restOrderPer = (restOrderTotal <= 0) ? 0 : parseFloat(100 * parseFloat(this.remove_item.total_amount)) / restOrderTotal;
          // console.log('restOrderPer:- ', restOrderPer);
          let depotOrderPer = (restOrderTotal <= 0) ? 0 : parseFloat(100 * parseFloat(this.remove_item.depot_total_amount)) / restOrderTotal;
          // console.log('depotOrderPer:- ', depotOrderPer);

          // Final used wallet amount after compare with order amount
          let finalUsedWalletAmount = (parseFloat(this.order.wallet_amount) >= restOrderTotal) ? parseFloat(restOrderTotal) : parseFloat(this.order.wallet_amount);
          // console.log('finalUsedWalletAmount:- ', finalUsedWalletAmount);

          if (finalUsedWalletAmount == restOrderTotal){
            this.remove_item.wallet_amount = parseFloat(this.remove_item.total_amount).toFixed(2);
            // console.log('wallet_amount:- ', this.remove_item.wallet_amount);
            this.remove_item.depot_wallet_amount = parseFloat(this.remove_item.depot_total_amount).toFixed(2);
            // console.log('depot_wallet_amount:- ', this.remove_item.depot_wallet_amount);
          } else {
            // Get Wallet amount as per restaurant and depot amount percentage
            this.remove_item.wallet_amount = (parseFloat(restOrderPer * finalUsedWalletAmount) / 100);
            // console.log('else wallet_amount:- ', this.remove_item.wallet_amount);
            this.remove_item.depot_wallet_amount = (parseFloat(depotOrderPer * finalUsedWalletAmount) / 100);
            // console.log('else depot_wallet_amount:- ', this.remove_item.depot_wallet_amount);
          }

          //Order remain amount after use wallet and discount
          this.remove_item.remaining_amount = (parseFloat(this.remove_item.total_amount) - parseFloat(this.remove_item.wallet_amount));
          // console.log('remaining_amount:- ',this.remove_item.remaining_amount);
          this.remove_item.depot_remaining_amount = (parseFloat(this.remove_item.depot_total_amount) - parseFloat(this.remove_item.depot_wallet_amount));
          // console.log('depot_remaining_amount:- ',this.remove_item.depot_remaining_amount);

          //Refund
          // this.remove_item.refund_amount = (parseFloat(this.order.total_amount) - (parseFloat(this.remove_item.remaining_amount) + parseFloat(this.remove_item.depot_remaining_amount)));
          // console.log('total_amount:- ',this.order.total_amount);
          // console.log('remove_item store total_amount:- ',this.remove_item.total_amount);
          // console.log('remove_item depot total_amount:- ',this.remove_item.depot_total_amount);
          this.remove_item.refund_amount = (parseFloat(this.order.total_amount) - (parseFloat(this.remove_item.total_amount) + parseFloat(this.remove_item.depot_total_amount)));
          // console.log('refund_amount:- ',this.remove_item.refund_amount);
          
          this.item_ids.push(event.target.value);
        } else {
          // console.log('hasMapItem:- ', hasMapItem);
          let newdepotsubtotal = parseFloat(itemPrice * itemQty);
          let newdepotsubtotalmrp = parseFloat(itemMrp * itemQty);
          let newdepotsavedamount = parseFloat(parseFloat(itemMrp * itemQty) - parseFloat(itemPrice * itemQty));
          if (hasMapItem == 1) {
            this.remove_item.depot_mrp_sub_total = parseFloat(this.remove_item.depot_mrp_sub_total - newdepotsubtotalmrp);
            this.remove_item.depot_sub_total = parseFloat(this.remove_item.depot_sub_total - newdepotsubtotal);
            this.remove_item.depot_saved_amount = parseFloat(this.remove_item.depot_saved_amount - newdepotsavedamount);
            this.remove_item.depot_service_fee = parseFloat(parseFloat(this.remove_item.depot_sub_total * restaurantServiceFee) / 100);
          }

          // console.log(this.remove_item);
          // return false;
          this.remove_item.mrp_sub_total = parseFloat(parseFloat(this.remove_item.mrp_sub_total) + parseFloat(newdepotsubtotalmrp)).toFixed(2);
          this.remove_item.sub_total = parseFloat(parseFloat(this.remove_item.sub_total) + parseFloat(newdepotsubtotal)).toFixed(2);
          // console.log('sub_total:- ', this.remove_item.sub_total);
          this.remove_item.saved_amount = parseFloat(parseFloat(this.remove_item.saved_amount) + parseFloat(newdepotsavedamount)).toFixed(2);
          // console.log('saved_amount:- ', this.remove_item.saved_amount);
          this.remove_item.service_fee = parseFloat(parseFloat(parseFloat(this.remove_item.sub_total) * parseFloat(restaurantServiceFee)) / 100).toFixed(2);
          // console.log('service_fee:- ', this.remove_item.service_fee);

          // console.log(this.remove_item);
          // return false;
          
          //Total without order discount
          let restDepotOrderWithoutDiscount = parseFloat(parseFloat(this.remove_item.sub_total) + parseFloat(this.remove_item.depot_sub_total)).toFixed(2);
          // console.log('restDepotOrderWithoutDiscount:- ', restDepotOrderWithoutDiscount);
          let restOrderPerWithoutDiscount = (restDepotOrderWithoutDiscount <= 0) ? 0 : parseFloat(parseFloat(100 * parseFloat(this.remove_item.sub_total)) / restDepotOrderWithoutDiscount).toFixed(2);
          // console.log('restOrderPerWithoutDiscount:- ', restOrderPerWithoutDiscount);
          let depotOrderPerWithoutDiscount = (restDepotOrderWithoutDiscount <= 0) ? 0 : parseFloat(parseFloat(100 * parseFloat(this.remove_item.depot_sub_total)) / restDepotOrderWithoutDiscount).toFixed(2);
          // console.log('depotOrderPerWithoutDiscount:- ', depotOrderPerWithoutDiscount);

          // console.log(this.remove_item);
          // return false;

          //Final calculated discounted as per order percentage
          let totalWithItemRemovedPer = (restDepotOrderWithoutDiscount * 100) / this.order.sub_total;
          let newDiscountPrice = (couponDiscount * totalWithItemRemovedPer) / 100;
          let restDiscountAmount = parseFloat(parseFloat(restOrderPerWithoutDiscount * newDiscountPrice) / 100).toFixed(2);
          // console.log('restDiscountAmount:- ', restDiscountAmount);
          let depotDiscountAmount = parseFloat(newDiscountPrice - restDiscountAmount).toFixed(2);
          // console.log('depotDiscountAmount:- ', depotDiscountAmount);
          
          //Divide delivery charges if master and depot order
          let dividedDeliveryCharge = (isMasterDepotAvailable && isRestAvaiable) ? parseFloat(parseFloat(this.order.delivery_fee) / 2).toFixed(2) : parseFloat(this.order.delivery_fee).toFixed(2);
          // console.log('dividedDeliveryCharge:- ', dividedDeliveryCharge);
          // Calculate rest total amount, sub total and cashback
          // console.log('isRestAvaiable:- ', isRestAvaiable);
          if (isRestAvaiable) {
            this.remove_item.original_price = parseFloat(this.remove_item.sub_total) + parseFloat(this.remove_item.service_fee) + parseFloat(dividedDeliveryCharge);
            // console.log('original_price:- ', original_price);
            this.remove_item.total_amount = parseFloat(this.remove_item.sub_total) + parseFloat(this.remove_item.service_fee) + parseFloat(dividedDeliveryCharge) - parseFloat(restDiscountAmount);
            // console.log('total_amount:- ', this.remove_item.total_amount);
            this.remove_item.discount_price = parseFloat(this.remove_item.sub_total) + parseFloat(this.remove_item.service_fee) + parseFloat(dividedDeliveryCharge) - parseFloat(restDiscountAmount);
            // console.log('discount_price:- ', this.remove_item.discount_price);
          } else {
            this.remove_item.original_price = 0;
            this.remove_item.total_amount = 0;
            this.remove_item.discount_price = 0;
          }
          // console.log('remove_item:- ', this.remove_item);
          // return false;

          // If master order available then calculate this other wise all is 0
          // console.log('isMasterDepotAvailable:- ', isMasterDepotAvailable);
          if (isMasterDepotAvailable) {
            this.remove_item.depot_total_amount = parseFloat(this.remove_item.depot_sub_total) + parseFloat(this.remove_item.depot_service_fee) + parseFloat(dividedDeliveryCharge) - parseFloat(depotDiscountAmount);
            // console.log('depot_total_amount:- ', this.remove_item.depot_total_amount);
            this.remove_item.depot_original_price = parseFloat(this.remove_item.depot_sub_total) + parseFloat(this.remove_item.depot_service_fee) + parseFloat(dividedDeliveryCharge);
            // console.log('depot_original_price:- ', this.remove_item.depot_original_price);
            this.remove_item.depot_discount_price = parseFloat(this.remove_item.depot_sub_total) + parseFloat(this.remove_item.depot_service_fee) + parseFloat(dividedDeliveryCharge) - parseFloat(depotDiscountAmount);
            // console.log('depot_discount_price:- ', this.remove_item.depot_discount_price);
          } else {
            this.remove_item.depot_total_amount = 0;
            this.remove_item.depot_original_price = 0;
            this.remove_item.depot_discount_price = 0;
          }

          // console.log('remove_item:- ', this.remove_item);
          // return false;

          // Total amount of rest and depot with minus discount
          let restOrderTotal = parseFloat(this.remove_item.total_amount) + parseFloat(this.remove_item.depot_total_amount);
          // console.log('restOrderTotal:- ', restOrderTotal);
          
          // Percentage of restaurant order
          let restOrderPer = (restOrderTotal <= 0) ? 0 : parseFloat(100 * parseFloat(this.remove_item.total_amount)) / restOrderTotal;
          // console.log('restOrderPer:- ', restOrderPer);
          let depotOrderPer = (restOrderTotal <= 0) ? 0 : parseFloat(100 * parseFloat(this.remove_item.depot_total_amount)) / restOrderTotal;
          // console.log('depotOrderPer:- ', depotOrderPer);

          // Final used wallet amount after compare with order amount
          let finalUsedWalletAmount = (parseFloat(this.order.wallet_amount) >= restOrderTotal) ? parseFloat(restOrderTotal) : parseFloat(this.order.wallet_amount);
          // console.log('finalUsedWalletAmount:- ', finalUsedWalletAmount);

          if (finalUsedWalletAmount == restOrderTotal){
            this.remove_item.wallet_amount = parseFloat(this.remove_item.total_amount).toFixed(2);
            // console.log('wallet_amount:- ', this.remove_item.wallet_amount);
            this.remove_item.depot_wallet_amount = parseFloat(this.remove_item.depot_total_amount).toFixed(2);
            // console.log('depot_wallet_amount:- ', this.remove_item.depot_wallet_amount);
          } else {
            // Get Wallet amount as per restaurant and depot amount percentage
            this.remove_item.wallet_amount = (parseFloat(restOrderPer * finalUsedWalletAmount) / 100);
            // console.log('else wallet_amount:- ', this.remove_item.wallet_amount);
            this.remove_item.depot_wallet_amount = (parseFloat(depotOrderPer * finalUsedWalletAmount) / 100);
            // console.log('else depot_wallet_amount:- ', this.remove_item.depot_wallet_amount);
          }

          //Order remain amount after use wallet and discount
          this.remove_item.remaining_amount = (parseFloat(this.remove_item.total_amount) - parseFloat(this.remove_item.wallet_amount));
          // console.log('remaining_amount:- ',this.remove_item.remaining_amount);
          this.remove_item.depot_remaining_amount = (parseFloat(this.remove_item.depot_total_amount) - parseFloat(this.remove_item.depot_wallet_amount));
          // console.log('depot_remaining_amount:- ',this.remove_item.depot_remaining_amount);

          //Refund
          // this.remove_item.refund_amount = (parseFloat(this.order.total_amount) - (parseFloat(this.remove_item.remaining_amount) + parseFloat(this.remove_item.depot_remaining_amount)));
          // console.log('total_amount:- ',this.order.total_amount);
          // console.log('remove_item store total_amount:- ',this.remove_item.total_amount);
          // console.log('remove_item depot total_amount:- ',this.remove_item.depot_total_amount);
          this.remove_item.refund_amount = (parseFloat(this.order.total_amount) - (parseFloat(this.remove_item.total_amount) + parseFloat(this.remove_item.depot_total_amount)));
          
          this.item_ids.splice(this.item_ids.indexOf(event.target.value), 1);
        }

        this.remove_item.item_id = this.item_ids.join(',');

        // console.log(storeTotalitemCount, this.item_ids.length);
        if (storeTotalitemCount == this.item_ids.length) {
          this.remove_item.store_has_item = 0;
        } else {
          this.remove_item.store_has_item = 1;
        }
        // console.log('item_ids:- ', this.item_ids);
        // console.log('remove_item:- ',this.remove_item);
        // console.log('item_ids str:- ', this.item_ids.join(','));
       
        if(!event.target.checked){
          var index1 = this.order.order_item.map(e => e.id).indexOf(item.id);
          this.allItem.push({item_id: event.target.value, quantity: this.order.order_item[index1].quantity});
          this.remove_items.items = this.remove_items.items.filter((e) => e.item_id != item.id)
          this.remove_items.items.push({item_id: parseInt(event.target.value), quantity: this.order.order_item[index1].quantity});
          // console.log(this.remove_items.items, "this.remove_items.items");
          if(!this.enableupdatedmrp){
            var index = this.order.order_item.map(e => e.id).indexOf(item.id);
            this.$refs.fileInput[index].value = this.order.order_item[index].quantity;
          }
          else if(this.enableupdatedmrp){
            var index = this.order.order_item.map(e => e.id).indexOf(item.id);
            this.$refs.fileInput[index].value = this.$refs.fileInput[index]._value;
            this.newOrderArray[index].item_mrp = this.order.order_item[index].item_mrp;
            this.newOrderArray[index].item_price = this.order.order_item[index].item_price;
            this.newOrderArray[index].quantity = this.order.order_item[index].quantity;
            this.update_items.items = this.update_items.items.filter((e) => e.item_id != item.id)
            // console.log(this.update_items.items, "this.update_items.items");
          }
          if(this.order.order_item.length == this.allItem.length){
            this.disableSubmit = true;
            this.$emit('disableSubmit',this.disableSubmit);
          } 
        }else if(event.target.checked){
          this.allItem = this.allItem.filter((e) => e.item_id != item.id)
          this.remove_items.items = this.remove_items.items.filter((e) => e.item_id != item.id)
        }
      }
    },
    decreaseOrderItem(item, event) {
      if(!this.enableupdatedmrp){
        this.newOrderArray = this.order.order_item.map(o => ({...o}));
      }else if(this.enableupdatedmrp){
        this.newOrderArray = this.newOrderArray;
      }
      this.enableupdatedmrp = true;
      this.remove_items.vendor_id = this.$auth.getVendorId();
      this.remove_items.restaurant_id = this.order.restaurant_id;
      this.remove_items.order_id = this.order.id;
      var index = this.order.order_item.map(e => e.id).indexOf(item.id);
      if(event.target.value > this.order.order_item[index].quantity){
        this.$toasted.error(`You can't increase the quantity more than original quantity (${this.order.order_item[index].quantity})`);
        // alert("You can't increase original quantity")
        this.disableSubmit = true;
        this.$emit('disableSubmit',this.disableSubmit);
        event.target.value=""
      } else{
        if(event.target.value < 1){
          this.$toasted.error("You can't decrease the quantity to 0");
          // alert("You can't decrease original quantity to 0")
          this.disableSubmit = true;
          this.$emit('disableSubmit',this.disableSubmit);
          event.target.value=""
        }else if (event.target.value != Math.floor(event.target.value)){
          // console.log(Math.floor(event.target.value), "math");
          // console.log(event.target.value, "value");
          this.$toasted.error("Please enter valid quantity");
          this.disableSubmit = true;
          this.$emit('disableSubmit',this.disableSubmit);
          event.target.value=""
        }
        else{
          if(event.target.value != this.order.order_item[index].quantity){
            if(this.order.order_item.length != this.allItem.length){
              this.disableSubmit = false;
            this.$emit('disableSubmit',this.disableSubmit);
            this.disableSubmitMRP = false;
            this.$emit('disableSubmitMRP',this.disableSubmitMRP);
            }
          this.newOrderArray[index].quantity = this.$refs.fileInput[index].value;
          // console.log(this.newOrderArray, "newOrderArray");
          // console.log(this.enableupdatedmrp, "enableupdatedmrp");
          // console.log(this.$refs.fileInput, "this.$refs.fileInput");
          this.remove_items.items.push({item_id: item.id, quantity: (this.order.order_item[index].quantity - event.target.value)})
          this.remove_items.items = this.remove_items.items.filter((e) => e.item_id != item.id)
          this.remove_items.items.push({item_id: item.id, quantity: parseInt(this.order.order_item[index].quantity - event.target.value)})
          }else{
            this.remove_items.items = this.remove_items.items.filter((e) => e.item_id != item.id)
            if(this.order.order_item.length != this.allItem.length){
              this.disableSubmit = false;
            this.$emit('disableSubmit',this.disableSubmit);
            }
          } 
        }
      }
    },

    changeOriginalMrp(item, event) {
      if(!this.enableupdatedmrp){
        this.newOrderArray = this.order.order_item.map(o => ({...o}));
        // console.log(this.order.order_item, "this.order.order_item");
      }else if(this.enableupdatedmrp){
        this.newOrderArray = this.newOrderArray;
      }
      this.changed_mrp = event.target.value;
      if(event.target.value > 99999.99){
        event.target.value = "";
        this.disableSubmitMRP = true;
        this.$emit('disableSubmitMRP',this.disableSubmitMRP);
        this.$toasted.error("You can't increase the price more than 99999.99");
      }else if(event.target.value > 0){
        this.discountPriceNew(item);
        this.disableSubmitMRP = false;
        this.$emit('disableSubmitMRP',this.disableSubmitMRP);
      }else{
        event.target.value = "";
        this.disableSubmitMRP = true;
        this.$emit('disableSubmitMRP',this.disableSubmitMRP);
        this.$toasted.error("You can't decrease the price to 0");
      }
    },

    sentToStore(id) {
      this.sentToStoreLoader = true;
      orderService.sentToStore({order_id:id})
      .then(response => {
        if (response.data.code == 200){
          this.order.depot_item_delivered = 1;
          this.sentToStoreLoader = false;
          this.$toasted.success(response.data.msg);
          this.$emit('closeItemDetailsEmit');
        } else {
          this.sentToStoreLoader = false;
          this.$toasted.error(response.data.msg);
        }
      });
    },

    medicineDiscount(order) {
      let itmTotalMrp = 0;
      let itmTotalPrice = 0;
      order.forEach((item) => {
        if (item.promocode_applicable == 1) {
          itmTotalMrp += item.item_mrp * item.quantity;
          itmTotalPrice += item.item_price * item.quantity;
        }
      });
      return parseFloat(itmTotalMrp-itmTotalPrice).toFixed(2);
    },

    otherItemDiscount(order) {
      let itmTotalMrp = 0;
      let itmTotalPrice = 0;
      order.forEach((item) => {
        if (item.promocode_applicable == 0) {
          itmTotalMrp += item.item_mrp * item.quantity;
          itmTotalPrice += item.item_price * item.quantity;
        }
      });
      return parseFloat(itmTotalMrp-itmTotalPrice).toFixed(2);
    },
  }
};
</script>

<template>
  <!-- View Detail Main Start -->
  <div class="row mt-3">
    <div class="col-12">
      <div class="text-center text-danger my-2" v-if="loading">
        <b-spinner class="align-middle"></b-spinner>
        <strong>{{trans.get('__JSON__.Loading...')}}</strong>
      </div>
    </div>
    <div class="table-responsive item-order-main" v-if="!loading">
      <table class="table table-centered table-bordered item-table-main">
        <thead>
          <tr>
            <th scope="col" class="item-name-main">{{trans.get('__JSON__.Item Name')}}</th>
            <th scope="col">{{trans.get('__JSON__.Quantity')}}</th>
            <!-- <th scope="col">{{trans.get('__JSON__.Unit Price')}}</th> -->
            <th scope="col">{{trans.get('__JSON__.Original')}}</th>
            <th scope="col">{{trans.get('__JSON__.Discounted Price')}}</th>
            <th scope="col">{{trans.get('__JSON__.Total')}}</th>
          </tr>
        </thead>
        <tbody>
          <template v-if="order.suborder && order.suborder.id && order.suborder.id > 0">
            <tr v-if="![statuses.placed,statuses.cancelled,statuses.paymentpending,statuses.paymentfailure].includes(order.order_status)">
              <td align="left" colspan="5">
                <h6>Shipment {{order.suborder.is_depot_store == 0 ? '1 (Store)' : '2 (Depot)'}}</h6>
              </td>
            </tr>
            <tr v-for="subitem in order.suborder.order_item" :key="subitem.id"  :class="{'removed-item' : subitem.item_removed == 1}">
              <td v-if="order.order_status == 'Placed' && order.suborder.is_depot_store == 0">
                <div style="display: flex;">
                  <input type="checkbox" :id="'item_sub_'+subitem.id" :value="subitem.id" @change="removeOrderItem(subitem, $event)" checked />
                  <label style="font-weight: normal;" class="ml-2 mb-0" :for="'item_sub_'+subitem.id">{{subitem.menu_item && subitem.menu_item.item_name}} {{getSelections(subitem.menu_item_selections)}} <template v-if="subitem.item_removed == 1"><br>(Item removed)</template></label>
                </div>
              </td>
              <td v-else>{{subitem.menu_item && subitem.menu_item.item_name}} {{getSelections(subitem.menu_item_selections)}} <template v-if="subitem.item_removed == 1"><br>(Item removed)</template></td>
              <!-- <td>{{subitem.menu_item && subitem.menu_item.item_name}} {{getSelections(subitem.menu_item_selections)}}</td> -->
              <td v-if="order.order_status == 'Placed'" align="center">
                <!-- <p  style="margin-bottom: 0 !important;">{{item.quantity}}</p> -->
                <input :value="subitem.quantity" type="number" @input="decreaseOrderItem(item, $event)">
                <!-- <p>{{subitem.quantity}}</p> -->
              </td>
              <td v-else align="center">
                <p>{{subitem.quantity}}</p>
              </td>
              <td align="center">
                <p><span v-html="currency"></span> {{((parseFloat(subitem.item_mrp || 0))).toFixed(decimal)}} </p>    
              </td>
              <td align="center">
                <p><span v-html="currency" v-if="discountPrice(subitem) != ' - '" style="margin-bottom: 0 !important;"></span> {{discountPrice(subitem)}} </p>
              </td>
              <td align="center">
                <p><template v-if="subitem.item_removed == 1">- </template> <span v-html="currency"></span> {{totalPrice(subitem).toFixed(decimal)}} </p>
              </td>
            </tr>
          </template>
          <tr v-if="order.suborder && order.suborder.id && order.suborder.id > 0 && ![statuses.placed,statuses.cancelled,statuses.paymentpending,statuses.paymentfailure].includes(order.order_status)">
            <template v-if="order.depot_item_delivered == 0 && !$auth.partner_login">
              <template v-if="[statuses.inkitchen].includes(order.order_status)">
                <td align="left" colspan="3">
                  <h6>Shipment {{order.is_depot_store == 0 ? '1 (Store)' : '2 (Depot)'}}</h6>
                </td>
                <td align="right" colspan="2">
                  <b-button v-if="$auth.hasPermission(config.permissions.order_write)" variant="primary" size="md" @click="sentToStore(order.id)" :disabled="sentToStoreLoader"><b-spinner v-if="sentToStoreLoader" class="align-middle"></b-spinner> Sent to store</b-button>
                </td>
              </template>
              <template v-else>
                <td align="left" colspan="5">
                  <h6>Shipment {{order.is_depot_store == 0 ? '1 (Store)' : '2 (Depot)'}}</h6>
                </td>
              </template>
            </template>
            <template v-else>
              <td align="left" colspan="5">
                <h6>Shipment {{order.is_depot_store == 0 ? '1 (Store)' : '2 (Depot)'}}</h6>
              </td>
            </template>
          </tr>
          <tr v-else-if="!order.suborder && [statuses.inkitchen].includes(order.order_status) && !$auth.partner_login && order.is_depot_store == 1 && order.depot_item_delivered == 0">
            <td align="right" colspan="5">
              <b-button v-if="$auth.hasPermission(config.permissions.order_write)" variant="primary" size="md" @click="sentToStore(order.id)" :disabled="sentToStoreLoader"><b-spinner v-if="sentToStoreLoader" class="align-middle"></b-spinner> Sent to store</b-button>
            </td>
          </tr>
          <tr v-if="enableupdatedmrp == false" v-for="item in order.order_item" :key="item.id" :class="{'removed-item' : item.item_removed == 1}">
            <td v-if="order.order_status == 'Placed' && order.is_depot_store == 0">
              <div style="display: flex;">
                <input type="checkbox" :id="'item_main_'+item.id" :value="item.id" @change="removeOrderItem(item, $event)" checked />
                <label style="font-weight: normal;" class="ml-2 mb-0" :for="'item_main_'+item.id">{{item.menu_item && item.menu_item.item_name}} {{getSelections(item.menu_item_selections)}} <template v-if="item.item_removed == 1"><br>(Item removed)</template></label>
              </div>
            </td>
            <!-- <td v-else>{{item.menu_item && item.menu_item.item_name}} {{getSelections(item.menu_item_selections)}}</td> -->
            <td v-else-if="order.order_status == 'Placed' && order.is_depot_store == 1">
              <div style="display: flex;">
                <input type="checkbox" :id="'item_main_'+item.id" :value="item.id" @change="removeOrderItem(item, $event)" checked />
                <label style="font-weight: normal;" class="ml-2 mb-0" :for="'item_main_'+item.id">{{item.menu_item && item.menu_item.item_name}} {{getSelections(item.menu_item_selections)}} <template v-if="item.item_removed == 1"><br>(Item removed)</template></label>
              </div>
            </td>
            <td v-else>{{item.menu_item && item.menu_item.item_name}} {{getSelections(item.menu_item_selections)}} <template v-if="item.item_removed == 1"><br>(Item removed)</template></td>
            <td v-if="order.order_status == 'Placed'" align="center">
              <!-- <span><button v-on:click="item.quantity -= 1" style="border: none; background: none;" @click="decreaseOrderItem(item, $event)">-</button></span> -->
              <!-- <p style="margin-bottom: 0 !important;">{{item.quantity}}</p> -->
              <input :value="item.quantity" type="number" ref="fileInput" @input="decreaseOrderItem(item, $event)">
            </td>
            <td v-else align="center">
              <p>{{item.quantity}}</p>
            </td>
            <td v-if="order.order_status == 'Placed'" align="center" style="white-space:inherit">
              <!-- <p><span v-html="currency"></span> {{((parseFloat(item.item_mrp || 0))).toFixed(decimal)}}</p> -->
              <!-- <input :value="parseFloat(item.item_mrp)" type="hidden" ref="originalpriceHidden" style="display:none" > -->
              <input :value="((parseFloat(item.item_mrp || 0))).toFixed(decimal)" type="number" ref="originalprice" @change="changeOriginalMrp(item, $event)"> 
            </td>
            <td v-else align="center">
              <p><span v-html="currency"></span> {{((parseFloat(item.item_mrp || 0))).toFixed(decimal)}}</p>
            </td>
            <td align="center">
              <!-- <p><span v-html="currency"></span>{{ discounted_price }}</p>   -->
              <p><span v-html="currency" v-if="discountPrice(item) != ' - '" style="margin-bottom: 0 !important;"></span> {{discountPrice(item)}}</p>
            </td>
            <td align="center">
              <!-- <p><template v-if="item.item_removed == 1">- </template> <span v-html="currency"></span> {{totalPrice(item).toFixed(decimal)}} </p>\ -->
              <p><template v-if="item.item_removed == 1">- </template> <span v-html="currency"></span> {{item.amount}} </p>
            </td>
          </tr>
          <tr v-if="enableupdatedmrp == true" v-for="item in newOrderArray" :key="item.id" :class="{'removed-item' : item.item_removed == 1}">
            <td v-if="order.order_status == 'Placed' && order.is_depot_store == 0">
              <div style="display: flex;">
                <input type="checkbox" :id="'item_main_'+item.id" :value="item.id" @change="removeOrderItem(item, $event)" checked />
                <label style="font-weight: normal;" class="ml-2 mb-0" :for="'item_main_'+item.id">{{item.menu_item && item.menu_item.item_name}} {{getSelections(item.menu_item_selections)}} <template v-if="item.item_removed == 1"><br>(Item removed)</template></label>
              </div>
            </td>
            <!-- <td v-else>{{item.menu_item && item.menu_item.item_name}} {{getSelections(item.menu_item_selections)}}</td> -->
            <td v-else-if="order.order_status == 'Placed' && order.is_depot_store == 1">
              <div  style="display: flex;">
                <input type="checkbox" :id="'item_main_'+item.id" :value="item.id" @change="removeOrderItem(item, $event)" checked />
                <label style="font-weight: normal;" class="ml-2 mb-0" :for="'item_main_'+item.id">{{item.menu_item && item.menu_item.item_name}} {{getSelections(item.menu_item_selections)}} <template v-if="item.item_removed == 1"><br>(Item removed)</template></label>
              </div>
            </td>
            <td v-else>{{item.menu_item && item.menu_item.item_name}} {{getSelections(item.menu_item_selections)}} <template v-if="item.item_removed == 1"><br>(Item removed)</template></td>
            <td v-if="order.order_status == 'Placed'" align="center">
              <!-- <span><button v-on:click="item.quantity -= 1" style="border: none; background: none;" @click="decreaseOrderItem(item, $event)">-</button></span> -->
              <!-- <p style="margin-bottom: 0 !important;">{{item.quantity}}</p> -->
              <input :value="item.quantity" type="number" ref="fileInput" @change="decreaseOrderItem(item, $event)">
            </td>
            <td v-else align="center">
              <p>{{item.quantity}}</p>
            </td>
            <td v-if="order.order_status == 'Placed'" align="center" style="white-space:inherit">
              <!-- <p><span v-html="currency"></span> {{((parseFloat(item.item_mrp || 0))).toFixed(decimal)}}</p> -->
              <!-- <input :value="parseFloat(item.item_mrp)" type="hidden" ref="originalpriceHidden" style="display:none" > -->
              <input :value="((parseFloat(item.item_mrp || 0))).toFixed(decimal)" type="number" ref="originalprice" @change="changeOriginalMrp(item, $event)"> 
            </td>
            <td v-else align="center">
              <p><span v-html="currency"></span> {{((parseFloat(item.item_mrp || 0))).toFixed(decimal)}}</p>
            </td>
            <td align="center">
              <!-- <p v-if="enableupdatedmrp"><span v-html="currency"></span>{{ discounted_price }}</p>  -->
              <p><span v-html="currency" v-if="discountPrice(item) != ' - '" style="margin-bottom: 0 !important;"></span> {{discountPrice(item)}}</p>
            </td>
            <td align="center">
              <!-- <p><template v-if="item.item_removed == 1">- </template> <span v-html="currency"></span> {{totalPrice(item).toFixed(decimal)}} </p>\ -->
              <p><template v-if="item.item_removed == 1">- </template> <span v-html="currency"></span> {{item.amount}} </p>
            </td>
          </tr>
          <tr>
            <!-- <td colspan="4" align="center"><h6 class="text-right mb-0">{{trans.get('__JSON__.Sub Total')}}</h6></td>
            <td align="center"> <span v-html="currency"></span> {{parseFloat(order.sub_total || 0).toFixed(decimal)}}</td> -->
            <td colspan="4" align="center"><h6 class="text-right mb-0">{{trans.get('__JSON__.Medicine Total (MRP)')}}</h6></td>
            <td align="center"> <span v-html="currency"></span> {{parseFloat(order.promocode_applicable_mrp_sub_total || 0).toFixed(decimal)}}</td>
          </tr>
          <tr v-if="medicineDiscount(order.order_item) != 0">
            <td colspan="4" align="right">
              <span class="text-right text-danger">{{trans.get('__JSON__.Medicine Price Discount')}}</span>
            </td>
            <td align="center">
              <span class="text-danger" v-html="currency"></span>
              <span class="text-danger">{{ medicineDiscount(order.order_item) }}</span>
            </td>
          </tr>
          <tr>
            <td colspan="4" align="right" v-if="order.reddemed_coupon_order != null">
              <span class="text-right text-danger">{{trans.get('__JSON__.Promocode Discount on Meds')}}</span>
              <span class="text-right text-danger"> {{order.reddemed_coupon_order.coupon?' ('+order.reddemed_coupon_order.coupon.coupon_code+')':''}}</span>
            </td>
            <td align="center" v-if="order.reddemed_coupon_order != null">
              <span class="text-danger" v-html="currency"></span>
              <span class="text-danger">{{parseFloat((order.reddemed_coupon_order.original_price - order.reddemed_coupon_order.discount_price) || 0).toFixed(decimal)}}</span>
            </td>
          </tr>
          <tr>
            <td colspan="4" align="right" v-if="order.reddemed_coupon_order != null">
              <span class="text-right text-danger">{{trans.get('__JSON__.Promocode Discount Percentage on Meds')}}</span>
            </td>
            <td align="center" class="text-danger" v-if="order.reddemed_coupon_order != null">
              <!-- <span class="text-danger">{{parseFloat((((order.reddemed_coupon_order.original_price - order.reddemed_coupon_order.discount_price) || 0)/order.mrp_sub_total)*100).toFixed(decimal)}} %</span> -->
              <span v-if="parseFloat(order.reddemed_coupon_order.original_price) == parseFloat(order.reddemed_coupon_order.discount_price)" class="text-danger">0 %</span>
              <span v-else class="text-danger">{{parseFloat((((order.reddemed_coupon_order.original_price - order.reddemed_coupon_order.discount_price) || 0)/order.promocode_applicable_mrp_sub_total)*100).toFixed(decimal)}} %</span>
            </td>
          </tr>
          <tr v-if="order.mrp_sub_total != order.promocode_applicable_mrp_sub_total">
            <!-- <td colspan="4" align="center"><h6 class="text-right mb-0">{{trans.get('__JSON__.MRP Sub Total')}}</h6></td>
            <td align="center"> <span v-html="currency"></span> {{parseFloat(order.mrp_sub_total || 0).toFixed(decimal)}}</td> -->
            <td colspan="4" align="center"><h6 class="text-right mb-0">{{trans.get('__JSON__.Other Item Total (MRP)')}}</h6></td>
            <td align="center"> <span v-html="currency"></span> {{parseFloat(order.mrp_sub_total - order.promocode_applicable_mrp_sub_total).toFixed(decimal)}}</td>
          </tr>
          <tr v-if="otherItemDiscount(order.order_item) != 0">
            <td colspan="4" align="right">
              <span class="text-right text-danger">{{trans.get('__JSON__.Other Item Price Discount')}}</span>
            </td>
            <td align="center">
              <span class="text-danger" v-html="currency"></span>
              <span class="text-danger">{{ otherItemDiscount(order.order_item) }}</span>
            </td>
          </tr>
          <tr v-if="order.service_fee > 0">
            <td colspan="4" align="center"><h6 class="text-right mb-0">{{$auth.setting.tax_name_primary_lang}}</h6></td>
            <td align="center"> <span v-html="currency"></span> {{parseFloat(order.service_fee || 0).toFixed(decimal)}}</td>
          </tr>
          <!-- <tr>
            <td colspan="4" align="center"><h6 class="text-right mb-0">{{trans.get('__JSON__.Promocode Applied MRP')}}</h6></td>
            <td align="center"><span v-html="currency"></span> {{parseFloat(order.promocode_applicable_mrp_sub_total || 0).toFixed(decimal)}}</td>
          </tr> -->
          <tr>
            <td colspan="4" align="center"><h6 class="text-right mb-0">{{trans.get('__JSON__.Delivery Fees')}}</h6></td>
            <td align="center"><span v-html="currency"></span> {{parseFloat(order.delivery_fee || 0).toFixed(decimal)}} </td>
          </tr>
          <tr :class="{ 'saved-amount': order.saved_amount > 0 }">
            <td colspan="4" align="center"><h6 class="text-right mb-0">{{trans.get('__JSON__.Total Amount')}}<span v-if="order.saved_amount > 0" class="you-saved">{{ trans.get('__JSON__.You Saved') }}</span></h6></td>
            <td align="center" :class="order.saved_amount > 0 ? 'values' :  'lineh0'"> 
              <span v-html="currency"></span>&nbsp;{{parseFloat(order.total_amount || 0).toFixed(decimal)}}
              <p class="save-amount" v-if="order.saved_amount > 0">
                <span v-html="currency" class="you-saved"></span>&nbsp;<span class="you-saved">{{parseFloat(order.saved_amount || 0).toFixed(decimal)}}</span>
              </p>
            </td>
          </tr>
          <tr v-if="order.payable_amount">
            <td colspan="4" align="center"><h6 class="text-right mb-0">{{trans.get('__JSON__.Payable Amount')}}</h6></td>
            <td align="center"><span v-html="currency"></span> {{parseFloat(order.payable_amount || 0).toFixed(decimal)}} </td>
          </tr>
          <tr v-if="order.instamed_cash">
            <td colspan="4" align="center"><h6 class="text-right mb-0 text-danger">{{trans.get('__JSON__.Instamed Cash')}}</h6></td>
            <td align="center" class="text-danger"><span v-html="currency"></span> {{parseFloat(order.instamed_cash || 0).toFixed(decimal)}} </td>
          </tr>
          <tr v-if="order.referral_discount">
            <td colspan="4" align="center"><h6 class="text-right mb-0 text-danger">{{trans.get('__JSON__.Referral Discount')}}</h6></td>
            <td align="center" class="text-danger"><span v-html="currency"></span> {{parseFloat(order.referral_discount || 0).toFixed(decimal)}} </td>
          </tr>
        </tbody>
      </table>
    </div>
    <order-images :order="order" :loading="loading" v-if="!loading && $auth.setting.is_upload_order_image == 'Yes' && order.order_images && order.order_images.length > 0"/>
    <order-invoice :order="order" :loading="loading" v-if="!loading && order.vendor_invoice"/>
    <!-- View Detail Main End -->
  </div>
</template>
<style scoped>
.item-table-main .item-name-main{
  width: 45%;
}
.saved-amount .you-saved{
  color: #34c38f;
  margin-top: 10px;
}
.saved-amount h6{
  display: flex;
  justify-content: right;
  line-height: 18px;
  flex-direction: column;
}
.save-amount{
 margin:0px !important;
}
.saved-amount .tax-slab{
  margin-top: 10px;
}
.saved-amount .values{
  line-height: 15px;
}
.lineh0 {
  line-height: 0px !important;
}
.removed-item td {
  /* filter: blur(1px); */
  color: #F46A6A;
}
</style>