<script>
import { required, requiredIf } from "vuelidate/lib/validators";
import { webLayoutService } from '../../../services';
import { success_message, error_message } from '../../../Helper/helper';
import config from '../../../config';
import DropzonePopup from "../../../components/widgets/dropzone-popup";
import { routes } from "../../../config/api_routes";
/**
 * Starter component
 */
export default {
  components: { DropzonePopup },
  data() {
    return {
      config:config,
      web: [],
      web: {
        fevicon_icon : '',
        theme_primary_color : '000',
        theme_secondary_color : '000',
        text_primary_color : '',
        text_secondary_color : '',
        button_primary_color: '',
        button_secondary_color: '',
        button_text_primary_color: '',
        button_text_secondary_color: '',
        home_slider_background_color: '',
        footer_background_color: '',
        footer_text_color: ''
      },
      web_logo_image: "",
      web_logo_image_path: "",
      web_logo_image_delete_url: routes.deleteWebLogoApi,
    };
  },
  validations: {
    web: {
    }
  },
  mounted() {
    this.getWebSetting();
  },
  methods: {
    onWebLogoImagedeleted(value) {
      this.web_logo_image = "";
    },
    getWebSetting(){
      webLayoutService.getWebSetting().then((response) => {
        const { 
          theme_primary_color,
          theme_secondary_color,
          text_primary_color,
          text_secondary_color,
          button_primary_color,
          button_secondary_color,
          button_text_primary_color,
          button_text_secondary_color,
          home_slider_background_color,
          footer_background_color,
          footer_text_color
        } = response.data.result;
        this.web_logo_image = response.data.result.fevicon_icon;
        this.web_logo_image_path = response.data.result.web_logo_image_path;
        this.web = {
          theme_primary_color: theme_primary_color || '',
          theme_secondary_color: theme_secondary_color || '',
          text_primary_color: text_primary_color || '',
          text_secondary_color: text_secondary_color || '',
          button_primary_color : button_primary_color || '',
          button_secondary_color : button_secondary_color || '',
          button_text_primary_color : button_text_primary_color || '',
          button_text_secondary_color : button_text_secondary_color || '',
          home_slider_background_color : home_slider_background_color || '',
          footer_background_color : footer_background_color || '' ,
          footer_text_color : footer_text_color || ''
        };
      })
    },
    generalIt(){
      this.$v.$touch();
      if (this.$v.web.$invalid) {
        return;
      } else {
        const fd = new FormData();
        fd.append("fevicon_icon", this.web.fevicon_icon);
        fd.append("theme_primary_color", this.web.theme_primary_color);
        fd.append("theme_secondary_color", this.web.theme_secondary_color);
        fd.append("text_primary_color", this.web.text_primary_color);
        fd.append("text_secondary_color", this.web.text_secondary_color);
        fd.append("button_primary_color", this.web.button_primary_color);
        fd.append("button_secondary_color", this.web.button_secondary_color);
        fd.append("button_text_primary_color", this.web.button_text_primary_color);
        fd.append("button_text_secondary_color", this.web.button_text_secondary_color);
        fd.append("home_slider_background_color", this.web.home_slider_background_color);
        fd.append("footer_background_color", this.web.footer_background_color);
        fd.append("footer_text_color", this.web.footer_text_color);
        fd.append('type',2);
        webLayoutService.updateWebSetting(fd).then((response) => {
          if (response.data.code === 200) {
            success_message(response.data.message);
          } else {
            error_message(response.data.message);
          }
        });
      }
    }
  }
};
</script>
<template>
  <b-form @submit.prevent="generalIt">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label class="control-label">{{ trans.get('__JSON__.Favicon Icon') }}</label>
                  <dropzone-popup
                    v-model="web.fevicon_icon"
                    id="fevicon_icon"
                    class="business-logo"
                    acceptedFiles=".jpeg,.jpg,.png,.webp,image/webp"
                    :editable-images="web_logo_image"
                    :media-path="web_logo_image_path"
                    :delete-path="web_logo_image_delete_url"
                    @image-deleted="this.onWebLogoImagedeleted"
                  />
                </div>
              </div>
              <div class="col-md-9">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="control-label">{{ trans.get('__JSON__.Theme Primary Color') }}</label>
                      <input type="color" id="theme_primary_color" name="color" class="form-control" v-model="web.theme_primary_color" pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$" value="#000"> 
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="control-label">{{ trans.get('__JSON__.Theme Secondary Color') }}</label>
                      <input type="color" id="theme_secondary_color" name="color" class="form-control" v-model="web.theme_secondary_color" pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$" value="#000"> 
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="control-label">{{ trans.get('__JSON__.Text Primary Color') }}</label>
                      <input type="color" id="text_primary_color" name="color" class="form-control" v-model="web.text_primary_color" pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$" value="#000"> 
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="control-label">{{ trans.get('__JSON__.Text Secondary Color') }}</label>
                      <input type="color" id="text_secondary_color" name="color" class="form-control" v-model="web.text_secondary_color" pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$" value="#000"> 
                    </div>
                  </div>
                </div>
                 <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="control-label">{{ trans.get('__JSON__.Button Primary Color') }}</label>
                      <input type="color" id="button_primary_color" name="color" class="form-control" v-model="web.button_primary_color" pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$" value="#000"> 
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="control-label">{{ trans.get('__JSON__.Button Secondary Color') }}</label>
                      <input type="color" id="button_secondary_color" name="color" class="form-control" v-model="web.button_secondary_color" pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$" value="#000"> 
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="control-label">{{ trans.get('__JSON__.Button Text Primary Color') }}</label>
                      <input type="color" id="button_text_primary_color" name="color" class="form-control" v-model="web.button_text_primary_color" pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$" value="#000"> 
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="control-label">{{ trans.get('__JSON__.Button Text Secondary Color') }}</label>
                      <input type="color" id="button_text_secondary_color" name="color" class="form-control" v-model="web.button_text_secondary_color" pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$" value="#000"> 
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="control-label">{{ trans.get('__JSON__.Home Slider Background Color') }}</label>
                      <input type="color" id="home_slider_background_color" name="color" class="form-control" v-model="web.home_slider_background_color" pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$" value="#000"> 
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="control-label">{{ trans.get('__JSON__.Footer Background Color') }}</label>
                      <input type="color" id="footer_background_color" name="color" class="form-control" v-model="web.footer_background_color" pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$" value="#000"> 
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="control-label">{{ trans.get('__JSON__.Footer Text Color') }}</label>
                      <input type="color" id="footer_text_color" name="color" class="form-control" v-model="web.footer_text_color" pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$" value="#000"> 
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div class="btn-update">
              <div class="col-sm-6">
                <button
                  v-if="$auth.hasPermission(config.permissions.web_layout_write)"
                  type="submit"
                  class="btn btn-primary mr-1 mt-3"
                >{{ trans.get('__JSON__.Update') }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-form>
</template>
