
<script>
import {
  required
} from "vuelidate/lib/validators";
import { success_message } from '../../../Helper/helper';
import { userAccessService } from '../../../services';

export default {
    props: ['permission-added'],
    data(){
        return {

            typeform: {
                name: '',
            },
            typesubmit: false,
            guards: [
              {key: 1,label:'Admin', name: 'vendor'},
              {key: 2,label:'Restaurant', name: 'restaurant'},
              {key: 3,label:'Driver', name: 'driver'},
            ]
        };
    },
    validations: {
        typeform: {
            name: {
              required
            }
        }
    },
    methods:{
        addPermission(){
          this.$v.$touch();
          if(!this.$v.typeform.$invalid){
            userAccessService.addPermission(this.typeform)
                .then(response => {
                  this.typeform = {
                    name: '',
                  }
                  this.$v.typeform.$reset();
                  this.$bvModal.hide('modal-permission');
                  this.$emit('permission-added');
                  success_message(response.data.message);
                });
          }
        }
    }
};
</script>
<template>
  <div>
    <b-button v-b-modal.modal-permission variant="primary" class="btn-rounded">
      <i class="mdi mdi-plus mr-1"></i>
      {{ trans.get("__JSON__.Add Permision") }}
    </b-button>
    <b-modal
      id="modal-permission"
      centered
      title="Create Permission"
      title-class="font-18"
      hide-footer
    >
      <form action="#" @submit.prevent="addPermission">
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <label>{{ trans.get("__JSON__.Permission") }}</label>
              <input
                id="name"
                v-model.trim="$v.typeform.name.$model"
                type="text"
                class="form-control"
                :placeholder="trans.get('__JSON__.Enter Permission')"
                name="name"
                :class="{ 'is-invalid': $v.typeform.name.$error }"
              />
              <div v-if="$v.typeform.name.$error" class="invalid-feedback">
                <span v-if="!$v.typeform.name.required">{{
                  trans.get("__JSON__.Please enter permission name.")
                }}</span>
              </div>
            </div>
          </div>
          <div class="col-sm-12">
            <button class="btn btn-primary mr-1 mt-3" type="submit">
              {{ trans.get("__JSON__.Submit") }}
            </button>
          </div>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<style>
</style>