<script>
import Multiselect from "vue-multiselect";
import { required, requiredIf } from "vuelidate/lib/validators";
import { defaultSettingService, businessTypeService } from '../../../services';
import { success_message, error_message } from '../../../Helper/helper';
import config from '../../../config';

/**
 * Starter component
 */
export default {
  components: { Multiselect },
  data() {
    return {
      config:config,
      setting: [],
      id: "",
      app_setting_id: "",
      setting: {
        auto_scroll_banner: 1,
        home_page_category_title: '',
        home_page_category_title_sec_lang: '',
        home_page_sub_title: '',
        home_page_sub_title_sec_lang: '',
        banner_app_layout : '',
      }
    };
  },
  validations: {
    setting: {
      home_page_category_title: {
        required: requiredIf(function(form){
          return (this.$auth.setting.store_selection == 1);
        }),
      },
      home_page_sub_title: {
        required: requiredIf(function(form){
          return (this.$auth.setting.store_selection == 1);
        }),
      }
    },
  },
  mounted() {
    this.getBusinessTypeById();
  },
  methods: {
     getBusinessTypeById() {
      businessTypeService
        .getBusinessTypeById({ id: this.$route.params.id })
        .then((response) => {
           const { auto_scroll_banner,
            home_page_category_title,
            home_page_category_title_sec_lang,
            home_page_sub_title,
            home_page_sub_title_sec_lang,
            banner_app_layout
            } = response.data.result.settings;
            this.id = response.data.result.business.id;
            this.app_setting_id = response.data.result.business.app_setting_id;
            this.setting = {
                auto_scroll_banner: auto_scroll_banner || '1',
                home_page_category_title: home_page_category_title || '',
                home_page_category_title_sec_lang: home_page_category_title_sec_lang || '',
                home_page_sub_title: home_page_sub_title || '',
                home_page_sub_title_sec_lang: home_page_sub_title_sec_lang || '',
                banner_app_layout : banner_app_layout || '1'
            };
        });
    },
    // getAppSetting(){
    //   settingService.getAppSettingApi().then((response) => {
    //     const { auto_scroll_banner,
    //         home_page_category_title,
    //         home_page_category_title_sec_lang,
    //         home_page_sub_title,
    //         home_page_sub_title_sec_lang,
    //         banner_app_layout
    //     } = response.data.result.settings;
    //     this.setting = {
    //         auto_scroll_banner: auto_scroll_banner || '1',
    //         home_page_category_title: home_page_category_title || '',
    //         home_page_category_title_sec_lang: home_page_category_title_sec_lang || '',
    //         home_page_sub_title: home_page_sub_title || '',
    //         home_page_sub_title_sec_lang: home_page_sub_title_sec_lang || '',
    //         banner_app_layout : banner_app_layout || '1'
    //     };
    //   })
    // },
    generalIt(){
      this.$v.setting.$touch();
      if (this.$v.setting.$invalid) {
        return;
      } else {
        const fd = new FormData();
        fd.append("setting_id",this.id);
        fd.append("app_setting_id",this.app_setting_id);
        fd.append("auto_scroll_banner", this.setting.auto_scroll_banner);
        fd.append("home_page_category_title", this.setting.home_page_category_title);
        fd.append("home_page_category_title_sec_lang", this.setting.home_page_category_title_sec_lang);
        fd.append("home_page_sub_title",this.setting.home_page_sub_title);
        fd.append('home_page_sub_title_sec_lang',this.setting.home_page_sub_title_sec_lang);
        fd.append('banner_app_layout',this.setting.banner_app_layout);
        fd.append('type',8);
        defaultSettingService.updateDefaultSetting(fd).then((response) => {
          if (response.data.code === 200) {
              success_message(response.data.message);
            } else {
              error_message(response.data.message);
            }
        });
      }
    }
  }
};
</script>
<template>
  <b-form @submit.prevent="generalIt">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-4">
                 <div class="form-group">
                    <label class="control-label">{{ trans.get('__JSON__.Home Page Banner Auto Scrolling') }}</label>
                    <select
                      class="custom-select"
                      id="auto-scroll-banner"
                      name="auto_scroll_banner"
                      v-model="setting.auto_scroll_banner"
                    >
                      <option v-bind:value="1">{{ trans.get('__JSON__.On') }}</option>
                      <option v-bind:value="0">{{ trans.get('__JSON__.Off') }}</option>
                    </select>
                </div>
              </div>
              <div class="col-md-4">
                 <div class="form-group">
                    <label class="control-label">{{ trans.get('__JSON__.Banner Size') }}</label>
                    <select
                      class="custom-select"
                      id="banner_app_layout"
                      name="banner_app_layout"
                      v-model="setting.banner_app_layout"
                    >
                      <option v-bind:value="1">{{ trans.get('__JSON__.Multiple Boxed') }}</option>
                      <option v-bind:value="2">{{ trans.get('__JSON__.Single Boxed') }}</option>
                      <option v-bind:value="3">{{ trans.get('__JSON__.Full Width') }}</option>
                    </select>
                </div>
              </div>
              <div class="col-md-4" v-if="app_setting_id != '3'">
                <div class="form-group">
                <label>{{ trans.get('__JSON__.Home Page Category Title') }}</label>
                <input
                  id="home_page_category_title"
                  name="home_page_category_title"
                  type="text"
                  v-model="setting.home_page_category_title"
                  :placeholder="trans.get('__JSON__.Enter Home Page Category Title')"
                  class="form-control"
                  :class="{ 'is-invalid': $v.setting.home_page_category_title.$error }"
                />
                 <div v-if="$v.setting.home_page_category_title.$error" class="invalid-feedback">
                    <span v-if="!$v.setting.home_page_category_title.required">{{ trans.get('__JSON__.Please enter home page category title')}}</span>
                  </div>
               </div>
              </div>
              <div class="col-md-4 d-none" v-if="$auth.setting && $auth.langauges[0].name == 'No Secondary Language'">
              </div>
               <div class="col-md-4 d-none" v-else>
                <div class="form-group" v-if="$auth.setting.store_selection == 1">
                <label>{{ trans.trans('__JSON__.Home Page Category Title Languages', { lang: $auth.langauges[0].name }) }}</label>
                <input
                  id="home_page_category_title_sec_lang"
                  name="home_page_category_title_sec_lang"
                  type="text"
                  v-model="setting.home_page_category_title_sec_lang"
                  :placeholder="trans.trans('__JSON__.Enter Home Page Category Title Languages', { lang: $auth.langauges[0].name })"
                  class="form-control"
                />
               </div>
              </div>
            </div>
            
            <div class="row">
              <div class="col-md-4" v-if="app_setting_id != '3'">
                <div class="form-group">
                <label>{{ trans.get('__JSON__.Home Page Subtitle') }}</label>
                <input
                  id="home_page_sub_title"
                  name="home_page_sub_title"
                  type="text"
                  v-model="setting.home_page_sub_title"
                  :placeholder="trans.get('__JSON__.Enter Home Page Subtitle')"
                  class="form-control"
                  :class="{ 'is-invalid': $v.setting.home_page_sub_title.$error }"
                />
                 <div v-if="$v.setting.home_page_sub_title.$error" class="invalid-feedback">
                    <span v-if="!$v.setting.home_page_sub_title.required">{{ trans.get('__JSON__.Please enter home page sub title')}}</span>
                  </div>
               </div>
              </div>
               <div class="col-md-4 d-none" v-if="$auth.setting && $auth.langauges[0].name == 'No Secondary Language'">
              </div>
               <div class="col-md-4 d-none" v-else>
                <div class="form-group" v-if="$auth.setting.store_selection == 1">
                <label>{{ trans.trans('__JSON__.Home Page Subtitle Title Languages', { lang: $auth.langauges[0].name }) }}</label>
                <input
                  id="home_page_sub_title_sec_lang"
                  name="home_page_sub_title_sec_lang"
                  v-model="setting.home_page_sub_title_sec_lang"
                  type="text"
                  :placeholder="trans.trans('__JSON__.Enter Home Page Subtitle Title Languages', { lang: $auth.langauges[0].name })"
                  class="form-control"
                />
               </div>
              </div>
              <div class="col-md-4 d-none">
                 <div class="form-group">
                    <label class="control-label">{{ trans.get('__JSON__.Banner Size') }}</label>
                    <select
                      class="custom-select"
                      id="banner_app_layout"
                      name="banner_app_layout"
                      v-model="setting.banner_app_layout"
                    >
                      <option v-bind:value="1">{{ trans.get('__JSON__.Multiple Boxed') }}</option>
                      <option v-bind:value="2">{{ trans.get('__JSON__.Single Boxed') }}</option>
                      <option v-bind:value="3">{{ trans.get('__JSON__.Full Width') }}</option>
                    </select>
                </div>
             </div>
            
          </div>

            <div class="btn-update">
              <div class="col-sm-6">
                <button
                  type="submit"
                  class="btn btn-primary mr-1 mt-3"
                >{{ trans.get('__JSON__.Update') }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-form>
</template>