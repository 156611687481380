<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";

import Delivery from '../../components/widgets/delivery.vue';
/**
 * Tabs & accordions component
 */
export default {
  components: { Layout, PageHeader, Delivery },
  data() {
    return {};
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="trans.get('__JSON__.Report')" />
    <div class="row">
      <div class="col-lg-12 list-table-main">
        <div class="card">
          <div class="card-body">
            <ul class="nav nav-tabs nav-tabs-custom" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  data-toggle="tab"
                  href="#all-order"
                  role="tab"
                >Delivered</a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  data-toggle="tab"
                  href="#processing"
                  role="tab"
                >Rejected</a>
              </li>
            </ul>

            <!-- Tab panes -->
            <div class="tab-content p-3">
              <div class="tab-pane active" id="all-order" role="tabpanel">
                <Delivery/>
              </div>
              <div class="tab-pane" id="processing" role="tabpanel">
                <Delivery/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>