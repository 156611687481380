
<script>
import vue2Dropzone from "vue2-dropzone";
import Multiselect from "vue-multiselect";
import { bannerService, restaurantService } from "../../services";
import { required, requiredIf } from "vuelidate/lib/validators";
import config from "../../config";
import { routes } from "../../config/api_routes";
import { error_message } from "../../Helper/helper";
import { success_message } from "../../Helper/helper";
import DropzoneSingle from "../../components/widgets/dropzone-single";
import DropzonePopup from "../../components/widgets/dropzone-popup";

export default {
  components: {
    vueDropzone: vue2Dropzone,
    Multiselect,
    DropzoneSingle,
    DropzonePopup,
  },
  data() {
    return {
      value: null,
      tmpBannerImage: "",
      url: null,
      config: config,
      search: "",
      errors: "",
      isLoading: false,
      resturant: [],
      resturantcategory: [],
      selectedRestaurant: [],
      isreataurantcategory: false,
      typeform: {
        name: "",
        type: "",
        link: "",
        selectedRestaurant: "",
        menu_category_id: "",
        image: null,
        status: true,
      },
    };
  },
  validations: {
    typeform: {
      name: {
        required,
      },
      image: {
        required,
      },
      type: {
        required,
      },
      selectedRestaurant: {
        required:requiredIf(function (res) {
          return this.typeform.type == 'restaurant';
        })
      },
      link: {
        required:requiredIf(function (lnk) {
          return this.typeform.type == 'link';
        })
      },
      menu_category_id: {
        required:requiredIf(function (res) {
          return (this.typeform.type == 'restaurant' && !this.$auth.isProductMarket());
        })
      }
    },
  },
  methods: {
    getRestaurantMenuCategory(ev) {
      this.resturantcategory = [];
      this.typeform.menu_category_id = "";
      this.isreataurantcategory = false;
      if (!this.$auth.isProductMarket()) {
        this.isreataurantcategory = true;
        if (!ev.restaurant_id) {
          if (ev[0] && ev[0].restaurant_id) {
            ev.restaurant_id = ev[0].restaurant_id;
          }
        }
        if (ev && ev.restaurant_id && ev.restaurant_id > 0) {
          let searchQuery = {
            search : '',
            restaurant_id : ev.restaurant_id,
            sortBy : 'name',
            orderBy : 'asc'
          };
          restaurantService.getRestaurantMenuCategory(searchQuery).then((response) => {
            if (response.data.result.data) {
              this.resturantcategory = response.data.result.data;
            }
          });
        }
      }
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.url = URL.createObjectURL(file);
      this.typeform.image = file;
    },
    asyncFind(query) {
      this.isLoading = true;
      bannerService.getRestaurant(query).then((response) => {
        this.resturant = response.data.result;
        this.isLoading = false;
      });
    },
    clearAll() {
      this.selectedRestaurant = [];
    },
    bannerInIt() {
      this.$v.$touch();
      if (this.$v.typeform.$invalid) {
        return;
      } else {
        if (this.typeform.type != 'restaurant') {
          this.typeform.selectedRestaurant = [];
          this.typeform.menu_category_id = "";
        }
        if (this.typeform.type != 'link') {
          this.typeform.link = '';
        }
        const fd = new FormData();
        fd.append("image", this.typeform.image);
        fd.append("type", this.typeform.type);
        fd.append("link", this.typeform.link);
        fd.append("name", this.typeform.name);
        fd.append("status", this.typeform.status ? 1 : 0);
        fd.append("menu_category_id", this.typeform.menu_category_id);
        if (!!this.typeform.selectedRestaurant) {
          this.typeform.selectedRestaurant
          .map((item) => item.restaurant_id)
          .map((item, index) => {
            fd.append("selectedRestaurant[" + index + "]", item);
          });
        }
        bannerService.addBanner(fd).then((response) => {
          if (response.data.code === 200) {
            success_message(response.data.message);
            this.$router.push({ name: "banner-list" });
          } else {
            error_message(response.data.message);
          }
        });
      }
    }
  },
};
</script>

<template>
  <b-form @submit.prevent="bannerInIt">
    <!-- Banner Info Start -->
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">{{ trans.get('__JSON__.Banner Info') }}</h4>
        <p class="card-title-desc"></p>
        <div class="row">
          <div class="col-sm-6">
            <b-form-group id="input-group-1" label-class="required" :label="trans.get('__JSON__.Name')" label-for="name">
              <b-form-input
                id="name"
                name="name"
                v-model="$v.typeform.name.$model"
                type="text"
                :placeholder="trans.get('__JSON__.Banner Name Placeholder')"
                :class="{ 'is-invalid':  $v.typeform.name.$error }"
              ></b-form-input>
              <div v-if=" $v.typeform.name.$error" class="invalid-feedback">
                <span
                  v-if="!$v.typeform.name.required"
                >{{ trans.get('__JSON__.Banner Name Error') }}.</span>
              </div>
            </b-form-group>
          </div>

          <div class="col-sm-6">
            <div class="form-group">
              <label class="control-label required">{{ trans.get('__JSON__.Type') }}</label>
              <select
                class="custom-select"
                id="type"
                name="type"
                v-model="$v.typeform.type.$model"
                :class="{ 'is-invalid':  $v.typeform.type.$error }"
              >
                <option value>{{ trans.get('__JSON__.Select Type') }}</option>
                <!-- <option value="restaurant">{{ $auth.isProductMarket() ? trans.trans('__JSON__.Vendor',{ vendor: $auth.setting.restaurant_name+'/s' }) : trans.trans('__JSON__.Vendor',{ vendor: $auth.setting.restaurant_name }) }}</option>
                <option value="link">{{ trans.get('__JSON__.Webpage') }}</option>
                <option value="noaction">{{ trans.get('__JSON__.Blank (No Action)') }}</option> -->
                <option value="web">{{ trans.get('__JSON__.Banner Web') }}</option>
                <option value="app">{{ trans.get('__JSON__.Banner App') }}</option>
              </select>
              <div v-if="$v.typeform.type.$error" class="invalid-feedback">
                <span
                  v-if="!$v.typeform.type.required"
                >{{ trans.get('__JSON__.Type is Required') }}.</span>
              </div>
            </div>
          </div>

          <div class="col-sm-6" v-if="typeform.type == 'restaurant'">
            <b-form-group
              id="input-group-1"
              :label="$auth.isProductMarket() ? trans.trans('__JSON__.Vendor',{ vendor: $auth.setting.restaurant_name+'/s' }) : trans.trans('__JSON__.Vendor',{ vendor: $auth.setting.restaurant_name })"
              label-for="banner_name"
              label-class="required"
              for="ajax"
            >
              <multiselect
                v-model="$v.typeform.selectedRestaurant.$model"
                id="ajax"
                label="name"
                track-by="name"
                :placeholder="trans.trans('__JSON__.Banner Vendor Search Placeholder',{ vendor: $auth.setting.restaurant_name })"
                open-direction="bottom"
                :options="resturant"
                :multiple="true"
                :max="$auth.isProductMarket() ? 100 : 1"
                :searchable="true"
                :loading="isLoading"
                :internal-search="true"
                :clear-on-select="true"
                :close-on-select="false"
                :max-height="600"
                :show-no-results="false"
                :hide-selected="true"
                @search-change="asyncFind"
                @input="getRestaurantMenuCategory"
                :class="{ 'is-invalid': $v.typeform.selectedRestaurant.$error }"
              >
                <template slot="tag" slot-scope="{ option, remove }">
                  <span class="custom__tag">
                    <span>{{ option.name }}</span>
                    <span class="custom__remove" @click="remove(option)">❌</span>
                  </span>
                </template>
                <template slot="clear" slot-scope="props">
                  <div
                    class="multiselect__clear"
                    v-if="selectedRestaurant.length"
                    @mousedown.prevent.stop="clearAll(props.search)"
                  ></div>
                </template>
                <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
              </multiselect>
              <div v-if="$v.typeform.selectedRestaurant.$error" class="invalid-feedback">
                <span
                  v-if="!$v.typeform.selectedRestaurant.required"
                >{{ trans.trans('__JSON__.Vendor Select Error',{ vendor: $auth.setting.restaurant_name }) }}.</span>
              </div>
            </b-form-group>
          </div>

          <div class="col-sm-6" v-if="typeform.type == 'link'">
            <b-form-group id="input-group-1" label-class="required" :label="trans.get('__JSON__.Webpage')" label-for="Webpage">
              <b-form-input
                id="link"
                name="link"
                v-model="$v.typeform.link.$model"
                type="text"
                :placeholder="trans.get('__JSON__.Enter Banner Webpage')"
                :class="{ 'is-invalid':  $v.typeform.link.$error }"
              ></b-form-input>
              <div v-if="$v.typeform.link.$error" class="invalid-feedback">
                <span
                  v-if="!$v.typeform.link.required"
                >{{ trans.get('__JSON__.Webpage is Required') }}.</span>
              </div>
            </b-form-group>
          </div>

          <div class="col-sm-6" v-if="typeform.type == 'restaurant' && !$auth.isProductMarket() && isreataurantcategory">
            <b-form-group id="input-group-1" label-class="required" :label="trans.get('__JSON__.Select Category')" label-for="Category">
              <b-form-select v-model="$v.typeform.menu_category_id.$model" :class="{ 'is-invalid':  $v.typeform.menu_category_id.$error }">
                <b-form-select-option value selected>{{trans.get('__JSON__.Select Category')}}</b-form-select-option>
                <b-form-select-option v-for="cat in resturantcategory" :value="cat.id" :key="cat.id">{{cat.name}}</b-form-select-option>
              </b-form-select>
              <div v-if="$v.typeform.menu_category_id.$error" class="invalid-feedback">
                <span
                  v-if="!$v.typeform.menu_category_id.required"
                >{{ trans.get('__JSON__.Please select category') }}</span>
              </div>
            </b-form-group>
          </div>

          <div class="col-sm-6 vendor-status">
            <b-form-group
              id="input-group-1"
              :label="trans.get('__JSON__.Status')"
              label-for="status"
            >
              <label class="switch">
                <input
                  type="checkbox"
                  id="togBtn"
                  class="switch-on"
                  checked
                  v-model="typeform.status"
                />
                <div class="slider round">
                  <span class="on">{{ trans.get('__JSON__.Active') }}</span>
                  <span class="off">{{ trans.get('__JSON__.Inactive') }}</span>
                </div>
              </label>
            </b-form-group>
          </div>
        </div>
      </div>
    </div>
    <!-- Banner Info End -->

    <!-- Banner Image Start -->
    <div class="card">
      <div class="card-body">
        <h4 class="card-title required">{{ trans.get('__JSON__.Banner Image') }}</h4>
        <p class="card-title-desc"></p>

        <div class="row">
          <div class="col-sm-3">
            <dropzone-popup
              v-model="typeform.image"
              type="file"
              id="banner_image"
              name="image"
              acceptedFiles=".jpeg,.jpg,.png,.webp,image/webp"
              :class="{ 'is-invalid': $v.typeform.image.$error }"
            />
            <div v-if="$v.typeform.image.$error" class="invalid-feedback cuisine-img">
              <span
                v-if="!$v.typeform.image.required"
              >{{ trans.get('__JSON__.Banner Image Error') }}.</span>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <button class="btn btn-primary mr-1 mt-3">{{ trans.get('__JSON__.Add Banner') }}</button>
            <b-button @click="$router.back()" type="button" variant="secondary" class="mt-3">{{ trans.get('__JSON__.Cancel') }}</b-button>
          </div>
        </div>
      </div>
    </div>
    <!-- Banner Image End -->
  </b-form>
</template>

<style>
</style>