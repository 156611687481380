<script>
import pagination from "laravel-vue-pagination";
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header.vue";
import { get_currency, get_decimal, success_message } from '../../Helper/helper';
import config from "../../config";
import { customerService } from "../../services";
import moment from "moment";
export default {
  components: { pagination, Layout, PageHeader },
  data() {
    return {
      moment: moment,
      config: config,
      history: null,
      loading:false,
      fields: [
        { label: "#", key: "recharge_id", sortable: false },
        { label: "Date", key: "created_at", sortable: false },
        { label: "Time", key: "time", sortable: false },
        { label: "Order ID", key: "order_id", sortable: false },
        { label: "Transaction", key: "transactions", sortable: false },
      ],
      isDownloading:'',
      instamedCashValue:'',
      instamedCashData:''
    };
  },
  mounted(){
      this.getHistory();
  },
  methods: {
    getHistory(page = 1) {
      this.loading = true;
      let data = {
       vendor_id: this.$auth.getVendorId(),
       affiliate_id: parseInt(atob(this.$route.params.affiliate_id)),
       pagination:true,
       page: page && page > 0 ? page : 1
      }
      customerService.getInstamedCashDetails(data)
      .then(response => {
        console.log(response, "affiliate history");
        if(response.data.code ==200){
          console.log("hiiiii");
          this.loading = false;
          this.history = response.data.Result;
          this.instamedCashValue = response.data.total_balance;
        }
		});      
    },
    affiliateExportCsv(){
      this.isDownloading = true;
      let data = {
       vendor_id: this.$auth.getVendorId(),
       affiliate_id: parseInt(atob(this.$route.params.affiliate_id)),
       pagination:false
      }
      customerService
        .getInstamedCashDetails(data)
        .then((response) => {
          this.isDownloading = false;
          this.instamedCashData = response.data.Result;
          this.downloadsCSV();
        });
    },
    downloadsCSV: function () {
      let instamedCashData = "\ufeff" + "S. No.,Date,Time,Order ID,Transaction Type,Transaction Amount\n";
      this.instamedCashData.forEach((el, ind) => {
        let Sno = ind + 1;
        var line = Sno + 
          "," +
          (el["created_at"] ? moment(el["created_at"]).format('DD-MM-YYYY') : "-") +
          "," +
          (el["created_at"] ? moment(el["created_at"]).format('h:mm A') : "-") +
          "," +
          (el["order_id"] ? el["order_id"] : "-") + 
          "," +
          el["transactions"] +
          "," +
          el["amount"] +
          "\n";
          instamedCashData += line;
      });
      var blob = new Blob([instamedCashData], { type: "csv/plain" });
      var date = new Date().toLocaleString();
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "InstamedCash-History" + date + ".csv";
      link.click();
    },
  },
};
</script>
<template>
  <div>
    <div style="display: flex; justify-content: space-between; width: 100%;">
      <div class="mt-3">
        <h4 style="font-weight: 600; font-size: 18px;font-size: 18px;">INSTAMEDCASH HISTORY</h4>
        <div  style="display: flex; align-items: center;">
         <div style="font-size: 36px; font-weight: 600;">{{ instamedCashValue || 0 }}</div>
         <img :src="config.basepath+'Instamedcash.svg'" alt="insatmedcash" class="instamed-cash" style="height: 40px; width: 40px;">
        </div>
      </div>
    <!-- <div style="display: flex; align-items: end;"> -->
      <div>
      <!-- <div class="date-range-list">
                    <label>{{ trans.get('__JSON__.Search') }} :</label>
                    <div class="text-sm-right">
                      <div class="search-box mr-2 d-inline-block">
                        <div class="position-relative">
                          <input
                            type="text"
                            class="form-control"
                            id="search"
                            :placeholder="trans.get('__JSON__.Search by name')"
                          />
                          <i class="bx bx-search-alt search-icon"></i>
                        </div>
                      </div>
                    </div>
      </div> -->
      <button
                @click="affiliateExportCsv"
                class="btn btn-primary btn-rounded export-btn affiliate-export"
                v-if="isDownloading"
                disabled
        >{{ trans.get('__JSON__.Exporting...') }}</button>
      <button
                @click="affiliateExportCsv"
                class="btn btn-primary btn-rounded export-btn affiliate-export"
                v-else
        >{{ trans.get('__JSON__.Export To CSV') }}</button>
      <button
                @click="affiliateExportCsv"
                class="btn btn-primary btn-rounded m-export-btn affiliate-export"
        ><i class="bx bx-archive-in"></i></button>
      </div>  
    </div>
    <div class="row mt-5">
        <div class="col-12 table-responsive">
            <b-table
                :items="history && history.data || []"
                :fields="fields"
                :busy="loading"
                show-empty
            >
                <template #table-busy>
                    <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>{{trans.get('__JSON__.Loading...')}}</strong>
                    </div>
                </template>
                <template v-slot:cell(recharge_id)="data">                    
                    <span>{{ data.index + 1 + ((history.current_page-1) * history.per_page)  }}</span>
                </template>
                <template v-slot:cell(created_at)="data">
                  {{moment(data.value).format('DD-MM-YYYY')}}
                </template>
                <template v-slot:cell(time)="data">
                  {{moment(data.item.created_at).format('h:mm A')}}
                </template>
                <template v-slot:cell(order_id)="data">
                  {{ data.value || "-" }}
                </template>
                <template v-slot:cell(transactions)="data">
                  <span :style="{ color: data.item.transactions === 'Credit' ? '#34C38F' : '#F00' }">
                    {{ data.item.amount }}
                  </span>
                  <span>
                    <img :src="config.basepath + 'Instamedcash.svg'" alt="insatmedcash" class="instamed-cash" style="height: 20px; width: 20px;">
                  </span>
                </template>

                
            </b-table>  
        </div>
        <div class="row col-sm-5 offset-sm-7" v-if="history">
            <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <pagination
                    :data="history"
                    :limit="1"
                    @pagination-change-page="getHistory"
                    ></pagination>
                </ul>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<style>
 .affiliate-export{
  height: 40px;
 }

</style>