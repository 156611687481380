var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.keyInIt($event)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body setting-main"},[_c('div',{staticClass:"row section-main"},[_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"setting-heading"},[_c('h4',[_vm._v(_vm._s(_vm.trans.get("__JSON__.Google Map")))])])]),_vm._v(" "),_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"setting-data"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('h6',[_vm._v(_vm._s(_vm.trans.get("__JSON__.Google Map Key")))])]),_vm._v(" "),_c('div',{staticClass:"col-md-6 order-value"},[_c('b-form-group',[_c('b-form-input',{class:{
                          'is-invalid': _vm.$v.setting.google_map_key.$error,
                        },attrs:{"id":"google_map_key","name":"google_map_key","type":"text","placeholder":_vm.trans.get('__JSON__.Google Map Key')},model:{value:(_vm.$v.setting.google_map_key.$model),callback:function ($$v) {_vm.$set(_vm.$v.setting.google_map_key, "$model", $$v)},expression:"$v.setting.google_map_key.$model"}}),_vm._v(" "),(_vm.$v.setting.google_map_key.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.setting.google_map_key.required)?_c('span',[_vm._v(_vm._s(_vm.trans.get("__JSON__.Google Map Key Required"))+".")]):_vm._e()]):_vm._e()],1)],1)]),_vm._v(" "),_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-md-6"},[_c('h6',[_vm._v(_vm._s(_vm.trans.get("__JSON__.Latitude")))]),_vm._v(" "),_c('b-form-group',[_c('b-form-input',{class:{
                          'is-invalid': _vm.$v.setting.system_latitude.$error,
                        },attrs:{"id":"system_latitude","name":"system_latitude","type":"text","placeholder":_vm.trans.get('__JSON__.Latitude')},model:{value:(_vm.$v.setting.system_latitude.$model),callback:function ($$v) {_vm.$set(_vm.$v.setting.system_latitude, "$model", $$v)},expression:"$v.setting.system_latitude.$model"}}),_vm._v(" "),(_vm.$v.setting.system_latitude.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.setting.system_latitude.required)?_c('span',[_vm._v(_vm._s(_vm.trans.get("__JSON__.Latitude Required"))+".")]):_vm._e()]):_vm._e()],1)],1),_vm._v(" "),_c('div',{staticClass:"col-md-6"},[_c('h6',[_vm._v(_vm._s(_vm.trans.get("__JSON__.Longitude")))]),_vm._v(" "),_c('b-form-group',{attrs:{"id":"input-group-1","label-for":"name"}},[_c('b-form-input',{class:{
                          'is-invalid': _vm.$v.setting.system_longitude.$error,
                        },attrs:{"id":"system_longitude","name":"system_longitude","type":"text","placeholder":_vm.trans.get('__JSON__.Longitude')},model:{value:(_vm.$v.setting.system_longitude.$model),callback:function ($$v) {_vm.$set(_vm.$v.setting.system_longitude, "$model", $$v)},expression:"$v.setting.system_longitude.$model"}}),_vm._v(" "),(_vm.$v.setting.system_longitude.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.setting.system_longitude.required)?_c('span',[_vm._v(_vm._s(_vm.trans.get("__JSON__.Longitude Required"))+".")]):_vm._e()]):_vm._e()],1)],1)])])])]),_vm._v(" "),_c('div',{staticClass:"row section-main"},[_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"setting-heading"},[_c('h4',[_vm._v("Push Notifications")])])]),_vm._v(" "),_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"setting-data"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('h6',[_vm._v("Pem Files (iOS Application)")])]),_vm._v(" "),_c('div',{staticClass:"col-md-4 mt-3 customer-app"},[_c('p',[_vm._v("Customer Application")]),_vm._v(" "),_c('dropzone-popup',{attrs:{"readonly":!_vm.$auth.hasPermission(
                          _vm.config.permissions.terminology_write
                        ),"id":"customer_pem","acceptedFiles":".pem","media-path":_vm.setting.pem_url,"delete-path":_vm.owner_pem_delete_url},on:{"image-deleted":this.onCustomerPemdeleted},model:{value:(_vm.setting.customer_pem),callback:function ($$v) {_vm.$set(_vm.setting, "customer_pem", $$v)},expression:"setting.customer_pem"}})],1),_vm._v(" "),_c('div',{staticClass:"col-md-4 mt-3 customer-app"},[_c('p',[_vm._v("Merchant Application")]),_vm._v(" "),_c('dropzone-popup',{attrs:{"readonly":!_vm.$auth.hasPermission(
                          _vm.config.permissions.terminology_write
                        ),"id":"owner_pem","acceptedFiles":".pem","media-path":_vm.setting.pem_url,"delete-path":_vm.owner_pem_delete_url},on:{"image-deleted":this.onOwnerPemdeleted},model:{value:(_vm.setting.owner_pem),callback:function ($$v) {_vm.$set(_vm.setting, "owner_pem", $$v)},expression:"setting.owner_pem"}})],1),_vm._v(" "),_c('div',{staticClass:"col-md-4 mt-3 customer-app"},[_c('p',[_vm._v("Driver Application")]),_vm._v(" "),_c('dropzone-popup',{attrs:{"readonly":!_vm.$auth.hasPermission(
                          _vm.config.permissions.terminology_write
                        ),"id":"driver_pem","acceptedFiles":".pem","media-path":_vm.setting.pem_url,"delete-path":_vm.owner_pem_delete_url},on:{"image-deleted":this.onDriverPemdeleted},model:{value:(_vm.setting.driver_pem),callback:function ($$v) {_vm.$set(_vm.setting, "driver_pem", $$v)},expression:"setting.driver_pem"}})],1)]),_vm._v(" "),_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-md-12"},[_c('h6',[_vm._v("\n                      "+_vm._s(_vm.trans.get("__JSON__.Notification Server Key"))+" ("+_vm._s(_vm.trans.get("__JSON__.Android"))+")\n                    ")])]),_vm._v(" "),_c('div',{staticClass:"col-md-6"},[_c('b-form-input',{class:{
                        'is-invalid':
                          _vm.$v.setting.notification_server_key.$error,
                      },attrs:{"id":"notification_server_key","name":"notification_server_key","type":"text","placeholder":_vm.trans.get('__JSON__.Notification Server Key')},model:{value:(_vm.$v.setting.notification_server_key.$model),callback:function ($$v) {_vm.$set(_vm.$v.setting.notification_server_key, "$model", $$v)},expression:"$v.setting.notification_server_key.$model"}}),_vm._v(" "),(_vm.$v.setting.notification_server_key.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.setting.notification_server_key.required)?_c('span',[_vm._v(_vm._s(_vm.trans.get("__JSON__.Notification Server Key"))+".")]):_vm._e()]):_vm._e()],1)])])])]),_vm._v(" "),_c('div',{staticClass:"btn-update"},[_c('div',{staticClass:"col-sm-6"},[(
                  _vm.$auth.hasPermission(_vm.config.permissions.configuration_write)
                )?_c('button',{staticClass:"btn btn-primary mr-1 mt-3",attrs:{"type":"submit"}},[_vm._v("\n                "+_vm._s(_vm.trans.get("__JSON__.Update"))+"\n              ")]):_vm._e()])])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }