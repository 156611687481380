<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";

import vue2Dropzone from 'vue2-dropzone'
import Multiselect from 'vue-multiselect'

/**
 * Starter component
 */
export default {
  components: { Layout, PageHeader, vueDropzone: vue2Dropzone, Multiselect },
  data() {
    return {
       title: "Themes",
       dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailHeight: 100,
      },
    };
  }
};
</script>
<template>
  <Layout>
    <PageHeader :title="title"/>
      <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body setting-main">

                 <div class="row section-main">
                        <div class="col-md-4">
                            <div class="setting-heading">  
                                <h4>Branding</h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                tempor.</p>
                            </div>
                        </div>
                        <div class="col-md-8">
                            <div class="setting-data">
                                <div class="row">
                                    <div class="col-md-6">
                                        <h6>Business Logo</h6>
                                          <div id="profile-upload">
                                            <div class="hvr-profile-img">
                                              <input type="file" name="logo" id='getval' class="upload w180" title="business logo">
                                            </div>
                                              <i class="fa fa-camera"></i>
                                        </div>
                                    </div>
                                     <div class="col-md-6">
                                        <h6>Square Logo</h6>
                                          <div id="profile-upload-flat">
                                            <div class="hvr-profile-img-flat">
                                              <input type="file" name="flat" id='getvalflat' class="upload w180" title="Square logo">
                                            </div>
                                              <i class="fa fa-camera"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                      </div>
                 </div>

                  <div class="row section-main">
                        <div class="col-md-4">
                            <div class="setting-heading">  
                                <h4>Theme Colors</h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                tempor.</p>
                            </div>
                        </div>
                        <div class="col-md-8">
                            <div class="setting-data">
                                <div class="row">
                                    <div class="col-md-6">
                                        <input type="color" id="colorpicker1" name="color" pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$" value="#000"> 
                                    </div>
                                     <div class="col-md-6">
                                       <input type="color" id="colorpicker2" name="color" pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$" value="#ff6347"> 
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <input type="color" id="colorpicker3" name="color" pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$" value="#777777"> 
                                    </div>
                                     <div class="col-md-6">
                                       <input type="color" id="colorpicker4" name="color" pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$" value="#444444"> 
                                    </div>
                                </div>
                            </div>
                      </div>
                 </div>


                <div class="btn-update">
                    <div class="col-sm-6">
                    <button type="submit" class="btn btn-primary mr-1 mt-3">Update</button>
                    </div>
                </div>

            </div>
        </div>
      </div>
    </div>
  </Layout>
</template>