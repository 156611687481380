<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import General from "./terminology/general.vue";
import ApplicationMessage from "./terminology/application-message.vue";
import OrderNotificationText from "./terminology/order-notification-text.vue";
/**
 * Tabs & accordions component
 */
export default {
  components: { Layout, PageHeader, General, ApplicationMessage, OrderNotificationText },
  data() {
    return {
    };
  },
};
</script>

<template>
  <Layout>
     <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
        >
          <div class="page-title-left">
            <div class="avatar-xs mr-3">
              <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                <i class="bx bx-text"></i>
              </span>
            </div>
             <h4 class="mb-0 font-size-18">{{ trans.trans('__JSON__.TERMINOLOGY') }}</h4>
          </div>
        </div>
      </div>
    </div>
       <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
              <b-tabs
                nav-class="nav-tabs-custom"
                content-class="p-3 text-muted"
                lazy
              >
                <b-tab>
                  <template v-slot:title>
                    <span class="d-inline-block d-sm-none">
                      <i class="fas fa-file"></i>
                    </span>
                    <span class="d-none d-sm-inline-block">{{
                      trans.get("__JSON__.General")
                    }}</span>
                  </template>
                  <General/>
                </b-tab>
                <b-tab>
                  <template v-slot:title>
                    <span class="d-inline-block d-sm-none">
                      <i class="fas fa-book-open"></i>
                    </span>
                    <span class="d-none d-sm-inline-block">{{
                      trans.get("__JSON__.Application Messages")
                    }}</span>
                  </template>
                  <!-- code now -->
                  <ApplicationMessage />
                </b-tab>
                <b-tab>
                  <template v-slot:title>
                    <span class="d-inline-block d-sm-none">
                      <i class="fas fa-book"></i>
                    </span>
                    <span class="d-none d-sm-inline-block">{{
                      trans.get("__JSON__.Order Notification Text")
                    }}</span>
                  </template>
                  <!-- code now -->
                  <OrderNotificationText/>
                </b-tab>
              </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>