<script>
import vue2Dropzone from "vue2-dropzone";
import Multiselect from "vue-multiselect";
import { defaultSettingService, businessTypeService } from "../../../services";
import { required, numeric, requiredIf } from "vuelidate/lib/validators";
import { success_message } from "../../../Helper/helper";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import Swal from "sweetalert2";
import config from "../../../config";

/**
 * Starter component
 */
export default {
    components: { vueDropzone: vue2Dropzone, Multiselect, DatePicker },
    data() {
        return {
            config: config,
            checked: true,
            setting: [],
            errors: "",
            id: "",
            app_setting_id : "",
            taxslab:[],
            taxslab: [{ id: '', tax_per: '',tax_name: '',tax_name_other: '',tax_uniquecode: '' }],
            setting: {
                included_tax: 'No',
                restaurant_service_tax: 0,
                taxslab:[]
            },
        };
    },
    validations: {
        setting: {
        },
    },
    mounted() {
        this.getBusinessTypeById();
    },
    methods: {
        getBusinessTypeById() {
            businessTypeService
                .getBusinessTypeById({ id: this.$route.params.id })
                .then((response) => {
                    this.id = response.data.result.business.id;
                    this.app_setting_id = response.data.result.business.app_setting_id;
                    this.setting = response.data.result.settings;
                    this.taxslab = response.data.result.settings.taxslab;
                    if (this.taxslab !== undefined && this.taxslab.length == 0) {
                        this.addTaxSlabField();
                    }
                });
        },
        addTaxSlabField: function () {
            let tax_uniquecode = 'TS'+(this.taxslab.length + 1);
            this.taxslab.push({ id: '', tax_per: '',tax_name: '',tax_name_other: '',tax_uniquecode: tax_uniquecode});
        },
        removeTaxSlab(index){
            this.taxslab.splice(index,1);
        },
        numberOnly(evt) {
            var max = 100;
            let val1 = evt.target.value;
            if (val1 > max) {
                evt.preventDefault();
            }
        },
        generalIt() {
            this.$v.$touch();
            if (this.$v.setting.$invalid) {
                return;
            } else {
                const fd = new FormData();
                fd.append("setting_id", this.id);
                fd.append("app_setting_id",this.app_setting_id);
                fd.append("included_tax", this.setting.included_tax);
                fd.append("restaurant_service_tax", this.setting.restaurant_service_tax);
                fd.append("taxslab",JSON.stringify(this.setting.taxslab));
                fd.append("type",13);
                defaultSettingService.updateDefaultSetting(fd).then((response) => {
                    if (response.data.code === 200) {
                        success_message(response.data.message);
                    } else {
                        error_message(response.data.message);
                    }
                });
            }
        }
    },
};
</script>
<template>
    <b-form @submit.prevent="generalIt">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body setting-main">
                        <div class="row section-main">
                            <div class="col-md-4">
                                <div class="setting-heading">
                                    <h4>Cart {{ $auth.getTaxName() }}</h4>
                                </div>
                            </div>
                            <div class="col-md-8">
                                <div class="setting-data">
                                    <div class="row">
                                        <div class="col-md-6 vendor-status">
                                            <div class="swtich-data">
                                                <b-form-group>
                                                    <label class="switch">
                                                        <input
                                                            type="checkbox"
                                                            id="included_tax"
                                                            name="included_tax"
                                                            v-model="setting.included_tax"
                                                            class="switch-on"
                                                            true-value="Yes"
                                                            false-value="No"
                                                        />
                                                        <div class="slider round">
                                                            <span class="on">{{ trans.get('__JSON__.On') }}</span>
                                                            <span class="off">{{ trans.get('__JSON__.Off') }}</span>
                                                        </div>
                                                    </label>
                                                </b-form-group>
                                            </div>
                                            <div class="col-md-12">
                                                <h6>
                                                    <!-- {{ trans.get('__JSON__.Tax on items in cart') }} -->
                                                    Additional {{ $auth.getTaxName() }} on Cart
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tax-main">
                                        <div class="row mt-4" v-if="setting.included_tax == 'Yes'">
                                            <div class="col-md-12">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <h6>Enter Tax Value (In Percentage)</h6>
                                                    </div>
                                                    <div class="col-md-6 order-value">
                                                        <input
                                                            id="restaurant_service_tax"
                                                            name="restaurant_service_tax"
                                                            type="number"
                                                            class="form-control"
                                                            @keypress="numberOnly($event)"
                                                            v-model="setting.restaurant_service_tax"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="btn-update">
                            <div class="col-sm-6">
                                <button
                                    type="submit"
                                    class="btn btn-primary mr-1 mt-3"
                                >Update</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </b-form>
</template>