
<script>
import config from "../../config";
import pagination from "laravel-vue-pagination";
import { categoryService } from "../../services";
import { error_message,debounce } from "../../Helper/helper";
import draggable from "vuedraggable";

export default {
  components: { pagination, categoryService, draggable },
  data() {
    const { status,orderBy,sortBy} = this.$route.query;
    return {
      config:config,
      sortBy: sortBy || "reorder",
      sortDesc: orderBy && orderBy != '' ? true : orderBy,
      categories: [],
      categoryData: [],
      paginations: {},
      limit: 1,
      search: "",
      status: status == undefined ? 1 :status,
      config: config,
      loading: true,
      isDownloading: false,
      searchData:debounce(()=>{
        if(!this.init){
          const {status,orderBy,sortBy} = this.$data;
          this.$router.replace({name: 'category-list', query: {status,orderBy,sortBy}})
        }
        this.getCategory();
        // this.getCategoryDetail();
        this.init = false;
      },500),
      fields: [
        { key: "category_img", label: "#", sortable: false },
        {
          key: "category_name",
          label: this.trans.get("__JSON__.Title"),
          sortable: true,
        },
        {
          key: "category_desc",
          label: this.trans.get("__JSON__.Description"),
          sortable: true,
        },
        {
          key: "status",
          label: this.trans.get("__JSON__.Status"),
          sortable: true,
        },
        {
          key: "action",
          label: this.trans.get("__JSON__.Action"),
          sortable: false,
        },
      ],
    };
  },
  mounted() {
    this.getCategory();
    // this.getCategoryDetail();
  },
  methods: {
    downloadsCSV: function () {
      let categoryData = "\ufeff" + "Title,Description,Status\n";
      this.categoryData.forEach((el) => {
        var line =
          (el["category_name"] ? el["category_name"] : "").replaceAll(
            ",",
            " "
          ) +
          "," +
          (el["category_desc"] ? el["category_desc"] : "").replaceAll(
            ",",
            " "
          ) +
          "," +
          (el["status"] == 1 ? "Active" : "InActive") +
          "\n";
        categoryData += line;
      });

      var blob = new Blob([categoryData], { type: "csv/plain" });
      var currentDate = new Date();
      var date = new Date().toLocaleString();
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "Categories" + date + ".csv";
      link.click();
    },
    getCategory(page) {
      this.loading = true;
      var filters = {
        search: this.search,
        status: this.status,
        sortBy: this.sortBy,
        orderBy: this.sortDesc ? "desc" : "asc",
        page: page && page > 0 ? page : 1,
      }
      categoryService
        .getCategory(filters)
        .then((response) => {
          this.loading = false;
          this.paginations = response.data.result;
          this.categories = response.data.result.data;
        });
    },
    getCategoryDetail() {
      this.isDownloading = true;
      categoryService.exportToCsv({status: this.status}).then((response) => {
        this.isDownloading = false;
        this.categoryData = response.data.result;
        this.downloadsCSV();
      });
    },
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      this.getCategory();
    },
    deleteById(category_id) {
      if (confirm("Are you sure you want to delete?")) {
        categoryService
          .deleteCategory({ category_id: category_id })
          .then((response) => {
            error_message(response.data.message);
            this.getCategory();
          });
      }
    },
    updateCategoryOrder: function() {
      var items = this.categories.map(function(item, index) {
        return { item: item, order: index }
      });
      if(this.debounce) return
      var ids = items.map(item => item.item.category_id);

      this.debounce = setTimeout(function(items) {
        this.debounce = false;
        categoryService.updateCategoryOrder({ category_ids : ids})
        .then(response => {
          if (response.data.code === 200) {
            this.getCategory();
          }
        });
      }.bind(this, items), 10)
    },
  },
};
</script>

<template>
  <div>
     <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
        >
          <div class="page-title-left">
            <div class="avatar-xs mr-3">
              <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                <i class="bx bx-briefcase"></i>
              </span>
            </div>
             <h4 class="mb-0 font-size-18">{{ trans.trans('__JSON__.Categories') }}</h4>
          </div>
          <div class="page-title-right d-flex">
            <router-link :to="{name: 'category-add'}" class="btn btn-success btn-rounded mr-2 add-btn"
              v-if="$auth.hasPermission(config.permissions.category_write)">
              <i class="mdi mdi-plus mr-1"></i>
              {{ trans.get('__JSON__.Add Category') }}
            </router-link>
            <button
              @click="getCategoryDetail"
              class="btn btn-primary btn-rounded export-btn"
              v-if="isDownloading"
              disabled
            >{{ trans.get('__JSON__.Exporting...') }}</button>
              <button
              @click="getCategoryDetail"
              class="btn btn-primary btn-rounded export-btn"
              v-else
            >{{ trans.get('__JSON__.Export To CSV') }}</button>
            <div class="d-flex m-vendor-btn">
              <router-link :to="{name: 'category-add'}" class="btn btn-success btn-rounded mr-2 m-add-btn">
                <i class="mdi mdi-plus"></i>
              </router-link>
              <button
                @click="getCategoryDetail"
                class="btn btn-primary btn-rounded m-export-btn"
              ><i class="bx bx-archive-in"></i></button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
    <div class="row list-table-main">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-2 offset-sm-8">
                <div class="form-group">
                  <div class="date-range-list">
                    <label>{{ trans.get('__JSON__.Status') }} :</label>
                    <select class="custom-select" v-model="status" @change="searchData()">
                      <option value="">{{ trans.get('__JSON__.All') }}</option>
                      <option value="1" selected>{{ trans.get('__JSON__.Active') }}</option>
                      <option value="0">{{ trans.get('__JSON__.Inactive') }}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="date-range-list">
                  <label>{{ trans.get('__JSON__.Search') }} :</label>
                  <div class="text-sm-right">
                    <div class="search-box mr-2 d-inline-block">
                      <div class="position-relative">
                        <input
                          type="text"
                          class="form-control"
                          id="search"
                          @input="searchData()"
                          v-model="search"
                          :placeholder="trans.get('__JSON__.Search Placeholder')"
                        />
                        <i class="bx bx-search-alt search-icon"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 table-main-list category-table table-responsive custom-tbl">
                <div class="card task-box custom-tblheader">
                  <div class="card-body menu-items">
                    <div class="media">
                      <div class="tbl-heading mr-4 font-weight-bolder"> # </div>
                      <div class="tbl-heading mr-4 font-weight-bolder"> {{ trans.get('__JSON__.Name') }} </div>
                      <div class="tbl-heading mr-4 font-weight-bolder"> {{ trans.get('__JSON__.Description') }} </div>
                      <div class="tbl-heading mr-4 font-weight-bolder"> {{ trans.get('__JSON__.Status') }} </div>
                      <div class="tbl-heading mr-4 font-weight-bolder"> {{ trans.get('__JSON__.Action') }} </div>
                    </div>
                  </div>
                </div>
                <draggable class="list-group" group="category" :list="categories" v-model="categories" @end="updateCategoryOrder">
                  <div v-for="category in categories" :key="category.category_id" class="card task-box">
                    <div class="card-body menu-items drag-item">
                      <div class="media">
                        <div class="avatar-md tbl-heading mr-4">
                          <span class="text-danger font-size-16" v-bind:id="category.category_id" >
                          <div v-if="category.category_img">
                            <img title="product-img" class="avatar-md rounded-circle-banner" :src="config.category+category.category_img" alt height="30" @error="$event.target.src=config.no_image"/>
                          </div>
                          <div v-else>
                            <img class="avatar-md rounded-circle-banner" :src="config.no_image"/>
                          </div>
                          </span>
                        </div>

                        <div class="tbl-heading mr-4">
                          <h5 class="font-size-14">{{category.category_name | truncate(25, '...')}}</h5>
                        </div>
                          
                        <div class="tbl-heading mr-4">
                          <span>{{category.category_desc | truncate(40, '...')}}</span>
                        </div>

                        <div class="tbl-heading mr-4">
                          <div v-if="category.status == '1'"  class="badge badge-pill badge-soft-success font-size-12" >{{ trans.get('__JSON__.Active') }}</div>
                          <div v-else class="badge badge-pill badge-soft-danger font-size-12" >{{ trans.get('__JSON__.Inactive') }}</div>
                        </div>

                        <div class="tbl-heading mr-4">
                          <router-link
                            v-if="$auth.hasPermission(config.permissions.category_write)"
                            :to="{name: 'category-edit',params: { category_id: base64(category.category_id) }}"
                            class="btn btn-success btn-sm btn-rounded cursor-pointer"
                          >{{ trans.get('__JSON__.Edit') }}</router-link>
                          <button
                            v-if="$auth.hasPermission(config.permissions.category_write)"
                            type="submit"
                            class="btn btn-danger btn-sm btn-rounded ml-1"
                            @click="deleteById(base64(category.category_id))"
                          >{{ trans.get('__JSON__.Delete') }}</button>
                        </div>

                      </div>
                    </div>
                  </div>
                </draggable>
              </div>
              <div class="row col-sm-5 offset-sm-7">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <pagination
                        :data="paginations"
                        :limit="limit"
                        @pagination-change-page="getCategory"
                      ></pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </div>
</template>

<style>
.category-table .table thead th:nth-child(1),.category-table .table thead th:nth-child(5){
    pointer-events: none;
}
.custom-tblheader .card-body{
    height: auto;
    background-color: #b3b0b033 !important;
    border-bottom: 0 solid #b3b0b033 !important;
}
.custom-tbl .media{
    align-items: center;
}
.tbl-heading:first-child{
    width: 4.5rem;
}

.tbl-heading:nth-child(2){
    width: 6rem;
}
.tbl-heading:nth-child(3){
    width: 30rem;
}
.tbl-heading:nth-child(4){
    width: 6rem;
}
.drag-item.menu-items{
  height: auto;
}
</style>