<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import pagination from "laravel-vue-pagination";
import { vendorService } from "../../services";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import { date_ranges, error_message, success_message, get_currency } from "../../Helper/helper";
import moment from "moment";


export default {
  components: { Layout, PageHeader, pagination, DateRangePicker },
  data() {
    let startDate = this.moment().startOf('day').toDate();
    let endDate = this.moment().endOf('day').toDate();
    return {
      currency: get_currency(),
      dateRange: { startDate, endDate },
      dateRange1: { startDate, endDate },
      paginations: {},
      paginationsDetails: {},
      settlmentList: [],
      settlementDetails:[],
      settlementDetailsData:{},
      sortBy: "created_at",
      sortDesc: true,
      loading: true,
      loadingModel: true,
      limit: 2,
      restaurants_id: this.$auth.partner_login ? this.$auth.user.restaurant.restaurant_id : 0,
      fields: [
        {
          key: "unique",
          label: this.trans.get("__JSON__.S. No."),
          sortable: false,
        },
        {
          key: "processed_at",
          label: this.trans.get("__JSON__.Settlement Date"),
          sortable: false,
        },
        {
          key: "settled_orders",
          label: this.trans.get("__JSON__.Total Payments"),
          sortable: false,
        },
        {
          key: "settlement_to",
          label: this.trans.get("__JSON__.Settlement To"),
          sortable: false,
        },
        {
          key: "amount",
          label: this.trans.get("__JSON__.Settlement Amount"),
          sortable: false,
        },
        {
          key: "status",
          label: this.trans.get("__JSON__.Status"),
          sortable: false,
        },
        {
          key: "action",
          label: this.trans.get("__JSON__.Action"),
          sortable: false,
        },
      ],
      lastTableFields: [
        {
          key: "unique",
          label: this.trans.get("__JSON__.S. No."),
          sortable: false,
        },
        {
          key: "processed_at",
          label: this.trans.get("__JSON__.Settlement Date"),
          sortable: false,
        },
        {
          key: "order_id",
          label: this.trans.get("__JSON__.Order ID"),
          sortable: false,
        },
        {
          key: "customer_name",
          label: this.trans.get("__JSON__.Customer Name"),
          sortable: false,
        },
        {
          key: "order_amount",
          label: this.trans.get("__JSON__.Order Amount"),
          sortable: false,
        },
        {
          key: "status",
          label: this.trans.get("__JSON__.Status"),
          sortable: false,
        },
      ],
      filters: {
        start_date: this.moment().format('YYYY-MM-DD'),
        end_date: this.moment().format('YYYY-MM-DD')
      },
      date_ranges: date_ranges,
      showModal: false,
      processId: "",
      secondFilterStartDate: '',
      secondFilterEndDate: ''
    }
  },
  mounted() {
    this.getSettlementList();
  },
  computed:{
    getSelected:function(){
      const { start_date, end_date } = this.filters;
      const date_ranges = this.date_ranges();
      return Object.keys(date_ranges).reduce((accu, day) => {
        if(this.moment(date_ranges[day][0]).format('YYYY-MM-DD') == start_date && this.moment(date_ranges[day][1]).format('YYYY-MM-DD') == end_date){
          return day;
        }
        return accu;
      },'');
    }
  },
  methods: {
    changeSettingRange(){
      var now = this.moment();
      var then = this.moment();
      if(this.$auth && this.$auth.setting){
        var range = this.$auth.setting.order_dashboard_default_time;
        var date_ranges = this.date_ranges();
        var keys = Object.keys(date_ranges)
        switch(range){
          case "0":
            return {startDate: date_ranges[keys[0]][0],endDate:date_ranges[keys[0]][1]};
          case "1":
            return {startDate: date_ranges[keys[1]][0],endDate:date_ranges[keys[1]][1]};
          case "6":
            return {startDate: date_ranges[keys[2]][0],endDate:date_ranges[keys[2]][1]};
          case "29":
            return {startDate: date_ranges[keys[3]][0],endDate:date_ranges[keys[3]][1]};
          case "365":
            return {startDate: date_ranges[keys[4]][0],endDate:date_ranges[keys[4]][1]};
          case "101":
            return {startDate: date_ranges[keys[5]][0],endDate:date_ranges[keys[5]][1]};
          default:
            return { startDate: now.startOf('day').toDate(), endDate: then.endOf('day').toDate() }
        }
      }
      return { startDate: now.startOf('day').toDate(), endDate: then.endOf('day').toDate() }
    },
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      this.getSettlementList();
    },
    sortingChangedtwo(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      this.getSettlementDetails();
    },
    getSettlementList(page) {
        let data = {
            restaurant_id: this.restaurants_id,
            vendor_id: this.$auth.getVendorId(),
            start_date: this.filters.start_date,
            end_date: this.filters.end_date,
            total_records: 50,
            pagination: true,
            page: page && page > 0 ? page : 1
        };
      vendorService.getTransactionsByResturantId(data).then((response) => {
        this.loading = false;
        this.settlmentList = response.data.result.data;
        this.paginations = response.data.result;
      })
    },
    getSettlementDetails(page) {
        let data = {
            process_id: this.processId,
            restaurant_id: this.restaurants_id,
            vendor_id: this.$auth.getVendorId(),
            start_date: this.secondFilterStartDate,
            end_date: this.secondFilterEndDate,
            total_records: 50,
            pagination: true,
            page: page && page > 0 ? page : 1
        };
      vendorService.getTransactionsDatewise(data).then((response) => {
        this.loadingModel = false;
        this.settlementDetails = response.data.result.data;
        this.paginationsDetails = response.data.result;
        this.settlementDetailsData = response.data.data;
        console.log(this.settlementDetails,"settlementDetails");
        console.log(response, "response");
      })
    },
    updateValues(date) {
      this.filters = {
        ...this.filters,
        start_date :  this.moment(date.startDate.toISOString()).format('YYYY-MM-DD'),
        end_date :  this.moment(date.endDate.toISOString()).format('YYYY-MM-DD') 
      }
      this.getSettlementList();
    },
    updateValuesTwo(date) {
      this.secondFilterStartDate = this.moment(date.startDate.toISOString()).format('YYYY-MM-DD');
      this.secondFilterEndDate = this.moment(date.endDate.toISOString()).format('YYYY-MM-DD');
      this.getSettlementDetails();
    },
    dateFormat(classes, date) {
      this.filters = {
        ...this.filters,
        start_date: this.moment(date.startDate.toISOString()).format(
          "YYYY-MM-DD"
        ),
        end_date: this.moment(date.endDate.toISOString()).format("YYYY-MM-DD"),
      };
    },
    showDetails(id){
      this.showModal = true;
      this.processId = id;
      this.secondFilterStartDate = this.filters.start_date;
      this.secondFilterEndDate = this.filters.end_date;
      this.getSettlementDetails();
    }
  },
  filters: {
    date(val) {
      return val ? val.toLocaleString() : "";
    },
  },
};
</script>

<template>
  <Layout>
    <div>
      <div class="row">
        <div class="col-12">
          <div class="page-title-box d-flex align-items-center justify-content-between icon-main-page">
            <div class="page-title-left">
              <div class="avatar-xs mr-3">
                <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                  <i class="bx bx-user-circle"></i>
                </span>
              </div>
              <h4 class="mb-0 font-size-18">{{ trans.get('__JSON__.Settlements') }}({{ settlmentList && settlmentList.length }})</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="row list-table-main">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-sm-2" style="margin-left: 79.3333% !important;">
                  <div class="form-group">
                    <div class="date-range-list">
                        <label>{{ trans.get("__JSON__.Date") }}:</label>
                        <date-range-picker
                          ref="picker"
                          :locale-data="{
                            firstDay: 1,
                            format: 'dd-mm-yyyy',
                          }"
                          :showDropdowns="true"
                          :showWeekNumbers="true"
                          opens="left"
                          v-model="dateRange1"
                          style="width: 127% !important;"
                          :ranges="date_ranges()"
                          @update="updateValues"
                        >
                          <template
                            v-slot:input="picker"
                            style="min-width: 350px"
                          >
                            {{
                              moment(picker.startDate.toISOString()).format(
                                "MMMM DD, YYYY"
                              ) | date
                            }}
                            -
                            {{
                              moment(picker.endDate.toISOString()).format(
                                "MMMM DD, YYYY"
                              ) | date
                            }}
                          </template>
                        </date-range-picker>
                      </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 table-main-list customer-table table-responsive">
                  <b-table :items="settlmentList" :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                    :no-local-sorting="true" @sort-changed="sortingChanged" :busy="loading" show-empty>
                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>{{ trans.get('__JSON__.Loading...') }}</strong>
                      </div>
                    </template>

                    <template v-slot:cell(unique)="data">
                      <h5 class="font-size-14">{{ data.index + 1 }}</h5>
                    </template>

                    <template v-slot:cell(processed_at)="data">
                      <h5 class="font-size-14">
                        {{
                            moment(data.item.processed_at).format("DD-MM-YY hh:mm A")
                        }}
                      </h5>
                    </template>

                    <template v-slot:cell(settled_orders)="data">
                      <h5 class="font-size-14">{{ data.item.settled_orders }}</h5>
                    </template>

                    <template v-slot:cell(settlement_to)="data">
                      <h5 class="font-size-14">{{ data.item.bank_name }} - {{ data.item.last_digits }}</h5>
                    </template>

                    <template v-slot:cell(amount)="data">
                      <h5 class="font-size-14">{{ data.item.amount }}</h5>
                    </template>

                    <template v-slot:cell(status)="data">
                      <h5 class="font-size-14">
                        {{ data.item.status }}
                    </h5>
                    </template>

                    <template v-slot:cell(action)="data">
                      <button class="btn btn-primary btn-sm btn-rounded" @click="showDetails(data.item.id)">View Details</button>
                    </template>

                    <template #empty>
                      <p class="text-center">{{ trans.get('__JSON__.Settlement list not found') }}</p>
                    </template>
                  </b-table>
                </div>
                <div class="row col-sm-5 offset-sm-7">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <pagination
                        :data="paginations"
                        :limit="limit"
                        @pagination-change-page="getSettlementList"
                      ></pagination>
                    </ul>
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <b-modal v-model="showModal" centered hide-footer>
          <template #modal-title>
            <div class="d-flex" style="align-items: center !important;">
              <h5 style="margin-top: 8px;">Settlement Details</h5>
            </div>
          </template>
          <div class="row">
              <div class="settlement-details">
                <p style="font-weight: bold;" class="settlement-font">Amount</p>
                <p style="font-weight: bold;" class="settlement-font"><span v-html="currency"></span> {{ settlementDetailsData.total_amount }}</p>
                <p class="settlement-font14">{{ settlementDetailsData.amount_in_words }}</p>
                <hr class="line">
                <p class="settlement-font14">Settled at {{ moment(settlementDetailsData.processed_at).format('hh.mmA, DD-MMM-YYYY') }}</p>
                <p class="settlement-font14">{{ settlementDetailsData.bank_name }} - {{ settlementDetailsData.last_digits }}</p>
              </div>

              <div class="settlement-details mt-4">
                <p style="font-weight: bold;" class="settlement-font">Settlement Receipt</p>
                <div style="display: flex; justify-content: space-between;" class="settlement-font14">
                  <p>{{ settlementDetails && settlementDetails.length }} Payments Collected</p>
                  <p style="font-weight: bold;"><span v-html="currency"></span> {{ settlementDetailsData.total_amount }}</p>
                </div>               
                <hr class="line">
                <div style="display: flex; justify-content: space-between;" class="settlement-font14">
                  <p>Settled Amount</p>
                  <p style="font-weight: bold;"><span v-html="currency"></span> {{ settlementDetailsData.total_amount }}</p>
                </div>
              </div>

              <div class="settlement-details mt-4">
                <p style="font-weight: bold;" class="settlement-font">All Transactions</p>
                <div style="display: flex; justify-content: flex-end;">
                  <div class="date-range-list">
                    <label>{{ trans.get("__JSON__.Date") }}:</label>
                  <date-range-picker
                          ref="picker"
                          :locale-data="{
                            firstDay: 1,
                            format: 'dd-mm-yyyy',
                          }"
                          :showDropdowns="true"
                          :showWeekNumbers="true"
                          opens="left"
                          v-model="dateRange"
                          :ranges="date_ranges()"
                          @update="updateValuesTwo"
                        >
                          <template
                            v-slot:input="picker"
                            style="min-width: 350px"
                          >
                            {{
                              moment(picker.startDate.toISOString()).format(
                                "MMMM DD, YYYY"
                              ) | date
                            }}
                            -
                            {{
                              moment(picker.endDate.toISOString()).format(
                                "MMMM DD, YYYY"
                              ) | date
                            }}
                          </template>
                </date-range-picker>
                  </div>                  
                </div>
                <div class="col-12 table-main-list customer-table table-responsive">
                  <b-table :items="settlementDetails" :fields="lastTableFields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                    :no-local-sorting="true" @sort-changed="sortingChangedtwo" :busy="loadingModel" show-empty>
                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>{{ trans.get('__JSON__.Loading...') }}</strong>
                      </div>
                    </template>

                    <template v-slot:cell(unique)="data">
                      <h5 class="font-size-14">{{ data.index + 1 }}</h5>
                    </template>

                    <template v-slot:cell(processed_at)="data">
                      <h5 class="font-size-14">
                        {{
                            moment(data.item.processed_at).format("DD-MM-YY hh:mm A")
                        }}
                      </h5>
                    </template>

                    <template v-slot:cell(order_id)="data">
                      <h5 class="font-size-14">{{ data.item.order_id }}</h5>
                    </template>

                    <template v-slot:cell(customer_name)="data">
                      <h5 class="font-size-14">{{ data.item.customer_name }}</h5>
                    </template>

                    <template v-slot:cell(order_amount)="data">
                      <h5 class="font-size-14">{{ data.item.order_amount }}</h5>
                    </template>

                    <template v-slot:cell(status)="data">
                      <h5 class="font-size-14">
                        {{ data.item.status }}
                      </h5>
                    </template>

                    <template #empty>
                      <p class="text-center">{{ trans.get('__JSON__.Settlement details not found') }}</p>
                    </template>
                  </b-table>
                </div>
                <div class="row col-sm-5 offset-sm-7">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <pagination
                        :data="paginationsDetails"
                        :limit="limit"
                        @pagination-change-page="getSettlementDetails"
                      ></pagination>
                    </ul>
                  </div>
                </div>
              </div>
              </div>
          </div>
        </b-modal>
    </div>
  </Layout>
</template>
<style>
  .settlement-details {
    border: 1px solid #eff2f7;
    border-radius: 8px;
    width: 100%;
    padding: 5px 10px;
  }
  .line {
    border: none;
    border: 1px solid #eff2f7;
    margin-top: 10px;
  }
  .settlement-font {
    font-size: 16px;
  }
  .settlement-font14 {
    font-size: 14px;
  }
</style>