<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import { helpService } from "../../services";
export default {
  components: {
    Layout,
    PageHeader,
    helpService
  },
  data() {
    return {
      help: []
    };
  },
  mounted() {
    this.getHelpListing();
  },
  methods: {
      getHelpListing(){
          helpService.getHelpListing()
            .then((response)=>{
                this.help = response.data.result;
            });
          }
  }
};
</script>
<template>
 <Layout>
  <div>
   <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
        >
          <div class="page-title-left">
            <div class="avatar-xs mr-3">
              <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                <i class="bx bx-help-circle"></i>
              </span>
            </div>
             <h4 class="mb-0 font-size-18">{{ trans.trans('__JSON__.Onboarding Videos') }}</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 order-table">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive table-border">
              <table class="table table-centered table-nowrap table-hover">
                <tbody>
                  <tr v-for="h in help" :key="h.id">
                    <td>{{ h.name }}</td>
                    <td>
                      <a :href="h.link" target="_blank" class="btn btn-primary btn-sm btn-rounded">
                        {{trans.get('__JSON__.View Video')}}
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </div>
 </Layout>
</template>
