<script>
import PageHeader from '../../../components/page-header';
import pagination from "laravel-vue-pagination";
import { get_currency, get_decimal } from '../../../Helper/helper';
import Layout from "../../../layouts/main";
import { driverService,  vendorFinanceService } from '../../../services';
import OrderShow from './../../orders/order_tabs/order-show';
export default {
    components:{ Layout,PageHeader,pagination,OrderShow },
    data(){
        return {
            showModalTab:0,
            driver: {},
            transactions: null,
            currency: get_currency(),
            decimal: get_decimal(),
            showModal: false,
            loading: false,
            orderId: null,
            limit:1,
            fields: [
                {
                    key: "hash_id",
                    label: '#',
                    sortable: false,
                },
                {
                    key: "delivered_date",
                    label: this.trans.get("__JSON__.Delivered time"),
                    sortable: false,
                },
                {
                    key: "cod_amount",
                    label: this.trans.get("__JSON__.Cash Collected"),
                    sortable: false,
                },
                {
                    key: "driver_earning",
                    label: this.trans.get("__JSON__.Earning"),
                    sortable: false,
                },
                {
                    key: "base64_id",
                    label: this.trans.get("__JSON__.View"),
                    sortable: false,
                }
            ],
        }
    },
    mounted(){
        this.getDriverInfo();
    },
    methods:{
        getDriverInfo(){
            driverService.getDriverInfo({driver_id:this.$route.params.driverid})
                .then(response => {
                    this.driver = response.data.result;
                    this.getTransactions()
            })
        },
        getTransactions(page = 1){
            this.loading = true;
            driverService.driverTransactions({
                driver_id: this.$route.params.driverid,
                start_date: this.$route.query.start_date,
                end_date: this.$route.query.end_date,
                page: page
            })
            .then(response => {
                this.loading = false;
                this.transactions = response.data.result.transactions;
                var total = response.data.result.total;
                total.hash_id = this.trans.get('__JSON__.Total');
                total.delivered_date = '-';
                total.base64_id = '';
                this.transactions.data.push(response.data.result.total);
            }).catch(e =>{
              this.loading = false;  
            })
        },
        viewPageDetails(item,index, event){
            this.viewIndex = index;
            if (item && item.hash_id && item.hash_id != "Total") {
                if (item.parent_id && item.parent_id > 0) {
                    this.viewDetails(this.base64(item.parent_id));
                } else if (item.suborder && item.suborder.id && item.suborder.id > 0) {
                    this.viewDetails(item.base64_id);
                } else {
                    this.viewDetails(item.base64_id);
                }
            }
            // this.viewDetails(item.base64_id);
            // if(item.base64_id == ''){
            //     this.showModal = false;
            // }
            this.$refs.selectableTable.clearSelected()
        },
        viewDetails(id){
            this.showModal = true;
            this.orderId = id;
        },
    }
}
</script>
<template>
  <Layout>
    <page-header  v-if="!$auth.partner_login" :title="(driver && (driver.firstname +' '+driver.lastname )) + ' ' + trans.get('__JSON__.Transactions')">
        <template #icon>
            <i class="bx bx-purchase-tag"></i>
        </template>
    </page-header>
    <page-header  v-else :title="trans.get('__JSON__.Transactions')">
        <template #icon>
            <i class="bx bx-purchase-tag"></i>
        </template>
    </page-header>  
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div class="table-responsive mb-0 table-border" data-pattern="priority-columns" v-if="transactions">
                        <b-table
                            ref="selectableTable"
                            :items="transactions.data"
                            :fields="fields"
                            show-empty
                            :busy="loading"
                            :filter="null"
                            class="table-centered table-nowrap table-hover order-menu"
                            @row-clicked="viewPageDetails">
                            <template #table-busy>
                                <div class="text-center text-danger my-2">
                                    <b-spinner class="align-middle"></b-spinner>
                                    <strong>{{trans.get('__JSON__.Loading...')}}</strong>
                                </div>
                            </template>
                            <template #cell(hash_id)="data">
                                <template v-if="data.value && data.value != 'Total' && data.item.parent_id && data.item.parent_id > 0">
                                    <span class="font-weight-bold">{{'#'+data.item.parent_id}}</span><br> Ship. 1 
                                </template>
                                <template v-else-if="data.value && data.value != 'Total' && data.item.suborder && data.item.suborder.id && data.item.suborder.id > 0">
                                    <span class="font-weight-bold">{{data.value}}</span><br> Ship. 2
                                </template>
                                <template v-else-if="data.value">
                                    <span class="font-weight-bold">{{data.value}}</span>
                                </template>
                            </template>
                            <template #cell(delivered_date)="data">
                                {{ data.value !== '-'?moment(data.value).format('DD-MM-YYYY hh:mm A'):data.value }}
                            </template>
                            <template #cell(cod_amount)="data">
                                <span v-html="currency"></span>
                                {{ data.value.toFixed(decimal) }}
                            </template>
                            <template #cell(driver_earning)="data">
                                <span v-html="currency"></span>
                                {{ data.value.toFixed(decimal) }}
                            </template>
                             <template #cell(base64_id)="data">
                                <!-- <b-button v-if="data.value" @click="viewDetails(data.value)" variant="primary" size="sm">{{trans.get('__JSON__.View')}}</b-button> -->
                                <b-button v-if="data.value && data.item.parent_id && data.item.parent_id > 0" @click="viewDetails(base64(data.item.parent_id))" variant="primary" size="sm">{{trans.get('__JSON__.View')}}</b-button>
                                <b-button v-else-if="data.value && data.item.suborder && data.item.suborder.id && data.item.suborder.id > 0" @click="viewDetails(data.value)" variant="primary" size="sm">{{trans.get('__JSON__.View')}}</b-button>
                                <b-button v-else-if="data.value" @click="viewDetails(data.value)" variant="primary" size="sm">{{trans.get('__JSON__.View')}}</b-button>
                            </template>
                            <!-- <template #cell(status)="data">
                                <span
                                class="badge badge-pill badge-soft-success font-size-12"
                                :class=" { 'badge-soft-success':data.value == 'Paid',
                                                    'badge-soft-danger': data.value != 'Paid' }"
                                >{{data.value == 'Paid'?'Received':'Pending'}}</span>
                            </template> -->
                        </b-table>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-12" v-if="transactions && transactions.last_page > 1">
            <div class="card">
                <div class="card-body">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                            <ul class="pagination pagination-rounded mb-0">
                            <!-- pagination -->
                            <pagination
                                :data="transactions"
                                :limit="limit"
                                @pagination-change-page="getTransactions"
                            ></pagination>
                            </ul>
                        </div>
                </div>
            </div>
        </div>
        <OrderShow v-if="showModal"  :order-id="orderId" @hidden="showModal = false" :showModalTab="showModalTab"/>
    </div>
  </Layout>
</template>
<style>
</style>