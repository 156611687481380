<script>
import vue2Dropzone from "vue2-dropzone";
import Multiselect from "vue-multiselect";
import PageHeader from "../../components/page-header";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import {
  promoCodeService,
  bannerService,
  deliveryZoneService,
} from "../../services";
import { error_message, get_currency, success_message } from "../../Helper/helper";
import DropzonePopup from "../../components/widgets/dropzone-popup";

import {required,requiredIf,helpers} from "vuelidate/lib/validators";
const floating = helpers.regex('floating', /^(?:\d*\.\d{1,3}|\d+)$/)

export default {
  name: "Range",
  components: {
    vueDropzone: vue2Dropzone,
    Multiselect,
    PageHeader,
    DatePicker,
    promoCodeService,
    bannerService,
    DropzonePopup,
  },
  data() {
    return {
      checkUpdate:[],
      currency: get_currency(),
      tmpCouponImage: "",
      search: "",
      search1: "",
      showPopup: true,
      serachInput: "",
      languages:[],
      isCheckAll: false,
      isLoading: false,
      checkbox: false,
      customercheckbox: false,
      isLoading1: false,
      customer: [],
      value2: [],
      showTimeRangePanel: false,
      checkOpen: true,
      // value1: [this.moment().startOf('now').toDate(), this.moment().endOf('day').toDate()],
      rangeSeparator: "-",
      format: "YYYY/MM/DD HH:s",
      discount_type: "0",
      selectedCustomer: [],
      resturant: [],
      selectedRestaurant: [],
      newCoupon_restaurants: [],
      updateValues: "",
      countries: [],
      states: [],
      cities: [],
      // state: "",
      // city: "",
      // country: "",
      country_count: "",
      state_count: "",
      city_count: "",
      typeform: {
        coupon_name: "",
        coupon_code: "",
        coupon_name_other_lang: "",
        description_other_lang: "",
        minimum_order_amount: "",
        country: "",
        orders_valid_for_first: "",
        state: "",
        city: "",
        checkbox: false,
        customercheckbox: false,
        coupon_type: "",
        coupon_image: "",
        selectedRestaurant: "",
        selectedCustomer: "",
        is_show_customer_app: "",
        pharmacy_specify:"",
        delivery_discount_percentage: "",
        maximum_discount_amount: "",
        is_flat_discount: "0",
        per_user_usage: "1",
        description: "",
        max_user_count: "",
        flat_discount: "",
        start_datetime: "",
        end_datetime: "",
        show_display_text: "",
        show_display_text_another_lang : "",
        promo_code_type: "1",
        takeaway_discount_percentage: "",
        delivery_show_display_text: "",
        takeaway_show_display_text: ""
      },
      submitted: false
    };
  },
  validations: {
    typeform: {
      promo_code_type: {
        required,
      },
      coupon_name: {
        required,
      },
      coupon_code: {
        required,
      },
      minimum_order_amount: {
        required,
        floating,
      },
      coupon_image: {
        required,
      },
      country: {
        required,
      },
      city: {
        required,
      },
      state: {
        required,
      },
      start_datetime: {
        required,
      },
      max_user_count: {
        // required,
        floating,
      },
      // key:{
      //   requiredIf
      // },
      description: {
        required,
      },
      delivery_discount_percentage: {
        required: requiredIf(function(form){
        	// return this.typeform.is_flat_discount == '0';
          return (this.typeform.promo_code_type == '1' || this.typeform.promo_code_type == '4');
        }),
        floating,
      },
      takeaway_discount_percentage: {
        required: requiredIf(function(form){
        	// return this.typeform.is_flat_discount == '0';
          return (this.typeform.promo_code_type == '1' || this.typeform.promo_code_type == '4');
        }),
        floating,
      },
      delivery_show_display_text: {
        required: requiredIf(function(form){
        	// return this.typeform.is_flat_discount == '0';
          return (this.typeform.promo_code_type == '1' || this.typeform.promo_code_type == '4');
        }),
        floating,
      },
      takeaway_show_display_text: {
        required: requiredIf(function(form){
        	// return this.typeform.is_flat_discount == '0';
          return (this.typeform.promo_code_type == '1' || this.typeform.promo_code_type == '4');
        }),
        floating,
      },
      maximum_discount_amount: {
        required : requiredIf(function(form){
        	// return this.typeform.is_flat_discount == '0';
          return (this.typeform.promo_code_type == '1' || this.typeform.promo_code_type == '4');
        }),
        floating,
      },
      flat_discount: {
        required : requiredIf(function(form){
        	// return this.typeform.is_flat_discount == '1';
          return (this.typeform.promo_code_type == '2' || this.typeform.promo_code_type == '3');
        }),
        floating,
      },
      selectedRestaurant: {
        required: requiredIf(function (nestedModel) {
          return this.typeform.checkbox == false;
        }),
      },
      selectedCustomer: {
        required: requiredIf(function (nestedModel) {
           return this.typeform.customercheckbox == false;
        }),
      },
    },
  },
  mounted() {
    this.getVendorCountry();
    // this.getAllState();
    // this.getAllCity();
  },
  methods: {
    nameWithLang ({ user_name, last_name, mobile_number }) {
      return `${user_name} ${last_name} (${mobile_number})`
    },
    toggleTimeRangePanel() {
      this.showTimeRangePanel = !this.showTimeRangePanel;
    },
    handleRangeClose() {
      this.showTimeRangePanel = false;
    },
    disabledBeforeTodayAndAfterAWeek(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0));
    },
    Onclick: function () {
      if (this.typeform.checkbox == false) this.typeform.checkbox = !this.typeform.checkbox;
        this.typeform.selectedRestaurant = '';
        this.resturant ='';
        this.languages ='';
        this.newCoupon_restaurants = [];
        this.serachInput = '';
        this.showPopup = false;
    },
    customerOnclick: function () {
      if (this.typeform.customercheckbox == false)
        this.typeform.customercheckbox = !this.typeform.customercheckbox;
    },
    asyncFind(query) {
      this.checkUpdate=[];
      this.showPopup = true;
      this.isLoading = true;
      // bannerService.getRestaurant(query).then((response) => {
      // bannerService.getRestaurant({search: query}).then((response) => {
        bannerService.getRestaurant({search: this.serachInput}).then((response) => {
        this.resturant = response.data.result;
        this.isLoading = false;
        if(this.resturant.length != this.newCoupon_restaurants.length){
          this.isCheckAll = false;
        }
      });
    },
    checkAll() {
      this.isCheckAll = !this.isCheckAll;
      if (this.isCheckAll) { // Check all
        for (var key in this.resturant) {
          this.languages = this.languages.filter((e) => e.restaurant_id != this.resturant[key].restaurant_id)
          this.newCoupon_restaurants = this.newCoupon_restaurants.filter((e) => e.restaurant_id != this.resturant[key].restaurant_id)
          this.languages.push(this.resturant[key]);
          this.newCoupon_restaurants.push(this.resturant[key]);
          this.typeform.selectedRestaurant = this.newCoupon_restaurants;
        }
      }
      if(!this.isCheckAll){
        this.newCoupon_restaurants = [];
        this.languages = [];
        this.typeform.selectedRestaurant = this.newCoupon_restaurants;
      }
    },
    updateCheckall(event, lang) {
      if (event.target.checked) {
        this.newCoupon_restaurants.push(lang);
        this.typeform.selectedRestaurant = this.newCoupon_restaurants;
      } else {
        this.newCoupon_restaurants = this.newCoupon_restaurants.filter((e) => e.restaurant_id != lang.restaurant_id)
        this.checkUpdate = this.checkUpdate.filter((e) => e != lang.restaurant_id);
        this.typeform.selectedRestaurant = this.newCoupon_restaurants;
      }

      for (var key in this.resturant) {
        this.newCoupon_restaurants.map((item) => {
            if(item.restaurant_id == this.resturant[key].restaurant_id){
              this.checkUpdate.push(this.resturant[key].restaurant_id);
            }
        })
      }
        
      const jsonObject = this.checkUpdate.map(JSON.stringify);
      const uniqueSet = new Set(jsonObject);
      this.arr = Array.from(uniqueSet).map(JSON.parse);



      if (this.arr.length == this.resturant.length) {
        this.isCheckAll = true;
      } else {
        this.isCheckAll = false;
      }

      // if (this.newCoupon_restaurants.length == this.resturant.length) {
      //   this.isCheckAll = true;
      // } else {
      //   this.isCheckAll = false;
      // }
    },
    clearAll() {
      this.selectedRestaurant = [];
    },
    asyncFindCustomer(query) {
      // this.isLoading = true;
      // promoCodeService.getCustomer(query).then((response) => {
      this.isLoading1 = true;
      promoCodeService.getCustomer({search: query}).then((response) => {
        this.customer = response.data.result;
        this.isLoading1 = false;
      });
    },
    clearAllCustomer() {
      this.selectedCustomer = [];
    },
    getVendorCountry() {
      this.typeform.state = '';
      this.typeform.city = '';
      this.states = [];
      this.cities = [];
      deliveryZoneService.getVendorCountry().then((response) => {
        this.country_count = response.data.result.length;
        this.countries = response.data.result;
        if (this.country_count == 1) {
          this.typeform.country = this.countries[0].id;
          this.getVendorState();
        }
      });
    },
    getVendorState() {
      this.states = [];
      this.cities = [];
      this.city_count = 0;
      this.state_count = 0;
      this.typeform.city = '';
      if(!this.typeform.country) return false;
      deliveryZoneService.getVendorState({country_id: this.typeform.country}).then((response) => {
        this.state_count = response.data.result.length;
        this.states = response.data.result;
        if (this.state_count == 1) {
          this.typeform.state = this.states[0].id;
          this.getVendorCity();
        } else {
          // if (this.typeform.country == "") {
          //   this.getAllState();
          //   this.getAllCity();
          //   this.typeform.state = "";
          //   this.typeform.city = "";
          // }
          // if(this.typeform.state ==''){
          //   this.getVendorCity();
          //   this.typeform.state = "";
          //   this.typeform.city = "";
          // }
        }
      });
    },
    getVendorCity() {
      this.cities = [];
      this.city_count = 0;
      if(!this.typeform.state) return false;
      deliveryZoneService.getVendorCity({state_id: this.typeform.state}).then((response) => {
        this.city_count = response.data.result.length;
        this.cities = response.data.result;
        if (this.city_count == 1) {
          this.typeform.city = this.cities[0].cityid;
        } else{
          // if(this.typeform.state ==''){
          //   this.getAllCity();
          //   this.typeform.city = '';
          // }
        }
      });
    },
    getAllState() {
      deliveryZoneService.getAllState().then((response) => {
        this.states = response.data.result;
        this.state_count = response.data.result.length;
      });
    },
    getAllCity() {
      deliveryZoneService.getAllCity().then((response) => {
        this.cities = response.data.result;
        this.city_count = response.data.result.length;
      });
    },
    couponInIt() {
      this.$v.$touch();
      if (this.$v.typeform.$invalid || this.submitted) {
        this.showPopup = false;
        return;
      } else if(this.typeform.pharmacy_specify == 1){
         if(this.typeform.checkbox == true){
          error_message('You can select only one store as you made this coupon pharmacy specific');
         }else if(this.typeform.selectedRestaurant.length > 1){
            error_message('You can select only one store as you made this coupon pharmacy specific');
         }else {
          this.couponInItNew();
         }
      }else {
        this.couponInItNew();
      }
    },
    couponInItNew() {
      this.showPopup = false;
        this.submitted = true;
        this.typeform.is_flat_discount = (this.typeform.promo_code_type == 2 || this.typeform.promo_code_type == 3) ? 1 : 0;
        const fd = new FormData();
        fd.append("coupon_name", this.typeform.coupon_name);
        fd.append(
          "coupon_name_other_lang",
          this.typeform.coupon_name_other_lang
        );
        fd.append("description", this.typeform.description);
        fd.append(
          "description_other_lang",
          this.typeform.description_other_lang
        );
        fd.append("coupon_code", this.typeform.coupon_code);
        fd.append("coupon_image", this.typeform.coupon_image);
        fd.append("coupon_type", this.typeform.coupon_type);
        fd.append("is_show_customer_app", this.typeform.is_show_customer_app ? 1 : 0);
        fd.append("pharmacy_specify", this.typeform.pharmacy_specify ? 1 : 0);
        fd.append("is_flat_discount", this.typeform.is_flat_discount);
        fd.append("promo_code_type", this.typeform.promo_code_type);
        fd.append("flat_discount", this.typeform.flat_discount);
        fd.append("delivery_discount_percentage", this.typeform.delivery_discount_percentage);
        fd.append("takeaway_discount_percentage", this.typeform.takeaway_discount_percentage);
        fd.append("delivery_show_display_text", this.typeform.delivery_show_display_text);
        fd.append("takeaway_show_display_text", this.typeform.takeaway_show_display_text);
        fd.append(
          "maximum_discount_amount",
          this.typeform.maximum_discount_amount
        );
        fd.append("minimum_order_amount", this.typeform.minimum_order_amount);
        fd.append("per_user_usage", this.typeform.per_user_usage);
        fd.append("max_user_count", this.typeform.max_user_count);
        fd.append(
          "orders_valid_for_first",
          this.typeform.orders_valid_for_first
        );
        fd.append("country", this.typeform.country);
        fd.append("start_datetime", this.typeform.start_datetime);
        fd.append("state", this.typeform.state);
        fd.append("city", this.typeform.city);
        fd.append("show_display_text", this.typeform.show_display_text);
        fd.append("show_display_text_another_lang", this.typeform.show_display_text_another_lang);
        if (this.typeform.checkbox == true) {
          fd.append("checkbox", 0);
        } else {
          this.typeform.selectedRestaurant
            .map((item) => item.restaurant_id)
            .map((item, index) => {
              fd.append("selectedRestaurant[" + index + "]", item);
            });
        }
        if (this.typeform.customercheckbox == true) {
          fd.append("customercheckbox", 0);
        } else {
          this.typeform.selectedCustomer
            .map((items) => items.user_id)
            .map((items, index) => {
              fd.append("selectedCustomer[" + index + "]", items);
            });
        }
        promoCodeService.addPromoCode(fd).then((response) => {
          this.submitted = false;
          if (response.data.code === 200) {
            success_message(response.data.message);
            this.$router.push({ name: "coupon-list" });
          } else {
            error_message(response.data.message);
          }
        });
    },
  },
};
</script>

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
        >
          <div class="page-title-left avatar-main-icon">
            <div class="avatar-xs mr-3">
              <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                <i class="bx bx-purchase-tag"></i>
              </span>
            </div>
            <div class="report-tag">
              <h4 class="mb-0 font-size-18">{{ trans.get('__JSON__.Add Promo Code') }}</h4>
              <p><router-link :to="{ name: 'coupon-list'}">{{ trans.trans('__JSON__.PROMO CODES') }}</router-link> > {{ trans.get('__JSON__.Add Promo Code') }}</p>
          </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <form action="#" @submit.prevent="couponInIt">
          <!-- Promo Code Detail Section Start -->
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">{{ trans.get('__JSON__.Promo Code Detail') }}</h4>
              <p class="card-title-desc"></p>
              <div class="row">
                <div class="col-sm-6" v-if="$auth.setting">
                  <div class="form-group">
                    <label
                      class="control-label required"
                    >{{ trans.trans('__JSON__.Promo Code Name Language',{ lang: $auth.langauges[0].name }) }}</label>
                    <input
                      id="coupon_name"
                      v-model="$v.typeform.coupon_name.$model"
                      type="text"
                      class="form-control"
                      :placeholder="trans.trans('__JSON__.Promo Code Name Language',{ lang: $auth.langauges[0].name })"
                      name="coupon_name"
                      :class="{ 'is-invalid': $v.typeform.coupon_name.$error }"
                    />
                    <div v-if="$v.typeform.coupon_name.$error" class="invalid-feedback">
                      <span
                        v-if="!$v.typeform.coupon_name.required"
                      >{{ trans.get('__JSON__.Please enter promo code name') }}.</span>
                    </div>
                  </div>
                </div>

                <div class="col-sm-6 d-none" v-if="$auth.langauges[1].name === 'No Secondary Language'">
                </div>
                <div class="col-sm-6" v-else>
                  <div class="form-group">
                    <label
                      class="control-label"
                    >{{ trans.trans('__JSON__.Promo Code Name Secondary Language(Optional)',{ lang: $auth.langauges[1].name }) }}</label>
                    <input
                      maxlength="100"
                      id="coupon_name_other_lang"
                      name="coupon_name_other_lang"
                      v-model="typeform.coupon_name_other_lang"
                      type="text"
                      :placeholder="trans.trans('__JSON__.Promo Code Name Secondary Language(Optional)',{ lang: $auth.langauges[1].name })"
                      class="form-control"
                    />
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="control-label required">{{ trans.get('__JSON__.Promo Code') }}</label>
                    <input
                      id="coupon_code"
                      v-model="$v.typeform.coupon_code.$model"
                      type="text"
                      class="form-control"
                      :placeholder="this.trans.get('__JSON__.Enter Promo Code Name')"
                      name="coupon_code"
                      :class="{ 'is-invalid': $v.typeform.coupon_code.$error }"
                    />
                    <div v-if="$v.typeform.coupon_code.$error" class="invalid-feedback">
                      <span
                        v-if="!$v.typeform.coupon_code.required"
                      >{{ trans.get('__JSON__.Please enter promo code') }}.</span>
                    </div>
                  </div>
                </div>

                  <div class="col-sm-6">
                  <div class="form-group">
                    <label
                      class="control-label required"
                    >{{ trans.trans('__JSON__.Promo Code Description Primary Language', { lang: $auth.langauges[0].name }) }}</label>
                    <textarea
                      rows="5"
                      id="description"
                      v-model="$v.typeform.description.$model"
                      type="text"
                      class="form-control"
                      :placeholder="trans.trans('__JSON__.Promo Code Description Primary Language', { lang: $auth.langauges[0].name })"
                      name="description"
                      :class="{ 'is-invalid': $v.typeform.description.$error }"
                    ></textarea>
                    <div v-if="$v.typeform.description.$error" class="invalid-feedback">
                      <span
                        v-if="!$v.typeform.description.required"
                      >{{ trans.get('__JSON__.Please enter promo code description') }}.</span>
                    </div>
                  </div>
                </div>

                <div class="col-sm-6 d-none" v-if="$auth.langauges[1].name === 'No Secondary Language'">
                </div>                
                <div class="col-sm-6" v-else>
                  <div class="form-group">
                    <label
                      class="control-label"
                    >{{ trans.trans('__JSON__.Promo Code Description Secondary Language(Optional)', { lang: $auth.langauges[1].name }) }}</label>
                    <textarea
                      rows="5"
                      id="description_other_lang"
                      v-model="typeform.description_other_lang"
                      type="text"
                      class="form-control"
                      :placeholder="trans.trans('__JSON__.Promo Code Description Secondary Language(Optional)', { lang: $auth.langauges[1].name })"
                      name="description_other_lang"
                    ></textarea>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label
                      class="control-label required"
                    >{{ trans.trans('__JSON__.Display Text Primary Language', { lang: $auth.langauges[0].name }) }}</label>
                    <textarea
                      rows="5"
                      id="show_display_text"
                      v-model="typeform.show_display_text"
                      type="text"
                      class="form-control"
                      :placeholder="trans.trans('__JSON__.Display Text Primary Language', { lang: $auth.langauges[0].name })"
                      name="description"
                    ></textarea>
                  </div>
                </div>

                <div class="col-sm-6 d-none" v-if="$auth.langauges[1].name === 'No Secondary Language'">
                </div>                
                <div class="col-sm-6" v-else>
                  <div class="form-group">
                    <label
                      class="control-label"
                    >{{ trans.trans('__JSON__.Display Text Secondary Language(Optional)', { lang: $auth.langauges[1].name }) }}</label>
                    <textarea
                      rows="5"
                      id="show_display_text_another_lang"
                      v-model="typeform.show_display_text_another_lang"
                      type="text"
                      class="form-control"
                      :placeholder="trans.trans('__JSON__.Display Text Secondary Language(Optional)', { lang: $auth.langauges[1].name })"
                      name="show_display_text_another_lang"
                    ></textarea>
                  </div>
                </div>

                 <div class="col-sm-6">
                  <div class="form-group">
                    <label class="control-label required">{{ trans.get('__JSON__.Promo Code Image') }}</label>
                    <dropzone-popup
                      v-model="typeform.coupon_image"
                      type="file"
                      id="coupon_image"
                      name="coupon_image"
                      acceptedFiles=".jpeg,.jpg,.png,.webp,image/webp"
                      :class="{ 'is-invalid': $v.typeform.coupon_image.$error }"
                    />
                    <div
                      v-if="$v.typeform.coupon_image.$error"
                      class="invalid-feedback cuisine-img"
                    >
                      <span
                        v-if="!$v.typeform.coupon_image.required"
                      >{{ trans.get('__JSON__.Please select promo code Image') }}.</span>
                    </div>
                  </div>
                </div>

                 <div class="col-sm-6">
                  <div class="form-group">
                    <label
                      class="control-label required"
                    >{{ trans.get('__JSON__.Promo Code Redeem start/end Period') }}</label>
                    <br />
                    <!-- <date-picker
                      v-model="value1"
                      type="datetime"
                      :disabled-date="disabledBeforeTodayAndAfterAWeek"
                      :format="format"
                      :rangeSeparator="rangeSeparator"
                      range
                      placeholder="Select date range"
                    ></date-picker>-->
                    <date-picker
                      v-model="$v.typeform.start_datetime.$model"
                      :disabled-date="disabledBeforeTodayAndAfterAWeek"
                      type="datetime"
                      value-type="format"
                      :rangeSeparator="rangeSeparator"
                      placeholder="Select datetime range"
                      range
                      :show-time-panel="showTimeRangePanel"
                      @close="handleRangeClose"
                      :class="{ 'is-invalid': $v.typeform.start_datetime.$error }"
                    >
                      <template v-slot:footer>
                        <button
                          class="mx-btn mx-btn-text"
                          @click="toggleTimeRangePanel"
                        >{{ showTimeRangePanel ? 'select date' : 'select time' }}</button>
                      </template>
                    </date-picker>
                    <div v-if="$v.typeform.start_datetime.$error" class="invalid-feedback">
                      <span
                        v-if="!$v.typeform.start_datetime.required"
                      >{{ trans.get('__JSON__.Please select date') }}.</span>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                      <label>{{ trans.get('__JSON__.pharmacy_specify') }}</label>
                      <div class>
                        <div class="swtich-data">
                          <label class="switch">
                            <input
                              type="checkbox"
                              id="togBtn"
                              class="switch-on"
                              true-value="1"
                              false-value="0"
                              v-model="typeform.pharmacy_specify"
                            />
                            <div class="slider round">
                              <!--ADDED HTML -->
                              <span class="on">{{ trans.get('__JSON__.On') }}</span>
                              <span class="off">{{ trans.get('__JSON__.Off') }}</span>
                              <!--END-->
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Promo Code Detail Section End -->

          <!-- Discount Details Section Start -->
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">{{ trans.get('__JSON__.Discount Details') }}</h4>
              <p class="card-title-desc"></p>
              <div class="col-md-12">
                <div class="row">
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label class="control-label">{{ trans.get('__JSON__.Coupon Valid For') }}</label>
                      <select
                        class="custom-select"
                        v-bind:value="typeform.coupon_type"
                        v-model="typeform.coupon_type"
                        id="coupon_type"
                        name="coupon_type"
                      >
                        <option value>{{ trans.get('__JSON__.Select Coupon Type') }}</option>
                        <option value="Delivery">{{ trans.get('__JSON__.Delivery') }}</option>
                        <option value="Pickup">{{ trans.get('__JSON__.Pickup') }}</option>
                        <option value="Both">{{ trans.get('__JSON__.Both') }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label
                        class="control-label"
                      >{{ trans.get('__JSON__.Promo Code Usage Per User') }}</label>
                      <select
                        class="custom-select"
                        v-bind:value="typeform.per_user_usage"
                        v-model="typeform.per_user_usage"
                        id="per_user_usage"
                        name="per_user_usage"
                      >
                        <!-- <option value="">{{ trans.get('__JSON__.Select Redeem Count') }}</option> -->
                        <option v-bind:value="1">1</option>
                        <option v-bind:value="2">2</option>
                        <option v-bind:value="3">3</option>
                        <option v-bind:value="4">4</option>
                        <option v-bind:value="5">5</option>
                        <option v-bind:value="6">6</option>
                        <option v-bind:value="7">7</option>
                        <option v-bind:value="8">8</option>
                        <option v-bind:value="9">9</option>
                        <option v-bind:value="10">10</option>
                        <option value="Unlimited">Unlimited</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label
                        class="control-label"
                      >{{ trans.get('__JSON__.Coupon Valid For First X Order') }}</label>
                      <select
                        class="custom-select"
                        v-bind:value="typeform.orders_valid_for_first"
                        v-model="typeform.orders_valid_for_first"
                        id="orders_valid_for_first"
                        name="orders_valid_for_first"
                      >
                        <option value>{{ trans.get('__JSON__.Unlimited') }}</option>
                        <option v-bind:value="1">1</option>
                        <option v-bind:value="2">2</option>
                        <option v-bind:value="3">3</option>
                        <option v-bind:value="4">4</option>
                        <option v-bind:value="5">5</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="row">
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label class="control-label">{{ trans.get('__JSON__.Promo Code Type') }}</label>
                      <select
                        class="custom-select"
                        id="promo_code_type"
                        name="promo_code_type"
                        v-model="typeform.promo_code_type"
                      >
                        <!-- <option value>{{ trans.get('__JSON__.Select Discount Type') }}</option> -->
                        <option value="1">{{ trans.get('__JSON__.Percentage Discount') }}</option>
                        <option value="2">{{ trans.get('__JSON__.Flat Discount') }}</option>
                        <option value="3" v-if="$auth.getVendorId() == 40818">{{ trans.get('__JSON__.Flat Cashback') }}</option>
                        <option value="4" v-if="$auth.getVendorId() == 40818">{{ trans.get('__JSON__.Percentage Cashback') }}</option>
                      </select>
                    </div>
                  </div>
                  <!-- <div class="col-sm-4" v-if="typeform.promo_code_type == 1 || typeform.promo_code_type == 4">
                    <div class="form-group">
                      <label class="control-label required" v-if="typeform.promo_code_type == 1">{{ trans.get('__JSON__.Discount') }} (%)</label>
                      <label class="control-label required" v-if="typeform.promo_code_type == 4">{{ trans.get('__JSON__.Cashback') }} (%)</label>
                      <input
                        id="discount_percentage"
                        name="discount_percentage"
                        v-model="$v.typeform.discount_percentage.$model"
                        type="text"
                        :placeholder="this.trans.get((typeform.promo_code_type == 1 ? '__JSON__.Enter Discount in Percentage' : '__JSON__.Enter Cashback in Percentage'))"
                        class="form-control"
                         :class="{ 'is-invalid': $v.typeform.discount_percentage.$error }"
                      />
                     <div v-if="$v.typeform.discount_percentage.$error" class="invalid-feedback">
                        <span
                          v-if="!$v.typeform.discount_percentage.required && typeform.promo_code_type == 1"
                        >{{ trans.get('__JSON__.Please enter discount')}}.</span>
                        <span
                          v-if="!$v.typeform.discount_percentage.required && typeform.promo_code_type == 4"
                        >{{ trans.get('__JSON__.Please enter cashback')}}.</span>
                        <span
                          v-if="!$v.typeform.discount_percentage.floating"
                        >{{ trans.get('__JSON__.This value should be digits')}}.</span>
                      </div>
                    </div>
                  </div> -->
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label class="control-label required">{{ trans.get('__JSON__.Delivery Discount') }} (%)</label>
                      <input
                        id="delivery_discount_percentage"
                        name="delivery_discount_percentage"
                        v-model="$v.typeform.delivery_discount_percentage.$model"
                        type="text"
                        :placeholder="this.trans.get(('__JSON__.Enter Delivery Discount in Percentage'))"
                        class="form-control"
                         :class="{ 'is-invalid': $v.typeform.delivery_discount_percentage.$error }"
                      />
                     <div v-if="$v.typeform.delivery_discount_percentage.$error" class="invalid-feedback">
                        <span
                          v-if="!$v.typeform.delivery_discount_percentage.required && typeform.promo_code_type == 1"
                        >{{ trans.get('__JSON__.Please enter delivery discount')}}.</span>
                        <span
                          v-if="!$v.typeform.delivery_discount_percentage.required && typeform.promo_code_type == 4"
                        >{{ trans.get('__JSON__.Please enter cashback')}}.</span>
                        <span
                          v-if="!$v.typeform.delivery_discount_percentage.floating"
                        >{{ trans.get('__JSON__.This value should be digits')}}.</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label class="control-label required">{{ trans.get('__JSON__.Takeaway Discount') }} (%)</label>
                      <input
                        id="takeaway_discount_percentage"
                        name="takeaway_discount_percentage"
                        v-model="$v.typeform.takeaway_discount_percentage.$model"
                        type="text"
                        :placeholder="this.trans.get(('__JSON__.Enter Takeaway Discount in Percentage'))"
                        class="form-control"
                         :class="{ 'is-invalid': $v.typeform.takeaway_discount_percentage.$error }"
                      />
                     <div v-if="$v.typeform.takeaway_discount_percentage.$error" class="invalid-feedback">
                        <span
                          v-if="!$v.typeform.takeaway_discount_percentage.required && typeform.promo_code_type == 1"
                        >{{ trans.get('__JSON__.Please enter Takeaway discount')}}.</span>
                        <span
                          v-if="!$v.typeform.takeaway_discount_percentage.required && typeform.promo_code_type == 4"
                        >{{ trans.get('__JSON__.Please enter cashback')}}.</span>
                        <span
                          v-if="!$v.typeform.takeaway_discount_percentage.floating"
                        >{{ trans.get('__JSON__.This value should be digits')}}.</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label class="control-label required">{{ trans.get('__JSON__.Delivery Display Text') }}</label>
                      <input
                        id="delivery_show_display_text"
                        name="delivery_show_display_text"
                        v-model="$v.typeform.delivery_show_display_text.$model"
                        type="text"
                        :placeholder="this.trans.get(('__JSON__.Enter Delivery Display Text'))"
                        class="form-control"
                         :class="{ 'is-invalid': $v.typeform.delivery_show_display_text.$error }"
                      />
                     <div v-if="$v.typeform.delivery_show_display_text.$error" class="invalid-feedback">
                        <span
                          v-if="!$v.typeform.delivery_show_display_text.required && typeform.promo_code_type == 1"
                        >{{ trans.get('__JSON__.Please enter delivery display Text')}}.</span>
                        <span
                          v-if="!$v.typeform.delivery_show_display_text.required && typeform.promo_code_type == 4"
                        >{{ trans.get('__JSON__.Please enter cashback')}}.</span>
                        <span
                          v-if="!$v.typeform.delivery_show_display_text.floating"
                        >{{ trans.get('__JSON__.This value should be digits')}}.</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label class="control-label required">{{ trans.get('__JSON__.Takeaway Display Text') }}</label>
                      <input
                        id="takeaway_show_display_text"
                        name="takeaway_show_display_text"
                        v-model="$v.typeform.takeaway_show_display_text.$model"
                        type="text"
                        :placeholder="this.trans.get(('__JSON__.Enter Takeaway Display Text'))"
                        class="form-control"
                         :class="{ 'is-invalid': $v.typeform.takeaway_show_display_text.$error }"
                      />
                     <div v-if="$v.typeform.takeaway_show_display_text.$error" class="invalid-feedback">
                        <span
                          v-if="!$v.typeform.takeaway_show_display_text.required && typeform.promo_code_type == 1"
                        >{{ trans.get('__JSON__.Please enter takeaway display text')}}.</span>
                        <span
                          v-if="!$v.typeform.takeaway_show_display_text.required && typeform.promo_code_type == 4"
                        >{{ trans.get('__JSON__.Please enter cashback')}}.</span>
                        <span
                          v-if="!$v.typeform.takeaway_show_display_text.floating"
                        >{{ trans.get('__JSON__.This value should be digits')}}.</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4" v-if="typeform.promo_code_type == 1 || typeform.promo_code_type == 4">
                    <div class="form-group">
                     <label class="control-label required">
                        {{ trans.get((typeform.promo_code_type == 1 ? '__JSON__.Maximum Discount Amount' : '__JSON__.Maximum Cashback Amount')) }}(
                        <span v-html="currency"></span>)
                      </label>
                      <input
                        id="maximum_discount_amount"
                        name="maximum_discount_amount"
                        type="text"
                        v-model="$v.typeform.maximum_discount_amount.$model"
                        :placeholder="this.trans.get((typeform.promo_code_type == 1 ? '__JSON__.Maximum Discount Amount' : '__JSON__.Maximum Cashback Amount'))"
                        class="form-control"
                         :class="{ 'is-invalid': $v.typeform.maximum_discount_amount.$error }"
                      />
                       <div v-if="$v.typeform.maximum_discount_amount.$error" class="invalid-feedback">
                        <span
                          v-if="!$v.typeform.maximum_discount_amount.required && typeform.promo_code_type == 1"
                        >{{ trans.get('__JSON__.Please enter maximum discount')}}.</span>
                        <span
                          v-if="!$v.typeform.maximum_discount_amount.required && typeform.promo_code_type == 4"
                        >{{ trans.get('__JSON__.Please enter maximum cashback')}}.</span>
                        <span
                          v-if="!$v.typeform.maximum_discount_amount.floating"
                        >{{ trans.get('__JSON__.This value should be digits')}}.</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4" v-if="typeform.promo_code_type == 2 || typeform.promo_code_type == 3">
                    <div class="form-group">
                      <label class="control-label required">
                        {{ trans.get((typeform.promo_code_type == 2 ? '__JSON__.Flat Discount' : '__JSON__.Flat Cashback')) }} (
                        <span v-html="currency"></span>)
                      </label>
                      <input
                        id="flat_discount"
                        name="flat_discount"
                        v-model="$v.typeform.flat_discount.$model"
                        type="number"
                        :placeholder="this.trans.get((typeform.promo_code_type == 2 ? '__JSON__.Enter Flat Discount in Amount' : '__JSON__.Enter Flat Cashback in Amount'))"
                        class="form-control"
                         :class="{ 'is-invalid': $v.typeform.flat_discount.$error }"
                      />
                      <div v-if="$v.typeform.flat_discount.$error" class="invalid-feedback">
                        <span
                          v-if="!$v.typeform.flat_discount.required && typeform.promo_code_type == 2"
                        >{{ trans.get('__JSON__.Please enter flat discount')}}.</span>
                        <span
                          v-if="!$v.typeform.flat_discount.required && typeform.promo_code_type == 3"
                        >{{ trans.get('__JSON__.Please enter flat cashback')}}.</span>
                        <span
                          v-if="!$v.typeform.flat_discount.floating"
                        >{{ trans.get('__JSON__.This value should be digits')}}.</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="row">
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label class="control-label required">
                        {{ trans.get('__JSON__.Minimum Order Values') }}(
                        <span v-html="currency"></span>)
                      </label>
                      <input
                        id="minimum_order_amount"
                        name="minimum_order_amount"
                        v-model="$v.typeform.minimum_order_amount.$model"
                        type="text"
                        :placeholder="this.trans.get('Enter Minimum Order Amount')"
                        class="form-control"
                        :class="{ 'is-invalid': $v.typeform.minimum_order_amount.$error }"
                      />
                      <div v-if="$v.typeform.minimum_order_amount.$error" class="invalid-feedback">
                        <span
                          v-if="!$v.typeform.minimum_order_amount.required"
                        >{{ trans.get('__JSON__.Please enter minimum order amount')}}.</span>
                        <span
                          v-if="!$v.typeform.minimum_order_amount.floating"
                        >{{ trans.get('__JSON__.This value should be digits')}}.</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label
                        class="control-label"
                      >{{ trans.get('__JSON__.Promo Code Quantity') }}</label>
                      <input
                        id="max_user_count"
                        name="max_user_count"
                        v-model="$v.typeform.max_user_count.$model"
                        type="text"
                        :placeholder="this.trans.get('Enter Promo Code Quantity')"
                        class="form-control"
                        :class="{ 'is-invalid': $v.typeform.max_user_count.$error }"
                      />
                      <!-- :class="{ 'is-invalid': $v.typeform.max_user_count.$error }" -->
                       <div v-if="$v.typeform.max_user_count.$error" class="invalid-feedback">
                        <!-- <span
                          v-if="!$v.typeform.max_user_count.required"
                        >{{ trans.get('__JSON__.Please enter max count number')}}.</span> -->
                        <span
                          v-if="!$v.typeform.max_user_count.floating"
                        >{{ trans.get('__JSON__.This value should be digits')}}.</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>{{ trans.get('__JSON__.Show in Customer App') }}?</label>
                      <div class>
                        <div class="swtich-data">
                          <label class="switch">
                            <input
                              type="checkbox"
                              id="togBtn"
                              class="switch-on"
                              true-value="1"
                              false-value="0"
                              v-model="typeform.is_show_customer_app"
                            />
                            <div class="slider round">
                              <!--ADDED HTML -->
                              <span class="on">{{ trans.get('__JSON__.On') }}</span>
                              <span class="off">{{ trans.get('__JSON__.Off') }}</span>
                              <!--END-->
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Discount Details Section End -->

          <!-- Vendors Section Start -->
          <div class="card">
            <div class="card-body">
              <h4 class="card-title required">{{ trans.trans('__JSON__.Vendors',{ vendor: $auth.setting.restaurant_name }) }}</h4>
              <p class="card-title-desc"></p>
              <div class="row">
                <div class="col-md-12">
                  <div class="vendor-main">
                    <div class="col-sm-3 col-12">
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          v-on:click="Onclick()"
                          v-model="typeform.checkbox"
                          class="custom-control-input"
                          id="all_rest"
                        />
                        <label
                          class="custom-control-label"
                          for="all_rest"
                        >{{ trans.trans('__JSON__.Promo code valid for all Vendors',{ vendor: $auth.setting.restaurant_name }) }}</label>
                      </div>
                    </div>
                    <div class="col-sm-1 col-12 m-coupon-or">
                      <p>{{ trans.get('__JSON__.OR') }}</p>
                    </div>
                    <div class="col-sm-8 col-12">
                      <div class="search-box mr-2 d-inline-block">
                        <div class="position-relative" :style="[typeform.checkbox ? {'background' : '#ededed', 'min-height': '40px', 'opacity': '.3'} : {'background' : 'none', 'min-height': '40px', 'opacity': '1'}]" >
                          <!-- <multiselect
                            v-model="$v.typeform.selectedRestaurant.$model"
                            id="ajax"
                            v-bind:disabled="typeform.checkbox"
                            label="name"
                            track-by="name"
                            :placeholder="trans.trans('__JSON__.Vendor Search',{ vendor: $auth.setting.restaurant_name })"
                            open-direction="bottom"
                            :options="resturant"
                            :multiple="true"
                            :searchable="true"
                            :loading="isLoading"
                            :internal-search="true"
                            :clear-on-select="true"
                            :close-on-select="false"
                            :max-height="600"
                            :show-no-results="false"
                            :hide-selected="true"
                            @search-change="asyncFind"
                            :class="{ 'is-invalid': $v.typeform.selectedRestaurant.$error }"
                          >
                            <template slot="tag" slot-scope="{ option, remove }">
                              <span class="custom__tag">
                                <span>{{ option.name }}</span>
                                <span class="custom__remove" @click="remove(option)">❌</span>
                              </span>
                            </template>
                            <template slot="clear" slot-scope="props">
                              <div
                                class="multiselect__clear"
                                v-if="selectedRestaurant.length"
                                @mousedown.prevent.stop="clearAll(props.search)"
                              ></div>
                            </template>
                            <span
                              slot="noResult"
                            >{{ trans.get('__JSON__.Oops! No elements found. Consider changing the search query') }}.</span>
                          </multiselect> -->
                          <div style="position: relative;">
                              <input v-bind:disabled="typeform.checkbox" type="text" class="search_input" v-model="serachInput" placeholder="Store Search" @input="asyncFind"/>
                            </div>
                            <div v-if="resturant.length > 0 && showPopup" class="check_search">
                              <div class="check_box">
                                <input type='checkbox' @click="checkAll" v-model='isCheckAll'> <label class="check_name"> Check All </label>
                              </div>
                              <div class="check_box" v-for='lang in resturant'>
                                <input type='checkbox' v-bind:value='lang' v-model="languages" @change="updateCheckall($event, lang)"> <label class="check_name">
                                  {{ lang.name }} </label>
                              </div>
                            </div>
                          <div
                            v-if=" $v.typeform.selectedRestaurant.$error"
                            class="invalid-feedback"
                            style="display: block !important;"
                          >
                            <span
                              v-if="!$v.typeform.selectedRestaurant.required"
                            >{{ trans.get('__JSON__.Vendor Select Error') }}.</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Vendors Section End -->

          <!-- Customer Section Start -->
          <div class="card">
            <div class="card-body">
              <h4 class="card-title required">{{ trans.get('__JSON__.Customers') }}</h4>
              <p class="card-title-desc"></p>
              <div class="row">
                <div class="col-md-12">
                  <div class="vendor-main">
                    <div class="col-sm-3 col-12">
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          v-on:click="customerOnclick()"
                          v-model="typeform.customercheckbox"
                          class="custom-control-input"
                          id="all_cust"
                        />
                        <label
                          class="custom-control-label"
                          for="all_cust"
                        >{{ trans.get('__JSON__.Promo code valid for all Customers') }}</label>
                      </div>
                    </div>
                    <div class="col-sm-1 col-12 m-coupon-or">
                      <p>{{ trans.get('__JSON__.OR') }}</p>
                    </div>
                    <div class="col-sm-8 col-12">
                      <div class="search-box mr-2 d-inline-block">
                        <div class="position-relative">
                          <multiselect
                            v-model="$v.typeform.selectedCustomer.$model"
                            id="ajax"
                            label="user_name"
                            v-bind:disabled="typeform.customercheckbox"
                            track-by="user_name"
                            :custom-label="nameWithLang"
                            :placeholder="trans.get('__JSON__.Customer Search')"
                            open-direction="bottom"
                            :options="customer"
                            :multiple="true"
                            :searchable="true"
                            :loading="isLoading1"
                            :internal-search="true"
                            :clear-on-select="true"
                            :close-on-select="false"
                            :max-height="600"
                            :show-no-results="false"
                            :hide-selected="true"
                            @search-change="asyncFindCustomer"
                            :class="{ 'is-invalid': $v.typeform.selectedCustomer.$error }"
                          >
                            <template slot="tag" slot-scope="{ option, remove }">
                              <span class="custom__tag">
                                <span>{{ option.user_name }} {{ option.last_name }} ({{ option.mobile_number }})</span>
                                <span class="custom__remove" @click="remove(option)">❌</span>
                              </span>
                            </template>
                            <template slot="clear" slot-scope="props">
                              <div
                                class="multiselect__clear"
                                v-if="selectedCustomer.length"
                                @mousedown.prevent.stop="clearAll(props.search)"
                              ></div>
                            </template>
                            <span
                              slot="noResult"
                            >{{ trans.get('__JSON__.Oops! No elements found. Consider changing the search query') }}.</span>
                          </multiselect>
                          <div v-if=" $v.typeform.selectedCustomer.$error" class="invalid-feedback">
                            <span
                              v-if="!$v.typeform.selectedCustomer.required"
                            >{{ trans.get('__JSON__.Please choose at least one customer') }}.</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Customer Section End -->

          <!-- location Section Start -->
          <div class="card" v-if="country_count > 1 || state_count > 1 || city_count > 1">
            <div class="card-body">
              <h4 class="card-title">{{ trans.get('__JSON__.Select Location') }}</h4>
              <p class="card-title-desc"></p>
              <div class="col-md-12">
                <div class="row">
                  <div v-if="country_count > 1" class="col-sm-4">
                    <!-- v-if="country_count > 1" -->
                    <div class="form-group">
                      <label class="control-label required">{{ trans.get('__JSON__.Country') }}</label>
                      <select
                        class="custom-select"
                        v-model="$v.typeform.country.$model"
                        @change="getVendorState"
                        id="country"
                        name="country"
                        :class="{ 'is-invalid': $v.typeform.country.$error }"
                      >
                        <option value selected>{{ trans.get('__JSON__.Select Country') }}</option>
                        <option
                          v-for="con in countries"
                          :value="con.id"
                          :key="con.id"
                        >{{con.country_name}}</option>
                      </select>
                      <div v-if="$v.typeform.country.$error" class="invalid-feedback">
                        <span
                          v-if="!$v.typeform.country.required"
                        >{{ trans.get('__JSON__.Please select country') }}</span>
                      </div>
                    </div>
                  </div>

                  <div v-if="(country_count == 1 && state_count > 1) || country_count > 1" class="col-sm-4">
                    <!-- v-if="country_count >= 1 && state_count > 1" -->
                    <div class="form-group">
                      <label class="control-label required">{{ trans.get('__JSON__.State') }}</label>
                      <select
                        class="custom-select"
                        v-model="$v.typeform.state.$model"
                        @change="getVendorCity"
                        id="state"
                        name="state"
                        :class="{ 'is-invalid': $v.typeform.state.$error }"
                      >
                        <option value selected>{{ trans.get('__JSON__.Select State') }}</option>
                        <option
                          v-for="sat in states"
                          :value="sat.id"
                          :key="sat.id"
                        >{{sat.state_name}}</option>
                      </select>
                      <div v-if="$v.typeform.state.$error" class="invalid-feedback">
                        <span
                          v-if="!$v.typeform.state.required"
                        >{{ trans.get('__JSON__.Please select state')}}</span>
                      </div>
                    </div>
                  </div>

                  <div v-if="(country_count == 1 && state_count == 1 && city_count > 1) || country_count > 1 || state_count > 1" class="col-sm-4">
                    <!-- v-if="state_count >= 1 && city_count > 1" -->
                    <div class="form-group">
                      <label class="control-label required">{{ trans.get('__JSON__.City') }}</label>
                      <select
                        class="custom-select"
                        v-model="$v.typeform.city.$model"
                        id="city"
                        name="city"
                        :class="{ 'is-invalid': $v.typeform.city.$error }"
                      >
                        <option value selected>{{ trans.get('__JSON__.Select City') }}</option>
                        <option
                          v-for="cit in cities"
                          :value="cit.cityid"
                          :key="cit.cityid"
                        >{{cit.cityname}}</option>
                      </select>
                      <div v-if="$v.typeform.city.$error" class="invalid-feedback">
                        <span
                          v-if="!$v.typeform.city.required"
                        >{{ trans.get('__JSON__.Please select city') }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
              <div class="row">
                <div class="col-sm-6">
                  <button
                    class="btn btn-primary mr-1 mt-3"
                    :disabled="submitted"
                  >{{ trans.get('__JSON__.Add Promo Code') }}</button>
                  <b-button @click="$router.back()" type="button" variant="secondary" class="mt-3">{{ trans.get('__JSON__.Cancel') }}</b-button>
                </div>
              </div>
          <!-- location Section End -->
        </form>
      </div>
    </div>
    <!-- end row -->
  </div>
</template>

<style>

.check_search{
    width: 100%;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-top: none !important;
    padding: 0.47rem 0.75rem !important;
    font-size: 0.8125rem;
    position: absolute;
    /* top: -2px; */
    height: 120px !important;
    z-index: 50;
    overflow-y: scroll;
}
.search_input{
  width: 100%;
    border: 1px solid #ced4da;
    min-height: calc(1.5em + 0.94rem + 2px) !important;
    padding: 0.47rem 0.75rem !important;
    font-family: "Poppins", sans-serif !important;
    font-size: 0.8125rem;
    line-height: 1.5 !important;
    color: #495057 !important;
    background-color: #fff !important;
    background-clip: padding-box;
    border-radius: 5px;
}
.search_input:focus-visible{
  outline: none !important;
}
.check_box{
  
  padding: 6px 0px;
    font-size: 13px !important;
    color: #495057 !important;

}
.check_name{
  padding: 0px 4px;
}


</style>