<template>
  <b-modal id="change-password"
    lazy
    hide-footer
    :title="'#'+user_id"
    @hidden="$emit('hidden')"
    >
     <b-form @submit.prevent="updateUserPassword">
        <b-form-group
            id="password"
            :label="trans.get('__JSON__.Password')"
            label-for="password"
        >
            <b-form-input
            id="password-input"
            v-model="$v.typeform.password.$model"
            type="password"
            :placeholder="trans.get('__JSON__.Enter Password')"
            required
            :class="{ 'is-invalid': $v.typeform.password.$error }"
            ></b-form-input>
            <div
                v-if="$v.typeform.password.$error"
                class="invalid-feedback"
            >
                <span v-if="!$v.typeform.password.minLength"
                >{{
                    trans.get(
                    "__JSON__.Password must have at least :id letters.",
                    { id: $v.typeform.password.$params.minLength.min }
                    )
                }}.</span
                >
                <span v-if="!$v.typeform.password.required"
                >{{ trans.get("__JSON__.Please enter password") }}.</span
                >
            </div>
        </b-form-group>
        <b-form-group
            id="confirm_password"
            :label="trans.get('__JSON__.Confirm Password')"
            label-for="confirm_password"
        >
            <b-form-input
            id="confirm_password-input"
            v-model="$v.typeform.confirm_password.$model"
            type="password"          
            :placeholder="trans.get('__JSON__.Confirm Password')"
            required
            :class="{ 'is-invalid': $v.typeform.confirm_password.$error }"
            ></b-form-input>
            <div
                v-if="$v.typeform.confirm_password.$error"
                class="invalid-feedback"
            >
                <span v-if="!$v.typeform.confirm_password.sameAsPassword">
                    {{trans.get("__JSON__.Passwords must be identical")}}.
                </span>
            </div>
        </b-form-group>
        <div class="col-sm-12 text-right">
            <button @click="$bvModal.hide('change-password')" class="btn btn-secondary mr-1 mt-3" type="button">
                {{ trans.get("__JSON__.Cancel") }}
            </button>
            <button class="btn btn-primary mr-1 mt-3" type="submit">
                {{ trans.get("__JSON__.Submit") }}
            </button>
        </div>
     </b-form>
  </b-modal>
</template>

<script>
import { vendorService } from '../../../services';
import { required, sameAs,minLength } from "vuelidate/lib/validators";
export default {
    props: ['user_id'],
    data(){
        return {
            typeform: {
                password: '',
                confirm_password: ''
            }
        }
    },
    mounted(){
        this.$bvModal.show('change-password')
    },
    validations: {
        typeform: {
            password: {
                required,
                minLength:minLength(6)
            },
            confirm_password: {
                required,
                sameAsPassword: sameAs("password"),
            },
        },
    },
    methods:{
        updateUserPassword(){
            this.$v.typeform.$touch();
            if (!this.$v.typeform.$invalid) {
                vendorService.updateUserPassword({...this.typeform,id: this.user_id})
                    .then(res => {
                        this.$toasted.success(res.data.message);
                        this.$bvModal.hide('change-password')
                    })
                    .catch(err => {
                        this.$bvModal.hide('change-password')
                        console.log(err);
                    })
            }
        }
    }
}
</script>

<style>

</style>