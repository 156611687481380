<script>
import Multiselect from "vue-multiselect";
import config from "../../config";
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import DropzonePopup from '../../components/widgets/dropzone-popup';
import { error_message, get_user, success_message, get_languages, get_partner_login,
  get_user_detail,
  get_partner_details,
  get_partner,
  set_user,
  set_user_detail,
  remove_get_user,
  remove_get_user_detail,
  remove_partner_login,
  remove_partner,
  remove_partner_token,
  remove_partner_details,
  set_partner_details,
  set_partner } from "../../Helper/helper";
import { vendorService, deliveryZoneService, settingService, userService } from "../../services";

import { required,email,maxLength,minlength,numeric,helpers,requiredIf} from "vuelidate/lib/validators";
import Swal from "sweetalert2";
const floating = helpers.regex('floating', /^(?:\d*\.\d{1,2}|\d+)$/)

/**
 * Add-product component
 */
export default {
  components: { Multiselect,VueGoogleAutocomplete, DropzonePopup},
  data() {
    return {
      file1: null,
      url: null,
      config: config,
      value: null,
      value1: null,
      search: '',
      isLoading: false,
      center: { lat: 0.00, lng: 0.00 },
      marker: null,
      edited: null,
      rescategories: [],
      countries: [],
      states: [],
      cities: [],
      filename: "",
      vendor_currency:'',
      country_count:'',
      state_count:'',
      city_count:'',
      cuisine: [],
      selectedCuisine: [],
      typeform: {
        name: "",
        email: "",
        phone: "",
        radius: "",
        country: "",
        state: "",
        city: "",
        address: "",
        location_address: "",
        minimum_order_value: "",
        two_person_price:"",
        delivery_time: "",
        admin_commision: "",
        fssai_licence: "",
        delivery_type_time_slots: 2,
        restaurant_type: 0,
        time_slots: 15,
        currency: "",
        admin_commision_type: "commission_in",
        category_id: 0,
        restaurant_category:"REGULAR",
        restaurant_tax: "",
        restaurant_item_layout: 1,
        key: "",
        selectedCuisine: "",
        icon_image: null,
        latitude:"",
        longitude: "",
        name_thai: "",
        address_thai: "",
        support_contact_number: "",
        payment_option: "Both",
        bank_name: "",
        bank_no:"",
        ifsc_code: "",
        branch_name: "",
        grade:"",
      },
      map: null,
      gradeDetails: [],
    };
  },
  validations: {
    typeform: {
      name: {
        required,
      },
      email: {
        required,
        email,
      },
      phone: {
        required,
        // numeric,
      },
      radius: {
        required,
      },
      grade: {
        required,
      },
      country: {
        required,
      },
      city: {
        required,
      },
      state: {
        required,
      },
      address: {
        required,
      },
      location_address: {
        required,
      },
      minimum_order_value: {
        required,
        floating
      },
      delivery_time: {
        required,
        numeric
      },
      admin_commision: {
        required,
        floating
      },
      restaurant_tax: {
        floating
      },
      icon_image: {
        required
      },
      selectedCuisine: {
        required
      }
    },
  },
  mounted() {
    this.initMap();
    this.getVendorCountry();
    this.getGradeDetails();
    // this.getAllState();
    // this.getAllCity();
    this.getResCategory();
    this.getSetting();
    // this.getAddressData();
    this.$refs.address.focus();
    this.vendor_id = get_user().vendor_id || 40818;
    console.log(this.$auth.setting.restaurant_kilometer, "vendor add");
  },
  methods: {
    initMap(){
      this.map = new google.maps.Map(document.getElementById("map"), {
        center: { lat: -34.397, lng: 150.644 },
        zoom: 15,
        mapTypeId: google.maps.MapTypeId.ROADMAP
      });
    },
    floatingNumber(e){   
      const floating = /^[0-9.]\d*(\.\d{1,2})?$/;
      let char = String.fromCharCode(e.keyCode);
      if (floating.test(char)) return true;
      else e.preventDefault();
    },
    isLetterOrNumber(e) {
        let char = String.fromCharCode(e.keyCode);
        if (/^[A-Za-z0-9]+$/.test(char)) return true;
        else e.preventDefault();
    },
    isNumber(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[0-9]+$/.test(char)) return true;
      else e.preventDefault();
    },
    userCheckEmail(e) {
      userService.userCheckEmail({
        email:this.typeform.email,
        table:'User',
        column:'user_id',
        vendor_id:this.vendor_id,
        user_role:'2'
      })
      .then(response => {
        if (response.data.code === 101) {
          error_message(response.data.message);
        }
      });
    },
    asyncFind(query) {
      this.isLoading = true;
      vendorService.getVendorCuisine(query).then((response) => {
        this.cuisine = response.data.result;
        this.isLoading = false;
      });
    },
    clearAll() {
      this.selectedCuisine = [];
    },
    getAddressData: function (addressData, placeResultData, id) {
      this.address = addressData;
      this.typeform.address = placeResultData.formatted_address;
      this.typeform.latitude = addressData.latitude;
      this.typeform.longitude = addressData.longitude;
      if(this.marker) {
        this.marker.setMap(null)
      }
      const marker = {
        lat: addressData.latitude,
        lng: addressData.longitude
      };
      var geocoder = new google.maps.Geocoder();
      const latlng = new google.maps.LatLng(addressData.latitude,addressData.longitude)
      this.marker = new google.maps.Marker({
        position: latlng,
        title:"Restaurant",
        draggable:true,
      });
      this.marker.setMap(this.map);
      this.map.setCenter(latlng);
      this.center = marker;
      this.map.setZoom(14);
      this.marker.addListener("dragend", e => {
        const latLng = new google.maps.LatLng(e.latLng.lat(),e.latLng.lng());
        this.typeform.latitude = e.latLng.lat();
        this.typeform.longitude = e.latLng.lng();
        let self = this;
        geocoder.geocode({ 'latLng': latLng }, function(results,status) {
          if(status === "OK") {
            const address = $("#addres").val(results[0].formatted_address);
            self.typeform.address = results[0].formatted_address;
          }
        });
      });
    },
    getSetting(){
      settingService.getSetting()
      .then(response => {
        const cent = {
          lat: parseFloat(response.data.result.system_latitude),
          lng: parseFloat(response.data.result.system_longitude)
        };
        this.center = cent; 
        var geocoder = new google.maps.Geocoder();
        const latlng = new google.maps.LatLng(response.data.result.system_latitude,response.data.result.system_longitude)
        this.marker = new google.maps.Marker({
          position: latlng,
          title:"Restaurant",
          draggable:true,
        });
        this.marker.setMap(this.map);
        this.map.setCenter(latlng);
        this.map.setZoom(14);
        this.vendor_currency = response.data.result.currency;
        this.marker.addListener("dragend", e => {
          const latLng = new google.maps.LatLng(e.latLng.lat(),e.latLng.lng());
          this.typeform.latitude = e.latLng.lat();
          this.typeform.longitude = e.latLng.lng();
          let self = this;
          geocoder.geocode({ 'latLng': latLng }, function(results,status) {
            if(status === "OK") {
              const address = $("#addres").val(results[0].formatted_address);
              self.typeform.address = results[0].formatted_address;
            }
          });
        });
      });
    },
    getVendorCountry() {
      this.typeform.state = '';
      this.typeform.city = '';
      this.states = [];
      this.cities = [];
      deliveryZoneService.getVendorCountry().then((response) => {
        this.country_count = response.data.result.length;
        this.countries = response.data.result;
        if (this.country_count == 1) {
          this.typeform.country = this.countries[0].id;
          this.getVendorState();
        }
      });
    },
    getVendorState() {
      this.states = [];
      this.cities = [];
      this.city_count = 0;
      this.state_count = 0;
      this.typeform.city = '';
      if(!this.typeform.country) return false;
      deliveryZoneService.getVendorState({country_id: this.typeform.country}).then((response) => {
        this.state_count = response.data.result.length;
        this.states = response.data.result;
        if (this.state_count == 1) {
          this.typeform.state = this.states[0].id;
          this.getVendorCity();
        } else {
          // if (this.typeform.country == "") {
          //   this.getAllState();
          //   this.getAllCity();
          //   this.typeform.state = "";
          //   this.typeform.city = "";
          // }
          // if(this.typeform.state ==''){
          //   this.getVendorCity();
          //   this.typeform.state = "";
          //   this.typeform.city = "";
          // }
        }
      });
    },
    getVendorCity() {
      this.cities = [];
      this.city_count = 0;
      if(!this.typeform.state) return false;
      deliveryZoneService.getVendorCity({state_id: this.typeform.state}).then((response) => {
        this.city_count = response.data.result.length;
        this.cities = response.data.result;
        if (this.city_count == 1) {
          this.typeform.city = this.cities[0].cityid;
        } else{
          // if(this.typeform.state ==''){
          //   this.getAllCity();
          //   this.typeform.city = '';
          // }
        }
      });
    },
    getAllState() {
      deliveryZoneService.getAllState()
        .then((response) => {
        this.states = response.data.result;
        this.state_count = response.data.result.length;
      });
    },
    getAllCity() {
      deliveryZoneService.getAllCity().then((response) => {
        this.cities = response.data.result;
        this.city_count = response.data.result.length;
      });
    },
    getResCategory() {
      vendorService.getResCategory().then((response) => {
        this.rescategories = response.data.result;
      });
    },
    vendorAdd() {
      this.$v.typeform.$touch();
      if (this.$v.typeform.$invalid || (parseFloat(this.typeform.radius) > parseFloat(this.$auth.setting.restaurant_kilometer))) {
        return;
      } else {
        const fd = new FormData();
        fd.append("name", this.typeform.name);
        fd.append("email", this.typeform.email);
        fd.append("phone", this.typeform.phone);
        fd.append("radius", this.typeform.radius);
        fd.append("country", this.typeform.country);
        fd.append("state", this.typeform.state);
        fd.append("city", this.typeform.city);
        fd.append("address", this.typeform.address);
        fd.append("location_address", this.typeform.location_address);
        fd.append("minimum_order_value", this.typeform.minimum_order_value);
        fd.append("two_person_price", this.typeform.two_person_price);
        fd.append("delivery_time", this.typeform.delivery_time);
        fd.append("restaurant_type", this.typeform.restaurant_type);
        fd.append("time_slots", this.typeform.time_slots);
        fd.append("currency", this.typeform.currency);
        fd.append("admin_commision_type", this.typeform.admin_commision_type);
        fd.append("admin_commision", this.typeform.admin_commision);
        fd.append("category_id", this.typeform.category_id);
        fd.append("restaurant_category", this.typeform.restaurant_category);
        fd.append("restaurant_tax", this.typeform.restaurant_tax ? this.typeform.restaurant_tax : 0);
        fd.append("restaurant_item_layout", this.typeform.restaurant_item_layout);
        fd.append("latitude", this.typeform.latitude);
        fd.append("longitude", this.typeform.longitude);
        fd.append("name_thai", this.typeform.name);
        fd.append("address_thai", this.typeform.address);
        fd.append("fssai_licence", 'NA');
        fd.append("delivery_type_time_slots", this.typeform.delivery_type_time_slots);
        fd.append("support_contact_number",this.typeform.support_contact_number ? this.typeform.support_contact_number : this.typeform.phone);
        fd.append("bank_name",this.typeform.bank_name);
        fd.append("bank_no",this.typeform.bank_no);
        fd.append("ifsc_code",this.typeform.ifsc_code);
        fd.append("branch_name",this.typeform.branch_name);
        fd.append("key", this.typeform.key);
        fd.append("payment_option",this.typeform.payment_option);
        fd.append("grade", this.typeform.grade);
        if (!!this.typeform.selectedCuisine) {
          this.typeform.selectedCuisine
            .map((item) => item.cuisine_id)
            .map((item, index) => {
              fd.append("selectedCuisine[" + index + "]", item);
            });
        }
        fd.append("icon_image", this.typeform.icon_image);
        vendorService.addVendor(fd).then((response) => {
          if (response.data.code === 200) {
            success_message(response.data.message);
            this.$router.push({ name: "vendor-list" });
          } else {
            if (response.data.code == 101 && response.data.result.exceeding_limit == 1) {
              Swal.fire({
                text: this.trans.trans('__JSON__.You have exceed limit for Adding vendor. Please upgrade your plan or contact support for same.',{ vendor: this.$auth.setting.restaurant_name }),
                imageHeight: 20,
                confirmButtonColor: "#556ee6"
              });
            } else {
              error_message(response.data.message);
            }
          }
        });
      }
    },
    filterInput(e){
      e.target.value = e.target.value.replace(/[^0-9]+/g, '');
    },
    contactNumber(evt) {
      let val1 = evt.target.value;
      if (!(evt.keyCode == 43 || (evt.keyCode >= 48 && evt.keyCode <= 57))) {
        evt.preventDefault();
      }
      let partlen = val1.split("+").length - 1;
      if (evt.keyCode == 43 && partlen > 0) {
        evt.preventDefault();
      } else if (evt.keyCode == 43 && (evt.target.selectionStart !== undefined && evt.target.selectionStart !== 0)) {
        evt.preventDefault();
      }
    },
    getGradeDetails(){
      vendorService.gradeDetailsList().then((response) => {
        this.gradeDetails = response.data.result;
      })
    }
  },
};
</script>

<template>
  <div class="row">
    <div class="col-12">
      <form @submit.prevent="vendorAdd">
        <!-- Vendor Info Start -->
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">{{ trans.trans('__JSON__.Vendor Info',{ vendor:  $auth.setting && $auth.setting.restaurant_name })}}</h4>
            <p class="card-title-desc"></p>
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group" v-if="$auth.setting">
                  <label class="required">{{ trans.trans('__JSON__.Vendor Name',{ vendor: $auth.setting && $auth.setting.restaurant_name , lang: $auth.langauges[0] && $auth.langauges[0].name}) }}</label>
                  <input
                    id="name"
                    v-model="$v.typeform.name.$model"
                    type="text"
                    class="form-control"
                    :placeholder="trans.trans('__JSON__.Enter Vendor Name langauge',{vendor: $auth.setting && $auth.setting.restaurant_name , lang: $auth.langauges[0] && $auth.langauges[0].name})"
                    name="name"
                    :class="{ 'is-invalid': $v.typeform.name.$error }"
                  />
                  <div v-if="$v.typeform.name.$error" class="invalid-feedback">
                    <span v-if="!$v.typeform.name.required">{{ trans.trans('__JSON__.Please enter vendor name',{ vendor: $auth.setting && $auth.setting.restaurant_name })}}</span>
                  </div>
                </div>
              </div>

              <div class="col-sm-6 d-none" v-if="$auth.langauges[1].name == 'No Secondary Language'">
              </div>
              <div class="col-sm-6" v-else>
               <div class="form-group" v-if="$auth.setting">
                <label>{{ trans.trans('__JSON__.Vendor Name',{vendor: $auth.setting && $auth.setting.restaurant_name_sec_lang , lang: $auth.langauges[0] && $auth.langauges[1].name })}}</label>
                <input
                  maxlength="100"
                  id="name_thai"
                  name="name_thai"
                  type="text"
                  v-model="typeform.name_thai"
                  :placeholder="trans.trans('__JSON__.Enter Vendor Name langauge',{vendor: $auth.setting && $auth.setting.restaurant_name_sec_lang , lang: $auth.langauges[0] && $auth.langauges[1].name })"
                  class="form-control"
                />
               </div>
              </div>

              <div class="col-sm-6">
                <div class="form-group">
                  <label class="required">{{ trans.get('__JSON__.E-Mail') }}</label>
                  <input
                    id="email"
                    v-model="$v.typeform.email.$model"
                    type="email"
                    name="email"
                    class="form-control"
                    @blur="userCheckEmail"
                    :class="{ 'is-invalid': $v.typeform.email.$error }"
                    placeholder="Enter a valid e-mail"
                  />
                  <div v-if="$v.typeform.email.$error" class="invalid-feedback">
                    <span v-if="!$v.typeform.email.required">{{ trans.get('__JSON__.Please enter email') }}</span>
                    <span v-if="!$v.typeform.email.email">{{ trans.get('__JSON__.This value should be a valid email') }}</span>
                  </div>
                </div>
              </div>

              <div class="col-sm-6">
                <div class="form-group">
                  <label class="required">{{ trans.get('__JSON__.Contact Number') }}</label>
                  <input
                    maxlength="20"
                    minlength="6"
                    id="phone"
                    v-model="$v.typeform.phone.$model"
                    type="text"
                    name="phone"
                    class="form-control"
                    :class="{ 'is-invalid': $v.typeform.phone.$error }"
                    :placeholder="trans.get('__JSON__.Enter Contact Number')"
                    @keypress="contactNumber($event)"
                    @paste.prevent
                    autocomplete="nope"
                  />
                  <!-- 
                    maxlength="10"
                    type="tel" 
                  -->
                  <div v-if="$v.typeform.phone.$error" class="invalid-feedback">
                    <span v-if="!$v.typeform.phone.required">{{ trans.get('__JSON__.Please enter contact number') }}</span>
                    <!-- <span v-if="!$v.typeform.phone.numeric">{{ trans.get('__JSON__.This value should be a valid number') }}</span> -->
                  </div>
                </div>
              </div>

              <div class="col-sm-6" v-if="$auth.isProductMarket()">
                <!-- <div class="form-group">
                  <label class="control-label">{{ trans.get('__JSON__.Select Category') }}</label>
                  <select
                    class="custom-select"
                    v-model="typeform.category_id"
                    id="category_id"
                    name="category_id"
                  >
                  <option value="0" selected>{{ trans.get('__JSON__.None') }}</option>
                    <option
                      v-for="res in rescategories"
                      :value="res.category_id"
                      :key="res.category_id"
                    >{{res.category_name}}</option>
                  </select>
                </div> -->

                <div class="form-group">
                  <label class="control-label">{{ trans.get('__JSON__.Select Category') }}</label>
                  <select
                    class="custom-select"
                    v-model="typeform.restaurant_category"
                    id="restaurant_category"
                    name="restaurant_category"
                  >
                  <option value="REGULAR" selected>{{ trans.get('__JSON__.Regular') }}</option>
                  <option value="SUPER_SPECIALITY">{{ trans.get('__JSON__.Super Special')}}</option>
                  </select>
                </div>
              </div>

              <div class="col-sm-6">
                <div class="form-group">
                  <label class="control-label">Select Tags</label>
                   <multiselect
                    v-model="$v.typeform.selectedCuisine.$model"
                    id="ajax"
                    label="cuisine_name"
                    track-by="cuisine_name"
                    :placeholder="trans.get('__JSON__.Search...')"
                    open-direction="bottom"
                    :options="cuisine"
                    :multiple="true"
                    :searchable="true"
                    :loading="isLoading"
                    :internal-search="true"
                    :clear-on-select="true"
                    :close-on-select="false"
                    :max-height="600"
                    :show-no-results="false"
                    :hide-selected="true"
                    @search-change="asyncFind"
                    :class="{ 'is-invalid': $v.typeform.selectedCuisine.$error }"
                  >
                  <template slot="tag" slot-scope="{ option, remove }">
                    <span class="custom__tag">
                      <span>{{ option.cuisine_name }}</span>
                      <span class="custom__remove" @click="remove(option)">❌</span>
                    </span>
                  </template>
                  <template slot="clear" slot-scope="props">
                    <div
                      class="multiselect__clear"
                      v-if="selectedCuisine.length"
                      @mousedown.prevent.stop="clearAll(props.search)"
                    ></div>
                  </template>
                  <span slot="noResult">{{trans.get('__JSON__Oops! No elements found. Consider changing the search query')}}</span>
                </multiselect>
                  <div v-if=" $v.typeform.selectedCuisine.$error" class="invalid-feedback">
                    <span
                    v-if="!$v.typeform.selectedCuisine.required"
                    >{{ trans.trans('__JSON__.Please select at least one') }}.</span>
                  </div>
                </div>
              </div>

              <div class="col-sm-6">
                  <div class="form-group">
                    <label class="required">{{ trans.get('__JSON__.Serviceable Radius (In KM)') }}</label>
                    <input
                      id="radius"
                      v-model="$v.typeform.radius.$model"
                      type="text"
                      name="radius"
                      class="form-control"
                      :class="{ 'is-invalid': $v.typeform.radius.$error || parseFloat(this.typeform.radius) > parseFloat(this.$auth.setting.restaurant_kilometer) }"
                      :placeholder="trans.get('__JSON__.Enter Serviceable Radius')"
                    />
                    <div v-if="$v.typeform.radius.$error" class="invalid-feedback">
                      <span
                        v-if="!$v.typeform.radius.required"
                      >{{ trans.get('__JSON__.Please enter serviceable radius') }}</span>
                    </div>
                    <div class="invalid-feedback">
                      <span v-if="this.typeform.radius && parseFloat(this.typeform.radius) > parseFloat(this.$auth.setting.restaurant_kilometer)">{{ trans.get('__JSON__.Serviceable radius can not be greater than near by stores radius')}}</span>
                    </div>
                  </div>
                </div>
              
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="required">{{ trans.get('__JSON__.Grade') }}</label>
                  <select
                        class="custom-select"
                        v-model="$v.typeform.grade.$model"
                        :class="{ 'is-invalid': $v.typeform.grade.$error}"
                  >
                        <option
                          v-for="res in gradeDetails"
                          :value="res.id"
                          :key="res.id"
                        >
                          {{ res.grade + '-' + res.color }}
                        </option>
                  </select>
                  <div v-if="$v.typeform.grade.$error" class="invalid-feedback">
                      <span
                        v-if="!$v.typeform.grade.required"
                      >{{ trans.get('__JSON__.Please enter grade') }}</span>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Vendor Info End -->

        <!-- Location Section Start -->
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <h4 class="card-title">{{ trans.get('__JSON__.Location') }}</h4>
                <p class="card-title-desc"></p>
              </div>
              <div class="col-md-6">
                 <p>{{ trans.get('__JSON__.Click & drag a Marker to reposition exact location') }}</p>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-6">
                <div v-if="country_count >1" class="form-group">
                  <label class="control-label required">{{ trans.get('__JSON__.Country') }}</label>
                  <select
                    class="custom-select"
                    v-model="$v.typeform.country.$model"
                    @change="getVendorState"
                    id="country"
                    name="country"
                    :class="{ 'is-invalid': $v.typeform.country.$error }"
                  >
                    <option value selected>{{ trans.get('__JSON__.Select Country') }}</option>
                    <option
                      v-for="con in countries"
                      :value="con.id"
                      :key="con.id"
                    >{{con.country_name}}</option>
                  </select>
                  <div v-if="$v.typeform.country.$error" class="invalid-feedback">
                    <span v-if="!$v.typeform.country.required">{{ trans.get('__JSON__.Please select country') }}</span>
                  </div>
                </div>

                <div v-if="(country_count == 1 && state_count > 1) || country_count > 1" class="form-group">
                  <label class="control-label required">{{ trans.get('__JSON__.State') }}</label>
                  <select
                    class="custom-select"
                    v-model="$v.typeform.state.$model"
                    @change="getVendorCity"
                    id="state"
                    name="state"
                    :class="{ 'is-invalid': $v.typeform.state.$error }"
                  >
                    <option value selected>{{ trans.get('__JSON__.Select State') }}</option>
                    <option v-for="sat in states" :value="sat.id" :key="sat.id">{{sat.state_name}}</option>
                  </select>
                  <div v-if="$v.typeform.state.$error" class="invalid-feedback">
                    <span v-if="!$v.typeform.state.required">{{ trans.get('__JSON__.Please select state')}}</span>
                  </div>
                </div>

                <div v-if="(country_count == 1 && state_count == 1 && city_count > 1) || country_count > 1 || state_count > 1" class="form-group">
                  <label class="control-label required">{{ trans.get('__JSON__.City') }}</label>
                  <select
                    class="custom-select"
                    v-model="$v.typeform.city.$model"
                    id="city"
                    name="city"
                    :class="{ 'is-invalid': $v.typeform.city.$error }"
                  >
                    <option value selected>{{ trans.get('__JSON__.Select City') }}</option>
                    <option
                      v-for="cit in cities"
                      :value="cit.cityid"
                      :key="cit.cityid"
                    >{{cit.cityname}}</option>
                  </select>
                  <div v-if="$v.typeform.city.$error" class="invalid-feedback">
                    <span v-if="!$v.typeform.city.required">{{ trans.get('__JSON__.Please select city') }}</span>
                  </div>
                </div>

                <div class="form-group">
                  <label class="control-label required">{{ trans.get('__JSON__.Search Vendor Location On Google Map') }}</label>
                  <vue-google-autocomplete
                    ref="address"
                    id="addres"
                    name="address"
                    type="text"
                    class="form-control"
                    :placeholder="trans.get('__JSON__.Search Vendor Location On Google Map')"
                    v-model="$v.typeform.address.$model"
                    v-on:placechanged="getAddressData"
                    :class="{'is-invalid':$v.typeform.address.$error}"
                    types=""
                    :country="[$auth.setting.country_code_type]"
                  >
                  </vue-google-autocomplete>
                  <!-- types="establishment" -->
                  <div v-if="$v.typeform.address.$error" class="invalid-feedback">
                    <span v-if="!$v.typeform.address.required">{{ trans.get('__JSON__.Please enter address') }}</span>
                  </div>
                </div>

                <div class="form-group">
                  <label class="control-label required">{{ trans.trans('__JSON__.Vendor Full address',{vendor: $auth.setting.restaurant_name} ) }}</label>
                  <input
                    id="location_address"
                    name="location_address"
                    type="text"
                    v-model="$v.typeform.location_address.$model"
                    class="form-control"
                    :placeholder="trans.trans('__JSON__.Enter Vendor full address',{vendor: $auth.setting.restaurant_name})"
                    :class="{ 'is-invalid': $v.typeform.location_address.$error }"
                  />
                  <div v-if="$v.typeform.location_address.$error" class="invalid-feedback">
                    <span
                      v-if="!$v.typeform.location_address.required"
                    >{{ trans.trans('__JSON__.Please enter Vendor full address',{vendor: $auth.setting.restaurant_name}) }}</span>
                  </div>
                </div>

                <div v-if="$auth.langauges[1].name == 'No Secondary Language'" class="form-group d-none">
                </div>
                <div class="form-group" v-else>
                  <label class="control-label">{{ trans.trans('__JSON__.Vendor Full address Secondary Language (Optional)', {vendor: $auth.setting.restaurant_name_sec_lang , lang: $auth.langauges[1].name }) }}</label>
                  <input
                    id="address_thai"
                    name="address_thai"
                    type="text"
                    class="form-control"
                    v-model="typeform.address_thai"
                    :placeholder="trans.trans('__JSON__.Enter Vendor full address Secondary language', {vendor: $auth.setting.restaurant_name_sec_lang , lang: $auth.langauges[1].name })"
                  />
                </div>
              </div>

              <div class="col-sm-6">
                <div class="map1" id="map" style="height: 100%">
                  <!-- <gmap-map :center="center" :zoom="7" >
                    <gmap-marker
                      v-for="(m, index) in markers"
                      :key="index"
                      :position="m.position"
                      :clickable="true"
                      :draggable="true"
                      @click="center = m.position"
                    ></gmap-marker>
                  </gmap-map> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Location Section End -->

        <!-- Other Info Start -->
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">{{ trans.get('__JSON__.Other Info') }}</h4>
            <p class="card-title-desc"></p>
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="required">
                    {{ trans.get('__JSON__.Minimum Order Amount') }} 
                    ( {{ vendor_currency}} )
                  </label>
                  <input
                    @input="filterInput"
                    id="minimum_order_value"
                    name="minimum_order_value"
                    type="text" 
                    v-model="$v.typeform.minimum_order_value.$model"
                    class="form-control"
                    :placeholder="trans.get('__JSON__.Minimum Order Amount')"
                    :class="{ 'is-invalid':$v.typeform.minimum_order_value.$error }"
                  />
                  <div v-if="$v.typeform.minimum_order_value.$error" class="invalid-feedback">
                    <span v-if="!$v.typeform.minimum_order_value.required">{{ trans.get('__JSON__.Please enter amount') }}</span>
                    <span
                          v-if="!$v.typeform.minimum_order_value.floating"
                        >{{ trans.get('__JSON__.This value should be digits')}}.</span>
                  </div>
                </div>
              </div>

              <div class="col-sm-6 d-none">
                <div class="form-group">
                  <label>
                    {{ trans.get('__JSON__.Cost for two person') }} ( {{ vendor_currency}} )  ({{ trans.get('__JSON__.Applicable for Restaurants') }})
                  </label>
                  <input
                    @input="filterInput"
                    id="two_person_price"
                    name="two_person_price"
                    type="text"
                    v-model="typeform.two_person_price"
                    class="form-control"
                    :placeholder="trans.get('__JSON__.Cost for two person')"
                    v-on:keypress="floatingNumber($event,typeform.two_person_price)"
                  /> 
                </div>
              </div>

              <div class="col-sm-6">
                <div class="form-group">
                  <label class="required">{{ trans.get('__JSON__.Avg. Processing Time (Min)') }}</label>
                  <input
                    maxlength="6"
                    @input="filterInput"
                    id="delivery_time"
                    name="delivery_time"
                    type="number"
                    v-model="$v.typeform.delivery_time.$model"
                    class="form-control"
                    :placeholder="trans.get('__JSON__.Avg. Processing Time (Min)')"
                    :class="{ 'is-invalid': $v.typeform.delivery_time.$error }"
                  />
                  <div v-if="$v.typeform.delivery_time.$error" class="invalid-feedback">
                    <span v-if="!$v.typeform.delivery_time.required">{{ trans.get('__JSON__.Please enter avg delivery time') }}</span>
                    <span v-if="!$v.typeform.delivery_time.numeric">{{ trans.get('__JSON__.Please enter avg delivery time') }}</span>
                  </div>
                </div>
              </div>

              <div class="col-sm-6">
                <div class="form-group">
                  <label>{{ trans.get('__JSON__.Business Licence Number') }}</label>
                  <input
                    id="fssai_licence"
                    name="fssai_licence"
                    type="text"
                    class="form-control"
                    v-model="typeform.fssai_licence"
                    :placeholder="trans.get('__JSON__.Business Licence Number')"
                    v-on:keypress="isLetterOrNumber($event)"
                  />
                </div>
              </div>

              <div class="col-sm-6">
                <div class="form-group">
                  <label class="control-label required">{{ trans.get('__JSON__.Admin Commission Type') }}</label>
                  <select
                    class="custom-select"
                    v-model="typeform.admin_commision_type"
                    id="admin_commision_type"
                    name="admin_commision_type"
                  >
                    <option value="">{{ trans.get('__JSON__.Select Admin Commission') }}</option>
                    <option value="commission_in">{{ trans.get('__JSON__.Fixed') }} ( {{ vendor_currency}} )</option>
                    <option value="commission_in_percentage">{{ trans.get('__JSON__.Percentage') }} (%)</option>
                  </select>
                </div>
              </div>

              <div class="col-sm-6">
                <div class="form-group">
                  <label class="required">{{ trans.get('__JSON__.Admin Commission Value') }}</label>
                  <input
                    id="admin_commision"
                    name="admin_commision"
                    type="text"
                    maxlength="100"
                    @input="filterInput"
                    v-model="$v.typeform.admin_commision.$model"
                    class="form-control"
                    :placeholder="trans.get('__JSON__.Admin Commission Value')"
                    :class="{ 'is-invalid': $v.typeform.admin_commision.$error }"
                  />
                  <div v-if="$v.typeform.admin_commision.$error" class="invalid-feedback">
                    <span
                      v-if="!$v.typeform.admin_commision.required"
                    >{{ trans.get('__JSON__.Please enter admin commission') }}</span>
                    <span
                          v-if="!$v.typeform.admin_commision.floating"
                        >{{ trans.get('__JSON__.This value should be digits')}}.</span>
                  </div>
                </div>
              </div>

              <div class="col-sm-6 d-none">
                <div class="form-group">
                  <label class="control-label">{{ trans.trans('__JSON__.Vendor Type',{ vendor: $auth.setting.restaurant_name }) }}</label>
                  <select
                    class="custom-select"
                    v-model="typeform.restaurant_type"
                    id="restaurant_type"
                    name="restaurant_type"
                  >
                    <option value="" >{{ trans.get('__JSON__.Select Vendor Type',{ vendor: $auth.setting.restaurant_name }) }}</option>
                    <option v-bind:value="0" selected>{{ trans.get('__JSON__.All') }}</option>
                    <option v-bind:value="1">{{ trans.get('__JSON__.Pure Veg') }}</option>
                    <option v-bind:value="2">{{ trans.get('__JSON__.Non Veg') }}</option>
                    <option v-bind:value="3">{{ trans.get('__JSON__.Not Applicable') }}</option>
                  </select>
                </div>
              </div>

              <div class="col-sm-6">
                <div class="form-group">
                  <label class="control-label">{{ trans.get('__JSON__.Time Slot') }}</label>
                  <select
                    name="time_slots"
                    class="custom-select"
                    id="time_slots"
                    v-model="typeform.time_slots"
                  >
                    <option value="">{{ trans.get('__JSON__.Select Time Slot') }}</option>
                    <option value="15" selected>15 {{ trans.get('__JSON__.Mins') }}</option>
                    <option value="30">30 {{ trans.get('__JSON__.Mins') }}</option>
                    <option value="45">45 {{ trans.get('__JSON__.Mins') }}</option>
                    <option value="60">1 {{ trans.get('__JSON__.Hour') }}</option>
                    <option value="120">2 {{ trans.get('__JSON__.Hours') }}</option>
                    <option value="180">3 {{ trans.get('__JSON__.Hours') }}</option>
                    <option value="240">4 {{ trans.get('__JSON__.Hours') }}</option>
                    <option value="480">8 {{ trans.get('__JSON__.Hours') }}</option>
                    <option value="540">9 {{ trans.get('__JSON__.Hours') }}</option>
                  </select>
                </div>
              </div>

              <div class="col-sm-6" v-if="$auth.setting.is_pickup_enable == true">
                <div class="form-group">
                  <label class="control-label">{{ trans.get('__JSON__.Delivery Type & Time Slots') }}</label>
                  <select
                    class="custom-select"
                    v-model="typeform.delivery_type_time_slots"
                    id="delivery_type_time_slots"
                    name="delivery_type_time_slots"
                  >
                    <option value="">{{ trans.get('__JSON__.Select Delivery Type') }}</option>
                    <option v-bind:value="0">{{ trans.get('__JSON__.Pickup') }}</option>
                    <option v-bind:value="1">{{ trans.get('__JSON__.Delivery') }}</option>
                    <option v-bind:value="2" selected>{{ trans.get('__JSON__.Both') }}</option>
                  </select>
                </div>
              </div>

              <div class="col-sm-6">
                <div class="form-group">
                  <label class="control-label">{{ trans.get('__JSON__.GST',{gst: $auth.getTaxName()})}} (%)</label>
                  <input
                    id="restaurant_tax"
                    name="restaurant_tax"
                    type="text"
                    class="form-control"
                    v-model="typeform.restaurant_tax"
                    v-on:keypress="floatingNumber($event,typeform.restaurant_tax)"
                    :placeholder="trans.get('__JSON__.Vendor GST',{gst: $auth.getTaxName()})"
                    maxlength="3"
                  />
                </div>
              </div>

              <div class="col-sm-6">
                <div class="form-group">
                  <label class="control-label">{{ trans.trans('__JSON__.Vendor Details Page Layout',{ vendor: $auth.setting.restaurant_name }) }}</label>
                  <select
                    class="custom-select"
                    v-model="typeform.restaurant_item_layout"
                    id="restaurant_item_layout"
                    name="restaurant_item_layout"
                  >
                    <option value="">{{ trans.get('__JSON__.Select Page Layout') }}</option>
                    <option v-bind:value="1" selected>{{ trans.get('__JSON__.Layout') }} 1</option>
                    <option v-bind:value="2">{{ trans.get('__JSON__.Layout') }} 2</option>
                    <option v-bind:value="3">{{ trans.get('__JSON__.Layout') }} 3</option>
                  </select>
                </div>
              </div>

               <div class="col-sm-6">
                <div class="form-group">
                  <label>{{ trans.get('__JSON__.Phone Support') }}</label>
                  <input
                    maxlength="12"
                    minlength="8"
                    id="support_contact_number"
                    v-model="typeform.support_contact_number"
                    type="tel"
                    name="support_contact_number"
                    class="form-control"
                    :placeholder="trans.get('__JSON__.Enter Phone Number')"
                  />
                </div>
              </div>

              <div class="col-sm-6" v-if="$auth.setting && $auth.setting.restaurant_payment_option == 'Restaurant Select Payment Option'">
                <div class="form-group">
                  <label>{{ trans.get('__JSON__.Payment Option') }}</label>
                 <select
                    class="custom-select"
                    v-model="typeform.payment_option"
                    id="payment_option"
                    name="payment_option"
                  >
                    <option value="Both">{{ trans.get('__JSON__.Both') }}</option>
                    <option value="COD">{{ trans.get('__JSON__.Cash On Delivery') }}</option>
                    <option value="PAY ONLINE">{{ trans.get('__JSON__.PAY ONLINE') }}</option>
                  </select>
                </div>
              </div>
              
            </div>
          </div>
        </div>
        <!-- Other Info End -->

         <!-- Vendor Bank Detail Start -->
        <div class="card">
	        <div class="card-body">
	        	<h4 class="card-title">{{ trans.get('__JSON__.Finance') }}</h4>
	        	<p></p>
	        	<div class="row">
	        		<div class="col-sm-6">
	        		 <div class="form-group">
	        		  <label>
	                    {{ trans.get('__JSON__.Bank Name') }}
	                  </label>
	                  <input
	                    id="bank_name"
	                    name="bank_name"
	                    type="text"
	                    v-model="typeform.bank_name"
	                    class="form-control"
	                    :placeholder="trans.get('__JSON__.Enter Bank Name')"
	                  /> 
	        		</div>
	        	   </div>
	        		<div class="col-sm-6">
	        		 <div class="form-group">
	        		  <label>
	                    {{ trans.get('__JSON__.Account No') }}
	                  </label>
	                  <input
	                    id="bank_no"
	                    name="bank_no"
	                    type="text"
	                    v-model="typeform.bank_no"
	                    class="form-control"
	                    :placeholder="trans.get('__JSON__.Enter Bank Account Number')"
	                  /> 
	        		</div>
	        	  </div>
	        	</div>
	        	<div class="row">
	        		<div class="col-sm-6">
	        		 <div class="form-group">
	        		  <label>
	                    {{ trans.get('__JSON__.IFSC Code') }}
	                  </label>
	                  <input
	                    id="ifsc_code"
	                    name="ifsc_code"
	                    type="text"
	                    v-model="typeform.ifsc_code"
	                    class="form-control"
	                    :placeholder="trans.get('__JSON__.Enter IFSC Code')"
	                  /> 
	        		</div>
	        	   </div>
	        		<div class="col-sm-6">
	        		 <div class="form-group">
	        		  <label>
	                    {{ trans.get('__JSON__.Branch Name') }}
	                  </label>
	                  <input
	                    id="branch_name"
	                    name="branch_name"
	                    type="text"
	                    v-model="typeform.branch_name"
	                    class="form-control"
	                    :placeholder="trans.get('__JSON__.Enter Branch Name')"
	                  /> 
	        		</div>
	        	  </div>
	        	</div>
	        </div>
        </div>
        <!-- Vendor Bank Detail End -->

        <!-- Vendor Image Start -->
        <div class="card">
          <div class="card-body">
            <h4 class="card-title required">{{ trans.trans('__JSON__.Vendor Image',{ vendor: $auth.setting.restaurant_name }) }}</h4>
            <p class="card-title-desc"></p>
            <div class="row">
              <div class="col-sm-3">
                <dropzone-popup
                  v-model="typeform.icon_image"
                  type="file"
                  id="icon_image"
                  name="icon_image"
                  acceptedFiles=".jpeg,.jpg,.png,.webp,image/webp"
                  :class="{ 'is-invalid': $v.typeform.icon_image.$error }"
                />
                <div v-if="$v.typeform.icon_image.$error" class="invalid-feedback cuisine-img">
                  <span
                    v-if="!$v.typeform.icon_image.required"
                  >{{ trans.get('__JSON__.Please select image') }}</span>
                </div>
              </div> 
            </div>

            <div class="row">
              <div class="col-sm-6">
                <button type="submit" class="btn btn-primary mr-1 mt-3">{{ trans.trans('__JSON__.Add Vendor',{ vendor: $auth.setting.restaurant_name }) }}</button>
                <b-button @click="$router.back()" type="button" variant="secondary" class="mt-3">{{ trans.get('__JSON__.Cancel') }}</b-button>
              </div>
            </div>
          </div>
        </div>

        <!-- Vendor Image End -->
      </form>
    </div>
  </div>
  <!-- end row -->
</template>
