<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import pagination from "laravel-vue-pagination";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import {
  get_partner_login,
  set_partner,
  set_partner_details,
  set_user,
  set_user_detail,
  debounce,
  get_decimal,
  get_currency,
  date_ranges,
} from "../../Helper/helper";
import moment from "moment";
import { reportService, deliveryZoneService } from "../../services";
/**
 * Report component
 */
export default {
  components: { Layout, PageHeader, pagination, DateRangePicker },
  data() {
    let startDate = this.$auth.partner_login
      ? this.moment().startOf("day").subtract(29, "days")
      : this.moment().startOf("day").subtract(6, "days");
    let endDate = this.moment().endOf("day").toDate();
    return {
      decimal: get_decimal(),
      currency: get_currency(),
      dateRange: { startDate, endDate },
      reports: [],
      reportsData: [],
      restaurant: [],
      restaurant_count: "",
      restaurants_id: "",
      paginations: {},
      countries: [],
      country: "0",
      states: [],
      state: "0",
      cities: [],
      city: "0",
      country_count: "",
      state_count: "",
      city_count: "",
      date_ranges: date_ranges,
      restaurant: [],
      restaurant_count: "",
      order_status: "",
      restaurants_id: "",
      limit: 2,
      perPage: 0,
      currentPage: 1,
      payment_method: "",
      filters: {
        start_date: this.$auth.partner_login
          ? this.moment()
              .startOf("day")
              .subtract(29, "days")
              .format("YYYY-MM-DD")
          : this.moment()
              .startOf("day")
              .subtract(6, "days")
              .format("YYYY-MM-DD"),
        end_date: this.moment().format("YYYY-MM-DD"),
      },
      sortBy: "created_at",
      moment: moment,
      loading: true,
      isDownloading: false,
      fields: [
        { key: "table_id", label: "#", sortable: false },
        { key: "id", label: "Order ID", sortable: true },
        { key: "created_at", label: "Order Date", sortable: false },
        { key: "customer_name", label: "Patient Name", sortable: false },
        {
          key: "customer_mobile_number",
          label: "Patient Phone Number",
          sortable: false,
          requiresAdmin: true,
        },
        {
          key: "store_name",
          label: "Vendor Name",
          sortable: false,
          requiresAdmin: true,
        },
        {
          key: "driver_name",
          label: "Driver Name",
          sortable: false,
          requiresAdmin: true,
        },
        {
          key: "medicine_item_mrp_sub_total",
          label: "Medicine MRP",
          sortable: false,
        },
        { key: "medicine_item_price", label: "Medicine Price", sortable: true },
        {
          key: "other_item_mrp_sub_total",
          label: "General Products MRP",
          sortable: true,
        },
        {
          key: "other_item_price",
          label: "General Products Price",
          sortable: true,
        },
        {
          key: "distance_km",
          label: "Distance",
          sortable: false,
          requiresAdmin: true,
        },
        {
          key: "driver_earning",
          label: "Driver Earning",
          sortable: false,
          requiresAdmin: true,
        },
        {
          key: "itemtaxslab",
          label: "Item " + this.$auth.getTaxName(),
          sortable: false,
          requiresAdmin: true,
        },
        {
          key: "delivery_fee",
          label: "Delivery Charge",
          sortable: true,
          requiresAdmin: true,
        },
        { key: "coupon_discount", label: "Coupon Discount", sortable: false },
        {
          key: "total_amount",
          label: "Total Amount",
          sortable: true,
          requiresAdmin: true,
        },
        {
          key: "payable_amount",
          label: "Payable Amount",
          sortable: true,
          requiresAdmin: true,
        },
        {
          key: "wallet_amount",
          label: "Wallet Amount",
          sortable: true,
          requiresAdmin: true,
          walletActive: this.$auth.user.wallet_status,
        },
        {
          key: "admin_commision",
          label: "Admin Commission",
          sortable: true,
          requiresAdmin: true,
        },
        {
          key: "admin_commision_gst",
          label: "Admin Commission GST",
          sortable: true,
          requiresAdmin: true,
        },
        { key: "payment_method", label: "Payment Method", sortable: true },
        { key: "order_status", label: "Order Status", sortable: true },
        { key: "earning", label: "Settlement Amount", sortable: false },
        {
          key: "instamed_cash",
          label: "Instamed Cash",
          sortable: false,
          requiresAdmin: true,
        },
        {
          key: "referral_discount",
          label: "Referral Discount",
          sortable: false,
          requiresAdmin: true,
        },
        { key: "start_created_at", label: "Start Time", sortable: false },
        { key: "delivered_date", label: "End Time", sortable: false },
        { key: "Duration", label: "Duration", sortable: false },

        {
          key: "accountholder_name",
          label: "Account Holder Name",
          sortable: false,
          requiresAdmin: true,
        },
        {
          key: "accountholder_number",
          label: "Account Holder Number",
          sortable: false,
          requiresAdmin: true,
        },
        {
          key: "source_type",
          label: "Lead Source",
          sortable: false,
          requiresAdmin: true,
        },
        {
          key: "device_type",
          label: "Device Type",
          sortable: false,
          requiresAdmin: true,
        },
        {
          key: "settled",
          label: "Settlement Status",
          sortable: false,
          requiresAdmin: true,
        },
        {
          key: "un_settle_reason",
          label: "Reason",
          sortable: false,
          requiresAdmin: true,
        },
        {
          key: "customer_state",
          label: "Customer State",
          sortable: false,
          requiresAdmin: true,
        },
        {
          key: "hdp_Id",
          label: "HDP Order",
          sortable: false,
          requiresAdmin: true,
        },
        {
          key: "invoice_date",
          label: "Invoice Date",
          sortable: false,
          requiresAdmin: true,
        },
        {
          key: "order_placed_by_name",
          label: "Order Placed By Name",
          sortable: false,
          requiresAdmin: true,
        },
        {
          key: "order_placed_by_number",
          label: "Order Placed By Number",
          sortable: false,
          requiresAdmin: true,
        },
      ],
    };
  },
  computed: {
    computedFields() {
      // If the user isn't an admin, filter out fields that require auth.
      if (this.$auth.partner_login)
        return this.fields.filter((field) => !field.requiresAdmin);
      // If the user IS an admin, return all fields.
      else
        return this.fields.filter(
          (field) => !field.walletActive || field.walletActive == "active"
        );
      // return this.fields;
    },
  },
  mounted() {
    this.$data.fields[5].label = this.$auth.getRestaurantName() + " Name";
    this.$data.fields[6].label = this.$auth.getDriverName() + " Name";
    this.$data.fields[12].label = this.$auth.getDriverName() + " Earning";
    // this.getSalesReports();
    // this.getRestaurants();
    this.getVendorCountry();
  },
  methods: {
    updateValues(date) {
      this.filters = {
        ...this.filters,
        start_date: this.moment(date.startDate.toISOString()).format(
          "YYYY-MM-DD"
        ),
        end_date: this.moment(date.endDate.toISOString()).format("YYYY-MM-DD"),
      };
      this.getSalesReports();
    },
    dateFormat(classes, date) {
      this.filters = {
        ...this.filters,
        start_date: this.moment(date.startDate.toISOString()).format(
          "YYYY-MM-DD"
        ),
        end_date: this.moment(date.endDate.toISOString()).format("YYYY-MM-DD"),
      };
    },
    downloadsCSV: function () {
      if (this.$auth.partner_login) {
        let reportsData =
          "\ufeff" +
          "Order ID,Order Date,Patient Name,Medicine MRP,Medicine Price,General Products MRP,General Products Price,Coupon Discount,Payment Method,Order Status,Settlement Amount,Start Time, End Time, Duration,Invoice Status,Invoice Date,Order placed by name,Order placed by number\n";
        this.reportsData.forEach((el) => {
          let orderIdText = el["id"];
          if (el["sipment1_orderId"]) {
            if (el["is_depot_store"] == 1) {
              orderIdText = `Depot ${el["id"]} (Sub Order of ${el["sipment1_orderId"]})`;
            }
          }
          // if (el.parent_id && el.parent_id > 0) {
          //   orderIdText = el.parent_id + " Ship. 1";
          // } else if (el.suborder && el.suborder.id && el.suborder.id > 0) {
          //   orderIdText = el["id"] + " Ship. 2";
          // }
          var line =
            orderIdText +
            "," +
            moment(el["created_at"]).format("DD-MM-YY hh:mm A") +
            "," +
            el["customer_name"].split(",").join("") +
            "," +
            el["medicine_item_mrp_sub_total"] +
            "," +
            el["medicine_item_price"] +
            "," +
            el["other_item_mrp_sub_total"] +
            "," +
            el["other_item_price"] +
            "," +
            el["coupon_discount"] +
            "," +
            (el["payment_method"] == "Cash On Delivery" ? "COD" : "Online") +
            "," +
            // (el["order_status"] == "Cancelled" ? "Cancelled" : "Delivered") +
            (el["order_status"] == "Placed"
              ? "New Request"
              : el["order_status"] == "InKitchen"
              ? "Confirmed"
              : el["order_status"]) +
            "," +
            el["earning"] +
            "," +
            el["created_at"] +
            "," +
            (el["delivered_date"]
              ? el["delivered_date"]
              : "0000-00-00 00:00:00") +
            "," +
            this.getDuration(el["created_at"], el["delivered_date"]) +
            "," +
            (el["order_status"] &&
            el["order_status"].toLowerCase() == "delivered"
              ? el["vendor_invoice"] == null
                ? "Pending"
                : "Uploaded"
              : "-") +
            "," +
            (el["order_status"] &&
            el["order_status"].toLowerCase() == "delivered" &&
            el["invoice_date"]
              ? moment(el["invoice_date"]).format("DD-MM-YY hh:mm A")
              : "-") +
            "," +
            el["order_placed_by_name"] +
            "," +
            el["order_placed_by_number"] +
            "\n";
          reportsData += line;
        });

        var blob = new Blob([reportsData], { type: "csv/plain" });
        var date = new Date().toLocaleString();
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "sales_" + date + ".csv";
        link.click();
      } else {
        let reportsData = "";
        if (this.$auth.user.wallet_status == "active") {
          reportsData =
            "\ufeff" +
            "Order ID,Order Date,Patient Name,Patient Phone Number," +
            this.$auth.getRestaurantName() +
            " Name," +
            this.$auth.getDriverName() +
            " Name,Medicine MRP,Medicine Price,General Products MRP,General Products Price,Distance," +
            this.$auth.getDriverName() +
            " Earning,Item " +
            this.$auth.getTaxName() +
            ",Delivery Charge,Coupon Discount,Total Amount,Payable Amount,Wallet Amount,Admin Commission,Admin Commission GST,Payment Method,Order Status,Settlement Amount,Instamed Cash, Referral Discount,Start Time, End Time, Duration,Account Holder Name,Account Holder Number,Lead Source,Device Type,Settlement Status,Reason,Customer State,HDP Order,Invoice Status,Invoice Date,Order placed by name,Order placed by number\n";
          this.reportsData.forEach((el) => {
            let orderIdText = el["id"];
            let accountholdernumber;
            if (el["accountholder_number"] == "9999999999") {
              accountholdernumber = "-";
            } else {
              accountholdernumber = el["accountholder_number"];
            }
            if (el["sipment1_orderId"]) {
              if (el["is_depot_store"] == 1) {
                orderIdText = `Depot ${el["id"]} (Sub Order of ${el["sipment1_orderId"]})`;
              }
            }
            // if (el.parent_id && el.parent_id > 0) {
            //   orderIdText = el.parent_id + " Ship. 1";
            // } else if (el.suborder && el.suborder.id && el.suborder.id > 0) {
            //   orderIdText = el["id"] + " Ship. 2";
            // }
            var line =
              orderIdText +
              "," +
              moment(el["created_at"]).format("DD-MM-YY hh:mm A") +
              "," +
              el["customer_name"].split(",").join("") +
              "," +
              el["customer_mobile_number"] +
              "," +
              (el["store_name"] ? el["store_name"] : "").replaceAll(" ", " ") +
              "," +
              (el["driver_name"] ? el["driver_name"] : "-") +
              "," +
              el["medicine_item_mrp_sub_total"] +
              "," +
              el["medicine_item_price"] +
              "," +
              el["other_item_mrp_sub_total"] +
              "," +
              el["other_item_price"] +
              "," +
              (el["distance_km"] ? el["distance_km"] : "-") +
              "," +
              el["driver_earning"] +
              "," +
              el["itemtaxslab"] +
              "," +
              el["delivery_fee"] +
              "," +
              el["coupon_discount"] +
              "," +
              el["total_amount"] +
              "," +
              (el["payable_amount"] ? el["payable_amount"] : "-") +
              "," +
              el["wallet_amount"] +
              "," +
              el["admin_commision"] +
              "," +
              this.getAdminCommisionGst(el["admin_commision"]) +
              "," +
              (el["payment_method"] == "Cash On Delivery" ? "COD" : "Online") +
              "," +
              // (el["order_status"] == "Cancelled" ? "Cancelled" : "Delivered") +
              (el["order_status"] == "Placed"
                ? "New Request"
                : el["order_status"] == "InKitchen"
                ? "Confirmed"
                : el["order_status"]) +
              "," +
              el["earning"] +
              "," +
              (el["instamed_cash"] ? el["instamed_cash"] : "-") +
              "," +
              (el["referral_discount"] ? el["referral_discount"] : "-") +
              "," +
              el["created_at"] +
              "," +
              (el["delivered_date"]
                ? el["delivered_date"]
                : "0000-00-00 00:00:00") +
              "," +
              this.getDuration(el["created_at"], el["delivered_date"]) +
              "," +
              el["accountholder_name"] +
              "," +
              accountholdernumber +
              "," +
              (el["source_type"] ? el["source_type"] : "-") +
              "," +
              el["device_type"] +
              "," +
              (el["settled"] == 0 ? "Unsettled" : "Settled") +
              "," +
              (el["un_settle_reason"] ? el["un_settle_reason"] : "-") +
              "," +
              el["customer_state"] +
              "," +
              (el["hdp_Id"] ? "Yes" : "No") +
              "," +
              (el["order_status"] &&
              el["order_status"].toLowerCase() == "delivered"
                ? el["vendor_invoice"] == null
                  ? "Pending"
                  : "Uploaded"
                : "-") +
              "," +
              (el["order_status"] &&
              el["order_status"].toLowerCase() == "delivered" &&
              el["invoice_date"]
                ? moment(el["invoice_date"]).format("DD-MM-YY hh:mm A")
                : "-") +
              "," +
              el["order_placed_by_name"] +
              "," +
              el["order_placed_by_number"] +
              "\n";
            reportsData += line;
          });
        } else {
          reportsData =
            "\ufeff" +
            "Order ID,Order Date,Patient Name,Patient Phone Number," +
            this.$auth.getRestaurantName() +
            " Name," +
            this.$auth.getDriverName() +
            " Name,Medicine MRP,Medicine Price,General Products MRP,General Products Price,Distance," +
            this.$auth.getDriverName() +
            " Earning,Item " +
            this.$auth.getTaxName() +
            ",Delivery Charge,Coupon Discount,Total Amount,Payable Amount,Admin Commission,Admin Commission GST,Payment Method,Order Status,Settlement Amount,Instamed Cash, Referral Discount,Start Time, End Time, Duration,Account Holder Name,Account Holder Number,Lead Source,Device Type,Settlement Status,Reason,Customer State,HDP Order,Invoice Status,Invoice Date,Order placed by name,Order placed by number\n";
          this.reportsData.forEach((el) => {
            let orderIdText = el["id"];
            if (el["sipment1_orderId"]) {
              if (el["is_depot_store"] == 1) {
                orderIdText = `Depot ${el["id"]} (Sub Order of #${el["sipment1_orderId"]})`;
              }
            }
            let accountholdernumber;
            if (el["accountholder_number"] == "9999999999") {
              accountholdernumber = "-";
            } else {
              accountholdernumber = el["accountholder_number"];
            }
            // if (el.parent_id && el.parent_id > 0) {
            //   orderIdText = el.parent_id + " Ship. 1";
            // } else if (el.suborder && el.suborder.id && el.suborder.id > 0) {
            //   orderIdText = el["id"] + " Ship. 2";
            // }
            var line =
              orderIdText +
              "," +
              moment(el["created_at"]).format("DD-MM-YY hh:mm A") +
              "," +
              el["customer_name"].split(",").join("") +
              "," +
              el["customer_mobile_number"] +
              "," +
              (el["store_name"] ? el["store_name"] : "").replaceAll(" ", " ") +
              "," +
              (el["driver_name"] ? el["driver_name"] : "-") +
              "," +
              el["medicine_item_mrp_sub_total"] +
              "," +
              el["medicine_item_price"] +
              "," +
              el["other_item_mrp_sub_total"] +
              "," +
              el["other_item_price"] +
              "," +
              (el["distance_km"] ? el["distance_km"] : "-") +
              "," +
              el["driver_earning"] +
              "," +
              el["itemtaxslab"] +
              "," +
              el["delivery_fee"] +
              "," +
              el["coupon_discount"] +
              "," +
              el["total_amount"] +
              "," +
              (el["payable_amount"] ? el["payable_amount"] : "-") +
              "," +
              el["admin_commision"] +
              "," +
              this.getAdminCommisionGst(el["admin_commision"]) +
              "," +
              (el["payment_method"] == "Cash On Delivery" ? "COD" : "Online") +
              "," +
              // (el["order_status"] == "Cancelled" ? "Cancelled" : "Delivered") +
              (el["order_status"] == "Placed"
                ? "New Request"
                : el["order_status"] == "InKitchen"
                ? "Confirmed"
                : el["order_status"]) +
              "," +
              el["earning"] +
              "," +
              (el["instamed_cash"] ? el["instamed_cash"] : "-") +
              "," +
              (el["referral_discount"] ? el["referral_discount"] : "-") +
              "," +
              el["created_at"] +
              "," +
              (el["delivered_date"]
                ? el["delivered_date"]
                : "0000-00-00 00:00:00") +
              "," +
              this.getDuration(el["created_at"], el["delivered_date"]) +
              "," +
              el["accountholder_name"] +
              "," +
              accountholdernumber +
              "," +
              (el["source_type"] ? el["source_type"] : "-") +
              "," +
              el["device_type"] +
              "," +
              (el["settled"] == 0 ? "Unsettled" : "Settled") +
              "," +
              (el["un_settle_reason"] ? el["un_settle_reason"] : "-") +
              "," +
              el["customer_state"] +
              "," +
              (el["hdp_Id"] ? "Yes" : "No") +
              "," +
              (el["order_status"] &&
              el["order_status"].toLowerCase() == "delivered"
                ? el["vendor_invoice"] == null
                  ? "Pending"
                  : "Uploaded"
                : "-") +
              "," +
              (el["order_status"] &&
              el["order_status"].toLowerCase() == "delivered" &&
              el["invoice_date"]
                ? moment(el["invoice_date"]).format("DD-MM-YY hh:mm A")
                : "-") +
              "," +
              el["order_placed_by_name"] +
              "," +
              el["order_placed_by_number"] +
              "\n";
            reportsData += line;
          });
        }
        var blob = new Blob([reportsData], { type: "csv/plain" });
        var date = new Date().toLocaleString();
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "sales_" + date + ".csv";
        link.click();
      }
    },
    getVendorCountry() {
      this.countries = [];
      this.states = [];
      this.cities = [];
      deliveryZoneService.getVendorCountry().then((response) => {
        this.countries = response.data.result;
        this.getVendorState();
      });
    },
    getDuration(created_at, delivered_date) {
      let date1 = moment(created_at);
      let date2 = moment(delivered_date);
      return date2.diff(date1, "minutes");
    },
    getAdminCommisionGst(admin_commision) {
      let adminCommisionCal = admin_commision.toFixed(2);
      let adminGST = (adminCommisionCal * 18) / 100;
      return adminGST.toFixed(2);
    },
    getRestaurants() {
      this.restaurant = [];
      reportService
        .getAllRestaurantName({
          country_id: this.country,
          state_id: this.state,
          city_id: this.city,
        })
        .then((response) => {
          this.restaurant = response.data.result;
          this.restaurant_id = this.restaurant[0]?.restaurant_id;
          this.searchData();
        });
    },
    getVendorState() {
      this.city = "0";
      this.state = "0";
      this.states = [];
      this.cities = [];
      deliveryZoneService
        .getVendorState({
          country_id: this.country,
        })
        .then((response) => {
          this.states = response.data.result;
          this.getVendorCity();
        });
    },
    getVendorCity() {
      this.city = "0";
      this.cities = [];
      deliveryZoneService
        .getVendorCity({
          state_id: this.state ? this.state : "0",
          country_id: this.country,
        })
        .then((response) => {
          this.cities = response.data.result;
          this.searchData();
          this.getRestaurants();
        });
    },
    citychange() {
      this.searchData();
      this.getRestaurants();
    },
    getUser() {
      this.users = this.$auth.user;
      this.userdetail = this.$auth.setting;
    },
    getSalesReports(page) {
      this.loading = true;
      if (this.$auth.partner_login) {
        reportService
          .getSalesReport({
            ...this.filters,
            payment_method: this.payment_method,
            sortBy: this.sortBy,
            orderBy: this.sortDesc ? "desc" : "asc",
            page: page ? page : 1,
          })
          .then((response) => {
            this.loading = false;
            this.paginations = response.data.result;
            this.reports = response.data.result.data;
            // if(response.data.result.length > 0)
            // {
            //   var total = {
            //     table_id: "Total",
            //     sub_total: this.reports.reduce((a, m) => a + m.sub_total, 0),
            //     delivery_fee: this.reports.reduce((a, m) => a + m.delivery_fee,0),
            //     coupon_discount: this.reports.reduce(
            //       (a, m) => a + m.coupon_discount,
            //       0
            //     ),
            //     total_amount: this.reports.reduce((a, m) => a + m.total_amount,0),
            //     earning: this.reports.reduce((a, m) => a + m.earning,0),
            //   };
            //   this.reports = [...this.reports, total];

            // }
          });
      } else {
        reportService
          .getSalesReport({
            ...this.filters,
            sortBy: this.sortBy,
            orderBy: this.sortDesc ? "asc" : "desc",
            country: this.country,
            state: this.state,
            city: this.city,
            order_status: this.order_status,
            restaurants_id: this.restaurants_id,
            page: page ? page : 1,
          })
          .then((response) => {
            this.loading = false;
            this.paginations = response.data.result;
            this.reports = response.data.result.data;
          });
      }
    },
    saleExportCsv() {
      if (this.$auth.partner_login) {
        this.isDownloading = true;
        reportService
          .exportToCsv({
            ...this.filters,
            payment_method: this.payment_method,
            sortBy: this.sortBy,
            orderBy: this.sortDesc ? "desc" : "asc",
          })
          .then((response) => {
            this.isDownloading = false;
            this.reportsData = response.data.result;
            this.downloadsCSV();
          });
      } else {
        this.isDownloading = true;
        reportService
          .exportToCsv({
            ...this.filters,
            order_status: this.order_status,
            restaurants_id: this.restaurants_id,
            sortBy: this.sortBy,
            orderBy: this.sortDesc ? "desc" : "asc",
          })
          .then((response) => {
            this.isDownloading = false;
            this.reportsData = response.data.result;
            this.downloadsCSV();
          });
      }
    },
    searchData() {
      this.getSalesReports();
    },
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      this.getSalesReports();
    },
  },
  filters: {
    date(val) {
      return val ? val.toLocaleString() : "";
    },
  },
};
</script>

<template>
  <Layout>
    <div>
      <div class="row">
        <div class="col-12">
          <div
            class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
          >
            <div class="page-title-left avatar-main-icon">
              <div class="avatar-xs mr-3">
                <span
                  class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18"
                >
                  <i class="bx bx-purchase-tag"></i>
                </span>
              </div>
              <div class="report-tag" v-if="!$auth.partner_login">
                <h4 class="mb-0 font-size-18">
                  {{ trans.get("__JSON__.Sales Report") }}
                </h4>
                <p>
                  <router-link :to="{ name: 'reports-list' }">{{
                    trans.get("__JSON__.Reports")
                  }}</router-link>
                  > {{ trans.get("__JSON__.Sales Report") }}
                </p>
              </div>
              <div class="report-tag" v-else>
                <h4 class="mb-0 font-size-18">
                  {{ trans.get("__JSON__.Sales Report") }}
                </h4>
                <p>
                  <router-link :to="{ name: 'vendor.reports.list' }">{{
                    trans.get("__JSON__.Reports")
                  }}</router-link>
                  > {{ trans.get("__JSON__.Sales Report") }}
                </p>
              </div>
            </div>
            <div class="page-title-right">
              <button
                @click="saleExportCsv"
                class="btn btn-primary btn-rounded export-btn"
                v-if="isDownloading"
                disabled
              >
                {{ trans.get("__JSON__.Exporting...") }}
              </button>
              <button
                @click="saleExportCsv"
                class="btn btn-primary btn-rounded export-btn"
                v-else
              >
                {{ trans.get("__JSON__.Export To CSV") }}
              </button>
              <button
                @click="saleExportCsv"
                class="btn btn-primary btn-rounded m-export-btn"
              >
                <i class="bx bx-archive-in"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- end row -->
      <div class="row">
        <div class="col-12 list-table-main">
          <div class="card">
            <div class="card-body">
              <div class="row" v-if="$auth.partner_login">
                <div class="main-sales-report">
                  <div class="v-sales-report">
                    <div class="form-group" style="margin-right: 10px">
                      <div class="date-range-list">
                        <label>{{ trans.get("__JSON__.Status") }} :</label>
                        <select
                          class="custom-select"
                          v-model="payment_method"
                          @change="searchData()"
                        >
                          <option value>{{ trans.get("__JSON__.All") }}</option>
                          <option value="Delivered" selected>
                            {{ trans.get("__JSON__.Delivered") }}
                          </option>
                          <option value="Cancelled">
                            {{ trans.get("__JSON__.Cancelled") }}
                          </option>
                          <option value="Placed">
                            {{ trans.get("__JSON__.New Request") }}
                          </option>
                          <option value="InKitchen">
                            {{ trans.get("__JSON__.Confirmed") }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <!-- <div class="form-group">
                      <div class="date-range-list">
                        <label>{{ trans.get('__JSON__.Status') }} :</label>
                        <select class="custom-select" v-model="payment_method" @change="searchData()">
                          <option value selected>{{ trans.get('__JSON__.All') }}</option>
                          <option value="Cash On Delivery">{{ trans.get('__JSON__.COD') }}</option>
                          <option value="Online">{{ trans.get('__JSON__.Online') }}</option>
                        </select>
                      </div>
                    </div> -->
                  </div>
                  <div class="v-sales-report-date">
                    <div class="form-group">
                      <div class="date-range-list">
                        <label>{{ trans.get("__JSON__.Date") }}:</label>
                        <date-range-picker
                          ref="picker"
                          :locale-data="{
                            firstDay: 1,
                            format: 'dd-mm-yyyy',
                          }"
                          :showDropdowns="true"
                          :showWeekNumbers="true"
                          opens="left"
                          v-model="dateRange"
                          @update="updateValues"
                        >
                          <template
                            v-slot:input="picker"
                            style="min-width: 350px"
                          >
                            {{
                              moment(picker.startDate.toISOString()).format(
                                "MMMM DD, YYYY"
                              ) | date
                            }}
                            -
                            {{
                              moment(picker.endDate.toISOString()).format(
                                "MMMM DD, YYYY"
                              ) | date
                            }}
                          </template>
                        </date-range-picker>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="row justify-content-between"
                v-if="!$auth.partner_login"
              >
                <div v-if="countries.length > 1" class="sales-report d-none">
                  <div class="form-group">
                    <div class="date-range-list">
                      <label>{{ trans.get("__JSON__.Country") }} :</label>
                      <select
                        class="custom-select"
                        v-model="country"
                        @change="getVendorState"
                      >
                        <option value="0" selected>All</option>
                        <option
                          v-for="con in countries"
                          :value="con.id"
                          :key="con.id"
                        >
                          {{ con.country_name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div v-else class="offset-sm-2"></div>
                <div
                  v-if="countries.length >= 1 && states.length > 1"
                  class="sales-report d-none"
                >
                  <div class="form-group">
                    <div class="date-range-list">
                      <label>{{ trans.get("__JSON__.State") }} :</label>
                      <select
                        class="custom-select"
                        v-model="state"
                        @change="getVendorCity"
                      >
                        <option value="0" selected>All</option>
                        <option
                          v-for="sat in states"
                          :value="sat.id"
                          :key="sat.id"
                        >
                          {{ sat.state_name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div v-else class="offset-sm-2"></div>
                <div
                  v-if="states.length >= 1 && cities.length > 1"
                  class="sales-report d-none"
                >
                  <div class="form-group">
                    <div class="date-range-list">
                      <label>{{ trans.get("__JSON__.City") }} :</label>
                      <select
                        class="custom-select"
                        v-model="city"
                        @change="citychange"
                      >
                        <option value="0" selected>All</option>
                        <option
                          v-for="cit in cities"
                          :value="cit.cityid"
                          :key="cit.cityid"
                        >
                          {{ cit.cityname }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div v-else class="offset-sm-2"></div>
                <div v-if="restaurant.length > 1" class="sales-report">
                  <div class="form-group" style="margin-right: 10px">
                    <div class="date-range-list">
                      <label
                        >{{
                          trans.trans("__JSON__.Vendor", {
                            vendor: $auth.setting.restaurant_name,
                          })
                        }}
                        :</label
                      >
                      <select
                        class="custom-select"
                        v-model="restaurants_id"
                        @change="searchData()"
                      >
                        <option value selected>
                          {{
                            trans.trans("__JSON__.All Vendor", {
                              vendor: $auth.setting.restaurant_name,
                            })
                          }}
                        </option>
                        <option
                          v-for="res in restaurant"
                          :value="res.restaurant_id"
                          :key="res.restaurant_id"
                        >
                          {{ res.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="sales-report">
                  <div class="form-group" style="margin-right: 10px">
                    <div class="date-range-list">
                      <label>{{ trans.get("__JSON__.Status") }} :</label>
                      <select
                        class="custom-select"
                        v-model="order_status"
                        @change="searchData()"
                      >
                        <option value>{{ trans.get("__JSON__.All") }}</option>
                        <option value="Delivered" selected>
                          {{ trans.get("__JSON__.Delivered") }}
                        </option>
                        <option value="Cancelled">
                          {{ trans.get("__JSON__.Cancelled") }}
                        </option>
                        <option value="Placed">
                          {{ trans.get("__JSON__.New Request") }}
                        </option>
                        <option value="InKitchen">
                          {{ trans.get("__JSON__.Confirmed") }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="sales-report" v-if="!$auth.partner_login">
                  <div class="form-group">
                    <div class="date-range-list">
                      <label>{{ trans.get("__JSON__.Date") }} :</label>
                      <date-range-picker
                        ref="picker"
                        :locale-data="{
                          firstDay: 1,
                          format: 'dd-mm-yyyy',
                        }"
                        :showDropdowns="true"
                        :showWeekNumbers="true"
                        opens="left"
                        v-model="dateRange"
                        :ranges="date_ranges()"
                        @update="updateValues"
                      >
                        <template
                          v-slot:input="picker"
                          style="min-width: 350px"
                        >
                          {{
                            moment(picker.startDate.toISOString()).format(
                              "MMMM DD, YYYY"
                            ) | date
                          }}
                          -
                          {{
                            moment(picker.endDate.toISOString()).format(
                              "MMMM DD, YYYY"
                            ) | date
                          }}
                        </template>
                      </date-range-picker>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="row" v-if="!$auth.partner_login">
                <div class="col-sm-4 offset-sm-8">
                  <div class="form-group">
                    <div class="date-range-list">
                      <label>{{trans.get('__JSON__.Date')}}:</label>
                      <date-range-picker
                        ref="picker"
                        :locale-data="{
                          firstDay: 1,
                          format: 'dd-mm-yyyy',
                        }"
                        :showDropdowns="true"
                        :showWeekNumbers="true"
                        opens="left"
                        v-model="dateRange"
                        @update="updateValues"
                      >
                        <template v-slot:input="picker" style="min-width: 350px">
                          {{ moment(picker.startDate.toISOString()).format('MMMM DD, YYYY') | date }} -
                          {{ moment(picker.endDate.toISOString()).format('MMMM DD, YYYY') | date }}
                        </template>
                      </date-range-picker>
                    </div>
                  </div>
                </div>
              </div> -->
              <div class="row table-responsive">
                <div
                  class="col-12 table-main-list table table-centered table-nowrap sales-report-table"
                >
                  <b-table
                    :items="reports"
                    :fields="computedFields"
                    :sort-by.sync="sortBy"
                    :no-local-sorting="true"
                    @sort-changed="sortingChanged"
                    :busy="loading"
                    show-empty
                  >
                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>{{ trans.get("__JSON__.Loading...") }}</strong>
                      </div>
                    </template>

                    <template v-slot:cell(table_id)="data">
                      <div>{{ data.index + 1 }}</div>
                    </template>

                    <template v-slot:cell(id)="data">
                      <!-- <div>{{data.item.id}}</div> -->
                      <template
                        v-if="data.item.parent_id && data.item.parent_id > 0"
                      >
                        <div>{{ data.item.parent_id }}</div>
                        Ship. 1
                      </template>
                      <template
                        v-else-if="
                          data.item.suborder &&
                          data.item.suborder.id &&
                          data.item.suborder.id > 0
                        "
                      >
                        <div>{{ data.item.id }}</div>
                        Ship. 2
                      </template>
                      <template v-else>
                        <span
                          v-if="data.item.is_depot_store == 1"
                          class="badge badge-pill badge-soft-primary font-size-12 depot-badge"
                          >Depot</span
                        ><br />
                        <span
                          v-if="
                            !data.item.vendor_invoice &&
                            data.item.order_status &&
                            data.item.order_status.toLowerCase() == 'delivered'
                          "
                          class="badge badge-pill badge-soft-primary font-size-12 depot-badge"
                          style="background-color: #f46a6a !important"
                          >Bill Upload Pending</span
                        >
                        <div>{{ data.item.id }}</div>
                        <div
                          class="font-weight-bold"
                          v-if="data.item.sipment1_orderId"
                        >
                          ({{
                            "Sub Order of" +
                            " " +
                            "#" +
                            data.item.sipment1_orderId
                          }})
                        </div>
                      </template>
                    </template>

                    <template v-slot:cell(created_at)="data">
                      <!-- {{ data.item.created_at }} -->
                      {{
                        moment(data.item.created_at).format("DD-MM-YY hh:mm A")
                      }}
                    </template>
                    <template v-slot:cell(invoice_date)="data">
                      {{
                        data.item.order_status &&
                        data.item.order_status.toLowerCase() == "delivered" &&
                        data.item.invoice_date
                          ? moment(data.item.invoice_date).format(
                              "DD-MM-YY hh:mm A"
                            )
                          : "-"
                      }}
                    </template>

                    <!-- <template v-slot:cell(user_name)="data">
                      <span>
                        {{ data.item.user_name }}
                        {{ data.item.last_name }}
                        </span>
                    </template> -->

                    <template v-slot:cell(driver_name)="data">
                      <span v-if="data.item.driver_name">{{
                        data.item.driver_name
                      }}</span>
                      <span v-else>-</span>
                    </template>

                    <template v-slot:cell(medicine_item_mrp_sub_total)="data">
                      <span v-html="currency"></span>
                      {{
                        parseFloat(
                          data.item.medicine_item_mrp_sub_total
                        ).toFixed(2) || "0.00"
                      }}
                    </template>

                    <template v-slot:cell(medicine_item_price)="data">
                      <span v-html="currency"></span>
                      {{
                        parseFloat(data.item.medicine_item_price).toFixed(2) ||
                        "0.00"
                      }}
                    </template>

                    <template v-slot:cell(other_item_mrp_sub_total)="data">
                      <span v-html="currency"></span>
                      {{
                        parseFloat(data.item.other_item_mrp_sub_total).toFixed(
                          2
                        ) || "0.00"
                      }}
                    </template>

                    <template v-slot:cell(other_item_price)="data">
                      <span v-html="currency"></span>
                      {{
                        parseFloat(data.item.other_item_price).toFixed(2) ||
                        "0.00"
                      }}
                    </template>

                    <template v-slot:cell(distance_km)="data">
                      <span v-if="data.item.distance_km">{{
                        data.item.distance_km
                      }}</span>
                      <span v-else>-</span>
                    </template>

                    <template v-slot:cell(driver_earning)="data">
                      <span v-html="currency"></span>
                      {{
                        (parseFloat(data.value) &&
                          parseFloat(data.item.driver_earning).toFixed(2)) ||
                        "0.00"
                      }}
                    </template>

                    <template v-slot:cell(itemtaxslab)="data">
                      <span v-html="currency"></span>
                      {{
                        (parseFloat(data.value) &&
                          parseFloat(data.item.itemtaxslab).toFixed(2)) ||
                        "0.00"
                      }}
                    </template>

                    <template v-slot:cell(delivery_fee)="data">
                      <span v-html="currency"></span>
                      {{
                        parseFloat(data.item.delivery_fee).toFixed(2) || "0.00"
                      }}
                    </template>

                    <template v-slot:cell(coupon_discount)="data">
                      <span v-html="currency"></span>
                      {{
                        (parseFloat(data.value) &&
                          parseFloat(data.item.coupon_discount).toFixed(2)) ||
                        "0.00"
                      }}
                    </template>

                    <template v-slot:cell(total_amount)="data">
                      <span v-html="currency"></span>
                      {{
                        parseFloat(data.item.total_amount).toFixed(2) || "0.00"
                      }}
                    </template>

                    <template v-slot:cell(payable_amount)="data">
                      <span v-if="data.item.payable_amount"
                        ><span v-html="currency"></span>
                        {{
                          parseFloat(data.item.payable_amount).toFixed(2) ||
                          "0.00"
                        }}
                      </span>
                      <span v-else> - </span>
                    </template>

                    <template v-slot:cell(wallet_amount)="data">
                      <span v-html="currency"></span>
                      {{
                        parseFloat(data.item.wallet_amount || 0).toFixed(2) ||
                        "0.00"
                      }}
                    </template>

                    <template v-slot:cell(admin_commision)="data">
                      <span v-html="currency"></span>
                      {{
                        parseFloat(data.item.admin_commision).toFixed(2) ||
                        "0.00"
                      }}
                    </template>

                    <template v-slot:cell(admin_commision_gst)="data">
                      <span v-html="currency"></span>
                      {{
                        getAdminCommisionGst(data.item.admin_commision) ||
                        "0.00"
                      }}
                    </template>

                    <template v-slot:cell(payment_method)="data">
                      <div
                        v-if="data.item.payment_method === 'Cash On Delivery'"
                      >
                        {{ trans.get("__JSON__.COD") }}
                      </div>
                      <div v-else>{{ data.item.payment_method }}</div>
                    </template>

                    <template v-slot:cell(order_status)="data">
                      <div
                        v-if="data.item.order_status === 'Cancelled'"
                        class="text-danger"
                      >
                        {{ trans.get("__JSON__.Cancelled") }}
                      </div>
                      <!-- <div v-else-if="data.item.order_status === 'Delivered'"> -->
                      <div v-if="data.item.order_status === 'Delivered'">
                        {{ trans.get("__JSON__.Delivered") }}
                      </div>
                      <div
                        v-else-if="
                          data.item.order_status !== 'Delivered' &&
                          data.item.order_status !== 'Cancelled'
                        "
                      >
                        {{
                          data.item.order_status == "Placed"
                            ? "New Request"
                            : data.item.order_status == "InKitchen"
                            ? "Confirmed"
                            : data.item.order_status
                        }}
                      </div>
                    </template>

                    <template v-slot:cell(earning)="data">
                      <span v-html="currency"></span>
                      {{ parseFloat(data.item.earning).toFixed(2) || "0.00" }}
                    </template>
                    <template v-slot:cell(instamed_cash)="data">
                      <span v-if="data.item.instamed_cash"
                        ><span v-html="currency"></span>
                        {{
                          parseFloat(data.item.instamed_cash).toFixed(2) ||
                          "0.00"
                        }}
                      </span>
                      <span v-else> - </span>
                    </template>

                    <template v-slot:cell(referral_discount)="data">
                      <span v-if="data.item.referral_discount"
                        ><span v-html="currency"></span>
                        {{
                          parseFloat(data.item.referral_discount).toFixed(2) ||
                          "0.00"
                        }}
                      </span>
                      <span v-else> - </span>
                    </template>
                    <template v-slot:cell(start_created_at)="data">
                      <!-- {{ data.item.created_at }} -->
                      {{ data.item.created_at }}
                    </template>
                    <template v-slot:cell(delivered_date)="data">
                      {{ data.item.delivered_date || "0000-00-00 00:00:00" }}
                    </template>
                    <template v-slot:cell(Duration)="data">
                      <div v-if="data.item.order_status === 'Cancelled'">
                        {{ "NA" }}
                      </div>
                      <div v-else>
                        {{
                          getDuration(
                            data.item.created_at,
                            data.item.delivered_date
                          )
                        }}
                      </div>
                    </template>

                    <!-- <template v-slot:cell(accountholder_name)="data">
                      <span
                        >{{ data.item.user.user_name }}</span
                      >
                    </template> -->

                    <template v-slot:cell(accountholder_number)="data">
                      <span
                        v-if="data.item.accountholder_number == '9999999999'"
                        >-</span
                      >
                      <span v-else>{{ data.item.accountholder_number }}</span>
                    </template>

                    <template v-slot:cell(source_type)="data">
                      <span>{{ data.item.source_type || "-" }}</span>
                    </template>

                    <template v-slot:cell(settled)="data">
                      <span v-if="data.item.settled == 0">Unsettled</span>
                      <span v-else>Settled</span>
                    </template>

                    <template v-slot:cell(un_settle_reason)="data">
                      <span v-if="data.item.un_settle_reason">{{
                        data.item.un_settle_reason
                      }}</span>
                      <span v-else>-</span>
                    </template>

                    <template v-slot:cell(customer_state)="data">
                      {{ data.item.customer_state }}
                    </template>

                    <template v-slot:cell(hdp_Id)="data">
                      {{ data.item.hdp_Id ? "Yes" : "No" }}
                    </template>

                    <template #empty>
                      <p class="text-center">
                        {{ trans.get("__JSON__.No Sales Report Found") }}
                      </p>
                    </template>
                  </b-table>
                </div>
                <div class="row col-sm-5 offset-sm-7">
                  <div class="col">
                    <div
                      class="dataTables_paginate paging_simple_numbers float-right"
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <pagination
                          :data="paginations"
                          :limit="limit"
                          @pagination-change-page="getSalesReports"
                        ></pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>


<style>
.sales-report-table .table thead th:nth-child(1),
.sales-report-table .table thead th:nth-child(3),
.sales-report-table .table thead th:nth-child(4),
.sales-report-table .table thead th:nth-child(5),
.sales-report-table .table thead th:nth-child(8),
.sales-report-table .table thead th:nth-child(13) {
  pointer-events: none;
}
.depot-badge {
  background-color: #556ee6 !important;
  color: #fff !important;
  margin-bottom: 10px;
}
</style>