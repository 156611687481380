
// Report services rest api calling

import { routes } from '../config/api_routes'

export const reportService = {
    // get sales report 

    getSalesReport: (formData) => {    
        return window.axios.post(routes.getSalesReportApi,formData)
    }, 
    
    // sales report export to csv
    exportToCsv: (formData) => {
        return window.axios.post(routes.saleReportExportToCsvApi,formData)
    },

    // get item report 

    getItemReport: (formData) => {    
        return window.axios.post(routes.getItemReportApi,formData)
    }, 

    // get Customer Report

    getCustomerReport: (formData) => {
        return window.axios.post(routes.getCustomerReportApi,formData)
    },

    // Customer Export To CSV

    customerExportToCSV: (formData) => {
        return window.axios.post(routes.reportcustomerExportToCsvApi,formData)
    },

    // get Total Sale report
    gettotalSaleReport: (formData) => {
        return window.axios.post(routes.totalSaleReportApi,formData)
    },

    // get Total Summary report
    gettotalSummaryReport: (formData) => {
        return window.axios.post(routes.totalSummaryReportApi,formData)
    },

    // get Total Product report
    gettotalProductReport: (formData) => {
        return window.axios.post(routes.totalProductReportApi,formData)
    },

    // get Storewise Product report
    getStorewiseProductReport: (formData) => {
        return window.axios.post(routes.storewiseProductReportApi,formData)
    },

    // get Total store report
    gettotalStoreReport: (formData) => {
        return window.axios.post(routes.storeListReportApi,formData)
    },

    // get Total store wise GST report
    gettotalStoreWiseGstReport: (formData) => {
        return window.axios.post(routes.vendorMonthListReportApi,formData)
    },

    // get Total store wise GST report
    gettotalGenerateGstReportApi: (formData) => {
        return window.axios.post(routes.generateGstReportApi,formData)
    },

    // get Total update mrp report
    getUpdateItemPriceReport: (formData) => {
        return window.axios.post(routes.totalgetUpdateItemPriceReportApi,formData)
    },

    // Total Sale Export To CSV
    totalSalesExportToCSV: (formData) => {
        return window.axios.post(routes.totalSaleReportExportToCsvApi,formData)
    },

    // Total Summary Export To CSV
    totalSummaryExportToCSV: (formData) => {
        return window.axios.post(routes.totalSummaryReportExportToCsvApi,formData)
    },

    // Total Product Export To CSV
    totalProductExportToCSV: (formData) => {
        return window.axios.post(routes.totalProductReportExportToCsvApi,formData)
    },

    // Total update mrp Export To CSV
    updateItemPriceReportExportToCSV: (formData) => {
        return window.axios.post(routes.updateItemPriceReportExportToCsvApi,formData)
    },

    // get All Restaurants
    getAllRestaurantName: (formData) => {
        return window.axios.post(routes.getRestaurantsApi, formData)
    },

    // get All Restaurants Confirm
    getRestaurantsConfirm: (formData) => {
        return window.axios.post(routes.getRestaurantsConfirmApi,formData)
    },  

    // get GST Admin commission Report
    getTaxReport: (formData) => {
        return window.axios.post(routes.taxReportReportApi,formData)
    },

    // Gst Admin Export To CSV
    taxReportExportToCsv: (formData) => {
        return window.axios.post(routes.taxReportExportToCsvApi,formData)
    },

    // get All User
    getAllUser: () => {
        return window.axios.get(routes.getAllUserApi)
    },

    // get DriverReportListing
    getDriverReportListing: (formData) => {
        return window.axios.post(routes.getDriverReportApi,formData)
    },
    // To export driver data in csv
    driverReportExportToCsv: (formData) => {
        return window.axios.post(routes.getDriverReportCsvApi,formData)  
    },

    // get InactiveCustomerReport
    getInactiveCustomerReport: (formData) => {
        return window.axios.post(routes.getInactiveCustomerReportApi,formData)
    },

    // Export InactiveCustomerReport
    exportInactiveCustomer: (formData) => {
        return window.axios.post(routes.exportInactiveCustomerApi,formData)
    },

    // get CODOnlineorderReport
    getCODOnlineOrderReport: (formData) => {
        return window.axios.post(routes.getCODOnlineOrderReportApi,formData)
    },

    // export To Csv CodOnlineReport
    exportOnlineReport: (formData) => {
        return window.axios.post(routes.exportCODOnlineReportApi,formData)
    },

    // get Vendor Rating Report
    getVendorAvgRatingReport: (formData) => {
        return window.axios.post(routes.getVendorRatingReportApi,formData)
    },

    // Vendor Avg Export To Csv
    exportVendorAvgRating: (formData) => {
        return window.axios.post(routes.exportVendorAvgRatingApi,formData)
    },

    // get GST Delivery Charge commission Report
    getGstDeliveryChargeReport: (formData) => {
        return window.axios.post(routes.getGSTDeliveryChargeReportApi,formData)
    },

    // Export To Csv GST delivery Charge
    exportGSTDeliveryChargeReport: (formData) => {
        return window.axios.post(routes.exportGSTDelivertReportApi,formData)
    },

    getPromoCodeReport: (formData) => {
        return window.axios.post(routes.getPromoCodereportApi,formData)
    },

    // Get Sales Vendor Report
    getVendorSalesReport: (formData) => {
        return window.axios.post(routes.getVendorSalesReportApi,formData)
    },

    // Get Vendor Rating Review Report
    getVendorRatingReview: (formData) => {
        return window.axios.post(routes.getVendorRatingReviewReportApi,formData)
    },

    // Export Vendor Rating Review Report
    exportVendorRatingReview: (formData) => {
        return window.axios.post(routes.exportVendorRatingReviewReportApi,formData)
    },

    // Get Driver Rating Review Report
    getDriverRatingReview: (formData) => {
        return window.axios.post(routes.getDriverRatingReviewReportApi,formData)
    },

    // Export Driver Rating Review Report
    exportDriverRatingReview: (formData) => {
        return window.axios.post(routes.exportDriverRatingReviewReportApi,formData)
    }
    ,
    getWalletReport:(data)=>{
        return window.axios.post(routes.getWalletReportApi,data)
    },
    getWalletReportExport:(data)=>{
        return window.axios.post(routes.getWalletReportExportApi,data)
    },
    getDriverSettlementReport:(data)=>{
        return window.axios.post(routes.getDriverSettlementApi,data)
    },
    exportDriverSettlementToCsv: (formData) => {
        return window.axios.post(routes.driveSettlementExportToCsvApi,formData)
    },

    // Get Restaurant QR Feedback Report
    getVendorQrFeedbackReport:  (formData) => {
        return window.axios.post(routes.getVendorQrFeedbackReportApi,formData)
    },
    
    // Export Restaurant QR Feedback Report
    getVendorQrFeedbackReportExport:  (formData) => {
        return window.axios.post(routes.getVendorQrFeedbackReportExportApi,formData)
    },

    getUserListByResturant: (formData) => {    
        return window.axios.post(routes.getUserListByResturantApi,formData)
    },

    getSettlementReport: (formData) => {    
        return window.axios.post(routes.getSettlementReportApi,formData)
    },

    getAllStoresSettlementReport: (formData) => {
        return window.axios.post(routes.getAllStoresSettlementReportApi,formData)
    },

    getPharmacyCustomers: (headers) => {
        return window.axios.get(routes.getPharmacyCustomersApi,{
            headers: headers
        })
    },

    getWhatsappMessageSent: (formData) => {
        return window.axios.post(routes.getWhatsappMessageSentApi, formData)
    },

    getIDPAgent: (headers) => {
        return window.axios.get(routes.getIDPAgentApi,{
            headers: headers
        })
    },

    getIDPOrderDetails: (formData) => {
        return window.axios.post(routes.getIDPOrderDetailsApi, formData)
    },

    // get All Restaurants Special
    getAllSpecialRestaurantName: (formData) => {
        return window.axios.post(routes.getRestaurantsSpecialApi, formData)
    },
}