<script>
import vue2Dropzone from 'vue2-dropzone'
import { routes } from '../../config/api_routes';
import { commonService } from '../../services';
export default {
  components: { vueDropzone: vue2Dropzone },
  props: {
    'value': [String, Array],
    'acceptedFiles': String,
    'multiple': Boolean,
    'id':String,
    'preSelected': [String, Array]
  },
  data() {
    return {
      dropzoneOpt: {
        url: routes.uploadTmpImageApi,
        headers: window.axios.defaults.headers.common,
        thumbnailHeight: 100,
        maxFilesize: 10,
        acceptedFiles: this.acceptedFiles,
        addRemoveLinks: true,
        success: (response) => {
          console.log(response);
          if (response.status == "success") {
            const data = JSON.parse(response.xhr.response);
            this.$emit('input', this.multiple ? [...this.value?this.value:[], data.result.file] : data.result.file);
            $(response.previewElement)
              .find(".dz-remove")
              .attr("href", data.result.file);
          }
        },
      },
    };
  },
  watch: {
    // the callback will be called immediately after the start of the observation
    preSelected: function (val, oldVal) {
      console.log(this.preSelected);
      this.preSelected && this.preSelected.map((image, index) => {
        this.$refs.proDropzone.manuallyAddFile({size: 200}, image.driver_document_path);
        $(this.$refs.proDropzone.files[index].previewElement)
            .find(".dz-remove")
            .attr("href", data.result.file);
      });
    }
  },
  created(){
    if(!this.multiple){
      this.dropzoneOpt.maxFiles = 1;
    }
    // this.preSelected = 
  },
  mounted(){
    console.log(this.preSelected)
    this.preSelected && this.preSelected.map((image, index) => {
      this.$refs.proDropzone.manuallyAddFile({size: 200}, image.driver_document_path);
    });
  },
  methods: {
    removeTmpFile(file, error, xhr){
      const filename = $(file._removeLink).attr('href');
      commonService.removeTmpImage(filename)
        .then(res => {
          if(this.multiple) this.value.splice(this.value.indexOf(filename),1);

          this.$emit('input', this.multiple ? this.value : '' );
        })
    },
    maxFileExceed(file){
      this.$refs.proDropzone.removeFile(file)
    }
  }
};
</script>
<template>
  <vue-dropzone
    :id="id"
    ref="proDropzone"
    :use-custom-slot="true"
    @vdropzone-removed-file="removeTmpFile"
    @vdropzone-max-files-exceeded="maxFileExceed"
    :options="dropzoneOpt"
  >
    <div class="dropzone-custom-content">
      <div class="mb-1">
        <i class="display-4 text-muted bx bxs-cloud-upload"></i>
      </div>
      <h4>Drop files here or click to upload.</h4>
    </div>
  </vue-dropzone>
</template>
<style>
</style>