import { routes } from '../config/api_routes'

export const driverService = {

    getDriver: (formData) => window.axios.post(routes.getDriverApi,formData),

    addDriver: (formData) => window.axios.post(routes.addDriverApi,formData),

    getDriverInfo: (formData) => window.axios.post(routes.getDriverInfoApi,formData),   

    getDriverRating: (formData) => window.axios.post(routes.getDriverRatingApi,formData),

    updateDriveProfile: (formData) => window.axios.post(routes.updateDriveProfileApi,formData),

    driverExportCsv: (formData) => window.axios.post(routes.driverExportToCsvApi, formData),

    getFrenchise: () => window.axios.get(routes.getFrenchiseApi),

    updateDriverStatus: (formData) => window.axios.post(routes.updateDriverStatusApi, formData),

    updateAvailbilityStatus: (formData) => window.axios.post(routes.updateAvailbilityStatusApi, formData),

    
    // finance
    getDriverFinance: (data) => window.axios.post(routes.getDriverFinanceApi,data),

    getDriverFinanceDays: (data) => window.axios.post(routes.getDriverFinanceDaysApi,data),
 
    getDriverFinanceMonth: (data) => window.axios.post(routes.getDriverFinanceMonthApi,data),

    getDayWiseData: (driver_id) => window.axios.post(routes.getDriverDayWiseDataApi,{driver_id}),
    
    getDriverSevenDayEarning: (data) => window.axios.post(routes.getDriverSevenDayEarningApi,data),
    
    getDriverMonthWiseEarning: (data) => window.axios.post(routes.getDriverMonthWiseEarningApi,data),

    getDriverOrderWiseEarning: (data) => window.axios.post(routes.getDriverOrderWiseEarningApi,data),
    
    getDriverSettlements: (data) => window.axios.post(routes.getDriverSettlementsApi,data),

    getDriverPendingSettlement: (data) => window.axios.post(routes.getDriverPendingSettlementApi,data),
    
    receiveDriverSettlement: (data) => window.axios.post(routes.receiveDriverSettlementApi,data),

    eagleViewDriverList : (data) => window.axios.post(routes.eagleViewDriverListApi,data),
    
    driverByAvailability: (data) => window.axios.post(routes.driverByAvailabilityApi,data),

    driverTransactions: (data) => window.axios.get(routes.driverTransactionsApi,{params:data}),

    changeDriverPassword: (formData) => window.axios.post(routes.changeDriverPasswordApi,formData),

    deleteDriverRating: (formData) => window.axios.post(routes.deleteDriverRatingApi,formData),
    
    signup:(formData) => window.axios.post(routes.signupDriver,formData),

    getRestaurant: (formData) => window.axios.post(routes.getRestaurantApi),

}