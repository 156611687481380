<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import DateRangePicker from "vue2-daterange-picker";
import pagination from "laravel-vue-pagination";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import {
  get_partner_login,
  set_partner,
  set_partner_details,
  set_user,
  set_user_detail,
  debounce,
  get_decimal,
  get_currency,
  date_ranges,
  get_user
} from "../../Helper/helper";
import moment from "moment";
import { reportService, deliveryZoneService } from "../../services";
/**
 * Report component
 */
export default {
  components: { Layout, PageHeader, pagination, DateRangePicker },
  data() {
    let startDate = this.moment().startOf("day").subtract(6, "days");
    let endDate = this.moment().endOf("day").toDate();
    return {
      decimal: get_decimal(),
      totalSale: {},
      restaurant: [],
      totalSaleReport: [],
      restaurant_count: "",
      restaurants_id: "",
      sortBy: "restaurant_menu_item_id",
      sortDesc: true,
      paginations: {},
      limit: 2,
      perPage: 0,
      currentPage: 1,
      currency: get_currency(),
      countries: [],
      country: 0,
      states: [],
      state: 0,
      cities: [],
      city: 0,
      country_count: "",
      state_count: "",
      city_count: "",
      search: "",
      loading: true,
      isDownloading: false,
      dateRange: { startDate, endDate },
      filters: {
        start_date: this.moment().subtract(6,'days').format('YYYY-MM-DD'),
        end_date: this.moment().format('YYYY-MM-DD'),
      },
      date_ranges: date_ranges,
      fields: [
        { key: "serial_no", label: "S. No.", sortable: false },
        // { key: "product_code", label: "Product code(Keshavlal)", sortable: false },
        { key: "product_name", label: "Product Name", sortable: false },
        { key: "category_name", label: "Category(Chronic)", sortable: false },
        { key: "unique_customers", label: "No. of unique customers", sortable: false },
        { key: "total_orders", label: "No. of orders", sortable: false },
        { key: "quantity", label: "No. of units", sortable: false },
        { key: "total_value_of_the_product", label: "Total value of the product", sortable: false},
        { key: "view_store", label: "View Storewise Details", sortable: false},
      ],
    };
  },
  mounted() {
    // this.$data.fields[3].label = this.$auth.getRestaurantName() +' Name';
    // this.$data.fields[6].label = this.$auth.getRestaurantName() +' Earning';
    // this.getTotalSalesReports();
    this.getVendorCountry();
    // this.getRestaurants();
    // this.totalSaleExportCsv();
  },
  methods: {
    downloadsCSV: function () {
      let totalSaleReport =
        '\ufeff' +
        'S.No.,Product Name,Category(Chronic),No. of unique customers,No. of orders,No. of units,Total value of the product, \n'
      this.totalSaleReport.forEach((el, ind) => {
        let sno = ind + 1;
        var line = sno + "," +
        // el["restaurant_menu_item_id"] +
        //   "," +
          el["product_name"].split(',').join('') +
          "," +
          el["category_name"].split(',').join('') +
          "," +
          el["no_of_unique_customer"] +
          "," +
          el["no_of_orders"] +
          "," +
          el["no_of_units"] +
          "," +
          el["total_value_of_the_product"] +
          "\n"
        totalSaleReport += line;
      });

      var blob = new Blob([totalSaleReport], { type: "csv/plain" });
      var date = new Date().toLocaleString();
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "product_" + date + ".csv";
      link.click();
    },
    updateValues(date) {
      this.filters = {
        ...this.filters,
        start_date: this.moment(date.startDate.toISOString()).format(
          "YYYY-MM-DD"
        ),
        end_date: this.moment(date.endDate.toISOString()).format("YYYY-MM-DD"),
      };
      this.getTotalSalesReports();
      // this.totalSaleExportCsv();
    },
    dateFormat(classes, date) {
      this.filters = {
        ...this.filters,
        start_date: this.moment(date.startDate.toISOString()).format(
          "YYYY-MM-DD"
        ),
        end_date: this.moment(date.endDate.toISOString()).format("YYYY-MM-DD"),
      };
    },
    getTotalSalesReports(page) {
      this.loading = true;
      reportService
      .gettotalProductReport({
        // ...this.filters,
        sortBy: this.sortBy,
        orderBy: this.sortDesc ? "asc" : "desc",
        page: page ? page : 1,
        vendor_id: get_user().vendor_id ,
        search: this.search,
      })
      .then((response) => {
        this.loading = false;
        // this.paginations = response.data.result;
        this.totalSale = response.data.result;
      });
    },
    totalSaleExportCsv() {
      this.isDownloading = true;
      reportService
      .totalProductExportToCSV({ 
          // ...this.filters,
        sortBy: this.sortBy,
        orderBy: this.sortDesc ? "asc" : "desc",
        vendor_id: get_user().vendor_id ,
        search: this.search,
      })
      .then((response) => {
        this.isDownloading = false;
        this.totalSaleReport = response.data.result;
        this.downloadsCSV();
      });
    },
    getVendorCountry() {
      this.country = 0;
      this.city = 0;
      this.state = 0;
      this.countries = [];
      this.states = [];
      this.cities = [];
      deliveryZoneService.getVendorCountry().then((response) => {
        this.countries = response.data.result;
        if (this.countries.length == 1) {
          this.country = this.countries[0].id;
        }
        this.getVendorState();
      });
    },
    getRestaurants(){
      this.restaurant = [];
      reportService.getAllRestaurantName({
        country_id: this.country,
        state_id: this.state,
        city_id: this.city
      }).then((response) => {
        this.restaurant = response.data.result;
        this.restaurant_id = this.restaurant[0].restaurant_id;
        this.searchData();
      });
    },
    getVendorState() {
      this.city = 0;
      this.state = 0;
      this.states = [];
      this.cities = [];
      this.country = parseInt(this.country);
      if(this.country > 0) {
        deliveryZoneService.getVendorState({country_id: this.country})
        .then((response) => {
          this.states = response.data.result;
          if (this.states.length == 1) {
            this.state = this.states[0].id;
          }
          this.getVendorCity();
        });
      } else {
        this.searchData();
      }
    },
    getVendorCity() {
      this.city = 0;
      this.cities = [];
      this.state = parseInt(this.state);
      if(this.state > 0) {
        deliveryZoneService.getVendorCity({state_id:this.state ? this.state : 0,country_id: this.country})
        .then((response) => {
          this.cities = response.data.result;
          if (this.cities.length == 1) {
            this.city = this.cities[0].cityid;
          }
          this.searchData();
          this.getRestaurants();
        });
      } else {
        this.searchData();
        this.getRestaurants();
      }
    },
    citychange() {
      this.searchData();
      this.getRestaurants();
    },
    searchData() {
      this.getTotalSalesReports();
      // this.totalSaleExportCsv();
    },
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      this.getTotalSalesReports();
      // this.totalSaleExportCsv();
    },
  },
  filters: {
    date(val) {
      return val ? val.toLocaleString() : "";
    },
  },
};
</script>

<template>
  <Layout>
    <div>
      <div class="row">
          <div class="col-12">
              <div
              class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
              >
              <div class="page-title-left avatar-main-icon">
                  <div class="avatar-xs mr-3">
                  <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                      <i class="bx bx-purchase-tag"></i>
                  </span>
                  </div>
                  <div class="report-tag">
                    <h4 class="mb-0 font-size-18">{{ trans.get('__JSON__.Product Report') }}</h4>
                    <p><router-link :to="{ name: 'reports-list'}">{{ trans.get('__JSON__.Reports') }}</router-link> > {{ trans.get('__JSON__.Product Report') }}</p>
                  </div>
              </div>
              <div class="page-title-right">
                <button @click="totalSaleExportCsv" class="btn btn-primary btn-rounded export-btn" v-if="isDownloading" disabled>{{ trans.get('__JSON__.Exporting...') }}</button>
                <button @click="totalSaleExportCsv" class="btn btn-primary btn-rounded export-btn" v-else>{{ trans.get('__JSON__.Export To CSV') }}</button>
                <button @click="totalSaleExportCsv" class="btn btn-primary btn-rounded m-export-btn"><i class="bx bx-archive-in"></i></button>
              </div>
              </div>
          </div>
      </div>

      <!-- end row -->
      <div class="row">
        <div class="col-12 list-table-main">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-sm-2 offset-sm-10">
                  <div class="search-box mr-2 mb-0 d-block form-group">
                    <div class="date-range-list">
                      <label>{{trans.get('__JSON__.Search')}} :</label>
                      <div class="position-relative d-inline-block w-100">
                        <input
                          type="text"
                          class="form-control"
                          id="search"
                          @keyup="searchData()"
                          v-model="search"
                          :placeholder="trans.get('__JSON__.Search Product Name')"
                        />
                        <i class="bx bx-search-alt search-icon"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 table-main-list total-sales-report-table table-responsive">
                  <b-table
                    :items="totalSale.data"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :no-local-sorting="true"
                    @sort-changed="sortingChanged"
                    :busy="loading"
                    show-empty
                  >
                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>{{trans.get('__JSON__.Loading...')}}</strong>
                      </div>
                    </template>

                    <template v-slot:cell(serial_no)="data">
                      <div>{{ (totalSale.per_page * (totalSale.current_page-1)) + data.index + 1 }}</div>
                    </template>

                    <!-- <template v-slot:cell(product_code)="data">
                      <div>{{data.item.restaurant_menu_item_id}}</div>
                    </template> -->

                    <template v-slot:cell(product_name)="data">
                      <div>{{data.item.product_name}}</div>
                    </template>

                    <template v-slot:cell(category_name)="data">
                      <span>{{ data.item.category_name }}</span>
                    </template>

                    <template v-slot:cell(unique_customers)="data">
                      <span>{{ data.item.no_of_unique_customer }}</span>
                    </template>

                    <template v-slot:cell(total_orders)="data">
                      <span>{{ data.item.no_of_orders }}</span>
                    </template>

                    <template v-slot:cell(quantity)="data">
                      {{data.item.no_of_units}}
                    </template>

                    <template v-slot:cell(total_value_of_the_product)="data">
                      {{data.item.total_value_of_the_product}}
                    </template>

                    <template v-slot:cell(view_store)="data">
                      <router-link :to="{name:'reports.storewise.product.details.report', query: { data: JSON.stringify({id: data.item.restaurant_menu_item_id, product_name: data.item.product_name, category_name: data.item.category_name}) }}" class="btn btn-primary btn-sm btn-rounded">
                             View Store
                      </router-link>
                    </template>

                    <template #empty>
                      <p class="text-center">{{ trans.get('__JSON__.No Product Report Found') }}</p>
                    </template>
                  </b-table>
                </div>
                <div class="row col-sm-5 offset-sm-7">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <pagination
                          :data="totalSale"
                          :limit="limit"
                          @pagination-change-page="getTotalSalesReports"
                        ></pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>


<style>
.total-sales-report-table .table thead th:nth-child(1),.total-sales-report-table .table thead th:nth-child(3),.total-sales-report-table .table thead th:nth-child(4){
    pointer-events: none;
}
</style>