<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import { businessTypeService } from "../../services";
import pagination from "laravel-vue-pagination";
import { required } from "vuelidate/lib/validators";
export default {
  components: {
    Layout,
    PageHeader,
    businessTypeService,
    pagination
  },
  data() {
    return {
      addModal: false,
      submitDisable: false,
      typeform: {
        name: "",
        status: "0"
      },
      businessType: {},
      businessTypeCount: '',
      paginations: {},
      sortBy: 'id',
      search: '',
      sortDesc : false,
      limit: 1,
      status: "0",
      loading: true,
      fields: [
        { key: "id", label: "#", sortable: false },
        { key: "name", label: this.trans.get("__JSON__.Business Type Name"), sortable: true },
        {
          key: "status",
          label: this.trans.get("__JSON__.Status"),
          sortable: false,
        },
        {
          key: "action",
          label: this.trans.get("__JSON__.Action"),
          sortable: false,
        },
      ],
    };
  },
  validations: {
    typeform: {
      name: {
        required,
      }
    }
  },
  mounted() {
    this.getBusinessType();
  },
  methods: {
     getBusinessType(page){
      this.loading = true;
      businessTypeService.getBusinessTypeListing({
        status: this.status,
        search: this.search,
        sortBy: this.sortBy,
        orderBy: this.sortDesc ? "asc" : "desc",
        page: page && page > 0 ? page : 1,
      })
        .then((response) => {
          this.loading = false;
          this.businessType = response.data.result.data;
          // this.paginations = response.data.result;
          this.businessTypeCount = response.data.result.businessTypeCount;
        });
    },
    addBusinessType() {
      this.submitDisable = true;
      this.$v.typeform.$touch();
      if (this.$v.typeform.$invalid) {
        return;
      } else {
        businessTypeService.addBusinessType(this.typeform).then((response) => {
           this.typeform = {
                name: "",
                status: "0",
          }
          this.$v.typeform.$reset();
          if (response.data.code === 200) {
            this.submitDisable = false;
            this.$toasted.success(response.data.message);
            this.addModal = false;
            this.getBusinessType();
          } else {
            this.submitDisable = false;
            this.$toasted.error(response.data.message);
          }
        });
      }
    },
    searchData() {
      this.getBusinessType();
    },
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      this.getBusinessType();
    }
  },
};
</script>
<template>
 <Layout>
  <div>
   <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
        >
          <div class="page-title-left">
            <div class="avatar-xs mr-3">
              <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                <i class="bx bx-user"></i>
              </span>
            </div>
             <h4 class="mb-0 font-size-18">{{ trans.trans('__JSON__.Buiness Type') }}({{businessTypeCount}})</h4>
          </div>
          <div class="page-title-right">
            <button type="button" class="btn btn-success btn-rounded mr-2 add-btn" @click="addModal = true">
              <i class="mdi mdi-plus mr-1"></i>
              {{ trans.get('__JSON__.Add Business Type') }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 list-table-main">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-2 offset-sm-8">
                <div class="form-group">
                  <div class="date-range-list">
                    <label>{{ trans.get('__JSON__.Status') }} :</label>
                    <select class="custom-select" v-model="status" @change="searchData()">
                        <option value="">{{ trans.get('__JSON__.All') }}</option>
                        <option value="0" selected>{{ trans.get('__JSON__.Active') }}</option>
                        <option value="1">{{ trans.get('__JSON__.Inactive') }}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="date-range-list">
                  <label>{{ trans.get('__JSON__.Search') }} :</label>
                  <div class="text-sm-right">
                    <div class="search-box mr-2 d-inline-block">
                      <div class="position-relative">
                        <input
                          type="text"
                          class="form-control"
                          id="search"
                          @input="searchData()"
                          v-model="search"
                          :placeholder="trans.get('__JSON__.Search Placeholder')"
                        />
                        <i class="bx bx-search-alt search-icon"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 table-main-list banner-table table-responsive">
                <b-table
                  :items="businessType.data"
                  :fields="fields"
                  :sort-by.sync="sortBy"
                  :no-local-sorting="true"
                  @sort-changed="sortingChanged"
                  :busy="loading"
                  show-empty
                >
                  <template #table-busy>
										<div class="text-center text-danger my-2">
										<b-spinner class="align-middle"></b-spinner>
										<strong>{{trans.get('__JSON__.Loading...')}}</strong>
										</div>
									</template>

                  <template v-slot:cell(id)="data">
                    {{ (businessType.per_page * (businessType.current_page-1)) + data.index + 1}}
                  </template>

                  <template v-slot:cell(status)="data">
                    <div
                      v-if="data.item.status == '0'"
                      class="badge badge-pill badge-soft-success font-size-12"
                    >{{ trans.get('__JSON__.Active') }}</div>
                    <div
                      v-else
                      class="badge badge-pill badge-soft-danger font-size-12"
                    >{{ trans.get('__JSON__.Inactive') }}</div>
                  </template>

                  <template v-slot:cell(action)="data">
                    <div class="vendor-btn">
                      <router-link
                        :to="{ name: 'business-type-edit', params: { id: base64(data.item.id)} }"
                        class="btn btn-success btn-sm btn-rounded"
                      >{{ trans.get('__JSON__.Edit') }}</router-link>
                    </div>
                  </template>

                  <template #empty>
                    <p class="text-center">{{ trans.get('__JSON__.No Business Type Found') }}</p>
                  </template>
                </b-table>
              </div>
              <div class="row col-sm-5 offset-sm-7">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <pagination
                        :data="businessType"
                        :limit="limit"
                        @pagination-change-page="getBusinessType"
                      ></pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
     <!-- Add Business Type Modal Start -->
      <b-modal
        id="modal-scoped"
        v-model="addModal"
        :title="trans.get('__JSON__.Add Business Type')"
        centered
      >
        <b-form @submit.prevent="addBusinessType">
          <div class="row">
            <div class="col-md-12">
              <b-form-group
                id="input-group-1"
                :label="trans.get('__JSON__.Business Type Name')"
                label-for="name"
              >
                <b-form-input
                  id="name"
                  name="name"
                  v-model="$v.typeform.name.$model"
                  type="text"
                  :placeholder="trans.get('__JSON__.Business Type Name Placeholder')"
                  :class="{ 'is-invalid': $v.typeform.name.$error }"
                ></b-form-input>
                <div v-if="$v.typeform.name.$error" class="invalid-feedback">
                  <span
                    v-if="!$v.typeform.name.required"
                  >{{ trans.get('__JSON__.Please enter business type name') }}</span>
                </div>
              </b-form-group>
            </div>
          </div>
        </b-form>
        <template slot="modal-footer">
          <div class="w-100">
            <b-button
              variant="primary"
              class="float-right"
              @click="addBusinessType"
            >{{ trans.get('__JSON__.Submit') }}</b-button>
            <b-button class="float-left" @click="addModal=false">{{ trans.get('__JSON__.Close') }}</b-button>
          </div>
        </template>
      </b-modal>
      <!-- Add Business Type Modal End -->
    </div>
    <!-- end row -->
  </div>
 </Layout>
</template>
