
<script>
import config from "../../config";
import pagination from "laravel-vue-pagination";
import PageHeader from "../../components/page-header";
import moment from "moment";
import { promoCodeService, deliveryZoneService } from "../../services";
import { error_message,debounce } from "../../Helper/helper";

export default {
  components: {
    pagination,
    promoCodeService,
    deliveryZoneService,
    PageHeader,
    moment,
  },
  data() {
    const {country,state,city,orderBy,sortBy,status} = this.$route.query;
    return {
      config:config,
      sortBy: sortBy || "id",
      sortDesc: orderBy && orderBy != '' ? true : orderBy,
      coupons: [],
      paginations: {},
      limit: 2,
      search: "",
      status: status == undefined ? "1" :status,
      moment: moment,
      couponCount: '',
      countries: [],
      states: [],
      cities: [],
      country: country || 0,
      state: state || 0,
      city: city || 0,
      loading: true,
      searchData:debounce(()=>{
        if(!this.init){
          const {status,country,state,city,orderBy,sortBy} = this.$data;
          this.$router.replace({name: 'coupon-list', query: {status,country,state,city,orderBy,sortBy}})
        }
        this.getPromocode();
        this.init = false;
      },500),
      fields: [
        { key: "coupon_image", label: "#", sortable: false },
        { key: "coupon_name", label: "Title", sortable: true },
        { key: "coupon_code", label: "Promo Code", sortable: true },
        { key: "delivery_discount_percentage", label: "Delivery Discount(%)", sortable: true },
        { key: "takeaway_discount_percentage", label: "Takeaway Discount(%)", sortable: true },
        { key: "flat_discount", label: "Flat Discount", sortable: true },
        { key: "start_datetime", label: "Start Date", sortable: true },
        { key: "end_datetime", label: "End Date", sortable: true },
        { key: "status", label: "Status", sortable: false },
        { key: "action", label: "Action", sortable: false , requiresAdmin: true},
      ],
    };
  },
  computed: {
    computedFields() {
      // If the user isn't an admin, filter out fields that require auth.
      if(this.$auth.partner_login)
        return this.fields.filter(field => !field.requiresAdmin);
        
      // If the user IS an admin, return all fields.
      else
        return this.fields;
    }
  },
  mounted() {
    // this.getPromocode();
    this.getVendorCountry();
    //this.getAllState();
    //this.getAllCity();
  },
  methods: {
    getPromocode(page) {
      this.loading = true;
      var filters = {
          search: this.search,
          status: this.status,
          sortBy: this.sortBy,
          orderBy: this.sortDesc ? "desc" : "asc",
          country: this.country,
          state: this.state,
          city: this.city,
          page: page && page > 0 ? page : 1,
      }
      promoCodeService
        .getPromoCode(filters)
        .then((response) => {
          this.loading = false;
          this.paginations = response.data.result.data;
          this.coupons = response.data.result.data.data;
          this.couponCount = response.data.result.promoCodeCount;
        });
    },
     getVendorCountry() {
        this.country = 0;
        this.city = 0;
        this.state = 0;
        this.countries = [];
        this.states = [];
        this.cities = [];
        deliveryZoneService.getVendorCountry().then((response) => {
            this.countries = response.data.result;
            if (this.countries.length == 1) {
            this.country = this.countries[0].id;
            }
            this.getVendorState();
        });
    },
    getVendorState() {
        this.city = 0;
        this.state = 0;
        this.states = [];
        this.cities = [];
        this.country = parseInt(this.country);
        if(this.country > 0) {
            deliveryZoneService.getVendorState({country_id: this.country})
            .then((response) => {
            this.states = response.data.result;
            if (this.states.length == 1) {
                this.state = this.states[0].id;
            }
            this.getVendorCity();
            });
        } else {
            this.searchData();
        }
    },
     getVendorCity() {
        this.city = 0;
        this.cities = [];
        this.state = parseInt(this.state);
        if(this.state > 0) {
            deliveryZoneService.getVendorCity({state_id:this.state ? this.state : 0,country_id: this.country})
            .then((response) => {
            this.cities = response.data.result;
            if (this.cities.length == 1) {
                this.city = this.cities[0].cityid;
            }
            this.searchData();
            });
        } else {
            this.searchData();
        }
    },
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      this.getPromocode();
    },
  },
};
</script>

<template>
  <div>
   <div class="row">
          <div class="col-12">
              <div
              class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
              >
              <div class="page-title-left">
                  <div class="avatar-xs mr-3">
                  <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                      <i class="bx bx-purchase-tag"></i>
                  </span>
                  </div>
                  <h4 class="mb-0 font-size-18">{{ trans.get('__JSON__.PROMO CODES') }}({{couponCount}})</h4>
              </div>
              <div class="page-title-right" v-if="!$auth.partner_login">
                  <router-link :to="{name: 'coupon-add'}" v-if="$auth.hasPermission(config.permissions.promo_code_write)" class="btn btn-success btn-rounded mr-2 add-btn">
                  <i class="mdi mdi-plus mr-1"></i> {{ trans.get('__JSON__.Add Promo Code') }}
                  </router-link>
                  <router-link :to="{name: 'coupon-add'}" v-if="$auth.hasPermission(config.permissions.promo_code_write)" class="btn btn-success btn-rounded mr-2 m-add-btn">
                  <i class="mdi mdi-plus"></i>
                  </router-link>
              </div>
              </div>
          </div>
      </div>
    <!-- end row -->
    <div class="row list-table-main">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div v-if="!$auth.partner_login">
              <div class="row">
                <div v-if="countries.length >1" class="col-sm-2 offset-sm-2">
                  <div class="form-group">
                    <div class="date-range-list">
                      <label>{{ trans.get('__JSON__.Country') }} :</label>
                      <select class="custom-select" v-model="country" @change="getVendorState">
                        <option value="0" selected>All</option>
                        <option
                          v-for="con in countries"
                          :value="con.id"
                          :key="con.id"
                        >{{con.country_name}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div v-else class="offset-sm-4">
                </div>
                <div v-if="states.length >1" class="col-sm-2">
                  <div class="form-group">
                    <div class="date-range-list">
                      <label>{{ trans.get('__JSON__.State') }} :</label>
                      <select class="custom-select" v-model="state" @change="getVendorCity">
                        <option value="0" selected>All</option>
                        <option
                          v-for="sat in states"
                          :value="sat.id"
                          :key="sat.id"
                        >{{sat.state_name}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                 <div v-else class="offset-sm-2">
                </div>
                <div v-if="cities.length >1" class="col-sm-2">
                  <div class="form-group">
                    <div class="date-range-list">
                      <label>{{ trans.get('__JSON__.City') }} :</label>
                      <select class="custom-select" v-model="city" @change="searchData">
                        <option value="0" selected>All</option>
                        <option
                          v-for="cit in cities"
                          :value="cit.cityid"
                          :key="cit.cityid"
                        >{{cit.cityname}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div v-else class="offset-sm-2">
                </div>
                <div class="col-sm-2">
                  <div class="form-group">
                    <div class="date-range-list">
                      <label>{{ trans.get('__JSON__.Status') }} :</label>
                      <select class="custom-select" v-model="status" @change="searchData()">
                        <option value="">{{ trans.get('__JSON__.All') }}</option>
                        <option value="1" selected>{{ trans.get('__JSON__.Active') }}</option>
                        <option value="0">{{ trans.get('__JSON__.Inactive') }}</option>
                        <option value="-1">{{ trans.get('__JSON__.Expired') }}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-sm-2">
                  <div class="search-box mr-2 mb-2 d-inline-block">
                    <div class="date-range-list">
                      <label>{{ trans.get('__JSON__.Search') }} :</label>
                      <div class="position-relative">
                        <input
                          type="text"
                          class="form-control"
                          id="search"
                          @keyup="searchData()"
                          v-model="search"
                          :placeholder="trans.get('__JSON__.Search Placeholder')"
                        />
                        <i class="bx bx-search-alt search-icon"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="$auth.partner_login">
              <div class="row">
                 <div class="col-sm-2  offset-sm-8">
                  <div class="form-group">
                    <div class="date-range-list">
                      <label>{{ trans.get('__JSON__.Status') }} :</label>
                      <select class="custom-select" v-model="status" @change="searchData()">
                        <option value="">{{ trans.get('__JSON__.All') }}</option>
                        <option value="1" selected>{{ trans.get('__JSON__.Active') }}</option>
                        <option value="0">{{ trans.get('__JSON__.Inactive') }}</option>
                        <option value="-1">{{ trans.get('__JSON__.Expired') }}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-sm-2">
                  <div class="search-box mr-2 mb-2 d-inline-block">
                    <div class="date-range-list">
                      <label>{{ trans.get('__JSON__.Search') }} :</label>
                      <div class="position-relative">
                        <input
                          type="text"
                          class="form-control"
                          id="search"
                          @input="searchData()"
                          v-model="search"
                          :placeholder="trans.get('__JSON__.Search Placeholder')"
                        />
                        <i class="bx bx-search-alt search-icon"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 table-main-list coupon-table table-responsive">
                <b-table
                  :items="coupons"
                  :fields="computedFields"
                  :sort-by.sync="sortBy"
                  :no-local-sorting="true"
                  @sort-changed="sortingChanged"
                  :busy="loading"
                  show-empty
                >
                  <template #table-busy>
                    <div class="text-center text-danger my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>{{trans.get('__JSON__.Loading...')}}</strong>
                    </div>
                  </template>

                  <template v-slot:cell(coupon_image)="data">
                     <div v-if="data.item.coupon_image">
                      <img alt title="product-img" class="vendor-img avatar-xs" :src="config.coupon+data.item.coupon_image"/>
                    </div>
                    <div v-else>
                        <img class="vendor-img avatar-xs" :src="config.no_image" alt />
                    </div>
                  </template>

                  <template v-slot:cell(coupon_name)="data">
                    <h5 class="font-size-14">
                      <div
                        v-if="data.item.coupon_name > 15"
                      >{{data.item.coupon_name.substr(0, 15)+ '...'}}</div>
                      <div v-else>{{data.item.coupon_name}}</div>
                    </h5>
                  </template>

                  <template v-slot:cell(coupon_code)="data">
                    <div
                      v-if="data.item.coupon_code.length > 15"
                    >{{data.item.coupon_code.substr(0, 15)+ '...'}}</div>
                    <div v-else>{{data.item.coupon_code}}</div>
                  </template>

                  <template v-slot:cell(start_datetime)="data">
                    <div
                      v-if="data.item.start_datetime"
                    >{{moment(data.item.start_datetime).format('D MMM,Y HH:mm A')}}</div>
                  </template>

                  <template v-slot:cell(end_datetime)="data">
                    <div
                      v-if="data.item.end_datetime"
                    >{{moment(data.item.end_datetime).format('D MMM,Y HH:mm A')}}</div>
                  </template>

                  <template v-slot:cell(status)="data">
                    <div 
                    v-if="moment(data.item.end_datetime).isBefore(moment())"
                     class="badge badge-pill badge-soft-warning font-size-12"
                    >
                    {{ trans.get('__JSON__.Expired') }}
                    </div>
                    <div
                      v-else-if="data.item.status === '0'"
                      class="badge badge-pill badge-soft-danger font-size-12"
                    >
                    {{ trans.get('__JSON__.Inactive') }}
                    </div>
                    <div
                      v-else
                      class="badge badge-pill badge-soft-success font-size-12"
                    >
                    {{ trans.get('__JSON__.Active') }}
                    </div>
                  </template>

                  <template v-slot:cell(action)="data" v-if="!$auth.partner_login">
                    <router-link
                      v-if="$auth.hasPermission(config.permissions.promo_code_write)"
                      :to="{ name: 'coupon-edit',params: { id: base64(data.item.id) }}"
                      class="btn btn-success btn-sm btn-rounded"
                    >{{ trans.get('__JSON__.Edit') }}</router-link>
                  </template>

                  <template #empty>
                    <p class="text-center">{{ trans.get('__JSON__.No Promo Code') }}</p>
                  </template>
                </b-table>
              </div>
              <div class="row col-sm-5 offset-sm-7">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <pagination
                        :data="paginations"
                        :limit="limit"
                        @pagination-change-page="getPromocode"
                      ></pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </div>
</template>

<style>
.coupon-table .table thead th:nth-child(1),.coupon-table .table thead th:nth-child(8),.coupon-table .table thead th:nth-child(9){
  pointer-events: none;
}
</style>