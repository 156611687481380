<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import pagination from "laravel-vue-pagination";
import { reportService } from "../../services";
import moment from "moment";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import { date_ranges } from "../../Helper/helper";


export default {
  components: { Layout, PageHeader, pagination, DateRangePicker },
  data() {
    let startDate = this.moment().startOf("day").subtract(6, "days");
    let endDate = this.moment().endOf("day").toDate();
    return {
      totalIDPOrder: {},
      agentName:[],
      sortBy: "created_at",
      sortDesc: true,
      paginations: {},
      token: localStorage.getItem('token'),
      limit: 2,
      loading: false,
      isDownloading: false,
      date_ranges: date_ranges,
      orderByAgent: "",
      fields: [
        { key: "user_id", label: "#", sortable: false },
        { key: "created_at", label: "Order Date", sortable: false},
        { key: "id", label: "Ticket ID", sortable: false},
        { key: "order_id", label: "Order ID", sortable: false},
        { key: "order_by_agent", label: "Order By Agent", sortable: false},
        { key: "source_type", label: "Source Type", sortable: false},
        { key: "patient_name", label: "Patient Name", sortable: false},
        { key: "patient_phone_number", label: "Patient Phone Number", sortable: false},
        { key: "name", label: "Store Name", sortable: false}
      ],
      moment: moment,
      reportsData: [],
      dateRange: { startDate, endDate },
      filters: {
        start_date: this.moment()
              .startOf("day")
              .subtract(6, "days")
              .format("YYYY-MM-DD"),
        end_date: this.moment().format("YYYY-MM-DD"),
      },
    };
  },
  mounted() {
    this.getIDPOrderDetailsReport();
    if(this.token){
      this.getAgentName();
    }
  },
  methods: {
    getAgentName(){
     const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+ this.token
     }
     this.loading = true;
     reportService
     .getIDPAgent({
          headers: headers,
        })
        .then((response) => {
            console.log(response.data.result, "response");
            this.loading = false;
            this.agentName = response.data.result;
        })
    },
    getIDPOrderDetailsReport(page) {
      this.loading = true;
      reportService
      .getIDPOrderDetails({
        ...this.filters,
        page: page ? page : 1,
        pagination: true,
        order_by_agent: this.orderByAgent
      })
      .then((response) => {
        this.loading = false;
        this.paginations = response.data.result;
        this.totalIDPOrder = response.data.result;
      });
    },
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      this.getIDPOrderDetailsReport();
    },
    saleExportCsv() {
      this.isDownloading = true;
      reportService
      .getIDPOrderDetails({
        ...this.filters,
        pagination: false,
        order_by_agent: this.orderByAgent
      })
      .then((response) => {
        this.isDownloading = false;
        this.reportsData = response.data.result;
        this.downloadCSV();
      });
    },
    updateValues(date) {
      this.filters = {
        ...this.filters,
        start_date: this.moment(date.startDate.toISOString()).format(
          "YYYY-MM-DD"
        ),
        end_date: this.moment(date.endDate.toISOString()).format("YYYY-MM-DD"),
      };
      this.getIDPOrderDetailsReport();
    },
    downloadCSV() {
        let reportsData =
          '\ufeff' +
          'S. No.,Order Date,Ticket ID,Order ID,Order By Agent,Source Type,Patient Name,Patient Phone Number,Store Name\n'
        this.reportsData.forEach((el, ind) => {
            let sno = ind + 1;
          var line =
            sno +
            "," +
            moment(el["created_at"]).format('DD-MM-YY hh:mm A') + 
            "," +
            el["id"] +
            "," +
            el["order_id"] +
            "," +
            (el["order_by_agent"] ? el["order_by_agent"] : "-")+
            "," +
            (el["source_type"] ? el["source_type"] : "-") +
            "," +
            (el["patient_name"] ? el["patient_name"].split(',').join('') : "-") +
            "," +
            el["patient_phone_number"] +
            "," +
            (el["name"] ? el["name"] : "-") +
            "\n";
          reportsData += line;
        });

        var blob = new Blob([reportsData], { type: "csv/plain" });
        var date = new Date().toLocaleString();
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "idp_order_details" + date + ".csv";
        link.click();
    },
    searchData() {
      this.getIDPOrderDetailsReport();
    },
  },
  filters: {
    date(val) {
      return val ? val.toLocaleString() : "";
    },
  },
};
</script>

<template>
  <Layout>
    <div>
      <div class="row">
          <div class="col-12">
              <div
              class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
              >
              <div class="page-title-left avatar-main-icon">
                  <div class="avatar-xs mr-3">
                  <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                      <i class="bx bx-purchase-tag"></i>
                  </span>
                  </div>
                  <div class="report-tag">
                    <h4 class="mb-0 font-size-18">{{ trans.get('__JSON__.IDP Order Report') }}</h4>
                    <p><router-link :to="{ name: 'reports-list'}">{{ trans.get('__JSON__.Reports') }}</router-link> > {{ trans.get('__JSON__.IDP Order Report') }}</p>
                  </div>
              </div>
              <div class="page-title-right">
              <button
                @click="saleExportCsv"
                class="btn btn-primary btn-rounded export-btn"
                v-if="isDownloading"
                disabled
              >
                {{ trans.get("__JSON__.Exporting...") }}
              </button>
              <button
                @click="saleExportCsv"
                class="btn btn-primary btn-rounded export-btn"
                v-else
              >
                {{ trans.get("__JSON__.Export To CSV") }}
              </button>
              <button
                @click="saleExportCsv"
                class="btn btn-primary btn-rounded m-export-btn"
              >
                <i class="bx bx-archive-in"></i>
              </button>
                </div>
              </div>
          </div>
      </div>

      <div class="row">
        <div class="col-12 list-table-main">
          <div class="card">
            <div class="card-body">
              <div class="row justify-content-end">
                <div class="form-group" style="margin-right: 12px;">
                    <div class="date-range-list">
                      <label>{{ trans.get("__JSON__.Agent Login") }} :</label>
                      <select
                        class="custom-select"
                        v-model="orderByAgent"
                        @change="searchData()"
                      >
                        <option value selected>{{ trans.get("__JSON__.All") }}</option>
                        <option v-for="(agent, index) in agentName" :value="agent.user_name" :key="agent.index">{{agent.full_name}}</option>
                      </select>
                    </div>
                  </div>
                <div class="form-group" style="margin-right: 12px;">
                  <div class="date-range-list">
                      <label>{{ trans.get("__JSON__.Date") }} :</label>
                      <date-range-picker
                        ref="picker"
                        :locale-data="{
                          firstDay: 1,
                          format: 'dd-mm-yyyy',
                        }"
                        :showDropdowns="true"
                        :showWeekNumbers="true"
                        opens="left"
                        v-model="dateRange"
                        :ranges="date_ranges()"
                        @update="updateValues"
                      >
                        <template
                          v-slot:input="picker"
                          style="min-width: 350px"
                        >
                          {{
                            moment(picker.startDate.toISOString()).format(
                              "MMMM DD, YYYY"
                            ) | date
                          }}
                          -
                          {{
                            moment(picker.endDate.toISOString()).format(
                              "MMMM DD, YYYY"
                            ) | date
                          }}
                        </template>
                      </date-range-picker>
                    </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 table-main-list total-sales-report-table table-responsive">
                  <b-table
                    :items="totalIDPOrder.data"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :no-local-sorting="true"
                    @sort-changed="sortingChanged"
                    :busy="loading"
                    show-empty
                  >
                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>{{trans.get('__JSON__.Loading...')}}</strong>
                      </div>
                    </template>

                    <template v-slot:cell(user_id)="data">
                      <div>{{ (totalIDPOrder.per_page * (totalIDPOrder.current_page-1)) + data.index + 1 }}</div>
                    </template>

                    <template v-slot:cell(created_at)="data">
                      <div>{{moment(data.item.created_at).format('D MMM,Y hh:mm A')}}</div>
                    </template>

                    <template v-slot:cell(order_by_agent)="data">
                      <div>{{data.item.order_by_agent ? data.item.order_by_agent : "-"}}</div>
                    </template>

                    <template v-slot:cell(source_type)="data">
                      <div>{{data.item.source_type ? data.item.source_type : "-"}}</div>
                    </template>

                    <template v-slot:cell(name)="data">
                      <div>{{data.item.name ? data.item.name : "-"}}</div>
                    </template>

                    <template #empty>
                      <p class="text-center">{{ trans.get('__JSON__.No record found') }}</p>
                    </template>
                  </b-table>
                </div>
                <div class="row col-sm-5 offset-sm-7">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <pagination
                          :data="totalIDPOrder"
                          :limit="limit"
                          @pagination-change-page="getIDPOrderDetailsReport"
                        ></pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>


<style>
.total-sales-report-table .table thead th:nth-child(1),.total-sales-report-table .table thead th:nth-child(3),.total-sales-report-table .table thead th:nth-child(4){
    pointer-events: none;
}

.store-gst{
    width: 96% !important;
}

.mx-input:disabled,
.mx-input.disabled {
  color: #555;
  background-color: #fff;
  border-color: #ccc;
  cursor: not-allowed !important;
}

.mx-input {
  height: calc(1.5em + 0.94rem + 1px) !important;
}

.sales-date.mx-datepicker svg {
  cursor: pointer !important;
}

.mx-datepicker svg {
    cursor: not-allowed;
}

</style>