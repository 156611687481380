<script>
import vue2Dropzone from 'vue2-dropzone'
import Multiselect from 'vue-multiselect'
import { settingService, businessTypeService } from "../../../services";
import { required } from "vuelidate/lib/validators";
import { error_message, success_message } from '../../../Helper/helper';
import config from '../../../config';

import DropzonePopup from "../../../components/widgets/dropzone-popup";
import { routes } from '../../../config/api_routes';

/**
 * Starter component
 */
export default {
  components: { vueDropzone: vue2Dropzone, Multiselect, DropzonePopup },
  data() {
    return {
      config: config,
      checked: true,
      pem_file_path:"",
      owner_pem_delete_url: routes.removeIOSPemApi,
      owner_pem:"",
      customer_pem:"",
      driver_pem:"",
      setting:{},
      errors: '',
      setting: {
        google_map_key: "",
        system_latitude: "",
        system_longitude: "",
        customer_pem :'',
        owner_pem:'',
        driver_pem:'',
        notification_server_key:'',
        firebase_apiKey: "",
        firebase_authDomain: "",
        firebase_databaseURL: "",
        firebase_projectId: "",
        firebase_storageBucket: "",
        firebase_messagingSenderId: "",
        firebase_appId: "",
        firebase_measurementId: ""
      }
    };
  }, 
  validations: {
    setting: {
      google_map_key: {
        required,
      },
      system_latitude: {
        required,
      },
      system_longitude: {
        required,
      },

      // customer_pem: {
      //     required
      // },
      // owner_pem: {
      //     required
      // },
      // driver_pem: {
      //     required
      // },
      notification_server_key:{
        required
      },
    },
  },
  mounted() {
    this.getSetting();
    this.getBusinessTypeById();
  },
  methods: {
    getBusinessTypeById() {
      businessTypeService
        .getBusinessTypeById({ id: this.$route.params.id })
        .then((response) => {
          const { id, name, status } = response.data.result;
          this.id = response.data.result.id;
          this.setting = response.data.result.settings;
          console.log(this.setting);
          this.typeform = {
            id,
            name,
            status,
          };
        });
    },
    onOwnerPemdeleted(){
      this.setting.owner_pem = "";
    },
    onCustomerPemdeleted(){
      this.setting.customer_pem = "";
    },
    onDriverPemdeleted(){
      this.setting.driver_pem = "";
    },
    getSetting(){
      settingService.getSetting().then(response => {
        this.setting = response.data.result;
      });
    },
    keyInIt(){
      this.$v.$touch();
      if (this.$v.setting.$invalid) {
        return;
      } else {
        const fd = new FormData();

        if (!!this.setting.customer_pem){
          fd.append("customer_pem", this.setting.customer_pem);
        }
        if (!!this.setting.owner_pem){
          fd.append("owner_pem", this.setting.owner_pem);
        }
        if (!!this.setting.driver_pem){
          fd.append("driver_pem", this.setting.driver_pem);
        }
        fd.append("notification_server_key",this.setting.notification_server_key);

        fd.append("google_map_key", this.setting.google_map_key);
        fd.append("system_latitude", this.setting.system_latitude);
        fd.append("system_longitude", this.setting.system_longitude);
        //FireBase
        fd.append("firebase_apiKey", this.setting.firebase_apiKey);
        fd.append("firebase_authDomain", this.setting.firebase_authDomain);
        fd.append("firebase_databaseURL", this.setting.firebase_databaseURL);
        fd.append("firebase_projectId", this.setting.firebase_projectId);
        fd.append("firebase_storageBucket", this.setting.firebase_storageBucket);
        fd.append("firebase_messagingSenderId", this.setting.firebase_messagingSenderId);
        fd.append("firebase_appId", this.setting.firebase_appId);
        fd.append("firebase_measurementId", this.setting.firebase_measurementId);

        fd.append("type",5);
        settingService.updateSetting(fd).then((response) => {
          if (response.data.code === 200) {
            if(response.data.result){
              this.$auth.updateSetting(response.data.result.settings)
            }
            success_message(response.data.message);
          } else {
            error_message(response.data.message);
          }
        });
      }
    }
  }
};
</script>

<template>
  <b-form @submit.prevent="keyInIt">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body setting-main">
            <div class="row section-main">
              <div class="col-md-4">
                <div class="setting-heading">  
                  <h4>{{ trans.get('__JSON__.Google Map') }}</h4>
                </div>
              </div>
              <div class="col-md-8">
                <div class="setting-data">
                  <div class="row">
                    <div class="col-md-12">
                      <h6>{{ trans.get('__JSON__.Google Map Key') }}</h6>
                    </div>
                    <div class="col-md-6 order-value">
                      <b-form-group>
                        <b-form-input
                          id="google_map_key"
                          name="google_map_key"
                          v-model="$v.setting.google_map_key.$model"
                          type="text"
                          :placeholder="trans.get('__JSON__.Google Map Key')"
                          :class="{ 'is-invalid':  $v.setting.google_map_key.$error }"
                        ></b-form-input>
                        <div v-if=" $v.setting.google_map_key.$error" class="invalid-feedback">
                          <span v-if="!$v.setting.google_map_key.required"
                          >{{ trans.get('__JSON__.Google Map Key Required') }}.</span>
                        </div>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="row mt-4">
                    <div class="col-md-6">
                      <h6>{{ trans.get('__JSON__.Latitude') }}</h6>
                      <b-form-group>
                        <b-form-input
                          id="system_latitude"
                          name="system_latitude"
                          v-model="$v.setting.system_latitude.$model"
                          type="text"
                          :placeholder="trans.get('__JSON__.Latitude')"
                          :class="{ 'is-invalid':  $v.setting.system_latitude.$error }"
                        ></b-form-input>
                        <div v-if=" $v.setting.system_latitude.$error" class="invalid-feedback">
                          <span v-if="!$v.setting.system_latitude.required"
                          >{{ trans.get('__JSON__.Latitude Required') }}.</span>
                        </div>
                      </b-form-group>
                    </div>
                    <div class="col-md-6">
                      <h6>{{ trans.get('__JSON__.Longitude') }}</h6>
                      <b-form-group id="input-group-1" label-for="name">
                        <b-form-input
                          id="system_longitude"
                          name="system_longitude"
                          v-model="$v.setting.system_longitude.$model"
                          type="text"
                          :placeholder="trans.get('__JSON__.Longitude')"
                          :class="{ 'is-invalid':  $v.setting.system_longitude.$error }"
                        ></b-form-input>
                        <div v-if=" $v.setting.system_longitude.$error" class="invalid-feedback">
                          <span v-if="!$v.setting.system_longitude.required"
                          >{{ trans.get('__JSON__.Longitude Required') }}.</span>
                        </div>
                      </b-form-group>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row section-main">
              <div class="col-md-4">
                <div class="setting-heading">  
                  <h4>Push Notifications</h4>
                </div>
              </div>
              <div class="col-md-8">
                <div class="setting-data">
                  <div class="row">
                    <div class="col-md-12">
                      <h6>Pem Files (iOS Application)</h6>  
                    </div>
                    <div class="col-md-4 mt-3 customer-app">
                      <p>Customer Application</p>
                      <dropzone-popup
                        :readonly="!$auth.hasPermission(config.permissions.terminology_write)"
                        v-model="setting.customer_pem"
                        id="customer_pem"
                        acceptedFiles=".pem"
                        :media-path="setting.pem_url"
                        :delete-path="owner_pem_delete_url"
                        @image-deleted="this.onCustomerPemdeleted"
                      />
                    </div>
                    <div class="col-md-4 mt-3 customer-app">
                      <p>Merchant Application</p>
                      <dropzone-popup
                        :readonly="!$auth.hasPermission(config.permissions.terminology_write)"
                        v-model="setting.owner_pem"
                        id="owner_pem"
                        acceptedFiles=".pem"
                        :media-path="setting.pem_url"
                        :delete-path="owner_pem_delete_url"
                        @image-deleted="this.onOwnerPemdeleted"
                      />
                    </div>
                    <div class="col-md-4 mt-3 customer-app">
                      <p>Driver Application</p>
                      <dropzone-popup
                        :readonly="!$auth.hasPermission(config.permissions.terminology_write)"
                        v-model="setting.driver_pem"
                        id="driver_pem"
                        acceptedFiles=".pem"
                        :media-path="setting.pem_url"
                        :delete-path="owner_pem_delete_url"
                        @image-deleted="this.onDriverPemdeleted"
                      />
                    </div>
                  </div>
                  <div class="row mt-4">
                    <div class="col-md-6">
                      <b-form-group id="input-group-1" :label="trans.get('__JSON__.Notification Server Key')" label-for="name">
                        <b-form-input
                          id="notification_server_key"
                          name="notification_server_key"
                          v-model="$v.setting.notification_server_key.$model"
                          type="text"
                          :placeholder="trans.get('__JSON__.Notification Server Key')"
                          :class="{ 'is-invalid':  $v.setting.notification_server_key.$error }"
                        ></b-form-input>
                        <div v-if=" $v.setting.notification_server_key.$error" class="invalid-feedback">
                          <span v-if="!$v.setting.notification_server_key.required"
                            >{{ trans.get('__JSON__.Notification Server Key') }}.</span>
                        </div>
                      </b-form-group>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row section-main" v-if="[40818, 40969, 40967, 41191, 40940, 41200, 40942].includes(this.$auth.getVendorId())">
              <div class="col-md-4">
                <div class="setting-heading">  
                  <h4>{{ trans.get('__JSON__.Firebase front web settings') }}</h4>
                </div>
              </div>
              <div class="col-md-8">
                <div class="setting-data">
                  <div class="row">
                    <div class="col-md-6">
                      <h6>{{ trans.get('__JSON__.API Key') }}</h6>
                      <b-form-group>
                        <b-form-input
                          id="firebase_apiKey"
                          name="firebase_apiKey"
                          v-model="setting.firebase_apiKey"
                          type="text"
                          :placeholder="trans.get('__JSON__.API Key')"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="col-md-6">
                      <h6>{{ trans.get('__JSON__.Auth Domain') }}</h6>
                      <b-form-group>
                        <b-form-input
                          id="firebase_authDomain"
                          name="firebase_authDomain"
                          v-model="setting.firebase_authDomain"
                          type="text"
                          :placeholder="trans.get('__JSON__.Auth Domain')"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="row mt-4">
                    <div class="col-md-6">
                      <h6>{{ trans.get('__JSON__.Database URL') }}</h6>
                      <b-form-group id="input-group-1" label-for="name">
                        <b-form-input
                          id="firebase_databaseURL"
                          name="firebase_databaseURL"
                          v-model="setting.firebase_databaseURL"
                          type="text"
                          :placeholder="trans.get('__JSON__.Database URL')"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="col-md-6">
                      <h6>{{ trans.get('__JSON__.Project Id') }}</h6>
                      <b-form-group>
                        <b-form-input
                          id="firebase_projectId"
                          name="firebase_projectId"
                          v-model="setting.firebase_projectId"
                          type="text"
                          :placeholder="trans.get('__JSON__.Project Id')"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="row mt-4">
                    <div class="col-md-6">
                      <h6>{{ trans.get('__JSON__.Storage Bucket') }}</h6>
                      <b-form-group id="input-group-1" label-for="name">
                        <b-form-input
                          id="firebase_storageBucket"
                          name="firebase_storageBucket"
                          v-model="setting.firebase_storageBucket"
                          type="text"
                          :placeholder="trans.get('__JSON__.Storage Bucket')"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="col-md-6">
                      <h6>{{ trans.get('__JSON__.Messaging Sender Id') }}</h6>
                      <b-form-group>
                        <b-form-input
                          id="firebase_messagingSenderId"
                          name="firebase_messagingSenderId"
                          v-model="setting.firebase_messagingSenderId"
                          type="text"
                          :placeholder="trans.get('__JSON__.Messaging Sender Id')"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="row mt-4">
                    <div class="col-md-6">
                      <h6>{{ trans.get('__JSON__.App Id') }}</h6>
                      <b-form-group id="input-group-1" label-for="name">
                        <b-form-input
                          id="firebase_appId"
                          name="firebase_appId"
                          v-model="setting.firebase_appId"
                          type="text"
                          :placeholder="trans.get('__JSON__.App Id')"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="col-md-6">
                      <h6>{{ trans.get('__JSON__.Measurement Id') }}</h6>
                      <b-form-group>
                        <b-form-input
                          id="firebase_measurementId"
                          name="firebase_measurementId"
                          v-model="setting.firebase_measurementId"
                          type="text"
                          :placeholder="trans.get('__JSON__.Measurement Id')"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="btn-update">
              <div class="col-sm-6">
                <button type="submit" class="btn btn-primary mr-1 mt-3">{{ trans.get('__JSON__.Update') }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-form>
</template>