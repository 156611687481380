<script>

import config from "../../config";
import pagination from "laravel-vue-pagination";
import { pageService } from "../../services";
import { success_message, error_message } from "../../Helper/helper";

/**
 * Starter component
 */
export default {
  components: { pagination, pageService },
  data() {
    return {
      sortBy: "id",
      sortDesc: true,
      pages: [],
      paginations: {},
      limit: 1,
      search: "",
      config: config,
      loading: true,
      fields: [
        {
          key: "page_name",
          label: this.trans.get("__JSON__.#"),
          sortable: true,
        },
        {
          key: "page_title",
          label: this.trans.get("__JSON__.Name"),
          sortable: true,
        },
        {
          key: "action",
          label: this.trans.get("__JSON__.Action"),
          sortable: false,
        },
      ],
    };
  },
  mounted() {
    this.getContentPage();
  },
  methods: {
    getContentPage(page) {
      this.loading = true;
      pageService
        .getContentPage({
          search: this.search,
          sortBy: this.sortBy,
          orderBy: this.sortDesc ? "asc" : "desc",
          page: page && page > 0 ? page : 1,
        })
        .then((response) => {
          this.loading = false;
          this.paginations = response.data.result;
          this.pages = response.data.result.data;
        });
    },
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      this.getContentPage();
    },
    deleteById(id) {
      pageService.deleteContentPage({ id: id }).then((response) => {
        if (response.data.code === 200) {
          success_message(response.data.message);
          this.getContentPage();
        } else {
          error_message(response.data.message);
        }
      });
    },
  },
};
</script>

<template>
    <div>
      <div class="row">
        <div class="col-12 list-table-main">
          <div class="card">
            <div class="card-body">
              <div class="row">
                
              </div>
              <div class="row">
                <div class="col-12 table-main-list cuisine-table table-responsive">
                  <b-table
                    :items="pages"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :no-local-sorting="true"
                    @sort-changed="sortingChanged"
                    :busy="loading"
                    show-empty
                  >
                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>{{trans.get('__JSON__.Loading...')}}</strong>
                      </div>
                    </template>

                    <template v-slot:cell(page_name)="data">
                      <h5 class="font-size-14 mb-1">
                        <div>{{data.item.page_name}}</div>
                      </h5>
                    </template>

                    <template v-slot:cell(page_title)="data">
                      <h5 class="font-size-14 mb-1">
                        <div>{{data.item.page_title}}</div>
                      </h5>
                    </template>

                    <template v-slot:cell(action)="data">
                      <div class="vendor-btn">
                        <button
                          type="submit"
                          class="btn btn-danger btn-sm btn-rounded ml-1"
                          @click="deleteById(base64(data.item.id))"
                        >{{ trans.get('__JSON__.Delete') }}</button>
                      </div>
                    </template>

                    <template #empty>
                      <p class="text-center">{{ trans.get('__JSON__.No Pages') }}</p>
                    </template>
                  </b-table>
                </div>
                <div class="row col-sm-5 offset-sm-7">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <pagination
                          :data="paginations"
                          :limit="limit"
                          @pagination-change-page="getContentPage"
                        ></pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end row -->
    </div>
</template>