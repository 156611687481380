<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";

import OrderList from "./list";
import { orderService } from "../../services";
import { get_decimal } from "../../Helper/helper";
/**
 * Products-order component
 */
export default {
  components: { Layout, PageHeader, OrderList },
  data() {
    return {
      filters: null,
      orders: null,
      decimal: get_decimal(),
    };
  },
  methods: {
    exportOrders() {
      // this.loading = true;
      // orderService
      //   .exportOrders({
      //     ...this.filters,
      //   })
      orderService
        .exportAllOrders({
          ...this.filters,
          paginate: false,
        })
        .then((response) => {
          // this.loading = false;
          this.orders = response.data.result;
          // console.log(this.orders[0]["customer"]["customer_name"],"response.data.result");
          this.downloadCSV();
        })
        .catch(function (error) {
          // this.loading = false;
          console.log(error);
        });
    },
    getSelectionPrices(items) {
      return parseFloat(
        items.length > 0
          ? items.reduce(function (accu, item) {
              return accu + parseFloat(item.selection_price);
            }, 0)
          : 0
      );
    },
    getPrice(item) {
      if (
        item.delivery_pickup_types == "Delivery" &&
        item.master_menu_item_selections
      ) {
        return parseFloat(
          parseInt(item.quantity) *
            this.getSelectionPrices(item.master_menu_item_selections) +
            parseInt(item.quantity) * parseFloat(item.item_price || 0)
        ).toFixed(this.decimal);
      } else if (
        item.delivery_pickup_types == "Pickup" &&
        item.menu_item_selections
      ) {
        return parseFloat(
          parseInt(item.quantity) *
            this.getSelectionPrices(item.menu_item_selections) +
            parseInt(item.quantity) * parseFloat(item.item_price || 0)
        ).toFixed(this.decimal);
      } else {
        return 0;
      }
    },
    downloadCSV: function () {
      let orders = [];
      if (this.$auth.partner_login) {
        if (this.$auth.setting.show_customer_to_vendor == "Yes") {
          orders =
            "\ufeff" +
            "SR,Order ID,Patient Name,Patient Number,Order Date," +
            this.$auth.getRestaurantName() +
            " Name," +
            this.$auth.getRestaurantName() +
            " Number,Payment Method,Order Status,Coupon Code,Sub Total,Delivery Charge,Discount,Tax,Total Amount,Cancel Date,Special Instruction,Driver Name,Delivery Type,Invoice Status,Invoice Date,Order placed by name,Order placed by number\n";
        } else {
          orders =
            "\ufeff" +
            "SR,Order ID,Patient Name,Order Date," +
            this.$auth.getRestaurantName() +
            " Name," +
            this.$auth.getRestaurantName() +
            " Number,Payment Method,Order Status,Coupon Code,Sub Total,Delivery Charge,Discount,Tax,Total Amount,Cancel Date,Special Instruction,Driver Name,Delivery Type,Invoice Status,Invoice Date,Order placed by name,Order placed by number\n";
        }
      } else {
        orders =
          "\ufeff" +
          "SR,Order ID,Patient Name,Patient Number,Account Holder Name,Account Holder Number,Lead Source,Order Date," +
          this.$auth.getRestaurantName() +
          " Name," +
          this.$auth.getRestaurantName() +
          " Number,Payment Method,Order Status,Coupon Code,Sub Total,Delivery Charge,Discount,Tax,Total Amount,Cancel Date,Special Instruction,Driver Name,Delivery Type,Cancellation Description,Customer State,HDP Order,Invoice Status,Invoice Date,Order placed by name,Order placed by number\n";
      }
      this.orders.forEach((el, ind) => {
        let sno = ind + 1;
        let accountholdernumber;
        if (el["account_holder_number"] == "9999999999") {
          accountholdernumber = "-";
        } else {
          accountholdernumber = el["account_holder_number"];
        }
        let Dunzo = el["runner_name"];
        let Dunzotaskid = el["task_id"];
        // let Driver;
        // if(el["driver"].length > 0 ){
        //   Driver = `${el["driver"][0]["firstname"]} ${el["driver"][0]["lastname"]}`;
        // }
        let Driver = el["driver_name"];
        let text = el["order_id"];
        if (el["sipment1_orderId"]) {
          if (el["is_depot_store"] == 1) {
            text = `Depot ${el["order_id"]} (Sub Order of #${el["sipment1_orderId"]})`;
          }
        }
        if (this.$auth.partner_login) {
          if (this.$auth.setting.show_customer_to_vendor == "Yes") {
            var line =
              sno +
              "," +
              text +
              "," +
              el["patient_name"].split(",").join("") +
              "," +
              el["patient_number"] +
              "," +
              this.moment(el["created_at"]).format("DD-MM-YYYY hh:mm A") +
              "," +
              (el["stores_name"] ? el["stores_name"] : "-") +
              "," +
              (el["store_number"] ? el["store_number"] : "-") +
              "," +
              el["payment_method"] +
              "," +
              el["order_status"] +
              "," +
              (el["coupon_code"] ? el["coupon_code"] : "--") +
              "," +
              el["sub_total"] +
              "," +
              el["delivery_fee"] +
              "," +
              (el["discount_price"]
                ? parseFloat(el["original_price"] - el["discount_price"])
                : "--") +
              "," +
              (el["tax"] ? el["tax"] : "--") +
              "," +
              el["total_amount"] +
              "," +
              (el["cancelled_date"] === "0000-00-00 00:00:00"
                ? "-"
                : this.moment(el["cancelled_date"]).format(
                    "DD-MM-YYYY hh:mm A"
                  )) +
              "," +
              (el["notes"] ? el["notes"] : "--") +
              "," +
              (el["runner_name"] && Dunzotaskid ? Dunzo : Driver ? Driver : "-") +
              "," +
              el["delivery_pickup_types"] +
              "," +
              (el["order_status"] &&
              el["order_status"].toLowerCase() == "delivered"
                ? el["vendor_invoice"] == null
                  ? "Pending"
                  : "Uploaded"
                : "-") +
              "," +
              (el["order_status"] &&
              el["order_status"].toLowerCase() == "delivered" &&
              el["invoice_date"]
                ? this.moment(el["invoice_date"]).format("DD-MM-YYYY hh:mm A")
                : "-") +
              "," +
              el["order_placed_by_name"] +
              "," +
              el["order_placed_by_number"] +
              "\n";
            orders += line;
          } else {
            var line =
              sno +
              "," +
              text +
              "," +
              el["patient_name"].split(",").join("") +
              "," +
              this.moment(el["created_at"]).format("DD-MM-YYYY hh:mm A") +
              "," +
              (el["stores_name"] ? el["stores_name"] : "-") +
              "," +
              (el["store_number"] ? el["store_number"] : "-") +
              "," +
              el["payment_method"] +
              "," +
              el["order_status"] +
              "," +
              (el["coupon_code"] ? el["coupon_code"] : "--") +
              "," +
              el["sub_total"] +
              "," +
              el["delivery_fee"] +
              "," +
              (el["discount_price"]
                ? parseFloat(el["original_price"] - el["discount_price"])
                : "--") +
              "," +
              (el["tax"] ? el["tax"] : "--") +
              "," +
              el["total_amount"] +
              "," +
              (el["cancelled_date"] === "0000-00-00 00:00:00"
                ? "-"
                : this.moment(el["cancelled_date"]).format(
                    "DD-MM-YYYY hh:mm A"
                  )) +
              "," +
              (el["notes"] ? el["notes"] : "--") +
              "," +
              (el["runner_name"] && Dunzotaskid ? Dunzo : Driver ? Driver : "-") +
              "," +
              el["delivery_pickup_types"] +
              "," +
              (el["order_status"] &&
              el["order_status"].toLowerCase() == "delivered"
                ? el["vendor_invoice"] == null
                  ? "Pending"
                  : "Uploaded"
                : "-") +
              "," +
              (el["order_status"] &&
              el["order_status"].toLowerCase() == "delivered" &&
              el["invoice_date"]
                ? this.moment(el["invoice_date"]).format("DD-MM-YYYY hh:mm A")
                : "-") +
              "," +
              el["order_placed_by_name"] +
              "," +
              el["order_placed_by_number"] +
              "\n";
            orders += line;
          }
        } else {
          var line =
            sno +
            "," +
            text +
            "," +
            el["patient_name"].split(",").join("") +
            "," +
            el["patient_number"] +
            "," +
            el["account_holder_name"] +
            "," +
            accountholdernumber +
            "," +
            (el["source_type"] ? el["source_type"] : "-") +
            "," +
            this.moment(el["created_at"]).format("DD-MM-YYYY hh:mm A") +
            "," +
            (el["stores_name"] ? el["stores_name"] : "-") +
            "," +
            (el["store_number"] ? el["store_number"] : "-") +
            "," +
            el["payment_method"] +
            "," +
            el["order_status"] +
            "," +
            (el["coupon_code"] ? el["coupon_code"] : "--") +
            "," +
            el["sub_total"] +
            "," +
            el["delivery_fee"] +
            "," +
            (el["discount_price"]
              ? parseFloat(el["original_price"] - el["discount_price"])
              : "--") +
            "," +
            (el["tax"] ? el["tax"] : "--") +
            "," +
            el["total_amount"] +
            "," +
            (el["cancelled_date"] === "0000-00-00 00:00:00"
              ? "-"
              : this.moment(el["cancelled_date"]).format(
                  "DD-MM-YYYY hh:mm A"
                )) +
            "," +
            (el["notes"] ? el["notes"] : "--") +
            "," +
            (el["runner_name"] && Dunzotaskid ? Dunzo : Driver ? Driver : "-") +
            "," +
            el["delivery_pickup_types"] +
            "," +
            (el["cancelled_desc"] === null ? "-" : el["cancelled_desc"]) +
            "," +
            el["customer_state"] +
            "," +
            (el["hdp_Id"] ? "Yes" : "No") +
            "," +
            (el["order_status"] &&
            el["order_status"].toLowerCase() == "delivered"
              ? el["vendor_invoice"] == null
                ? "Pending"
                : "Uploaded"
              : "-") +
            "," +
            (el["order_status"] &&
            el["order_status"].toLowerCase() == "delivered" &&
            el["invoice_date"]
              ? this.moment(el["invoice_date"]).format("DD-MM-YYYY hh:mm A")
              : "-") +
            "," +
            el["order_placed_by_name"] +
            "," +
            el["order_placed_by_number"] +
            "\n";
          orders += line;
        }
        // var i = 0;
        // do {
        //   if (el["delivery_pickup_types"] == "Delivery") {
        //     line +=
        //       ((el["order_item"] &&
        //         el["order_item"][i] &&
        //         el["order_item"][i]["master_menu_item"] &&
        //         el["order_item"][i]["master_menu_item"]["item_name"].replaceAll(
        //           " ",
        //           " "
        //         )) ||
        //         "") +
        //       "," +
        //       ((el["order_item"] &&
        //         el["order_item"][i] &&
        //         this.getPrice(el["order_item"][i])) ||
        //         "0") +
        //       "," +
        //       ((el["order_item"] &&
        //         el["order_item"][i] &&
        //         el["order_item"][i]["quantity"]) ||
        //         "") +
        //       "," +
        //       (el["order_item"] &&
        //       el["order_item"][i] &&
        //       el["order_item"][i]["master_menu_item_selections"].length > 0
        //         ? el["order_item"][i]["master_menu_item_selections"]
        //             .map((i) => i.selection_name)
        //             .join("|")
        //         : "--") +
        //       "" +
        //       "\n";
        //   } else {
        //     line +=
        //       ((el["order_item"] &&
        //         el["order_item"][i] &&
        //         el["order_item"][i]["menu_item"] &&
        //         el["order_item"][i]["menu_item"]["item_name"].replaceAll(
        //           " ",
        //           " "
        //         )) ||
        //         "") +
        //       "," +
        //       ((el["order_item"] &&
        //         el["order_item"][i] &&
        //         this.getPrice(el["order_item"][i])) ||
        //         "0") +
        //       "," +
        //       ((el["order_item"] &&
        //         el["order_item"][i] &&
        //         el["order_item"][i]["quantity"]) ||
        //         "") +
        //       "," +
        //       (el["order_item"] &&
        //       el["order_item"][i] &&
        //       el["order_item"][i]["menu_item_selections"].length > 0
        //         ? el["order_item"][i]["menu_item_selections"]
        //             .map((i) => i.selection_name)
        //             .join("|")
        //         : "--") +
        //       "" +
        //       "\n";
        //   }

        //   i++;
        //   orders += line;
        //   line = ",,,,,,,,,,,,,,,,,";
        // } while (el["order_item"].length > i);

        //Suborder start
        // var si = 0;
        // do {
        //   if (el["delivery_pickup_types"] == "Delivery") {
        //     line +=
        //       ((el["suborder"]["suborder_item"] &&
        //         el["suborder"]["suborder_item"][si] &&
        //         el["suborder"]["suborder_item"][si]["master_menu_item"] &&
        //         el["suborder"]["suborder_item"][si]["master_menu_item"][
        //           "item_name"
        //         ].replaceAll(" ", " ")) ||
        //         "") +
        //       "," +
        //       ((el["suborder"]["suborder_item"] &&
        //         el["suborder"]["suborder_item"][si] &&
        //         this.getPrice(el["suborder"]["suborder_item"][si])) ||
        //         "0") +
        //       "," +
        //       ((el["suborder"]["suborder_item"] &&
        //         el["suborder"]["suborder_item"][si] &&
        //         el["suborder"]["suborder_item"][si]["quantity"]) ||
        //         "") +
        //       "," +
        //       (el["suborder"]["suborder_item"] &&
        //       el["suborder"]["suborder_item"][si] &&
        //       el["suborder"]["suborder_item"][si]["master_menu_item_selections"]
        //         .length > 0
        //         ? el["suborder"]["suborder_item"][si][
        //             "master_menu_item_selections"
        //           ]
        //             .map((i) => i.selection_name)
        //             .join("|")
        //         : "--") +
        //       "" +
        //       "\n";
        //   } else {
        //     line +=
        //       ((el["suborder"]["suborder_item"] &&
        //         el["suborder"]["suborder_item"][si] &&
        //         el["suborder"]["suborder_item"][si]["menu_item"] &&
        //         el["suborder"]["suborder_item"][si]["menu_item"][
        //           "item_name"
        //         ].replaceAll(" ", " ")) ||
        //         "") +
        //       "," +
        //       ((el["suborder"]["suborder_item"] &&
        //         el["suborder"]["suborder_item"][si] &&
        //         this.getPrice(el["suborder"]["suborder_item"][si])) ||
        //         "0") +
        //       "," +
        //       ((el["suborder"]["suborder_item"] &&
        //         el["suborder"]["suborder_item"][si] &&
        //         el["suborder"]["suborder_item"][si]["quantity"]) ||
        //         "") +
        //       "," +
        //       (el["suborder"]["suborder_item"] &&
        //       el["suborder"]["suborder_item"][si] &&
        //       el["suborder"]["suborder_item"][si]["menu_item_selections"]
        //         .length > 0
        //         ? el["suborder"]["suborder_item"][si]["menu_item_selections"]
        //             .map((i) => i.selection_name)
        //             .join("|")
        //         : "--") +
        //       "" +
        //       "\n";
        //   }
        //   si++;
        //   orders += line;
        //   line = ",,,,,,,,,,,,,,,,,";
        // } while (el["suborder"]["suborder_item"].length > si);
        //Suborder end
      });
      var blob = new Blob([orders], { type: "csv/plain" });
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "orders-" + new Date().getTime() + ".csv";
      link.click();
    },
    updateFilters(filters) {
      this.filters = filters;
    },
    exportAllOrders() {
      let data = {
        search: this.filters.search,
        start_date: this.filters.start_date,
        end_date: this.filters.end_date,
        paginate: false,
        pending_invoice: this.filters.pending_invoice,
        delivery_pickup_types: this.filters.delivery_pickup_types
      };
      orderService
        .exportAllOrders({
          ...data,
        })
        .then((response) => {
          this.orders = response.data.result;
          this.downloadAllCSV();
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    downloadAllCSV: function () {
      let orders = [];
      if (this.$auth.partner_login) {
        if (this.$auth.setting.show_customer_to_vendor == "Yes") {
          orders =
            "\ufeff" +
            "SR,Order ID,Patient Name,Patient Number,Order Date," +
            this.$auth.getRestaurantName() +
            " Name," +
            this.$auth.getRestaurantName() +
            " Number,Payment Method,Order Status,Coupon Code,Sub Total,Delivery Charge,Discount,Tax,Total Amount,Cancel Date,Special Instruction,Driver Name,Delivery Type,Invoice Status,Invoice Date,Order placed by name,Order placed by number\n";
        } else {
          orders =
            "\ufeff" +
            "SR,Order ID,Patient Name,Order Date," +
            this.$auth.getRestaurantName() +
            " Name," +
            this.$auth.getRestaurantName() +
            " Number,Payment Method,Order Status,Coupon Code,Sub Total,Delivery Charge,Discount,Tax,Total Amount,Cancel Date,Special Instruction,Driver Name,Delivery Type,Invoice Status,Invoice Date,Order placed by name,Order placed by number\n";
        }
      } else {
        orders =
          "\ufeff" +
          "SR,Order ID,Patient Name,Patient Number,Account Holder Name,Account Holder Number,Lead Source,Order Date," +
          this.$auth.getRestaurantName() +
          " Name," +
          this.$auth.getRestaurantName() +
          " Number,Payment Method,Order Status,Coupon Code,Sub Total,Delivery Charge,Discount,Tax,Total Amount,Cancel Date,Special Instruction,Driver Name,Delivery Type,Cancellation Description,Customer State,HDP Order,Invoice Status,Invoice Date,Order placed by name,Order placed by number\n";
      }
      this.orders.forEach((el, ind) => {
        let sno = ind + 1;
        let accountholdernumber;
        if (el["account_holder_number"] == "9999999999") {
          accountholdernumber = "-";
        } else {
          accountholdernumber = el["account_holder_number"];
        }
        let Dunzo = el["runner_name"];
        let Driver = el["driver_name"];
        let Dunzotaskid = el["task_id"];
        let text = el["order_id"];
        if (el["sipment1_orderId"]) {
          if (el["is_depot_store"] == 1) {
            text = `Depot ${el["order_id"]} (Sub Order of #${el["sipment1_orderId"]})`;
          }
        }
        if (this.$auth.partner_login) {
          if (this.$auth.setting.show_customer_to_vendor == "Yes") {
            var line =
              sno +
              "," +
              text +
              "," +
              el["patient_name"].split(",").join("") +
              "," +
              el["patient_number"] +
              "," +
              this.moment(el["order_date"]).format("DD-MM-YYYY hh:mm A") +
              "," +
              (el["stores_name"] ? el["stores_name"] : "-") +
              "," +
              (el["store_number"] ? el["store_number"] : "-") +
              "," +
              el["payment_method"] +
              "," +
              el["order_status"] +
              "," +
              (el["coupon_code"] ? el["coupon_code"] : "--") +
              "," +
              el["sub_total"] +
              "," +
              el["delivery_fee"] +
              "," +
              (el["discount_price"]
                ? parseFloat(el["original_price"] - el["discount_price"])
                : "--") +
              "," +
              (el["tax"] ? el["tax"] : "--") +
              "," +
              el["total_amount"] +
              "," +
              (el["cancelled_date"] === "0000-00-00 00:00:00"
                ? "-"
                : this.moment(el["cancelled_date"]).format(
                    "DD-MM-YYYY hh:mm A"
                  )) +
              "," +
              (el["notes"] ? el["notes"] : "--") +
              "," +
              (el["runner_name"]&& Dunzotaskid ? Dunzo : Driver ? Driver : "-") +
              "," +
              el["delivery_pickup_types"] +
              "," +
              (el["order_status"] &&
              el["order_status"].toLowerCase() == "delivered"
                ? el["vendor_invoice"] == null
                  ? "Pending"
                  : "Uploaded"
                : "-") +
              "," +
              (el["order_status"] &&
              el["order_status"].toLowerCase() == "delivered" &&
              el["invoice_date"]
                ? this.moment(el["invoice_date"]).format("DD-MM-YYYY hh:mm A")
                : "-") +
              "," +
              el["order_placed_by_name"] +
              "," +
              el["order_placed_by_number"] +
              "\n";
            orders += line;
          } else {
            var line =
              sno +
              "," +
              text +
              "," +
              el["patient_name"].split(",").join("") +
              "," +
              this.moment(el["order_date"]).format("DD-MM-YYYY hh:mm A") +
              "," +
              (el["stores_name"] ? el["stores_name"] : "-") +
              "," +
              (el["store_number"] ? el["store_number"] : "-") +
              "," +
              el["payment_method"] +
              "," +
              el["order_status"] +
              "," +
              (el["coupon_code"] ? el["coupon_code"] : "--") +
              "," +
              el["sub_total"] +
              "," +
              el["delivery_fee"] +
              "," +
              (el["discount_price"]
                ? parseFloat(el["original_price"] - el["discount_price"])
                : "--") +
              "," +
              (el["tax"] ? el["tax"] : "--") +
              "," +
              el["total_amount"] +
              "," +
              (el["cancelled_date"] === "0000-00-00 00:00:00"
                ? "-"
                : this.moment(el["cancelled_date"]).format(
                    "DD-MM-YYYY hh:mm A"
                  )) +
              "," +
              (el["notes"] ? el["notes"] : "--") +
              "," +
              (el["runner_name"] && Dunzotaskid ? Dunzo : Driver ? Driver : "-") +
              "," +
              el["delivery_pickup_types"] +
              "," +
              (el["order_status"] &&
              el["order_status"].toLowerCase() == "delivered"
                ? el["vendor_invoice"] == null
                  ? "Pending"
                  : "Uploaded"
                : "-") +
              "," +
              (el["order_status"] &&
              el["order_status"].toLowerCase() == "delivered" &&
              el["invoice_date"]
                ? this.moment(el["invoice_date"]).format("DD-MM-YYYY hh:mm A")
                : "-") +
              "," +
              el["order_placed_by_name"] +
              "," +
              el["order_placed_by_number"] +
              "\n";
            orders += line;
          }
        } else {
          var line =
            sno +
            "," +
            text +
            "," +
            el["patient_name"].split(",").join("") +
            "," +
            el["patient_number"] +
            "," +
            el["account_holder_name"] +
            "," +
            accountholdernumber +
            "," +
            (el["source_type"] ? el["source_type"] : "-") +
            "," +
            this.moment(el["order_date"]).format("DD-MM-YYYY hh:mm A") +
            "," +
            (el["stores_name"] ? el["stores_name"] : "-") +
            "," +
            (el["store_number"] ? el["store_number"] : "-") +
            "," +
            el["payment_method"] +
            "," +
            el["order_status"] +
            "," +
            (el["coupon_code"] ? el["coupon_code"] : "--") +
            "," +
            el["sub_total"] +
            "," +
            el["delivery_fee"] +
            "," +
            (el["discount_price"]
              ? parseFloat(el["original_price"] - el["discount_price"])
              : "--") +
            "," +
            (el["tax"] ? el["tax"] : "--") +
            "," +
            el["total_amount"] +
            "," +
            (el["cancelled_date"] === "0000-00-00 00:00:00"
              ? "-"
              : this.moment(el["cancelled_date"]).format(
                  "DD-MM-YYYY hh:mm A"
                )) +
            "," +
            (el["notes"] ? el["notes"] : "--") +
            "," +
            (el["runner_name"] && Dunzotaskid ? Dunzo : Driver ? Driver : "-") +
            "," +
            el["delivery_pickup_types"] +
            "," +
            (el["cancelled_desc"] === null ? "-" : el["cancelled_desc"]) +
            "," +
            el["customer_state"] +
            "," +
            (el["hdp_Id"] ? "Yes" : "No") +
            "," +
            (el["order_status"] &&
            el["order_status"].toLowerCase() == "delivered"
              ? el["vendor_invoice"] == null
                ? "Pending"
                : "Uploaded"
              : "-") +
            "," +
            (el["order_status"] &&
            el["order_status"].toLowerCase() == "delivered" &&
            el["invoice_date"]
              ? this.moment(el["invoice_date"]).format("DD-MM-YYYY hh:mm A")
              : "-") +
            "," +
            el["order_placed_by_name"] +
            "," +
            el["order_placed_by_number"] +
            "\n";
          orders += line;
        }
      });
      var blob = new Blob([orders], { type: "csv/plain" });
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "orders-" + new Date().getTime() + ".csv";
      link.click();
    },
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
        >
          <div class="page-title-left">
            <div class="avatar-xs mr-3">
              <span
                class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18"
              >
                <i class="bx bx-copy-alt"></i>
              </span>
            </div>
            <h4 class="mb-0 font-size-18">
              {{ trans.get("__JSON__.Orders") }}
            </h4>
          </div>
          <div class="page-title-right exportalltitle">
            <button
              @click="exportAllOrders"
              class="btn btn-primary btn-rounded export-btn"
            >
              {{ trans.get("__JSON__.Export To CSV All") }}
            </button>
            <button
              @click="exportAllOrders"
              class="btn btn-primary btn-rounded m-export-btn"
            >
              <i class="bx bx-archive-in"></i>
            </button>
          </div>
          <div class="page-title-right">
            <!-- <b-spinner v-if="loading" class="m-2" variant="primary" role="status"></b-spinner> -->
            <button
              @click="exportOrders"
              class="btn btn-primary btn-rounded export-btn"
            >
              {{ trans.get("__JSON__.Export To CSV") }}
            </button>
            <button
              @click="exportOrders"
              class="btn btn-primary btn-rounded m-export-btn"
            >
              <i class="bx bx-archive-in"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <OrderList @filterChange="updateFilters" />
  </Layout>
</template>

<style>
.exportalltitle {
  /* Default margin for most resolutions */
  margin-left: 65rem;
}

/* Adjust margin for 150% resolution */
@media only screen and (max-width: 1440px) {
  .exportalltitle {
    margin-left: 49rem; /* Your adjusted margin value for 150% resolution */
  }
}
</style>
