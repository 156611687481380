<script>
import { FormWizard, TabContent } from "vue-form-wizard";

import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import { callCenterDashboardService, customizationService, promoCodeService } from "../../services";
import { BIcon } from 'bootstrap-vue';
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import MenuList from "./menu";
import OrderSummary from "./order-summary"
import { vendorService } from "../../services";
import draggable from "vuedraggable";
import pagination from "laravel-vue-pagination";
import config from "../../config";
import { required,numeric, minLength, } from "vuelidate/lib/validators";
import { error_message,success_message,get_currency, get_decimal } from "../../Helper/helper";
import Swal from "sweetalert2";
import CustomerTab from './customer-tab.vue';
import moment from 'moment';
import { debounce } from './../../Helper/helper';
/**
 * Form wizard component
 */
export default {
  components: { 
    CustomerTab,
    Layout, 
    PageHeader,
    VueFormWizard: FormWizard, 
    TabContent, VueGoogleAutocomplete, 
    MenuList, 
    OrderSummary, 
    draggable,
    pagination ,
    DatePicker,
    BIcon
  },
  data() {
    return {
        minSelectionVal: [],
        center: { lat: 0.00, lng: 0.00 },
        marker: null,
        typeform:{
            address: '',
            latitude: '',
            longitude: '',
            address_clarification: 'Home',
            house_name: '',
            flat_no: '',
            landmark: '',
            city: '',
            state: '',
            zip: ''
        },
        limit:2,
        mobile_number: '',
        name: '',
        restaurants: [],
        restaurants_id: "",
        category_count:'',
        category_id: '',
        category : [],
        menu_id: 1,
        menu: [],
        search: '',
        loading: true,
        selection: '',
        item_count: '',
        restaurant_menu_id: '',
        currency: get_currency(),
        decimal: 2,
        timer: '',
        config: config,
        addNewAddress: false,
        checksNumber: '',
        emails: '',
        user_id: '',
        user: null,
        customerAddress: [],
        addreses: '',
        menuModal: false,
        items: null,
        cart: [],
        selectedCustomisation: [],
        isPlaceOrder: false,
        custItemTotal:0,
        selected_item: null,
        special_instruction: '',
        service_fee: 0,
        future_delivery_date: '',
        scheduled: false,
        restaurant: null,
        moment:moment,
        schedule_date: null,
        searchData:debounce(()=>{
          this.listitembyid(1);
        },500),
        promocode: '',
        promocodeDetail:null,
        promo_loading:false,
        promoApplied: false,
        address: null
    };
  },
  validations: {
    mobile_number: {
        required,
        numeric
    },
    typeform: {
      address: {
        required,
      },
      house_name: {
        required,
      },
      flat_no: {
        required,
        numeric,
      },
    },
    
  },
  created (){
    // this.getMenuCategories();
    // this.timer = setInterval(this.getMenuCategories, 20000);
    // this.getmenu();
    // this.timer = setInterval(this.getmenu, 20000);
  },
  mounted(){
    // 
  },
  methods:{
    disabledTime(date){
      // console.log(date)
      let cdate = this.moment(date);  
      let start_time = this.moment( cdate.format('YYYY-MM-DD')+' '+ this.$auth.setting.system_start_time.split(' ')[1]);
      let end_time = this.moment( cdate.format('YYYY-MM-DD')+' '+ this.$auth.setting.system_end_time.split(' ')[1]);
      if(!cdate.isBetween(this.moment().startOf('day'),this.moment().add(3,'days').startOf('day'), undefined, '[]')){
        return true;
      }
      if(cdate.format('YYYY-MM-DD') == this.moment().format('YYYY-MM-DD')){
        return !cdate.isBetween(this.moment(),end_time, undefined, '[]');
      }
      return !cdate.isBetween(start_time,end_time, undefined, '[]');
    },
    disabledDate(date){
      let cdate = this.moment(date);
      // let start_time = this.moment( cdate.format('YYYY-MM-DD')+' '+ this.$auth.setting.system_start_time.split(' ')[1]);
      // let end_time = this.moment( cdate.format('YYYY-MM-DD')+' '+ this.$auth.setting.system_end_time.split(' ')[1]);
      if(!cdate.isBetween(this.moment().startOf('day'),this.moment().add(3,'days').startOf('day'), undefined, '[]')){
        return true;
      }
      return false;
    },
    cartQty(item){
        if(this.cart) {
          return this.cart.reduce((ac,ct) => (item.restaurant_menu_item_id == ct.item_id?(ac + ct.qty ):ac),0)          
        }
        return 0;
    },
    total(){
        let total = 0;
        for (let item in this.cart) {
          total = total + this.cart[item].qty * this.cart[item].item_total
        }
        return total;
    },
    // calculation tax
    getTax(){
      if(!this.restaurant){
        return false;
      }
      const { included_tax, restaurant_service_tax } = this.$auth.setting;
      const { restaurant_tax } = this.restaurant;
      const total = parseFloat(this.total() || 0);
      // check tax is No or Yes
      switch(included_tax){
        case 'No':
          return 0;
        case 'Yes':
          if(restaurant_tax > 0){
            return (total * parseInt(restaurant_tax) / 100).toFixed(this.decimal);
          }
          return (total * parseInt(restaurant_service_tax) / 100).toFixed(this.decimal);

      }
    },
    getDeliveryCharge(){
      const { delivery_charges_type, charges }= this.$auth.setting;
      const total = this.total();
      if(!this.restaurant || !charges){
        return 0;
      }

      if(this.user.delivery_type == 'Delivery'){
        if(delivery_charges_type == 'Amount'){
          const charge = charges && charges.reduce((a,c) => parseFloat(total) >= parseFloat(c.min_value) && parseFloat(total) <= parseFloat(c.max_value) ? c.delivery_charges : a,0 );
          if(charge == 0){
            return charges[0].delivery_charges;
          }
          return charge;
        }
        else if(delivery_charges_type == 'Distance'){
          const charge = charges && charges.reduce((a,c) => parseFloat(this.restaurant.distance) >= parseFloat(c.min_value) && parseFloat(this.restaurant.distance) <= parseFloat(c.max_value) ? c.delivery_charges : a,0 );
          if(!charge || charge == 0){
            return charges[0].delivery_charges;
          }
          return charge;
        }
      }
      return 0;
    },

    originalPrice(){
      let total = this.total()
      return parseFloat(parseFloat(total)  + parseFloat(this.getTax()) + parseFloat(this.getDeliveryCharge()) ).toFixed(this.decimal); 
    },
    totalAmount(){
      let originalPrice = this.originalPrice();
      return originalPrice;
    },  
    discountAmount(){
      let originalPrice = this.originalPrice();
      return (originalPrice - this.promocodeDetail.discount);
    },
    getWalletAmount(){
      return 0;
    },
    removeItem(id){
      this.cart.splice(id,1);
      if(this.promoApplied){
        this.removePromocode()
      }
    },
    addCart(item,id) {
      if(this.promoApplied){
        this.removePromocode()
      }
      let c = this.cart.find(ct => ct.item_id == item.restaurant_menu_item_id);
      if(!c){
        item.item_total = item.price;
        const { restaurant_menu_item_id,item_name, packaging_charge, price, mrp, item_total } = item;
        let cartItem = { item_id: restaurant_menu_item_id, packaging_charge, amount: price, qty:1, item_name, mrp,item_total };
        this.cart.push({...cartItem});
      }else{
        c.qty = c.qty + 1;
        c.total = c.qty * c.item_total;
        let cart = this.cart.filter(ct => ct.item_id != item.restaurant_menu_item_id);
        c.amount = c.qty * c.item_total;
        c.packaging_charge = item.packaging_charge;
        this.cart = [ 
          ...cart,{...c}
        ];
      }
    },
    lessCart(id) {
      if(this.promoApplied){
        this.removePromocode()
      }
      let c = this.cart.findIndex(ct => ct.item_id == id);
      if(c >= 0){
        if(this.cart[c].qty > 1) {
          this.cart[c].qty--;
        }else{
          this.cart.splice(c,1);
        }
      }
    },
    deliveryFee(){
        return 10;
    },
    serviceFee(){
        return this.service_fee;
    },
    mrpPrice(item){
      return parseFloat((parseInt(item.qty) * this.getSelectionPrices(item.multiple_selection_new)) + (parseInt(item.qty) * parseFloat(item.mrp || 0)))
    },
    totalPrice(item){
      // return parseFloat((parseInt(item.qty) * this.getSelectionPrices(item.multiple_selection_new)) + (parseInt(item.qty) * parseFloat(item.item_total || 0)))
      return (parseInt(item.qty) * parseFloat(item.item_total || 0));
    },
    getSelections(item){
      return item && item.length > 0 ? item.reduce((accu, item) => {
        return accu.concat(item.name +' x '+ parseFloat(item.price).toFixed(this.decimal));
      },[]).join(', '): '';
    },
    getSelectionPrices(items){
      return parseFloat(items && items.data.length > 0 ? items.data.reduce(function(accu, item){
        return accu + parseFloat(item.price);
      },0): 0);
    },
    locateAddress(lat, lng) {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ location: { lat, lng } }, (results, status) => {
        if (status === "OK") {
          this.typeform.address = results[0].formatted_address;
          // this.address = results[0].formatted_address;
          var value = this.typeform.address.split(", ");
          var value1 = this.typeform.address.split(" ");
          var count = value.length;
          var count1 = value1.length;
          this.typeform.state = value1[count1 - 3];
          this.typeform.city = value[count - 3];
          this.typeform.zip = value1[count1 - 2];
          this.typeform.latitude = this.marker.position.lat;
          this.typeform.longitude = this.marker.position.lng;
        }
      });
    },
    userCreate(){
      callCenterDashboardService.userCreate({
        user_details: this.user,
        address: this.address
      }).then(response => {
        if(response.data.result){
          this.user_id = response.data.result.user_id 
          if(this.address && this.address){
            this.address.id = response.data.result.address_id || ''
          }
          this.placeOrderApi()
        }
      })
    },
    createOrder(){
      if (this.user_id) { 
        this.placeOrderApi();
      } else {
        this.userCreate();
      }
    },


    placeOrderApi(){
        if(!this.cart || this.cart.length == 0){
          alert('Please add item in cart');
          return false;
        }
        this.isPlaceOrder = true;
        let cart = JSON.parse(JSON.stringify(this.cart));
        cart.forEach((c,index) => {
          cart[index].amount = cart[index].item_total * cart[index].qty;
          cart[index].multiple_selection_new = JSON.stringify(cart[index].multiple_selection_new);
          if(this.cart[index].multiple_selection_new){
            cart[index].multiple_selection = this.cart[index].multiple_selection_new
              .reduce((a,i,ind) => (ind !==0 ? a+',' : '') + i.id 
                + ( i.selectedSubCust && i.selectedSubCust.reduce((aa,bb,cc)=> aa 
                + ',' + bb.menu_item_selection_id,'') || ''),'')
          }
          // console.log(cart[index].multiple_selection);
          cart[index].quantity = JSON.stringify(cart[index].qty);
        })
        const fd = new FormData();
        fd.append('restaurant_service_fee',0);
        fd.append('original_price', this.originalPrice());
        fd.append('restaurant_id', this.restaurants_id);
        fd.append('device_type', 'CALL-CENTER');
        fd.append('is_langauge', 'en');
        fd.append('contact_less_delivery', '0');
        fd.append('delivery_fee', this.getDeliveryCharge());
        fd.append('delivery_pickup_types', this.user.delivery_type);
        fd.append('delivery_type', '0');
        fd.append('payment_method', 'Cash On Delivery');
        fd.append('braveges_amount','0');
        fd.append('address_id',(this.user.delivery_type == 'Delivery'?this.address.id:''));
        fd.append('user_id',this.user_id);
        fd.append('driver_tip','0');
        fd.append('wallet_amount',this.getWalletAmount());
        fd.append('total_amount',parseFloat(this.promoApplied ? this.discountAmount() : this.totalAmount() || 0));
        fd.append('vendor_id',this.$auth.getVendorId());
        fd.append('user_access_id',this.$auth.user.vendor_id);
        fd.append('service_fee',this.getTax());
        fd.append('sub_total',this.total());
        fd.append('notes',this.special_instruction?this.special_instruction:'');
        fd.append('suggestion','');
        // fd.append('total_packaging_charge',this.getPackagingCharge());
        fd.append('future_delivery_date',this.future_delivery_date);
        fd.append('restaurant_item_details',JSON.stringify(cart));
        if(this.promoApplied){
          fd.append('coupon_id',this.promocodeDetail.coupon_id);
          fd.append('coupon_redeem',this.promocodeDetail.coupon_code);
          fd.append('discount_price',this.discountAmount());
          fd.append('cashback',this.promocodeDetail.cashback);
        }
        callCenterDashboardService.placeOrderApi(fd).then((response)=>{
              this.isPlaceOrder = false;
              if(response.data.code == 200){
                  Swal.fire(response.data.msg, "You order no # "+ response.data.Result +"", "success")
                    .then((result) => {
                        this.$router.push({name:'orders-list'})
                    });
                  this.restaurants_id = '';
                  this.mobile_number = '';
                  this.name = '';
                  this.emails = '';
                  this.customerAddress = '';
                  this.cart = [];
                  // Swal.fire(response.data.message,"success");
                  // success_message(response.data.message);
              }else{
                alert(response.data.msg)
              }
        })
    },
    addressChanged(address){
      this.address = address;
    },
    getRestaurant(restaurants){
      this.restaurants = restaurants;
    },
    changeItem(restaurant_id) {
      this.restaurants_id = restaurant_id;
      this.selected = "id:, target.value: " + this.restaurants_id;
      
      let services_fee = this.$auth.setting.restaurant_service_tax;
      this.restaurant =  this.restaurants.find(i => i.restaurant_id == this.restaurants_id);
      if(services_fee == '0'){
        //  let options = event.target.options
        //  if (options.selectedIndex > -1) {
        //  let services_fee = options[options.selectedIndex].getAttribute('service_fee');
        this.service_fee = this.restaurant && this.restaurant.service_fee || 0;
        //  }
      }
      this.getmenu()
      this.cart=[]
    },

    changeMenu(id){
      this.menu_id = id;
      this.restaurant_menu_id = id;
      this.listitembyid(1);
    },
    listitembyid(page=1) {
        this.loading = true;
        vendorService.getItemByMenuID({ 
          menu_id: this.menu_id,
          search: this.search,
          page: page && page > 0 ? page : 1,
          restaurant_id: btoa(this.restaurants_id),
          category_id : this.category_id
        }).then((response) => {
          this.loading = false;
          this.item_count = response.data.result.get_menus_item.data.length;
          this.items = response.data.result.get_menus_item;
          this.items.data.map(function(x) {
                    x.qty = 0;
                    return x;
          });
          this.menu = response.data.result.get_restaurant_menu;
          // console.log(this.menu_id);
          if(response.data.result.get_restaurant_menu.length > 0 && !response.data.result.get_restaurant_menu.some(m => m.id == this.menu_id && m.item_count > 0)){
            let menudata = this.menu.find(m => m.item_count > 0);
            this.menu_id = menudata.id;
            this.restaurant_menu_id = menudata.id;
            this.listitembyid(1);
          }
        });
    },
    getmenu() {
      if(!this.restaurants_id){
        return false;
      }
      vendorService
      .getRestaurantMenu({ restaurant_id: btoa(this.restaurants_id), category_id : this.category_id })
      .then((response) => {
        this.menu = response.data.result;
        if( response.data.result.length > 0){
          this.menu_id = this.menu[0].id;
          this.restaurant_menu_id = this.menu[0].id;
          this.listitembyid();
        }else{
          this.menu_id = '';
          this.listitembyid();
        }
      });
    },
    show(item) {
      // // this.custModal == true;
      // var show = document.getElementById(id);
      // // show.style.display = "block";
      // this.$bvModal.show(show);
      // console.log('@show')
      if(item.customization.length == 0  || (item.customization.length > 0 && !item.customization.some(c => c.menu_customization.length > 0))){
        this.addCart(item);
      }else{
        this.selected_item = {
          ...item,
          qty: 1
        };
        this.selected_item.customization.forEach((item,index) => {
          this.selected_item.customization[index].selectedCustomisation = this.selected_item.customization[index].is_selection > 1?[]: null;
          this.selected_item.customization[index].menu_customization.forEach((it,ind) => {
            this.selected_item.customization[index].menu_customization[ind].selectedSubCust = [];
          }) 
        });
        setTimeout(()=>{
          this.$bvModal.show('popup_item');
        },300)
      }
    },
    hide(event, id,item) {

      if(!item.customization.every(itc => itc.selectedCustomisation !== null 
        && (itc.is_selection > 1 
          ? itc.selectedCustomisation.length >= itc.is_min_selection 
            && itc.selectedCustomisation.length <= parseInt(itc.is_selection) 
          : [itc.selectedCustomisation].length == itc.is_selection)
          && (itc.menu_customization.length == 0 || (itc.is_selection > 1 ? itc.selectedCustomisation : [] ).every(mc => 
          mc.selectedSubCust.length >= mc.is_min_selection && mc.selectedSubCust.length <= mc.is_selection)) 
          || (itc.is_selection == 0 && itc.is_min_selection == 0)
        )
        
      ){
        alert('Please select customization as per min max selection');
        return false;
      }

      this.minSelectionVal = [];
      var cart_id = item.restaurant_menu_item_id;
      const { restaurant_menu_item_id, item_name, packaging_charge, price,mrp,item_total,qty } = item;
      var cartItem = { item_id: restaurant_menu_item_id, packaging_charge, amount: price, qty,item_name,mrp,item_total };
      cartItem.multiple_selection_new = []
      let temp;
      item.customization.map(cu => {
        if(Array.isArray(cu.selectedCustomisation)){
          temp = cu.selectedCustomisation.map(p => {
            cart_id = cart_id+"."+p.menu_item_selection_id;
            p.selectedSubCust.forEach(ssc => {
              cart_id = cart_id+"."+ssc.menu_item_selection_id;
            })
            let sub_price = p.selectedSubCust.reduce((a,b) => a + parseFloat(b.selection_price),0)
            return ({
              id : p.menu_item_selection_id,
              price : p.selection_price + sub_price,
              name : p.selection_name,
              packaging_charge : p.packaging_charge,
              selectedSubCust : p.selectedSubCust,
            });
          });
          if(temp.length > 0){
            cartItem.multiple_selection_new = [...cartItem.multiple_selection_new.concat(temp)];
          }
        }else{
          if(cu.selectedCustomisation){
            let sub_price = cu.selectedCustomisation.selectedSubCust.reduce((a,b) => a + parseFloat(b.selection_price),0)
            temp = {
              id : cu.selectedCustomisation.menu_item_selection_id,
              price : cu.selectedCustomisation.selection_price + sub_price,
              name : cu.selectedCustomisation.selection_name,
              packaging_charge : cu.selectedCustomisation.packaging_charge,
              selectedSubCust : cu.selectedSubCust,
            }
            cartItem.multiple_selection_new.push(temp);
            cart_id = cart_id+"."+cu.selectedCustomisation.menu_item_selection_id;
            cu.selectedCustomisation.selectedSubCust.forEach(ssc => {
              cart_id = cart_id+"."+ssc.menu_item_selection_id;
            })
          }
        }
      })
      cartItem.item_total = cartItem.amount + (cartItem.multiple_selection_new && cartItem.multiple_selection_new.length > 0 ? cartItem.multiple_selection_new.reduce((a,u) => a+u.price,0) : 0);
      cartItem.cart_id = cart_id;
      let cIndex = this.cart.findIndex(c => c.cart_id == cart_id);
      if(cIndex > -1){
        this.cart[cIndex].qty++; 
      } else {
        // cartItem.qty = 1
        this.cart = [...this.cart,cartItem];
      }

      this.$bvModal.hide(id);
      this.selected_item = null;
    },
    scheduledOrder(){
      if(this.scheduled){
        this.future_delivery_date = '';
      }
    },
    getMenuCategories() {
      vendorService
        .getMenuCategories({ restaurant_id: this.restaurants_id })
        .then((response) => {
          this.category_count = response.data.result.length;
          this.category = response.data.result;
        });
    },
    beforeDestroy () {
      clearInterval(this.timer);
    },
    getUser(user){
      this.user_id = user.user_id;
      this.user = user;
    },
    formatDate(){
      this.future_delivery_date = this.moment(this.schedule_date).format('YYYY-MM-DD HH:mm:ss')
    },
    validateStep(name) {
      var refToValidate = this.$refs[name];
      return refToValidate.validate();
    },
    removePromocode(){
      this.promoApplied = false;
      this.promocode = '';
      this.promocodeDetail = null;
      error_message('Coupon Removed.');
    },
    custQty(sign){
      let qty = this.selected_item.qty;
      if(sign == '+'){
        qty++;
      }else{
        if(this.selected_item.qty != 0){
          qty--;
        }
      }
      this.selected_item = {
        ...this.selected_item,
        qty
      }

    },
    applyPromoCode(){
      if(this.promoApplied){
        this.removePromocode()
        return false;
      }
      let postData = {
        is_language : this.$auth.setting.primary_lang,
        vendor_id : this.$auth.getVendorId(),
        user_id : this.user_id,
        restaurant_id : this.restaurant.restaurant_id,
        coupon_code : this.promocode,
        original_price : this.totalAmount(),
      }
      this.promoApplied = false;
      this.promo_loading = true;
      promoCodeService.applyPromoCode(postData)
        .then((response)=>{
          this.promo_loading = false;
          if(response.data.code == 200){
            const { id, coupon_code, promo_code_type, flat_discount, discount_percentage,maximum_discount_amount } = response.data.Result;
            this.promocodeDetail = { coupon_id: id, coupon_code, promo_code_type,  discount_percentage, flat_discount,maximum_discount_amount };
            let sub_total = this.total();
            var discounted_amount = 0
            var cashback = 0
            if(promo_code_type == 1){
              discounted_amount = (sub_total * discount_percentage / 100);
            }
            else if(promo_code_type == 2){
              discounted_amount = (flat_discount);
            }else if(promo_code_type == 3){
              cashback = (sub_total > cashback?cashback:sub_total);
            }else if(promo_code_type == 4){
              cashback = (sub_total * discount_percentage / 100);
            }
            discounted_amount = maximum_discount_amount > discounted_amount ? discounted_amount : maximum_discount_amount;
            cashback = maximum_discount_amount > cashback ? cashback : maximum_discount_amount;
            this.promocodeDetail.discount = parseFloat(discounted_amount);
            this.promocodeDetail.cashback = parseFloat(cashback);
            success_message('Coupon Applied');
            this.promoApplied = true;
            return false;
          }
          this.$refs.promocode.focus();
          error_message('Invalid coupon code');
        })

    },
    tabChanged(prevIndex,nextIndex){
      if(nextIndex == 2){
        setTimeout(() => this.$refs.promocode.focus(), 500);
        
      }
    },
    subCustomizationChange(event,selc,cesIndex,optIndex){
      // console.log(cesIndex);
      let val = selc;
      // console.log(val);
      if(event.target.checked){
        let mIndex;
        var temp;
        if(Array.isArray(this.selected_item.customization[cesIndex]
            .selectedCustomisation)){
            mIndex = this.selected_item.customization[cesIndex]
              .selectedCustomisation.findIndex(b=>b.menu_item_selection_id == val.parent_item_id)
            console.log(mIndex)
            temp = {...this.selected_item.customization[cesIndex]
              .selectedCustomisation[mIndex]};
        }else{
          temp = this.selected_item.customization[cesIndex].selectedCustomisation
        }
          
        if(temp.is_min_selection <= temp.is_selection && temp.selectedSubCust.length == temp.is_selection){
            event.target.checked = false;
            alert('Maximum ' + temp.is_selection + ' item selectable');
            return false;
        }
        
        this.selected_item = {...this.selected_item}

        if(Array.isArray(this.selected_item.customization[cesIndex]
          .selectedCustomisation)){
            this.selected_item.customization[cesIndex]
              .selectedCustomisation[mIndex].selectedSubCust = [
                ...(this.selected_item.customization[cesIndex]
                  .selectedCustomisation[mIndex].selectedSubCust || []),
                val
              ]
          }else{
            this.selected_item.customization[cesIndex]
              .selectedCustomisation.selectedSubCust = [
                ...(this.selected_item.customization[cesIndex]
                  .selectedCustomisation.selectedSubCust || []),
                val
              ]
          }
      }else{
        let mIndex;
        let nIndex;
        this.selected_item = {...this.selected_item}
        if(Array.isArray(this.selected_item.customization[cesIndex]
          .selectedCustomisation)){
          mIndex = this.selected_item.customization[cesIndex]
            .selectedCustomisation.findIndex(b=>b.menu_item_selection_id == val.parent_item_id)
          nIndex = this.selected_item.customization[cesIndex]
            .selectedCustomisation[mIndex]
            .selectedSubCust.findIndex(c => c.menu_item_selection_id == val.menu_item_selection_id);
          this.selected_item.customization[cesIndex]
            .selectedCustomisation[mIndex]
            .selectedSubCust.splice(nIndex,1);
        }else{
          nIndex = this.selected_item.customization[cesIndex]
            .selectedCustomisation
            .selectedSubCust.findIndex(c => c.menu_item_selection_id == val.menu_item_selection_id);
          this.selected_item.customization[cesIndex]
            .selectedCustomisation
            .selectedSubCust.splice(nIndex,1);
        }
      }
    },
    customizationChange(event,ces,cesIndex,aesIndex){
      if(!event.target.checked){
        this.selected_item.customization[cesIndex].menu_customization[aesIndex].selectedSubCust = []
      }
      this.selected_item = { 
        ...this.selected_item,
        customization: [
          ...this.selected_item.customization
        ]
      }
    }
  },
};
</script>

<template>
  <Layout>
  <div>
     <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
        >
          <div class="page-title-left">
            <div class="avatar-xs mr-3">
              <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                <i class="bx bx-phone-call"></i>
              </span>
            </div>
             <h4 class="mb-0 font-size-18">{{ trans.trans('__JSON__.Call Center Dashboard') }}</h4>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
    <div class="row">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <vue-form-wizard shape="tab" color="#556ee6" ref="wizard" @on-change="tabChanged">
              <tab-content icon="mdi mdi-account-circle" title="Customer Details" :before-change="()=>validateStep('customerTab')">
                <customer-tab ref="customerTab" @onGetUser="getUser" @onRestaurantSelect="changeItem" @onGetRestaurant="getRestaurant" @addressChanged="addressChanged"></customer-tab>
              </tab-content>
              <b-button slot="next" class="finish-order-btn" >{{ trans.get('__JSON__.Next') }}</b-button>
              <tab-content icon="mdi mdi-food" :title="trans.get('__JSON__.Menu')">
                <div class="row">
                  <div class="col-12">
                   <div class="drag-container">
                      <div class="row drag-list menu-left-main">
                        <div class="col-lg-3 col-sm-6 drag-column menu-box-main">
                          <b-list-group>
                            <b-list-group-item key="0">
                              <p class="h5">{{trans.get('__JSON__.Menu')}}</p>
                            </b-list-group-item>       
                            <template v-for="menus in menu" >
                              <b-list-group-item 
                                class="d-flex justify-content-between align-items-center"
                                :key="menus.id"
                                :active="menu_id === menus.id"
                                v-on:click="changeMenu(menus.id)"
                                v-if="(search !== '' && menus.item_count !== 0) || (search == '')">
                                {{menus.menu_name}} <b-badge variant="primary" pill>{{menus.item_count}}</b-badge>
                              </b-list-group-item>
                            </template>
                            <!-- <b-list-group-item>Morbi leo risus</b-list-group-item>
                            <b-list-group-item>Porta ac consectetur ac</b-list-group-item>
                            <b-list-group-item>Vestibulum at eros</b-list-group-item> -->
                          </b-list-group>
                          
                        </div>
                        <!-- end col-->

                        <div class="col-lg-5 col-sm-6 drag-column">
                          <b-list-group>
                            <b-list-group-item key="0" class="align-items-center d-flex justify-content-between">
                              <span class="font-size-15 font-weight-medium">{{trans.get('__JSON__.Items')}}</span>
                              <div class="d-inline-block search-box">
                                <div class="position-relative">
                                  <input type="text" @keyup="searchData()" class="form-control" id="search" v-model="search" :placeholder="this.trans.get('__JSON__.Search')" />
                                  <i class="bx bx-search-alt search-icon"></i>
                                </div>
                              </div>
                            </b-list-group-item>
                            <template v-if="items && items.total != 0 && !loading">
                              <template v-for="(item) in items && items.data || []" >
                                <b-list-group-item 
                                  :key="item.restaurant_menu_item_id">

                                  <div class="d-flex justify-content-between">
                                    <!-- <div v-if="item.item_image.length > 0">
                                      <img class="vendor-img avatar-xs" :src="`${item.item_image[0].item_image_path}`" alt height="30" @error="$event.target.src=config.no_image"/>
                                    </div>
                                    <div v-else>
                                      <img class="vendor-img avatar-xs" :src="config.no_image" alt />
                                    </div> -->
                                    <div class="d-flex">
                                      <div>
                                        <div v-if="item.item_type == '0'" class="position-static veg"><span></span></div>
                                        <div v-if="item.item_type == '1'" class="position-static non-veg"><span></span></div>
                                      </div>
                                      <div>
                                        <div class="d-flex">
                                          <span class="font-size-13 font-weight-medium pl-1" :title="item.item_name">{{item.item_name | truncate(35, '...')}} </span>
                                          <span v-b-tooltip.hover.v-light :title="item.item_description" >
                                              <i class="bx bx-info-circle"></i>
                                          </span>
                                          <div>
                                            <span v-if="item.customization.length != 0 && item.customization.some(c => c.menu_customization.length > 0)" class="badge badge-pill font-size-12 ml-2 badge-soft-primary">{{trans.get('__JSON__.Customizable')}}</span>
                                          </div>
                                        </div>
                                        <span class="font-size-11 pl-1 text-secondary">Qty : {{item.quantity}} | <span v-html="currency"></span> {{parseFloat(item.price || 0).toFixed(decimal)}}</span>
                                        <b-badge variant="danger" v-if="item.tags && JSON.parse(item.tags).includes('SPICY')">{{ JSON.parse(item.tags)[0] }}</b-badge>
                                      </div>
                                    </div>
                                    <div>
                                      <div class="cst-swtch-set">
                                        <div class="list-available d-flex item-new-add" v-if="cartQty(item) == '0' && item.customization.length != 0" @click="show(item)">
                                          <button :disabled="item.quantity == 0" class="px-4" :class="{'qty-item-add': item.quantity != 0,'btn btn-secondary': item.quantity == 0 }">{{trans.get('__JSON__.Add')}}</button>
                                        </div>
                                        <div class="list-available d-flex item-new-add" v-else-if="cartQty(item) == '0'" @click="show(item)">
                                          <button :disabled="item.quantity == 0" class="px-4" :class="{'qty-item-add': item.quantity != 0,'btn btn-secondary': item.quantity == 0 }">{{trans.get('__JSON__.Add')}}</button>
                                        </div>
                                        <div class="list-available d-flex item-new-add" v-else>
                                          <span class="minus d-flex justify-content-center align-items-center" 
                                          v-show="item.count > 0" @click="lessCart(item.restaurant_menu_item_id)">-</span>
                                          <span class="qty-item">{{cartQty(item)}}</span>
                                          <span class="d-flex justify-content-center align-items-center" v-if="item.customization.length != 0"
                                          @click="show(item)">+</span>
                                          <span v-else class="d-flex justify-content-center align-items-center" 
                                          @click="show(item)">+</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </b-list-group-item>
                              </template>
                            </template>
                            <div v-else-if="loading" class="text-center text-danger my-2 pt-5">
                              <b-spinner class="align-middle"></b-spinner>
                              <strong>{{trans.get('__JSON__.Loading...')}}</strong>
                            </div>
                            <b-list-group-item v-else key="abc" class="align-items-center d-flex justify-content-between">
                              {{trans.get('__JSON__.No menu found')}}
                            </b-list-group-item>

                            <!-- <b-list-group-item>Morbi leo risus</b-list-group-item>
                            <b-list-group-item>Porta ac consectetur ac</b-list-group-item>
                            <b-list-group-item>Vestibulum at eros</b-list-group-item> -->
                          </b-list-group>
                          
                          <div class="row mt-2"  v-if="!loading">
                            <div class="col">
                              <div class="dataTables_paginate paging_simple_numbers float-right" v-if="items && items.data">
                                <ul class="pagination pagination-rounded mb-0">
                                    <pagination :data="items" :limit="limit" @pagination-change-page="listitembyid"></pagination>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <b-modal v-if="selected_item" id="popup_item" centered scrollable>
                                  <template #modal-header>
                                    <div>
                                    <h5>{{ selected_item.item_name }}</h5>
                                    </div>
                                    <div>
                                    <button type="button" aria-label="Close" class="close" @click="$bvModal.hide('popup_item')">×</button>
                                    </div>
                                  </template>
                                  <div class="product-content pt-3">
                                    <div class="row">
                                      <div
                                        class="col-12 pro-popup-content"
                                        v-for="(ces,cesIndex) in selected_item.customization"
                                        :key="ces.customize_type_id"
                                      >
                                        <!-- :id="ces.$model.customize_type_id" -->
                                      <div class="row">
                                        <div class="col-sm-6">
                                          <h5>
                                            {{ ces.type_name }}
                                            <!-- ({{ ces.option.map((i)=>i.selection_name).join(',') }}) -->
                                          </h5>
                                        </div>
                                        <div class="col-sm-6">
                                          <p v-if="ces.is_selection == ces.is_min_selection" class="text-right">{{ trans.get('__JSON__.Minimum Items',{attribute: ces.is_min_selection}) }}</p>
                                          <p v-else class="text-right">{{ trans.get('__JSON__.Minimum Items',{attribute: ces.is_min_selection+' - '+ces.is_selection}) }}</p>
                                        </div>
                                      </div>
                                        <div class="w-100">
                                          <div
                                            class="opt-main align-items-center w-100"
                                            v-for="(opt,optIndex) in ces.menu_customization"
                                            :key="opt.menu_item_selection_id"
                                          > 
                                            <div class="d-flex w-100 py-2"  >
                                              <label :for="'optionsRadios'+opt.menu_item_selection_id" class="m-0 opt_selection font-weight-normal">{{ opt.selection_name }}</label>
                                              <label :for="'optionsRadios'+opt.menu_item_selection_id" class="m-0 font-weight-normal"><span v-html="currency"></span> {{ opt.selection_price }}</label>
                                              <span class="check_new_item" v-if="ces.is_selection <= '1'">
                                                <input type="radio"  :name="'optionsRadios'+ces.customize_type_id" :value="opt"
                                                :id="'optionsRadios'+opt.menu_item_selection_id"
                                                v-model="ces.selectedCustomisation" 
                                                @change="customizationChange($event, opt,cesIndex,optIndex)"
                                                />
                                                <label class="radio" :for="'optionsRadios'+opt.menu_item_selection_id"></label>
                                              </span>
                                                <!-- :checked="opt.is_default == '1'" -->
                                              <span v-else class="check_new_item">
                                                <input type="checkbox" name="checkBox" :value="opt"
                                                  :id="'checkBox1'+opt.menu_item_selection_id" 
                                                  v-model="ces.selectedCustomisation"
                                                  @change="customizationChange($event, opt,cesIndex,optIndex)"
                                                />
                                                <!-- v-on:change="onchange($event,opt)" -->
                                                <label class="radio" :for="'checkBox1'+opt.menu_item_selection_id"></label>
                                              </span>
                                            </div>
                                            <div class="border border-primary p-2" v-if="opt.sub_customization.length > 0 && 
                                             (ces.is_selection > '1' && ces.selectedCustomisation && ces.selectedCustomisation.map(se => se.menu_item_selection_id).includes(opt.menu_item_selection_id)
                                              || (ces.selectedCustomisation && ces.selectedCustomisation.menu_item_selection_id == opt.menu_item_selection_id))
                                              " >
                                              <div class="d-flex justify-content-end">
                                                <p v-if="opt.is_selection == opt.is_min_selection "
                                                  :class="{'text-danger': opt.is_selection != opt.selectedSubCust.length}"
                                                 class="text-right">{{ trans.get('__JSON__.Minimum Items',{attribute: opt.is_min_selection}) }}</p>
                                                <p v-else 
                                                :class="{'text-danger': (opt.is_selection < opt.selectedSubCust.length || opt.is_min_selection > opt.selectedSubCust.length )}"
                                                class="text-right">{{ trans.get('__JSON__.Minimum Items',{attribute: (opt.is_selection > opt.is_min_selection ? opt.is_selection : opt.is_min_selection) +' - '+opt.is_selection}) }}</p>
                                              </div>
                                              <div class="d-flex w-100 " v-for="selc in opt.sub_customization" :key="selc.menu_item_selection_id"> 
                                                <label :for="'optionsRadios_sub_'+selc.menu_item_selection_id" class="mb-0 opt_selection font-weight-normal">{{ selc.selection_name }}</label>
                                                <label :for="'optionsRadios_sub_'+selc.menu_item_selection_id" class="mb-0 font-weight-normal"><span v-html="currency"></span> {{ selc.selection_price }}</label>
                                                <span class="check_new_item_sub_" v-if="selc.is_selection == '1'">
                                                  <input type="radio"  :name="'optionsRadios_sub_'+selc.customize_type_id" :value="selc"
                                                  :id="'optionsRadios_sub_'+selc.menu_item_selection_id" @change="subCustomizationChange($event,selc,cesIndex,optIndex)"
                                                  />
                                                  <label class="radio" :for="'optionsRadios_sub_'+selc.menu_item_selection_id"></label>
                                                </span>
                                                  <!-- :checked="opt.is_default == '1'" -->
                                                <span v-else class="check_new_item">
                                                  <input type="checkbox" :id="'checkBox1_sub_'+selc.menu_item_selection_id"  name="checkBox_sub_" :value="selc"
                                                    @change="subCustomizationChange($event,selc,cesIndex,optIndex)"
                                                  />
                                                  <!-- v-on:change="onchange($event,opt)" -->
                                                  <label class="radio" :for="'checkBox1_sub_'+selc.menu_item_selection_id"></label>
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          <!-- <template v-if="ces.selectedCustomisation.$error">
                                            <span class="text-danger" key="" >Please select one</span>
                                          </template> -->
                                        </div>
                                      </div>
                                    </div>
                                  </div> 
                                  <template slot="modal-footer">
                                    <div class="w-100">
                                      <b-button
                                        variant="primary"
                                        class="float-right"
                                        @click="hide($event,'popup_item',selected_item)"
                                      >{{ trans.get('__JSON__.Add') }}</b-button>
                                      <div class="list-available d-flex item-new-add mt-2">
                                          <span class="minus d-flex justify-content-center align-items-center" @click="custQty('-')">-</span>
                                          <span class="qty-item">{{selected_item.qty}}</span>
                                          <span class="d-flex justify-content-center align-items-center" @click="custQty('+')">+</span>
                                      </div>
                                    </div>
                                  </template>  
                                </b-modal>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                          <div  id="carts">
                            <div class="table-responsive item-order-main">
                              <table class="table table-centered table-bordered item-table-main">
                                  <thead>
                                    <tr>
                                      <th colspan="4" scope="col" class="item-name-main w-50 pr-2">{{trans.get('__JSON__.Item Name')}}</th>
                                    </tr>
                                    <tr>
                                      <th colspan="3" scope="col" class="text-center">{{trans.get('__JSON__.Quantity')}}</th>
                                      <th colspan="1" scope="col" class="text-center">{{trans.get('__JSON__.Original')}}</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <template  v-for="(carts,cindex) in cart">
                                    <tr :key="carts.id" v-if="carts.qty > 0">
                                      <td colspan="4">
                                        <div class="d-flex justify-content-between">
                                          <div><strong>{{ carts.item_name }}</strong> {{getSelections(carts.multiple_selection_new)}}</div>
                                          <div><b-link href="javascript:void(0)" @click="removeItem(cindex)"><b-icon icon="x-circle" font-scale="1.5" class=" text-danger"></b-icon></b-link></div>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr :key="carts.id">
                                      <td align="center" colspan="3">
                                        <span>{{ carts.qty }}</span><span> x </span><span><span v-html="currency"></span> {{ carts.item_total }} </span>
                                      </td>
                                      <td align="center" colspan="1" class="text-primary">
                                        <span><span v-html="currency"></span> {{ parseFloat(totalPrice(carts) || 0).toFixed(decimal) }} </span>
                                      </td>
                                    </tr>
                                    </template>
                                    <tr>
                                      <td colspan="3" align="center"><h6 class="text-right mb-0">{{trans.get('__JSON__.Sub Total')}}</h6></td>
                                      <td align="center" class="bg-soft-primary"> <span><span v-html="currency"></span> {{ parseFloat(total() || 0).toFixed(decimal) }} </span> </td>
                                    </tr>
                                    
                                    <tr v-if="promoApplied > 0">
                                      <td colspan="3" align="center">
                                        <div class="d-flex justify-content-end">
                                          <h6 class="text-right mb-0">{{trans.get('__JSON__.Promo Code')}} </h6>
                                          <span class="h5"><b-badge variant="danger"> {{ promocodeDetail.coupon_code }}</b-badge></span>
                                        </div>
                                      </td>
                                      <td align="center" class="text-danger" v-if="promocodeDetail.discount > 0"> - <span v-html="currency"></span> {{ parseFloat(promocodeDetail.discount).toFixed(2) }} </td>
                                      <td align="center" class="text-danger" v-if="promocodeDetail.cashback > 0"> - <span v-html="currency"></span> {{ parseFloat(promocodeDetail.cashback).toFixed(2) }} </td>
                                    </tr>
                                    <tr>
                                      <td colspan="3" align="center"><h6 class="text-right mb-0">{{trans.get('__JSON__.Tax')}}</h6></td>
                                      <td align="center"> <span v-html="currency"></span> {{ getTax() }} </td>
                                    </tr>
                                    <tr>
                                      <td colspan="3" align="center"><h6 class="text-right mb-0">{{trans.get('__JSON__.Delivery Charges')}}</h6></td>
                                      <td align="center"> <span v-html="currency"></span> {{ getDeliveryCharge() }} </td>
                                    </tr>
                                  
                                    <!-- <tr >
                                      <td colspan="3" align="right">
                                        <span class="text-right">{{trans.get('__JSON__.Packaging Charge')}}</span>
                                      </td>
                                      <td align="center"> <span v-html="currency"></span> {{ getPackagingCharge() }} </td>
                                    </tr> -->
                                    <tr>
                                      <td colspan="3" align="center"><h6 class="text-right mb-0">{{trans.get('__JSON__.Total Amount')}}</h6></td>
                                      <td align="center" class="bg-success font-size-16 font-weight-bold text-white"><span v-html="currency"></span> {{ parseFloat(promoApplied ? discountAmount() : totalAmount() || 0) }} </td>
                                    </tr>
                                  </tbody>
                                </table>
                            </div>
                          </div>
                        </div>
                        <!-- end col-->
                      </div>

                    </div>
                    <!-- end row -->
                  </div>
                  <!-- end col -->
                </div>
                <!-- end row -->
              </tab-content>
              <tab-content icon="mdi mdi-checkbox-marked-circle-outline" :title="trans.get('__JSON__.Order Summary')+' ('  + cart.length + ')'">
                 <!-- View Detail Main Start -->
                 <div class="row">
                   <div class="col-sm-4">
                     <b-list-group>
                     <b-list-group-item>
                     <h3>{{restaurant && restaurant.name}}</h3>
                     </b-list-group-item>
                     <b-list-group-item>
                      <span class="font-size-14 font-weight-bold">{{trans.get('__JSON__.Customer Name')}}: </span> {{user && user.user_name}}
                     </b-list-group-item>
                     <b-list-group-item>
                      <span class="font-size-14 font-weight-bold">{{trans.get('__JSON__.Email')}}: </span> {{user && user.email}}
                     </b-list-group-item>
                     <b-list-group-item>
                      <span class="font-size-14 font-weight-bold">{{trans.get('__JSON__.Phone')}}: </span> {{user && user.mobile_number}}
                     </b-list-group-item>
                     <b-list-group-item>
                      <span class="font-size-14 font-weight-bold">{{trans.get('__JSON__.Address')}}: </span> {{address && address.address || ''}}
                     </b-list-group-item>
                     </b-list-group>
                   </div>
                   <div class="col-sm-4">
                      <div  id="carts">
                        <div class="table-responsive item-order-main">
                            <table class="table table-centered table-bordered item-table-main">
                              <thead>
                                <tr>
                                  <th colspan="4" scope="col" class="item-name-main w-50 pr-2">{{trans.get('__JSON__.Item Name')}}</th>
                                </tr>
                                <tr>
                                  <th colspan="3" scope="col" class="text-center">{{trans.get('__JSON__.Quantity')}}</th>
                                  <th colspan="1" scope="col" class="text-center">{{trans.get('__JSON__.Original')}}</th>
                                </tr>
                              </thead>
                              <tbody>
                                <template  v-for="(carts,cindex) in cart">
                                <tr :key="carts.id" v-if="carts.qty > 0">
                                  <td colspan="4">
                                    <div class="d-flex justify-content-between">
                                      <div><strong>{{ carts.item_name }}</strong> {{getSelections(carts.multiple_selection_new)}}</div>
                                      <div><b-link href="javascript:void(0)" @click="removeItem(cindex)"><b-icon icon="x-circle" font-scale="1.5" class=" text-danger"></b-icon></b-link></div>
                                    </div>
                                  </td>
                                </tr>
                                <tr :key="carts.id">
                                  <td align="center" colspan="3">
                                    <span>{{ carts.qty }}</span><span> x </span><span><span v-html="currency"></span> {{ carts.item_total }} </span>
                                  </td>
                                  <td align="center" colspan="1" class="text-primary">
                                    <span><span v-html="currency"></span> {{ parseFloat(totalPrice(carts) || 0).toFixed(decimal) }} </span>
                                  </td>
                                </tr>
                                </template>
                                <tr>
                                  <td colspan="3" align="center"><h6 class="text-right mb-0">{{trans.get('__JSON__.Sub Total')}}</h6></td>
                                  <td align="center" class="bg-soft-primary"> <span><span v-html="currency"></span> {{ parseFloat(total() || 0).toFixed(decimal) }} </span> </td>
                                </tr>
                                <tr v-if="promoApplied > 0">
                                  <td colspan="3" align="center">
                                    <div class="d-flex justify-content-end">
                                      <h6 class="text-right mb-0">{{trans.get('__JSON__.Promo Code')}} </h6>
                                      <span class="h5"><b-badge variant="danger"> {{ promocodeDetail.coupon_code }}</b-badge></span>
                                    </div>
                                  </td>
                                  <td align="center" class="text-danger" v-if="promocodeDetail.discount > 0"> - <span v-html="currency"></span> {{ parseFloat(promocodeDetail.discount).toFixed(2) }} </td>
                                  <td align="center" class="text-danger" v-if="promocodeDetail.cashback > 0"> - <span v-html="currency"></span> {{ parseFloat(promocodeDetail.cashback).toFixed(2) }} </td>
                                </tr>
                                <tr>
                                  <td colspan="3" align="center"><h6 class="text-right mb-0">{{trans.get('__JSON__.Tax')}}</h6></td>
                                  <td align="center"> <span v-html="currency"></span> {{ getTax() }} </td>
                                </tr>
                                <tr>
                                  <td colspan="3" align="center"><h6 class="text-right mb-0">{{trans.get('__JSON__.Delivery Charges')}}</h6></td>
                                  <td align="center"> <span v-html="currency"></span> {{ getDeliveryCharge() }} </td>
                                </tr>
                             
                                                               
                                <tr>
                                  <td colspan="3" align="center"><h6 class="text-right mb-0">{{trans.get('__JSON__.Total Amount')}}</h6></td>
                                  <td align="center" class="bg-success font-size-16 font-weight-bold text-white"><span v-html="currency"></span> {{ parseFloat(promoApplied ? discountAmount() : totalAmount() || 0) }} </td>
                                </tr>
                              </tbody>
                            </table>
                        </div>
                        
                      </div>
                    </div>
                   <div class="col-sm-4">
                     <div>
                      <!-- 
                        vendor_id
                        is_language
                        user_id
                        restaurant_id
                        Coupon_code
                        original_price
                       -->
                      <label for="Promo Code">{{trans.get('__JSON__.Promo Code')}}</label>
                      <b-input-group prepend="Enter Code">
                        <b-form-input ref="promocode" name="promocode" v-model="promocode" :disabled="promoApplied"
                          @keyup.enter="applyPromoCode" ></b-form-input>
                        <b-input-group-append>
                          <b-button :variant="promoApplied?'outline-danger':'outline-success'" @click="applyPromoCode" :disabled="promo_loading">{{promoApplied?'Remove':'Apply'}}</b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </div>
                    <div class="mt-2">
                      <label for="">{{trans.get('__JSON__.Special Instruction')}}</label>
                      <textarea type="text" v-model="special_instruction" class="form-control" ></textarea>
                    </div>
                    <div class="mt-2">
                      <input type="checkbox" v-model="scheduled" id="scheduled" @change="scheduledOrder">
                      <label for="scheduled">{{trans.get('__JSON__.Is Scheduled Order')}}</label>
                    </div>                
                    <div v-if="scheduled" class="mt-2">
                      <label for="future_delivery_date">{{trans.get('__JSON__.Schedule Time')}}</label>
                      <date-picker
                        :clearable="false"
                        v-model="schedule_date"
                        format="YYYY-MM-DD hh:mm A"
                        type="datetime"
                        class="date-time"                          
                        :placeholder="trans.get('__JSON__.Select Schedule Time')"
                        valueType="date"
                        @change="formatDate"
                        :disabled-date="(date)=>disabledDate(date)"
                      ></date-picker>
                        <!-- :disabled-time="(date)=>disabledTime(date)" -->
                    </div>
                    <div class="mt-2">
                      <b-button variant="success"  v-if="isPlaceOrder" class="font-size-20 w-100">{{ trans.get('__JSON__.Placing Order') }}</b-button>
                      <b-button variant="success" @click="createOrder" v-else class="font-size-20 w-100">{{ trans.get('__JSON__.Place Order') }}</b-button>
                    </div>
                   </div>
                 </div>
                  <!-- View Detail Main End -->
                <!-- end row -->
              </tab-content>
              <div slot="finish"></div>
              <!-- <button slot="finish" class="finish-order-btn" v-if="isPlaceOrder" disabled>{{ trans.get('__JSON__.Placing Order') }}</button>
              <button slot="finish" class="finish-order-btn" v-else  @click="createOrder">{{ trans.get('__JSON__.Place Order') }}</button> -->
            </vue-form-wizard>
          </div>
          
          <!-- end card-body -->
        </div>
        <!-- end card -->
      </div>

     
      <!-- end col -->

    </div>
 </div>
    <!-- end row -->
  </Layout>
</template>