<script>
import { required } from "vuelidate/lib/validators";
import { settingService,businessTypeService,defaultSettingService } from "../../../services";
import { success_message, error_message } from "../../../Helper/helper";
import config from "../../../config";
/**
 * Starter component
 */
export default {
  data() {
    return {
      config: config,
      setting: [],
      app_setting_id : "",
      id: "",
      setting: {
        tax_name_primary_lang: "",
        restaurant_name: "",
        driver_name: "",
        recommended_menu_name: "",
        cuisine_name: "",
        cart_info_text: "",
        cod_text_primary_lang: "",
        payvia_text_primary_lang: "",
        marque_text : ""
      },
    };
  },
  validations: {
    setting: {
      tax_name_primary_lang: {
        required,
      },
      restaurant_name: {
        required,
      },
      driver_name: {
        required,
      },
      recommended_menu_name: {
        required,
      },
      cuisine_name: {
        required,
      }
    },
  },
  mounted() {
    this.getBusinessTypeById();
  },
  methods: {
    getBusinessTypeById() {
      businessTypeService
        .getBusinessTypeById({ id: this.$route.params.id })
        .then((response) => {
          this.setting = response.data.result.settings;
          this.id = response.data.result.business.id;
          this.app_setting_id = response.data.result.business.app_setting_id;
        });
    }, 
    generalIt() {
      this.$v.setting.$touch();
      if (this.$v.setting.$invalid) {
        return;
      } else {
        const fd = new FormData();
        fd.append("setting_id",this.id);
        fd.append("app_setting_id",this.app_setting_id);
        fd.append("tax_name_primary_lang", this.setting.tax_name_primary_lang);
        fd.append("restaurant_name", this.setting.restaurant_name);
        fd.append("driver_name", this.setting.driver_name);
        fd.append("recommended_menu_name", this.setting.recommended_menu_name);
        fd.append("cuisine_name", this.setting.cuisine_name);
        fd.append("cart_info_text", this.setting.cart_info_text);
        fd.append("cod_text_primary_lang", this.setting.cod_text_primary_lang);
        fd.append("payvia_text_primary_lang", this.setting.payvia_text_primary_lang);
        fd.append("marque_text",this.setting.marque_text);
        fd.append("type", 7);
        defaultSettingService.updateDefaultSetting(fd).then((response) => {
          if (response.data.code === 200) {
            success_message(response.data.message);
          } else {
            error_message(response.data.message);
          }
        });
      }
    },
  },
};
</script>
<template>
  <b-form @submit.prevent="generalIt">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group" v-if="$auth.setting">
                  <label>{{ trans.get('__JSON__.Tax Type') }}</label>
                  <input
                    v-model="setting.tax_name_primary_lang"
                    id="tax_name_primary_lang"
                    name="tax_name_primary_lang"
                    type="text"
                    :placeholder="trans.get('__JSON__.Enter Tax Type')"
                    class="form-control"
                    :class="{ 'is-invalid': $v.setting.tax_name_primary_lang.$error }"
                  />
                  <div v-if="$v.setting.tax_name_primary_lang.$error" class="invalid-feedback">
                    <span
                      v-if="!$v.setting.tax_name_primary_lang.required"
                    >{{ trans.get('__JSON__.Please enter tax type')}}</span>
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group" v-if="$auth.setting">
                  <label>{{ trans.trans('__JSON__.Vendors Names', {vendor: $auth.setting.restaurant_name}) }}</label>
                  <input
                    v-model="setting.restaurant_name"
                    id="restaurant_name"
                    name="restaurant_name"
                    type="text"
                    :placeholder="trans.trans('__JSON__.Enter Vendor Names', { vendor: $auth.setting.restaurant_name })"
                    class="form-control"
                    :class="{ 'is-invalid': $v.setting.restaurant_name.$error }"
                  />
                  <div v-if="$v.setting.restaurant_name.$error" class="invalid-feedback">
                    <span
                      v-if="!$v.setting.restaurant_name.required"
                    >{{ trans.get('__JSON__.Please enter vendor name',{vendor: $auth.setting.restaurant_name})}}</span>
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group" v-if="$auth.setting">
                  <label>{{ trans.trans('__JSON__.Driver Names', {driver: $auth.setting.driver_name}) }}</label>
                  <input
                    v-model="setting.driver_name"
                    id="driver_name"
                    name="driver_name"
                    type="text"
                    :placeholder="trans.trans('__JSON__.Enter Driver Names', { driver: $auth.setting.driver_name})"
                    class="form-control"
                    :class="{ 'is-invalid': $v.setting.driver_name.$error }"
                  />
                  <div v-if="$v.setting.driver_name.$error" class="invalid-feedback">
                    <span
                      v-if="!$v.setting.driver_name.required"
                    >{{ trans.get('__JSON__.Please enter driver name',{ driver: $auth.setting.driver_name })}}</span>
                  </div>
                </div>
              </div>
      
              <div class="col-md-6">
                <div class="form-group" v-if="$auth.setting">
                  <label>{{ trans.get('__JSON__.Recommended Menu Names') }}</label>
                  <input
                    id="recommended_menu_name"
                    name="recommended_menu_name"
                    v-model="setting.recommended_menu_name"
                    type="text"
                    :placeholder="trans.get('__JSON__.Enter Recommended Menu Names')"
                    class="form-control"
                    :class="{ 'is-invalid': $v.setting.recommended_menu_name.$error }"
                  />
                  <div v-if="$v.setting.recommended_menu_name.$error" class="invalid-feedback">
                    <span
                      v-if="!$v.setting.recommended_menu_name.required"
                    >{{ trans.get('__JSON__.Please enter menu name') }}</span>
                  </div>
                </div>
              </div>
            
              <div class="col-md-6">
                <div class="form-group" v-if="$auth.setting">
                  <label>{{ trans.get('__JSON__.Cuisine Names') }}</label>
                  <input
                    id="cuisine_name"
                    name="cuisine_name"
                    v-model="setting.cuisine_name"
                    type="text"
                    :placeholder="trans.get('__JSON__.Enter Cuisine Name Placeholders')"
                    class="form-control"
                    :class="{ 'is-invalid': $v.setting.cuisine_name.$error }"
                  />
                  <div v-if="$v.setting.cuisine_name.$error" class="invalid-feedback">
                    <span
                      v-if="!$v.setting.cuisine_name.required"
                    >{{ trans.trans('__JSON__.Please Enter Cuisine Name Errors')}}</span>
                  </div>
                </div>
              </div>
             
              <div class="col-md-6">
                <div class="form-group">
                  <label>{{ trans.get('__JSON__.Cart Information Texts') }}</label>
                  <input
                    id="cart_info_text"
                    name="cart_info_text"
                    type="text"
                    v-model="setting.cart_info_text"
                    :placeholder="trans.get('__JSON__.Enter Cart Information Texts')"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>{{ trans.trans('__JSON__.Cash On Delivery Text blank') }}</label>
                  <input
                    id="cod_text_primary_lang"
                    name="cod_text_primary_lang"
                    type="text"
                    v-model="setting.cod_text_primary_lang"
                    :placeholder="trans.trans('__JSON__.Cash On Delivery Text blank')"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>{{ trans.trans('__JSON__.Pay Via Text blank') }}</label>
                  <input
                    id="payvia_text_primary_lang"
                    name="payvia_text_primary_lang"
                    type="text"
                    v-model="setting.payvia_text_primary_lang"
                    :placeholder="trans.trans('__JSON__.Pay Via Text blank')"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>{{ trans.trans('__JSON__.Marque Text') }}</label>
                  <input
                    id="marque_text"
                    name="marque_text"
                    v-model="setting.marque_text"
                    type="text"
                    :placeholder="trans.trans('__JSON__.Enter Marque Text')"
                    class="form-control"
                  />
                </div>
              </div>
            </div>

            <div class="btn-update">
              <div class="col-sm-6">
                <button
                  v-if="$auth.hasPermission(config.permissions.terminology_write)"
                  type="submit"
                  class="btn btn-primary mr-1 mt-3"
                >{{ trans.get('__JSON__.Update') }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-form>
</template>
