<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import { settingService } from "../../services";
import VueGoogleAutocomplete from 'vue-google-autocomplete';

export default {
  components: { Layout, PageHeader, VueGoogleAutocomplete},
  data() {
    return {
      center: { lat: 0.00, lng: 0.00 },
      markers: [],
      pov: null,
      pano: null,
      edited: null,
      paths: [
        [
          { lat: 1.38, lng: 103.8 },
          { lat: 1.38, lng: 103.81 },
          { lat: 1.39, lng: 103.81 },
          { lat: 1.39, lng: 103.8 },
        ],
        [
          { lat: 1.382, lng: 103.802 },
          { lat: 1.382, lng: 103.808 },
          { lat: 1.388, lng: 103.808 },
          { lat: 1.388, lng: 103.802 },
        ],
      ],
    };
  },
  mounted() {
    this.getSetting();
  },
  methods: {
    /**
     * street view data update
     */
    updatePov(pov) {
      this.pov = pov;
    },
    /**
     * street view data update
     */
    updatePano(pano) {
      this.pano = pano;
    },
    /**
     * polygon editing
     */
    updateEdited(mvcArray) {
      let paths = [];
      for (let i = 0; i < mvcArray.getLength(); i++) {
        let path = [];
        for (let j = 0; j < mvcArray.getAt(i).getLength(); j++) {
          let point = mvcArray.getAt(i).getAt(j);
          path.push({ lat: point.lat(), lng: point.lng() });
        }
        paths.push(path);
      }
      this.edited = paths;
    },
    getSetting(){
      settingService.getSetting()
      .then(response => {
        const cent = {
          lat: parseFloat(response.data.result.system_latitude),
          lng: parseFloat(response.data.result.system_longitude)
        };
        this.center = cent; 
        this.markers.push({ position: cent });
        this.vendor_currency = response.data.result.currency;
      });
    },
  },
};
</script>
<template>
  <!-- <div class="row">
    <div class="col-12"> -->

      <div class="map1" id="map">
        <gmap-map :center="center" :zoom="7" style="height: 500px">
          <gmap-marker
            v-for="(m, index) in markers"
            :key="index"
            :position="m.position"
            :clickable="true"
            :draggable="true"
            @click="center = m.position"
          ></gmap-marker>
        </gmap-map>
      </div>
      
      <!-- <GmapMap :center="{ lat: 2, lng: 2 }" :zoom="5" style="height: 500px"></GmapMap> -->
    <!-- </div>
  </div> -->
  <!-- end row -->
</template>