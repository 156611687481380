const vendorsEarning = [
    {
        id: 1,
        month: '01 Oct 20',
        item: '₹ 0.00',
        delivery: '₹ 0.00',
        coupon: '₹ 0.00',
        total: '₹ 0.00',
        admin: '₹ 0.00',
        earning: '₹ 0.00',
        vendor: '₹ 0.00'
    },
    {
        id: 2,
        month: '02 Oct 20',
        item: '₹ 0.00',
        delivery: '₹ 0.00',
        coupon: '₹ 0.00',
        total: '₹ 0.00',
        admin: '₹ 0.00',
        earning: '₹ 0.00',
        vendor: '₹ 0.00'
    },
    {
        id: 3,
        month: '03 Oct 20',
        item: '₹ 0.00',
        delivery: '₹ 0.00',
        coupon: '₹ 0.00',
        total: '₹ 0.00',
        admin: '₹ 0.00',
        earning: '₹ 0.00',
        vendor: '₹ 0.00'
    },
    {
        id: 4,
        month: '04 Oct 20',
        item: '₹ 0.00',
        delivery: '₹ 0.00',
        coupon: '₹ 0.00',
        total: '₹ 0.00',
        admin: '₹ 0.00',
        earning: '₹ 0.00',
        vendor: '₹ 0.00'
    },
    {
        id: 5,
        month: '05 Oct 20',
        item: '₹ 0.00',
        delivery: '₹ 0.00',
        coupon: '₹ 0.00',
        total: '₹ 0.00',
        admin: '₹ 0.00',
        earning: '₹ 0.00',
        vendor: '₹ 0.00'
    },
    {
        id: 6,
        month: '06 Oct 20',
        item: '₹ 0.00',
        delivery: '₹ 0.00',
        coupon: '₹ 0.00',
        total: '₹ 0.00',
        admin: '₹ 0.00',
        earning: '₹ 0.00',
        vendor: '₹ 0.00'
    },
    {
        id: 7,
        month: '07 Oct 20',
        item: '₹ 0.00',
        delivery: '₹ 0.00',
        coupon: '₹ 0.00',
        total: '₹ 0.00',
        admin: '₹ 0.00',
        earning: '₹ 0.00',
        vendor: '₹ 0.00'
    },
    {
        id: 8,
        month: '08 Oct 20',
        item: '₹ 0.00',
        delivery: '₹ 0.00',
        coupon: '₹ 0.00',
        total: '₹ 0.00',
        admin: '₹ 0.00',
        earning: '₹ 0.00',
        vendor: '₹ 0.00'
    },
    {
        id: 9,
        month: '09 Oct 20',
        item: '₹ 0.00',
        delivery: '₹ 0.00',
        coupon: '₹ 0.00',
        total: '₹ 0.00',
        admin: '₹ 0.00',
        earning: '₹ 0.00',
        vendor: '₹ 0.00'
    },
    {
        id: 10,
        month: '10 Oct 20',
        item: '₹ 0.00',
        delivery: '₹ 0.00',
        coupon: '₹ 0.00',
        total: '₹ 0.00',
        admin: '₹ 0.00',
        earning: '₹ 0.00',
        vendor: '₹ 0.00'
    },
    {
        id: 12,
        month: '11 Oct 20',
        item: '₹ 0.00',
        delivery: '₹ 0.00',
        coupon: '₹ 0.00',
        total: '₹ 0.00',
        admin: '₹ 0.00',
        earning: '₹ 0.00',
        vendor: '₹ 0.00'
    },
    {
        id: 13,
        month: '12 Oct 20',
        item: '₹ 0.00',
        delivery: '₹ 0.00',
        coupon: '₹ 0.00',
        total: '₹ 0.00',
        admin: '₹ 0.00',
        earning: '₹ 0.00',
        vendor: '₹ 0.00'
    },
    {
        id: 14,
        month: '13 Oct 20',
        item: '₹ 0.00',
        delivery: '₹ 0.00',
        coupon: '₹ 0.00',
        total: '₹ 0.00',
        admin: '₹ 0.00',
        earning: '₹ 0.00',
        vendor: '₹ 0.00'
    },
    {
        id: 15,
        month: '14 Oct 20',
        item: '₹ 0.00',
        delivery: '₹ 0.00',
        coupon: '₹ 0.00',
        total: '₹ 0.00',
        admin: '₹ 0.00',
        earning: '₹ 0.00',
        vendor: '₹ 0.00'
    },
    {
        id: 16,
        month: '15 Oct 20',
        item: '₹ 0.00',
        delivery: '₹ 0.00',
        coupon: '₹ 0.00',
        total: '₹ 0.00',
        admin: '₹ 0.00',
        earning: '₹ 0.00',
        vendor: '₹ 0.00'
    },
    {
        id: 17,
        month: '16 Oct 20',
        item: '₹ 0.00',
        delivery: '₹ 0.00',
        coupon: '₹ 0.00',
        total: '₹ 0.00',
        admin: '₹ 0.00',
        earning: '₹ 0.00',
        vendor: '₹ 0.00'
    },
    {
        id: 18,
        month: '17 Oct 20',
        item: '₹ 0.00',
        delivery: '₹ 0.00',
        coupon: '₹ 0.00',
        total: '₹ 0.00',
        admin: '₹ 0.00',
        earning: '₹ 0.00',
        vendor: '₹ 0.00'
    },
    {
        id: 19,
        month: '18 Oct 20',
        item: '₹ 0.00',
        delivery: '₹ 0.00',
        coupon: '₹ 0.00',
        total: '₹ 0.00',
        admin: '₹ 0.00',
        earning: '₹ 0.00',
        vendor: '₹ 0.00'
    },
    {
        id: 20,
        month: '19 Oct 20',
        item: '₹ 0.00',
        delivery: '₹ 0.00',
        coupon: '₹ 0.00',
        total: '₹ 0.00',
        admin: '₹ 0.00',
        earning: '₹ 0.00',
        vendor: '₹ 0.00'
    },
    {
        id: 21,
        month: '20 Oct 20',
        item: '₹ 0.00',
        delivery: '₹ 0.00',
        coupon: '₹ 0.00',
        total: '₹ 0.00',
        admin: '₹ 0.00',
        earning: '₹ 0.00',
        vendor: '₹ 0.00'
    },
    {
        id: 22,
        month: '21 Oct 20',
        item: '₹ 0.00',
        delivery: '₹ 0.00',
        coupon: '₹ 0.00',
        total: '₹ 0.00',
        admin: '₹ 0.00',
        earning: '₹ 0.00',
        vendor: '₹ 0.00'
    },
    {
        id: 23,
        month: '22 Oct 20',
        item: '₹ 0.00',
        delivery: '₹ 0.00',
        coupon: '₹ 0.00',
        total: '₹ 0.00',
        admin: '₹ 0.00',
        earning: '₹ 0.00',
        vendor: '₹ 0.00'
    },
    {
        id: 24,
        month: '23 Oct 20',
        item: '₹ 0.00',
        delivery: '₹ 0.00',
        coupon: '₹ 0.00',
        total: '₹ 0.00',
        admin: '₹ 0.00',
        earning: '₹ 0.00',
        vendor: '₹ 0.00'
    },
    {
        id: 25,
        month: '24 Oct 20',
        item: '₹ 0.00',
        delivery: '₹ 0.00',
        coupon: '₹ 0.00',
        total: '₹ 0.00',
        admin: '₹ 0.00',
        earning: '₹ 0.00',
        vendor: '₹ 0.00'
    },
    {
        id: 26,
        month: '25 Oct 20',
        item: '₹ 0.00',
        delivery: '₹ 0.00',
        coupon: '₹ 0.00',
        total: '₹ 0.00',
        admin: '₹ 0.00',
        earning: '₹ 0.00',
        vendor: '₹ 0.00'
    },
    {
        id: 27,
        month: '26 Oct 20',
        item: '₹ 0.00',
        delivery: '₹ 0.00',
        coupon: '₹ 0.00',
        total: '₹ 0.00',
        admin: '₹ 0.00',
        earning: '₹ 0.00',
        vendor: '₹ 0.00'
    },
    {
        id: 7,
        month: '27 Oct 20',
        item: '₹ 0.00',
        delivery: '₹ 0.00',
        coupon: '₹ 0.00',
        total: '₹ 0.00',
        admin: '₹ 0.00',
        earning: '₹ 0.00',
        vendor: '₹ 0.00'
    },
    {
        id: 7,
        month: '28 Oct 20',
        item: '₹ 0.00',
        delivery: '₹ 0.00',
        coupon: '₹ 0.00',
        total: '₹ 0.00',
        admin: '₹ 0.00',
        earning: '₹ 0.00',
        vendor: '₹ 0.00'
    },
    {
        id: 28,
        month: '29 Oct 20',
        item: '₹ 0.00',
        delivery: '₹ 0.00',
        coupon: '₹ 0.00',
        total: '₹ 0.00',
        admin: '₹ 0.00',
        earning: '₹ 0.00',
        vendor: '₹ 0.00'
    },
    {
        id: 29,
        month: '30 Oct 20',
        item: '₹ 0.00',
        delivery: '₹ 0.00',
        coupon: '₹ 0.00',
        total: '₹ 0.00',
        admin: '₹ 0.00',
        earning: '₹ 0.00',
        vendor: '₹ 0.00'
    },
    {
        id: 30,
        month: '31 Oct 20',
        item: '₹ 0.00',
        delivery: '₹ 0.00',
        coupon: '₹ 0.00',
        total: '₹ 0.00',
        admin: '₹ 0.00',
        earning: '₹ 0.00',
        vendor: '₹ 0.00'
    }
];

export { vendorsEarning };