<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";

import StateLocationList from "./state-location-list";
/**
 * State component
 */
export default {
  components: { Layout, PageHeader, StateLocationList },
};
</script>

<template>
  <Layout>
    <state-location-list />
  </Layout>
</template>
