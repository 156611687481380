import { routes } from '../config/api_routes'

export const orderService = {
    getOrders: (filters) => window.axios.post(routes.getOrdersApi, filters),
    getOrder: (id) => window.axios.post(routes.getOrderApi, {order_id: id}),
    getOrdersCount: (filters) => window.axios.post(routes.getOrdersCountApi,filters),
    exportOrders: (filters) => window.axios.post(routes.exportOrdersApi, filters),
    getNewOrders: (filters) => window.axios.post(routes.getNewOrdersApi, filters),
    checkAvailableDrivers: (data)   =>   window.axios.get(routes.checkAvailableDriversApi, {params: data}),
    assignDriver: (data)   =>   window.axios.post(routes.assignDriverApi, data),
    exportAllOrders: (filters) => window.axios.post(routes.exportAllOrdersApi, filters),
    
    // app apis 
    // ADD APP API BELOW
    changeOrderStatus: (data) => window.axios.post(routes.changeOrderStatusApi, data),
    ownerOrderStatus: (data) => window.axios.post(routes.ownerOrderStatusApi, data),
    cancelOrder: (data) => window.axios.post(routes.cancelOrderApi, data),
    vendorInvoice: (data) => window.axios.post(routes.uploadInvoiceApi, data),
    deliverOrder: (data) => window.axios.post(routes.deliverOrderApi, data),
    changeToCodOrder: (data) => window.axios.post(routes.changeToCodOrderApi, data),
    pingOrderDrivers: (data) => window.axios.post(routes.pingDriversApi, data),
    removeOrderItem: (data) => window.axios.post(routes.removeOrderItemApi, data),
    updatePriceWeb: (data) => window.axios.post(routes.updatePriceWebApi, data),
    sentToStore: (data)   =>   window.axios.post(routes.sentToStoreApi, data),
    cancelOrderDunzo: (data)   =>   window.axios.post(routes.cancelOrderDunzoApi, data),
    markOrderUnsettle: (data) => window.axios.post(routes.markOrderUnsettleApi, data),

    //doctorConsultation
    doctorConsultation: (data) => window.axios.post(routes.doctorConsultationApi, data),
}