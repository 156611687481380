<script>
import { get_currency, get_decimal } from '../../../Helper/helper';
import config from '../../../config';
export default {
  props: ['order', 'loading'],
  data() {
    return {
      config: config,
    }
  },
};
</script>

<template>
  <div class="row mt-3" v-if="!loading && order.vendor_invoice">
    <div class="col-12">
      <h3>Vendor Invoice</h3>
    </div>
    <div class="col-12">
      <a target="_blank" :href="order.vendor_invoice">
        <div class="content-overlay">
          <div class="content-details">
            <h3><i class="fas fa-search"></i></h3>
          </div>
        </div>
      </a>
      <img v-if="order.vendor_invoice.split(/[#?]/)[0].split('.').pop().trim() == 'pdf'" :src="config.basepath+'pdfimage.png'" alt="Invoice" width="130" height="130">
      <img v-else :src="order.vendor_invoice" alt="Invoice" width="130" height="130">
    </div>
  </div>
</template>

<style type="text/css">
.gallery {
  margin: 5px;
  border: 1px solid #ccc;
  float: left;
  position: relative;
}

/*.gallery:hover {
  border: 1px solid #777;
}*/
.gallery img {
  height: 130px;
  width: 130px;
  object-fit: cover;
}

.gallery .content-overlay {
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  height: 99%;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}

.gallery:hover .content-overlay {
  opacity: 0.9;
}

.content-details {
  position: absolute;
  text-align: center;
  padding-left: 1em;
  padding-right: 1em;
  width: 100%;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.gallery:hover .content-details {
  top: 50%;
  left: 50%;
  opacity: 1;
}

.content-details h3 {
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.15em;
  margin-bottom: 0.5em;
  text-transform: uppercase;
}
</style>