
// BusinessType services rest api calling

import { routes } from '../config/api_routes'

export const businessTypeService = {
    // get BusinessTypeList 

   getBusinessTypeListing: (formData) => {    
        return window.axios.post(routes.getBusinessTypeApi,formData)
   },

   addBusinessType: (formData) => {
       return window.axios.post(routes.addBusinessTypeApi,formData)
   },

   getBusinessTypeById: (formData) => {
       return window.axios.post(routes.getBusinessTypeByIdApi,formData)
   },

   updateBusinessTypeById: (formData) => {
       return window.axios.post(routes.updateBusinessTypeByIdApi,formData)
   }
}