
// Currency services rest api calling

import { routes } from '../config/api_routes'

export const currencyService = {
    // get currencyList 

    getCurrencyListing: (formData) => {    
        return window.axios.post(routes.getCurrencyApi,formData)
   },

   addCurrency: (formData) => {
       return window.axios.post(routes.addCurrencyApi,formData)
   },

   getCurrencyById: (formData) => {
       return window.axios.post(routes.getCurrencyByIdApi,formData)
   },

   updateCurrency: (formData) => {
       return window.axios.post(routes.updateCurrencyApi,formData)
   }
}