<script>

import Layout from '../../layouts/main'
import PageHeader from '../../components/page-header'
import EditDeliveryZone from './edit-delivery-zone';
import { deliveryZoneService} from '../../services';

/**
 * Add-product component
 */
export default {
    components: {Layout, PageHeader, EditDeliveryZone, deliveryZoneService},
    data() {
        return {
          users: []
        };
    },
    mounted(){
    this.getDeliveryInfo();
   },
   methods: {
    getDeliveryInfo(){
      deliveryZoneService.getDeliveryInfo({
        user_id: this.$route.params.user_id
      })
      .then(response => {
        this.users = response.data.result;
      });
    },
   }
}
</script>

<template>
  <Layout>
     <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
        >
          <div class="page-title-left avatar-main-icon">
            <div class="avatar-xs mr-3">
              <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                <i class="bx bx-map-alt"></i>
              </span>
            </div>
             <div class="report-tag">
              <h4 class="mb-0 font-size-18">{{ users.user_name }}</h4>
              <p><router-link :to="{ name: 'list'}">{{ trans.get('__JSON__.Delivery Zones') }}</router-link> > {{ users.user_name }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
        <div class="col-12">
            <edit-delivery-zone />
        </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
