
// Web services rest api calling

import { routes } from '../config/api_routes'

export const webLayoutService = {
    // get Web Layout
    // info: sortDesc : Boolean

    getWebSetting: () => window.axios.get(routes.getWebSettingApi),

    // update WebLayoutSetting
    updateWebSetting: (formData) => window.axios.post(routes.updateWebSettingApi, formData),

    // delete Section
    deleteFrontImage: (formData) => window.axios.post(routes.deleteFrontImageApi, formData)
}