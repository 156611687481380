<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";

import CuisineList from "./cuisine-list";
/**
 * Cuisine component
 */
export default {
  components: { Layout, PageHeader, CuisineList },
};
</script>

<template>
  <Layout>
    <cuisine-list />
  </Layout>
</template>
