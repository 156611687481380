import config from '../config'

export default {
    config: config,
}

const base = config.base_folder;

export const clientmenuItems = [
    {
        id: 1,
        label: "Dashboard",
        icon: "bx-home-circle",
        link: base+"dashboard",
       
    },
    {
        id: 2,
        label: "Clients",
        icon: "bx-user",
        link: base+"client",
    },
    {
        id: 3,
        label: "Currency",
        icon: "bx-dollar",
        link: base+"currency",
    },
    {
        id: 4,
        label: "Business Type",
        icon: "bx bxs-business",
        link: base+"business-type",
    }
];
