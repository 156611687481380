<script>
import pagination from "laravel-vue-pagination";
import { customerService } from "../../services";
import { debounce} from "../../Helper/helper";
import config from '../../config';
import Layout from "../../layouts/main";

export default {
  components: { pagination, Layout },
  data() {
    const { status,orderBy,sortBy} = this.$route.query;
    return {
      config: config,
      sortBy: sortBy || "created_at",
      sortDesc: orderBy && orderBy != '' ? orderBy : true,
      brands: [],
      brandsData: [],
      brandCount: '',
      paginations: {},
      limit: 2,
      search: "",
      status: status == undefined ? "1" :status,
      is_promotional: "1",
      loading: true,
      isDownloading: false,
      searchData:debounce(()=>{
          const {status,is_promotional,orderBy,sortBy} = this.$data;
          this.$router.replace({name: 'brand-list', query: {status,is_promotional,orderBy,sortBy}})
          this.getBrandList();
      },500),
      fields: [
        {
          key: "id",
          label: this.trans.get("__JSON__.S. No."),
          sortable: false,
        },
        {
          key: "brand_name",
          label: this.trans.get("__JSON__.Brand Name"),
          sortable: false,
        },
        {
          key: "brand_image",
          label: this.trans.get("__JSON__.Brand Image"),
          sortable: false,
        },
        {
          key: "is_promotional",
          label: this.trans.get("__JSON__.Is Promotional"),
          sortable: false,
        },
        {
          key: "status",
          label: this.trans.get("__JSON__.status"),
          sortable: false,
        },
        {
          key: "action",
          label: this.trans.get("__JSON__.Action"),
          sortable: false,
        }
      ],
    };
  },
  mounted() {
    this.getBrandList();
    this.getBrandCount();
  },
  methods: {
    onRowClicked(item) {
        this.$router.push({name: 'brand-edit',params: { id: this.base64(item.id)}})
    },
    downloadsCSV: function () {
      let brandsData = "\ufeff" + "S. No.,Brand Name,Brand Image,Is promotional,Status\n";
      this.brandsData.forEach((el, ind) => {
        let Sno = ind + 1;
        var line = Sno + "," +
          el["brand_name"] +
          "," +
          (el["brand_image"] ? el["brand_image"] : "-") +
          "," +
          (el["is_promotional"] == 1 ? "Yes" : "No") +
          "," +
          (el["status"] == 1 ? "Active" : "Inactive") +
          "\n";
        brandsData += line;
      });
      var blob = new Blob([brandsData], { type: "csv/plain" });
      var date = new Date().toLocaleString();
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "brands" + date + ".csv";
      link.click();
    },
    getBrandList(page) {
      this.loading = true;
      var filters = {
          search: this.search,
          status: this.status,
          is_promotional: this.is_promotional,
          pagination: true,
          page: page && page > 0 ? page : 1
      }
      customerService
        .getBrandDetails(filters)
        .then((response) => {
          this.loading = false;
          this.paginations = response.data.result;
          this.brands = response.data.result.data;
        });
    },
    affiliateExportCsv() {
      this.isDownloading = true;
      customerService
        .getBrandDetails({ status: this.status, sortBy: this.sortBy, orderBy: this.sortDesc ? "desc" : "asc",
          pagination: false, search: this.search, is_promotional: this.is_promotional})
        .then((response) => {
          this.isDownloading = false;
          this.brandsData = response.data.result;
          this.downloadsCSV();
        });
    },
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      this.getBrandList();
    },
    getBrandCount(){
        customerService.getBrandCount()
        .then((response) =>{
            this.brandCount = response.data.result;
        })
    },
  },
};
</script>

<template>
    <Layout>
  <div>
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
        >
          <div class="page-title-left">
            <div class="avatar-xs mr-3">
              <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                <i class="bx bx-user-circle"></i>
              </span>
            </div>
            <h4 class="mb-0 font-size-18">{{ trans.get('__JSON__.Brands') }}({{brandCount}})</h4>
          </div>
          <div class="page-title-right">
            <div style="display: flex;">
              <router-link
                class="btn btn-success btn-rounded mr-2 add-btn"
                      :to="{name: 'brand-add'}"
                    >+ Add Brand
              </router-link>
            <button
              @click="affiliateExportCsv"
              class="btn btn-primary btn-rounded export-btn"
              v-if="isDownloading"
              disabled
            >{{ trans.get('__JSON__.Exporting...') }}</button>
            <button
              @click="affiliateExportCsv"
              class="btn btn-primary btn-rounded export-btn"
              v-else
            >{{ trans.get('__JSON__.Export To CSV') }}</button>
            <button
              @click="affiliateExportCsv"
              class="btn btn-primary btn-rounded m-export-btn"
            ><i class="bx bx-archive-in"></i></button>
          </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row list-table-main">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
                <div style="margin-left: 50%" class="col-sm-2">
                <div class="form-group">
                  <div class="date-range-list">
                    <label>{{ trans.get('__JSON__.Is Promotional') }} :</label>
                    <select class="custom-select" v-model="is_promotional" @change="searchData()">
                      <option value="all">{{ trans.get('__JSON__.All') }}</option>
                      <option value="1" selected>{{ trans.get('__JSON__.Yes') }}</option>
                      <option value="0">{{ trans.get('__JSON__.No') }}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="form-group">
                  <div class="date-range-list">
                    <label>{{ trans.get('__JSON__.Status') }} :</label>
                    <select class="custom-select" v-model="status" @change="searchData()">
                      <option value="all">{{ trans.get('__JSON__.All') }}</option>
                      <option value="1" selected>{{ trans.get('__JSON__.Active') }}</option>
                      <option value="0">{{ trans.get('__JSON__.Inactive') }}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="date-range-list">
                  <label>{{ trans.get('__JSON__.Search') }} :</label>
                  <div class="text-sm-right">
                    <div class="search-box mr-2 d-inline-block">
                      <div class="position-relative">
                        <input
                          style="width: 104% !important"
                          type="text"
                          class="form-control"
                          id="search"
                          @input="searchData()"
                          v-model="search"
                          :placeholder="trans.get('__JSON__.Search by brand name')"
                        />
                        <i class="bx bx-search-alt search-icon"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 table-main-list customer-table table-responsive">
                <b-table
                  :items="brands"
                  :fields="fields"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :no-local-sorting="true"
                  @sort-changed="sortingChanged"
                  :busy="loading"
                  show-empty
                  @row-clicked="onRowClicked"
                >
                  <template #table-busy>
                    <div class="text-center text-danger my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>{{trans.get('__JSON__.Loading...')}}</strong>
                    </div>
                  </template>

                  <template v-slot:cell(id)="data">
                      <h5 class="font-size-14">{{data.index + 1 + ((paginations.current_page-1) * paginations.per_page)}}</h5>
                  </template>

                  <template v-slot:cell(brand_name)="data">
                    <h5 class="font-size-14">{{ data.item.brand_name }}</h5>
                  </template>

                  <template v-slot:cell(brand_image)="data">
                    <img v-if="data.item.brand_image" :src="data.item.brand_image" alt="Brand Image" width="100" height="100"/>
                    <span v-else>-</span>
                  </template>

                  <template v-slot:cell(is_promotional)="data">
                    <div
                      v-if="data.item.is_promotional === 1"
                      class="badge badge-pill badge-soft-success font-size-12"
                    >{{ trans.get('__JSON__.Yes') }}</div>
                    <div
                      v-else
                      class="badge badge-pill badge-soft-danger font-size-12"
                    >{{ trans.get('__JSON__.No') }}</div>
                  </template>

                  <template v-slot:cell(status)="data">
                    <div
                      v-if="data.item.status === 1"
                      class="badge badge-pill badge-soft-success font-size-12"
                    >{{ trans.get('__JSON__.Active') }}</div>
                    <div
                      v-else
                      class="badge badge-pill badge-soft-danger font-size-12"
                    >{{ trans.get('__JSON__.Inactive') }}</div>
                  </template>

                  <template v-slot:cell(action)="data">
                    <router-link
                      :to="{name: 'brand-edit',params: { id:base64(data.item.id)}}"
                      class="btn btn-primary btn-sm btn-rounded"
                    >View</router-link>
                  </template>

                  <template #empty>
                    <p class="text-center">{{ trans.get('__JSON__.No Brands') }}</p>
                  </template>
                </b-table>
              </div>
              <div class="row col-sm-5 offset-sm-7">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <pagination
                        :data="paginations"
                        :limit="limit"
                        @pagination-change-page="getBrandList"
                      ></pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</Layout>
</template>

