<script>
import Layout from "../../layouts/main";
import config from "../../config";
import PageHeader from "../../components/page-header";
import draggable from "vuedraggable";
import DropzonePopup from '../../components/widgets/dropzone-popup';
import { upcomingTasks, progressTasks, completedTasks } from "./catalog.js";
import { vendorService, taxSlabService, settingService, commonService } from "../../services";
import { required,requiredIf,numeric,helpers} from "vuelidate/lib/validators";
import pagination from "laravel-vue-pagination";
import DatePicker from "vue2-datepicker";
import { error_message, success_message, debounce, get_currency, get_user} from "../../Helper/helper";
import { routes } from '../../config/api_routes';
const floating = helpers.regex('floating', /^(?:\d*\.\d{1,2}|\d+)$/)
import moment from "moment";
import Multiselect from "vue-multiselect";
/**
 * Kanban-board component
 */
export default {
  components: { Layout, PageHeader, draggable, DropzonePopup,routes,pagination,DatePicker, Multiselect},
  data() {
    return {
        moment:moment,
        taxslabtext:"",
        taxslablist: [],
        custom_item: [],
        debounce: false,
        title: "Catalog",
        menu_id: 0,
        config: config,
        showModal: false,
        itemModal: false,
        checked: true,
        limit:2,
        editMenuModal: false,
        editItemModal: false,
        imageModal: false,
        importModal: false,
        itemImportModal: false,
        menuItemExport: false,
        multiple: false,
        loading: true,
        search:'',
        ismapping: 0,
        menu: [],
        items: [],
        slots: [],
        restaurant_id: "",
        restaurant: [],
        category_count:'',
        item_count:'',
        slot_count:'',
        paginations:{},
        menu_item_time:'',
        currency: get_currency(),
        item_menu_name:"",
        typeform: {
          menu_name: "",
          status: "1",
          image: "",
          menu_name_thai:"",
          parent_menu_id:"",
          taxslab:0,
          is_selling_time_slot:"0",
          start_date:"",
          selling_time:"WholeDay",
          is_wholeday:"1",
          timeslot_id:0,
          promcode_applicable:"1",
          non_medicine_flag:"0",
          show_on_home_page: "0",
        },
        res_menu: {
          id: "",
          menu_name: "",
          status: "",
          image: "",
          menu_name_thai: "",
          parent_menu_id:"",
          is_selling_time_slot:"0",
          start_date:"",
          selling_time:"",
          is_wholeday_edit:"1",
          datetime:"",
          timeslot_id:0,
          promcode_applicable:"",
          non_medicine_flag:"",
          show_on_home_page:"",
        },
        typeForm:{
          item_name:"",
          item_name_thai:"",
          item_description:"",
          item_description_thai:"",
          is_available:"1",
          is_featured:"0",
          price :"",
          item_type:"2",
          quantity:"",
          is_selling_time_slot:"0",
          qty_reset:"Never",
          is_wholeday:"1",
          start_date:"",
          restaurant_menu_id:"",
          selling_time:"",
          restaurant_id : this.$route.params.restaurant_id,
          mrp:"",
          taxslab:"",
          custome_fields: []
        },
        menu_item:{
          item_name:"",
          item_name_thai:"",
          item_description:"",
          item_description_thai:"",
          is_available:"1",
          is_featured:"",
          price :"",
          item_type:"2",
          quantity:"",
          is_selling_time_slot:"0",
          qty_reset:"",
          is_wholeday_edit:"1",
          start_date:"",
          restaurant_menu_id:"",
          selling_time:"",
          mrp:"",
          taxslab:0,
          custome_fields: []
        },
        menu_item_image:{
          image_name:[],
          restaurant_menu_item_id:"",
          restaurant_id:"",
        },
        res_image : "",
        menu_media_path: "",
        menu_item_media_path:"",
        menu_image_delete_url: routes.resImageDeleteUrl,
        menu_item_image_delete_url: routes.menuImageDeleteUrl,
        item_image : [],
        showTimeRangePanel: false,
        import_file : '',
        import_image_zip: '',
        custome_search: "",
        searchData:debounce(()=>{
          this.listitembyid();
        },500),
        category_id: '',
        category : [],
        vendor_id: "",
        batchList: [],
        batchTimer: 0,
        failbatch: [],
        largeImportLoader: false,
        largeDataImport: false,
        largeDataUpdate: false,
        largeitemDataUpdate:false,
        mapMenuItemModel: false,
        mapMenuItemLoader: false,
        depotItemList: [],
        mapItemform: {
          restaurant_menu_item_id: 0,
          item_name: '',
          master_menu_item_id: null
        },
        deleteAllMenuItemLoader: false,
        itemCurrentPage:1,
        automenuSelection:true,
    };
  },
  mounted() {
    if (this.$route.query) {
      if (this.$route.query.menu && this.$route.query.menu != '') {
        this.menu_id = atob(this.$route.query.menu);
      }
      if (this.$route.query.page && this.$route.query.page != '') {
        this.itemCurrentPage = atob(this.$route.query.page);
      }
    }
    this.getBatchAutoLoad();
    this.getTimeSlot();
    // this.getmenu();
    this.getMenuCategories();
    this.getRestaurantById();
    this.getTaxSlablist();
    this.getSetting();
    this.vendor_id = get_user().vendor_id || 40818;
  },
  watch:{
    'menu_item.is_wholeday' : function(){
    }
  },
  validations: {
    typeform: {
      menu_name: {
        required,
      },
      parent_menu_id: {
        required: requiredIf(function(form){
          return this.category_count > '0';
        }),
      },
      is_selling_time_slot:{},
      start_date: {
        // required: requiredIf(function(form){
        //   return this.typeform.is_selling_time_slot == '1';
        // }),
      },
    },
    res_menu: {
      menu_name: {
        required,
      },
      parent_menu_id: {
        required: requiredIf(function(form){
          return this.category_count > '0';
        }),
      },
      is_selling_time_slot:{},
      datetime: {
        // required: requiredIf(function(form){
        //   return this.res_menu.is_selling_time_slot == '1';
        // }),
      },
    },
    typeForm:{
      item_name: {
        required,
      },
      price: {
        required,
        floating
      },
      quantity: {
        required,
        numeric
      },
      start_date: {
        // required: requiredIf(function(form){
        //   return this.typeForm.is_selling_time_slot == '1';
        // }),
      },
    },
    menu_item:{
      item_name: {
        required,
      },
      price: {
        required,
        floating
      },
      quantity: {
        required,
        numeric
      },
      datetime: {
        // required: requiredIf(function(form){
        //   return this.menu_item.is_selling_time_slot == '1';
        // }),
      },
    },
    import_file: {
      required,
    },
  },
  methods: {
    getRestaurantById() {
      vendorService
        .getRestaurantById({ restaurant_id: this.$route.params.restaurant_id })
        .then((response) => {
          const {
            name,
            restaurant_id,
          } = response.data.result;
          this.restaurant_id = response.data.result.restaurant_id;
          this.restaurant = {
            name,
            restaurant_id,
          };
        }).catch((error) => {
           if(error.response.status == '403') {
              this.$router.push({name: 'vendor-list'});
           }
        });
    },
    handleRangeClose() {
      this.showTimeRangePanel = false;
    },
    disabledBeforeTodayAndAfterAWeek(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0));
    },
    toggleTimeRangePanel() {
      this.showTimeRangePanel = !this.showTimeRangePanel;
    },
    onImagedeleted(value){
        this.res_image = ""
    },
    onMenuImageDeleted(value) {
      this.item_image = this.item_image.filter(doc => doc.image_name !== value);
    },
    changeMenu(id){
      this.menu_id = id;
      this.typeForm.restaurant_menu_id = id;
      this.listitembyid(1);
    },
    listitembyid(page=1) {
      this.itemCurrentPage = page;
      this.loading = true;
      vendorService.getItemByMenuID({ 
        menu_id: this.menu_id,
        search: this.search,
        page: page && page > 0 ? page : 1,
        restaurant_id: this.$route.params.restaurant_id,
        category_id : this.category_id,
        ismapping : this.ismapping
      }).then((response) => {
        this.loading = false;
        this.item_count = response.data.result.get_menus_item.data.length;
        this.items = response.data.result.get_menus_item.data;
        this.paginations = response.data.result.get_menus_item;
        this.menu = response.data.result.get_restaurant_menu;
      });
    },
    getmenu() {
      vendorService
      .getRestaurantMenu({ restaurant_id: this.$route.params.restaurant_id, category_id : this.category_id })
      .then((response) => {
        this.menu = response.data.result;
        let menuId = "";
        let itempage = 1;
        if(response.data.result.length > 0){
          if (this.automenuSelection && this.menu_id > 0) {
            this.automenuSelection = false;
            let findmenu = this.menu.find(m => m.id == this.menu_id)
            if (findmenu) {
              menuId = findmenu.id;
            }
            itempage = this.itemCurrentPage??1;
            this.$router.replace({query: {}});
          } else {
            menuId = this.menu[0].id;
          }
          this.menu_id = menuId;
          this.typeForm.restaurant_menu_id = menuId;
          this.listitembyid(itempage);
        }else{
          this.menu_id = '';
          this.listitembyid(itempage);
        }
      });
    },
    getMenuCategories() {
      vendorService
        .getMenuCategories({ restaurant_id: this.$route.params.restaurant_id })
        .then((response) => {
          this.category_count = response.data.result.length;
          this.category = response.data.result;
        });
    },
    getTaxSlablist() {
      taxSlabService.getTaxSlab()
      .then(response => {
        if (response.data.result.length > 0) {
          this.taxslablist = response.data.result;
        } else {
          this.taxslablist = [];
        }        
      });
    },
    getSetting() {
      settingService.getSetting().then((response) => {
        if (response.data.result !== undefined && response.data.result.custom_item != null) {
          if (response.data.result.custom_item.length > 0) {
            this.custom_item = response.data.result.custom_item;
            this.setCustomItemtoDefault();
          }
        }
      });
    },
    setCustomItemtoDefault() {
      let createNewId = 1;
      this.custom_item.forEach((el) => {
        let data = {
          id: createNewId,
          custom_key: el.name,
          custom_value: ''
        };
        this.typeForm.custome_fields.push(data);
        createNewId++;
      });
    },
    addMenu() {
      this.$v.typeform.$touch();
      if (this.$v.typeform.$invalid) {
        return;
      } else {
        if (this.typeform.selling_time == "WholeDay") {
          this.typeform.is_wholeday = '1';
          this.typeform.is_selling_time_slot = '0';
        } else {
          this.typeform.is_wholeday = '0';
          this.typeform.is_selling_time_slot = '1';
        }
        const fd = new FormData();
        fd.append("menu_name", this.typeform.menu_name);
        fd.append("status", this.typeform.status);
        fd.append("promcode_applicable", this.typeform.promcode_applicable);
        fd.append("non_medicine_flag", this.typeform.non_medicine_flag);
        fd.append("show_on_home_page", this.typeform.show_on_home_page);
        fd.append("image", this.typeform.image);
        fd.append("menu_name_thai", this.typeform.menu_name_thai);
        fd.append("restaurant_id", this.$route.params.restaurant_id);
        fd.append("parent_menu_id", this.typeform.parent_menu_id);
        fd.append("is_selling_time_slot", this.typeform.is_selling_time_slot);
        fd.append("start_date", this.typeform.start_date);
        fd.append("is_wholeday", this.typeform.is_wholeday);
        fd.append("selling_time", this.typeform.selling_time);
        fd.append("timeslot_id", this.typeform.timeslot_id);
        vendorService.addMenu(fd).then((response) => {
          if (response.data.code === 200) {
              success_message(response.data.message);
              this.typeform ={
                menu_name: "",
                status: "1",
                image: "",
                menu_name_thai:"",
                parent_menu_id:"",
                is_selling_time_slot:"0",
                start_date:"",
                selling_time:"",
                is_wholeday:"1",
                selling_time:"WholeDay",
                timeslot_id:0,
                promcode_applicable:"1",
                non_medicine_flag:"0",
                show_on_home_page:"0",
              },
              this.showModal = false;
              this.$v.typeform.$reset();
              this.getmenu();
          } else {
            error_message(response.data.message);
          }
        });
      }
    },
    fetchMenu(id) {
      return vendorService.getMenuById({ menu_id: id }).then((response) => {
        if (response.data.code === 200) {
          const{id, restaurant_id, menu_name, menu_name_thai, menu_description, image, status,parent_menu_id, is_selling_time_slot, selling_time, datetime, time, is_wholeday, timeslot_id, promcode_applicable, non_medicine_flag, show_on_home_page} = response.data.result;
          this.res_menu = {id, restaurant_id, menu_name, menu_name_thai, menu_description, status,parent_menu_id, is_selling_time_slot, selling_time, datetime, time, is_wholeday, timeslot_id, promcode_applicable, non_medicine_flag, show_on_home_page};
          this.res_image = response.data.result.image;
          this.menu_media_path = response.data.result.menu_media_path;
          this.res_menu.datetime = datetime.split("~");
          this.res_menu.is_wholeday = is_wholeday;
          this.res_menu.is_wholeday_edit = is_wholeday;
          this.res_menu = {...this.res_menu};
          this.editMenuModal = true;
        } else {
          this.$toasted.error(response.data.message);
        }
      });
    },
    updateMenu(e) {
      this.$v.res_menu.$touch();
      if (this.$v.res_menu.$invalid) {
        return;
      } else {
        if (this.res_menu.time == "WholeDay") {
          this.res_menu.is_wholeday = '1';
          this.res_menu.is_selling_time_slot = '0';
        } else {
          this.res_menu.is_wholeday = '0';
          this.res_menu.is_selling_time_slot = '1';
        }
        vendorService.editMenu({...this.res_menu, is_wholeday:this.res_menu.is_wholeday_edit})
        .then(response => {
          if (response.data.code === 200) {
            success_message(response.data.message);
            this.editMenuModal = false;
            this.getmenu();
            // this.$router.push({ name: "vendor-catalog" });
          } else {
            error_message(response.data.message);
          }
        });
      }
    },
    onChangeEventHandler(e,id){
        this.status = e;
        this.id = id;
        vendorService.updateMenuStatus({status:this.status, id:this.menu_id})
        .then((response) => {
            if (response.data.code === 200) {
                success_message(response.data.message);
            } else {
                error_message(response.data.message);
            }
        });
    },
    deleteMenuWithItem(){
      if(confirm("Do you really want to delete?")){
        vendorService.deleteMenuWithItem({id:this.menu_id,restaurant_id: this.$route.params.restaurant_id})
        .then(response => {
          if (response.data.code === 200) {
            // this.listitembyid();
            this.getmenu();
            success_message(response.data.message);
          } else {
            error_message(response.data.message);
          }
        });
      }
    },
    menuItemAdd(e) {
      const buttonValue = e.target.value;
      this.$v.typeForm.$touch();
      if (this.$v.typeForm.$invalid) {
        return;
      } else {
        vendorService.addItmeMenu({...this.typeForm, custome_fields:JSON.stringify(this.typeForm.custome_fields)})
        .then(response => {
          if (response.data.code === 200) {
            success_message(response.data.message);
            if(buttonValue == 0){
              this.typeForm = {
                item_name:"",
                item_name_thai:"",
                item_description:"",
                item_description_thai:"",
                is_available:"1",
                is_featured:"0",
                price :"",
                item_type:"2",
                quantity:"",
                is_selling_time_slot:"0",
                qty_reset:"Never",
                is_wholeday:"1",
                start_date:"",
                selling_time:"",
                mrp:"",
                taxslab:"",
                restaurant_id : this.$route.params.restaurant_id,
                restaurant_menu_id : this.typeForm.restaurant_menu_id,
                custome_fields : []
              };
            }else{
              this.typeForm = {
                item_name:"",
                item_name_thai:"",
                item_description:"",
                item_description_thai:"",
                is_available:"1",
                is_featured:"0",
                price :"",
                item_type:"2",
                quantity:"",
                is_selling_time_slot:"0",
                qty_reset:"Never",
                is_wholeday:"1",
                start_date:"",
                selling_time:"",
                mrp:"",
                taxslab:"",
                restaurant_id : this.$route.params.restaurant_id,
                restaurant_menu_id : this.typeForm.restaurant_menu_id,
                custome_fields : []
              };
              this.itemModal = false;
              this.getmenu();
            }
            this.$v.typeForm.$reset();
            this.listitembyid();
            this.setCustomItemtoDefault();
          } else {
            error_message(response.data.message);
          }
        });
      }
    },
    itemModalClose(e){
      this.taxslabtext = "";
      this.itemModal = false;
      this.typeForm = {
        item_name:"",
        item_name_thai:"",
        item_description:"",
        item_description_thai:"",
        is_available:"1",
        is_featured:"0",
        price :"",
        item_type:"2",
        quantity:"",
        is_selling_time_slot:"0",
        qty_reset:"Never",
        is_wholeday:"1",
        start_date:"",
        selling_time:"",
        taxslab:"",
        restaurant_id : this.$route.params.restaurant_id,
        restaurant_menu_id : this.typeForm.restaurant_menu_id,
        custome_fields : []
      };
      this.listitembyid();
      // this.getmenu();
      this.$v.typeForm.$reset();
      this.setCustomItemtoDefault();
    },
    getTimeSlot(){
      vendorService.getResTimeSlot({id:this.$route.params.restaurant_id})
      .then(response => {
        if (response.data.code === 200) {
          this.slot_count = response.data.result.length;
          this.slots = response.data.result;
        } else {
          this.slots = [];
          error_message(response.data.message);
        }
      });
    },
    getMenuItemById(id){
      vendorService.getMenuItem({id:id, restaurant_id: this.$route.params.restaurant_id})
      .then(response => {
        const{restaurant_menu_item_id,restaurant_menu_id, item_name,item_name_thai, item_description, item_description_thai,is_available,is_featured,price,item_type, quantity, is_selling_time_slot, qty_reset, start_date, selling_time,mrp,time_slot,datetime,time,is_wholeday,taxslab,custome_fields} = response.data.result;
        this.editItemModal = true;
        this.menu_item = {restaurant_menu_item_id, restaurant_menu_id, item_name,item_name_thai, item_description, item_description_thai,is_available,is_featured,price,item_type, quantity, is_selling_time_slot, qty_reset, start_date, selling_time,mrp,is_wholeday,taxslab,custome_fields};
        this.menu_item.datetime = datetime.split("~");
        this.menu_item.time = time;
        this.menu_item.restaurant_id = this.$route.params.restaurant_id;
        this.menu_item.is_wholeday = is_wholeday;
        this.menu_item.is_wholeday_edit = is_wholeday;
        this.menu_item = {...this.menu_item};
        this.changeTaxSlab(1, 2);
      });
    },
    deleteMenu(id){
      if(confirm("Do you really want to delete?")){
        vendorService.deleteMenu({id:id,restaurant_id: this.$route.params.restaurant_id})
        .then(response => {
          if (response.data.code === 200) {
            this.getmenu();
            success_message(response.data.message);
          } else {
            error_message(response.data.message);
          }
        });
      }
    },
    menuItemEdit(e) {
      this.$v.menu_item.$touch();
      if (this.$v.menu_item.$invalid) {
        return;
      }else{
        this.menu_item.custome_items = JSON.stringify(this.menu_item.custome_fields);
        vendorService.menuItemEdit({...this.menu_item, is_wholeday:this.menu_item.is_wholeday_edit})
        .then(response => {
          if (response.data.code === 200) {
            this.editItemModal = false;
            this.listitembyid();
            success_message(response.data.message);
          } else {
            error_message(response.data.message);
          }
        });
      }
    },
    deleteMenuItem(id){
      if(confirm("Do you really want to delete?")){
        vendorService.deleteMenuItem({id:id,restaurant_id :this.$route.params.restaurant_id})
        .then(response => {
          if (response.data.code === 200) {
            this.listitembyid();
            success_message(response.data.message);
          } else {
            error_message(response.data.message);
          }
        });
      }
    },
    addItemImage(id){
      this.imageModal = true;
      this.menu_item_image.restaurant_menu_item_id = id;
      this.menu_item_image.restaurant_id = this.$route.params.restaurant_id;
      vendorService.getMenuImage({id:id, restaurant_id: this.$route.params.restaurant_id}).then(response => {
        if(response.data.result.item_image.length > 0){
          const{id,image_name,restaurant_id,restaurant_menu_item_id,item_image} = response.data.result;
          this.menu_item_media_path = response.data.result.item_image[0].menu_media_path;
          this.item_image = response.data.result.item_image;

          this.menu_item_image = {
            id,
            restaurant_id,
            restaurant_menu_item_id,
            image_name:'',
            image_name:[],
          }
        }else{
          this.menu_item_media_path = response.data.result.menu_item_media_path;
          this.item_image = [];
        } 
      });
    },
    addMenuImage(){
      this.menu_item_image.restaurant_menu_item_id = this.menu_item_image.restaurant_menu_item_id
      this.menu_item_image.restaurant_id = this.restaurant_id;

      vendorService.addMenuImage({...this.menu_item_image,restaurant_id : this.base64(this.restaurant_id)})
      .then(response => {
        if (response.data.code === 200) {
          this.imageModal = false;
          this.menu_item_image = {
            image_name:[],
            restaurant_menu_item_id:"",
            restaurant_id:"",
          },
          this.listitembyid();
          // this.getmenu();
          success_message(response.data.message);
        } else {
          error_message(response.data.message);
        }
      });
    },
    filterInput(e){
      const key = e.key;

      // If is '.' key, stop it
      if (key === '.')
        return e.preventDefault();
      
      // OPTIONAL
      // If is 'e' key, stop it
      if (key === 'e')
        return e.preventDefault();
    },
    Uploadfile(){
      this.importModal = true;
    },
    importMenuItem(e){
      this.$v.import_file.$touch();
      if (this.$v.import_file.$invalid) {
        return;
      } else {
        vendorService.importMenuItem({import_file:this.import_file,import_image_zip: this.import_image_zip,restaurant_id :this.$route.params.restaurant_id})
        .then(response => {
          if (response.data.code === 200) {
            this.importModal = false;
            this.listitembyid(this.menu_id);
            // this.getmenu();
            success_message(response.data.message);
          } else {
            error_message(response.data.message);
          }
        });
      }
    },
    updateMenuOrder: function() {
      var items = this.menu.map(function(item, index) {
        return { item: item, order: index }
      })
      if(this.debounce) return
      var ids = items.map(item => item.item.id);

      this.debounce = setTimeout(function(items) {
        this.debounce = false;
        vendorService.updateMenuOrder({ menu_ids : ids})
        .then(response => {
          if (response.data.code === 200) {
            this.listitembyid();
            //this.getmenu();
          }
        });
      }.bind(this, items), 10)
    },
    updateItemOrder: function() {
      var items = this.items.map(function(item, index) {
        return { item: item, order: index }
      });

      if(this.debounce) return
      var ids = items.map(item => item.item.restaurant_menu_item_id);

      this.debounce = setTimeout(function(items) {
        this.debounce = false;
        vendorService.updateItemOrder({ menu_item_ids : ids})
        .then(response => {
          if (response.data.code === 200) {
            this.listitembyid();
            //this.getmenu();
          }
        });
      }.bind(this, items), 10)
    },
    updateItemAvailability(event,id){
      var availability = event.target.value;
      vendorService.updateItemAvailability({ is_available:availability, id:id, restaurant_id: this.$route.params.restaurant_id})
        .then((response) => {
            if (response.data.code === 200) {
                success_message(response.data.message);
            } else {
                error_message(response.data.message);
            }
        });
    },
    someHandler(event){
      this.typeForm.mrp = event.target.value;
      this.taxslabtext = "";
      if (this.typeForm.taxslab > 0) {
        let taxIndex = this.taxslablist.findIndex(el => el.id == this.typeForm.taxslab);
        if (this.taxslablist[taxIndex]) {
          if (this.typeForm.price != undefined && this.typeForm.price > 0) {
            let per = '0';
            if (this.taxslablist[taxIndex].tax_per.toString().length == 1) {
              per = '1.0'+this.taxslablist[taxIndex].tax_per.toString();
            } else {
              per = '1.'+this.taxslablist[taxIndex].tax_per;
            }
            per = parseFloat(per);
            let calitemwithouttax = (this.typeForm.price / per);
            this.taxslabtext = "Item price without "+this.$auth.getTaxName()+" : "+calitemwithouttax.toFixed(2);
          }
        }
      }
    },
    addMrp(event){
      this.menu_item.mrp = event.target.value;
      this.changeTaxSlab(event, 2);
    },
    menuClose(e){
      this.showModal = false;
      this.typeform ={
        menu_name: "",
        status: "1",
        image: "",
        menu_name_thai:"",
        parent_menu_id:"",
        is_selling_time_slot:"0",
        start_date:"",
        selling_time:"",
        is_wholeday:"1",
        selling_time:"WholeDay",
        timeslot_id:0,
        promcode_applicable:"1",
        non_medicine_flag:"0",
        show_on_home_page:"0",
      }
      this.getmenu();
      this.$v.typeform.$reset();
    },
    menuItemImport(){
      this.$v.import_file.$touch();
      if (this.$v.import_file.$invalid) {
        return;
      } else {
        vendorService.importMenuItem({import_file:this.import_file,import_image_zip: this.import_image_zip,restaurant_id :this.$route.params.restaurant_id})
        .then(response => {
          if (response.data.code === 200) {

            this.importModal = false;
            this.itemImportModal = false;
            this.menuItemExport = false;
            this.listitembyid(this.menu_id);
            this.getmenu();
            
            success_message(response.data.message);
          } else {
            error_message(response.data.message);
          }
        });
      }
    },
    calculateTaxPrice(itemprice = 0, taxper = 0) {
      let taxprice = 0;
      if (itemprice > 0 && taxper > 0) {
        let devider = (taxper/100) + 1;
        taxprice = (itemprice / devider);
      }
      return parseFloat(taxprice);
    },
    changeTaxSlab(event, status) {
      if (status == 1) {
        this.typeForm.taxslab = event.target.value;
        this.taxslabtext = "";
        if (event.target.value > 0) {
          let taxIndex = this.taxslablist.findIndex(el => el.id == event.target.value);
          if (this.taxslablist[taxIndex]) {
            if (this.typeForm.price != undefined && this.typeForm.price > 0) {
              // let per = '0';
              // if (this.taxslablist[taxIndex].tax_per.toString().length == 1) {
              //   per = '1.0'+this.taxslablist[taxIndex].tax_per.toString();
              // } else {
              //   per = '1.'+this.taxslablist[taxIndex].tax_per;
              // }
              // per = parseFloat(per);
              // let calitemwithouttax = (this.typeForm.price / per);
              let calitemwithouttax = this.calculateTaxPrice(this.typeForm.price, this.taxslablist[taxIndex].tax_per);
              this.taxslabtext = "Item price without "+this.$auth.getTaxName()+" : "+calitemwithouttax.toFixed(2);
            }
          }
        }
      } else if (status == 2) {
        this.taxslabtext = "";
        if (this.menu_item.taxslab > 0) {
          let taxIndex = this.taxslablist.findIndex(el => el.id == this.menu_item.taxslab);
          if (this.taxslablist[taxIndex]) {
            if (this.menu_item.price != undefined && this.menu_item.price > 0) {
              // let per = '0';
              // if (this.taxslablist[taxIndex].tax_per.toString().length == 1) {
              //   per = '1.0'+this.taxslablist[taxIndex].tax_per.toString();
              // } else {
              //   per = '1.'+this.taxslablist[taxIndex].tax_per;
              // }
              // per = parseFloat(per);
              // let calitemwithouttax = (this.menu_item.price / per);
              let calitemwithouttax = this.calculateTaxPrice(this.menu_item.price, this.taxslablist[taxIndex].tax_per);
              this.taxslabtext = "Item price without "+this.$auth.getTaxName()+" : "+calitemwithouttax.toFixed(2);
            }
          }
        }
      }
    },
    onChangeMenuTimeSlot(event, status) {
      this.typeform.timeslot_id = 0;
      this.res_menu.timeslot_id = 0;
      if (event.target.value != 'WholeDay') {
        let timeArr = event.target.value.split('-');
        if (timeArr.length == 2 && this.slots.length > 0) {
          let start_time = timeArr[0];
          let end_time = timeArr[1];
          let findTime = this.slots.find(time => (time.start_time == start_time && time.end_time));
          if (findTime) {
            if (status == 1) { //addmenu
              this.typeform.timeslot_id = findTime.id;
            } else if (status == 2) {  //editmenu
              this.res_menu.timeslot_id = findTime.id;
            }
          }
        }
      }
    },
    resetImport(){
      let files = [];
      this.import_file != '' && files.push(this.import_file);
      this.import_image_zip != '' && files.push(this.import_image_zip);
      files.length && commonService.removeTmpImage([this.import_file, this.import_image_zip])
      .then(res => {
        this.import_file = '';
        this.import_image_zip = '';
      })
    },
    largeDataImportMenuItem(e) {
      this.$v.import_file.$touch();
      if (this.$v.import_file.$invalid) {
        return;
      } else {
        let restaurant_id = 0;
        let vendor_id = 0;
        if (this.$auth.partner_login) {
          restaurant_id = this.base64(this.$auth.user.restaurant.restaurant_id);
          vendor_id = this.$auth.user.vendor_id;
        } else {
          restaurant_id = this.$route.params.restaurant_id;
          vendor_id = this.$auth.getVendorId();
        }
        if (restaurant_id == 0 || restaurant_id == '') {
          return false;
        }
        this.largeImportLoader = true;
        let data = {
          import_file:this.import_file,
          import_image_zip: this.import_image_zip,
          restaurant_id: restaurant_id,
          vendor_id: vendor_id,
        };
        vendorService.largeDataImportMenuItem(data)
        .then(response => {
          this.largeImportLoader = false;
          if (response.data.code === 200) {
            this.largeDataImport = false;
            this.getBatchAutoLoad();
            success_message(response.data.message);
          } else {
            error_message(response.data.message);
          }
        });
      }
    },
    getBatchList() {
      let restaurant_id = 0;
      let vendor_id = 0;
      if (this.$auth.partner_login) {
        restaurant_id = this.base64(this.$auth.user.restaurant.restaurant_id);
        vendor_id = this.$auth.user.vendor_id;
      } else {
        restaurant_id = this.$route.params.restaurant_id;
        vendor_id = this.$auth.getVendorId();
      }
      vendorService.getVendorBatchList({vendor_id:vendor_id, restaurant_id:restaurant_id})
      .then(response => {
        this.getmenu();
        if (response.data.code === 200) {
            this.batchList = response.data.result;
            this.failbatch = this.batchList.filter(b => (b.batch && (b.batch.failedJobs+b.batch.processedJobs) == b.batch.totalJobs));
            if (response.data.result.length == 0) {
                clearInterval(this.batchTimer);
            } else if (response.data.result.length == this.failbatch.length) {
                clearInterval(this.batchTimer);
                for (const item of this.failbatch) {
                    if (item.batch && item.batch.progress >= 100) { //Update status to success
                        this.updateBatchStatusOrDelete(item.unique_id, 1);
                    } else if (item.batch && item.batch.failedJobs > 0) { //Delete All records
                        // this.updateBatchStatusOrDelete(item.unique_id, 2);
                    }
                }
            } else {
                for (const item of this.batchList) {
                    if (item.batch && item.batch.progress >= 100) { //Update status to success
                        this.updateBatchStatusOrDelete(item.unique_id, 1);
                    } else if (item.batch && item.batch.failedJobs > 0) { //Delete All records
                        // this.updateBatchStatusOrDelete(item.unique_id, 2);
                    }
                }
            }
        } else {
          this.batchList = [];
          this.failbatch = [];
          clearInterval(this.batchTimer);
        }
      });
    },
    getBatchAutoLoad() {
      this.getBatchList();
      this.batchTimer = setInterval(()=>{
        this.getBatchList();
      }, 20000);
    },
    updateBatchStatusOrDelete(uniqueid, status) {//status,1:UpdateStatus,2:Delete
      let restaurant_id = 0;
      let vendor_id = 0;
      if (this.$auth.partner_login) {
        restaurant_id = this.base64(this.$auth.user.restaurant.restaurant_id);
        vendor_id = this.$auth.user.vendor_id;
      } else {
        restaurant_id = this.$route.params.restaurant_id;
        vendor_id = this.$auth.getVendorId();
      }
      vendorService.updateVendorBatchStatusOrDelete({uniqueid:uniqueid, status:status, restaurant_id:restaurant_id, vendor_id:vendor_id})
      .then(response => {
        if (response.data.code === 200) {
          clearInterval(this.batchTimer);
          success_message(response.data.message);
          this.getBatchAutoLoad();
        } else {
          error_message(response.data.message);
        }
      });
    },
    largeMenuItemImport(e) {
      this.$v.import_file.$touch();
      if (this.$v.import_file.$invalid) {
        return;
      } else {
        let restaurant_id = 0;
        let vendor_id = 0;
        if (this.$auth.partner_login) {
          restaurant_id = this.base64(this.$auth.user.restaurant.restaurant_id);
          vendor_id = this.$auth.user.vendor_id;
        } else {
          restaurant_id = this.$route.params.restaurant_id;
          vendor_id = this.$auth.getVendorId();
        }
        if (restaurant_id == 0 || restaurant_id == '') {
          return false;
        }
        this.largeImportLoader = true;
        let data = {
          import_file:this.import_file,
          import_image_zip: this.import_image_zip,
          restaurant_id: restaurant_id,
          vendor_id: vendor_id,
        };
        vendorService.largeDataImportMenuItem(data)
        .then(response => {
          this.largeImportLoader = false;
          if (response.data.code === 200) {
            this.largeDataUpdate = false;
            this.largeitemDataUpdate = false;
            this.getBatchAutoLoad();
            success_message(response.data.message);
          } else {
            error_message(response.data.message);
          }
        });
      }
    },
    mapMenuItem(item) {
      this.mapItemform.restaurant_menu_item_id = item.restaurant_menu_item_id;
      this.mapItemform.item_name = item.item_name;
      if (item.master_menu_item_id) {
        this.mapItemform.master_menu_item_id = {
          id: item.master_menu_item_id,
          item_name: item.item_name
        };
      }
      if (item.depotitem) {
        this.mapItemform.master_menu_item_id = {
          id: item.depotitem.master_menu_item_id,
          item_name: item.depotitem.item_name
        };
      }
      this.mapMenuItemModel = true;
      this.getDepotItemList(item.item_name, item.master_menu_item_id);
    },
    getDepotItemList(searchText='',id = 0) {
      if (searchText == '') {
        return false;
      }
      let restaurant_id = 0;
      let vendor_id = 0;
      if (this.$auth.partner_login) {
        restaurant_id = this.base64(this.$auth.user.restaurant.restaurant_id);
        vendor_id = this.$auth.user.vendor_id;
      } else {
        restaurant_id = this.$route.params.restaurant_id;
        vendor_id = this.$auth.getVendorId();
      }
      let data = {
        searchText: searchText,
        restaurant_id: restaurant_id,
        vendor_id: vendor_id,
        master_menu_item_id:id
      };
      // if (this.mapItemform.master_menu_item_id) {
      //   if (this.mapItemform.master_menu_item_id.id && this.mapItemform.master_menu_item_id.id > 0) {
      //     data.master_menu_item_id = this.mapItemform.master_menu_item_id.id;
      //   }
      // }
      vendorService.getMasterDepotItem(data)
      .then(response => {
        this.depotItemList = response.data.result;
        if(id){
          this.mapItemform.master_menu_item_id = response.data.result.find(i => i.id === id)
        }
      });
    },
    mapMenuItemUpdate(e) {
      if (this.mapItemform.master_menu_item_id) {
        this.mapMenuItemLoader = true;
        vendorService.linktoMasterDepotItem(this.mapItemform)
        .then(response => {
          success_message(response.data.message);
          this.mapMenuItemLoader = false;
          this.mapMenuItemModel = false;
          this.resetMapForm();
          this.listitembyid(this.itemCurrentPage??1);
        });
      } else {
        error_message('Select Item to Map');
      }
    },
    resetMapForm() {
      this.mapItemform = {
        restaurant_menu_item_id: 0,
        item_name: '',
        master_menu_item_id: null
      };
    },
    deleteAllMenuItem() {
      if (confirm("Do you really want to delete?")) {
        this.deleteAllMenuItemLoader = true;
        let restaurant_id = 0;
        let vendor_id = 0;
        if (this.$auth.partner_login) {
          restaurant_id = this.base64(this.$auth.user.restaurant.restaurant_id);
          vendor_id = this.$auth.user.vendor_id;
        } else {
          restaurant_id = this.$route.params.restaurant_id;
          vendor_id = this.$auth.getVendorId();
        }
        if (restaurant_id == 0 || restaurant_id == '') {
          return false;
        }
        let data = {
          restaurant_id: restaurant_id,
          vendor_id: vendor_id,
        };
        vendorService.deleteAllMenuItems(data)
        .then(response => {
          this.deleteAllMenuItemLoader = false;
          if (response.data.code === 200) {
            this.getmenu();
            success_message(response.data.message);
          } else {
            error_message(response.data.message);
          }
        });
      }
    },
    hideImportProgress(batchImportuniqueid) {
      if (batchImportuniqueid != '') {
        if (confirm("Warning!! \nAre you sure want to Hide Progress ?? \nThe progress bar will get hide but still the process will run in background")) {
          this.updateBatchStatusOrDelete(batchImportuniqueid, 1);//status,1:UpdateStatus,2:Delete
        }
      }
    }
  },
};
</script>
<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between icon-main-page" >
          <div class="page-title-left avatar-main-icon">
            <div class="avatar-xs mr-3">
              <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                <i class="bx bx-store"></i>
              </span>
            </div>
            <div class="report-tag" v-if="!$auth.partner_login">
              <h4 class="mb-0 font-size-18">{{ restaurant.name }}</h4>
              
              <!-- <p><router-link :to="{ name: 'vendor-list'}">{{ trans.trans('__JSON__.Vendor',{ vendor: $auth.setting.restaurant_name }) }}</router-link> > <router-link :to="{name: 'vendor-catalog',params: { restaurant_id: $route.params.restaurant_id}}">{{ trans.trans('__JSON__.Catalog') }}</router-link>  >  {{ restaurant.name }}</p> -->
              <p><router-link :to="{ name: 'vendor-list'}">{{ trans.trans('__JSON__.Vendor',{ vendor: $auth.setting.restaurant_name }) }}</router-link> > {{ trans.get('__JSON__.Catalog') }}</p>
            </div>
            <div v-else>
              <h4 class="mb-4 font-size-18">{{ restaurant.name }}</h4>
            </div>
          </div>
          
          <div class="page-title-right">
            <router-link v-if="!$auth.partner_login && restaurant_id" style="margin-right: 10px;"
              :to="{name: 'vendor-detail',params: { restaurant_id: base64(restaurant_id)}}"
              class="btn btn-primary btn-rounded"
            >{{ trans.get('__JSON__.Profile') }}</router-link>
            <router-link v-if="$auth.partner_login && restaurant_id" style="margin-right: 10px;"
              :to="{name: 'vendor.profile'}"
              class="btn btn-primary btn-rounded"
            >{{ trans.get('__JSON__.Profile') }}</router-link>

            <b-dropdown right variant="white" class="float-right" toggle-class="p-0" :disabled="batchList && batchList.length > 0 && (batchList.length != failbatch.length)">
              <template slot="button-content">
                <button class="btn btn-primary btn-rounded">{{ trans.get('__JSON__.Import Items') }}</button>
              </template>

              <!-- <b-dropdown-item v-if="$auth.hasPermission(config.permissions.merchant_write)" @click="Uploadfile()">{{ trans.get('__JSON__.Fresh Import Items') }}</b-dropdown-item> -->
              <!-- <b-dropdown-item v-if="$auth.hasPermission(config.permissions.merchant_write)" @click="menuItemExport = true">{{ trans.get('__JSON__.Update Items') }}</b-dropdown-item> -->
              <b-dropdown-item v-if="$auth.hasPermission(config.permissions.merchant_write)" @click="largeDataImport = true">{{ trans.get('__JSON__.Fresh Import Items') }}</b-dropdown-item>
              <!-- Update Large Data Start -->
              <b-dropdown-item v-if="$auth.hasPermission(config.permissions.merchant_write) && menu_id" @click="largeDataUpdate = true">{{ trans.get('__JSON__.Update Items') }}</b-dropdown-item>
              <!-- Update Large Data End -->
            </b-dropdown>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="batchList && batchList.length > 0">
      <div class="col-12">
        <div class="card">
          <div class="col-12 mt-2 mb-2">
            <h4 class="mb-0 font-size-18">Import Progress <i v-b-tooltip="'In-Progress data will show here'" class="mdi mdi-information"></i></h4>
          </div>
          <div class="col-12 table-main-list table-responsive custom-tbl">
            <div class="card task-box custom-tblheader mb-0">
              <div class="card-body menu-items">
                <div class="media text-center">
                  <div style="width:10%;" class="mr-4 font-weight-bolder">#</div>
                  <div style="width:10%;" class="mr-4 font-weight-bolder">TotalJob</div>
                  <div style="width:10%;" class="mr-4 font-weight-bolder">PendingJob</div>
                  <div style="width:10%;" class="mr-4 font-weight-bolder">DoneJob</div>
                  <div style="width:15%;" class="mr-4 font-weight-bolder">FailJob</div>
                  <div style="width:15%;" class="mr-4 font-weight-bolder">Progress (%)</div>
                  <div style="width:15%;" class="mr-4 font-weight-bolder">Status</div>
                  <div style="width:15%;" class="mr-4 font-weight-bolder">Action</div>
                </div>
              </div>
            </div>
            <div class="list-group yscrolling">
              <div class="card task-box mb-0" v-for="(batch, index) in batchList" :key="batch.id">
                <div class="card-body">
                  <div class="media text-center">
                    <div style="width:10%;" class="tbl-heading mr-4">{{index+1}}
                    </div>
                    <div style="width:10%;" class="tbl-heading mr-4">
                      {{batch.batch && batch.batch.totalJobs ? batch.batch.totalJobs : 0}}
                    </div>
                    <div style="width:10%;" class="tbl-heading mr-4">
                      {{batch.batch && batch.batch.pendingJobs ? batch.batch.pendingJobs : 0}}
                    </div>
                    <div style="width:10%;" class="tbl-heading mr-4">
                      {{batch.batch && batch.batch.processedJobs ? batch.batch.processedJobs : 0}}
                    </div>
                    <div style="width:15%;" class="tbl-heading mr-4">
                      {{batch.batch && batch.batch.failedJobs ? batch.batch.failedJobs : 0}}
                      <i v-if="batch.batch && batch.batch.progress && batch.batch.failedJobs > 0" :id="'fail_'+batch.id" class="mdi mdi-information text-danger"></i>
                      <b-tooltip v-if="batch.batch && batch.batch.progress && batch.batch.failedJobs > 0" :target="'fail_'+batch.id">We are trying again for failed records!!</b-tooltip>
                    </div>
                    <div style="width:15%;" class="tbl-heading mr-4">
                        <b-progress :id="'progressid_'+batch.id" :value="batch.batch.progress" :max="100" show-value variant="success" animated v-if="batch.batch && batch.batch.progress && batch.batch.progress >= 100"></b-progress>
                        <b-progress :id="'progressid_'+batch.id" :value="batch.batch.progress" :max="100" show-value variant="warning" animated v-else-if="batch.batch && batch.batch.progress && batch.batch.failedJobs > 0"></b-progress>
                        <b-progress :id="'progressid_'+batch.id" :value="batch.batch.progress" :max="100" show-value variant="warning" animated v-else-if="batch.batch && (batch.batch.progress >= 0 && batch.batch.progress < 100)"></b-progress>
                        <b-tooltip :target="'progressid_'+batch.id">{{batch.batch.progress+'%'}}</b-tooltip>
                    </div>
                    <div style="width:15%;" class="tbl-heading mr-4" v-if="batch.batch && batch.batch.progress && batch.batch.progress >= 100">
                      <h5 class="text-success">Done</h5>
                    </div>
                    <div style="width:15%;" class="tbl-heading mr-4" v-else-if="batch.batch && batch.batch.progress && batch.batch.failedJobs > 0">
                      <!-- <h5 class="text-danger">{{batch.batch.failedJobs+' Job Fail'}} <i v-b-tooltip="'We are trying again for failed records!!'" class="mdi mdi-information"></i></h5> -->
                      <h5 class="text-warning">In Progress</h5>
                    </div>
                    <div style="width:15%;" class="tbl-heading mr-4" v-else-if="batch.batch && (batch.batch.progress >= 0 && batch.batch.progress < 100)">
                      <h5 class="text-warning">In Progress</h5>
                    </div>
                    <div style="width:15%;" class="tbl-heading mr-4" v-else>
                      <h5>Wait For The Progress</h5>
                    </div>
                    <div style="width:15%;" class="tbl-heading mr-4">
                      <button v-if="batch.unique_id && batch.unique_id != ''" class="btn btn-primary btn-rounded" @click="hideImportProgress(batch.unique_id)">
                        Hide Progress
                      </button>
                      <span v-else>-</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="drag-container">
      <div class="row drag-list menu-left-main">
        <div class="col-lg-4 drag-column menu-box-main">
          <div class="card">
            <div class="card-body menu-body">
              <div class="menu-box">
                <!-- dropdown -->
                <div class="menu-add">
                  <div right variant="white" class="float-right" toggle-class="p-0" v-if="$auth.hasPermission(config.permissions.merchant_write)">
                    <button class="btn btn-success btn-rounded mb-0 mr-0" @click="showModal = true" :disabled="batchList && batchList.length > 0 && (batchList.length != failbatch.length)">
                      <i class="mdi mdi-plus mr-1"></i> {{ trans.get('__JSON__.Add') }}
                    </button>
                  </div>
                  <!-- <div right variant="white" class="float-right mr-1" toggle-class="p-0" v-if="$auth.hasPermission(config.permissions.merchant_write) && menu && menu.length > 0">
                    <button class="btn btn-danger btn-rounded mb-0 mr-0" @click="deleteAllMenuItem" :disabled="(batchList && batchList.length > 0 && (batchList.length != failbatch.length)) || deleteAllMenuItemLoader">
                      <i class="mdi mdi-minus mr-1"></i> Delete All
                      <b-spinner v-if="deleteAllMenuItemLoader" class="align-middle"></b-spinner>
                    </button>
                  </div> -->
                  <span class="drag-column-header">
                    <h4 class="card-title mb-4 pb-1">{{ trans.get('__JSON__.Menu') }} <i v-b-tooltip="'Do not update or delete any Menus while import'" v-if="batchList && batchList.length > 0 && (batchList.length != failbatch.length)" class="mdi mdi-information text-danger"></i></h4>
                  </span>
                </div>

                <div class="mt-3" v-if="category_count > 0">
                  <div class="form-group">
                    <!-- <label class="control-label">{{ trans.get('__JSON__.Category') }}</label> -->
                      <select class="custom-select" v-model="category_id" @change="getmenu()">
                        <option value="" selected>{{ trans.get('__JSON__.All') }}</option>
                       <option v-for="con in category" :value="con.id" :key="con.id">{{con.name}}</option>
                      </select>
                  </div>
                </div>

                <draggable class="list-group menu-task" group="menus" :list="menu" @end="updateMenuOrder" v-model="menu">
                  <template v-for="menus in menu">
                  <div
                    :key="menus.id"
                    v-on:click="changeMenu(menus.id)"
                    class="card task-box"
                    :class="{ 'active': menu_id === menus.id }"
                    v-if="(search !== '' && menus.item_count !== 0) || (search == '')"
                  >
                    <div class="card-body">
                      <b-dropdown right variant="white" class="float-right" toggle-class="p-0"  v-if="$auth.hasPermission(config.permissions.merchant_write)">
                        <template slot="button-content">
                          <i class="mdi mdi-dots-vertical m-0 text-muted h5"></i>
                        </template>
                        <b-dropdown-item @click="fetchMenu(menus.id)">
                          <i class="fas fa-pencil-alt text-success mr-1"></i> {{ trans.get('__JSON__.Edit') }}
                        </b-dropdown-item>
                        <b-dropdown-item @click="deleteMenu(menus.id)">
                          <i class="fas fa-trash-alt text-danger mr-1"></i> {{ trans.get('__JSON__.Delete') }}
                        </b-dropdown-item>
                      </b-dropdown>
                      
                      <div class="float-right swtich-task check-status">
                        <b-form-checkbox
                            v-if="$auth.hasPermission(config.permissions.merchant_write)"
                            v-model="menus.status"
                            switch
                            v-bind:id="menus.id.toString()"
                            @change="onChangeEventHandler($event,menus.id)"
                            value="1"
                            unchecked-value="0"
                        ></b-form-checkbox>
                      </div>
                      <div>
                        <h5 class="font-size-15 title">{{ menus.menu_name }}({{menus.item_count}})</h5>
                      </div>
                      <p
                        class="badge badge-pill font-size-12" v-bind:id="menus.id" 
                        :class=" { 
                        'badge-soft-success': (menus.status == '1'),
                        'badge-soft-danger': (menus.status == '0' )}"
                        >
                        <span v-if="menus.status == '0'">{{ trans.get('__JSON__.Inactive') }}</span>
                        <span v-else>{{ trans.get('__JSON__.Active') }}</span>
                      </p>
                    </div>
                  </div>
                  </template>
                </draggable>
              </div>
            </div>
          </div>
        </div>
        <!-- end col-->

        <div class="col-lg-8 drag-column">
          <div class="card">
            <div class="card-body catalog-main">
              <div class="catalog-item">
                <span class="drag-column-header">
                  <h4 class="card-title">{{ trans.get('__JSON__.Item') }}
                    <i v-b-tooltip="'Do not update or delete any Items while import'" v-if="batchList && batchList.length > 0 && (batchList.length != failbatch.length)" class="mdi mdi-information text-danger"></i>
                  </h4>
                </span>
                <div class="item-menu-main">
                  <div class="search-box mr-2 mb-2 d-inline-block">
                    <div class="position-relative">
                      <input type="text" class="form-control" id="search" @keyup="searchData()" v-model="search" :placeholder="this.trans.get('__JSON__.Search')" />
                      <i class="bx bx-search-alt search-icon"></i>
                    </div>
                  </div>
                  <div class="mr-2 mb-2 d-inline-block">
                    <select class="custom-select" v-model="ismapping" @change="searchData();">
                      <option value="0" selected>All</option>
                      <option value="1">Map Item</option>
                      <option value="2">Un-Map Item</option>
                    </select>
                  </div>
                  <button
                    v-if="$auth.hasPermission(config.permissions.merchant_write) && menu_id"
                    type="button"
                    class="btn btn-success btn-rounded mb-2 mr-2"
                    @click="itemModal = true"
                    :disabled="batchList && batchList.length > 0 && (batchList.length != failbatch.length)"
                  >
                    <!-- <i class="mdi mdi-plus mr-1"></i>  -->
                    {{ trans.get('__JSON__.Add') }}
                  </button>
                  <!-- <button
                    v-if="$auth.hasPermission(config.permissions.merchant_write) && menu_id"
                    type="button" class="btn btn-danger btn-rounded mb-2 mr-2" @click="deleteMenuWithItem();" :disabled="batchList && batchList.length > 0 && (batchList.length != failbatch.length)">
                    <i class="mdi mdi-trash-can mr-1"></i> 
                    {{ trans.get('__JSON__.Delete') }}
                  </button> -->

                  <!-- <button
                    v-if="$auth.hasPermission(config.permissions.merchant_write)"
                    type="button" class="btn btn-primary btn-rounded mb-2 mr-2" @click="downloadsCSV();">
                    {{ trans.get('__JSON__.Export') }}
                  </button> -->

                  <!-- <button
                    v-if="$auth.hasPermission(config.permissions.merchant_write)"
                    type="button" class="btn btn-primary btn-rounded mb-2 mr-2" @click="itemImportModal = true" :disabled="batchList && batchList.length > 0 && (batchList.length != failbatch.length)">
                    {{ trans.get('__JSON__.Bulk Update') }}
                  </button> -->
                  <button
                    v-if="$auth.hasPermission(config.permissions.merchant_write) && menu_id"
                    type="button" class="btn btn-primary btn-rounded mb-2 mr-2" @click="largeitemDataUpdate = true" :disabled="batchList && batchList.length > 0 && (batchList.length != failbatch.length)">
                    {{ trans.get('__JSON__.Bulk Update') }}
                  </button>
                </div>
              </div>
              <div class="left-card">
                <!-- dropdown -->
                <draggable class="list-group" group="item" :list="items" @end="updateItemOrder" v-model="items">
                  <!-- <div class="col-12">
                    <div class="text-center text-danger my-2" v-if="loading">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>{{trans.get('__JSON__.Loading...')}}</strong>
                    </div>
                  </div> -->

                  <div
                    v-for="item in items"
                    :key="item.restaurant_menu_item_id"
                    class="card task-box"
                  >
                    <div class="card-body menu-items">
                      <div class="media">
                        <div class="avatar-md mr-4">
                          <span class="text-danger font-size-16" v-bind:id="item.restaurant_menu_item_id" >
                          <div v-if="item.item_image.length > 0">
                            <img class="vendor-img avatar-xs" :src="`${item.item_image[0].item_image_path}`" alt height="30" @error="$event.target.src=config.no_image" @click="addItemImage(item.restaurant_menu_item_id)"/>
                          </div>
                          <div v-else>
                            <img class="vendor-img avatar-xs" :src="config.no_image" alt @click="addItemImage(item.restaurant_menu_item_id)"/>
                          </div>
                          </span>
                        </div>
                        <!-- <div v-if="item.item_type == '0'" class="veg"><span></span></div>
                        <div v-if="item.item_type == '1'" class="non-veg"><span></span></div> -->
                        <div class="media-body overflow-hidden">
                          <h5 class="font-size-15" :class="{ 'mapped-success': item.master_menu_item_id > 0 }">{{item.item_name | truncate(35, '...')}}</h5>
                          <!-- <p class="text-truncate mb-4 overflow-hidden">{{item.item_description | truncate(50, '...')}}</p> -->
                          <ul class="list-inline">
                            <li v-b-tooltip.hover.top class="list-inline-item">Qty : {{item.quantity}}</li>
                            <li v-b-tooltip.hover.top class="list-inline-item">
                              <span v-html="currency"></span>
                              {{item.price}}
                            </li>
                          </ul>
                        </div>
                        <div class="task-status catalog-task-status newset-media">
                      	  
                        <div class="cst-swtch-set">
                          <div class="list-available">
                            <span v-if="item.customization.length != 0" class="badge badge-pill font-size-12 badge-soft-primary">Customized</span>
                          </div>
                          <b-dropdown right variant="white" class="float-right" toggle-class="p-0"  v-if="$auth.hasPermission(config.permissions.merchant_write) && !$auth.partner_login">
                            <template slot="button-content">
                              <i class="mdi mdi-dots-vertical m-0 text-muted h5"></i>
                            </template>
                            <!-- <b-dropdown-item v-if="item.customization.length == 0" :to="{name: 'vendor-add-customization',params: { menu_item_id: base64(item.restaurant_menu_item_id)}, query: { menu: base64(item.restaurant_menu_id), page: base64(itemCurrentPage)}}">
                              <i class="fas fa-plus text-success mr-1"></i> {{ trans.get('__JSON__.Add Customization') }}
                            </b-dropdown-item>
                            <b-dropdown-item v-else :to="{name: 'vendor-add-customization',params: { menu_item_id: base64(item.restaurant_menu_item_id)}, query: { menu: base64(item.restaurant_menu_id), page: base64(itemCurrentPage)}}">
                              <i class="fas fa-eye text-success mr-1"></i> {{ trans.get('__JSON__.View Customization') }}
                            </b-dropdown-item> -->
                            <b-dropdown-item @click="getMenuItemById(item.restaurant_menu_item_id)">
                              <i class="fas fa-pencil-alt text-success mr-1"></i> {{ trans.get('__JSON__.Edit') }}
                            </b-dropdown-item>
                            <b-dropdown-item @click="mapMenuItem(item)">
                              <i class="fas fa-map-marker-alt text-success mr-1"></i> 
                              {{item.master_menu_item_id > 0 ? 'Re-Map' : 'Map'}}
                            </b-dropdown-item>
                            <b-dropdown-item @click="deleteMenuItem(item.restaurant_menu_item_id)">
                              <i class="fas fa-trash-alt text-danger mr-1"></i> {{ trans.get('__JSON__.Delete') }}
                            </b-dropdown-item>
                          </b-dropdown>
                          <b-dropdown right variant="white" class="float-right" toggle-class="p-0"  v-else>
                            <template slot="button-content">
                              <i class="mdi mdi-dots-vertical m-0 text-muted h5"></i>
                            </template>
                            <!-- <b-dropdown-item v-if="item.customization.length == 0" :to="{name: 'vendor.vendor.add.customization',params: { menu_item_id: base64(item.restaurant_menu_item_id)}, query: { menu: base64(item.restaurant_menu_id), page: base64(itemCurrentPage)}}">
                              <i class="fas fa-plus text-success mr-1"></i> {{ trans.get('__JSON__.Add Customization') }}
                            </b-dropdown-item>
                            <b-dropdown-item v-else :to="{name: 'vendor.vendor.add.customization',params: { menu_item_id: base64(item.restaurant_menu_item_id)}, query: { menu: base64(item.restaurant_menu_id), page: base64(itemCurrentPage)}}">
                              <i class="fas fa-eye text-success mr-1"></i> {{ trans.get('__JSON__.View Customization') }}
                            </b-dropdown-item> -->
                            <b-dropdown-item @click="getMenuItemById(item.restaurant_menu_item_id)">
                              <i class="fas fa-pencil-alt text-success mr-1"></i> {{ trans.get('__JSON__.Edit') }}
                            </b-dropdown-item>
                            <b-dropdown-item @click="deleteMenuItem(item.restaurant_menu_item_id)">
                              <i class="fas fa-trash-alt text-danger mr-1"></i> {{ trans.get('__JSON__.Delete') }}
                            </b-dropdown-item>
                          </b-dropdown>
                          </div>
                           <div class="list-available cst-small-swtch">
                            <b-form-group id="input-group-1">
                              <label class="switch">
                                <input type="checkbox" 
                                  id="togBtn" 
                                  name="is_available" 
                                  :value="item.is_available"
                                  v-model="item.is_available"
                                  class="switch-on" 
                                  true-value="1" 
                                  false-value="0"
                                  @change="updateItemAvailability($event,item.restaurant_menu_item_id)"/>
                                <div class="slider round">
                                  <span class="on"></span>
                                  <span class="off"></span>
                                </div>
                              </label>
                            </b-form-group>
                          </div>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </draggable>
                <div class="row col-sm-5 offset-sm-7">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">
                          <pagination :data="paginations" :limit="limit" @pagination-change-page="listitembyid"></pagination>
                      </ul>
                    </div>
                  </div>
                </div>
                <div v-if="item_count == 0 && loading == false">
                  <p class="text-center">{{ trans.get('__JSON__.No Search Result Found') }}</p>
                </div>
              </div>
            </div>
          </div>  
        </div>

        <!-- end col-->
      </div>

      <!-- Add Menu Modal Start -->
      <b-modal v-model="showModal" scrollable :title="trans.get('__JSON__.Add Menu')" title-class="font-18" centered>
        <b-form @submit.prevent="addMenu">
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                    <label class="control-label">{{ trans.get('__JSON__.Menu Image') }}</label>
                        <dropzone-popup v-model="typeform.image" id="image" acceptedFiles=".jpeg,.jpg,.png,.webp,image/webp" />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group" v-if="$auth.setting">
                        <b-form-group
                        id="input-group-1" :label="trans.get('__JSON__.Menu Name',{ lang: $auth.langauges[0].name})" label-for="menu_name" >
                        <b-form-input
                            id="menu_name"
                            name="menu_name"
                            v-model="$v.typeform.menu_name.$model"
                            type="text"
                            :placeholder="trans.get('__JSON__.Please enter menu name',{ lang: $auth.langauges[0].name})"
                            :class="{ 'is-invalid': $v.typeform.menu_name.$error }"
                        ></b-form-input>
                        <div v-if="$v.typeform.menu_name.$error" class="invalid-feedback">
                            <span
                            v-if="!$v.typeform.menu_name.required"
                            >{{ trans.get('__JSON__.Please enter menu name',{ lang: $auth.langauges[0].name}) }}</span>
                        </div>
                        </b-form-group>
                    </div>
                </div>
            </div>
            <div class="col-md-12" v-if="$auth.langauges[1].name == 'No Secondary Language'">
            </div>
            <div class="row" v-else>
                <div class="col-md-12">
                    <div class="form-group" v-if="$auth.setting">
                        <label>{{ trans.trans('__JSON__.Menu Name Secondary',{lang: $auth.langauges[1].name })}}</label>    
                        <input id="menu_name_thai" type="text" v-model="typeform.menu_name_thai" class="form-control" :placeholder="trans.get('__JSON__.Please enter menu name secondary',{lang: $auth.langauges[1].name})" name="menu_name_thai"
                        />
                    </div>
                </div>
            </div>
            <div v-if="category_count > 0">
              <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                      <label>{{ trans.get('__JSON__.Menu Category') }}</label>
                      <select class="custom-select" v-model="$v.typeform.parent_menu_id.$model" id="parent_menu_id" name="parent_menu_id" :class="{ 'is-invalid':$v.typeform.parent_menu_id.$error }"> 
                        <option value="">{{trans.get('__JSON__.Select Category')}}</option>
                        <option v-for="con in category" :value="con.id" :key="con.id">{{con.name}}</option>
                      </select>
                      <div v-if="$v.typeform.parent_menu_id.$error" class="invalid-feedback">
                        <span v-if="!$v.typeform.parent_menu_id.required">{{ trans.get('__JSON__.Please select category') }}</span>
                      </div>
                    </div>
                </div>
              </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <b-form-group
                        id="input-group-2"
                        :label="trans.get('__JSON__.Status')"
                        label-for="Status"
                        >
                        <div class="row">
                            <div class="col-sm-6">
                            <b-form-radio
                                v-model="typeform.status"
                                name="status"
                                value="1"
                                checked
                            >{{ trans.get('__JSON__.Active') }}</b-form-radio>
                            </div>
                            <div class="col-sm-6">
                            <b-form-radio
                                v-model="typeform.status"
                                name="status"
                                value="0"
                            >{{ trans.get('__JSON__.Inactive') }}</b-form-radio>
                            </div>
                        </div>
                        </b-form-group>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <b-form-group
                        id="input-group-3"
                        :label="trans.get('__JSON__.Promocode')"
                        label-for="promcode_applicable"
                        >
                        <div class="row">
                            <div class="col-sm-6">
                            <b-form-radio
                                v-model="typeform.promcode_applicable"
                                name="promcode_applicable"
                                value="1"
                                checked
                            >{{ trans.get('__JSON__.Applicable') }}</b-form-radio>
                            </div>
                            <div class="col-sm-6">
                            <b-form-radio
                                v-model="typeform.promcode_applicable"
                                name="promcode_applicable"
                                value="0"
                            >{{ trans.get('__JSON__.Not Applicable') }}</b-form-radio>
                            </div>
                        </div>
                        </b-form-group>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <b-form-group
                        id="input-group-3"
                        :label="trans.get('__JSON__.Show on APP Home')"
                        label-for="non_medicine_flag"
                        >
                        <div class="row">
                            <div class="col-sm-6">
                            <b-form-radio
                                v-model="typeform.non_medicine_flag"
                                name="non_medicine_flag"
                                value="0"
                                checked
                            >{{ trans.get('__JSON__.Yes') }}</b-form-radio>
                            </div>
                            <div class="col-sm-6">
                            <b-form-radio
                                v-model="typeform.non_medicine_flag"
                                name="non_medicine_flag"
                                value="1"
                            >{{ trans.get('__JSON__.No') }}</b-form-radio>
                            </div>
                        </div>
                        </b-form-group>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <b-form-group
                        id="input-group-3"
                        :label="trans.get('__JSON__.Promotional Category')"
                        label-for="show_on_home_page"
                        >
                        <div class="row">
                            <div class="col-sm-6">
                            <b-form-radio
                                v-model="typeform.show_on_home_page"
                                name="show_on_home_page"
                                value="1"
                                checked
                            >{{ trans.get('__JSON__.Yes') }}</b-form-radio>
                            </div>
                            <div class="col-sm-6">
                            <b-form-radio
                                v-model="typeform.show_on_home_page"
                                name="show_on_home_page"
                                value="0"
                            >{{ trans.get('__JSON__.No') }}</b-form-radio>
                            </div>
                        </div>
                        </b-form-group>
                    </div>
                </div>
            </div>
            <!--Menu base timeing start-->
            <!-- <div class="row" v-if="$auth.getVendorId() == 40818">
              <div class="col-md-12">
                <div class="form-group catalog-item-status">
                  <label class="control-label">{{trans.get('__JSON__.Selling Timeslot')}}</label>
                </div>
              </div>
            </div> -->
            <!-- <div class="row rdio-btns" v-if="$auth.getVendorId() == 40818">
              <div class="col-md-6">
                <div class="custom-control custom-radio">
                  <input type="radio" id="is_selling_time_slot1" name="is_selling_time_slot" v-model="typeform.is_selling_time_slot" class="custom-control-input selling_slot" value="1"/>
                  <label class="custom-control-label" for="is_selling_time_slot1">{{trans.get('__JSON__.Yes')}}</label>
                </div>
              </div>

              <div class="col-md-6">
                <div class="custom-control custom-radio">
                  <input type="radio" id="is_selling_time_slot2" name="is_selling_time_slot" v-model="typeform.is_selling_time_slot" class="custom-control-input selling_slot" value="0" checked/>
                  <label class="custom-control-label" for="is_selling_time_slot2">{{trans.get('__JSON__.No')}}</label>
                </div>
              </div>
            </div> -->
            <!-- <div class="row" v-if="$auth.getVendorId() == 40818">
              <div class="col-md-12 whole-main" v-if="typeform.is_selling_time_slot == 1">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group catalog-item-status">
                      <label class="control-label">{{trans.get('__JSON__.Whole Day')}}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="custom-control custom-radio">
                      <input type="radio" id="is_wholeday1" name="is_wholeday_add" v-model="typeform.is_wholeday" class="custom-control-input selling_slot" value="1"/>
                      <label class="custom-control-label" for="is_wholeday1">{{ trans.get('__JSON__.Yes') }}</label>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="custom-control custom-radio">
                      <input type="radio" id="is_wholeday2" name="is_wholeday_add" v-model="typeform.is_wholeday" class="custom-control-input selling_slot" value="0"/>
                      <label class="custom-control-label" for="is_wholeday2">{{ trans.get('__JSON__.No') }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
            <div class="row d-none">
              <!-- v-if="typeform.is_wholeday == 0 && typeform.is_selling_time_slot == 1 && slot_count > 0" -->
              <div class="col-md-12 timeslt">
                <label class="control-label">{{ trans.get('__JSON__.Menu time availability time slot') }} </label>
                <select class="form-control" id="selling_time" name="selling_time[]" v-model=typeform.selling_time v-bind:class="{ 'fix-height': multiple === 'true' }" @change="onChangeMenuTimeSlot($event, 1)">
                  <option value="WholeDay" selected>Whole day</option>
                  <option v-for="con in slots" :value="con.start_time + '-' + con.end_time" :key="con.id">
                    {{moment(con.start_time, ["hh:mm A"]).format("hh:mm A")}} - {{moment(con.end_time, ["hh:mm A"]).format("hh:mm A")}} {{(con.name != '' && con.name !== null) ? con.name : ''}}
                  </option>
                </select>
              </div>
            </div>
            <div class="row d-none">
               <!-- v-if="typeform.is_selling_time_slot == 1" -->
              <div class="col-md-12">
                <input type="hidden" name="start_date" id="start_date" value="">
                <input type="hidden" name="end_date" id="end_date" value="">
                <label class="control-label">Select date range if you want this menu to be available during specific date range.</label>
                  
                  <date-picker
                    v-model="$v.typeform.start_date.$model"
                    :disabled-date="disabledBeforeTodayAndAfterAWeek"
                    type="date"
                    id="start_end_date"
                    value-type="format"
                    placeholder="Select date range"
                    range
                    @close="handleRangeClose"
                    :class="{ 'is-invalid': $v.typeform.start_date.$error }"
                  >
                    <template v-slot:footer>
                      <button
                        class="mx-btn mx-btn-text"
                        @click="toggleTimeRangePanel"
                      >{{ showTimeRangePanel ? 'select date' : '' }}</button>
                    </template>
                  </date-picker>
                  <div v-if="$v.typeform.start_date.$error" class="invalid-feedback">
                    <span
                      v-if="!$v.typeform.start_date.required"
                    >{{ trans.get('__JSON__.Please select date') }}.</span>
                  </div>
              </div>
            </div>
            <!--Menu base timeing end-->
        </b-form>
        <template slot="modal-footer">
          <div class="w-100">
            <b-button
              variant="primary"
              class="float-right"
              @click="addMenu"
            >{{ trans.get('__JSON__.Submit') }}</b-button>
            <b-button class="float-left" @click="menuClose($event)">{{ trans.get('__JSON__.Close') }}</b-button>
          </div>
        </template>
      </b-modal>
      <!-- Add Menu Modal End -->

      <!-- Edit Menu Modal Start -->
      <b-modal v-model="editMenuModal" scrollable :title="trans.get('__JSON__.Edit Menu')" title-class="font-18" centered>
        <b-form @submit.prevent="updateMenu">
          <div class="row">
              <div class="col-md-12">
                  <div class="form-group">
                  <label class="control-label">{{ trans.get('__JSON__.Menu Image') }}</label>
                    <dropzone-popup
                        v-model="res_menu.image"
                        id="res_image"
                        acceptedFiles=".jpeg,.jpg,.png,.webp,image/webp"
                        :editable-images="res_image"
                        :media-path="menu_media_path+'/original'"
                        :delete-path="menu_image_delete_url"
                        @image-deleted="this.onImagedeleted"
                    />
                  </div>
              </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group" v-if="$auth.setting">
                <label>{{ trans.trans('__JSON__.Menu Name',{ lang: $auth.langauges[0].name})}}</label>
                  <input id="menu_name" type="text" class="form-control" v-model="$v.res_menu.menu_name.$model" :placeholder="trans.get('__JSON__.Please enter menu name',{ lang: $auth.langauges[0].name})" name="menu_name" :class="{ 'is-invalid':$v.res_menu.menu_name.$error }"/>
                  <div v-if="$v.res_menu.menu_name.$error" class="invalid-feedback">
                      <span v-if="!$v.res_menu.menu_name.required">{{ trans.get('__JSON__.Please enter menu name',{ lang: $auth.langauges[0].name}) }}</span>
                  </div>
              </div>
            </div>
          </div>
          <div class="col-md-12" v-if="$auth.langauges[1].name == 'No Secondary Language'">
          </div>
          <div class="row" v-else>
            <div class="col-md-12" v-if="$auth.setting">
              <div class="form-group">
                <label>{{ trans.trans('__JSON__.Menu Name Secondary',{lang: $auth.langauges[1].name })}}</label>
                <input id="menu_name_thai" type="text" v-model="res_menu.menu_name_thai" class="form-control" :placeholder="trans.get('__JSON__.Please enter menu name secondary',{lang: $auth.langauges[1].name})" name="menu_name_thai"/>
              </div>
            </div>
          </div>
          <div v-if="category_count > 0">
              <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                      <label>{{ trans.get('__JSON__.Menu Category') }}</label>
                      <select class="custom-select" v-model="$v.res_menu.parent_menu_id.$model" id="parent_menu_id" name="parent_menu_id" :class="{ 'is-invalid':$v.res_menu.parent_menu_id.$error }"> 
                        <option value="">{{trans.get('__JSON__.Select Category')}}</option>
                        <option v-for="con in category" :value="con.id" :key="con.id">{{con.name}}</option>
                      </select>
                      <div v-if="$v.res_menu.parent_menu_id.$error" class="invalid-feedback">
                        <span v-if="!$v.res_menu.parent_menu_id.required">{{ trans.get('__JSON__.Please select category') }}</span>
                      </div>
                    </div>
                </div>
              </div>
          </div>
          <div class="row">
              <div class="col-md-12">
                  <div class="form-group">
                      <b-form-group
                      id="input-group-2"
                      :label="trans.get('__JSON__.Status')"
                      label-for="Status"
                      >
                      <div class="row">
                          <div class="col-sm-6">
                          <b-form-radio
                              v-model="res_menu.status"
                              name="status"
                              value="1"
                              checked
                          >{{ trans.get('__JSON__.Active') }}</b-form-radio>
                          </div>
                          <div class="col-sm-6">
                          <b-form-radio
                              v-model="res_menu.status"
                              name="status"
                              value="0"
                          >{{ trans.get('__JSON__.Inactive') }}</b-form-radio>
                          </div>
                      </div>
                      </b-form-group>
                  </div>
              </div>
          </div>
          <div class="row">
              <div class="col-md-12">
                  <div class="form-group">
                      <b-form-group
                      id="input-group-3"
                      :label="trans.get('__JSON__.Promocode')"
                      label-for="promcode_applicable"
                      >
                      <div class="row">
                          <div class="col-sm-6">
                          <b-form-radio
                              v-model="res_menu.promcode_applicable"
                              name="promcode_applicable"
                              value="1"
                              checked
                          >{{ trans.get('__JSON__.Applicable') }}</b-form-radio>
                          </div>
                          <div class="col-sm-6">
                          <b-form-radio
                              v-model="res_menu.promcode_applicable"
                              name="promcode_applicable"
                              value="0"
                          >{{ trans.get('__JSON__.Not Applicable') }}</b-form-radio>
                          </div>
                      </div>
                      </b-form-group>
                  </div>
              </div>
          </div>
          <div class="row">
              <div class="col-md-12">
                  <div class="form-group">
                      <b-form-group
                      id="input-group-3"
                      :label="trans.get('__JSON__.Show on APP Home')"
                      label-for="non_medicine_flag"
                      >
                      <div class="row">
                          <div class="col-sm-6">
                          <b-form-radio
                              v-model="res_menu.non_medicine_flag"
                              name="non_medicine_flag"
                              value="0"
                              checked
                          >{{ trans.get('__JSON__.Yes') }}</b-form-radio>
                          </div>
                          <div class="col-sm-6">
                          <b-form-radio
                              v-model="res_menu.non_medicine_flag"
                              name="non_medicine_flag"
                              value="1"
                          >{{ trans.get('__JSON__.No') }}</b-form-radio>
                          </div>
                      </div>
                      </b-form-group>
                  </div>
              </div>
          </div>
          <div class="row">
              <div class="col-md-12">
                  <div class="form-group">
                      <b-form-group
                      id="input-group-3"
                      :label="trans.get('__JSON__.Promotional Category')"
                      label-for="show_on_home_page"
                      >
                      <div class="row">
                          <div class="col-sm-6">
                          <b-form-radio
                              v-model="res_menu.show_on_home_page"
                              name="show_on_home_page"
                              value="1"
                              checked
                          >{{ trans.get('__JSON__.Yes') }}</b-form-radio>
                          </div>
                          <div class="col-sm-6">
                          <b-form-radio
                              v-model="res_menu.show_on_home_page"
                              name="show_on_home_page"
                              value="0"
                          >{{ trans.get('__JSON__.No') }}</b-form-radio>
                          </div>
                      </div>
                      </b-form-group>
                  </div>
              </div>
          </div>
          <!--Menu base timeing start-->
          <!-- <div class="row" v-if="$auth.getVendorId() == 40818">
            <div class="col-md-12">
              <div class="form-group catalog-item-status">
                <label class="control-label">{{trans.get('__JSON__.Selling Timeslot')}}</label>
              </div>
            </div>
          </div>
          <div class="row rdio-btns" v-if="$auth.getVendorId() == 40818">
            <div class="col-md-6">
              <div class="custom-control custom-radio">
                <input type="radio" id="edit_is_selling_time_slot1" name="is_selling_time_slot" v-model="res_menu.is_selling_time_slot" class="custom-control-input selling_slot" value="1"/>
                <label class="custom-control-label" for="edit_is_selling_time_slot1">{{trans.get('__JSON__.Yes')}}</label>
              </div>
            </div>

            <div class="col-md-6">
              <div class="custom-control custom-radio">
                <input type="radio" id="edit_is_selling_time_slot2" name="is_selling_time_slot" v-model="res_menu.is_selling_time_slot" class="custom-control-input selling_slot" value="0"/>
                <label class="custom-control-label" for="edit_is_selling_time_slot2">{{trans.get('__JSON__.No')}}</label>
              </div>
            </div>
          </div> -->
          <!-- <div class="row" v-if="$auth.getVendorId() == 40818">
            <div class="col-md-12 whole-main" v-show="res_menu.is_selling_time_slot == 1">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group catalog-item-status">
                    <label class="control-label">{{trans.get('__JSON__.Whole Day')}}</label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="custom-control custom-radio">
                    <input type="radio" id="edit_is_wholeday3" name="is_wholeday_2" v-model="res_menu.is_wholeday_edit" class="custom-control-input selling_slot" value="1"/>
                    <label class="custom-control-label" for="edit_is_wholeday3">{{ trans.get('__JSON__.Yes') }}</label>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="custom-control custom-radio">
                    <input type="radio" id="edit_is_wholeday4" name="is_wholeday_2" v-model="res_menu.is_wholeday_edit" class="custom-control-input selling_slot" value="0"/>
                    <label class="custom-control-label" for="edit_is_wholeday4">{{ trans.get('__JSON__.No') }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
          <div class="row mt-3 d-none">
            <div class="col-md-12 timeslt">
              <!-- v-if="res_menu.is_wholeday_edit != 1 && res_menu.is_selling_time_slot == 1" -->
              <!-- <label class="control-label">{{ trans.get('__JSON__.Time Slot') }} </label> -->
              <label class="control-label">{{ trans.get('__JSON__.Menu time availability time slot') }} </label>
              <select class="form-control" id="time" name="time[]" v-model="res_menu.time"  @change="onChangeMenuTimeSlot($event, 2)">
                <option value="WholeDay" selected>Whole day</option>
                <option v-for="con in slots" :value="con.start_time + '-' + con.end_time" :key="con.id">
                  {{moment(con.start_time, ["hh:mm A"]).format("hh:mm A")}} - {{moment(con.end_time, ["hh:mm A"]).format("hh:mm A")}} {{(con.name != '' && con.name !== null) ? con.name : ''}}
                </option>
              </select>
            </div>
          </div>
          <div class="row d-none">
            <div class="col-md-12">
              <!-- v-show="res_menu.is_selling_time_slot == 1" -->
              <input type="hidden" name="start_date" id="edit_start_date" value="">
              <input type="hidden" name="end_date" id="edit_end_date" value="">
              <label class="control-label">Select date range if you want this menu to be available during specific date range.</label>
                
                <date-picker
                  v-model="$v.res_menu.datetime.$model"
                  :disabled-date="disabledBeforeTodayAndAfterAWeek"
                  type="date"
                  id="datetime"
                  name="datetime"
                  value-type="format"
                  placeholder="Select date range"
                  range
                  @close="handleRangeClose"
                  :class="{ 'is-invalid': $v.res_menu.datetime.$error }"
                >
                  <template v-slot:footer>
                    <button
                      class="mx-btn mx-btn-text"
                      @click="toggleTimeRangePanel"
                    >{{ showTimeRangePanel ? 'select date' : '' }}</button>
                  </template>
                </date-picker>
                <div v-if="$v.res_menu.datetime.$error" class="invalid-feedback">
                  <span
                    v-if="!$v.res_menu.datetime.required"
                  >{{ trans.get('__JSON__.Please select date') }}.</span>
                </div>
            </div>
          </div>
          <!--Menu base timeing end-->
        </b-form>
        <template slot="modal-footer">
          <div class="w-100">
            <b-button variant="primary" class="float-right" @click="updateMenu">{{ trans.get('__JSON__.Submit') }}</b-button>
            <b-button class="float-left" @click="editMenuModal=false">{{ trans.get('__JSON__.Close') }}</b-button>
          </div>
        </template>
      </b-modal>
      <!-- Edit Menu Modal End -->

      <!-- Add Item Modal Start -->
      <b-modal v-model="itemModal" scrollable :title="trans.get('__JSON__.Add Item')" title-class="font-18" centered size="lg" @hide="taxslabtext = ''">
        <form @submit.prevent="menuItemAdd">
        <div class="row">
          <div class="col-md-8">
            <div class="form-group" v-if="$auth.setting">
              <label>{{trans.trans('__JSON__.Item Name Primary',{ lang: $auth.langauges[0].name})}}</label>
              <input id="item_name" type="text" class="form-control" v-model="$v.typeForm.item_name.$model" :placeholder="trans.get('__JSON__.Enter Item Name',{lang: $auth.langauges[0].name})" name="item_name" :class="{ 'is-invalid': $v.typeForm.item_name.$error }"/>
              <div v-if="$v.typeForm.item_name.$error" class="invalid-feedback">
                <span v-if="!$v.typeForm.item_name.required">{{ trans.get('__JSON__.Please enter item name')}}</span>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group" v-if="$auth.setting">
              <label>{{trans.get('__JSON__.Item Quantity')}}</label>
              <input id="quantity" type="number" @input="filterInput" class="form-control" v-model="$v.typeForm.quantity.$model" placeholder="Enter Item Quantity" name="quantity" :class="{ 'is-invalid': $v.typeForm.quantity.$error }"
              />
              <div v-if="$v.typeForm.quantity.$error" class="invalid-feedback">
                <span v-if="!$v.typeForm.quantity.required">{{ trans.get('__JSON__.Please enter quantity')}}</span>
                <span v-if="!$v.typeForm.quantity.numeric">{{ trans.get('__JSON__.This value should be digits')}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12" v-if="$auth.langauges[1].name == 'No Secondary Language'">
        </div>
        <div class="row" v-else>
          <div class="col-md-8" v-if="$auth.setting">
            <div class="form-group">
              <label>{{trans.get('__JSON__.Item Name Secondary',{lang: $auth.langauges[1].name })}}</label>
              <input id="item_name_thai " type="text" class="form-control" v-model="typeForm.item_name_thai " :placeholder="trans.get('__JSON__.Enter Item Name Secondary',{lang: $auth.langauges[1].name})" name="item_name_thai "
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">  
            <div class="form-group">
              <label>{{trans.get('__JSON__.Item Price')}} (<span v-html="currency"></span>)</label>
              <input id="price" type="number" @input="filterInput" v-on:keyup="someHandler($event)" class="form-control" v-model="$v.typeForm.price.$model" placeholder="Enter Item Price" name="price" :class="{ 'is-invalid': $v.typeForm.price.$error }" />
              <div v-if="$v.typeForm.price.$error" class="invalid-feedback">
                <span v-if="!$v.typeForm.price.required">{{ trans.get('__JSON__.Please enter price')}}</span>
                <span v-if="!$v.typeForm.price.floating">{{ trans.get('__JSON__.This value should be digits')}}</span>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label>{{trans.get('__JSON__.Item MRP')}} (<span v-html="currency"></span>) <span v-b-tooltip.hover.v-light title="When Item Price will be less Then MRP It will show with strikethrough In Ordering App (Eg. $1000)" class="font-size-15"><i class="bx bx-info-circle"></i></span></label>
              <input id="mrp" type="number" @input="filterInput" class="form-control" v-model="typeForm.mrp" placeholder="Enter Item MRP" name="mrp"/>
            </div>
          </div>
          <div class="col-md-4" v-if="taxslablist.length > 0">
            <div class="form-group">
              <label>{{trans.get('__JSON__.Item') + " " + $auth.getTaxName()}}</label>
              <select
                class="custom-select"
                v-model="typeForm.taxslab"
                id="taxslab"
                name="taxslab"
                @change="changeTaxSlab($event, 1)"
              >
                <option value>{{ trans.get('__JSON__.None') }}</option>
                <option
                  v-for="res in taxslablist"
                  :value="res.taxslab"
                  :key="res.taxslab"
                >{{res.tax_per + '%'}} - {{res.tax_name}}</option>
              </select>
              {{taxslabtext}}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12" v-if="$auth.setting">
            <div class="form-group">
              <label>{{trans.trans('__JSON__.Item Description Primary',{ lang: $auth.langauges[0].name})}}</label>
              <textarea rows="5" id="item_description" type="text" class="form-control" v-model="typeForm.item_description" :placeholder="trans.get('__JSON__.Enter Item Description Primary',{lang: $auth.langauges[0].name})" name="item_description"
              ></textarea>
            </div>
          </div>
        </div>
        <div class="col-md-12" v-if="$auth.langauges[1].name == 'No Secondary Language'">
        </div>
        <div class="row" v-else>
          <div class="col-md-12" v-if="$auth.setting">
            <div class="form-group">
              <label>{{trans.get('__JSON__.Item Description Secondary',{lang: $auth.langauges[1].name })}}</label>
              <textarea rows="5" id="item_description_thai" type="text" class="form-control" v-model="typeForm.item_description_thai" :placeholder="trans.get('__JSON__.Enter Item Description Secondary',{lang: $auth.langauges[1].name})"  name="item_description_thai"
              ></textarea>
            </div>
          </div>
        </div>

        <div class="row status-switch">
          <div class="col-md-4">
            <div class="form-group catalog-item-status">
              <label class="control-label">{{trans.get('__JSON__.Status')}}</label>
            </div>
          </div>
          <div class="col-md-4 pl-0 d-none">
            <div class="form-group catalog-item-status">
              <label class="control-label">{{trans.get('__JSON__.Featured Item')}}</label>
            </div>
          </div>
          <div class="col-md-4 pl-0 d-none">
            <div class="form-group catalog-item-status">
              <label class="control-label">{{trans.get('__JSON__.Quantity Reset')}}</label>
            </div>
          </div>
        </div>

        <div class="row cstm-switch">
          <div class="col-md-4">
            <b-form-group id="input-group-1">
              <label class="switch">
                <input
                  type="checkbox"
                  id="togBtn"
                  class="switch-on"
                  checked
                  v-model="typeForm.is_available"
                  true-value="1"
                  false-value="0"
                />
                <div class="slider round">
                  <span class="on">{{ trans.get('__JSON__.On') }}</span>
                  <span class="off">{{ trans.get('__JSON__.Off') }}</span>
                </div>
              </label>
            </b-form-group>
          </div>
          <div class="col-sm-4 pl-0 d-none">
            <b-form-group id="input-group-1">
              <label class="switch">
                <input
                  type="checkbox"
                  id="togBtn"
                  class="switch-on"
                  checked
                  v-model="typeForm.is_featured"
                  true-value="1"
                  false-value="0"
                />
                <div class="slider round">
                  <span class="on">{{ trans.get('__JSON__.On') }}</span>
                  <span class="off">{{ trans.get('__JSON__.Off') }}</span>
                </div>
              </label>
            </b-form-group>
          </div>
          <div class="col-sm-4 pl-0 d-none">
            <b-form-group id="input-group-1">
              <label class="switch">
                <input
                  type="checkbox"
                  id="togBtn"
                  class="switch-on"
                  checked
                  v-model="typeForm.qty_reset"
                  true-value="Daily"
                  false-value="Never"
                />
                <div class="slider round">
                  <span class="on">{{ trans.get('__JSON__.On') }}</span>
                  <span class="off">{{ trans.get('__JSON__.Off') }}</span>
                </div>
              </label>
            </b-form-group>
          </div>
        </div>

        <div class="row d-none">
          <div class="col-md-12">
            <div class="form-group catalog-item-status">
              <label class="control-label">{{trans.get('__JSON__.Type')}}</label>
            </div>
          </div>
        </div>
        <div class="row rdio-btns d-none">
          <div class="col-md-4">
            <div class="custom-control custom-radio">
              <input type="radio" name="item_type" id="radio3" value="2" v-model="typeForm.item_type" class="custom-control-input" />
              <label class="custom-control-label" for="radio3">{{trans.get('__JSON__.None')}}</label>
            </div>
          </div>

          <div class="col-md-4">
            <div class="custom-control custom-radio">
              <input type="radio" name="item_type" id="radio1" v-model="typeForm.item_type" class="custom-control-input" value="0" />
              <label class="custom-control-label" for="radio1">{{trans.get('__JSON__.Veg')}}</label>
            </div>
          </div>

          <div class="col-md-4">
            <div class="custom-control custom-radio">
              <input type="radio" name="item_type" id="radio2" v-model="typeForm.item_type" class="custom-control-input" value="1"/>
              <label class="custom-control-label" for="radio2">{{trans.get('__JSON__.Non Veg')}}</label>
            </div>
          </div>
        </div>

        <!-- <div class="row">
          <div class="col-md-12">
            <div class="form-group catalog-item-status">
              <label class="control-label">{{trans.get('__JSON__.Selling Timeslot')}}</label>
            </div>
          </div>
        </div>
        <div class="row rdio-btns">
          <div class="col-md-6">
            <div class="custom-control custom-radio">
              <input type="radio" id="is_selling_time_slot1" name="is_selling_time_slot" v-model="typeForm.is_selling_time_slot" class="custom-control-input selling_slot" value="1"/>
              <label class="custom-control-label" for="is_selling_time_slot1">{{trans.get('__JSON__.Yes')}}</label>
            </div>
          </div>

          <div class="col-md-6">
            <div class="custom-control custom-radio">
              <input type="radio" id="is_selling_time_slot2" name="is_selling_time_slot" v-model="typeForm.is_selling_time_slot" class="custom-control-input selling_slot" value="0" checked/>
              <label class="custom-control-label" for="is_selling_time_slot2">{{trans.get('__JSON__.No')}}</label>
            </div>
          </div>
        </div> -->
        <!-- <div class="row">
          <div class="col-md-12" v-if="typeForm.is_selling_time_slot == 1">
            <input type="hidden" name="start_date" id="start_date" value="">
            <input type="hidden" name="end_date" id="end_date" value="">
            <label class="control-label">{{trans.get('__JSON__.Do you want to sell this dish across multiple days')}}</label>
              
              <date-picker
                v-model="$v.typeForm.start_date.$model"
                :disabled-date="disabledBeforeTodayAndAfterAWeek"
                type="date"
                id="start_end_date"
                value-type="format"
                placeholder="Select datetime range"
                range
                @close="handleRangeClose"
                :class="{ 'is-invalid': $v.typeForm.start_date.$error }"
              >
                <template v-slot:footer>
                  <button
                    class="mx-btn mx-btn-text"
                    @click="toggleTimeRangePanel"
                  >{{ showTimeRangePanel ? 'select date' : '' }}</button>
                </template>
              </date-picker>
              <div v-if="$v.typeForm.start_date.$error" class="invalid-feedback">
                <span
                  v-if="!$v.typeForm.start_date.required"
                >{{ trans.get('__JSON__.Please select date') }}.</span>
              </div>
          </div>
        </div> -->
        <!-- <div class="row">
          <div class="col-md-12 whole-main" v-if="typeForm.is_selling_time_slot == 1">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group catalog-item-status">
                  <label class="control-label">{{trans.get('__JSON__.Whole Day')}}</label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="custom-control custom-radio">
                  <input type="radio" id="is_wholeday1" name="is_wholeday_add" v-model="typeForm.is_wholeday" class="custom-control-input selling_slot" value="1"/>
                  <label class="custom-control-label" for="is_wholeday1">{{ trans.get('__JSON__.Yes') }}</label>
                </div>
              </div>

              <div class="col-md-6">
                <div class="custom-control custom-radio">
                  <input type="radio" id="is_wholeday2" name="is_wholeday_add" v-model="typeForm.is_wholeday" class="custom-control-input selling_slot" value="0"/>
                  <label class="custom-control-label" for="is_wholeday2">{{ trans.get('__JSON__.No') }}</label>
                </div>
              </div>
            </div>
          </div>
        </div> -->

        <!-- <div class="row">
          <div class="col-md-12 timeslt" v-if="typeForm.is_wholeday == 0 && typeForm.is_selling_time_slot == 1 && slot_count > 0">
            <label class="control-label">{{ trans.get('__JSON__.Time Slot') }} </label>
            <select class="form-control" id="selling_time" name="selling_time[]" v-model=typeForm.selling_time v-bind:class="{ 'fix-height': multiple === 'true' }">
              <option v-for="con in slots" :value="con.start_time + '-' + con.end_time" :key="con.id">{{con.start_time}} - {{con.end_time}}  </option>
            </select>
          </div>
        </div> -->

        <div class="row" v-if="typeForm.custome_fields != null && typeForm.custome_fields.length > 0">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-12 catalog-item-status">
                <label class="control-label">{{ trans.get('__JSON__.Other Info') }} </label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 mb-2" v-for="(v,index) in typeForm.custome_fields" :key="index">
                <h6>{{ v.custom_key }}</h6>
                <input
                  :id="'customefields_'+index"
                  :name="'customefields_'+index"
                  type="text"
                  :placeholder="'Enter '+v.custom_key"
                  class="form-control"
                  v-model="v.custom_value"
                />
              </div>
            </div>
          </div>
        </div>

        <input type="hidden" name="restaurant_menu_id" v-model="typeForm.restaurant_menu_id" value="typeForm.restaurant_menu_id">
      </form>
      <template slot="modal-footer">
          <div class="w-100">
            <b-button variant="primary"  class="float-right add_item_"  @click="menuItemAdd($event)" value="0">{{ trans.get('__JSON__.Continuous Add') }}</b-button>
            <b-button variant="primary"  class="float-right add_item_"  @click="menuItemAdd($event)" value="1">{{ trans.get('__JSON__.Add close') }}</b-button>
            <b-button class="float-left" @click="itemModalClose($event)">{{ trans.get('__JSON__.Close') }}</b-button>
          </div>
        </template>
      </b-modal>
      <!-- Add Item Modal End -->
      
      <!-- Edit Item Modal Start -->
      <b-modal v-model="editItemModal" scrollable :title="trans.get('__JSON__.Edit Item')" title-class="font-18" centered size="lg">
        <form @submit.prevent="menuItemEdit">
        <div class="row">
          <div class="col-md-8">
            <div class="form-group" v-if="$auth.setting">
              <label>{{trans.trans('__JSON__.Item Name Primary',{ lang: $auth.langauges[0].name})}}</label>
              <input id="edit_item_name" type="text" class="form-control" v-model="$v.menu_item.item_name.$model" :placeholder="trans.get('__JSON__.Enter Item Name',{lang: $auth.langauges[0].name})" name="item_name" :class="{ 'is-invalid': $v.menu_item.item_name.$error }"/>
              <div v-if="$v.menu_item.item_name.$error" class="invalid-feedback">
                <span v-if="!$v.menu_item.item_name.required">{{ trans.get('__JSON__.Please enter item name')}}</span>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label>{{trans.get('__JSON__.Item Quantity')}}</label>
              <input id="edit_quantity" type="number" @input="filterInput" class="form-control" v-model="$v.menu_item.quantity.$model" placeholder="Enter Item Quantity" name="quantity" :class="{ 'is-invalid': $v.menu_item.quantity.$error }"
              />
              <div v-if="$v.menu_item.quantity.$error" class="invalid-feedback">
                <span v-if="!$v.menu_item.quantity.required">{{ trans.get('__JSON__.Please enter quantity')}}</span>
                <span v-if="!$v.menu_item.quantity.numeric">{{ trans.get('__JSON__.This value should be digits')}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12" v-if="$auth.langauges[1].name == 'No Secondary Language'">
        </div>
        <div class="row" v-else>
          <div class="col-md-8" v-if="$auth.setting">
            <div class="form-group">
              <label>{{trans.get('__JSON__.Item Name Secondary',{lang: $auth.langauges[1].name })}}</label>
              <input id="edit_item_name_thai " type="text" class="form-control" v-model="menu_item.item_name_thai " :placeholder="trans.get('__JSON__.Enter Item Name Secondary',{lang: $auth.langauges[1].name})" name="item_name_thai "
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label>{{trans.get('__JSON__.Item Price')}} (<span v-html="currency"></span>)</label>
              <input id="edit_price" type="number" @input="filterInput" v-on:keyup="addMrp($event)" class="form-control" v-model="$v.menu_item.price.$model" placeholder="Enter Item Price" name="price" :class="{ 'is-invalid': $v.menu_item.price.$error }"
              />
              <div v-if="$v.menu_item.price.$error" class="invalid-feedback">
                <span v-if="!$v.menu_item.price.required">{{ trans.get('__JSON__.Please enter price')}}</span>
                <span v-if="!$v.menu_item.price.floating">{{ trans.get('__JSON__.This value should be digits')}}</span>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label>{{trans.get('__JSON__.Item MRP')}} (<span v-html="currency"></span>) <span v-b-tooltip.hover.v-light title="When Item Price will be less Then MRP It will show with strikethrough In Ordering App (Eg. $1000)" class="font-size-15"><i class="bx bx-info-circle"></i></span></label>
              <input id="mrp" type="number" @input="filterInput" class="form-control" v-model="menu_item.mrp" placeholder="Enter Item MRP" name="mrp"/>
            </div>
          </div>
          <div class="col-md-4" v-if="taxslablist.length > 0">
            <div class="form-group">
              <label>{{trans.get('__JSON__.Item') + " " + $auth.getTaxName()}}</label>
              <select
                class="custom-select"
                v-bind:value="menu_item.taxslab"
                v-model="menu_item.taxslab"
                id="taxslab"
                name="taxslab"
                @change="changeTaxSlab($event, 2)"
              >
                <option v-bind:value="0" selected>{{ trans.get('__JSON__.None') }}</option>
                <option
                  v-for="res in taxslablist"
                  :value="res.taxslab"
                  :key="res.taxslab"
                >{{res.tax_per + '%'}} - {{res.tax_name}}</option>
              </select>
              {{taxslabtext}}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12" v-if="$auth.setting">
            <div class="form-group">
              <label>{{trans.trans('__JSON__.Item Description Primary',{ lang: $auth.langauges[0].name})}}</label>
              <textarea rows="5" id="edit_item_description" type="text" class="form-control" v-model="menu_item.item_description" :placeholder="trans.get('__JSON__.Enter Item Description Primary',{lang: $auth.langauges[0].name})" name="item_description"
              ></textarea>
            </div>
          </div>
        </div>
        <div class="col-md-12" v-if="$auth.langauges[1].name == 'No Secondary Language'">
        </div>
        <div class="row" v-else>
          <div class="col-md-12" v-if="$auth.setting">
            <div class="form-group">
              <label>{{trans.get('__JSON__.Item Description Secondary',{lang: $auth.langauges[1].name })}}</label>
              <textarea rows="5" id="edit_item_description_thai" type="text" class="form-control" v-model="menu_item.item_description_thai" :placeholder="trans.get('__JSON__.Enter Item Description Secondary',{lang: $auth.langauges[1].name})"  name="item_description_thai"
              ></textarea>
            </div>
          </div>
        </div>

        <div class="row status-switch">
          <div class="col-md-4">
            <div class="form-group catalog-item-status">
              <label class="control-label">{{trans.get('__JSON__.Status')}}</label>
            </div>
          </div>
          <div class="col-md-4 pl-0 d-none">
            <div class="form-group catalog-item-status">
              <label class="control-label">{{trans.get('__JSON__.Featured Item')}}</label>
            </div>
          </div>
          <div class="col-md-4 pl-0 d-none">
            <div class="form-group catalog-item-status">
              <label class="control-label">{{trans.get('__JSON__.Quantity Reset')}}</label>
            </div>
          </div>
        </div>
        <div class="row cstm-switch">
          <div class="col-md-4">
            <b-form-group id="input-group-1">
              <label class="switch">
                <input
                  type="checkbox"
                  id="edit_togBtn"
                  class="switch-on"
                  checked
                  v-model="menu_item.is_available"
                  true-value="1"
                  false-value="0"
                />
                <div class="slider round">
                  <span class="on">{{ trans.get('__JSON__.On') }}</span>
                  <span class="off">{{ trans.get('__JSON__.Off') }}</span>
                </div>
              </label>
            </b-form-group>
          </div>

          <div class="col-sm-4 pl-0 d-none">
            <b-form-group id="input-group-1">
              <label class="switch">
                <input
                  type="checkbox"
                  id="edit_togBtn"
                  class="switch-on"
                  checked
                  v-model="menu_item.is_featured"
                  true-value="1"
                  false-value="0"
                />
                <div class="slider round">
                  <span class="on">{{ trans.get('__JSON__.On') }}</span>
                  <span class="off">{{ trans.get('__JSON__.Off') }}</span>
                </div>
              </label>
            </b-form-group>
          </div>

          <div class="col-sm-4 pl-0 d-none">
            <b-form-group id="input-group-1">
              <label class="switch">
                <input
                  type="checkbox"
                  id="edit_togBtn"
                  class="switch-on"
                  checked
                  v-model="menu_item.qty_reset"
                  true-value="Daily"
                  false-value="Never"
                />
                <div class="slider round">
                  <span class="on">{{ trans.get('__JSON__.On') }}</span>
                  <span class="off">{{ trans.get('__JSON__.Off') }}</span>
                </div>
              </label>
            </b-form-group>
          </div>
        </div>

        <div class="row d-none">
          <div class="col-md-12">
            <div class="form-group catalog-item-status">
              <label class="control-label">{{trans.get('__JSON__.Type')}}</label>
            </div>
          </div>
        </div>
        <div class="row rdio-btns d-none">
          <div class="col-md-4">
            <div class="custom-control custom-radio">
              <input type="radio" name="item_type" id="edit_radio3" value="2" v-model="menu_item.item_type" class="custom-control-input" />
              <label class="custom-control-label" for="edit_radio3">{{trans.get('__JSON__.None')}}</label>
            </div>
          </div>

          <div class="col-md-4">
            <div class="custom-control custom-radio">
              <input type="radio" name="item_type" id="edit_radio1" v-model="menu_item.item_type" class="custom-control-input" value="0" />
              <label class="custom-control-label" for="edit_radio1">{{trans.get('__JSON__.Veg')}}</label>
            </div>
          </div>

          <div class="col-md-4">
            <div class="custom-control custom-radio">
              <input type="radio" name="item_type" id="edit_radio2" v-model="menu_item.item_type" class="custom-control-input" value="1"/>
              <label class="custom-control-label" for="edit_radio2">{{trans.get('__JSON__.Non Veg')}}</label>
            </div>
          </div>
        </div>

        <!-- <div class="row">
          <div class="col-md-12">
            <div class="form-group catalog-item-status">
              <label class="control-label">{{trans.get('__JSON__.Selling Timeslot')}}</label>
            </div>
          </div>
        </div>
        <div class="row rdio-btns">
          <div class="col-md-6">
            <div class="custom-control custom-radio">
              <input type="radio" id="edit_is_selling_time_slot1" name="is_selling_time_slot" v-model="menu_item.is_selling_time_slot" class="custom-control-input selling_slot" value="1"/>
              <label class="custom-control-label" for="edit_is_selling_time_slot1">{{trans.get('__JSON__.Yes')}}</label>
            </div>
          </div>

          <div class="col-md-6">
            <div class="custom-control custom-radio">
              <input type="radio" id="edit_is_selling_time_slot2" name="is_selling_time_slot" v-model="menu_item.is_selling_time_slot" class="custom-control-input selling_slot" value="0"/>
              <label class="custom-control-label" for="edit_is_selling_time_slot2">{{trans.get('__JSON__.No')}}</label>
            </div>
          </div>
        </div> -->

        <!-- <div class="row">
          <div class="col-md-12" v-show="menu_item.is_selling_time_slot == 1">
            <input type="hidden" name="start_date" id="edit_start_date" value="">
            <input type="hidden" name="end_date" id="edit_end_date" value="">
            <label class="control-label">{{trans.get('__JSON__.Do you want to sell this dish across multiple days')}}</label>
              
              <date-picker
                v-model="$v.menu_item.datetime.$model"
                :disabled-date="disabledBeforeTodayAndAfterAWeek"
                type="date"
                id="datetime"
                name="datetime"
                value-type="format"
                placeholder="Select datetime range"
                range
                @close="handleRangeClose"
                :class="{ 'is-invalid': $v.menu_item.datetime.$error }"
              >
                <template v-slot:footer>
                  <button
                    class="mx-btn mx-btn-text"
                    @click="toggleTimeRangePanel"
                  >{{ showTimeRangePanel ? 'select date' : '' }}</button>
                </template>
              </date-picker>
              <div v-if="$v.menu_item.datetime.$error" class="invalid-feedback">
                <span
                  v-if="!$v.menu_item.datetime.required"
                >{{ trans.get('__JSON__.Please select date') }}.</span>
              </div>
          </div>
        </div> -->
        <!-- <div class="row">
          <div class="col-md-12 whole-main" v-show="menu_item.is_selling_time_slot == 1">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group catalog-item-status">
                  <label class="control-label">{{trans.get('__JSON__.Whole Day')}}</label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="custom-control custom-radio">
                  <input type="radio" id="edit_is_wholeday1" name="is_wholeday_1" v-model="menu_item.is_wholeday_edit" class="custom-control-input selling_slot" value="1"/>
                  <label class="custom-control-label" for="edit_is_wholeday1">{{ trans.get('__JSON__.Yes') }}</label>
                </div>
              </div>

              <div class="col-md-6">
                <div class="custom-control custom-radio">
                  <input type="radio" id="edit_is_wholeday2" name="is_wholeday_1" v-model="menu_item.is_wholeday_edit" class="custom-control-input selling_slot" value="0"/>
                  <label class="custom-control-label" for="edit_is_wholeday2">{{ trans.get('__JSON__.No') }}</label>
                </div>
              </div>
            </div>
          </div>
        </div> -->

        <!-- <div class="row">
          <div class="col-md-12 timeslt" v-if="menu_item.is_wholeday_edit != 1 && menu_item.is_selling_time_slot == 1">
            <label class="control-label">{{ trans.get('__JSON__.Time Slot') }} </label>
            <select class="form-control" id="time" name="time[]" v-model=menu_item.time>
              <option v-for="con in slots" :value="con.start_time + '-' + con.end_time" :key="con.id">{{con.start_time}} - {{con.end_time}}  </option>
            </select>
          </div>
        </div> -->

        <div class="row" v-if="menu_item.custome_fields != null && menu_item.custome_fields.length > 0">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-12 catalog-item-status">
                <label class="control-label">{{ trans.get('__JSON__.Other Info') }} </label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 mb-2" v-for="(v,index) in menu_item.custome_fields" :key="index">
                <h6>{{ v.custom_key }}</h6>
                <input
                  :id="'customefields_'+index"
                  :name="'customefields_'+index"
                  type="text"
                  :placeholder="'Enter '+v.custom_key"
                  class="form-control"
                  v-model="v.custom_value"
                />
              </div>
            </div>
          </div>
        </div>

        <input type="hidden" name="restaurant_menu_id" v-model="menu_item.restaurant_menu_id" value="menu_item.restaurant_menu_id">
      </form>
      <template slot="modal-footer">
          <div class="w-100">
            <b-button variant="primary"  class="float-right"  @click="menuItemEdit($event)" >{{ trans.get('__JSON__.Update') }}</b-button>
            <b-button class="float-left" @click="editItemModal=false">{{ trans.get('__JSON__.Close') }}</b-button>
          </div>
        </template>
      </b-modal>
      <!-- Edit Item Modal End -->

      <!--Image Modal Start -->
      <b-modal v-if="$auth.hasPermission(config.permissions.merchant_write)" v-model="imageModal" scrollable :title="trans.get('__JSON__.Upload Item Images')" title-class="font-18" centered>
        <form @submit.prevent="addMenuImage">
          <div class="row">
            <div class="col-sm-12">
                <div class="form-group">
                  <label>{{ trans.get('__JSON__.Item Image') }}</label>
                  <dropzone-popup
                    multiple
                    v-model="menu_item_image.image_name"
                    id="image_name"
                    name="image_name"
                    acceptedFiles=".jpeg,.jpg,.png,.webp,image/webp"
                    :editable-images="item_image.map(o => o.image_name)"
                    :media-path="menu_item_media_path"
                    :delete-path="menu_item_image_delete_url"
                    :custom-params="{menu_item_id: this.menu_item_image.restaurant_menu_item_id}"
                    @image-deleted="this.onMenuImageDeleted"
                  />
                </div>
            </div>
          </div>
          <input type="hidden" name="restaurant_menu_item_id" v-model="menu_item_image.restaurant_menu_item_id" value="menu_item.restaurant_menu_id">
        </form>
        <template slot="modal-footer">
          <div class="w-100">
            <b-button variant="primary"  class="float-right"  @click="addMenuImage">{{ trans.get('__JSON__.Upload') }}</b-button>
            <b-button class="float-left" @click="imageModal=false">{{ trans.get('__JSON__.Close') }}</b-button>
          </div>
        </template>
      </b-modal>
      <!-- Image Modal End -->

      <!--Import Modal Start -->
      <b-modal v-if="$auth.hasPermission(config.permissions.merchant_write)" v-model="importModal" scrollable :title="trans.get('__JSON__.Import Menu & Item')" title-class="font-18" centered @hidden="resetImport">
        <form @submit.prevent="importMenuItem">
          <div class="row">
            <h5 class="download">{{trans.get('__JSON__.To Upload Fresh Menu & Items, Download Sample File')}} <a :href="config.appurl+'public/images/Sample_menu_item_file.xls'" download> {{ trans.get('__JSON__.from Here') }} </a> 
              <a v-if="$auth && $auth.getVendorId() > 0" class="btn btn-primary btn-sm btn-rounded" style="float: right;margin:5px;" :href="config.appurl+'blank-sample-menu-item/'+base64($auth.getVendorId())" download> Download Blank Sheet </a></h5>
            <div class="col-sm-6">
                <div class="form-group">
                  <label>{{ trans.get('__JSON__.Upload .xls file') }}</label>
                  <dropzone-popup v-model="import_file" id="import_file" name="import_file" acceptedFiles=".xls" :class="{ 'is-invalid': $v.import_file.$error }"/>
                  <div v-if="$v.import_file.$error" class="invalid-feedback cuisine-img">
                    <span
                      v-if="!$v.import_file.required"
                    >{{ trans.get('__JSON__.Please select file') }}.</span>
                  </div>
                </div>
            </div>

            <div class="col-sm-6">
                <div class="form-group">
                  <label>{{ trans.get('__JSON__.Upload ZIP file of item images (Optional)') }}</label>
                  <dropzone-popup v-model="import_image_zip" id="import_image_zip" name="import_image_zip" acceptedFiles=".zip"/>
                </div>
            </div>
          </div>
          <input type="hidden" name="restaurant_menu_item_id" v-model="menu_item_image.restaurant_menu_item_id" value="menu_item.restaurant_menu_id">
        </form>
        <template slot="modal-footer">
          <div class="w-100">
            <b-button variant="primary"  class="float-right"  @click="importMenuItem($event)">{{ trans.get('__JSON__.Upload') }}</b-button>
            <b-button class="float-left" @click="importModal=false">{{ trans.get('__JSON__.Close') }}</b-button>
          </div>
        </template>
      </b-modal>
      <!-- Import Modal End -->
      
      <!--Bulk Update Modal Start -->
      <b-modal v-if="$auth.hasPermission(config.permissions.merchant_write)" v-model="itemImportModal" scrollable :title="trans.get('__JSON__.Bulk Update Menu Items')" title-class="font-18" centered @hidden="resetImport">
        <form @submit.prevent="menuItemImport">
          <div class="row">
            <h5 class="download">{{ trans.get('__JSON__.You can download Existing Menu XLS File and Upload New File with Changes') }}.</h5>

            <div class="col-sm-4">
                <div class="form-group">
                  <label>{{ trans.get('__JSON__.Download Current Menu Items') }}</label>
                  <a :href="config.appurl+'export/'+ base64(this.menu_id)+'/'+ this.$route.params.restaurant_id +'/'+base64('item')" target="_blank">
                    <img alt class="rounded avatar-lg image_thumb" :src="config.basepath+'xls_download.png'"/>
                  </a>
                </div>
            </div>
            
            <div class="col-sm-4">
                <div class="form-group">
                  <label>{{ trans.get('__JSON__.Upload Updated Menu Items') }}</label>
                  <dropzone-popup v-model="import_file" id="import_file" name="import_file" acceptedFiles=".xls" :class="{ 'is-invalid': $v.import_file.$error }"/>
                  <div v-if="$v.import_file.$error" class="invalid-feedback cuisine-img">
                    <span
                      v-if="!$v.import_file.required"
                    >{{ trans.get('__JSON__.Please select file') }}.</span>
                  </div>
                </div>
            </div>

            <div class="col-sm-4">
              <div class="form-group">
                <label>Upload ZIP file of item images <span style="font-size: 8px;">(Optional)</span></label>
                <dropzone-popup
                  v-model="import_image_zip"
                  id="import_image_zip"
                  name="import_image_zip"
                  acceptedFiles=".zip"
                />
              </div>
            </div>

            <!-- <div class="col-sm-6">
                <div class="form-group">
                  <label>{{ trans.get('__JSON__.Upload .zip file of item images') }}</label>
                  <dropzone-popup v-model="import_image_zip" id="import_image_zip" name="import_image_zip" acceptedFiles=".zip"/>
                </div>
            </div> -->
          </div>
          <input type="hidden" name="restaurant_menu_item_id" v-model="menu_item_image.restaurant_menu_item_id" value="menu_item.restaurant_menu_id">
        </form>
        <template slot="modal-footer">
          <div class="w-100">
            <b-button variant="primary"  class="float-right"  @click="menuItemImport($event)">{{ trans.get('__JSON__.Upload') }}</b-button>
            <b-button class="float-left" @click="itemImportModal=false">{{ trans.get('__JSON__.Close') }}</b-button>
          </div>
        </template>
      </b-modal>
      <!-- Bulk Update Modal End -->

      <!--Bulk Update Menu & Item Modal Start -->
      <b-modal v-if="$auth.hasPermission(config.permissions.merchant_write)" v-model="menuItemExport" scrollable :title="trans.get('__JSON__.Update Menu & Items')" title-class="font-18" centered @hidden="resetImport">
        <form @submit.prevent="menuItemImport">
          <div class="row">
            <h5 class="download">{{ trans.get('__JSON__.You can download Existing Menu & Item XLS File and Upload New File with Changes') }}.</h5>
            <h5 class="download">{{ trans.get('__JSON__.Please Note Updating Image Files not supported & Don’t Change “ID” & “Vendor Item ID” In Excel') }}.</h5>

            <div class="col-sm-4">
              <div class="form-group">
                <label>{{ trans.get('__JSON__.Download Current Menu & Items') }}</label>
                <a v-if="$auth.partner_login" :href="config.appurl+'export/'+ base64(this.menu_id)+'/'+ base64($auth.user.restaurant.restaurant_id) +'/'+base64('menu')" target="_blank">
                  <img alt class="rounded avatar-lg image_thumb" :src="config.basepath+'xls_download.png'"/>
                </a>
                <a v-else :href="config.appurl+'export/'+ base64(this.menu_id)+'/'+ this.$route.params.restaurant_id +'/'+base64('menu')" target="_blank">
                  <img alt class="rounded avatar-lg image_thumb" :src="config.basepath+'xls_download.png'"/>
                </a>
              </div>
            </div>
            
            <div class="col-sm-4">
                <div class="form-group">
                  <label>{{ trans.get('__JSON__.Upload Updated Menu & Items') }}</label>
                  <dropzone-popup v-model="import_file" id="import_file" name="import_file" acceptedFiles=".xls" :class="{ 'is-invalid': $v.import_file.$error }"/>
                  <div v-if="$v.import_file.$error" class="invalid-feedback cuisine-img">
                    <span
                      v-if="!$v.import_file.required"
                    >{{ trans.get('__JSON__.Please select file') }}.</span>
                  </div>
                </div>
            </div>

            <div class="col-sm-4">
              <div class="form-group">
                <label>Upload ZIP file of item images <span style="font-size: 8px;">(Optional)</span></label>
                <dropzone-popup v-model="import_image_zip" id="import_image_zip" name="import_image_zip" acceptedFiles=".zip"/>
              </div>
            </div>
          </div>
          <input type="hidden" name="restaurant_menu_item_id" v-model="menu_item_image.restaurant_menu_item_id" value="menu_item.restaurant_menu_id">
        </form>
        <template slot="modal-footer">
          <div class="w-100">
            <b-button variant="primary"  class="float-right"  @click="menuItemImport($event)">{{ trans.get('__JSON__.Upload') }}</b-button>
            <b-button class="float-left" @click="menuItemExport=false">{{ trans.get('__JSON__.Close') }}</b-button>
          </div>
        </template>
      </b-modal>
      <!-- Bulk Update Menu & Item Modal End -->

      <!-- Large Data Import Modal Start -->
      <b-modal v-if="$auth.hasPermission(config.permissions.merchant_write)" v-model="largeDataImport" scrollable title="Import Menu & Item" title-class="font-18" centered @hidden="resetImport">
          <form @submit.prevent="largeDataImportMenuItem">
              <div class="row">
                <div class="mb-3 col-sm-12">
                  <h5 style="margin:0 auto;" class="download">{{trans.get('__JSON__.To Upload Fresh Menu & Items')}} 
                    <!-- <a :href="config.appurl+'public/images/anahad_sample_menu_item.csv'" download> {{ trans.get('__JSON__.from Here') }} </a> 
                    <a v-if="$auth && $auth.getVendorId() > 0" class="btn btn-primary btn-sm btn-rounded" style="float: right;margin:5px;" :href="config.appurl+'blank-sample-menu-item-csv/'+base64($auth.getVendorId())" download> Download Blank Sheet </a> -->
                  </h5>
                  <a v-if="$auth && $auth.getVendorId() > 0" :href="config.appurl+'blank-sample-menu-item-csv/'+base64($auth.getVendorId())" download>{{trans.get("__JSON__.Download Blank Sheet")}}</a>
                </div>
                <div class="col-sm-4">
                    <div class="form-group">
                    <label>Upload .csv file</label>
                    <dropzone-popup v-model="import_file" id="import_file" name="import_file" acceptedFiles=".csv" :class="{ 'is-invalid': $v.import_file.$error }"/>
                    <div v-if="$v.import_file.$error" class="invalid-feedback cuisine-img">
                        <span
                        v-if="!$v.import_file.required"
                        >{{ trans.get('__JSON__.Please select file') }}.</span>
                    </div>
                    </div>
                </div>

                <div class="col-sm-8">
                    <div class="form-group">
                    <label>{{ trans.get('__JSON__.Upload ZIP file of item images (Optional)') }}</label>
                    <dropzone-popup v-model="import_image_zip" id="import_image_zip" name="import_image_zip" acceptedFiles=".zip"/>
                    </div>
                </div>
              </div>
              <input type="hidden" name="master_menu_item_id" v-model="menu_item_image.master_menu_item_id" value="menu_item.master_menu_id">
          </form>
          <template slot="modal-footer">
              <div class="w-100">
                  <a class="ml-2 btn btn-light" :href="config.appurl + 'public/images/anahad_sample_menu_item.csv'" download>{{trans.get("__JSON__.Download Instructions")}}</a>
                  <b-button variant="primary"  class="float-right"  @click="largeDataImportMenuItem($event)" :disabled="largeImportLoader">
                      <b-spinner v-if="largeImportLoader" class="align-middle"></b-spinner>
                      {{(largeImportLoader) ? 'Uploading...' : trans.get('__JSON__.Upload')}}
                  </b-button>
                  <b-button class="float-left" @click="importModal=false">{{ trans.get('__JSON__.Close') }}</b-button>
              </div>
          </template>
      </b-modal>
      <!-- Large Data Import Modal End -->

      <!--Bulk Update Menu & Item Modal Start -->
      <b-modal v-if="$auth.hasPermission(config.permissions.merchant_write)" v-model="largeDataUpdate" scrollable :title="trans.get('__JSON__.Update Menu & Items')" title-class="font-18" centered @hidden="resetImport">
        <form @submit.prevent="largeMenuItemImport">
          <div class="row">
            <h5 class="download">You can download Existing Menu & Item CSV File and Upload New File with Changes.</h5>
            <h5 class="download">Please Note Updating Image Files not supported & Don’t Change “ID” & “Vendor Item ID” In CSV.</h5>

            <div class="col-sm-4">
              <div class="form-group">
                <label>{{ trans.get('__JSON__.Download Current Menu & Items') }}</label>
                <a v-if="$auth.partner_login" :href="config.appurl+'export-csv/'+ base64(this.menu_id)+'/'+ base64($auth.user.restaurant.restaurant_id) +'/'+base64('menu')+'/'+base64(this.ismapping)" target="_blank">
                  <img alt class="rounded avatar-lg image_thumb" :src="config.basepath+'csv_download.png'"/>
                </a>
                <a v-else :href="config.appurl+'export-csv/'+ base64(this.menu_id)+'/'+ this.$route.params.restaurant_id +'/'+base64('menu')+'/'+base64(this.ismapping)" target="_blank">
                  <img alt class="rounded avatar-lg image_thumb" :src="config.basepath+'csv_download.png'"/>
                </a>
              </div>
            </div>
            
            <div class="col-sm-4">
                <div class="form-group">
                  <label>{{ trans.get('__JSON__.Upload Updated Menu & Items') }}</label>
                  <dropzone-popup v-model="import_file" id="import_file" name="import_file" acceptedFiles=".csv" :class="{ 'is-invalid': $v.import_file.$error }"/>
                  <div v-if="$v.import_file.$error" class="invalid-feedback cuisine-img">
                    <span
                      v-if="!$v.import_file.required"
                    >{{ trans.get('__JSON__.Please select file') }}.</span>
                  </div>
                </div>
            </div>

            <div class="col-sm-4">
              <div class="form-group">
                <label>Upload ZIP file of item images <span style="font-size: 8px;">(Optional)</span></label>
                <dropzone-popup v-model="import_image_zip" id="import_image_zip" name="import_image_zip" acceptedFiles=".zip"/>
              </div>
            </div>
          </div>
          <input type="hidden" name="restaurant_menu_item_id" v-model="menu_item_image.restaurant_menu_item_id" value="menu_item.restaurant_menu_id">
        </form>
        <template slot="modal-footer">
          <div class="w-100">
            <b-button variant="primary"  class="float-right"  @click="largeMenuItemImport($event)" :disabled="largeImportLoader">
              <b-spinner v-if="largeImportLoader" class="align-middle"></b-spinner>
              {{(largeImportLoader) ? 'Uploading...' : trans.get('__JSON__.Upload')}}
            </b-button>
            <b-button class="float-left" @click="largeDataUpdate=false">{{ trans.get('__JSON__.Close') }}</b-button>
          </div>
        </template>
      </b-modal>
      <!-- Bulk Update Menu & Item Modal End -->

      <!--Bulk Update Modal Start -->
      <b-modal v-if="$auth.hasPermission(config.permissions.merchant_write)" v-model="largeitemDataUpdate" scrollable :title="trans.get('__JSON__.Bulk Update Menu Items')" title-class="font-18" centered @hidden="resetImport">
        <form @submit.prevent="largeMenuItemImport">
          <div class="row">
            <h5 class="download">You can download Existing Menu CSV File and Upload New File with Changes.</h5>

            <div class="col-sm-4">
              <div class="form-group">
                <label>{{ trans.get('__JSON__.Download Current Menu Items') }}</label>
                <a v-if="$auth.partner_login" :href="config.appurl+'export-csv/'+ base64(this.menu_id)+'/'+ base64($auth.user.restaurant.restaurant_id) +'/'+base64('item')+'/'+base64(this.ismapping)" target="_blank">
                  <img alt class="rounded avatar-lg image_thumb" :src="config.basepath+'csv_download.png'"/>
                </a>
                <a v-else :href="config.appurl+'export-csv/'+ base64(this.menu_id)+'/'+ this.$route.params.restaurant_id +'/'+base64('item')+'/'+base64(this.ismapping)" target="_blank">
                  <img alt class="rounded avatar-lg image_thumb" :src="config.basepath+'csv_download.png'"/>
                </a>
              </div>
            </div>
            
            <div class="col-sm-4">
              <div class="form-group">
                <label>{{ trans.get('__JSON__.Upload Updated Menu Items') }}</label>
                <dropzone-popup v-model="import_file" id="import_file" name="import_file" acceptedFiles=".csv" :class="{ 'is-invalid': $v.import_file.$error }"/>
                <div v-if="$v.import_file.$error" class="invalid-feedback cuisine-img">
                  <span
                    v-if="!$v.import_file.required"
                  >{{ trans.get('__JSON__.Please select file') }}.</span>
                </div>
              </div>
            </div>

            <div class="col-sm-4">
              <div class="form-group">
                <label>Upload ZIP file of item images <span style="font-size: 8px;">(Optional)</span></label>
                <dropzone-popup
                  v-model="import_image_zip"
                  id="import_image_zip"
                  name="import_image_zip"
                  acceptedFiles=".zip"
                />
              </div>
            </div>
          </div>
          <input type="hidden" name="restaurant_menu_item_id" v-model="menu_item_image.restaurant_menu_item_id" value="menu_item.restaurant_menu_id">
        </form>
        <template slot="modal-footer">
          <div class="w-100">
            <b-button variant="primary"  class="float-right"  @click="largeMenuItemImport($event)" :disabled="largeImportLoader">
              <b-spinner v-if="largeImportLoader" class="align-middle"></b-spinner>
              {{(largeImportLoader) ? 'Uploading...' : trans.get('__JSON__.Upload')}}
            </b-button>
            <b-button class="float-left" @click="largeitemDataUpdate=false">{{ trans.get('__JSON__.Close') }}</b-button>
          </div>
        </template>
      </b-modal>
      <!-- Bulk Update Modal End -->

      <!-- Map MenuItem Model Modal Start -->
      <b-modal v-if="$auth.hasPermission(config.permissions.merchant_write)" v-model="mapMenuItemModel" scrollable title="Map Item with Master Depot" title-class="font-18" centered @hidden="resetMapForm" modal-class="visible-modal" content-class="visible-modal" body-class="visible-modal">
        <form @submit.prevent="mapMenuItemUpdate">
          <div class="row">
            <h5 class="download">Map Item For: <b>{{mapItemform.item_name}}</b></h5>
            <br><br>
            <div class="col-sm-12">
              <div class="form-group">
                <label class="control-label">Depot Item</label>
                <multiselect 
                  label="item_name"
                  track-by="item_name"
                  placeholder="Select Item"
                  openDirection="bottom"
                  v-model="mapItemform.master_menu_item_id" 
                  :options="depotItemList" 
                  :searchable="true"
                  :multiple="false"
                  :max-height="150"
                  :show-no-results="true"
                  :hide-selected="false"
                  @search-change="getDepotItemList"
                >
                  <template #noResult>
                    No result found.
                  </template>
                </multiselect>    
              </div>
            </div>
          </div>
        </form>
        <template slot="modal-footer">
          <div class="w-100">
            <b-button variant="primary"  class="float-right"  @click="mapMenuItemUpdate($event)" :disabled="mapMenuItemLoader || (!mapItemform.master_menu_item_id)">
              <b-spinner v-if="mapMenuItemLoader" class="align-middle"></b-spinner>
              {{(mapMenuItemLoader) ? 'Updating...' : 'Update'}}
            </b-button>
            <b-button class="float-left" @click="mapMenuItemModel=false">{{ trans.get('__JSON__.Close') }}</b-button>
          </div>
        </template>
      </b-modal>
      <!-- Map MenuItem Model Modal End -->

    </div>
    <!-- end row -->
  </Layout>
</template>
<style>
.mapped-success {
  color: #34c38f;
}
ul.dropdown-menu li {
  width: 100%;
  text-align: right;
}
.multiselect .multiselect__content-wrapper {
  max-height: 150px !important;
}
.visible-modal {
  overflow: visible !important;
}
</style>