<script>
import {
  get_currency,
  get_decimal,
  order_statuses,
} from "../../../Helper/helper";
import { orderService } from "../../../services";
import OrderShow from "./order-show";
import OrderAction from "./order-action";
import OrderAssign from "./order-assign";
import OrderTrack from "./order-track";
import config from "../../../config";
export default {
  components: { OrderShow, OrderAction, OrderAssign, OrderTrack },
  props: {
    orders: {
      default: {},
      type: Object,
    },
    loading: {
      default: false,
      type: Boolean,
    },
    "extra-columns": {
      default: function () {
        return [];
      },
      type: Array,
    },
    status: {
      default: "",
      type: String,
    },
  },
  data() {
    return {
      statuses: order_statuses,
      config: config,
      filters: {
        order_status: this.order_status,
      },
      meta: [
        { charset: "utf-8" },
        { equiv: "Content-Type", content: "text/html" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
      ],
      decimal: get_decimal(),
      currency: get_currency(),
      fields: [
        {
          key: "order_id",
          label: this.trans.get("__JSON__.Order ID"),
          sortable: false,
        },
        {
          key: "stores_name",
          label: this.trans.get("__JSON__.Vendor's Name", {
            vendor: this.$auth.getRestaurantName(),
          }),
          sortable: false,
        },
        {
          key: "patient_name",
          label: this.trans.get("__JSON__.Patient Name"),
          sortable: false,
        },
        {
          key: "hdp_Id",
          label: this.trans.get("__JSON__.HDP Order"),
          sortable: false,
          requiresAdmin: true,
        },
        {
          key: "patient_number",
          label: this.trans.get("__JSON__.Patient Number"),
          sortable: false,
          requiresAdmin: true,
        },
        {
          key: "created_at",
          label: this.trans.get("__JSON__.Time"),
          sortable: false,
        },
        {
          key: "total_amount",
          label: this.trans.get("__JSON__.Amount"),
          sortable: false,
        },
        // { key: "delivery_pickup_types", label: this.trans.get("__JSON__.Type"), sortable: false },
        {
          key: "payment_method",
          label: this.trans.get("__JSON__.Payment"),
          sortable: false,
        },
        ...(this.extraColumns || []),
        {
          key: "action",
          label: this.trans.get("__JSON__.Action"),
          sortable: false,
        },
      ],
      showModal: false,
      showModalTab: 0,
      showTrackModel: false,
      order: null,
      viewIndex: 0,
      orderId: null,
      abandonedstatus: "",
    };
  },
  computed: {
    computedFields() {
      // If the user isn't an admin, filter out fields that require auth.
      if (this.$auth.partner_login)
        return this.fields.filter((field) => !field.requiresAdmin);
      // If the user IS an admin, return all fields.
      else return this.fields;
    },
  },
  mounted() {
    this.$data.fields[1].label =
      this.$auth.setting && this.$auth.setting.restaurant_name;
  },
  methods: {
    viewDetails(id) {
      this.showModal = true;
      this.orderId = id;
      // orderService.getOrder(id)
      //   .then(res => {
      //     this.order = res.data.result;
      //     this.orderLoading = false;
      //   })
    },
    viewPageDetails(item, index, event) {
      this.showModalTab = 0;
      this.viewIndex = index;
      this.viewDetails(item.base_id);
      this.$refs.selectableTable.clearSelected();
    },
    viewPageDetailsShipmentTwo(item, index, event) {
      this.showModalTab = 0;
      this.viewIndex = index;
      this.viewDetails(item.sipment1_base_id);
      this.$refs.selectableTable.clearSelected();
    },
    viewPageDetailsParentOrder(item, index, event) {
      this.showModalTab = 0;
      this.viewIndex = index;
      this.viewDetails(item.sipment1_base_id);
      this.$refs.selectableTable.clearSelected();
    },
    abandonedStatusChange() {
      this.$emit("abandonedstatusEmitchanged", this.abandonedstatus);
    },
    trackOrder(order) {
      this.showTrackModel = true;
      this.order = order;
    },
    viewOrderDetailsSecondTab(data) {
      if (data.orderid && data.orderid > 0) {
        this.showModalTab = 1;
        this.viewIndex = data.orderindex ?? 0;
        this.viewDetails(btoa(data.orderid));
        this.$refs.selectableTable.clearSelected();
      }
    },
    resetTrackOrder() {
      this.showTrackModel = false;
      this.order = null;
    },
  },
};
</script>
<template>
  <div class="row">
    <div class="col-12 m-order-main">
      <!-- <span v-if="status == 'PaymentFailure'" class="text-warning" ><strong>Pending :</strong></span><span v-if="status == 'PaymentFailure'"  class="text-warning"> Customer did not proceed with Payment.</span><br  v-if="status == 'PaymentFailure'" />
    <span v-if="status == 'PaymentFailure'" class="text-danger" ><strong>Failed :</strong></span><span v-if="status == 'PaymentFailure'"  class="text-danger"> Customer tried to pay but failed due to issue with Card/Bank/Wallet.</span> -->
      <div class="row" v-if="status == 'PaymentFailure'">
        <div class="col-10">
          <span class="text-warning"
            ><strong>{{ trans.get("__JSON__.Pending") }} : </strong></span
          >
          <span class="text-warning">{{
            trans.get("__JSON__.Customer did not proceed with Payment.")
          }}</span
          ><br />
          <span class="text-danger"
            ><strong>{{ trans.get("__JSON__.Failed") }} : </strong></span
          >
          <span class="text-danger">{{
            trans.get(
              "__JSON__.Customer tried to pay but failed due to issue with Card/Bank/Wallet."
            )
          }}</span
          ><br />
          <span class="text-info"
            ><strong>{{ trans.get("__JSON__.Awaiting") }} : </strong></span
          >
          <span class="text-info">{{ trans.get("__JSON__.Awaiting") }}</span>
        </div>
        <div class="col-2">
          <label>{{ trans.get("__JSON__.Status") }} :</label>
          <select
            class="custom-select"
            v-model="abandonedstatus"
            @change="abandonedStatusChange"
          >
            <option value="" selected>{{ trans.get("__JSON__.All") }}</option>
            <option value="PaymentPending">
              {{ trans.get("__JSON__.Pending") }}
            </option>
            <option value="PaymentFailure">
              {{ trans.get("__JSON__.Failed") }}
            </option>
            <option value="AwaitForPayment">
              {{ trans.get("__JSON__.Awaiting") }}
            </option>
          </select>
        </div>
      </div>
      <div class="order-table-data table-responsive order-data-table">
        <b-table
          :items="(orders && orders.data) || []"
          :fields="computedFields"
          ref="selectableTable"
          show-empty
          :busy="loading"
          class="table-centered table-nowrap table-hover table-main-border order-menu"
          selectable
          select-mode="single"
          @row-clicked="viewPageDetails"
        >
          <template #empty>
            <p class="text-center">
              {{ trans.get("__JSON__.No Order Found") }}
            </p>
          </template>
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>{{ trans.get("__JSON__.Loading...") }}</strong>
            </div>
          </template>
          <template #cell(order_id)="data">
            <template
              v-if="
                ![
                  statuses.placed,
                  statuses.cancelled,
                  statuses.paymentpending,
                  statuses.paymentfailure,
                ].includes(data.item.order_status) &&
                data.item.suborder &&
                data.item.suborder.id &&
                data.item.suborder.id > 0
              "
            >
              <span
                class="badge badge-pill badge-soft-primary font-size-12 depot-badge"
                >Depot</span
              ><br />
            </template>
            <template
              v-else-if="
                ![
                  statuses.placed,
                  statuses.cancelled,
                  statuses.paymentpending,
                  statuses.paymentfailure,
                ].includes(data.item.order_status) &&
                !data.item.suborder &&
                data.item.is_depot_store == 1
              "
            >
              <span
                class="badge badge-pill badge-soft-primary font-size-12 depot-badge"
                >Depot</span
              ><br />
            </template>
            <template
              v-if="
                [statuses.delivered].includes(data.item.order_status) &&
                data.item.vendor_invoice == null
              "
            >
              <span
                class="badge badge-pill badge-soft-primary font-size-12 depot-badge"
                style="background-color: #f46a6a !important"
                >Bill Upload Pending</span
              ><br />
            </template>
            <span class="font-weight-bold">{{ data.value }}</span>
            <span
              class="badge badge-pill badge-soft-primary font-size-12"
              v-if="data.item.delivery_pickup_types == 'Delivery'"
            >
              {{ trans.get("__JSON__." + data.item.delivery_pickup_types) }}
            </span>
            <span
              class="badge badge-pill badge-soft-danger font-size-12"
              v-if="data.item.delivery_pickup_types == 'Takeaway'"
            >
              {{ trans.get("__JSON__." + data.item.delivery_pickup_types) }}
            </span>
            <span
              class="badge badge-pill badge-soft-danger font-size-12"
              v-if="data.item.delivery_pickup_types == 'Pickup'"
            >
              {{
                trans.get("__JSON__." + data.item.delivery_pickup_types)
              }} </span
            ><br />
            <span
              class="font-weight-bold"
              v-if="data.item.sipment1_orderId != null"
              @click="viewPageDetailsShipmentTwo(data.item)"
              >({{
                "Sub Order of" +
                " " +
                "#" +
                ((data.item && data.item.sipment1_orderId) || "000")
              }})</span
            >
            <!-- <span class="font-weight-bold" v-if="data.item.sipment1_orderId == null" @click="viewPageDetailsParentOrder(data.item)">({{'Parent Order of' +' '+'#'+ ('000')}})</span> -->
            <br
              v-if="
                (data.item.future_delivery_date != '0000-00-00 00:00:00' &&
                  data.item.future_delivery_date != null) ||
                data.item.access_name
              "
            />
            <span
              class="badge badge-pill badge-success font-size-12"
              v-if="
                data.item.future_delivery_date != '0000-00-00 00:00:00' &&
                data.item.future_delivery_date != null
              "
            >
              Scheduled
            </span>
            <b-badge
              v-if="data.item.access_name"
              pill
              class="font-size-12 badge-soft-secondary mt-1"
              ><i class="bx bx-phone-call"></i>
              {{ data.item.access_name }}</b-badge
            >
          </template>
          <template #cell(stores_name)="data">
            <!-- {{ data.item.restaurant && data.item.restaurant.name || data.item.id}} -->
            <span class="font-weight-bold">{{ data.value }}</span>
          </template>
          <template #cell(patient_name)="data">
            <!-- {{ data.item.user && (data.item.user.user_name +" "+ data.item.user.last_name) || data.item.id}} -->
            {{ data.value }}
          </template>
          <template #cell(hdp_Id)="data">
            <!-- {{ data.item.user && (data.item.user.user_name +" "+ data.item.user.last_name) || data.item.id}} -->
            {{ data.value ? "Yes" : "No" }}
          </template>
          <template #cell(patient_number)="data">
            {{ data.value || "-" }}
          </template>
          <template #cell(created_at)="data">
            <!-- {{ moment(data.value).format('DD-MM-YYYY hh:mm A') }} <span v-b-tooltip.hover.v-light :title="data.value" class="font-size-15"><i class="bx bx-info-circle"></i></span> -->
            {{ moment(data.value).fromNow() }}
            <span
              v-b-tooltip.hover.v-light
              :title="data.value"
              class="font-size-15"
              ><i class="bx bx-info-circle"></i
            ></span>
          </template>
          <template #cell(total_amount)="data">
            <span v-html="currency"></span>
            {{ (data.value || 0).toFixed(decimal) }}
          </template>
          <!-- <template #cell(delivery_pickup_types)="data">
        <span class="badge badge-pill badge-soft-primary font-size-12" v-if="data.value == 'Delivery'">
          {{trans.get('__JSON__.'+data.value)}}
        </span>
        <span class="badge badge-pill badge-soft-danger font-size-12" v-if="data.value == 'Takeaway'">
          {{trans.get('__JSON__.'+data.value)}}
        </span>
        <span class="badge badge-pill badge-soft-danger font-size-12" v-if="data.value == 'Pickup'">
          {{trans.get('__JSON__.'+data.value)}}
        </span>
      </template> -->
          <template #cell(payment_method)="data">
            <span
              class="badge badge-pill font-size-12"
              :class="{
                'badge-soft-success': data.value == 'Cash On Delivery',
                'badge-soft-warning': data.value != 'Cash On Delivery',
              }"
              v-if="
                data.item.order_status !== 'PaymentPending' &&
                data.item.order_status !== 'PaymentFailure' &&
                data.item.order_status !== 'AwaitForPayment'
              "
            >
              {{ data.value == "Cash On Delivery" ? "COD" : data.value }}
            </span>
            <span
              v-else
              class="badge badge-pill font-size-12"
              :class="{
                'badge-soft-warning':
                  data.item.order_status == 'PaymentPending',
                'badge-soft-danger': data.item.order_status == 'PaymentFailure',
                'badge-soft-info': data.item.order_status == 'AwaitForPayment',
              }"
            >
              {{
                data.item.order_status == "PaymentPending"
                  ? trans.get("__JSON__.Pending")
                  : data.item.order_status == "PaymentFailure"
                  ? trans.get("__JSON__.Failed")
                  : trans.get("__JSON__.Awaiting")
              }}
            </span>
          </template>
          <template
            v-for="(col, index) in extraColumns"
            v-slot:[`cell(${col.key})`]="data"
          >
            <span v-if="col.key == 'future_delivery_date'" :key="index">
              <span>{{ data.value }}</span>
              <span
                v-b-tooltip.hover.v-light
                :title="moment(data.value).fromNow()"
                class="font-size-15"
              >
                <i class="bx bx-info-circle"></i>
              </span>
            </span>
            <span v-else-if="col.key == 'invoice_date'" :key="index">
              <template>
                {{
                  data.value
                    ? moment(data.value).format("DD-MM-YYYY hh:mm A")
                    : "-"
                }}
              </template>
            </span>
            <span v-else-if="col.key.indexOf('date') !== -1" :key="index">
              <span>{{ moment(data.value).fromNow() }}</span>
              <span
                v-b-tooltip.hover.v-light
                :title="data.value"
                class="font-size-15"
              >
                <i class="bx bx-info-circle"></i>
              </span>
            </span>
            <div
              v-else-if="col.key == 'assign' && !$auth.partner_login"
              :key="index"
            >
              <template
                v-if="
                  status == statuses.inkitchen &&
                  data.item.delivery_pickup_types == 'Delivery'
                "
              >
                <template
                  v-if="
                    data.item.suborder &&
                    data.item.suborder.order_status &&
                    data.item.suborder.order_status == statuses.readytoserve &&
                    data.item.suborder.driver &&
                    data.item.suborder.driver.length == 0
                  "
                >
                  <OrderAssign
                    :order="data.item"
                    @actionChanged="$emit('refreshData')"
                  />
                </template>
                <template v-else>
                  <span>-</span>
                </template>
              </template>
              <template
                v-else-if="
                  status == statuses.readytoserve &&
                  data.item.delivery_pickup_types == 'Delivery'
                "
              >
                <OrderAssign
                  :order="data.item"
                  @actionChanged="$emit('refreshData')"
                />
              </template>
              <template v-else>
                <span>-</span>
              </template>
            </div>
            <div
              v-else-if="col.key == 'track' && !$auth.partner_login"
              :key="index"
            >
              <template
                v-if="
                  data.item.delivery_pickup_types == 'Delivery' &&
                  $auth.hasPermission(config.permissions.order_write)
                "
              >
                <div
                  class="text-center"
                  v-if="
                    data.item.driver &&
                    data.item.driver.length > 0 &&
                    data.item.suborder &&
                    data.item.suborder.id &&
                    data.item.suborder.id > 0
                  "
                >
                  <template
                    v-if="
                      data.item.suborder.driver &&
                      data.item.suborder.driver.length > 0
                    "
                  >
                    <b-button
                      variant="primary"
                      size="sm"
                      @click="trackOrder(data.item.suborder)"
                    >
                      <!-- {{trans.get('__JSON__.Track')}} -->
                      {{
                        data.item.suborder.is_depot_store == 0
                          ? "Track Ship 1"
                          : "Track Ship 2"
                      }} </b-button
                    ><br /><br />
                  </template>
                  <b-button
                    variant="primary"
                    size="sm"
                    @click="trackOrder(data.item)"
                  >
                    <!-- {{trans.get('__JSON__.Track')}} -->
                    {{
                      data.item.is_depot_store == 0
                        ? "Track Ship 1"
                        : "Track Ship 2"
                    }}
                  </b-button>
                </div>
                <!-- <div class="text-center" v-else-if="data.item.driver && data.item.driver.length > 0"> -->
                <div
                  class="text-center"
                  v-else-if="
                    data.item.driver_name && data.item.task_id == null
                  "
                >
                  <b-button
                    variant="primary"
                    size="sm"
                    @click="trackOrder(data.item)"
                  >
                    {{ trans.get("__JSON__.Track") }}
                  </b-button>
                </div>
                <div
                  class="text-center"
                  v-else-if="
                    data.item.suborder &&
                    data.item.suborder.driver &&
                    data.item.suborder.driver.length > 0
                  "
                >
                  <b-button
                    variant="primary"
                    size="sm"
                    @click="trackOrder(data.item.suborder)"
                  >
                    <!-- {{trans.get('__JSON__.Track')}} -->
                    {{
                      data.item.suborder.is_depot_store == 0
                        ? "Track Ship 1"
                        : "Track Ship 2"
                    }}
                  </b-button>
                </div>
                <div class="text-center" v-else>
                  <span>-</span>
                </div>
              </template>
              <span v-else>-</span>
            </div>
            <span v-else-if="col.key == 'driver'" :key="index">
              <template v-if="status == statuses.inkitchen">
                <template
                  v-if="
                    data.item.driver &&
                    data.item.driver.length > 0 &&
                    data.item.suborder &&
                    data.item.suborder.id &&
                    data.item.suborder.id > 0
                  "
                >
                  <template
                    v-if="
                      data.item.suborder.driver &&
                      data.item.suborder.driver.length > 0
                    "
                  >
                    {{
                      data.item.suborder.is_depot_store == 0
                        ? "Shipment 1 (Store)"
                        : "Shipment 2 (Depot)"
                    }}<br />
                    {{
                      data.item.suborder.driver[0].firstname +
                      " " +
                      data.item.suborder.driver[0].lastname
                    }}<br />
                  </template>
                  {{
                    data.item.is_depot_store == 0
                      ? "Shipment 1 (Store)"
                      : "Shipment 2 (Depot)"
                  }}<br />
                  {{
                    data.item.driver[0].firstname +
                    " " +
                    data.item.driver[0].lastname
                  }}
                </template>
                <template
                  v-else-if="
                    data.item.driver_name && data.item.task_id == null
                  "
                >
                  {{ data.item.driver_name }}
                </template>
                <template
                  v-else-if="
                    data.item.suborder &&
                    data.item.suborder.driver &&
                    data.item.suborder.driver.length > 0
                  "
                >
                  {{
                    data.item.suborder.is_depot_store == 0
                      ? "Shipment 1 (Store)"
                      : "Shipment 2 (Depot)"
                  }}<br />
                  {{
                    data.item.suborder.driver[0].firstname +
                    " " +
                    data.item.suborder.driver[0].lastname
                  }}
                </template>
                <template
                  v-else-if="
                    data.item.task_id &&
                    data.item.delivery_partner_status == null
                  "
                >
                  {{
                    `Awaiting for ${data.item.delivery_partner_name} response`
                  }}
                </template>
                <template
                  v-else-if="
                    data.item.task_id &&
                    data.item.delivery_partner_status.toLowerCase() == 'cancelled'
                  "
                >
                  {{ `Cancelled by ${data.item.delivery_partner_name}` }}
                </template>
                <template v-else>
                  {{
                    data.item.task_id && data.item.runner_name
                      ? data.item.runner_name
                      : "-"
                  }}
                </template>
              </template>
              <template v-else>
                <template
                  v-if="
                    data.item.driver &&
                    data.item.driver.length > 0 &&
                    data.item.suborder &&
                    data.item.suborder.id &&
                    data.item.suborder.id > 0
                  "
                >
                  <template
                    v-if="
                      data.item.suborder.driver &&
                      data.item.suborder.driver.length > 0
                    "
                  >
                    {{
                      data.item.suborder.is_depot_store == 0
                        ? "Shipment 1 (Store)"
                        : "Shipment 2 (Depot)"
                    }}<br />
                    {{
                      data.item.suborder.driver[0].firstname +
                      " " +
                      data.item.suborder.driver[0].lastname
                    }}<br />
                  </template>
                  {{
                    data.item.is_depot_store == 0
                      ? "Shipment 1 (Store)"
                      : "Shipment 2 (Depot)"
                  }}<br />
                  {{
                    data.item.driver[0].firstname +
                    " " +
                    data.item.driver[0].lastname
                  }}
                </template>
                <template
                  v-else-if="
                    data.item.driver_name && data.item.task_id == null
                  "
                >
                  {{ data.item.driver_name }}
                </template>
                <template
                  v-else-if="
                    data.item.suborder &&
                    data.item.suborder.driver &&
                    data.item.suborder.driver.length > 0
                  "
                >
                  {{
                    data.item.suborder.is_depot_store == 0
                      ? "Shipment 1 (Store)"
                      : "Shipment 2 (Depot)"
                  }}<br />
                  {{
                    data.item.suborder.driver[0].firstname +
                    " " +
                    data.item.suborder.driver[0].lastname
                  }}
                </template>
                <template
                  v-else-if="
                    data.item.task_id &&
                    data.item.delivery_partner_status == null
                  "
                >
                  {{
                    `Awaiting for ${data.item.delivery_partner_name} response`
                  }}
                </template>
                <template
                  v-else-if="
                    data.item.task_id &&
                    data.item.delivery_partner_status.toLowerCase() == 'cancelled'
                  "
                >
                  {{ `Cancelled by ${data.item.delivery_partner_name}` }}
                </template>
                <template v-else>
                  {{
                    data.item.task_id && data.item.runner_name
                      ? data.item.runner_name
                      : "-"
                  }}
                </template>
              </template>
            </span>
          </template>
          <template #cell(action)="data">
            <OrderAction
              :orderIndex="data.index"
              :order="data.item"
              @actionChanged="$emit('refreshData')"
              @viewOrderDetailsSecondTabEmit="viewOrderDetailsSecondTab"
              :showModalTab="showModalTab"
            />
            <!-- <b-button variant="primary" class="btn-sm btn-rounded" @click="viewDetails(data.item.base_id)">{{trans.get('__JSON__.View')}}</b-button> -->
          </template>
        </b-table>
      </div>
    </div>
    <OrderTrack
      v-if="showTrackModel"
      :order="order"
      @hidden="resetTrackOrder"
    />
    <OrderShow
      v-if="showModal"
      @actionChanged="$emit('refreshData')"
      :order-id="orderId"
      @hidden="showModal = false"
      :showModalTab="showModalTab"
    />
    <!-- View Detail Main End -->
  </div>
</template>
<style>
.depot-badge {
  background-color: #556ee6 !important;
  color: #fff !important;
  margin-bottom: 10px;
}
</style> 