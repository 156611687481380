<script>
import Layout from '../../layouts/main'
import PageHeader from '../../components/page-header'
import editCategory from './edit-category'
import { categoryService } from "../../services";
/**
 * Add-product component
 */
export default {
  components: { Layout, PageHeader,editCategory },
  data() {
    return {
      category: []
    };
  },
  mounted() {
    this.getCategoryById();
  },
  methods: {
    getCategoryById() {
      categoryService
        .getCategoryById({ category_id: this.$route.params.category_id })
        .then((response) => {
          this.category = response.data.result;
        });
    },
  }
}
</script>

<template>
  <Layout>
   <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
        >
          <div class="page-title-left avatar-main-icon">
            <div class="avatar-xs mr-3">
              <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                <i class="bx bx-briefcase"></i>
              </span>
            </div>
            <div class="report-tag">
              <h4 class="mb-0 font-size-18">{{ category.category_name }}</h4>
              <p><router-link :to="{ name: 'category-list'}">{{ trans.get('__JSON__.Categories') }}</router-link> > {{ category.category_name }}</p>
          </div>
          </div>
        </div>
      </div>
    </div>
   <div class="row">
    <div class="col-12">
      <edit-category/>
    </div>
   </div>
    <!-- end row -->
  </Layout>
</template>
