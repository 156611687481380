
<script>
import { customerService } from "../../services";
import {
  error_message,
  get_partner_login,
  set_partner,
  set_partner_details,
  set_user,
  set_user_detail,
  success_message,
} from "../../Helper/helper";
import {
  required,
  email,
  minLength,
  sameAs,
  maxLength,
  minValue,
  maxValue,
  numeric,
  url,
  alphaNum,
} from "vuelidate/lib/validators";
export default {
  components: {},
  props:['cust_data'],
  data() {
    return {
      customers: {
          user_id : this.cust_data.user_id,
      },
      typeform: {
        user_id: "",
        new_password: "",
        confirm_password: "",
      },
    };
  },
  validations: {
    typeform: {
      new_password: {
        required,
        minLength: minLength(6),
      },
      confirm_password: {
        required,
        sameAsPassword: sameAs("new_password"),
      },
    },
  },
  mounted() {
    this.typeform.user_id = this.$route.params.user_id;
    // this.getRestaurantById();
  },
  methods: {
    // getRestaurantById() {
    //   vendorService
    //     .getRestaurantById({ restaurant_id: this.$route.params.restaurant_id })
    //     .then((response) => {
    //       const { user_id } = response.data.result;
    //       this.restaurant = {
    //         user_id,
    //       };
    //     });
    // },
    // getUser() {
    //   this.users = this.$auth.user;
    //   this.userdetail = this.$auth.setting;
    // },
    typeForm(e) {
      this.$v.$touch();
      if (this.$v.typeform.$invalid) {
        return;
      } else {
        customerService.changeCustPassword(this.typeform).then((response) => {
          this.typeform = {
            new_password: '',
            confirm_password: ''
          }
          this.$v.typeform.$reset();
          this.typeform.user_id = this.$route.params.user_id;
          if (response.data.code === 200) {
            success_message(response.data.message);
            this.$router.push({ name: "customer-detail" });
          } else {
            error_message(response.data.message);
          }
        });
      }
    },
  },
};
</script>

<template>
  <!-- Change Password Start -->
  <div class="row">
    <div class="col-12">
      <form @submit.prevent="typeForm">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title"></h4>
            <p class="card-title-desc"></p>
            <div class="row">
              <div class="form-group">
                <input
                    id="user_id"
                    name="user_id"
                    type="hidden"
                    v-model="typeform.user_id"
                    class="form-control"
                  />
              </div>
              <div class="col-sm-4">
                <div class="form-group">
                  <label>{{ trans.trans('__JSON__.Password') }}</label>
                  <input
                    id="new_password"
                    name="new_password"
                    type="password"
                    v-model="$v.typeform.new_password.$model"
                    placeholder="Enter New Password"
                    class="form-control"
                    :class="{ 'is-invalid':$v.typeform.new_password.$error }"
                  />
                  <div v-if="$v.typeform.new_password.$error" class="invalid-feedback">
                    <span
                      v-if="!$v.typeform.new_password.required"
                    >{{ trans.get('__JSON__.Please enter new password') }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4">
                <div class="form-group">
                  <label>{{ trans.get('__JSON__.Re-enter new password') }}</label>
                  <input
                    id="confirm_password"
                    name="confirm_password"
                    type="password"
                    placeholder="Re enter New password"
                    class="form-control"
                    v-model="$v.typeform.confirm_password.$model"
                    :class="{ 'is-invalid':$v.typeform.confirm_password.$error }"
                  />
                  <div v-if="$v.typeform.confirm_password.$error" class="invalid-feedback">
                    <span
                      v-if="!$v.typeform.confirm_password.required"
                    >{{ trans.get('__JSON__.Please re enter your password') }}</span>
                    <span
                      v-else-if="!$v.typeform.confirm_password.sameAsPassword"
                    >{{ trans.get('__JSON__.Passwords must match') }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <button
                  type="submit"
                  class="btn btn-primary mr-1 mt-3"
                >{{ trans.get('__JSON__.Update') }}</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <!-- Change Password End -->
  <!-- end row -->
</template>

<style>
</style>