// Setting services rest api calling

import { routes } from "../config/api_routes";

export const settingService = {
    // get banner
    // info: sortDesc : Boolean

    getSetting: () => window.axios.get(routes.getSettingApi),

    // update Settings
    updateSetting: formData =>
        window.axios.post(routes.updateSettingApi, formData),

    // get App Setting Api
    getAppSettingApi: () => window.axios.get(routes.getAppLayoutSettingApi),

    // Update App Setting
    updateAppSetting: formData =>
        window.axios.post(routes.updateAppLayoutApi, formData),

    // Update General terminology Setting
    updateGeneralTerminology: formData =>
        window.axios.post(routes.updateGeneralTerminologyApi, formData),

    // get Notification
    getNotificationText: formData =>
        window.axios.post(routes.getOrderNotificationTextApi, formData),

    //  // update Notification
    updateNotificationtext: formData =>
        window.axios.post(routes.updateOrderTextApi, formData),

    getVendorSettingById: formData =>
        window.axios.post(routes.getVendorSettingByIdApi, formData),

    updatepaymentgateway: formData =>
        window.axios.post(routes.updatepaymentgatewayApi, formData),

    //It will return all payment gateway, vendor list will come in getsettings api
    getPaymentGatewayList: formData =>
        window.axios.get(routes.getPaymentGatewayList, formData),

    // profile menu APi
    getprofilemenulist: () => window.axios.get(routes.getprofilemenus),
    getprofilemenubyId: id =>
        window.axios.get(`${routes.getprofilemenubyId}${id}`),
    Uploadprofilemenuimageicon: formdata =>
        window.axios.post(routes.uploadmenuimageicon, formdata),
    Updateprofilemenu: object =>
        window.axios.post(routes.updateprofilemenu, object),
    Removeprofilemenuiconimage: object =>
        window.axios.post(routes.removeprofilemenuiconimage, object),


    // Stores location API 

    getStoresLocationAPI: () => window.axios.get(routes.storelocationapi),
};
