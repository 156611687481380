<script>

import Layout from '../../layouts/main'

import CouponEdit from './coupon-edit'

/**
 * Promo-code component
 */
export default {
  components: { Layout, CouponEdit },
  data() {
    return {};
  }
}
</script>

<template>
<Layout>
  <coupon-edit/>
</Layout>
</template>
