
// Discount services rest api calling

import { multipart_headers } from '../Helper/helper';
import { routes } from '../config/api_routes'

export const discountService = {
    // get Discount
    // info: sortDesc : Boolean

    getDiscount: (formData) => {    
        return window.axios.post(routes.getDiscountApi,formData)
    },

     // Discount add

     addDiscount: (formData) => {
        return window.axios.post(routes.addDiscountApi,formData,{headers: multipart_headers() });
    },

     // get discountById
     getDiscountById: (formData) => {
        return window.axios.post(routes.getDiscountByIdApi,formData)
   },

    // update discountById

    updateDiscountById: (formData) => window.axios.post(routes.updateDiscountApi,formData,{headers: multipart_headers() }),

}