<template>
  <div>
    <div class="plr15">
      <div class="review-boxs mt-4">
        <div class="mt-4 position-relative">
          <div class="google-addnew-address position-relative">
            <vue-google-autocomplete
              ref="address"
              id="addres"
              name="address"
              class="form-control"
              type="text"
              v-model="typeform.address"
              v-on:placechanged="getAddressData"
              placeholder="Search Location"
              types=""
            ></vue-google-autocomplete>
            <i class="fas fa-search search-icon"></i>
          </div>
        </div>
        <input type="hidden" v-model="user_id" />
        <div class="mt-4 position-relative">
          <div
            class="map1"
            id="address_map"
            ref="address_map"
            style="height: 250px"
          ></div>
        </div>
        <div class="mt-4 position-relative">
          <h6 class="mb-2">House/Building Name</h6>
          <input
            class="form-control mb-0 h50"
            type="text"
            v-model="$v.typeform.house_name.$model"
            placeholder="Enter House/Building Name"
            aria-label="House/Building Name"
            :class="{ 'is-invalid': $v.typeform.house_name.$error }"
          />
          <div v-if="$v.typeform.house_name.$error" class="invalid-feedback">
            <span v-if="!$v.typeform.house_name.required"
              >Enter House/Building Name.</span
            >
          </div>
        </div>
        <div class="mt-4 position-relative">
          <h6 class="mb-2">Flat Number/Floor</h6>
          <input
            class="form-control mb-0 h50"
            type="text"
            v-model="$v.typeform.flat_no.$model"
            placeholder="Enter Flat/ Floor Number"
            aria-label="Flat Number/ Floor"
            :class="{ 'is-invalid': $v.typeform.flat_no.$error }"
          />
          <div v-if="$v.typeform.flat_no.$error" class="invalid-feedback">
            <span v-if="!$v.typeform.flat_no.required"
              >Enter Flat/ Floor Number</span
            >
            <!-- <span v-else-if="!$v.typeform.flat_no.numeric"
              >This value should be a number</span
            > -->
          </div>
        </div>
        <div class="mt-4 position-relative">
          <h6 class="mb-2">Landmark</h6>
          <input
            class="form-control mb-0 h50"
            type="text"
            v-model="typeform.landmark"
            placeholder="Enter Landmark"
            aria-label="Landmark"
          />
        </div>
      </div>
      <div class="mt-4 mb-4">
        <h6 class="mb-3">Set tag for Address</h6>
        <form class="boxed d-flex set-tag">
          <input
            type="radio"
            id="home"
            name="Home"
            value="Home"
            v-model="typeform.address_clarification"
          />
          <label for="home">Home</label>

          <input
            type="radio"
            id="office"
            name="office"
            value="Office"
            v-model="typeform.address_clarification"
          />
          <label for="office">Office</label>

          <input
            type="radio"
            id="other"
            name="other"
            value="Other"
            v-model="typeform.address_clarification"
          />
          <label for="other">Other</label>
        </form>
      </div>
    </div>
    <div class="w-100">
      <b-button variant="primary" class="float-right" @click="addAddress">{{
        trans.get("__JSON__.Add Address")
      }}</b-button>
      <b-button class="float-left" @click="$emit('onCancel')">{{
        trans.get("__JSON__.Close")
      }}</b-button>
    </div>
  </div>
</template>

<script>
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import { callCenterDashboardService } from '../../services';
import { required,numeric } from "vuelidate/lib/validators";
import { error_message, success_message } from '../../Helper/helper';
export default {
  components:{VueGoogleAutocomplete},
  props:['user_id'],
  data() {
    return {
      map: null,
      marker: null,
      typeform:{
        address: '',
        latitude: '',
        longitude: '',
        address_clarification: 'Home',
        house_name: '',
        flat_no: '',
        landmark: '',
        city: '',
        state: '',
        zip: ''
      },
      address: null
    };
  },
  validations:{
    typeform: {
        address: {
          required,
        },
        house_name: {
          required,
        },
        flat_no: {
          required,
        },
      },
  },
  mounted(){
    this.initMap();
  },
  methods: {
    initMap(){
      this.map = new google.maps.Map(this.$refs.address_map, {
        center: { lat: -34.397, lng: 150.644 },
        zoom: 8,
        mapTypeId: google.maps.MapTypeId.ROADMAP
      });
    },
    getAddressData: function (addressData, placeResultData, id) {
      // console.log(placeResultData)
      this.address = addressData;
      this.typeform.address = placeResultData.formatted_address;
      this.typeform.latitude = addressData.latitude;
      this.typeform.longitude = addressData.longitude;
      this.typeform.country = addressData.country;
      this.typeform.locality = addressData.country;
      if (this.marker) {
        this.marker.setMap(null);
      }
      const marker = {
        lat: addressData.latitude,
        lng: addressData.longitude,
      };
      var geocoder = new google.maps.Geocoder();
      const latlng = new google.maps.LatLng(
        addressData.latitude,
        addressData.longitude
      );
      this.marker = new google.maps.Marker({
        position: latlng,
        title: "Restaurant",
        draggable: true,
      });
      this.marker.setMap(this.map);
      this.map.setCenter(latlng);
      this.center = marker;
      this.map.setZoom(12);
      this.marker.addListener("dragend", (e) => {
        const latLng = new google.maps.LatLng(e.latLng.lat(), e.latLng.lng());
        this.typeform.latitude = e.latLng.lat();
        this.typeform.longitude = e.latLng.lng();
        let self = this;
        geocoder.geocode({ latLng: latLng }, function (results, status) {
          if (status === "OK") {
            const address = $("#addres").val(results[0].formatted_address);
            self.typeform.address = results[0].formatted_address;
          }
        });
      });
    },
    addAddress() {
      this.$v.$touch();
      if (this.$v.typeform.$invalid) {
        return;
      } else {
        const { flat_no,  house_name, landmark, address  } = this.typeform;
        this.typeform.address = flat_no +','+ house_name+','+ landmark+','+address;
        if(!this.user_id ){
          this.$emit('address-added',{...this.typeform,user_id: null, id:Math.random().toString(36).substring(7)});
          return false;
        }
        const fd = new FormData();
        fd.append("user_id", this.user_id);
        fd.append("city", this.typeform.city);
        fd.append("address_clarification", this.typeform.address_clarification);
        fd.append("landmark", this.typeform.landmark);
        fd.append("address", this.typeform.address);
        fd.append("state", this.typeform.state);
        fd.append("flat_no", this.typeform.flat_no);
        fd.append("house_name", this.typeform.house_name);
        fd.append("latitude", this.typeform.latitude);
        fd.append("zip", this.typeform.zip);
        fd.append("longitude", this.typeform.longitude);
        callCenterDashboardService.addCustomerAddress(fd).then((response) => {
          this.$v.typeform.$reset();
          if (response.data.code == 200) {
            success_message(response.data.message);
            this.$emit('address-added',response.data.result);
            this.$bvModal.hide('addNewAddress');
            this.typeform = {
              address: '',
              latitude: '',
              longitude: '',
              address_clarification: 'Home',
              house_name: '',
              flat_no: '',
              landmark: '',
              city: '',
              state: '',
              zip: ''
            }
            // this.type
          } else {
            error_message(response.data.message);
          }
        });
      }
    },
  },
};
</script>

<style>
</style>