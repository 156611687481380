<script>
import DatePicker from "vue2-datepicker";
import Multiselect from 'vue-multiselect'
import PageHeader from "../../../components/page-header";
import Layout from "../../../layouts/main";
import { vendorFinanceService, settingService } from '../../../services';
import { get_currency, get_decimal, toKebabCase } from '../../../Helper/helper';

/**
 * Contacts-list component
 */ 
export default {
    components: { Layout, PageHeader, DatePicker,Multiselect },
    props: ['restaurant_id'],
    data() {
        return {
            decimal:get_decimal(),
            month_data: [],
            month: this.moment().format('MMMM'),
            year: this.moment().format('YYYY'),
            sortBy: "date",
            sortDesc: true,
            setting: [],
            years: [],
            currency: get_currency(),
            months: Array.apply(0, Array(12)).map((_,i) => this.moment().month(i).format('MMMM')).reverse(),
            fields: [
                { key: "date", label: this.trans.get("__JSON__.Date"), sortable: false },
                { key: "sum_sub_mrp_total", label: this.trans.get("__JSON__.Item MRP"), sortable: false },
                { key: "sum_sub_total", label: this.trans.get("__JSON__.Item Price"), sortable: false },
                { key: "sum_itemtax_total", label: this.trans.get("__JSON__.Item")+ " " + this.$auth.getTaxName(), sortable: false },
                { key: "sum_delivery_fee", label: this.trans.get("__JSON__.Delivery Charges"), sortable: false },
                { key: "sum_service_fee", label: this.trans.get("__JSON__.Cart")+ " " + this.$auth.getTaxName(), sortable: false },
                { key: "sum_discount", label: this.trans.get("__JSON__.Coupon Discount"), sortable: false },
                { key: "sum_amount", label: this.trans.get("__JSON__.Total Amount"), sortable: false },
                { key: "sum_admin_commision", label: this.trans.get("__JSON__.Admin Commission") + " (Order)", sortable: false },
                { key: "sum_driver_eanring", label: this.trans.get("__JSON__.Admin Commission") + " (Delivery)", sortable: false},
                { key: "chef_earning", label: this.trans.get("__JSON__.Vendor Earning",{vendor: this.$auth.getRestaurantName()}), sortable: false },
                { key: "sum_admin_earning", label: this.trans.get("__JSON__.Admin Earning"), sortable: false , requiresAdmin: true},
            ],
        };
    },
    computed: {
        computedFields() {
            // If the user isn't an admin, filter out fields that require auth.
            if(this.$auth.partner_login)
                return this.fields.filter(field => !field.requiresAdmin);
            // If the user IS an admin, return all fields.
            else
                return this.fields;
        }
    },
    mounted(){
        this.getYears()
        this.monthWiseRestaurantEarnings()
    },
    methods:{
        getYears(){
            var year = 2017;
            var years = [];
            while(year != this.moment().format('YYYY') ){
                years.push(++year);
            }
            this.years = years;
        },
        monthWiseRestaurantEarnings(page = 1){
            vendorFinanceService.getMonthwiseRestaurantEarnings({
                month: this.month,
                year: this.year,
                restaurant_id: this.restaurant_id,
                sortBy: this.sortBy,
                orderBy: this.sortDesc ? "desc" : "asc",
                page: page ? page : 1,
            }).then(res => {
                this.month_data = res.data.result.data;
                var total = {
                    chef_earning: this.month_data.reduce((a, m) => (a + m.chef_earning),0),
                    date: "Total",
                    month: "September 2020",
                    sum_admin_commision: this.month_data.reduce((a, m) => (a + m.sum_admin_commision),0),
                    sum_driver_eanring: this.month_data.reduce((a, m) => (a + m.sum_driver_eanring),0),
                    sum_admin_earning: this.month_data.reduce((a, m) => (a + m.sum_admin_earning),0),
                    sum_amount: this.month_data.reduce((a, m) => (a + m.sum_amount),0),
                    sum_chef_penalty_charge: this.month_data.reduce((a, m) => (a + m.sum_chef_penalty_charge),0),
                    sum_delivery_fee: this.month_data.reduce((a, m) => (a + m.sum_delivery_fee),0),
                    sum_discount: this.month_data.reduce((a, m) => (a + m.sum_discount),0),
                    sum_service_fee: this.month_data.reduce((a, m) => (a + m.sum_service_fee),0),
                    sum_sub_mrp_total: this.month_data.reduce((a, m) => (a + m.sum_sub_mrp_total),0),
                    sum_sub_total: this.month_data.reduce((a, m) => (a + m.sum_sub_total),0),
                    sum_itemtax_total: this.month_data.reduce((a, m) => (a + m.sum_itemtax_total),0),
                }
                this.month_data = [...this.month_data,total]
                this.currency = res.data.result.currency
            });
        },
        downloadsCSV: function() {
            let vendorFinance = '\ufeff' +
            ''+','
            +'IM'+','
            +'IP'+','
            +'IT'+','
            +'DC'+','
            +'CT'+','
            +'CD'+','
            +'TA = IP + DC - CD'+','
            +'ACO = (IM % Commission/ Fix)'+','
            +'ACD = DC - FME'+','
            +'VE = IP - ACO'+','
            +'AE = ACO + ACD - CD'+','+'\n'

            vendorFinance +=  
            this.trans.get("__JSON__.Date")+','
            +this.trans.get("__JSON__.Item MRP")+','
            +this.trans.get("__JSON__.Item Price")+','
            +this.trans.get("__JSON__.Item")+ " " + this.$auth.getTaxName()+','
            +this.trans.get("__JSON__.Delivery Charges")+','
            +this.trans.get("__JSON__.Cart")+ " " + this.$auth.getTaxName()+','
            +this.trans.get("__JSON__.Coupon Discount")+','
            +this.trans.get("__JSON__.Total Amount")+','
            +this.trans.get("__JSON__.Admin Commission")+' (Order),'
            +this.trans.get("__JSON__.Admin Commission")+' (Delivery),'
            +this.trans.get("__JSON__.Vendor Earning",{vendor: this.$auth.getRestaurantName()})+','
            +this.trans.get("__JSON__.Admin Earning")
            +'\n';
      
            this.month_data.map(el=> {
                var line = el['date'] + ',' 
                + el['sum_sub_mrp_total'].toFixed(this.decimal) + ',' 
                + el['sum_sub_total'].toFixed(this.decimal) + ',' 
                + el['sum_itemtax_total'].toFixed(this.decimal) + ',' 
                + el['sum_delivery_fee'].toFixed(this.decimal) + ',' 
                + el['sum_service_fee'].toFixed(this.decimal) + ',' 
                + el['sum_discount'].toFixed(this.decimal) + ',' 
                + el['sum_amount'].toFixed(this.decimal) + ',' 
                + el['sum_admin_commision'].toFixed(this.decimal) + ',' 
                + el['sum_driver_eanring'].toFixed(this.decimal) + ',' 
                + el['chef_earning'].toFixed(this.decimal) + ','
                + el['sum_admin_earning'].toFixed(this.decimal) +'\n'
                vendorFinance +=line;
            });

            var blob = new Blob([ vendorFinance ], { "type" : "csv/plain" });
            let link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = toKebabCase(this.$auth.getRestaurantName()) + "-finance-"+this.month+'-'+this.year+'.csv'
            link.click()
        },
        disabledDate(date){
            return (this.moment(date).format('YYYY') > this.moment().format('YYYY'))
        }
        /* getTotal(key){
        return Object.keys(this.vendorFinance && this.vendorFinance.monthWiseFinance || {})
            .reduce((acc, index) => {
            return acc + parseFloat(this.vendorFinance.monthWiseFinance[index][key]?this.vendorFinance.monthWiseFinance[index][key].toString().replaceAll(',',''):0);
            },0).toFixed(2);
        } */
    }
};
</script>

<template>
    <div class="row">
        <div class="col-12">
            <div class="justify-content-end mb-3 row restaurant-month">
                <div class="w-xl">
                    <label for="">{{trans.get('__JSON__.Month')}}</label>
                    <div class="text-sm-right">
                        <div class="search-box mr-2 ">
                            <multiselect v-model="month" :options="months" @input="monthWiseRestaurantEarnings()" :allow-empty="false"></multiselect>
                        </div>
                    </div>
                </div>
                <div class="">
                    <label for="">{{trans.get('__JSON__.Year')}}</label>
                    <div class="text-sm-right">
                        <div class="search-box mr-2 ">
                            <date-picker v-model="year" type="year" value-type="format" lang="en" :disabledDate="disabledDate" :clearable="false" @change="monthWiseRestaurantEarnings()" confirm :placeholder="trans.get('__JSON__.Select Year')"></date-picker>
                        </div>
                    </div>
                </div>
                <div class="mt-4 pt-1 mr-3">
                    <div class="text-sm-right">
                        <button @click="downloadsCSV" class="btn btn-primary btn-rounded export-btn">
                        {{trans.get('__JSON__.Export To CSV')}}
                        </button>
                            <button @click="downloadsCSV" class="btn btn-primary btn-rounded m-export-btn">
                            <i class="bx bx-archive-in"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="table-responsive table-border">
                <b-table
                    :items="month_data"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :no-local-sorting="true"
                    show-empty
                    class="table-centered table-nowrap table-hover order-menu"
                >
                    <template #thead-top>
                        <b-tr class="formulas">
                            <b-th></b-th>
                            <b-th>IM</b-th>
                            <b-th>IP</b-th>
                            <b-th>IT</b-th>
                            <b-th>DC</b-th>
                            <b-th>CT</b-th>
                            <b-th>CD</b-th>
                            <b-th>TA = IP + DC - CD</b-th>
                            <b-th>ACO = (IM % Commission/ Fix)</b-th>
                            <b-th>ACD = DC - FME</b-th>
                            <b-th>VE = IP - ACO</b-th>
                            <b-th>AE = ACO + ACD - CD</b-th>
                        </b-tr>
                    </template>
                    <template #cell(date)="data">
                        <span class="font-weight-bold">{{data.value}}</span>
                    </template>
                    <template #cell(sum_sub_mrp_total)="data">
                        <span v-html="currency"></span> {{ data.value.toFixed(decimal) }}
                    </template>
                    <template #cell(sum_sub_total)="data">
                        <span v-html="currency"></span> {{ data.value.toFixed(decimal) }}
                    </template>
                    <template #cell(sum_itemtax_total)="data">
                        <span v-html="currency"></span> {{ data.value.toFixed(decimal) }}
                    </template>
                    <template #cell(sum_delivery_fee)="data">
                        <span v-html="currency"></span> {{ data.value.toFixed(decimal) }}
                    </template>
                    <template #cell(sum_service_fee)="data">
                        <span v-html="currency"></span> {{ data.value.toFixed(decimal) }}
                    </template>
                    <template #cell(sum_discount)="data">
                        <span v-html="currency"></span> {{ data.value && data.value.toFixed(decimal) || '0.00'}}
                    </template>
                    <template #cell(sum_amount)="data">
                        <span v-html="currency"></span> {{ data.value.toFixed(decimal) }}
                    </template>
                    <template #cell(sum_admin_commision)="data">
                        <span v-html="currency"></span> {{ data.value.toFixed(decimal) }}
                    </template>
                    <template #cell(sum_driver_eanring)="data">
                        <span v-html="currency"></span> {{ data.value.toFixed(decimal) }}
                    </template>
                    <template #cell(chef_earning)="data">
                        <span v-html="currency"></span> {{ data.value && data.value.toFixed(decimal) || '0.00'}}
                    </template>
                    <template #cell(sum_admin_earning)="data">
                        <span v-html="currency"></span> {{ data.value && data.value.toFixed(decimal) || '0.00'}}
                    </template> 
                </b-table>
            </div>
        </div>
    </div>
</template>
<style scoped>
.formulas th{
  font-size: 10px;
}
</style>