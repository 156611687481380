<script>
import { businessTypeService } from "../../services";
import { required } from "vuelidate/lib/validators";
import { success_message, error_message } from "../../Helper/helper";
import General from "./general-settings/general.vue";
import Order from "./general-settings/order";
import DeliveryBoy from "./general-settings/delivery-boy";
import PushNotifications from "./general-settings/push-notifications";
import Keys from "./general-settings/keys";
import Version from "./general-settings/version";
import Tax from "./general-settings/tax";
import Wallet from "./general-settings/wallet";

/**
 * Add-product component
 */
export default {
  components: {
    businessTypeService,
    General,
    Order,
    DeliveryBoy,
    PushNotifications,
    Keys,
    Version,
    Tax,
    Wallet,
  },
  data() {
    return {
      typeform: {
        id: "",
        name: "",
        status: "",
      },
      id: "",
      business: {},
    };
  },
  validations: {
    typeform: {
      name: {
        required,
      },
    },
  },
  mounted() {
    this.getBusinessTypeById();
  },
  methods: {
    getBusinessTypeById() {
      businessTypeService
        .getBusinessTypeById({ id: this.$route.params.id })
        .then((response) => {
          const { id, name, status } = response.data.result.business;
          this.id = response.data.result.business.id;
          this.business = response.data.result.business;
          this.typeform = {
            id,
            name,
            status,
          };
        });
    },
    businessInIt() {
      this.$v.typeform.$touch();

      if (this.$v.typeform.$invalid) {
        return;
      } else {
        const fd = new FormData();
        fd.append("id", this.typeform.id);
        fd.append("name", this.typeform.name);
        fd.append("status", this.typeform.status);
        businessTypeService.updateBusinessTypeById(fd).then((response) => {
          if (response.data.code === 200) {
            success_message(response.data.message);
            this.$router.push({ name: "business-type-edit" });
          } else {
            error_message(response.data.message);
          }
        });
      }
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col-12">
      <div class="">
        <div class="row">
          <div class="col-lg-12">
            <div class="card">
              <div class="card-body">
                <!-- Tab panel start-->
                <b-tabs
                  justified
                  lazy
                  nav-class="nav-tabs-custom mt-3 order-tabs"
                  content-class="p-3 text-muted"
                >
                  <b-tab active>
                    <template v-slot:title>
                      <span class="d-inline-block d-sm-none">
                        <i class="fas fa-home"></i>
                      </span>
                      <span class="d-none d-sm-inline-block">{{
                        trans.get("__JSON__.General")
                      }}</span>
                    </template>
                    <General />
                  </b-tab>
                  <b-tab>
                    <template v-slot:title>
                      <span class="d-inline-block d-sm-none">
                        <i class="far fa-user"></i>
                      </span>
                      <span class="d-none d-sm-inline-block">{{
                        trans.get("__JSON__.Order")
                      }}</span>
                    </template>
                    <Order />
                  </b-tab>
                  <b-tab>
                    <template v-slot:title>
                      <span class="d-inline-block d-sm-none">
                        <i class="far fa-envelope"></i>
                      </span>
                      <span class="d-none d-sm-inline-block">{{
                        $auth.getDriverName()
                      }}</span>
                    </template>
                    <DeliveryBoy />
                  </b-tab>
                  <b-tab>
                    <template v-slot:title>
                      <span class="d-inline-block d-sm-none">
                        <i class="fas fa-home"></i>
                      </span>
                      <span class="d-none d-sm-inline-block">{{
                        $auth.getTaxName()
                      }}</span>
                    </template>
                    <Tax />
                  </b-tab>
                  <!-- <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-cog"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">{{ trans.get('__JSON__.Push Notifications') }}</span>
                </template>
                <PushNotifications/>
              </b-tab> -->
                  <b-tab>
                    <template v-slot:title>
                      <span class="d-inline-block d-sm-none">
                        <i class="fas fa-cog"></i>
                      </span>
                      <span class="d-none d-sm-inline-block">{{
                        trans.get("__JSON__.Wallet")
                      }}</span>
                    </template>
                    <Wallet />
                  </b-tab>
                </b-tabs>
                <!-- Tab panel end -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end row -->
</template>
