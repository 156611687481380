<script>
import config from "../../config";
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import DriverProfile from "./pages/driver-profile";
import DriverOrder from "./pages/driver-order";
import DriverRating from "./driver-rating";
import { driverService, orderService } from "../../services";
import DateRangePicker from "vue2-daterange-picker";
import OrderList from "../orders/order_tabs/order-list";
import pagination from "laravel-vue-pagination";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import {  order_statuses,success_message,error_message } from "../../Helper/helper";
import ChangePassword from "./change-password";
/**
 * Tabs & accordions component
 */
export default {
  components: {
    Layout,
    PageHeader,
    DriverProfile,
    DriverRating,
    DateRangePicker,
    OrderList,
    pagination,
    DriverOrder,
    ChangePassword
  },
  props:['driver_id'],
  data() {
    return {
      config:config,
      driver: [],
      loading: false,
      statuses: order_statuses,
      is_block: '',
      is_block_default: '',
      availbility_status: '',
      deliveryZoneEmit: '',
      restaurant_id:'',
    };
  },
  created() {
    this.getDriverInfo();
  },
  methods: {
    getDriverInfo() {
      driverService
        .getDriverInfo({ driver_id: this.$route.params.driver_id })
        .then((response) => {
          this.driver = response.data.result;
          this.is_block_default = response.data.result.is_block;
          this.is_block = response.data.result.is_block;
          this.availbility_status = response.data.result.availbility_status;
        });
    },
    changeStatus(){
      if (this.$auth.user.vendor_id == this.deliveryZoneEmit || this.deliveryZoneEmit == '') {
        error_message("Please update driver delivery zone and Pharmacy to change profile status");
        this.is_block = this.is_block_default;
        return false;
      } else {
        driverService.updateDriverStatus({
          driver_id:this.$route.params.driver_id,
          is_block: this.is_block
        }).then(response => {
          if(response.data.code == 200){
            success_message(response.data.message)
          }
        })
      }
    },
    onStatusChange(){
      driverService.updateAvailbilityStatus({
        driver_id:this.$route.params.driver_id,
        availbility_status: this.availbility_status
      }).then(response => {
        if(response.data.code == 200){
          success_message(response.data.message)
        }
      })
    },
    deliveryZoneEmitchanged(val) {
      this.deliveryZoneEmit = val;
    }
  },
  filters: {
    date(val) {
      return val ? val.toLocaleString() : "";
    },
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
        >
          <div class="page-title-left avatar-main-icon">
            <div class="avatar-xs mr-3">
              <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                <i class="bx bx-user"></i>
              </span>
            </div>
            <div class="report-tag">
              <h4 class="mb-0 font-size-18">{{ driver.firstname }} {{ driver.lastname }}</h4>
              <p>
                <router-link
                  :to="{ name: 'driver-list'}"
                >{{ trans.trans('__JSON__.Driver',{ driver: $auth.setting.driver_name }) }}</router-link>
                > {{ driver.firstname }} {{ driver.lastname }}
              </p>
            </div>
          </div>
          <div class="page-title-left avatar-main-icon">
            <div class="row">
              <div class="col-md-5 ml-auto">
                <label class="control-label font-weight-bold">{{ trans.get('__JSON__.Profile Status') }}</label> 
                  <select class="custom-select" v-bind:value="is_block" v-model="is_block"  id="is_block" name="is_block" @change="changeStatus" :disabled="!$auth.hasPermission(config.permissions.driver_write)"> 
                      <option value="0">{{ trans.get('__JSON__.Active') }}</option>
                      <option value="1">{{ trans.get('__JSON__.Inactive') }}</option>
                      <option value="2">{{ trans.get('__JSON__.Waiting For Approval') }}</option>
                  </select>
              </div>
              <div class="col-md-3 accept-order">
                <label class="control-label font-weight-bold">{{ trans.get('__JSON__.Job Status') }}</label>
                <div class="swtich-data big-swtch">
                  <b-form-group>
                    <label class="switch" :class="{'disableswitch' : !$auth.hasPermission(config.permissions.driver_write)}">
                      <input
                        type="checkbox"
                        id="availbility_status"
                        name="availbility_status"
                        v-model="availbility_status"
                        class="switch-on"
                        true-value="1"
                        false-value="0"
                        :disabled="!$auth.hasPermission(config.permissions.driver_write)"
                        @change="onStatusChange"
                      />
                      <div class="slider round">
                        <span class="on">{{ trans.get('__JSON__.Online') }}</span>
                        <span class="off">{{ trans.get('__JSON__.Offline') }}</span>
                      </div>
                    </label>
                  </b-form-group>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="nav-tabs-main">
        <div class="card">
          <div class="card-body">
            <!-- Tab panel start-->
            <b-tabs
              justified
              nav-class="nav-tabs-custom mt-3 order-tabs"
              content-class="p-3 text-muted"
            >
              <b-tab active>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-user"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">{{ trans.get('__JSON__.Profile') }}</span>
                </template>
                <DriverProfile v-if="!!driver" :driver_data ="driver" :deliveryZoneEmit="deliveryZoneEmit"  @deliveryZoneEmitchanged="deliveryZoneEmitchanged($event)"/>
              </b-tab>
              <b-tab lazy :button-id="statuses.delivered">
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-clock"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">{{ trans.get('__JSON__.Delivered') }}</span>
                </template>
                <DriverOrder :driver_id="$route.params.driver_id" :status="statuses.delivered" />
              </b-tab>
              <b-tab :button-id="statuses.cancelled">
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-times-circle"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">{{ trans.get('__JSON__.Rejected') }}</span>
                </template>
                <DriverOrder :driver_id="$route.params.driver_id" :status="statuses.cancelled" />
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                      <i class="fas fa-star"></i>
                    </span>
                    <span class="d-none d-sm-inline-block">{{ trans.get('__JSON__.Rating & Reviews') }}</span>
                </template>
                <DriverRating />
              </b-tab>
               <b-tab v-if="$auth.hasPermission(config.permissions.driver_write)">
                  <template v-slot:title>
                    <span class="d-inline-block d-sm-none">
                      <i class="fas fa-lock"></i>
                    </span>
                    <span class="d-none d-sm-inline-block">{{ trans.get('__JSON__.Change Password') }}</span>
                  </template>
                  <ChangePassword v-if="!!driver" :driver_data="driver"/>
               </b-tab>
            </b-tabs>
            <!-- Tab panel end -->
          </div>
        </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<style scoped>
.switch.disableswitch .slider {
  background-color: #d7dde9;
}
</style>