<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import VendorEarning from "../../components/widgets/vendor-earning";
import VendorSettlements from "../../components/widgets/vendor-settlements";

/**
 * Starter component
 */
export default {
  components: { Layout, PageHeader, VendorEarning, VendorSettlements },
  data() {
    return {
      title: "Finance"
    };
  }
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" />
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">

                    <!-- Tab panel start-->
                      <b-tabs justified nav-class="nav-tabs-custom mt-3 order-tabs" content-class="p-3 text-muted">
                          <b-tab active>
                            <template v-slot:title>
                              <span class="d-inline-block d-sm-none">
                                <i class="fas fa-home"></i>
                              </span>
                              <span class="d-none d-sm-inline-block">DashBoard</span>
                            </template>
                            <div class="search-loader">
                                <div class="search-icon">
                                    <span class="bx bx-search-alt"></span>
                                </div>
                            </div>
                          </b-tab>
                          <b-tab>
                            <template v-slot:title>
                              <span class="d-inline-block d-sm-none">
                                <i class="far fa-user"></i>
                              </span>
                              <span class="d-none d-sm-inline-block">Earnings</span>
                            </template>
                            <VendorEarning/>
                          </b-tab>
                          <b-tab>
                            <template v-slot:title>
                              <span class="d-inline-block d-sm-none">
                                <i class="far fa-envelope"></i>
                              </span>
                              <span class="d-none d-sm-inline-block">Settlements</span>
                            </template>
                            <VendorSettlements/>
                          </b-tab>
                       </b-tabs>
                      <!-- Tab panel end -->
                </div>
            </div>
        </div>
    </div>
  </Layout>
</template>