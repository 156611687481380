<script>
import Layout from '../../layouts/main'
import PageHeader from '../../components/page-header'
import { vendorService} from '../../services';
import { error_message,replaceByDefault } from "../../Helper/helper";
import { success_message } from '../../Helper/helper';
import { required,email, numeric, requiredIf } from "vuelidate/lib/validators";
import DropzonePopup from "../../components/widgets/dropzone-popup";
import { routes } from "../../config/api_routes";
import config from "../../config";

export default {
  components: { Layout, PageHeader, DropzonePopup },
  data() {
    return {
      config: config,
      profile: {
        app_name: "",
        vendor_name: "",
        email: "",
        vendor_number: "",
        address: "",
        gst_number: "",
        call_for_help: "",
        primary_lang: "",
        secondary_lang: "",
        currency: "",
        timezone: "",
        fromemail: "",
        country_code: "",
        app_logo: "",
        flat_icon: "",
        date_format: "",
        currency_short_name: "",
        distance_metric: "kilometers"
      },
      support_field: [{ email: '',phone: '',whatsapp: ''}],
      primary_langs: "",
      secondary_langs: "",
      filtered_secondary_langs : "",
      currencies: "",
      timezones: "",
      countrycodes: "",
      typesubmit: false,
      app_logo_image: "",
      app_logo_image_path: "",
      app_logo_image_delete_url: routes.deleteAppLogoApi,
      flat_icon_image: "",
      flat_icon_image_path: "",
      flat_icon_image_delete_url: routes.deleteFlatIconApi,
    };
  },
  validations: {
    profile: {
      app_name: {
        required
      },
      vendor_name: {
        required,
      },
      email: {
        required,
        email
      },
      vendor_number: {
        required,
        numeric
      },
      primary_lang: {
        required
      },
      currency: {
        required,
      },
      timezone: {
        required,
      },
      fromemail: {
        required,
        email
      },
      country_code: {
        required,
      },
      date_format: {
        required,
      },
      distance_metric: {
        required,
      }
    }
  },
  computed: {
  },
  mounted(){
    this.getBusinessProfile();
    this.getLanguage();
  },
  methods: {
    filtered: function(){
      this.filtered_secondary_langs = this.secondary_langs.filter((sec) => {
        return sec.abbrivation != this.profile.primary_lang;
      });
    },
    onAppLogoImagedeleted(value) {
      this.app_logo_image = "";
    },
    onFlatIconImagedeleted(value) {
      this.flat_icon_image = "";
    },
    getBusinessProfile(){
      vendorService.getBusinessProfile()
      .then(response => {
        const { 
          app_name,
          vendor_name,
          email,
          vendor_number,
          address,
          gst_number,
          call_for_help,
          primary_lang,
          secondary_lang,
          currency,
          timezone,
          fromemail,
          country_code,
          date_format,
          currency_short_name,
          distance_metric
         } = response.data.result;
        this.app_logo_image_path = response.data.result.app_image_logo_path;
        this.app_logo_image = response.data.result.app_logo;
        this.flat_icon_image_path = response.data.result.flat_icon_path;
        this.flat_icon_image = response.data.result.flat_icon;
        if (response.data.result.support_field && response.data.result.support_field !== '') {
          this.support_field = response.data.result.support_field;
        }
        this.profile = { 
          app_name,
          vendor_name,
          email,
          vendor_number,
          address,
          gst_number,
          call_for_help,
          primary_lang,
          secondary_lang,
          currency,
          timezone,
          fromemail,
          country_code,
          date_format,
          currency_short_name,
          distance_metric
        };
      });
    },
    getLanguage(){
      vendorService.getLanguage()
      .then(response => {
        this.primary_langs = response.data.result.primary_lang;
        this.secondary_langs = response.data.result.secondary_lang;
        this.filtered_secondary_langs = this.secondary_langs;
        this.currencies  = response.data.result.currency;
        this.timezones  = response.data.result.timezone;
        this.countrycodes  = response.data.result.country_code;
      });
    },
    typeForm(e) {
      this.$v.$touch();
      if (this.$v.profile.$invalid) {
        return;
      } else {
        vendorService.updateBusinessProfile({...this.profile,support_field:JSON.stringify(this.support_field)})
        .then(response => {
          if (response.data.code === 200) {
            if (response.data.result) {
              if (response.data.result.settings) {
                this.$auth.updateSetting(response.data.result.settings);
              }
            }
            success_message(response.data.message);
            // this.$router.push({ name: "business-profile" });
          } else {
            error_message(response.data.message);
          }
        });
      }
    },
    onCurrencyChange() {
      if (this.profile.currency) {
        var curr = this.currencies.find((elem)=>(elem.symbol == this.profile.currency));
        if (curr) {
          this.profile.currency_short_name = (curr.short_name != null) ? curr.short_name : '';
        }
      } else {
        this.profile.currency_short_name = '';
      }
    }
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between icon-main-page">
          <div class="page-title-left">
            <div class="avatar-xs mr-3">
              <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                <i class="bx bx-store"></i>
              </span>
            </div>
            <h4 class="mb-0 font-size-18">{{ trans.get('__JSON__.My Business Profile') }}</h4>
          </div>
          <div class="page-title-right">
            <label class="control-label font-weight-bold">{{ trans.get('__JSON__.Client Id') }}</label>
            <h6>{{ this.$auth.user.vendor_id }}</h6>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
    <div class="row">
      <div class="col-12">
        <form action="#" @submit.enter.prevent="typeForm()">
          <!-- Business Info Start -->
          <div class="card">
            <div class="card-body">
              <!-- <h4 class="card-title">Business Info</h4> -->
              <p class="card-title-desc"></p>
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="control-label required">{{ this.trans.get('__JSON__.Business Name') }}</label>
                    <input id="app_name" name="app_name" type="text" v-model="$v.profile.app_name.$model" placeholder="Enter business name" class="form-control" :class="{ 'is-invalid':$v.profile.app_name.$error }"/>
                    <div v-if="$v.profile.app_name.$error" class="invalid-feedback">
                      <span v-if="!$v.profile.app_name.required">{{ this.trans.get('__JSON__.Please enter business name') }}</span>
                    </div>
                  </div>    
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="control-label required">{{ this.trans.get('__JSON__.Business owner Name') }}</label>
                    <input id="vendor_name" name="vendor_name" type="text" v-model="$v.profile.vendor_name.$model" placeholder="Enter business owner name" class="form-control" :class="{ 'is-invalid':$v.profile.vendor_name.$error }"/>
                    <div v-if="$v.profile.vendor_name.$error" class="invalid-feedback">
                      <span v-if="!$v.profile.vendor_name.required">{{ this.trans.get('__JSON__.Please enter business owner name') }}</span>
                    </div>
                  </div>    
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="control-label required">{{ this.trans.get('__JSON__.Email Address') }}</label>
                    <input id="email" type="email" name="email" v-model="$v.profile.email.$model" class="form-control" placeholder="Enter email address" :class="{ 'is-invalid':$v.profile.email.$error }"/>
                    <div v-if="$v.profile.email.$error" class="invalid-feedback">
                      <span v-if="!$v.profile.email.required">{{ trans.get('__JSON__.Please enter email') }}</span>
                      <span v-if="!$v.profile.email.email">{{ trans.get('__JSON__.This value should be a valid email') }}</span>
                    </div>
                  </div>    
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="control-label required">{{ trans.get('__JSON__.Phone Number') }}</label>
                    <input maxlength="12" minlength="10" id="vendor_number" type="tel" name="vendor_number" v-model.number="$v.profile.vendor_number.$model" class="form-control" placeholder="Enter vendor_number number" :class="{ 'is-invalid':$v.profile.vendor_number.$error }"/>
                    <div v-if="$v.profile.vendor_number.$error" class="invalid-feedback">
                      <span v-if="!$v.profile.vendor_number.required">{{ trans.get('__JSON__.Please enter contact number') }}</span>
                      <span v-if="!$v.profile.vendor_number.numeric">{{ trans.get('__JSON__.This value should be a valid number') }}</span>
                    </div>
                  </div>    
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="control-label">{{ trans.get('__JSON__.Business Address') }}</label>
                    <input id="address" type="text" name="address" v-model="profile.address" class="form-control" placeholder="Enter business address"/>
                  </div>    
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="control-label">{{ trans.get('__JSON__.Business Tax Number') }}</label>
                    <input id="gst_number" name="gst_number" type="text" v-model="profile.gst_number" placeholder="Enter business tax number" class="form-control"/>
                  </div> 
                </div> 

                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="control-label">{{ trans.get('__JSON__.Help Line Number') }}</label>
                    <input  maxlength="15" id="call_for_help" name="call_for_help" type="tel" v-model="profile.call_for_help" placeholder="Enter business tax number" class="form-control"/>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="control-label required">{{ this.trans.get('__JSON__.Primary Language') }}</label>
                    <select class="custom-select" v-model="$v.profile.primary_lang.$model" @change="filtered" id="primary_lang" name="primary_lang" :class="{ 'is-invalid':$v.profile.primary_lang.$error }">
                      <option value="">{{trans.get('__JSON__.Select Primary Language')}}</option>
                      <option v-for="plang in primary_langs" :value="plang.abbrivation" :key="plang.id">{{ plang.name }}</option>
                    </select>
                    <div v-if="$v.profile.primary_lang.$error" class="invalid-feedback">
                      <span v-if="!$v.profile.primary_lang.required">{{ this.trans.get('__JSON__.Please select primary language') }}</span>
                    </div>
                  </div>    
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="control-label">{{ this.trans.get('__JSON__.Secondary Language') }}</label>
                    <select class="custom-select" v-model="profile.secondary_lang" id="secondary_lang" name="secondary_lang">
                      <!-- <option value="">{{trans.get('__JSON__.Select Secondary Language')}}</option> -->
                      <option v-for="slang in filtered_secondary_langs" :value="slang.abbrivation" :key="slang.id">{{ slang.name }}</option>
                    </select>
                  </div>    
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="control-label required">{{ this.trans.get('__JSON__.Country') }}</label>
                    <select class="custom-select" v-model="$v.profile.country_code.$model" id="country_code" name="country_code" :class="{ 'is-invalid':$v.profile.country_code.$error }">
                      <option value="">{{trans.get('__JSON__.Select Country')}}</option>
                      <option v-for="concode in countrycodes" :value="concode.code" :key="concode.id" >(+{{ concode.code }}) {{ concode.name }}</option>
                    </select>
                    <div v-if="$v.profile.country_code.$error" class="invalid-feedback">
                      <span v-if="!$v.profile.country_code.required">{{ trans.get('__JSON__.Please select country') }}</span>
                    </div>
                  </div>    
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="control-label required">{{ this.trans.get('__JSON__.Currency') }}</label>
                    <select class="custom-select" v-model="$v.profile.currency.$model" id="currency" name="currency" :class="{ 'is-invalid':$v.profile.currency.$error }" @change="onCurrencyChange">
                      <option value="">{{trans.get('__JSON__.Select Currency')}}</option>
                      <option v-for="cur in currencies" :value="cur.symbol" :key="cur.id"><span v-html="cur.symbol"></span> ({{ cur.name }})</option>
                    </select>
                    <div v-if="$v.profile.currency.$error" class="invalid-feedback">
                      <span v-if="!$v.profile.currency.required">{{ trans.get('__JSON__.Please enter currency') }}</span>
                    </div>
                  </div>    
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="control-label required">{{ trans.get('__JSON__.Timezone') }}</label>
                    <select class="custom-select" v-model="$v.profile.timezone.$model" id="timezone" name="timezone" :class="{ 'is-invalid':$v.profile.timezone.$error }">
                      <option value="">{{trans.get('__JSON__.Select Timezone')}}</option>
                      <option v-for="(value, key) in timezones" :value="key" :key="key">{{ value }}</option>
                    </select>
                    <div v-if="$v.profile.timezone.$error" class="invalid-feedback">
                      <span v-if="!$v.profile.timezone.required">{{ trans.get('__JSON__.Please select timezone') }}</span>  
                    </div>
                  </div>    
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="control-label required">{{ trans.get('__JSON__.Order Update From Email') }}</label>
                    <input id="fromemail" name="fromemail" type="email" v-model="$v.profile.fromemail.$model" placeholder="Enter order update from email" class="form-control" :class="{ 'is-invalid':$v.profile.fromemail.$error }"/>
                    <div v-if="$v.profile.fromemail.$error" class="invalid-feedback">
                      <span v-if="!$v.profile.fromemail.required">{{ trans.get('__JSON__.Please enter order update from email') }}</span>
                      <span v-if="!$v.profile.fromemail.email">{{ trans.get('__JSON__.This value should be a valid email') }}</span>
                    </div>
                  </div>    
                </div>
                

                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="control-label required">{{ trans.get('__JSON__.Date Format') }}</label>
                    <select class="custom-select" v-model="$v.profile.date_format.$model" id="date_format" name="date_format" :class="{ 'is-invalid':$v.profile.date_format.$error }">
                      <!-- <option value="">{{trans.get('__JSON__.Select Date Format')}}</option> -->
                      <option value="m-d-y h:i A" selected>MM-DD-YY 12 Hours</option>
                      <option value="d-m-y h:i A">DD-MM-YY 12 Hours</option>
                      <option value="m-d-y H:i">MM-DD-YY 24 Hours</option>
                      <option value="d-m-y H:i">DD-MM-YY 24 Hours</option>
                    </select>
                    <div v-if="$v.profile.date_format.$error" class="invalid-feedback">
                      <span v-if="!$v.profile.date_format.required">{{ trans.get('__JSON__.Please select date format') }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="control-label required">{{ trans.get('__JSON__.Distance Metric') }}</label>
                    <select class="custom-select" v-model="$v.profile.distance_metric.$model" id="distance_metric" name="distance_metric" :class="{ 'is-invalid':$v.profile.distance_metric.$error }">
                      <option value="kilometers" selected>Kilometers</option>
                      <option value="miles">Miles</option>
                    </select>
                    <div v-if="$v.profile.distance_metric.$error" class="invalid-feedback">
                      <span v-if="!$v.profile.distance_metric.required">{{ trans.get('__JSON__.Please select distance metric') }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-sm-6"></div>

                <div class="col-sm-6">
                  <div class="row">
                    <div class="col-sm-3 business-app-image">
                      <div class="form-group">
                        <label class="control-label">{{ this.trans.get('__JSON__.Business Logo') }}<br>({{ trans.get('__JSON__.Rectangle 3:2') }})</label>
                         <dropzone-popup
                          v-model="profile.app_logo"
                          id="app_logo"
                          class="business-logo"
                          acceptedFiles=".jpeg,.jpg,.png,.webp,image/webp"
                          :editable-images="app_logo_image"
                          :media-path="app_logo_image_path"
                          :delete-path="app_logo_image_delete_url"
                          @image-deleted="this.onAppLogoImagedeleted"
                        />
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div class="form-group">
                        <label class="control-label">{{ this.trans.get('__JSON__.Square Logo') }}<br>({{ trans.get('__JSON__.Square 1:1') }})</label>
                         <dropzone-popup
                          v-model="profile.flat_icon"
                          id="flat_icon"
                          class="business-logo"
                          acceptedFiles=".jpeg,.jpg,.png,.webp,image/webp"
                          :editable-images="flat_icon_image"
                          :media-path="flat_icon_image_path"
                          :delete-path="flat_icon_image_delete_url"
                          @image-deleted="this.onFlatIconImagedeleted"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="row" v-for="(v,index) in support_field" :key="index">
                    <div class="col-6">
                      <label class="control-label">{{ trans.get('__JSON__.Email Support') }}</label>
                      <input id="email" name="email" type="email" v-model="v.email"  placeholder="Enter email" class="form-control"/>
                    </div> 
                    <div class="col-6">
                      <label class="control-label">{{ trans.get('__JSON__.Phone Support') }}</label>
                      <input maxlength="15" minlength="8" id="phone" type="tel" v-model="v.phone"  name="phone" class="form-control" placeholder="Enter phone number"/>
                    </div> 
                    <div class="col-6 mt-2">
                      <label class="control-label">{{ trans.get('__JSON__.Whatsapp Support') }}</label>
                      <input maxlength="15" minlength="8" id="whatsapp" type="tel" name="whatsapp" v-model="v.whatsapp" class="form-control" placeholder="Enter whatsapp number"/>
                    </div> 
                  </div>
                </div>
              </div> 
              
              <div class="row">
                <div class="col-sm-6">
                  <button class="btn btn-primary mr-1 mt-3">{{ trans.get('__JSON__.Update') }}</button>
                  <b-button @click="$router.back()" type="button" variant="secondary" class="mt-3">{{ trans.get('__JSON__.Cancel') }}</b-button>
                </div>
              </div>
            </div>
          </div>
          <!-- Business Info End -->   
        </form>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>