<script>
import PageHeader from "../../components/page-header";
import pagination from "laravel-vue-pagination";
import { logActivityService } from "../../services";
import VTooltip from 'v-tooltip';
import moment from "moment";
export default {
  components: {
    PageHeader,
    pagination,
    VTooltip
  },
  data() {
    return {
      logList: {},
      limit: 1,
      perPage: 0,
      currentPage: 1,
      search: "",
      moment: moment
    //   paginations: {},
    };
  },
  mounted() {
    this.getLogListing();
  },
  methods: {
    getLogListing(page) {
      logActivityService
        .getLogActivityListing({
          search: this.search,
          page: page ? page : 1,
        })
        .then((response) => {
        //   this.paginations = response.data.result;
          this.logList = response.data.result;
        });
    },
     searchData() {
      this.getLogListing();
    },
  },
};
</script>
<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
        >
          <div class="page-title-left">
            <div class="avatar-xs mr-3">
              <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                <i class="bx bx-log-in-circle"></i>
              </span>
            </div>
             <h4 class="mb-0 font-size-18">{{ trans.trans('__JSON__.SYSTEM LOGS') }}</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 order-table">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-2 offset-sm-10">
                <div class="date-range-list">
                  <label>{{ trans.get('__JSON__.Search') }} :</label>
                  <div class="text-sm-right">
                    <div class="search-box mr-2 d-inline-block">
                      <div class="position-relative">
                        <input
                          type="text"
                          class="form-control"
                          id="search"
                          @keyup="searchData()"
                          v-model="search"
                          :placeholder="trans.get('__JSON__.Search Placeholder')"
                        />
                        <i class="bx bx-search-alt search-icon"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="system-log-list">
              <ul
                v-for="(list, index) in logList.data"
                :key="list.id"
                class="list-unstyled chat-list"
                data-simplebar
              >
                <li>
                  <a>
                    <div class="media">
                      <div class="align-self-center mr-3">{{ (logList.per_page * (logList.current_page-1)) + index + 1 }}</div>

                      <div class="media-body overflow-hidden m-system-list">
                        <h5 class="text-truncate font-size-14 mb-1">
                          <b>{{list.email}}</b>
                        </h5>
                        <p class="text-truncate mb-0">{{list.subject}}</p>
                      </div>
                      <div class="font-size-12 system-time-log">
                        {{moment(list.created_at).fromNow()}}
                        <i class="bx bx-time text-primary mt-1 ml-1" v-b-tooltip.hover :title="list.created_date"></i>
                      </div>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
              <div class="row col-sm-5 offset-sm-7 mt-5">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <pagination
                        :data="logList"
                        :limit="limit"
                        @pagination-change-page="getLogListing"
                      ></pagination>
                    </ul>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </div>
</template>
