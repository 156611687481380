
<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import config from "../../config";
import pagination from "laravel-vue-pagination";
import { vendorService } from "../../services";
import {
  error_message,
  success_message,
} from "../../Helper/helper";
import { required, requiredIf } from "vuelidate/lib/validators";
import DropzonePopup from "../../components/widgets/dropzone-popup";
import { routes } from "../../config/api_routes";
import draggable from "vuedraggable";

export default {
  components: { Layout, PageHeader, pagination, vendorService, DropzonePopup, draggable },
  data() {
    return {
      title: this.trans.get('__JSON__.Category'),
      url: null,
      checked: true,
      showModal: false,
      editModal: false,
      sortBy: "reorder_data",
      sortDesc: true,
      loading: true,
      vcategory: [],
      vcategoryData: [],
      paginations: {},
      limit: 1,
      search: "",
      status: 1,
      config: config,
      typeform: {
        name: "",
        restaurant_id: this.$route.params.restaurant_id,
        name_another_lang: "",
        reorder_data: "0",
        color_code: "#f2f3f4",
        text_color_code: "#000000",
        image: null,
        status: 1,
      },
      menucategory: {
        id: "",
        name: "",
        restaurant_id: this.$route.params.restaurant_id,
        name_another_lang: "",
        reorder_data: "0",
        color_code: "#f2f3f4",
        text_color_code: "#000000",
        status: "",
        category_image: "",
      },
      image: "",
      category_image_path: "",
      category_media_path: "",
      category_image_delete_url: routes.categroyImageDeleteUrl,
      id: "",
      submitted: false,
      fields: [
        { key: "category_image_path", label: "#", sortable: false },
        { key: "name", label: "Name", sortable: true },
        { key: "status", label: "Status", sortable: false },
        { key: "action", label: "Action", sortable: false },
      ],
    };
  },
  validations: {
    typeform: {
      name: {
        required,
      },
      image: {
        required,
      },
    },
    menucategory: {
      name: {
        required,
      },
      category_image: {
        required: requiredIf(function (nestedModel) {
          return !(!!this.image || !!this.menucategory.category_image);
        }),
      },
    },
  },
  mounted() {
    this.getVendorCategory();
  },
  methods: {
    onCategoryImagedeleted(value) {
      this.image = "";
    },

    getVendorCategory(page) {
      this.loading = true;
      vendorService
        .getCategoryList({
          search: this.search,
          status: this.status,
          sortBy: this.sortBy,
          orderBy: this.sortDesc ? "asc" : "desc",
          page: page ? page : 1,
          restaurant_id: this.$route.params.restaurant_id,
        })
        .then((response) => {
          this.loading = false;
          this.paginations = response.data.result;
          this.vcategory = response.data.result.data;
        });
    },
    searchData() {
      this.getVendorCategory();
    },
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      this.getVendorCategory();
    },
    menuCategory() {
      this.$v.typeform.$touch();
      if (this.$v.typeform.$invalid) {
        return;
      } else {
        this.submitted = true;
        const fd = new FormData();
        fd.append("restaurant_id", this.typeform.restaurant_id);
        fd.append("name", this.typeform.name);
        fd.append("name_another_lang", this.typeform.name_another_lang);
        fd.append("image", this.typeform.image);
        fd.append("color_code", this.typeform.color_code);
        fd.append("text_color_code", this.typeform.text_color_code);
        fd.append("reorder_data", this.typeform.reorder_data);
        fd.append("status", this.typeform.status);
        vendorService.addMenuCategory(fd).then((response) => {
          this.submitted = false;
           this.typeform = {
              restaurant_id: this.$route.params.restaurant_id,
              name: '',
              name_another_lang: '',
              reorder_data: "0",
              color_code: "#f2f3f4",
              text_color_code: "#000000",
              image: null,
              status: 1
            }
          this.$v.typeform.$reset();
          if (response.data.code === 200) {
            success_message(response.data.message);
            this.$bvModal.hide("showModal");
            this.$emit('category-added');
            this.getVendorCategory();
          } else {
            error_message(response.data.message);
          }
        });
      }
    },
    editCategory(id) {
      this.fetchCategory(id);
    },
    fetchCategory(id) {
      return vendorService.getMenuCategoryById({ id: id }).then((response) => {
        const {
          id,
          restaurant_id,
          name,
          name_another_lang,
          color_code,
          text_color_code,
          reorder_data,
          status,
        } = response.data.result;

        this.category_image_path = response.data.result.category_image_path;
        this.category_media_path = response.data.result.category_media_path;
        this.image = response.data.result.image;
        this.id = response.data.result.id;

        this.menucategory = {
          id,
          restaurant_id,
          name,
          name_another_lang,
          color_code,
          text_color_code,
          reorder_data,
          status,
        };
        this.editModal = true;
      });
    },
    updateMenuCategory() {
      this.$v.menucategory.$touch();
      if (this.$v.menucategory.$invalid) {
        return;
      } else {
        this.submitted = true;
        const fd = new FormData();
        fd.append("id", this.menucategory.id);
        fd.append("restaurant_id", this.menucategory.restaurant_id);
        fd.append("name", this.menucategory.name);
        fd.append("name_another_lang", this.menucategory.name_another_lang);
        fd.append("color_code", this.menucategory.color_code);
        fd.append("text_color_code", this.menucategory.text_color_code);
        fd.append("reorder_data", this.menucategory.reorder_data);
        fd.append("status", this.menucategory.status);
        if (!!this.menucategory.category_image) {
          fd.append("image", this.menucategory.category_image);
        }
        vendorService.updateMenuCategoryById(fd).then((response) => {
          this.submitted = false;
          if (response.data.code === 200) {
            success_message(response.data.message);
            this.$bvModal.hide("editModal");
            this.getVendorCategory();
          } else {
            error_message(response.data.message);
          }
        });
      }
    },
    deleteById(id) {
      if (confirm("Are you sure you want to delete?")) {
        vendorService.deleteMenuCategory({ id: id }).then((response) => {
          error_message(response.data.message);
          this.getVendorCategory();
        });
      }
    },
    updateMenuCatOrder: function() {
      var items = this.vcategory.map(function(item, index) {
        return { item: item, order: index }
      });
      if(this.debounce) return
      var ids = items.map(item => item.item.id);

      this.debounce = setTimeout(function(items) {
        this.debounce = false;
        vendorService.updateMenuCatOrder({ ids : ids})
        .then(response => {
          if (response.data.code === 200) {
            this.getVendorCategory();
          }
        });
      }.bind(this, items), 10)
    }
  },
};
</script>

<template>
  <div v-if="$auth.partner_login">
    <Layout>
      <PageHeader :title="title" />
        <div class="row">
          <div class="col-12 list-table-main">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-sm-4 offset-sm-8 category-search">
                    <div class="d-flex justify-content-between align-items-end">
                      <div class="col-sm-6">
                        <div class="search-box d-inline-block">
                          <div class="date-range-list">
                            <label>{{ trans.get('__JSON__.Search') }} :</label>
                            <div class="position-relative">
                              <input
                                type="text"
                                class="form-control"
                                @keyup="searchData()"
                                v-model="search"
                                :placeholder="trans.get('__JSON__.Search...')"
                              />
                              <i class="bx bx-search-alt search-icon"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6 pr-0">
                        <div class="text-sm-right">
                          <button
                            v-if="$auth.hasPermission(config.permissions.merchant_write)"
                            type="button"
                            class="btn btn-success btn-rounded mr-2 w-100 add-btn"
                            v-b-modal.showModal
                          >
                            <i class="mdi mdi-plus mr-1"></i>
                            {{ trans.get('__JSON__.Add Category') }}
                          </button>
                          <button
                            v-if="$auth.hasPermission(config.permissions.merchant_write)"
                            type="button"
                            class="btn btn-success btn-rounded mr-2 m-add-btn float-right"
                            v-b-modal.showModal
                          >
                            <i class="mdi mdi-plus"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 table-main-list table-responsive vendor-category custom-table">
                    <div class="card task-box custom-tableheader">
                      <div class="card-body menu-items">
                        <div class="media">
                          <div class="table-heading mr-4 font-weight-bolder"> # </div>
                          <div class="table-heading mr-4 font-weight-bolder"> {{ trans.get('__JSON__.Name') }} </div>
                          <div class="table-heading mr-4 font-weight-bolder"> {{ trans.get('__JSON__.Status') }} </div>
                          <div class="table-heading mr-4 font-weight-bolder"> {{ trans.get('__JSON__.Action') }} </div>
                        </div>
                      </div>
                    </div>
                    <draggable class="list-group" group="category" :list="vcategory" v-model="vcategory" @end="updateMenuCatOrder">
                      <div v-for="category in vcategory" :key="category.id" class="card task-box">
                        <div class="card-body menu-items drag-item">
                          <div class="media">
                            <div class="avatar-md table-heading mr-4">
                              <span class="text-danger font-size-16" v-bind:id="category.id" >
                              <div v-if="category.category_image_path">
                                <img title="product-img" class="avatar-md rounded-circle-banner" :src="category.category_image_path" alt height="30" @error="$event.target.src=config.no_image"/>
                              </div>
                              <div v-else>
                                <img class="avatar-md rounded-circle-banner" :src="config.no_image"/>
                              </div>
                              </span>
                            </div>

                            <div class="table-heading mr-4">
                              <h5 class="font-size-14">{{category.name | truncate(25, '...')}}</h5>
                            </div>

                            <div class="table-heading mr-4">
                              <div v-if="category.status == '1'"  class="badge badge-pill badge-soft-success font-size-12" >{{ trans.get('__JSON__.Active') }}</div>
                              <div v-else class="badge badge-pill badge-soft-danger font-size-12" >{{ trans.get('__JSON__.Inactive') }}</div>
                            </div>

                            <div class="table-heading mr-4">
                              <button
                                type="button"
                                @click="editCategory(category.id)"
                                class="btn btn-primary btn-sm btn-rounded"
                              >{{ trans.get('__JSON__.Edit')}}</button>
                              <button
                                  type="submit"
                                  @click="deleteById(category.id)"
                                  class="btn btn-danger btn-sm btn-rounded ml-1"
                              >{{ trans.get('__JSON__.Delete') }}</button>
                            </div>

                          </div>
                        </div>
                      </div>
                    </draggable>
                  </div>
                  <div class="row col-sm-5 offset-sm-7">
                    <div class="col">
                      <div class="dataTables_paginate paging_simple_numbers float-right">
                        <ul class="pagination pagination-rounded mb-0">
                          <!-- pagination -->
                          <pagination
                            :data="paginations"
                            :limit="limit"
                            @pagination-change-page="getVendorCategory"
                          ></pagination>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- add modal start -->
              <b-modal
                id="showModal"
                v-model="showModal"
                scrollable
                :title="trans.get('__JSON__.Add Menu Category')"
                title-class="font-18"
                @hidden="submitted = false"
              >
                <b-form @submit.prevent="menuCategory">
                  <div class="form-group">
                    <input
                      id="restaurant_id"
                      name="restaurant_id"
                      type="hidden"
                      v-model="typeform.restaurant_id"
                      class="form-control"
                    />
                  </div>
                  <div class="row">
                    <div class="col-sm-12 imgUp">
                      <label class="card-title">{{ trans.get('__JSON__.Upload Photo') }}</label>
                      <dropzone-popup
                        v-model="typeform.image"
                        id="image"
                        acceptedFiles=".jpeg,.jpg,.png,.webp,image/webp"
                      />
                      <div v-if="$v.typeform.image.$error" class="invalid-feedback cuisine-img">
                        <span
                          v-if="!$v.typeform.image.required"
                        >{{ trans.get('__JSON__.Please Select Category Image Error') }}.</span>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12" v-if="$auth.setting">
                      <b-form-group
                        id="input-group-1"
                        :label="trans.trans('__JSON__.Category Name Language',{lang: $auth.langauges[0].name})"
                        label-for="category_name"
                      >
                        <b-form-input
                          id="name"
                          name="name"
                          v-model="$v.typeform.name.$model"
                          type="text"
                          :placeholder="trans.trans('__JSON__.Enter Category Name Language',{lang: $auth.langauges[0].name})"
                          :class="{ 'is-invalid': $v.typeform.name.$error }"
                        ></b-form-input>
                        <div v-if="$v.typeform.name.$error" class="invalid-feedback">
                          <span
                            v-if="!$v.typeform.name.required"
                          >{{ trans.get('__JSON__.Please Enter category Name Error') }}.</span>
                        </div>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="row">
                    <div
                      class="col-sm-12 d-none"
                      v-if="$auth.langauges[1].name === 'No Secondary Language'"
                    ></div>
                    <div class="col-md-12" v-else>
                      <b-form-group
                        id="input-group-1"
                        :label="trans.trans('__JSON__.Category Name Language',{lang: $auth.langauges[1].name })"
                        label-for="v"
                      >
                        <b-form-input
                          id="name_another_lang"
                          name="name_another_lang"
                          v-model="typeform.name_another_lang"
                          type="text"
                          :placeholder="trans.trans('__JSON__.Enter Category Name Language',{lang: $auth.langauges[1].name })"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <b-form-group
                        id="input-group-2"
                        :label="trans.get('__JSON__.Backgorund Color Code')"
                        label-for="Backgorund Color Code"
                      >
                        <b-form-input
                          type="color"
                          id="colorpickers1"
                          v-model="typeform.color_code"
                          name="color_code"
                          pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$"
                          value="#f2f3f4"
                        />
                      </b-form-group>
                    </div>
                    <div class="col-md-6">
                      <b-form-group
                        id="input-group-2"
                        :label="trans.get('__JSON__.Text Color Code')"
                        label-for="Text Color Code"
                      >
                        <b-form-input
                          type="color"
                          id="colorpickers1"
                          v-model="typeform.text_color_code"
                          name="text_color_code"
                          pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$"
                          value="#000000"
                        />
                      </b-form-group>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <b-form-group
                        id="input-group-2"
                        :label="trans.get('__JSON__.Status')"
                        label-for="Status"
                      >
                        <div class="row">
                          <div class="col-sm-6">
                            <div class="swtich-data">
                              <label class="switch">
                                <input
                                  type="checkbox"
                                  id="togBtn"
                                  class="switch-on"
                                  name="status"
                                  v-model="typeform.status"
                                  true-value="1"
                                  false-value="0"
                                />
                                <div class="slider round">
                                  <!--ADDED HTML -->
                                  <span class="on">ON</span>
                                  <span class="off">OFF</span>
                                  <!--END-->
                                </div>
                              </label>
                            </div>
                          </div>
                        </div>
                      </b-form-group>
                    </div>
                  </div>
                </b-form>
                <template slot="modal-footer">
                  <div class="w-100">
                    <b-button
                      variant="primary"
                      class="float-right"
                      :disabled="submitted"
                      @click="menuCategory"
                    >{{ trans.get('__JSON__.Add Category') }}</b-button>
                    <b-button
                      class="float-left"
                      @click="showModal = false"
                    >{{trans.get('__JSON__.Close')}}</b-button>
                  </div>
                </template>
              </b-modal>

              <!-- add modal end -->

              <!-- edit modal start -->
              <b-modal
                id="editModal"
                v-model="editModal"
                scrollable
                :title="trans.get('__JSON__.Edit Menu Category')"
                title-class="font-18"
                @hidden="submitted = false"
              >
                <b-form @submit.prevent="updateMenuCategory">
                  <div class="form-group">
                    <input id="id" name="id" type="hidden" v-model="menucategory.id" class="form-control" />
                  </div>
                  <div class="form-group">
                    <input
                      id="restaurant_id"
                      name="restaurant_id"
                      type="hidden"
                      v-model="menucategory.restaurant_id"
                      class="form-control"
                    />
                  </div>
                  <div class="row">
                    <div class="col-sm-12 imgUp">
                      <label class="card-title">{{ trans.get('__JSON__.Upload Photo') }}</label>
                      <dropzone-popup
                        v-model="menucategory.category_image"
                        id="image"
                        acceptedFiles=".jpeg,.jpg,.png,.webp,image/webp"
                        :editable-images="image"
                        :media-path="category_media_path+''"
                        :delete-path="category_image_delete_url"
                        @image-deleted="this.onCategoryImagedeleted"
                        :class="{ 'is-invalid': $v.menucategory.category_image.$error }"
                      />
                      <div
                        v-if="$v.menucategory.category_image.$error"
                        class="invalid-feedback cuisine-img"
                      >
                        <span
                          v-if="!$v.menucategory.category_image.required"
                        >{{ trans.get('__JSON__.Please Select Category Image Error') }}.</span>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <b-form-group
                        id="input-group-1"
                        :label="trans.trans('__JSON__.Category Name Language',{lang: $auth.langauges[0].name})"
                        label-for="category_name"
                      >
                        <b-form-input
                          id="name"
                          name="name"
                          v-model="$v.menucategory.name.$model"
                          type="text"
                          :placeholder="trans.trans('__JSON__.Enter Category Name Language',{lang: $auth.langauges[0].name})"
                          :class="{ 'is-invalid': $v.menucategory.name.$error }"
                        ></b-form-input>
                        <div v-if="$v.menucategory.name.$error" class="invalid-feedback">
                          <span
                            v-if="!$v.menucategory.name.required"
                          >{{ trans.get('__JSON__.Please Enter category Name Error') }}.</span>
                        </div>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="row">
                    <div
                      class="col-sm-12 d-none"
                      v-if="$auth.langauges[1].name === 'No Secondary Language'"
                    ></div>
                    <div class="col-md-12" v-else>
                      <b-form-group
                        id="input-group-1"
                        :label="trans.trans('__JSON__.Category Name Language',{lang: $auth.langauges[1].name })"
                        label-for="v"
                      >
                        <b-form-input
                          id="name_another_lang"
                          name="name_another_lang"
                          v-model="menucategory.name_another_lang"
                          type="text"
                          :placeholder="trans.trans('__JSON__.Enter Category Name Language',{lang: $auth.langauges[1].name })"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <b-form-group
                        id="input-group-2"
                        :label="trans.get('__JSON__.Backgorund Color Code')"
                        label-for="Backgorund Color Code"
                      >
                        <b-form-input
                          type="color"
                          id="colorpickers1"
                          v-model="menucategory.color_code"
                          name="color_code"
                          pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$"
                          value="#f2f3f4"
                        />
                      </b-form-group>
                    </div>
                    <div class="col-md-6">
                      <b-form-group
                        id="input-group-2"
                        :label="trans.get('__JSON__.Text Color Code')"
                        label-for="Text Color Code"
                      >
                        <b-form-input
                          type="color"
                          id="colorpickers1"
                          v-model="menucategory.text_color_code"
                          name="text_color_code"
                          pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$"
                          value="#000000"
                        />
                      </b-form-group>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <b-form-group
                        id="input-group-2"
                        :label="trans.get('__JSON__.Status')"
                        label-for="Status"
                      >
                        <div class="row">
                          <div class="col-sm-6">
                            <div class="swtich-data">
                              <label class="switch">
                                <input
                                  type="checkbox"
                                  id="togBtn"
                                  class="switch-on"
                                  name="status"
                                  v-model="menucategory.status"
                                  true-value="1"
                                  false-value="0"
                                />
                                <div class="slider round">
                                  <!--ADDED HTML -->
                                  <span class="on">{{ trans.get('__JSON__.On') }}</span>
                                  <span class="off">{{ trans.get('__JSON__.Off') }}</span>
                                  <!--END-->
                                </div>
                              </label>
                            </div>
                          </div>
                        </div>
                      </b-form-group>
                    </div>
                  </div>
                </b-form>
                <template slot="modal-footer">
                  <div class="w-100">
                    <b-button
                      @click="updateMenuCategory"
                      :disabled="submitted"
                      variant="primary"
                      class="float-right"
                    >{{ trans.get('__JSON__.Update Category') }}</b-button>
                    <b-button class="float-left" @click="editModal=false">{{trans.get('__JSON__.Close')}}</b-button>
                  </div>
                </template>
              </b-modal>

              <!-- edit modal end -->
            </div>
          </div>
        </div>
    </Layout>
  </div>
  <!-- end row -->
  <div v-else>
    <div class="row">
      <div class="col-12 list-table-main">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-6 offset-sm-6 category-search">
                <div class="d-flex justify-content-between align-items-end">
                  <div class="col-sm-12">
                    <div class="row">
                      <div class="search-box d-inline-block">
                        <div class="date-range-list">
                          <label>{{ trans.get('__JSON__.Search') }} :</label>
                          <div class="position-relative">
                            <input
                              type="text"
                              class="form-control"
                              @keyup="searchData()"
                              v-model="search"
                              :placeholder="trans.get('__JSON__.Search...')"
                            />
                            <i class="bx bx-search-alt search-icon"></i>
                          </div>
                        </div>
                      </div>
                      <div class="text-sm-right">
                        <button
                          v-if="$auth.hasPermission(config.permissions.merchant_write)"
                          type="button"
                          class="btn btn-success btn-rounded mr-2 w-100 add-btn"
                          v-b-modal.showModal
                        >
                          <i class="mdi mdi-plus mr-1"></i>
                          {{ trans.get('__JSON__.Add Category') }}
                        </button>
                        <button
                          v-if="$auth.hasPermission(config.permissions.merchant_write)"
                          type="button"
                          class="btn btn-success btn-rounded mr-2 m-add-btn float-right"
                          v-b-modal.showModal
                        >
                          <i class="mdi mdi-plus"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 table-main-list table-responsive vendor-category custom-table">
                <div class="card task-box custom-tableheader">
                  <div class="card-body menu-items">
                    <div class="media">
                      <div class="table-heading mr-4 font-weight-bolder"> # </div>
                      <div class="table-heading mr-4 font-weight-bolder"> {{ trans.get('__JSON__.Name') }} </div>
                      <div class="table-heading mr-4 font-weight-bolder"> {{ trans.get('__JSON__.Status') }} </div>
                      <div class="table-heading mr-4 font-weight-bolder"> {{ trans.get('__JSON__.Action') }} </div>
                    </div>
                  </div>
                </div>
                <draggable class="list-group" group="category" :list="vcategory" v-model="vcategory" @end="updateMenuCatOrder">
                  <div v-for="category in vcategory" :key="category.id" class="card task-box">
                    <div class="card-body menu-items drag-item">
                      <div class="media">
                        <div class="avatar-md table-heading mr-4">
                          <span class="text-danger font-size-16" v-bind:id="category.id" >
                          <div v-if="category.category_image_path">
                            <img title="product-img" class="avatar-md rounded-circle-banner" :src="category.category_image_path" alt height="30" @error="$event.target.src=config.no_image"/>
                          </div>
                          <div v-else>
                            <img class="avatar-md rounded-circle-banner" :src="config.no_image"/>
                          </div>
                          </span>
                        </div>

                        <div class="table-heading mr-4">
                          <h5 class="font-size-14">{{category.name | truncate(25, '...')}}</h5>
                        </div>

                        <div class="table-heading mr-4">
                          <div v-if="category.status == '1'"  class="badge badge-pill badge-soft-success font-size-12" >{{ trans.get('__JSON__.Active') }}</div>
                          <div v-else class="badge badge-pill badge-soft-danger font-size-12" >{{ trans.get('__JSON__.Inactive') }}</div>
                        </div>

                        <div class="table-heading mr-4">
                          <button
                            type="button"
                            @click="editCategory(category.id)"
                            class="btn btn-primary btn-sm btn-rounded"
                          >{{ trans.get('__JSON__.Edit')}}</button>
                          <button
                              type="submit"
                              @click="deleteById(category.id)"
                              class="btn btn-danger btn-sm btn-rounded ml-1"
                          >{{ trans.get('__JSON__.Delete') }}</button>
                        </div>

                      </div>
                    </div>
                  </div>
                </draggable>
              </div>
              <div class="row col-sm-5 offset-sm-7">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <pagination
                        :data="paginations"
                        :limit="limit"
                        @pagination-change-page="getVendorCategory"
                      ></pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- add modal start -->
          <b-modal
            id="showModal"
            v-model="showModal"
            @hidden="submitted = false"
            scrollable
            :title="trans.get('__JSON__.Add Menu Category')"
            title-class="font-18"
          >
            <b-form @submit.prevent="menuCategory">
              <div class="form-group">
                <input
                  id="restaurant_id"
                  name="restaurant_id"
                  type="hidden"
                  v-model="typeform.restaurant_id"
                  class="form-control"
                />
              </div>
              <div class="row">
                <div class="col-sm-12 imgUp">
                  <label class="card-title">{{ trans.get('__JSON__.Upload Photo') }}</label>
                  <dropzone-popup
                    v-model="typeform.image"
                    id="image"
                    acceptedFiles=".jpeg,.jpg,.png,.webp,image/webp"
                  />
                  <div v-if="$v.typeform.image.$error" class="invalid-feedback cuisine-img">
                    <span
                      v-if="!$v.typeform.image.required"
                    >{{ trans.get('__JSON__.Please Select Category Image Error') }}.</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12" v-if="$auth.setting">
                  <b-form-group
                    id="input-group-1"
                    :label="trans.trans('__JSON__.Category Name Language',{lang: $auth.langauges[0].name})"
                    label-for="category_name"
                  >
                    <b-form-input
                      id="name"
                      name="name"
                      v-model="$v.typeform.name.$model"
                      type="text"
                      :placeholder="trans.trans('__JSON__.Enter Category Name Language',{lang: $auth.langauges[0].name})"
                      :class="{ 'is-invalid': $v.typeform.name.$error }"
                    ></b-form-input>
                    <div v-if="$v.typeform.name.$error" class="invalid-feedback">
                      <span
                        v-if="!$v.typeform.name.required"
                      >{{ trans.get('__JSON__.Please Enter category Name Error') }}.</span>
                    </div>
                  </b-form-group>
                </div>
              </div>
              <div class="row">
                <div
                  class="col-sm-12 d-none"
                  v-if="$auth.langauges[1].name === 'No Secondary Language'"
                ></div>
                <div class="col-md-12" v-else>
                  <b-form-group
                    id="input-group-1"
                    :label="trans.trans('__JSON__.Category Name Language',{lang: $auth.langauges[1].name })"
                    label-for="v"
                  >
                    <b-form-input
                      id="name_another_lang"
                      name="name_another_lang"
                      v-model="typeform.name_another_lang"
                      type="text"
                      :placeholder="trans.trans('__JSON__.Enter Category Name Language',{lang: $auth.langauges[1].name })"
                    ></b-form-input>
                  </b-form-group>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <b-form-group
                    id="input-group-2"
                    :label="trans.get('__JSON__.Backgorund Color Code')"
                    label-for="Backgorund Color Code"
                  >
                    <b-form-input
                      type="color"
                      id="colorpickers1"
                      v-model="typeform.color_code"
                      name="color_code"
                      pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$"
                      value="#f2f3f4"
                    />
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <b-form-group
                    id="input-group-2"
                    :label="trans.get('__JSON__.Text Color Code')"
                    label-for="Text Color Code"
                  >
                    <b-form-input
                      type="color"
                      id="colorpickers1"
                      v-model="typeform.text_color_code"
                      name="text_color_code"
                      pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$"
                      value="#000000"
                    />
                  </b-form-group>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <b-form-group
                    id="input-group-2"
                    :label="trans.get('__JSON__.Status')"
                    label-for="Status"
                  >
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="swtich-data">
                          <label class="switch">
                            <input
                              type="checkbox"
                              id="togBtn"
                              class="switch-on"
                              name="status"
                              v-model="typeform.status"
                              true-value="1"
                              false-value="0"
                            />
                            <div class="slider round">
                              <!--ADDED HTML -->
                              <span class="on">ON</span>
                              <span class="off">OFF</span>
                              <!--END-->
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </b-form-group>
                </div>
              </div>
            </b-form>
            <template slot="modal-footer">
              <div class="w-100">
                <b-button
                  variant="primary"
                  class="float-right"
                  @click="menuCategory"
                  :disabled="submitted"
                >{{ trans.get('__JSON__.Add Category') }}</b-button>
                <b-button
                  class="float-left"
                  @click="showModal = false"
                >{{trans.get('__JSON__.Close')}}</b-button>
              </div>
            </template>
          </b-modal>

          <!-- add modal end -->

          <!-- edit modal start -->
          <b-modal
            id="editModal"
            v-model="editModal"
            @hidden="submitted = false"
            scrollable
            :title="trans.get('__JSON__.Edit Menu Category')"
            title-class="font-18"
          >
            <b-form @submit.prevent="updateMenuCategory">
              <div class="form-group">
                <input id="id" name="id" type="hidden" v-model="menucategory.id" class="form-control" />
              </div>
              <div class="form-group">
                <input
                  id="restaurant_id"
                  name="restaurant_id"
                  type="hidden"
                  v-model="menucategory.restaurant_id"
                  class="form-control"
                />
              </div>
              <div class="row">
                <div class="col-sm-12 imgUp">
                  <label class="card-title">{{ trans.get('__JSON__.Upload Photo') }}</label>
                  <dropzone-popup
                    v-model="menucategory.category_image"
                    id="image"
                    acceptedFiles=".jpeg,.jpg,.png,.webp,image/webp"
                    :editable-images="image"
                    :media-path="category_media_path+''"
                    :delete-path="category_image_delete_url"
                    @image-deleted="this.onCategoryImagedeleted"
                    :class="{ 'is-invalid': $v.menucategory.category_image.$error }"
                  />
                  <div
                    v-if="$v.menucategory.category_image.$error"
                    class="invalid-feedback cuisine-img"
                  >
                    <span
                      v-if="!$v.menucategory.category_image.required"
                    >{{ trans.get('__JSON__.Please Select Category Image Error') }}.</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <b-form-group
                    id="input-group-1"
                    :label="trans.trans('__JSON__.Category Name Language',{lang: $auth.langauges[0].name})"
                    label-for="category_name"
                  >
                    <b-form-input
                      id="name"
                      name="name"
                      v-model="$v.menucategory.name.$model"
                      type="text"
                      :placeholder="trans.trans('__JSON__.Enter Category Name Language',{lang: $auth.langauges[0].name})"
                      :class="{ 'is-invalid': $v.menucategory.name.$error }"
                    ></b-form-input>
                    <div v-if="$v.menucategory.name.$error" class="invalid-feedback">
                      <span
                        v-if="!$v.menucategory.name.required"
                      >{{ trans.get('__JSON__.Please Enter category Name Error') }}.</span>
                    </div>
                  </b-form-group>
                </div>
              </div>
              <div class="row">
                <div
                  class="col-sm-12 d-none"
                  v-if="$auth.langauges[1].name === 'No Secondary Language'"
                ></div>
                <div class="col-md-12" v-else>
                  <b-form-group
                    id="input-group-1"
                    :label="trans.trans('__JSON__.Category Name Language',{lang: $auth.langauges[1].name })"
                    label-for="v"
                  >
                    <b-form-input
                      id="name_another_lang"
                      name="name_another_lang"
                      v-model="menucategory.name_another_lang"
                      type="text"
                      :placeholder="trans.trans('__JSON__.Enter Category Name Language',{lang: $auth.langauges[1].name })"
                    ></b-form-input>
                  </b-form-group>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <b-form-group
                    id="input-group-2"
                    :label="trans.get('__JSON__.Backgorund Color Code')"
                    label-for="Backgorund Color Code"
                  >
                    <b-form-input
                      type="color"
                      id="colorpickers1"
                      v-model="menucategory.color_code"
                      name="color_code"
                      pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$"
                      value="#f2f3f4"
                    />
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <b-form-group
                    id="input-group-2"
                    :label="trans.get('__JSON__.Text Color Code')"
                    label-for="Text Color Code"
                  >
                    <b-form-input
                      type="color"
                      id="colorpickers1"
                      v-model="menucategory.text_color_code"
                      name="text_color_code"
                      pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$"
                      value="#000000"
                    />
                  </b-form-group>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <b-form-group
                    id="input-group-2"
                    :label="trans.get('__JSON__.Status')"
                    label-for="Status"
                  >
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="swtich-data">
                          <label class="switch">
                            <input
                              type="checkbox"
                              id="togBtn"
                              class="switch-on"
                              name="status"
                              v-model="menucategory.status"
                              true-value="1"
                              false-value="0"
                            />
                            <div class="slider round">
                              <!--ADDED HTML -->
                              <span class="on">{{ trans.get('__JSON__.On') }}</span>
                              <span class="off">{{ trans.get('__JSON__.Off') }}</span>
                              <!--END-->
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </b-form-group>
                </div>
              </div>
            </b-form>
            <template slot="modal-footer">
              <div class="w-100">
                <b-button
                  @click="updateMenuCategory"
                  :disabled="submitted"
                  variant="primary"
                  class="float-right"
                >{{ trans.get('__JSON__.Update Category') }}</b-button>
                <b-button class="float-left" @click="editModal=false">{{trans.get('__JSON__.Close')}}</b-button>
              </div>
            </template>
          </b-modal>

          <!-- edit modal end -->
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.custom-tableheader .card-body{
  height: auto;
  background-color: #b3b0b033 !important;
  border-bottom: 0 solid #b3b0b033 !important;
}
.custom-table .media{
  align-items: center;
}
.table-heading:first-child{
  width: 15rem;
}
.table-heading:nth-child(2){
  width: 18rem;
}
.table-heading:nth-child(3){
  width: 14rem;
}
</style>