<template>
    <Layout>
      <div class="row">
        <div class="col-12">
          <div
            class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
          >
            <div class="page-title-left">
              <div class="avatar-xs mr-3 pmenuavatar">
                <span
                  class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18"
                >
                  <i class="bx bx-briefcase"></i>
                </span>
              </div>
              <div class="report-tag">
                <h4 class="mb-0 font-size-18">
                  {{ trans.trans("__JSON__.Edit Brands") }}
                </h4>
                <p><router-link :to="{ name: 'brand-list'}">{{ trans.get('__JSON__.Brands') }}</router-link> > {{ trans.get('__JSON__.Edit Brands') }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <form action="#" @submit.prevent="typeForm">
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="formgroup">
                          <label class="required"> Brand Name
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            style="width: 76% !important;"
                            id="sort_order"
                            v-model="brand_name"
                            :class="{ 'is-invalid':$v.brand_name.$error }"
                          />
                          <div v-if="$v.brand_name.$error" class="invalid-feedback">
                            <span v-if="!$v.brand_name.required">{{ trans.get('__JSON__.Please Enter Brand Name') }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="formgroup">
                          <label class="required"> Brand Image </label>
                            <div class="d-flex">
                                <label
                                class="filelabel"
                                :style="{
                                    display:
                                    brand_url || menuimageurl ? 'none' : 'block',
                                }"
                                ><i class="bx bx-plus plus" style="cursor: pointer; line-height: 1 !important;"
                                    ><input
                                    accept="image/jpeg,image/png,image/jpg,image/svg+xml,.webp,image/webp"
                                    @change="uploadmenuImage"
                                    class="FileUpload1"
                                    id="FileInput"
                                    ref="menuimagefileInput"
                                    type="file"
                                    /></i
                                ></label>
                                <div class="uploaded-img-main">
                                    <div v-if="brand_url" class="prvie-img uploaded-img">
                                        <img
                                            v-if="brand_url"
                                            :src="brand_url"
                                            class="dis-img"
                                            @click="openmenuimageFileInput"
                                            />  
                                    </div>
                                </div>
                                <div class="uploaded-img-main">    
                                    <div
                                    v-if="menuimageurl"
                                    class="prvie-img uploaded-img"
                                >
                                    <p
                                    class="remove-flg"
                                    @click="removeUploadmenuImage"
                                    >
                                    X
                                    </p>
                                    <img
                                    v-if="
                                        this.selectedmenuimage.type == 'image/jpeg' ||
                                        this.selectedmenuimage.type == 'image/png' ||
                                        this.selectedmenuimage.type == 'image/jpg' ||
                                        this.selectedmenuimage.type == 'image/svg+xml'
                                    "
                                    :src="menuimageurl"
                                    class="dis-img"
                                    />
                                    </div>
                                </div>
                            </div>
                            <div v-if="$v.previewmenuimage.$error" style="font-size: 80%; color: #f46a6a;">
                              <span v-if="!$v.previewmenuimage.required">{{ trans.get('__JSON__.Please Select Brand Image') }}</span>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div class="row mt-3">
                    <div class="col-sm-3">
                      <div class="make-defualt-switch">
                        <b-form-group
                                class="text-end"
                                id="input-group-1"
                                :label="trans.get('__JSON__.Status')"
                                label-for="status"
                            >
                            <label class="switch rightmargin">
                            <input
                                type="checkbox"
                                id="status"
                                name="status"
                                v-model="status"
                                class="switch-on"
                                true-value="1"
                                false-value="0"
                            />
                            <div class="slider round">
                                <span class="on">{{trans.get("__JSON__.Active")}}</span>
                                <span class="off" style="left: 66% !important;">{{ trans.get("__JSON__.Inactive")}}</span>
                            </div>
                            </label>
                        </b-form-group>
                      </div>
                    </div>

                    <div class="col-sm-3">
                      <div class="make-defualt-switch">
                        <b-form-group
                                class="text-end"
                                id="input-group-1"
                                :label="trans.get('__JSON__.Promotional Category')"
                                label-for="is_promotional"
                            >
                            <label class="switch rightmargin">
                            <input
                                type="checkbox"
                                id="is_promotional"
                                name="is_promotional"
                                v-model="is_promotional"
                                class="switch-on"
                                true-value="1"
                                false-value="0"
                            />
                            <div class="slider round">
                                <span class="on">{{trans.get("__JSON__.Yes")}}</span>
                                <span class="off">{{ trans.get("__JSON__.No")}}</span>
                            </div>
                            </label>
                        </b-form-group>
                      </div>
                    </div>                                        
                  </div>

                  <div class="row mt-3">
                    <button class="btn btn-primary mr-1 mt-3">Update Brand</button>
                    <button class="btn mt-3 btn-secondary" @click="cancelBrand"> Cancel</button>
                  </div>                
                </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  </template>
  
  <script>
  import Layout from "../../layouts/main";
  import DropzonePopup from "../../components/widgets/dropzone-popup";
  import vue2Dropzone from "vue2-dropzone";
  import { settingService, customerService } from "../../services";
  import { required } from "vuelidate/lib/validators";
  import { error_message, success_message } from "../../Helper/helper";
  
  export default {
    components: { Layout, DropzonePopup, vueDropzone: vue2Dropzone},
    data() {
      return {
        brand_name: "",
        status: "1",
        loading: false,
        menuimageurl: "",
        selectedmenuimage: "",
        previewmenuimage: "",
        menuimageformdata: "",
        is_promotional: "1",
        brand_url: "",
        id: this.$route.params.id ? atob(this.$route.params.id) : null,
      };
    },
    validations: {
      brand_name:{
        required,
      },
      previewmenuimage:{
        required,
      }
    },
    mounted() {
        this.getBrandById();
    },
    methods: {
        typeForm(e){
          console.log("updated brand");          
          this.$v.$touch();
          if(this.$v.$invalid){
            return;
          }else{
            this.loading = true;
            let data = {
              "brand_name" : this.brand_name,
              "brand_image" : this.previewmenuimage,
              "status" : this.status,
              "is_promotional" : this.is_promotional,
              "id": this.id
            };
            customerService.updateBrandDetails(data).then((response) => {
              this.loading = false;
              console.log("nonon", response);
              if(response.data.code = 200){
                this.menuimageurl = "",
                this.selectedmenuimage = "",
                this.previewmenuimage = "",
                this.menuimageformdata = "",
                this.brand_url = "",
                this.getBrandById();
                success_message(response.data.msg);
              }else{
                error_message(response.data.msg);
              }
              
            })
          }
        },
        uploadmenuImage(e){
          this.brand_url = "";
          this.selectedmenuimage = e.target.files[0];
          this.menuimageformdata = new FormData();
          this.menuimageformdata.append("brand_icon", this.selectedmenuimage);
          this.menuimageurl = URL.createObjectURL(e.target.files[0]);
          if (this.selectedmenuimage) {
            settingService.Uploadprofilemenuimageicon(this.menuimageformdata)
            .then((response) => {
            this.previewmenuimage = response.data.result;
          });
          }
          if (!this.selectedmenuimage) {
            return;
          }
          e.target.value = null;
        },
        openmenuimageFileInput(){
            this.$refs.menuimagefileInput.click();
        },
        removeUploadmenuImage(){
          if (this.previewmenuimage) {
            let obj = {
              brand_image: this.previewmenuimage,
            };
          settingService.Removeprofilemenuiconimage(obj).then((response) => {
            console.log("response", response.data);
          });
          }
          this.selectedmenuimage = "";
          this.menuimageurl = "";
          this.previewmenuimage = "";
        },
        cancelBrand(){
          console.log("test test");          
          this.$router.push({ name: "brand-list" });
        },
        getBrandById(){
          customerService.getBrandDetailsById(this.id).then((response) => {
                if(response.data.code == 200){
                    this.brand_name = response.data.result.brand_name;
                    this.status = response.data.result.status;
                    this.is_promotional = response.data.result.is_promotional;
                    this.previewmenuimage = response.data.result.brand_image;  
                    this.brand_url = response.data.result.brand_url;            
                    }else{
                        error_message(response.data.msg);
                    }
            })
        },
    },
  };
  </script>
  
  
  <style>

  .status label:first-child {
    text-align: end !important;
  }
  .status div:first-child {
    text-align: end !important;
  }
  .pmenuavatar {
    margin-bottom: 15px !important;
  }
  
  .remove2 {
    position: absolute;
    cursor: pointer;
    text-align: center;
    left: 3px;
    top: 3px;
    color: white;
    background: red;
    height: 20px;
    padding-top: 0px;
    border-radius: 50%;
    width: 20px;
    font-size: 16px;
    box-shadow: 0px 0px 5px black;
  }
  
  .filelabel {
    background: var(--secondarylight);
    min-height: 85px;
    min-width: 85px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    margin-bottom: 0;
    margin-right: 10px;
  }
  
  #FileInput {
    display: none;
  }
  
  .uploaded-img-main {
    display: flex;
    align-items: center;
    gap: 15px;
    flex-wrap: wrap;
    position: relative;
  }
  .uploaded-img {
    position: relative;
  }
  
  .uploaded-img p {
    margin-bottom: 0;
    position: absolute;
    color: #fff;
    right: -8px;
    top: -8px;
    background: red;
    z-index: 2;
    height: 20px;
    width: 20px;
    object-fit: contain;
    font-size: 14px;
    display: flex;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
  }
  
  p.remove-flg {
    cursor: pointer;
  }
  .dis-img {
    height: 100px;
    display: flex;
    margin: 15px;
    cursor: pointer;
  }
  .make-defualt-switch input.switch-on:checked + .slider:before {
    transform: translateX(47px) !important;
  }
  .make-defualt-switch .switch {
      width: 77px !important;
  }
  </style>

  