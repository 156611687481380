
<script>
import { vendorService } from "../../services";
import { error_message, success_message } from "../../Helper/helper";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import config from '../../config';
import { required } from "vuelidate/lib/validators";
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
export default {
    components: {config,ckeditor: CKEditor.component,vendorService,Layout,PageHeader},
    data() {
        return {
            title: this.trans.get('__JSON__.T&C'),
            config: config,
            editor: ClassicEditor,
            updateLoader: false,
            typeform: {
                id:0,
                store_id:0,
                page_name: "Terms & Conditions",
                page_title: "Terms & Conditions",
                page_content: "",
            }
        };
    },
    validations: {
        typeform: {
            page_content: {
                required,
            },
        }
    },
    mounted() {
        this.getStorePage();
    },
    methods: {
        getStorePage() {
            let restaurant_id = 0;
            let vendor_id = 0;
            if (this.$auth.partner_login) {
                restaurant_id = this.base64(this.$auth.user.restaurant.restaurant_id);
                vendor_id = this.$auth.user.vendor_id;
            } else {
                restaurant_id = this.$route.params.restaurant_id;
                vendor_id = this.$auth.getVendorId();
            }
            vendorService.getStoreTermConPage({vendor_id:vendor_id, restaurant_id:restaurant_id})
            .then(response => {
                if (response.data.code == 200) {
                    const { page_name, page_title, page_content, id, store_id} = response.data.result;
                    this.typeform = { page_name, page_title, page_content, id, store_id }
                }
            });
        },
        updatePage(e) {
            this.$v.$touch();
            if (this.$v.typeform.$invalid) {
                return;
            } else {
                this.updateLoader = true;
                vendorService.updateStoreTermConPage(this.typeform)
                .then(response => {
                    if (response.data.code === 200) {
                        success_message(response.data.message);
                    } else {
                        error_message(response.data.message);
                    }
                    this.updateLoader = false;
                });
            }
        }
    },
};
</script>

<template>
<div v-if="$auth.partner_login"> 
    <Layout>
        <PageHeader :title="title" />
        <div class="row">
            <div class="col-12">
                <form @submit.prevent="updatePage">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <!-- <label class="required">{{ trans.trans('__JSON__.Page Content') }}</label> -->
                                        <span v-if="typeform.page_content != '' && typeform.page_content != null" v-html="typeform.page_content"></span>
                                        <span v-else>T&C not found.</span>
                                        <!-- <ckeditor
                                            id="page_content"
                                            v-model="$v.typeform.page_content.$model"
                                            type="text"
                                            class="form-control"
                                            :placeholder="trans.trans('__JSON__.Page Content')"
                                            name="page_content"
                                            :editor="editor"
                                            :class="{ 'is-invalid': $v.typeform.page_content.$error }"
                                        ></ckeditor> -->
                                        <!-- <div v-if="$v.typeform.page_content.$error" class="invalid-feedback">
                                            <span v-if="!$v.typeform.page_content.required">{{ trans.get('__JSON__.Please Enter Page Content') }}</span>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="row">
                                <div class="col-sm-6">
                                    <button type="submit" class="btn btn-primary mr-1 mt-3">{{ trans.get('__JSON__.Update') }}</button>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </Layout>
</div>
<div v-else>
    <div class="row">
        <div class="col-12">
            <form @submit.prevent="updatePage">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <label class="required">{{ trans.trans('__JSON__.Page Content') }}</label>
                                    <ckeditor
                                        id="page_content"
                                        v-model="$v.typeform.page_content.$model"
                                        type="text"
                                        class="form-control"
                                        :placeholder="trans.trans('__JSON__.Page Content')"
                                        name="page_content"
                                        :editor="editor"
                                        :class="{ 'is-invalid': $v.typeform.page_content.$error }"
                                    ></ckeditor>
                                    <div v-if="$v.typeform.page_content.$error" class="invalid-feedback">
                                        <span v-if="!$v.typeform.page_content.required">{{ trans.get('__JSON__.Please Enter Page Content') }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6">
                                <button :disabled="updateLoader" type="submit" class="btn btn-primary mr-1 mt-3">
                                    <b-spinner v-if="updateLoader" class="align-middle"></b-spinner>
                                    {{(updateLoader) ? 'Updating...' : 'Update'}}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
</template>