<script>
import Layout from "../../layouts/main";
import pagination from "laravel-vue-pagination";
import { customerService, reportService } from "../../services";
import { debounce, error_message, success_message} from "../../Helper/helper";


export default {
  components: { pagination, Layout },
  data() {
    return {
      sortBy: "created_at",
      sortDesc: true,
      priceRequest: [],
      priceRequestData: [],
      restaurant: [],
      requestCount: '',
      restaurants_id:'',
      paginations: {},
      limit: 2,
      country: 0,
      state: 0,
      city: 0,
      status: '',
      loading: true,
      mrp:0,
      requestId:'',
      priceValue: '',
      isDownloading: false,
      searchData:debounce(()=>{
          this.getRequestList();
      },500),
      fields: [
        {
          key: "unique",
          label: this.trans.get("__JSON__.S. No."),
          sortable: false,
        },
        {
          key: "medicine_name",
          label: this.trans.get("__JSON__.Medicine Name"),
          sortable: true,
        },
        {
          key: "mrp",
          label: this.trans.get("__JSON__.MRP"),
          sortable: true,
        },
        {
          key: "price",
          label: this.trans.get("__JSON__.Price"),
          sortable: true,
        },
        {
          key: "quantity",
          label: this.trans.get("__JSON__.Quantity"),
          sortable: true,
        },
        {
          key: "status",
          label: this.trans.get("__JSON__.Request Status"),
          sortable: true,
        },
        {
          key: "customer_name",
          label: this.trans.get("__JSON__.Customer Name"),
          sortable: true,
        },
        {
          key: "customer_mobile_number",
          label: this.trans.get("__JSON__.Customer Mobile Number"),
          sortable: true,
        },
        {
          key: "urgent",
          label: this.trans.get("__JSON__.Urgent"),
          sortable: true,
        },
        {
          key: "special_instructions",
          label: this.trans.get("__JSON__.Special Instructions"),
          sortable: true,
        },
        ...(this.$auth.partner_login ? [{
          key: "action",
          label: this.trans.get("__JSON__.Action"),
          sortable: false
        }] : []),
      ],
    };
  },
  mounted() {
    this.getRequestList();
    this.getRestaurants();
  },
  methods: {
    downloadsCSV: function () {
      let priceRequestData = "\ufeff" + "S. No.,Medicine Name,MRP,Price,Quantity,Request Status,Customer Name,Customer Mobile Number,Urgent,Special Instructions\n";
      this.priceRequestData.forEach((el, ind) => {
        let Sno = ind + 1;
        var line =
          Sno +
          "," +
          el["master_menu_item"]["item_name"] +
          "," +
          el["mrp"] +
          "," +
          (el["price"] ? el["price"] : "-") +
          "," +
          el["quantity"] +
          "," +
          el["status"] +
          "," +
          (el["user"]["user_name"]) +
          "," +
          (el["user"]["mobile_number"]) +
          "," +
          (el["urgent"] == true ? "Yes" : "No") +
          "," +
          (el["special_instructions"] ? el["special_instructions"] : "-") +
          "\n";
          priceRequestData += line;
      });
      var blob = new Blob([priceRequestData], { type: "csv/plain" });
      var date = new Date().toLocaleString();
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "price_request" + date + ".csv";
      link.click();
    },
    getRequestList(page) {
      this.loading = true;
      var filters = {
          restaurant_id: this.$auth.partner_login ? this.$auth.user.restaurant.restaurant_id : this.restaurants_id, 
          status: this.$auth.partner_login ? "requested" : this.status,
          sortBy: this.sortBy,
          orderBy: this.sortDesc ? "desc" : "asc",
          page: page && page > 0 ? page : 1,
          pagination: true
      }
      customerService
        .getPriceRequest(filters)
        .then((response) => {
          this.loading = false;
          this.paginations = response.data.result.data;
          this.priceRequest = response.data.result.data.data;
          this.requestCount = response.data.result.requestCount
;
        });
    },
    customerExportCsv() {
      this.isDownloading = true;
      let data = { 
        status: this.$auth.partner_login ? "requested" : this.status,
        sortBy: this.sortBy, 
        orderBy: this.sortDesc ? "desc" : "asc", 
        restaurant_id: this.$auth.partner_login ? this.$auth.user.restaurant.restaurant_id : this.restaurants_id, 
        pagination: false
      }
      customerService
        .getPriceRequest(data)
        .then((response) => {
          this.isDownloading = false;
          this.priceRequestData = response.data.result.data;
          this.downloadsCSV();
        });
    },
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      this.getRequestList();
      // this.customerExportCsv();
    },
    getRestaurants() {
      this.restaurant = [];
      reportService
        .getAllSpecialRestaurantName({
          country_id: this.country,
          state_id: this.state,
          city_id: this.city,
        })
        .then((response) => {
          this.restaurant = response.data.result;
        });
    },
    resetModalprice(){
      this.mrp = "";
      this.priceValue="";
      this.id = "";
    },
    submitPriceValue(bvModalEvt){
      bvModalEvt.preventDefault();
      if(this.mrp < this.priceValue){
        error_message("Price cannot be greater than MRP.")
      }else{
        this.updateRequestPrice();
      }
    },
    submitPrice(mrp, id){
      this.mrp = mrp;
      this.id = id;
      this.$refs["price-modal"].show();
    },
    updateRequestPrice(){
      let data = {
        "id": this.id,
        "price": this.priceValue
      }
      customerService
        .updatePriceRequest(data)
        .then((response) => {
          if(response.data.code == 200){
            this.getRequestList();
            this.$refs["price-modal"].hide();
            success_message(response.data.msg)
          }else{
            error_message(response.data.msg)
          }
        });
    }

  },
};
</script>

<template>
  <Layout>
  <div>
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
        >
          <div class="page-title-left">
            <div class="avatar-xs mr-3">
              <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                <i class="bx bx-user-circle"></i>
              </span>
            </div>
            <h4 class="mb-0 font-size-18">{{ trans.get('__JSON__.Price Request') }}({{$auth.partner_login ? priceRequest && priceRequest.length : requestCount}})</h4>
          </div>
          <div v-if="!$auth.partner_login" class="page-title-right">
            <button
              @click="customerExportCsv"
              class="btn btn-primary btn-rounded export-btn"
              v-if="isDownloading"
              disabled
            >{{ trans.get('__JSON__.Exporting...') }}</button>
            <button
              @click="customerExportCsv"
              class="btn btn-primary btn-rounded export-btn"
              v-else
            >{{ trans.get('__JSON__.Export To CSV') }}</button>
            <button
              @click="customerExportCsv"
              class="btn btn-primary btn-rounded m-export-btn"
            ><i class="bx bx-archive-in"></i></button>
          </div>
        </div>
      </div>
    </div>
    <div class="row list-table-main">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-2 offset-sm-8">
                <div class="form-group">
                  <div v-if="!$auth.partner_login" class="date-range-list">
                    <label>{{ trans.get('__JSON__.Request Status') }} :</label>
                    <select class="custom-select" v-model="status" @change="searchData()">
                      <option value="">{{ trans.get('__JSON__.All') }}</option>
                      <option value="requested" selected>{{ trans.get('__JSON__.Requested') }}</option>
                      <option value="priceAdded" selected>{{ trans.get('__JSON__.Price Added') }}</option>
                      <option value="completed">{{ trans.get('__JSON__.Completed') }}</option>
                      <option value="closed">{{ trans.get('__JSON__.Closed') }}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-sm-2">
                <div v-if="!$auth.partner_login" class="date-range-list">
                  <label
                        >{{
                          trans.trans("__JSON__.Vendor", {
                            vendor: $auth.setting.restaurant_name,
                          })
                        }}
                        :</label
                      >
                      <select
                        class="custom-select"
                        v-model="restaurants_id"
                        @change="searchData()"
                      >
                        <option value selected>
                          {{
                            trans.trans("__JSON__.Select Store")
                          }}
                        </option>
                        <option
                          v-for="res in restaurant"
                          :value="res.restaurant_id"
                          :key="res.restaurant_id"
                        >
                          {{ res.name }}
                        </option>
                      </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 table-main-list customer-table table-responsive">
                <b-table
                  :items="priceRequest"
                  :fields="fields"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :no-local-sorting="true"
                  @sort-changed="sortingChanged"
                  :busy="loading"
                  show-empty
                >
                  <template #table-busy>
                    <div class="text-center text-danger my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>{{trans.get('__JSON__.Loading...')}}</strong>
                    </div>
                  </template>

                  <template v-slot:cell(unique)="data">
                      <h5 class="font-size-14">{{ data.index + 1 }}</h5>
                  </template>

                  <template v-slot:cell(medicine_name)="data">
                    <h5 class="font-size-14">{{ data.item.master_menu_item.item_name }}</h5>
                  </template>

                  <template v-slot:cell(mrp)="data">
                    <h5 class="font-size-14">{{ data.item.mrp }}</h5>
                  </template>

                  <template v-slot:cell(price)="data">
                    <h5 class="font-size-14">{{ data.item.price ? data.item.price : "-" }}</h5>
                  </template>

                  <template v-slot:cell(customer_name)="data">
                    <h5 class="font-size-14">{{ data.item.user.user_name }}</h5>
                  </template>

                  <template v-slot:cell(customer_mobile_number)="data">
                    <h5 class="font-size-14">{{ data.item.user.mobile_number }}</h5>
                  </template>

                  <template v-slot:cell(urgent)="data">
                    <h5 class="font-size-14">{{ data.item.urgent == true ? "Yes" : "No" }}</h5>
                  </template>

                  <template v-slot:cell(special_instructions)="data">
                    <h5 class="font-size-14">{{ data.item.special_instructions ? data.item.special_instructions : "-" }}</h5>
                  </template>

                  <template v-if="$auth.partner_login" v-slot:cell(action)="data">
                    <button class="btn btn-primary btn-sm btn-rounded" @click="submitPrice(data.item.mrp, data.item.id)">Submit Price</button>
                  </template>

                  <template #empty>
                    <p class="text-center">{{ trans.get('__JSON__.No requests found') }}</p>
                  </template>
                </b-table>
              </div>
              <div class="row col-sm-5 offset-sm-7">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <pagination
                        :data="paginations"
                        :limit="limit"
                        @pagination-change-page="getRequestList"
                      ></pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="modal-price"
      size="sm"
      title-class="font-18"
      @hidden="resetModalprice"
      @ok="submitPriceValue"
      :ok-disabled="loading"
      :ok-title="
        loading
          ? trans.get('__JSON__.Loading...')
          : trans.get('__JSON__.Save')
      "
      ok-only
      ok-variant="danger"
      ref="price-modal"
      lazy
      centered
    >
      <template #modal-header="{ close }">
        <div class="d-flex justify-content-between w-100">
          <h4 class="mb-0">{{ trans.get("__JSON__.Submit Price") }}</h4>
        </div>
        <button type="button" aria-label="Close" class="close" @click="close()">
          &times;
        </button>
      </template>
      <div class="d-flex flex-column">
        <div>
          <input type="number" class="form-control rounded-input" placeholder="Enter price" v-model="priceValue" :class="{ 'is-invalid': mrp < priceValue}">
          <span v-if="mrp < priceValue" class="show-error">Price cannot be greater than MRP.</span>     
        </div>
      </div>
    </b-modal>
  </div>
</Layout>
</template>
<style>
  .rounded-input 
  {
    border-radius: 1rem !important;
  }

  .show-error
  {
    font-size: 80%;
    color: #f46a6a;    
    margin-left: 8px;
  }
</style>

