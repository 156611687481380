<script>
import Layout from "../../../layouts/main";
import PageHeader from "../../../components/page-header";
import DriverDashboard from "./driver-finance-dashboard";
import DriverEarning from "./driver-earning";
import DriverOrderEarning from "./driver-finance-order";
import DriverSettlement from "./driver-settlement.vue";
import { driverService } from '../../../services';
/**
 * Driver Dashboard
 */
export default {
  components: { Layout, PageHeader, DriverEarning, DriverSettlement,DriverDashboard,DriverOrderEarning },
  data() {
    return {
      driver: null
    };
  },
  mounted(){
    this.getDriverInfo()
  },
  methods: {
    getDriverInfo(){
      driverService.getDriverInfo({driver_id:this.$route.params.driverid})
        .then(response => {
          this.driver = response.data.result;
        })
    }
  }
};
</script>
<template>
  <Layout>
       <div class="row">
          <div class="col-12">
              <div
              class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
              >
              <div class="page-title-left avatar-main-icon">
                  <div class="avatar-xs mr-3">
                  <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                      <i class="bx bx-purchase-tag"></i>
                  </span>
                  </div>
                  <div class="report-tag">
                  <h4 class="mb-0 font-size-18">{{ trans.trans('__JSON__.Driver Finance',{ driver: $auth.getDriverName() }) }}</h4>
                  <p>
                    <router-link :to="{ name: 'driver'}">{{ trans.trans('__JSON__.Driver Finance',{ driver: $auth.getDriverName() }) }}</router-link>
                    >
                    <router-link :to="{name: 'driver-finance-month',params: { month:$route.params.month}}">
                    {{$route.params.month}}</router-link>
                    >
                    {{ trans.trans('__JSON__.Driver Finance',{ driver: $auth.getDriverName() }) }}
                    </p>
                  </div>
              </div>
              </div>
          </div>
      </div>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">

                    <!-- Tab panel start-->
                      <b-tabs justified nav-class="nav-tabs-custom mt-3 order-tabs" content-class="p-3 text-muted">
                          <b-tab active>
                            <template v-slot:title>
                              <span class="d-inline-block d-sm-none">
                                <i class="fas fa-home"></i>
                              </span>
                              <span class="d-none d-sm-inline-block">{{trans.get('__JSON__.DashBoard')}}</span>
                            </template>
                            <DriverDashboard v-if="driver" :driver="driver" :driver_id="$route.params.driverid" />
                          </b-tab>
                          <b-tab>
                            <template v-slot:title>
                              <span class="d-inline-block d-sm-none">
                                <i class="far fa-user"></i>
                              </span>
                              <span class="d-none d-sm-inline-block">{{trans.get('__JSON__.Month Wise Earnings')}}</span>
                            </template>
                            <DriverEarning :driver_id="$route.params.driverid"/>
                          </b-tab>
                          <b-tab>
                            <template v-slot:title>
                              <span class="d-inline-block d-sm-none">
                                <i class="far fa-user"></i>
                              </span>
                              <span class="d-none d-sm-inline-block">{{trans.get('__JSON__.Order Wise Earnings')}}</span>
                            </template>
                            <DriverOrderEarning :driver_id="$route.params.driverid"/>
                          </b-tab>
                          <b-tab>
                            <template v-slot:title>
                              <span class="d-inline-block d-sm-none">
                                <i class="far fa-envelope"></i>
                              </span>
                              <span class="d-none d-sm-inline-block">{{trans.get('__JSON__.Settlements')}}</span>
                            </template>
                            <DriverSettlement :driver_id="$route.params.driverid"/>
                          </b-tab>
                       </b-tabs>
                      <!-- Tab panel end -->
                </div>
            </div>
        </div>
    </div>
  </Layout>
</template>