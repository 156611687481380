
<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import config from "../../config";
import pagination from "laravel-vue-pagination";
import moment from "moment";
import { restaurantService, vendorService } from "../../services";
import { error_message, success_message } from "../../Helper/helper";

export default {
  components: { Layout, PageHeader ,pagination, vendorService },
  data() {
    return {
      title: this.trans.get('__JSON__.Reviews'),
      sortBy: "order_id",
      sortDesc: true,
      ratings: [],
      ratingData: [],
      paginations: {},
      limit: 1,
      search: "",
      status: 1,
      config: config,
      moment: moment,
      loading: true,
      fields: [
        { key: "order_id", label: "Order ID", sortable: true },
        { key: "user_id", label: "Customer Id", sortable: true },
        { key: "user_name", label: "Customer Name", sortable: true, requiresAdmin: true },
        { key: "mobile_number", label: "Mobile Number", sortable: true, requiresAdmin: true },
        { key: "rating", label: "Rating", sortable: true },
        { key: "description", label: "Review", sortable: true },
        { key: "created_at", label: "Date", sortable: true },
        { key: "action", label: "Action", sortable: false, requiresAdmin: true }
      ],
    };
  },
  computed: {
    computedFields() {
      // If the user isn't an admin, filter out fields that require auth.
      if(this.$auth.partner_login)
        return this.fields.filter(field => !field.requiresAdmin);
        
      // If the user IS an admin, return all fields.
      else
        return this.fields;
    }
  },
  mounted() {
    this.getRating();
  },
  methods: {
    getRating(page) {
      this.loading = true;
      vendorService
        .getRestaurantRating({
          search: this.search,
          status: this.status,
          sortBy: this.sortBy,
          orderBy: this.sortDesc ? "desc" : "asc",
          page: page ? page : 1,
          restaurant_id: this.$route.params.restaurant_id,
        })
        .then((response) => {
          this.loading = false;
          this.paginations = response.data.result;
          this.ratings = response.data.result.data;
        });
    },
    deleteById(id) {
      if (confirm("Are you sure you want to delete rating? Once it deleted. It will not retrieve")) {
        restaurantService.deleteRestRating({ id: id }).then((response) => {
          success_message(response.data.message);
          this.getRating();
        });
      }
    },
    searchData() {
      this.getRating();
    },
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      this.getRating();
    },
  },
};
</script>

<template>
<div v-if="$auth.partner_login"> 
  <Layout>
    <PageHeader :title="title" />
        <!-- end row -->
        <div class="row">
          <div class="col-12 list-table-main">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-sm-2 offset-sm-10">
                    <div class="search-box mb-3 d-inline-block">
                      <div class="date-range-list">
                        <label>{{ trans.get('__JSON__.Search') }} :</label>
                        <div class="position-relative">
                          <input
                            type="text"
                            class="form-control"
                            @keyup="searchData()"
                            v-model="search"
                            :placeholder="trans.get('__JSON__.Search...')"
                          />
                          <i class="bx bx-search-alt search-icon"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 table-main-list table-responsive">
                    <b-table
                      :items="ratings"
                      :fields="computedFields"
                      :sort-by.sync="sortBy"
                      :no-local-sorting="true"
                      @sort-changed="sortingChanged"
                      :busy="loading"
                      show-empty
                    >
                      <template #table-busy>
                          <div class="text-center text-danger my-2">
                          <b-spinner class="align-middle"></b-spinner>
                          <strong>{{trans.get('__JSON__.Loading...')}}</strong>
                          </div>
                      </template>

                      <template v-slot:cell(created_at)="data">
                        <div
                          v-if="data.item.created_at"
                        >{{moment(data.item.created_at).format('D MMM,Y')}}</div>
                      </template>

                      <template v-slot:cell(rating)="data">
                        <div v-if="data.item.rating <= 2">
                          <span class="badge badge-danger font-size-12">
                            <i class="mdi mdi-star mr-1"></i>
                            {{parseFloat(data.item.rating).toFixed(2)}}
                          </span>
                        </div>
                        <div v-else>
                          <span class="badge badge-success font-size-12">
                            <i class="mdi mdi-star mr-1"></i>
                            {{parseFloat(data.item.rating).toFixed(2)}}
                          </span>
                        </div>
                      </template>

                      <template v-slot:cell(description)="data">
                        <div v-if="data.item.description">
                          <div
                            v-if="data.item.description.length > 140"
                          >{{data.item.description.substr(0, 140)+ '...'}}</div>
                          <div v-else>{{data.item.description}}</div>
                        </div>
                        <div v-else class="mt-3">
                          <p>-</p>
                        </div>
                      </template>
                      <template #empty>
                          <p class="text-center">{{ trans.get('__JSON__.No Rating') }}</p>
                        </template>
                    </b-table>
                  </div>
                  <div class="row col-sm-5 offset-sm-7">
                    <div class="col">
                      <div class="dataTables_paginate paging_simple_numbers float-right">
                        <ul class="pagination pagination-rounded mb-0">
                          <!-- pagination -->
                          <pagination
                            :data="paginations"
                            :limit="limit"
                            @pagination-change-page="getRating"
                          ></pagination>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  </Layout>
</div>
        <!-- end row -->
<div v-else>
  <div class="row">
    <div class="col-12 list-table-main">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-2 offset-sm-10">
              <div class="search-box mb-3 d-inline-block">
                <div class="date-range-list">
                  <label>{{ trans.get('__JSON__.Search') }} :</label>
                  <div class="position-relative">
                    <input
                      type="text"
                      class="form-control"
                      @keyup="searchData()"
                      v-model="search"
                      :placeholder="trans.get('__JSON__.Search...')"
                    />
                    <i class="bx bx-search-alt search-icon"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 table-main-list table-responsive">
              <b-table
                :items="ratings"
                :fields="computedFields"
                :sort-by.sync="sortBy"
                :no-local-sorting="true"
                @sort-changed="sortingChanged"
                :busy="loading"
                show-empty
              >
                <template #table-busy>
                    <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>{{trans.get('__JSON__.Loading...')}}</strong>
                    </div>
                </template>

                <template v-slot:cell(created_at)="data">
                  <div
                    v-if="data.item.created_at"
                  >{{moment(data.item.created_at).format('D MMM,Y')}}</div>
                </template>

                <template v-slot:cell(rating)="data">
                  <div v-if="data.item.rating <= 2">
                    <span class="badge badge-danger font-size-12">
                      <i class="mdi mdi-star mr-1"></i>
                      {{parseFloat(data.item.rating).toFixed(2)}}
                    </span>
                  </div>
                  <div v-else>
                    <span class="badge badge-success font-size-12">
                      <i class="mdi mdi-star mr-1"></i>
                      {{parseFloat(data.item.rating).toFixed(2)}}
                    </span>
                  </div>
                </template>

                <template v-slot:cell(description)="data">
                  <div v-if="data.item.description">
                    <div
                      v-if="data.item.description.length > 140"
                    >{{data.item.description.substr(0, 140)+ '...'}}</div>
                    <div v-else>{{data.item.description}}</div>
                  </div>
                  <div v-else class="mt-3">
                    <p>-</p>
                  </div>
                </template>

                <template v-slot:cell(action)="data">
                    <div class="vendor-btn">
                      <button
                        type="submit"
                        class="btn btn-danger btn-sm btn-rounded ml-1"
                        @click="deleteById(base64(data.item.rating_id))"
                      >{{ trans.get('__JSON__.Delete') }}</button>
                    </div>
                 </template>

                <template #empty>
                    <p class="text-center">{{ trans.get('__JSON__.No Rating') }}</p>
                  </template>
              </b-table>
            </div>
            <div class="row col-sm-5 offset-sm-7">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <pagination
                      :data="paginations"
                      :limit="limit"
                      @pagination-change-page="getRating"
                    ></pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<style>
</style>