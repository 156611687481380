
<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";
import { restaurantService } from "../../services";
import {
  error_message,
  success_message,
  replaceByDefault,
} from "../../Helper/helper";
import { routes } from "../../config/api_routes";
import config from '../../config';

/**
 * update-profile component
 */
export default {
  name: "FixedTimeList",
  components: { Layout, PageHeader, DatePicker, moment },
  data() {
    return {
      // title: this.trans.get('__JSON__.Selling Time'),
      title: this.trans.get('__JSON__.Menu time-slot'),
      config:config,
      times: [{name: '',start_time: '',end_time: '',id:''}],
      restaurant_id: this.$route.params.restaurant_id,
    };
  },
  mounted() {
    this.getSelingTimes(this.$route.params.restaurant_id);
  },
  methods: {
    getDisabledStartTime(date,index){
      var times = this.times.filter((time,ind) => ind !== index && (time.start_time != '' && time.end_time != '') );
      var rs = false
      times.forEach(time => {
        if(this.moment(date).isSameOrAfter(this.moment(time.start_time,'hh:mm A'))
         && this.moment(date).isBefore(this.moment(time.end_time,'hh:mm A'))){
          rs = true;
        }
        if(this.times[index].end_time !== '' && !rs){
          if(!rs && this.moment(this.times[index].end_time,'hh:mm A').isSameOrAfter(this.moment(time.end_time,'hh:mm A')) && this.moment(date).isBefore(this.moment(time.end_time,'hh:mm A'))){
            rs = true;
          }
        }
      });
      if(this.times[index].end_time !== '' && !rs){
        if(this.moment(date).isSameOrAfter(this.moment(this.times[index].end_time,'hh:mm A'))){
          rs = true;
        }
      }
      return rs;
    },
    getDisabledEndTime(date,index){
      var times = this.times.filter((time,ind) => ind !== index && (time.start_time != '' && time.end_time != '') );
      var rs = false
      times.forEach(time => {
        // console.log(this.moment(date).isAfter(this.moment(time.start_time,'hh:mm A')),this.moment(date).isBefore(this.moment(time.end_time,'hh:mm A')))
        if(this.moment(date).isAfter(this.moment(time.start_time,'hh:mm A'))
         && this.moment(date).isSameOrBefore(this.moment(time.end_time,'hh:mm A'))){
          rs = true;
        }
        if(this.times[index].start_time !== '' && !rs){
          if(!rs && this.moment(this.times[index].start_time,'hh:mm A').isSameOrBefore(this.moment(time.start_time,'hh:mm A')) && this.moment(date).isAfter(this.moment(time.start_time,'hh:mm A'))){
            rs = true;
          }
        }
      });
      if(this.times[index].start_time !== '' && !rs){
        if(this.moment(date).isSameOrBefore(this.moment(this.times[index].start_time,'hh:mm A'))){
          rs = true;
        }
      }
      return rs;
    },
    /* var time = this.times.reduce( (accu,time,ind) => {
      if(accu && time.start_time !== '' && index != ind ){
        if(this.moment(time.start_time,'hh:mm A').isBefore(this.moment(accu,'hh:mm A'))){
          return time.start_time;
        }else{
          return accu;
        }
      }else if(time.start_time !== '' && index != ind ){
        return time.start_time
      }else{
        return accu;
      }
    }, null);
    if(this.moment(date).isAfter(this.moment(time,'hh:mm A'))){
      return true;
    }
    return false; */
    handleSubmit(){
      if (!this.$auth.hasPermission(config.permissions.merchant_write)) {
        return false;
      }
      const fd = new FormData();
      fd.append('selling_times',JSON.stringify(this.times));
      fd.append('restaurant_id',this.restaurant_id);
      restaurantService.addTimeSlot(fd).then((response) => {
        if (response.data.code === 200) {
          if (response.data.result.length !== 0) {
            this.times = response.data.result;
          }
          success_message(response.data.message);
        } else {
          error_message(response.data.message);
        }
      });
    },
    getSelingTimes: function (restaurant_id) {
      restaurantService.getSelingTimes(restaurant_id).then((response) => {
        if(response.data.result.length > 0) {
          this.times = response.data.result;
        }
      });
    },
    AddSlot: function () {
      if(this.times.filter(t => (t.start_time == '' || t.end_time == '')).length == 0){
        this.times.push({name: '', start_time: '',end_time: ''});
        return true;
      }
      alert('Please select available slots first.')
      // alert('Please select available slots first');
      //this.getSelingTimes(this.restaurant_id)
    },
    remove_slot(id,index){
      if (id) {
        if (!confirm('Are you want to delete menu time slot? It will convert the Menu to "whole day" by default')) {
          return;
        }
        restaurantService.removeSellingTime(id).then((response) => {
          if(response.data.code === 200) {
            this.times = this.times.filter((time) => id != time.id );
            if(this.times.length == 0) {
              this.times.push({name:'', start_time: '',end_time:'',id: ''});
            }
            success_message(response.data.message);
          } else {
            error_message(response.data.message);
          }
        });
      } else {
        this.times.splice(index,1);
      }
    }
  },
};
</script>

<template>
  <div v-if="$auth.partner_login"> 
    <Layout>
      <PageHeader :title="title" />
      <div class="card">
        <div class="card-body">
          <b-form @submit.prevent="handleSubmit">
            <div class="table-responsive mt-5 m-selling-time">
              <table class="table table-centered table-nowrap">
                <thead class="thead-light">
                  <tr>
                    <th>#</th>
                    <th>{{trans.get('__JSON__.Name')}}</th>
                    <th>{{trans.get('__JSON__.Start Time')}}</th>
                    <th>{{trans.get('__JSON__.Closing Time')}}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <input type="hidden" id="restaurant_id" name="restaurant_id" v-model="restaurant_id">
                  <tr v-for="(time,index) in times" :key="time.id">
                    <td>{{ index + 1 }}</td>
                    <td class="selling-time-slot">
                      <input
                        :id="'slot_name'+index"
                        :name="'slot_name'+index"
                        type="text"
                        class="form-control"
                        v-model="time.name"
                        maxlength="50"
                        placeholder="Enter Name"
                        @paste.prevent
                      />
                    </td>   
                    <td class="selling-time-slot">
                      <date-picker
                        v-model="time.start_time"
                        :time-picker-options="{
                            start: '00:00',
                            step: '00:15',
                            end: '23:30',
                          }"
                        format="hh:mm A"
                        type="time"
                        class="date-time"
                        placeholder="hh:mm A"
                        name="start_time[]"
                        value-type="format"
                        :editable="false"
                        :disabled-time="function(date){return getDisabledStartTime(date, index)}"
                      ></date-picker>
                    </td>
                    <td class="selling-time-slot">
                      <date-picker
                        v-model="time.end_time"
                        :time-picker-options="{
                            start: '00:00',
                            step: '00:15',
                            end: '23:30',
                          }"
                        format="hh:mm A"
                        type="time"
                        class="date-time"
                        placeholder="hh:mm A"
                        name="end_time[]"
                        value-type="format"
                        :editable="false"
                        :disabled-time="function(date){return getDisabledEndTime(date, index)}"
                      ></date-picker>
                    </td>
                    <td>
                      <button v-if="$auth.hasPermission(config.permissions.merchant_write) && index == 0" @click.prevent="AddSlot" class="btn btn-primary btn-sm btn-rounded"><i class="fas fa-plus"></i></button>
                      <button @click.prevent="remove_slot(time.id,index)" v-if="$auth.hasPermission(config.permissions.merchant_write) && time.id!='' && index != 0"  class="btn btn-danger btn-sm btn-rounded"><i class="fas fa-minus"></i></button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row">
              <div class="col-sm-6 operation-btn">
                <button v-if="$auth.hasPermission(config.permissions.merchant_write)" type="submit" class="btn btn-primary mr-1 mt-3">Update</button>
              </div>
            </div>
          </b-form>
        </div>
      </div>
    </Layout>
  </div>
    <!-- end row -->
  <div v-else>
    <b-form @submit.prevent="handleSubmit">
      <div class="table-responsive mt-5 m-selling-time">
        <table class="table table-centered table-nowrap">
          <thead class="thead-light">
            <tr>
              <th>#</th>
              <th>{{trans.get('__JSON__.Name')}}</th>
              <th>{{trans.get('__JSON__.Start Time')}}</th>
              <th>{{trans.get('__JSON__.Closing Time')}}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <input type="hidden" id="restaurant_id" name="restaurant_id" v-model="restaurant_id">
            <tr v-for="(time,index) in times" :key="time.id">
              <td>{{ index + 1 }}</td>     
              <td class="selling-time-slot">
                <input
                  :id="'slot_name'+index"
                  :name="'slot_name'+index"
                  type="text"
                  class="form-control"
                  v-model="time.name"
                  maxlength="50"
                  placeholder="Enter Name"
                  @paste.prevent
                />
              </td>     
              <td class="selling-time-slot">
                <date-picker
                  v-model="time.start_time"
                  :time-picker-options="{
                      start: '00:00',
                      step: '00:15',
                      end: '23:30',
                    }"
                  format="hh:mm A"
                  type="time"
                  class="date-time"
                  placeholder="hh:mm A"
                  name="start_time[]"
                  value-type="format"
                  :editable="false"
                  :disabled-time="function(date){return getDisabledStartTime(date, index)}"
                ></date-picker>
              </td>
              <td class="selling-time-slot">
                <date-picker
                  v-model="time.end_time"
                  :time-picker-options="{
                      start: '00:00',
                      step: '00:15',
                      end: '23:30',
                    }"
                  format="hh:mm A"
                  type="time"
                  class="date-time"
                  placeholder="hh:mm A"
                  name="end_time[]"
                  value-type="format"
                  :editable="false"
                  :disabled-time="function(date){return getDisabledEndTime(date, index)}"
                ></date-picker>
              </td>
              <td>
                <button v-if="$auth.hasPermission(config.permissions.merchant_write) && index == 0" @click.prevent="AddSlot" class="btn btn-primary btn-sm btn-rounded"><i class="fas fa-plus"></i></button>
                <button @click.prevent="remove_slot(time.id,index)" v-if="$auth.hasPermission(config.permissions.merchant_write) && time.id!='' && index != 0"  class="btn btn-danger btn-sm btn-rounded"><i class="fas fa-minus"></i></button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="row">
        <div class="col-sm-6 operation-btn">
          <button v-if="$auth.hasPermission(config.permissions.merchant_write)" type="submit" class="btn btn-primary mr-1 mt-3">Update</button>
        </div>
      </div>
    </b-form>
  </div>
</template>
