<script>
import Layout from "../../../layouts/main";
import PageHeader from "../../../components/page-header";
import { required, integer,maxLength } from "vuelidate/lib/validators";
import { get_user } from "../../../Helper/helper";
import { deliveryZoneService, userAccessService } from "../../../services";
import { menuItems } from '../../../components/menu';
export default {
  components: { Layout, PageHeader },
  //props: ['user-updated','userId'],
  data() {
    return {
      typeform: {
        vendor_name: "",
        email: "",
        vendor_number: "",
        state: "",
        city: "",
        country: "",
        status: true,
        user_permissions: [],
      },
      menuItems: menuItems,
      states: [],
      countries: [],
      cities: [],
      user: {},
      permissions: [],
      permission_types: {},
      updated: false,
      is_checked: {},
      country_count: "",
      state_count: "",
      city_count: "",
      defaultcountry: "",
      defaultstate: "",
      defaultcity: "",
    };
  },
  validations: {
    typeform: {
      vendor_name: {
        required,
      },
      email: {
        required,
      },
      vendor_number: {
        required,
        integer,
        maxLength:maxLength(15)
      },
      country: {
        required,
      },
      state: {
        required,
      },
      city: {
        required,
      },
    },
  },
  mounted() {
    if (this.$route.params.id) this.getEditUser(this.$route.params.id);
    this.getPermissions()
    this.user = get_user();
    // this.getCountry();
    this.getEditUser();
  },
  watch:{
    "typeform.user_permissions": function(){
      this.menuItems.forEach(item => {
        this.isChecked(item.id);
      })
    }
  },
  methods: {
    getEditUser(user_id) {
      userAccessService.getUserDetail(user_id).then((res) => {
        const {
          vendor_name,
          email,
          vendor_number,
          state,
          city,
          country,
          status,
        } = res.data.result;
        this.typeform = {
          ...this.typeform,
          vendor_name,
          email,
          vendor_number,
          country,
          state,
          city,
          status: !!status,
        };
        this.defaultcountry = this.typeform.country;
        this.defaultstate = this.typeform.state;
        this.defaultcity = this.typeform.city;
        this.typeform.user_permissions =
          (res.data.result.permissions &&
            res.data.result.permissions.map((p) => p.name)) ||
          [];
         this.getCountry();
        // this.getStates(false);
        // this.getCities();
      });
    },
    getPermissions(){
        //var id = this.$route.params.id;
        userAccessService.getAllPermissions()
            .then(res => {
                this.permissions = res.data.result;
                this.setPermissionTypes();
            });
    },
    setPermissionTypes(){
      this.permission_types = this.permissions.reduce(function(a, p){
        var x = p.name.replace('Read','').replace('Write','').trim();
        if(!a[x]) a[x]= {};
        a[x][p.name.includes('Read')?'Read':'Write'] = p;
        return a;
      },{});
    },
    updateUser() {
      this.$v.typeform.$touch();
      if (!this.$v.typeform.$invalid) {
        userAccessService
          .updateUserApi({
            ...this.typeform,
            user_id: this.$route.params.id,
            status: this.typeform.status ? 1 : 0,
          })
          .then((res) => {
            this.updated = true;
            this.$toasted.success(res.data.message);
            this.$router.push({ name: "user-access" });
          });
      }
    },
    getCountry(){
      this.typeform.city = '';
      this.typeform.state = '';
      this.states = [];
      this.cities = [];
      deliveryZoneService.getVendorCountry()
        .then(res => {
          this.country_count = res.data.result.length;
          this.countries = res.data.result
          if(this.country_count == 1) {
            this.typeform.country = this.countries[0].id;
            this.getStates()
          } else if (this.country_count > 0) {
           if (this.typeform.country > 0) {
            this.getStates();
           }
         }

        });
    },
    getStates() {
      this.states = [];
      this.cities = [];
      this.city_count = 0;
      this.state_count = 0;
      this.typeform.city = '';
      this.typeform.state = '';
      // if(cityremove) this.typeform.city = '';
      if(!this.typeform.country) return false;
      deliveryZoneService
        .getVendorState({ country_id: this.typeform.country })
        .then((res) => {
          this.state_count = res.data.result.length;
          this.states = res.data.result;
          if (this.defaultcountry === this.typeform.country && this.state_count > 0) {
            this.typeform.state = this.defaultstate;
          }
          if(this.state_count == 1) {
            this.typeform.state = this.states[0].id;
            this.getCities()
          } else {
            if (this.defaultcountry === this.typeform.country && this.state_count > 0) {
              if (this.typeform.state > 0) {
                this.getCities();
              }
            }
           }
        });
    },
    // getCitiesEvent(event) {
    //   this.getCities(event.target.value);
    // },
    getCities() {
      this.cities = [];
      this.city_count = 0;
      if(!this.typeform.state) return false;
      deliveryZoneService.getVendorCity({ state_id: this.typeform.state }).then((res) => {
        this.city_count = res.data.result.length;
        this.cities = res.data.result;
         // if (this.cities.length == 1) {
         //    this.typeform.city = this.cities[0].cityid;
         //  }
         if (this.defaultcountry === this.typeform.country && this.state_count > 0) {
          if (this.defaultstate === this.typeform.state && this.city_count > 0) {
            this.typeform.city = this.defaultcity;
          }
        }
        if (this.city_count == 1) {
          this.typeform.city = this.cities[0].cityid;
        } else {
          // if (this.typeform.state == "") {
          //   this.typeform.city = "";
          //   this.getAllCity();
          // }
        }
      });
    },
    // onCountryChange(){
    //   this.typeform.state = "";
    //   this.typeform.city = "";
    //   this.getStates()
    // },
    // onStateChange(){
    //   this.typeform.city = "";
    //   this.getCities()
    // },
    hasItems(item) {
      return item.subItems !== undefined ? item.subItems.length > 0 : false;
    },
    selectAll($event, id){
      var newp = this.menuItems.find(i => i.id == id).subItems.reduce((a,i) => a.concat(i.permissions),[]);
      if($event.target.checked){
        var modip = [];
        var cont = newp.concat(this.typeform.user_permissions);
        cont.forEach((i) =>  (!modip.includes(i) && modip.push(i)));
        this.typeform.user_permissions = modip 
      }else{
        this.typeform.user_permissions = this.typeform.user_permissions.filter(i => !newp.includes(i));
      }
    },
    isChecked(id){
      var newp = this.menuItems.find(i => i.id == id).subItems.reduce((a,i) => a.concat(i.permissions),[]);
      this.is_checked[id] = newp.every(i => this.typeform.user_permissions.includes(i))?true:false
    }
  },
};
</script>
<template>
  <Layout>
   <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
        >
          <div class="page-title-left avatar-main-icon">
            <div class="avatar-xs mr-3">
              <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                <i class="bx bx-group"></i>
              </span>
            </div>
             <div class="report-tag">
              <h4 class="mb-0 font-size-18">{{ trans.trans('__JSON__.Edit User') }}</h4>
              <p><router-link :to="{ name: 'user-access'}">{{ trans.get('__JSON__.User & Access') }}</router-link> > {{ trans.get('__JSON__.Edit User') }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-form @submit.prevent="updateUser">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-4">
              <b-form-group
                id="input-group-1"
              >
                <template #label>
                  <label for="vendor_name">{{trans.get('__JSON__.Name')}} <span class="text-danger">*</span> </label>
                </template>
                <b-form-input
                  id="vendor_name"
                  name="vendor_name"
                  v-model="$v.typeform.vendor_name.$model"
                  type="text"
                  :placeholder="trans.get('__JSON__.Name')"
                  :class="{ 'is-invalid': $v.typeform.vendor_name.$error }"
                ></b-form-input>
                <div
                  v-if="$v.typeform.vendor_name.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.typeform.vendor_name.required"
                    >{{ trans.get("__JSON__.Please enter user name") }}.</span
                  >
                </div>
              </b-form-group>
            </div>
            <div class="col-sm-4">
              <b-form-group
                id="input-group-2"
              >
                <template #label>
                  <label for="email">{{trans.get('__JSON__.Email')}} <span class="text-danger">*</span> </label>
                </template>
                <b-form-input
                  id="email"
                  name="email"
                  v-model="$v.typeform.email.$model"
                  type="text"
                  :placeholder="trans.get('__JSON__.Enter Email')"
                  :class="{ 'is-invalid': $v.typeform.email.$error }"
                ></b-form-input>
                <div v-if="$v.typeform.email.$error" class="invalid-feedback">
                  <span v-if="!$v.typeform.email.required"
                    >{{ trans.get("__JSON__.Please enter email") }}.</span
                  >
                </div>
              </b-form-group>
            </div>
            <div class="col-sm-4">
              <b-form-group
                id="input-group-3"
              >
                <template #label>
                  <label for="vendor_number">{{trans.get('__JSON__.Phone')}} <span class="text-danger">*</span> </label>
                </template>
                <b-form-input
                  id="vendor_number"
                  name="vendor_number"
                  v-model="$v.typeform.vendor_number.$model"
                  type="text"
                  :placeholder="trans.get('__JSON__.Enter Phone')"
                  :class="{ 'is-invalid': $v.typeform.vendor_number.$error }"
                ></b-form-input>
                <div
                  v-if="$v.typeform.vendor_number.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.typeform.vendor_number.required"
                    >{{ trans.get("__JSON__.Please enter phone") }}.</span
                  >
                  <span v-if="!$v.typeform.vendor_number.integer"
                    >{{ trans.get("__JSON__.Please enter valid phone") }}.</span
                  ><span v-if="!$v.typeform.vendor_number.maxLength"
                    >{{ trans.get("__JSON__.Please enter valid phone") }}.</span
                  >
                </div>
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4">
              <b-form-group
                id="input-group-3"
                :label="trans.get('__JSON__.Status')"
                label-for="status"
              >
                <b-form-checkbox v-model="typeform.status" switch class="mb-1">
                  <label>{{ trans.get("__JSON__.Active") }}</label>
                </b-form-checkbox>
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4" v-if="country_count > 1">
              <b-form-group
                id="input-group-3"
              >
                <template #label>
                  <label for="country">{{trans.get('__JSON__.Country')}} <span class="text-danger">*</span> </label>
                </template>
                <select
                  class="form-control"
                  v-model="$v.typeform.country.$model"
                  @change="getStates"
                  :class="{ 'is-invalid': $v.typeform.country.$error }"
                >
                  <option value="">
                    {{ trans.get("__JSON__.Select Country") }}
                  </option>
                  <option
                    v-for="country in countries"
                    :value="country.id"
                    :key="country.id"
                  >
                    {{ country.country_name }}
                  </option>
                </select>
                <div v-if="$v.typeform.country.$error" class="invalid-feedback">
                  <span v-if="!$v.typeform.country.required"
                    >{{ trans.get("__JSON__.Please select country") }}.</span
                  >
                </div>
              </b-form-group>
            </div>
            <div class="col-sm-4" v-if="(country_count == 1 && state_count > 1) || country_count > 1">
              <b-form-group
                id="input-group-3"
              >
                <template #label>
                  <label for="state">{{trans.get('__JSON__.State')}} <span class="text-danger">*</span> </label>
                </template>
                <select
                  class="form-control"
                  v-model="$v.typeform.state.$model"
                  @change="getCities"
                  :class="{ 'is-invalid': $v.typeform.state.$error }"
                >
                  <option value="">
                    {{ trans.get("__JSON__.Select State") }}
                  </option>
                  <option
                    v-for="state in states"
                    :value="state.id"
                    :key="state.id"
                  >
                    {{ state.state_name }}
                  </option>
                </select>
                <div v-if="$v.typeform.state.$error" class="invalid-feedback">
                  <span v-if="!$v.typeform.state.required"
                    >{{ trans.get("__JSON__.Please select state") }}.</span
                  >
                </div>
              </b-form-group>
            </div>
            <div class="col-sm-4" v-if="(country_count == 1 && state_count == 1 && city_count > 1) || country_count > 1 || state_count > 1">
              <b-form-group
                id="input-group-3"
              >
                <template #label>
                  <label for="city">{{trans.get('__JSON__.City')}} <span class="text-danger">*</span> </label>
                </template>
                <select
                  class="form-control"
                  v-model="$v.typeform.city.$model"
                  :class="{ 'is-invalid': $v.typeform.city.$error }"
                >
                  <option value="">
                    {{ trans.get("__JSON__.Select City") }}
                  </option>
                  <option
                    v-for="city in cities"
                    :value="city.cityid"
                    :key="city.cityid"
                  >
                    {{ city.cityname }}
                  </option>
                </select>
                <div v-if="$v.typeform.city.$error" class="invalid-feedback">
                  <span v-if="!$v.typeform.city.required"
                    >{{ trans.get("__JSON__.Please select city") }}.</span
                  >
                </div>
              </b-form-group>
            </div>
          </div>
           <div class="row">
              <div class="col-12 access m-add-user">
                <!--- Sidemenu -->
                  <div id="sidebar-menu">
                    <!-- Left Menu Start -->
                    <ul id="side-menu" class="metismenu list-unstyled">
                      <template v-for="item in menuItems">
                        <li
                          class="menu-title"
                          v-if="item.isTitle"
                          :key="item.id"
                          :id="'side-'+item.id"
                        >{{ (item.label) }}</li>
                        <li v-if="!item.isTitle && !item.isLayout" :key="item.id" :id="'side-'+item.id">
                          <a
                            v-if="hasItems(item)"
                            href="javascript:void(0);"
                            class="is-parent"
                          >
                          <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-6 col-6">
                              <i :class="`bx ${item.icon}`" v-if="item.icon"></i>
                              <span>{{ (item.label) }}</span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 col-6 text-right">
                              <div class="custom-control custom-checkbox custom-checkbox-right mb-3">
                                <input type="checkbox" class="custom-control-input" :id="'side-label-'+item.id" :checked="is_checked[item.id]" @change="selectAll($event,item.id)" true-value="true" false-value="false" />
                                <label :for="'side-label-'+item.id" class="custom-control-label">{{'Select All'}}</label>
                              </div>
                            </div>
                          </div>
                          </a>

                          <a v-if="!hasItems(item)" class="side-nav-link-ref" href="javascript:void(0);">
                            <div class="row">
                            <div class="col-4">
                              <i :class="`bx ${item.icon}`" v-if="item.icon"></i>
                              <span>{{ (item.label) }}</span>
                            </div>
                            <div class="col-4" v-for="(permission, index) in item.permissions" :key="index">
                              <div class="custom-control custom-checkbox custom-checkbox-right mb-3">
                                <input type="checkbox" class="custom-control-input" :id="(index?'write':'read')+'side-'+item.id" :value="permission" v-model="typeform.user_permissions" />
                                <label :for="(index?'write':'read')+'side-'+item.id" class="custom-control-label">{{index?'Write':'Read'}}</label>
                              </div>
                            </div>
                          </div>
                          </a>

                          <ul v-if="hasItems(item)" class="sub-menu" aria-expanded="false" href="javascript:void(0);">
                            <li v-for="(subitem, index) of item.subItems" v-if="subitem.id == 33 && $auth.getVendorId() == 40818 || subitem.id != 33" :key="index">
                              <a
                                href="javascript:void(0);"
                                v-if="!hasItems(subitem)"
                                class="side-nav-link-ref"
                              >
                              <div class="row">
                                  <div class="col-4">
                                    <span>{{ (subitem.label) }}</span>
                                  </div>
                                  <div class="col-4" v-for="(permission, index) in subitem.permissions" :key="index">
                                    <div class="custom-control custom-checkbox custom-checkbox-right mb-3">
                                      <input type="checkbox" class="custom-control-input" :id="'a-'+(index?'write':'read')+'side-'+subitem.id" :value="permission" v-model="typeform.user_permissions" />
                                      <label :for="'a-'+(index?'write':'read')+'side-'+subitem.id" class="custom-control-label">{{index?'Write':'Read'}}</label>
                                    </div>
                                  </div>
                                </div>
                              </a>
                              <a
                                v-if="hasItems(subitem)"
                                class="side-nav-link-a-ref has-arrow"
                                href="javascript:void(0);"
                              >
                                <div class="row">
                                  <div class="col-4">
                                    <span>{{ (subitem.label) }}</span>
                                  </div>
                                  <div class="col-4" v-for="(permission, index) in subitem.permissions" :key="index">
                                    <div class="custom-control custom-checkbox custom-checkbox-right mb-3">
                                      <input type="checkbox" class="custom-control-input" :id="'sub'+(index?'write':'read')+'side-'+subitem.id" :value="permission" v-model="typeform.user_permissions" />
                                      <label :for="'sub'+(index?'write':'read')+'side-'+subitem.id" class="custom-control-label">{{index?'Write':'Read'}}</label>
                                    </div>
                                  </div>
                                </div>
                              </a>
                              <ul v-if="hasItems(subitem)" class="sub-menu mm-collapse" aria-expanded="false">
                                <li v-for="(subSubitem, i) of subitem.subItems" :key="100+i">
                                  <a
                                    href="javascript:void(0);"
                                    class="side-nav-link-ref"
                                  >
                                    <div class="row">
                                      <div class="col-4">
                                        <span>{{ (subSubitem.label) }}</span>
                                      </div>
                                      <div class="col-4" v-for="(permission, index) in subSubitem.permissions" :key="index">
                                        <div class="custom-control custom-checkbox custom-checkbox-right mb-3">
                                          <input type="checkbox" class="custom-control-input" :id="'subsub'+(index?'write':'read')+'side-'+subSubitem.id" :value="permission" v-model="typeform.user_permissions" />
                                          <label :for="'subsub'+(index?'write':'read')+'side-'+subSubitem.id" class="custom-control-label">{{index?'Write':'Read'}}</label>
                                        </div>
                                      </div>
                                    </div>
                                  </a>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                      </template>
                    </ul>
                  </div>
              </div>
            <div class="col-12">
              <div class="col-sm-12 text-right">
                <button @click="$router.back()" class="btn btn-secondary mr-1 mt-3" type="button">
                  {{ trans.get("__JSON__.Cancel") }}
                </button>
                <button class="btn btn-primary mr-1 mt-3" type="submit">
                  {{ trans.get("__JSON__.Update") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-form>
  </Layout>
</template>
<style scoped>
.access .metismenu a:hover{
  color: black !important;
}
.access .metismenu a:hover i{
  color: black !important;
}
</style>