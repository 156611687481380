<script>
import config from '../../config'
import { required, email } from "vuelidate/lib/validators";
import { changePasswordService } from "../../services";
import { error_message, success_message } from "../../Helper/helper";
/**
 * Forgot Password component
 */
export default {
    data() {
        return {
            tryingToReset: false,
            isResetError: false,
            config: config,
            typeform: {
                email: "",
            },
        };
    },
    validations: {
        typeform: {
            email: {
                required,
                email
            }
        },
    },
    mounted() {
        this.isResetError = !!this.error;
        this.tryingToReset = !!this.status;
    },
    methods:{
        forgotPassword(){
            this.$v.$touch();
            if (this.$v.typeform.$invalid) {
                return;
            } else {
                changePasswordService.forgotPassword(this.typeform)
                    .then(response => {
                    if (response.data.code === 200) {
                        success_message(response.data.message);
                    } else {
                        error_message(response.data.message);
                    }
                });
            }
        }
    },
    props: {
        error: {
            type: String,
            required: false,
            default: () => null
        },
        status: {
            type: String,
            required: false,
            default: () => null
        }
    }
};
</script>

<template>
    <div>
        <div class="account-pages my-5 pt-5">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-8 col-lg-6 col-xl-5">
                        <div class="card overflow-hidden">
                            <div class="bg-soft-danger">
                                <div class="row">
                                    <div class="col-7">
                                        <div class="text-danger p-4">
                                            <h5 class="text-danger">Reset Password</h5>
                                            <p>Re-Password with {{config.appname}}.</p>
                                        </div>
                                    </div>
                                    <div class="col-5 align-self-end">
                                        <img :src="config.basepath+'profile-img.png'" alt class="img-fluid" />
                                    </div>
                                </div>
                            </div>
                            <div class="card-body pt-0">
                                <div>
                                    <div class="avatar-md profile-user-wid mb-4 avatar-logo-main">
                                        <span class="avatar-title rounded-circle bg-light">
                                            <img :src="config.applogo" alt />
                                        </span>
                                    </div>
                                </div>
                                <div class="p-2">
                                    <form @submit.prevent="forgotPassword">
                                    <slot />
                                        <div class="form-group">
                                            <label for="useremail">Email</label>
                                            <input type="email" name="email" v-model="$v.typeform.email.$model" class="form-control" id="useremail" :placeholder="trans.get('__JSON__.Enter Email')" :class="{ 'is-invalid': $v.typeform.email.$error }"/>
                                            <div v-if="$v.typeform.email.$error" class="invalid-feedback">
                                                <span v-if="!$v.typeform.email.required">{{ trans.get('__JSON__.Please enter email') }}</span>
                                                <span v-if="!$v.typeform.email.email">{{ trans.get('__JSON__.This value should be a valid email') }}</span>
                                            </div>
                                        </div>
                                        <div class="form-group row mb-0">
                                            <div class="col-12 text-right">
                                                <b-button type="submit" variant="danger" class="btn btn-danger w-md">Reset</b-button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <!-- end card-body -->
                        </div>
                        <!-- end card -->

                        <div class="mt-5 text-center">
                        <p> Remember It ? 
                            <router-link to="/vendor/login" class="font-weight-medium text-danger">Sign In here</router-link>
                        </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end col -->
    </div>
</template>
