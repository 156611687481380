<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import { customerService } from "../../services";
import { debounce, error_message, order_statuses, success_message, toastr_message } from '../../Helper/helper';
import DateRangePicker from "vue2-daterange-picker";
import OrderList from "../orders/order_tabs/order-list";
import pagination from "laravel-vue-pagination";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import AffiliateProfile from '../../views/affiliate/affiliate-profile.vue';
import AffiliateAdd from '../../views/affiliate/affiliate-add.vue';
import InstamedcashHistory from '../../views/affiliate/instamedcash-history.vue';
import ReferredHistory from '../../views/affiliate/referred-history.vue';
import config from "../../config";
import { required, numeric, decimal } from "vuelidate/lib/validators";
/**
 * Products-order component
 */
export default {
  components: { Layout, PageHeader, OrderList , DateRangePicker, pagination, AffiliateProfile, AffiliateAdd, InstamedcashHistory, ReferredHistory},
  data() {
    
    return {
      affiliates: [],
      referredCount: '',
      statuses: order_statuses,
      loading: false,
      affiliate_status: 0,
      debounce,
      showModal: false,
      instamedcashvalue: '',
      config: config,
      deposit_value: '',
      showError: false
    };
  },
  mounted() {
    this.getCustomerProfile();
  },
  validations: {
    deposit_value : {
      required,
      decimal
    },
  },
  methods: {
    getCustomerProfile() {
      customerService
        .getAffiliateDetails({
          affiliate_id: parseInt(atob(this.$route.params.affiliate_id)),
          vendor_id: this.$auth.getVendorId()
        })
        .then((response) => {
          console.log(response, "affiliate response");
          this.affiliates = response.data.Result[0];
          this.referredCount = response.data.Referred_count;
          this.affiliate_status = response.data.Result[0].status;
          this.instamedcashvalue = response.data.Result[0].instamed_cash;
        });
    },
    onStatusChange($event){
      console.log($event, "event affiliate",$event.target.checked);
       this.affiliate_status = $event.target.checked == true ? 1 : 0;
      customerService
      .changeAffiliateStatus({
        affiliate_id: parseInt(atob(this.$route.params.affiliate_id)),
        status: this.affiliate_status
      })
      .then((response) => {
        console.log("bhdbhbfd", response);
        if(response.data.code === 200){
            success_message(response.data.msg);
        }else{
            error_message(response.data.msg)
        }
      });
    },
    showInstamedCashPay(){
      this.showModal = true;
    },
    hideInstamedCashPay(){
      this.showModal = false;
      this.$v.$reset();
      this.deposit_value = '';
      this.showError = false;
    },
    typeForm(e) {
      this.$v.$touch();
      if (this.$v.deposit_value.$invalid) {
        console.log("hi");
        return;
      }else if (parseFloat(this.deposit_value) > parseFloat(this.instamedcashvalue)) {
        this.showError = true;
          return;
        }
      else{
        console.log("this.hiiiiiiiiiiiiiiiiiiiiii");
        let data = {
          affiliate_id: parseInt(atob(this.$route.params.affiliate_id)),
          amount: parseFloat(this.deposit_value),
          transactions:"Debit",
          vendor_id: this.$auth.getVendorId()
        }
        customerService.storeInstamedCashDetails(data)
        .then(response => {
        console.log(response, "affiliate history");
        if(response.data.code ==200){
          success_message(response.data.msg);
          this.showModal = false;
          this.deposit_value = '';
          this.$v.$reset();
          this.showError = false;  
          this.getCustomerProfile();  
          this.$refs.childRef.getHistory();      
        }
		    });
      }  
    },
    clearInput() {
      this.$v.$reset();
      this.deposit_value = '';
      this.showError = false;
    },
  },
  
};
</script>

<template>
  <Layout>
    <div class="row">
          <div class="col-12">
            <div
              class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
            >
              <div class="page-title-left avatar-main-icon">
                <div class="avatar-xs mr-3">
                  <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                    <i class="bx bx-user-circle"></i>
                  </span>
                </div>
                <div class="report-tag">
                  <h4 class="mb-0 font-size-18">{{ affiliates.name }} (Referred: <span style="color: #546DE4;">{{referredCount}}</span>)</h4>
                  <p>
                    <router-link :to="{ name: 'affiliate-list' }">{{ trans.get('__JSON__.Affiliates') }}</router-link>
                    > {{ affiliates.name }}
                  </p>
                </div>
              </div>
              <div class="page-title-right">
                <div style="display: flex; align-items: center;">
                <button class="btn btn-primary btn-rounded export-btn" style="height: 40px; margin-right: 10px;" @click="showInstamedCashPay">InstaMedCash Pay</button>
                    <div class="vendor-status">
                        <b-form-group
                          id="input-group-1"
                          :label="trans.get('__JSON__.Status')"
                          label-for="wallet"
                        >
                          <label class="switch">
                            <input
                              type="checkbox"
                              id="togBtn"
                              name="wallet_status"
                              class="switch-on"
                              true-value="1"
                              false-value="0"
                              v-model="affiliate_status"
                              @change="onStatusChange"
                            />
                            <div class="slider round">
                              <span class="on">{{ trans.get('__JSON__.Active') }}</span>
                              <span class="off">{{ trans.get('__JSON__.Inactive') }}</span>
                            </div>
                          </label>
                        </b-form-group>
                     </div>
                </div>
              </div>
                    
            </div>
          </div>
        </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
              <b-tabs
                nav-class="nav-tabs-custom"
                content-class="p-3 text-muted"
                lazy
              >
                <b-tab >
                  <template v-slot:title>
                    <span class="d-inline-block d-sm-none">
                      <i class="fas fa-clock"></i>
                    </span>
                    <span class="d-none d-sm-inline-block">{{
                      trans.get("__JSON__.Profile")
                    }}</span>
                  </template>
                  <!-- code now -->
                  <affiliate-profile />
                </b-tab>
                <b-tab >
                  <template v-slot:title>
                     <span class="d-inline-block d-sm-none">
                      <i class="fas fa-times-circle"></i>
                    </span>
                    <span class="d-none d-sm-inline-block">{{
                      trans.get("__JSON__.Referred History")
                    }}</span>
                  </template>
                  <referred-history/>
                </b-tab>
                <b-tab >
                  <template v-slot:title>
                     <span class="d-inline-block d-sm-none">
                      <i class="fas fa-times-circle"></i>
                    </span>
                    <span class="d-none d-sm-inline-block">{{
                      trans.get("__JSON__.InstaMedCash History")
                    }}</span>
                  </template>
                  <instamedcash-history ref="childRef"/>
                </b-tab>
              </b-tabs>
          </div>
        </div>
      </div>
    </div>

    <b-modal v-model="showModal" centered @hide="clearInput">
          <template #modal-title>
            <div class="d-flex" style="align-items: center !important;">
              <h5 style="margin-top: 8px;">Pay InstaMedCash Balance</h5>
              <h5 style="color: #546DE4; margin: 1px 2px 0px 4px;"> {{ instamedcashvalue || 0 }}</h5>
              <img :src="config.basepath+'Instamedcash.svg'" alt="insatmedcash" style="height: 30px; width: 30px;" />
            </div>
          </template>
          <div class="row">
              <div class="col-md-12">
                  <div class="form-group">
                      <label>Enter InstaMedCash</label>
                      <input id="deposit_value" type="text" class="form-control" placeholder="Enter InstaMedCash amount" name="menu_name" v-model="deposit_value" :class="{ 'is-invalid':$v.deposit_value.$error || showError}"/>
                      <div v-if="$v.deposit_value.$error || showError" class="invalid-feedback">
                        <span v-if="!$v.deposit_value.required">Please enter relevant InstaMedCash Amount.</span>
                        <span v-if="!$v.deposit_value.decimal">Please enter relevant InstaMedCash Amount.</span>
                        <span v-if="showError">InstaMedCash Amount can't be greater than InstaMedCash Balance.</span>
                       </div> 
                  </div>
              </div>
          </div>
            <div slot="modal-footer" class="w-100">
                <b-btn class="float-right btn" @click="typeForm">
                    Submit
                </b-btn>
                <b-btn @click="hideInstamedCashPay">
                    Close
                </b-btn>
              </div>
        </b-modal>
  </Layout>
</template>
