<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import pagination from "laravel-vue-pagination";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import { reportService } from "../../services";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";


export default {
  components: { Layout, PageHeader, pagination, DatePicker },
  data() {
    return {
      totalSale: {},
      restaurant: [],
      restaurants_id: "",
      sortBy: "created_at",
      sortDesc: true,
      paginations: {},
      limit: 2,
      country: 0,
      state: 0,
      city: 0,
      loading: false,
      fields: [
        { key: "user_id", label: "#", sortable: false },
        { key: "customer_name", label: "Customer Name", sortable: false },
        { key: "customer_mobile_number", label: "Customer Number", sortable: false },
        { key: "order_placed_by_name", label: "Order Placed By Name",sortable: false},
        { key: "order_placed_by_number", label: "Order Placed By Number",sortable: false},
        { key: "view_report", label: "View Report", sortable: false}
      ],
      selectedStartDate: this.getPreviousMonday(new Date()),
      selectedEndDate: this.getPreviousSunday(new Date()),
      moment: moment,
    };
  },
  mounted() {
    this.getRestaurants();
  },
  methods: {
    getTotalUserDetailsReports(page) {
      this.loading = true;
      reportService
      .getUserListByResturant({
        restaurant_id: this.restaurants_id,
        start_date: this.selectedStartDate,
        end_date: this.selectedEndDate,
        page: page ? page : 1,
      })
      .then((response) => {
        this.loading = false;
        this.paginations = response.data.result;
        this.totalSale = response.data.result;
      });
    },
    searchData() {
      this.getTotalUserDetailsReports();
    },
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      this.getTotalUserDetailsReports();
    },
    getRestaurants() {
      this.restaurant = [];
      reportService
        .getAllRestaurantName({
          country_id: this.country,
          state_id: this.state,
          city_id: this.city,
        })
        .then((response) => {
          this.restaurant = response.data.result;
        });
    },
    disableEndDate(date) {
      return moment(date).isBefore(this.selectedStartDate, 'day');
    },
    disableStartDate(date) {
      return moment(date).day() !== 1;
    },
    updateEndDate() {
      if (this.selectedStartDate) {
        const nextSunday = moment(this.selectedStartDate).add(6, 'days').endOf('isoWeek');
        this.selectedEndDate = nextSunday.format('YYYY-MM-DD');
      }else{
        this.selectedEndDate = '';
      }
    },
    getPreviousMonday(d) {
      d = new Date(d);
      var day = d.getDay(),
        diff = d.getDate() - day + (day === 0 ? -6 : 1) - 7; 
      let monday = new Date(d.setDate(diff));
      return moment(monday).format('YYYY-MM-DD');
    },
    getPreviousSunday(d) {
      d = new Date(d);
      var day = d.getDay(),
        diff = d.getDate() - day + (day === 0 ? 0 : 7) - 7;
      let sunday = new Date(d.setDate(diff));
      return moment(sunday).format('YYYY-MM-DD');
    }
  },
  watch: {
    selectedStartDate() {
      this.updateEndDate();
      if(this.selectedStartDate){
        this.getTotalUserDetailsReports();
      }else{
        this.totalSale = {};
      }
    },
  },
};
</script>

<template>
  <Layout>
    <div>
      <div class="row">
          <div class="col-12">
              <div
              class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
              >
              <div class="page-title-left avatar-main-icon">
                  <div class="avatar-xs mr-3">
                  <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                      <i class="bx bx-purchase-tag"></i>
                  </span>
                  </div>
                  <div class="report-tag">
                    <h4 class="mb-0 font-size-18">{{ trans.get('__JSON__.Store Wise Settlement Report') }}</h4>
                    <p><router-link :to="{ name: 'reports-list'}">{{ trans.get('__JSON__.Reports') }}</router-link> > {{ trans.get('__JSON__.Store Wise Settlement Report') }}</p>
                  </div>
              </div>
              </div>
          </div>
      </div>

      <div class="row">
        <div class="col-12 list-table-main">
          <div class="card">
            <div class="card-body">
              <div class="row justify-content-end">
                <div class="form-group">
                  <div style="display: flex;">
                  <div style="display: flex; align-items: end; margin-right: 20px;">
                    <div style="margin: 5px 10px;">From</div>
                      <date-picker v-model="selectedStartDate" format="YYYY-MM-DD" value-type="YYYY-MM-DD" type="date" class="sales-date"
                        :disabled-date="disableStartDate"></date-picker>
                      <div style="margin: 5px 10px;">To</div>
                      <date-picker v-model="selectedEndDate" :disabled-date="disableEndDate" format="YYYY-MM-DD" :disabled="true"
                        value-type="YYYY-MM-DD" type="date"></date-picker>
                  </div>
                  <div class="date-range-list">
                      <label
                        >{{
                          trans.trans("__JSON__.Vendor", {
                            vendor: $auth.setting.restaurant_name,
                          })
                        }}
                        :</label
                      >
                      <select
                        class="custom-select"
                        v-model="restaurants_id"
                        @change="searchData()"
                      >
                        <option value selected>
                          {{
                            trans.trans("__JSON__.Select Store")
                          }}
                        </option>
                        <option
                          v-for="res in restaurant"
                          :value="res.restaurant_id"
                          :key="res.restaurant_id"
                        >
                          {{ res.name }}
                        </option>
                      </select>
                  </div>
                  <div class="page-title-right" style="margin-top: 28px; margin-left: 18px;">
                    <router-link :to="{name:'reports.all.stores.settlement.report',params: {startDate: selectedStartDate, endDate: selectedEndDate}}"
                  class="btn btn-primary btn-rounded export-btn">
                  {{ trans.get("__JSON__.All Stores") }}
                  </router-link>
                  </div>
                </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 table-main-list total-sales-report-table table-responsive">
                  <b-table
                    :items="totalSale.data"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :no-local-sorting="true"
                    @sort-changed="sortingChanged"
                    :busy="loading"
                    show-empty
                  >
                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>{{trans.get('__JSON__.Loading...')}}</strong>
                      </div>
                    </template>

                    <template v-slot:cell(user_id)="data">
                      <div>{{ (totalSale.per_page * (totalSale.current_page-1)) + data.index + 1 }}</div>
                    </template>

                    <template v-slot:cell(customer_name)="data">
                      <span>{{ data.item.customer_name }}</span>
                    </template>

                    <template v-slot:cell(customer_mobile_number)="data">
                      <span>{{ data.item.customer_mobile_number }}</span>
                    </template> 
                    
                    <template v-slot:cell(view_report)="data">
                      <router-link :to="{name:'reports.settlement.details.report',params: {restaurantID : data.item.restaurant_id, userID: data.item.order_palced_by, startDate: selectedStartDate, endDate: selectedEndDate}}" class="btn btn-primary btn-sm btn-rounded">
                             View Settlement Report
                      </router-link>
                    </template>

                    <template #empty>
                      <p class="text-center">{{ trans.get('__JSON__.No record found') }}</p>
                    </template>
                  </b-table>
                </div>
                <div class="row col-sm-5 offset-sm-7">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <pagination
                          :data="totalSale"
                          :limit="limit"
                          @pagination-change-page="getTotalUserDetailsReports"
                        ></pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>


<style>
.total-sales-report-table .table thead th:nth-child(1),.total-sales-report-table .table thead th:nth-child(3),.total-sales-report-table .table thead th:nth-child(4){
    pointer-events: none;
}

.store-gst{
    width: 96% !important;
}

.mx-input:disabled,
.mx-input.disabled {
  color: #555;
  background-color: #fff;
  border-color: #ccc;
  cursor: not-allowed !important;
}

.mx-input {
  height: calc(1.5em + 0.94rem + 1px) !important;
}

.sales-date.mx-datepicker svg {
  cursor: pointer !important;
}

.mx-datepicker svg {
    cursor: not-allowed;
}
</style>