
<script>
import { cuisineService } from "../../services";
import { required, requiredIf } from "vuelidate/lib/validators";
import config from "../../config";
import { error_message } from "../../Helper/helper";
import { success_message, replaceByDefault } from "../../Helper/helper";
import DropzonePopup from "../../components/widgets/dropzone-popup";
import DropzoneSingle from '../../components/widgets/dropzone-single';
import { routes } from "../../config/api_routes";

export default {
  components: {
    DropzonePopup,
    DropzoneSingle
  },
  data() {
    return {
      file1: null,
      url: null,
      value: null,
      tmpCategoryImage: "",
      config: config,
      cuisine: {
        cuisine_id: "",
        cuisine_name: "",
        cuisine_name_thai: "",
        cuisine_description: "",
        cuisine_description_thai: "",
        image: "",
        cuisine_active: "",
      },
      cuisine_image: "",
      cuisine_image_path: "",
      cuisine_image_delete_url: routes.cuisineImageDeleteUrl,
      cuisine_id: "",
    };
  },
  validations: {
    cuisine: {
      cuisine_name: {
        required,
      },
      cuisine_description: {
        required,
      },
      // image: {
      //  required: requiredIf(function (nestedModel) {
      //    return !(!!this.cuisine_image || !!this.cuisine.image);
      //  }),
      // },
    },
  },
  mounted() {
    this.getCuisineById();
  },
  methods: {
    onCuisineImagedeleted(value) {
      this.cuisine_image = "";
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.url = URL.createObjectURL(file);
      this.cuisine.image = file;
    },
    getCuisineById() {
      cuisineService
        .getCuisineById({ cuisine_id: this.$route.params.cuisine_id })
        .then((response) => {
          const {
            cuisine_id,
            cuisine_name,
            cuisine_name_thai,
            cuisine_description,
            cuisine_description_thai,
            cuisine_active,
          } = response.data.result;
          this.cuisine_image_path = response.data.result.cuisine_image_path;
          this.cuisine_image = response.data.result.image;
          this.cuisine_id = response.data.result.cuisine_id;
          this.cuisine = {
            cuisine_id,
            cuisine_name,
            cuisine_name_thai,
            cuisine_description,
            cuisine_description_thai,
            cuisine_active,
          };
        });
    },
    cuisineInIt() {
      this.$v.cuisine.$touch();

      if (this.$v.cuisine.$invalid) {
        return;
      } else {
        const fd = new FormData();
        fd.append("cuisine_id", this.cuisine.cuisine_id);
        if (!!this.cuisine.image) {
          fd.append("image", this.cuisine.image);
        }
        fd.append("cuisine_name", this.cuisine.cuisine_name);
        fd.append("cuisine_name_thai", this.cuisine.cuisine_name_thai);
        fd.append("cuisine_description", this.cuisine.cuisine_description);
        fd.append(
          "cuisine_description_thai",
          this.cuisine.cuisine_description_thai
        );
        fd.append("cuisine_active", this.cuisine.cuisine_active);
        cuisineService.updateCuisineById(fd).then((response) => {
          if (response.data.code === 200) {
            success_message(response.data.message);
            this.$router.push({ name: "cuisines-edit" });
          } else {
            error_message(response.data.message);
          }
        });
      }
    },
  },
};
</script>

<template>
  <b-form @submit.prevent="cuisineInIt">
    <!-- Cuisine Info Start -->
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">{{ trans.get('__JSON__.Cuisine Info',{cuisine: $auth.setting.cuisine_name}) }}</h4>
        <p class="card-title-desc"></p>
        <div class="row">
          <div class="form-group">
            <input
              id="cuisine_id"
              name="cuisine_id"
              type="hidden"
              @id="cuisine_id"
              v-model="cuisine.cuisine_id"
              class="form-control"
            />
          </div>
          <div class="col-sm-6" v-if="$auth.setting">
            <b-form-group
              id="input-group-1"
              :label="trans.trans('__JSON__.Cuisine Name Language', { cuisine: $auth.setting.cuisine_name,lang: $auth.langauges[0].name })"
              label-for="cuisine_name"
            >
              <b-form-input
                id="cuisine_name"
                name="cuisine_name"
                v-model="$v.cuisine.cuisine_name.$model"
                type="text"
                :placeholder="trans.trans('__JSON__.Cuisine Name Language', { cuisine: $auth.setting.cuisine_name,lang: $auth.langauges[0].name })"
                :class="{ 'is-invalid': $v.cuisine.cuisine_name.$error }"
              ></b-form-input>
              <div v-if="$v.cuisine.cuisine_name.$error" class="invalid-feedback">
                <span
                  v-if="!$v.cuisine.cuisine_name.required"
                >{{ trans.get('__JSON__.Please Enter Cuisine Name Error',{cuisine: $auth.setting.cuisine_name}) }}.</span>
              </div>
            </b-form-group>
          </div>

          <div class="col-sm-6 d-none" v-if="$auth.langauges[1].name === 'No Secondary Language'">
          </div>
          <div class="col-sm-6" v-else>
            <b-form-group
              id="input-group-1"
              :label="trans.trans('__JSON__.Cuisine Name Language', { cuisine: $auth.setting.cuisine_name_sec_lang,lang: $auth.langauges[1].name })"
              label-for="cuisine_name_thai"
            >
              <b-form-input
                id="cuisine_name_thai"
                name="cuisine_name_thai"
                v-model="cuisine.cuisine_name_thai"
                type="text"
                :placeholder="trans.trans('__JSON__.Cuisine Name Language', { cuisine: $auth.setting.cuisine_name_sec_lang,lang: $auth.langauges[1].name })"
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="col-sm-6">
            <b-form-group
              id="input-group-1"
              :label="trans.trans('__JSON__.Cuisine Description Language', { cuisine: $auth.setting.cuisine_name,lang: $auth.langauges[0].name })"
              label-for="cuisine_description"
            >
              <b-form-textarea
                rows="5"
                id="cuisine_description"
                type="text"
                class="form-control"
                v-model="$v.cuisine.cuisine_description.$model"
                :placeholder="trans.trans('__JSON__.Cuisine Description Language', { cuisine: $auth.setting.cuisine_name,lang: $auth.langauges[0].name })"
                name="cuisine_description" 
                :class="{ 'is-invalid': $v.cuisine.cuisine_description.$error }"
              ></b-form-textarea>
              <div v-if="$v.cuisine.cuisine_description.$error" class="invalid-feedback">
                <span
                  v-if="!$v.cuisine.cuisine_description.required"
                >{{ trans.get('__JSON__.Please Enter Cuisine Description',{cuisine: $auth.setting.cuisine_name}) }}.</span>
              </div>
            </b-form-group>
          </div>

          <div class="col-sm-6 d-none" v-if="$auth.langauges[1].name === 'No Secondary Language'">
          </div>
          <div class="col-sm-6" v-else>
            <b-form-group
              id="input-group-1"
              :label="trans.trans('__JSON__.Cuisine Description Language', { cuisine: $auth.setting.cuisine_name_sec_lang,lang: $auth.langauges[1].name })"
              label-for="cuisine_description_thai"
            >
              <b-form-textarea
                rows="5"
                id="cuisine_description_thai"
                type="text"
                class="form-control"
                v-model="cuisine.cuisine_description_thai"
                :placeholder="trans.trans('__JSON__.Cuisine Description Language', { cuisine: $auth.setting.cuisine_name_sec_lang,lang: $auth.langauges[1].name })"
                name="cuisine_description_thai"
              ></b-form-textarea>
            </b-form-group>
          </div>

         <div class="col-md-6 vendor-status">
            <b-form-group
              id="input-group-2"
              :label="trans.get('__JSON__.Status')"
              label-for="Status"
            >
              <div class="row">
                <div class="col-sm-6">
                  <div class="swtich-data">
                    <label class="switch">
                      <input
                        type="checkbox"
                        id="togBtn"
                        class="switch-on"
                        name="cuisine_active"
                        v-model="cuisine.cuisine_active"
                        true-value="1"
                        false-value="0"
                      />
                      <div class="slider round">
                        <!--ADDED HTML -->
                        <span class="on">{{ trans.get('__JSON__.Active') }}</span>
                        <span class="off">{{ trans.get('__JSON__.Inactive') }}</span>
                        <!--END-->
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </b-form-group>
          </div>
        </div>
      </div>
    </div>
    <!-- Cuisine Info End -->

    <!-- Cuisine Image Start -->
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">{{ trans.get('__JSON__.Cuisine Image',{cuisine: $auth.setting.cuisine_name}) }}</h4>
        <p class="card-title-desc"></p>

        <div class="row">
          <div class="col-sm-3">
            <dropzone-popup
              v-model="cuisine.image"
              id="cuisine_image"
              acceptedFiles=".jpeg,.jpg,.png,.webp,image/webp"
              :editable-images="cuisine_image"
              :media-path="cuisine_image_path"
              :delete-path="cuisine_image_delete_url"
              @image-deleted="this.onCuisineImagedeleted"
            />
            <!-- :class="{ 'is-invalid': $v.cuisine.image.$error }" -->
            <!-- <div v-if="$v.cuisine.image.$error" class="invalid-feedback cuisine-img">
              <span
                v-if="!$v.cuisine.image.required"
              >{{ trans.get('__JSON__.Please Select Cuisine Image') }}.</span>
            </div> -->
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <button
              type="submit"
              class="btn btn-primary mr-1 mt-3"
            >{{ trans.get('__JSON__.Update Cuisines',{cuisine: $auth.setting.cuisine_name}) }}</button>
            <b-button @click="$router.back()" type="button" variant="secondary" class="mt-3">{{ trans.get('__JSON__.Cancel') }}</b-button>
          </div>
        </div>
      </div>
    </div>
    <!-- Cuisine Image End -->
  </b-form>
</template>

<style>
</style>