<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import pagination from "laravel-vue-pagination";
import {
  get_partner_login,
  set_partner,
  set_partner_details,
  set_user,
  set_user_detail,
  debounce,
  get_decimal,
  get_currency
} from "../../Helper/helper";
import moment from "moment";
import { reportService , deliveryZoneService} from "../../services";
/**
 * Report component
 */
export default {
  components: { Layout, PageHeader, pagination },
  data() {
    return {
      decimal: get_decimal(),
      customerReport: [],
      days:"30",
      customerExportReport: [],
      sortBy: "created_at",
      search: "",
      sortDesc: true,
      paginations: {},
      limit: 2,
      currency: get_currency(),
      loading: true,
      isDownloading: false,
      fields: [
        { key: "unique", label: "Customer ID", sortable: false },
        { key: "user_name", label: "Name", sortable: true },
        { key: "mobile_number", label: "Phone Number", sortable: true },
        { key: "email", label: "Email", sortable: true },
        { key: "referrer_type", label: "Referred By", sortable: false },
        { key: "referred_name", label: "Referrer", sortable: false },
        { key: "referral_count", label: "Referred Count", sortable: false},
      ],
    };
  },
  mounted() {
    this.getCustomerReport();
    // this.exportCustomerReport();
  },
  methods: {
    downloadsCSV: function () {
      let customerExportReport =
        '\ufeff' +
        'Customer ID,Name,Phone Number,Email,Referred By,Referrer,Referred Count\n'
      this.customerExportReport.forEach((el) => {
        var line =
          el["unique"] +
          "," + 
          el["user_name"] +" " + el["last_name"] +
          "," +
          el["mobile_number"] +
          "," +
          el["email"] +
          "," +
          (el["referred_by"] ? "Customer" : el["referred_by_affiliate"] ? "Affiliate" : "-") +
          "," +
          (el["referred_name"] ? el["referred_name"] : "-") + 
          "," + 
          el["referral_count"] +
          "," +
          "\n"
        customerExportReport += line;
      });

      var blob = new Blob([customerExportReport], { type: "csv/plain" });
      var date = new Date().toLocaleString();
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "inactive_customer_" + date + ".csv";
      link.click();
    },
    getCustomerReport(page) {
      this.loading = true;
      reportService
        .getInactiveCustomerReport({
          days: this.days,
          search: this.search,
          sortBy: this.sortBy,
          orderBy: this.sortDesc ? "desc" : "asc",
          page: page ? page : 1,
        })
        .then((response) => {
          this.loading = false;
          this.paginations = response.data.result;
          this.customerReport = response.data.result.data;
        });
    },
    exportCustomerReport()
    {
        this.isDownloading = true;
        reportService.exportInactiveCustomer({
            days: this.days,
            search: this.search,
            sortBy: this.sortBy,
            orderBy: this.sortDesc ? "desc" : "asc"
        }).
        then((response)=>{
            this.isDownloading = false;
            this.customerExportReport = response.data.result;
            this.downloadsCSV();
        });
    },
    searchData() {
      this.getCustomerReport();
      // this.exportCustomerReport();
    },
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      this.getCustomerReport();
      // this.exportCustomerReport();
    },
  }
};
</script>

<template>
  <Layout>
    <div>
      <div class="row">
          <div class="col-12">
              <div
              class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
              >
              <div class="page-title-left avatar-main-icon">
                  <div class="avatar-xs mr-3">
                  <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                      <i class="bx bx-purchase-tag"></i>
                  </span>
                  </div>
                  <div class="report-tag">
                    <h4 class="mb-0 font-size-18">{{ trans.get('__JSON__.Inactive Customer Report') }}</h4>
                    <p><router-link :to="{ name: 'reports-list'}">{{ trans.get('__JSON__.Reports') }}</router-link> > {{ trans.get('__JSON__.Inactive Customer Report') }}</p>
                  </div>
              </div>
              <div class="page-title-right">
                <button @click="exportCustomerReport" class="btn btn-primary btn-rounded export-btn" v-if="isDownloading" disabled>{{ trans.get('__JSON__.Exporting...') }}</button>
                <button @click="exportCustomerReport" class="btn btn-primary btn-rounded export-btn" v-else>{{ trans.get('__JSON__.Export To CSV') }}</button>
                <button @click="exportCustomerReport" class="btn btn-primary btn-rounded m-export-btn mb-4"><i class="bx bx-archive-in"></i></button>
              </div>
              </div>
          </div>
      </div>

      <!-- end row -->
      <div class="row">
        <div class="col-12 list-table-main">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-sm-2 offset-sm-8">
                  <div class="form-group">
                    <div class="date-range-list">
                      <label>{{ trans.get('__JSON__.Days') }} :</label>
                      <select class="custom-select" v-model="days" @change="searchData">
                        <option value="30" selected>{{ trans.get('__JSON__.30 Days') }}</option>
                        <option value="60">{{ trans.get('__JSON__.60 Days') }}</option>
                        <option value="90">{{ trans.get('__JSON__.90 Days') }}</option>
                        <option value="91">{{ trans.get('__JSON__.More than 90 Days') }}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-sm-2">
                  <div class="search-box mr-2 mb-0 d-inline-block form-group">
                    <div class="date-range-list">
                      <label>{{trans.get('__JSON__.Search')}} :</label>
                      <div class="position-relative">
                        <input
                          type="text"
                          class="form-control"
                           @keyup="searchData()"
                           v-model="search"
                          :placeholder="trans.get('__JSON__.Search...')"
                        />
                        <i class="bx bx-search-alt search-icon"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 table-main-list inactive-customer-report-table table-responsive">
                  <b-table
                    :items="customerReport"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :no-local-sorting="true"
                    @sort-changed="sortingChanged"
                    :busy="loading"
                    show-empty
                  >

                  <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>{{trans.get('__JSON__.Loading...')}}</strong>
                      </div>
                    </template>

                    <template v-slot:cell(user_name)="data">
                        <p>{{ data.item.user_name }} {{ data.item.last_name }}</p>
                    </template>

                    <template v-slot:cell(referrer_type)="data">
                      {{ data.item.referred_by ? "Customer" : data.item.referred_by_affiliate ? "Affiliate" : "-"}}
                    </template>

                     <template #empty>
                      <p class="text-center">{{ trans.get('__JSON__.No Inactive customer Found') }}</p>
                    </template>
                  </b-table>
                </div>
                <div class="row col-sm-5 offset-sm-7">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <pagination
                          :data="paginations"
                          :limit="limit"
                          @pagination-change-page="getCustomerReport"
                        ></pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style>
.inactive-customer-report-table .table thead th:nth-child(1){
    pointer-events: none;
}
</style>