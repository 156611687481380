<template>
  <div class="row">
    <div class="col-12">
        <div class="row border-bottom mb-3">
            <div class="col-lg-4">
                <div class="media" v-if="restaurant">
                    <div class="mr-3">
                        <img :src="restaurant.icon_image_path" @error="replaceByDefault" alt="" class="avatar-md rounded-circle img-thumbnail">
                    </div>
                    <div class="media-body align-self-center">
                        <div class="text-muted">
                            <!-- <div v-if="restaurant.cuisines && restaurant.cuisines.length > 0">
                            <span v-for="cuisine in restaurant.cuisines" :key="cuisine.cuisine_id" class="badge badge-soft-primary font-size-12 mr-1">{{ cuisine.cuisine_name }}</span>
                            </div> -->
                            <h5 class="mb-1">{{restaurant.name}}</h5>
                            <p class="mb-0">{{restaurant.email}}</p>
                            <p class="mb-0">{{restaurant.address}}</p>
                            <!-- <input type="hidden" class="rating" data-filled="mdi mdi-star text-primary"
                                data-empty="mdi mdi-star-outline text-muted" :value="ratings" disabled /> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-8 align-self-center">
                <div class="text-lg-center mt-4 mt-lg-0">
                    <div class="row" v-if="Object.keys(dayWise).length > 0">
                        <div class="col-lg-3 col-md-3 col-sm-6 col-6 mb-3">
                            <div>
                                <p class="text-muted text-truncate mb-2">{{trans.get('__JSON__.Today')}}</p>
                                <h5 class="mb-0"><span v-html="currency"></span> {{dayWise.today}}</h5>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-6 col-6 mb-3">
                            <div>
                                <p class="text-muted text-truncate mb-2">{{trans.get('__JSON__.7 Days')}}</p>
                                <h5 class="mb-0"><span v-html="currency"></span> {{dayWise.lastWeek}}</h5>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-6 col-6 mb-3">
                            <div>
                                <p class="text-muted text-truncate mb-2">{{trans.get('__JSON__.This Month')}}</p>
                                <h5 class="mb-0"><span v-html="currency"></span> {{dayWise.thisMonth}}</h5>

                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-6 col-6 mb-3">
                            <div>
                                <p class="text-muted text-truncate mb-2">{{trans.get('__JSON__.Last Month')}}</p>
                                <h5 class="mb-0"><span v-html="currency"></span> {{dayWise.lastMonth}}</h5>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12">
        <div class="row border-bottom">
            <div class="col-lg-4">
                <b-card class="card">
                    <b-card-title>
                        <h5 class="card-title">{{trans.get('__JSON__.Bank Details')}}</h5>
                    </b-card-title>
                    <b-card-text v-if="bank_info">
                        <p>{{ trans.get('__JSON__.Bank Name')}} : {{bank_info.bank_name}}</p>
                        <p>{{ trans.get('__JSON__.Bank Acc Number')}} : {{bank_info.bank_no}}</p>
                        <p>{{ trans.get('__JSON__.Bank IFSC/Routing Code')}} : {{bank_info.ifsc_code}}</p>
                        <p>{{ trans.get('__JSON__.Branch Name')}} : {{bank_info.branch_name}}</p>
                    </b-card-text>
                    <b-card-text v-else>
                        <p>{{trans.get('__JSON__.No bank details')}}</p>
                    </b-card-text>
                </b-card>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { restaurantService } from '../../../services';
import {
  get_currency, replaceByDefault
} from "../../../Helper/helper";
export default {
  props: [ 'restaurant','ratings','dayWise','bank_info' ],
  data() {
      return {
          currency: get_currency(),
          replaceByDefault:replaceByDefault
      }
  },
  mounted(){
      $('.rating').rating(); 
  },
  watch:{
      ratings:function(old, newv){
      }
  },
  methods:{
  }
}
</script>

<style>

</style>