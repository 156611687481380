<script>
import config from "../../config";
import pagination from "laravel-vue-pagination";

export default {
  components: { pagination },
  data() {
    return {
      sortBy: "id",
      sortDesc: true,
      paginations: {},
      limit: 2,
      transactions: [
        {
          id: "1",
          name: "Entire World",
          vendor: "Chicken center",
          index: 1,
        },
        {
          id: "2",
          name: "Entire World",
          vendor: "The Food Zone",
          index: 2,
        },
        {
          id: "3",
          name: "Entire World",
          vendor: "Grocery Store",
          index: 3,
        },
        {
          id: "4",
          name: "Entire World",
          vendor: "The Flower Store",
          index: 4,
        },
        {
          id: "5",
          name: "Entire World",
          vendor: "The Liquor Store",
          index: 5,
        },
        {
          id: "6",
          name: "Entire World",
          vendor: "Chowman",
          index: 6,
        },
        {
          id: "7",
          name: "Entire World",
          vendor: "Rock N Roll Subs",
          index: 7,
        },
        {
          id: "8",
          name: "Entire World",
          vendor: "The Pizza Store",
          index: 8,
        },
        {
          id: "9",
          name: "Entire World",
          vendor: "Gwalia Sweets & Fast Food",
          index: 9,
        },
        {
          id: "10",
          name: "Entire World",
          vendor: "Deonde Store",
          index: 10,
        },
      ],
    };
  },
};
</script>

<template>
  <div>
    <div class="row list-table-main">
      <div class="col-12">
        <div class="row">
          <div class="col-12 table-main-list mt-3">
            <div class="table-responsive">
              <table class="table table-centered table-nowrap">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Area</th>
                    <th># Vendors</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="list in transactions" :key="list.id">
                    <td>{{ list.id }}</td>
                    <td>{{ list.name }}</td>
                    <td>{{ list.vendor }}</td>
                    <td>
                      <router-link v-if="$auth.hasPermission(config.permissions.delivery_zone_write)" :to="{ name: 'edit-area' }" class="btn btn-success btn-sm btn-rounded">
                        {{ trans.get('__JSON__.Edit') }}
                      </router-link>
                      <button v-if="$auth.hasPermission(config.permissions.delivery_zone_write)" type="submit" class="btn btn-danger btn-sm btn-rounded">
                        {{ trans.get('__JSON__.Delete') }}
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row col-sm-5 offset-sm-7">
            <div class="col">
              <div class="dataTables_paginate paging_simple_numbers float-right">
                <ul class="pagination pagination-rounded justify-content-end mb-2">
                  <li class="page-item disabled">
                    <a class="page-link" href="javascript: void(0);" aria-label="Previous">
                      <i class="mdi mdi-chevron-left"></i>
                    </a>
                  </li>
                  <li class="page-item active">
                    <a class="page-link" href="javascript: void(0);">1</a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="javascript: void(0);">2</a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="javascript: void(0);">3</a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="javascript: void(0);">4</a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="javascript: void(0);">5</a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="javascript: void(0);" aria-label="Next">
                      <i class="mdi mdi-chevron-right"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>