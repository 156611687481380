<script>
import config from "../../config";
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import pagination from "laravel-vue-pagination";
import { bannerService } from "../../services";
import { error_message } from "../../Helper/helper";
import bannerList from "./banner-list";
/**
 * Customers component
 */
export default {
  components: { Layout, PageHeader, pagination, bannerService, bannerList },
  data() {
    return {};
  },
};
</script>

<template>
  <Layout>
    <banner-list />
  </Layout>
</template>
