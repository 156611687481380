
// Cuisine services rest api calling

import { multipart_headers } from '../Helper/helper';
import { routes } from '../config/api_routes'

export const cuisineService = {
    // get cuisine
    // info: sortDesc : Boolean

    getCuisine: (formData) => {    
        return window.axios.post(routes.getCuisineApi,formData)
    },   
    
    // cuisine export to csv
    exportToCsv: (formData) => window.axios.post(routes.getCuisineListApi, formData),

    // cuisine add

    addCuisine: (formData) => {
        return window.axios.post(routes.addCuisineApi,formData,{headers: multipart_headers() });
    },

    // get cuisinebyID
    getCuisineById: (formData) => {
         return window.axios.post(routes.getCuisineByIdApi,formData)
    },

    // update cuisineByID

    updateCuisineById: (formData) => window.axios.post(routes.updateCuisineApi,formData,{headers: multipart_headers() }),

    // delete cuisine

    deleteCuisine: (formData) => {
        return window.axios.post(routes.deleteCuisineApi,formData)
    }
}