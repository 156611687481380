<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import moment from "moment";
import pagination from "laravel-vue-pagination";

import {
  get_partner_login,
  set_partner,
  set_partner_details,
  set_user,
  set_user_detail,
  debounce,
  get_currency,
} from "../../Helper/helper";
import { reportService } from "../../services";
/**
 * Report component
 */
export default {
  components: { Layout, PageHeader, pagination, moment },
  data() {
    return {
      customers: {},
      customerData: [],
      moment: moment,
      limit: 2,
      paginations: {},
      currency: get_currency(),
      sortBy: "created_at",
      sortDesc: true,
      search: "",
      loading: true,
      isDownloading: false,
      fields: [
        { key: "unique", label: "Customer ID", sortable: false },
        { key: "user_name", label: "Name", sortable: true },
        { key: "mobile_number", label: "Phone Number", sortable: true },
        { key: "email", label: "Email", sortable: true },
        { key: "referrer_type", label: "Referred By", sortable: false },
        { key: "referred_name", label: "Referrer", sortable: false },
        { key: "referral_count", label: "Referred Count", sortable: false },
        { key: "created_date", label: "Member Since", sortable: false },
        { key: "order_count", label: "# Of Orders", sortable: true },
        { key: "delivered_order", label: "# Of Delivered Orders", sortable: true },
        {
          key: "order_total_amount",
          label: "Total Order Value",
          sortable: true,
        },
        { key: "first_order_date", label: "First Order Date", sortable: false },
      ],
    };
  },
  mounted() {
    this.getCustomerReports();
    // this.customerExportCsv();
  },
  methods: {
    downloadsCSV: function () {
      let customerData =
        "\ufeff" +
        "Customer ID,Name,Phone Number,Email,Referred By,Referrer,Referred Count,Member Since,# Of Orders,# Of Delivered Orders,Total Order Value,First Order Date\n";
      this.customerData.forEach((el) => {
        var line = el["unique"] + "," + el["user_name"] +" " + el["last_name"] + "," + el["mobile_number"] + "," + el["email"] + "," + (el["referred_by"] ? "Customer" : el["referred_by_affiliate"] ? "Affiliate" : "-") + "," + (el["referred_name"] ? el["referred_name"] : "-") + "," + el["referral_count"] + "," + el["created_date"] + "," + el["order_count"] +  "," + el["delivered_order"] + "," + el["order_total_amount"].toFixed(2) + "," + el["first_order_date"] + "\n";
        customerData += line;
      });

      var blob = new Blob([customerData], { type: "csv/plain" });
      var date = new Date().toLocaleString();
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "customer_" + date + ".csv";
      link.click();
    },
    getCustomerReports(page) {
      this.loading = true;
      //  if (this.sortBy == "user_name") {
      //   this.sortDesc = false;
      // } else {
      //   this.sortDesc = true;
      // };
      reportService
        .getCustomerReport({
          search: this.search,
          sortBy: this.sortBy,
          orderBy: this.sortDesc ? "desc" : "asc",
          page: page ? page : 1,
        })
        .then((response) => {
          this.loading = false;
          // this.paginations = response.data.result;
          this.customers = response.data.result;
        });
    },
    customerExportCsv() {
      this.isDownloading = true;
      reportService
        .customerExportToCSV({
          search: this.search,
          sortBy: this.sortBy, 
          orderBy: this.sortDesc ? "desc" : "asc"
        })
        .then((response) => {
          this.isDownloading = false;
          this.customerData = response.data.result;
          this.downloadsCSV();
        });
    },
    searchData() {
      this.getCustomerReports();
      // this.customerExportCsv();
    },
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      this.getCustomerReports();
      this.customerExportCsv();
    },
  },
};
</script>

<template>
  <Layout>
    <div>
      <div class="row">
          <div class="col-12">
              <div
              class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
              >
              <div class="page-title-left avatar-main-icon">
                  <div class="avatar-xs mr-3">
                  <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                      <i class="bx bx-purchase-tag"></i>
                  </span>
                  </div>
                  <div class="report-tag">
                    <h4 class="mb-0 font-size-18">{{ trans.get('__JSON__.Customer Report') }}</h4>
                    <p><router-link :to="{ name: 'reports-list'}">{{ trans.get('__JSON__.Reports') }}</router-link> > {{ trans.get('__JSON__.Customer Report') }}</p>
                  </div>
              </div>
              <div class="page-title-right">
                 <button @click="customerExportCsv" class="btn btn-primary btn-rounded export-btn" v-if="isDownloading" disabled>
                {{ trans.get('__JSON__.Exporting...') }}</button>
                 <button @click="customerExportCsv" class="btn btn-primary btn-rounded export-btn" v-else>
                {{ trans.get('__JSON__.Export To CSV') }}</button>
                <button @click="customerExportCsv" class="btn btn-primary btn-rounded m-export-btn"><i class="bx bx-archive-in"></i></button>
              </div>
              </div>
          </div>
      </div>

      <!-- end row -->
      <div class="row">
        <div class="col-12 list-table-main">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-sm-4 offset-sm-8">
                  <div class="search-box mr-2 mb-0 d-block form-group">
                    <div class="date-range-list">
                      <label>{{trans.get('__JSON__.Search')}} :</label>
                      <div class="position-relative d-inline-block w-100">
                        <input
                          type="text"
                          class="form-control"
                          id="search"
                          @keyup="searchData()"
                          v-model="search"
                          :placeholder="trans.get('__JSON__.Search By First Name, Last Name')"
                        />
                        <i class="bx bx-search-alt search-icon"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 table-main-list customer-report-table table-responsive">
                  <b-table
                    :items="customers.data"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :no-local-sorting="true"
                    @sort-changed="sortingChanged"
                    :busy="loading"
                    show-empty
                  >
                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>{{trans.get('__JSON__.Loading...')}}</strong>
                      </div>
                    </template>

                    <template v-slot:cell(user_id)="data">
                      <div>{{ (customers.per_page * (customers.current_page-1)) + data.index + 1}}</div>
                    </template>

                    <template v-slot:cell(user_name)="data">
                      <h5 class="font-size-14">{{ data.item.user_name }} {{ data.item.last_name }}</h5>
                    </template>

                    <template v-slot:cell(referrer_type)="data">
                      {{ data.item.referred_by ? "Customer" : data.item.referred_by_affiliate ? "Affiliate" : "-"}}
                    </template>

                    <!-- <template v-slot:cell(created_at)="data">
                      {{ moment(data.item.created_at).format($auth.setting.date_format) }}
                    </template> -->

                    <template v-slot:cell(order_total_amount)="data">
                      <span v-html="currency"></span>
                      {{data.item.order_total_amount.toFixed(2) || '0.00' }}
                    </template>

                    <template #empty>
                      <p class="text-center">{{ trans.get('__JSON__.No Customer Report Found') }}</p>
                    </template>
                  </b-table>
                </div>
                <div class="row col-sm-5 offset-sm-7">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <pagination
                          :data="customers"
                          :limit="limit"
                          @pagination-change-page="getCustomerReports"
                        ></pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>


<style>
.customer-report-table .table thead th:nth-child(1),.customer-report-table .table thead th:nth-child(5){
    pointer-events: none;
}
</style>