<script>
import config from "../../config";
import { settingService, pageService,vendorService,deliveryZoneService } from "../../services";
import { error_message, success_message } from "../../Helper/helper";
import { required, email, sameAs, numeric } from "vuelidate/lib/validators";

export default {
    data() {
      return {
        config: config,
        error: "",
        restaurant_name: "",
        app_name: "",
        applogo: "",
        privacy_policy_url: "",
        terms_and_conditions: "",
        countries: [],
        states: [],
        cities: [],
        country_count:'',
        state_count:'',
        city_count:'',
        typeform: {
            name: "",
            phone: "",
            email: "",
            password: "",
            confirm_password: "",
            location_address: "",
            terms: "",
            country: "",
            state: "",
            city: "",
            vendor_id : this.$route.params.vendor_id
        },
      };
    },
    validations: {
        typeform: {
            name: {
                required,
            },
            phone: {
                required,
                numeric,
            },
            email: {
                required,
                email,
            },
            password: {
                required,
            },
            confirm_password: {
                required,
                sameAsPassword: sameAs("password"),
            },
            location_address: {
                required,
            },
            terms: {
                required,
            }
        },
    },
    mounted(){
      this.getVendorSettingById();
      this.getStaticPage();
      this.getVendorCountry();
      this.getVendorTerm();
    },
    methods: {
        getVendorSettingById(){
          settingService.getVendorSettingById({vendor_id : this.$route.params.vendor_id})
          .then(response => {
            
              this.restaurant_name = response.data.result.restaurant_name;
              this.app_name = response.data.result.app_name;
              this.applogo = response.data.result.app_logo;
          });
        },
        getStaticPage(){
          pageService.getStaticPage({
            vendor_id : this.$route.params.vendor_id
          })
            .then((response) => {
              this.privacy_policy_url = response.data.result.page_title;
            });
        },
        getVendorTerm(){
          pageService.getVendorTerm({
            vendor_id : this.$route.params.vendor_id
          })
            .then((response) => {
              this.terms_and_conditions = response.data.result.page_title;
            });
        },
        getVendorCountry() {
          this.typeform.state = '';
          this.typeform.city = '';
          this.states = [];
          this.cities = [];
          deliveryZoneService.getVendorsCountry({vendor_id: this.$route.params.vendor_id}).then((response) => {
            this.country_count = response.data.result.length;
            this.countries = response.data.result;
            if (this.country_count == 1) {
              this.typeform.country = this.countries[0].id;
              this.getVendorState();
            }
          });
        },
        getVendorState() {
          this.states = [];
          this.cities = [];
          this.city_count = 0;
          this.state_count = 0;
          this.typeform.city = '';
          if(!this.typeform.country) return false;
          deliveryZoneService.getVendorStates({country_id: this.typeform.country,vendor_id: this.$route.params.vendor_id}).then((response) => {
            this.state_count = response.data.result.length;
            this.states = response.data.result;
            if (this.state_count == 1) {
              this.typeform.state = this.states[0].id;
              this.getVendorCity();
            } else {
              // if (this.typeform.country == "") {
              //   this.getAllState();
              //   this.getAllCity();
              //   this.typeform.state = "";
              //   this.typeform.city = "";
              // }
              // if(this.typeform.state ==''){
              //   this.getVendorCity();
              //   this.typeform.state = "";
              //   this.typeform.city = "";
              // }
            }
          });
        },
      getVendorCity() {
          this.cities = [];
          this.city_count = 0;
          if(!this.typeform.state) return false;
          deliveryZoneService.getVendorsCity({state_id: this.typeform.state,vendor_id: this.$route.params.vendor_id}).then((response) => {
            this.city_count = response.data.result.length;
            this.cities = response.data.result;
            if (this.city_count == 1) {
              this.typeform.city = this.cities[0].cityid;
            } else{
              // if(this.typeform.state ==''){
              //   this.getAllCity();
              //   this.typeform.city = '';
              // }
            }
          });
        },
        getAllState() {
          deliveryZoneService.getAllState()
            .then((response) => {
            this.states = response.data.result;
            this.state_count = response.data.result.length;
          });
        },
        getAllCity() {
          deliveryZoneService.getAllCity().then((response) => {
            this.cities = response.data.result;
            this.city_count = response.data.result.length;
          });
        },
        vendorSignup() {
          this.$v.$touch();
          if (this.$v.typeform.$invalid) {
            return;
          } else {
            vendorService.vendorSignup(this.typeform).then((response) => {
                if (response.data.code === 200) {
                    success_message(response.data.message);
                    this.typeform = {
                    name: "",
                    email: "",
                    password: "",
                    phone: "",
                    confirm_password: "",
                    location_address: "",
                    terms: "not_accepted",
                    country: "",
                    state: "",
                    city: "",
                    vendor_id : this.$route.params.vendor_id
                    };
                    this.$v.typeform.$reset();
                } else {
                    error_message(response.data.message);
                }
            });
          }
        },
    },
};
</script>

<template>
  <div>
    <div class="account-pages my-5 pt-5">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5 login-main">
            <div class="card overflow-hidden">
              <div class="bg-soft-primary">
                <div class="row">
                  <div class="col-7">
                    <div class="text-primary p-4">
                      <h5 class="text-primary">{{ trans.get('__JSON__.Welcome Back to vendor',{ vendor: app_name})}}</h5>
                      <p>{{ trans.get('__JSON__.Sign up to start selling with')}} {{ app_name }}</p>
                    </div>
                  </div>
                  <div class="col-5 align-self-end">
                    <img :src="config.basepath+'profile-img.png'" alt class="img-fluid" />
                  </div>
                </div>
              </div>
              <div class="card-body pt-0">
                <div>
                  <div class="avatar-md profile-user-wid mb-4 avatar-logo-main">
                    <span class="avatar-title rounded-circle bg-light">
                      <img :src="config.applogo" alt />
                    </span>
                  </div>
                </div>

                <b-form class="p-2" @submit.prevent="vendorSignup">
                  <!-- <div class="alert alert-danger" v-show="this.error">{{ this.error }}</div> -->
                    <slot />

                    <b-form-group
                        id="input-group-1"
                        :label="trans.trans('__JSON__.Vendor Business Name',{ vendor: restaurant_name})"
                        label-for="input-1"
                    >
                    <b-form-input
                        id="input-1"
                        name="name"
                        ref="name"
                        v-model="$v.typeform.name.$model"
                        type="text"
                        :placeholder="this.trans.get('__JSON__.Enter Vendor Business Name',{ vendor: restaurant_name})"
                        :class="{ 'is-invalid':  $v.typeform.name.$error }"
                    ></b-form-input>
                    <div v-if="$v.typeform.name.$error" class="invalid-feedback">
                      <span
                        v-if="!$v.typeform.name.required"
                      >{{ trans.get('__JSON__.Please enter vendor business name',{ vendor: restaurant_name}) }}</span>
                    </div>
                  </b-form-group>

                  <b-form-group
                    id="input-group-1"
                    :label="this.trans.get('__JSON__.Email')"
                    label-for="input-1"
                  >
                    <b-form-input
                      id="input-1"
                      name="email"
                      ref="email"
                      v-model="$v.typeform.email.$model"
                      type="text"
                      :placeholder="this.trans.get('__JSON__.Enter email')"
                      :class="{ 'is-invalid':  $v.typeform.email.$error }"
                    ></b-form-input>
                    <div v-if="$v.typeform.email.$error" class="invalid-feedback">
                      <span
                        v-if="!$v.typeform.email.required"
                      >{{ trans.get('__JSON__.Please enter email') }}</span>
                      <span
                        v-if="!$v.typeform.email.email"
                      >{{ trans.get('__JSON__.This value should be a valid email') }}</span>
                    </div>
                  </b-form-group>

                  <b-form-group
                    id="input-group-1"
                    :label="this.trans.get('__JSON__.Contact Number')"
                    label-for="input-1"
                  >
                    <b-form-input
                      id="input-1"
                      maxlength="20"
                      minlength="6"
                      name="phone"
                      ref="phone"
                      v-model="$v.typeform.phone.$model"
                      type="text"
                      :placeholder="this.trans.get('__JSON__.Enter Contact Number')"
                      :class="{ 'is-invalid':  $v.typeform.phone.$error }"
                    ></b-form-input>
                    <div v-if="$v.typeform.phone.$error" class="invalid-feedback">
                      <span
                        v-if="!$v.typeform.phone.required"
                      >{{ trans.get('__JSON__.Please enter contact number') }}</span>
                      <span
                        v-if="!$v.typeform.phone.numeric"
                      >{{ trans.get('__JSON__.This value should be a valid number') }}</span>
                    </div>
                  </b-form-group>

                                    <b-form-group
                    id="input-group-1"
                    v-if="country_count >1"
                    :label="this.trans.get('__JSON__.Country')"
                    label-for="input-1"
                  >
                    <select
                      class="custom-select"
                      v-model="typeform.country"
                       @change="getVendorState"
                      id="country"
                      name="country"
                    >
                      <option value selected>{{ trans.get('__JSON__.Select Country') }}</option>
                      <option
                      v-for="con in countries"
                      :value="con.id"
                      :key="con.id"
                    >{{con.country_name}}</option>
                    </select>
                  </b-form-group>

                  <b-form-group
                    id="input-group-1"
                    v-if="(country_count == 1 && state_count > 1) || country_count > 1"
                    :label="this.trans.get('__JSON__.State')"
                    label-for="input-1"
                  >
                    <select
                      class="custom-select"
                      v-model="typeform.state"
                      @change="getVendorCity"
                      id="state"
                      name="state"
                    >
                      <option value selected>{{ trans.get('__JSON__.Select State') }}</option>
                      <option v-for="sat in states" :value="sat.id" :key="sat.id">{{sat.state_name}}</option>
                    </select>
                  </b-form-group>

                  <b-form-group
                    id="input-group-1"
                    v-if="(country_count == 1 && state_count == 1 && city_count > 1) || country_count > 1 || state_count > 1"
                    :label="this.trans.get('__JSON__.City')"
                    label-for="input-1"
                  >
                    <select
                      class="custom-select"
                      v-model="typeform.city"
                      id="city"
                      name="city"
                    >
                      <option value selected>{{ trans.get('__JSON__.Select City') }}</option>
                      <option
                      v-for="cit in cities"
                      :value="cit.cityid"
                      :key="cit.cityid"
                    >{{cit.cityname}}</option>
                    </select>
                  </b-form-group>

                  <b-form-group
                    id="input-group-1"
                    :label="this.trans.get('__JSON__.Address')"
                    label-for="input-1"
                  >
                    <b-form-input
                      id="input-1"
                      name="location_address"
                      v-model="$v.typeform.location_address.$model"
                      type="text"
                      :placeholder="this.trans.get('__JSON__.Enter Address')"
                      :class="{ 'is-invalid':  $v.typeform.location_address.$error }"
                    ></b-form-input>
                    <div v-if="$v.typeform.location_address.$error" class="invalid-feedback">
                      <span
                        v-if="!$v.typeform.location_address.required"
                      >{{ trans.get('__JSON__.Please enter address') }}</span>
                    </div>
                  </b-form-group>

                  <b-form-group
                    id="input-group-2"
                    :label="this.trans.get('__JSON__.Password')"
                    label-for="input-2"
                  >
                    <b-form-input
                      id="input-2"
                      v-model="$v.typeform.password.$model"
                      name="password"
                      type="password"
                      :placeholder="this.trans.get('__JSON__.Enter password')"
                      :class="{ 'is-invalid':  $v.typeform.password.$error  }"
                    ></b-form-input>
                    <div v-if="$v.typeform.password.$error" class="invalid-feedback">
                      <span
                        v-if="!$v.typeform.password.required"
                      >{{ trans.get('__JSON__.Please enter password')}}</span>
                    </div>
                  </b-form-group>

                  <b-form-group
                    id="input-group-2"
                    :label="this.trans.get('__JSON__.Confirm Password')"
                    label-for="input-2"
                  >
                    <b-form-input
                      id="input-2"
                      v-model="$v.typeform.confirm_password.$model"
                      name="confirm_password"
                      type="password"
                      :placeholder="this.trans.get('__JSON__.Re-enter password')"
                      :class="{ 'is-invalid':  $v.typeform.confirm_password.$error  }"
                    ></b-form-input>
                    <div v-if="$v.typeform.confirm_password.$error" class="invalid-feedback">
                      <span
                        v-if="!$v.typeform.confirm_password.required"
                      >{{ trans.get('__JSON__.Please re enter your password')}}</span>
                      <span
                        v-else-if="!$v.typeform.confirm_password.sameAsPassword"
                      >{{ trans.get('__JSON__.Passwords must match') }}</span>
                    </div>
                  </b-form-group>

                  <div>
                    <b-form-checkbox
                      id="terms"
                      v-model="$v.typeform.terms.$model"
                      name="terms"
                      value="accepted"
                      unchecked-value="not_accepted"
                      :class="{ 'is-invalid':  $v.typeform.terms.$error }"
                    >
                      By doing signup I Agree

                      <a :href="config.appurl+'page/'+app_name+'/'+terms_and_conditions.toLowerCase().replaceAll(' ','-')+'/'+this.$route.params.vendor_id" target="_blank"> Terms of Service </a> &
                      
                      <a :href="config.appurl+'page/'+app_name+'/'+privacy_policy_url.toLowerCase().replaceAll(' ','-')+'/'+this.$route.params.vendor_id" target="_blank"> Privacy policy </a> of {{ app_name }}.

                    </b-form-checkbox>
                    <div v-if="$v.typeform.terms.$error" class="invalid-feedback">
                      <span
                        v-if="!$v.typeform.terms.required"
                      >{{ trans.get('__JSON__.Please select the checkbox')}}</span>
                    </div>
                  </div>
                  <br />
                  <br />

                  <div>
                    <button
                      id="submit"
                      class="btn btn-primary btn-block"
                      @keyup.enter="vendorSignup"
                    >{{ trans.get('__JSON__.Signup') }}</button>
                  </div>
                </b-form>
              </div>
              <!-- end card-body -->
            </div>
            <!-- end card -->
          </div>
          <!-- end col -->
        </div>
      </div>
    </div>
  </div>
</template>

