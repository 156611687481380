<script>
  import Layout from "../../layouts/main";
  import PageHeader from "../../components/page-header";
  import pagination from "laravel-vue-pagination";
  import customerList from './customer-list';

  export default {
    components: { Layout, PageHeader, pagination, customerList},
    data() {
      return {};
    },
  };

</script>

<template>
  <Layout>
    <customer-list/>
  </Layout>
</template>
