<script>
import Layout from "../../layouts/main"; 
import PageHeader from "../../components/page-header";
import pagination from "laravel-vue-pagination";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import { reportService } from '../../services';
import { date_ranges, generate_csv, get_currency, get_decimal } from '../../Helper/helper';
import moment from 'moment';

export default{
  components: { Layout, PageHeader, pagination, DateRangePicker },
  data() {
    let startDate = this.$auth.partner_login ? this.moment().startOf('day').subtract(29, "days") : this.moment().startOf('day').subtract(6, "days");
    let endDate = this.moment().endOf("day").toDate();
    return {
      decimal:get_decimal(),
      currency: get_currency(),
      dateRange: { startDate, endDate },
      reports: [],
      reportsData: [],
      paginations: {},
      date_ranges: date_ranges,
      limit: 2,   
      perPage: 0,
      currentPage: 1,
      filters: {
        start_date: this.$auth.partner_login ? this.moment().startOf('day').subtract(29, "days").format('YYYY-MM-DD') : this.moment().startOf('day').subtract(6, "days").format('YYYY-MM-DD'),
        end_date: this.moment().format('YYYY-MM-DD'),
        status:'',
        type: ''
        
      },  
      sortBy: "created_at",
      moment: moment,
      loading: true,
      isDownloading: false,
      fields: [

        // { key: "recharge_id", label: "Coupon Discount", sortable: false },
        { key: "recharge_id", label: "#", sortable: false },        
        { key: "user_order_id", label: this.trans.get("__JSON__.Order Id"), sortable: false },        
        { key: "full_name", label: this.trans.get("__JSON__.Customer Name"), sortable: false, requiresAdmin: true },
        { key: "ip_address", label: "IP", sortable: false, requiresAdmin: true},
        { key: "recharge_amount", label: this.trans.get("__JSON__.Amount"), sortable: true,requiresAdmin: true},
        { key: "recharge_status", label: this.trans.get("__JSON__.Status"), sortable: true,requiresAdmin: true},
        { key: "transaction_id", label: this.trans.get("__JSON__.Transaction ID"), sortable: true,requiresAdmin: true},
        { key: "type", label: this.trans.get("__JSON__.Type"), sortable: true },
        { key: "updated_at", label: this.trans.get("__JSON__.Time"), sortable: true},
        // { key: "created_at", label: "Driver Name", sortable: false, requiresAdmin: true },
      ],
    };
  },
  mounted(){
    this.getWalletReport(1)
  },
  filters: {
    date(val) {
      return val ? val.toLocaleString() : "";
    },
  },
  methods:{
    updateValues(date) {
      this.filters = {
        ...this.filters,
        start_date :  this.moment(date.startDate.toISOString()).format('YYYY-MM-DD'),
        end_date :  this.moment(date.endDate.toISOString()).format('YYYY-MM-DD'),
      }
      // this.saleExportCsv();
      this.getWalletReport();
    },
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      //this.getSalesReports();
      //this.saleExportCsv();
    },
    getWalletReport(page) {
      this.loading = true;
     
      reportService
        .getWalletReport({
          filters: this.filters,
          sortBy: this.sortBy,
          orderBy: this.sortDesc ? "desc" : "asc",
          page: page ? page : 1,
        })
      .then((response) => {
        this.loading = false;
        this.paginations = response.data.result;
        this.reports = response.data.result.data;
      });
    },
    walletExportCsv(){
       reportService
        .getWalletReportExport({
          filters: this.filters,
          sortBy: this.sortBy,
          orderBy: this.sortDesc ? "desc" : "asc",
          // page: page ? page : 1,
        })
      .then((response) => {
        this.loading = false;
        this.reportsData = response.data.result;
        this.downloadCsv()
      });
    },
    downloadCsv(){
      let reportsData =
          '\ufeff' +
          `#,${this.trans.get("__JSON__.Order Id")},${this.trans.get("__JSON__.Customer Name")},IP,${this.trans.get("__JSON__.Amount")},${this.trans.get("__JSON__.Status")},${this.trans.get("__JSON__.Transaction ID")},${this.trans.get("__JSON__.Type")},${this.trans.get("__JSON__.Time")}\n`;
        this.reportsData.forEach((el,index) => {
          var line =
            (index+1)+','+
            (el["user_order_id"] || '-' )+','+
            el["full_name"]+','+
            (el["ip_address"] || '-')+','+
            (el["recharge_amount"] || 0)+','+
            (el["recharge_status"] || '-')+','+
            (el["transaction_id"] || '-')+','+
            el["type"]+','+
            this.moment(el["updated_at"]).format('lll')+
            "\n"
          reportsData += line;
        });
        var date = new Date().toLocaleString();
        generate_csv(reportsData,"wallet_report_" + date + ".csv");
    }
  }
 }
 </script>
 
<template>
<Layout>
    <div>
       <div class="row">
          <div class="col-12">
              <div
              class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
              >
              <div class="page-title-left avatar-main-icon">
                  <div class="avatar-xs mr-3">
                  <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                      <i class="bx bx-purchase-tag"></i>
                  </span>
                  </div>
                  <div class="report-tag" v-if="!$auth.partner_login">
                    <h4 class="mb-0 font-size-18">{{ trans.get('__JSON__.Wallet Report') }}</h4>
                    <p><router-link :to="{ name: 'reports-list'}">{{ trans.get('__JSON__.Reports') }}</router-link> > {{ trans.get('__JSON__.Wallet Report') }}</p>
                  </div>

                  <div class="report-tag" v-else>
                    <h4 class="mb-0 font-size-18">{{ trans.get('__JSON__.Wallet Report') }}</h4>
                    <p><router-link :to="{ name: 'vendor.reports.list'}">{{ trans.get('__JSON__.Reports') }}</router-link> > {{ trans.get('__JSON__.Wallet Report') }}</p>
                  </div>                
              </div>
              <div class="page-title-right">
                <button class="btn btn-primary btn-rounded export-btn" v-if="isDownloading" disabled>{{ trans.get('__JSON__.Exporting...') }}</button>
                <button @click="walletExportCsv" class="btn btn-primary btn-rounded export-btn" v-else>{{ trans.get('__JSON__.Export To CSV') }}</button>
                <button @click="walletExportCsv" class="btn btn-primary btn-rounded m-export-btn"><i class="bx bx-archive-in"></i></button>
              </div>
              </div>
          </div>
      </div>

      <!-- end row -->
      <div class="row">
        <div class="col-12 list-table-main">
          <div class="card">
            <div class="card-body">
              <div class="row" >
                <div class="main-sales-report">
                  <div class="v-sales-report">
                    <div class="form-group">
                      <div class="date-range-list">
                        <label>{{ trans.get('__JSON__.Type') }} :</label>
                        <select class="custom-select" v-model="filters.type" @change="getWalletReport">
                          <option value="">{{ trans.get('__JSON__.All') }}</option>
                          <option value="recharge" selected>{{ trans.get('__JSON__.Recharge') }}</option>
                          <option value="spent">{{ trans.get('__JSON__.Spent') }}</option>
                          <option value="refund">{{ trans.get('__JSON__.Refund') }}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="v-sales-report">
                    <div class="form-group">
                      <div class="date-range-list">
                        <label>{{ trans.get('__JSON__.Status') }} :</label>
                        <select class="custom-select" v-model="filters.status" @change="getWalletReport">
                          <option value="">{{ trans.get('__JSON__.All') }}</option>
                          <option value="success" selected>{{ trans.get('__JSON__.Success') }}</option>
                          <option value="pending">{{ trans.get('__JSON__.Failed') }}</option>
                        </select>
                      </div>
                    </div>                    
                  </div>
                  <div class="v-sales-report-date">
                    <div class="form-group">
                      <div class="date-range-list">
                        <label>{{trans.get('__JSON__.Date')}}:</label>
                        <date-range-picker
                          ref="picker"
                          :locale-data="{
                            firstDay: 1,
                            format: 'dd-mm-yyyy',
                          }"
                          :showDropdowns="true"
                          :showWeekNumbers="true"
                          opens="left"
                          v-model="dateRange"
                          @update="updateValues"
                        >
                          <template v-slot:input="picker" style="min-width: 350px">
                            {{ moment(picker.startDate.toISOString()).format('MMMM DD, YYYY') | date }} -
                            {{ moment(picker.endDate.toISOString()).format('MMMM DD, YYYY') | date }}
                          </template>
                        </date-range-picker>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              <div class="row table-responsive">
                <div class="col-12 table-main-list table table-centered table-nowrap sales-report-table">
                  <b-table
                    :items="reports"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :no-local-sorting="true"
                    @sort-changed="sortingChanged"
                    :busy="loading"
                    show-empty
                  >
                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>{{trans.get('__JSON__.Loading...')}}</strong>
                      </div>
                    </template>
                    <template v-slot:cell(recharge_id)="data">
                      <div>{{ data.index + 1 }}</div>
                    </template>
                    <template v-slot:cell(user_order_id)="data">
                      {{data.value && '#'+data.value || '-'}}
                    </template>
                    <template v-slot:cell(full_name)="data">
                      {{data.value || '-'}}
                    </template>
                    <template v-slot:cell(ip_address)="data">
                      {{data.value || '-'}}
                    </template>
                    <template v-slot:cell(recharge_amount)="data">
                      <span v-html="currency"></span> {{data.value || '0'}}
                    </template>
                    <template v-slot:cell(recharge_status)="data">
                      <span class="badge badge-pill font-size-12"
                        :class="{'badge-soft-success':(['success'].includes(data.value)),'badge-soft-danger':(data.value == 'pending')}"
                        >{{data.value.replace('pending','failed').toUpperCase()}}</span>  
                    </template>
                    <template v-slot:cell(transaction_id)="data">
                      {{data.value}}
                    </template>
                    <template v-slot:cell(type)="data">
                      <span class="badge badge-pill font-size-12"
                        :class="{'badge-soft-success':(['recharge','cashback','referral'].includes(data.value)),'badge-soft-danger':(data.value == 'spent'),'badge-soft-info':(data.value == 'refund')}"
                        >{{data.value.toUpperCase()}}</span>
                    </template>
                    <template v-slot:cell(updated_at)="data">
                      {{moment(data.value).format('lll')}}
                    </template>                    
                  </b-table>
                </div>
                <div class="row col-sm-5 offset-sm-7">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <pagination
                          :data="paginations"
                          :limit="limit"
                          @pagination-change-page="getWalletReport"
                        ></pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>    
</template>
 
 