<script>
import { required, requiredIf } from "vuelidate/lib/validators";
import { webLayoutService } from '../../../services';
import { success_message, error_message } from '../../../Helper/helper';
import config from '../../../config';
import { routes } from "../../../config/api_routes";
/**
 * Starter component
 */
export default {
  data() {
    return {
      config:config,
      isWebSiteUrlDisable: false,
      web: [],
      web: {
        client_website_url: '',
        button_color : '000',
        button_text : '',
        widget_key : '',
      },
      vendorId: this.$auth.getVendorId(),
      widget_code_snippet: "",
      widget_code: ""
    };
  },
  validations: {
    web: {
    }
  },
  mounted() {
    this.getWebSetting();
    this.widget_code_snippet = "&#x3C;!-- Start of "+window.appname+" widget script --&#x3E; <br> <span class='pln'> &#x3C;script type=&#x27;text/javascript&#x27; src=&#x27;"+this.config.appurl+"widget?key="+btoa(this.vendorId)+"&#x27;&#x3E;&#x3C;/script&#x3E; </span> <br> &#x3C;!-- End of "+window.appnname+" widget script --&#x3E;";
    this.widget_code = '&#x3C;!-- Start of '+window.appname+' widget script --&#x3E; &#x3C;script type="text/javascript" src="'+this.config.appurl+'widget?key='+btoa(this.vendorId)+'"&#x3E;&#x3C;/script&#x3E; &#x3C;!-- End of '+window.appnname+' widget script --&#x3E;';
  },
  methods: {
    decodeHtml: function (html) {
      var txt = document.createElement("textarea");
      txt.innerHTML = html;
      var val = txt.value;
      txt.remove();
      return val;
    },
    copyWidgetCode() {
      if (document.getElementById("copyicon").className == 'bx bx-copy') {
        document.getElementById("copyicon").className = 'bx bx-check';
      }
      let decoder = this.decodeHtml($('.widgetcopycode').html());
      let lth = decoder.replaceAll('&#x3C;', '<');
      let gth = lth.replaceAll('&#x3E;', '>');
      var $temp = $("<input>");
      $("body").append($temp);
      $temp.val(gth).select();
      document.execCommand("copy");
      $temp.remove();
      setTimeout(function() { 
        document.getElementById("copyicon").className = 'bx bx-copy';
      }, 1000);
    },
    getWebSetting(){
      webLayoutService.getWebSetting().then((response) => {
        const { 
          client_website_url,
          button_color,
          button_text,
          widget_key
        } = response.data.result;
        // this.web_logo_image = response.data.result.header_image;
        // this.web_logo_image_path = response.data.result.web_logo_image_path;
        this.web = {
          client_website_url: client_website_url || '',
          button_color: button_color || '',
          button_text: button_text || '',
          widget_key: widget_key || ''
        };
        if (this.web.client_website_url != '' && this.web.client_website_url != undefined && this.web.client_website_url != null) {
          this.isWebSiteUrlDisable = true;
        }
      })
    },
    generalIt(){
      this.$v.$touch();
      if (this.$v.web.$invalid) {
        return;
      } else {
        const fd = new FormData();
        fd.append("client_website_url", this.web.client_website_url);
        fd.append("button_color", this.web.button_color);
        fd.append("button_text", this.web.button_text);
        fd.append("widget_key", this.web.widget_key);
        fd.append('type',3);
        webLayoutService.updateWebSetting(fd).then((response) => {
          if (response.data.code === 200) {
            success_message(response.data.message);
          } else {
            error_message(response.data.message);
          }
        });
      }
    }
  }
};
</script>
<template>
  <b-form @submit.prevent="generalIt">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="control-label">{{ trans.get('__JSON__.Your website URL') }}</label>
                  <input id="client_website_url" name="client_website_url" type="text" class="form-control" v-model="web.client_website_url" placeholder="https://" :disabled="isWebSiteUrlDisable"/>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label class="control-label">{{ trans.get('__JSON__.Button Color') }}</label>
                  <input type="color" id="button_color" name="button_color" class="form-control" v-model="web.button_color" pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$" value="#000"> 
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label class="control-label">{{ trans.get('__JSON__.Button Text') }}</label>
                  <input id="button_text" name="button_text" type="text" class="form-control" v-model="web.button_text" placeholder="Enter Button Text"/>
                </div>
              </div>
            </div>

            <div class="row coderow">
              <label>Add to Ordering Widget to Website</label><br>
              <label class="smalltext">Copy the below code snippet and paste it into your HTML code, preferably before the closing head tag (&#x3C;/head&#x3E).</label>
              <div class="col-md-8 code-snipped">
                <code class="block whitespace-pre overflow-x-scroll copycode" v-html="widget_code_snippet" />
                <button class="copybtn" @click.prevent="copyWidgetCode()">
                  <i class="bx bx-copy" id="copyicon"></i>
                </button>
              </div>
              <div class="widgetcopycode" style="display: none !important;">
                {{widget_code}}
              </div>
            </div>
            
            <div class="btn-update">
              <div class="col-sm-6">
                <button
                  v-if="$auth.hasPermission(config.permissions.web_layout_write)"
                  type="submit"
                  class="btn btn-primary mr-1 mt-3"
                >{{ trans.get('__JSON__.Update') }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-form>
</template>
<style type="text/css">
  .coderow {
    display: inline-block;
  }
  .coderow label, .coderow h3 {
    margin-left: 15px;
  }
  .coderow .smalltext {
    font-size: 12px;
    font-weight: 200;
    max-width: 70%;
  }
  .code-snipped {
    margin: 0px 15px;
    border-radius: 8px;
    line-height: 22px;
    padding: 20px;
    background-color: #eee;
    min-height: 32px;
    overflow: auto;
  }
  .pln {
    color: rgba(0, 0, 0, 0.6);
  }
  .copybtn {
    display: inline-block;
    font-weight: 400;
    color: #495057;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.47rem 0.75rem;
    font-size: 0.8125rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    background-color: transparent;
    color: rgba(0,0,0,.54);
    font-size: 26px;
    line-height: 32px;
    pointer-events: all;
    position: absolute;
    bottom: 0px;
    right: 0px;
  }
  .copybtn:hover {
    color: #000000bd;
  }
  #copyicon.bx-copy, #copyicon.bx-check {
    /*animation:fade2 1s linear;*/
    animation: fadeIn linear 7s;
    -webkit-animation: fadeIn linear 7s;
    -moz-animation: fadeIn linear 7s;
    -o-animation: fadeIn linear 7s;
    -ms-animation: fadeIn linear 7s;
  }
</style>