<script>

import { success_message } from '../../Helper/helper'
import {
  required,
  email,
  minLength,
  sameAs,
  maxLength,
  minValue,
  maxValue,
  numeric,
  url,
  alphaNum,
} from "vuelidate/lib/validators";
import { faqService, settingService } from '../../services';

/**
 * FAQ component
 */
export default {
  data() {
    return {
      typeform: {
        question: "",
        answer: "",
        type: "customer",
        question_another_lang: "",
        answer_another_lang: "",
        status: "1"
      },
      faqimageurl: "",
      selectedimage:"",
      previewFaqIcon:"",
      faq_image:"",
    };
  },
  validations: {
    typeform: {
      question: {
        required,
      },
      answer: {
        required,
      },
    },
  },
  methods: {
   FaqInIt() {
      this.$v.$touch();

      if (this.$v.typeform.$invalid) {
        return;
      } else {
        const fd = new FormData();
        fd.append("question", this.typeform.question);
        fd.append("answer", this.typeform.answer);
        fd.append("question_another_lang", this.typeform.question_another_lang);
        fd.append("answer_another_lang",this.typeform.answer_another_lang);
        fd.append("type",this.typeform.type);
        fd.append("status", this.typeform.status);
        fd.append("faq_icon",this.previewFaqIcon)
        faqService.addFaq(fd).then((response) => {
          if (response.data.code === 200) {
            success_message(response.data.message);
            //this.$router.push({ name: "faq-list" });  
            // this.$router.go(0); 
            this.typeform.question = "";
            this.typeform.answer = "";
            this.typeform.question_another_lang = "";
            this.typeform.answer_another_lang = "";
            this.typeform.type = "";
            this.typeform.status = "";
            this.previewFaqIcon = ""; 
            this.selectedimage = ""; 
            this.faqimageurl = "";

            this.$v.$reset();

          } else {
            error_message(response.data.message);
          }
        });
      }
   },
   uploadImage(e) {
      this.faq_icon = "";
      console.log("target", e.target.files[0]);
      this.selectedimage = e.target.files[0];
      this.faq_image = e.target.files[0];
      this.faqimageurl = URL.createObjectURL(e.target.files[0]);
      this.faqIconformdata = new FormData();
      this.faqIconformdata.append("faq_icons", this.selectedimage);
      if (this.selectedimage) {
        console.log(this.faqIconformdata, "faqIconformdata");
        settingService
          .Uploadprofilemenuimageicon(this.faqIconformdata)
          .then((response) => {
            console.log("responsemenuimageupload", response.data.result);
            // this.updatemenuimage(response.data.result);
            this.previewFaqIcon = response.data.result;
          });
      }
      if (!this.selectedimage) {
        return;
      }
      e.target.value = null;
   }, 
   removeUploadImage() {
      if (this.previewFaqIcon) {
        let obj = {
          faq_icons: this.previewFaqIcon,
        };
        console.log("icon remove api here");
        settingService.Removeprofilemenuiconimage(obj).then((response) => {
          console.log("response", response.data);
        });
      }
      this.selectedimage = "";
      this.faq_image = "";
      this.faqimageurl = "";
      this.previewFaqIcon = "";
    },
  },
};
</script>

<template>
  <b-form @submit.prevent="FaqInIt">
    <!-- FAQ Info Start -->
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">{{ trans.get('__JSON__.FAQ Info') }}</h4>
        <p class="card-title-desc"></p>
        <div class="row">
          <div class="col-sm-6" v-if="$auth.setting">
            <b-form-group
              id="input-group-1"
              :label="trans.trans('__JSON__.Question Primary language', { lang: $auth.langauges[0].name })"
              label-for="question"
            >
              <b-form-input
                id="question"
                name="question"
                v-model="$v.typeform.question.$model"
                type="text"
                :placeholder="trans.trans('__JSON__.Question Primary language', { lang: $auth.langauges[0].name })"
                :class="{ 'is-invalid':  $v.typeform.question.$error }"
              ></b-form-input>
              <div v-if=" $v.typeform.question.$error" class="invalid-feedback">
                <span
                  v-if="!$v.typeform.question.required"
                >{{ trans.get('__JSON__.Please enter Question') }}.</span>
              </div>
            </b-form-group>
          </div>

          <div class="col-sm-6 d-none" v-if="$auth.langauges[1].name === 'No Secondary Language'">
          </div>
          <div class="col-sm-6" v-else>
            <b-form-group
              id="input-group-1"
              :label="trans.trans('__JSON__.Question Secondary Language', { lang: $auth.langauges[1].name })"
              label-for="question_another_lang"
            >
              <b-form-input
                id="question_another_lang"
                name="question_another_lang"
                v-model="typeform.question_another_lang"
                type="text"
                :placeholder="trans.trans('__JSON__.Question Secondary Language', { lang: $auth.langauges[1].name })"
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="col-sm-6">
            <div class="form-group">
              <label class="control-label">{{ trans.get('__JSON__.Type') }}</label>
              <select class="custom-select" v-model="typeform.type" id="type" name="type">
                <option value="customer">{{ trans.get('__JSON__.Customer') }}</option>
                <option value="store">{{ trans.trans('__JSON__.Vendors Faq',{ vendor: $auth.setting.restaurant_name }) }}</option>
                <option value="driver">{{ trans.get('__JSON__.Driver Faq',{ driver: $auth.setting.driver_name }) }}</option>
              </select>
            </div>
          </div>

          <div class="col-sm-6">
            <b-form-group
              id="input-group-1"
              :label="trans.trans('__JSON__.Answer Primary Language', { lang: $auth.langauges[0].name })"
              label-for="answer"
            >
              <b-form-textarea
                rows="5"
                id="answer"
                v-model="$v.typeform.answer.$model"
                type="text"
                class="form-control"
                :placeholder="trans.trans('__JSON__.Answer Primary Language', { lang: $auth.langauges[0].name })"
                name="answer"
                :class="{ 'is-invalid':  $v.typeform.answer.$error }"
              ></b-form-textarea>
               <div v-if=" $v.typeform.answer.$error" class="invalid-feedback">
                <span
                  v-if="!$v.typeform.answer.required"
                >{{ trans.get('__JSON__.Please enter Answer') }}.</span>
              </div>
            </b-form-group>
          </div>

          <div class="col-sm-6 d-none" v-if="$auth.langauges[1].name === 'No Secondary Language'">
          </div>
          <div class="col-sm-6" v-else>
            <b-form-group
              id="input-group-1"
              :label="trans.trans('__JSON__.Answer Secondary Language', { lang: $auth.langauges[1].name })"
              label-for="answer_another_lang"
            >
              <b-form-textarea
                rows="5"
                id="answer_another_lang"
                v-model="typeform.answer_another_lang"
                type="text"
                class="form-control"
                :placeholder="trans.trans('__JSON__.Answer Secondary Language', { lang: $auth.langauges[1].name })"
                name="answer_another_lang"
              ></b-form-textarea>
            </b-form-group>
          </div>

          <div class="col-sm-6">
            <div class="form-group">
              <label>
                FAQ Icon
              </label>
              <div class="d-flex">
              <label class="filelabel"
              :style="{
                        display:
                        faqimageurl ? 'none' : 'block',
                      }"
              >
              <i class="bx bx-plus plus" style="cursor: pointer;">
                <input
                  accept="image/jpeg,image/png,image/jpg,image/svg+xml"
                  class="FileUpload1"
                  id="faq_icon"
                  ref="faq_icon"
                  type="file"
                  @change="uploadImage"
                />
              </i>
            </label>
            </div>
            <div class="uploaded-img-main1">
              <img
                  v-if="
                    this.selectedimage.type == 'image/jpeg' ||
                    this.selectedimage.type == 'image/png' ||
                    this.selectedimage.type == 'image/jpg' ||
                    this.selectedimage.type == 'image/svg+xml'
                  "
                  :src="faqimageurl"
                  class="dis-img1"
                />
            <div v-if="selectedimage"
              class="prvie-img uploaded-img" style="position: absolute;
                top: 0; right: -5px;">
              <p class="remove-flg" @click="removeUploadImage">X</p>
            </div>
            </div>                
              </div>
            </div>

        </div>
         <div class="row">
            <div class="col-sm-6">
              <button class="btn btn-primary mr-1 mt-3">{{ trans.get('__JSON__.ADD FAQ') }}</button>
              <b-button @click="$router.back()" type="button" variant="secondary" class="mt-3">{{ trans.get('__JSON__.Cancel') }}</b-button>
            </div>
          </div>
      </div>
    </div>
    <!-- FAQ Info End -->
  </b-form>
  <!-- end row -->
</template>
