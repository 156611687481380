<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import pagination from "laravel-vue-pagination";
import { vendorService } from "../../services";
import { required, numeric, email } from "vuelidate/lib/validators";
import { error_message, success_message } from "../../Helper/helper";


export default {
  components: { Layout, PageHeader, pagination },
  data() {
    return {
      paginations: {},
      fundAccounts: [],
      bankList: [],
      sortBy: "created_at",
      sortDesc: true,
      loading: true,
      isDownloading: false,
      limit: 2,
      addModal: false,
      restaurants_id: this.$auth.partner_login ? this.$auth.user.restaurant.restaurant_id : 0,
      typeform: {
        restaurant_id: this.$auth.user.restaurant.restaurant_id,
        name: "",
        email: "",
        contact_number: "",
        account_number: "",
        ifsc: "",
        bank_name: "",
      },
      fields: [
        {
          key: "unique",
          label: this.trans.get("__JSON__.S. No."),
          sortable: false,
        },
        {
          key: "account_holder_name",
          label: this.trans.get("__JSON__.Account Holder Name"),
          sortable: false,
        },
        {
          key: "bank_named",
          label: this.trans.get("__JSON__.Bank Name"),
          sortable: false,
        },
        {
          key: "fund_account_id",
          label: this.trans.get("__JSON__.Fund Account Id"),
          sortable: false,
        },
        {
          key: "ifsc",
          label: this.trans.get("__JSON__.IFSC"),
          sortable: false,
        },
        {
          key: "last_digits",
          label: this.trans.get("__JSON__.Last Digits"),
          sortable: false,
        },
        {
          key: "status",
          label: this.trans.get("__JSON__.Account Status"),
          sortable: false,
        },
        {
          key: "action",
          label: this.trans.get("__JSON__.Action"),
          sortable: false
        },
      ]
    }
  },
  validations: {
    typeform: {
      name: {
        required,
      },
      email: {
        required,
        email,
      },
      contact_number: {
        required,
        numeric,
      },
      account_number: {
        required,
      },
      ifsc: {
        required,
      },
      bank_name: {
        required,
      }
    },
  },
  mounted() {
    this.getFundAccountList();
    this.getBankName();
  },
  methods: {
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      this.getFundAccountList();
    },
    getBankName() {
      vendorService.getBankNameList().then((response) => {
        this.bankList = response.data.result;
        console.log(response, "response");
      })
    },
    getFundAccountList() {
      vendorService.getrestaurantFundAccounts(this.restaurants_id).then((response) => {
        this.loading = false;
        this.fundAccounts = response.data.result;
        console.log(response, "response is", this.fundAccounts);
      })
    },
    discountInIt() {
      this.$v.$touch();
      if (this.$v.typeform.$invalid) {
        return;
      } else {
        let data = {
          restaurant_id: this.restaurants_id,
          name: this.typeform.name,
          email: this.typeform.email,
          contact_number: this.typeform.contact_number,
          account_number: this.typeform.account_number,
          ifsc: this.typeform.ifsc,
          bank_name: this.typeform.bank_name,
          vendor_id: this.$auth.getVendorId()
        };
        vendorService.cretaeRazorPayContact(data).then((response) => {
          this.$v.typeform.$reset();
          if (response.data.code === 200) {
            success_message(response.data.msg);
            this.typeform = {
              name: "",
              email: "",
              contact_number: "",
              account_number: "",
              ifsc: "",
              bank_name: "",
            };
            this.$bvModal.hide("addModal");
            this.getFundAccountList();
          } else {
            error_message(response.data.msg);
          }
        });
      }
    },
    makePrimaryAccount(fundAccountId, event) {
      this.loading = true;
      let data = {
        active: event.target.checked,
        vendor_id: this.$auth.getVendorId(),
        restaurant_id: this.restaurants_id,
        fund_id: fundAccountId
      };
      vendorService.fundAccountStatus(data).then((response) => {
        this.loading = false;
        if (response.data.code === 200) {
          success_message(response.data.msg);
        }
        else {
          error_message(response.data.msg);
        }
        this.getFundAccountList();
      })
    },
    deleteFundAccount(fundAccountId, status) {
      if (!status) {
        if (confirm("Do you really want to delete?")) {
          vendorService.fundAccountDelete(fundAccountId).then((response) => {
            this.loading = false;
            console.log(response, "response is", this.fundAccounts);
            this.getFundAccountList();
          })
        }
      } else {
        error_message("Please mark fund account inactive to delete the fund account");
      }
    }
  }
};
</script>

<template>
  <Layout>
    <div>
      <div class="row">
        <div class="col-12">
          <div class="page-title-box d-flex align-items-center justify-content-between icon-main-page">
            <div class="page-title-left">
              <div class="avatar-xs mr-3">
                <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                  <i class="bx bx-user-circle"></i>
                </span>
              </div>
              <h4 class="mb-0 font-size-18">{{ trans.get('__JSON__.Banks List') }}({{ fundAccounts && fundAccounts.length }})</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="row list-table-main">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-sm-2" style="margin-left: 90.333333% !important;">
                  <div class="form-group">
                    <div class="date-range-list">
                      <button type="button" class="btn btn-success btn-rounded mr-2" v-b-modal.addModal>
                        <i class="mdi mdi-plus mr-1"></i>
                        {{ trans.get('__JSON__.Add Bank') }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 table-main-list customer-table table-responsive">
                  <b-table :items="fundAccounts" :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                    :no-local-sorting="true" @sort-changed="sortingChanged" :busy="loading" show-empty>
                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>{{ trans.get('__JSON__.Loading...') }}</strong>
                      </div>
                    </template>

                    <template v-slot:cell(unique)="data">
                      <h5 class="font-size-14">{{ data.index + 1 }}</h5>
                    </template>

                    <template v-slot:cell(account_holder_name)="data">
                      <h5 class="font-size-14">{{ data.item.account_holder_name }}</h5>
                    </template>

                    <template v-slot:cell(bank_named)="data">
                      <h5 class="font-size-14">{{ data.item.bank_name }}</h5>
                    </template>

                    <template v-slot:cell(fund_account_id)="data">
                      <h5 class="font-size-14">{{ data.item.fund_account_id }}</h5>
                    </template>

                    <template v-slot:cell(ifsc)="data">
                      <h5 class="font-size-14">{{ data.item.ifsc }}</h5>
                    </template>

                    <template v-slot:cell(last_digits)="data">
                      <h5 class="font-size-14">{{ `*******${data.item.last_digits}` }}</h5>
                    </template>

                    <template v-slot:cell(status)="data">
                      <!-- <div v-if="data.item.status == true" class="badge badge-pill badge-soft-success font-size-12">{{
                        trans.get('__JSON__.Active') }}</div>
                      <div v-else class="badge badge-pill badge-soft-danger font-size-12">{{
                        trans.get('__JSON__.Inactive') }}</div> -->
                      <div class="make-defualt-switch">
                        <label class="switch">
                        <input type="checkbox" id="status" name="status" v-model="data.item.status" class="switch-on"
                          true-value="1" false-value="0"
                          @change="makePrimaryAccount(data.item.fund_account_id, $event)" />
                        <div class="slider round">
                          <span class="on">{{ trans.get('__JSON__.Active') }}</span>
                          <span class="off">{{ trans.get('__JSON__.Inactive') }}</span>
                        </div>
                      </label>
                      </div>                      
                    </template>

                    <template v-slot:cell(action)="data">
                      <!-- <button v-if="data.item.status != true" class="btn btn-primary btn-sm btn-rounded" @click="makePrimaryAccount(data.item.fund_account_id, true)">Make
                        Default</button>
                        <button v-else-if="data.item.status != false" class="btn btn-primary btn-sm btn-rounded" @click="makePrimaryAccount(data.item.fund_account_id, false)">Remove
                        Default</button> -->
                      <button class="btn btn-danger btn-sm btn-rounded"
                        @click="deleteFundAccount(data.item.fund_account_id, data.item.status)">Delete</button>
                    </template>

                    <template #empty>
                      <p class="text-center">{{ trans.get('__JSON__.Bank list not found') }}</p>
                    </template>
                  </b-table>
                </div>
                <!-- <div class="row col-sm-5 offset-sm-7">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <pagination
                        :data="paginations"
                        :limit="limit"
                        @pagination-change-page="getFundAccountList"
                      ></pagination>
                    </ul>
                  </div>
                </div>
              </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <b-modal id="addModal" v-model="addModal" scrollable :title="trans.get('__JSON__.Add Bank')"
        title-class="font-18">
        <b-form @submit.prevent="discountInIt">
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <label class="control-label">{{ trans.get('__JSON__.Account Holder Name') }}</label>
                <input id="name" name="name" v-model="$v.typeform.name.$model" type="text" @wheel="$event.target.blur()"
                  :placeholder="this.trans.get('__JSON__.Enter account holder name')" class="form-control"
                  :class="{ 'is-invalid': $v.typeform.name.$error }" />
                <div v-if="$v.typeform.name.$error" class="invalid-feedback">
                  <span v-if="!$v.typeform.name.required">{{ trans.get('__JSON__.Please enter account holder name')}}.</span>
                </div>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="form-group">
                <label class="control-label">{{ trans.get('__JSON__.Contact Number') }}</label>
                <input id="contact_number" name="contact_number" @wheel="$event.target.blur()"
                  v-model="$v.typeform.contact_number.$model" type="number"
                  :placeholder="this.trans.get('__JSON__.Enter contact number')" class="form-control"
                  :class="{ 'is-invalid': $v.typeform.contact_number.$error }" />
                <div v-if="$v.typeform.contact_number.$error" class="invalid-feedback">
                  <span v-if="!$v.typeform.contact_number.required">{{ trans.get('__JSON__.Please enter contact number')}}.</span>
                  <span v-if="!$v.typeform.contact_number.numeric">{{ trans.get('__JSON__.This value should be digits')}}.</span>
                </div>
              </div>

            </div>
            <div class="col-sm-12">
              <div class="form-group">
                <label class="control-label">{{ trans.get('__JSON__.Email') }}</label>
                <input id="email" name="email" type="text" @wheel="$event.target.blur()"
                  v-model="$v.typeform.email.$model" :placeholder="this.trans.get('__JSON__.Enter email')"
                  class="form-control" :class="{ 'is-invalid': $v.typeform.email.$error }" />
                <div v-if="$v.typeform.email.$error" class="invalid-feedback">
                  <span v-if="!$v.typeform.email.required">{{ trans.get('__JSON__.Please enter email') }}</span>
                  <span v-if="!$v.typeform.email.email">{{ trans.get('__JSON__.Enter a valid email') }}.</span>
                </div>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="form-group">
                <label class="control-label">{{ trans.get('__JSON__.Account Number') }}</label>
                <input rows="5" id="account_number" v-model="$v.typeform.account_number.$model" type="text"
                  class="form-control" :placeholder="this.trans.get('__JSON__.Enter account number')"
                  name="account_number" :class="{ 'is-invalid': $v.typeform.account_number.$error }"></input>
                <div v-if="$v.typeform.account_number.$error" class="invalid-feedback">
                  <span v-if="!$v.typeform.account_number.required">{{ trans.get('__JSON__.Please enter account number')
                    }}.</span>
                </div>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="form-group">
                <label class="control-label">{{ trans.trans('__JSON__.IFSC') }}</label>
                <input rows="5" id="ifsc" v-model="typeform.ifsc" type="text" class="form-control"
                  :placeholder="trans.trans('__JSON__.Enter IFSC code')" name="ifsc"
                  :class="{ 'is-invalid': $v.typeform.ifsc.$error }"></input>
                <div v-if="$v.typeform.ifsc.$error" class="invalid-feedback">
                  <span v-if="!$v.typeform.ifsc.required">{{ trans.get('__JSON__.Please enter ifsc code') }}.</span>
                </div>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="form-group">
                <label class="control-label">{{ trans.trans('__JSON__.Bank Name') }}</label>
                <select class="custom-select" v-model="typeform.bank_name"
                  :class="{ 'is-invalid': $v.typeform.bank_name.$error }">
                  <option value selected>
                    {{ trans.trans("__JSON__.Select bank name") }}
                  </option>
                  <option v-for="res in bankList" :value="res.bankname" :key="res.id">
                    {{ res.bankname }}
                  </option>
                </select>
                <div v-if="$v.typeform.bank_name.$error" class="invalid-feedback">
                  <span v-if="!$v.typeform.bank_name.required">{{ trans.get('__JSON__.Please select bank name')
                    }}.</span>
                </div>
              </div>
            </div>
          </div>
        </b-form>
        <template slot="modal-footer">
          <div class="w-100">
            <b-button variant="primary" @click="discountInIt" class="float-right">{{ trans.get('__JSON__.Add Bank')
              }}</b-button>
            <b-button class="float-left" @click="addModal = false">{{ trans.get('__JSON__.Close') }}</b-button>
          </div>
        </template>
      </b-modal>
    </div>
  </Layout>
</template>

<style>
.make-defualt-switch input.switch-on:checked + .slider:before {
    transform: translateX(47px) !important;
}
.make-defualt-switch .switch {
    width: 75px !important;
}
</style>