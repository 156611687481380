
import { routes } from '../config/api_routes'

export const masterDepoteCustomizationService = {
    //To get master depote item customization
    getDepoteItemCustmization: (formData) => {
        return window.axios.post(routes.getDepoteItemCustmizationApi, formData)
    },
    //To add master depote item customization
    addDepoteCustmization: (formData) => {
        return window.axios.post(routes.addDepoteCustmizationApi, formData)
    },
    //Get customize type
    getDepoteCustmizationType: (formData) => {
        return window.axios.post(routes.getDepoteCustmizationTypeApi, formData)
    },
    //Update customization type
    updateDepoteCustomizationType: (formData) => {
        return window.axios.post(routes.updateDepoteCustomizationTypeApi, formData)
    },
    //Delete customization type
    deleteDepoteCustmizationType: (formData) => {
        return window.axios.post(routes.deleteDepoteCustmizationTypeApi, formData)
    },
    //Save template
    saveDepoteTemplate: (formData) => {
        return window.axios.post(routes.saveDepoteTemplateApi, formData)
    },
    //Delete item customization
    deleteDepoteitemCustmization: (formData) => {
        return window.axios.post(routes.deleteDepoteItemCustmizationApi, formData)
    },
    //Search for template
    getDepoteTemplate: (formData) => {
        return window.axios.post(routes.getDepoteTemplateApi, formData)
    },
    //Add template
    addDepoteTemplate: (formData) => {
        return window.axios.post(routes.addDepoteTemplateApi, formData)
    },
    //To add menu selection
    addDepoteMenuSelection: (formData) => {
        return window.axios.post(routes.addDepoteMenuSelectionApi, formData)
    },
    //Add sub menu selection
    addDepoteSubMenuSelection: (formData) => {
        return window.axios.post(routes.addDepoteSubMenuSelectionApi, formData)
    },
    //Get sub cust
    getDepoteSubCustomization: (formData) => {
        return window.axios.post(routes.getDepoteSubCustomizationApi, formData)
    },
    //Min selection
    minDepoteSelection: (formData) => {
        return window.axios.post(routes.minDepoteSelectionApi, formData)
    },
    //Max selection
    maxDepoteSelection: (formData) => {
        return window.axios.post(routes.maxDepoteSelectionApi, formData)
    },
    //Delete sub item customization
    deleteDepoteSubitemCustmization: (formData) => {
        return window.axios.post(routes.deleteDepoteSubItemCustmizationApi, formData)
    },
}
