<script>
import { get_currency, get_decimal, order_statuses } from '../../../Helper/helper'

export default{
  props:['order','loading'],
  data() {
    return {
      statuses: order_statuses,
      currency: get_currency(),
      decimal: get_decimal(),
    }
  },
  methods:{
    btoa(str){
      return btoa(str);
    }
  }
};

</script>

<template>

   <!-- View Detail Main Start -->
  <div class="row mt-3 settings-main">
      <div class="col-12">
        <div class="text-center text-danger my-2" v-if="loading">
          <b-spinner class="align-middle"></b-spinner>
          <strong>{{trans.get('__JSON__.Loading...')}}</strong>
        </div>
      </div>
      <div class="col-md-12" v-if="!loading">
        <template v-if="order.suborder && order.suborder.id && order.suborder.id > 0">
          <div class="row">
            <div class="col-md-3" v-if="!$auth.partner_login">
              <h6>{{trans.get("__JSON__.Vendor's Name",{ vendor: $auth.getRestaurantName() })}}</h6>
              <p><b-link :to="{ name: 'vendor-detail', params:{restaurant_id: btoa(order.restaurant.restaurant_id) }}">{{ order.restaurant.name }}</b-link></p>
            </div>
            <div class="col-md-3" v-else>
              <h6>{{trans.get("__JSON__.Vendor's Name",{ vendor: $auth.getRestaurantName() })}}</h6>
              <p>{{ order.restaurant.name }}</p>
            </div>
            <div class="col-md-3" v-if="!$auth.partner_login">
              <h6>{{trans.get("__JSON__.Patient's Name")}}</h6>
              <p><b-link :to="{ name: 'customer-detail', params:{user_id: btoa(order.user.user_id) }}">{{ order.customer_name }}</b-link></p>
            </div>
            <div class="col-md-3" v-else>
              <h6>{{trans.get("__JSON__.Patient's Name")}}</h6>
              <p>{{ order.customer_name }}</p>
            </div>
            <div class="col-md-3" v-if="order.delivery_pickup_types == 'Delivery' && ![statuses.placed,statuses.cancelled,statuses.paymentpending,statuses.paymentfailure].includes(order.order_status)">
              <h6>Shipment {{order.suborder.is_depot_store == 0 ? '1 (Store)' : '2 (Depot)'}} {{trans.get('__JSON__.Driver Name',{ driver: $auth.getDriverName() })}}</h6>
              <p v-if="order.suborder.driver.length > 0"><b-link v-if="!$auth.partner_login" :to="{ name: 'driver-details', params:{driver_id: btoa(order.suborder.driver[0].driverid) }}"> {{ order.suborder.driver.length > 0 ? order.suborder.driver[0].firstname+' '+order.suborder.driver[0].lastname:'-' }}</b-link></p> <p v-else>-</p>
            </div>
            <div class="col-md-3" v-if="order.delivery_pickup_types == 'Delivery' && ![statuses.placed,statuses.cancelled,statuses.paymentpending,statuses.paymentfailure].includes(order.order_status)">
              <h6>Shipment {{order.is_depot_store == 0 ? '1 (Store)' : '2 (Depot)'}} {{trans.get('__JSON__.Driver Name',{ driver: $auth.getDriverName() })}}</h6>
              <p v-if="order.driver.length > 0"><b-link v-if="!$auth.partner_login" :to="{ name: 'driver-details', params:{driver_id: btoa(order.driver[0].driverid) }}"> {{ order.driver.length > 0 ? order.driver[0].firstname+' '+order.driver[0].lastname:'-' }}</b-link></p> 
              <p v-else-if="order.task_id && order.deliveryPartner_order && order.delivery_partners">{{ order.deliveryPartner_order.runner_name ? order.delivery_partners.prefix + '- '+ order.deliveryPartner_order.runner_name : '-'}}</p>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="row">
            <div class="col-md-4" v-if="!$auth.partner_login">
              <h6>{{trans.get("__JSON__.Vendor's Name",{ vendor: $auth.getRestaurantName() })}}</h6>
              <p><b-link :to="{ name: 'vendor-detail', params:{restaurant_id: btoa(order.restaurant.restaurant_id) }}">{{ order.restaurant.name }}</b-link></p>
            </div>
            <div class="col-md-4" v-else>
              <h6>{{trans.get("__JSON__.Vendor's Name",{ vendor: $auth.getRestaurantName() })}}</h6>
              <p>{{ order.restaurant.name }}</p>
            </div>
            <div class="col-md-4">
              <h6>{{trans.get("__JSON__.Patient's Name")}}</h6>
              <p>{{ order.customer_name }}</p>
            </div>
            <div class="col-md-4" v-if="order.delivery_pickup_types == 'Delivery' && ![statuses.placed,statuses.cancelled,statuses.paymentpending,statuses.paymentfailure].includes(order.order_status)">
              <h6>{{trans.get('__JSON__.Driver Name',{ driver: $auth.getDriverName() })}}</h6>
              <p v-if="order.driver.length > 0"><b-link v-if="!$auth.partner_login" :to="{ name: 'driver-details', params:{driver_id: btoa(order.driver[0].driverid) }}"> {{ order.driver.length > 0 ? order.driver[0].firstname+' '+order.driver[0].lastname:'-' }}</b-link></p>
              <p v-else-if="order.driver.length > 0 && $auth.partner_login">{{ order.driver.length > 0 ? order.driver[0].firstname+' '+order.driver[0].lastname:'-' }}</p>
              <p v-else-if="order.task_id && order.deliveryPartner_order && order.delivery_partners">{{ order.deliveryPartner_order.runner_name ? order.delivery_partners.prefix + '- '+ order.deliveryPartner_order.runner_name : '-'}}</p>
            </div>
          </div>
        </template>
      </div>

      <div class="col-md-12" v-if="!loading">
        <template v-if="order.suborder && order.suborder.id && order.suborder.id > 0">
          <div class="row sections-main">
            <div class="col-md-3">
              <h6>{{trans.get("__JSON__.Merchant Phone",{vendor: $auth.getRestaurantName()})}}</h6>
              <p>{{ order.restaurant.phone }}</p>
            </div>
            <div class="col-md-3" v-if="$auth.partner_login && $auth.setting.show_customer_to_vendor == 'Yes'">
              <h6>{{trans.get("__JSON__.Patient's Phone")}}</h6>
              <p>{{ order.user.country_code+' '+order.customer_mobile_number }}</p>
            </div>
            <div class="col-md-3" v-if="$auth.partner_login && $auth.setting.show_customer_to_vendor == 'No'">
              &nbsp;
            </div>
            <div class="col-md-3" v-if="!$auth.partner_login">
              <h6>{{trans.get("__JSON__.Patient's Phone")}}</h6>
              <p>{{ order.user.country_code+' '+order.customer_mobile_number }}</p>
            </div>
            <div class="col-md-3" v-if="order.delivery_pickup_types == 'Delivery' && ![statuses.placed,statuses.cancelled,statuses.paymentpending,statuses.paymentfailure].includes(order.order_status)">
              <h6>Shipment {{order.suborder.is_depot_store == 0 ? '1 (Store)' : '2 (Depot)'}} {{trans.get('__JSON__.Driver Phone',{ driver: $auth.getDriverName() })}}</h6>
              <p>{{ order.suborder.driver.length > 0 ? order.suborder.driver[0].country_code+' '+order.suborder.driver[0].contactno:'-' }}</p>
            </div>
            <div class="col-md-3" v-if="order.delivery_pickup_types == 'Delivery' && ![statuses.placed,statuses.cancelled,statuses.paymentpending,statuses.paymentfailure].includes(order.order_status)">
              <h6>Shipment {{order.is_depot_store == 0 ? '1 (Store)' : '2 (Depot)'}} {{trans.get('__JSON__.Driver Phone',{ driver: $auth.getDriverName() })}}</h6>
              <p v-if="order.driver.length > 0">{{order.driver[0].country_code+' '+order.driver[0].contactno}}</p>
              <p v-else>{{order.deliveryPartner_order && order.deliveryPartner_order.runner_contact_number ? '+91'+' '+order.deliveryPartner_order.runner_contact_number : '-'}}</p>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="row">
            <div class="col-md-4">
              <h6>{{trans.get("__JSON__.Merchant Phone",{vendor: $auth.getRestaurantName()})}}</h6>
              <p>{{ order.restaurant.phone }}</p>
            </div>
            <div class="col-md-4" v-if="$auth.partner_login && $auth.setting.show_customer_to_vendor == 'Yes'">
              <h6>{{trans.get("__JSON__.Patient's Phone")}}</h6>
              <p>{{ order.user.country_code+' '+order.customer_mobile_number }}</p>
            </div>
            <div class="col-md-4" v-if="$auth.partner_login && $auth.setting.show_customer_to_vendor == 'No'">
              &nbsp;
            </div>
            <div class="col-md-4" v-if="!$auth.partner_login">
              <h6>{{trans.get("__JSON__.Patient's Phone")}}</h6>
              <p>{{ order.user.country_code+' '+order.customer_mobile_number }}</p>
            </div>
            <div class="col-md-4" v-if="order.delivery_pickup_types == 'Delivery' && ![statuses.placed,statuses.cancelled,statuses.paymentpending,statuses.paymentfailure].includes(order.order_status)">
              <h6>{{trans.get('__JSON__.Driver Phone',{ driver: $auth.getDriverName() })}}</h6>
              <p v-if="order.driver.length > 0">{{order.driver[0].country_code+' '+order.driver[0].contactno}}</p>
              <p v-else>{{order.deliveryPartner_order && order.deliveryPartner_order.runner_contact_number ? '+91'+' '+order.deliveryPartner_order.runner_contact_number : '-'}}</p>            
            </div>
          </div>
        </template>
      </div>

      <div class="col-md-12" v-if="!loading">
        <template>
          <div class="row sections-main">
            <div class="col-md-4" v-if="!$auth.partner_login">
              <h6>{{trans.get("__JSON__.Doctor's name")}}</h6>
              <p>{{ order.doctor_name ? order.doctor_name : '-'}}</p>
            </div>
            <div class="col-md-4" v-else>
              <h6>{{trans.get("__JSON__.Doctor's name")}}</h6>
              <p>{{ order.doctor_name ? order.doctor_name : '-' }}</p>
            </div>
            <div class="col-md-4" v-if="!$auth.partner_login">
              <h6>{{trans.get("__JSON__.Account Holder's Name")}}</h6>
              <p><b-link :to="{ name: 'customer-detail', params:{user_id: btoa(order.user.user_id) }}">{{ order.user.user_name + ' ' + order.user.last_name}}</b-link></p>
            </div>
            <div class="col-md-4" v-if="!$auth.partner_login">
              <h6>{{trans.get("__JSON__.Account Holder's Phone")}}</h6>
              <p v-if="order.user.mobile_number == '9999999999'">-</p>
              <p v-else>{{ order.user.country_code+' '+order.user.mobile_number}}</p>
            </div>
          </div>
        </template>
      </div>

      <div class="col-md-12 mt-3" v-if="!loading">
        <div class="row sections-main">
          <div class="col-md-4">
            <h6>{{trans.get('__JSON__.Pickup Location')}} <i class="bx bx-map bx-border"></i></h6>
            <p>{{order.restaurant.address}}</p>
          </div>
          <div class="col-md-4">
            <h6>{{trans.get('__JSON__.Drop Location')}} <i class="bx bx-map bx-border"></i></h6>
            <!-- <p><span class="font-weight-bold">{{ trans.get('__JSON__.Area') }}</span>: {{order.area}}</p> -->
            <p><span class="font-weight-bold">{{ trans.get('__JSON__.Address') }}</span>: {{ order.area }}, {{order.shipping_address}}</p>
            <p><span class="font-weight-bold">{{ trans.get('__JSON__.Locality') }}</span>: {{order.locality}}</p>
          </div>
          <div class="col-md-4">
            <h6>{{trans.get('__JSON__.Payment Details')}}</h6>
            <p>
              <template v-if="order.wallet_amount == 0">
                <span class="text-success" v-html="currency"></span> <span  class="text-success">{{ (order.total_amount || 0).toFixed(decimal) }}/{{order.payment_method}}</span>
              </template>
              <template v-else>
                <span class="text-success" v-html="currency"></span> <span  class="text-success">{{ parseFloat(order.amount==0?order.wallet_amount:order.amount || 0).toFixed(decimal) }}/{{order.payment_method}}</span>
              </template>
              <!-- <template>
                <span class="text-success" v-html="currency"></span> <span  class="text-success">{{ (order.total_amount - order.wallet_amount || 0).toFixed(decimal) }}/{{order.payment_method}}</span>
              </template>
              <template>
                <span class="text-success" v-html="currency"></span> <span  class="text-success">{{ (order.total_amount - order.balance_amount || 0).toFixed(decimal) }}/{{order.payment_method}}</span>
              </template> -->
              <template v-if="order.wallet_amount > 0 && order.amount > 0">
                <!-- <template v-if="order.wallet_amount > 0"> -->
                <br>
                <span class="text-success" v-html="currency"></span> <span  class="text-success">{{ (parseFloat(order.wallet_amount || 0)).toFixed(decimal) }}/{{trans.get('__JSON__.Wallet')}}</span>
              </template>
              <template v-if="order.reddemed_coupon_order">
                <br>
                <span  class="text-danger" v-html="currency"></span> 
                <span class="text-danger" v-if="order.reddemed_coupon_order.coupon && (order.reddemed_coupon_order.coupon.promo_code_type == 1 || order.reddemed_coupon_order.coupon.promo_code_type == 2)">
                  {{ ((order.reddemed_coupon_order.original_price - order.reddemed_coupon_order.discount_price) || 0).toFixed(decimal) }}/{{trans.get('__JSON__.Coupon Discount')}}
                </span>
                <span class="text-danger" v-if="order.reddemed_coupon_order.coupon && (order.reddemed_coupon_order.coupon.promo_code_type == 3 || order.reddemed_coupon_order.coupon.promo_code_type == 4)">
                  {{ ((order.reddemed_coupon_order.cashback) || 0).toFixed(decimal) }}/{{trans.get('__JSON__.Coupon Cashback')}}
                </span>
              </template>
            </p>
            <p v-if="order.payment_method != 'Cash On Delivery' && order.transaction_id != null && $auth.setting.payment_gateway != 'kina'">
              <span class="font-weight-bold">{{ trans.get('__JSON__.Transaction ID') }}</span><br>
              {{ order.transaction_id }}
            </p>
            <p v-if="order.payment_method != 'Cash On Delivery' && order.transaction_id != null && $auth.setting.payment_gateway == 'kina'">
              <span class="font-weight-bold">{{ trans.get('__JSON__.Transaction ID') }}</span><br>
              {{ order.transaction_id }}
              <br v-if="order.kina_reference_number != ''">
              <br v-if="order.kina_reference_number != ''">
              <span class="font-weight-bold" v-if="order.kina_reference_number != ''">{{ trans.get('__JSON__.Reference Number') }}</span><br v-if="order.kina_reference_number != ''">
              <span v-if="order.kina_reference_number != ''"> {{ order.kina_reference_number }}</span>
              <br v-if="order.kina_approval_code != ''">
              <br v-if="order.kina_approval_code != ''">
              <span class="font-weight-bold" v-if="order.kina_approval_code != ''">{{ trans.get('__JSON__.Approval Code') }}</span><br v-if="order.kina_approval_code != ''">
              <span v-if="order.kina_approval_code != ''"> {{ order.kina_approval_code }}</span>
            </p>
          </div>
        </div>
      </div>

      <div class="col-md-12 mt-3" v-if="!loading">
        <template v-if="order.suborder && order.suborder.id && order.suborder.id > 0 && ![statuses.placed,statuses.cancelled,statuses.paymentpending,statuses.paymentfailure].includes(order.order_status)">
          <div class="row mt-3">
            <div class="col-md-12">
              <h6>Shipment {{order.suborder.is_depot_store == 0 ? '1 (Store)' : '2 (Depot)'}}</h6>
            </div>
          </div>
          <div class="row sections-main">
            <div class="col-md-4" v-if="order.suborder.created_date != '0000-00-00 00:00:00'">
              <h6>{{trans.get('__JSON__.Placement time')}}</h6>
              <p v-if="order.suborder.created_date != '0000-00-00 00:00:00'">{{ order.suborder.created_date }}</p>
            </div>
            <div class="col-md-4" v-if="order.suborder.future_delivery_date != '0000-00-00 00:00:00' && order.suborder.future_delivery_date != null">
              <h6>{{trans.get('__JSON__.Schedule Time')}}</h6>
              <p>{{ order.suborder.future_delivery_date }}</p>
            </div>
            <div class="col-md-4" v-if="order.suborder.confirm_at != '0000-00-00 00:00:00'">
              <h6 >{{trans.get('__JSON__.Accepted Time')}}</h6>
              <p v-if="order.suborder.confirm_at != '0000-00-00 00:00:00'">{{ order.suborder.confirm_at }}</p>
            </div>
            <div class="col-md-4" v-if="order.suborder.readytoserve_date != '0000-00-00 00:00:00'">
              <h6>{{trans.get('__JSON__.Ready To Serve time')}}</h6>
              <p v-if="order.suborder.readytoserve_date != '0000-00-00 00:00:00'">{{ order.suborder.readytoserve_at }}</p>
            </div>
            <div class="col-md-4" v-if="order.suborder.ontheway_date != '0000-00-00 00:00:00'"> 
              <h6>{{trans.get('__JSON__.Picked up Time')}}</h6>
              <p>{{ order.suborder.ontheway_at }}</p>
            </div>
            <div class="col-md-4" v-if="order.suborder.delivered_date != '0000-00-00 00:00:00'">
              <h6>{{trans.get('__JSON__.Delivery time')}}</h6>
              <p>{{ order.suborder.delivered_at }}</p>
            </div>
            <div class="col-md-4" v-if="order.suborder.order_status == 'Cancelled'">
              <h6>{{trans.get('__JSON__.Cancelled time')}}</h6>
              <p>{{ order.suborder.cancelled_date }}</p>
            </div>
            <div class="col-md-4" v-if="order.suborder.order_status == 'Cancelled'">
              <h6>{{trans.get('__JSON__.Cancel Description')}}</h6>
              <p>{{ order.suborder.cancelled_desc }}</p>
            </div>
          </div>
        </template>
        <div class="row" v-if="order.suborder && order.suborder.id && order.suborder.id > 0 && ![statuses.placed,statuses.cancelled,statuses.paymentpending,statuses.paymentfailure].includes(order.order_status)">
          <div class="col-md-12 mt-3">
            <h6>Shipment {{order.is_depot_store == 0 ? '1 (Store)' : '2 (Depot)'}}</h6>
          </div>
        </div>
        <div class="row sections-main">
          <div class="col-md-4" v-if="order.created_date != '0000-00-00 00:00:00'">
            <h6>{{trans.get('__JSON__.Placement time')}}</h6>
            <p v-if="order.created_date != '0000-00-00 00:00:00'">{{ order.created_date }}</p>
          </div>
          <div class="col-md-4" v-if="order.future_delivery_date != '0000-00-00 00:00:00' && order.future_delivery_date != null">
            <h6>{{trans.get('__JSON__.Schedule Time')}}</h6>
            <p>{{ order.future_delivery_date }}</p>
          </div>
          <div class="col-md-4" v-if="order.confirm_date != '0000-00-00 00:00:00'">
            <h6 >{{trans.get('__JSON__.Accepted Time')}}</h6>
            <p v-if="order.confirm_date != '0000-00-00 00:00:00'">{{ order.confirm_at }}</p>
          </div>
          <div class="col-md-4" v-if="order.readytoserve_date != '0000-00-00 00:00:00'">
            <h6>{{trans.get('__JSON__.Ready To Serve time')}}</h6>
            <p v-if="order.readytoserve_date != '0000-00-00 00:00:00'">{{ order.readytoserve_at }}</p>
          </div>
          <div class="col-md-4" v-if="order.ontheway_date != '0000-00-00 00:00:00'"> 
            <h6>{{trans.get('__JSON__.Picked up Time')}}</h6>
            <p>{{ order.ontheway_at }}</p>
          </div>
          <div class="col-md-4" v-if="order.delivered_date != '0000-00-00 00:00:00'">
            <h6>{{trans.get('__JSON__.Delivery time')}}</h6>
            <p>{{ order.delivered_at }}</p>
          </div>
          <div class="col-md-4" v-if="order.order_status == 'Cancelled'">
            <h6>{{trans.get('__JSON__.Cancelled time')}}</h6>
            <p>{{ order.cancelled_date }}</p>
              <!-- <span v-b-popover.hover.top="order.cancelled_desc" :title="trans.get('__JSON__.Cancel Description')" class="font-size-15"><i class="bx bx-info-circle"></i></span> -->
          </div>
          <div class="col-md-4" v-if="order.order_status == 'Cancelled'">
            <h6>{{trans.get('__JSON__.Cancel Description')}}</h6>
            <p>{{ order.cancelled_desc }}</p>
          </div>
        </div>
        
      </div>

      <div class="col-md-12 mt-3" v-if="!loading">
        <div class="row sections-main" v-if="order.order_status == 'Delivered'">
          <div class="col-md-4" v-if="order.turnaround_time != '0000-00-00 00:00:00' && order.turnaround_time != '' && order.turnaround_time != null">
            <h6>{{trans.get('__JSON__.Turn-Around time')}}</h6>
            <p>{{ order.turnaround_time }}</p>
          </div>
          <div class="col-md-4" v-if="order.delivered_time != '0000-00-00 00:00:00' && order.delivered_time != '' && order.delivered_time != null">
            <h6>{{trans.get('__JSON__.Delivered time')}}</h6>
            <p>{{ order.delivered_time }}</p>
          </div>
        </div>
      </div>

      <!-- <div class="col-md-12" v-if="!loading">
        <div class="row sections-main">
        </div>
      </div> -->

      <div class="col-md-12 mt-3" v-if="!loading">
        <div class="row sections-main">
          <div class="col-md-4" v-if="order.distance_km && order.distance_km != ''">
            <h6>{{trans.get('__JSON__.Distance In')}} {{$auth.getVendorDistanceMetric()}}</h6>
            <p>{{ order.distance_km }}</p>
          </div>
          <div class="col-md-4" v-if="order.distance_mintue && order.distance_mintue != ''">
            <h6>{{trans.get('__JSON__.Distance In Minute')}}</h6>
            <p>{{ order.distance_mintue }}</p>
          </div>
          <div class="col-md-4" v-if="!$auth.partner_login">
            <h6>{{trans.get('__JSON__.Last Mile Earning')}}</h6>
            <p><span v-html="currency"></span> {{ parseFloat(order.driver_earning || 0).toFixed(decimal) }}</p>
          </div>
          <div class="col-md-4" v-if="!$auth.partner_login">
            <h6>{{trans.get('__JSON__.Admin Earning')}}</h6>
            <p><span v-html="currency"></span> {{ parseFloat(order.admin_commision || 0).toFixed(decimal) }}</p>
          </div>
          <div class="col-md-4" v-if="!$auth.partner_login">
            <h6>{{trans.get('__JSON__.Delivery Charge')}}</h6>
            <p><span v-html="currency"></span> {{ parseFloat(order.delivery_fee || 0).toFixed(decimal) }}</p>
          </div>
          <div class="col-md-4">
            <h6>{{trans.get('__JSON__.Notes')}}</h6>
            <p>{{ order.notes }}</p>
          </div>
        </div>
      </div>

      <div class="col-md-12 mt-3" v-if="!loading && !$auth.partner_login">
        <div class="row sections-main">
          <div class="col-md-4">
            <h6>{{trans.get('__JSON__.Device Type')}}</h6>
            <p v-if="order.device_type == 'Web'">{{ order.device_type }}</p>
            <p v-else>{{ order.device_type + ' ' + order.app_version_code }}</p>
          </div>
        </div>
      </div>

      <!-- <div class="order-version">
        <p class="mb-0">{{ order.device_type + ' ' + order.app_version_code }}</p>
      </div> -->

    <!-- View Detail Main End -->

  </div>
</template>
