<template>
  <div style="display: flex; align-items: center">
    <button
      style="margin-right: 4px"
      v-if="
        $auth.hasPermission(config.permissions.order_write) &&
        order.order_status == statuses.placed
      "
      class="btn accept-btn"
      v-b-tooltip.hover.v-light
      :title="trans.get('__JSON__.Confirm Order')"
      @click="changeStatus(order.base_id, statuses.inkitchen)"
      :disabled="isAcceptbtnDisabled"
    >
      <i class="fas fa-check"></i>
    </button>
    <button
      style="margin-right: 4px"
      v-if="
        $auth.hasPermission(config.permissions.order_write) &&
        [
          statuses.placed,
          statuses.inkitchen,
          statuses.readytoserve,
          statuses.scheduled,
        ].includes(order.order_status)
      "
      class="btn reject-btn"
      v-b-tooltip.hover.v-light
      :title="trans.get('__JSON__.Cancel Order')"
      @click="cancelOrderPopup(order.base_id, statuses.cancelled)"
    >
      <i class="fas fa-times"></i>
      <!-- [statuses.placed,statuses.inkitchen,statuses.readytoserve,statuses.ontheway,statuses.scheduled].includes(order.order_status) -->
    </button>
    <b-dropdown
      lazy
      id="dropdown-1"
      variant="link"
      right
      role="navigation"
      class="order-option-dropdown"
      v-if="$auth.hasPermission(config.permissions.order_write)"
    >
      <template #button-content>
        <i
          class="bx bx-dots-vertical-rounded"
          v-b-tooltip.hover.v-light
          title="More"
        ></i>
      </template>
      <b-dropdown-item
        class="d-none"
        v-if="
          [statuses.paymentfailure, statuses.paymentpending].includes(
            order.order_status
          ) && isCODEnable()
        "
        @click="changeToCODPopup(order.base_id)"
      >
        {{ trans.get("__JSON__.Change to COD") }}
      </b-dropdown-item>
      <b-dropdown-item @click="viewDetails(order.base_id)">{{
        trans.get("__JSON__.View")
      }}</b-dropdown-item>
      <b-dropdown-item
        v-if="order.order_status == statuses.placed"
        @click="changeStatus(order.base_id, statuses.inkitchen)"
        >{{ trans.get("__JSON__.Confirm Order") }}</b-dropdown-item
      >
      <b-dropdown-item
        v-if="order.order_status == statuses.inkitchen && !$auth.partner_login && order.delivery_pickup_types == 'Delivery'"
        :disabled="order.balance_amount != null && order.balance_amount > 0 && order.payment_method && order.payment_method.toLowerCase() != 'cash on delivery'"
        :style="{ cursor: order.balance_amount != null && order.balance_amount > 0 && order.payment_method && order.payment_method.toLowerCase() != 'cash on delivery' ? 'not-allowed' : 'pointer' }"
        @click="changeStatus(order.base_id, statuses.readytoserve)"
        >{{ trans.get("__JSON__.Ready To Delivery Order") }}</b-dropdown-item
      >
      <b-dropdown-item
        v-if="order.order_status == statuses.inkitchen && $auth.partner_login && order.delivery_pickup_types == 'Delivery'"
        @click="changeStatus(order.base_id, statuses.readytoserve)"
        >{{ trans.get("__JSON__.Ready To Delivery Order") }}</b-dropdown-item
      >
      <b-dropdown-item
        v-if="
          ![
            statuses.placed,
            statuses.cancelled,
            statuses.paymentpending,
            statuses.paymentfailure,
          ].includes(order.order_status) && $auth.partner_login
        "
        @click="uploadInvoice(order.order_id, statuses.inkitchen)"
        >{{ trans.get("__JSON__.Upload Invoice") }}</b-dropdown-item
      >
      <b-dropdown-divider
        v-if="
          [
            statuses.placed,
            statuses.inkitchen,
            statuses.readytoserve,
            statuses.ontheway,
            statuses.scheduled,
          ].includes(order.order_status)
        "
      ></b-dropdown-divider>

      <b-dropdown-item
        v-if="
          [statuses.readytoserve].includes(order.order_status) &&
          order.driver &&
          order.driver.length == 0 &&
          order.delivery_pickup_types == 'Delivery' &&
          (order.task_id == null ||
            (order.delivery_partner_status != null
              ? order.delivery_partner_status.toLowerCase() == 'cancelled'
              : order.delivery_partner_status == 'cancelled'))
        "
        @click="pingOrderDrivers(order.base_id)"
      >
        Ping {{ $auth.getDriverName() }}
      </b-dropdown-item>

      <b-dropdown-item
        v-if="
          ([statuses.readytoserve, statuses.ontheway].includes(
            order.order_status
          ) &&
          (order.task_id == null ||
            (order.delivery_partner_status != null
              ? order.delivery_partner_status.toLowerCase() == 'cancelled'
              : order.delivery_partner_status == 'cancelled')) || ([statuses.inkitchen].includes(
            order.order_status
          ) && order.delivery_pickup_types == 'Pickup'))
        "
        @click="deliveredOrderPopup(order.base_id, statuses.delivered)"
        >{{ trans.get("__JSON__.Deliver Order") }}</b-dropdown-item
      >
      <!-- [statuses.placed,statuses.inkitchen,statuses.readytoserve,statuses.ontheway, statuses.scheduled].includes(order.order_status) -->
      <b-dropdown-item
        v-if="[statuses.placed].includes(order.order_status)"
        @click="cancelOrderPopup(order.base_id, statuses.cancelled)"
        ><span class="text-danger">{{
          trans.get("__JSON__.Cancel Order")
        }}</span></b-dropdown-item
      >
      <b-dropdown-item
        v-if="
          [statuses.delivered].includes(order.order_status) &&
          order.settled === 1 &&
          !$auth.partner_login
        "
        @click="unsettleOrderPopup(order.base_id, order.settled)"
        >{{ trans.get("__JSON__.Unsettle") }}</b-dropdown-item
      >
      <b-dropdown-item
        v-if="
          [statuses.delivered].includes(order.order_status) &&
          order.settled === 0 &&
          !$auth.partner_login
        "
        @click="unsettleOrderPopup(order.base_id, order.settled)"
        >{{ trans.get("__JSON__.Settle") }}</b-dropdown-item
      >
    </b-dropdown>
    <i
      v-else
      v-b-tooltip="'Need Permission'"
      class="mdi mdi-information h4 text-danger"
    ></i>

    <template
      v-if="
        order.suborder &&
        order.suborder.id &&
        order.suborder.id > 0 &&
        [statuses.inkitchen].includes(order.order_status) &&
        !$auth.partner_login &&
        order.depot_item_delivered == 0
      "
    >
      <button
        type="button"
        class="btn btn-primary"
        v-if="$auth.hasPermission(config.permissions.order_write)"
        @click.stop="sentToStore(order.order_id)"
        :disabled="sentToStoreLoader"
      >
        <b-spinner v-if="sentToStoreLoader" class="align-middle"></b-spinner>
        Sent to store
      </button>
    </template>
    <template
      v-else-if="
        [statuses.inkitchen].includes(order.order_status) &&
        !$auth.partner_login &&
        !order.suborder &&
        order.is_depot_store == 1 &&
        order.depot_item_delivered == 0
      "
    >
      <button
        type="button"
        class="btn btn-primary"
        v-if="$auth.hasPermission(config.permissions.order_write)"
        @click.stop="sentToStore(order.order_id)"
        :disabled="sentToStoreLoader"
      >
        <b-spinner v-if="sentToStoreLoader" class="align-middle"></b-spinner>
        Sent to store
      </button>
    </template>
    <template
      v-if="
        [statuses.readytoserve].includes(
          order.order_status
        ) &&
        !$auth.partner_login &&
        order.task_id != null &&
        (order.delivery_partner_status != null
          ? order.delivery_partner_status.toLowerCase() != 'cancelled'
          : order.delivery_partner_status != 'cancelled')
      "
      :disabled="cancelDunzoTaskLoader"
    >
      <button
        style="margin-left: 4px"
        type="button"
        class="btn btn-primary"
        @click="cancelDunzoTask(order.order_id, order.task_id)"
      >
        <b-spinner
          v-if="cancelDunzoTaskLoader"
          class="align-middle"
        ></b-spinner>
        Cancel {{ order.delivery_partner_name }} Task
      </button>
    </template>
    <OrderShow
      v-if="showModal"
      :order-id="order.base_id"
      @hidden="showModal = false"
      :showModalTab="showModalTab"
      @actionChanged="$emit('refreshData')"
    />
    <b-modal
      id="modal-sm"
      size="lg"
      title-class="font-18"
      @hidden="resetModal"
      @ok="cancelOrder"
      :ok-disabled="loading"
      :ok-title="
        loading
          ? trans.get('__JSON__.Cancelling')
          : trans.get('__JSON__.Cancel Order')
      "
      ok-only
      ok-variant="danger"
      ref="cancel-modal"
      lazy
    >
      <template #modal-header="{ close }">
        <div class="d-flex justify-content-between w-100">
          <h4 class="mb-0">{{ trans.get("__JSON__.Cancel Order") }}</h4>
          <h4 class="mb-0">{{ order.order_id }}</h4>
        </div>
        <button type="button" aria-label="Close" class="close" @click="close()">
          &times;
        </button>
      </template>

      <div class="row">
        <div class="col-12">
          <b-form role="form">
            <div class="row">
              <div
                class="col"
                v-for="(reason, index) in cancel_reasons"
                :key="index"
              >
                <a href="#" @click="onReasonSelect(reason)">
                  <b-card
                    header-class="bg-transparent border-primary"
                    class="border border-primary"
                    :bg-variant="
                      (other_selected && reason == 'Other') ||
                      reason == cancelled_desc
                        ? 'primary'
                        : 'default'
                    "
                    body-class="d-flex align-items-center justify-content-center height-100"
                  >
                    <h5
                      class="card-title mt-0"
                      :class="{
                        'text-white':
                          (other_selected && reason == 'Other') ||
                          reason == cancelled_desc,
                      }"
                    >
                      {{ reason }}
                    </h5>
                  </b-card>
                </a>
              </div>
            </div>
            <b-form-group label="Cancel Description" v-if="other_selected">
              <b-form-textarea
                id="textarea"
                v-model="cancelled_desc"
                placeholder="Enter Cancel Order Description"
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </b-form-group>
            <!-- <b-form-group
              v-if="
                !$auth.partner_login &&
                !(
                  order.payment_method == 'Cash On Delivery' &&
                  order.wallet_amount == 0
                )
              "
            >
              <b-form-select
                v-model="is_refundable"
                :options="[
                  ...(order.payment_method == 'Online'
                    ? [{ value: '1', text: 'Refundable - Original Payment' }]
                    : []),
                  { value: '0', text: 'No Refundable' },
                ]"
              ></b-form-select>
              <div> 
                third option is commented here of upper select box 
...(order.payment_method == 'Online' || order.payment_method == 'Wallet' || (order.payment_method == 'Cash On Delivery' && order.wallet_amount > 0)?[{ value: '2', text: 'Refundable - to Wallet' }]:[])
</div>
            </b-form-group> -->
            <!-- <b-form-group v-else-if="!$auth.partner_login">
                             <b-form-checkbox
                                id="checkbox-1"
                                switch
                                v-model="is_refundable"
                                class="text-right"
                                name="checkbox-1"
                                size="lg"
                                value="1"
                                unchecked-value="0"
                            >
                            Is Refundable
                            </b-form-checkbox>
                        </b-form-group> -->
          </b-form>
        </div>
      </div>
    </b-modal>

    <b-modal
      id="modal-invoice"
      size="lg"
      title-class="font-18"
      @hidden="resetModalInvoice"
      @ok="uploadInvoiceApi"
      :ok-disabled="loading || typeform.vendor_invoice == ''"
      :ok-title="
        loading
          ? trans.get('__JSON__.Uploading')
          : trans.get('__JSON__.Upload Invoice')
      "
      ok-only
      ok-variant="danger"
      ref="invoice-modal"
      lazy
    >
      <template #modal-header="{ close }">
        <div class="d-flex justify-content-between w-100">
          <h4 class="mb-0">{{ trans.get("__JSON__.Upload Invoice") }}</h4>
        </div>
        <button type="button" aria-label="Close" class="close" @click="close()">
          &times;
        </button>
      </template>
      <div class="d-flex">
        <label class="filelabel"
          ><i class="bx bx-plus plus" style="cursor: pointer"
            ><input
              accept="image/jpeg,image/png,image/jpg,application/pdf"
              @change="uploadImage"
              class="FileUpload1"
              id="FileInput"
              type="file" /></i
        ></label>
        <div class="uploaded-img-main">
          <div v-if="url" class="prvie-img uploaded-img">
            <p class="remove-flg" @click="removeUploadImage">X</p>
            <img
              v-if="
                this.selectedInvoice.type == 'image/jpeg' ||
                this.selectedInvoice.type == 'image/png' ||
                this.selectedInvoice.type == 'image/jpg'
              "
              :src="url"
              class="dis-img"
            />
            <img
              v-if="this.selectedInvoice.type == 'application/pdf'"
              :src="config.basepath + 'pdfimage.png'"
              class="dis-img"
            />
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal
      id="modal-sm"
      size="lg"
      title-class="font-18"
      @hidden="resetModal"
      @ok="deliveredOrder"
      :ok-disabled="loading"
      :ok-title="
        loading
          ? trans.get('__JSON__.Delivering')
          : trans.get('__JSON__.Deliver Order')
      "
      ok-only
      ok-variant="danger"
      ref="delivered-modal"
      lazy
    >
      <template #modal-header="{ close }">
        <div class="d-flex justify-content-between w-100">
          <h4 class="mb-0">{{ trans.get("__JSON__.Deliver Order") }}</h4>
          <h4 class="mb-0">{{ order.order_id }}</h4>
        </div>
        <button type="button" aria-label="Close" class="close" @click="close()">
          &times;
        </button>
      </template>

      <div class="row">
        <div class="col-12">
          <b-form role="form">
            <b-form-group label="Notes">
              <b-form-textarea
                id="textarea"
                v-model="delivered_note"
                placeholder="Enter something..."
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </b-form-group>
          </b-form>
        </div>
      </div>
    </b-modal>

    <b-modal
      id="modal-sm"
      size="lg"
      title-class="font-18"
      @hidden="resetModal"
      @ok="changeToCODOrder"
      :ok-disabled="loading"
      :ok-title="
        loading
          ? trans.get('__JSON__.Change to COD')
          : trans.get('__JSON__.Change to COD')
      "
      ok-only
      ok-variant="danger"
      ref="changeto-cod-modal"
      lazy
    >
      <template #modal-header="{ close }">
        <div class="d-flex justify-content-between w-100">
          <h4 class="mb-0">{{ trans.get("__JSON__.Change to COD") }}</h4>
          <h4 class="mb-0">{{ order.order_id }}</h4>
        </div>
        <button type="button" aria-label="Close" class="close" @click="close()">
          &times;
        </button>
      </template>

      <div class="row">
        <div class="col-12">
          <b-form role="form">
            <b-form-group label="Notes">
              <b-form-textarea
                id="textarea"
                v-model="changeto_cod_note"
                placeholder="Enter something..."
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </b-form-group>
          </b-form>
        </div>
      </div>
    </b-modal>

    <b-modal
      id="modal-sm"
      size="lg"
      title-class="font-18"
      @hidden="resetModal"
      @ok="unsettleOrder"
      :ok-disabled="loading"
      :ok-title="
        loading ? trans.get('__JSON__.Loading') : trans.get('__JSON__.Submit')
      "
      ok-only
      ok-variant="danger"
      ref="unsettle-modal"
      lazy
    >
      <template #modal-header="{ close }">
        <div class="d-flex justify-content-between w-100">
          <h4 class="mb-0">
            {{ trans.get("__JSON__.Mark Order Settle/Unsettle") }}
          </h4>
          <h4 class="mb-0">{{ order.order_id }}</h4>
        </div>
        <button type="button" aria-label="Close" class="close" @click="close()">
          &times;
        </button>
      </template>

      <div class="row">
        <div class="col-12">
          <b-form role="form">
            <b-form-group label="Notes">
              <b-form-textarea
                id="textarea"
                v-model="unsettle_note"
                placeholder="Enter something..."
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </b-form-group>
          </b-form>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import OrderShow from "./order-show";
import { debounce, order_statuses } from "./../../../Helper/helper";
import config from "../../../config";
import { orderService } from "../../../services";

export default {
  props: ["order", "orderIndex"],
  components: { OrderShow },
  data() {
    return {
      config: config,
      showModal: false,
      showModalUpload: false,
      showModalTab: 0,
      statuses: order_statuses,
      cancelled_desc: "",
      delivered_note: "",
      changeto_cod_note: "",
      is_refundable: 1 ,//this.$auth.partner_login ? 1 : 0,
      showPopup: false,
      order_id: null,
      cancel_reasons: [
        "Too Busy",
        "Out of Delivery Area",
        "Out of Stock",
        "Other",
      ],
      other_selected: false,
      loading: false,
      isAcceptbtnDisabled: false,
      sentToStoreLoader: false,
      cancelDunzoTaskLoader: false,
      // cancelThisOrder:debounce((bvModalEvt)=>{
      //     bvModalEvt.preventDefault()
      //     this.cancelOrder(bvModalEvt)
      // })
      typeform: {
        vendor_invoice: "",
        order_id: "",
      },
      selectedInvoice: "",
      previewImage: "",
      url: "",
      unsettle_note: "",
      settlementStatus: null,
    };
  },
  methods: {
    viewDetails(id) {
      this.showModalTab = 0;
      this.showModal = true;
      this.orderLoading = true;
      this.orderId = id;
    },
    changeStatus(id, status) {
      let shipmentMessage = "";
      if (status == "InKitchen") {
        this.isAcceptbtnDisabled = true;
      } else if (status == "ReadyToServe") {
        if (
          this.order.suborder &&
          this.order.suborder.id &&
          this.order.suborder.id > 0
        ) {
          if (this.order.suborder.order_status == "InKitchen") {
            id = btoa(this.order.suborder.id);
            shipmentMessage = "Shipment 1 successfully updated";
          } else {
            shipmentMessage = "Shipment 2 successfully updated";
            // if (this.order.depot_item_delivered == 0) {
            //     this.$toasted.error('Waiting for Depot to approve !!');
            //     return false;
            // }
          }
        }
        // else if (this.order.is_depot_store == 1 && this.order.depot_item_delivered == 0) {
        //     this.$toasted.error('Waiting for Depot to approve !!');
        //     return false;
        // }
      }
      const data = {
        order_id: atob(id),
        status_name: status,
        vendor_id: this.$auth.getVendorId(),
        is_langauge: this.$auth.setting.primary_lang,
      };
      orderService.changeOrderStatus(data).then((response) => {
        if (response.data.code == 200) {
          if (status == "ReadyToServe" && shipmentMessage != "") {
            this.$toasted.success(shipmentMessage);
          } else {
            this.$toasted.success(response.data.msg);
          }
        } else {
          this.$toasted.error(response.data.msg);
        }
        // if (status == 'InKitchen') {
        //     this.isAcceptbtnDisabled = false;
        // }
        this.$emit("actionChanged");
      });
    },
    uploadInvoice(id, status) {
      //   this.showModalUpload = true;
      this.typeform.order_id = id;
      this.$refs["invoice-modal"].show();
    },
    uploadImage(e) {
      this.selectedInvoice = e.target.files[0];
      this.previewImage = this.selectedInvoice.name;
      var formData = new FormData();
      formData.append("vendor_invoice", this.selectedInvoice);
      formData.append("order_id", this.typeform.order_id);
      this.typeform.vendor_invoice = formData;
      this.url = URL.createObjectURL(e.target.files[0]);
      if (!this.selectedInvoice) {
        return;
      }
      e.target.value = null;
    },
    removeUploadImage() {
      this.selectedInvoice = "";
      this.typeform.vendor_invoice = "";
      this.url = "";
      this.previewImage = "";
    },
    pingOrderDrivers(id) {
      const data = {
        order_id: atob(id),
        vendor_id: this.$auth.getVendorId(),
        is_langauge: this.$auth.setting.primary_lang,
      };
      orderService.pingOrderDrivers(data).then((response) => {
        if (response.data.code == 200) {
          this.$toasted.success(response.data.msg);
        } else {
          this.$toasted.error(response.data.msg);
        }
      });
    },
    ownerOrderStatus(id, status) {
      const data = {
        order_id: atob(id),
        status_name: status,
        vendor_id: this.$auth.getVendorId(),
      };
      orderService.ownerOrderStatus(data).then((response) => {
        if (response.data.code == 200) {
          this.$toasted.success(response.data.msg);
        } else {
          this.$toasted.error(response.data.msg);
        }
        this.$emit("actionChanged");
      });
    },
    resetModal() {
      this.order_id = null;
      this.is_refundable = 1 //this.$auth.partner_login ? 1 : 0;
      this.delivered_note = "";
      this.changeto_cod_note = "";
    },
    resetModalInvoice() {
      this.typeform.order_id = null;
      this.typeform.vendor_invoice = "";
    },
    cancelOrderPopup(id, status) {
      this.$refs["cancel-modal"].show();
      this.order_id = id;
    },
    cancelOrder(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.loading = true;
      if (this.cancelled_desc == "") {
        this.loading = false;
        if (this.other_selected) {
          this.$toasted.info("Please Enter Cancel Order Description");
        } else {
          this.$toasted.info("Please Select Cancel Option");
        }
        return false;
      }
      const data = {
        orderId: atob(this.order_id),
        cancelled_desc: this.cancelled_desc,
        is_refundable: this.is_refundable,
      };
      orderService.cancelOrder(data).then((response) => {
        if (response.data.code == 200) {
          this.$toasted.success(response.data.message);
        } else {
          this.$toasted.error(response.data.message);
        }
        this.loading = false;
        this.$refs["cancel-modal"].hide();
        this.$emit("actionChanged");
      });
    },
    uploadInvoiceApi(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.loading = true;
      orderService
        .vendorInvoice(this.typeform.vendor_invoice)
        .then((response) => {
          if (response.data?.code == 200) {
            this.$toasted.success(response.data.message);
          } else {
            this.$toasted.error(response.data.message);
          }
          this.loading = false;
          this.$refs["invoice-modal"].hide();
          this.$emit("actionChanged");
        });
    },
    deliveredOrderPopup(id, status) {
      this.$refs["delivered-modal"].show();
      this.order_id = id;
    },
    deliveredOrder(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.loading = true;
      if (this.delivered_note == "") {
        this.$toasted.info("Please write deliver note.");
        this.loading = false;
        return false;
      }
      const data = {
        orderId: atob(this.order_id),
        delivered_note: this.delivered_note,
      };
      orderService.deliverOrder(data).then((response) => {
        if (response.data.code == 200) {
          this.$toasted.success(response.data.message);
        } else {
          this.$toasted.error(response.data.message);
        }
        this.loading = false;
        this.$refs["delivered-modal"].hide();
        this.$emit("actionChanged");
      });
    },
    changeToCODPopup(id) {
      this.$refs["changeto-cod-modal"].show();
      this.order_id = id;
    },
    changeToCODOrder(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.loading = true;
      if (this.changeto_cod_note == "") {
        this.$toasted.info("Please write change to cod note.");
        this.loading = false;
        return false;
      }
      const data = {
        orderId: atob(this.order_id),
        changeto_cod_note: this.changeto_cod_note,
      };
      orderService.changeToCodOrder(data).then((response) => {
        if (response.data.code == 200) {
          this.$toasted.success(response.data.message);
        } else {
          this.$toasted.error(response.data.message);
        }
        this.loading = false;
        this.$refs["changeto-cod-modal"].hide();
        this.$emit("actionChanged");
      });
    },
    onReasonSelect(reason) {
      if (reason !== "Other") {
        this.cancelled_desc = reason;
        this.other_selected = false;
      } else {
        this.other_selected = true;
        this.cancelled_desc = "";
      }
    },
    isCODEnable() {
      if (this.$auth.setting.restaurant_payment_option) {
        if (
          this.$auth.setting.restaurant_payment_option == "Both" ||
          this.$auth.setting.restaurant_payment_option == "COD"
        ) {
          return true;
        } else if (
          this.$auth.setting.restaurant_payment_option ==
          "Restaurant Select Payment Option"
        ) {
          if (this.order.store_payment_option) {
            if (
              this.order.store_payment_option == "Both" ||
              this.order.store_payment_option == "COD"
            ) {
              return true;
            }
          }
        }
      }
      return false;
    },
    sentToStore(id) {
      let data = { orderid: id, orderindex: this.orderIndex };
      this.$emit("viewOrderDetailsSecondTabEmit", data);
      // this.sentToStoreLoader = true;
      // orderService.sentToStore({order_id:id})
      // .then(response => {
      //     if (response.data.code == 200){
      //         this.sentToStoreLoader = false;
      //         this.$toasted.success(response.data.msg);
      //         this.$emit('actionChanged');
      //     } else {
      //         this.sentToStoreLoader = false;
      //         this.$toasted.error(response.data.msg);
      //     }
      // });
    },
    cancelDunzoTask(id, task_id) {
      this.cancelDunzoTaskLoader = true;
      orderService
        .cancelOrderDunzo({ order_id: id, task_id: task_id })
        .then((response) => {
          if (response.data.code == 200) {
            this.cancelDunzoTaskLoader = false;
            this.$toasted.success(response.data.msg);
            this.$emit("actionChanged");
          } else {
            this.cancelDunzoTaskLoader = false;
            this.$toasted.error(response.data.msg);
          }
        });
    },
    unsettleOrderPopup(id, order) {
      this.$refs["unsettle-modal"].show();
      this.order_id = id;
      this.settlementStatus = order === 0 ? 1 : 0;
    },
    unsettleOrder(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.loading = true;
      if (this.unsettle_note == "") {
        this.$toasted.info("Please write a reason.");
        this.loading = false;
        return false;
      }
      const data = {
        order_id: atob(this.order_id),
        settled: this.settlementStatus,
        un_settle_reason: this.unsettle_note,
        vendor_id: this.$auth.getVendorId(),
      };
      orderService.markOrderUnsettle(data).then((response) => {
        if (response.data.code == 200) {
          this.$toasted.success(response.data.message);
        } else {
          this.$toasted.error(response.data.message);
        }
        this.loading = false;
        this.$refs["unsettle-modal"].hide();
        this.$emit("actionChanged");
      });
    },
  },
};
</script>
<style  scoped>
.height-100 {
  height: 100px;
}
.accept-btn,
.reject-btn {
  color: #fff;
  font-size: 16px;
  font-weight: 100;
  line-height: 0;
  border-radius: 50%;
}
.accept-btn {
  background-color: #34c38f;
  padding: 5px;
}
.reject-btn {
  background-color: #f46a6a;
  padding: 5px 7px 5px 7px;
}
.order-option-dropdown button {
  padding: 0;
}
.filelabel {
  background: var(--secondarylight);
  min-height: 85px;
  min-width: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-bottom: 0;
  margin-right: 10px;
}

#FileInput {
  display: none;
}
.dis-img {
  height: 70px;
  display: flex;
  margin: 15px;
}
.uploaded-img-main {
  display: flex;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
  position: relative;
}
.uploaded-img {
  position: relative;
}

.uploaded-img p {
  margin-bottom: 0;
  position: absolute;
  color: #fff;
  right: -8px;
  top: -8px;
  background: red;
  z-index: 2;
  height: 20px;
  width: 20px;
  object-fit: contain;
  font-size: 14px;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
}

p.remove-flg {
  cursor: pointer;
}

/* .btn-cancel {
  color: #fff;
  background-color: #f46a6a;
  border-color: #f46a6a;
}
.btn-cancel:hover {
  color: #fff;
  background-color: #f65757;
  border-color: #f65757;
}
.btn-cancel:focus, .btn-cancel.focus {
  color: #fff;
  background-color: #f74b4b;
  border-color: #f15f5f;
  box-shadow: 0 0 0 0.15rem rgba(233, 127, 121, 0.5);
}
.btn-cancel.disabled, .btn-cancel:disabled {
  color: #fff;
  background-color: #f46a6a;
  border-color: #f46a6a;
}
.btn-cancel:not(:disabled):not(.disabled):active, .btn-cancel:not(:disabled):not(.disabled).active, .show > .btn-cancel.dropdown-toggle {
  color: #fff;
  background-color: #f74b4b;
  border-color: #f15f5f;
}
.btn-cancel:not(:disabled):not(.disabled):active:focus, .btn-cancel:not(:disabled):not(.disabled).active:focus, .show > .btn-cancel.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.15rem rgba(233, 127, 121, 0.5);
} */
</style>
