<script>
import config from "../../config";
import Layout from '../../layouts/main'
import PageHeader from '../../components/page-header'
import { customerService} from '../../services';
import { set_axios_defaults } from '../../Helper/helper';
import { error_message } from "../../Helper/helper"
import { success_message } from "../../Helper/helper"
import {
  required,
  email,
  numeric,
  requiredIf
}from "vuelidate/lib/validators";
/**
 * Add-product component
 */
export default {
  components: { Layout, PageHeader},
  data() {
    return {
      config:config,
      customers: {
        user_name: "",
        last_name: "",
        email: "",
        mobile_number: "",
        status: "",
        is_cod_enable: "",
        customise: '0',
        wallet_status:  "active",
        wallet_recharge_limit:  0,
        maximum_wallet_amount:  0,
        self_deleted: ""
      }, 
      typesubmit: false,
      isCheckboxDisabled: false,
    };
  },
  computed:{
    isOptional(){
      return this.$auth && this.$auth.setting.wallet_status == 'active' && this.customers.customise == '1'
    }
  },
  validations: {
    customers: {
      user_name: {
        required
      },
      last_name: {
        required,
      },
      email: {
        required,
        email
      },
      mobile_number: {
        required,
        numeric
      }, 
      // wallet_status: {
      //   required: requiredIf(function(){
      //     return this.isOptional;
      //   }),
      //   numeric
      // },
      customise:{
        required: requiredIf(function(){
          return this.isOptional;
        }),
      },
      wallet_recharge_limit: {
        required: requiredIf(function(){
          return this.isOptional;
        }),
        numeric
      },
      maximum_wallet_amount: {
        required: requiredIf(function(){
          return this.isOptional;
        }),
        numeric
      },
    }
  },
  mounted(){
    this.getCustomerProfile();
  },
  methods: {
    getCustomerProfile(){
      customerService.getCustomerProfile({
        user_id: this.$route.params.user_id
      })
      .then(response => {
        this.customers = response.data.result;
        this.isCheckboxDisabled = response.data.result.self_deleted == 1 ? true : false;
      });
    },
    typeForm(e) {
      this.$v.$touch();
      if (this.$v.customers.$invalid) {
        return;
      }
      else{
        customerService.updateCustomerProfile(this.customers)
        .then(response => {
          if (response.data.code === 200) {
            success_message(response.data.message);
            this.$router.push({ name: "customer-detail" });
          } else {
            error_message(response.data.message);
          }
        });
      }  
    } 
  }
};
</script>

<template>
  <div class="row">
    <div class="col-12">
      <form action="#" @submit.prevent="typeForm">  
      <!-- profile Info Start -->
        <div class="card details-profile-main">
          <div class="card-body">
            <h4 class="card-title"></h4>
            <p class="card-title-desc"></p>

              <div class="row">
                <div class="col-sm-12">
                  <h4 class="card-title">{{trans.get('__JSON__.Personal Details')}}</h4>
                </div>
                <hr>
                <div class="col-sm-6">
                  <div class="form-group">
                      <label>{{ trans.get('__JSON__.First Name') }}</label>
                      <input id="user_name" type="text" class="form-control" :placeholder="trans.get('__JSON__.Enter First Name')" v-model="$v.customers.user_name.$model" name="user_name" :class="{ 'is-invalid':$v.customers.user_name.$error }"/>
                    <div v-if="$v.customers.user_name.$error" class="invalid-feedback">
                      <span v-if="!$v.customers.user_name.required">{{ trans.get('__JSON__.Please enter first name') }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label>{{ trans.get('__JSON__.Last Name') }}</label>
                    <input id="last_name" type="text" class="form-control" :placeholder="trans.get('__JSON__.Enter Last Name')" v-model="$v.customers.last_name.$model" name="last_name" :class="{ 'is-invalid':$v.customers.last_name.$error }"/>
                    <div v-if="$v.customers.last_name.$error" class="invalid-feedback">
                        <span v-if="!$v.customers.last_name.required">{{ trans.get('__JSON__.Please enter last name') }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label>{{ trans.get('__JSON__.E-Mail') }}</label>
                    <input id="email" type="text" class="form-control" :placeholder="trans.get('__JSON__.Enter Email')" v-model="$v.customers.email.$model" name="email" :class="{ 'is-invalid':$v.customers.email.$error }"/>
                      <div v-if="$v.customers.email.$error" class="invalid-feedback">
                        <span v-if="!$v.customers.email.required">{{ trans.get('__JSON__.Please enter email') }}</span>
                        <span v-if="!$v.customers.email.email">{{ trans.get('__JSON__.This value should be a valid email') }}</span>
                      </div>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="control-label">{{ trans.get('__JSON__.Phone Number') }}</label>
                    <input id="mobile_number" type="text" class="form-control" :placeholder="trans.get('__JSON__.Enter Phone Number')" v-model="$v.customers.mobile_number.$model" name="mobile_number" :class="{ 'is-invalid':$v.customers.mobile_number.$error }"/>
                      <div v-if="$v.customers.mobile_number.$error" class="invalid-feedback">
                        <span v-if="!$v.customers.mobile_number.required">{{ trans.get('__JSON__.Please enter contact number') }}</span>
                        <span v-if="!$v.customers.mobile_number.numeric">{{ trans.get('__JSON__.This value should be a valid number') }}</span>
                      </div>
                  </div>
                </div>

                  <div class="col-sm-6 vendor-status">
                    <b-form-group
                      id="input-group-1"
                      :label="trans.get('__JSON__.Status')"
                      label-for="status"
                    >
                      <label class="switch">
                        <input
                          type="checkbox"
                          id="togBtn"
                          name="status"
                          v-model="customers.status"
                          class="switch-on"
                          true-value="0"
                          false-value="1"
                          :disabled="isCheckboxDisabled"
                        />
                        <div :class="{ 'slider round': !isCheckboxDisabled, 'slider round disable-checkboxStatus': isCheckboxDisabled }">
                          <span class="on">{{ trans.get('__JSON__.Active') }}</span>
                          <span class="off">{{ trans.get('__JSON__.Inactive') }}</span>
                        </div>
                      </label>
                    </b-form-group>
               </div>

                <div class="col-sm-6 vendor-status">
                    <b-form-group
                      id="input-group-1"
                      :label="trans.get('__JSON__.Allow COD')"
                      label-for="is_cod_enable"
                    >
                      <label class="switch">
                        <input
                          type="checkbox"
                          id="togBtn"
                          name="is_cod_enable"
                          v-model="customers.is_cod_enable"
                          class="switch-on"
                          true-value="1"
                          false-value="0"
                        />
                        <div class="slider round">
                          <span class="on">{{ trans.get('__JSON__.Enable') }}</span>
                          <span class="off">{{ trans.get('__JSON__.Disable') }}</span>
                        </div>
                      </label>
                    </b-form-group>
               </div>

              </div>
              <div class="row" v-if="$auth.isWalletActive()">
                <div class="col-sm-12">
                  <h4 class="card-title">{{trans.get('__JSON__.Wallet Setting')}}</h4>
                </div>
                <hr>
                 <div class="col-sm-6 vendor-status">
                    <b-form-group
                      id="input-group-1"
                      :label="trans.get('__JSON__.Wallet')"
                      label-for="wallet"
                    >
                      <label class="switch">
                        <input
                          type="checkbox"
                          id="togBtn"
                          name="wallet_status"
                          v-model="customers.wallet_status"
                          class="switch-on"
                          true-value="active"
                          false-value="inactive"
                        />
                        <div class="slider round">
                          <span class="on">{{ trans.get('__JSON__.Active') }}</span>
                          <span class="off">{{ trans.get('__JSON__.Inactive') }}</span>
                        </div>
                      </label>
                    </b-form-group>
                </div>
                <div class="col-sm-6 vendor-status">
                    <b-form-group
                      id="input-group-1"
                      :label="trans.get('__JSON__.Wallet Setting')"
                      label-for="wallet"
                    >
                      <label class="switch">
                        <input
                          type="checkbox"
                          id="togBtn"
                          name="customise"
                          v-model="customers.customise"
                          class="switch-on"
                          true-value="1"
                          false-value="0"
                        />
                        <div class="slider round">
                          <span class="on">{{ trans.get('__JSON__.Enable') }}</span>
                          <span class="off">{{ trans.get('__JSON__.Disable') }}</span>
                        </div>
                      </label>
                    </b-form-group>
                </div>
                <div class="col-sm-6 vendor-status" v-if="customers.customise == '1'">
                    <div class="form-group">
                    <label class="control-label">{{ trans.get('__JSON__.Maximum Recharge Limit') }}</label>
                    <input id="wallet_recharge_limit" type="text" class="form-control" :placeholder="trans.get('__JSON__.Enter Maximum Recharge Limit')" v-model="$v.customers.wallet_recharge_limit.$model" name="mobile_number" :class="{ 'is-invalid':$v.customers.wallet_recharge_limit.$error }"/>
                      <div v-if="$v.customers.wallet_recharge_limit.$error" class="invalid-feedback">
                        <span v-if="!$v.customers.wallet_recharge_limit.required">{{ trans.get('__JSON__.Please enter wallet recharge limit') }}</span>
                        <span v-if="!$v.customers.wallet_recharge_limit.numeric">{{ trans.get('__JSON__.This value should be a valid number') }}</span>
                      </div>
                  </div>
                </div>
                <div class="col-sm-6 vendor-status" v-if="customers.customise == '1'">
                  <div class="form-group">
                    <label class="control-label">{{ trans.get('__JSON__.Maximum Wallet Amount') }}</label>
                    <input id="maximum_wallet_amount" type="text" class="form-control" :placeholder="trans.get('__JSON__.Enter Maximum Wallet Amount')" v-model="$v.customers.maximum_wallet_amount.$model" name="maximum_wallet_amount" :class="{ 'is-invalid':$v.customers.maximum_wallet_amount.$error }"/>
                    <div v-if="$v.customers.maximum_wallet_amount.$error" class="invalid-feedback">
                      <span v-if="!$v.customers.maximum_wallet_amount.required">{{ trans.get('__JSON__.Please enter wallet mount') }}</span>
                      <span v-if="!$v.customers.maximum_wallet_amount.numeric">{{ trans.get('__JSON__.This value should be a valid number') }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6">
                  <button v-if="$auth.hasPermission(config.permissions.customer_write)" type="submit" class="btn btn-primary mr-1 mt-3">{{ trans.get('__JSON__.Update Profile') }}</button>
                  <b-button @click="$router.back()" type="button" variant="secondary" class="mt-3">{{ trans.get('__JSON__.Cancel') }}</b-button>
                </div>
              </div>
          </div>
        </div>
      <!-- profile Info End -->
      </form>
    </div>
  </div>
</template>

<style>
  .disable-checkboxStatus {
    cursor: not-allowed !important;
  }
</style>
