<script>
import vue2Dropzone from "vue2-dropzone";
import Multiselect from "vue-multiselect";
import PageHeader from "../../components/page-header";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { discountService } from "../../services";
import { 
  error_message, 
  success_message,
  get_partner_login,
  set_partner,
  set_partner_details,
  set_user,
  set_user_detail,
  replaceByDefault
} from "../../Helper/helper";
import DropzonePopup from "../../components/widgets/dropzone-popup";

import {
  required,
  email,
  minLength,
  sameAs,
  maxLength,
  minValue,
  maxValue,
  numeric,
  url,
  alphaNum,
  requiredIf,
} from "vuelidate/lib/validators";

export default {
  name: "Range",
  components: {
    vueDropzone: vue2Dropzone,
    Multiselect,
    PageHeader,
    DatePicker,
    discountService,
    DropzonePopup,
  },
  data() {
    return {
      title: this.trans.get("__JSON__.Add Discount"),
      tmpDiscountImage: "",
      search: "",
      search1: "",
      showTimeRangePanel: false,
      rangeSeparator: "-",
      format: "YYYY/MM/DD HH:s",
      typeform: {
        restaurant_id : "",
        discount: "",
        maximum_discount_amount: "",
        minimum_order_amount: "",
        discount_image: "",
        description: "",
        show_display_text: "",
        show_display_text_another_lang: "",
        start_datetime: "",
        end_datetime: "",
      },
    };
  },
  validations: {
    typeform: {
      discount: {
        required,
        numeric,
      },
      maximum_discount_amount: {
        required,
        numeric,
      },
      minimum_order_amount: {
        required,
        numeric,
      },
      start_datetime: {
        required,
      },
      description: {
        required,
      },
      discount_image: {
        required,
      }
    },
  },
  mounted(){
      this.getUser();
  },
  methods: {
    replaceByDefault,
    getUser() {
      this.users = this.$auth.user;
      this.userdetail = this.$auth.setting;

    },
    toggleTimeRangePanel() {
      this.showTimeRangePanel = !this.showTimeRangePanel;
    },
    handleRangeClose() {
      this.showTimeRangePanel = false;
    },
    disabledBeforeTodayAndAfterAWeek(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0));
    },
    discountInIt() {
      this.$v.$touch();
      if (this.$v.typeform.$invalid) {
        return;
      }
      else
      {
        const fd = new FormData();
        fd.append("restaurant_id", this.$auth.setting.restaurant_id);
        fd.append("discount", this.typeform.discount);
        fd.append(
          "maximum_discount_amount",
          this.typeform.maximum_discount_amount
        );
        fd.append("minimum_order_amount", this.typeform.minimum_order_amount);
        fd.append("discount_image", this.typeform.discount_image);
        fd.append("description", this.typeform.description);
        fd.append("start_datetime", this.typeform.start_datetime);
        fd.append("show_display_text", this.typeform.show_display_text);
        fd.append("show_display_text_another_lang", this.typeform.show_display_text_another_lang);
        discountService.addDiscount(fd).then((response) => {
          if (response.data.code === 200) {
            success_message(response.data.message);
            console.log('hi');
            this.$router.push({ name: "vendor.discount.list" });
          } else {
            error_message(response.data.message);
          }
        });
      }
    },
  },
};
</script>

<template>
  <div>
    <PageHeader :title="title" />
    <div class="row">
      <div class="col-12">
        <form @submit.prevent="discountInIt">
          <!-- Discount Detail Section Start -->
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">{{ trans.get('__JSON__.Discount Detail') }}</h4>
              <p class="card-title-desc"></p>
              <div class="row" v-if="$auth.setting">
                <input type="hidden" name="restaurant_id" v-bind:value="this.$auth.setting.restaurant_id" />
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="control-label">{{ trans.get('__JSON__.Discount') }} (%)</label>
                    <input
                      id="discount"
                      name="discount"
                      v-model="$v.typeform.discount.$model"
                      type="number"
                      :placeholder="this.trans.get('__JSON__.Enter Discount in Percentage')"
                      class="form-control"
                      :class="{ 'is-invalid': $v.typeform.discount.$error }"
                    />
                    <div v-if="$v.typeform.discount.$error" class="invalid-feedback">
                      <span
                        v-if="!$v.typeform.discount.required"
                      >{{ trans.get('__JSON__.Please enter discount')}}.</span>
                      <span
                        v-if="!$v.typeform.discount.numeric"
                      >{{ trans.get('__JSON__.This value should be digits')}}.</span>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group" v-if="$auth.setting">
                    <label class="control-label">
                      {{ trans.get('__JSON__.Minimum Item Amount') }} ({{$auth.setting.currency}})
                    </label>
                    <input
                      id="minimum_order_amount"
                      name="minimum_order_amount"
                      v-model="$v.typeform.minimum_order_amount.$model"
                      type="number"
                      :placeholder="this.trans.get('__JSON__.Enter Minimum Item Amount')"
                      class="form-control"
                      :class="{ 'is-invalid': $v.typeform.minimum_order_amount.$error }"
                    />
                    <div v-if="$v.typeform.minimum_order_amount.$error" class="invalid-feedback">
                      <span
                        v-if="!$v.typeform.minimum_order_amount.required"
                      >{{ trans.get('__JSON__.Please enter minimum item amount')}}.</span>
                      <span
                        v-if="!$v.typeform.minimum_order_amount.numeric"
                      >{{ trans.get('__JSON__.This value should be digits')}}.</span>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="control-label">
                      {{ trans.get('__JSON__.Maximum Discount Amount') }} ({{$auth.setting.currency}})
                    </label>
                    <input
                      id="maximum_discount_amount"
                      name="maximum_discount_amount"
                      type="number"
                      v-model="$v.typeform.maximum_discount_amount.$model"
                      :placeholder="this.trans.get('__JSON__.Enter Maximum Discount Amount')"
                      class="form-control"
                      :class="{ 'is-invalid': $v.typeform.maximum_discount_amount.$error }"
                    />
                    <div v-if="$v.typeform.maximum_discount_amount.$error" class="invalid-feedback">
                      <span
                        v-if="!$v.typeform.maximum_discount_amount.required"
                      >{{ trans.get('__JSON__.Please enter maximum discount')}}.</span>
                      <span
                        v-if="!$v.typeform.maximum_discount_amount.numeric"
                      >{{ trans.get('__JSON__.This value should be digits')}}.</span>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label
                      class="control-label"
                    >{{ trans.get('__JSON__.Discount start/end Period') }}</label>
                    <br />
                    <date-picker
                      v-model="$v.typeform.start_datetime.$model"
                      :disabled-date="disabledBeforeTodayAndAfterAWeek"
                      type="datetime"
                      value-type="format"
                      :rangeSeparator="rangeSeparator"
                      placeholder="Select datetime range"
                      range
                      :show-time-panel="showTimeRangePanel"
                      @close="handleRangeClose"
                      :class="{ 'is-invalid': $v.typeform.start_datetime.$error }"
                    >
                      <template v-slot:footer>
                        <button
                          class="mx-btn mx-btn-text"
                          @click="toggleTimeRangePanel"
                        >{{ showTimeRangePanel ? 'select date' : 'select time' }}</button>
                      </template>
                    </date-picker>
                    <div v-if="$v.typeform.start_datetime.$error" class="invalid-feedback">
                      <span
                        v-if="!$v.typeform.start_datetime.required"
                      >{{ trans.get('__JSON__.Please select date') }}.</span>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="control-label">{{ trans.get('__JSON__.Discount Description') }}</label>
                    <textarea
                      rows="5"
                      id="description"
                      v-model="$v.typeform.description.$model"
                      type="text"
                      class="form-control"
                      :placeholder="this.trans.get('__JSON__.Enter discount Description')"
                      name="description"
                      :class="{ 'is-invalid': $v.typeform.description.$error }"
                    ></textarea>
                    <div v-if="$v.typeform.description.$error" class="invalid-feedback">
                      <span
                        v-if="!$v.typeform.description.required"
                      >{{ trans.get('__JSON__.Please enter discount description') }}.</span>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6" v-if="$auth.setting">
                  <div class="form-group">
                    <label
                      class="control-label"
                    >{{ trans.trans('__JSON__.Display Text Primary Language(Optional)', { lang: $auth.langauges[0].name }) }}</label>
                    <textarea
                      rows="5"
                      id="show_display_text"
                      v-model="typeform.show_display_text"
                      type="text"
                      class="form-control"
                      :placeholder="trans.trans('__JSON__.Display Text Primary Language(Optional)', { lang: $auth.langauges[0].name })"
                      name="show_display_text"
                    ></textarea>
                  </div>
                </div>

                 <div class="col-sm-6 d-none" v-if="$auth.langauges[1].name === 'No Secondary Language'">
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label
                      class="control-label"
                    >{{ trans.trans('__JSON__.Display Text Secondary Language(Optional)', { lang: $auth.langauges[1].name }) }}</label>
                    <textarea
                      rows="5"
                      id="show_display_text_another_lang"
                      v-model="typeform.show_display_text_another_lang"
                      type="text"
                      class="form-control"
                      :placeholder="trans.trans('__JSON__.Display Text Secondary Language(Optional)', { lang: $auth.langauges[1].name })"
                      name="show_display_text_another_lang"
                    ></textarea>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="control-label">{{ trans.get('__JSON__.Discount Image') }}</label>
                    <dropzone-popup
                      v-model="typeform.discount_image"
                      type="file"
                      id="discount_image"
                      name="discount_image"
                      acceptedFiles=".jpeg,.jpg,.png,.webp,image/webp"
                      :class="{ 'is-invalid': $v.typeform.discount_image.$error }"
                    />
                    <div
                      v-if="$v.typeform.discount_image.$error"
                      class="invalid-feedback cuisine-img"
                    >
                      <span
                        v-if="!$v.typeform.discount_image.required"
                      >{{ trans.get('__JSON__.Please select discount image') }}.</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6">
                  <button
                    class="btn btn-primary mr-1 mt-3"
                  >{{ trans.get('__JSON__.Add Discount') }}</button>
                  <router-link
                    :to="{name: 'vendor.discount.list'}"
                    type="submit"
                    class="btn btn-secondary mt-3"
                  >{{ trans.get('__JSON__.Cancel') }}</router-link>
                </div>
              </div>
            </div>
          </div>
          <!-- Discount Detail Section End -->
        </form>
      </div>
    </div>
    <!-- end row -->
  </div>
</template>

<style>
</style>