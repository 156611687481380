<script>
import config from "../../config";
import Layout from '../../layouts/main'
import PageHeader from '../../components/page-header'
import { customerService, driverService} from '../../services';
import { error_message } from "../../Helper/helper"
import { success_message } from "../../Helper/helper"
import {
  required,
  numeric,
  minLength,
  maxLength
}from "vuelidate/lib/validators";
/**
 * Add-product component
 */
export default {
  components: { Layout, PageHeader},
  data() {
    return {
      config:config,
      affiliates: {
        restaurant_id:"",
        name: "",
        contact_number: "",
        full_address: "",
        referral_code: "",
        affiliate_id: "",
        restaurant_name:""
      }, 
      typesubmit: false,
      restaurant:[],
    };
  },
  computed:{
  },
  validations: {
    affiliates: {
      name: {
        required
      },
      restaurant_id: {
        required,
      },
      full_address: {
        required,
      },
      contact_number: {
        required,
        numeric,
        minLength: minLength(10),
        maxLength: maxLength(10)
      },
    }
  },
  mounted(){
    this.getCustomerProfile();
    this.getRestaurant();
  },
  methods: {
    getCustomerProfile(){
      customerService.getAffiliateDetails({
          affiliate_id: parseInt(atob(this.$route.params.affiliate_id)),
          vendor_id: this.$auth.getVendorId()
        })
      .then(response => {
        this.affiliates = 
        {
            restaurant_id: response.data.Result[0].restaurant_id,
            name: response.data.Result[0].name,
            contact_number: response.data.Result[0].contact_number,
            full_address: response.data.Result[0].full_address,
            referral_code: response.data.Result[0].referral_code,
            affiliate_id: response.data.Result[0].affiliate_id,
            restaurant_name: response.data.Result[0].restaurant_name,
            vendor_id: response.data.Result[0].vendor_id
        };
      });
    },
    typeForm(e) {
      this.$v.$touch();
      if (this.$v.affiliates.$invalid) {
        console.log("hi");
        return;
      }
      else{
        console.log(this.affiliates.restaurant_id, "this.affiliates");
        console.log(this.restaurant, "restbdfhbd");

        const affiliateRest = this.restaurant.find(item => item.restaurant_id === this.affiliates.restaurant_id);
        this.affiliates.restaurant_name = affiliateRest.name;
        customerService.updateAffiliateDetails(this.affiliates)
        .then(response => {
          if (response.data.code == 200) {
            success_message(response.data.msg);
          } else {
            error_message(response.data.msg);
          }
        });
      }  
    },
    getRestaurant(){
            var filters = {
                search: '',
            };
            driverService.getRestaurant(filters)
               .then(response => {
                  this.restaurant =  response.data.result;
            });
    }, 
  }
};
</script>

<template>
  <div class="row">
    <div class="col-12">
      <form action="#" @submit.prevent="typeForm">  
      <!-- profile Info Start -->
        <div class="card details-profile-main">
          <div class="card-body">
            <h4 class="card-title"></h4>
            <p class="card-title-desc"></p>

              <div class="row">
                <div class="col-sm-12">
                  <h4 class="card-title">{{trans.get('__JSON__.Personal Details')}}</h4>
                </div>
                <hr>
                <div class="col-sm-6">
                  <div class="form-group">
                      <label>{{ trans.get('__JSON__.Store Name') }}(English)</label>
                    <select class="custom-select" v-model="affiliates.restaurant_id" id="restaurant_id" name="restaurant_id" :class="{ 'is-invalid':$v.affiliates.restaurant_id.$error }"> 
                        <option value="">Select Pharmacy </option>
                        <option v-for="restaurants in restaurant" :value="restaurants.restaurant_id" :key="restaurants.restaurant_id">{{restaurants.name}}</option>
                    </select>
                    <div v-if="$v.affiliates.restaurant_id.$error" class="invalid-feedback">
                        <span v-if="!$v.affiliates.restaurant_id.required">{{ trans.get('__JSON__.Please enter Store name') }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label>{{ trans.get('__JSON__.Affiliate Name') }}(English)</label>
                    <input id="name" type="text" class="form-control" :placeholder="trans.get('__JSON__.Enter Affiliate Name')" v-model="affiliates.name" name="name" :class="{ 'is-invalid':$v.affiliates.name.$error }"/>
                    <div v-if="$v.affiliates.name.$error" class="invalid-feedback">
                        <span v-if="!$v.affiliates.name.required">{{ trans.get('__JSON__.Please enter Affiliate name') }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="control-label">{{ trans.get('__JSON__.Contact Number') }}</label>
                    <input id="contact_number" type="text" class="form-control" :placeholder="trans.get('__JSON__.Enter Contact Number')" v-model="affiliates.contact_number" name="contact_number" :class="{ 'is-invalid':$v.affiliates.contact_number.$error }"/>
                      <div v-if="$v.affiliates.contact_number.$error" class="invalid-feedback">
                        <span v-if="!$v.affiliates.contact_number.required">{{ trans.get('__JSON__.Please enter contact number') }}</span>
                        <span v-if="!$v.affiliates.contact_number.numeric">{{ trans.get('__JSON__.This value should be a valid number') }}</span>
                        <span v-else-if="!$v.affiliates.contact_number.minLength">{{ trans.get('__JSON__.The phone number should have at least 10 digits') }}</span>
                        <span v-else-if="!$v.affiliates.contact_number.maxLength">{{ trans.get('__JSON__.The phone number should have at most 10 digits') }}</span>
                      </div>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label>{{ trans.get('__JSON__.Full Address') }}</label>
                    <input id="full_address" type="text" class="form-control" :placeholder="trans.get('__JSON__.Enter Full Address')" v-model="affiliates.full_address" name="full_address" :class="{ 'is-invalid':$v.affiliates.full_address.$error }"/>
                      <div v-if="$v.affiliates.full_address.$error" class="invalid-feedback">
                        <span v-if="!$v.affiliates.full_address.required">{{ trans.get('__JSON__.Please enter Full Address') }}</span>
                      </div>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="control-label">{{ trans.get('__JSON__.Affiliate Refer Code') }}</label>
                        <input id="referral_code" type="text" class="form-control" disabled v-model="affiliates.referral_code" name="referral_code" style="width: 20% !important; background-color : #E8E7E8;"/>
                </div>
                </div>

              </div>
              <div class="row">
                <div class="col-sm-6">
                  <button type="submit" class="btn btn-primary mr-1 mt-3">{{ trans.get('__JSON__.Update Profile') }}</button>
                  <b-button @click="$router.back()" type="button" variant="secondary" class="mt-3">{{ trans.get('__JSON__.Cancel') }}</b-button>
                </div>
              </div>
          </div>
        </div>
      <!-- profile Info End -->
      </form>
    </div>
  </div>
</template>
