import config from '../config'

export default {
    config: config,
}
const permissions = config.permissions;
const base = config.base_folder
export const menuItems = [
    {
        id: 1,
        label: "Dashboard",
        icon: "bx-home-circle",
        badge: {
            'variant'   :  'danger',
            "text"      :   'order'
        },
        subItems: [
            {
                id: 2,
                label: "General",
                link: base+"dashboard",
                parentId: 1,
                required_permission: permissions.dashboard_read,
                permissions: [permissions.dashboard_read]
            },
            {
                id: 3,
                label: "Ordering",
                link: base+"orders/list",
                parentId: 1,
                required_permission: permissions.order_read,
                permissions: [permissions.order_read,permissions.order_write],
                badge: {
                    'variant'   :  'danger',
                    "text"      :   'order'
                }
            },
            {
                id: 5,
                label: "Driver",
                link: base+"driver/dashboard",
                parentId: 1,
                required_permission: permissions.dashboard_driver_read,
                permissions: [permissions.dashboard_driver_read]
            },
            {
                id: 4,
                label: "Eagle View",
                link: base+"eagle-view/list",
                parentId: 1,
                required_permission: permissions.eagle_view_read,
                permissions: [permissions.eagle_view_read]
            } 
        ]
    },
    {
        id: 6,
        label: "Management",
        icon: "bx-user",
        subItems: [
            {
                id: 7,
                label: "Vendors",
                link: base+"vendors",
                parentId: 6,
                required_permission: permissions.merchant_read,
                permissions: [permissions.merchant_read,permissions.merchant_write]
            },
            {
                id: 8,
                label: "Driver",
                link: base+"driver",
                parentId: 6,
                required_permission: permissions.driver_read,
                permissions: [permissions.driver_read,permissions.driver_write]
            },
            {
                id: 9,
                label: "Customer",
                link: base+"customer",
                parentId: 6,
                required_permission: permissions.customer_read,
                permissions: [permissions.customer_read,permissions.customer_write]
            }
            ,
            {
                id: 36,
                label: "Affiliate",
                link: base+"affiliate",
                parentId: 6,
                required_permission: permissions.customer_read,
                permissions: [permissions.customer_read,permissions.customer_write]
            },
            {
                id: 35,
                label: "Master Depot",
                link: base+"master/depot-catalog",
                parentId: 6,
                required_permission: permissions.master_depot_read,
                permissions: [permissions.master_depot_read,permissions.master_depot_write]
            },
            {
                id: 39,
                label: "Brands",
                link: base+"brands",
                parentId: 6,
                required_permission: permissions.master_depot_read,
                permissions: [permissions.master_depot_read,permissions.master_depot_write]
            }
        ]
    },
    {
        id: 14,
        label: "Locations",
        icon: "bx-map",
        subItems: [
            {
                id: 15,
                label: "Location",
                link: base+"location",
                parentId: 14,
                required_permission: permissions.location_read,
                permissions: [permissions.location_read,permissions.location_write]
            },
            {
                id: 16,
                label: "Delivery Zones",
                link: base+"delivery_zone",
                parentId: 14,
                required_permission: permissions.delivery_zone_read,
                permissions: [permissions.delivery_zone_read,permissions.delivery_zone_write]
            }
        ]
    },
    {
        id: 29,
        label: "Promotions",
        icon: "bx-purchase-tag",
        subItems: [
            {
                id: 30,
                label: "Promocode",
                link: base+"coupon",
                parentId: 29,
                required_permission: permissions.promo_code_read,
                permissions: [permissions.promo_code_read,permissions.promo_code_write]
            },
            {
                id: 31,
                label: "Banners",
                link: base+"banner",
                parentId: 29,
                required_permission: permissions.banner_read,
                permissions: [permissions.banner_read,permissions.banner_write]
            }
        ]
    },
    {
        id: 10,
        label: "Financial Reports",
        icon: "bx-file",//bx-rupee
        subItems: [
            {
                id: 11,
                label: "Vendors",
                link: base+"finance/vendor",
                parentId: 10,
                required_permission: permissions.vendor_finance_read,
                permissions: [permissions.vendor_finance_read,permissions.vendor_finance_write]
            },
            {
                id: 12,
                label: "Drivers",
                link: base+"finance/driver",
                parentId: 10,
                required_permission: permissions.driver_finance_read,
                permissions: [permissions.driver_finance_read,permissions.driver_finance_write]
            },
            {
                id: 13,
                label: "Reports",
                link: base+"reports",
                parentId: 10,
                required_permission: permissions.reports_read,
                permissions: [permissions.reports_read,permissions.reports_write]
            }
        ]
    },   
    {
        id: 17,
        label: "Contents",
        icon: "bx-home-circle",
        subItems: [
            {
                id: 18,
                label: "Business Category",
                link: base+"category",
                parentId: 17,
                required_permission: permissions.category_read,
                permissions: [permissions.category_read,permissions.category_write]

            },
            {
                id: 19,
                label: "Tag",
                link: base+"cuisines",
                parentId: 17,
                required_permission: permissions.cuisine_read,
                permissions: [permissions.cuisine_read,permissions.cuisine_write]
            },
            {
                id: 20,
                label: "Pages",
                link: base+"content-page",
                parentId: 17,
                required_permission: permissions.content_pages_read,
                permissions: [permissions.content_pages_read,permissions.content_pages_write]
            },
            {
                id: 21,
                label: "FAQ",
                link: base+"faq",
                parentId: 17,
                required_permission: permissions.faq_read,
                permissions: [permissions.faq_read,permissions.faq_write]
            },
            {
                id: 38,
                label: "Blogs",
                link: base+"blog",
                parentId: 17,
                required_permission: permissions.content_pages_read,
                permissions: [permissions.content_pages_read,permissions.content_pages_write]
            }
        ]
    },
    {
        id: 24,
        label: "Settings",
        icon: "bx-laptop",
        subItems: [
            {
                id: 25,
                label: "Configurations",
                link: base+"settings/configurations",
                parentId: 24,
                required_permission: permissions.configuration_read,
                permissions: [permissions.configuration_read,permissions.configuration_write]
            },
            {
                id: 26,
                label: "App Layout Settings",
                link: base+"settings/app-layout-setting",
                parentId: 24,
                required_permission: permissions.layout_read,
                permissions: [permissions.layout_read,permissions.layout_write]
            },
            {
                id: 34,
                label: "Web Configurations",
                link: base+"settings/web-layout-setting",
                parentId: 24,
                required_permission: permissions.web_layout_read,
                permissions: [permissions.web_layout_read,permissions.web_layout_write]
            },
            {
                id: 27,
                label: "User & Access",
                link: base+"settings/user-access",
                parentId: 24,
                required_permission: permissions.user_access_read,
                permissions: [permissions.user_access_read,permissions.user_access_write]

            },
            {
                id: 28,
                label: "Terminology",
                link: base+"settings/terminology",
                parentId: 24,
                required_permission: permissions.terminology_read,
                permissions: [permissions.terminology_read,permissions.terminology_write]
            },
            {
                id: 37,
                label:"Profile Menu",
                link: base+"settings/profilemenu",
                parentId: 24,
                required_permission: permissions.terminology_read,
                permissions: [permissions.terminology_read,permissions.terminology_write]
            },
           
        ]
    },
    {
        id: 22,
        label: "Others",
        icon: "bx-file",
        subItems: [
            {
                id: 23,
                label: "Logs",
                link: base+"system/list",
                parentId: 22,
                required_permission: permissions.logs_read,
                permissions: [permissions.logs_read,permissions.logs_write]
            },
            {
                id: 33,
                label: "Call Center",
                link: base+"call-center/dashboard",
                parentId: 22,
                required_permission: permissions.call_center_read,
                permissions: [permissions.call_center_read,permissions.call_center_write]
            },
        ]
    }
];
