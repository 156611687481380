
<script>

  import Layout from '../../layouts/main'
  import PageHeader from '../../components/page-header'
  import AffiliateAdd from '../../views/affiliate/affiliate-add.vue';

  export default {
    components: {Layout, PageHeader, AffiliateAdd},
    data() {
      return {};
    },
  }
</script>
<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
        >
          <div class="page-title-left avatar-main-icon">
            <div class="avatar-xs mr-3">
              <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                <i class="bx bx-user"></i>
              </span>
            </div>
            <div class="report-tag">
              <h4 class="mb-0 font-size-18">ADD AFFILIATE</h4>
              <p><router-link :to="{ name: 'affiliate-list' }">{{ trans.get('__JSON__.Affiliates') }}</router-link> > Add Affiliate</p>
          </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <affiliate-add />
      </div>      
    </div>
  </Layout>
</template>
