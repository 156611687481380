<script>
import DatePicker from "vue2-datepicker";
import PageHeader from "../../components/page-header";
import pagination from "laravel-vue-pagination";
import Layout from "../../layouts/main";

import { vendorFinanceService, dashboardService } from "./../../services";
import { get_currency, get_decimal, toKebabCase } from "../../Helper/helper";
import { columnDeliveredOrderDataChart } from "../../views/data-apex";
/**
 * Starter component
 */
export default {
  components: { Layout, PageHeader, pagination, DatePicker },
  data() {
    return {
      columnDeliveredOrderDataChart: columnDeliveredOrderDataChart,
      columnSalesDataChart: columnDeliveredOrderDataChart,
      columnEarningDataChart: columnDeliveredOrderDataChart,
      columnCancelOrderDataChart: columnDeliveredOrderDataChart,
      year: this.moment().format("YYYY"),
      years: [],
      chart: 'sales',
      series: [],
      restaurant_id: "",
      sortBy: "delivered_date",
      sortDesc: true,
      limit: 1,
      currency: get_currency(),
      decimal: get_decimal(),
      vendorsData: [],
      dayWiseFinance: null,
      monthWiseFinance: {},
      isDownloading: false,
      loading: true,
      chartOptions: {
        chart: {
            toolbar: {
                show: false,
            }
        },
        plotOptions: {
            bar: {
                dataLabels: {
                    position: 'top', // top, center, bottom
                },
            }
        },
        dataLabels: {
            enabled: true,
            offsetY: -20,
            style: {
                fontSize: '12px',
                colors: ["#34c38f"]
            }
        },
        colors: ['#34c38f'],
        grid: {
            borderColor: '#f1f1f1',
        },
        xaxis: {
         title : {
                formatter: function(i) {
                    let start = moment().startOf('month');
                    for (i = 0; i < 12; i++)
                    {
                        return start.subtract(1, 'month').format('MM/YY')
                    }
                },
            },
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            },
            crosshairs: {
                fill: {
                    type: 'gradient',
                    gradient: {
                        colorFrom: '#D8E3F0',
                        colorTo: '#BED1E6',
                        stops: [0, 100],
                        opacityFrom: 0.4,
                        opacityTo: 0.5,
                    }
                }
            },
            tooltip: {
                enabled: true,
                offsetY: -35,

            }
        },
        fill: {
            gradient: {
                shade: 'light',
                type: "horizontal",
                shadeIntensity: 0.25,
                gradientToColors: undefined,
                inverseColors: true,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [50, 0, 100, 100]
            },
        },
        yaxis: {
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false,
            },
            labels: {
                show: false
            }

        },
        title: {
            floating: false,
            offsetY: 100,
            align: 'center',
            style: {
                color: '#444'
            }
        },
      },
      echartOptions: {
        chart: {
            toolbar: {
                show: false,
            }
        },
        plotOptions: {
            bar: {
                dataLabels: {
                    position: 'top', // top, center, bottom
                },
            }
        },
        dataLabels: {
            enabled: true,
            offsetY: -20,
            style: {
                fontSize: '12px',
                colors: ["#f1b44c"]
            }
        },
        colors: ['#f1b44c'],
        grid: {
            borderColor: '#f1f1f1',
        },
        xaxis: {
         title : {
                formatter: function(i) {
                    let start = moment().startOf('month');
                    for (i = 0; i < 12; i++)
                    {
                        return start.subtract(1, 'month').format('MM/YY')
                    }
                },
            },
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            },
            crosshairs: {
                fill: {
                    type: 'gradient',
                    gradient: {
                        colorFrom: '#D8E3F0',
                        colorTo: '#BED1E6',
                        stops: [0, 100],
                        opacityFrom: 0.4,
                        opacityTo: 0.5,
                    }
                }
            },
            tooltip: {
                enabled: true,
                offsetY: -35,

            }
        },
        fill: {
            gradient: {
                shade: 'light',
                type: "horizontal",
                shadeIntensity: 0.25,
                gradientToColors: undefined,
                inverseColors: true,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [50, 0, 100, 100]
            },
        },
        yaxis: {
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false,
            },
            labels: {
                show: false
            }

        },
        title: {
            floating: false,
            offsetY: 100,
            align: 'center',
            style: {
                color: '#444'
            }
        },
      },
      cchartOptions: {
        chart: {
            toolbar: {
                show: false,
            }
        },
        plotOptions: {
            bar: {
                dataLabels: {
                    position: 'top', // top, center, bottom
                },
            }
        },
        dataLabels: {
            enabled: true,
            offsetY: -20,
            style: {
                fontSize: '12px',
                colors: ["#f46a6a"]
            }
        },
        colors: ['#f46a6a'],
        grid: {
            borderColor: '#f1f1f1',
        },
        xaxis: {
         title : {
                formatter: function(i) {
                    let start = moment().startOf('month');
                    for (i = 0; i < 12; i++)
                    {
                        return start.subtract(1, 'month').format('MM/YY')
                    }
                },
            },
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            },
            crosshairs: {
                fill: {
                    type: 'gradient',
                    gradient: {
                        colorFrom: '#D8E3F0',
                        colorTo: '#BED1E6',
                        stops: [0, 100],
                        opacityFrom: 0.4,
                        opacityTo: 0.5,
                    }
                }
            },
            tooltip: {
                enabled: true,
                offsetY: -35,

            }
        },
        fill: {
            gradient: {
                shade: 'light',
                type: "horizontal",
                shadeIntensity: 0.25,
                gradientToColors: undefined,
                inverseColors: true,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [50, 0, 100, 100]
            },
        },
        yaxis: {
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false,
            },
            labels: {
                show: false
            }

        },
        title: {
            floating: false,
            offsetY: 100,
            align: 'center',
            style: {
                color: '#444'
            }
        },
      },
      fields: [
        {
          key: "month",
          label: this.trans.get("__JSON__.Month"),
          sortable: true,
          class: "bold-text",
        },
        {
          key: "item_mrp_total",
          label: this.trans.get("__JSON__.Item MRP"),
          sortable: false,
        },
        {
          key: "item_total",
          label: this.trans.get("__JSON__.Item Price"),
          sortable: false,
        },
        {
          key: "itemtax_total",
          label: this.trans.get("__JSON__.Item") + " " + this.$auth.getTaxName(),
          sortable: false,
        },
        {
          key: "delivery_fee",
          label: this.trans.get("__JSON__.Delivery Charges"),
          sortable: false,
        },
        {
          key: "service_fee",
          label: this.trans.get("__JSON__.Cart") + " " + this.$auth.getTaxName(),
          sortable: false,
        },
        {
          key: "discount",
          label: this.trans.get("__JSON__.Coupon Discount"),
          sortable: false,
        },
        {
          key: "amount",
          label: this.trans.get("__JSON__.Total Amount"),
          sortable: false,
        },
        {
          key: "admin_commision",
          label: this.trans.get("__JSON__.Admin Commission") + " (Order)",
          sortable: false,
        },
        {
          key: "driver_eanring_total",
          label: this.trans.get("__JSON__.Admin Commission") + " (Delivery)",
          sortable: false,
        },
        {
          key: "chefEarning",
          label: this.trans.get("__JSON__.Vendor Earning",{vendor: this.$auth.getRestaurantName()}),
          sortable: false,
        },
        {
          key: "adminEarning",
          label: this.trans.get("__JSON__.Admin Earning"),
          sortable: false,
        },
        {
          key: "action",
          label: this.trans.get("__JSON__.Action"),
          sortable: false,
        },
      ],
    };
  },
  mounted() {
    this.getVendorFinance();
    this.getYears();
    this.getChart();
  },
  methods: {
    getChart(){
        if(this.chart == 'order')
        {
           dashboardService.getMonthWiseOrder({
                chart: this.chart
              }).then((response)=>{
                  this.series = [{
                    name: 'Delivered Order',
                    data: response.data.result
                }];
              })
        }
        else if(this.chart == 'sales')
        {
           dashboardService.getMonthWiseSales({
             chart: this.chart
              }).then((response)=>{
                  this.series = [{
                    name: 'Sales',
                    data: response.data.result
                }];
              })
        }
        else if(this.chart == 'earning')
        {
            dashboardService.getMonthWiseEarning({
             chart: this.chart
              }).then((response)=>{
                  this.series = [{
                    name: 'Earning',
                    data: response.data.result
                }];
              })
        }
        else if(this.chart == 'cancelled')
        {
            dashboardService.getMonthWiseCancelOrder({
             chart: this.chart
              }).then((response)=>{
                  this.series = [{
                    name: 'Cancel Order',
                    data: response.data.result
                }];
              })
        }
    },
    getYears() {
      var year = 2017;
      var years = [];
      while (year != this.moment().format("YYYY")) {
        years.push(++year);
      }
      this.years = years.reverse();
    },
    sortingChanged(ctx) {
      // this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      this.getVendorFinance();
    },
    getVendorFinance(page = 1) {
      this.loading = true;
      vendorFinanceService
        .getVendorFinance({
          sortBy: this.sortBy,
          orderBy: this.sortDesc ? "desc" : "asc",
          page: page ? page : 1,
          year: this.year,
        })
        .then((res) => {
          this.loading = false;
          this.vendorsData = res.data.result;
          this.dayWiseFinance = res.data.result.finances;
          this.monthWiseFinance = res.data.result.monthWiseFinance;
        });
    },
    removeCommas(text) {
      return text.replaceAll(",", "");
    },
    downloadsCSV: function () {
      this.isDownloading = true;
      vendorFinanceService
        .getVendorFinanceCsv({
          sortBy: this.sortBy,
          orderBy: this.sortDesc ? "desc" : "asc",
          year: this.year,
        })
        .then((res) => {
          this.isDownloading = false;
          const monthWise = res.data.result.monthWiseFinance;
          let vendorsData = '\ufeff' + 
          ''+','
          +'IM'+','
          +'IP'+','
          +'IT'+','
          +'DC'+','
          +'CT'+','
          +'CD'+','
          +'TA = IP + DC - CD'+','
          +'ACO = (IM % Commission/ Fix)'+','
          +'ACD = DC - FME'+','
          +'VE = IP - ACO'+','
          +'AE = ACO + ACD - CD'+','+'\n'
          
          vendorsData +=
            ''+ this.trans.get("__JSON__.Month")+','
            +this.trans.get("__JSON__.Item MRP")+','
            +this.trans.get("__JSON__.Item Price")+','
            +this.trans.get("__JSON__.Item")+ " " + this.$auth.getTaxName()+','
            +this.trans.get("__JSON__.Delivery Charges")+','
            +this.trans.get("__JSON__.Cart")+ " " + this.$auth.getTaxName()+','
            +this.trans.get("__JSON__.Coupon Discount")+','
            +this.trans.get("__JSON__.Total Amount")+','
            +this.trans.get("__JSON__.Admin Commission")+' (Order),'
            +this.trans.get("__JSON__.Admin Commission")+' (Delivery),'
            +this.trans.get("__JSON__.Vendor Earning",{vendor: this.$auth.getRestaurantName()})
            +this.trans.get("__JSON__.Admin Earning")            
            +'\n';
          monthWise.forEach((element) => {
            var line =
              element.month +
              "," +
              element["item_mrp_total"].toFixed(this.decimal) +
              "," +
              element["item_total"].toFixed(this.decimal) +
              "," +
              element["itemtax_total"].toFixed(this.decimal) +
              "," +
              element["delivery_fee"].toFixed(this.decimal) +
              "," +
              element["service_fee"].toFixed(this.decimal) +
              "," +
              element["discount"].toFixed(this.decimal) +
              "," +
              element["amount"].toFixed(this.decimal) +
              "," +
              element["admin_commision"].toFixed(this.decimal) +
              "," +
              element["driver_eanring_total"].toFixed(this.decimal) +
              "," +
              element["chefEarning"].toFixed(this.decimal) +
              "," +
              element["adminEarning"].toFixed(this.decimal) +
              "\n";
            vendorsData += line;
          });
          var blob = new Blob([vendorsData], { type: "csv/plain" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = toKebabCase(this.$auth.getRestaurantName()) + "-finance-" + this.year + ".csv";
          link.click();
        });
    },
    disabledDate(date) {
      return this.moment(date).format("YYYY") > this.moment().format("YYYY");
    },
  },
};
</script>
<template>
  <Layout>
     <div class="row">
          <div class="col-12">
              <div
              class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
              >
              <div class="page-title-left">
                  <div class="avatar-xs mr-3">
                  <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                      <i class="bx bx-purchase-tag"></i>
                  </span>
                  </div>
                  <h4 class="mb-0 font-size-18">{{ trans.trans("__JSON__.Vendor Finance",{ vendor: $auth.setting.restaurant_name }) }}</h4>
                </div>
              </div>
          </div>
      </div>
  
    <!-- Chart -->
    
      <div class="row chart-loader" v-if="!$auth.partner_login">
          <div class="col-lg-12">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-10" v-if="chart == 'order'">
                    <h4 class="card-title mb-4">{{ trans.get('__JSON__.Last 12 Months Orders') }}</h4>
                  </div>
                    <div class="col-md-10" v-else-if="chart == 'sales'">
                    <h4 class="card-title mb-4">{{ trans.get('__JSON__.Last 12 Months Sales') }}</h4>
                  </div>
                    <div class="col-md-10" v-else-if="chart == 'earning'">
                    <h4 class="card-title mb-4">{{ trans.get('__JSON__.Last 12 Months Earning') }}</h4>
                  </div>
                    <div class="col-md-10" v-else-if="chart == 'cancelled'">
                    <h4 class="card-title mb-4">{{ trans.get('__JSON__.Last 12 Months Cancelled Orders') }}</h4>
                  </div>
                  <div class="col-md-2 float-right">
                    <select class="custom-select" v-model="chart" @change="getChart">
                      <option value="sales" selected>{{ trans.get('__JSON__.Sales') }}</option>
                      <option value="earning">{{ trans.get('__JSON__.Your Earning') }}</option>
                      <option value="order">{{ trans.get('__JSON__.Delivered Orders') }}</option>
                      <option value="cancelled">{{ trans.get('__JSON__.Cancelled Orders') }}</option>
                    </select>
                  </div>
                </div>
                <!-- Column with Data Labels -->
                <div v-if="chart == 'order'">
                  <apexchart
                    class="apex-charts"
                    height="350"
                    type="bar"
                    dir="ltr"
                    :series="series"
                    :options="columnDeliveredOrderDataChart.chartOptions"
                  ></apexchart>
                </div>
                <div v-else-if="chart == 'sales'">
                  <apexchart
                    class="apex-charts"
                    height="350"
                    type="bar"
                    dir="ltr"
                    :series="series"
                    :options="chartOptions"
                  ></apexchart>
                </div>
                <div v-else-if="chart == 'earning'">
                  <apexchart
                    class="apex-charts"
                    height="350"
                    type="bar"
                    dir="ltr"
                    :series="series"
                    :options="echartOptions"
                  ></apexchart>
                </div>
                <div v-else-if="chart == 'cancelled'">
                  <apexchart
                    class="apex-charts"
                    height="350"
                    type="bar"
                    dir="ltr"
                    :series="series"
                    :options="cchartOptions"
                  ></apexchart>
                </div>
              </div>
            </div>
          </div>
      </div>

      <div class="row">
        <div class="col-xl-12">
          <div class="row">
            <div class="col-sm-3">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex align-items-center mb-3">
                    <div class="avatar-xs mr-3">
                      <span
                        class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18"
                      >
                        <i class="bx bx-dollar"></i>
                      </span>
                    </div>
                    <h5 class="font-size-14 mb-0">{{trans.get('__JSON__.Today')}}</h5>
                  </div>
                  <div class="text-muted">
                      <h4>
                        <span v-html="currency"></span>
                        {{dayWiseFinance && dayWiseFinance.today || 0}}
                      </h4>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-3">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex align-items-center mb-3">
                    <div class="avatar-xs mr-3">
                      <span
                        class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18"
                      >
                        <i class="bx bx-dollar"></i>
                      </span>
                    </div>
                    <h5 class="font-size-14 mb-0">{{trans.get('__JSON__.7 Days')}}</h5>
                  </div>
                  <div class="text-muted">
                    <h4>
                      <span v-html="currency"></span>
                      {{dayWiseFinance && dayWiseFinance.lastWeek || 0}}
                    </h4>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-3">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex align-items-center mb-3">
                    <div class="avatar-xs mr-3">
                      <span
                        class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18"
                        >
                        <i class="bx bx-dollar"></i></span>
                    </div>
                    <h5 class="font-size-14 mb-0">{{trans.get('__JSON__.This Month')}}</h5>
                  </div>
                  <div class="text-muted">
                    <h4>
                      <span v-html="currency"></span>
                      {{dayWiseFinance && dayWiseFinance.thisMonth || 0}}
                    </h4>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-3">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex align-items-center mb-3">
                    <div class="avatar-xs mr-3">
                      <span
                        class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18"
                      >
                        <i class="bx bx-dollar"></i>
                      </span>
                    </div>
                    <h5 class="font-size-14 mb-0">{{trans.get('__JSON__.Last Month')}}</h5>
                  </div>
                  <div class="text-muted">
                    <h4>
                      <span v-html="currency"></span>
                      {{dayWiseFinance && dayWiseFinance.lastMonth || 0}}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- end row -->
        </div>
      </div>
    <!-- end row -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-3">
              <div class="col-sm-2 offset-sm-8 mt-4">
                <button
                  @click="downloadsCSV"
                  class="btn btn-primary btn-rounded export-btn"
                  v-if="isDownloading"
                  disabled
                >{{trans.get('__JSON__.Exporting...')}}</button>
                <button
                  @click="downloadsCSV"
                  class="btn btn-primary btn-rounded export-btn"
                  v-else
                >{{trans.get('__JSON__.Export To CSV')}}</button>
                <button
                  @click="downloadsCSV"
                  class="btn btn-primary btn-rounded m-export-btn"
                ><i class="bx bx-archive-in"></i></button>
              </div>
              <div class="col-sm-2">
                <label for>{{trans.get('__JSON__.Year')}}</label>
                <div class="text-sm-right">
                  <div class="search-box mr-2">
                    <date-picker
                      v-model="year"
                      type="year"
                      value-type="format"
                      lang="en"
                      :disabledDate="disabledDate"
                      :clearable="false"
                      @change="getVendorFinance()"
                      confirm
                      :placeholder="trans.get('Select Year')"
                    ></date-picker>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="table-responsive table-border">
                  <b-table
                    class="table-centered table-nowrap table-hover order-menu"
                    :items="monthWiseFinance.data"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :no-local-sorting="true"
                    @sort-changed="sortingChanged"
                    :busy="loading"
                    show-empty
                  >
                    <template #thead-top>
                      <b-tr class="formulas">
                        <b-th></b-th>
                        <b-th>IM</b-th>
                        <b-th>IP</b-th>
                        <b-th>IT</b-th>
                        <b-th>DC</b-th>
                        <b-th>CT</b-th>
                        <b-th>CD</b-th>
                        <b-th>TA = IP + DC - CD</b-th>
                        <b-th>ACO = (IM % Commission/ Fix)</b-th>
                        <b-th>ACD = DC - FME</b-th>
                        <b-th>VE = IP - ACO</b-th>
                        <b-th>AE = ACO + ACD - CD</b-th>
                        <b-th></b-th>
                      </b-tr>
                    </template>
                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>{{trans.get('__JSON__.Loading...')}}</strong>
                      </div>
                    </template>
                    <template #cell(month)="data">
                      <span class="font-weight-bold">{{ data.value }}</span>
                    </template>
                    <template #cell(item_mrp_total)="data">
                      <span v-html="currency"></span>
                      {{ data.value.toFixed(decimal) }}
                    </template>
                    <template #cell(item_total)="data">
                      <span v-html="currency"></span>
                      {{ data.value.toFixed(decimal) }}
                    </template>
                    <template #cell(itemtax_total)="data">
                      <span v-html="currency"></span>
                      {{ data.value.toFixed(decimal) }}
                    </template>
                    <template #cell(service_fee)="data">
                      <span v-html="currency"></span>
                      {{ data.value.toFixed(decimal) }}
                    </template>
                    <template #cell(delivery_fee)="data">
                      <span v-html="currency"></span>
                      {{ data.value.toFixed(decimal) }}
                    </template>
                    <template #cell(discount)="data">
                      <span v-html="currency"></span>
                      {{ data.value.toFixed(decimal) }}
                    </template>
                    <template #cell(amount)="data">
                      <span v-html="currency"></span>
                      {{ data.value.toFixed(decimal) }}
                    </template>
                    <template #cell(admin_commision)="data">
                      <span v-html="currency"></span>
                      {{ data.value.toFixed(decimal) }}
                    </template>
                    <template #cell(driver_eanring_total)="data">
                      <span v-html="currency"></span>
                      {{ data.value.toFixed(decimal) }}
                    </template>
                    <template #cell(chefEarning)="data">
                      <span v-html="currency"></span>
                      {{ data.value.toFixed(decimal) }}
                    </template>
                    <template #cell(adminEarning)="data">
                      <span v-html="currency"></span>
                      {{ data.value.toFixed(decimal) }}
                    </template>
                    <template #cell(action)="data">
                      <router-link
                        :to="{name: 'vendor-finance-month',params: {month: data.item.month}}" 
                        class="btn btn-primary btn-sm btn-rounded" v-if="!$auth.partner_login"
                      >{{trans.get('__JSON__.View')}}</router-link>
                      <router-link :to="{name:'vendor.restaurant.finance.list',params: {restaurant_id : base64(data.item.restaurant_id) }}" class="btn btn-primary btn-sm btn-rounded" v-if="$auth.partner_login">
                          View
                      </router-link>
                    </template>
                  </b-table>
                </div>
              </div>
              <div class="col-12">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <pagination
                      :data="monthWiseFinance"
                      :limit="limit"
                      @pagination-change-page="getVendorFinance"
                    ></pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<style scoped>
.formulas th{
  font-size: 10px;
}
</style>