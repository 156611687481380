<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";

import DatePicker from "vue2-datepicker";
import pagination from "laravel-vue-pagination";
import { driverService } from "../../services";
import { get_currency, get_decimal, toKebabCase } from "../../Helper/helper";

/**
 * Starter component
 */
export default {
  components: { Layout, PageHeader, pagination, DatePicker },
  data() {
    return {
      year: this.moment().format("YYYY"),
      years: [],
      sortBy: "delivered_date",
      sortDesc: true,
      limit: 1,
      currency: get_currency(),
      decimal: get_decimal(),
      driverData: null,
      financeDays: null,
      loading: true,
      fields: [
        {
          key: "month",
          label: this.trans.get("__JSON__.Month"),
          sortable: true,
          class: "bold-text",
        },
        {
          key: "order_count",
          label: this.trans.get("__JSON__.Delivered Order"),
          sortable: false,
        },
        {
          key: "driver_earning",
          label: this.trans.get("__JSON__.Earning"),
          sortable: false,
        },
        {
          key: "cod_amount",
          label: this.trans.get("__JSON__.Total Wallet Amount"),
          sortable: false,
        },
        {
          key: "pending_amount",
          label: this.trans.get("__JSON__.Pending Wallet Amount"),
          sortable: false,
        },
        {
          key: "view",
          label: this.trans.get("__JSON__.View"),
          sortable: false,
        },
      ],
    };
  },
  mounted() {
    this.getDriverFinance();
    this.getDriverFinanceDays();
  },
  methods: {
    sortingChanged(ctx) {
      // this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      this.getDriverFinance();
    },
    downloadsCSV: function () {
      let driverData =
        "\ufeff" +
        "Month,Delivered Order,Earning,Total Wallet Amount,Pending Wallet Amount\n";
      this.driverData.data.map((el) => {
        var line =
          el["month"] +
          "," +
          el["order_count"] +
          "," +
          el["driver_earning"].toFixed(this.decimal) +
          "," +
          el["cod_amount"].toFixed(this.decimal) +
          "," +
          el["pending_amount"].toFixed(this.decimal) +
          "\n";
        driverData += line;
      });

      var blob = new Blob([driverData], { type: "csv/plain" });
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = toKebabCase(this.$auth.getDriverName())+"-finance-" + this.year + ".csv";
      link.click();
    },
    getDriverFinance(page = 1) {
      this.loading = true;
      driverService
        .getDriverFinance({
          sortBy: this.sortBy,
          orderBy: this.sortDesc ? "desc" : "asc",
          page: page ? page : 1,
          year: this.year,
        })
        .then((res) => {
          this.loading = false;
          this.driverData = res.data.result;
        });
    },
    getDriverFinanceDays() {
      driverService.getDriverFinanceDays().then((res) => {
        this.financeDays = res.data.result;
      });
    },
    disabledDate(date) {
      return this.moment(date).format("YYYY") > this.moment().format("YYYY");
    },
  },
};
</script>
<template>
  <Layout>
     <div class="row">
          <div class="col-12">
              <div
              class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
              >
              <div class="page-title-left">
                  <div class="avatar-xs mr-3">
                  <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                      <i class="bx bx-purchase-tag"></i>
                  </span>
                  </div>
                  <h4 class="mb-0 font-size-18">{{ trans.trans('__JSON__.Driver Finance',{ driver: $auth.setting.driver_name }) }}</h4>
              </div>
               <div class="page-title-right">
                  <button
                    @click="downloadsCSV"
                    class="btn btn-primary btn-rounded export-btn"
                  >{{trans.get('__JSON__.Export To CSV')}}</button>
                   <button
                    @click="downloadsCSV"
                    class="btn btn-primary btn-rounded m-export-btn"
                  ><i class="bx bx-archive-in"></i></button>
                </div>
              </div>
          </div>
      </div>
    <div class="row" v-if="financeDays">
      <div class="col-xl-12">
        <div class="row">
          <div class="col-sm-3">
            <div class="card">
              <div class="card-body">
                <div class="d-flex align-items-center mb-3">
                  <div class="avatar-xs mr-3">
                    <span
                      class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18"
                    >
                      <i class="bx bx-dollar"></i>
                    </span>
                  </div>
                  <h5 class="font-size-14 mb-0">{{trans.get('__JSON__.Today')}}</h5>
                </div>
                <div class="text-muted">
                     <h4>
                      <span v-html="currency"></span>
                      {{financeDays.s_today.toFixed(decimal)}}
                    </h4>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-3">
            <div class="card">
              <div class="card-body">
                <div class="d-flex align-items-center mb-3">
                  <div class="avatar-xs mr-3">
                    <span
                      class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18"
                    >
                      <i class="bx bx-dollar"></i>
                    </span>
                  </div>
                  <h5 class="font-size-14 mb-0">{{trans.get('__JSON__.7 Days')}}</h5>
                </div>
                <div class="text-muted">
                  <h4>
                    <span v-html="currency"></span>
                    {{financeDays.s_week.toFixed(decimal)}}
                  </h4>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-3">
            <div class="card">
              <div class="card-body">
                <div class="d-flex align-items-center mb-3">
                  <div class="avatar-xs mr-3">
                    <span
                      class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18"
                      >
                      <i class="bx bx-dollar"></i></span>
                  </div>
                  <h5 class="font-size-14 mb-0">{{trans.get('__JSON__.This Month')}}</h5>
                </div>
                <div class="text-muted">
                  <h4>
                    <span v-html="currency"></span>
                    {{financeDays.s_month.toFixed(decimal)}}
                  </h4>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-3">
            <div class="card">
              <div class="card-body">
                <div class="d-flex align-items-center mb-3">
                  <div class="avatar-xs mr-3">
                    <span
                      class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18"
                    >
                      <i class="bx bx-dollar"></i>
                    </span>
                  </div>
                  <h5 class="font-size-14 mb-0">{{trans.get('__JSON__.Last Month')}}</h5>
                </div>
                <div class="text-muted">
                   <h4>
                    <span v-html="currency"></span>
                    {{financeDays.last_month.toFixed(decimal)}}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end row -->
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-3">
              <div class="col-sm-2 offset-sm-10">
                <label for>{{trans.get('__JSON__.Year')}}</label>
                <div class="text-sm-right">
                  <div class="search-box mr-2">
                    <date-picker
                      v-model="year"
                      type="year"
                      value-type="format"
                      lang="en"
                      :disabledDate="disabledDate"
                      :clearable="false"
                      @change="getDriverFinance()"
                      confirm
                      :placeholder="trans.get('Select Year')"
                    ></date-picker>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="table-responsive table-border" v-if="driverData">
                  <b-table
                    :items="driverData.data"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :no-local-sorting="true"
                    @sort-changed="sortingChanged"
                    :busy="loading"
                    show-empty
                    class="table-centered table-nowrap table-hover order-menu"
                  >
                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>{{trans.get('__JSON__.Loading...')}}</strong>
                      </div>
                    </template>
                    <template #cell(month)="data">
                      <span class="font-weight-bold">{{ data.value }}</span>
                    </template>
                    <template #cell(order_count)="data">{{ data.value }}</template>
                    <template #cell(driver_earning)="data">
                      <span v-html="currency"></span>
                      {{ data.value.toFixed(decimal) }}
                    </template>
                    <template #cell(cod_amount)="data">
                      <span v-html="currency"></span>
                      {{ data.value.toFixed(decimal) }}
                    </template>
                    <template #cell(pending_amount)="data">
                      <span v-html="currency"></span>
                      {{ data.value.toFixed(decimal) }}
                    </template>
                    <template #cell(view)="data">
                      <router-link
                        :to="{name: 'driver-finance-month',params: {month: data.item.month}}"
                        class="btn btn-primary btn-sm btn-rounded"
                      >{{trans.get('__JSON__.View')}}</router-link>
                    </template>
                  </b-table>
                </div>
              </div>
              <div class="col-12">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0" v-if="driverData">
                    <!-- pagination -->
                    <pagination
                      :data="driverData"
                      :limit="limit"
                      @pagination-change-page="getDriverFinance"
                    ></pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>