<script>
import config from "../../config";
import { commonService, vendorService} from "../../services";
import { clientService } from "../../services";
import { error_message, success_message } from "../../Helper/helper";
import { required, email, sameAs, numeric, requiredIf } from "vuelidate/lib/validators";
import DropzonePopup from "../../components/widgets/dropzone-popup";
import { routes } from "../../config/api_routes";
import Multiselect from "vue-multiselect";

export default {
  components: { DropzonePopup , Multiselect, clientService },
  data() {
    return {
      config: config,
      isLoading: false,
      error: "",
      typeform: {
        app_name:"",
        vendor_name: "",
        vendor_number: "",
        email: "",
        password: "",
        confirm_password: "",
        subscription_plan_type: "premium",
        product_type_id: "",
        business_type_id: "",
        // app_logo: "",
        // flat_icon: "",
        country_code: "",
        timezone: "",
        currency: "",
        currency_short_name: "",
        website_url: ""
      },
      // app_logo_image: "",
      // app_logo_image_path: "",
      // app_logo_image_delete_url: routes.deleteAppLogoApi,
      // flat_icon_image: "",
      // flat_icon_image_path: "",
      // flat_icon_image_delete_url: routes.deleteFlatIconApi,
      currencies: [],
      currency: [],
      timezones: [],
      timezone: [],
      countrycodes: [],
      countries: [],
      logo: "",
      frontUrl: "",
      default_appname: "",
      termscondition: "",
      termscondition_error: false,
      isSubmitDisable: false,
      product_type_list: [],
      business_type_list: []
    };
  },
  validations: {
    typeform: {
      app_name: {
        required,
      },
      vendor_name: {
        required,
      },
      vendor_number: {
        required,
        numeric
      },
      email: {
        required,
        email,
      },
      password: {
        required,
      },
      confirm_password: {
        required,
        sameAsPassword: sameAs("password"),
      },
      subscription_plan_type:{
        required,
      },
      product_type_id:{
        required,
      },
      business_type_id:{
        required,
      },
      // app_logo: {
      //   required:requiredIf(function (nestedModel) {
      //     return !(!!this.app_logo_image || !!this.typeform.app_logo)
      //   })
      // },
      // flat_icon: {
      //   required:requiredIf(function (nestedModel) {
      //     return !(!!this.flat_icon_image || !!this.typeform.flat_icon)
      //   })
      // },
      country_code: {
        required,
      },
      timezone: {
        required,
      },
      currency: {
        required,
      },
      website_url: {}
    },
  },
  mounted(){
    this.getProductBusinessTypes();
    this.getLanguage();
  },
  methods: {
    getProductBusinessTypes() {
      clientService.getProductBusinessTypes().then((response) => {
        if (response.data.result.product_type_list) {
          this.product_type_list = response.data.result.product_type_list;
        }
        if (response.data.result.business_type_list) {
          this.business_type_list = response.data.result.business_type_list;
        }
      });
    },
    nameWithLang ({ code, name }) {
      return `(+${code}) ${name}`
    },
    asyncFind(query) {
      this.isLoading = true;
      vendorService.getLanguage(query).then((response) => {
        this.countrycodes  = response.data.result.country_code;
        this.isLoading = false;
      });
    },
    clearAll() {
      this.countries = [];
    },
    nameWithCurrency ({ symbol,name }) {
      return `${symbol} (${name})`
    },
    asyncCurrencyFind(query) {
      this.isLoading = true;
      vendorService.getLanguage(query).then((response) => {
        this.currencies  = response.data.result.currency;
        this.isLoading = false;
      });
    },
    clearAll() {
      this.currency = [];
    },
    asyncZoneFind(query) {
      this.isLoading = true;
      vendorService.getLanguage(query).then((response) => {
        this.timezones  = response.data.result.timezone;
        this.isLoading = false;
      });
    },
    clearAll() {
      this.timezone = [];
    },
    getLanguage(){
      vendorService.getLanguage()
      .then(response => {
        // this.primary_langs = response.data.result.primary_lang;
        // this.secondary_langs = response.data.result.secondary_lang;
        // this.filtered_secondary_langs = this.secondary_langs;
        this.currencies  = response.data.result.currency;
        this.timezones  = response.data.result.timezone;
        this.countrycodes  = response.data.result.country_code;
        if (
          (response.data.result.logo && response.data.result.logo != "" && response.data.result.logo != null) &&
          (response.data.result.flat_icon_path && response.data.result.flat_icon_path != "" && response.data.result.flat_icon_path != null)
        ) {
          this.logo = response.data.result.flat_icon_path+'/'+response.data.result.logo;
          document.querySelector('[type="image/x-icon"]').setAttribute('href', this.logo);
        }
        if (response.data.result.app_name && response.data.result.app_name != "") {
          this.default_appname = response.data.result.app_name;
          document.querySelector('head title').textContent = response.data.result.app_name;
        }
      });
    },
    termsconditionfun(term) {
      this.termscondition_error = false;
      if (term != 'accepted') {
        this.termscondition_error = true;
      }
    },
    addClient() {
      this.termscondition_error = false;
      this.$v.$touch();
      if (this.$v.typeform.$invalid) {
        if (this.termscondition != 'accepted') {
          this.termscondition_error = true;
        }
        return;
      } else {
        if (this.termscondition != 'accepted') {
          this.termscondition_error = true;
          return
        }
        this.isSubmitDisable = true;
        commonService.signup({...this.typeform,country_code: '+'+this.typeform.country_code.code,currency: this.typeform.currency.symbol,
        timezone: this.typeform.timezone.replace(/[(](.*?)[)]\s/, '').replace(', ','\/') })
        .then(response => {
          this.isSubmitDisable = false;
          if (response.data.code === 200) {
            success_message(response.data.message);
            this.typeform =  {
              vendor_name: "",
              vendor_number: "",
              email: "",
              password: "",
              confirm_password: "",
              subscription_plan_type: "",
              product_type_id: "",
              business_type_id: "",
              // app_logo: "",
              // flat_icon: "",
              country_code: "",
              currency: "",
              currency_short_name: "",
              website_url: "",
            };
            // this.app_logo_image = "";
            // this.flat_icon_image = "";
            this.frontUrl = "";
            this.termscondition = "";
            this.$v.typeform.$reset();
            this.typeform.subscription_plan_type = "premium";
          } else {
            error_message(response.data.message);
          }
        });
      }
    },
    // onAppLogoImagedeleted(value) {
    //   this.app_logo_image = "";
    // },
    // onFlatIconImagedeleted(value) {
    //   this.flat_icon_image = "";
    // },
    onCurrencyChange() {
      if (this.typeform.currency) {
        var curr = this.currencies.find((elem)=>(elem.symbol == this.typeform.currency));
        if (curr) {
          this.typeform.currency_short_name = (curr.short_name != null) ? curr.short_name : '';
        }
      } else {
        this.typeform.currency_short_name = '';
      }
    },
    removeSpecialChars(str) {
      return str.replace(/(?![A-Za-z])./g, '')
        .replace(/\s+/g, ' ')
        .replace(/^(\s*)([\W\w]*)(\b\s*$)/g, '$2');
    },
    updateFrontLink(e) {
      this.typeform.website_url = "";
      this.frontUrl = "";
      if (e.target.value && e.target.value != "") {
        this.typeform.website_url = this.removeSpecialChars(e.target.value).toLowerCase();
        this.frontUrl = ((this.typeform.website_url != "") ? "https://" + this.typeform.website_url + config.orderWebDefaultUrl : "").toLowerCase();
      }
    },
    onProductChange(ev) {
      this.typeform.business_type_id = "";
    }
  }
};
</script>

<template>
  <div>
    <div class="account-pages my-5 pt-5">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-7 login-main">
            <div class="card overflow-hidden">
              <div class="bg-soft-primary">
                <div class="row">
                  <div class="col-12">
                    <div class="text-primary p-4">
                      <h5 class="text-primary">{{trans.get('__JSON__.Sign up for')}} {{ default_appname }} !</h5>
                      <p>{{trans.get('__JSON__.Fill all the details to setup account with')}} {{ default_appname }}</p>
                    </div>
                  </div>
                  <!-- <div class="col-5 align-self-end">
                    <img :src="config.basepath+'profile-img.png'" alt class="img-fluid" />
                  </div> -->
                </div>
              </div>
              <div class="card-body pt-0">
                <div>
                  <div class="avatar-md profile-user-wid mb-4 avatar-logo-main">
                    <span class="avatar-title rounded-circle bg-light">
                      <img :src="logo" alt />
                    </span>
                  </div>
                </div>

                <div class="row d-none">
                  <h4 class="card-title pl-3 ml-1">{{ trans.get('__JSON__.Client Info') }}</h4>
                </div>
                <b-form class="p-2 mt-4" @submit.prevent="addClient">
                  <slot />
                  <div class="row" :class="{ 'businessbm': typeform.website_url != '' && frontUrl != '' }">
                    <div class="col-md-6">
                      <b-form-group
                        id="input-group-1"
                        :label="this.trans.get('__JSON__.Business Name')"
                        label-for="input-1"
                        label-class="required"
                      >
                        <b-form-input
                          id="input-1"
                          name="app_name"
                          ref="app_name"
                          v-model="$v.typeform.app_name.$model"
                          type="text"
                          autocomplete="off"
                          :placeholder="this.trans.get('__JSON__.Enter Business Name')"
                          :class="{ 'is-invalid':  $v.typeform.app_name.$error}"
                          v-on:keyup="updateFrontLink"
                        ></b-form-input>
                        <div v-if="$v.typeform.app_name.$error" class="invalid-feedback">
                          <span
                            v-if="!$v.typeform.app_name.required"
                          >{{ trans.get('__JSON__.Please enter business name') }}</span>
                        </div>
                      </b-form-group>
                      <span v-if="typeform.website_url != '' && frontUrl != ''">
                        <a :href="frontUrl" target="_blank">{{typeform.website_url+config.orderWebDefaultUrl}}</a>
                      </span>
                    </div>

                    <div class="col-md-6">
                      <b-form-group
                        id="input-group-1"
                        :label="this.trans.get('__JSON__.Your Name')"
                        label-for="input-1"
                        label-class="required"
                      >
                        <b-form-input
                          id="input-1"
                          name="vendor_name"
                          ref="vendor_name"
                          v-model="$v.typeform.vendor_name.$model"
                          type="text"
                          autocomplete="off"
                          :placeholder="this.trans.get('__JSON__.Enter Your Name')"
                          :class="{ 'is-invalid':  $v.typeform.vendor_name.$error }"
                        ></b-form-input>
                        <div v-if="$v.typeform.vendor_name.$error" class="invalid-feedback">
                          <span
                            v-if="!$v.typeform.vendor_name.required"
                          >{{ trans.get('__JSON__.Please enter your name') }}</span>
                        </div>
                      </b-form-group>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <b-form-group
                        id="input-group-1"
                        :label="this.trans.get('__JSON__.Email')"
                        label-for="input-1"
                        label-class="required"
                      >
                        <b-form-input
                          id="input-1"
                          name="email"
                          ref="email"
                          v-model="$v.typeform.email.$model"
                          type="text"
                          autocomplete="off"
                          :placeholder="this.trans.get('__JSON__.Enter email')"
                          :class="{ 'is-invalid':  $v.typeform.email.$error }"
                        ></b-form-input>
                        <div v-if="$v.typeform.email.$error" class="invalid-feedback">
                          <span
                            v-if="!$v.typeform.email.required"
                          >{{ trans.get('__JSON__.Please enter email') }}</span>
                          <span
                            v-if="!$v.typeform.email.email"
                          >{{ trans.get('__JSON__.This value should be a valid email') }}</span>
                        </div>
                      </b-form-group>
                    </div>
                  </div>


                  <div class="row">
                      <div class="col-sm-6">
                        <div class="form-group">
                          <label class="control-label required" for="ajax">{{ this.trans.get('__JSON__.Country') }}</label>
                          <!-- <select class="custom-select" v-model="$v.typeform.country_code.$model" id="country_code" name="country_code" :class="{ 'is-invalid':$v.typeform.country_code.$error }">
                            <option value="">{{trans.get('__JSON__.Select Country')}}</option>
                            <option v-for="concode in countrycodes" :value="concode.code" :key="concode.id" >(+{{ concode.code }}) {{ concode.name }}</option>
                          </select> -->
                          <multiselect 
                            v-model="$v.typeform.country_code.$model" 
                            :options="countrycodes" 
                            :custom-label="nameWithLang" 
                            :placeholder="trans.get('__JSON__.Select Country Code')"
                            label="name" 
                            track-by="name"
                            selectLabel=''
                            @search-change="asyncFind"
                            :class="{ 'is-invalid': $v.typeform.country_code.$error }"
                          >
                          <template>
                            <span slot="noResult">{{ trans.get('No Country Code Found') }}.</span>
                          </template>
                          </multiselect>  
                          <div v-if="$v.typeform.country_code.$error" class="invalid-feedback">
                            <span v-if="!$v.typeform.country_code.required">{{ trans.get('__JSON__.Please select country') }}</span>
                          </div>
                        </div>    
                      </div>

                      <div class="col-md-6">
                        <b-form-group
                          id="input-group-1"
                          :label="trans.get('__JSON__.Phone Number')"
                          label-for="input-1"
                          label-class="required"
                        >
                          <b-form-input
                            id="input-1"
                            maxlength="15"
                            minlength="10"
                            name="vendor_number"
                            ref="vendor_number"
                            v-model="$v.typeform.vendor_number.$model"
                            type="text"
                            autocomplete="off"
                            :placeholder="trans.get('__JSON__.Phone Number')"
                            :class="{ 'is-invalid':  $v.typeform.vendor_number.$error }"
                          ></b-form-input>
                          <div v-if="$v.typeform.vendor_number.$error" class="invalid-feedback">
                            <span
                              v-if="!$v.typeform.vendor_number.required"
                            >{{ trans.get('__JSON__.Please enter phone number') }}</span>
                            <span
                              v-if="!$v.typeform.vendor_number.numeric"
                            >{{ trans.get('__JSON__.This value should be a valid number') }}</span>
                          </div>
                        </b-form-group>
                      </div>
                   </div>


                  <div class="row">

                     <div class="col-sm-6">
                      <div class="form-group">
                        <label class="control-label required" id="ajax">{{ trans.get('__JSON__.Currency') }}</label>
                        <!-- <select class="custom-select" v-model="$v.typeform.currency.$model" id="currency" name="currency" :class="{ 'is-invalid':$v.typeform.currency.$error }" @change="onCurrencyChange">
                          <option value="">{{trans.get('__JSON__.Select Currency')}}</option>
                          <option v-for="cur in currencies" :value="cur.symbol" :key="cur.id"><span v-html="cur.symbol"></span> ({{ cur.name }})</option>
                        </select> -->
                         <multiselect 
                            v-model="$v.typeform.currency.$model" 
                            :options="currencies" 
                            :placeholder="trans.get('__JSON__.Select Currency')"
                            label="name" 
                            track-by="name"
                            selectLabel=''
                            deselectLabel=''
                            @search-change="asyncCurrencyFind"
                            :class="{ 'is-invalid': $v.typeform.currency.$error }"
                          >
                          <template slot="option" slot-scope="props">
                              <div class="option__desc"><span class="option__title" v-html="props.option.symbol"></span><span class="option__small">  ({{ props.option.name }}) </span></div>
                          </template>
                          <template>
                            <span slot="noResult">{{ trans.get('No Currency Found') }}.</span>
                          </template>
                        </multiselect>  
                        <div v-if="$v.typeform.currency.$error" class="invalid-feedback">
                          <span v-if="!$v.typeform.currency.required">{{ trans.get('__JSON__.Please select currency') }}</span>
                        </div>
                      </div>
                     </div>


                     <div class="col-sm-6">
                      <div class="form-group">
                        <label class="control-label required" id="ajax">{{ trans.get('__JSON__.Timezone') }}</label>
                        <!-- <select class="custom-select" v-model="$v.typeform.timezone.$model" id="timezone" name="timezone" :class="{ 'is-invalid':$v.typeform.timezone.$error }">
                          <option value="">{{trans.get('__JSON__.Select Timezone')}}</option>
                          <option v-for="(value, key) in timezones" :value="key" :key="key">{{ value }}</option>
                        </select> -->
                        <multiselect 
                            v-model="$v.typeform.timezone.$model" 
                            :options="Object.values(timezones)" 
                            :placeholder="trans.get('__JSON__.Select Time Zone')"
                            selectLabel=''
                            @search-change="asyncZoneFind"
                            :class="{ 'is-invalid': $v.typeform.timezone.$error }"
                          >
                          <template>
                            <span slot="noResult">{{ trans.get('No Time Zone Found') }}.</span>
                          </template>
                        </multiselect>  
                        <div v-if="$v.typeform.timezone.$error" class="invalid-feedback">
                          <span v-if="!$v.typeform.timezone.required">{{ trans.get('__JSON__.Please select timezone') }}</span>  
                        </div>
                      </div>    
                     </div>

                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <b-form-group
                        id="input-group-2"
                        :label="this.trans.get('__JSON__.Password')"
                        label-for="input-2"
                        label-class="required"
                      >
                        <b-form-input
                          id="input-2"
                          v-model="$v.typeform.password.$model"
                          name="password"
                          type="password"
                          autocomplete="off"
                          :placeholder="this.trans.get('__JSON__.Enter password')"
                          :class="{ 'is-invalid':  $v.typeform.password.$error  }"
                        ></b-form-input>
                        <div v-if="$v.typeform.password.$error" class="invalid-feedback">
                          <span
                            v-if="!$v.typeform.password.required"
                          >{{ trans.get('__JSON__.Please enter password')}}</span>
                        </div>
                      </b-form-group>
                    </div>

                    <div class="col-md-6">
                      <b-form-group
                        id="input-group-2"
                        :label="this.trans.get('__JSON__.Confirm Password')"
                        label-for="input-2"
                        label-class="required"
                      >
                        <b-form-input
                          id="input-2"
                          v-model="$v.typeform.confirm_password.$model"
                          name="confirm_password"
                          type="password"
                          autocomplete="off"
                          :placeholder="this.trans.get('__JSON__.Re-enter password')"
                          :class="{ 'is-invalid':  $v.typeform.confirm_password.$error  }"
                        ></b-form-input>
                        <div v-if="$v.typeform.confirm_password.$error" class="invalid-feedback">
                          <span
                            v-if="!$v.typeform.confirm_password.required"
                          >{{ trans.get('__JSON__.Please re enter your password')}}</span>
                          <span
                              v-else-if="!$v.typeform.confirm_password.sameAsPassword"
                            >{{ trans.get('__JSON__.Passwords must match') }}</span>
                        </div>
                      </b-form-group>
                    </div>

                  </div>

                  <div class="row">
                    <h4 class="card-title pl-2 ml-1 mt-4">{{ trans.get('__JSON__.Plan Details') }}</h4>
                  </div>

                  <div class="row mb-4 mt-4">
                    <div class="col-sm-6 d-none">
                      <div class="form-group">
                        <label class="control-label required">{{ trans.trans('__JSON__.Plan Type') }}</label>
                        <select
                          class="custom-select"
                          v-model="$v.typeform.subscription_plan_type.$model"
                          id="subscription_plan_type"
                          name="subscription_plan_type"
                          :class="{ 'is-invalid':  $v.typeform.subscription_plan_type.$error  }"
                        >
                          <!-- <option value="" selected>{{ trans.get('__JSON__.Select Plan Type') }}</option> -->
                          <option value="basic">{{ trans.get('__JSON__.Basic') }}</option>
                          <option value="plus">{{ trans.get('__JSON__.Plus') }}</option>
                          <option value="premium">{{ trans.get('__JSON__.Premium') }}</option>
                        </select>
                        <div v-if=" $v.typeform.subscription_plan_type.$error" class="invalid-feedback">
                          <span
                            v-if="!$v.typeform.subscription_plan_type.required"
                          >{{ trans.trans('__JSON__.Please select at least one type.') }}.</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-6">
                      <div class="form-group">
                        <label class="control-label required">{{ trans.trans('__JSON__.Product You are Interested In') }}</label>
                        <select
                          class="custom-select"
                          v-model="$v.typeform.product_type_id.$model"
                          id="product_type_id"
                          name="product_type_id"
                          :class="{ 'is-invalid':  $v.typeform.product_type_id.$error  }"
                          @change="onProductChange($event)"
                        >
                          <option value="" selected>{{ trans.get('__JSON__.Select Product You are Interested In') }}</option>
                          <!-- <option value="Yes">{{ trans.get('__JSON__.Ressto') }} ({{ trans.get('__JSON__.Single Vendor/Restaurant/Store') }})</option>
                          <option value="No">{{ trans.get('__JSON__.Market') }} ({{ trans.get('__JSON__.Multi Vendor Market Place') }})</option> -->
                          <option v-for="product in product_type_list" :value="product.id" :key="product.id">{{product.name}} {{product.extratext}}</option>
                        </select>
                        <div v-if="$v.typeform.product_type_id.$error" class="invalid-feedback">
                          <span
                            v-if="!$v.typeform.product_type_id.required"
                          >{{ trans.trans('__JSON__.Please select at least one product.') }}.</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-6">
                      <div class="form-group">
                        <label class="control-label required">{{ trans.trans('__JSON__.Business Type') }}</label>
                        <select
                          class="custom-select"
                          v-model="$v.typeform.business_type_id.$model"
                          id="business_type_id"
                          name="business_type_id"
                          :class="{ 'is-invalid':  $v.typeform.business_type_id.$error  }"
                        >
                          <option value="" selected>{{ trans.get('__JSON__.Select Business Type') }}</option>
                          <template v-for="business in business_type_list">
                            <template v-if="typeform.product_type_id == 1">
                              <option :value="business.id" :key="business.id">{{business.name}} {{(typeform.product_type_id == 1) ? business.extratext : ''}}</option>
                            </template>
                            <template v-if="typeform.product_type_id == 2">
                              <option v-if="business.id != 3" :value="business.id" :key="business.id">{{business.name}} {{(typeform.product_type_id == 1) ? business.extratext : ''}}</option>
                            </template>
                          </template>
                        </select>
                        <div v-if=" $v.typeform.business_type_id.$error" class="invalid-feedback">
                          <span
                            v-if="!$v.typeform.business_type_id.required"
                          >{{ trans.trans('__JSON__.Please select at least one business type') }}.</span>
                        </div>
                      </div>
                    </div>

                  </div>

                  <!-- <div class="row">
                    <div class="col-sm-3">
                      <div class="form-group">
                        <label class="control-label">{{ this.trans.get('__JSON__.Business Logo') }}<br>({{ trans.get('__JSON__.Rectangle 3:2') }})</label>
                        <dropzone-popup
                          v-model="typeform.app_logo"
                          id="app_logo"
                          class="business-logo"
                          acceptedFiles=".jpeg,.jpg,.png"
                          :editable-images="app_logo_image"
                          :media-path="app_logo_image_path"
                          :delete-path="app_logo_image_delete_url"
                          @image-deleted="this.onAppLogoImagedeleted"
                          :class="{ 'is-invalid': $v.typeform.app_logo.$error }"
                        />
                         <div v-if="$v.typeform.app_logo.$error" class="invalid-feedback cuisine-img">
                          <span
                            v-if="!$v.typeform.app_logo.required"
                          >{{ trans.get('__JSON__.Please Select Business Logo') }}.</span>
                         </div>
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div class="form-group">
                        <label class="control-label">{{ this.trans.get('__JSON__.Square Logo') }}<br>({{ trans.get('__JSON__.Square 1:1') }})</label>
                        <dropzone-popup
                          v-model="typeform.flat_icon"
                          id="flat_icon"
                          class="business-logo"
                          acceptedFiles=".jpeg,.jpg,.png"
                          :editable-images="flat_icon_image"
                          :media-path="flat_icon_image_path"
                          :delete-path="flat_icon_image_delete_url"
                          @image-deleted="this.onFlatIconImagedeleted"
                          :class="{ 'is-invalid': $v.typeform.flat_icon.$error }"
                        />
                        <div v-if="$v.typeform.flat_icon.$error" class="invalid-feedback cuisine-img">
                          <span
                            v-if="!$v.typeform.flat_icon.required"
                          >{{ trans.get('__JSON__.Please Select Sqaure Logo') }}.</span>
                        </div>
                      </div>
                    </div>
                  </div> -->

                  <div>
                    <b-form-checkbox
                      id="terms"
                      v-model="termscondition"
                      name="terms"
                      value="accepted"
                      unchecked-value=""
                      :class="{ 'is-invalid': termscondition_error }"
                      @change="termsconditionfun"
                    >
                      {{trans.get('__JSON__.I Agree')}} <a href="https://deonde.co/terms-of-service.shtml" target="_blank">{{ trans.get('__JSON__.Terms of Service') }}</a> & <a href="https://deonde.co/privacy-policy.shtml" target="_blank">{{trans.get('__JSON__.Privacy policy')}}</a> {{trans.get('__JSON__.of')}} {{ default_appname }}.
                    </b-form-checkbox>
                    <div v-if="termscondition_error" class="invalid-feedback">
                      <span
                        v-if="termscondition_error"
                      >{{ trans.get('__JSON__.Please select the checkbox')}}</span>
                    </div>
                  </div><br><br>

                  <div>
                    <button id="submit"
                      class="btn btn-primary btn-block"
                      @keyup.enter="addClient"
                      :disabled="isSubmitDisable"
                    >
                      <b-spinner v-if="isSubmitDisable" class="align-middle"></b-spinner> 
                      {{(isSubmitDisable) ? trans.get('__JSON__.Signup')+'...' : trans.get('__JSON__.Signup')}}
                    </button>
                  </div>
                </b-form>
              </div>
              <!-- end card-body -->
            </div>
            <!-- end card -->
          </div>
          <!-- end col -->
        </div>
      </div>
    </div>
  </div>
</template>
<style type="text/css">
.businessbm {
  margin-bottom: 1rem;
}
.businessbm .form-group {
  margin-bottom: 0px;
}
.spinner-border {
  height: 20px !important;
  width: 20px !important;
}
</style>
