
// Master Depot services rest api calling

import { multipart_headers } from '../Helper/helper';
import { routes } from '../config/api_routes'

export const masterDepotService = {
    // Get Menu APi 
    getDepotMenu: (formData) => window.axios.post(routes.getDepotMenuApi, formData),
    //Get Item by Menu id
    getItemByMenuID: (formData) => window.axios.post(routes.getDepotItemByMenuIDApi, formData),
    //Add Menu 
    addDepotMenu: (formData) => window.axios.post(routes.addDepotMenuApi, formData, { headers: multipart_headers() }),
    //Get Menu by Menu id
    getDepoteMenuById: (formData) => window.axios.post(routes.getDepoteMenuByIdApi, formData),
    //Update Depote Menu
    updateDepoteMenu: (formData) => window.axios.post(routes.updateDepoteMenuApi, formData),
    //Update Depote Menu Status
    updateDepoteMenuStatus: (formData) => window.axios.post(routes.updateDepoteMenuStatusApi, formData),
    //Delete Depote Menu
    deleteDepoteMenu: (formData) => window.axios.post(routes.deleteDepoteMenuApi, formData),
    //Add Depote Item
    addDepoteItmeMenu: (formData) => window.axios.post(routes.addDepoteItemMenuApi, formData),
    //Get Depote Menu Item
    getDepoteMenuItem: (formData) => window.axios.post(routes.getDepoteMenuItemApi, formData),
    //Update Depote Item
    updateDepotemenuItem: (formData) => window.axios.post(routes.updateDepoteMenuItemApi, formData),
    //Delete Depote Item
    deleteDepoteMenuItem: (formData) => window.axios.post(routes.deleteDepoteMenuItemApi, formData),
    //Update Depote Menu Item Status
    updateDepoteItemAvailability: (data) => window.axios.post(routes.updateDepoteItemAvailabilityApi, data),
    //Update Depote Item Order
    updateDepoteItemOrder: (data) => window.axios.post(routes.updateDepoteItemOrderApi, data),
    //Update Depote Menu Order
    updateDepoteMenuOrder: (data) => window.axios.post(routes.updateDepoteMenuOrderApi, data),
    //Delete depote menu with item
    deleteDepoteMenuWithItem: (formData) => window.axios.post(routes.deleteDepoteMenuWithItemApi, formData),
    //Get Depote Menu image
    getDepoteMenuImage: (formData) => window.axios.post(routes.getDepoteMenuImageApi, formData),
    //Add Depote menu image
    addDepoteMenuImage: (formData) => window.axios.post(routes.addDepoteMenuImageApi, formData),
    //Import depot menu item
    importDepoteMenuItem: (formData) => window.axios.post(routes.importDepoteMenuItemApi, formData),
    //Large data import menu & item
    largeDataImportDepoteMenuItem: (formData) => window.axios.post(routes.largedataimportDepoteMenuItemApi, formData),
    //Delete all menu items
    deleteDepoteAllMenuItems: (formData) => window.axios.post(routes.deleteDepoteAllMenuItemApi, formData),
    //Get Batch List
    getBatchList: (formData) => window.axios.post(routes.getBatchListApi, formData),
    //Update status or delete fail record
    updateBatchStatusOrDelete: (formData) => window.axios.post(routes.updateBatchStatusOrDeleteApi, formData)
}