<script>

import Layout from '../../layouts/main'

import DiscountEdit from './discount-edit'

/**
 * Promo-code component
 */
export default {
  components: { Layout, DiscountEdit },
  data() {
    return {};
  }
}
</script>

<template>
<Layout>
  <discount-edit/>
</Layout>
</template>
