<script>
import Layout from "../../../layouts/main";
import PageHeader from "../../../components/page-header";
// import VendorEarning from "../../../components/widgets/vendor-earning";
import VendorSettlements from "../restaurant/restaurant-settlement";
import RestaurantDashboard from "./restaurant-dashboard";
import RestaurantFinanceMonth from "./restaurant-finance-month";
import RestaurantFinanceOrder from "./restaurant-finance-order";
import VendorEarning from "./vendor-earning";
import { restaurantService, vendorFinanceService } from '../../../services';
import {
  get_partner_login,
  set_partner,
  set_partner_details,
  set_user,
  set_user_detail,
} from "../../../Helper/helper";
/**
 * Starter component
 */
export default {
  components: { Layout, PageHeader, VendorEarning, VendorSettlements,RestaurantDashboard, RestaurantFinanceMonth, RestaurantFinanceOrder},
  data() {
    return {
      restaurant: {},
      ratings: [],
      finance: {},
      dayWise: {},
      bank_info: null,
      year: this.moment().format('YYYY'),
      monthWiseFinance: {},
      sortBy: "delivered_date",
      sortDesc: true,
      years: [],
      vendorsData: [],
      // pastdayWise: {},
    };
  },
  created(){
    this.getRestaurant(this.$route.params.id);
    this.getRestaurantRatings(this.$route.params.id);
    this.getDayWiseFinance(this.$route.params.id);
    this.getBankInfo(this.$route.params.id);
    this.getVendorFinance();
    this.getYears();
  },
  methods: {
    getYears(){
        var year = 2017;
        var years = [];
        while(year != this.moment().format('YYYY') ){
            years.push(++year);
        }
        this.years = years.reverse();
    },
    sortingChanged(ctx) {
      // this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      this.getVendorFinance();
	},
    getVendorFinance(page = 1,index){
		vendorFinanceService.getVendorFinance({
      sortBy: this.sortBy,
      orderBy: this.sortDesc ? "desc" : "asc",
      page: page ? page : 1,
      year: this.year
		})
		.then(res => {
      this.vendorsData = res.data.result;
      this.monthWiseFinance = res.data.result.monthWiseFinance.data;
		})
	},
    getUser() {
      this.users = this.$auth.user;
      this.userdetail = this.$auth.setting;

    },
    getRestaurant(id){
      restaurantService.getRestaurantById({restaurant_id:id, base64: 1})
        .then(res => {
          this.restaurant = res.data.result;
        })
    },
    getDayWiseFinance(id){
      vendorFinanceService.getDayWiseFinance(id)
        .then(res => {
          this.dayWise = res.data.result;
        })
    },
    getRestaurantRatings(id){
      restaurantService.getRestaurantAvgRating(id, 1)
        .then(res => {
          this.ratings = res.data.result;
          setTimeout  (function(){
            $('.rating').trigger('change');
        },500)
        })
    },
    getBankInfo(id){
      restaurantService.getBankInfo(id)
        .then(res => {
          this.bank_info = res.data.result;
        })
    },
  }
};
</script>
<template>
  <Layout>
   <div class="row">
          <div class="col-12" v-if="!$auth.partner_login">
              <div
              class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
              >
              <div class="page-title-left avatar-main-icon">
                  <div class="avatar-xs mr-3">
                  <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                      <i class="bx bx-purchase-tag"></i>
                  </span>
                  </div>
                   <div class="report-tag">
                    <h4 class="mb-0 font-size-18">{{ (restaurant && restaurant.name || '') + ' ' + trans.get('__JSON__.Finance') }}</h4>
                    <p><router-link :to="{ name: 'vendor'}">{{ trans.trans('__JSON__.Vendor Finance',{ vendor: $auth.setting.restaurant_name }) }}</router-link>
                    >
                    <router-link :to="{ name: 'vendor-finance-month',params: {month: $route.params.month}}">{{ $route.params.month }}</router-link>
                    >
                    {{(restaurant && restaurant.name || '')}} {{trans.get('__JSON__.DashBoard')}}
                    </p>
                </div>
              </div>
              </div>
          </div>
          <div class="col-12" v-else>
            <div
              class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
              >
              <div class="page-title-left">
                  <div class="avatar-xs mr-3">
                  <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                      <i class="bx bx-purchase-tag"></i>
                  </span>
                  </div>
                   <div class="report-tag">
                    <h4 class="mb-0 font-size-18">{{ trans.get('__JSON__.Finance') }}</h4>
                </div>
              </div>
              </div>
          </div>
      </div>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <!-- Tab panel start-->
                      <b-tabs justified nav-class="nav-tabs-custom mt-3 order-tabs" content-class="p-3 text-muted">
                          <b-tab active>
                            <template v-slot:title>
                              <span class="d-inline-block d-sm-none">
                                <i class="fas fa-home"></i>
                              </span>
                              <span class="d-none d-sm-inline-block">{{trans.get('__JSON__.DashBoard')}}</span>
                            </template>
                            <restaurant-dashboard :restaurant="restaurant" :ratings="ratings" :dayWise="dayWise" :bank_info="bank_info" />
                            <!-- <div class="search-loader">
                                <div class="search-icon">
                                    <span class="bx bx-search-alt"></span>
                                </div>
                            </div> -->
                          </b-tab>
                          <b-tab>
                            <template v-slot:title>
                              <span class="d-inline-block d-sm-none">
                                <i class="far fa-user"></i>
                              </span>
                              <span class="d-none d-sm-inline-block">{{trans.get('__JSON__.Month Wise Earnings')}}</span>
                            </template>
                            <!-- <vendor-earning :restaurant_id="$route.params.id"/> -->
                            <restaurant-finance-month :restaurant_id="$route.params.id"/>
                          </b-tab>
                          <b-tab>
                            <template v-slot:title>
                              <span class="d-inline-block d-sm-none">
                                <i class="far fa-user"></i>
                              </span>
                              <span class="d-none d-sm-inline-block">{{trans.get('__JSON__.Date Wise Earnings')}}</span>
                            </template>
                            <!-- <vendor-earning :restaurant_id="$route.params.id"/> -->
                            <restaurant-finance-order :restaurant_id="$route.params.id"/>
                          </b-tab>
                          <b-tab>
                            <template v-slot:title>
                              <span class="d-inline-block d-sm-none">
                                <i class="far fa-envelope"></i>
                              </span>
                              <span class="d-none d-sm-inline-block">{{trans.get('__JSON__.Settlements')}}</span>
                            </template>
                            <VendorSettlements :restaurant_id="$route.params.id"/>
                          </b-tab>
                       </b-tabs>
                      <!-- Tab panel end -->
                </div>
            </div>
        </div>
    </div>
  </Layout>
</template>