
// LogActivity services rest api calling

import { routes } from '../config/api_routes'

export const logActivityService = {
    // get LogActivity 

    getLogActivityListing: (formData) => {    
        return window.axios.post(routes.getLogActivityApi,formData)
    }
}