<script>
import config from "../../config";
import pagination from "laravel-vue-pagination";
import moment from "moment";
import { vendorService } from "../../services";
import { error_message } from "../../Helper/helper";

/**
 * Tabs & accordions component
 */
export default {
  components: { pagination, vendorService },
  data() {
    return {
      sortBy: "order_id",
      sortDesc: true,
      ratings: [],
      ratingData: [],
      paginations: {},
      limit: 1,
      search: "",
      status: 1,
      config: config,
      moment: moment,
      loading: true,
      fields: [
        { key: "order_id", label: "Order ID", sortable: true },
        { key: "user_id", label: "Customer Id", sortable: true },
        { key: "rating", label: "Rating", sortable: true },
        { key: "description", label: "Review", sortable: true },
        { key: "created_date", label: "Date", sortable: true }
      ],
    };
  },
  mounted() {
    this.getRating();
  },
  methods: {
    getRating(page) {
      vendorService
        .getRestaurantRating({
          search: this.search,
          status: this.status,
          sortBy: this.sortBy,
          orderBy: this.sortDesc ? "desc" : "asc",
          page: page ? page : 1,
          restaurant_id: this.$route.params.restaurant_id,
        })
        .then((response) => {
          this.loading = false;
          this.paginations = response.data.result;
          this.ratings = response.data.result.data;
        });
    },
    searchData() {
      this.getRating();
    },
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      this.getRating();
    },
  },
};
</script>

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <h4 class="mb-0 font-size-18">{{ trans.get('__JSON__.Reviews') }}</h4>
        </div>
      </div>
    </div>

    <!-- end row -->
    <div class="row">
      <div class="col-12 list-table-main">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-2 offset-sm-10">
                <div class="search-box mb-3 d-inline-block">
                  <div class="date-range-list">
                    <label>{{ trans.get('__JSON__.Search') }} :</label>
                    <div class="position-relative">
                      <input
                        type="text"
                        class="form-control"
                        @keyup="searchData()"
                        v-model="search"
                        :placeholder="trans.get('__JSON__.Search...')"
                      />
                      <i class="bx bx-search-alt search-icon"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 table-main-list">
                <b-table
                    :items="ratings"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :no-local-sorting="true"
                    @sort-changed="sortingChanged"
                    :busy="loading"
                    show-empty
                  >
                    <template #table-busy>
                        <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>{{trans.get('__JSON__.Loading...')}}</strong>
                        </div>
                    </template>

                    <template v-slot:cell(created_at)="data">
                      <div
                        v-if="data.item.created_at"
                      >{{moment(data.item.created_at).format('D MMM,Y')}}</div>
                    </template>

                    <template v-slot:cell(rating)="data">
                      <div v-if="data.item.rating <= 2">
                        <span class="badge badge-danger font-size-12">
                          <i class="mdi mdi-star mr-1"></i>
                          {{parseFloat(data.item.rating).toFixed(2)}}
                        </span>
                      </div>
                      <div v-else>
                        <span class="badge badge-success font-size-12">
                          <i class="mdi mdi-star mr-1"></i>
                          {{parseFloat(data.item.rating).toFixed(2)}}
                        </span>
                      </div>
                    </template>

                    <template v-slot:cell(description)="data">
                      <div v-if="data.item.description">
                        <div
                          v-if="data.item.description.length > 140"
                        >{{data.item.description.substr(0, 140)+ '...'}}</div>
                        <div v-else>{{data.item.description}}</div>
                      </div>
                      <div v-else class="mt-3">
                        <p>{{ trans.get('__JSON__.Description not added by user') }}</p>
                      </div>
                    </template>
                     <template #empty>
                      <p class="text-center">{{ trans.get('__JSON__.No Rating') }}</p>
                    </template>
                  </b-table>
              </div>
              <div class="row col-sm-5 offset-sm-7">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <pagination
                        :data="paginations"
                        :limit="limit"
                        @pagination-change-page="getRating"
                      ></pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>