<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import AddBanner from "./add-banner";
import { bannerService } from "../../services";

/**
 * Add-product component
 */
export default {
  components: { Layout, PageHeader, AddBanner },
  data() {
    return {};
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
        >
          <div class="page-title-left avatar-main-icon">
            <div class="avatar-xs mr-3">
              <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                <i class="bx bx-purchase-tag"></i>
              </span>
            </div>
            <div class="report-tag">
              <h4 class="mb-0 font-size-18">{{ trans.get('__JSON__.Add Banner') }}</h4>
              <p><router-link :to="{ name: 'banner-list'}">{{ trans.get('__JSON__.Banners') }}</router-link> > {{ trans.get('__JSON__.Add Banner') }}</p>
          </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <add-banner />
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
