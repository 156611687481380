<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import pagination from "laravel-vue-pagination";
import {
  get_partner_login,
  set_partner,
  set_partner_details,
  set_user,
  set_user_detail,
  debounce,
  get_decimal,
  get_currency,
  date_ranges
} from "../../Helper/helper";
import moment from "moment";
import { reportService } from "../../services";
/**
 * Report component
 */
export default {
  components: { Layout, PageHeader, DateRangePicker, pagination },
  data() {
    let startDate = this.moment().startOf("day").subtract(29, "days");
    let endDate = this.moment().endOf("day").toDate();
    return {
      decimal: get_decimal(),
      vendorAvgReport: {},
      exportAvgReport: [],
      restaurant: [],
      restaurant_count: "",
      restaurants_id: "",
      sortBy: "created_at",
      sortDesc: true,
      paginations: {},
      limit: 2,
      perPage: 0,
      currentPage: 1,
      currency: get_currency(),
      loading: true,
      date_ranges: date_ranges,
      isDownloading: false,
      dateRange: { startDate, endDate },
      filters: {
        start_date: this.moment().subtract(29,'days').format('YYYY-MM-DD'),
        end_date: this.moment().format('YYYY-MM-DD'),
      },
      fields: [
        { key: "name", label: "Vendor Name", sortable: true },
        { key: "average", label: "Average Feedback", sortable: true },
        { key: "total", label: "Orders", sortable: true }
      ],
    };
  },
  mounted() {
    this.$data.fields[0].label = this.$auth.getRestaurantName() +' Name';
    this.getVendorRatingReport();
    this.getRestaurants();
    // this.exportToCsv();
  },
  methods: {
    downloadsCSV: function () {
      let exportAvgReport =
        '\ufeff' +
        ''+this.$auth.getRestaurantName()+' Name,Average Feedback,Orders\n'
      this.exportAvgReport.forEach((el) => {
        var line =
          el["name"] +
          "," +
          el["average"].toFixed(2) +
          "," +
          el["total"] +
          "\n"
        exportAvgReport += line;
      });

      var blob = new Blob([exportAvgReport], { type: "csv/plain" });
      var date = new Date().toLocaleString();
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = this.$auth.getRestaurantName()+"_avg_rating_report_" + date + ".csv";
      link.click();
    },
    updateValues(date) {
      this.filters = {
        ...this.filters,
        start_date: this.moment(date.startDate.toISOString()).format(
          "YYYY-MM-DD"
        ),
        end_date: this.moment(date.endDate.toISOString()).format("YYYY-MM-DD"),
      };
      this.getVendorRatingReport();
      // this.exportToCsv();
    },
    dateFormat(classes, date) {
      this.filters = {
        ...this.filters,
        start_date: this.moment(date.startDate.toISOString()).format(
          "YYYY-MM-DD"
        ),
        end_date: this.moment(date.endDate.toISOString()).format("YYYY-MM-DD"),
      };
    },
    getRestaurants() {
      reportService.getAllRestaurantName().then((response) => {
        this.restaurant_count = response.data.result.length;
        this.restaurant = response.data.result;
        if (this.restaurant_count > 0) {
          this.restaurant_id = this.restaurant[0].restaurant_id;
        }
        this.searchData();
      });
    },
    getVendorRatingReport(page) {
      this.loading = true;
      reportService
        .getVendorAvgRatingReport({
          ...this.filters,
          sortBy: this.sortBy,
          orderBy: this.sortDesc ? "desc" : "asc",
          restaurants_id: this.restaurants_id,
          page: page ? page : 1,
        })
        .then((response) => {
          this.loading = false;
          // this.paginations = response.data.result;
          this.vendorAvgReport = response.data.result;
        });
    },
    exportToCsv()
    {
        this.isDownloading = true;
        reportService.exportVendorAvgRating({
            ...this.filters,
            restaurants_id: this.restaurants_id,
            sortBy: this.sortBy,
            orderBy: this.sortDesc ? "desc" : "asc"
        }).
        then((response) => {
            this.isDownloading = false;
            this.exportAvgReport = response.data.result;
            this.downloadsCSV();
        });
    },
    searchData() {
      this.getVendorRatingReport();
      // this.exportToCsv();
    },
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      this.getVendorRatingReport();
      this.exportToCsv();
    },
  },
  filters: {
    date(val) {
      return val ? val.toLocaleString() : "";
    },
  },
};
</script>

<template>
  <Layout>
    <div>
     <div class="row">
          <div class="col-12">
              <div
              class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
              >
              <div class="page-title-left avatar-main-icon">
                  <div class="avatar-xs mr-3">
                  <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                      <i class="bx bx-purchase-tag"></i>
                  </span>
                  </div>
                  <div class="report-tag">
                    <h4 class="mb-0 font-size-18">{{ trans.trans('__JSON__.Vendor - Average Feedback Report',{ vendor : $auth.setting.restaurant_name }) }}</h4>
                    <p><router-link :to="{ name: 'reports-list'}">{{ trans.get('__JSON__.Reports') }}</router-link> > {{ trans.trans('__JSON__.Vendor - Average Feedback Report',{ vendor : $auth.setting.restaurant_name }) }}</p>
                  </div>
              </div>
              <div class="page-title-right">
                 <button @click="exportToCsv" class="btn btn-primary btn-rounded export-btn" v-if="isDownloading" disabled>{{ trans.get('__JSON__.Exporting...') }}</button>
                 <button @click="exportToCsv" class="btn btn-primary btn-rounded export-btn" v-else>{{ trans.get('__JSON__.Export To CSV') }}</button>
                 <button @click="exportToCsv" class="btn btn-primary btn-rounded m-export-btn mb-4"><i class="bx bx-archive-in"></i></button>
              </div>
              </div>
          </div>
      </div>

      <!-- end row -->
      <div class="row">
        <div class="col-12 list-table-main">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="main-sales-report m-avg-rating">
                  <div v-if="restaurant_count > 0" class="v-avg-rating-report">
                    <div class="form-group">
                      <div class="date-range-list">
                        <label>{{ trans.trans('__JSON__.Vendor',{ vendor: $auth.setting.restaurant_name }) }} :</label>
                        <select class="custom-select" v-model="restaurants_id" @change="searchData()">
                          <option value selected>{{ trans.trans('__JSON__.All Vendor',{ vendor: $auth.setting.restaurant_name }) }}</option>
                          <option
                            v-for="res in restaurant"
                            :value="res.restaurant_id"
                            :key="res.restaurant_id"
                          >{{res.name}}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="v-avg-report-date">
                    <div class="form-group">
                      <div class="date-range-list">
                        <label>{{trans.get('__JSON__.Date')}}:</label>
                        <date-range-picker
                          ref="picker"
                          :locale-data="{
                            firstDay: 1,
                            format: 'dd-mm-yyyy',
                          }"
                          :showDropdowns="true"
                          :showWeekNumbers="true"
                          opens="left"
                          v-model="dateRange"
                          :ranges="date_ranges()"
                          @update="updateValues"
                        >
                          <template
                            v-slot:input="picker"
                            style="min-width: 350px"
                          >
                            {{ moment(picker.startDate.toISOString()).format('MMMM DD, YYYY') | date }} -
                            {{ moment(picker.endDate.toISOString()).format('MMMM DD, YYYY') | date }}
                          </template>
                        </date-range-picker>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 table-main-list table-responsive">
                  <b-table
                    :items="vendorAvgReport.data"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :no-local-sorting="true"
                    @sort-changed="sortingChanged"
                    :busy="loading"
                    show-empty
                  >
                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>{{trans.get('__JSON__.Loading...')}}</strong>
                      </div>
                    </template>

                    <template #cell(average)="data">
                        <span>{{ data.item.average.toFixed(2) || '0.00'  }}</span>
                    </template>

                    <template #empty>
                      <p class="text-center">{{ trans.get('__JSON__.No Avg Rating Report Found') }}</p>
                    </template>
                  </b-table>
                </div>
                <div class="row col-sm-5 offset-sm-7">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <pagination
                          :data="vendorAvgReport"
                          :limit="limit"
                          @pagination-change-page="getVendorRatingReport"
                        ></pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>