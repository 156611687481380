<template>
<div class="row">
    <div class="col-12 mt-3" v-if="pending">
        <PageHeader :title="trans.get('__JSON__.Pending Settlement')" />
        <div class="table-responsive mb-0 table-border" data-pattern="priority-columns">
            <table class="table table-centered table-nowrap table-hover" v-if="pending">
                <thead class="thead-light">
                <tr role="row" class="">
                    <th>{{trans.get('__JSON__.Duration')}}</th>
                    <th>{{trans.get('__JSON__.Delivered Order')}}</th>
                     <th>{{trans.get('__JSON__.DriverEarning')}}</th>
                      <th>{{trans.get('__JSON__.COD Amount')}}</th>
                    <th>{{trans.get('__JSON__.Wallet')}}</th>
                    <th>{{trans.get('__JSON__.Status')}}</th>
                    <th>{{trans.get('__JSON__.Action')}}</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>{{pending.finance_month_year}}</td>
                    <td>{{pending.order_count}}</td>
                    <td>{{pending.driver_earning}}</td>
                    <td>{{(pending.cod_amount||0).toFixed(decimal)}}</td>
                    <td v-if="pending.pending_amount > 0"><span v-html="currency"></span> {{(pending.pending_amount|| 0).toFixed(decimal)}}</td>
                    <td v-else-if="pending.pending_amount < 0"><span v-html="currency"></span> {{(pending.driver_earning|| 0).toFixed(decimal)}}</td>
                    <td>
                        <span
                            class="badge badge-pill badge-soft-danger font-size-12"
                        >{{trans.get('__JSON__.Pending')}}</span>
                    </td>
                    <td>
                        <b-button variant="success" @click="openPaymodel()"  v-if="pending.pending_amount > 0 && $auth.hasPermission(config.permissions.driver_finance_write) && !$auth.partner_login">{{trans.get('__JSON__.Receive')}}</b-button>
                        <b-button variant="success" @click="openPaymodel()"  v-if="pending.pending_amount < 0 && $auth.hasPermission(config.permissions.driver_finance_write) && !$auth.partner_login">{{trans.get('__JSON__.Pay')}}</b-button>
                        <b-button variant="primary" @click="viewTransaction(pending.min_date,pending.max_date)">{{trans.get('__JSON__.View')}}</b-button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="col-12 mt-4">
        <PageHeader :title="trans.get('__JSON__.Processed Settlement')" />
        <div class="table-responsive mb-0 table-border" data-pattern="priority-columns">
            <b-table
                :items="finances && finances.data || []"
                :fields="fields"
                show-empty
                :busy="loading"
                class="table-centered table-nowrap table-hover order-menu"
            >
                <template #table-busy>
                    <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>{{trans.get('__JSON__.Loading...')}}</strong>
                    </div>
                </template>
                <template #cell(duration_period)="data">
                    {{ data.value}}
                </template>
                <template #cell(order_count)="data">
                    {{ data.value}}
                </template>
                <template #cell(cod_amount)="data">
                    <span v-html="currency"></span> {{ data.value && data.value.toFixed(decimal) || 0}}
                </template>
                <template #cell(status)="">
                    <span
                            class="badge badge-pill badge-soft-success font-size-12"
                        >{{trans.get('__JSON__.Received')}}</span>
                    <!-- <b-button :variant="data.value == 'Paid'?'success':'danger'" class="btn-xs">{{data.value }}</b-button> -->
                </template>
                <template #cell(id)="data">
                    <b-button variant="primary" @click="paymentInfo(data.value)">{{trans.get('__JSON__.Info')}}</b-button>
                    <b-button variant="primary" @click="openTransaction(data.item.finance_month_year)">{{trans.get('__JSON__.View')}}</b-button>
                </template>
            </b-table>
        </div>    
    </div>
     <div class="col-12">
            <div class="dataTables_paginate paging_simple_numbers float-right">
            <ul class="pagination pagination-rounded mb-0" v-if="finances">
                <!-- pagination -->
                <pagination
                    :data="finances"
                    :limit="limit"
                    @pagination-change-page="getDriverSettlements"
                ></pagination>
            </ul>
            </div>
    </div>
    <b-modal id="modal-pay-info"
        size="lg"
        :title="trans.get('__JSON__.Info')" hide-footer>
        <table v-if="payment_info" class="table">
            <tr>
                <td><span class="font-weight-bold">{{trans.get('__JSON__.Date Of Payment')}}</span></td>
                <td><span class="font-weight-bold">{{trans.get('__JSON__.Payment Mode')}}</span></td>
                <td><span class="font-weight-bold">{{trans.get('__JSON__.Transaction Id / Cheque No')}}</span></td>
                <td><span class="font-weight-bold">{{trans.get('__JSON__.Notes')}}</span></td>
            </tr>
            <tr>
                <td>{{ payment_info.date_of_payment }}</td>
                <td>{{ payment_info.payment_mode }}</td>
                <td>{{ payment_info.transaction_id }}</td>
                <td>{{ payment_info.notes }}</td>
            </tr>
        </table>
    </b-modal>
    <b-modal id="modal-pay" :title="trans.get('__JSON__.Book Payment')" hide-footer>
        <form @submit.prevent="postBookPayment">
            <div class="row">
                <div class="col">
                    <b-form-group id="example text" :label="trans.get('__JSON__.Settlement From (Non Selectable)')" label-for="settlement_from">
                        <b-form-input :value="pendingWithChangeDate && pendingWithChangeDate.start_date || null"
                        :class="{ 'is-invalid': $v.book_form.settlement_from.$error }"
                        class="form-control" id="settlement_from" type="text" disabled></b-form-input>                
                        <!-- <div v-if="$v.book_form.date_of_payment.$error" class="invalid-feedback">
                            <span v-if="!$v.book_form.date_of_payment.required">{{ trans.get('__JSON__.Please enter date') }}</span>
                        </div> -->
                    </b-form-group>  
                    <b-form-group id="example text" :label="trans.get('__JSON__.Settlement To(Selectable)')" label-for="settlement_to">
                        <date-picker v-model="$v.book_form.settlement_to.$model" @change="getPending" value-type="format" :disabledDate="disabledDate" :clearable="false" format="YYYY-MM-DD" id="settlement_to"></date-picker>
                        <div v-if="$v.book_form.settlement_to.$error" class="invalid-feedback">
                            <span v-if="!$v.book_form.settlement_to.required">{{ trans.get('__JSON__.Please enter date') }}</span>
                        </div>
                    </b-form-group>
                    <div class="form-group">
                        <label for="payment_mode">{{trans.get('__JSON__.Payment Mode')}}</label>
                        <select name="payment_mode"
                        :class="{ 'is-invalid': $v.book_form.payment_mode.$error }"
                        id="payment_mode" class="form-control" v-model="$v.book_form.payment_mode.$model" >
                            <option value="Wire">Wire</option>
                            <option value="Cash">Cash</option>
                            <option value="Cheque">Cheque</option>
                        </select>
                        <div v-if="$v.book_form.payment_mode.$error" class="invalid-feedback">
                            <span v-if="!$v.book_form.payment_mode.required">{{ trans.get('__JSON__.Please enter payment mode') }}</span>
                        </div>
                    </div>
                    <b-form-group :label="trans.get('__JSON__.Transaction Id / Cheque No')" label-for="transaction_id">
                        <b-form-input id="transaction_id" type="text"
                        :class="{ 'is-invalid': $v.book_form.transaction_id.$error }"
                        :placeholder="trans.get('__JSON__.Transaction Id / Cheque No')" v-model="$v.book_form.transaction_id.$model"></b-form-input>
                        <div v-if="$v.book_form.transaction_id.$error" class="invalid-feedback">
                            <span v-if="!$v.book_form.transaction_id.required">{{ trans.get('__JSON__.Please enter transaction id') }}</span>
                        </div>
                    </b-form-group>
                    <b-form-group :label="trans.get('__JSON__.Amount')" label-for="cod_amount">
                        <b-form-input id="cod_amount" type="text"
                        disabled
                        :placeholder="trans.get('__JSON__.Amount')" :value="(pendingWithChangeDate && pendingWithChangeDate.pending_amount || 0).toFixed(decimal)"></b-form-input>
                       
                    </b-form-group>                
                    <div class="form-group">
                        <label for="notes">{{trans.get('__JSON__.Notes')}}</label>
                        <textarea id="notes" cols="30" rows="10" class="form-control"
                        :class="{ 'is-invalid': $v.book_form.notes.$error }"
                        :placeholder="trans.get('__JSON__.Notes')" v-model="$v.book_form.notes.$model"></textarea>
                        <div v-if="$v.book_form.notes.$error" class="invalid-feedback">
                            <span v-if="!$v.book_form.notes.required">{{ trans.get('__JSON__.Please enter notes') }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <button class="btn btn-secondary" type="button" @click="$bvModal.hide('modal-pay')">{{trans.get('__JSON__.Cancel')}}</button>
                    <button class="btn btn-primary" type="submit">{{trans.get('__JSON__.Receive')}}</button>
                </div>
            </div>
        </form>
    </b-modal>
</div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import PageHeader from "../../../components/page-header";
import pagination from "laravel-vue-pagination";
import { driverService } from '../../../services';
import config from "../../../config";
import {
  required
} from "vuelidate/lib/validators";
import { get_currency, get_decimal, success_message } from '../../../Helper/helper';
export default {
    components:{pagination,DatePicker, PageHeader},
    props: ['driver_id'],
    data() {
        return {
            config:config,
            currency: get_currency(),
            decimal: get_decimal(),
            book_form: {
                date_of_payment: this.moment().format('YYYY-MM-DD'),
                payment_mode: "Wire",
                transaction_id: "",
                notes: "",
                driver_id: this.driver_id,
                order_ids: '',
                settlement_to: null,
                settlement_from: null,
                amount: 0,
            },
            limit: 1,
            sortBy: "date",
            sortDesc: true,
            finances: null,
            pending:null,
            pendingWithChangeDate : null,
            loading: false,
            payment_info: {},
            fields: [
                { key: "finance_month_year", label: this.trans.get("__JSON__.Duration"), sortable: false },
                { key: "order_count", label: this.trans.get("__JSON__.Delivered Order"), sortable: false },
                { key: "cod_amount", label: this.trans.get("__JSON__.Wallet"), sortable: false },
                { key: "status", label: this.trans.get("__JSON__.Status"), sortable: false },
                { key: "id", label: this.trans.get("__JSON__.Action"), sortable: false },
            ],
            
        }
    },
    validations: {
        book_form: {
            settlement_from: { required },
            settlement_to: { required },
            payment_mode: { required },
            transaction_id: { required },
            notes: {},
            order_ids:{ required }
        },
    },
    created(){
        this.getDriverSettlements();
    },
    methods: {
        disabledDate(date){
            return !(this.moment(date).isAfter(this.moment(this.pending.start_date)) && this.moment(date).isSameOrBefore(this.moment().subtract(1,'day')))
        },
        sortingChanged(ctx) {
            this.sortBy = ctx.sortBy;
            this.sortDesc = ctx.sortDesc;
            this.getDriverSettlements();
        },
        getDriverSettlements(page = 1){
            this.loading = true;
            driverService.getDriverSettlements({
                    driver_id: this.driver_id,
                    sortBy: this.sortBy,
                    orderBy: this.sortDesc ? "desc" : "asc",
                    page: page ? page : 1,
            })
            .then(res => {
                this.loading = false;
                this.finances = res.data.result.settlements;
                this.pending = res.data.result.pending;
                this.pendingWithChangeDate = res.data.result.pending;
            })
        },
        getPending(date){
            driverService.getDriverPendingSettlement({
                driver_id: this.driver_id,
                end_date: this.book_form.settlement_to
            }).then(res => {
                this.pendingWithChangeDate = res.data.result;
                this.book_form.order_ids = this.pendingWithChangeDate.order_ids;
                if(this.pendingWithChangeDate.pending_amount > 0){
                    this.book_form.amount = this.pendingWithChangeDate.pending_amount;
                }else{
                    this.pendingWithChangeDate.pending_amount = this.pendingWithChangeDate.driver_earning;
                    this.book_form.amount = this.pendingWithChangeDate.driver_earning;
                   
                }
                
              
            })
        },
        paymentInfo(id){
            this.$bvModal.show('modal-pay-info')
            this.payment_info = this.finances.data.find( fins => fins.id == id );
        },
        postBookPayment(){
            this.$v.book_form.$touch()
          
            if(!this.$v.book_form.$invalid){
                driverService.receiveDriverSettlement(this.book_form)
                    .then(response => {
                        success_message(response.data.message)
                        this.$bvModal.hide('modal-pay')
                        this.getDriverSettlements()
                    })   
            }
        },
        openPaymodel(){
            this.book_form.order_ids = this.pendingWithChangeDate.order_ids;
            if(this.pendingWithChangeDate.pending_amount > 0){
                    this.book_form.amount = this.pendingWithChangeDate.pending_amount;
            }else{
               this.pendingWithChangeDate.pending_amount = this.pendingWithChangeDate.driver_earning;
               this.book_form.amount = this.pendingWithChangeDate.driver_earning;

            }

           // this.book_form.amount = this.pendingWithChangeDate.pending_amount;
            this.book_form.settlement_to = this.pending.end_date;
            this.book_form.settlement_from = this.pending.start_date;
            this.$bvModal.show('modal-pay')
        },
        viewTransaction(start_date, end_date){
            this.$router.push({name:'driver-finance-transactions', params: this.driver_id, query:{start_date, end_date}})
        },
        openTransaction(duration){
            this.viewTransaction(
                this.moment(duration.split('-')[0].trim(),'DD MMM YYYY').startOf('day').format('YYYY-MM-DD HH:mm:ss'),
                this.moment(duration.split('-')[1].trim(),'DD MMM YYYY').endOf('day').format('YYYY-MM-DD HH:mm:ss')
            );
        }
    }
}
</script>

<style>

</style>