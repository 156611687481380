
// Category services rest api calling

import { multipart_headers } from '../Helper/helper';
import { routes } from '../config/api_routes'

export const categoryService = {
    // get category 
    // info: sortDesc : Boolean

    getCategory: (formData) => {
        return window.axios.post(routes.getCategoryApi, formData)
    },

    // category export to csv
    exportToCsv(formData) {
        return window.axios.post(routes.categoryExportToCsvApi, formData)
    },

    // category add

    addCategory: (formData) => {
        return window.axios.post(routes.addCategoryApi, formData, { headers: multipart_headers() });
    },

    // get categorybyID

    getCategoryById: (formData) => {
        return window.axios.post(routes.getCategoryByIdApi, formData)
    },

    // update categoryByID

    updateCategoryById: (formData) => {
        return window.axios.post(routes.updateCategoryApi, formData, { headers: multipart_headers() });
    },

    // delete category

    deleteCategory: (formData) => {
        return window.axios.post(routes.deleteCategoryApi, formData)
    },

    // Reorder

    updateCategoryOrder: (formData) => {
        return window.axios.post(routes.updateCategoryOrderApi, formData)
    }
}