<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import pagination from "laravel-vue-pagination";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import {
  get_partner_login,
  set_partner,
  set_partner_details,
  set_user,
  set_user_detail,
  debounce,
  get_decimal,
  get_currency,
} from "../../Helper/helper";
import moment from "moment";
import { reportService, deliveryZoneService } from "../../services";
/**
 * Report component
 */
export default {
  components: { Layout, PageHeader, pagination },
  data() {
    return {
      decimal: get_decimal(),
      totalSale: {},
      restaurant: [],
      totalSaleReport: [],
      restaurant_count: "",
      restaurants_id: "",
      sortBy: "created_date",
      sortDesc: true,
      paginations: {},
      limit: 2,
      perPage: 0,
      currentPage: 1,
      currency: get_currency(),
      countries: [],
      country: 0,
      states: [],
      state: 0,
      cities: [],
      city: 0,
      country_count: "",
      state_count: "",
      city_count: "",
      loading: true,
      isDownloading: false,
      fields: [
        { key: "user_id", label: "#", sortable: false },
        { key: "user_name", label: "Store Name", sortable: false },
        { key: "store_id", label: "Store ID", sortable: false },
        { key: "view_invoice", label: "View GST Invoice", sortable: false },
      ],
    };
  },
  mounted() {
    // this.$data.fields[3].label = this.$auth.getRestaurantName() +' Name';
    // this.$data.fields[6].label = this.$auth.getRestaurantName() +' Earning';
    // this.getTotalSalesReports();
    this.getVendorCountry();
    // this.getRestaurants();
    // this.totalSaleExportCsv();
  },
  methods: {
    downloadsCSV: function () {
      let totalSaleReport =
        '\ufeff' +
        'S.No.,Date,'+this.$auth.getRestaurantName()+' Name,No. of Orders,Order Value,Average Duration\n'
      this.totalSaleReport.forEach((el, ind) => {
        let sno = ind + 1;
        var line = sno + "," +
        el["created_at"] +
          "," +
          el["restaurants_name"] +
          "," +
          el["order_count"] +
          "," +
          el["order_value"].toFixed(2) +
          "," +
          parseFloat(el["average_duration"]).toFixed(2) +
          "\n"
        totalSaleReport += line;
      });

      var blob = new Blob([totalSaleReport], { type: "csv/plain" });
      var date = new Date().toLocaleString();
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "summary_" + date + ".csv";
      link.click();
    },
    getTotalSalesReports(page) {
      this.loading = true;
      reportService
      .gettotalStoreReport({
        sortBy: this.sortBy,
        orderBy: this.sortDesc ? "asc" : "desc",
        country: this.country,
        state: this.state,
        city: this.city,
        // restaurants_id: this.restaurants_id,
        search: this.restaurants_id,
        is_confirm: 1,
        page: page ? page : 1,
      })
      .then((response) => {
        this.loading = false;
        // this.paginations = response.data.result;
        this.totalSale = response.data.result;
      });
    },
    totalSaleExportCsv() {
      this.isDownloading = true;
      reportService
      .totalSummaryExportToCSV({ 
        restaurants_id: this.restaurants_id,
        sortBy: this.sortBy,
        orderBy: this.sortDesc ? "asc" : "desc",
      })
      .then((response) => {
        this.isDownloading = false;
        this.totalSaleReport = response.data.result;
        this.downloadsCSV();
      });
    },
    getVendorCountry() {
      this.country = 0;
      this.city = 0;
      this.state = 0;
      this.countries = [];
      this.states = [];
      this.cities = [];
      deliveryZoneService.getVendorCountry().then((response) => {
        this.countries = response.data.result;
        if (this.countries.length == 1) {
          this.country = this.countries[0].id;
        }
        this.getVendorState();
      });
    },
    getRestaurants(){
      this.restaurant = [];
      reportService.getAllRestaurantName({
        country_id: this.country,
        state_id: this.state,
        city_id: this.city
      }).then((response) => {
        this.restaurant = response.data.result;
        this.restaurant_id = this.restaurant[0].restaurant_id;
        this.searchData();
      });
    },
    getVendorState() {
      this.city = 0;
      this.state = 0;
      this.states = [];
      this.cities = [];
      this.country = parseInt(this.country);
      if(this.country > 0) {
        deliveryZoneService.getVendorState({country_id: this.country})
        .then((response) => {
          this.states = response.data.result;
          if (this.states.length == 1) {
            this.state = this.states[0].id;
          }
          this.getVendorCity();
        });
      } else {
        this.searchData();
      }
    },
    getVendorCity() {
      this.city = 0;
      this.cities = [];
      this.state = parseInt(this.state);
      if(this.state > 0) {
        deliveryZoneService.getVendorCity({state_id:this.state ? this.state : 0,country_id: this.country})
        .then((response) => {
          this.cities = response.data.result;
          if (this.cities.length == 1) {
            this.city = this.cities[0].cityid;
          }
          this.searchData();
          this.getRestaurants();
        });
      } else {
        this.searchData();
        this.getRestaurants();
      }
    },
    citychange() {
      this.searchData();
      this.getRestaurants();
    },
    searchData() {
      this.getTotalSalesReports();
      // this.totalSaleExportCsv();
    },
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      this.getTotalSalesReports();
      // this.totalSaleExportCsv();
    },
  },
};
</script>

<template>
  <Layout>
    <div>
      <div class="row">
          <div class="col-12">
              <div
              class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
              >
              <div class="page-title-left avatar-main-icon">
                  <div class="avatar-xs mr-3">
                  <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                      <i class="bx bx-purchase-tag"></i>
                  </span>
                  </div>
                  <div class="report-tag">
                    <h4 class="mb-0 font-size-18">{{ trans.get('__JSON__.Store GST Report') }}</h4>
                    <p><router-link :to="{ name: 'reports-list'}">{{ trans.get('__JSON__.Reports') }}</router-link> > {{ trans.get('__JSON__.Store GST Report') }}</p>
                  </div>
              </div>
              <!-- <div class="page-title-right">
                <button @click="totalSaleExportCsv" class="btn btn-primary btn-rounded export-btn" v-if="isDownloading" disabled>{{ trans.get('__JSON__.Exporting...') }}</button>
                <button @click="totalSaleExportCsv" class="btn btn-primary btn-rounded export-btn" v-else>{{ trans.get('__JSON__.Export To CSV') }}</button>
                <button @click="totalSaleExportCsv" class="btn btn-primary btn-rounded m-export-btn"><i class="bx bx-archive-in"></i></button>
              </div> -->
              </div>
          </div>
      </div>

      <!-- end row -->
      <div class="row">
        <div class="col-12 list-table-main">
          <div class="card">
            <div class="card-body">
              <div class="row justify-content-between">
                <div v-if="countries.length >1" class="total-sales-report-earning">
                </div>
                <div v-else class="offset-sm-2">
                </div>
                 <div v-if="(countries.length >1 || states.length > 1 || cities.length > 1) && restaurant.length > 1" class="total-sales-report-earning">
                  <div class="form-group">
                    <div class="date-range-list">
                      <label>{{ trans.trans('__JSON__.Vendor',{ vendor: $auth.setting.restaurant_name }) }} :</label>
                      <select class="custom-select store-gst" v-model="restaurants_id" @change="searchData()">
                        <option value selected>{{ trans.trans('__JSON__.All Vendor',{ vendor: $auth.setting.restaurant_name }) }}</option>
                        <option v-for="res in restaurant" :value="res.restaurant_id" :key="res.restaurant_id">{{res.name}}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 table-main-list total-sales-report-table table-responsive">
                  <b-table
                    :items="totalSale.data"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :no-local-sorting="true"
                    @sort-changed="sortingChanged"
                    :busy="loading"
                    show-empty
                  >
                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>{{trans.get('__JSON__.Loading...')}}</strong>
                      </div>
                    </template>

                    <template v-slot:cell(user_id)="data">
                      <div>{{ (totalSale.per_page * (totalSale.current_page-1)) + data.index + 1 }}</div>
                    </template>

                    <template v-slot:cell(user_name)="data">
                      <span>{{ data.item.name }}</span>
                    </template>

                    <template v-slot:cell(store_id)="data">
                      <span>{{ data.item.restaurant_id }}</span>
                    </template>

                    <template v-slot:cell(view_invoice)="data">
                        <router-link :to="{name:'reports.store.wise.gst.report',params: {restaurantID : data.item.restaurant_id}}" class="btn btn-primary btn-sm btn-rounded">
                             View GST Invoice
                        </router-link>
                    </template>              

                    <template #empty>
                      <p class="text-center">{{ trans.get('__JSON__.No Store GST Report Found') }}</p>
                    </template>
                  </b-table>
                </div>
                <div class="row col-sm-5 offset-sm-7">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <pagination
                          :data="totalSale"
                          :limit="limit"
                          @pagination-change-page="getTotalSalesReports"
                        ></pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>


<style>
.total-sales-report-table .table thead th:nth-child(1),.total-sales-report-table .table thead th:nth-child(3),.total-sales-report-table .table thead th:nth-child(4){
    pointer-events: none;
}

.store-gst{
    width: 96% !important;
}
</style>