<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import pagination from "laravel-vue-pagination";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import {
  get_partner_login,
  set_partner,
  set_partner_details,
  set_user,
  set_user_detail,
  debounce,
  get_decimal,
  get_currency,
  date_ranges
} from "../../Helper/helper";
import moment from "moment";
import { reportService, deliveryZoneService } from "../../services";
/**
 * Report component
 */
export default {
  components: { Layout, PageHeader, pagination, DateRangePicker },
  data() {
    let startDate = this.$auth.partner_login ? this.moment().startOf('day').subtract(29, "days") : this.moment().startOf('day').subtract(6, "days");
    let endDate = this.moment().endOf("day").toDate();
    return {
      decimal:get_decimal(),
      currency: get_currency(),
      dateRange: { startDate, endDate },
      reports: [],
      reportsData: [],
      paginations: {},
      countries: [],
      country: "0",
      states: [],
      state: "0",
      cities: [],
      city: "0",
      country_count: "",
      state_count: "",
      city_count: "",
      date_ranges: date_ranges,
      limit: 2,
      perPage: 0,
      currentPage: 1,
      filters: {
        start_date: this.$auth.partner_login ? this.moment().startOf('day').subtract(29, "days").format('YYYY-MM-DD') : this.moment().startOf('day').subtract(29, "days").format('YYYY-MM-DD'),
        end_date: this.moment().format('YYYY-MM-DD'),
      },
      sortBy: "created_at",
      moment: moment,
      loading: true,
      isDownloading: false,
      fields: [
        { key: "table_id", label: "#", sortable: false},
        { key: "driver_name", label: "Driver Name", sortable: false, requiresAdmin: true },
        { key: "driver_earning", label: "Driver Earning", sortable: false, requiresAdmin: true},
        { key: "cod_amount", label: "COD Amount", sortable: true},
        { key: "wallet_amount", label: "Wallet Amount", sortable: false }
      ],
    };
  },
  computed: {
    computedFields() {
      // If the user isn't an admin, filter out fields that require auth.
      if(this.$auth.partner_login)
        return this.fields.filter(field => !field.requiresAdmin);
        
      // If the user IS an admin, return all fields.
      else 
        return this.fields;
    }
  },
  mounted() {
    this.getSalesReports();
  },
  methods: {
    updateValues(date) {
      this.filters = {
        ...this.filters,
        start_date :  this.moment(date.startDate.toISOString()).format('YYYY-MM-DD'),
        end_date :  this.moment(date.endDate.toISOString()).format('YYYY-MM-DD'),
      }
      this.getSalesReports();
    },
    dateFormat(classes, date) {
      this.filters = {
        ...this.filters,
        start_date: this.moment(date.startDate.toISOString()).format(
          "YYYY-MM-DD"
        ),
        end_date: this.moment(date.endDate.toISOString()).format("YYYY-MM-DD")
      };
    },
    downloadsCSV: function () {
        let reportsData =
          '\ufeff' +
          ''+this.$auth.getDriverName()+' Name,'+this.$auth.getDriverName()+' Earning,Item '+this.$auth.getTaxName()+',COD Amont,Wallet Amount\n'
        this.reportsData.forEach((el) => {
          var line =
            el["firstname"] +
            "," +
            el["driver_earning"] +
            "," + 
            el["cod_amount"] +
            "," +
            el["wallet_amount"] +","
            +"\n"
          reportsData += line;
        });

        var blob = new Blob([reportsData], { type: "csv/plain" });
        var date = new Date().toLocaleString();
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "driver_settlement_" + date + ".csv";
        link.click();
      
    },
    getVendorCountry() {
      this.countries = [];
      this.states = [];
      this.cities = [];
      deliveryZoneService.getVendorCountry().then((response) => {
        this.countries = response.data.result;
        this.getVendorState();
      });
    },
     getRestaurants(){
        this.restaurant = [];
        reportService.getAllRestaurantName({
            country_id: this.country,
            state_id: this.state,
            city_id: this.city
        }).then((response) => {
            this.restaurant = response.data.result;
            this.restaurant_id = this.restaurant[0].restaurant_id;
            this.searchData();
          });
    },
     getVendorState() {
      this.city = '0';
      this.state = '0';
      this.states = [];
      this.cities = [];
      deliveryZoneService
        .getVendorState({
          country_id: this.country,
        })
        .then((response) => {
          this.states = response.data.result;
          this.getVendorCity();
        });
    },
    getVendorCity() {
      this.city = '0';
      this.cities = [];
      deliveryZoneService
        .getVendorCity({
          state_id:this.state ? this.state : '0',
          country_id: this.country
        })
        .then((response) => {
          this.cities = response.data.result;
          this.searchData();
          this.getRestaurants();
        });
    },
    citychange()
    {
        this.searchData();
        this.getRestaurants();
    },
    getUser() {
      this.users = this.$auth.user;
      this.userdetail = this.$auth.setting;
    },
    getSalesReports(page) {
      this.loading = true;
        reportService
          .getDriverSettlementReport({
            ...this.filters,
            sortBy: this.sortBy,
            orderBy: this.sortDesc ? "asc" : "desc",
            country: this.country,
            state: this.state,
            city: this.city,
            order_status:  this.order_status,
            restaurants_id: this.restaurants_id,
            page: page ? page : 1,
          })
          .then((response) => {

            this.loading = false;
          //  this.paginations = response.data;
            this.reports = response.data.result;
                       
          });
     
    },
    saleExportCsv() {
          this.isDownloading = true;
          reportService
          .exportDriverSettlementToCsv({ 
            ...this.filters,
            payment_method:  this.payment_method,
            sortBy: this.sortBy,
            orderBy: this.sortDesc ? "desc" : "asc",
          })
          .then((response) => {
            this.isDownloading = false;
            this.reportsData = response.data.result;
            this.downloadsCSV();
          });

    },
    searchData() {
      this.getSalesReports();
      // this.saleExportCsv();
    },
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      this.getSalesReports();
      this.saleExportCsv();
    },
  },
  filters: {
      date(val) {
        return val ? val.toLocaleString() : "";
      },
    },
};
</script>

<template>
  <Layout>
    <div>
       <div class="row">
          <div class="col-12">
              <div
              class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
              >
              <div class="page-title-left avatar-main-icon">
                  <div class="avatar-xs mr-3">
                  <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                      <i class="bx bx-purchase-tag"></i>
                  </span>
                  </div>
                  <div class="report-tag" v-if="!$auth.partner_login">
                    <h4 class="mb-0 font-size-18">{{ trans.get('__JSON__.Driver Settlement') }}</h4>
                    <p><router-link :to="{ name: 'reports-list'}">{{ trans.get('__JSON__.Reports') }}</router-link> > {{ trans.get('__JSON__.Driver Settlement') }}</p>
                  </div>
                  <div class="report-tag" v-else>
                    <h4 class="mb-0 font-size-18">{{ trans.get('__JSON__.Driver Settlement') }}</h4>
                    <p><router-link :to="{ name: 'vendor.reports.list'}">{{ trans.get('__JSON__.Reports') }}</router-link> > {{ trans.get('__JSON__.Driver Settlement') }}</p>
                  </div>
              </div>
              <div class="page-title-right">
                <button @click="saleExportCsv" class="btn btn-primary btn-rounded export-btn" v-if="isDownloading" disabled>{{ trans.get('__JSON__.Exporting...') }}</button>
                <button @click="saleExportCsv" class="btn btn-primary btn-rounded export-btn" v-else>{{ trans.get('__JSON__.Export To CSV') }}</button>
                <button @click="saleExportCsv" class="btn btn-primary btn-rounded m-export-btn"><i class="bx bx-archive-in"></i></button>
              </div>
              </div>
          </div>
      </div>

      <!-- end row -->
      <div class="row">
        <div class="col-12 list-table-main">
          <div class="card">
            <div class="card-body">
              <div class="row" v-if="$auth.partner_login">
                <div class="main-sales-report">
                  <div class="v-sales-report">
                
                  </div>
                  <div class="v-sales-report-date">
                    <div class="form-group">
                      <div class="date-range-list">
                        <label>{{trans.get('__JSON__.Date')}}:</label>
                        <date-range-picker
                          ref="picker"
                          :locale-data="{
                            firstDay: 1,
                            format: 'dd-mm-yyyy',
                          }"
                          :showDropdowns="true"
                          :showWeekNumbers="true"
                          opens="left"
                          v-model="dateRange"
                          @update="updateValues"
                        >
                          <template v-slot:input="picker" style="min-width: 350px">
                            {{ moment(picker.startDate.toISOString()).format('MMMM DD, YYYY') | date }} -
                            {{ moment(picker.endDate.toISOString()).format('MMMM DD, YYYY') | date }}
                          </template>
                        </date-range-picker>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row justify-content-between" v-if="!$auth.partner_login">
                <div v-if="countries.length >1" class="sales-report d-none">
                  <div class="form-group">
                    <div class="date-range-list">
                      <label>{{ trans.get('__JSON__.Country') }} :</label>
                      <select class="custom-select" v-model="country" @change="getVendorState">
                        <option value="0" selected>All</option>
                        <option
                          v-for="con in countries"
                          :value="con.id"
                          :key="con.id"
                        >{{con.country_name}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div v-else class="offset-sm-2">
                </div>
                <div v-if="countries.length >=1 && states.length >1" class="sales-report d-none">
                  <div class="form-group">
                    <div class="date-range-list">
                      <label>{{ trans.get('__JSON__.State') }} :</label>
                      <select class="custom-select" v-model="state" @change="getVendorCity">
                        <option value="0" selected>All</option>
                        <option
                          v-for="sat in states"
                          :value="sat.id"
                          :key="sat.id"
                        >{{sat.state_name}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div v-else class="offset-sm-2">
                </div>
                <div v-if="states.length >=1 && cities.length >1" class="sales-report d-none">
                  <div class="form-group">
                    <div class="date-range-list">
                      <label>{{ trans.get('__JSON__.City') }} :</label>
                      <select class="custom-select" v-model="city" @change="citychange">
                        <option value="0" selected>All</option>
                        <option
                          v-for="cit in cities"
                          :value="cit.cityid"
                          :key="cit.cityid"
                        >{{cit.cityname}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div v-else class="offset-sm-2">
                </div>
               
                
                 <div class="sales-report" v-if="!$auth.partner_login">
                  <div class="form-group">
                    <div class="date-range-list">
                      <label>{{ trans.get('__JSON__.Date') }} :</label>
                     <date-range-picker
                        ref="picker"
                        :locale-data="{
                          firstDay: 1,
                          format: 'dd-mm-yyyy',
                        }"
                        :showDropdowns="true"
                        :showWeekNumbers="true"
                        opens="left"
                        v-model="dateRange"
                        :ranges="date_ranges()"
                        @update="updateValues"
                      >
                        <template
                          v-slot:input="picker"
                          style="min-width: 350px"
                        >
                          {{ moment(picker.startDate.toISOString()).format('MMMM DD, YYYY') | date }} -
                          {{ moment(picker.endDate.toISOString()).format('MMMM DD, YYYY') | date }}
                        </template>
                      </date-range-picker>
                    </div>
                  </div>
                </div>
              </div>
         
              <div class="row table-responsive">
                <div class="col-12 table-main-list table table-centered table-nowrap sales-report-table">
                  <b-table
                    :items="this.reports"
                    :fields="computedFields"
                    :sort-by.sync="sortBy"
                    :no-local-sorting="true"
                    @sort-changed="sortingChanged"
                    :busy="loading"
                    show-empty
                  >
                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>{{trans.get('__JSON__.Loading...')}}</strong>
                      </div>
                    </template>

                    <template v-slot:cell(table_id)="data">
                        <div>{{ data.index + 1 }}</div>
                    </template>

                    <template v-slot:cell(driver_name)="data">
                        <span v-if="data.item.firstname">{{ data.item.firstname }}</span>
                        <span v-else>-</span>
                    </template>
                     <template v-slot:cell(driver_earning)="data">
                        <span v-if="data.item.driver_earning">{{ data.item.driver_earning }}</span>
                        <span v-else>-</span>
                    </template>
                     <template v-slot:cell(cod_amount)="data">
                        <span v-if="data.item.cod_amount">{{ data.item.cod_amount }}</span>
                        <span v-else>-</span>
                      </template>
                    <template v-slot:cell(wallet_amount)="data">
                        <span v-if="data.item.wallet_amount">{{ data.item.wallet_amount }}</span>
                        <span v-else>-</span>
                    </template>


                    <template #empty>
                      <p class="text-center">{{ trans.get('__JSON__.No Driver Settlement is Pending') }}</p>
                    </template>
                  </b-table>
                </div>
                <div class="row col-sm-5 offset-sm-7">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <pagination
                          :data="paginations"
                          :limit="limit"
                          @pagination-change-page="getSalesReports"
                        ></pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>


<style>
.sales-report-table .table thead th:nth-child(1),.sales-report-table .table thead th:nth-child(3),.sales-report-table .table thead th:nth-child(4),.sales-report-table .table thead th:nth-child(5),.sales-report-table .table thead th:nth-child(8),.sales-report-table .table thead th:nth-child(13){
    pointer-events: none;
}
</style>