<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
        >
          <div class="page-title-left">
            <div class="avatar-xs mr-3 pmenuavatar">
              <span
                class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18"
              >
                <i class="bx bx-briefcase"></i>
              </span>
            </div>
            <div class="report-tag">
              <h4 class="mb-0 font-size-18">
                {{ trans.trans("__JSON__.Profilemenuedit") }}
              </h4>
              <p>
                <router-link :to="{ name: 'profilemenu' }">{{
                  trans.trans("__JSON__.Profilemenu")
                }}</router-link>
                > {{ menu_name }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <form action="#" @submit.prevent="statuschange">
                  <div class="row">
                    <div class="col-sm-6">
                      <h4 class="card-title">{{ menu_name }}</h4>
                    </div>
                    <div class="col-sm-6 vendor-status status">
                      <b-form-group
                        class="text-end"
                        id="input-group-1"
                        :label="trans.get('__JSON__.Status')"
                        label-for="status"
                      >
                        <label class="switch rightmargin">
                          <input
                            type="checkbox"
                            id="status"
                            name="status"
                            v-model="status"
                            class="switch-on"
                            true-value="1"
                            false-value="0"
                            disabled
                          />
                          <div class="slider round notallowed">
                            <span class="on">{{
                              trans.get("__JSON__.Active")
                            }}</span>
                            <span class="off">{{
                              trans.get("__JSON__.Inactive")
                            }}</span>
                          </div>
                        </label>
                      </b-form-group>
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="formgroup">
                        <label> Menu Name (English)</label>
                        <input
                          type="text"
                          class="form-control notallowed"
                          id="sort_order"
                          disabled
                          v-model="menu_name"
                        />
                      </div>
                    </div>
                    <div class="col-sm-6">
                    <div class="formgroup">
                    <label>Menu Name (Hindi)</label>
                    <input type="text" class="form-control notallowed" disabled id="sort_order" v-model="menu_name_hindi"/>
                    </div>
                  </div>
                  </div>
                </form>
                <div class="row mt-2">
                  <div class="col-sm-6">
                    <div class="formgroup">
                      <label>Menu Description (English)</label>
                      <input
                        type="text"
                        disabled
                        class="form-control notallowed"
                        id="sort_order"
                        v-model="menu_description"
                      />
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="formgroup">
                      <label>Menu Description (Hindi)</label>
                      <input type="text"
                       disabled
                       class="form-control notallowed" id="sort_order" v-model="menu_description_hindi">
                    </div>
                  </div>
                </div>
                  <div class="row mt-2">
                  <div class="col-sm-6">
                      <div class="formgroup">
                        <label>Sort Order</label>
                        <input
                          type="text"
                          class="form-control notallowed"
                          disable
                          id="sort_order"
                          v-model="sort_order"
                        />
                      </div>
                    </div>
                </div>
                <div class="row mt-3">
                  <div class="col-sm-5">
                    <div class="formgroup">
                      <label>
                        <h4 class="card-title required">Menu icon</h4>
                        <p class="card-title-desc"></p>
                      </label>
                      <!-- <div class="relative">
                          <dropzone-popup
                            v-model="menuIcon"
                            id="menu_icons"
                            acceptedFiles=".jpeg,.jpg,.png,.svg"
                            :editable-images="menu_icons"
                            :media-path="menu_icon_path"
                            @image-deleted="this.onMenuicondelete"
                          />
                          <div class="remove2">
                            <i class="bx bx-x"></i>
                          </div>
                          <img
                            :src="menu_icon_path + '/' + menu_icons"
                            alt
                            class="rounded avatar-lg image_thumb"
                            @click="
                              openGallery(menu_icon_path + '/' + menu_icons)
                            "
                          />
                        </div> -->
                      <div class="d-flex">
                        <label
                          class="filelabel"
                          :style="{
                            display:
                              menu_icons || menuiconurl ? 'none' : 'block',
                          }"
                          ><i class="bx bx-plus plus" style="cursor: pointer"
                            ><input
                              accept="image/jpeg,image/png,image/jpg,image/svg+xml"
                              @change="uploadImage"
                              class="FileUpload1"
                              id="FileInput"
                              ref="fileInput"
                              type="file" /></i
                        ></label>
                        <div class="uploaded-img-main">
                          <div v-if="menu_icons" class="prvie-img uploaded-img">
                            <!-- <p class="remove-flg" @click="removeUploadImage">
                              X
                            </p> -->
                            <img
                              v-if="menu_icons"
                              :src="menu_icon_path + '/' + menu_icons"
                              class="dis-img"
                              @click="openFileInput"
                            />
                          </div>
                        </div>
                        <div class="uploaded-img-main">
                          <div
                            v-if="menuiconurl"
                            class="prvie-img uploaded-img"
                          >
                            <p class="remove-flg" @click="removeUploadImage">
                              X
                            </p>
                            <img
                              v-if="
                                this.selectedmenuicon.type == 'image/jpeg' ||
                                this.selectedmenuicon.type == 'image/png' ||
                                this.selectedmenuicon.type == 'image/jpg' ||
                                this.selectedmenuicon.type == 'image/svg+xml'
                              "
                              :src="menuiconurl"
                              class="dis-img"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-2"></div>
                  <div class="col-sm-5">
                    <div class="formgroup">
                      <label>
                        <h4 class="card-title required">Menu image</h4>
                        <p class="card-title-desc"></p>
                      </label>

                      <!-- <dropzone-popup
                          v-model="menuImage"
                          id="menu_image"
                          acceptedFiles=".jpeg,.jpg,.png,.svg"
                          :editable-images="menu_image"
                          :media-path="menu_image_path"
                          @image-deleted="this.onMenuimagedelete"
                        /> -->
                      <div class="d-flex">
                        <label
                          class="filelabel"
                          :style="{
                            display:
                              menu_image || menuimageurl ? 'none' : 'block',
                          }"
                          ><i class="bx bx-plus plus" style="cursor: pointer"
                            ><input
                              accept="image/png"
                              @change="uploadmenuImage"
                              class="FileUpload1"
                              id="FileInput"
                              ref="menuimagefileInput"
                              type="file" /></i
                        ></label>
                        <div class="uploaded-img-main">
                          <div v-if="menu_image" class="prvie-img uploaded-img">
                            <!-- <p
                              class="remove-flg"
                              @click="removeUploadmenuImage"
                            >
                              X
                            </p> -->
                            <img
                              v-if="menu_image"
                              :src="menu_image_path + '/' + menu_image"
                              class="dis-img"
                              @click="openmenuimageFileInput"
                            />
                          </div>
                        </div>
                        <div class="uploaded-img-main">
                          <div
                            v-if="menuimageurl"
                            class="prvie-img uploaded-img"
                          >
                            <p
                              class="remove-flg"
                              @click="removeUploadmenuImage"
                            >
                              X
                            </p>
                            <img
                              v-if="
                                this.selectedmenuimage.type == 'image/jpeg' ||
                                this.selectedmenuimage.type == 'image/png' ||
                                this.selectedmenuimage.type == 'image/jpg' ||
                                this.selectedmenuimage.type == 'image/svg+xml'
                              "
                              :src="menuimageurl"
                              class="dis-img"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-5">
                    <button
                      class="btn btn-primary mr-1 mt-3"
                      @click="updatemenuicon"
                      :disabled="
                        menu_icons ? true : !selectedmenuicon ? true : false
                      "
                    >
                      Update Menu Icon
                    </button>
                  </div>
                  <div class="col-sm-2"></div>
                  <div class="col-sm-5">
                    <button
                      class="btn btn-primary mr-1 mt-3"
                      @click="updatemenuimage"
                      :disabled="
                        menu_image ? true : !selectedmenuimage ? true : false
                      "
                    >
                      Update Menu Image
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../../layouts/main";
import DropzonePopup from "../../../components/widgets/dropzone-popup";
import vue2Dropzone from "vue2-dropzone";
import { settingService } from "../../../services";

export default {
  components: { Layout, DropzonePopup, vueDropzone: vue2Dropzone },
  data() {
    console.log(
      "$route.params",
      this.menuIamge,
      "menu icon",
      this.menuIcon,
      this.$route.params,
      atob(this.$route.params.id)
      // this.$route.params.profilemenu_id,
      // this.$route.params.id,
      // this.$route.params.sort_order,
      // this.$route.params.menu_images
    );
    return {
      menu_id: atob(this.$route.params.id),
      sort_order: "",
      menu_name: "",
      menu_name_hindi: "",
      menu_description: "",
      menu_description_hindi: "",
      status: "",
      menu_image: "",
      menu_image_path: "",
      menuImage: "",
      menu_icons: "",
      menu_icon_path: "",
      menuIcon: "",
      loading: false,
      menuiconurl: "",
      selectedmenuicon: "",
      previewmenuicon: "",
      menuiconformdata: "",
      menuimageurl: "",
      selectedmenuimage: "",
      previewmenuimage: "",
      menuimageformdata: "",
    };
  },
  mounted() {
    this.getprofilemenudetails();
  },
  methods: {
    statuschange() {
      console.log("status changed");
    },
    openFileInput() {
      // Programmatically trigger the file input when the image is clicked
      this.$refs.fileInput.click();
    },
    openmenuimageFileInput() {
      this.$refs.menuimagefileInput.click();
    },
    onMenuimagedelete() {
      this.menu_image = "";
    },
    onMenuicondelete() {
      this.menu_icons = "";
    },
    getprofilemenudetails() {
      this.loading = true;
      settingService.getprofilemenubyId(this.menu_id).then((response) => {
        console.log("response", response, response?.data.result);
        let resresult = response?.data?.result[0];
        this.sort_order = resresult?.sort_order;
        this.menu_name = resresult?.menu_name;
        this.menu_name_hindi = resresult?.menu_name_hindi;
        this.menu_description = resresult?.menu_description;
        this.menu_description_hindi = resresult?.menu_description_hindi
        this.menu_icons = resresult?.menu_icons;
        this.menu_image = resresult?.menu_images;
        this.menu_icon_path = resresult?.menu_icons_path;
        this.menu_image_path = resresult?.menu_images_path;
        this.status = resresult?.status;
        this.selectedmenuicon = "";
        this.menuiconurl = "";
        this.previewmenuicon = "";
        this.selectedmenuimage = "";
        this.menuimageurl = "";
        this.previewmenuimage = "";
      });
    },
    uploadImage(e) {
      this.menu_icons = "";
      this.selectedmenuicon = e.target.files[0];
      // this.previewmenuicon = this.selectedmenuicon.name;
      this.menuiconformdata = new FormData();
      this.menuiconformdata.append("menu_icon", this.selectedmenuicon);
      // formData.append('order_id', this.typeform.order_id);
      // this.typeform.vendor_invoice = formData;
      this.menuiconurl = URL.createObjectURL(e.target.files[0]);
      if (this.selectedmenuicon) {
        settingService
          .Uploadprofilemenuimageicon(this.menuiconformdata)
          .then((response) => {
            console.log("responseiamgeupload", response.data.result);
            this.previewmenuicon = response.data.result;
            // this.getprofilemenubyId();
            // this.updatemenuicon(response.data.result);
            // updatemenuicon(response.data.result);
          });
      }
      if (!this.selectedmenuicon) {
        return;
      }
      e.target.value = null;
    },
    removeUploadImage() {
      if (this.previewmenuicon) {
        let obj = {
          menu_icon: this.previewmenuicon,
        };
        console.log("icon remove api here");
        settingService.Removeprofilemenuiconimage(obj).then((response) => {
          console.log("response", response.data);
        });
      }
      this.selectedmenuicon = "";
      this.menu_icons = "";
      this.menuiconurl = "";
      this.previewmenuicon = "";
    },
    removeUploadmenuImage() {
      if (this.previewmenuimage) {
        let obj = {
          menu_image: this.previewmenuimage,
        };
        console.log("image remove api here");
        settingService.Removeprofilemenuiconimage(obj).then((response) => {
          console.log("response", response.data);
        });
      }
      this.selectedmenuimage = "";
      this.menu_image = "";
      this.menuimageurl = "";
      this.previewmenuimage = "";
    },
    uploadmenuImage(e) {
      this.menu_image = "";
      this.selectedmenuimage = e.target.files[0];
      console.log(this.selectedmenuimage, "sele");
      // this.previewmenuimage = this.selectedmenuimage.name;
      this.menuimageformdata = new FormData();
      this.menuimageformdata.append("menu_image", this.selectedmenuimage);
      // formData.append('order_id', this.typeform.order_id);
      // this.typeform.vendor_invoice = formData;
      this.menuimageurl = URL.createObjectURL(e.target.files[0]);
      if (this.selectedmenuimage) {
        settingService
          .Uploadprofilemenuimageicon(this.menuimageformdata)
          .then((response) => {
            console.log("responsemenuimageupload", response.data.result);
            // this.updatemenuimage(response.data.result);
            this.previewmenuimage = response.data.result;
          });
      }
      if (!this.selectedmenuimage) {
        return;
      }
      e.target.value = null;
    },
    updatemenuicon() {
      if (this.previewmenuicon) {
        let obj = {
          id: this.menu_id,
          menu_icons: this.previewmenuicon,
        };
        settingService.Updateprofilemenu(obj).then((response) => {
          console.log("updateresponse", response.data.result, response);
          this.getprofilemenudetails();
        });
      }
    },
    updatemenuimage() {
      if (this.previewmenuimage) {
        let obj = {
          id: this.menu_id,
          menu_images: this.previewmenuimage,
        };
        settingService.Updateprofilemenu(obj).then((response) => {
          console.log("updateresponse", response.data.result, response);
          this.getprofilemenudetails();
        });
      }
    },
    onuploadmenuicon() {
      if (this.selectedmenuicon) {
        settingService
          .Uploadprofilemenuimageicon(this.menuiconformdata)
          .then((response) => {
            console.log("responseiamgeupload", response.data.result);
            // this.getprofilemenubyId();
            this.updatemenuicon(response.data.result);
            // updatemenuicon(response.data.result);
          });
      }
    },
    onuploadmenuimage() {
      if (this.selectedmenuimage) {
        settingService
          .Uploadprofilemenuimageicon(this.menuimageformdata)
          .then((response) => {
            console.log("responsemenuimageupload", response.data.result);
            // this.getprofilemenubyId();
            this.updatemenuimage(response.data.result);
            // updatemenuimage(response.data.result);
          });
      }
    },
  },
};
</script>


<style>
.notallowed {
  cursor: not-allowed;
}
/* .rightmargin {
  margin-right: 28px;
} */
.status label:first-child {
  text-align: end !important;
  /* margin-right: 50px!important; */
}
.status div:first-child {
  text-align: end !important;
}
.pmenuavatar {
  margin-bottom: 15px !important;
}

.remove2 {
  position: absolute;
  cursor: pointer;
  text-align: center;
  left: 3px;
  top: 3px;
  color: white;
  background: red;
  height: 20px;
  padding-top: 0px;
  border-radius: 50%;
  width: 20px;
  font-size: 16px;
  box-shadow: 0px 0px 5px black;
}

.filelabel {
  background: var(--secondarylight);
  min-height: 85px;
  min-width: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-bottom: 0;
  margin-right: 10px;
}

#FileInput {
  display: none;
}

.uploaded-img-main {
  display: flex;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
  position: relative;
}
.uploaded-img {
  position: relative;
}

.uploaded-img p {
  margin-bottom: 0;
  position: absolute;
  color: #fff;
  right: -8px;
  top: -8px;
  background: red;
  z-index: 2;
  height: 20px;
  width: 20px;
  object-fit: contain;
  font-size: 14px;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
}

p.remove-flg {
  cursor: pointer;
}
.dis-img {
  height: 100px;
  display: flex;
  margin: 15px;
  cursor: pointer;
}
</style>
