<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import pagination from "laravel-vue-pagination";
import {
  get_partner_login,
  set_partner,
  set_partner_details,
  set_user,
  set_user_detail,
  debounce,
  get_decimal,
  get_currency,
  date_ranges
} from "../../Helper/helper";
import moment from "moment";
import { reportService, deliveryZoneService } from "../../services";
/**
 * Report component
 */
export default {
  components: { Layout, PageHeader, DateRangePicker, pagination },
  data() {
    let startDate = this.moment().startOf("day").subtract(29, "days");
    let endDate = this.moment().endOf("day").toDate();
    return {
      decimal: get_decimal(),
      dateRange: { startDate, endDate },
      filters: {
        start_date: this.moment().subtract(29,'days').format('YYYY-MM-DD'),
        end_date: this.moment().format('YYYY-MM-DD'),
      },
      orderReport: {},
      orderExportReport: [],
      sortBy: "created_date",
      sortDesc: true,
      paginations: {},
      limit: 2,
      currency: get_currency(),
      search:'',
      countries: [],
      country: "0",
      states: [],
      state: "0",
      cities: [],
      city: "0",
      date_ranges: date_ranges,
      country_count: "",
      state_count: "",
      city_count: "",
      loading: true,
      fields: [
        { key: "id", label: "#", sortable: false },
        { key: "orderids", label: "Order ID", sortable: true },
        { key: "restuarant_name", label: "Vendor", sortable: true },
        { key: "user_name", label: "Customer", sortable: true },
        { key: "drivername", label: "Delivery Boy", sortable: true },
        { key: "fees", label: "Delivery Charge", sortable: true },
        { key: "distance", label: "Distance in KM", sortable: true },
        { key: "total", label: "COD", sortable: true },
        { key: "totals", label: "Online", sortable: true },
        { key: "delivery_date", label: "Deliverd Date", sortable: true },
      ],
    };
  },
  mounted() {
    this.$data.fields[2].label = this.$auth.setting.restaurant_name;
    this.$data.fields[4].label = this.$auth.setting.driver_name;
    this.getOrderReport();
    this.getVendorCountry();
    this.exportTocsv();
  },
  methods: {
    downloadsCSV: function () {
      let orderExportReport =
        '\ufeff' +
        'Order Id,Vendor,Customer,Delivery Boy,Delivery Charge,Distance in KM,COD,Online,Delivered Date\n'
      this.orderExportReport.forEach((el) => {
        var line =
          el["orderids"] +
          "," +
          el["restuarant_name"]  +
          "," +
          el["user_name"] +
          "," +
          el["drivername"] +
          "," + 
          el["fees"].toFixed(2) + 
          "," +
          el["distance"] +
          "," +
          (el["total"] ? el["total"] : "-") +
          "," + 
          (el["totals"] ? el["totals"] : "-") + 
          "," +
          el["delivery_date"].replaceAll( ",",
            " ") +
          "\n"
        orderExportReport += line;
      });

      var blob = new Blob([orderExportReport], { type: "csv/plain" });
      var date = new Date().toLocaleString();
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "cod_vs_online_order_report_" + date + ".csv";
      link.click();
    },
    updateValues(date) {
      this.filters = {
        ...this.filters,
        start_date: this.moment(date.startDate.toISOString()).format(
          "YYYY-MM-DD"
        ),
        end_date: this.moment(date.endDate.toISOString()).format("YYYY-MM-DD"),
      };
      this.exportTocsv();
      this.getOrderReport();
    },
    dateFormat(classes, date) {
      this.filters = {
        ...this.filters,
        start_date: this.moment(date.startDate.toISOString()).format(
          "YYYY-MM-DD"
        ),
        end_date: this.moment(date.endDate.toISOString()).format("YYYY-MM-DD"),
      };
    },
    getOrderReport(page) {
      this.loading = true;
      reportService
        .getCODOnlineOrderReport({
          ...this.filters,
          search: this.search,
          sortBy: this.sortBy,
          orderBy: this.sortDesc ? "desc" : "asc",
          country: this.country,
          state: this.state,
          city: this.city,
          page: page ? page : 1,
        })
        .then((response) => {
          this.loading = false;
          // this.paginations = response.data.result;
          this.orderReport = response.data.result;
        });
    },
    exportTocsv()
    {
        reportService.exportOnlineReport({
              ...this.filters,
              search: this.search
        }).
        then((response) => {
          this.orderExportReport = response.data.result;
        });
    },
     getVendorCountry() {
      deliveryZoneService.getVendorCountry().then((response) => {
        this.countries = response.data.result;
        this.getVendorState();
      });
    },
    getVendorState() {
      this.city = "0";
      this.state = "0";
      deliveryZoneService
        .getVendorState({
          country_id: this.country,
        })
        .then((response) => {
          this.states = response.data.result;
          this.getVendorCity();
        });
    },
    getVendorCity() {
      this.city = '0';
      deliveryZoneService
        .getVendorCity({
          state_id:this.state ? this.state : '0',
          country_id: this.country
        })
        .then((response) => {
          this.cities = response.data.result;
          this.searchData();
        });
    },
    searchData() {
      this.getOrderReport();
      this.exportTocsv();
    },
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      this.getOrderReport();
    },
  },
  filters: {
    date(val) {
      return val ? val.toLocaleString() : "";
    },
  },
};
</script>

<template>
  <Layout>
    <div>
      <div class="row">
          <div class="col-12">
              <div
              class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
              >
              <div class="page-title-left avatar-main-icon">
                  <div class="avatar-xs mr-3">
                  <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                      <i class="bx bx-purchase-tag"></i>
                  </span>
                  </div>
                  <div class="report-tag">
                    <h4 class="mb-0 font-size-18">{{ trans.get('__JSON__.COD VS Online Orders Report') }}</h4>
                    <p><router-link :to="{ name: 'reports-list'}">{{ trans.get('__JSON__.Reports') }}</router-link> > {{ trans.get('__JSON__.COD VS Online Orders Report') }}</p>
                  </div>
              </div>
              <div class="page-title-right">
                <button @click="downloadsCSV" class="btn btn-primary btn-rounded">{{ trans.get('__JSON__.Export To CSV') }}</button>
              </div>
              </div>
          </div>
      </div>

      <!-- end row -->
      <div class="row">
        <div class="col-12 list-table-main">
          <div class="card">
            <div class="card-body">
              <div class="row justify-content-between">
                <div v-if="countries.length >1" class="cod-online-order">
                  <div class="form-group">
                    <div class="date-range-list">
                      <label>{{ trans.get('__JSON__.Country') }} :</label>
                      <select class="custom-select" v-model="country" @change="getVendorState">
                        <option value="0" selected>All</option>
                        <option
                          v-for="con in countries"
                          :value="con.id"
                          :key="con.id"
                        >{{con.country_name}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div v-if="countries.length >=1 && states.length >1" class="cod-online-order">
                  <div class="form-group">
                    <div class="date-range-list">
                      <label>{{ trans.get('__JSON__.State') }} :</label>
                      <select class="custom-select" v-model="state" @change="getVendorCity">
                        <option value="0" selected>All</option>
                        <option
                          v-for="sat in states"
                          :value="sat.id"
                          :key="sat.id"
                        >{{sat.state_name}}</option>
                      </select>
                    </div>
                  </div>
                </div>
               <div v-if="states.length >=1 && cities.length >1" class="cod-online-order">
                  <div class="form-group">
                    <div class="date-range-list">
                      <label>{{ trans.get('__JSON__.City') }} :</label>
                      <select class="custom-select" v-model="city" @change="searchData">
                        <option value="0" selected>All</option>
                        <option
                          v-for="cit in cities"
                          :value="cit.cityid"
                          :key="cit.cityid"
                        >{{cit.cityname}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="cod-online-order">
                  <div class="form-group">
                    <div class="date-range-list">
                      <label>{{trans.get('__JSON__.Date')}}:</label>
                      <date-range-picker
                        ref="picker"
                        :locale-data="{
                          firstDay: 1,
                          format: 'dd-mm-yyyy',
                        }"
                        :showDropdowns="true"
                        :showWeekNumbers="true"
                        opens="left"
                        v-model="dateRange"
                        :ranges="date_ranges()"
                        @update="updateValues"
                      >
                        <template
                          v-slot:input="picker"
                          style="min-width: 350px"
                        >
                          {{ moment(picker.startDate.toISOString()).format('MMMM DD, YYYY') | date }} -
                          {{ moment(picker.endDate.toISOString()).format('MMMM DD, YYYY') | date }}
                        </template>
                      </date-range-picker>
                    </div>
                  </div>
                </div>
                <div class="cod-online-order">
                  <div class="search-box mr-2 mb-0 d-inline-block form-group">
                    <div class="date-range-list">
                      <label>{{trans.get('__JSON__.Search')}} :</label>
                      <div class="position-relative">
                        <input
                          type="text"
                          class="form-control"
                          @keyup="searchData()"
                          v-model="search"
                          :placeholder="trans.get('__JSON__.Search By Order Id')"
                        />
                        <i class="bx bx-search-alt search-icon"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 table-main-list cod-online-report-table">
                  <b-table
                    :items="orderReport.data"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :no-local-sorting="true"
                    @sort-changed="sortingChanged"
                    :busy="loading"
                    show-empty
                  >
                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>{{trans.get('__JSON__.Loading...')}}</strong>
                      </div>
                    </template>

                    <template v-slot:cell(id)="data">
                      <div>{{ (orderReport.per_page * (orderReport.current_page-1)) + data.index + 1 }}</div>
                    </template>

                    <template v-slot:cell(drivername)="data">
                      <div v-if="data.item.drivername">
                        {{ data.item.drivername }}
                      </div>
                      <div v-else>
                        -
                      </div>
                    </template>

                    <template v-slot:cell(fees)="data">
                      <span v-html="currency"></span>
                      {{data.item.fees}}
                    </template>
                  

                     <template v-slot:cell(total)="data">
                      <div v-if="data.item.payment == 'Cash On Delivery'">
                            <span v-html="currency"></span> {{data.item.total.toFixed(2)}}
                      </div>
                      <div v-else>
                        -
                      </div>
                    </template>

                     <template v-slot:cell(totals)="data">
                      <div v-if="data.item.payment != 'Cash On Delivery'">
                            <span v-html="currency"></span> {{data.item.total.toFixed(2)}}
                      </div>
                      <div v-else>
                        -
                      </div>
                    </template>

                    <template #empty>
                      <p class="text-center">{{ trans.get('__JSON__.No Sales Report Found') }}</p>
                    </template>
                  </b-table>
                </div>
                <div class="row col-sm-5 offset-sm-7">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <pagination
                          :data="orderReport"
                          :limit="limit"
                          @pagination-change-page="getOrderReport"
                        ></pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<style>
.cod-online-report-table .table thead th:nth-child(1){
    pointer-events: none;
}
</style>