<script>
import { deliveryZoneService, userService} from '../../services';
import { set_axios_defaults } from '../../Helper/helper';
import { error_message, success_message, get_user } from "../../Helper/helper";
import config from "../../config";

import {
  required,
  email,
  numeric,
} from "vuelidate/lib/validators";

export default {
  components: { },
  data() {
    return {
      config:config,
      countries: [],
      states: [],
      cities: [],
      // state:'',
      // city:'',
      // country:'',
      country_count:'',
      state_count:'',
      city_count:'',
      typeform: {
        company_name: "",
        contact_name: "",
        country_name: "",
        state_name: "",
        city_name: "",
      },
      typesubmit: false,
      dataInfo:[],
      key:{}
    };
  },
  validations: {
    typeform: {
      company_name: {
        required
      },
      contact_name: {
        required
      },
      country_name: {
        required
      },
      city_name: {
        required
      },
      state_name: {
        required
      }
    }
  },
  mounted(){
    this.getVendorCountry();
    // this.getAllState();
    // this.getAllCity();
    this.vendor_id = get_user().vendor_id || 40818;
  },
  methods: {
    userCheckEmail(e) {
      userService.userCheckEmail({
        email:this.typeform.email,
        table:'User',
        column:'user_id',
        vendor_id:this.vendor_id,
        user_role:'3'
      })
      .then(response => {
        if (response.data.code === 101) {
          error_message(response.data.message);
        }
      });
    },
     getVendorCountry() {
      this.typeform.state_name = '';
      this.typeform.city_name = '';
      this.states = [];
      this.cities = [];
      deliveryZoneService.getVendorCountry().then((response) => {
        this.country_count = response.data.result.length;
        this.countries = response.data.result;
        if (this.country_count == 1) {
          this.typeform.country_name = this.countries[0].id;
          this.getVendorState();
        }
      });
    },
    getVendorState() {
      this.states = [];
      this.cities = [];
      this.city_count = 0;
      this.state_count = 0;
      this.typeform.city_name = '';
      if(!this.typeform.country_name) return false;
      deliveryZoneService.getVendorState({country_id: this.typeform.country_name}).then((response) => {
        this.state_count = response.data.result.length;
        this.states = response.data.result;
        if (this.state_count == 1) {
          this.typeform.state_name = this.states[0].id;
          this.getVendorCity();
        } else {
          // if (this.typeform.country == "") {
          //   this.getAllState();
          //   this.getAllCity();
          //   this.typeform.state = "";
          //   this.typeform.city = "";
          // }
          // if(this.typeform.state ==''){
          //   this.getVendorCity();
          //   this.typeform.state = "";
          //   this.typeform.city = "";
          // }
        }
      });
    },
    getVendorCity() {
      this.cities = [];
      this.city_count = 0;
      if(!this.typeform.state_name) return false;
      deliveryZoneService.getVendorCity({state_id: this.typeform.state_name}).then((response) => {
        this.city_count = response.data.result.length;
        this.cities = response.data.result;
        if (this.city_count == 1) {
          this.typeform.city_name = this.cities[0].cityid;
        } else{
          // if(this.typeform.state ==''){
          //   this.getAllCity();
          //   this.typeform.city = '';
          // }
        }
      });
    },
    getAllState() {
      deliveryZoneService.getAllState()
        .then((response) => {
        this.states = response.data.result;
        this.state_count = response.data.result.length;
      });
    },
    getAllCity() {
      deliveryZoneService.getAllCity().then((response) => {
        this.cities = response.data.result;
        this.city_count = response.data.result.length;
      });
    },
    typeForm(e) {
      this.$v.$touch();
      if (this.$v.typeform.$invalid) {
        return;
      }
      else{
        deliveryZoneService.addDeliveryZone(this.typeform)
        .then(response => {
          if (response.data.code === 200) {
            success_message(response.data.message);
            this.$router.push({ name: "list" });
          } else {
            error_message(response.data.message);
          }
        });
      }
    },
  }
}
</script>

<template>
  <b-form action="#" @submit.prevent="typeForm">
    <!-- Delivery Zone Info Start -->
      <div class="card">
        <div class="card-body">
          <!-- <h4 class="card-title">Delivery Zone Info</h4> -->
          <p class="card-title-desc"></p>
          <div class="row">

              <div class="col-sm-6">
                  <div class="form-group">
                  <label class="control-label required">{{ trans.get('__JSON__.Delivery Zone Name') }}</label>
                  <input id="company_name" v-model="$v.typeform.company_name.$model" type="text" class="form-control" :placeholder="trans.get('__JSON__.Enter Delivery Zone Name')" name="company_name" :class="{ 'is-invalid':$v.typeform.company_name.$error }" />
                    <div v-if="$v.typeform.company_name.$error" class="invalid-feedback">
                      <span v-if="!$v.typeform.company_name.required">{{ trans.get('__JSON__.Please enter delivery zone name') }}</span>
                    </div>
                </div>    
              </div>

              <div class="col-sm-6">
                  <div class="form-group">
                  <label class="control-label required">{{ trans.get('__JSON__.Admin Name') }}</label>
                  <input id="contact_name" v-model="$v.typeform.contact_name.$model" type="text" class="form-control" :placeholder="trans.get('__JSON__.Enter Admin Name')" name="contact_name" :class="{ 'is-invalid':$v.typeform.contact_name.$error }" />
                    <div v-if="$v.typeform.contact_name.$error" class="invalid-feedback">
                      <span v-if="!$v.typeform.contact_name.required">{{ trans.get('__JSON__.Please enter admin name') }}</span>
                    </div>
                </div>    
              </div>

              <div class="col-sm-6 d-none">
                  <div class="form-group">
                  <label class="required">{{ trans.get('__JSON__.Contact Number') }}</label>
                    <input maxlength="10" minlength="8" id="contact_no" type="tel" name="contact_no" class="form-control" :placeholder="trans.get('__JSON__.Enter Contact Number')">
                </div>    
              </div>

              <div class="col-sm-6 d-none">
                <div class="form-group">
                  <label class="required">{{ trans.get('__JSON__.E-Mail') }}</label>
                      <input id="email" type="email" name="email" class="form-control" :placeholder="trans.get('__JSON__.Enter a valid e-mail')"/>
                  </div>
              </div>
              
              <div v-if="country_count >1" class="col-sm-6">
                <div class="form-group">
                  <label class="required">{{ trans.get('__JSON__.Country') }}</label>
                    <select class="custom-select" v-model="$v.typeform.country_name.$model" @change="getVendorState" id="country_name" name="country_name" :class="{ 'is-invalid':$v.typeform.country_name.$error }"> 
                      <option value="">{{trans.get('__JSON__.Select Country')}}</option>
                      <option v-for="con in countries" :value="con.id" :key="con.id">{{con.country_name}}</option>
                    </select>
                    <div v-if="$v.typeform.country_name.$error" class="invalid-feedback">
                      <span v-if="!$v.typeform.country_name.required">{{ trans.get('__JSON__.Please select country') }}</span>
                    </div>
                  </div>
              </div>
              

              <div v-if="(country_count == 1 && state_count > 1) || country_count > 1" class="col-sm-6">
                <div class="form-group">
                  <label class="required">{{ trans.get('__JSON__.State') }}</label>
                    <select class="custom-select" v-model="$v.typeform.state_name.$model" @change="getVendorCity" id="state_name" name="state_name" :class="{ 'is-invalid':$v.typeform.state_name.$error }">
                        <option value="">{{trans.get('__JSON__.Select State')}}</option>
                        <option v-for="sat in states" :value="sat.id" :key="sat.id">{{sat.state_name}}</option>
                    </select>
                    <div v-if="$v.typeform.state_name.$error" class="invalid-feedback">
                      <span v-if="!$v.typeform.state_name.required">{{ trans.get('__JSON__.Please select state') }}</span>
                    </div>
                  </div>
              </div>
              

              <div v-if="(country_count == 1 && state_count == 1 && city_count > 1) || country_count > 1 || state_count > 1" class="col-sm-6">
                <div class="form-group">
                  <label class="required">{{ trans.get('__JSON__.City') }}</label>
                      <select class="custom-select" v-model="$v.typeform.city_name.$model" id="city_name" name="city_name" :class="{ 'is-invalid':$v.typeform.city_name.$error }">
                        <option value="">{{trans.get('__JSON__.Select City')}}</option>
                        <option v-for="cit in cities" :value="cit.cityid" :key="cit.cityid">{{cit.cityname}}</option>
                      </select>
                      <div v-if="$v.typeform.city_name.$error" class="invalid-feedback">
                        <span v-if="!$v.typeform.city_name.required">{{ trans.get('__JSON__.Please select city') }}</span>
                      </div>
                  </div>
              </div>   
          </div> 

          <div class="row">
              <div class="col-sm-6">
                <button type="submit" class="btn btn-primary mr-1 mt-3">{{ trans.get('__JSON__.Add Delivery Zone') }}</button>
                <b-button @click="$router.back()" type="button" variant="secondary" class="mt-3" >{{ trans.get('__JSON__.Cancel') }}</b-button>
              </div>
          </div>
          
        </div>
      </div>
    <!-- Delivery Zone Info End -->
  
  </b-form>
</template>
