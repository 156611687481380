<script>
import Layout from "../../layouts/main";

import SystemList from "./system-list";

export default {
  components: {
    Layout,
    SystemList,
  },
  data() {
    return {};
  },
};
</script>
<template>
  <Layout>
    <system-list />
  </Layout>
</template>
