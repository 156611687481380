<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import moment from "moment";
import pagination from "laravel-vue-pagination";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

import {
  get_partner_login,
  set_partner,
  set_partner_details,
  set_user,
  set_user_detail,
  debounce,
  get_currency,
  date_ranges
} from "../../Helper/helper";
import { reportService } from "../../services";
/**
 * Report component
 */
export default {
  components: { Layout, PageHeader, pagination, moment, DateRangePicker },
  data() {
    let startDate = this.moment().startOf("day").subtract(29, "days");
    let endDate = this.moment().endOf("day").toDate();
    return {
      drivers: {},
      driversData: [],
      moment: moment,
      limit: 2,
      paginations: {},
      currency: get_currency(),
      sortBy: "created_date",
      sortDesc: true,
      search: "",
      loading: true,
      rating: '',
      date_ranges: date_ranges,
      dateRange: { startDate, endDate },
      filters: {
        start_date: this.moment().subtract(29,'days').format('YYYY-MM-DD'),
        end_date: this.moment().format('YYYY-MM-DD'),
      },
      isDownloading: false,
      fields: [
        { key: "order_id", label: "Order ID", sortable: false },
        { key: "created_date", label: "Date", sortable: false },
        { key: "driver_name", label: this.trans.get("__JSON__.Driver Name",{driver: this.$auth.getDriverName()}), sortable: false },
        { key: "driver_number", label: this.trans.get("__JSON__.Driver Phone",{driver: this.$auth.getDriverName()}), sortable: false },
        { key: "user_name", label: "Customer Name", sortable: false },
        { key: "mobile_number", label: "Customer Phone", sortable: false },
        { key: "rating", label: "Rating", sortable: false },
        { key: "description", label: "Review", sortable: false }
      ],
    };
  },
  mounted() {
    this.getDriverReports();
    // this.customerExportCsv();
  },
  methods: {
    downloadsCSV: function () {
      let driversData =
        "\ufeff" +
        "Order ID,Date,"+this.$auth.getDriverName()+" Name,"+this.$auth.getDriverName()+" Phone,Customer Name,Customer Phone,Rating,Review\n";
      this.driversData.forEach((el) => {
        var line = el["order_id"] + "," + (el["created_date"] ? el["created_date"] : "").replaceAll(",", " ") + "," + el["driver_name"] + "," + el["driver_number"] + "," + el["user_name"] + "," + el["mobile_number"] + "," + el["rating"].toFixed(2) + "," + (el["description"] ? el["description"] : "-").replaceAll(",", " ") +"\n";
        driversData += line;
      });

      var blob = new Blob([driversData], { type: "csv/plain" });
      var date = new Date().toLocaleString();
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "driver_rating_review_" + date + ".csv";
      link.click();
    },
     updateValues(date) {
      this.filters = {
        ...this.filters,
        start_date: this.moment(date.startDate.toISOString()).format(
          "YYYY-MM-DD"
        ),
        end_date: this.moment(date.endDate.toISOString()).format("YYYY-MM-DD"),
      };
      this.getDriverReports();
      // this.exportToCsv();
    },
    dateFormat(classes, date) {
      this.filters = {
        ...this.filters,
        start_date: this.moment(date.startDate.toISOString()).format(
          "YYYY-MM-DD"
        ),
        end_date: this.moment(date.endDate.toISOString()).format("YYYY-MM-DD"),
      };
    },
    getDriverReports(page) {
      this.loading = true;
      //  if (this.sortBy == "user_name") {
      //   this.sortDesc = false;
      // } else {
      //   this.sortDesc = true;
      // };
      reportService
        .getDriverRatingReview({
          ...this.filters,
          rating: this.rating,
          sortBy: this.sortBy,
          orderBy: this.sortDesc ? "desc" : "asc",
          page: page ? page : 1,
        })
        .then((response) => {
          this.loading = false;
          // this.paginations = response.data.result;
          this.drivers = response.data.result;
        });
    },
    driverExportCsv() {
      this.isDownloading = true;
      reportService
        .exportDriverRatingReview({
          ...this.filters,
          rating: this.rating,
          sortBy: this.sortBy, 
          orderBy: this.sortDesc ? "desc" : "asc"
        })
        .then((response) => {
          this.isDownloading = false;
          this.driversData = response.data.result;
          this.downloadsCSV();
        });
    },
    searchData() {
      this.getDriverReports();
      // this.customerExportCsv();
    },
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      this.getDriverReports();
      // this.driverExportCsv();
    },
  },
  filters: {
    date(val) {
      return val ? val.toLocaleString() : "";
    },
  },
};
</script>

<template>
  <Layout>
    <div>
      <div class="row">
          <div class="col-12">
              <div
              class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
              >
              <div class="page-title-left avatar-main-icon">
                  <div class="avatar-xs mr-3">
                  <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                      <i class="bx bx-purchase-tag"></i>
                  </span>
                  </div>
                  <div class="report-tag">
                    <h4 class="mb-0 font-size-18">{{ trans.get('__JSON__.Driver Rating & Review Report') }}</h4>
                    <p><router-link :to="{ name: 'reports-list'}">{{ trans.get('__JSON__.Reports') }}</router-link> > {{ trans.get('__JSON__.Driver Rating & Review Report') }}</p>
                  </div>
              </div>
              <div class="page-title-right">
                 <button @click="driverExportCsv" class="btn btn-primary btn-rounded export-btn" v-if="isDownloading" disabled>
                {{ trans.get('__JSON__.Exporting...') }}</button>
                 <button @click="driverExportCsv" class="btn btn-primary btn-rounded export-btn" v-else>
                {{ trans.get('__JSON__.Export To CSV') }}</button>
                <button @click="driverExportCsv" class="btn btn-primary btn-rounded m-export-btn"><i class="bx bx-archive-in"></i></button>
              </div>
              </div>
          </div>
      </div>

      <!-- end row -->
      <div class="row">
        <div class="col-12 list-table-main">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="main-sales-report m-driver-report">
                <div class="v-sales-report m-sales-driver-report">
                  <div class="form-group">
                    <div class="date-range-list">
                      <label>{{ trans.get('__JSON__.Rating') }} :</label>
                      <select class="custom-select" v-model="rating" @change="searchData">
                        <option value >{{ trans.get('__JSON__.All') }}</option>
                        <option value="1">{{ trans.get('__JSON__.1 Star') }}</option>
                        <option value="2">{{ trans.get('__JSON__.2 Star') }}</option>
                        <option value="3">{{ trans.get('__JSON__.3 Star') }}</option>
                        <option value="4">{{ trans.get('__JSON__.4 Star') }}</option>
                        <option value="5">{{ trans.get('__JSON__.5 Star') }}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="v-driver-report-date">
                  <div class="form-group">
                    <div class="date-range-list">
                      <label>{{trans.get('__JSON__.Date')}}:</label>
                      <div class="position-relative d-inline-block w-100">
                        <date-range-picker
                          ref="picker"
                          :locale-data="{
                            firstDay: 1,
                            format: 'dd-mm-yyyy',
                          }"
                          :showDropdowns="true"
                          :showWeekNumbers="true"
                          opens="left"
                          v-model="dateRange"
                          :ranges="date_ranges()"
                          @update="updateValues"
                        >
                          <template
                            v-slot:input="picker"
                            style="min-width: 350px"
                          >
                            {{ moment(picker.startDate.toISOString()).format('MMMM DD, YYYY') | date }} -
                            {{ moment(picker.endDate.toISOString()).format('MMMM DD, YYYY') | date }}
                          </template>
                        </date-range-picker>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
             </div>
              <div class="row">
                <div class="col-12 table-main-list vendor-rating-review-report-table table-responsive">
                  <b-table
                    :items="drivers.data"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :no-local-sorting="true"
                    @sort-changed="sortingChanged"
                    :busy="loading"
                    show-empty
                  >
                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>{{trans.get('__JSON__.Loading...')}}</strong>
                      </div>
                    </template>

                    <template v-slot:cell(created_at)="data">
                        <div
                          v-if="data.item.created_at"
                        >{{data.item.created_date}}</div>
                    </template>

                    <template v-slot:cell(rating)="data">
                      <div v-if="data.item.rating <= 2">
                        <span class="badge badge-danger font-size-12">
                          <i class="mdi mdi-star mr-1"></i>
                          {{parseFloat(data.item.rating).toFixed(2)}}
                        </span>
                      </div>
                      <div v-else>
                        <span class="badge badge-success font-size-12">
                          <i class="mdi mdi-star mr-1"></i>
                          {{parseFloat(data.item.rating).toFixed(2)}}
                        </span>
                      </div>
                    </template>

                    <template v-slot:cell(description)="data">
                      <div v-if="data.item.description">
                        <div
                          v-if="data.item.description.length > 140"
                        >{{data.item.description.substr(0, 140)+ '...'}}</div>
                        <div v-else>{{data.item.description}}</div>
                      </div>
                      <div v-else class="mt-3">
                        <p>-</p>
                      </div>
                    </template>

                    <template #empty>
                      <p class="text-center">{{ trans.get('__JSON__.No Customer Report Found') }}</p>
                    </template>
                  </b-table>
                </div>
                <div class="row col-sm-5 offset-sm-7">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <pagination
                          :data="drivers"
                          :limit="limit"
                          @pagination-change-page="getDriverReports"
                        ></pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>


<style>
.vendor-rating-review-report-table .table thead th:nth-child(1),.vendor-rating-review-report-table .table thead th:nth-child(5){
    pointer-events: none;
}
.vendor-rating-review-report-table .table tbody tr td:nth-child(2){
    width: 10% !important;
}
</style>