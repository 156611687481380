<script>
import pagination from "laravel-vue-pagination";

import config from "../../../config";

import { success_message, error_message } from "../../../Helper/helper";

import {
  required,
  email,
  minLength,
  sameAs,
  maxLength,
  minValue,
  maxValue,
  numeric,
  url,
  alphaNum,
} from "vuelidate/lib/validators";
import { settingService } from "../../../services";
/**
 * Starter component
 */
export default {
  components: { pagination },
  data() {
    return {
      limit: 1,
      config: config,
      customertext: [],
      drivertext: [],
      vendortext: [],
      paginations: {},
      customerlist: [{
        message: "",
        message_another_lang: "",
      }],
      vendorlist: [{
        message: "",
        message_another_lang: "",
      }],
      driverlist: [{
        message: "",
        message_another_lang: "",
      }],
    };
  },
  mounted() {
    this.getOrderTextList();
  },
  methods: {
    getOrderTextList(page) {
      settingService
        .getNotificationText({
          page: page ? page : 1,
        })
        .then((response) => {
          this.paginations = response.data.result;
          this.customertext = response.data.result.customer;
          this.drivertext = response.data.result.driver;
          this.vendortext = response.data.result.vendor;
        });
    },
    updateOrder() {
        const fd = new FormData();
        fd.append('customerlist',JSON.stringify(this.customertext));
        fd.append('driverlist',JSON.stringify(this.drivertext));
        fd.append('vendorlist',JSON.stringify(this.vendortext));
        settingService.updateNotificationtext(fd).then((response) => {
          if (response.data.code === 200) {
            success_message(response.data.message);
            this.getOrderTextList();
          } else {
            error_message(response.data.message);
          }
        });
    },
  }
};
</script>
<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body settings-main">
         <b-form @submit.prevent="updateOrder">
            <div class="row sections-main">
              <div class="col-md-12 mt-4" v-if="customertext">
                <div class="row">
                  <div class="col-md-4 custom-text-noti" v-if="$auth.setting">
                    <h6>{{ trans.get('__JSON__.Customer Notification') }}</h6>
                  </div>
                  <div class="col-md-4 custom-text-noti">
                    <h6>{{ trans.trans('__JSON__.Primary Message', { lang: $auth.langauges[0].name }) }}</h6>
                  </div>
                  <div class="col-md-4 custom-text-noti d-none" v-if="$auth.setting && $auth.langauges[1].name == 'No Secondary Language'">
                  </div>
                  <div class="col-md-4 custom-text-noti" v-else>
                    <h6>{{ trans.trans('__JSON__.Secondary Message', { lang: $auth.langauges[1].name }) }}</h6>
                  </div>
                </div>
              </div>
            </div>
            <div class="row sections-main" v-for="(customerlist,index) in customertext" :key="index">
              <div class="col-md-12">
                <div class="row align-items-center api-text-list">
                  <input type="hidden" name="id" v-model="customerlist.id" />
                  <div class="col-md-4">
                    <h6>{{customerlist.type}}</h6>
                  </div>
                  <div class="col-md-4">
                    <b-form-group>
                      <b-form-input
                        v-model="customerlist.message"
                        name="message[]" 
                        type="text"
                      ></b-form-input>
                
                    </b-form-group>
                  </div>
                  <div class="col-md-4 d-none" v-if="$auth.setting && $auth.langauges[1].name == 'No Secondary Language'">
                  </div>
                  <div class="col-md-4" v-else>
                    <b-form-group>
                      <b-form-input
                        v-model="customerlist.message_another_lang"
                        name="message_another_lang[]"
                        type="text"
                      ></b-form-input>
                    
                    </b-form-group>
                  </div>
                </div>
              </div>
            </div>
            <div class="row sections-main">
              <div class="col-md-12 mt-4" v-if="vendortext">
                <div class="row">
                  <div class="col-md-4 custom-text-noti" v-if="$auth.setting">
                    <h6>{{ trans.trans('__JSON__.Vendors Notification',{ vendor: $auth.setting.restaurant_name }) }}</h6>
                  </div>
                  <div class="col-md-4 custom-text-noti">
                    <h6>{{ trans.trans('__JSON__.Primary Message', { lang: $auth.langauges[0].name }) }}</h6>
                  </div>
                  <div class="col-md-4 custom-text-noti d-none" v-if="$auth.setting && $auth.langauges[1].name == 'No Secondary Language'">
                  </div>
                  <div class="col-md-4 custom-text-noti" v-else>
                    <h6>{{ trans.trans('__JSON__.Secondary Message', { lang: $auth.langauges[1].name }) }}</h6>
                  </div>
                </div>
              </div>
            </div>
            <div class="row sections-main" v-for="(vendorlist,index) in vendortext" :key="index">
              <div class="col-md-12">
                <div class="row align-items-center api-text-list">
                  <input type="hidden" name="id" v-model="vendorlist.id" />
                  <div class="col-md-4">
                    <h6>{{vendorlist.type}}</h6>
                  </div>
                  <div class="col-md-4">
                    <b-form-group>
                      <b-form-input
                        v-model="vendorlist.message"
                        name="message[]" 
                        type="text"
                      ></b-form-input>
                
                    </b-form-group>
                  </div>
                  <div class="col-md-4 d-none" v-if="$auth.setting && $auth.langauges[1].name == 'No Secondary Language'">
                  </div>
                  <div class="col-md-4" v-else>
                    <b-form-group>
                      <b-form-input
                        v-model="vendorlist.message_another_lang"
                        name="message_another_lang[]"
                        type="text"
                      ></b-form-input>
                    
                    </b-form-group>
                  </div>
                </div>
              </div>
            </div>
            <div class="row sections-main">
              <div class="col-md-12 mt-4" v-if="drivertext">
                <div class="row">
                  <div class="col-md-4 custom-text-noti" v-if="$auth.setting">
                    <h6>{{ trans.trans('__JSON__.Driver Notification',{ driver: $auth.setting.driver_name }) }}</h6>
                  </div>
                  <div class="col-md-4 custom-text-noti">
                    <h6>{{ trans.trans('__JSON__.Primary Message', { lang: $auth.langauges[0].name }) }}</h6>
                  </div>
                  <div class="col-md-4 custom-text-noti d-none" v-if="$auth.setting && $auth.langauges[1].name == 'No Secondary Language'">
                  </div>
                  <div class="col-md-4 custom-text-noti" v-else>
                    <h6>{{ trans.trans('__JSON__.Secondary Message', { lang: $auth.langauges[1].name }) }}</h6>
                  </div>
                </div>
              </div>
            </div>
            <div class="row sections-main" v-for="(driverlist,index) in drivertext" :key="index">
              <div class="col-md-12">
                <div class="row align-items-center api-text-list">
                  <input type="hidden" name="id" v-model="driverlist.id" />
                  <div class="col-md-4">
                    <h6>{{driverlist.type}}</h6>
                  </div>
                  <div class="col-md-4">
                    <b-form-group>
                      <b-form-input
                        v-model="driverlist.message"
                        name="message[]" 
                        type="text"
                      ></b-form-input>
                
                    </b-form-group>
                  </div>
                  <div class="col-md-4 d-none" v-if="$auth.setting && $auth.langauges[1].name == 'No Secondary Language'">
                  </div>
                  <div class="col-md-4" v-else>
                    <b-form-group>
                      <b-form-input
                        v-model="driverlist.message_another_lang"
                        name="message_another_lang[]"
                        type="text"
                      ></b-form-input>
                    
                    </b-form-group>
                  </div>
                </div>
              </div>
            </div>
             <div class="btn-update">
                <div class="col-sm-6">
                  <b-button
                    v-if="$auth.hasPermission(config.permissions.terminology_write)"
                    variant="primary"
                    @click="updateOrder"
                    class="float-left mr-1 mt-3"
                  >{{ trans.get('__JSON__.Update') }}</b-button>
                </div>
             </div>
          </b-form>
          <div class="row col-sm-5 offset-sm-7 mt-3">
          <div class="col">
            <div class="dataTables_paginate paging_simple_numbers float-right">
              <ul class="pagination pagination-rounded mb-0">
                <!-- pagination -->
                <pagination
                  :data="paginations"
                  :limit="limit"
                  @pagination-change-page="getOrderTextList"
                ></pagination>
              </ul>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>