const baseUrl = window.location.protocol+"//"+window.location.hostname+"/"+window.folderName;
console.log(window.location.protocol, window.location.hostname, window.folderName);
const image_baseUrl =  process.env.MIX_STORAGE_PATH;

export default {

  socket_url: process.env.MIX_SOCKET_URL,

  orderWebDefaultUrl: '.'+process.env.MIX_SUB_URL,
  appname: window.appname,
  merchantname: window.merchantname,
  applogo: image_baseUrl + 'media/foodapp/original/'+window.applogo,
  appurl: baseUrl,
  api_url: baseUrl + 'api/',
  media_path: baseUrl + 'public/',
  app_api_url: process.env.MIX_API_URL+'api/'+process.env.MIX_API_VERSION+'/',
  base_folder: "/"+window.folderName,//'/folder/', // put base folder path here
  google_key: window.google_map_key,

  //tripetto url
  tripetto_url : process.env.MIX_TRIPETTO_URL,

  basepath: baseUrl + 'public/images/',
  profile_img: baseUrl + 'public/images/profile-img.png',
  bannerpath: image_baseUrl + 'media/Banner/thumb/',

  restauranticon: image_baseUrl + 'media/Restaurant_Icon_image/original/',
  foodicon: image_baseUrl + 'media/foodapp/original/',
  brandicon: baseUrl + 'public/images/brands/',
  category: image_baseUrl + 'media/Category/original/',
  coupon: image_baseUrl + 'media/Coupon/',
  driver: image_baseUrl + 'media/Driver/Profile/original/',
  cuisineicon: image_baseUrl + 'media/Cuisine/original/',
  driver_document: image_baseUrl + 'media/Driver/Document/original/',
  restaurantmenu: image_baseUrl + 'media/Restaurant_Menu_image/original/',
  date_format: 'DD-MM-YYYY',
  no_image: baseUrl + 'public/images/no-img.png',
  discounticon: image_baseUrl + 'media/Discount/',
  restaurantmenuitemicon: image_baseUrl + 'media/Restaurant_Menu_Item_Image/',
  homepageimage: baseUrl + 'public/images/HomePageLayout/',
  categorypageimage: baseUrl + 'public/images/CategoryPageLayout/',
  vendorlistingimage: baseUrl + 'public/images/VendorListingLayout/',
  vendordetailimage: baseUrl + 'public/images/VendorDetailsLayout/',
  web_store_baseurl: baseUrl + 'public/media/weblayout/',
  web_home_page: image_baseUrl + 'media/',
  
  whatsnew: baseUrl + 'public/media/images/',

  audiofile: baseUrl + 'public/notification.mp3',
  permissions: {
    dashboard_read: "Dashboard Read",
    order_read: "Order Read",
    order_write: "Order Write",
    dashboard_driver_read: "Dashboard Driver Read",
    eagle_view_read: "Eagle view Read",
    merchant_read: "Merchant Read",
    merchant_write: "Merchant Write",
    driver_read: "Driver Read",
    driver_write: "Driver Write",
    customer_read: "Customer Read",
    customer_write: "Customer Write",
    location_read: "Location Read",
    location_write: "Location Write",
    delivery_zone_read: "Delivery Zone Read",
    delivery_zone_write: "Delivery Zone Write",
    promo_code_read: "Promo Code Read",
    promo_code_write: "Promo Code Write",
    banner_read: "Banner Read",
    banner_write: "Banner Write",
    vendor_finance_read: "Vendor Finance Read",
    vendor_finance_write: "Vendor Finance Write",
    driver_finance_read: "Driver Finance Read",
    driver_finance_write: "Driver Finance Write",
    reports_read: "Reports Read",
    reports_write: "Reports Write",
    category_read: "Category Read",
    category_write: "Category Write",
    cuisine_read: "Cuisine Read",
    cuisine_write: "Cuisine Write",
    content_pages_read: "Content Pages Read",
    content_pages_write: "Content Pages Write",
    faq_read: "FAQ Read",
    faq_write: "FAQ Write",
    configuration_read: "Configuration Read",
    configuration_write: "Configuration Write",
    layout_read: "Layout Read",
    layout_write: "Layout Write",
    user_access_read: "User & Access Read",
    user_access_write: "User & Access Write",
    terminology_read: "Terminology Read",
    terminology_write: "Terminology Write",
    logs_read: "Logs Read",
    logs_write: "Logs Write",
    call_center_read: "Call Center Read",
    call_center_write: "Call Center Write",
    web_layout_read: "Web Layout Read",
    web_layout_write: "Web Layout Write",
    analytics_read: "Analytics Read",
    analytics_write: "Analytics Write",
    master_depot_read: "Master Depot Read",
    master_depot_write: "Master Depot Write"
  },
  default_time_options: [
    { value: '0', text: 'Today' },
    { value: '1', text: 'Yesterday' },
    { value: '6', text: 'Last 7 Days' },
    { value: '29', text: 'Last 30 Days' },
    { value: '365', text: 'This year' },
    { value: '101', text: 'Last month' },
  ],
  default_notification_sounds: [
    { value: 'public/sounds/notification.mp3', text: 'Sound 1' },
    { value: 'public/sounds/notification-2.mp3', text: 'Sound 2' },
    { value: 'public/sounds/notification-3.mp3', text: 'Sound 3' },
  ]
}
