
// Country services rest api calling

import { routes } from '../config/api_routes'

export const locationService = {

    // get country 
    // info: sortDesc : Boolean
    
    getCountry: (formData) => window.axios.post(routes.getCountryListApi,formData),

    // get state

    getState: (formData) => window.axios.post(routes.getStateListApi,formData),

    // get city

    getCity: (formData) => window.axios.post(routes.getCityListApi,formData),

    // add country 

    addCountry: (formData) => window.axios.post(routes.addCountryApi,formData),

    // get countryByID 

    getCountryById: (formData) => window.axios.post(routes.getCountryByIdApi,formData),

    // update countryByID

    updateCountryById: (formData) => window.axios.post(routes.updateCountryApi,formData),

    // add State 

    addState: (formData) => window.axios.post(routes.addStateApi,formData),

    // get statebyID 

    getStateById: (formData) => window.axios.post(routes.getStateByIdApi,formData),

    // update stateByID

    updateStateById: (formData) => window.axios.post(routes.updateStateApi,formData),

    // add city 

    addCity: (formData) => window.axios.post(routes.addCityApi,formData),

    // get cityByID

    getCityById: (formData) => window.axios.post(routes.getCityByIdApi,formData),

    // update cityByID 

    updateCityById: (formData) => window.axios.post(routes.updateCityApi,formData),
}