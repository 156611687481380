<script>
import vue2Dropzone from "vue2-dropzone";
import Multiselect from "vue-multiselect";
import { settingService } from "../../../services";
import { required, numeric, requiredIf } from "vuelidate/lib/validators";
import { success_message } from "../../../Helper/helper";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import Swal from "sweetalert2";
import config from "../../../config";

/**
 * Starter component
 */
export default {
    components: { vueDropzone: vue2Dropzone, Multiselect, DatePicker },
    data() {
        return {
            config: config,
            checked: true,
            setting: [],
            errors: "",
            taxslab:[],
            taxslab: [{ id: '', tax_per: '',tax_name: '',tax_name_other: '',tax_uniquecode: '' }],
            setting: {
                included_tax: 'No',
                restaurant_service_tax: 0,
                taxslab:[]
            },
        };
    },
    validations: {
        setting: {
        },
    },
    mounted() {
        this.getSetting();
    },
    methods: {
        addTaxSlabField: function () {
            let tax_uniquecode = 'TS'+(this.taxslab.length + 1);
            this.taxslab.push({ id: '', tax_per: '',tax_name: '',tax_name_other: '',tax_uniquecode: tax_uniquecode});
        },
        removeTaxSlab(index){
            this.taxslab.splice(index,1);
        },
        getSetting() {
            settingService.getSetting().then((response) => {
                this.setting = response.data.result;
                this.taxslab = response.data.result.taxslab;
                if (this.taxslab !== undefined && this.taxslab.length == 0) {
                    this.addTaxSlabField();
                }
            });
        },
        generalIt() {
            this.$v.$touch();
            if (this.$v.setting.$invalid) {
                return;
            } else {
                const fd = new FormData();
                fd.append("included_tax", this.setting.included_tax);
                fd.append("restaurant_service_tax", this.setting.restaurant_service_tax);
                fd.append("taxslab",JSON.stringify(this.setting.taxslab));
                fd.append("type",7);
                settingService.updateSetting(fd).then((response) => {
                    if (response.data.code === 200) {
                        if (response.data.result) {
                            this.$auth.updateSetting(response.data.result.settings);
                            response.data.result.taxslab.forEach(el => {
                                let index = this.setting.taxslab.findIndex(d => d.tax_name == el.tax_name && d.tax_per == el.tax_per);
                                if (this.setting.taxslab[index]) {
                                    this.setting.taxslab[index].id = el.id;
                                }
                                if (this.taxslab[index]) {
                                    this.taxslab[index].id = el.id;
                                }
                            });
                        }
                        success_message(response.data.message);
                    } else {
                        error_message(response.data.message);
                    }
                });
            }
        },
        decNumberOnly(evt) {
            let val1 = evt.target.value;
            if (!(evt.keyCode == 46 || (evt.keyCode >= 48 && evt.keyCode <= 57))) {
                evt.preventDefault();
            }
            let partlen = val1.split(".").length - 1;
            if (evt.keyCode == 46 && partlen > 0) {
                evt.preventDefault();
            }
        }
    },
};
</script>
<template>
    <b-form @submit.prevent="generalIt">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body setting-main">
                        <div class="row section-main">
                            <div class="col-md-4">
                                <div class="setting-heading">
                                    <h4>Cart {{ $auth.getTaxName() }}</h4>
                                </div>
                            </div>
                            <div class="col-md-8">
                                <div class="setting-data">
                                    <div class="row">
                                        <div class="col-md-6 vendor-status">
                                            <div class="swtich-data">
                                                <b-form-group>
                                                    <label class="switch">
                                                        <input
                                                            type="checkbox"
                                                            id="included_tax"
                                                            name="included_tax"
                                                            v-model="setting.included_tax"
                                                            class="switch-on"
                                                            true-value="Yes"
                                                            false-value="No"
                                                        />
                                                        <div class="slider round">
                                                            <span class="on">{{ trans.get('__JSON__.On') }}</span>
                                                            <span class="off">{{ trans.get('__JSON__.Off') }}</span>
                                                        </div>
                                                    </label>
                                                </b-form-group>
                                            </div>
                                            <div class="col-md-12">
                                                <h6>
                                                    <!-- {{ trans.get('__JSON__.Tax on items in cart') }} -->
                                                    Additional {{ $auth.getTaxName() }} on Cart
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tax-main">
                                        <div class="row mt-4" v-if="setting.included_tax == 'Yes'">
                                            <div class="col-md-12">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <h6>Enter Tax Value (In Percentage)</h6>
                                                    </div>
                                                    <div class="col-md-6 order-value">
                                                        <input
                                                            id="restaurant_service_tax"
                                                            name="restaurant_service_tax"
                                                            type="number"
                                                            class="form-control"
                                                            v-model="setting.restaurant_service_tax"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row section-main">
                            <div class="col-md-4">
                                <div class="setting-heading">  
                                    <h4>Item {{ $auth.getTaxName() }} Slab</h4>
                                </div>
                            </div>
                            <div class="col-md-8">
                                <div class="setting-data">
                                    <div class="row mt-3">
                                        <div class="col-md-12" v-for="(v,index) in taxslab" :key="index">
                                            <div class="row mt-2">
                                                <div class="col-md-2">
                                                    <h6>{{ $auth.getTaxName() }} Code</h6>
                                                    <span style="font-weight: 500;font-size: 14px;">{{v.tax_uniquecode}}</span>
                                                </div>
                                                <div class="col-md-4">
                                                    <h6>Slab Name</h6>
                                                    <input v-model="v.tax_name" name = "tax_name[]" type="text" class="form-control"/>
                                                </div>
                                                <div class="col-md-4">
                                                    <h6>{{ $auth.getTaxName() }} (In Percentage)</h6>
                                                    <input v-model="v.tax_per" name = "tax_per[]" type="text" @keypress="decNumberOnly($event)" @paste.prevent class="form-control"/>
                                                </div>
                                                <div class="col-md-2">
                                                    <h6>&nbsp;</h6>
                                                    <button type="button" class="btn btn-primary btn-sm btn-rounded" @click.prevent="addTaxSlabField">+</button>&nbsp;&nbsp;
                                                    <!-- <button class="btn btn-danger btn-sm btn-rounded" v-if="index != 0 || index!=''" @click.prevent="removeTaxSlab(index)">-</button> -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                             </div>
                        </div>

                        <div class="btn-update">
                            <div class="col-sm-6">
                                <button
                                    type="submit"
                                    v-if="$auth.hasPermission(config.permissions.configuration_write)"
                                    class="btn btn-primary mr-1 mt-3"
                                >Update</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </b-form>
</template>