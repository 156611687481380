<script>
import Layout from "../../layouts/main";
import ReportList from './report-list.vue';

/**
 * Starter component
 */
export default {
  components: { Layout , ReportList },
  data() {
    return {};
  },
};
</script>
<template>
  <Layout>
   <report-list/>
  </Layout>
</template>